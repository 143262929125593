import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/OnBoard';
import { toastify } from '../ducks/toast';
import { getProfile } from '../ducks/auth';

function* updObIndtry(action) {
    try {
        yield put(load());
        const res = yield call(api.POST, '/updObIndtry', action.payload);
        if (res.status === 'success') {
            yield put(getProfile());
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    }
    catch (error) {
        yield put(loaded());
        yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}

function* getIntList() {
    try {
        yield put(load());
        const res = yield call(api.GET, '/getIntList');
        if (res.status === 'success') {
            yield put(actions.getIntListSuccess(res.data));
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    }
    catch (error) {
        yield put(loaded());
        yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}

function* skipOnboarding(action) {
    try {
        yield put(load());
        const res = yield call(api.POST, '/skipOnboarding', action.payload);
        if (res.status === 'success') {
            yield put(getProfile());
            localStorage.setItem('iskponbrdng', true);
            window.location.reload();
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    }
    catch (error) {
        yield put(loaded());
        yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}

export function* watchUpdObIndtry() {
    yield takeLatest(actions.updObIndtry.type, updObIndtry);
}

export function* watchGetIntList() {
    yield takeLatest(actions.getIntList.type, getIntList);
}

export function* watchSkipOnboarding() {
    yield takeLatest(actions.skipOnboarding.type, skipOnboarding);
}

export default function* rootSaga() {
    yield fork(watchUpdObIndtry)
    yield fork(watchGetIntList)
    yield fork(watchSkipOnboarding);
}