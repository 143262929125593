import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const appsumoPlan = createSlice({
  name: 'appsumoPlan',
  initialState,
  reducers: {
    getAppsumoPlan: (state, action) => { },
    getAppsumoPlanSuccess: (state, action) => {
      return { ...state, appsumoPlan: action.payload };
    }
  }
});

export const {
  getAppsumoPlan,
  getAppsumoPlanSuccess
} = appsumoPlan.actions;

export default appsumoPlan.reducer;
