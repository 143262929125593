import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import * as actions from '../ducks/notifEdit';
import * as notifActions from '../ducks/notif';
import { load, loaded } from '../ducks/loading';
import { toastify } from '../ducks/toast';

function* getNotfById(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getNotifById?_id=' + action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotfByIdSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateNotifDesign(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updNotifDesign', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      //get notif by notif id data
      yield put(actions.getNotfByIdSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getIconList() {
  try {
    const res = yield call(api.GET, '/getIcon');
    if (res.status === 'success') {
      yield put(actions.getIconSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get icon list==1' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: error + 'Failed to get icon list' }));
  }
}

function* updDisplayType(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/updDisplayType?_id=' + action.payload._id + '&dt=' + action.payload.dt + '&fn=' + action.payload.fn);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification display type' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updAbsoluteURL(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updAbsoluteURL', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add url' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addDTU(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addDTU', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data added successfully' }));
      yield put(actions.getNotfById(action.payload._id));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add url' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteDTU(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteDTU', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data deleted successfully' }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getDomain(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getDomain?_id=' + action.payload);

    if (res.status === 'success') {
      yield put(actions.getDomainSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get domain list' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addDomain(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addDomain', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getDomain(res.data._id));
      yield put(notifActions.getNotiByUser(global.filterUserNotif));
      yield put(notifActions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification display rule' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delDomain(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/delDomain?_id=' + action.payload._id + '&ddnID=' + action.payload.ddnID + '&fn=' + action.payload.fn + '&ntt=' + action.payload.ntt);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getDomain(res.data._id));
      yield put(notifActions.getNotiByUser(global.filterUserNotif));
      yield put(notifActions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete domain' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updTargetToggle(action) {
  try {
    const res = yield call(api.POST, '/updTargetToggel', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      if (action.payload?.name === 'advfcnd' || action.payload?.name === 'icntntf') {
        yield put(actions.getNotfById(action.payload.nid));
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updNotifCntry(action) {
  try {
    const res = yield call(api.POST, '/updNotifCntry', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification setting' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addUTMDisplay(action) {
  try {
    const res = yield call(api.POST, '/addUTMDisplay', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteTarget(action) {
  try {
    const res = yield call(api.POST, '/deleteTarget', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addRefDisplay(action) {
  try {
    const res = yield call(api.POST, '/addRefDisplay', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addCoockiDisplay(action) {
  try {
    const res = yield call(api.POST, '/addCoockiDisplay', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updDST(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/updDST?_id=' + action.payload._id + '&dst=' + action.payload.dst + '&fn=' + action.payload.fn);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification data-source display type' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addDTSCU(action) {
  try {
    const res = yield call(api.POST, '/addDTSCU', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data added successfully' }));
      yield put(actions.getNotfById(action.payload._id));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add url' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteDTSCU(action) {
  try {
    const res = yield call(api.POST, '/deleteDTSCU', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data deleted successfully' }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add url' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getPixelData(action) {
  try {
    const res = yield call(api.POST, '/getPixelData', action.payload);
    if (res.status === 'success') {
      yield put(actions.getPixelDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get data' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getActiveIntgList(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getActiveIntgList', action.payload);
    if (res.status === 'success') {
      yield put(actions.getActiveIntgListSuccess(res.data));
    }
    else yield put(toastify({ type: 'error', msg: 'Failed to get active integration list' }));
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getDataCount(action) {
  try {
    const res = yield call(api.POST, '/getDataCount', action.payload);
    if (res.status === 'success') {
      yield put(actions.getDataCountSuccess(res.data));
    }
    else if (res.status === 'error') {
      yield put(toastify({ type: 'error', msg: 'Failed to get integration count' }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteMediaIcon(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/deleteIcon?_id=' + action.payload.id + '&url=' + action.payload.url);
    if (res.status === 'success') {
      yield put(actions.getIconList());
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete icon' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendWebhookData(action) {
  try {
    // yield put(load());
    const res = yield call(api.POST, '/sendWebhookData', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data sent successfully' }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to send data' }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Failed to send data' }));
  }
}

function* updNotifIcon(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updNotifIcon', action.payload);
    if (res.status === 'success') {
      yield put(actions.updNotifIconSuccess(res.iconUrl));
      yield put(actions.getIconSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updNotifStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updNotifStatus', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'limit') {
      if (action.payload.togl && action.payload.togl === true) {
        let obj = {
          _id: action.payload._id,
          dt: new Date().getTime()
        };
        yield put(actions.updNotifStatusSuccess(obj));
      }
      else {
        yield put(toastify({ type: 'error', msg: res.m }));
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification status' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getProductURL(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/getProductURL?pgid=' + action.payload.pgid);
    if (res.status === 'success') {
      yield put(actions.getProductURLSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get product url' }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addDataSrc(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addDataSrc', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfByIdSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add data source' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delDataSrc(action) {
  try {
    yield put(load());
    let res;
    if (action.payload.pid) {
      res = yield call(api.DELETE, '/delDataSrc?uid=' + action.payload.uid + '&_id=' + action.payload._id + '&ntt=' + action.payload.ntt + '&pid=' + action.payload.pid);
    }
    else {
      res = yield call(api.DELETE, '/delDataSrc?uid=' + action.payload.uid + '&_id=' + action.payload._id + '&ntt=' + action.payload.ntt);
    }
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfByIdSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete data source' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAbTestNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/deleteAbTestNotif?notifId=' + action.payload.notifId + '&abId=' + action.payload.abId);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfByIdSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAbTestNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createAbTestNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfByIdSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getUserNotif() {
  try {
    yield put(load());
    //check if login user is team member pass a flag
    let path = (localStorage.getItem('istm') === 'true') ? '/getUserNotif?istm=' + true : '/getUserNotif'

    const res = yield call(api.GET, path);

    if (res.status === 'success') {
      yield put(actions.getUserNotifSuccess(res.data));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* importDisplaySetting(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/importDisplaySetting?fromNotifId=' + action.payload.fromNotifId + '&_id=' + action.payload._id);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    } else {
      yield put(toastify({ type: 'error', msg: 'Failed to import display rule' }));
    }

    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getShpPrducts(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/shopify/getShpPrdList', action.payload);
    if (res.status === 'success') {
      yield put(actions.getShpPrductsSuccess(res.data));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, 'Failed to get product list');
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addShpProStock(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addShpProductStock', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data added successfully' }));
      yield put(actions.addShpProStockSuccess(res.status));
      yield put(actions.getNotfById(action.payload._id));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add' }));
    }

    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* changeShpProStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/changeShpProductStatus', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data updated successfully' }));
      yield put(actions.changeShpProStatusSuccess(res.status));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add url' }));
    }

    yield put(loaded());
  } catch (error) {
    console.log(error, '.....changeShpProductStatus');
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//delete shop product stock
function* delShpProStock(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteShpProductStock', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data updated successfully' }));
      yield put(actions.delShpProStockSuccess(res.status));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete' }));
    }

    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getIntData(action) {
  try {
    yield put(load())
    let res;
    if (action.payload.fuid) {
      if (action.payload.date)
        res = yield call(api.GET, '/getIntData?fuid=' + action.payload.fuid + '&page=' + action.payload.page + '&typ=' + action.payload.typ + '&date=' + action.payload.date + '&inm=' + action.payload.inm);
      else
        res = yield call(api.GET, '/getIntData?fuid=' + action.payload.fuid + '&page=' + action.payload.page + '&typ=' + action.payload.typ + '&inm=' + action.payload.inm);
    }
    else {
      if (action.payload.date)
        res = yield call(api.GET, '/getIntData?fuid=' + '' + '&page=' + action.payload.page + '&typ=' + action.payload.typ + '&pgid=' + action.payload.pgid + '&date=' + action.payload.date + '&inm=' + action.payload.inm);
      else
        res = yield call(api.GET, '/getIntData?fuid=' + '' + '&page=' + action.payload.page + '&typ=' + action.payload.typ + '&pgid=' + action.payload.pgid + '&inm=' + action.payload.inm);
    }

    if (res.status === 'success') {
      yield put(actions.getIntDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get integration data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delInteCntData(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/delSignupData?&q=' + JSON.stringify(action.payload));
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'not_found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, '=delInteCntData');
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addCapInc(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addCapInc', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      // yield put(actions.getFilterOption());
      yield put(actions.getNotfById(action.payload._id));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to add url' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delCapInclUrl(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/delCapInclUrl?nid=' + action.payload.nid + '&_id=' + action.payload._id);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete url' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delCapExclUrl(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/delCapExclUrl?nid=' + action.payload.nid + '&_id=' + action.payload._id);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotfById(action.payload.nid));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete url' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* callPolarisBug(action) {
  try {
    const res = yield call(api.POST, '/polarisBug', action.payload);
    if (res.status !== 'success') {
      console.log('Failed to call polarisBug api');
    }
  } catch (error) {
    console.log(error, 'Failed to call polarisBug api');
  }
}







export function* watchGetNotfById() {
  yield takeLatest(actions.getNotfById.type, getNotfById);
}
export function* watchUpdateNotifDesign() {
  yield takeLatest(actions.updateNotifDesign.type, updateNotifDesign);
}
export function* watchGetIconList() {
  yield takeLatest(actions.getIconList.type, getIconList);
}
export function* watchUpdDisplayType() {
  yield takeLatest(actions.updDisplayType.type, updDisplayType);
}
export function* watchUpdAbsoluteURL() {
  yield takeLatest(actions.updAbsoluteURL.type, updAbsoluteURL);
}
export function* watchAddDTU() {
  yield takeLatest(actions.addDTU.type, addDTU);
}
export function* watchDeleteDTU() {
  yield takeLatest(actions.deleteDTU.type, deleteDTU);
}
export function* watchGetDomain() {
  yield takeLatest(actions.getDomain.type, getDomain);
}
export function* watchAddDomain() {
  yield takeLatest(actions.addDomain.type, addDomain);
}
export function* watchDelDomain() {
  yield takeLatest(actions.delDomain.type, delDomain);
}
export function* watchUpdTargetToggel() {
  yield takeLatest(actions.updTargetToggle.type, updTargetToggle);
}
export function* watchUpdNotifCntry() {
  yield takeLatest(actions.updNotifCntry.type, updNotifCntry);
}
export function* watchAddUTMDisplay() {
  yield takeLatest(actions.addUTMDisplay.type, addUTMDisplay);
}
export function* watchDeleteTarget() {
  yield takeLatest(actions.deleteTarget.type, deleteTarget);
}
export function* watchAddRefDisplay() {
  yield takeLatest(actions.addRefDisplay.type, addRefDisplay);
}
export function* watchAddCoockiDisplay() {
  yield takeLatest(actions.addCoockiDisplay.type, addCoockiDisplay);
}
export function* watchUpdDST() {
  yield takeLatest(actions.updDST.type, updDST);
}
export function* watchAddDTSCU() {
  yield takeLatest(actions.addDTSCU.type, addDTSCU);
}
export function* watchDeleteDTSCU() {
  yield takeLatest(actions.deleteDTSCU.type, deleteDTSCU);
}
export function* watchGetPixelData() {
  yield takeLatest(actions.getPixelData.type, getPixelData);
}
export function* watchGetActiveIntgList() {
  yield takeLatest(actions.getActiveIntgList.type, getActiveIntgList);
}
export function* watchGetDataCount() {
  yield takeLatest(actions.getDataCount.type, getDataCount);
}
export function* watchDeleteIcon() {
  yield takeLatest(actions.deleteMediaIcon.type, deleteMediaIcon);
}
export function* watchSendWebhookData() {
  yield takeLatest(actions.sendWebhookData.type, sendWebhookData);
}
export function* watchUpdNotifIcon() {
  yield takeLatest(actions.updNotifIcon.type, updNotifIcon);
}
export function* watchUpdNotifStatus() {
  yield takeLatest(actions.updNotifStatus.type, updNotifStatus);
}
export function* watchGetProductURL() {
  yield takeLatest(actions.getProductURL.type, getProductURL);
}
export function* watchAddDataSrc() {
  yield takeLatest(actions.addDataSrc.type, addDataSrc);
}
export function* watchDelDataSrc() {
  yield takeLatest(actions.delDataSrc.type, delDataSrc);
}
export function* watchDeleteAbTestNotif() {
  yield takeLatest(actions.deleteAbTestNotif.type, deleteAbTestNotif);
}
export function* watchCreateAbTestNotif() {
  yield takeLatest(actions.createAbTestNotif.type, createAbTestNotif);
}
export function* watchGetUserNotif() {
  yield takeLatest(actions.getUserNotif.type, getUserNotif);
}
export function* watchImportDisplaySetting() {
  yield takeLatest(actions.importDisplaySetting.type, importDisplaySetting);
}
export function* watchGetShpPrducts() {
  yield takeLatest(actions.getShpPrducts.type, getShpPrducts);
}
export function* watchAddShpProductStock() {
  yield takeLatest(actions.addShpProStock.type, addShpProStock);
}
export function* watchChangeShpProStatus() {
  yield takeLatest(actions.changeShpProStatus.type, changeShpProStatus);
}
export function* watchDelShpProStock() {
  yield takeLatest(actions.delShpProStock.type, delShpProStock);
}
export function* watchGetIntData() {
  yield takeLatest(actions.getIntData.type, getIntData);
}
export function* watchDelInteCntData() {
  yield takeLatest(actions.delInteCntData.type, delInteCntData);
}
export function* watchAddCapInclUrl() {
  yield takeLatest(actions.addCapInc.type, addCapInc);
}
export function* watchDelCapInclUrl() {
  yield takeLatest(actions.delCapInclUrl.type, delCapInclUrl);
}
export function* watchDelCapExclUrl() {
  yield takeLatest(actions.delCapExclUrl.type, delCapExclUrl);
}
export function* watchCallPolarisBug() {
  yield takeLatest(actions.callPolarisBug.type, callPolarisBug);
}

export default function* rootSaga() {
  yield fork(watchGetNotfById);
  yield fork(watchUpdateNotifDesign);
  yield fork(watchGetIconList);
  yield fork(watchUpdDisplayType);
  yield fork(watchUpdAbsoluteURL);
  yield fork(watchDeleteDTU);
  yield fork(watchAddDTU);
  yield fork(watchGetDomain);
  yield fork(watchAddDomain);
  yield fork(watchDelDomain);
  yield fork(watchUpdTargetToggel);
  yield fork(watchUpdNotifCntry);
  yield fork(watchAddUTMDisplay);
  yield fork(watchDeleteTarget);
  yield fork(watchAddRefDisplay);
  yield fork(watchAddCoockiDisplay);
  yield fork(watchUpdDST);
  yield fork(watchAddDTSCU);
  yield fork(watchDeleteDTSCU);
  yield fork(watchGetPixelData);
  yield fork(watchGetActiveIntgList);
  yield fork(watchGetDataCount);
  yield fork(watchDeleteIcon);
  yield fork(watchUpdNotifIcon);
  yield fork(watchSendWebhookData);
  yield fork(watchUpdNotifStatus);
  yield fork(watchGetProductURL);
  yield fork(watchAddDataSrc);
  yield fork(watchDelDataSrc);
  yield fork(watchDeleteAbTestNotif);
  yield fork(watchCreateAbTestNotif);
  yield fork(watchGetUserNotif);
  yield fork(watchImportDisplaySetting);
  yield fork(watchGetShpPrducts);
  yield fork(watchAddShpProductStock);
  yield fork(watchChangeShpProStatus);
  yield fork(watchDelShpProStock);
  yield fork(watchGetIntData);
  yield fork(watchDelInteCntData);
  yield fork(watchAddCapInclUrl);
  yield fork(watchDelCapInclUrl);
  yield fork(watchDelCapExclUrl);
  yield fork(watchCallPolarisBug);
}