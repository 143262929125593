import { useCallback, useEffect, useState } from "react";
import { OnBoardCard } from "../../components";
import './OnBoard.css';
import { useDispatch, useSelector } from "react-redux";
import * as Onboarding from '../../ducks/OnBoard';
import { toastify } from "../../ducks/toast";
import * as authDucks from '../../ducks/auth';
import * as pricingPlanDucks from '../../ducks/pricingPlan';

const OnBoard = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    stepName: 'step0',
    key: 0,
    selectedValues: '',
    otherValue: '',
    selectedApps: [],
    otherApp: '',
    searchApp: '',
    appList_arr: [],
    filteredAppList: [],
    profile: {},
    newPlanList: [],
    basicPlan: {},
    monthPlan: [],
    yearPlan: [],
    lstAddon: [],
    isOpenChargebeeFrm: false,
    plv: 'month',
    proPlanList: [],
    proOptionList: [],
    growthPlanList: [],
    growthOptionList: [],
    basicPlanPrice: '',
    basicPlanVisitor: '',
    selctdProPlan: {},
    selctdProPlanPrice: '',
    selctdProPlanVisitor: '',
    selctdGrowthPlan: {},
    selctdGrowthPlanPrice: '',
    selctdGrowthPlanVisitor: '',
    selctdPlanId: '',
    selctdPlanPrice: 0,
  });

  //api data.
  const getAll_App = useSelector((state) => state.onBoarding.getappList);
  const profileState = useSelector(state => state.auth.profile);
  const lstPlan = useSelector(state => state.pricingPlan.lstPlan);
  const lstAddon = useSelector(state => state.pricingPlan.lstAddon);
  const chargebeeFrm = useSelector(state => state.pricingPlan.chargebeeFrm);
  const chargbeeSuccess = useSelector(state => state.pricingPlan.chargbeeSuccess);

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api call.
  useEffect(() => {
    dispatch(pricingPlanDucks.getPlan());
    dispatch(pricingPlanDucks.getAddon());
  }, []);

  const handleCheckboxChange = (value) => {
    changeNameValue({ selectedValues: value });
  };

  const handleSelectApp = useCallback((e, value) => {

    if (e.detail === 1) {
      setState((prevState) => {
        const { selectedApps } = prevState;
        const specialApp = 'Other';
        if (value === specialApp) {
          if (selectedApps.includes(specialApp)) {
            return prevState;
          }
          return {
            ...prevState,
            selectedApps: [...selectedApps, specialApp],
          };
        }

        const isSelected = selectedApps.includes(value);
        const updatedSelectedApps = isSelected
          ? selectedApps.filter((app) => app !== value)
          : [...selectedApps, value];

        return {
          ...prevState,
          selectedApps: updatedSelectedApps,
        };
      });
    }
    else if (value === 'Other' && e.detail === 2) {
      setState((prevState) => {
        const { selectedApps } = prevState;
        const specialApp = 'Other';

        if (value === 'Other' || selectedApps.includes(specialApp)) {
          return {
            ...prevState,
            selectedApps: selectedApps.filter((app) => app !== specialApp),
          };
        }
        return prevState;
      });
    }
  }, []);

  useEffect(() => {
    dispatch(Onboarding.getIntList());
  }, [dispatch]);

  useEffect(() => {
    if (getAll_App) {
      changeNameValue({ allApp_List: getAll_App });
    }
  }, [getAll_App, changeNameValue]);


  useEffect(() => {
    if (state.allApp_List?.length > 0) {
      let allAppList = state.allApp_List.flatMap(category => category.data);
      // allAppList.sort((a, b) => a.dn.localeCompare(b.dn));

      let withObdo = allAppList.filter(obj => obj.obdo !== undefined);
      let withoutObdo = allAppList.filter(obj => obj.obdo === undefined);

      // Sort the objects with `odbo` in descending order
      withObdo.sort((a, b) => b.obdo - a.obdo);
      //add new object for display other block
      withObdo.push({ dn: 'Other' })

      allAppList = [...withObdo, ...withoutObdo]

      changeNameValue({ appList_arr: allAppList, filteredAppList: allAppList });
    }
  }, [state.allApp_List, changeNameValue]);

  const handleSelNext = useCallback(() => {
    let obj = {};

    if (state.selectedValues === 'Other' && state.otherValue === '') {
      dispatch(toastify({ type: 'error', msg: "Enter your industry" }));
      return;
    }

    obj.indtry = state.selectedValues === 'Other' ? state.otherValue : state.selectedValues;
    changeNameValue({ 'selectedValues': '', 'otherValue': '' })

    //track customer.io event or attribute 
    if (window._cio) {
      window._cio.identify({
        id: state.profile.ti,
        industry: obj.indtry
      });
      window._cio.track('Step1_Done');
    }

    dispatch(Onboarding.updObIndtry(obj));
    obj = {}
  }, [state.selectedValues, state.otherValue]);


  let handleRoleChange = useCallback(() => {
    let obj = {}
    if ((state.selectedApps.includes('Other') && state.otherApp === '') && (state.selectedApps.length === 1)) {
      dispatch(toastify({ type: 'error', msg: "Enter application platform" }));
      return;
    }
    else if (state.selectedApps.length === 0) {
      dispatch(toastify({ type: 'error', msg: "Select at least one app" }));
      return;
    }
    obj.arrusrpltfm = state.selectedApps.filter(app => app !== 'Other').concat(state.otherApp || []);
    changeNameValue({ 'selectedApps': [], 'otherApp': '' })

    let selctdArr = [];
    for (let i = 0; i < state.selectedApps.length; i++) {
      const val = state.selectedApps[i];
      if (val !== 'Other') {
        let _index = state.appList_arr.findIndex(x => x.dn === val);
        if (_index !== -1) {
          selctdArr.push({ dn: state.appList_arr[_index].dn, hpul: state.appList_arr[_index].hpul || state.appList_arr[_index].hpulpxl });
        }
      }
      else if (state.selectedApps.length === 1) {
        selctdArr.push({ other: state.otherApp });
      }
    }

    //if user skip step2
    if (state.selectedApps.length === 0) {
      selctdArr.push({ dn: '', hpul: '' });
    }

    // track customer.io event or attribute 
    if (window._cio) {
      window._cio.identify({
        id: state.profile.ti,
        // Selected_Qouta: this.state.userQuota ? this.state.userQuota : '',
        // BusinessName: this.state.bsnsnm ? this.state.bsnsnm : ''
        marketing_stack: JSON.stringify(selctdArr)
      });
      window._cio.track('Step2_Done');
    }

    dispatch(Onboarding.updObIndtry(obj));
    obj = {}
  }, [state.otherApp, changeNameValue, state.selectedApps])


  const handleSearch = useCallback((query) => {
    changeNameValue({ searchApp: query });

    if (query === '') {
      changeNameValue({ filteredAppList: state.appList_arr });
    } else {
      const filtered = state.appList_arr.filter((app) => app.dn?.toLowerCase().includes(query.toLowerCase()));
      changeNameValue({ filteredAppList: filtered });
    }
  }, [state.appList_arr, changeNameValue]);

  useEffect(() => {
    //set profileState data.
    if (profileState) {
      changeNameValue({ profile: profileState });
    }
    //set lstAddon data.
    if (lstAddon) {
      changeNameValue({ lstAddon });
    }
  }, [profileState, lstAddon]);

  // handleAfterPaymant.
  const handleAfterPaymant = () => {
    window.location.href = '/pixel';
  }

  useEffect(() => {
    //set plan data.
    if (lstPlan && state.profile && localStorage.getItem('pvd') !== 'wix') {
      const updatedMonthPlan = [];
      const updatedYearPlan = [];
      const updatedNewPlanList = [];

      lstPlan.forEach(planObj => {
        // Create a shallow copy of the plan object
        let planCopy = { ...planObj.plan, newPrice: planObj.plan.price / 100 };

        if (planCopy.period_unit === 'month' && planCopy.period === 1 && planCopy.id !== 'free_plan') {
          updatedMonthPlan.push(planCopy);
        } else if (planCopy.period_unit === 'year' && planCopy.period === 1 && planCopy.id !== 'free_plan') {
          updatedYearPlan.push(planCopy);
        }

        if ((planCopy.period_unit === 'year' || planCopy.period_unit === 'month') && planCopy.period === 1 && planCopy.id !== 'free_plan' && planCopy.id !== 'basic-yearly') {
          updatedNewPlanList.push(planCopy);
        }

        if (planCopy.id === 'basic-yearly' && state.profile.csub === 'free') {
          updatedNewPlanList.push(planCopy);
        }

        if (planCopy.id === 'basic-yearly') {
          changeNameValue({ basicPlan: planCopy })
        }
      });

      let obj = {
        monthPlan: updatedMonthPlan,
        yearPlan: updatedYearPlan,
        newPlanList: updatedNewPlanList,
        selctdPlanId: state.profile?.billing?.pobj?.id,
        selctdPlanPrice: (state.profile?.billing?.pobj?.price) / 100
      }
      changeNameValue(obj);
    }
    //chargebee form open for purchase plan.
    if (chargebeeFrm && state.isOpenChargebeeFrm === true) {
      let isSuccess = false;
      if (chargebeeFrm) {
        //customer.io track event
        if (window._cio) {
          window._cio.track('Step3_CB', { value: 'Open' });
        }
        var chargebeeInstance = window.Chargebee.getInstance();
        chargebeeInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve) {
              var sampleResponse = chargebeeFrm;
              resolve(sampleResponse);
            });
          },
          success: function (hostedPageId) {
            //customer.io track event
            if (window._cio) {
              window._cio.track('Step3_CB', { value: 'Success' });
            }
            //clear data chargebee form
            dispatch(pricingPlanDucks.createChargeBeeFormClear());
            isSuccess = true;
            let obj = { pgId: hostedPageId };
            setTimeout(() => {
              dispatch(pricingPlanDucks.chargbeePayment(obj));
              setTimeout(() => {
                handleAfterPaymant();
              }, 4000);
            }, 100);
          },
          close: function () {
            setTimeout(() => {
              //clear data chargebee form
              dispatch(pricingPlanDucks.createChargeBeeFormClear());
              changeNameValue({ isOpenChargebeeFrm: false });
              if (isSuccess === true) {
                window.location.href = '/pixel';
              }
              else {
                dispatch(authDucks.getProfile());
              }
              //customer.io track event
              if (window._cio) {
                window._cio.track('Step3_CB', { value: 'Close' });
              }
            }, 100);
          }
        });
      }
    }
    if (chargbeeSuccess && state.profile && state.profile.billing) {
      if (chargbeeSuccess.content && chargbeeSuccess.content.invoice) {
        let paidAmount = chargbeeSuccess.content.invoice.amount_paid / 100;
        paidAmount = paidAmount * 74;

        if (window.gtag) {
          try {
            //for google ads
            window.gtag('event', 'conversion', {
              'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC',
              'value': paidAmount,
              'currency': 'INR',
              'transaction_id': state.profile.ti
            });
          } catch (error) {
            console.log('error===>', error);
          }
        }
        window.location.reload();
      }
    }

  }, [lstPlan, chargebeeFrm, state.isOpenChargebeeFrm, chargbeeSuccess, state.profile]);

  //handle Tab Change.
  const handleTabChange = (data) => {
    changeNameValue({ plv: data.tabName });
    // localStorage.setItem('plv', data.tabName);

    //customer.io track event
    if (window._cio) {
      window._cio.track('Plan_Radio_button', { button: data.tabName });
    }


    // Update plan options for both 'month' and 'year' tabs
    updatePlanOptions(state.proPlanList, 'proOptionList', 'selctdProPlan', 'selctdProPlanVisitor', 'selctdProPlanPrice', true);
    updatePlanOptions(state.growthPlanList, 'growthOptionList', 'selctdGrowthPlan', 'selctdGrowthPlanVisitor', 'selctdGrowthPlanPrice', true);
  }

  // function convert visitors.
  const convert = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  };

  // function update Month PlanList.
  const updateMonthPlanList = (excludedIds, stateKey) => {
    const filteredPlans = state.monthPlan.filter(({ id }) => excludedIds.includes(id))
    changeNameValue({ [stateKey]: filteredPlans });
  };

  // function update Year PlanList.
  const updateYearPlanList = (excludedIds, stateKey) => {
    const filteredPlans = state.yearPlan.filter(({ id }) => excludedIds.includes(id));
    changeNameValue({ [stateKey]: filteredPlans });
  };

  //set year and month plan list data.
  useEffect(() => {
    if (state.plv === 'year') {
      if (state.yearPlan && state.yearPlan.length > 0) {
        updateYearPlanList(['startups-yearly-plan', 'standard-yearly-plan', 'premium-yearly-plan'], 'proPlanList');
        updateYearPlanList(['agency-starter-yearly', 'agency-standard-yearly', 'agency-exclusive-yearly', '1-million-visitors'], 'growthPlanList');
      }
    } else {
      if (state.monthPlan && state.monthPlan.length > 0) {
        updateMonthPlanList(['startups-monthly-plan', 'standard-monthly-plan', 'premium-monthly-plan'], 'proPlanList');
        updateMonthPlanList(['agency-starter-monthly', 'agency-standard-monthly', 'agency-premium-monthly', 'agency-enterprise-monthly'], 'growthPlanList');
      }
    }
    if (state.basicPlan) {
      let obj = {
        basicPlanPrice: (state.basicPlan.price / 100),
        basicPlanVisitor: convert(state.basicPlan.cf_plan_quota)
      }
      changeNameValue(obj);
    }
  }, [state.plv, state.monthPlan, state.yearPlan, state.basicPlan]);

  // function updatePlanOptions
  const updatePlanOptions = (planList, optionListKey, selctdPlanKey, selctdPlanVisitorKey, selctdPlanPriceKey, resetSelection = false) => {
    if (planList) {
      planList.sort((a, b) => a.cf_plan_quota - b.cf_plan_quota);

      const options = planList.map((val) => ({
        label: `${convert(val.cf_plan_quota)} Visitors/month`,
        value: val.cf_plan_quota,
      }));
      changeNameValue({ [optionListKey]: options });
    }

    // If resetSelection is true, always select the first plan
    // if (resetSelection) {
    //   const defaultPlan = planList[0];
    //   changeNameValue({
    //     [selctdPlanVisitorKey]: defaultPlan?.cf_plan_quota,
    //     [selctdPlanPriceKey]: defaultPlan?.price / 100,
    //     [selctdPlanKey]: defaultPlan,
    //   });
    //   return;
    // }

    // Filter plan based on selctdPlanId
    const filterPlan = planList.filter((val) => val.id === state.selctdPlanId);

    const selctdPlan = state[selctdPlanVisitorKey];
    if (!selctdPlan) {
      let defaultPlan;
      if (filterPlan.length > 0) {
        defaultPlan = filterPlan[0];
      } else {
        defaultPlan = planList[0]; // First plan in the list
      }
      changeNameValue({
        [selctdPlanVisitorKey]: defaultPlan?.cf_plan_quota,
        [selctdPlanPriceKey]: defaultPlan?.price / 100,
      });
    } else {
      const filteredPlan = planList.find(item => item.cf_plan_quota === selctdPlan);
      if (filteredPlan) {
        changeNameValue({ [selctdPlanPriceKey]: filteredPlan.price / 100 });
        changeNameValue({ [selctdPlanKey]: filteredPlan });
      }
    }
  };

  const getSelectedPlan = (selectedQuota) => {
    const filteredPlan = state.newPlanList.find(item => item.cf_plan_quota === selectedQuota);

    //customer.io track event
    if (window._cio && filteredPlan && filteredPlan.name) {
      window._cio.track('Plan_Selected', { plan: filteredPlan.name });
    }
  }

  // useEffect to update plan options on state change
  useEffect(() => {
    updatePlanOptions(state.proPlanList, 'proOptionList', 'selctdProPlan', 'selctdProPlanVisitor', 'selctdProPlanPrice');
    updatePlanOptions(state.growthPlanList, 'growthOptionList', 'selctdGrowthPlan', 'selctdGrowthPlanVisitor', 'selctdGrowthPlanPrice');
  }, [state.proPlanList, state.growthPlanList, state.selctdProPlanVisitor, state.selctdGrowthPlanVisitor, state.selctdPlanId]);

  //get Upgrade Confirmation.
  const getUpgradeConfirmation = (type) => {
    const planList = {
      pro: state.selctdProPlan,
      growth: state.selctdGrowthPlan,
      basic: state.basicPlan
    };

    //customer.io track event
    if (window._cio) {
      window._cio.track('Step3_Submit');
    }

    const selectedPlan = planList[type];

    if (selectedPlan) {
      let _price = 0;
      const isValid = ((selectedPlan.price / 100) > _price)
      if (isValid === false) {
        return;
      }

      if (window.ga) {
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', 'Plan Select Dashboard', selectedPlan.invoice_name, state.profile.e, { transport: 'beacon' });
          //for GA-4
          window.gtag('event', 'Plan Select Dashboard', { 'event_action': selectedPlan.invoice_name, 'event_label': state.profile ? state.profile.e : '' });
        } catch (error) {
          console.log(error);
        }
      }
      state.coupon = state.cn;
      changeNameValue({ isOpenChargebeeFrm: true });

      let _coupon = [];
      if (state.profile.rfid && state.profile.rfid !== 'null') {
        _coupon.push('REFERRAL10');
      }
      let obj = {
        plan_id: selectedPlan?.id,
        sub_id: state.profile?.billing?.sobj?.id,
        email: state.profile.e,
        first_name: state.profile.fn,
        coupon: _coupon
      };
      if (state.profile.cbe && state.profile.cbe.client_id) {
        obj.cust_id = state.profile.cbe.client_id;
      }

      if (window.gtag) {
        try {
          window.gtag('event', 'view_item', {
            value: selectedPlan.newPrice,//plan amount: 16,26..
            pvd: state.profile.pvd,
            event_label: selectedPlan.name,//plan name
            email: state.profile.e,
            path: window.location.pathname,//page url
            items: [
              {
                item_id: selectedPlan.name,//plan name
                item_name: selectedPlan.name,//plan name,
                price: selectedPlan.newPrice,//plan amount: 16,26..
                quantity: 1,
                currency: 'USD'
              }
            ]
          });
        } catch (error) {
          console.log(error);
        }
      }
      dispatch(pricingPlanDucks.createChargeBeeForm(obj));
    }
  }

  // handle Chat Button.
  const handleChatButton = () => {
    if (window.Froged) {
      window.Froged('message', 'I have more than 200K monthly visitors');
    }
  }

  // handle Skip Onboarding.
  const handleSkipOnboarding = () => {
    dispatch(Onboarding.skipOnboarding());
    if (window._cio) {
      window._cio.track('plan_skip');
    }
  }

  return (
    <OnBoardCard
      changeNameValue={changeNameValue}
      state={state}
      handleSelNext={handleSelNext}
      handleRoleChange={handleRoleChange}
      handleCheckboxChange={handleCheckboxChange}
      handleSelectApp={handleSelectApp}
      handleSearch={handleSearch}
      handleTabChange={handleTabChange}
      getUpgradeConfirmation={getUpgradeConfirmation}
      handleChatButton={handleChatButton}
      getSelectedPlan={getSelectedPlan}
      handleSkipOnboarding={handleSkipOnboarding}
    />
  );
};

export default OnBoard;
