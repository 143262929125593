import { Frame, Modal, TextField } from '@shopify/polaris';

function Clone({ props }) {
  const { state, changeNameValue, handleCreateClone } = props;

  const handleChange = () => changeNameValue({ isClone: !state.isClone });
  const handleChangeNotifName = (e) => changeNameValue({ cloneNotifName: e });
  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Modal
          open={state.isClone}
          onClose={handleChange}
          title={`Create a clone of ${state.cnname}`}
          primaryAction={{
            content: 'Create clone',
            onAction: handleCreateClone,
          }}

        >
          <Modal.Section>
            <TextField
              label="New notification name"
              value={state.cloneNotifName}
              onChange={handleChangeNotifName}
              placeholder='Enter new notification name'
              autoComplete="off"
              error={state.errMessage.cloneNotifName ? "The new notification name field is required." : false}
            />
            {props.validator.message('cloneNotifName', state.cloneNotifName, 'required')}
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  );
}

export default Clone