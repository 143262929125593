import React, { memo } from 'react';
import Chart from 'react-apexcharts';

const HoverLine = (props) => {

  const { hAry = [0], date } = props;

  if (hAry.length < 1) {
    return;
  }

  let options = {
    chart: {
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: '#fba358',
        opacity: 0.2
      },
      toolbar: {
        show: false
       },
       zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    xaxis: {
      floating: true,
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: {
        show: true
      },
      tooltip: {
        enabled: false
      },
      categories: date
    },
    floating: true,

    axisTicks: {
      show: false
    },
    axisBorder: {
      show: false
    },
    yaxis: {
      show: false,
    },
    labels: {
      show: false
    },
    grid: {
      show: false,
      borderColor: '#fba358',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: undefined,
        opacity: 0.5
      },
      column: {
        colors: undefined,
        opacity: 0.5
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 5,
        left: 0
      },
    },
    colors: ['#fba358'],
  };

  let seriesData = [{
    name: 'Hover',
    data: hAry.length ? hAry : [0]
  }];
  return (
    <div>
      <Chart options={options} series={seriesData} type="area" height="125px" width="100%" />
    </div>
  );
};

export default memo(HoverLine);
