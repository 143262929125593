import { Box, Button, InlineStack, Layout, Page, Text, Select, Divider, BlockStack, Icon, Collapsible, Card, IndexTable, Badge, TextField, InlineGrid, IndexFilters, Modal, useSetIndexFiltersMode, IndexFiltersMode, Tooltip, Spinner } from '@shopify/polaris';
import React from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { wordpress_icon, magento_icon, prestashop_icon, opencart_icon, nopcommerce_icon } from '../../img';
import { ClipboardIcon, LockIcon } from '@shopify/polaris-icons';
import { Link } from 'react-router-dom';


function PixelCard(props) {
  const { state, profile } = props;
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  const tabs = [
    { tabName: 'WordPress', icon: wordpress_icon, label: 'WordPress', url: 'https://support.wisernotify.com/install-pixel-code-on-your-woo-commerce-wordpress/' },
    { tabName: 'Magento', icon: magento_icon, label: 'Magento', url: 'https://support.wisernotify.com/install-pixel-on-magento-2-0/' },
    { tabName: 'PrestaShop', icon: prestashop_icon, label: 'PrestaShop', url: 'https://support.wisernotify.com/how-to-connect-wisernotify-with-prestashop/' },
    { tabName: 'OpenCart', icon: opencart_icon, label: 'OpenCart', url: 'https://support.wisernotify.com/how-to-connect-wisernotify-with-opencart-3-0-amp-above/' },
    { tabName: 'nopCommerce', icon: nopcommerce_icon, label: 'nopCommerce', url: 'https://support.wisernotify.com/how-to-connect-wisernotify-with-nopcommerce/' }
  ];

  const delayOptions = [
    { label: '1 Second', value: '1000' },
    { label: '2 Second', value: '2000' },
    { label: '3 Second', value: '3000' },
    { label: '4 Second', value: '4000' },
    { label: '5 Second', value: '5000' },
    { label: '6 Second', value: '6000' },
    { label: '7 Second', value: '7000' },
    { label: '8 Second', value: '8000' },
    { label: '9 Second', value: '9000' },
    { label: '10 Second', value: '10000' }
  ];

  return (
    <Page title="Install wisernotify on your website in just 3 simple steps!">
      <Card>
        <Box padding={200}>
          <Box>
            <InlineStack align='space-between'>
              <Text as="h3" variant="headingMd" fontWeight='semibold'>Get started</Text>
            </InlineStack>
          </Box>
          <div className='step-pixel-box'>
            <Layout>
              <Layout.Section>
                <InlineStack align='space-between' wrap={false}>
                  <Box paddingBlockStart={500}>
                    <InlineStack gap={400} wrap={false}>
                      <div className='stepNo'>
                        <Text>1</Text>
                      </div>
                      <Text>Copy the installation code to add wisernotify to your website.</Text>
                    </InlineStack>
                  </Box>
                  <Tooltip content='Adjust the delay for how quickly the notification should appear after page load.' width='wide' padding='400'>
                    <InlineStack gap={200}>
                      <Box paddingBlockStart={300}>
                        <Text variant='bodyMd' fontWeight='regular'>Set delay</Text>
                      </Box>
                      <Box paddingBlockStart={200}>
                        <Select
                          options={delayOptions}
                          value={state.dlytm.toString()}
                          onChange={props.handleChangeDelay}
                        />
                      </Box>
                    </InlineStack>
                  </Tooltip>

                </InlineStack>
                <Box paddingBlockStart={200} paddingInlineStart={1000}>
                  {profile && profile?.csub === undefined && profile?.blid === undefined && profile?.pvd !== 'shopify' && profile?.pvd !== 'wix' && profile?.pvd !== 'shopline' &&
                    profile?.utyp !== 'appsumo' ? (
                    <div className="textarea-copy-box">
                      <div className="custom-textfield-wrapper-plan">
                        <TextField
                          multiline={4}
                          value={'xxxx-xxxx-xxx-xxx'}
                          id='txtPixelPlan'
                          // readOnly
                          suffix={
                            <Link to={'/plan'}>
                            <Button
                              // icon={ClipboardIcon}
                              icon={LockIcon}
                              id='btnCopyCodePlan'
                              variant='primary'
                              tone='success'
                              // disabled
                            >
                              Unlock with a plan
                            </Button>
                            </Link>
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='textarea-copy-box' onClick={() => props.handleCopyCode(state.copyCode)}>
                      <div className="custom-textfield-wrapper">
                        <TextField
                          multiline={4}
                          value={state.copyCode}
                          readOnly
                          id='txtPixel'
                          suffix={
                            <Button
                              icon={ClipboardIcon}
                              id='btnCopyCode'
                              onClick={() => props.handleCopyCode(state.copyCode)}
                              variant='primary'
                              tone='success'
                            >
                              Copy code
                            </Button>
                          }
                        />
                      </div>
                    </div>
                  )}
                </Box>

              </Layout.Section>

              <Layout.Section>
                <Box>
                  <InlineStack gap={400} wrap={false}>
                    <div className='stepNo'>
                      <Text>2</Text>
                    </div>
                    <div>
                    <Text>
                      Paste the code into the <code style={{ backgroundColor: '#FFF4F0' }}>&lt;head&gt;</code> section of every page where you want to track user activities and show notifications.
                    </Text>
                    </div>
                  </InlineStack>
                </Box>
              </Layout.Section>

              <Layout.Section>
                <InlineStack gap={400}>
                  <Box>
                    <InlineStack gap={400} wrap={false}>
                      <div className='stepNo'>
                        <Text>3</Text>
                      </div>
                      <Text>To confirm installation, click 'Verify Installation' to check if the code was added correctly.</Text>
                    </InlineStack>
                  </Box>
                </InlineStack>

                <Box paddingBlockStart={400} paddingInlineStart={1000}>
                  <InlineStack gap={300}>
                    <Button variant='primary' onClick={() => props.handleVerifyCode()}> <>{"</>"} Verify pixel installation</></Button>
                    {
                      !state.isClick && state.isPixel === 'found' ? (
                        <>
                          <div className="step-third-crtnoti">
                            <div className='step-third-block step-pixel-verify'>
                              <div className='step-pixel'>
                              </div>
                              <Text tone='success'>Your pixel code has been detected successfully!</Text>
                            </div>
                            <Box paddingInlineStart={300}>
                              <Button variant='plain' onClick={() => props.handleCreateNotif()}>
                                Create notification
                              </Button>
                            </Box>
                          </div>
                        </>
                      ) : !state.isClick && state.isPixel === 'notFound' ? (
                        <>
                          <div className='step-third-block'>
                            <div className="step-pixel"></div>
                            <Text tone="critical">Your pixel has not been detected yet!</Text>
                          </div>
                          {
                            localStorage.getItem('isbusr') === 'false' && (
                              <>
                                <Box paddingBlockStart={200}>
                                  <InlineStack gap={300}>
                                    <Text>Need help?</Text>
                                    <Button variant="plain" onClick={() => props.handleChat()}>Chat with us</Button>
                                  </InlineStack>
                                </Box>
                              </>
                            )
                          }
                        </>
                      ) : null
                    }
                  </InlineStack>
                </Box>

              </Layout.Section>
            </Layout>
          </div>
          <Box paddingBlockStart={500} paddingBlockEnd={300}>
            <Divider borderColor='border' />
          </Box>

          <Box paddingBlockEnd={300}>
            <Text as="h3" variant="headingMd" fontWeight='semibold'>Other ways to install</Text>
          </Box>
          <div className='pixelCollapsible'>
            <div onClick={() => props.changeNameValue({ 'guideOpenApp': !state.guideOpenApp })} id='cursorPoint'>
              <Box paddingBlockStart={500} paddingBlockEnd={500}>
                <InlineStack wrap={false} blockAlign='center' align='space-between'>
                  <Box paddingInlineStart={500}>
                    <InlineStack gap={300}>
                      <Text variant='headingSm' as='h3' fontWeight='medium'>Install via native app & API key</Text>
                      {tabs.map((tab, index) => (
                        <BlockStack inlineAlign='center' gap={100} key={index}>
                          <img src={tab.icon} alt={`${tab.label} icon`} height="20px" />
                        </BlockStack>
                      ))}
                    </InlineStack>
                  </Box>
                  <Box paddingInlineEnd={400}>
                    <Icon source={state.guideOpenApp ? ChevronUpIcon : ChevronDownIcon}></Icon>
                  </Box>
                </InlineStack>
              </Box>
            </div>
            <Collapsible
              open={state.guideOpenApp}
              id="db_collaspe"
              transition={{ duration: '300ms', timingFunction: 'ease-in' }}
            >
              <Divider />
              <Box paddingBlockStart={300} paddingInlineStart={600}>
                <Text variant='headingXs' fontWeight='regular' as='h3' tone='subdued'>Install the app and submit the below API key to complete integration.</Text>
              </Box>
              <Box paddingInlineStart={500} paddingBlockEnd={500} paddingBlockStart={400}>
                <InlineStack gap={500}>
                  {tabs.map((tab, index) => (
                    <div className='shopApp' key={index}>
                      <Card
                        sectioned
                      >
                        <BlockStack inlineAlign='center' gap={100} >
                          <Box>
                            <img src={tab.icon} alt={`${tab.label} icon`} height="32px" />
                          </Box>
                          <Text>{tab.label}</Text>
                          <Button variant='plain' target='_blank' url={tab.url}>Install</Button>
                        </BlockStack>
                      </Card>
                    </div>
                  ))}
                </InlineStack>
              </Box>

              <Box paddingInlineStart={500} paddingBlockEnd={300}>
                <Box paddingBlockEnd={200}>
                  <Tooltip hasUnderline content="Submit this API key after installing the plugin to set up the pixel, retrieve the last 30 records, and sync new sales data automatically" width='wide' padding='400'>
                    <Text variant='headingXs' fontWeight='regular' as='span' tone='subdued'>API Key</Text>
                  </Tooltip>
                </Box>
                <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>

                  {profile && profile?.csub === undefined && profile?.blid === undefined && profile?.pvd !== 'shopify' && profile?.pvd !== 'wix' && profile?.pvd !== 'shopline' &&
                    profile?.utyp !== 'appsumo' ? (
                    <TextField
                      id='apiKeyId'
                      value={'xxxx-xxxx-xxx-xxx'}
                      suffix={
                        <Box padding={100}>
                          <Link to={'/plan'}>
                          <Button
                            id='apiKeyId'
                            variant='primary'
                            tone='success'
                            // icon={ClipboardIcon}
                            icon={LockIcon }
                          >
                            Unlock with a plan
                          </Button>
                          </Link>
                        </Box>
                      }
                    />
                  ) : (
                    <TextField
                      value={state.ak}
                      suffix={
                        <Box padding={100}>
                          <Button
                            variant='primary'
                            tone='success'
                            icon={ClipboardIcon}
                            onClick={() => props.handleCopyApi(state.ak)}
                          >
                            Copy API Key
                          </Button>
                        </Box>
                      }
                    />
                  )}
                </InlineGrid>
              </Box>

            </Collapsible>
          </div>

          <Box paddingBlockStart={500}>
            <div className='pixelCollapsible'>
              <div onClick={() => props.changeNameValue({ 'guideopenPage': !state.guideopenPage })} id='cursorPoint'>
                <Box paddingBlockStart={500} paddingBlockEnd={500}>
                  <InlineStack wrap={false} blockAlign='center' align='space-between'>
                    <Box paddingInlineStart={500}>
                      <Text variant='headingSm' as='h3' fontWeight='medium'>Other 250+ page builders guides</Text>
                    </Box>
                    <Box paddingInlineEnd={400}>
                      <Icon source={state.guideopenPage ? ChevronUpIcon : ChevronDownIcon}></Icon>
                    </Box>
                  </InlineStack>
                </Box>
              </div>
              <Collapsible
                open={state.guideopenPage}
                id="db_collaspe"
                transition={{ duration: '300ms', timingFunction: 'ease-in' }}
              >
                <Divider />
                <Box paddingBlockStart={300}>
                  <Card>
                    <Box paddingInlineStart={100} paddingBlockEnd={200}>
                      <Text variant='headingXs' fontWeight='regular' as='h3' tone='subdued'>Other installation options</Text>
                    </Box>
                    <Card padding={0}>
                      <IndexFilters
                        queryValue={state.queryValue}
                        queryPlaceholder="Search by platform.."
                        onQueryChange={(e) => props.changeNameValue({ queryValue: e })}
                        onQueryClear={() => props.changeNameValue({ queryValue: '' })}
                        tabs={[]}
                        mode={mode}
                        setMode={setMode}
                        filters={[]}
                        onClearAll={props.handleFiltersClearAll}
                        canCreateNewView={false}
                      />
                      <div style={{ height: '400px', overflowY: 'auto' }} id='pixelTable'>
                        <IndexTable
                          itemCount={state.platformList ? state.platformList.length : 0}
                          headings={[]}
                          selectable={false}
                        >
                          {state.platformList && state.platformList.length > 0 && state.platformList.map((data, index) => {
                            return (
                              <IndexTable.Row key={index}>
                                <IndexTable.Cell >
                                  <InlineStack align='space-between' blockAlign='center'>
                                    <InlineStack gap={400} blockAlign='center'>
                                      <img src={data.img} alt={data.dn} width="20px" />
                                      <Box>
                                        <Text as='h4' variant='bodyMd' fontWeight='medium'>{data.dn}</Text>
                                        <div style={{ color: '#636363' }}>
                                          <Text as='h5' variant='bodySm' fontWeight='regular'>{data.dsc}</Text>
                                        </div>
                                      </Box>
                                    </InlineStack>
                                    <Box paddingInlineEnd={200}>
                                      <Button target='_blank' url={data.hpulpxl} variant='plain'>Install guide</Button>
                                    </Box>
                                  </InlineStack>
                                </IndexTable.Cell>
                              </IndexTable.Row>
                            );
                          })
                          }
                        </IndexTable>
                      </div>
                    </Card>

                  </Card>
                </Box>
              </Collapsible>
            </div>
          </Box>
        </Box>
      </Card>

      <Box paddingBlockStart={300}>
        <Card>
          <Box padding={200}>
            <Box paddingBlockEnd={200}>
              <InlineStack align='space-between'>
                <Text as="h3" variant="headingMd" fontWeight='semibold'>Pixel installed</Text>
              </InlineStack>
            </Box>
            <Card padding={0}>
              <div id="sd_tbl">
                <IndexTable
                  itemCount={state.pixelList ? state.pixelList.length : 0}
                  selectable={false}
                  headings={[
                    { title: 'Website URL' },
                    { title: 'Last seen' },
                    { title: 'Status' },
                  ]}
                >
                  {state.pixelList && state.pixelList.length > 0 ? (
                    state.pixelList.map((pixel, index) => (
                      <IndexTable.Row id={pixel.id} key={index}>
                        <IndexTable.Cell><span style={{ color: '#616161' }}>{pixel.ht}</span></IndexTable.Cell>
                        <IndexTable.Cell><span style={{ color: '#616161' }}>{props.days_between(pixel.t)}</span></IndexTable.Cell>
                        <IndexTable.Cell>
                          {
                            pixel.t !== 'live' ? (
                              pixel.ts ?
                                <Tooltip content='Green indicates that the pixel was found and active within the last 60 minutes' width='wide' padding='400'>
                                  <Badge progress="complete" tone="success"></Badge>
                                </Tooltip>
                                :
                                <Tooltip content='Red means the pixel is installed but hasn’t tracked any recent visits on the page' width='wide' padding='400'>
                                  <Badge progress="complete" tone="critical"></Badge>
                                </Tooltip>
                            ) : (
                              <Tooltip content='Green indicates that the pixel was found and active within the last 60 minutes' width='wide' padding='400'>
                                <Badge progress="complete" tone="success"></Badge>
                              </Tooltip>
                            )
                          }
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    ))
                  ) : (
                    <IndexTable.Row>
                      <IndexTable.Cell colSpan={3}>
                        <Text>No records found</Text>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  )}
                </IndexTable>
              </div>
            </Card>

          </Box>
        </Card>
      </Box>

      <Modal
        open={state.pixelOpen}
        onClose={(e) => props.handleCloseInstallPixel(e)}
        title="Check your pixel installation">
        <Modal.Section>
          <Text variant='bodyMd' tone='subdued'>After you've added the pixel code to your site, enter your page URL below to verify the setup. If you don't see the pixel, try using an incognito window or clearing your cache.</Text>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <Text variant='bodyMd'>Your Site</Text>
          </Box>
          <InlineStack gap={200}>
            <Box width='80%'>
              <TextField
                placeholder='Enter your site/page URL'
                value={state.url}
                onChange={(value) => props.changeNameValue({ url: value })}
              />
            </Box>
            {
              <Button variant='primary' onClick={() => props.handleVerifyPixel()}>
                Verify Pixel
              </Button>
            }
          </InlineStack>

          <Box paddingBlockStart={500}>
            <InlineStack gap={300}>
              {state.isLoading ? (
                <div className="step-third-crtnoti">
                  <div className="step-third-block step-pixel-verify">
                    <Text tone="subdued">Wait for 10-50 seconds, checking the pixel code...</Text>
                    <Spinner size="small" />
                  </div>
                </div>
              ) : state.userPixelVerify ? (
                state.userPixelVerify.isFound ? (
                  <div className="step-third-crtnoti">
                    <div className="step-third-block step-pixel-verify">
                      <div className="step-pixel"></div>
                      <Text tone="success">Your pixel code has been detected successfully!</Text>
                    </div>
                    <Box paddingInlineStart={200}>
                      <Button variant="plain" onClick={() => props.handleCreateNotif()}>
                        Create notification
                      </Button>
                    </Box>
                  </div>
                ) : (
                  <div className="step-third-crtnoti">
                    <div className="step-third-block">
                      <div className="step-pixel"></div>
                      <Text tone="critical">Your pixel has not been detected yet!</Text>
                      {/* <Spinner size="small" /> */}
                    </div>

                    {localStorage.getItem('isbusr') === 'false' && (
                      <Box paddingInlineStart={200}>
                        <InlineStack gap={200}>
                          <Text>Need help?</Text>
                          <Button variant="plain" onClick={() => props.handleChat()}>Chat With Us</Button>
                        </InlineStack>
                      </Box>
                    )}
                  </div>
                )
              ) : null}
            </InlineStack>
          </Box>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default PixelCard;