import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, OptionList } from '@shopify/polaris';
import { NotifCard } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as notifDucks from '../../ducks/notif';
import * as pricingPlanDucks from '../../ducks/pricingPlan';
import SimpleReactValidator from "simple-react-validator";
import * as PixelDucks from '../../ducks/pixel';
import copy from 'copy-to-clipboard';
import { toastify } from '../../ducks/toast';
import '../NotifEdit/NotifDesign.scss'; //for notiifcation design css
import '../NotifEdit/NotifEdit.css';
import './Notif.css';
import moment from 'moment';

const Notif = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intervalIdRef = useRef(null);
  const validator = new SimpleReactValidator({ locale: 'en' });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [state, setState] = useState({
    userNotifList: '',
    profile: {},
    statusTabs: [],
    domainList: [],
    activeDomainList: false,
    activePopover: null,
    paggiActive: 1,
    paggiActiveUserNotif: 1,
    pageNumber: 0,
    pageNumberUserNotif: 0,
    userNotifSeenId: '',
    userNotifSeenName: '',
    isSeenLoading: false,
    notifStatus: 'all',
    selected: 0,
    notifTyp: [],
    filterNotif: ['all'],
    notifDomain: [],
    clickedActionId: '',
    queryValue: '',
    listTyp: 'list',
    pageSize: 20,
    notifHeight: {},
    selectTemp: '',
    allTemplate: [],
    isConfiguration: false,
    notifTabTyp: 'all',
    openNotifIndex: null,
    socialProofNotif: [],
    widgetNotif: [],
    notifWidgTabTyp: 'all',
    notifWidgetCtg: 'all',
    notifWidgetTabTyp: 0,
    notificationStatus: [],
    nti: '',
    tmid: '',
    nn: '',
    ddn: [],
    ul: '',
    pdpUl: '',
    ctgUl: '',
    selctdNotif: {},
    inlnPdpNotif: [],
    inlnCtgNotif: [],
    notifPdpWebAdd: false,
    notifCtgWebAdd: false,
    openPdpCollapsible: [true],
    openCtgCollapsible: [true],
    installPdpShow: false,
    installCtgShow: false,
    mainTabPdpIndex: 'shopify',
    mainTabCtgIndex: 'shopify',
    codePdpLink: '',
    codeCtgLink: '',
    deleteOpen: false,
    selctdUl: '',
    selctdInlnNotifTyp: '',
    editNotifNames: [],
    isUserNotifDataModal: false,
    // isEditnotif: false,
    sugDomainList: [],
    stripeData: {},
    notifCreate: false,

    //clone
    isClone: false,
    ccid: '',//customer clone id
    cnname: '',//current notif name
    csub: '',
    istm: false,
    cloneNotifName: '',
    errMessage: {},

    //Debugger
    isDebugger: false,
    smartDebuggerList: '',
    cnmnn: '',
    cndid: '',
    cnntt: '',

    //AbTest
    abTest: false,
    popoverActive: false,
    crtVarntOpen: false,
    edtVarntOpen: false,
    objAbtestNotif: {},
    abTitle: '',
    abDesc: '',
    arrVrnt: [],
    abVrnt: 'current',
    abia: true,
    vartId: '',
    defaultAbtest: false,
    selctdAbtest: 0,
    isEditAbtest: false,

    //Detail
    detailShow: false,
    objDetailNotif: {},
    recentData: [],
    arrInte: [],

    //Analytics
    analyticsOpen: false,
    analyticNotifId: '',

    //Create Dummy Data

    dummyData: false,
    objDummyData: {},

    //Check pixel install
    pixelOpen: false,
    webUrl: '',
    isPending: false,
    searchUserNotifUrl: '',
    userNotifId: '',
    userNotifName: ''
  });

  //call user notification api.
  const callNotifApi = () => {
    const obj = {
      domain: state.notifDomain,
      listTyp: state.listTyp,
      name: state.queryValue,
      num: state.paggiActive,
      pagesize: state.pageSize,
      status: state.notifStatus,
      type: state.notifTyp
    }
    global.filterUserNotif = obj;
    dispatch(notifDucks.getNotiByUser(obj));
    dispatch(notifDucks.getNotifActivityByUser(obj));
  }

  //query Value call user notification api.
  useEffect(() => {
    if (state.queryValue) {
      let queryTimer = setTimeout(() => callNotifApi(), 1000);
      return () => clearTimeout(queryTimer);
    }
    else {
      callNotifApi();
    }
  }, [state.notifStatus, state.notifDomain, state.listTyp, state.paggiActive, state.queryValue, state.notifTyp]);

  //notification type list --> type
  useEffect(() => {
    if (state.listTyp && state.listTyp !== '') {
      let obj = {
        selected: 0,
        // paggiActive: 1,
        // notifStatus: 'all',
        // notifTyp: [],
        statusTabs: [],
        // userNotifList: []
      }
      changeNameValue(obj);
      dispatch(notifDucks.getNotifTypeList({ typ: state.listTyp, domain: state.notifDomain[0]?.value }))
    }
  }, [state.listTyp]);

  //notification domain
  useEffect(() => {
    if (state.notifDomain.length > 0) {
      dispatch(notifDucks.getNotifTypeList({ typ: state.listTyp, domain: state.notifDomain[0]?.value }))
    }
    changeNameValue({ notifTyp: [], filterNotif: ['all'] });
    dispatch(notifDucks.getNotifPixel());
  }, [state.notifDomain])

  //api call.
  useEffect(() => {
    dispatch(notifDucks.getNotif());
    dispatch(notifDucks.getNotiHght());
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/widgets') {
      changeNameValue({ listTyp: 'widget', notifTyp: [], paggiActive: 1, notifStatus: 'all', abTest: false, filterNotif: ['all'], isConfiguration: false, openNotifIndex: null, userNotifList: '', notifDomain: [] });
    }
    else if (window.location.pathname === '/inline') {
      changeNameValue({ listTyp: 'inlinelist', notifTyp: [], paggiActive: 1, notifStatus: 'all', abTest: false, filterNotif: ['all'], isConfiguration: false, openNotifIndex: null, userNotifList: '', notifDomain: [] });
    }
    else {
      const obj = {
        listTyp: 'list', paggiActive: 1, notifStatus: 'all', abTest: false, isConfiguration: false, openNotifIndex: null, userNotifList: '', notifDomain: []
      }
      if (state.notifTyp.length > 0) {
        obj['notifTyp'] = [];
      }
      changeNameValue(obj);
    }
  }, [window.location.pathname])

  //api data.
  const listOfUserNotif = useSelector(state => state.notif.notifByUser);
  const listOfNotif = useSelector(state => state.notif.notifTypeList);
  const notifData = useSelector(state => state.notif.notif);
  const notifHeight = useSelector(state => state.notif.notifHeight);
  const smartDebuggerList = useSelector(state => state.notif.smartDebuggerList);
  const profileState = useSelector(state => state.auth.profile);
  const getAbAnalyticEs = useSelector(state => state.notif.getAbAnalyticEs);
  const recentData = useSelector(state => state.notif.recentData);
  const lstActiveIntg = useSelector(state => state.notif.lstActiveIntg);
  const resCreateNotif = useSelector(state => state.notif.resCreateNotif);
  const abTestNotif = useSelector(state => state.notif.abTestNotif);
  const notifPixel = useSelector(state => state.notif.notifPixel);
  const stripeData = useSelector(state => state.pricingPlan.stripeData);
  const notifActivity = useSelector(state => state.notif.notifActivityByUser);
  const isLoading = useSelector(state => state.loading.isLoading);
  const getUserNotifData = useSelector(state => state.notif.getUserNotifData);
  const userVerify = useSelector(state => state.pixel.userPixelVerify);
  const activeStatus = useSelector(state => state.notif.activeStatus);


  //set variable api data.
  let NotifBycount = listOfUserNotif?.notifBycount || [];
  let notifTypeList = listOfNotif?.type || [];
  let TotalData = listOfUserNotif?.total || 0;

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //redirect edit notif create notification.
  // useEffect(() => {
  //   if (resCreateNotif && resCreateNotif.nti && resCreateNotif._id && resCreateNotif.ntt && state.isEditnotif) {
  //     let url = `/notifEdit?nti=${resCreateNotif.nti}&id=${resCreateNotif._id}&ntt=${resCreateNotif.ntt}`;

  //     if (state.listTyp === 'inlinelist') {
  //       url += '&ntyp=inlinenudge';
  //     }
  //     else if (state.listTyp === 'widget') {
  //       url += '&ntyp=widget';
  //     }
  //     navigate(url);
  //     dispatch(notifDucks.notifClear());
  //   }
  // }, [resCreateNotif, state.isEditnotif, state.listTyp, navigate]);

  //redirect edit Ab test.
  useEffect(() => {
    if (state.isEditAbtest && abTestNotif && abTestNotif._id) {
      const latestVariant = abTestNotif.vrnt?.reduce((latest, current) => {
        return current._id > latest._id ? current : latest;
      });
      handleEditNotif(abTestNotif, 'ab_test', latestVariant._id);
      dispatch(notifDucks.abTestClear());
    }
  }, [state.isEditAbtest, abTestNotif]);

  useEffect(() => {
    changeNameValue({ notifActivity, getUserNotifData: getUserNotifData?.data, isSeenLoading: false })
  }, [notifActivity, getUserNotifData])

  //handle pagination.
  const onPaggiCallUserNotif = (i) => {
    let num = 0;
    if (i === '+1') {
      num = state.paggiActiveUserNotif + 1;
    } else if (i === '-1') {
      num = state.paggiActiveUserNotif - 1;
    } else {
      num = i;
    }
    changeNameValue({ 'paggiActiveUserNotif': num });
  }

  useEffect(() => {
    handleGetUserNotifData(state.userNotifSeenId, state.userNotifSeenName);
  }, [state.paggiActiveUserNotif])

  useEffect(() => {
    changeNameValue({ paggiActiveUserNotif: 1 });
    let queryTimer = setTimeout(() => handleGetUserNotifData(state.userNotifSeenId, state.userNotifSeenName), 1000);
    return () => clearTimeout(queryTimer);
  }, [state.searchUserNotifUrl]);

  // pixel
  useEffect(() => {
    changeNameValue({ userPixelVerify: userVerify });
  }, [userVerify]);

  useEffect(() => {
    if (state.pixelOpen === true && state.userPixelVerify && state.userPixelVerify.isFound === true) {
      changeNameValue({ isPending: false })
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    if (state.pixelOpen === true && !state.userPixelVerify) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, [state.pixelOpen, state.webUrl, state.userPixelVerify])


  useEffect(() => {
    //set list of user notification data.
    if (listOfUserNotif && listOfUserNotif?.list) {
      changeNameValue({ userNotifList: listOfUserNotif?.list });
    }
    //set profile state data.
    if (profileState) {
      changeNameValue({ profile: profileState });
    }
    //set stripe state data.
    if (stripeData) {
      changeNameValue({ stripeData });
    }
  }, [listOfUserNotif, profileState, stripeData]);

  //redirect stripe payment url.
  useEffect(() => {
    if (state.stripeData && state.stripeData.url) {
      window.location.href = state.stripeData.url;
    }
  }, [state.stripeData]);

  //filter notification by ctg.
  const filterArr = (ctg) => notifData?.notifType.filter(item => item.dsplct === ctg);

  //filter notification And Separate By Ul.
  const filterAndSeparateByUl = (notifArr, userNotifList) => {
    const notifIds = notifArr?.map(item => item._id);
    const filteredNotifList = userNotifList && userNotifList.filter(item => notifIds?.includes(item.nti));

    const separatedByUl = filteredNotifList?.reduce((acc, val) => {
      const ul = val.ddn[0]?.ul;
      if (!acc[ul]) acc[ul] = [];
      acc[ul].push(val);
      return acc;
    }, {});

    return Object.keys(separatedByUl).map(ul => ({ [ul]: separatedByUl[ul] }));
  };

  useEffect(() => {
    //set social proof notification data.
    if (notifData?.notifType) {
      const { notifType } = notifData;
      const notifArr = notifType.filter(x => x.ctg === 1 && (state.notifTabTyp === 'all' || x.dsplct === state.notifTabTyp));
      changeNameValue({ socialProofNotif: notifArr });
    }

    //set widget notification data.
    let _arr = [];
    let _type = state.notifWidgetCtg;

    if (notifData) {
      notifData.notifType.forEach(x => {
        if (state.notifWidgetCtg === 'all' && x.ctg === 2) {
          notifData.allTemplate.forEach(z => {
            if (z.ni === x._id && z.ttn !== 'review_feed_1') {
              _arr.push({ notif: z, _id: x._id, typ: x.typ });
            }
          });
        } else if ((x.nc === state.notifWidgetCtg && x.ctg === 2) || (_type && _type.length > 1 && _type[1] && x.nc === _type[1])) {
          notifData.allTemplate.forEach(z => {
            if (z.ni === x._id && z.ttn !== 'review_feed_1') {
              switch (state.notifWidgetTabTyp) {
                case 1:
                  if (z.ttn === 'promotional_banner_timer') {
                    _arr.push({ notif: z, _id: x._id, typ: x.typ });
                  }
                  break;
                case 2:
                  if (z.ttn !== 'social_media_followers') {
                    _arr.push({ notif: z, _id: x._id, typ: x.typ });
                  }
                  break;
                case 3:
                  if (z.ttn !== 'promotional_banner_timer') {
                    _arr.push({ notif: z, _id: x._id, typ: x.typ });
                  }
                  break;
                case 7:
                  if (z.ttn !== 'title_bar') {
                    _arr.push({ notif: z, _id: x._id, typ: x.typ });
                  }
                  break;
                default:
                  _arr.push({ notif: z, _id: x._id, typ: x.typ });
              }
            }
          });
        }
      });
    }
    changeNameValue({ widgetNotif: _arr });

    //set inline notification data.
    if (state.userNotifList && state.listTyp === 'inlinelist') {
      const pdpNotifArr = filterArr('ecomm_snpt_pdp');
      const ctgNotifArr = filterArr('ecomm_snpt_catelog');

      const inlnPdpNotif = filterAndSeparateByUl(pdpNotifArr, state.userNotifList);
      const inlnCtgNotif = filterAndSeparateByUl(ctgNotifArr, state.userNotifList);

      changeNameValue({ inlnPdpNotif, inlnCtgNotif });
    }
  }, [notifData, state.notifTabTyp, state.notifWidgetCtg, state.notifWidgetTabTyp, state.listTyp, state.userNotifList]);

  //set notifHeight data.
  useEffect(() => {
    if (notifHeight && notifHeight !== undefined && notifHeight !== state.notifHeight) {
      changeNameValue({ notifHeight });
    }
    if (notifPixel && notifPixel.length > 0) {
      let domainName = notifPixel;
      let domainList = [];
      if (domainName && domainName.length > 0) {
        domainName.map(domain => {
          domainList.push({
            'name': domain
          });
        });
        changeNameValue({ sugDomainList: domainList });
      }
    }
  }, [notifHeight, state.notifHeight, notifPixel]);

  //set allTemplate data.
  useEffect(() => {
    if (notifData && notifData !== undefined && notifData !== state.notifData) {
      changeNameValue({ notifData, allTemplate: notifData?.allTemplate });
    }
  }, [notifData, state.notifData]);

  //domain options.
  const deselectedOptions = useMemo(
    () => state.sugDomainList.map(domain => ({
      value: domain.name,
      label: domain.name,
    })),
    [state.sugDomainList],
  );

  //set options data.
  useEffect(() => {
    if (deselectedOptions.length > 0) {
      changeNameValue({ options: deselectedOptions })
    }
  }, [deselectedOptions])

  //handleSugtTextChange.
  const handleSugtTextChange = useCallback((value) => {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp('^' + escapedValue, 'i');

    const resultOptions = deselectedOptions.filter((option) =>
      option.label.match(regex) // Match only the beginning of the string
    );

    // Update state with input value and filtered options
    changeNameValue({ ul: value, options: resultOptions });
  }, [deselectedOptions]);

  //handleSugtOptionChange.
  const handleSugtOptionChange = (selected) => {
    const selectedOption = deselectedOptions.find(option => option.value === selected[0]);
    if (selectedOption) {
      changeNameValue({ ul: selectedOption.label });
    }
  };

  //handleSugtInlnPdpTxtChange.
  const handleSugtInlnPdpTxtChange = useCallback((value) => {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp('^' + escapedValue, 'i');

    const resultOptions = deselectedOptions.filter((option) =>
      option.label.match(regex) // Match only the beginning of the string
    );

    // Update state with input value and filtered options
    changeNameValue({ pdpUl: value, options: resultOptions });
  }, [deselectedOptions]);

  //handleSugtInlnPdpOptChange.
  const handleSugtInlnPdpOptChange = (selected) => {
    const selectedOption = deselectedOptions.find(option => option.value === selected[0]);
    if (selectedOption) {
      changeNameValue({ pdpUl: selectedOption.label });
    }
  };

  //handleSugtInlnCtgTxtChange.
  const handleSugtInlnCtgTxtChange = useCallback((value) => {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp('^' + escapedValue, 'i');

    const resultOptions = deselectedOptions.filter((option) =>
      option.label.match(regex) // Match only the beginning of the string
    );

    // Update state with input value and filtered options
    changeNameValue({ ctgUl: value, options: resultOptions });
  }, [deselectedOptions]);

  //handleSugtInlnCtgOptChange.
  const handleSugtInlnCtgOptChange = (selected) => {
    const selectedOption = deselectedOptions.find(option => option.value === selected[0]);
    if (selectedOption) {
      changeNameValue({ ctgUl: selectedOption.label });
    }
  };

  useEffect(() => {
    if (notifData && notifData !== undefined && notifData !== state.notifData) {
      changeNameValue({ notifData, allTemplate: notifData?.allTemplate });
    }
  }, [notifData, state.notifData]);

  //set selected domain data.
  useEffect(() => {
    if (listOfNotif?.domain !== undefined) {
      let domainlts = listOfNotif?.domain;
      let structureDomain = domainlts?.map((elm) => {
        return {
          content: elm.label,
          onAction: () => changeNameValue({
            notifDomain: [elm],
            paggiActive: 1,
            notifStatus: 'all',
            notifTyp: [],
            statusTabs: [],
            selected: 0
          }),
          active: elm.label === state.notifDomain[0]?.label
        };
      });

      const newObj = [
        { label: 'All', value: 'all' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' }
      ];

      let allDomain = { content: 'All', onAction: () => changeNameValue({ notifDomain: [] }) };
      const newDomainLObj = [allDomain, ...structureDomain];
      changeNameValue({ domainList: newDomainLObj, statusTabs: newObj, selected: 0 });
    }
  }, [listOfNotif, state.notifDomain]);

  //set tabs data.
  const tabs = state?.statusTabs?.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
  }));

  //set variant array data (AbTest).
  useEffect(() => {
    if (state.objAbtestNotif && state.objAbtestNotif.vrnt) {

      const options = state.objAbtestNotif.vrnt.map(variant => ({
        label: variant.ttl,
        value: variant.ttl
      }));

      const arrVrnt = state.objAbtestNotif.idltfrmvrnt
        ? options
        : [{ label: 'Original', value: 'current' }, ...options];

      changeNameValue({ arrVrnt });
    }
  }, [state.objAbtestNotif]);

  //tabs (AbTest).
  const statusTabs = [
    { label: 'Last 24 hours', value: 'today' },
    { label: 'Last 7 Days', value: 'week' },
    { label: 'Last 30 Days', value: 'month' },
    { label: 'Custom Date', value: 'custom' },
  ]

  //set tabs data (AbTest).
  const abTestTabs = statusTabs.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
  }));

  // Utility function to format dates (AbTest).
  const formatDate = (date) => moment(date).format('YYYY-MM-DD');

  //set the selected tab date (AbTest).
  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    let obj = {
      ti: state.objAbtestNotif.ti,
      nid: state.objAbtestNotif._id,
    };

    switch (state.selctdAbtest) {
      case 0: // Today
        obj = { ...obj, rt: 'today', fDate: formatDate(today), tDate: formatDate(today), popoverActive: false };
        break;
      case 1: // Past week
        obj = {
          ...obj,
          rt: 'week',
          fDate: formatDate(new Date(today.setDate(today.getDate() - 7))),
          tDate: formatDate(yesterday),
          popoverActive: false
        };
        break;
      case 2: // Past month
        obj = {
          ...obj,
          rt: 'month',
          fDate: formatDate(new Date(today.setDate(today.getDate() - 30))),
          tDate: formatDate(yesterday),
          popoverActive: false
        };
        break;
      case 3: // Custom date
        changeNameValue({ popoverActive: true });
        return;
      default:
        changeNameValue({ popoverActive: false });
        return;
    }
    changeNameValue(obj);
  }, [state.selctdAbtest, state.objAbtestNotif]);

  //set abAnalyticEs data (AbTest).
  useEffect(() => {
    if (queryParams.get("ab") && getAbAnalyticEs) {
      changeNameValue({ abAnalyticEs: getAbAnalyticEs });
    }
  }, [getAbAnalyticEs]);

  //handle Select Custom Date (AbTest).
  const handleSelectCustomDate = () => {
    if (state.selctdAbtest === 3) {
      const obj = {
        ti: state.objAbtestNotif.ti,
        rt: 'custom',
        nid: state.objAbtestNotif._id,
        fDate: formatDate(new Date(state.frmdt)),
        tDate: formatDate(new Date(state.tdt)),
      };
      changeNameValue(obj);
      if (state.frmdt && state.tdt) {
        changeNameValue({ popoverActive: false });
      }
    }
  }

  //getAbAnalyticEs api (AbTest).
  useEffect(() => {
    if (queryParams.get("ab")) {
      let obj = {
        ti: state.ti,
        rt: state.rt,
        nid: state.nid,
        fDate: state.fDate,
        tDate: state.tDate,
      }
      dispatch(notifDucks.getAbAnalyticEs(obj));
    }
  }, [state.queryValue, state.ti, state.rt, state.nid, state.fDate, state.tDate]);

  //set selected status state data.
  useEffect(() => {
    const typ = state.statusTabs[state.selected];
    if (typ !== undefined) {
      let stateObj = {
        notifStatus: typ?.value,
        paggiActive: 1,
      }
      changeNameValue(stateObj);
    }
  }, [state.selected]);

  useEffect(() => {
    const all = { label: 'All', value: 'all' };
    if (state.notifTyp?.length === 0) {
      if (state.userNotifList?.length > 0) {
        const selectedDataNotif = state.userNotifList?.map(item => ({
          label: item.mnn,
          value: item.nt
        }));
        let uniqueValues = new Set();
        // Use filter to remove duplicates
        let uniqueObj = selectedDataNotif.filter(item => {
          if (!uniqueValues.has(item.value)) {
            uniqueValues.add(item.value);
            return true;
          }
          return false;
        });
        let notifArray = [all, ...uniqueObj];
        changeNameValue({ notificationStatus: notifArray })
      }
    }
  }, [state.userNotifList])

  //handle Change Notif Type.
  const handleChangeNotifType = (val) => {
    let findNotif = state.notificationStatus.find((elm) => elm.value === val[0]);
    changeNameValue({ notifTyp: findNotif.value === 'all' ? [] : [findNotif], paggiActive: 1, filterNotif: val[0] })
  }

  //Filters.
  const filters = [
    {
      key: 'notifTyp',
      label: (state.notifTyp !== "" ? `Notification: ${state.notifTyp.length !== 0 ? state.notifTyp[0]?.label : 'All'}` : "Notification"),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
      filter: (
        <div>
          <Box>
            <OptionList
              title="Notification Type"
              onChange={(e) => handleChangeNotifType(e)}
              options={state.notificationStatus}
              selected={[state.filterNotif]}
            />
          </Box>
        </div>
      )
    }
  ];

  //handle Filters Clear All.
  const handleFiltersClearAll = useCallback(() => {
    let obj = {
      queryValue: '',
      notifTyp: 'all',
    }
    changeNameValue(obj);
  }, []);

  //set TotalData.
  useEffect(() => {
    if (TotalData && TotalData !== '')
      changeNameValue({ pageNumber: parseInt(Math.ceil(TotalData / 20)) });
  }, [TotalData]);

  //handle pagination.
  const onPaggiCall = (i) => {
    let num = 0;
    if (i === '+1') {
      num = state.paggiActive + 1;
    } else if (i === '-1') {
      num = state.paggiActive - 1;
    } else {
      num = i;
    }
    changeNameValue({ 'paggiActive': num });
  }

  useEffect(() => {
    if (!activeStatus && !state?.userNotifList) return;

    const index = state?.userNotifList?.length > 0 && state.userNotifList.findIndex(x => x._id === activeStatus._id);
    if (index === -1) return;

    const updatedList = [...state?.userNotifList];
    updatedList[index] = { ...updatedList[index], ia: true };

    changeNameValue({ userNotifList: updatedList });

    setTimeout(() => {
      updatedList[index] = {
        ...updatedList[index],
        ia: false,
        iafab: false,
        udt: new Date()
      };

      changeNameValue({ userNotifList: updatedList });

      dispatch(toastify({
        type: 'error',
        msg: `Free Plan is limited to only 1 active notification. Upgrade now`
      }));
    }, 1000);
  }, [activeStatus]);

  //handle update notification status.
  const handleupdNotifStatus = (data) => {
    const updatedUserNotifList = state.userNotifList.map(notif =>
      notif._id === data._id ? { ...notif, ia: !notif.ia } : notif
    );
    changeNameValue({ userNotifList: updatedUserNotifList });
    const selectedNotif = updatedUserNotifList.find(elm => elm._id === data._id);
    if (selectedNotif) {
      const obj = {
        _id: selectedNotif._id,
        ia: selectedNotif.ia,
        iafab: selectedNotif.iafab,
        ntt: selectedNotif.ntt,
        togl: true,
        csub: state.profile.csub,
        fn: state.profile.fn,
      };
      dispatch(notifDucks.updNotifStatus(obj));
    }

  };

  //open close modal.
  const openCloseModal = useCallback((name, value, type) => {
    // add cond. here for modal close on outside click
    if (type !== undefined || name === 'webPreviewOpen' || name === 'isUserNotifDataModal' || name === 'pixelOpen') {
      changeNameValue({ [name]: !value });
    }
    // (AbTest)
    if (name === 'edtVarntOpen' && type === 'close') {
      let obj = {
        abId: '',
        notifId: '',
        abTitle: '',
        abDesc: '',
        abia: true
      }
      changeNameValue(obj);
    }
    if (name === 'crtVarntOpen' && type === 'close') {
      let obj = {
        abTitle: '',
        abDesc: '',
        abVrnt: 'current',
        abia: true
      }
      changeNameValue(obj);
    }
  }, []);

  const handleEditNotif = (data, path, abTyp, typ) => {
    let { listTyp, objAbtestNotif } = state;
    let notifData = (path && path === 'ab_test' && objAbtestNotif) ? objAbtestNotif : data;
    let url;
    if (typ === 'datasource') {
      url = `/notifEdit?nti=${notifData.nti}&id=${notifData._id}&ntt=${notifData.ntt}&tab=3`;
    } else {
      url = `/notifEdit?nti=${notifData.nti}&id=${notifData._id}&ntt=${notifData.ntt}`;
    }

    if (path && path === 'ab_test' && abTyp) {
      url += `&abTyp=${abTyp}`;
    }
    if (listTyp === 'inlinelist') {
      url += '&ntyp=inlinenudge';
    }
    else if (listTyp === 'widget') {
      url += '&ntyp=widget';
    }

    navigate(url);

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Notification List Button', 'Edit', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Notification List Button', { 'event_action': 'Edit', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  };

  //handle Tab Change.
  const handleTabChange = (data) => {
    let obj = {
      listTyp: data.tabName,
      isConfiguration: false,
      openNotifIndex: null,
      notifWidgetTabTyp: 0,
      notifTabTyp: 'all',
      notifWidgTabTyp: 'all',
      notifWidgetCtg: 'all',
      ddn: [],
      pdpUl: '',
      ctgUl: '',
      notifPdpWebAdd: false,
      notifCtgWebAdd: false,
      installPdpShow: false,
      installCtgShow: false,
      mainTabPdpIndex: 'shopify',
      mainTabCtgIndex: 'shopify',
      codePdpLink: '',
      codeCtgLink: ''
    }
    changeNameValue(obj);
  }

  //handle Add Social proof notification Show.
  const handleAddSocialShow = (data, i) => {
    let obj = {
      openNotifIndex: i,
      nti: data._id,
      tmid: data.tmid,
      nn: data.nn
    }
    changeNameValue(obj);
  }

  //handle Add Widget notification Show.
  const handleAddWidgetShow = (data, i) => {
    let obj = {
      openNotifIndex: i,
      nti: data.notif.ni,
      tmid: data.notif._id,
      nn: data.notif.tn
    }
    changeNameValue(obj);
  }

  //handle Add Website.
  const handleAddWebsite = () => {
    if (state.ul.trim() !== '') {
      let isValidUrl = state.ul.split('.');
      if (isValidUrl.length < 2) {
        dispatch(toastify({ type: 'error', msg: `Please enter valid URL` }));
        return false;
      }
      const newUl = state.ul.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];
      if (!state.ddn.some(item => item.ul === newUl)) {
        const updtd = [...state.ddn, { ul: newUl }];
        let obj = {
          ddn: updtd,
          ul: ''
        }
        changeNameValue(obj);
      } else {
        dispatch(toastify({ type: 'error', msg: `Domain already exist` }));
      }
    } else {
      dispatch(toastify({ type: 'error', msg: `Enter your website URL(s)` }));
    }
  }

  //handle Remove Website.
  const handleRemoveWebsite = (data) => {
    const updatedDdn = state.ddn.filter((t) => t !== data);
    changeNameValue({ ddn: updatedDdn });
  }

  //handle create notification.
  const handleCreateNotif = () => {
    const newUl = state?.ul.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];

    let isValidUrl = state.ul.split('.');
    if (state.ul.trim() !== '') {
      if (isValidUrl.length < 2) {
        dispatch(toastify({ type: 'error', msg: `Please enter valid URL` }));
        return false;
      }
    }

    // Check if newUl already exists in state.ddn
    if (!state.ddn.some(item => item.ul === newUl)) {
      const updtdDdn = newUl ? [...state.ddn, { ul: newUl }] : [...state.ddn];

      if (updtdDdn?.length > 0) {
        let obj = {
          nti: state.nti,
          tmid: state.tmid,
          nn: state.nn,
          ddn: updtdDdn,
          isFirst: state.userNotifList ? state.userNotifList.length : 0,
          pvd: localStorage.getItem('pvd'),
          istm: localStorage.getItem('istm'),
          isbusr: localStorage.getItem('isbusr'),
          fn: state.profile.fn,
          bu: state.profile.brand ? state.profile.brand.bu : '',
          bn: state.profile.brand ? state.profile.brand.bn : 'WiserNotify',
          navigate
        }

        //Create widget notification.
        if (state.listTyp === 'widget') {
          obj['typ'] = 'widget'
        }
        changeNameValue({ notifCreate: true });
        dispatch(notifDucks.createNotif(obj));
        // let stateObj = {
        //   isConfiguration: false,
        //   openNotifIndex: null,
        //   notifWidgetTabTyp: 0,
        //   notifTabTyp: 'all',
        //   notifWidgTabTyp: 'all',
        //   notifWidgetCtg: 'all',
        //   nti: '',
        //   tmid: '',
        //   ul: '',
        //   ddn: [],
        //   selected: 0,
        //   notifDomain: [],
        //   // isEditnotif: true
        // }
        // changeNameValue(stateObj);
      } else {
        dispatch(toastify({ type: 'error', msg: `Enter your website URL(s)` }));
      }
    } else {
      // Domain already exist.
      dispatch(toastify({ type: 'error', msg: `Domain already exist` }));
    }
  }

  //handle delete notification.
  const handleDelNotif = (data) => {
    let obj = {
      id: data._id,
      fn: state.profile.fn
    }
    dispatch(notifDucks.delNotif(obj));
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
    changeNameValue({ selctdNotif: {}, activePopover: null });
  }

  //handle Back To Main.
  const handleBackToMain = () => {
    let obj = {
      isConfiguration: false,
      openNotifIndex: null,
      notifWidgetTabTyp: 0,
      notifTabTyp: 'all',
      notifWidgTabTyp: 'all',
      notifWidgetCtg: 'all',
      ul: '',
      ddn: [],
      editNotifNames: []
    }
    changeNameValue(obj);
  }

  //handle update inline notification status.
  const handleInlnUpdNotifStatus = (data) => {
    const obj = {
      _id: data._id,
      ia: !data.ia,
      iafab: data.iafab,
      ntt: data.ntt,
      togl: true,
      csub: state.profile.csub,
      fn: state.profile.fn,
    }
    dispatch(notifDucks.updNotifStatus(obj));

    const toggleIa = (notifs) =>
      notifs.map(notif => ({
        ...notif,
        [Object.keys(notif)[0]]: Object.values(notif)[0].map(val =>
          val._id === data._id ? { ...val, ia: !data.ia } : val
        )
      }));

    let stateObj = {
      inlnPdpNotif: toggleIa(state.inlnPdpNotif),
      inlnCtgNotif: toggleIa(state.inlnCtgNotif)
    }
    changeNameValue(stateObj);
  }

  //handle getUserNotifData
  const handleGetUserNotifData = (id, notifName) => {
    if (id !== '') {
      const obj = {
        nid: id,
        pn: state.paggiActiveUserNotif,
        ps: 20,
        str: state.searchUserNotifUrl
      }
      dispatch(notifDucks.getUserNotifData(obj));
      changeNameValue({ isUserNotifDataModal: true, userNotifSeenId: id, userNotifSeenName: notifName, isSeenLoading: true });

      if (window.ga) {
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', 'Notification list button', 'Last seen link', state?.profile?.e || '', { transport: 'beacon' });
          //for GA-4
          window.gtag('event', 'Notification list button', { 'event_action': 'Last seen link', 'event_label': state?.profile?.e || '' });
        } catch (error) { console.log(error); }
      }
    }
  }

  //handle close user Notif Data Modal
  const handleCloseUserNotifDataModal = (e) => {
    openCloseModal('isUserNotifDataModal', state.isUserNotifDataModal, e)
    changeNameValue({ userNotifSeenId: '', paggiActiveUserNotif: 1, getUserNotifData: undefined, searchUserNotifUrl: '' });
  }

  //handle create inline notification.
  const handleCreateInlnNotif = (typ) => {
    const ul = (typ === 'detail') ? state.pdpUl : state.ctgUl
    const { ddn, inlnPdpNotif, inlnCtgNotif } = state;

    if (!ul.trim()) {
      dispatch(toastify({ type: 'error', msg: 'Enter your website URL(s)' }));
      return;
    }

    const isValidUrl = ul.split('.');
    if (isValidUrl.length < 2) {
      dispatch(toastify({ type: 'error', msg: 'Please enter a valid URL' }));
      return;
    }

    const newUl = ul.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];

    const checkNotifDomain = (notifList) => notifList?.some(notif => Object.values(notif)[0].some(val => val.ddn[0].ul === newUl));

    const isDomainExists = ddn.some(item => item.ul === newUl) ||
      (state.notifPdpWebAdd ? checkNotifDomain(inlnPdpNotif) : checkNotifDomain(inlnCtgNotif));

    if (isDomainExists) {
      dispatch(toastify({ type: 'error', msg: 'Domain already exists' }));
      return;
    }

    const updatedDdn = [...ddn, { ul: newUl }];
    changeNameValue({ ddn: updatedDdn });

    const obj = {
      ddn: updatedDdn,
      isbusr: localStorage.getItem('isbusr'),
      istm: localStorage.getItem('istm'),
      dt: '',
      dtu: '',
      typ: typ,
      fn: state.profile.fn,
      bu: state.profile.brand ? state.profile.brand.bu : '',
      bn: state.profile.brand ? state.profile.brand.bn : 'WiserNotify',
    };

    dispatch(notifDucks.createInlineNotif(obj));
    changeNameValue({ ul: '', pdpUl: '', ctgUl: '', notifPdpWebAdd: false, notifCtgWebAdd: false, ddn: [] });
  };

  //collapsible for inline notification default first open.
  useEffect(() => {
    const initializeCollapsible = (length) => Array(length).fill(false).map((item, index) => index === 0);
    let obj = {
      openPdpCollapsible: initializeCollapsible(state.inlnPdpNotif?.length),
      openCtgCollapsible: initializeCollapsible(state.inlnCtgNotif?.length)
    }
    changeNameValue(obj);
  }, [state.inlnPdpNotif?.length, state.inlnCtgNotif?.length]);

  //collapsible for inline notification.
  const handleCollapsible = (index, type) => {
    changeNameValue({
      [type]: state[type].map((item, i) => (i === index ? !item : false)),
    });
  };

  //handle install show close for product detail page.
  const handleInstallPdpShowClose = () => {
    let obj = {
      installPdpShow: !state.installPdpShow,
      mainTabPdpIndex: 'shopify',
      notifPdpWebAdd: false
    }
    changeNameValue(obj);
  }

  //handle install show close for collection page.
  const handleInstallCtgShowClose = () => {
    let obj = {
      installCtgShow: !state.installCtgShow,
      mainTabCtgIndex: 'shopify',
      notifCtgWebAdd: false
    }
    changeNameValue(obj);
  }

  //handle copy code links.
  const handleCopyCode = (value) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: `Your link is copied.` }));
  }

  //handle delete inline notification.
  const handleDelInlnNotif = () => {
    const { selctdUl, inlnPdpNotif, inlnCtgNotif, selctdInlnNotifTyp } = state;

    const idArray = Object.values(selctdInlnNotifTyp === 'detail' ? inlnPdpNotif : inlnCtgNotif).reduce((acc, item) => {
      selctdUl.forEach(key => {
        if (item[key]) {
          item[key].forEach(obj => {
            if (obj._id) {
              acc.push(obj._id);
            }
          });
        }
      });
      return acc;
    }, []);

    const obj = { idAry: idArray };
    dispatch(notifDucks.deleteInlineNotif(obj));
    changeNameValue({ selctdInlnNotifTyp: '', selctdUl: '' })
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
  }

  //handle clone btn click to create clone.
  const handleCreateClone = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });
      const obj = {
        cId: state.ccid,//customer clone id
        csub: state.profile.csub,
        istm: false,
        nn: state.cloneNotifName,
      };
      dispatch(notifDucks.createCloneNotif(obj));
      changeNameValue({ isClone: false, cloneNotifName: '' });
      callNotifApi();
    }
  }

  //handle debugger btn click to Action.
  const handleDebugger = (data) => {
    changeNameValue({ isDebugger: true, cnname: data?.nn, cnmnn: data?.mnn, cndid: data?._id, cnntt: data?.ntt?.replace(' ', '_'), cdti: data?.ti, debugerDdnList: data?.ddn });
    dispatch(notifDucks.smartDebugger(data?._id));

    //track click event
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Notification List Button', 'Debugger', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Notification List Button', { 'event_action': 'Debugger', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  }

  //refreshEligible
  const refreshEligible = () => {
    dispatch(notifDucks.smartDebugger(state.cndid));

    let mergeObj = {
      ti: state.cdti,
      ddn: state.debugerDdnList
    };
    dispatch(notifDucks.updMerge(mergeObj));
  }

  useEffect(() => {
    changeNameValue({ smartDebuggerList });
  }, [smartDebuggerList])

  //handle edit notification name show.
  const handleEditNotifNameShow = (index, newValues) => {
    const updated = {
      ...state.editNotifNames,
      [index]: { ...state.editNotifNames[index], ...newValues }
    }
    changeNameValue({ editNotifNames: updated });
  }

  //handle edit notification name close.
  const handleEditNotifNameClose = (index) => {
    const { [index]: _, ...remainingeditNotifNames } = state.editNotifNames;
    changeNameValue({ editNotifNames: remainingeditNotifNames });
  }

  //handle edit notification name text change.
  const handleTextChange = (index, value) => {
    const updated = {
      ...state.editNotifNames,
      [index]: { ...state.editNotifNames[index], nn: value },
    }
    changeNameValue({ editNotifNames: updated });
  }

  //handle edit notification name.
  const handleUpdateNotifName = (index, data) => {
    const newValue = state.editNotifNames[index]?.nn;
    if (!newValue) {
      return dispatch(toastify({ type: 'error', msg: 'Notification name is required' }));
    }

    if (data.nn !== newValue) {
      const updatedNotifList = state.userNotifList.map(notif =>
        notif._id === data._id ? { ...notif, nn: newValue } : notif
      );
      changeNameValue({ userNotifList: updatedNotifList });

      const obj = {
        nid: data._id,
        nn: newValue,
        fn: state.profile.fn,
      };

      dispatch(notifDucks.updNotifName(obj));
    }
    handleEditNotifNameClose(index);
  }

  // set the latest variant data (AbTest).
  // useEffect(() => {
  //   if (state.userNotifList && state.userNotifList.length > 0) {
  //     const latestNotif = state.userNotifList[0]; // Or find the relevant notif based on your logic
  //     changeNameValue({ objAbtestNotif: latestNotif });
  //   }
  // }, [state.userNotifList]);

  //handle Back To (AbTest).
  const handleBackToAbtest = () => {
    let obj = {
      abTest: false,
      popoverActive: false,
      selctdAbtest: 0
    }
    changeNameValue(obj);
    navigate(window.location.pathname);
  }

  //handle Create AbTest Notif (AbTest).
  const handleCreateAbTestNotif = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });

      let notifById = state.objAbtestNotif || {};
      let obj = {};
      let notifId = state.objAbtestNotif._id;

      //get current notif variant
      if (state.abVrnt === 'current') {
        obj = {
          id: notifId,
          cnf: notifById.cnf,
          ttl: state.abTitle,
          desc: state.abDesc,
          abia: state.abia,
          fn: state.profile.fn,
          tmid: notifById.tmid,
          dctp: notifById.dctp,
          hm: notifById.hm,
          mnbc: notifById.mnbc,
          lcpt: notifById.lcpt,
          isdon: notifById.isdon,
          isnutd: notifById.isnutd,
          snutd: notifById.snutd,
          dmnt: notifById.dmnt,
          dsnt: notifById.dsnt,
          dlynt: notifById.dlynt,
          intd: notifById.intd,
          rndnt: notifById.rndnt,
          mqt: notifById.mqt,
          icstlp: notifById.icstlp,
          irhwc: notifById.irhwc,
          rexkw: notifById.rexkw,
          rinkw: notifById.rinkw,
          rsrt: notifById.rsrt,
          rpgcnt: notifById.rpgcnt,
          lng: notifById.lng,
          idisnp: notifById.idisnp,
          isrtag: notifById.isrtag,
          tmagvl: notifById.tmagvl,
          tmagtyp: notifById.tmagtyp,
          tmagtxt: notifById.tmagtxt,
          isdsk: notifById.isdsk,
          icntpth: notifById.icntpth,
          cntpth: notifById.cntpth,
          itqc: notifById.itqc,
          isnpwdsg: notifById.isnpwdsg,
          isnpifdanmt: notifById.isnpifdanmt,
          isnpcsthgt: notifById.isnpcsthgt,
          isnpspcbtwnntf: notifById.isnpspcbtwnntf,
          idrkthm: notifById.idrkthm,
          icstcss: notifById.icstcss,
          cstcss: notifById.cstcss,
          csthtml: notifById.csthtml,
        };
      }
      else {
        obj = state?.objAbtestNotif?.vrnt && state.objAbtestNotif.vrnt.filter(x => x.ttl == state.abVrnt)[0];
        obj = {
          ...obj,
          id: notifId,
          ttl: state.abTitle,
          desc: state.abDesc,
          abia: state.abia,
          fn: state.fn
        }
      }

      dispatch(notifDucks.createAbTestNotif(obj));
      openCloseModal('crtVarntOpen', state.crtVarntOpen, 'close');
      const notif = state.userNotifList.find((item) => item._id === notifById._id);
      if (notif) {
        changeNameValue({ objAbtestNotif: notif }); // Update with the matched notification
      }
      changeNameValue({ abTitle: '', abDesc: '', abia: true, abVrnt: 'current', isEditAbtest: true });
    }
  }

  //handle update AbTest Show (AbTest).
  const handleUpdAbTestShow = (data) => {
    openCloseModal('edtVarntOpen', state.edtVarntOpen, 'open');
    if (data) {
      let obj = {
        abId: data._id,
        notifId: state.objAbtestNotif._id,
        abTitle: data.ttl,
        abDesc: data.desc,
        abia: data.ia,
        popoverActive: false
      }
      changeNameValue(obj);
    }
  }

  //handle update AbTest (AbTest).
  const handleUpdAbTestNotif = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });
      let obj = {
        abId: state.abId,
        notifId: state.notifId,
        ttl: state.abTitle,
        desc: state.abDesc,
        abia: state.abia,
        fn: state.profile.fn
      }
      dispatch(notifDucks.updAbTestNotif(obj));

      const updatedVariants = state.objAbtestNotif.vrnt.map(item =>
        item._id === state.abId ? { ...item, ttl: obj.ttl, desc: obj.desc, abia: obj.abia } : item
      );
      changeNameValue({ objAbtestNotif: { ...state.objAbtestNotif, vrnt: updatedVariants } });

      openCloseModal('edtVarntOpen', state.edtVarntOpen, 'close');
    }
  }

  //for the set the default variant from the notif list (AbTest).
  const handlerbtnChange = (type, value) => {
    const newObjAbtestNotif = {
      ...state.objAbtestNotif,
      vrnt: state.objAbtestNotif.vrnt.map(x => ({ ...x }))
    };

    if (type === 'current') {
      newObjAbtestNotif.iornl = true;
      newObjAbtestNotif.iafab = true;
      newObjAbtestNotif.vrnt.forEach(x => {
        x.iornl = false; // Safely mutate the clone
      });
    } else if (type === 'variant') {
      newObjAbtestNotif.iornl = false;
      newObjAbtestNotif.vrnt.forEach(x => {
        if (x._id === value) {
          x.iornl = true;
          x.ia = true;
        } else {
          x.iornl = false;
        }
      });
    }

    // Update the state with the modified clone
    changeNameValue({ objAbtestNotif: newObjAbtestNotif });
    let obj = {
      id: value,
      notifId: newObjAbtestNotif._id,
      type: type,
      ntt: newObjAbtestNotif.nt
    };
    dispatch(notifDucks.setOriginalNotif(obj));
  };

  //handle Update AbTest Notif status (AbTest).
  const handleUpdAbTestNotifStatus = (data) => {
    const obj = {
      _id: data._id,
      ia: !data.ia,
      iafab: data.iafab,
      ntt: data.ntt,
      togl: true,
      csub: state.profile.csub,
      fn: state.profile.fn,
    };
    dispatch(notifDucks.updNotifStatus(obj));
    changeNameValue({ objAbtestNotif: { ...state.objAbtestNotif, ia: obj.ia } });
  }

  //handle update variant status from the notif list (AbTest).
  const handleUpdAbTestVarntStatus = (notifId, val, abia) => {
    let obj = {
      abId: val._id,
      notifId: notifId,
      ttl: val.ttl,
      desc: val.desc,
      abia: abia,
      fn: state.profile.fn,
    };
    dispatch(notifDucks.updAbTestNotif(obj));

    // Create a new array with updated objects
    const newVrnt = state.objAbtestNotif.vrnt.map((x) => {
      if (x._id === val._id) {
        return {
          ...x,
          ia: abia,
          udt: new Date(),
        };
      }
      return x;
    });

    // Update the state with the new array
    changeNameValue({ objAbtestNotif: { ...state.objAbtestNotif, vrnt: newVrnt } });
  }

  //handle Delete AbTest Notif (AbTest).
  const handleDeleteAbTestNotif = (notifId, id) => {
    let obj = {
      notifId: notifId,
      abId: id,
    };

    if (id && state.objAbtestNotif && state.objAbtestNotif.vrnt.length > 0) {
      //delete variant
      const updatedVariants = state.objAbtestNotif.vrnt.filter(item => item._id !== id);
      changeNameValue({ objAbtestNotif: { ...state.objAbtestNotif, vrnt: updatedVariants } });
    } else {
      //delete main variant
      changeNameValue({
        objAbtestNotif: {
          ...state.objAbtestNotif,
          idltfrmvrnt: true
        },
        defaultAbtest: false,
      });
    }

    dispatch(notifDucks.deleteAbTestNotif(obj));
    changeNameValue({ vartId: '' });
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
  }

  //api call (Detail).
  useEffect(() => {
    if (state.detailShow && state.objDetailNotif) {
      dispatch(notifDucks.getRecentCapturedData(state.objDetailNotif._id));
      dispatch(notifDucks.getActiveIntgList());
    }
  }, [state.detailShow, state.objDetailNotif]);

  //api call (Dummy)
  useEffect(() => {
    if (state.dummyData && state.objDummyData) {
      let obj = {
        _id: state.objDummyData._id,
        ntt: state.objDummyData.ntt
      }
      dispatch(notifDucks.createDummyData(obj));
    }
  }, [state.dummyData, state.objDummyData])

  //set integration list (Detail).
  useEffect(() => {
    let arrInte = [];
    if (lstActiveIntg) {
      lstActiveIntg && lstActiveIntg.filter(intg => {
        intg.items.filter(item => {
          //for notification detail display integration list.
          state.objDetailNotif && state.objDetailNotif.dsi && state.objDetailNotif.dsi.filter(dsi => {
            if (dsi.uid == item._id) {
              arrInte.push({ iin: dsi.iin, rmk: item.rmk });
              changeNameValue({ arrInte });
            }
          });
        });
      });
    }
  }, [lstActiveIntg, state.objDetailNotif]);

  //set recentData (Detail).
  useEffect(() => {
    let _arrLatestData = [];
    if (recentData) {
      recentData && recentData.filter(x => {
        let obj = { fa: x.fa, fn: x.fn, ln: x.ln, insdt: x.insdt };
        lstActiveIntg && lstActiveIntg.filter(intg => {
          intg.items.filter(item => {
            if (x.fuid === item._id) {
              obj.rmk = item.rmk;
            }
          });
        });
        _arrLatestData.push(obj);
        changeNameValue({ recentData: _arrLatestData });
      });
    }
  }, [recentData, lstActiveIntg]);

  //handle Back To (Detail).
  const handleBackToDetail = () => {
    let obj = {
      detailShow: false,
      recentData: [],
      arrInte: []
    }
    changeNameValue(obj);
  }

  //openChat.
  const openChat = () => {
    if (window.Froged) {
      window.Froged('open');
    }
  };

  const handleCreateStripeLink = () => {
    let obj = {
      email: state.profile.e,
      first_name: state.profile.fn,
    }
    dispatch(pricingPlanDucks.createStripeLink(obj));
  }

  const isValidUrl = (url) => {
    const regex = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
    return regex.test(url);
  };

  const handleVerifyPixel = () => {
    let urlPixel = state.webUrl;

    if (!urlPixel.trim()) {
      dispatch(toastify({ type: 'error', msg: 'Please enter a URL' }));
      return;
    }

    if (!isValidUrl(urlPixel)) {
      dispatch(toastify({ type: 'error', msg: 'Invalid URL. Please enter a valid URL.' }));
      return;
    }

    const originalUrlPixel = urlPixel;

    let obj = { webUrl: originalUrlPixel };
    changeNameValue(obj);
    dispatch(PixelDucks.addUserSite(obj));
    dispatch(PixelDucks.getPixel());

    changeNameValue({ isPending: true });

    if (!state.pixelOpen) return;

    clearInterval(intervalIdRef.current);
    let count = 0;
    intervalIdRef.current = setInterval(() => {
      let objData = {
        urlPath: originalUrlPixel,
      };

      dispatch(PixelDucks.verifyUserPixelByURL(objData));

      count++;
      if (count >= 10) {
        clearInterval(intervalIdRef.current);
        changeNameValue({ isPending: false });
      }
      if (state.userPixelVerify && state.userPixelVerify.isFound === false) {
        clearInterval(intervalIdRef.current);
      }
    }, 5000);

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Troubleshoot popup', 'Verify pixel', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Troubleshoot popup', { 'event_action': 'Verify pixel', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  };

  const handleCloseInstallPixel = (e) => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    openCloseModal('pixelOpen', state.pixelOpen, e);
    changeNameValue({
      userPixelVerify: '',
      isPending: false
    })
  }

  const handleTroubleshoot = () => {
    if (state.webUrl) {
      let urlPixel = state.webUrl;
      urlPixel += (urlPixel.includes('?') ? '&' : '?') + 'wn_trblst=true&wn_ntid=' + state.userNotifId;

      if (!urlPixel.includes('http://') && !urlPixel.includes('https://')) {
        window.open('http://' + urlPixel, '_blank');
      } else {
        window.open(urlPixel, '_blank');
      }
    }
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Troubleshoot popup', 'Continue button', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Troubleshoot popup', { 'event_action': 'Continue button', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  }

  const handleChat = () => {
    if (window.Froged) {
      window.Froged('open');
    }
  }

  const handleNavigatePlan = () => {
    navigate('/plan');
  }

  return (
    <NotifCard
      tabs={tabs}
      state={state}
      filters={filters}
      NotifBycount={NotifBycount}
      notifActivity={notifActivity}
      isLoading={isLoading}
      changeNameValue={changeNameValue}
      onPaggiCall={onPaggiCall}
      onPaggiCallUserNotif={onPaggiCallUserNotif}
      handleFiltersClearAll={handleFiltersClearAll}
      handleupdNotifStatus={handleupdNotifStatus}
      handleEditNotif={handleEditNotif}
      openCloseModal={openCloseModal}
      handleTabChange={handleTabChange}
      handleCloseUserNotifDataModal={handleCloseUserNotifDataModal}
      handleGetUserNotifData={handleGetUserNotifData}
      handleSugtTextChange={handleSugtTextChange}
      handleSugtOptionChange={handleSugtOptionChange}
      handleSugtInlnPdpTxtChange={handleSugtInlnPdpTxtChange}
      handleSugtInlnPdpOptChange={handleSugtInlnPdpOptChange}
      handleSugtInlnCtgTxtChange={handleSugtInlnCtgTxtChange}
      handleSugtInlnCtgOptChange={handleSugtInlnCtgOptChange}
      handleAddSocialShow={handleAddSocialShow}
      handleAddWidgetShow={handleAddWidgetShow}
      handleAddWebsite={handleAddWebsite}
      handleRemoveWebsite={handleRemoveWebsite}
      handleCreateNotif={handleCreateNotif}
      handleDelNotif={handleDelNotif}
      handleBackToMain={handleBackToMain}
      handleInlnUpdNotifStatus={handleInlnUpdNotifStatus}
      handleCreateInlnNotif={handleCreateInlnNotif}
      handleCollapsible={handleCollapsible}
      handleInstallPdpShowClose={handleInstallPdpShowClose}
      handleInstallCtgShowClose={handleInstallCtgShowClose}
      openChat={openChat}
      handleCopyCode={handleCopyCode}
      handleDelInlnNotif={handleDelInlnNotif}
      handleCreateClone={handleCreateClone}
      validator={validator}
      handleDebugger={handleDebugger}
      refreshEligible={refreshEligible}
      handleEditNotifNameShow={handleEditNotifNameShow}
      handleEditNotifNameClose={handleEditNotifNameClose}
      handleTextChange={handleTextChange}
      handleUpdateNotifName={handleUpdateNotifName}
      abTestTabs={abTestTabs}
      handleBackToAbtest={handleBackToAbtest}
      handleSelectCustomDate={handleSelectCustomDate}
      handleCreateAbTestNotif={handleCreateAbTestNotif}
      handleUpdAbTestShow={handleUpdAbTestShow}
      handleUpdAbTestNotif={handleUpdAbTestNotif}
      handlerbtnChange={handlerbtnChange}
      handleUpdAbTestNotifStatus={handleUpdAbTestNotifStatus}
      handleUpdAbTestVarntStatus={handleUpdAbTestVarntStatus}
      handleDeleteAbTestNotif={handleDeleteAbTestNotif}
      handleBackToDetail={handleBackToDetail}
      handleCreateStripeLink={handleCreateStripeLink}
      handleVerifyPixel={handleVerifyPixel}
      handleCloseInstallPixel={handleCloseInstallPixel}
      handleTroubleshoot={handleTroubleshoot}
      handleChat={handleChat}
      handleNavigatePlan={handleNavigatePlan}
    />
  );
};
export default Notif;