import React from 'react';
import { Badge, Box, Button, Card, EmptySearchResult, IndexFilters, IndexTable, InlineStack, Layout, Link, Navigation, Page, Text, useSetIndexFiltersMode } from '@shopify/polaris';
import { CartFilledIcon, CursorFilledIcon, DeliveryFilledIcon, DomainFilledIcon, LocationFilledIcon, MarketsFilledIcon, MetafieldsFilledIcon, NotificationFilledIcon, PlanFilledIcon, StoreFilledIcon, TaxFilledIcon } from '@shopify/polaris-icons';

function MyDukkanCard(props) {
  const { state, changeNameValue, openModal } = props;
  const { mode, setMode } = useSetIndexFiltersMode();


  return (
    <Page title={'My Dukkan'} subtitle={'Connect your stack & automate the social proof on your site'}>
      <Box paddingBlockEnd={400}></Box>
    </Page>
  )
}

export default MyDukkanCard;