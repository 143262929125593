import React, { useEffect, useState } from 'react';
import { Banner, Button, InlineStack, Text } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as billingDucks from '../../ducks/billingInvoice';
import { useDispatch } from 'react-redux';

function BiilingWarning(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    mailTo: '',
    planUse: 0,
    isOpenChargebeePymFrm: false,
    isOpenBanner: true
  });

  const changeNameValue = (obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }

  const profile = useSelector((state) => state?.auth?.profile);
  const chargebeePay = useSelector(state => state.billingInvoice?.mangePaymentSrc);

  useEffect(() => {
    changeNameValue({ mailTo: profile?.brand?.feadr });
    let userQuota = profile?.uuq;
    if (profile?.puuq) {
      userQuota = userQuota + profile.puuq;
    }
    let planUse = userQuota / profile?.uaq * 100;
    changeNameValue({ planUse: parseInt(planUse) });
  }, [profile])

  const getPlanNew = (typ) => {
    if (typ === 'cancel') {
      navigate('/plan')
    }
    else {
      navigate('/plan?plan=' + profile.billing.pobj.id)
    }
  };

  const paymantRetry = () => {
    let obj = {
      cust_id: profile.cbe.client_id
    };
    dispatch(billingDucks.paymantRetry(obj));
  }

  //manage payment source
  const mangePaymentSrc = () => {
    dispatch(billingDucks.mangePaymentSrc());
    changeNameValue({ isOpenChargebeePymFrm: true })
  }

  useEffect(() => {
    if (chargebeePay && state.isOpenChargebeePymFrm === true) {
      if (chargebeePay) {
        var chargebeeInstance = window.Chargebee.getInstance();
        chargebeeInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve) {
              var sampleResponse = chargebeePay.hosted_page;
              resolve(sampleResponse);
            });
          },
        });
        changeNameValue({ isOpenChargebeePymFrm: false });
      }
    }
  }, [chargebeePay])

  //handle Banner Close Btn
  const handleDismissBtn = () => {
    changeNameValue({ isOpenBanner: false })
    dispatch(billingDucks.rmvUserAlrt());
  }

  return (
    state.isOpenBanner &&
    <div>
      {
        (profile && profile?.csub === undefined && profile?.blid === undefined && profile?.pvd !== 'shopify' && profile?.pvd !== 'wix' && profile?.pvd !== 'shopline' && profile?.utyp !== 'appsumo') ?
          <Banner
            title={'Activate Plan to Display Notifications'}
            tone="critical"
          >
            Unlock full access, boost conversions, and enjoy a 7-day free trial.<Link className='alerts-btn' to='/plan' style={{ padding: '0px 5px' }}>Start Your Free Trial</Link>
          </Banner>
          :
          profile && profile.billing && profile.cbtrnflmsg && profile.cbtrnflmsg !== undefined && profile.utyp === 'appsumo' && profile.billing.icnc === true && profile.billing.pobj.id === "appsumo-spacial" ?
            <Banner
              title='Your social-wall plan is inactive due to a payment failure'
              tone="critical"
              onDismiss={() => handleDismissBtn()}
            >
              <InlineStack gap={300}>
                <Button onClick={() => paymantRetry()}>Retry now</Button>
              </InlineStack>
            </Banner>
            :
            profile && profile.isfrexp !== true ?
              profile.cbtrnflmsg && profile.cbtrnflmsg !== undefined ?
                <Banner
                  title={profile.cbtrnflmsg}
                  tone="critical"
                >
                  <InlineStack gap={300}>
                    {
                      profile.billing && profile.billing.icnc === true ?
                        <Button onClick={() => getPlanNew()}>Retry now</Button> :
                        <Button onClick={() => paymantRetry()}>Retry now</Button>
                    }
                    {
                      profile.cbtrnflmsg === 'Your card was declined.' &&
                      <Button onClick={() => mangePaymentSrc()}>Change payment details</Button>
                    }
                  </InlineStack>
                </Banner>
                :
                (profile && profile.utyp !== 'appsumo' && profile.billing && profile.billing.iexp === true && profile.billing.icnc === true) ?
                  <Banner
                    title={'You have canceled your plan'}
                    tone="critical"
                  >
                    <Button onClick={() => getPlanNew('cancel')}>Subscribe Your Plan</Button>
                  </Banner> :
                  (state.planUse === 100 || state.planUse > 100) && <>
                    {
                      localStorage.getItem('isbusr') === 'true' ?
                        <>
                          {new Date() > new Date(profile?.billing?.pendt) ?
                            <Banner
                              title={'Your plan has expired'}
                              tone="critical"
                            >
                              <Text> Your plan has expired. Please contact us at <a style={{ padding: '0px' }} href={state.mailTo}>{profile?.brand?.feadr}</a> for renewal</Text>
                            </Banner>
                            :
                            <Banner
                              title={"You've consumed 100% visitor quota in your current plan. To continue displaying social proof"}
                              tone="critical"
                            >
                              <Text><a href={state.mailTo}>{profile?.brand?.feadr}</a></Text>
                            </Banner>
                          }
                        </> :
                        <Banner
                          title={"You've consumed 100% visitor quota in your current plan. To continue displaying social proof,"}
                          tone="critical"
                        >
                          <Link className='alerts-btn' to='/plan'>Upgrade Now.</Link>
                        </Banner>
                    }
                  </>
              :
              profile && profile.isfrexp === true &&
              <Banner
                title={'Your free plan has expired.'}
                tone="warning"
              >
                <Link className='alerts-btn' to='/plan' style={{ padding: '0px 5px' }}>Upgrade </Link> to continue displaying social proof, starting at $48/year.
              </Banner>
      }
    </div>
  );
}

export default BiilingWarning;