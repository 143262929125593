import React, { useCallback, useEffect, useState, useRef } from 'react';
import { PixelCard } from "../../components";
import './Pixel.css'
import { toastify } from '../../ducks/toast';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as PixelDucks from '../../ducks/pixel';
import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { checkPermission } from '../../services/permissions';
import { useLocation } from 'react-router-dom';

const Pixel = () => {

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intervalIdRef = useRef(null);

  const [state, setState] = useState({
    guideOpenApp: false,
    guideopenPage: false,
    ak: "",
    queryValue: "",
    selected: 0,
    pixelOpen: false,
    dlytm: 1000,
    platformList: [],
    pixelList: [],
    statusTabs: [],
    copyCode: '',
    url: '',
    isPixel: 'found',
    isVerify: 'true',
    verifyTime: 0,
    checkClick: false,
    isCallInterval: false,
    userPixelVerify: false,
  })




  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const pixelData = useSelector(state => state.pixel.pixelData)
  const profileData = useSelector(state => state.auth.profile);
  const platformData = useSelector(state => state.pixel.pixelPlatform);
  const userVerify = useSelector(state => state.pixel.userPixelVerify);


  let PixelInterval;
  let IS_TAB_VISIBILITY = true;
  document.addEventListener('visibilitychange', function () { document.hidden ? IS_TAB_VISIBILITY = false : IS_TAB_VISIBILITY = true; });

  useEffect(() => {
    dispatch(PixelDucks.getPixel());
  }, [])

  useEffect(() => {
    if (profileData?.ak) {
      changeNameValue({ ak: profileData.ak });
    }
    if (profileData?.dlytm) {
      changeNameValue({ dlytm: profileData.dlytm });
    }
    if (profileData?.vstp?.plx?.st === false) {
      changeNameValue({ isVerify: false });
    }
    let _brandName = 'WiserNotify';
    let ti = profileData ? profileData.ti : '';

    if (profileData && profileData.brand) {
      let _brandURL = profileData.brand.wurl || 'pt.wisernotify.com';
      _brandName = profileData.brand.bn || _brandURL;
      state.copyCode = '<script>!function () { if (window.t4hto4) console.log("' + _brandName + ' pixel installed multiple time in this page"); else { window.t4hto4 = !0; var t = document, e = window, n = function () { var e = t.createElement("script"); e.type = "text/javascript", e.async = !0, e.src = "https://' + _brandURL + '/pixel.js?ti=' + ti + '", document.body.appendChild(e) }; "complete" === t.readyState ? n() : window.attachEvent ? e.attachEvent("onload", n) : e.addEventListener("load", n, !1) } }();</script>';
    }
    else {
      state.copyCode = '<script>!function () { if (window.t4hto4) console.log("WiserNotify pixel installed multiple time in this page"); else { window.t4hto4 = !0; var t = document, e = window, n = function () { var e = t.createElement("script"); e.type = "text/javascript", e.async = !0, e.src = "https://pt.wisernotify.com/pixel.js?ti=' + ti + '", document.body.appendChild(e) }; "complete" === t.readyState ? n() : window.attachEvent ? e.attachEvent("onload", n) : e.addEventListener("load", n, !1) } }();</script>';
      _brandName = 'WiserNotify';
    }
    changeNameValue({ copyCode: state.copyCode })
  }, [profileData]);

  useEffect(() => {
    changeNameValue({ userPixelVerify: userVerify });
  }, [userVerify]);

  useEffect(() => {
    if (pixelData?.length > 0) {
      const updatedPixelData = pixelData.map((data) => {
        const time = data.t ? days_between(new Date(data.t)) : '-';
        return {
          ...data,
          time,
          ts: time.search(/(minutes|seconds|live) ago/) !== -1
        };
      });

      changeNameValue({ pixelList: updatedPixelData });
    }
  }, [pixelData]);

  useEffect(() => {
    let updatedList = platformData || [];

    if (state.queryValue) {
      updatedList = updatedList.filter(platform =>
        platform.dn.toLowerCase().includes(state.queryValue.toLowerCase())
      );
    }

    changeNameValue({ platformList: updatedList });
  }, [platformData, state.queryValue]);


  const tabs = state?.statusTabs?.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
  }));

  const handleCopyCode = (value) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Your pixel code is copied" }));
  }

  const handleVerifyCode = () => {
    changeNameValue({ pixelOpen: true, userPixelVerify: '' });

  }

  useEffect(() => {
    if (state.pixelOpen === true && state.userPixelVerify && state.userPixelVerify.isFound === true) {
      changeNameValue({ isLoading: false })
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    if (state.pixelOpen === true && !state.userPixelVerify) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, [state.pixelOpen, state.url, state.userPixelVerify])

  const handleCopyApi = (value) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Your API Key is copied" }));
    // navigate('/plan');
  }
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValue({ [name]: !value });
      changeNameValue({ url: '', userPixelVerify: null, pixelOpen: false });
    }
  }, []);

  const handleCloseInstallPixel = (e) => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    openCloseModal('pixelOpen', state.pixelOpen, e);
  }

  const handleChangeDelay = (selectedDelay) => {
    let obj = {
      dlytm: selectedDelay
    }
    changeNameValue(obj);
    dispatch(PixelDucks.updateDelay(obj));
  }

  useEffect(() => {
    if (state.isVerify) {
      if (state.checkClick === true && pixelData) {
        changeNameValue({ isPixel: 'found' });
      }
      if (pixelData?.length > 0) {
        changeNameValue({ isPixel: 'found' });
      } else {
        changeNameValue({ isPixel: 'notFound' });
      }
      changeNameValue({ checkClick: false });

    } else {
      if (state.isCallInterval === false) {
        verifyPixel();
        changeNameValue({ isCallInterval: true });
      }
    }
  }, [state.isVerify, state.checkClick, pixelData, state.isCallInterval, state.verifyTime]);

  const verifyPixel = () => {
    clearInterval(PixelInterval);
    PixelInterval = setInterval(function () {
      if (window.location.pathname === '/pixel' && IS_TAB_VISIBILITY === true) {
        if (IS_TAB_VISIBILITY === true) {
          if (state.verifyTime === 50) {
            clearInterval(PixelInterval);
          }
          else {
            state.verifyTime = state.verifyTime + 1;
            dispatch(PixelDucks.verifyUserPixel());
          }
        }
      }
      else {
        clearInterval(PixelInterval);
      }
    }, 5000);
  }

  const isValidUrl = (url) => {
    const regex = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
    return regex.test(url);
  };

  const handleVerifyPixel = () => {
    let urlPixel = state.url;

    if (!urlPixel.trim()) {
      dispatch(toastify({ type: 'error', msg: 'Please enter a URL' }));
      return;
    }

    if (!isValidUrl(urlPixel)) {
      dispatch(toastify({ type: 'error', msg: 'Invalid URL. Please enter a valid URL.' }));
      return;
    }

    const originalUrlPixel = urlPixel;
    urlPixel += (urlPixel.includes('?') ? '&' : '?') + 'CheckingWN=true&ti=' + profileData.ti;

    if (!urlPixel.includes('http://') && !urlPixel.includes('https://')) {
      window.open('http://' + urlPixel, '_blank');
    } else {
      window.open(urlPixel, '_blank');
    }

    let obj = { url: originalUrlPixel };
    changeNameValue(obj);
    dispatch(PixelDucks.addUserSite(obj));
    dispatch(PixelDucks.getPixel());

    changeNameValue({ isLoading: true });

    if (!state.pixelOpen) return;

    clearInterval(intervalIdRef.current);
    let count = 0;
    intervalIdRef.current = setInterval(() => {
      let objData = {
        urlPath: originalUrlPixel,
      };

      dispatch(PixelDucks.verifyUserPixelByURL(objData));

      count++;
      if (count >= 10) {
        clearInterval(intervalIdRef.current);
        changeNameValue({ isLoading: false });
      }
      if (state.userPixelVerify && state.userPixelVerify.isFound === false) {
        clearInterval(intervalIdRef.current);
      }
    }, 5000);
  };

  const filters = [
    {
      key: 'notifTyp',
      label: "",
      shortcut: true,
      pinned: true,
      hideClearButton: true,
    }
  ];

  const handleFiltersClearAll = useCallback(() => {
    let obj = {
      queryValue: '',
      notifTyp: 'all',
    }
    changeNameValue(obj);
  }, []);

  const callPixelApi = () => {
    const obj = {
      name: state.queryValue,
    }
    dispatch(PixelDucks.getPlatformInt(obj));
  }

  useEffect(() => {
    callPixelApi();
  }, []);

  const handleCreateNotif = () => {
    navigate('/socialproof')
  }

  const days_between = (date) => {
    if (new Date() >= new Date(date)) {
      var seconds = Math.floor((new Date() - new Date(date)) / 1000);
      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        return interval + ' years ago';
      }
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        return interval + ' months ago';
      }
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        return interval + ' days ago';
      }
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) {
        return interval + ' hours ago';
      }
      interval = Math.floor(seconds / 60);
      if (interval >= 1) {
        return interval + ' minutes ago';
      }
      return Math.floor(seconds) + ' seconds ago';
    } else {
      return 'live';
    }
  };

  const handleChat = () => {
    if (window.Froged) {
      window.Froged('open');
    }
  }

  return (
    <>
      {checkPermission('pixel', 'isview', profileData) &&
        <PixelCard
          state={state}
          tabs={tabs}
          filters={filters}
          handleFiltersClearAll={handleFiltersClearAll}
          changeNameValue={changeNameValue}
          handleCopyCode={handleCopyCode}
          handleVerifyCode={handleVerifyCode}
          handleCopyApi={handleCopyApi}
          openCloseModal={openCloseModal}
          handleChangeDelay={handleChangeDelay}
          handleVerifyPixel={handleVerifyPixel}
          handleCreateNotif={handleCreateNotif}
          days_between={days_between}
          profile={profileData}
          checkPermission={checkPermission}
          handleChat={handleChat}
          userVerify={userVerify}
          handleCloseInstallPixel={handleCloseInstallPixel}
        />
      }
    </>
  );
};

export default Pixel;
