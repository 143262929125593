import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    getEsAnalytic: (state, action) => { },
    getEsAnalyticSuccess: (state, action) => {
      return { ...state, getEsAnalytic: action.payload };
    },
    getEsAnalyticClear: (state, action) => {
      return { ...state, getEsAnalytic: {} };
    },

    getVisitHistoryPr: (state, action) => { },
    getVisitHistoryPrSuccess: (state, action) => {
      return { ...state, getVisitHistoryPr: action.payload };
    },

    getSignupDataCount: (state, action) => { },
    getSignupDataCountSuccess: (state, action) => {
      return { ...state, getSignupDataCount: action.payload };
    },
  }
});

export const {
  getEsAnalytic,
  getEsAnalyticSuccess,
  getEsAnalyticClear,
  getVisitHistoryPr,
  getVisitHistoryPrSuccess,
  getSignupDataCount,
  getSignupDataCountSuccess,

} = analytics.actions;

export default analytics.reducer;
