import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip, List, Spinner, Select, Badge
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ClipboardIcon, ChannelsIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from "react-router-dom";
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';

function CustomWebhookCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    errMessage: {},
    CustomWebhookCard: [],
    rmk: '',//Name your connection
    dataId: '',//delete id
    ia: false,//for toggle on/off
    deleteDataModal: false,//open delete modal
    modalC: false,//condition for delete modal
    notifListData: "",//show data in modal

    viewDataModal: false,//open view modal
    mapId: '',//map id
    isloading: false,
    wfkey: '',// keyword
    cfkey: '',//key&value
    optionKeyword: [],//keyword-list
    optionrvcKeyVal: [],//key-value list
    CWFInterval: '',//interval
    rmkName: '',//rmk name
    Endpoint: '',//endpoint
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //CustomWebhookCard data from api
  const getCustomWebhookData = useSelector(state => state.integration?.userIntData);
  const custWBFieldData = useSelector(state => state.integration?.custWBField);
  let resp = custWBFieldData?.resp;
  let findAmPData = info?.CustomWebhookCard && info?.CustomWebhookCard.length > 0 && info?.CustomWebhookCard?.find((elm) => elm._id === info?.mapId);

  useEffect(() => {
    const arrayOp = [{ label: '-- Select Key/Value --', value: '' }];
    if (resp?.length > 0) {
      clearInterval(info.CWFInterval);
      resp?.length > 0 && resp?.map((elm) => { arrayOp.push({ label: elm, value: elm }) });
      changeNameValueCard({ optionrvcKeyVal: arrayOp, isloading: false });
    }
    else if (info.viewDataModal === true) {
      changeNameValueCard({ isloading: true })
      getCWFInt();
    }
    changeNameValueCard({ CustomWebhookCard: getCustomWebhookData, optionrvcKeyVal: arrayOp });
  }, [getCustomWebhookData, custWBFieldData])

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.CustomWebhookCard?.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ CustomWebhookCard: updatedList })
  };

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, })
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteCustomWebhook(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //create CustomWebhookCard data 
  const createData = () => {
    if (props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    } else {
      changeNameValueCard({ errMessage: {} });
      let obj = {
        rmk: info.rmk,
      };
      dispatch(integrationDucks.createCustomWebhook(obj));
      changeNameValueCard({ rmk: '', errMessage: {} });
    }
  }

  //copy endpoint
  const copyEndpoint = (url) => {
    copy(url, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Endpoint copied" }));
    return;
  }

  //Object For WiserNotify Key
  let objk = {
    un: '{{USERNAME}}',
    e: '{{EMAIL}}',
    phn: '{{PHONE}}',
    ct: '{{CITY}}',
    st: '{{STATE}}',
    cn: '{{COUNTRY}}',
    i: '{{IP}}',
    lt: '{{LATITUDE}}',
    lg: '{{LONGITUDE}}',
    pn: '{{PRODUCT_NAME}}',
    pu: '{{PRODUCT_URL}}',
    piu: '{{PRODUCT_IMAGE}}',
    fa: '{{REVIEW_APP}}',
    rtxt: '{{REVIEW_TEXT}}',
    pgn: '{{PAGE_NAME}}',
    pgid: '{{PAGE_ID}}',
    rtng: '{{USER_RATING}}',
    insdt: '{{CAPTURE_DATE}}',
    pdid: '{{PRODUCT_ID}}',
    // rtng: '{{USER_RATING}}',
    // rtxt: '{{REVIEW_TEXT}}',
    // pgn: '{{PAGE_NAME}}',
    // pgid: '{{PAGE_ID}}',
    fn: '{{F_NAME}}',
    ln: '{{L_NAME}}',
    // oid: '{{ORDER_ID}}',
    // yvc: '{{YTB_VIEW_CNT}}',
    // ylc: '{{YTB_LIKE_CNT}}',
    // ydc: '{{YTB_DIS_LIKE_CNT}}',
    // yfc: '{{YTB_FAV_CNT}}',
    // ycc: '{{YTB_CMNT_CNT}}',
    // ysc: '{{YTB_SUB_CNT}}',
    // yvdc: '{{YTB_VIDEO_CNT}}',
    cst1: '{{CUSTOM_1}}',
    cst2: '{{CUSTOM_2}}',
    cst3: '{{CUSTOM_3}}'
  };

  //open view modal
  const handleModal = (id, rmk, wbURL) => {
    keyWordFilter(id);
    changeNameValueCard({ mapId: id, cfkey: "", wfkey: "", rmkName: rmk, Endpoint: wbURL });
    openCloseModal('viewDataModal', info.viewDataModal, 'open');
    dispatch(integrationDucks.getCustomWebhookField({ usind: id }));
  }

  // mapping listed keyword remove 
  const keyWordFilter = (id) => {
    let filterKeyWord = info?.intDataList?.find((elm) => elm._id === id);
    filterKeyWord?.amp?.map((value) => {
      const keys = Object.keys(value)[0];
      for (const key1 in objk) {
        if (objk[key1] === keys) {
          delete objk[key1];
        }
      }
    });
    let arrayOp = [];
    for (const key in objk) {
      if (objk.hasOwnProperty(key)) {
        arrayOp.push({ [key]: objk[key] });
      }
    }
    let optionKeyword = [];
    optionKeyword = arrayOp?.map((elm) => { return { label: elm[Object.keys(elm)[0]], value: elm[Object.keys(elm)[0]] } })
    optionKeyword.unshift({ label: '-- Select Keyword --', value: '' })
    changeNameValueCard({ optionKeyword })
  }

  // view modal delete field
  const handleDeleteMapField = (val) => {
    const obj = {
      fuid: info.mapId,
      key: val
    };
    dispatch(integrationDucks.delCustomWebhookFieldUint(obj));
    const oldOption = info.optionKeyword;
    oldOption.push({ label: val, value: val });
    changeNameValueCard({ optionKeyword: oldOption });
  }

  //Interval function for get custom webhook field
  const getCWFInt = () => {
    clearInterval(info.CWFInterval);
    let CWFInterval = setInterval(function () {
      dispatch(integrationDucks.getCustomWebhookField({ usind: info.mapId }));
    }, 5000);
    changeNameValueCard({ CWFInterval })
  }

  //start listening btn
  const handleStartListening = () => {
    getCWFInt();
    dispatch(integrationDucks.updCustField({ fuid: info.mapId }));
  }

  //Add button 
  const handleAddField = () => {
    if (info.wfkey !== '' && info.cfkey !== '') {
      const obj = {
        "fuid": info.mapId,
        "wfkey": info.wfkey,
        "cfkey": info.cfkey
      }
      dispatch(integrationDucks.createCustomWebhookFieldUint(obj));
      let optionfilter = info.optionKeyword.filter((elm) => elm.label !== info.wfkey)
      changeNameValueCard({ wfkey: '', cfkey: '', optionKeyword: optionfilter });
    }
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create Custom Webhooks data */}
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required|required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box align='center' padding={300}><Button onClick={(e) => createData(e)} variant="primary">Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info?.CustomWebhookCard?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Endpoint' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info?.CustomWebhookCard && info?.CustomWebhookCard?.length > 0 && info?.CustomWebhookCard?.map((i, index) => {
                  let wbURL = i.d && i.d?.web_url;
                  if (props.isWhite === true) {
                    wbURL = wbURL.replace('https://is.wisernotify.com', window.location.origin);
                  }
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>{i.rmk}</IndexTable.Cell>
                        <IndexTable.Cell><span className='text-break amazon-url'> {wbURL ? wbURL.slice(0, 30) + '...' : ''}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <InlineStack>
                            <Box paddingInline={100}>
                              <div id="delete" onClick={() => copyEndpoint(wbURL)}>
                                <Banner hideIcon tone='info'>
                                  <Icon
                                    source={ClipboardIcon}
                                    tone="info"
                                  />
                                </Banner>
                              </div>
                            </Box>
                            <Box paddingInline={100}>
                              <div id="delete" onClick={() => handleModal(i._id, i.rmk, wbURL)}>
                                <Banner hideIcon tone='success'>
                                  <Icon
                                    source={ChannelsIcon}
                                    tone="success"
                                  />
                                </Banner>
                              </div>
                            </Box>
                            {props.checkPermission('integration', 'isdelete', props.profile) &&
                              <Box paddingInline={100}>
                                <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                  <Banner hideIcon tone='critical'>
                                    <Icon
                                      source={DeleteIcon}
                                      tone="critical"
                                    />
                                  </Banner>
                                </div>
                              </Box>
                            }

                          </InlineStack>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    </>
                  )
                })}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close') }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e) }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => { handleDeleteCall(info.dataId) }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close') }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }

      {/* view modal  */}
      <Modal
        open={info.viewDataModal}
        title={<Text variant='headingMd' fontWeight='bold'>{info.rmkName} - Field mapping setup</Text>}
        onClose={(e) => { openCloseModal('viewDataModal', info.viewDataModal, e); clearInterval(info.CWFInterval); }}>
        <Modal.Section>
          <Box paddingBlockEnd={400} id="modal-in-modal">
            <Box paddingBlockEnd={200}>
              <TextField
                value={info.Endpoint ? info.Endpoint.slice(0, 70) : info.Endpoint}
                label={<Text variant="headingSm" fontWeight="bold">Webhook URL</Text>}
                suffix={
                  <Box padding={100}>
                    <Button
                      variant='primary'
                      tone='success'
                      icon={ClipboardIcon}
                      onClick={() => copyEndpoint(info.Endpoint)}
                    >
                      Copy
                    </Button>
                  </Box>
                }
              />
            </Box>
            <Text variant="headingSm" fontWeight="bold">Action required:</Text>
            <Box paddingBlockStart={100}>
              <List type="bullet">
                <List.Item>Please post sample data from your third-party platform over the webhook URL endpoint.</List.Item>
                <List.Item>Once wisernotify receives the sample data, you need to map those data with the wisernotify keyword.</List.Item>
              </List>
            </Box>
            <Box paddingBlockStart={0}>
              <Text variant="headingSm" fontWeight="bold">Status:</Text>
              <Box paddingBlockStart={100}>
                <InlineStack gap={200} blockAlign='center'>
                  <Button variant="primary" tone="success" onClick={() => handleStartListening()} disabled={info.isloading === true ? true : false} >Start listening</Button>
                  {info.isloading && <Spinner accessibilityLabel="Small spinner example" size="small" />}
                </InlineStack>
                {!info.isloading && (
                  <Box paddingBlockStart={100}>
                    <Text>Click on start listening & post a data on webhook URL from your third party platform. </Text>
                  </Box>
                )}
                {info.isloading && (
                  <Box paddingBlockStart={100}>
                    <Text>Please post sample data on the webhook URL from your third party platform. </Text>
                  </Box>
                )}
              </Box>
            </Box>
            <Box paddingBlockStart={100}>
              <Text variant="headingSm" fontWeight="bold">Mapping setup</Text>
              <InlineStack gap={400} blockAlign={'Stretch'} wrap={false}>
                <Box width='43%'>
                  <Select
                    label="Select default keyword"
                    options={info.optionKeyword}
                    onChange={(e) => changeNameValueCard({ wfkey: e })}
                    value={info.wfkey}
                  />
                </Box>
                <Box width='43%'>
                  <Select
                    label="Select received key-value"
                    options={info.optionrvcKeyVal}
                    onChange={(e) => changeNameValueCard({ cfkey: e })}
                    value={info.cfkey}
                  />
                  <Box paddingBlockStart={200}>
                    <Text>{!info.isloading && <Badge tone="success">Received data successfully!</Badge>}</Text>
                  </Box>
                </Box>
                <Box paddingBlockStart={600}>
                  <Button variant="primary" size="large" onClick={() => handleAddField()}>Add</Button>
                </Box>
              </InlineStack>
            </Box>
          </Box>
          <Card padding={0}>
            <IndexTable
              itemCount={findAmPData?.amp?.length || 0}
              headings={[
                {
                  title: <Text variant='headingSm' fontWeight='bold'>No</Text>
                },
                {
                  title: <Text variant='headingSm' fontWeight='bold'>Key </Text>
                },
                {
                  title: <Text variant='headingSm' fontWeight='bold'>Value</Text>
                },
                {
                  title: <Text variant='headingSm' fontWeight='bold'>Action </Text>
                }
              ]}
              selectable={false}
            >
              {findAmPData && findAmPData?.amp?.length > 0 && findAmPData?.amp?.map((data, i) => (
                <>
                  <IndexTable.Row key={i}>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="bold" as="span">
                        {i + 1}
                      </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{Object.keys(data)[0]}</IndexTable.Cell>
                    <IndexTable.Cell>{data[Object.keys(data)[0]]}</IndexTable.Cell>
                    <IndexTable.Cell>
                      <div id="delete" onClick={() => handleDeleteMapField(Object.keys(data)[0])}>
                        <Banner hideIcon tone='critical'>
                          <Icon
                            source={DeleteIcon}
                            tone="critical"
                          />
                        </Banner>
                      </div>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                </>
              ))}
            </IndexTable>
          </Card>
        </Modal.Section>
      </Modal>
    </Page >
  )
}

export default CustomWebhookCard;