import { Frame, Modal, Text, BlockStack, Box, Card, InlineStack, Badge, Button, SkeletonBodyText } from '@shopify/polaris';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';

function Debugger({ props }) {
  const { state, changeNameValue } = props;
  const handleChange = () => changeNameValue({ isDebugger: !state.isDebugger });
  const isLoading = useSelector(state => state?.loading?.isLoading);

  let isValidDisplay = false;
  if (state.cnntt != 'title_bar') {
    isValidDisplay = true;
  }
  let isValidSource = false;
  if (state.cnntt != 'limited_stock' &&
    state.cnntt != 'title_bar' &&
    state.cnntt != 'call_back banner' &&
    state.cnntt != 'custom' &&
    state.cnntt != 'video' &&
    state.cnntt != 'call_to_action' &&
    state.cnntt != 'collector' &&
    state.cnntt != 'live_visitor' &&
    state.cnntt != 'whatsapp_chat' &&
    state.cnntt != 'recent_view_bulk' &&
    state.cnntt != 'social_media_followers' &&
    state.cnntt != 'all_in_one' &&
    state.cnntt != 'lottery' &&
    state.cnntt != 'single_visits') {
    isValidSource = true;
  }
  let isValidEligible = false;
  if (state.cnntt != 'limited_stock' &&
    state.cnntt != 'title_bar' &&
    state.cnntt != 'custom' &&
    state.cnntt != 'video' &&
    state.cnntt != 'call_to_action' &&
    state.cnntt != 'collector' &&
    state.cnntt != 'live_visitor' &&
    state.cnntt != 'whatsapp_chat' &&
    state.cnntt != 'recent_view_bulk' &&
    state.cnntt != 'review_feed' &&
    state.cnntt != 'video_subscriber' &&
    state.cnntt != 'channel_subscriber' &&
    state.cnntt != 'social_media_followers' &&
    state.cnntt != 'all_in_one' &&
    state.cnntt != 'lottery' &&
    state.cnntt != 'single_visits') {
    isValidEligible = true;
  }
  let isValidPixel = true;
  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Modal
          open={state.isDebugger}
          onClose={handleChange}
          title={
            <InlineStack gap="200" align="start" blockAlign="baseline">
              <Text variant="headingMd" as="h6">
                {state?.cnname}
              </Text>
              <Badge size="small">{state?.cnmnn}</Badge>
            </InlineStack>
          }
        >
          <Modal.Section>

            <BlockStack gap="500">
              {isLoading ?
                <BlockStack gap="500">
                  <Card><SkeletonBodyText /></Card>
                  <Card><SkeletonBodyText /></Card>
                  <Card><SkeletonBodyText /></Card>
                </BlockStack>
                : <>
                  {state?.smartDebuggerList?.pixel && <Card>
                    <BlockStack >
                      <Box width="100%">
                        <BlockStack gap={100}>
                          <Box width="100%">
                            <InlineStack
                              gap="1200"
                              align="space-between"
                              blockAlign="start"
                              wrap={false}
                            >
                              <InlineStack wrap={false}>
                                <InlineStack gap="200" align="start" blockAlign="baseline">
                                  <label>
                                    <Text variant="headingMd" as="h6">
                                      Pixel detect
                                    </Text>
                                  </label>
                                  <InlineStack align="center" blockAlign="center" gap={200}>
                                    <Badge progress="complete" tone={state?.smartDebuggerList?.pixel == "0" ? 'success' : 'critical'}>{state?.smartDebuggerList?.pixel === "0" ? 'Success' : 'Missing'}</Badge>
                                  </InlineStack>
                                </InlineStack>
                              </InlineStack>
                            </InlineStack>
                          </Box>
                          <BlockStack gap="400">
                            <Text variant="bodyMd" as="p" tone="subdued">
                              {state?.smartDebuggerList?.pixel === "0" ? 'Everything looks perfect!' : "Looks like the website doesn't have any visitors in last 60 min or maybe pixel code is not available on your site. Please check it!"}
                            </Text>
                          </BlockStack>
                        </BlockStack>
                      </Box>
                    </BlockStack>
                  </Card>
                  }
                  {isValidDisplay == true && state?.smartDebuggerList?.displayPage && <Card>
                    <BlockStack >
                      <Box width="100%">
                        <BlockStack gap={100}>
                          <Box width="100%">
                            <InlineStack
                              gap="1200"
                              align="space-between"
                              blockAlign="start"
                              wrap={false}
                            >
                              <InlineStack wrap={false}>
                                <InlineStack gap="200" align="start" blockAlign="baseline">
                                  <label>
                                    <Text variant="headingMd" as="h6">
                                      Display rules
                                    </Text>
                                  </label>
                                  <InlineStack align="center" blockAlign="center" gap={200}>
                                    <Badge progress={state?.smartDebuggerList?.displayPage === "0" || state?.smartDebuggerList?.displayPage === "2" ? "complete" : "incomplete"} tone="success">{state?.smartDebuggerList?.displayPage === "0" ? 'Success' : 'Missing'}</Badge>
                                  </InlineStack>
                                </InlineStack>
                              </InlineStack>
                            </InlineStack>
                          </Box>
                          <BlockStack gap="400">
                            <Text variant="bodyMd" as="p" tone="subdued">
                              {state?.smartDebuggerList?.displayPage === "0" ? 'Everything looks perfect!' : state?.smartDebuggerList?.displayPage === "1" ? "Looks like, You haven't added any display URL. Please add at least one URL." : 'Looks like, You have customized display rules option. Notification will get display as per your display URL configuration.'}
                            </Text>
                          </BlockStack>
                        </BlockStack>
                      </Box>
                    </BlockStack>
                  </Card>
                  }

                  {isValidSource == true && state.listTyp !== 'widget' && state?.smartDebuggerList?.sourceList && <Card>
                    <BlockStack >
                      <Box width="100%">
                        <BlockStack gap={100}>
                          <Box width="100%">
                            <InlineStack
                              gap="1200"
                              align="space-between"
                              blockAlign="start"
                              wrap={false}
                            >
                              <InlineStack wrap={false}>
                                <InlineStack gap="200" align="start" blockAlign="baseline">
                                  <label>
                                    <Text variant="headingMd" as="h6">
                                      Data-source
                                    </Text>
                                  </label>
                                  <InlineStack align="center" blockAlign="center" gap={200}>
                                    <Badge progress="complete" tone={state?.smartDebuggerList?.sourceList === "0" ? 'success' : 'critical'}>{state?.smartDebuggerList?.sourceList === "0" ? 'Success' : 'Missing'}</Badge>
                                  </InlineStack>
                                </InlineStack>
                              </InlineStack>
                            </InlineStack>
                          </Box>
                          <BlockStack gap="400">
                            <Text variant="bodyMd" as="p" tone="subdued">
                              {state?.smartDebuggerList?.sourceList === "0" ? 'Everything looks perfect!' : 'Looks, you might forget to add data source. Please add it.'}
                            </Text>
                          </BlockStack>
                        </BlockStack>
                      </Box>
                    </BlockStack>
                  </Card>
                  }

                  {isValidEligible == true && state.listTyp !== 'widget' && state?.smartDebuggerList?.eligible && <Card>
                    <BlockStack >
                      <Box width="100%">
                        <BlockStack gap={100}>
                          <Box width="100%">
                            <InlineStack
                              gap="1200"
                              align="space-between"
                              blockAlign="start"
                              wrap={false}
                            >
                              <InlineStack wrap={false}>
                                <InlineStack gap="200" align="start" blockAlign="baseline">
                                  <label>
                                    <Text variant="headingMd" as="h6">
                                      Eligible
                                    </Text>
                                  </label>
                                  <InlineStack align="center" blockAlign="center" gap={200}>
                                    <Badge progress="complete" tone={state?.smartDebuggerList?.eligible === "0" ? 'success' : 'critical'} >{state?.smartDebuggerList?.eligible === "0" ? 'Success' : 'Missing'}</Badge>
                                    <Button variant="monochromePlain" icon={RefreshIcon} onClick={() => props.refreshEligible()}></Button>
                                  </InlineStack>
                                </InlineStack>
                              </InlineStack>
                            </InlineStack>
                          </Box>
                          <BlockStack gap="400">
                            <Text variant="bodyMd" as="p" tone="subdued">
                              {state?.smartDebuggerList?.eligible === "0" ? 'Everything looks perfect!' : 'As per data source setting, looks like there is no data available for this notification right now.'}
                            </Text>
                          </BlockStack>
                        </BlockStack>
                      </Box>
                    </BlockStack>
                  </Card>
                  }

                  {
                    ((isValidPixel == true && state?.smartDebuggerList?.pixel !== '0') ||
                      (isValidDisplay == true && state?.smartDebuggerList?.displayPage !== '0' && state?.smartDebuggerList?.displayPage !== '2') ||
                      (isValidSource == true && state?.smartDebuggerList?.sourceList !== '0') ||
                      (isValidEligible == true && state?.smartDebuggerList?.eligible !== '0')) &&
                    localStorage.getItem('isbusr') == 'false' &&
                    <Text>Not working? talk with <Button variant="plain">live chat support team {'->'}</Button></Text>
                  }
                </>}  </BlockStack>
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  );
}

export default Debugger;