import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/setting';
import { toastify } from '../ducks/toast';

function* getSetting(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `/getSetting?istm=${action.payload}`);

    if (res.status === 'success') {
      yield put(actions.getSettingSuccess(res));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getSettingById(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `/getSettingById?id=${action.payload}`);

    if (res.status === 'success') {
      yield put(actions.getSettingByIdSuccess(res));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateSetting(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateSetting', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetSetting() {
  yield takeLatest(actions.getSetting.type, getSetting);
}

export function* watchGetSettingById() {
  yield takeLatest(actions.getSettingById.type, getSettingById);
}

export function* watchUpdateSetting() {
  yield takeLatest(actions.updateSetting.type, updateSetting);
}
export default function* rootSaga() {
  yield fork(watchGetSetting);
  yield fork(watchGetSettingById);
  yield fork(watchUpdateSetting);
}


