import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, TextField, Tooltip, Banner, Icon, Modal, Divider, PageActions, OptionList, Popover } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ClipboardIcon, ChevronDownIcon, RefreshIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

function AweberCard(props) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props.props;
  const navigate = useNavigate();

  const [value, setValue] = useState({
    intDataList: [],
    ac: '',
    rmk: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    selected: [],
    activePopover: null,
    delSubTyp: false,
    delSubTypId: '',
    modalReidr: false,
    notifListData: []
  });
  let arryOption = [];
  let allOptionList = [];

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.userIntData);

  useEffect(() => {
    changeValue({ intDataList })
  }, [intDataList])

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updIntIsActive(obj))
    const filterData = value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  const handleDeleteData = (id, delSub, delSubId) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data?.length > 0) {
      changeValue({ modalReidr: true, notifListData: data })
    }
    else {
      changeValue({ modalReidr: false })
    }
    openCloseModal('deleteModal', value.deleteModal, 'open');
    changeValue({ dataId: id, delSubTyp: delSub, delSubTypId: delSubId });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    if (value.delSubTyp === false) {
      dispatch(integrationDucks.deleteAweber(id));
    }
    else {
      const obj = {
        ld: value.delSubTypId,
        _id: id
      }
      dispatch(integrationDucks.deleteList(obj));
    }
    openCloseModal('deleteModal', value.deleteModal, 'close');
  }

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  const handleCreateBtn = () => {
    if (props.validator.errorMessages.ac || props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      const obj = {
        ac: value.ac,
        rmk: value.rmk
      };
      dispatch(integrationDucks.createAweber(obj));
      changeValue({ ac: '', rmk: '', errMessage: {} });
    }
  }

  //copy endpoint
  const copyEndpoint = (url) => {
    copy(url, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Endpoint copied" }));
    return;
  }

  //sync data
  const syncAweberList = (id) => {
    dispatch(integrationDucks.syncAweberList(id));
  }

  //handle toggle
  const handleToggle = (id) => {
    changeValue({ activePopover: value.activePopover === id ? null : id });
  };

  //handle change select list
  const handleChange = (data, id) => {
    if (data !== '') {
      const findOption = allOptionList.find((elm) => elm.name == data);
      const obj = {
        ld: findOption.id,
        ln: findOption.name,
        _id: id
      }
      dispatch(integrationDucks.createAweberList(obj));
    }
    changeValue({ activePopover: null });
  };

  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => {
      let wbURL = usInt.d && usInt.d.webURL;
      if (props.isWhite === true) {
        wbURL = wbURL.replace('https://is.wisernotify.com', window.location.origin);
      }
      let optionsList = usInt.d?.aweberList?.length > 0 &&
        usInt?.d.aweberList.filter(x => !x.ia)?.length > 0 ? usInt.d?.aweberList.filter(x => !x.ia).map((obj, j) => arryOption.push({ label: obj.name, value: obj.name }))
        : arryOption.push({ label: 'No record found', value: '' });
      allOptionList = usInt.d.aweberList;

      return (<>
        <IndexTable.Row
          id={usInt._id}
          key={index}
          position={index}
        >
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {index + 1}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{usInt.d && usInt.d.accountID}</IndexTable.Cell>
          <IndexTable.Cell>{usInt.rmk}</IndexTable.Cell>
          <IndexTable.Cell>{wbURL?.slice(0, 30) + '...'}</IndexTable.Cell>
          <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
          <IndexTable.Cell> <label className="switch">
            <input type="checkbox" checked={usInt.ia}
              onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
            <span className="switch-label"></span>
            <span className="switch-handle"></span>
          </label></IndexTable.Cell>

          <IndexTable.Cell>
            <InlineStack blockAlign='center'>
              {/*copy btn */}
              <Box paddingInline={200}>
                <div id="delete" onClick={() => copyEndpoint(wbURL)}>
                  <Banner hideIcon tone='info'>
                    <Icon
                      source={ClipboardIcon}
                      tone="info"
                    />
                  </Banner>
                </div>
              </Box>

              {/* syncAweber btn */}
              <Box paddingInline={100}><Tooltip content="Sync latest list" padding='400' width='wide' dismissOnMouseOut> <Button icon={RefreshIcon} variant='monochromePlain' onClick={() => syncAweberList(usInt._id)}></Button></Tooltip></Box>

              {/* option list */}
              <Box paddingInline={200}>
                <Popover
                  active={value.activePopover === index}
                  activator={
                    <Button onClick={() => {
                      if (arryOption.length !== 0) {
                        handleToggle(index);
                      }
                    }}
                    >
                      <InlineStack>
                        <div className='add-events'>Add events</div>
                        <Icon
                          source={ChevronDownIcon}
                          tone="base"
                        />
                      </InlineStack>
                    </Button>
                  }
                  onClose={() => changeValue({ activePopover: null })}
                >
                  <OptionList
                    onChange={(selected) => handleChange(selected[0], usInt._id)}
                    options={arryOption}
                    selected={value.selected}
                  />
                </Popover>
              </Box>

              {/* delete btn */}
              {props.checkPermission('integration', 'isdelete', props.profile) &&
                <div id="delete" onClick={() => handleDeleteData(usInt._id, false)}>
                  <Banner hideIcon tone='critical'>
                    <Icon
                      source={DeleteIcon}
                      tone="critical"
                    />
                  </Banner>
                </div>
              }
            </InlineStack>
          </IndexTable.Cell>
        </IndexTable.Row>
        {usInt && usInt?.d && usInt?.d?.aweberList?.length > 0 &&
          usInt.d.aweberList.filter(x => x.ia === true)?.length > 0 &&
          <IndexTable.Row >
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="bold" as="span">
                #
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="bold" as="span">List ID</Text>
            </IndexTable.Cell>
            <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">List Name</Text></IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell>
            </IndexTable.Cell>
          </IndexTable.Row>}

        {usInt && usInt?.d && usInt.d?.aweberList?.length > 0 &&
          usInt.d?.aweberList.filter(x => x.ia === true)?.length > 0 ? usInt?.d?.aweberList.filter(x => x.ia === true)?.map((AWObj, i) => (
            <IndexTable.Row >
              <IndexTable.Cell></IndexTable.Cell>
              <IndexTable.Cell>
                <Text>
                  {i + 1}
                </Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text as="h6">{AWObj.id}</Text>
              </IndexTable.Cell>
              <IndexTable.Cell>{AWObj.name}</IndexTable.Cell>
              <IndexTable.Cell></IndexTable.Cell>
              <IndexTable.Cell></IndexTable.Cell>
              <IndexTable.Cell>
                {props.checkPermission('integration', 'isdelete', props.profile) &&
                  <div id="delete" onClick={() => handleDeleteData(usInt._id, true, AWObj.id)}>
                    <Banner hideIcon tone="critical">
                      <Icon source={DeleteIcon} tone="critical" />
                    </Banner>
                  </div>
                }
              </IndexTable.Cell>
            </IndexTable.Row >
          )) :
          <IndexTable.Row>No record found</IndexTable.Row>
        }
      </>
      )
    },
  );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How To Connect</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <BlockStack gap={200}>
                <div className='form-width'>
                  {props.checkPermission('integration', 'isadd', props.profile) &&
                    <div className='form-login-btn'><Box align='end' paddingBlockEnd={400}><Button id='login-btn-bg' variant='primary' onClick={() => { fnRedirect(process.env.REACT_APP_AWEBER_AUTH_URL); }}>Login with AWeber</Button></Box></div>
                  }
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.ac} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Access Code</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.ac}
                        onChange={(e) => changeValue({ ac: e })}
                        placeholder='Access Code'
                        autoComplete="off"
                        error={value.errMessage.ac ? "The Access Code field is required." : false}
                      />
                      {props.validator.message('ac', value.ac, 'required')}
                    </div>

                  </InlineStack>
                </div>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name Your Connection</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.rmk}
                        onChange={(e) => changeValue({ rmk: e })}
                        placeholder='Name Your Connection'
                        autoComplete="off"
                        error={value.errMessage.rmk ? "The Name Your Connection field is required." : false}
                      />
                      {props.validator.message('rmk', value.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </BlockStack>
              <Box align='center' padding={300}><Button variant="primary" onClick={() => handleCreateBtn()}>Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box><Text variant="headingSm" as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: 'No' },
                  { title: 'Account ID' },
                  { title: 'Name' },
                  { title: 'Endpoint' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {value.modalReidr === false ?
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                onAction: () => {
                  handleDeleteCall(value.dataId);
                }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal> :
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">Kindly disable this data-source from <b><i>{value?.notifListData[0]?.data[0]}</i></b> Notification first & after that, you can delete it.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                content: 'Go to notification', tone: 'success',
                onAction: () => { navigate('/notification?ds=list'); openCloseModal('deleteModal', value.deleteModal, 'close'); }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default AweberCard;