import React from 'react';
import { ActionList, Badge, BlockStack, Box, Button, ButtonGroup, Card, Icon, IndexTable, InlineGrid, InlineStack, Modal, Page, PageActions, Popover, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Text, TextField, Tooltip } from '@shopify/polaris';
import Parser from 'html-react-parser';
import DesignCard from '../NotifEdit/DesignCard';
import { CheckIcon, ClockIcon, DeleteIcon, DomainLandingPageIcon, EditIcon, MenuHorizontalIcon, PlusIcon, XIcon } from '@shopify/polaris-icons';
import { checkPermission } from '../../services/permissions';
import moment from 'moment';
import { notif_empty } from '../../img';

function AnnouncementCard(props) {
  const { state, changeNameValue, openCloseModal } = props;

  const wordSort = (word) => {
    if (word !== undefined && word !== '' && word !== null) {
      const wrd = word.split('');
      wrd.splice(21);
      let joindt = wrd.join('');
      return joindt;
    }
  }

  const handleToggle = (id) => {
    changeNameValue({ activePopover: state.activePopover === id ? null : id })
  };

  const handleLink = (url) => {
    window.open(`https://${url}`, '_blank');
  }

  const renderListHTML = (state, data) => {
    return Parser(DesignCard(state, data, 'listCard'));
  }

  return (
    <>
      {(state.userNotifList === '') || props.isLoading ?
        <Page>
          <Box paddingBlock={400}>
            <SkeletonPage primaryAction>
              <BlockStack gap={300}>
                <Card>
                  <SkeletonBodyText lines={1} />
                </Card>
                <Card>
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </BlockStack>
                </Card>
                <Card>
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </BlockStack>
                </Card>
              </BlockStack>
            </SkeletonPage>
          </Box>
        </Page>
        :
        <Page title={`Manage ${state.notifTyp === 'timer' ? 'countdown timer' : state.notifTyp === 'announcement' ? 'announcement' : 'call to actions'} notifications`}
          backAction={{
            onAction: () => {
              state.anncmtAllTempltShow
                ? changeNameValue({ anncmtAllTempltShow: false })
                : props.handleBackToMain()
            }
          }}
          primaryAction={!state.anncmtAllTempltShow && checkPermission('notification', 'isadd', state.profile) && <Button variant="primary" icon={PlusIcon} onClick={() => changeNameValue({ anncmtAllTempltShow: true, editNotifNames: [] })}>Add</Button>}
        >
          <Box paddingBlockEnd={400}>
            <BlockStack gap={400}>
              {/* <Box>
            {!state.anncmtAllTempltShow &&
              <InlineStack align='end'>
                {checkPermission('notification', 'isadd', state.profile) &&
                  <Button variant="primary"  icon={PlusIcon} onClick={() => changeNameValue({ anncmtAllTempltShow: true, editNotifNames: [] })}>Add notification</Button>
                }
              </InlineStack>
            }
          </Box> */}
              {state.anncmtAllTempltShow ?
                <Card>
                  <Box paddingBlockEnd={400}>
                    <Text variant='headingMd' as='p' fontWeight='semibold' alignment='center'>Select widgets type</Text>
                  </Box>
                  <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
                    {state.widgetNotif && state.widgetNotif.map((data, i) => (
                      <div className='card_bg' key={i} id='cursorPointer' onClick={() => props.handleCreateNotif(data)}>
                        <div className='noti_img_block'>
                          <img className='noti_type_img' src={data.notif.iu} alt='notif' />
                        </div>
                        <Box paddingBlockStart={200}>
                          <Text variant='headingXs' as='p' fontWeight='semibold'>{data.notif.tn}</Text>
                        </Box>
                        <Box paddingBlockStart={400}>
                          <Button>Add</Button>
                        </Box>
                      </div>
                    ))}
                  </InlineGrid>
                </Card>
                :
                state.userNotifList && state.userNotifList.length > 0 ?
                  <Box id='nTfEdit_tbl'>
                    <Card padding={0}>
                      <IndexTable
                        itemCount={state.userNotifList ? state.userNotifList.length : 0}
                        selectable={false}
                        headings={[
                          { title: 'Preview' },
                          { title: 'Name' },
                          { title: 'Website' },
                          { title: 'Status' },
                          { title: 'Change' },
                          { title: 'Actions' }
                        ]}
                        pagination={{
                          hasPrevious: state.paggiActive > 1 ? true : false,
                          hasNext: (state.paggiActive < state.pageNumber) ? true : false,
                          onPrevious: () => props.onPaggiCall('-1'),
                          onNext: () => props.onPaggiCall('+1')
                        }} >

                        {state.userNotifList && state.userNotifList.length > 0 && state.userNotifList.map((data, index) => {
                          //for title bar
                          if (data.nt === 'title_bar' && data.cnf.tltyp) {
                            setTimeout(() => {
                              if (document.getElementById('TitleTxt_' + index)) {
                                document.getElementById('TitleTxt_' + index).innerHTML = data.cnf.fl.txt;
                                document.getElementById('ImgURL_' + index).setAttribute('src', data.cnf.fliu);
                              }
                            }, 1000);
                          }
                          return (
                            <IndexTable.Row
                              id={data._id}
                              key={index}
                              position={index}
                              onClick={() => changeNameValue({})}  //for whole row click none
                            >
                              <IndexTable.Cell id='nTf_wrap'>
                                <div className='nTf_hTMl'>
                                  {data.nt === 'title_bar' ?
                                    <div className="FDGdflt26_WeBTitLeBar_fdhKMN FDGdflt26_WeBTitLeBar_TiTlEbaRTem01">
                                      <div className="FDGdflt26_ToPhEaDer_FirstBlock">
                                        <div className="FDGdflt26_WeBTitLeBar_BlocK">
                                          <div className="FDGdflt26_WeBTitLeBar_imG">
                                            <img id={`ImgURL_${index}`} alt='' />
                                          </div>
                                          <div className="FDGdflt26_WeBTitLeBar_LefTBlock">
                                            <div className="FDGdflt26_WeBTitLeBar_TexT" id={`TitleTxt_${index}`}>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    data.nt === 'video' ? //video notification
                                      <div className="notilist-notivideo">
                                        {renderListHTML(state, data)}
                                      </div>
                                      :
                                      data.nt === 'call_to_action' ? //call_to_action notification
                                        <div className="notilist-callback">
                                          {renderListHTML(state, data)}
                                        </div>
                                        :
                                        data.nt === 'collector' ?
                                          <div className="notilist-promotional">
                                            {renderListHTML(state, data)}
                                          </div>
                                          :
                                          data.nt === 'social_media_followers' ?
                                            <div className="notilist-promotional">
                                              {renderListHTML(state, data)}
                                            </div>
                                            :
                                            data.nt === 'all_in_one' ? //All in one
                                              <div className="notilist_allinone">
                                                {renderListHTML(state, data)}
                                              </div>
                                              :
                                              renderListHTML(state, data)
                                  }
                                </div>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <Text id='txt_limit' variant="headingXs" fontWeight="semibold" as="p">{data.rttl}</Text>
                                <Box paddingBlockStart={200} paddingBlockEnd={200}>
                                  <BlockStack gap="200" align="start">
                                    {state.editNotifNames[index]?.isEdit ?
                                      <TextField
                                        placeholder='Notification name'
                                        value={state.editNotifNames[index]?.nn}
                                        onChange={(value) => props.handleTextChange(index, value)}
                                        autoComplete="off"
                                        connectedRight={
                                          <ButtonGroup>
                                            <Button icon={CheckIcon} onClick={() => props.handleUpdateNotifName(index, data)}></Button>
                                            <Button icon={XIcon} onClick={() => props.handleEditNotifNameClose(index)}></Button>
                                          </ButtonGroup>
                                        }
                                      />
                                      :
                                      <div className='edit-notiname-block'>
                                        <InlineStack gap={200} blockAlign='center'>
                                          <Text variant="headingMd" as="h6" fontWeight="medium" >
                                            {data.nn}
                                          </Text>
                                          <Tooltip dismissOnMouseOut content={data.nn}>
                                            <div className='edit-notiname-icon' onClick={() => props.handleEditNotifNameShow(index, { isEdit: true, nn: data.nn })}>
                                              <Icon source={EditIcon} />
                                            </div>
                                          </Tooltip>
                                        </InlineStack>
                                      </div>
                                    }
                                    <Box>
                                      <Badge>{data.mnn}</Badge>
                                    </Box>
                                    <div style={{ padding: '0 120px 0 0' }}>
                                      <Tooltip dismissOnMouseOut content={<BlockStack><Text>Created on: {moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</Text><Text>Last modified: {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY hh:mm:ss a')}</Text></BlockStack>} width='wide'>
                                        <Text variant="bodyXs" tone='subdued' fontWeight="medium" as="p" >
                                          {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY')}
                                        </Text>
                                      </Tooltip>
                                    </div>
                                  </BlockStack>
                                </Box>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <Box>
                                  <ButtonGroup>
                                    <Tooltip dismissOnMouseOut content={data?.dtu?.length > 0 ? data?.dtu.map((elm, index) => { return <Text key={index}>{elm.dt == 1 ? 'Equals' : elm.dt == 2 ? 'Contains' : elm.dt == 3 ? 'Regex' : elm.dt == 4 ? 'Does not equal' : elm.dt == 5 ? 'Does not contain' : elm.dt == 6 ? 'Does not Regex' : ''} : {data.dtu[index]?.ul}</Text> }) : <Text>Display notification on all pages</Text>} width='100px'>
                                      <div className='iconRound'>
                                        <Icon source={DomainLandingPageIcon} tone="info" />
                                      </div>
                                    </Tooltip>
                                    <Tooltip dismissOnMouseOut content={data.cnf.ihdwn == true ? data.cnf.dtrg == 1 ? <BlockStack><Text>Display rule : Exit intent </Text><Text>Hide rule : Hide after {data.cnf.hdwn} second</Text></BlockStack> :
                                      data.cnf.dtrg == 2 ? <BlockStack><Text>Display rule : Show after {data.cnf.aft} second</Text><Text>Hide rule : Hide after {data.cnf.hdwn} second</Text></BlockStack> :
                                        data.cnf.dtrg == 3 ? <BlockStack><Text>Display rule : Show after {data.cnf.sprc}% scroll</Text><Text>Hide rule : Hide after {data.cnf.hdwn} second</Text></BlockStack> : '' :
                                      data.cnf.dtrg == 1 ? 'Display rule : Exit intent' :
                                        data.cnf.dtrg == 2 ? `Display rule : ${'Show after ' + data.cnf.aft + ' second'}` :
                                          data.cnf.dtrg == 3 ? `Display rule : ${' Show after ' + data.cnf.sprc + '% scroll'}` : ''}
                                      width='wide'>
                                      <div className='iconRound'>
                                        <Icon source={ClockIcon} tone="info" />
                                      </div>
                                    </Tooltip>
                                  </ButtonGroup>
                                  {data?.ddn.map((elm, index) => { return index < 3 ? <Text key={index}><Button variant='plain' onClick={() => handleLink(data.ddn[index].ul)}><Text tone="subdued">{wordSort(data.ddn[index].ul)}</Text></Button></Text> : '' })}
                                </Box>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <InlineStack gap={200} blockAlign='center'>
                                  <Badge size='medium' tone={data.ia ? 'success' : 'critical'}>
                                    <Text variant='headingXs' fontWeight='medium' as='span'>
                                      {data.ia ? 'On' : 'Off'}
                                    </Text>
                                  </Badge>
                                  <label className="switch">
                                    <input type="checkbox" checked={data.ia}
                                      onChange={() => props.handleUpdAnncmtNotifStatus(data)} />
                                    <span className="switch-label"></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </InlineStack>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <Button variant='primary' tone='success' onClick={() => props.handleEditNotif(data)}>Customize</Button>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <Button variant='primary' tone='critical' icon={DeleteIcon} onClick={() => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ notifData: data }); }}></Button>
                                {/* <Popover
                              active={state.activePopover === index}
                              activator={
                                <Button onClick={() => handleToggle(index)} icon={MenuHorizontalIcon}>
                                </Button>
                              }
                              onClose={() => changeNameValue({ activePopover: null })}
                            >
                              <ActionList
                                actionRole="menuitem"
                                items={[
                                  {
                                    content: 'A/B Test',
                                    onAction: () => { },
                                  },
                                  {
                                    content: 'Analytics',
                                    onAction: () => { },
                                  },
                                  {
                                    content: 'Clone',
                                    onAction: () => changeNameValue({ isClone: true, ccid: data._id, cnname: data.nn }),
                                  },
                                  {
                                    content: 'Details',
                                    onAction: () => { },
                                  },
                                  {
                                    content: (
                                      !data.ia ?
                                        <Tooltip dismissOnMouseOut content="This notification is OFF. Please turn it ON to diagnose." preferredPosition="below" width={100}>
                                          <span>Debugger</span>
                                        </Tooltip>
                                        : <span>Debugger</span>
                                    ),
                                    onAction: () => {
                                      if (data.ia) {
                                        props.handleDebugger(data);
                                      }
                                    },
                                    disabled: !data.ia,
                                  },
                                  {
                                    content: (<Button variant='plain' tone='critical'>Delete</Button>),
                                    onAction: () => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ notifData: data }); },
                                  },
                                ]}
                              />
                            </Popover> */}
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          );

                        })}
                      </IndexTable>
                    </Card>
                  </Box>
                  :
                  <Card>
                    <Box padding={400}>
                      <BlockStack gap={200} align='center' inlineAlign='center'>
                        <Box>
                          <img src={notif_empty} alt='notif' />
                        </Box>
                        <Text variant='headingLg' as='p' fontWeight='semibold'>Boost engagement with widgets</Text>
                        <Text variant='headingMd' as='p' fontWeight='regular'>Guide visitors to take action with offers, timers, buttons, and follower counters.</Text>
                        <Box paddingBlockStart={200}>
                          <ButtonGroup>
                            {checkPermission('notification', 'isadd', state.profile) &&
                              <Button variant='primary' onClick={() => changeNameValue({ anncmtAllTempltShow: true })}>Start creating widget</Button>
                            }
                          </ButtonGroup>
                        </Box>
                      </BlockStack>
                    </Box>
                  </Card>
              }
            </BlockStack>
          </Box>

          <Modal // delete notification
            size='small'
            open={state.deleteOpen}
            title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
            onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
            <Modal.Section>
              <div className="pageAction_paDDing">
                <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this notification?</Text>
                <PageActions
                  primaryAction={<Button variant='primary' tone='success' onClick={() => props.handleDelNotif(state.notifData)}>Yes</Button>}
                  secondaryActions={[{
                    content: 'No',
                    onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
                  }]} />
              </div>
            </Modal.Section>
          </Modal>
        </Page>
      }
    </>
  );
}

export default AnnouncementCard;