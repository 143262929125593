import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, BlockStack, IndexTable, Page, Text } from '@shopify/polaris';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import IntegrationHeader from './IntegrationHeader';
import moment from 'moment';

function Shift4ShopCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();

  //state data.
  const [info, setInfo] = useState({
    shift4Shop: [], //shift4Shop data
    idForUsInt: '',
    isIntActive: false
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api data.
  const userIntData = useSelector(state => state.integration.userIntData);

  //set shift4Shop data.
  useEffect(() => {
    changeNameValueCard({ shift4Shop: userIntData });
  }, [userIntData]);

  //redirect to the url.
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect.
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update api call (toggle button).
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.shift4Shop.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ shift4Shop: updatedList, idForUsInt: obj.id, isIntActive: obj.ia });
  };

  //login With3dCart.
  const loginWith3dCart = () => {
    const authURL = `https://apirest.3dcart.com/oauth/authorize?client_id=a2895324da2b8af5a6a22ac7d4366dff&redirect_uri=${process.env.REACT_APP_INT_API_URL}/3dcart/oauth&state=${props.profile.ti}&response_type=code`;
    window.open(authURL, '_blank');
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>Shift4Shop</span></div>} subtitle={'Show live customers & buy orders'} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <BlockStack inlineAlign='end'>
              <Box>
                <Button onClick={() => loginWith3dCart()} variant="primary" tone="success">Login with shift4Shop</Button>
              </Box>
            </BlockStack>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.shift4Shop?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' }
                ]}
                selectable={false}
              >
                {info.shift4Shop && info.shift4Shop.length > 0 && info.shift4Shop.map((data, index) => {
                  if (data._id === info.idForUsInt) {
                    data.ia = info.isIntActive ? info.isIntActive : false;
                  }
                  return (
                    <IndexTable.Row key={index}>
                      <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                      <IndexTable.Cell><span className="text-break">{data.rmk}</span></IndexTable.Cell>
                      <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                      <IndexTable.Cell>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data.ia}
                            onChange={() => handleIsActive(data)}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  )
                })}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>
    </Page>
  )
}

export default Shift4ShopCard;