import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NotifShopifyCard } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as NotifShopifyDucks from '../../ducks/notifShopify';
import SimpleReactValidator from "simple-react-validator";
import copy from 'copy-to-clipboard';
import * as PixelDucks from '../../ducks/pixel';
import { toastify } from '../../ducks/toast';
import * as pricingPlanDucks from '../../ducks/pricingPlan';
import '../NotifEdit/NotifDesign.scss'; //for notiifcation design css
import '../NotifEdit/NotifEdit.css';
import './NotifShopify.css';
import { checkPermission } from '../../services/permissions';

const NotifShopify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intervalIdRef = useRef(null);
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    isFirstRender: true,
    profile: {},
    notifList: [],
    floatingNotifList: [],
    inlnPdpNotif: [],
    inlnCtgNotif: [],
    userNotifList: '',
    notifData: {},
    deleteOpen: false,
    isConfiguration: false,
    isInlineShow: false,
    notifTyp: '',
    installPdpShow: false,
    installCtgShow: false,
    mainTabPdpIndex: 'shopify',
    mainTabCtgIndex: 'shopify',
    codePdpLink: '',
    codeCtgLink: '',
    anncmtAllTemplt: [],
    anncmtAllTempltShow: false,
    allTemplate: [],
    selctdUl: '',
    notifDomain: [],
    listTyp: '',
    queryValue: '',
    paggiActive: 1,
    pageNumber: 0,
    pageSize: 20,
    nStatus: 'all',
    notiType: [],
    editNotifNames: [],
    wdTyp: '',
    isback: false,
    isEditnotif: false,
    notifPdpWebAdd: false,
    notifCtgWebAdd: false,
    ddn: [],
    ul: '',
    pdpUl: '',
    ctgUl: '',
    sugDomainList: [],
    stripeData: {},

    //clone
    isClone: false,
    ccid: '',//customer clone id
    cnname: '',//current notif name
    csub: '',
    istm: false,
    cloneNotifName: '',
    errMessage: {},

    //Debugger
    isDebugger: false,
    smartDebuggerList: '',
    cnmnn: '',
    cndid: '',

    paggiActiveUserNotif: 1,
    isUserNotifDataModal: false,
    userNotifSeenId: '',
    userNotifSeenName: '',
    isSeenLoading: false,

    //Check pixel install
    pixelOpen: false,
    webUrl: '',
    isPending: false,
    searchUserNotifUrl: '',
    userNotifId: '',
    userNotifName: ''
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    //api call notification.
    if (state.isFirstRender) {
      dispatch(NotifShopifyDucks.getNotifShopify());
      changeNameValue({ isFirstRender: false }); // Update the flag after the first API call
    }

    //api call notification by user.
    const obj = {
      domain: state.notifDomain,
      listTyp: state.listTyp,
      name: state.queryValue,
      num: state.paggiActive,
      pagesize: state.pageSize,
      status: state.nStatus,
      type: state.notiType
    }
    if (state.listTyp === 'shopify_widget') {
      obj['wdTyp'] = state.wdTyp
    }
    global.filterUserNotif = obj;
    dispatch(NotifShopifyDucks.getNotifByUser(obj));
    dispatch(NotifShopifyDucks.getNotifActivityByUser(obj));

  }, [state.notifDomain, state.listTyp, state.queryValue, state.paggiActive, state.pageSize, state.nStatus, state.notiType]);

  //api data
  const notifyState = useSelector(state => state.notifShopify.notifShopify);
  const notifyUserState = useSelector(state => state.notifShopify.notifByUser);
  const notifHeight = useSelector(state => state.notifShopify.notifHeight);
  const profileState = useSelector(state => state.auth.profile);
  const smartDebuggerList = useSelector(state => state.notifShopify.smartDebuggerList);
  const resCreateNotif = useSelector(state => state.notifShopify.resCreateNotif);
  const notifPixel = useSelector(state => state.notif.notifPixel);
  const isLoading = useSelector(state => state.loading.isLoading);
  const activeStatus = useSelector(state => state.notifShopify.activeStatus);
  const getUserNotifData = useSelector(state => state.notifShopify.getUserNotifData);
  const notifActivity = useSelector(state => state.notifShopify.notifActivityByUser);
  const userVerify = useSelector(state => state.pixel.userPixelVerify);
  const stripeData = useSelector(state => state.pricingPlan.stripeData);

  //set variable api data.
  let TotalData = notifyUserState?.total || 0;

  //set notifHeight data.
  useEffect(() => {
    if (notifHeight && notifHeight !== undefined && notifHeight !== state.notifHeight) {
      changeNameValue({ notifHeight });
    }
    if (notifPixel && notifPixel.length > 0) {
      let domainName = notifPixel;
      let domainList = [];
      if (domainName && domainName.length > 0) {
        domainName.map(domain => {
          domainList.push({
            'name': domain
          });
        });
        changeNameValue({ sugDomainList: domainList });
      }
    }
  }, [notifHeight, state.notifHeight, notifPixel]);

  useEffect(() => {
    changeNameValue({ notifActivity, getUserNotifData: getUserNotifData?.data, isSeenLoading: false })
  }, [notifActivity, getUserNotifData])

  //redirect edit notif create notification.
  useEffect(() => {
    if (resCreateNotif && resCreateNotif.nti && resCreateNotif._id && resCreateNotif.ntt && state.isEditnotif) {
      let url = `/notifEdit?nti=${resCreateNotif.nti}&id=${resCreateNotif._id}&ntt=${resCreateNotif.ntt}`;

      if (state.listTyp === 'inlinelist') {
        url += '&ntyp=inlinenudge';
      }
      else if (state.listTyp === 'shopify_widget') {
        url += '&ntyp=widget';
      }
      navigate(url);
      dispatch(NotifShopifyDucks.notifClear());
    }
  }, [resCreateNotif, state.isEditnotif, state.listTyp, navigate]);

  useEffect(() => {
    //set list of user notification data.
    if (notifyUserState && notifyUserState?.list) {
      changeNameValue({ userNotifList: notifyUserState?.list });
    }
    //set profile state data.
    if (profileState) {
      changeNameValue({ profile: profileState });
    }
    //set stripe state data.
    if (stripeData) {
      changeNameValue({ stripeData });
    }
  }, [notifyUserState, profileState, stripeData]);

  //redirect stripe payment url.
  useEffect(() => {
    if (state.stripeData && state.stripeData.url) {
      window.location.href = state.stripeData.url;
    }
  }, [state.stripeData]);

  //filter notification by nt.
  const filterArr = (key, values) => notifyState?.notifType.filter(item => values.includes(item[key]));

  //filter notification And Separate By Ul.
  const filterAndSeparateByUl = (notifArr, userNotifList) => {
    const notifIds = notifArr?.map(item => item._id);
    const filteredNotifList = userNotifList.filter(item => notifIds.includes(item.nti));

    const separatedByUl = filteredNotifList.reduce((acc, val) => {
      const ul = val.ddn[0]?.ul;
      if (!acc[ul]) acc[ul] = [];
      acc[ul].push(val);
      return acc;
    }, {});

    return Object.keys(separatedByUl).map(ul => ({ [ul]: separatedByUl[ul] }));
  };

  useEffect(() => {
    if (notifyState?.notifType) {
      const { notifType, allTemplate } = notifyState;
      //set floating notification and allTemplate data.
      changeNameValue({
        notifList: notifType,
        allTemplate,
        floatingNotifList: filterArr('nt', ['purchase_recent', 'purchase_bulk', 'review_recent', 'live_visitor', 'limited_stock']),
      });

      //set inline notification data.
      if (state.userNotifList) {
        const pdpNotifArr = filterArr('dsplct', ['ecomm_snpt_pdp']);
        const ctgNotifArr = filterArr('dsplct', ['ecomm_snpt_catelog']);

        const inlnPdpNotif = filterAndSeparateByUl(pdpNotifArr, state.userNotifList);
        const inlnCtgNotif = filterAndSeparateByUl(ctgNotifArr, state.userNotifList);
        changeNameValue({ inlnPdpNotif, inlnCtgNotif });
      }

      //set widget notification data.
      let _arr = [];
      let _type = state.notifWidgetCtg;

      if (notifyState) {
        notifyState.notifType.forEach(x => {
          if (state.notifWidgetCtg === 'all' && x.ctg === 2) {
            notifyState.allTemplate.forEach(z => {
              if (z.ni === x._id && z.ttn !== 'review_feed_1') {
                _arr.push({ notif: z, _id: x._id, typ: x.typ });
              }
            });
          } else if ((x.nc === state.notifWidgetCtg && x.ctg === 2) || (_type && _type.length > 1 && _type[1] && x.nc === _type[1])) {
            notifyState.allTemplate.forEach(z => {
              if (z.ni === x._id && z.ttn !== 'review_feed_1') {
                switch (state.notifWidgetTabTyp) {
                  case 1:
                    if (z.ttn === 'promotional_banner_timer') {
                      _arr.push({ notif: z, _id: x._id, typ: x.typ });
                    }
                    break;
                  case 2:
                    if (z.ttn !== 'social_media_followers') {
                      _arr.push({ notif: z, _id: x._id, typ: x.typ });
                    }
                    break;
                  case 3:
                    if (z.ttn !== 'promotional_banner_timer') {
                      _arr.push({ notif: z, _id: x._id, typ: x.typ });
                    }
                    break;
                  case 7:
                    if (z.ttn !== 'title_bar') {
                      _arr.push({ notif: z, _id: x._id, typ: x.typ });
                    }
                    break;
                  default:
                    _arr.push({ notif: z, _id: x._id, typ: x.typ });
                }
              }
            });
          }
        });
      }
      changeNameValue({ widgetNotif: _arr });
    }
  }, [notifyState, state.notifWidgetCtg, state.notifWidgetTabTyp, state.notifTyp, state.userNotifList]);

  //set isEdit floating notification data.
  useEffect(() => {
    if (state.userNotifList && state.floatingNotifList) {
      const updatedFloatingNotifList = state.floatingNotifList?.map(item => {
        const isEdit = state.userNotifList.some(val => item.nt === val.ntt);
        return {
          ...item,
          isEdit,
        };
      });

      const isChanges = state.floatingNotifList.some((item, index) => item.isEdit !== updatedFloatingNotifList[index].isEdit);

      if (isChanges) {
        changeNameValue({ floatingNotifList: updatedFloatingNotifList });
      }
    }
  }, [state.floatingNotifList, state.userNotifList]);

  //set TotalData.
  useEffect(() => {
    if (TotalData && TotalData !== '')
      changeNameValue({ pageNumber: parseInt(Math.ceil(TotalData / 20)) });
  }, [TotalData]);

  //handle pagination.
  const onPaggiCall = (i) => {
    let num = 0;
    if (i === '+1') {
      num = state.paggiActive + 1;
    } else if (i === '-1') {
      num = state.paggiActive - 1;
    } else {
      num = i;
    }
    changeNameValue({ 'paggiActive': num });
  }

  //handle create notification.
  const handleCreateNotif = (data) => {
    let obj = {
      nti: data._id,
      tmid: data.tmid,
      nn: data.nn,
      ddn: [{ "ul": localStorage.getItem('shopify_custom_domain') }],
      pvd: localStorage.getItem('pvd'),
      istm: localStorage.getItem('istm'),
      isbusr: localStorage.getItem('isbusr'),
      fn: state.profile.fn,
      bu: state.profile.brand ? state.profile.brand.bu : '',
      bn: state.profile.brand ? state.profile.brand.bn : 'WiserNotify',
      navigate
    }

    //create widget notification.
    if (state.listTyp === 'shopify_widget') {
      obj['nti'] = data.notif.ni
      obj['tmid'] = data.notif._id
      obj['nn'] = data.notif.tn
      obj['typ'] = 'widget'
    }
    dispatch(NotifShopifyDucks.createNotifShopify(obj));
    changeNameValue({ anncmtAllTempltShow: false, isEditnotif: true });
  }

  useEffect(() => {
    if (!activeStatus) return;

    const index = state?.userNotifList?.length > 0 && state.userNotifList.findIndex(x => x._id === activeStatus._id);
    if (index === -1) return;

    const updatedList = [...state?.userNotifList];
    updatedList[index] = { ...updatedList[index], ia: true };

    changeNameValue({ userNotifList: updatedList });

    setTimeout(() => {
      updatedList[index] = {
        ...updatedList[index],
        ia: false,
        iafab: false,
        udt: new Date()
      };

      changeNameValue({ userNotifList: updatedList });

      dispatch(toastify({
        type: 'error',
        msg: `Free Plan is limited to only 1 active notification. Upgrade now`
      }));
    }, 1000);
  }, [activeStatus]);

  //handle update notification status.
  const handleUpdNotifStatus = (data) => {
    const updated = state.userNotifList?.map(notif =>
      notif.ntt === data.nt ? { ...notif, ia: !notif.ia } : notif
    );
    changeNameValue({ userNotifList: updated });

    const selectedNotif = state.userNotifList.find(val => data.nt === val.ntt);

    if (selectedNotif) {
      const obj = {
        _id: selectedNotif._id,
        ia: !selectedNotif.ia,
        iafab: selectedNotif.iafab,
        ntt: selectedNotif.ntt,
        togl: true,
        csub: state.profile.csub,
        fn: state.profile.fn,
      }
      dispatch(NotifShopifyDucks.updNotifStatus(obj));
    }
  }

  //handle delete notification.
  const handleDelNotif = (data) => {
    let obj = {
      id: data._id,
      fn: state.profile.fn
    }
    dispatch(NotifShopifyDucks.delNotification(obj));
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
    changeNameValue({ notifData: {} });
  }

  //open close modal.
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined || name === 'isUserNotifDataModal' || name === 'pixelOpen') { //stop closing on outside click 
      changeNameValue({ [name]: !value });
    }
  }, []);

  //handle copy code links.
  const handleCopyCode = (value) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: `Your link is copied.` }));
  }

  //handle install show close for product detail page.
  const handleInstallPdpShowClose = () => {
    let obj = {
      installPdpShow: !state.installPdpShow,
      mainTabPdpIndex: 'shopify',
      notifPdpWebAdd: false
    }
    changeNameValue(obj);
  }

  //handle install show close for collection page.
  const handleInstallCtgShowClose = () => {
    let obj = {
      installCtgShow: !state.installCtgShow,
      mainTabCtgIndex: 'shopify',
      notifCtgWebAdd: false
    }
    changeNameValue(obj);
  }

  //handle Back To Main.
  const handleBackToMain = () => {
    let obj = {
      notifTyp: '',
      isConfiguration: false,
      isInlineShow: false,
      installPdpShow: false,
      installCtgShow: false,
      notifPdpWebAdd: false,
      notifCtgWebAdd: false,
      anncmtAllTempltShow: false,
      mainTabPdpIndex: 'shopify',
      mainTabCtgIndex: 'shopify',
      notifWidgetCtg: '',
      notifWidgetTabTyp: '',
      listTyp: '',
      editNotifNames: [],
      wdTyp: '',
      isback: true
    }
    changeNameValue(obj);
  }

  //useEffect hook for smooth scrolling.
  useEffect(() => {
    if (state.isback) {
      const announcementElement = document.getElementById('anncmt_notif');
      if (announcementElement) {
        setTimeout(() => {
          announcementElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          changeNameValue({ isback: false });
        }, 0);
      }
    }
  }, [state.isback]);

  //handle manage inline notification.
  const handleManageInlnNotif = () => {
    let obj = {
      isConfiguration: true,
      isInlineShow: true,
      listTyp: 'inlinelist'
    }
    changeNameValue(obj);
    dispatch(NotifShopifyDucks.getNotiHght());
  }

  //domain options.
  const deselectedOptions = useMemo(
    () => state.sugDomainList.map(domain => ({
      value: domain.name,
      label: domain.name,
    })),
    [state.sugDomainList],
  );

  //handleSugtInlnPdpTxtChange.
  const handleSugtInlnPdpTxtChange = useCallback((value) => {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp('^' + escapedValue, 'i');

    const resultOptions = deselectedOptions.filter((option) =>
      option.label.match(regex) // Match only the beginning of the string
    );

    // Update state with input value and filtered options
    changeNameValue({ pdpUl: value, options: resultOptions });
  }, [deselectedOptions]);

  //handleSugtInlnPdpOptChange.
  const handleSugtInlnPdpOptChange = (selected) => {
    const selectedOption = deselectedOptions.find(option => option.value === selected[0]);
    if (selectedOption) {
      changeNameValue({ pdpUl: selectedOption.label });
    }
  };

  //handleSugtInlnCtgTxtChange.
  const handleSugtInlnCtgTxtChange = useCallback((value) => {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp('^' + escapedValue, 'i');

    const resultOptions = deselectedOptions.filter((option) =>
      option.label.match(regex) // Match only the beginning of the string
    );

    // Update state with input value and filtered options
    changeNameValue({ ctgUl: value, options: resultOptions });
  }, [deselectedOptions]);

  //handleSugtInlnCtgOptChange.
  const handleSugtInlnCtgOptChange = (selected) => {
    const selectedOption = deselectedOptions.find(option => option.value === selected[0]);
    if (selectedOption) {
      changeNameValue({ ctgUl: selectedOption.label });
    }
  };

  //handle create inline notification.
  const handleCreateInlnNotif = (typ) => {
    const ul = (typ === 'detail') ? state.pdpUl : state.ctgUl
    const { ddn, inlnPdpNotif, inlnCtgNotif } = state;

    if (!ul.trim()) {
      dispatch(toastify({ type: 'error', msg: 'Enter your website URL(s)' }));
      return;
    }

    const isValidUrl = ul.split('.');
    if (isValidUrl.length < 2) {
      dispatch(toastify({ type: 'error', msg: 'Please enter a valid URL' }));
      return;
    }

    const newUl = ul.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];

    const checkNotifDomain = (notifList) => notifList?.some(notif => Object.values(notif)[0].some(val => val.ddn[0].ul === newUl));

    const isDomainExists = ddn.some(item => item.ul === newUl) ||
      (state.notifPdpWebAdd ? checkNotifDomain(inlnPdpNotif) : checkNotifDomain(inlnCtgNotif));

    if (isDomainExists) {
      dispatch(toastify({ type: 'error', msg: 'Domain already exists' }));
      return;
    }

    const updatedDdn = [...ddn, { ul: newUl }];
    changeNameValue({ ddn: updatedDdn });

    const obj = {
      ddn: updatedDdn,
      isbusr: localStorage.getItem('isbusr'),
      istm: localStorage.getItem('istm'),
      dt: '',
      dtu: '',
      typ: typ,
      fn: state.profile.fn,
      bu: state.profile.brand ? state.profile.brand.bu : '',
      bn: state.profile.brand ? state.profile.brand.bn : 'WiserNotify',
    };

    dispatch(NotifShopifyDucks.createInlineNotif(obj));
    changeNameValue({ ul: '', pdpUl: '', ctgUl: '', notifPdpWebAdd: false, notifCtgWebAdd: false, ddn: [] });
  }

  //handle update inline notification status.
  const handleUpdInlnNotifStatus = (data) => {
    const obj = {
      _id: data._id,
      ia: !data.ia,
      iafab: data.iafab,
      ntt: data.ntt,
      togl: true,
      csub: state.profile.csub,
      fn: state.profile.fn,
    }
    dispatch(NotifShopifyDucks.updNotifStatus(obj));

    const toggleIa = (notifs) =>
      notifs.map(notif => ({
        ...notif,
        [Object.keys(notif)[0]]: Object.values(notif)[0].map(val =>
          val._id === data._id ? { ...val, ia: !data.ia } : val
        )
      }));

    let stateObj = {
      inlnPdpNotif: toggleIa(state.inlnPdpNotif),
      inlnCtgNotif: toggleIa(state.inlnCtgNotif)
    }
    changeNameValue(stateObj);
  }

  //handle delete inline notification.
  const handleDelInlnNotif = () => {
    const { selctdUl, inlnPdpNotif, inlnCtgNotif, notifTyp } = state;

    const idArray = Object.values(notifTyp === 'detail' ? inlnPdpNotif : inlnCtgNotif).reduce((acc, item) => {
      selctdUl.forEach(key => {
        if (item[key]) {
          item[key].forEach(obj => {
            if (obj._id) {
              acc.push(obj._id);
            }
          });
        }
      });
      return acc;
    }, []);

    const obj = { idAry: idArray };
    dispatch(NotifShopifyDucks.deleteInlineNotif(obj));
    changeNameValue({ selctdUl: '' })
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
  }

  //handle manage announcement notification.
  const handleManageAnncmtNotif = (data, addNotif) => {
    let obj = {
      isConfiguration: true,
      notifTyp: data.typ,
      listTyp: 'shopify_widget',
      wdTyp: data.typ,
      userNotifList: ''
    }
    changeNameValue(obj);

    const widgetConfig = {
      timer: { notifWidgetCtg: 'cta', notifWidgetTabTyp: 1 },
      announcement: { notifWidgetCtg: 'custom', notifWidgetTabTyp: 2 },
      call_to_action: { notifWidgetCtg: 'cta', notifWidgetTabTyp: 3 },
    };
    if (widgetConfig[data.typ]) {
      changeNameValue(widgetConfig[data.typ]);
    }
    // show add notification.
    if (addNotif) {
      changeNameValue({ anncmtAllTempltShow: true });
    }
  }

  //handle update announcement notification status.
  const handleUpdAnncmtNotifStatus = (data) => {
    const obj = {
      _id: data._id,
      ia: !data.ia,
      iafab: data.iafab,
      ntt: data.ntt,
      togl: true,
      csub: state.profile.csub,
      fn: state.profile.fn,
    }
    dispatch(NotifShopifyDucks.updNotifStatus(obj));

    const updated = state.userNotifList?.map(notif =>
      notif._id === data._id ? { ...notif, ia: !notif.ia } : notif
    );
    changeNameValue({ userNotifList: updated });
  }

  //handle Edit Notification.
  const handleEditNotif = (data) => {
    const filterArr = state.userNotifList.find(val => data.nt === val.ntt);
    navigate('/notifEdit?nti=' + filterArr.nti + '&id=' + filterArr._id + '&ntt=' + filterArr.ntt + '&ntyp=inlinenudge');
  }

  //handle clone btn click to create clone.
  const handleCreateClone = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      const obj = {
        cId: state.ccid,//customer clone id
        csub: state.profile.csub,
        istm: false,
        nn: state.cloneNotifName,
      };
      dispatch(NotifShopifyDucks.createCloneNotif(obj));
      changeNameValue({ isClone: false, cloneNotifName: '' });
    }
  }

  //handle debugger btn click to Action.
  const handleDebugger = (data) => {
    changeNameValue({ isDebugger: true, cnname: data?.nn, cnmnn: data?.mnn, cndid: data?._id });
    dispatch(NotifShopifyDucks.smartDebugger(data?._id));
  }
  useEffect(() => {
    changeNameValue({ smartDebuggerList });
  }, [smartDebuggerList])

  //handle edit notification name show.
  const handleEditNotifNameShow = (index, newValues) => {
    const updated = {
      ...state.editNotifNames,
      [index]: { ...state.editNotifNames[index], ...newValues }
    }
    changeNameValue({ editNotifNames: updated });
  }

  //handle edit notification name close.
  const handleEditNotifNameClose = (index) => {
    const { [index]: _, ...remainingeditNotifNames } = state.editNotifNames;
    changeNameValue({ editNotifNames: remainingeditNotifNames });
  }

  //handle edit notification name text change.
  const handleTextChange = (index, value) => {
    const updated = {
      ...state.editNotifNames,
      [index]: { ...state.editNotifNames[index], nn: value },
    }
    changeNameValue({ editNotifNames: updated });
  }

  //handle edit notification name.
  const handleUpdateNotifName = (index, data) => {
    const newValue = state.editNotifNames[index]?.nn;
    if (!newValue) {
      return dispatch(toastify({ type: 'error', msg: 'Notification name is required' }));
    }

    if (data.nn !== newValue) {
      const updated = state.userNotifList.map(notif =>
        notif._id === data._id ? { ...notif, nn: newValue } : notif
      );
      changeNameValue({ userNotifList: updated });

      const obj = {
        nid: data._id,
        nn: newValue,
        fn: state.profile.fn,
      };

      dispatch(NotifShopifyDucks.updNotifName(obj));
    }
    handleEditNotifNameClose(index);
  }

  //openChat.
  const openChat = () => {
    if (window.Froged) {
      window.Froged('open');
    }
  };

  //handle getUserNotifData
  const handleGetUserNotifData = (id, notifName) => {
    if (id !== '') {
      const obj = {
        nid: id,
        pn: state.paggiActiveUserNotif,
        ps: 20,
        str: state.searchUserNotifUrl
      }
      dispatch(NotifShopifyDucks.getUserNotifData(obj));
      changeNameValue({ isUserNotifDataModal: true, userNotifSeenId: id, userNotifSeenName: notifName, isSeenLoading: true });

      if (window.ga) {
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', 'Notification list button', 'Last seen link', state?.profile?.e || '', { transport: 'beacon' });
          //for GA-4
          window.gtag('event', 'Notification list button', { 'event_action': 'Last seen link', 'event_label': state?.profile?.e || '' });
        } catch (error) { console.log(error); }
      }
    }
  }

  //handle close user Notif Data Modal
  const handleCloseUserNotifDataModal = (e) => {
    openCloseModal('isUserNotifDataModal', state.isUserNotifDataModal, e)
    changeNameValue({ userNotifSeenId: '', paggiActiveUserNotif: 1, getUserNotifData: undefined, searchUserNotifUrl: '' });
  }

  //handle pagination.
  const onPaggiCallUserNotif = (i) => {
    let num = 0;
    if (i === '+1') {
      num = state.paggiActiveUserNotif + 1;
    } else if (i === '-1') {
      num = state.paggiActiveUserNotif - 1;
    } else {
      num = i;
    }
    changeNameValue({ 'paggiActiveUserNotif': num });
  }

  useEffect(() => {
    handleGetUserNotifData(state.userNotifSeenId, state.userNotifSeenName);
  }, [state.paggiActiveUserNotif])

  useEffect(() => {
    changeNameValue({ paggiActiveUserNotif: 1 });
    let queryTimer = setTimeout(() => handleGetUserNotifData(state.userNotifSeenId, state.userNotifSeenName), 1000);
    return () => clearTimeout(queryTimer);
  }, [state.searchUserNotifUrl]);

  // pixel
  useEffect(() => {
    changeNameValue({ userPixelVerify: userVerify });
  }, [userVerify]);

  useEffect(() => {
    if (state.pixelOpen === true && state.userPixelVerify && state.userPixelVerify.isFound === true) {
      changeNameValue({ isPending: false })
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    if (state.pixelOpen === true && !state.userPixelVerify) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, [state.pixelOpen, state.webUrl, state.userPixelVerify])

  const isValidUrl = (url) => {
    const regex = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
    return regex.test(url);
  };

  const handleVerifyPixel = () => {
    let urlPixel = state.webUrl;

    if (!urlPixel.trim()) {
      dispatch(toastify({ type: 'error', msg: 'Please enter a URL' }));
      return;
    }

    if (!isValidUrl(urlPixel)) {
      dispatch(toastify({ type: 'error', msg: 'Invalid URL. Please enter a valid URL.' }));
      return;
    }

    const originalUrlPixel = urlPixel;

    let obj = { webUrl: originalUrlPixel };
    changeNameValue(obj);
    dispatch(PixelDucks.addUserSite(obj));
    dispatch(PixelDucks.getPixel());

    changeNameValue({ isPending: true });

    if (!state.pixelOpen) return;

    clearInterval(intervalIdRef.current);
    let count = 0;
    intervalIdRef.current = setInterval(() => {
      let objData = {
        urlPath: originalUrlPixel,
      };

      dispatch(PixelDucks.verifyUserPixelByURL(objData));

      count++;
      if (count >= 10) {
        clearInterval(intervalIdRef.current);
        changeNameValue({ isPending: false });
      }
      if (state.userPixelVerify && state.userPixelVerify.isFound === false) {
        clearInterval(intervalIdRef.current);
      }
    }, 5000);

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Troubleshoot popup', 'Verify pixel', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Troubleshoot popup', { 'event_action': 'Verify pixel', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  };

  const handleCloseInstallPixel = (e) => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    openCloseModal('pixelOpen', state.pixelOpen, e);
    changeNameValue({
      userPixelVerify: '',
      isPending: false
    })
  }

  const handleTroubleshoot = () => {
    let urlPixel = state.webUrl
    urlPixel += (urlPixel.includes('?') ? '&' : '?') + 'wn_trblst=true&wn_ntid=' + state.userNotifId;
    if (!urlPixel.includes('http://') && !urlPixel.includes('https://')) {
      window.open('http://' + urlPixel, '_blank');
    } else {
      window.open(urlPixel, '_blank');
    }

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Troubleshoot popup', 'Continue button', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Troubleshoot popup', { 'event_action': 'Continue button', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  }

  const handleAddInline = () => {
    if (checkPermission('notification', 'isadd', state.profile)) {
      let obj = {
        notifCtgWebAdd: true,
        installCtgShow: false
      }
      changeNameValue(obj);
    }
    else {
      dispatch(toastify({ type: 'error', msg: 'you have not permission to create notification!' }));
    }
  }

  const handleCreateStripeLink = () => {
    let obj = {
      email: state.profile.e,
      first_name: state.profile.fn,
    }
    dispatch(pricingPlanDucks.createStripeLink(obj));
  }

  return (
    <NotifShopifyCard
      state={state}
      validator={validator}
      isLoading={isLoading}
      onPaggiCall={onPaggiCall}
      changeNameValue={changeNameValue}
      handleCreateNotif={handleCreateNotif}
      handleUpdNotifStatus={handleUpdNotifStatus}
      handleDelNotif={handleDelNotif}
      openCloseModal={openCloseModal}
      handleCopyCode={handleCopyCode}
      handleInstallPdpShowClose={handleInstallPdpShowClose}
      handleInstallCtgShowClose={handleInstallCtgShowClose}
      handleBackToMain={handleBackToMain}
      handleManageInlnNotif={handleManageInlnNotif}
      handleSugtInlnPdpTxtChange={handleSugtInlnPdpTxtChange}
      handleSugtInlnPdpOptChange={handleSugtInlnPdpOptChange}
      handleSugtInlnCtgTxtChange={handleSugtInlnCtgTxtChange}
      handleSugtInlnCtgOptChange={handleSugtInlnCtgOptChange}
      handleCreateInlnNotif={handleCreateInlnNotif}
      handleUpdInlnNotifStatus={handleUpdInlnNotifStatus}
      handleDelInlnNotif={handleDelInlnNotif}
      handleManageAnncmtNotif={handleManageAnncmtNotif}
      handleUpdAnncmtNotifStatus={handleUpdAnncmtNotifStatus}
      handleEditNotif={handleEditNotif}
      handleCreateClone={handleCreateClone}
      handleDebugger={handleDebugger}
      openChat={openChat}
      handleEditNotifNameShow={handleEditNotifNameShow}
      handleEditNotifNameClose={handleEditNotifNameClose}
      handleTextChange={handleTextChange}
      handleUpdateNotifName={handleUpdateNotifName}
      handleGetUserNotifData={handleGetUserNotifData}
      handleCloseUserNotifDataModal={handleCloseUserNotifDataModal}
      onPaggiCallUserNotif={onPaggiCallUserNotif}
      handleVerifyPixel={handleVerifyPixel}
      handleCloseInstallPixel={handleCloseInstallPixel}
      handleTroubleshoot={handleTroubleshoot}
      handleAddInline={handleAddInline}
      handleCreateStripeLink={handleCreateStripeLink}
    />
  );
};

export default NotifShopify;