import React, { useState, useCallback, useEffect, } from "react";
import { BillingInvoiceCard } from "../../components";
import "./BillingInvoice.css";
import { useDispatch, useSelector } from "react-redux";
import * as billing from "../../ducks/billingInvoice";
import * as authDucks from '../../ducks/auth';

const BillingInvoice = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    billingInvoice: [],
    shopifyBill: [],
    isOpenChargebeePymFrm: false
  });

  //set data in state
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //get data from store
  const billingInvoice = useSelector(state => state.billingInvoice?.billingHistory);
  const chargebeePay = useSelector(state => state.billingInvoice?.mangePaymentSrc);
  const shopifyBill = useSelector(state => state.billingInvoice?.shopifyBill);
  const profile = useSelector(state => state.auth.profile);
  let isLoading = useSelector(state => state?.loading?.isLoading);

  //api call on page load
  useEffect(() => {
    if (localStorage.getItem('pvd') !== 'shopify') {
      dispatch(billing.getBillingInvoice());
      dispatch(billing.getUserCard());
    }
    else {
      dispatch(billing.getShopifyBill());
    }
    dispatch(authDucks.getProfile());
  }, [])

  //set data in state
  useEffect(() => {
    changeNameValue({ billingInvoice: billingInvoice, shopifyBill: shopifyBill });
  }, [billingInvoice, shopifyBill])

  //manage payment source
  const mangePaymentSrc = () => {
    dispatch(billing.mangePaymentSrc());
    changeNameValue({ isOpenChargebeePymFrm: true })
  }

  //download invoice
  const downloadInvoice = (id) => {
    let obj = {
      iv: id
    };
    dispatch(billing.downloadInvoice(obj));
  }

  useEffect(() => {
    if (chargebeePay && state.isOpenChargebeePymFrm === true) {
      if (chargebeePay) {
        var chargebeeInstance = window.Chargebee.getInstance();
        chargebeeInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve) {
              var sampleResponse = chargebeePay.hosted_page;
              resolve(sampleResponse);
            });
          },
        });
        changeNameValue({ isOpenChargebeePymFrm: false });
      }
    }
  }, [chargebeePay])

  return (
    <BillingInvoiceCard
      state={state}
      mangePaymentSrc={mangePaymentSrc}
      downloadInvoice={downloadInvoice}
      profile={profile}
      isLoading={isLoading}
    />
  );
};

export default BillingInvoice;
