import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const pixel = createSlice({
  name: 'pixel',
  initialState,
  reducers: {
    getPixel: (state, action) => { },
    getPixelSuccess: (state, action) => {
      return { ...state, pixelData: action.payload };
    },
    updateDelay: (state, action) => { },
    getPlatformInt: (state, action) => { },
    getPlatformIntSuccess: (state, action) => {
      return { ...state, pixelPlatform: action.payload };
    },
    addUserSite: (state, action) => { },
    verifyUserPixel: (state, action) => { },
    verifyUserPixelSuccess: (state, action) => {
      return { ...state, userPixelVerify: action.payload };
    },
    verifyUserPixelByURL: (state, action) => { },
    verifyUserPixelByURLSuccess: (state, action) => {
      return { ...state, userPixelVerify: action.payload };
    }
  }
});

export const {
  getPixel,
  getPixelSuccess,
  updateDelay,
  getPlatformInt,
  getPlatformIntSuccess,
  addUserSite,
  verifyUserPixel,
  verifyUserPixelSuccess,
  verifyUserPixelByURL,
  verifyUserPixelByURLSuccess
} = pixel.actions;

export default pixel.reducer;
