import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import * as actions from '../ducks/header';
// import { load, loaded } from '../ducks/loading';
import { toastify } from '../ducks/toast';

function* getActivity() {
  try {
    const res = yield call(api.GET, '/getUserActivity');
    if (res.status === 'success') {
      yield put(actions.getActivitySuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get user activity' }));
    }
  } catch (error) {
    console.log(error, 'getActivity==>');
  }
}

function* updateUserClick(action) {
  try {
    const res = yield call(api.POST, '/updUserClick', action.payload);

    if (res.status === 'success') {
      yield put(actions.getActivity());
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update activity notification' }));
    }
  } catch (error) {
    console.log(error, 'updateUserClick==>');
  }
}

function* updUserView() {
  try {
    const res = yield call(api.GET, '/updUserView');

    if (res.status === 'success') {
      yield put(actions.getActivity());
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update activity notification' }));
    }
  } catch (error) {
    console.log(error, 'updUserView==>');
  }
}


export function* watchgetActivity() {
  yield takeLatest(actions.getActivity.type, getActivity);
}
export function* watchUpdateUserClick() {
  yield takeLatest(actions.updateUserClick.type, updateUserClick);
}
export function* watchgetupdUserView() {
  yield takeLatest(actions.updUserView.type, updUserView);
}

export default function* rootSaga() {
  yield fork(watchgetActivity);
  yield fork(watchUpdateUserClick);
  yield fork(watchgetupdUserView);
}


