import { fork } from 'redux-saga/effects';
import notifEdit from './notifEdit';
import auth from './auth';
import analytics from './analytics';
import setting from './setting';
import notifShopify from './notifShopify';
import dashboard from './dashboard';
import header from './header';
import notif from './notif';
import data from './dataPage';
import visitor from './visitor';
import goal from './goal';
import rateUs from './rateUs';
import pixel from './pixel'
import profile from './profile';
import manageTeam from './manageTeam';
import agency from './agency';
import integration from './integration';
import referEarn from './referEarn';
import login from './login';
import pricingPlan from './pricingPlan';
import shopifyPricingPlan from './shopifyPricingPlan';
import onBoarding from './OnBoard';
import whitelabelLogin from './whitelabelLogin';
import whitelabelForgotPwd from './whitelabelForgotPwd';
import wixPricingPlan from './wixPricingPlan';
import appsumoPlan from './appsumoPlan';
import billingInvoice from './billingInvoice';

export default function* rootSaga() {
  yield fork(notifEdit);
  yield fork(auth);
  yield fork(analytics);
  yield fork(setting);
  yield fork(notifShopify);
  yield fork(dashboard);
  yield fork(header);
  yield fork(notif);
  yield fork(data);
  yield fork(visitor);
  yield fork(goal);
  yield fork(rateUs);
  yield fork(pixel);
  yield fork(profile);
  yield fork(referEarn);
  yield fork(manageTeam);
  yield fork(agency);
  yield fork(integration);
  yield fork(login);
  yield fork(pricingPlan);
  yield fork(shopifyPricingPlan);
  yield fork(onBoarding);
  yield fork(whitelabelLogin);
  yield fork(whitelabelForgotPwd);
  yield fork(wixPricingPlan);
  yield fork(appsumoPlan);
  yield fork(billingInvoice);
}
