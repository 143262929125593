import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, IndexTable, Page, Text, InlineStack
} from '@shopify/polaris';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function WixCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();

  //state data.
  const [info, setInfo] = useState({
    Wix: [],
    ia: false,//for toggle on/off
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //Wix data from api
  const getWixData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ Wix: getWixData });
  }, [getWixData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.Wix.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ Wix: updatedList })
  };

  const loginWithWix = () => {
    let objState = {
      uk: props.profile._id,
      ti: props.profile.ti,
      uri: window.location.href,//.replace(/&/gi, '@'),
      path: window.location.pathname
    };
    const authURL = `https://www.wix.com/installer/install?appId=c43175ad-247c-4a98-afbf-560059c8198e&redirectUrl=${process.env.REACT_APP_API_URL}/wix/oauth&&state=${JSON.stringify(objState)}`;
    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* Wix data*/}
        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {props.checkPermission('integration', 'isadd', props.profile) && localStorage.getItem('pvd') !== 'wix' &&
                <Box paddingBlockEnd={200}><div id='insta-btn' onClick={() => loginWithWix()}><Button size='large'>Login with wix</Button></div></Box>
              }
            </InlineStack>
            <Card padding={0}>
              <IndexTable
                itemCount={info.Wix?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                ]}
                selectable={false}
              >
                {info.Wix && info.Wix.length > 0 && info.Wix.map((i, index) => {
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell><span className='tbl-hightlight'>{i.rmk}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>

                      </IndexTable.Row>
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>
    </Page >
  )
}

export default WixCard;