import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from "react-router-dom";

function SendinblueCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, handleBackToMain } = props.props;

  //state data.
  const [info, setInfo] = useState({
    errMessage: {},
    sendinblue: [],
    ak: '',
    li: '',//List Id
    rmk: '',
    dataId: '',
    ia: false,
    deleteDataModal: false,//open delete modal
    modalC: false,//condition for delete modal
    notifListData: "",//show data in modal
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //sendinblue data from api
  const getsendinblue = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ sendinblue: getsendinblue });
  }, [getsendinblue]);

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal
  const deleteSendInBlue = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteSendInBlue(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //create Sendinblue data 
  const createSendinblue = () => {
    if (props.validator.errorMessages.ak || props.validator.errorMessages.rmk || props.validator.errorMessages.li) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      changeNameValueCard({ errMessage: {} });
      let obj = {
        ak: info.ak,
        rmk: info.rmk,
        li: info.li
      };
      dispatch(integrationDucks.createSendInBlue(obj));
      changeNameValueCard({ ak: '', rmk: '', li: '', errMessage: {} });
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.sendinblue.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ sendinblue: updatedList })
  };

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create Sendinblue data */}
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.ak} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">API Key</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.ak}
                        onChange={(e) => changeNameValueCard({ ak: e })}
                        placeholder='API Key'
                        autoComplete="off"
                        error={info.errMessage.ak ? "The API Key field is required." : false}
                      />
                      {props.validator.message('ak', info.ak, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='create-'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={"Enter your list Id"} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">List Id</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.li}
                        onChange={(e) => changeNameValueCard({ li: e })}
                        placeholder='List Id'
                        autoComplete="off"
                        error={info.errMessage.li ? "The list id field is required." : false}
                      />
                      {props.validator.message('li', info.li, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='create-'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box align='center' padding={300}><Button onClick={(e) => createSendinblue(e)} variant="primary">Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.sendinblue?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'SendInBlue Id' },
                  { title: 'Api Key' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >

                {info.sendinblue && info.sendinblue.length > 0 && info.sendinblue.map((i, index) => (
                  <IndexTable.Row key={index}>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="bold" as="span">
                        {index + 1}
                      </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell> {i.d?.id}</IndexTable.Cell>
                    <IndexTable.Cell><span className="text-break">{i.d?.ak}</span></IndexTable.Cell>
                    <IndexTable.Cell>{i.rmk}</IndexTable.Cell>
                    <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                    <IndexTable.Cell>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={i.ia}
                          onChange={() => handleIsActive(i)}
                        />
                        <span className="switch-label"></span>
                        <span className="switch-handle"></span>
                      </label>
                    </IndexTable.Cell>
                    {props.checkPermission('integration', 'isdelete', props.profile) &&
                      <IndexTable.Cell>
                        <div id="delete" onClick={() => deleteSendInBlue(i._id)}>
                          <Banner hideIcon tone='critical'>
                            <Icon
                              source={DeleteIcon}
                              tone="critical"
                            />
                          </Banner>
                        </div>
                      </IndexTable.Cell>
                    }
                  </IndexTable.Row>
                ))}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e) }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close') }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    handleDeleteCall(info.dataId);
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default SendinblueCard;