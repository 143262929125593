import React from 'react';
import { Autocomplete, Badge, Banner, BlockStack, Box, Button, ButtonGroup, Card, Icon, IndexTable, InlineGrid, InlineStack, LegacyCard, List, Modal, Page, PageActions, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail, Spinner, Text, TextField, useBreakpoints } from '@shopify/polaris';
import { inline_frame_1 } from '../../img';
import BillingSummery from '../PricingPlan/BillingSummery';
import Inline from './Inline';
import AnnouncementCard from './AnnouncementCard';
import Clone from '../Notif/Clone';
import Debugger from '../Notif/Debugger';
import { skeleton } from '../../utils/skeleton';
import { SearchIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import DesignCard from '../NotifEdit/DesignCard';
import { Link } from 'react-router-dom';

function NotifShopifyCard(props) {
  const { state, handleEditNotif, openCloseModal, changeNameValue } = props;

  const inlineList = [
    {
      key: 1,
      typ: 'detail',
      img: inline_frame_1,
      label: 'For Product & Collection pages',
      description: 'Show product specific live visits, recent sales & product sold counter.',
    },
  ];

  const anncmtNotifList = [
    {
      key: 1,
      typ: 'timer',
      img: 'https://storage.googleapis.com/wiserimg/template_img/5e200bc8b69e2aa75b7f2f93.svg?q=123',
      label: `Explore 10 Countdown Timer Designs`,
    },
    {
      key: 2,
      typ: 'announcement',
      img: 'https://storage.googleapis.com/wiserimg/notif_icon/NotiType_custom.png',
      label: `Explore 13 Announcement Designs`,
    },
    {
      key: 3,
      typ: 'call_to_action',
      img: 'https://storage.googleapis.com/wiserimg/notif_icon/NotiTtype_collector.png',
      label: `Explore 5 Call to action Designs`,
    },
  ];

  const getIa = (typ) => {
    let _ia = false;
    if (state.userNotifList && state.userNotifList.length > 0) {
      state.userNotifList.forEach(x => { if (x.ntt === typ) _ia = x.ia; });
    }
    return _ia;
  };

  function getSeenTime(date) {
    const currentDate = new Date();
    const previousDate = new Date(date);

    const diffInMilliseconds = currentDate - previousDate; // Get the difference in milliseconds
    const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60); // Convert to minutes

    // Define time units
    const minutes = diffInMinutes % 60;
    const hours = Math.floor(diffInMinutes / 60) % 24;
    const days = Math.floor(diffInMinutes / 60 / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'just now';
    }
  }

  const handleTrublShotClick = (id, notifName) => {
    changeNameValue({
      pixelOpen: true, userNotifId: id, userNotifName: notifName, webUrl: '', userPixelVerify: ''
    });

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Notification list button', 'Troubleshoot link', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Notification list button', { 'event_action': 'Troubleshoot link', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  }

  // Check notification activity
  const checkNotifActivity = (nt, notifName) => {
    let notif = state.userNotifList?.find((elm) => elm.nt === nt);

    let findNotif = state.notifActivity?.find((elm) => elm._id === notif._id);

    if (findNotif?.isFound === false) {
      return <span className='notif-activity'>
        <Badge
          tone="critical"
          progress="complete"
        >
          Not seen in the last 24 hours.<Button variant="plain"
            onClick={() => handleTrublShotClick(notif._id, notifName)}><Text variant='bodySm'>Troubleshoot?</Text></Button>
        </Badge>
      </span>;
    }
    else {
      return <span className='notif-activity'>
        <Badge
          tone="success"
          progress="complete"
        >
          <Button variant="plain" onClick={() => props.handleGetUserNotifData(notif._id, notifName)}><Text tone='success' variant='bodySm'>Last seen {getSeenTime(findNotif?.lstDt)}.</Text></Button> <Button variant="plain" onClick={() => handleTrublShotClick(notif._id, notifName)}><Text variant='bodySm'>Troubleshoot?</Text></Button>
        </Badge>
      </span>;
    }
  };

  const textField = (
    <Autocomplete.TextField
      onChange={(e) => changeNameValue({ searchUserNotifUrl: e })}
      value={state.searchUserNotifUrl}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search by page URL"
      autoComplete="off"
    />
  );

  const renderListHTML = (state, data) => {
    return Parser(DesignCard(state, data, 'listCard'));
  }

  return (
    <>
      {!state.isConfiguration ? (
        <Page>
          <Box paddingBlockEnd={400}>
            <BlockStack gap={400}>
              {((state.profile.pvd === 'shopify' || state.profile.pvd === 'wix' || state.profile.pvd === 'thinkific' || state.profile.pvd === 'bigcommerce') && state.profile.csub === 'free') &&
                <Banner tone='warning'>
                  <p>
                    Free Plan: Limited to 1 active notification.
                    <Link to={'/plan'}><Button variant="plain"> Upgrade to unlock more.</Button></Link>
                  </p>
                </Banner>
              }
              <BillingSummery props={props} />
              <Card>
                <Box paddingBlockEnd={600}>
                  <Text variant='headingLg' as='p' fontWeight='semibold'>Floating</Text>
                </Box>
                <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
                  {props.isLoading ?
                    Array.from({ length: 5 }).map((_, i) => (
                      <div className='card_bg' key={i}>
                        <Box paddingBlockEnd={800}>
                          <div className='noti_thumb_block'>
                            <SkeletonThumbnail size="large" />
                          </div>
                        </Box>
                        <Box width='30%' paddingBlockEnd={200}>
                          <SkeletonBodyText lines={1} />
                        </Box>
                        <SkeletonBodyText lines={1} />
                        <Box paddingBlockStart={400}>
                          <SkeletonDisplayText size="small" />
                        </Box>
                      </div>
                    ))
                    :
                    state.floatingNotifList && state.floatingNotifList.length > 0 && state.floatingNotifList.map((data, i) => {
                      let matchedData = state.userNotifList && state.userNotifList?.find(val => val.ntt === data.nt);
                      return (
                        <div className='card_bg' key={i}>
                          <div className='noti_img_block'>
                            {matchedData ?
                              <div className={`nTf_hTMl wn_${data._id} notificatio-pro`}>
                                {data.nt === 'title_bar' ?
                                  <div className="FDGdflt26_WeBTitLeBar_fdhKMN FDGdflt26_WeBTitLeBar_TiTlEbaRTem01">
                                    <div className="FDGdflt26_ToPhEaDer_FirstBlock">
                                      <div className="FDGdflt26_WeBTitLeBar_BlocK">
                                        <div className="FDGdflt26_WeBTitLeBar_imG">
                                          <img id={`ImgURL_${i}`} alt='' />
                                        </div>
                                        <div className="FDGdflt26_WeBTitLeBar_LefTBlock">
                                          <div className="FDGdflt26_WeBTitLeBar_TexT" id={`TitleTxt_${i}`}>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  data.nt === 'video' ? //video notification
                                    <div className="notilist-notivideo">
                                      {renderListHTML(state, matchedData)}
                                    </div>
                                    :
                                    data.nt === 'call_to_action' ? //call_to_action notification
                                      <div className="notilist-callback">
                                        {renderListHTML(state, matchedData)}
                                      </div>
                                      :
                                      (data.nt === 'collector' || data.nt === 'social_media_followers') ?
                                        <div className="notilist-promotional">
                                          {renderListHTML(state, matchedData)}
                                        </div>
                                        :
                                        data.nt === 'all_in_one' ? //All in one
                                          <div className="notilist_allinone">
                                            {renderListHTML(state, matchedData)}
                                          </div>
                                          :
                                          renderListHTML(state, matchedData)
                                }
                              </div>
                              :
                              <img className='noti_type_img' src={data.config.iu} alt='notif' />
                            }
                          </div>
                          <Box paddingBlockStart={200} paddingBlockEnd={100}>
                            <InlineStack gap={200}>
                              <Text variant='headingXs' as='p' fontWeight='semibold'>{data.nn}</Text>
                              {/* <div className='blt_clr'>
                            <List type="bullet">
                              <List.Item>Social Proof</List.Item>
                            </List>
                          </div> */}
                            </InlineStack>
                          </Box>
                          <div id='fix_hght'>
                            <Text variant='headingXs' as='p' fontWeight='regular'>{data.desc}</Text>
                          </div>
                          <Box paddingBlockStart={400}>
                            {data.isEdit ?
                              <InlineStack align='space-between'>
                                <InlineStack gap={200} blockAlign='center'>
                                  <Button onClick={() => handleEditNotif(data)}>Edit</Button>
                                  <label className="switch">
                                    <input type="checkbox" checked={getIa(data.nt)}
                                      onChange={() => props.handleUpdNotifStatus(data)} />
                                    <span className="switch-label"></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </InlineStack>
                                {/* <Button tone='critical' variant='primary' icon={DeleteIcon} onClick={() => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ notifData: data }) }} ></Button> */}
                              </InlineStack>
                              :
                              <Button onClick={() => props.handleCreateNotif(data)}>Add</Button>
                            }
                          </Box>
                          {getIa(data.nt) &&
                            <div>
                              {checkNotifActivity(data.nt, data.nn)}
                            </div>
                          }
                        </div>
                      )
                    }
                    )
                  }
                </InlineGrid>
              </Card>

              <Card>
                <Box paddingBlockEnd={600}>
                  <Text variant='headingLg' as='p' fontWeight='semibold'>Inline</Text>
                </Box>
                <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
                  {inlineList && inlineList.length > 0 && inlineList.map((data, i) => (
                    <div className='card_bg' key={i} id='cursorPointer' onClick={() => props.handleManageInlnNotif(data)}>
                      <img src={data.img} alt='inline' width="100%" />
                      <BlockStack gap={200}>
                        <Box paddingBlockStart={200}>
                          <Text variant='headingXs' as='p' fontWeight='semibold'>{data.label}</Text>
                        </Box>
                        {/* <div id='fix_hght'> */}
                        <Text variant='headingXs' as='p' fontWeight='regular'>{data.description}</Text>
                        {/* </div> */}
                        <Box paddingBlockStart={100}>
                          <Button variant='primary' onClick={() => props.handleManageInlnNotif(data)}>Manage</Button>
                        </Box>
                      </BlockStack>
                    </div>
                  ))}
                </InlineGrid>
              </Card>

              <div id='anncmt_notif'>
                <Card>
                  <Box paddingBlockEnd={600}>
                    <Text variant='headingLg' as='p' fontWeight='semibold'>Announcements</Text>
                  </Box>
                  <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
                    {anncmtNotifList && anncmtNotifList.length > 0 && anncmtNotifList.map((data, i) => (
                      <div className='card_bg' key={i} id='cursorPointer'>
                        {/* <div className='card_bg' key={i} id='cursorPointer' onClick={() => props.handleManageAnncmtNotif(data)}> */}
                        <div className='noti_img_block' onClick={() => props.handleManageAnncmtNotif(data)}>
                          <img className='noti_type_img' src={data.img} alt='timer' />
                        </div>
                        <BlockStack gap={200}>
                          <Box paddingBlockStart={200}>
                            <Text variant='headingXs' as='p' fontWeight='semibold'>{data.label}</Text>
                          </Box>
                          <Box paddingBlockStart={100}>
                            <ButtonGroup>
                              <Button onClick={() => props.handleManageAnncmtNotif(data, 'addNotif')}>Add</Button>
                              <Button variant='primary' onClick={() => props.handleManageAnncmtNotif(data)}>Manage</Button>
                            </ButtonGroup>
                          </Box>
                        </BlockStack>
                      </div>
                    ))}
                  </InlineGrid>
                </Card>
              </div>
            </BlockStack>
          </Box>
        </Page>
      ) : (
        <>
          {state.isInlineShow &&
            <Inline
              state={state}
              isLoading={props.isLoading}
              changeNameValue={props.changeNameValue}
              handleCopyCode={props.handleCopyCode}
              handleBackToMain={props.handleBackToMain}
              openCloseModal={props.openCloseModal}
              handleInstallPdpShowClose={props.handleInstallPdpShowClose}
              handleInstallCtgShowClose={props.handleInstallCtgShowClose}
              handleSugtInlnPdpTxtChange={props.handleSugtInlnPdpTxtChange}
              handleSugtInlnPdpOptChange={props.handleSugtInlnPdpOptChange}
              handleSugtInlnCtgTxtChange={props.handleSugtInlnCtgTxtChange}
              handleSugtInlnCtgOptChange={props.handleSugtInlnCtgOptChange}
              handleCreateInlnNotif={props.handleCreateInlnNotif}
              handleUpdInlnNotifStatus={props.handleUpdInlnNotifStatus}
              openChat={props.openChat}
              handleEditNotif={props.handleEditNotif}
              handleDelInlnNotif={props.handleDelInlnNotif}
              handleAddInline={props.handleAddInline}
              handleCreateStripeLink={props.handleCreateStripeLink}
            />
          }
          {(state.notifTyp === 'timer' || state.notifTyp === 'announcement' || state.notifTyp === 'call_to_action') && (
            <AnnouncementCard
              state={state}
              isLoading={props.isLoading}
              onPaggiCall={props.onPaggiCall}
              changeNameValue={props.changeNameValue}
              handleBackToMain={props.handleBackToMain}
              openCloseModal={props.openCloseModal}
              handleCreateNotif={props.handleCreateNotif}
              handleUpdAnncmtNotifStatus={props.handleUpdAnncmtNotifStatus}
              handleEditNotif={props.handleEditNotif}
              handleDelNotif={props.handleDelNotif}
              handleDebugger={props.handleDebugger}
              handleEditNotifNameShow={props.handleEditNotifNameShow}
              handleEditNotifNameClose={props.handleEditNotifNameClose}
              handleTextChange={props.handleTextChange}
              handleUpdateNotifName={props.handleUpdateNotifName}
            />
          )}
        </>
      )}
      {state.isClone === true ?
        <Clone props={props} />
        : state.isDebugger === true ? <Debugger props={props} /> : ''}

      <Modal // delete notification
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this notification?</Text>
            <PageActions
              primaryAction={<Button variant='primary' tone='success' onClick={() => props.handleDelNotif(state.notifData)}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      {/* get user notif data modal*/}
      <Modal
        size='large'
        open={state.isUserNotifDataModal}
        title={<Text variant='headingMd' fontWeight='bold'>{state.userNotifSeenName}: Last seen in last 24 hours.</Text>}
        onClose={(e) => props.handleCloseUserNotifDataModal(e)}
      >
        <Modal.Section>
          <Box paddingBlockEnd={200}>
            <Autocomplete
              options={[]}
              textField={textField}
            />
          </Box>
          <LegacyCard>
            <IndexTable
              condensed={useBreakpoints().smDown}
              itemCount={state.getUserNotifData?.length === undefined ? 1 : state.getUserNotifData?.length}
              selectable={false}
              headings={[
                { title: 'No' },
                { title: 'Page URL' },
                { title: 'Event' },
                { title: 'Last seen' },
              ]}
              pagination={{
                // label: props.paggiLabel,
                hasPrevious: state.paggiActiveUserNotif > 1 ? true : false,
                hasNext: state.getUserNotifData?.length >= 20 ? true : false,
                onPrevious: () => props.onPaggiCallUserNotif('-1'),
                onNext: () => props.onPaggiCallUserNotif('+1')
              }}
            >
              {
                state.isSeenLoading ?
                  skeleton(20, 4)
                  :
                  <>
                    {state.getUserNotifData?.length > 0 && state.getUserNotifData.map(
                      (
                        elm,
                        index,
                      ) => (
                        <IndexTable.Row
                          id={elm._id}
                          key={index}
                          selected={false}
                          position={index}
                        >
                          <IndexTable.Cell>
                            <Text variant="bodyMd" fontWeight="bold" as="span">
                              {index + 1}
                            </Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>{elm.hrf.length > 70 ? elm.hrf.slice(0, 70) + '...' : elm.hrf}</IndexTable.Cell>
                          <IndexTable.Cell>{elm.ev}</IndexTable.Cell>
                          <IndexTable.Cell>{getSeenTime(elm.t)}</IndexTable.Cell>
                        </IndexTable.Row>
                      ),
                    )
                    }
                  </>
              }
            </IndexTable>
          </LegacyCard>
        </Modal.Section>
      </Modal>

      {/* Pixel page url modal */}
      <Modal
        open={state.pixelOpen}
        onClose={(e) => props.handleCloseInstallPixel(e)}
        title={<BlockStack id='troubleshoot-header'>
          <InlineStack gap={300}> <Text variant='headingMd' as='h1'>{state.userNotifName}</Text><Box><Badge tone="attention">Troubleshoot</Badge></Box></InlineStack>
          <Text variant='bodyMd' tone='subdued'>Check notification status on a specific page</Text>
        </BlockStack>}>
        <Modal.Section>
          <Text variant='bodyMd' tone='subdued'>We’ll first check if the pixel code is loading on the page you provide. If the pixel is detected, you can continue troubleshooting design, display, or data-source configurations directly on that page.</Text>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <Text fontWeight="medium" variant='bodyMd' tone='subdued'>Enter the URL of the page where you expect the notification to appear</Text>
          </Box>
          <InlineStack gap={200}>
            <Box width='80%'>
              <TextField
                placeholder='Enter your site/page URL'
                value={state.webUrl}
                onChange={(value) => props.changeNameValue({ webUrl: value })}
              />
            </Box>
            {
              <Button variant="tertiary" onClick={() => props.handleVerifyPixel()}>
                Verify Pixel
              </Button>
            }
          </InlineStack>

          <Box paddingBlockStart={500}>
            <InlineStack gap={300}>
              {state.isPending ? (
                <div className="step-third-crtnoti troubleshoot-notice-block">
                  <div className="step-third-block step-pixel-verify">
                    <Text tone="subdued">Wait for 10-50 seconds, checking the pixel code...</Text>
                    <Spinner size="small" />
                  </div>
                </div>
              ) : state.userPixelVerify ? (
                state.userPixelVerify.isFound ? (
                  <div className="step-third-crtnoti troubleshoot-notice-block">
                    <div className="step-third-block step-pixel-verify">
                      <div className="step-pixel"></div>
                      <Text tone="success">
                        This page is loading the pixel code successfully.
                      </Text>
                    </div>
                    <Box paddingInlineStart={200}>
                      <Button variant="primary" tone="success" onClick={() => props.handleTroubleshoot()}>
                        Continue to Troubleshoot
                      </Button>
                    </Box>
                  </div>
                ) : (
                  <div className="step-third-crtnoti troubleshoot-notice-block" style={{ flexDirection: 'row-reverse' }}>
                    <div className="step-third-block">
                      <div className="step-pixel"></div>
                      <div className='step-novisit-text'>
                        <Text tone="critical">No visits in the last 24 hours or the pixel isn’t installed. Ensure the pixel is installed and the page has been visited.
                          {localStorage.getItem('isbusr') === 'false' && (
                            <>
                              <Text>Need help?</Text>
                              <Button variant="plain" onClick={() => props.openChat()}>Chat With Us</Button>
                            </>
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>
                )
              ) : null}
            </InlineStack>
          </Box>
        </Modal.Section>
      </Modal>
    </>
  );
}

export default NotifShopifyCard;