import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, TextField, Tooltip, Banner, Icon, Modal, RadioButton, ButtonGroup, DropZone, Thumbnail, InlineGrid, OptionList, Popover } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, PlusIcon, ViewIcon, NoteIcon, StarFilledIcon, ArrowDownIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { toastify } from '../../ducks/toast';

function ImportReviewsCard(props) {
  const dispatch = useDispatch();
  const { state, changeNameValue, handleBackToMain } = props.props;

  const [value, setValue] = useState({
    intDataList: [],
    rname: '',
    rtxt: '',
    rmk: '',
    sourceUrl: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    modalReidr: false,
    notifListData: [],
    importCSV: false,
    addReviewModal: false,
    isIconModal: false,
    myIcon: [],
    currImg: '',
    popoverSource: false,
    selectedSource: '',
    popoverRateing: false,
    selectedRateing: '5',
    importedFile: ''
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.userIntData);
  const iconListArr = useSelector(state => state.integration?.iconImages);

  useEffect(() => {
    changeValue({ myIcon: iconListArr })
  }, [iconListArr])

  useEffect(() => {
    changeValue({ intDataList });
    dispatch(integrationDucks.getImages());
  }, [intDataList])

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updIntIsActive(obj))
    const filterData = value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteImportReview(id));
  }

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  // create Btn
  const handleCreateBtn = () => {
    if (props.validator.errorMessages.rmk !== null) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      const formData = new window.FormData();
      formData.append(
        'remark',
        value.rmk
      );
      dispatch(integrationDucks.createImportReview(formData));
      changeValue({ rmk: '', errMessage: {} });
    }
  }

  const handleRadioBtn = (val) => {
    changeValue({ rname: '', rtxt: '', sourceUrl: '', currImg: '', selectedRateing: '5', selectedSource: '', errMessage: {}, importedFile: '', importCSV: val })
  }

  // add reviews btn
  const handleAddReview = (id) => {
    changeValue({ addReviewModal: true, dataId: id })
  }

  //social-media options
  const sourceOption = [
    { value: 'google', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/ggle.svg" alt="Google" /> <span>&nbsp; Google</span> </div> },
    { value: 'facebook', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/fb.svg" alt="Facebook" /> <span>&nbsp; Facebook</span> </div> },
    { value: 'twitterpost', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/twit.svg" alt="Twitter" /> <span>&nbsp; Twitter</span> </div> },
    { value: 'instagrampost', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/insta.svg" alt="Instagram" /> <span>&nbsp; Instagram</span> </div> },
    { value: 'linkedin', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/in.svg" alt="LinkedIn" /> <span>&nbsp; LinkedIn</span> </div> },
    { value: 'g2', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/g2.svg" alt="G2" /> <span>&nbsp; G2</span> </div> },
    { value: 'capterra', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/cap.svg" alt="Capterra" /> <span>&nbsp; Capterra</span> </div> },
    { value: 'clutch', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/wiser_goal/integration/clutch.webp" alt="Clutch" /> <span>&nbsp; Clutch</span> </div> }
  ];

  // ratting option 
  let starIcon = <Icon
    source={StarFilledIcon}
    tone="base"
  />
  const rateingOptions = [
    { label: <div className='optionList-Icon'><InlineStack>{starIcon}{starIcon}{starIcon}{starIcon}{starIcon}</InlineStack></div>, value: '5' },
    { label: <div className='optionList-Icon'><InlineStack>{starIcon}{starIcon}{starIcon}{starIcon}</InlineStack></div>, value: '4' },
    { label: <div className='optionList-Icon'><InlineStack>{starIcon}{starIcon}{starIcon}</InlineStack></div>, value: '3' },
    { label: <div className='optionList-Icon'><InlineStack>{starIcon}{starIcon}</InlineStack></div>, value: '2' },
    { label: <div className='optionList-Icon'><InlineStack>{starIcon}</InlineStack></div>, value: '1' },
  ];

  // handle icon modal
  const handleIconModalOpen = () => {
    changeValue({ isIconModal: true, iconUrl: '', iconLabel: '', uploadedUrl: [] })
  }
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];

  //base64 file convert
  const base64FileURL = (element, callback) => {
    let file = element;
    let reader = new window.FileReader();
    reader.onloadend = function (e) {
      callback(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  // on browse icon
  const handleDropZone = async (imgdrop) => {
    if (imgdrop && imgdrop.length > 0) {
      base64FileURL(imgdrop[0], (obj) => {
        let stateObj = {
          uploadedUrl: imgdrop,
          iconUrl: obj,
          iconLabel: imgdrop[0].name
        }
        changeValue(stateObj);
      })
    }
  }

  //upload custom icon url
  const uploadIconfile = () => {
    if (value.iconUrl) {
      let obj = {
        _id: value.dataId,
        piu: value.iconUrl,
        fuid: value.dataId
      };
      dispatch(integrationDucks.updateReview(obj));
      changeValue({ iconUrl: '', iconLabel: '', uploadedUrl: [] });
    }
  }

  //delete icon
  const deleteMediaIcon = (id, iu) => {
    const obj = {
      id: id,
      wbstlnk: iu
    };
    dispatch(integrationDucks.deleteImage(obj));
    changeNameValue({ isLoadIcon: true });
  }

  //handleImgChange 
  const handleImgChange = (value) => {
    changeValue({ currImg: value, isIconModal: false });
  }

  // select list for source
  const getSelectedLabel = (selectedValue) => {
    const selectedOption = sourceOption.find(option => option.value === selectedValue);
    return selectedOption ? selectedOption.label : 'Select';
  };
  const activatorSource = (
    <Button onClick={() => changeValue({ popoverSource: !value.popoverSource })} disclosure id='disSelect' textAlign="left" fullWidth>
      <Text fontWeight='medium' variant='bodyMd'>
        {getSelectedLabel(value.selectedSource[0])}
      </Text>
    </Button>
  );

  // select list for Rateing
  const getSelectedRateing = (selectedValue) => {
    const selectedOption = rateingOptions.find(option => option.value === selectedValue);
    return selectedOption ? selectedOption.label : 'Select';
  };
  const activatorRateing = (
    <Button onClick={() => changeValue({ popoverRateing: !value.popoverRateing })} disclosure id='disSelect' textAlign="left" fullWidth>
      <Text fontWeight='medium' variant='bodyMd'>
        {getSelectedRateing(value.selectedRateing[0])}
      </Text>
    </Button>
  );

  // save custom Review Btn
  const handleAddCustomReview = () => {
    if (props.validator.errorMessages.rname !== null || props.validator.errorMessages.rtxt !== null) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      const obj = {
        cl: 0,
        pn: 1,
        un: value.rname,
        rtxt: value.rtxt,
        fa: state.in,
        fuid: value.dataId,
        pgid: value.dataId,
        piu: value.currImg,
        rtng: value.selectedRateing[0],
        pu: value.sourceUrl,
        styp: value.selectedSource[0] ? value.selectedSource[0] : '',
        msg: '',
        cptntxt: '',
        wbstlg: '',
        wbstlnk: ''
      }
      dispatch(integrationDucks.addCutomReview(obj));
      changeValue({ addReviewModal: false, rname: '', rtxt: '', sourceUrl: '', currImg: '', selectedRateing: '5', selectedSource: '', errMessage: {} })
    }
  }

  // add review cancel Btn
  const handleCancelBtn = () => {
    changeValue({ addReviewModal: false, rname: '', rtxt: '', sourceUrl: '', currImg: '', selectedRateing: '5', selectedSource: '', errMessage: {} })
  }
  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile(acceptedFiles[0]),
    [],
  );

  // demo csv download
  const getDemoCsv = () => {
    let CSV = 'https://storage.googleapis.com/wiser_goal/integration/Import_review_demo_CSV.csv';
    window.location.href = CSV;
  };

  //get csv file
  const CSVfile = (event) => {
    let obj = event.target?.files[0];
    let type = obj?.name?.split('.');
    type = type[type?.length - 1];
    if (type == 'csv') {
      changeValue({ importedFile: event.target?.files[0] });
    }
    else {
      dispatch(toastify({ type: 'error', msg: "Only CSV format allowed!" }));
      changeValue({ importedFile: '' });
    }
  }

  // upload csv file btn
  const uploadCsvFile = () => {
    const formData = new window.FormData();
    formData.append('pn', 1);
    formData.append('cl', 0);
    formData.append('fuid', value.dataId);
    formData.append('myfile', value.importedFile);
    dispatch(integrationDucks.uploadCSV(formData));
    changeValue({ addReviewModal: false, importedFile: '', importCSV: false })
  }
  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => (
      <IndexTable.Row
        id={usInt._id}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {index + 1}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{usInt.rmk}</IndexTable.Cell>
        <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
        <IndexTable.Cell> <label className="switch">
          <input type="checkbox" checked={usInt.ia}
            onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
          <span className="switch-label"></span>
          <span className="switch-handle"></span>
        </label></IndexTable.Cell>
        <IndexTable.Cell></IndexTable.Cell>
        <IndexTable.Cell></IndexTable.Cell>
        <IndexTable.Cell></IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack gap={200}>
            <Button icon={PlusIcon} variant="primary" tone="success" onClick={() => handleAddReview(usInt._id)}>Add reviews</Button>
            <div id="delete" onClick={() => window.open('/data?id=' + usInt._id, '_blank')}>
              <Banner hideIcon tone='warning' onClick={() => window.open('/data?id=' + usInt._id, '_blank')}>
                <Icon
                  source={ViewIcon}
                  tone="warning"
                />
              </Banner>
            </div>
            {props.checkPermission('integration', 'isdelete', props.profile) &&
              <div id="delete" onClick={() => handleDeleteCall(usInt._id)}>
                <Banner hideIcon tone='critical'>
                  <Icon
                    source={DeleteIcon}
                    tone="critical"
                  />
                </Banner>
              </div>
            }
          </InlineStack>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to connect</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <BlockStack gap={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.rmk}
                        onChange={(e) => changeValue({ rmk: e })}
                        placeholder='Create one connection to add reviews'
                        autoComplete="off"
                        error={value.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', value.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </BlockStack>
              <Box align='center' padding={300}><Button variant="primary" onClick={() => handleCreateBtn()}>Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box><Text variant="headingSm" as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: 'No' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: '' },
                  { title: '' },
                  { title: '' },
                  { title: 'Action' }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* Add review Modal */}
      {value.addReviewModal === true &&
        <Modal
          open={value.addReviewModal}
          title={<Text variant='headingMd' fontWeight='bold'>Add reviews</Text>}
          onClose={(e) => { openCloseModal('addReviewModal', value.addReviewModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={300}>
              <InlineStack gap={300}>
                <RadioButton
                  label="Add reviews manually"
                  checked={value.importCSV === false}
                  onChange={() => handleRadioBtn(false)}
                />
                <RadioButton
                  label="Import reviews via CSV"
                  checked={value.importCSV === true}
                  onChange={() => handleRadioBtn(true)}
                />
              </InlineStack>
            </Box>

            {value.importCSV === false ?
              <Box>
                <BlockStack gap={200}>
                  <TextField
                    label="Reviewer name"
                    value={value.rname}
                    onChange={(e) => changeValue({ rname: e })}
                    placeholder='Name..'
                    autoComplete="off"
                    error={value.errMessage.rname ? "The reviewer name field is required." : false}
                  />
                  {props.validator.message('rname', value.rname, 'required')}

                  <label>Reviewer image</label>
                  {value.currImg !== '' ?
                    <InlineStack gap={200} blockAlign='center'>
                      <Box id='iconWrap' background="bg-surface" minHeight='70px' borderRadius="200" borderColor="border" borderWidth="025" minWidth='500px'>
                        <img className='iconImg' src={value.currImg} alt="Icons" />
                        <Button id='iconDelete' variant='monochromePlain' tone='critical' onClick={() => changeValue({ currImg: '' })}>
                          <Icon tone='critical' source={DeleteIcon}></Icon></Button>
                      </Box>
                      <Button onClick={() => handleIconModalOpen()}>Select</Button>
                    </InlineStack>
                    :
                    <InlineStack gap={200} blockAlign='center'>
                      <Box id='iconWrap' background="bg-surface" minHeight='70px' borderRadius="200" borderColor="border" borderWidth="025" minWidth='500px'>
                        <img className='iconImg' src={'https://storage.googleapis.com/widget_img/widget/default_share.png'} alt="Icons" />
                      </Box>
                      <Button onClick={() => handleIconModalOpen()}>Upload</Button>
                    </InlineStack>

                  }

                  <label>Ratings</label>
                  <Box>
                    <Popover
                      active={value.popoverRateing}
                      activator={activatorRateing}
                      onClose={() => changeValue({ popoverRateing: !value.popoverRateing })}
                      fullWidth
                    >
                      <Card>
                        <OptionList
                          options={rateingOptions}
                          selected={value.selectedRateing}
                          onChange={(e) => { changeValue({ popoverRateing: false, selectedRateing: e }) }}
                          allowMultiple={false}
                        />
                      </Card>
                    </Popover>
                  </Box>


                  <TextField
                    label="Review text"
                    value={value.rtxt}
                    onChange={(e) => changeValue({ rtxt: e })}
                    placeholder='Text..'
                    multiline={4}
                    autoComplete="off"
                    error={value.errMessage.rtxt ? "The review text field is required." : false}
                  />
                  {props.validator.message('rtxt', value.rtxt, 'required')}

                  <label>Review Source</label>
                  <Box>
                    <Popover
                      active={value.popoverSource}
                      activator={activatorSource}
                      onClose={() => changeValue({ popoverSource: !value.popoverSource })}
                      fullWidth
                    >
                      <Card>
                        <OptionList
                          options={sourceOption}
                          selected={value.selectedSource}
                          onChange={(e) => { changeValue({ popoverSource: false, selectedSource: e }) }}
                          allowMultiple={false}
                        />
                      </Card>
                    </Popover>
                  </Box>

                  <TextField
                    label="Source URL"
                    value={value.sourceUrl}
                    placeholder='URL..'
                    onChange={(e) => changeValue({ sourceUrl: e })}
                    autoComplete="off"
                  />
                  {/* <ButtonGroup align='end'> */}
                  <InlineStack align='end'>
                    <ButtonGroup>
                      <Button onClick={() => handleCancelBtn()}>Cancel</Button>
                      <Button variant="primary" onClick={() => handleAddCustomReview()}>Save</Button>
                    </ButtonGroup>
                  </InlineStack>
                </BlockStack>
              </Box>
              :
              <Box paddingBlockStart={300}>
                <InlineStack blockAlign='center' align='space-between'><label>Import reviews</label><Button onClick={() => getDemoCsv()} variant='monochromePlain' icon={ArrowDownIcon}>Download CSV template</Button></InlineStack>
                <Card padding={400}>
                  <input type='file' onChange={(e) => CSVfile(e)} />
                </Card>
                <Box align='end' paddingBlockStart={300}><Button onClick={() => uploadCsvFile()} disabled={value.importedFile === '' ? true : false}>Upload</Button></Box>

              </Box>}
          </Modal.Section>
        </Modal>
      }

      {/* Reviewer image modal  */}
      {value.isIconModal === true &&
        <Modal
          size="large"
          open={value.isIconModal}
          title={<Text variant='headingMd' fontWeight='bold'>Upload icon</Text>}
          onClose={(e) => { openCloseModal('isIconModal', value.isIconModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={300}>
              <InlineStack blockAlign='center' align='space-between'>
                <Box width='92%'>
                  <Box id='img_drops' paddingBlockStart={100}>
                    <DropZone accept="image/*" type='image' onDrop={handleDropZone}>
                      <InlineStack align='center' blockAlign='center'>
                        <InlineStack align='center' blockAlign='center'>

                          {value.iconUrl || value?.uploadedUrl?.length > 0 ? value?.uploadedUrl?.map((img, i) => (
                            <InlineStack align="center" key={i}>
                              <Thumbnail size="large" alt={'Logo'}
                                source={validImageTypes.includes(img.type)
                                  ? img ? window.URL.createObjectURL(img)
                                    : value.iconUrl : NoteIcon} />
                            </InlineStack>
                          ))
                            :
                            <Button><Text tone='base' fontWeight='semibold'>Browse</Text></Button>
                          }

                        </InlineStack>
                      </InlineStack>
                    </DropZone>
                  </Box>
                </Box>
                <Button variant='primary' onClick={() => uploadIconfile()}>Upload</Button>
              </InlineStack>
            </Box>


            <Box paddingBlockStart={300}>
              {(value?.myIcon && value?.myIcon.length > 0) &&
                <BlockStack gap={100}>
                  <Text fontWeight='semibold' tone='subdued'>My icon</Text>
                  <InlineGrid columns={{ xl: 8, lg: 8, md: 3, sm: 2, xs: 1 }} gap={400}>
                    {value?.myIcon?.map((val, key) => {
                      return (
                        <Box id='iconWrap' background="bg-surface" minHeight='115px' borderRadius="200" borderColor="border" borderWidth="025" padding="400" key={key}>
                          <img onClick={() => handleImgChange(val.iu)} className='iconImg' src={val.iu} alt="Icons" />
                          <Button id='iconDelete' variant='monochromePlain' tone='critical' onClick={() => deleteMediaIcon(val._id, val.iu)}>
                            <Icon tone='critical' source={DeleteIcon}></Icon></Button>
                        </Box>
                      );
                    })}
                  </InlineGrid>
                </BlockStack>
              }
            </Box>
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default ImportReviewsCard;