import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/analytics';
import { toastify } from '../ducks/toast';

function* getEsAnalytic(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getEsAnalytic?ti=' + action.payload.ti +
      '&fDate=' + action.payload.fDate +
      '&tDate=' + action.payload.tDate +
      '&nid=' + action.payload.nid +
      '&pfd=' + action.payload.pfd +
      '&ptd=' + action.payload.ptd +
      '&isClearRadis=' + action.payload.isClearRadis +
      '&rt=' + action.payload.rt +
      '&istm=' + localStorage.getItem('istm') +
      '&ht=' + action.payload.ht);
      
    if (res.status === 'success') {
      let finalRes = {};
      let objAnalytic = {
        clk: [],
        cls: [],
        dt: [],
        eng: [],
        hv: [],
        im: [],
        uqv: [],
        sum: {},
      };

      let PreAnalytic = { sum: {}, };
      if (res.data) {
        if (res.data.graph) {
          for (let i = 0; i < res.data.graph.length; i++) {
            objAnalytic.clk.push(res.data.graph[i].clk);
            objAnalytic.cls.push(res.data.graph[i].cls);
            objAnalytic.dt.push(res.data.graph[i].dt);
            objAnalytic.eng.push(res.data.graph[i].eng);
            objAnalytic.hv.push(res.data.graph[i].hv);
            objAnalytic.im.push(res.data.graph[i].im);
            objAnalytic.uqv.push(res.data.graph[i].uqv);
          }

          for (let j = 0; j < res.data.total.length; j++) {
            objAnalytic.sum[res.data.total[j].type] = res.data.total[j].current;
            PreAnalytic.sum[res.data.total[j].type] = res.data.total[j].previous;
          }

          PreAnalytic.sum['sgup'] = res.data.preSignupCount;
          finalRes = {
            Analytic: objAnalytic,
            PreAnalytic: PreAnalytic,
            signupCount: res.data.signupCount
          };
        }
      }
      yield put(actions.getEsAnalyticSuccess(finalRes));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getVisitHistoryPr(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getVisitHistoryPr', action.payload);

    if (res.status === 'success') {
      yield put(actions.getVisitHistoryPrSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//get Analytic signup data count
function* getSignupDataCount(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getSgupCount?nid=' + action.payload.nid +
      '&fDate=' + action.payload.fDate +
      '&tDate=' + action.payload.tDate +
      '&rt=' + action.payload.rt);
    if (res.status === 'success') {
      yield put(actions.getSignupDataCountSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetEsAnalytic() {
  yield takeLatest(actions.getEsAnalytic.type, getEsAnalytic);
}

export function* watchGetVisitHistoryPr() {
  yield takeLatest(actions.getVisitHistoryPr.type, getVisitHistoryPr);
}

export function* watchGetSignupDataCount() {
  yield takeLatest(actions.getSignupDataCount.type, getSignupDataCount);
}

export default function* rootSaga() {
  yield fork(watchGetEsAnalytic);
  yield fork(watchGetVisitHistoryPr);
  yield fork(watchGetSignupDataCount);
}
