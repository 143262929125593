import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const manageTeam = createSlice({
  name: 'manageTeam',
  initialState,
  reducers: {
    getUserTeam: (state, action) => { },
    getUserTeamSuccess: (state, action) => {
      return { ...state, getUserTeamList: action.payload };
    },
    addTeammate: (state, action) => { },
    getRoleList: (state, action) => { },
    getRoleListSuccess: (state, action) => {
      return { ...state, getRoleList: action.payload };
    },
    deleteTeamMember: (state, action) => { },
    getUserNotif: (state, action) => { },
    getUserNotifSuccess: (state, action) => {
      return { ...state, getUserNotifList: action.payload }
    },
    updTeamMember:(state,action) => {},

  }
});

export const {
  getUserTeam,
  getUserTeamSuccess,
  addTeammate,
  getRoleList,
  getRoleListSuccess,
  deleteTeamMember,
  getUserNotif,
  getUserNotifSuccess,
  updTeamMember
} = manageTeam.actions;

export default manageTeam.reducer;
