import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const header = createSlice({
  name: 'header',
  initialState,
  reducers: {
    getActivity: (state, action) => { },
    getActivitySuccess: (state, action) => {
      return { ...state, activity: action.payload };
    },
    updateUserClick: (state, action) => { },
    updUserView: (state, action) => { },
  }
});

export const {
  getActivity,
  getActivitySuccess,
  updateUserClick,
  updUserView,
} = header.actions;

export default header.reducer;