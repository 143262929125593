import React from 'react';

function List(props) {
  return (
    <div>
      User List
    </div>
  );
}

export default List;