import React, { useState, useCallback, useEffect, } from "react";
import { DataPageCard } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import * as dataDucks from "../../ducks/dataPage";
import { Box, OptionList, TextField, useIndexResourceState, Autocomplete, Icon } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import "./DataPage.css";
import moment from 'moment';
import { toastify } from '../../ducks/toast';
import { checkPermission } from '../../services/permissions';
import { encrypt } from "../../utils/passwordService";

const DataPage = () => {
  let Tdata = [];
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isLast: true,
    ps: 30,//page size
    md: {
      fm: new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0),
      to: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 59),
    },
    queStatus: "",//tab value
    sE: "",//Name
    dTyp: "",//OptionList
    selected: 0,//tab selected
    paggiActive: 1,//page number
    TotalData: [],
    ttlDtCnt: 0,//use for check data is availabel for next page or not
    queryValue: "",//large searchbar in indextable

    popoverActive: false,
    frmdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    tdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),

    sPN: "",//Product Name
    sCU: "",//Capture Page
    sSoC: "",//State or Country
    fuid: '',//selected source or connection name (id)
    sourceL: "",//source or connection name (label)
    filteredOptions: [],//Autocomplete
    sourceNotif: "",//notification state
    filteredNotif: [],//notif options
    nid: "",//notif id

    dataId: '',//id
    deleteDataModal: false,
    viewDataModal: false,
    EditDataModal: false,
    viewD: '',//viewModalData
    styp: '',//edit modal => Reviewer Source(value)
    rSource: false,
    editD: '',//edit modal object
    e: '',
    apiD: false,//api cond.
    Totalgndfs: [],

    ExcelModal: false,
    pd: '',//password
    isUrl: false,
    isCustomDate: false,
    inteDataList: [],
    isReloadIntData: true,//for empty state (skeleton)
    hasRefresh: false,//for refresh button condition
  });

  //set data in state
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //get data from store
  let excel = useSelector((state) => state.data?.checkPass);
  let dataList = useSelector((state) => state.data?.dataPage);
  let datalistStatus = dataList?.data
  let gndfsList = useSelector((state) => state.data?.gndf);
  let inteData = useSelector((state) => state.data?.userInte);
  let profileData = useSelector(state => state.auth?.profile);
  let isLoading = useSelector(state => state?.loading?.isLoading);

  //set data in state
  useEffect(() => {
    if (dataList && dataList?.data) {
      changeNameValue({ TotalData: dataList?.data?.slice(0, 30) });
      changeNameValue({ ttlDtCnt: dataList?.data?.length });
    }
    if (gndfsList) {
      changeNameValue({ Totalgndfs: gndfsList });
    }
    if (inteData) {
      changeNameValue({ inteDataList: inteData });
    }
  }, [dataList, gndfsList, inteData]);

  //for disable button
  useEffect(() => {
    if (state.TotalData.length === 0) {
      changeNameValue({ isUrl: true });
    } else {
      changeNameValue({ isUrl: false });
    }
  }, [state.TotalData]);

  useEffect(() => {
    dispatch(dataDucks.getNotifForSignup());
    dispatch(dataDucks.getUserInte());
  }, [])

  //True condition object
  let data = {
    isLast: state.isLast,
    pn: state.paggiActive,
    ps: state.ps,
    md: state.md,
  }

  //selected resources
  Tdata = state.TotalData.map(x => ({ ...x, id: x._id }));
  const { selectedResources, handleSelectionChange } = useIndexResourceState(Tdata);

  //deleteBulkSignUp api for delete all selected data
  const actionListClick = (typ) => {
    let pid = [];
    selectedResources.forEach((id) => {
      pid.push(id);
    });
    // Remove duplicates from arrays
    pid = Array.from(new Set(pid));
    let b = {
      isLast: state.isLast,
      pn: state.paggiActive,
      ps: state.ps,
      md: state.md,
    };
    let data = {
      b: b,
      array: pid.map(id => ({ _id: id }))
    };
    dispatch(dataDucks.deleteBulkSignUp(data));//all selected data delete api
    selectedResources.splice(0, selectedResources.length); // Clear selected resources
    if (typ === 'delete') {
      openCloseModal('deleteDataModal', state.deleteDataModal, 'close');
    }
  };

  //main datapage tabs
  const statusTabs = [
    { label: "All data", value: "" },
    { label: "Custom date", value: "1" },
    { label: "Refresh", value: "2" },
  ]

  const tabs = statusTabs?.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
    onAction: () => queStatusClick(item.value),
  }));

  const queStatusClick = useCallback((typ) => {
    let stateObj = {
      queStatus: typ,
      // paggiActive: 1,
    };
    setState((prevState) => ({ ...prevState, ...stateObj }));
  }, [state.queStatus]);

  //selected tab(custom date)
  useEffect(() => {
    changeNameValue({ popoverActive: (state.selected === 1) ? true : false });
  }, [state.selected]);

  // for Refresh (same tab click)
  if (document.getElementById("Refresh-2")) {
    document.getElementById("Refresh-2").onclick = function () {
      let obj;
      if (!state.isCustomDate) {
        obj = data;
      }
      else {
        const { sd, ed } = getFormattedDateRange(state.frmdt, state.tdt);
        obj = {
          isLast: false,
          pn: state.paggiActive,
          ps: state.ps,
          sd: sd,
          ed: ed,
        };
      }
      dispatch(dataDucks.getDataPage(obj));
    };
  }

  // all Data
  if (document.getElementById("All data-0")) {
    document.getElementById("All data-0").onclick = function () {
      let obj;
      if (state.isCustomDate) {
        obj = data;
        dispatch(dataDucks.getDataPage(obj));
      }
      else {
        changeNameValue({ paggiActive: 1, ps: 10, md: state.md, isCustomDate: false });
      }
    };
  }

  // for custom date popup open (same tab click, event is not coming)
  if (document.getElementById("Custom date-1")) {
    document.getElementById("Custom date-1").onclick = function () {
      changeNameValue({ popoverActive: true });
    };
  }

  //get formatted date
  const getFormattedDateRange = (fromDate, toDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    endDate.setHours(23, 59, 59, 59);
    return {
      sd: moment(startDate).valueOf(),
      ed: moment(endDate).valueOf(),
    };
  };

  const handleSelectDate = () => {
    changeNameValue({ popoverActive: false, isCustomDate: true, paggiActive: 1 });
    if (state.paggiActive === 1) {
      callSignUpDataApiTwo();
    }
    //callSignUpDataApiTwo();
  };

  // call getSignUpData-api
  const callSignUpDataApi = () => {
    const obj = {
      isLast: state.isLast,
      pn: state.paggiActive,
      ps: state.ps,
      md: state.md,
      ...(state.sE && { sE: state.sE }),
      ...(state.sPN && { sPN: state.sPN }),
      ...(state.sCU && { sCU: state.sCU }),
      ...(state.sSoC && { sSoC: state.sSoC }),
      ...(state.dTyp && { dTyp: state.dTyp }),
      ...(state.fuid && { fa: [{ fuid: state.fuid }] }),
      ...(state.nid && { nid: state.nid })
    }
    dispatch(dataDucks.getDataPage(obj));
  }

  // call getSignUpData-api
  const callSignUpDataApiTwo = () => {
    const { sd, ed } = getFormattedDateRange(state.frmdt, state.tdt);
    const obj = {
      isLast: false,
      pn: state.paggiActive,
      ps: state.ps,
      sd: sd,
      ed: ed,
    }
    dispatch(dataDucks.getDataPage(obj));
  }

  useEffect(() => {
    if (state.selected === 1) {
      callSignUpDataApiTwo();
    }
    else {
      if (state.isLast === true) {
        if (state.sE || state.sPN || state.sCU || state.sSoC) {
          let queryTimer = setTimeout(() =>
            callSignUpDataApi()
            , 1000);
          changeNameValue({ isUrl: true });
          return () => clearTimeout(queryTimer);
        } else {
          if (state.nid === "all" || state.fuid === "all") {
            dispatch(dataDucks.getDataPage(data));
            changeNameValue({ isUrl: false });
          } else {
            callSignUpDataApi();
            changeNameValue({ isUrl: false });
          }
        }
      }
    }
  }, [state.paggiActive, state.fd, state.td, state.dTyp, state.sE, state.sPN, state.sCU, state.sSoC, state.fuid, state.nid, state.hasRefresh]);

  //get data from getUserInte-api
  const extractedData = inteData?.map(item => ({
    id: item._id,
    value: item._id ?? '',
    label: [item.inm, item.rmk, item.d?.acn, item.d?.name, item.d?.shop].filter(Boolean).join(' | '),
  })) || [];

  extractedData.unshift({ id: 'all', value: 'all', label: 'All' });

  //selected source or connection name
  const handleOptionChange = (selected) => {
    const selectedOption = extractedData.find(option => option.value === selected[0]);
    if (selectedOption) {
      changeNameValue({ fuid: selectedOption.id, paggiActive: 1, sourceL: selectedOption.label, nid: "" });
    }
  };

  //perticular data search (source or connection name)
  const handleTextChange = useCallback((inputValue) => {
    if (inputValue.length > 0) {
      changeNameValue({ isUrl: true });
    }
    else {
      changeNameValue({ isUrl: false });
    }
    const filterRegex = new RegExp(inputValue, 'i');
    const resultOptions = extractedData.filter((option) =>
      option.label.match(filterRegex)
    );
    changeNameValue({ sourceL: inputValue, filteredOptions: resultOptions });
  }, [extractedData]);

  //selected source or connection name
  const handleNotifOptionChange = (selected) => {
    const selectedItem = gndfsids.find((item) => item.value === selected[0]);
    changeNameValue({ nid: selected[0], sourceNotif: selectedItem?.label, fuid: "" })
  };

  //onfocus source option
  const sourceOption = () => {
    const filterRegex = new RegExp('i');
    const resultOptions = extractedData.filter((option) =>
      option.label.match(filterRegex)
    );
    resultOptions.unshift({ id: 'all', value: 'all', label: 'All' });
    changeNameValue({ filteredOptions: resultOptions });
  }

  //text field for search by source...
  const textField = (
    <Autocomplete.TextField
      onChange={handleTextChange}
      value={state.sourceL || ''}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search by source..."
      autoComplete="off"
      onFocus={sourceOption}
    />
  );

  //Social proof notification list
  const ntt = ["All", "purchase_recent", "purchase_bulk", "signup_recent", "signup_bulk", "review_recent", "review_bulk", "social_media_count", "social_media_post", "recent_view_bulk",
    "video_subscriber", "channel_subscriber", "purchase_bulk_ecomm_snippet", "purchase_bulk_ecomm_snippet", "purchase_bulk_ecomm_snippet", "purchase_recent_ecomm_snippet"
  ]

  //option-list for notification
  const gndfsids = gndfsList?.map((item) => ({
    value: item._id,
    label: item.nn,
    ntt: item.ntt
  })) || [];

  gndfsids.unshift({ value: 'all', label: 'All', ntt: 'All' });

  //handle notification search
  const handlenotifText = useCallback((inputValue) => {
    const filterRegex = new RegExp(inputValue, 'i');
    const matchedValues = gndfsids.filter(x =>
      ntt.includes(x.ntt) && filterRegex.test(x.label)
    );
    changeNameValue({ sourceNotif: inputValue, filteredNotif: matchedValues });
  }, [gndfsList]);

  //notification search
  useEffect(() => {
    if (!inteData) return;
    const hasChanges = state.TotalData.some(obj => {
      const fuid = obj.fuid;
      const objInt = inteData.find(x => x._id === fuid);
      return obj.rmk !== (objInt ? objInt.rmk : undefined);
    });
    if (hasChanges) {
      const updatedTotalData = state.TotalData.map(obj => {
        const fuid = obj.fuid;
        const objInt = inteData.find(x => x._id === fuid);
        return {
          ...obj,
          rmk: objInt ? objInt.rmk : undefined,
        };
      });
      changeNameValue({ TotalData: updatedTotalData });
    }
  }, [state.TotalData]);

  //onfocus notification option
  const notificationOPtion = () => {
    changeNameValue({ filteredNotif: gndfsids });
  }

  //text field for search by notification...
  const textFieldNotif = (
    <Autocomplete.TextField
      onChange={handlenotifText}
      value={state.sourceNotif || ''}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search by notification..."
      autoComplete="off"
      onFocus={notificationOPtion}
    />
  );

  //clear all filters
  const handleFiltersClearAll = () => {
    let obj = {
      sE: '',
      dTyp: '',
    }
    changeNameValue(obj);
  }

  // paggination
  const onPaggiCall = (i) => {
    let num = 0;
    if (i === '+1') {
      num = state.paggiActive + 1;
    } else if (i === '-1') {
      num = state.paggiActive - 1;
    } else {
      num = i;
    }
    changeNameValue({ paggiActive: num });
  }

  //open close modal
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValue({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handleDeleteData = (id) => {
    openCloseModal('deleteDataModal', state.deleteDataModal, 'open');
    changeNameValue({ dataId: id });
  }

  //delete call api 
  const handleDeleteCall = (id) => {
    let obj = {
      _id: id,
      b: data
    };
    dispatch(dataDucks.delSignupData(JSON.stringify(obj)));
    setState(prevState => ({ ...prevState, TotalData: prevState.TotalData.filter(item => item._id !== id) }));
    openCloseModal('deleteDataModal', state.deleteDataModal, 'close');
  };

  //open ExcelModal modal
  const handleExcelData = () => {
    openCloseModal('ExcelModal', state.ExcelModal, 'open');
    changeNameValue({ pd: '' });
  }

  //check pass api
  const handleExcelCall = () => {
    if (state.pd === "") {
      return dispatch(toastify({ type: 'error', msg: "Enter your password!" }));
    }
    const encryptedPass = encrypt(state.pd);
    dispatch(dataDucks.checkPass({ pd: encryptedPass }));
  }

  //excel dawnload api
  useEffect(() => {
    if (excel?.isMatch === "yes") {
      let csvParams;
      if (!state.isCustomDate) {
        csvParams = {
          fp: "csv",
          isLast: state.isLast,
          pn: state.paggiActive,
          ps: state.ps,
          md: state.md,
          ...(state.dTyp && { dTyp: state.dTyp }),
        }
      } else {
        const { sd, ed } = getFormattedDateRange(state.frmdt, state.tdt);
        csvParams = {
          fp: "csv",
          isLast: false,
          pn: state.paggiActive,
          ps: state.ps,
          sd: sd,
          ed: ed
        };
      }
      dispatch(dataDucks.getDataForCSV(csvParams));
      openCloseModal('ExcelModal', state.ExcelModal, 'close');
    }
    else if (excel?.isMatch === "no") {
      dispatch(toastify({ type: 'error', msg: "Wrong password. Please try again." }));
    }
  }, [excel]);

  //edit call api
  const handleEditCall = (id) => {
    const obj = {
      b: data,
      _id: id,
      fn: state.editD.fn,
      ln: state.editD.ln,
      un: state.editD.un,
      ct: state.editD.ct,
      st: state.editD.st,
      cn: state.editD.cn,
      styp: state.styp,
      e: state.e,
    }
    dispatch(dataDucks.updateSignupData(obj));
    // Update the TotalData in the state
    setState((prevState) => ({ ...prevState, TotalData: prevState.TotalData.map(item => item._id === id ? { ...item, ...obj } : item) }));
    openCloseModal('EditDataModal', state.EditDataModal, 'close');
    changeNameValue({ styp: '' });
  };

  //open edit modal
  const handleEditData = (data, id) => {
    openCloseModal('EditDataModal', state.EditDataModal, 'open');
    changeNameValue({ editD: { ...data }, dataId: id, styp: data.styp });
  }

  //handle main text change
  const handleMainTextChange = useCallback((value) => {
    console.log(`Value: ${value}`);
  }, []);

  //open view data modal
  const handleViewData = (data) => {
    openCloseModal('viewDataModal', state.viewDataModal, 'open');
    const gndfsids = state.Totalgndfs.map((item) => item._id);
    const isMatch = data.cnid.some((id) => gndfsids.includes(id));
    const cnidD = isMatch ? data.cnid.map((id) => state.Totalgndfs.find((item) => item._id === id)?.nn) : [];
    const obj = { ...data, createdDate: new Date(data.insdt).toLocaleString(), cnid: cnidD };
    changeNameValue({ viewD: obj })
  };

  //drop-down list
  const dropdata = [
    { value: "all", label: "All" },
    { value: "signup", label: "Sign up" },
    { value: "purchase", label: "Purchase" },
    { value: "review", label: "Review" },
  ];

  const filters = [
    {
      key: "dTyp",
      label: (
        state.dTyp !== ""
          ? ` ${state.dTyp === 'all' ? 'All' : state.dTyp === 'signup' ? 'Sign Up' : state.dTyp === 'purchase' ? 'Purchase' : state.dTyp === 'review' ? 'Review' : 'All'}`
          : "Select data type"
      ),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
      filter: (
        <div>
          <Box>
            <OptionList
              title="Data list"
              onChange={(e) => changeNameValue({ dTyp: e[0], paggiActive: 1 })}
              options={dropdata}
              selected={state.dTyp}
            />
          </Box>
        </div>
      ),
    },
    {
      key: 'Search',
      label: (state.sE !== "" ? `Name: ${(state.sE)}` : "Name"),
      filter: (
        <TextField
          placeholder="Search by name..."
          value={state.sE}
          onChange={(value) => changeNameValue({ sE: value })}
          clearButton
          onClearButtonClick={() => changeNameValue({ sE: "" })}
        />
      ),
      pinned: true,
    },
    {
      key: 'sPN',
      label: (state.sPN !== "" ? `Product name: ${(state.sPN)}` : "Product name"),
      filter: (
        <TextField
          placeholder="Search by product name..."
          value={state.sPN}
          onChange={(value) => changeNameValue({ sPN: value })}
          clearButton
          onClearButtonClick={() => changeNameValue({ sPN: "" })}
        />
      ),
      pinned: true,
    },
    {
      key: 'sCU',
      label: (state.sCU !== "" ? `Capture page: ${(state.sCU)}` : "Capture page"),
      filter: (
        <TextField
          placeholder="Search by capture page..."
          value={state.sCU}
          onChange={(value) => changeNameValue({ sCU: value })}
          clearButton
          onClearButtonClick={() => changeNameValue({ sCU: "" })}

        />
      ),
      pinned: true,
    },
    {
      key: 'sSoC',
      label: (state.sSoC !== "" ? `State or Country: ${(state.sSoC)}` : "State or Country"),
      filter: (
        <TextField
          placeholder="Search by state or country..."
          value={state.sSoC}
          onChange={(value) => changeNameValue({ sSoC: value })}
          clearButton
          onClearButtonClick={() => changeNameValue({ sSoC: "" })}
        />
      ),
      pinned: true,
    },
    {
      key: 'sourceL',
      label: (state.sourceL !== "" ? `Search by ${(state.sourceL)}` : "Search by source or connection name"),
      filter: (
        <Autocomplete
          options={state.filteredOptions}
          selected={state.sourceL ? [state.sourceL] : []}
          onSelect={handleOptionChange}
          textField={textField}
        />
      ),
      pinned: true,
    },
    {
      key: 'sourceNotif',
      label: (state.sourceNotif !== "" ? `Search by ${(state.sourceNotif)}` : "Search by notification name"),
      filter: (
        <Autocomplete
          options={state.filteredNotif}
          selected={state.sourceNotif ? [state.sourceNotif] : []}
          onSelect={handleNotifOptionChange}
          textField={textFieldNotif}
        />
      ),
      pinned: true,
    },
  ];


  return (
    <>
      {
        checkPermission('data', 'isview', profileData) &&
        <DataPageCard
          tabs={tabs}
          state={state}
          filters={filters}
          changeNameValue={changeNameValue}
          handleSelectDate={handleSelectDate}
          onPaggiCall={onPaggiCall}
          handleFiltersClearAll={handleFiltersClearAll}
          openCloseModal={openCloseModal}
          handleDeleteData={handleDeleteData}//open delete modal
          handleDeleteCall={handleDeleteCall}// //delete call api 
          handleViewData={handleViewData}//open view modal
          handleEditCall={handleEditCall}//edit call api
          handleEditData={handleEditData}//open edit modal
          handleMainTextChange={handleMainTextChange}
          selectedResources={selectedResources}
          handleSelectionChange={handleSelectionChange}
          actionListClick={actionListClick}
          handleExcelData={handleExcelData}//open excel modal
          handleExcelCall={handleExcelCall}//excel call api
          profile={profileData}
          checkPermission={checkPermission}
          isLoading={isLoading}
          datalistStatus={datalistStatus}
        />
      }
    </>
  );
};

export default DataPage;
