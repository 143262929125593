import React, { useEffect } from 'react';
import { Autocomplete, Banner, BlockStack, Box, Button, ButtonGroup, Card, Collapsible, Divider, Icon, InlineGrid, InlineStack, Layout, Modal, Page, PageActions, SkeletonDisplayText, Tag, Text, TextField, Tooltip } from '@shopify/polaris';
import { bigCommerce_icon, inline_ctlg, inline_notif_1, inline_notif_2, inline_notif_3, inline_pdp, magento_icon, shopify_icon, wooCommerce_icon } from '../../img';
import { useDispatch } from 'react-redux';
import { ChevronDownIcon, ChevronUpIcon, ClipboardIcon, CodeIcon, PlusIcon, TextAlignLeftIcon, XIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import DesignCard from '../NotifEdit/DesignCard';
import { checkPermission } from '../../services/permissions';
import { toastify } from '../../ducks/toast';

function AddNotifCard(props) {
  const { state, changeNameValue } = props;
  const dispatch = useDispatch();

  const SocialTabs = [
    { tabName: 'all', label: 'All' },
    { tabName: 'sales', label: 'Sales Activities' },
    { tabName: 'conversion', label: 'Lead Activities' },
    { tabName: 'review', label: 'Customers Reviews' },
    { tabName: 'social media', label: 'Social Media Posts' },
    { tabName: 'live', label: 'Visitor Activity' },
    { tabName: 'video', label: 'YouTube Stats' },
    { tabName: 'limited stock', label: 'Limited Stock' },
  ];

  const widgetTabs = [
    { tabName: 'all', label: 'All', ctg: 'all', type: 0 },
    { tabName: 'ct', label: 'Countdown ', ctg: 'cta', type: 1 },
    { tabName: 'custom', label: 'Announcements', ctg: 'custom', type: 2 },
    { tabName: 'cta', label: 'Call To Actions', ctg: 'cta', type: 3 },
    { tabName: 'collector', label: 'Collect Emails', ctg: 'collector', type: 4 },
    { tabName: 'feed', label: 'Review Feed', ctg: 'feed', type: 5 },
    { tabName: 'lottery', label: 'Create Lottery', ctg: 'lottery', type: 6 },
    { tabName: 'others', label: 'Social Media Counter', ctg: 'others', type: 7 },
  ];

  const tabs = [
    { tabName: 'shopify', icon: shopify_icon, label: 'Shopify' },
    { tabName: 'wooCommerce', icon: wooCommerce_icon, label: 'WooCommerce' },
    { tabName: 'magento', icon: magento_icon, label: 'Magento' },
    { tabName: 'bigCommerce', icon: bigCommerce_icon, label: 'BigCommerce' }
  ];

  const codePdpLinks = {
    shopify: `<div data-id="{{ product.id }}" class="wn_ecomm_embed_pdp_{{ product.id }} wn_ecomm_snippet pdp"></div>`,
    wooCommerce: `<div data-id="<?php the_ID();?>" class="wn_ecomm_embed_pdp_<?php the_ID();?> wn_ecomm_snippet pdp"></div>`,
    magento: `<div data-id="<?php echo $_product->getId();?>" class="wn_ecomm_embed_pdp_<?php echo $_product->getId();?> wn_ecomm_snippet pdp"></div>`,
    bigCommerce: `<div data-id="{{product.id}}" class="wn_ecomm_embed_pdp_{{product.id}} wn_ecomm_snippet pdp"></div>`,
  };

  const codeCtgLinks = {
    shopify: `<div data-id="{{ product.id }}" class="wn_ecomm_embed_{{ product.id }} wn_ecomm_snippet catalog"></div>`,
    wooCommerce: `<div data-id="<?php the_ID();?>" class="wn_ecomm_embed_<?php the_ID();?> wn_ecomm_snippet catalog"></div>`,
    magento: `<div data-id="<?php echo $_product->getId();?>" class="wn_ecomm_embed_<?php echo $_product->getId();?> wn_ecomm_snippet catalog"></div>`,
    bigCommerce: `<div data-id="{{id}}" class="wn_ecomm_embed_{{id}} wn_ecomm_snippet catalog"></div>`,
  };

  const inlineList = [
    { label: 'Live visitor', img: inline_notif_1 },
    { label: 'Sold counter', img: inline_notif_2 },
    { label: 'Recent sales', img: inline_notif_3 }
  ];

  useEffect(() => {
    const codePdpLink = codePdpLinks[state.mainTabPdpIndex];
    const codeCtgLink = codeCtgLinks[state.mainTabCtgIndex];
    let obj = {
      codePdpLink: codePdpLink,
      codeCtgLink: codeCtgLink
    }
    changeNameValue(obj);
  }, [state.mainTabPdpIndex, state.mainTabCtgIndex]);

  const renderHTML = (data) => {
    let tempData = state.allTemplate && state.allTemplate.filter((x) => x._id === data.tmid)[0];
    return Parser(DesignCard(state, tempData));
  }

  const renderListHTML = (state, data) => {
    return Parser(DesignCard(state, data, 'listCard'));
  }

  const handleAddInline = () => {
    if (checkPermission('notification', 'isadd', state.profile)) {
      let obj = {
        notifCtgWebAdd: true,
        installCtgShow: false
      }
      changeNameValue(obj);
    }
    else {
      dispatch(toastify({ type: 'error', msg: 'you have not permission to create notification!' }));
    }
  }

  //autocomplete textField.
  const textField = (
    <Autocomplete.TextField
      label="Add website URL(s) & press the enter"
      value={state.ul}
      onChange={(e) => props.handleSugtTextChange(e)}
      autoComplete="off"
      autoFocus
      connectedRight={<Button onClick={() => props.handleAddWebsite()}>Add website</Button>}
    />
  );

  //autocomplete textInlnPdpField.
  const textInlnPdpField = (
    <div className='inline-add-url'>
      <Autocomplete.TextField
        label="Add your website"
        placeholder='Enter your website URL here'
        value={state.pdpUl}
        onChange={(e) => props.handleSugtInlnPdpTxtChange(e)}
        autoComplete="off"
        autoFocus
        connectedRight={
          <ButtonGroup>
            <Button id='primary_btn' variant='primary' onClick={() => props.handleCreateInlnNotif('detail')}>Add site</Button>
            <Button onClick={() => {
              let obj = {
                notifPdpWebAdd: false,
                pdpUl: ''
              }
              changeNameValue(obj)
            }}>Cancel</Button>
          </ButtonGroup>
        }
      />
    </div>
  );

  //autocomplete textInlnCtgField.
  const textInlnCtgField = (
    <div className='inline-add-url'>
      <Autocomplete.TextField
        label="Add your website"
        placeholder='Enter your website URL here'
        value={state.ctgUl}
        onChange={(e) => props.handleSugtInlnCtgTxtChange(e)}
        autoComplete="off"
        autoFocus
        connectedRight={
          <ButtonGroup>
            <Button id='primary_btn' variant='primary' onClick={() => props.handleCreateInlnNotif('catalog')}>Add site</Button>
            <Button onClick={() => {
              let obj = {
                notifCtgWebAdd: false,
                ctgUl: ''
              }
              changeNameValue(obj)
            }}>Cancel</Button>
          </ButtonGroup>
        }
      />
    </div>
  );

  //addsite skeleton
  useEffect(() => {
    const reloadData = {
      isReloadPdpData: props.isLoading && state.inlnPdpNotif.length > 0,
      isReloadCtgData: props.isLoading && state.inlnCtgNotif.length > 0
    };

    changeNameValue(reloadData);
  }, [props.isLoading, state.inlnPdpNotif, state.inlnCtgNotif]);

  return (
    <Page title={state.listTyp === 'inlinelist' ? `Manage inline notification` : `Select ${state.listTyp === 'widget' ? 'widget' : 'social proof'} type notifications`}
      backAction={state.listTyp !== 'inlinelist' ? { onAction: () => state.isConfiguration && props.handleBackToMain() } : false}>
      <Box paddingBlockEnd={400}>
        {state.listTyp === 'list' &&
          <Card>
            <BlockStack gap={400} id="select-noti-card">
              <ButtonGroup>
                {SocialTabs.map((d, i) => (
                  <Button key={i} variant={state.notifTabTyp === d.tabName ? 'tertiary' : ''}
                    onClick={() => {
                      let obj = {
                        notifTabTyp: d.tabName,
                        openNotifIndex: null,
                        ddn: []
                      }
                      changeNameValue(obj)
                    }}>
                    <Text fontWeight='medium' variant='headingSm' tone={state.notifTabTyp === d.tabName ? '' : 'subdued'} id='notif-tab-ttl'>{d.label}</Text>
                  </Button>
                ))}
              </ButtonGroup>
              <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
                {state.socialProofNotif && state.socialProofNotif.length > 0 && state.socialProofNotif.map((data, i) => (
                  <div key={i} className={state.openNotifIndex !== null && state.openNotifIndex !== i ? 'notif_bg' : ''}>
                    {state.openNotifIndex !== i ? (
                      <div className='card_bg' id='cursorPoint' onClick={() => props.handleAddSocialShow(data, i)}>
                        <div className='noti_img_block'>
                          <img className='noti_type_img' src={data.config.iu} alt='notif' />
                        </div>
                        <Box paddingBlockStart={200} paddingBlockEnd={100}>
                          <InlineStack gap={200}>
                            <Text variant='headingXs' as='p' fontWeight='semibold'>{data.nn}</Text>
                            {/* <div className='blt_clr'>
                              <List type="bullet">
                                <List.Item>Social Proof</List.Item>
                              </List>
                            </div> */}
                          </InlineStack>
                        </Box>
                        <div id='fix_hght'>
                          <Text tone="subdued" variant='headingXs' as='p' id='notif-desc'>{data.desc}</Text>
                        </div>
                        <Box paddingBlockStart={400}>
                          <Button onClick={() => props.handleAddSocialShow(data, i)}>Add</Button>
                        </Box>
                      </div>
                    ) : (
                      <Card background='bg-surface-secondary'>
                        <Box paddingBlockEnd={600}>
                          <Text variant='headingSm' as='p' fontWeight='semibold'>{`Let’s create ${data.nn} notification`}</Text>
                        </Box>
                        <BlockStack gap={300}>
                          <Box paddingBlockStart={200}>
                            <div id='notif_txt'>
                              <TextField
                                label="Name"
                                value={state.nn}
                                onChange={(value) => changeNameValue({ nn: value })}
                                autoComplete="off"
                              />
                            </div>
                          </Box>
                          <div id='notif_txt'>
                            <Autocomplete
                              options={state.ul ? state.options : []}
                              selected={state.ul ? [state.ul] : []}
                              onSelect={(value) => props.handleSugtOptionChange(value)}
                              textField={textField}
                            />
                          </div>
                          {state.ddn?.length > 0 && (
                            <InlineStack gap={200}>
                              {state.ddn?.map((val) => (
                                <Tag key={val} onRemove={() => props.handleRemoveWebsite(val)}>
                                  {val.ul}
                                </Tag>
                              ))}
                            </InlineStack>
                          )}
                          <Box paddingBlockStart={state.ddn.length > 0 ? 100 : 600}>
                            <ButtonGroup>
                              <Button variant='primary' tone='success' onClick={() => props.handleCreateNotif()} disabled={state.notifCreate}>Create & customize</Button>
                              <Button onClick={() => changeNameValue({ openNotifIndex: null })}>Cancel</Button>
                            </ButtonGroup>
                          </Box>
                        </BlockStack>
                      </Card>
                    )}
                  </div>
                ))}
              </InlineGrid>
            </BlockStack>
          </Card>
        }
        {state.listTyp === 'widget' &&
          <Card>
            <BlockStack gap={400}>
              <ButtonGroup>
                {widgetTabs.map((d, i) => (
                  <Button key={i} variant={state.notifWidgTabTyp === d.tabName ? 'tertiary' : ''}
                    onClick={() => {
                      let obj = {
                        notifWidgTabTyp: d.tabName,
                        openNotifIndex: null,
                        notifWidgetCtg: d.ctg,
                        notifWidgetTabTyp: d.type,
                        ddn: []
                      }
                      changeNameValue(obj)
                    }}>
                    <Text fontWeight='medium' variant='headingSm' tone={state.notifWidgTabTyp === d.tabName ? '' : 'subdued'} id='notif-tab-ttl'>{d.label}</Text>
                  </Button>
                ))}
              </ButtonGroup>
              <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
                {state.widgetNotif && state.widgetNotif.length > 0 && state.widgetNotif.map((data, i) => (
                  <div key={i} className={state.openNotifIndex !== null && state.openNotifIndex !== i ? 'notif_bg' : ''}>
                    {state.openNotifIndex !== i ? (
                      <div className='card_bg' id='cursorPoint' onClick={() => props.handleAddWidgetShow(data, i)}>
                        <div className='noti_img_block'>
                          <img className='noti_type_img' src={data.notif.iu} alt='notif' />
                        </div>
                        <Box paddingBlockStart={200}>
                          <Text variant='headingXs' as='p' fontWeight='semibold'>{data.notif.tn}</Text>
                        </Box>
                        <Box paddingBlockStart={400}>
                          <Button onClick={() => props.handleAddWidgetShow(data, i)}>Add</Button>
                        </Box>
                      </div>
                    ) : (
                      <Card background='bg-surface-secondary'>
                        <Box paddingBlockEnd={300}>
                          <Text variant='headingSm' as='p' fontWeight='semibold'>{`Let’s create ${data.notif.tn} notification`}</Text>
                        </Box>
                        <BlockStack gap={300}>
                          <div id='notif_txt'>
                            <TextField
                              label="Name"
                              value={state.nn}
                              onChange={(value) => changeNameValue({ nn: value })}
                              autoComplete="off"
                            />
                          </div>
                          <div id='notif_txt'>
                            <Autocomplete
                              options={state.ul ? state.options : []}
                              selected={state.ul ? [state.ul] : []}
                              onSelect={(value) => props.handleSugtOptionChange(value)}
                              textField={textField}
                            />
                          </div>
                          {state.ddn?.length > 0 && (
                            <InlineStack gap={200}>
                              {state.ddn?.map((val) => (
                                <Tag key={val} onRemove={() => props.handleRemoveWebsite(val)}>
                                  {val.ul}
                                </Tag>
                              ))}
                            </InlineStack>
                          )}
                          <Box paddingBlockStart={state.ddn?.length > 0 ? 100 : 200}>
                            <ButtonGroup>
                              <Button id='primary_btn' variant='primary' onClick={() => props.handleCreateNotif()} disabled={state.notifCreate}>Create & Customize</Button>
                              <Button onClick={() => changeNameValue({ openNotifIndex: null })}>Cancel</Button>
                            </ButtonGroup>
                          </Box>
                        </BlockStack>
                      </Card>
                    )}
                  </div>
                ))}
              </InlineGrid>
            </BlockStack>
          </Card>
        }
        {state.listTyp === 'inlinelist' &&
          <BlockStack gap={600}>
            {state.profile && state.profile.iprminlnsnpt !== true &&
              <Banner>
                <p>
                  Setup Fee: A one-time fee of $50 covers the integration, customized to your needs.
                </p>
                <Box paddingBlockStart={200}>
                  <ButtonGroup>
                    <Button onClick={() => props.openChat()}>Chat with us</Button>
                    <Button variant="primary" tone="success" onClick={() => props.handleCreateStripeLink()}>Pay now</Button>
                  </ButtonGroup>
                </Box>
              </Banner>
            }

            <Card>
              <Box paddingBlockEnd={400} paddingBlockStart={100}>
                <InlineStack align='space-between' blockAlign='center' wrap={false}>
                  <Text variant='headingMd' as='h4' fontWeight='semibold'>Product detail page</Text>
                  {state.inlnPdpNotif.length > 0 && !state.installPdpShow &&
                    <ButtonGroup>
                      <Button variant="tertiary" icon={CodeIcon} onClick={() => props.handleInstallPdpShowClose()}>Install now</Button>
                      {!state.notifPdpWebAdd &&
                        <Button variant='primary' icon={PlusIcon} onClick={() => {
                          let obj = {
                            notifPdpWebAdd: true,
                            installPdpShow: false
                          }
                          changeNameValue(obj);
                        }}>Add new site</Button>
                      }
                    </ButtonGroup>
                  }
                </InlineStack>
              </Box>
              <Layout>
                <Layout.Section variant="oneThird">
                  <div className='notif_card_bg'>
                    <img src={inline_pdp} alt='inline' width="100%" />
                    <Box maxWidth='90%' paddingBlockStart={100}>
                      <Text variant='headingSm' as='p' fontWeight='regular'>Display inline notifications on <span style={{ color: "#645CFC" }}>product detail page.</span></Text>
                    </Box>
                  </div>
                </Layout.Section>
                <Layout.Section>
                  {state.installPdpShow ?
                    <Card padding={0}>
                      <Box padding={600} background='bg-surface-secondary'>
                        <InlineStack align='space-between' wrap={false}>
                          <InlineStack gap={300} blockAlign='center' wrap={false}>
                            <Icon source={CodeIcon} />
                            <Box>
                              <Text variant='headingMd' as='p' fontWeight='semibold'>Copy the code and add it to your product page.</Text>
                              <Text variant='headingSm' as='p' fontWeight='regular'>Select a platform to get your unique code.</Text>
                            </Box>
                          </InlineStack>
                          <div id='cursorPointer' onClick={() => props.handleInstallPdpShowClose()}>
                            <Icon source={XIcon} />
                          </div>
                        </InlineStack>
                      </Box>
                      <Divider />
                      <Box padding={400}>
                        <BlockStack gap={400}>
                          <ButtonGroup>
                            {tabs.map((data, i) => (
                              <Button key={i} variant={state.mainTabPdpIndex === data.tabName ? 'tertiary' : ''}
                                onClick={() => changeNameValue({ mainTabPdpIndex: data.tabName })}
                              >
                                <InlineStack blockAlign='center' gap={100}>
                                  <img src={data.icon} alt='icon' />
                                  <Text fontWeight='medium' >{data.label}</Text>
                                </InlineStack>
                              </Button>
                            ))}
                          </ButtonGroup>
                          <div className='box-inlinenoti-code'>
                            <Box>
                              <BlockStack gap={200}>
                                <Text variant='headingMd' as='p' fontWeight='regular'>Here is your {state.mainTabPdpIndex} integration code:</Text>
                                <div className='box-inlinecopy'>
                                  <TextField
                                    readOnly
                                    multiline={4}
                                    value={state.codePdpLink}
                                    onFocus={(e) => e.target.select()}
                                    connectedRight={<Button id='primary_btn' variant='primary' icon={ClipboardIcon} onClick={() => props.handleCopyCode(state.codePdpLink)}>Copy code</Button>} />
                                </div>
                              </BlockStack>
                            </Box>
                          </div>
                          <Box id='box-inlinenoti-banner' paddingBlock={200}>
                            <Banner tone='info'><Text variant="bodySm" as="p">After adding the code, your product page will show real-time visits, sales, and sold counts.</Text></Banner>
                          </Box>
                          <Text alignment="end" variant='headingXs' as='p' fontWeight='regular'>Need help? our <Button variant='plain' onClick={() => props.openChat()}>live chat team</Button> is ready to assist you</Text>
                        </BlockStack>
                      </Box>
                    </Card>
                    :
                    state.notifPdpWebAdd ?
                      <Card background='bg-surface-secondary'>
                        {state.profile && state.profile.iprminlnsnpt === true &&
                          <Box paddingBlockEnd={400}>
                            <Autocomplete
                              options={state.pdpUl ? state.options : []}
                              selected={state.pdpUl ? [state.pdpUl] : []}
                              onSelect={(value) => props.handleSugtInlnPdpOptChange(value)}
                              textField={textInlnPdpField}
                            />
                          </Box>
                        }
                        <Box paddingBlockEnd={400}>
                          <Text tone='subdued' variant='headingSm' as='p' fontWeight='regular'>After adding your site, you'll show and be able to customize the following notifications:</Text>
                        </Box>
                        {inlineList && inlineList.map((data, index) => (
                          <Box key={index} paddingBlockEnd={index === inlineList.length - 1 ? 0 : 400}>
                            <Card>
                              <InlineStack blockAlign='center' gap={200} wrap={false}>
                                <Box width='17%'>
                                  <Text variant='headingMd' as='p' fontWeight='medium'>{data.label}</Text>
                                </Box>
                                <img src={data.img} alt='inline' />
                              </InlineStack>
                            </Card>
                          </Box>
                        ))}
                      </Card>
                      :
                      state.isReloadPdpData ?
                        <Card background='bg-surface-secondary'>
                          <Box paddingBlockEnd={400}>
                            <BlockStack gap={500}>
                              <Box paddingBlock={400}>
                                <SkeletonDisplayText size='medium' />
                              </Box>
                              <Box paddingBlockEnd={300}>
                                <Divider borderColor='border' />
                              </Box>
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                            </BlockStack>
                          </Box>
                        </Card>
                        :
                        state.inlnPdpNotif && state.inlnPdpNotif.length > 0 ?
                          <div className='inln_notif_bloack'>
                            {state.inlnPdpNotif.map((data, i) => (
                              <Box key={i} paddingBlockEnd={400}>
                                <Card background='bg-surface-secondary' padding={0}>
                                  <div id='cursorPoint' onClick={() => props.handleCollapsible(i, 'openPdpCollapsible')}>
                                    <Box padding={600}>
                                      <InlineStack gap={200} blockAlign='center' align='space-between'>
                                        <InlineStack gap={200} blockAlign='center'>
                                          <Box>
                                            <Icon source={TextAlignLeftIcon} />
                                          </Box>
                                          <Text variant='headingMd' as='h3' id='inln_domain_ttl'>{Object.keys(data)}</Text>
                                        </InlineStack>
                                        <Box>
                                          <Icon source={state.openPdpCollapsible[i] ? ChevronUpIcon : ChevronDownIcon}></Icon>
                                        </Box>
                                      </InlineStack>
                                    </Box>
                                  </div>
                                  <Collapsible open={state.openPdpCollapsible[i]} id="db_collaspe"
                                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                                    <div className='inln_bg'>
                                      <Box padding={400}>
                                        {Object.values(data)[0]?.map((data, i) => (
                                          <Box key={i} paddingBlockEnd={300}>
                                            <Card padding={0} roundedAbove='xs'>
                                              <Box paddingInline={400} paddingBlock={300}>
                                                <InlineStack align='space-between' blockAlign='center'>
                                                  <Box width='20%'>
                                                    <div className='inln_txt_wrap' id='cursorPointer'>
                                                      <Tooltip dismissOnMouseOut content={data.nn}>
                                                        <Text variant='headingMd' as='p' fontWeight='semibold'>{data.nn}</Text>
                                                      </Tooltip>
                                                    </div>
                                                  </Box>
                                                  <div className='inln_data'>
                                                    {/* {renderHTML(data)} */}
                                                    {renderListHTML(state, data)}
                                                  </div>
                                                  <label className="switch">
                                                    <input type="checkbox" checked={data.ia}
                                                      onChange={() => props.handleInlnUpdNotifStatus(data)} />
                                                    <span className="switch-label"></span>
                                                    <span className="switch-handle"></span>
                                                  </label>
                                                  <Box>
                                                    <Button id='primary_btn' variant='primary' onClick={() => props.handleEditNotif(data)}>Customize</Button>
                                                  </Box>
                                                </InlineStack>
                                              </Box>
                                            </Card>
                                          </Box>
                                        ))}
                                        <InlineStack align='end'>
                                          <Button variant='plain' tone='critical' onClick={() => { props.openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ selctdUl: Object.keys(data), selctdInlnNotifTyp: 'detail' }) }}>Delete</Button>
                                        </InlineStack>
                                      </Box>
                                    </div>
                                  </Collapsible>
                                </Card>
                              </Box>
                            ))}
                          </div>
                          :
                          <div className='notif_add_block'>
                            <BlockStack align='center' inlineAlign='center'>
                              <Text variant='headingMd' as='h4' fontWeight='semibold'>Build trust & urgency around product</Text>
                              <Text variant='headingSm' as='p' fontWeight='regular' alignment='center'>Show product specific live visits, recent sales & product sold counter</Text>
                              <Box paddingBlockStart={200}>
                                <ButtonGroup>
                                  {/* <Button variant="tertiary">Learn more</Button> */}
                                  <Button variant='primary' onClick={() => changeNameValue({ notifPdpWebAdd: true })}>Start creating inline</Button>
                                </ButtonGroup>
                              </Box>
                            </BlockStack>
                          </div>
                  }
                </Layout.Section>
              </Layout>
            </Card>
            <Card>
              <Box paddingBlockEnd={400} paddingBlockStart={100}>
                <InlineStack align='space-between' blockAlign='center' wrap={false}>
                  <Text variant='headingMd' as='h4' fontWeight='semibold'>Collection page</Text>
                  {state.inlnCtgNotif.length > 0 && !state.installCtgShow &&
                    <ButtonGroup>
                      <Button variant="tertiary" icon={CodeIcon} onClick={() => props.handleInstallCtgShowClose()}>Install now</Button>
                      {!state.notifCtgWebAdd &&
                        <Button variant='primary' icon={PlusIcon} onClick={() => handleAddInline()}>Add new site</Button>
                      }
                    </ButtonGroup>
                  }
                </InlineStack>
              </Box>
              <Layout>
                <Layout.Section variant="oneThird">
                  <div className='notif_card_bg'>
                    <img src={inline_ctlg} alt='inline' width="100%" />
                    <Box maxWidth='90%' paddingBlockStart={100}>
                      <Text variant='headingSm' as='p' fontWeight='regular'>Display inline notifications on <span style={{ color: "#645CFC" }}>product catalog page.</span></Text>
                    </Box>
                  </div>
                </Layout.Section>
                <Layout.Section>
                  {state.installCtgShow ?
                    <Card padding={0}>
                      <Box padding={600} background='bg-surface-secondary'>
                        <InlineStack align='space-between' wrap={false}>
                          <InlineStack gap={300} blockAlign='center' wrap={false}>
                            <Icon source={CodeIcon} />
                            <Box>
                              <Text variant='headingMd' as='p' fontWeight='semibold'>Copy the code and add it to your collection page.</Text>
                              <Text variant='headingSm' as='p' fontWeight='regular'>Select a platform to get your unique code.</Text>
                            </Box>
                          </InlineStack>
                          <div id='cursorPointer' onClick={() => props.handleInstallCtgShowClose()}>
                            <Icon source={XIcon} />
                          </div>
                        </InlineStack>
                      </Box>
                      <Divider />
                      <Box padding={400}>
                        <BlockStack gap={400}>
                          <ButtonGroup>
                            {tabs.map((data, i) => (
                              <Button key={i} variant={state.mainTabCtgIndex === data.tabName ? 'tertiary' : ''}
                                onClick={() => changeNameValue({ mainTabCtgIndex: data.tabName })}
                              >
                                <InlineStack blockAlign='center' gap={100}>
                                  <img src={data.icon} alt='icon' />
                                  <Text fontWeight='medium' >{data.label}</Text>
                                </InlineStack>
                              </Button>
                            ))}
                          </ButtonGroup>
                          <div className='box-inlinenoti-code'>
                            <Box>
                              <BlockStack gap={200}>
                                <Text variant='headingMd' as='p' fontWeight='regular'>Here is your {state.mainTabCtgIndex} integration code:</Text>
                                <div className='box-inlinecopy'>
                                  <TextField
                                    readOnly
                                    multiline={4}
                                    value={state.codeCtgLink}
                                    onFocus={(e) => e.target.select()}
                                    connectedRight={<Button id='primary_btn' variant='primary' icon={ClipboardIcon} onClick={() => props.handleCopyCode(state.codeCtgLink)}>Copy code</Button>} />
                                </div>
                              </BlockStack>
                            </Box>
                          </div>
                          <Box id='box-inlinenoti-banner' paddingBlockEnd={200} paddingBlockStart={200}>
                            <Banner tone='info'><Text variant="bodySm" as="p" >After adding the code, your collection page will show real-time visits, sales, and sold counts.</Text></Banner>
                          </Box>
                          <Text alignment="end" variant='headingXs' as='p' fontWeight='regular'>Need help? our <Button variant='plain' onClick={() => props.openChat()}>live chat team</Button> is ready to assist you</Text>
                        </BlockStack>
                      </Box>
                    </Card>
                    :
                    state.notifCtgWebAdd ?
                      <Card background='bg-surface-secondary'>
                        {state.profile && state.profile.iprminlnsnpt === true &&
                          <Box paddingBlockEnd={400}>
                            <Autocomplete
                              options={state.ctgUl ? state.options : []}
                              selected={state.ctgUl ? [state.ctgUl] : []}
                              onSelect={(value) => props.handleSugtInlnCtgOptChange(value)}
                              textField={textInlnCtgField}
                            />
                          </Box>
                        }
                        <Box paddingBlockEnd={400}>
                          <Text tone='subdued' variant='headingSm' as='p' fontWeight='regular'>After adding your site, you'll show and be able to customize the following notifications:</Text>
                        </Box>
                        {inlineList && inlineList.map((data, index) => (
                          <Box key={index} paddingBlockEnd={index === inlineList.length - 1 ? 0 : 400}>
                            <Card>
                              <InlineStack blockAlign='center' gap={200} wrap={false}>
                                <Box width='17%'>
                                  <Text variant='headingMd' as='p' fontWeight='medium'>{data.label}</Text>
                                </Box>
                                <img src={data.img} alt='inline' />
                              </InlineStack>
                            </Card>
                          </Box>
                        ))}
                      </Card>
                      :
                      state.isReloadCtgData ?
                        <Card background='bg-surface-secondary'>
                          <Box paddingBlockEnd={400}>
                            <BlockStack gap={500}>
                              <Box paddingBlock={400}>
                                <SkeletonDisplayText size='medium' />
                              </Box>
                              <Box paddingBlockEnd={300}>
                                <Divider borderColor='border' />
                              </Box>
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                            </BlockStack>
                          </Box>
                        </Card>
                        :
                        state.inlnCtgNotif && state.inlnCtgNotif.length > 0 ?
                          <div className='inln_notif_bloack'>
                            {state.inlnCtgNotif.map((data, i) => (
                              <Box key={i} paddingBlockEnd={400}>
                                <Card background='bg-surface-secondary' padding={0}>
                                  <div id='cursorPoint' onClick={() => props.handleCollapsible(i, 'openCtgCollapsible')}>
                                    <Box padding={600}>
                                      <InlineStack gap={200} blockAlign='center' align='space-between'>
                                        <InlineStack gap={200} blockAlign='center'>
                                          <Box>
                                            <Icon source={TextAlignLeftIcon} />
                                          </Box>
                                          <Text variant='headingMd' as='h3' id='inln_domain_ttl'>{Object.keys(data)}</Text>
                                        </InlineStack>
                                        <Box>
                                          <Icon source={state.openCtgCollapsible[i] ? ChevronUpIcon : ChevronDownIcon}></Icon>
                                        </Box>
                                      </InlineStack>
                                    </Box>
                                  </div>
                                  <Collapsible open={state.openCtgCollapsible[i]} id="db_collaspe"
                                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                                    <div className='inln_bg'>
                                      <Box padding={400}>
                                        {Object.values(data)[0]?.map((data, i) => (
                                          <Box key={i} paddingBlockEnd={300}>
                                            <Card padding={0} roundedAbove='xs'>
                                              <Box paddingInline={400} paddingBlock={300}>
                                                <InlineStack align='space-between' blockAlign='center'>
                                                  <Box width='20%'>
                                                    <div className='inln_txt_wrap' id='cursorPointer'>
                                                      <Tooltip dismissOnMouseOut content={data.nn}>
                                                        <Text variant='headingMd' as='p' fontWeight='semibold'>{data.nn}</Text>
                                                      </Tooltip>
                                                    </div>
                                                  </Box>
                                                  <div className='inln_data'>
                                                    {/* {renderHTML(data)} */}
                                                    {renderListHTML(state, data)}
                                                  </div>
                                                  <label className="switch">
                                                    <input type="checkbox" checked={data.ia}
                                                      onChange={() => props.handleInlnUpdNotifStatus(data)} />
                                                    <span className="switch-label"></span>
                                                    <span className="switch-handle"></span>
                                                  </label>
                                                  <Box>
                                                    <Button id='primary_btn' variant='primary' onClick={() => props.handleEditNotif(data)}>Customize</Button>
                                                  </Box>
                                                </InlineStack>
                                              </Box>
                                            </Card>
                                          </Box>
                                        ))}
                                        <InlineStack align='end'>
                                          <Button variant='plain' tone='critical' onClick={() => { props.openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ selctdUl: Object.keys(data), selctdInlnNotifTyp: 'catalog' }) }}>Delete</Button>
                                        </InlineStack>
                                      </Box>
                                    </div>
                                  </Collapsible>
                                </Card>
                              </Box>
                            ))}
                          </div>
                          :
                          <div className='notif_add_block'>
                            <BlockStack align='center' inlineAlign='center'>
                              <Text variant='headingMd' as='h4' fontWeight='semibold'>Help them to take decision faster</Text>
                              <Text variant='headingSm' as='p' fontWeight='regular' alignment='center'>Show which products are popular & trending in your product collections</Text>
                              <Box paddingBlockStart={200}>
                                <ButtonGroup>
                                  {/* <Button variant="tertiary">Learn more</Button> */}
                                  <Button variant='primary' onClick={() => changeNameValue({ notifCtgWebAdd: true })}>Start creating inline</Button>
                                </ButtonGroup>
                              </Box>
                            </BlockStack>
                          </div>
                  }
                </Layout.Section>
              </Layout>
            </Card>
          </BlockStack>
        }
      </Box>

      <Modal // delete notification
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => props.openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>{state.selctdInlnNotifTyp === 'detail' || state.selctdInlnNotifTyp === 'catalog' ? `Are you sure want to delete all notification for ${state.selctdUl}?` : 'Are you sure to delete this notification?'}</Text>
            <PageActions
              primaryAction={<Button id='primary_btn' onClick={() => props.handleDelInlnNotif()}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => props.openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default AddNotifCard;
