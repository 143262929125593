import { BlockStack, Box, Button, Card, InlineStack, Page, Text, TextField, Thumbnail, InlineGrid, Icon, IndexTable, Link, Tooltip, Modal, List } from '@shopify/polaris';
import React from 'react';
import { facebook_icon, linkDin_icon, twitter_icon, whatsapp_icon } from '../../img';
import { EmailIcon } from '@shopify/polaris-icons';

function ReferEarnCard(props) {
  let { performaneData, ReferralData, loading, copy_Link, changeNameValue, state, profileData, sendEmail, validator, shareReferralLink, eligible, wdlreqCount, afiApply, affWdlRequest, openCloseModal, Withdrawbtn, Wdlvalidator } = props

  let _afi = profileData.afi || '';
  let script = 'https://wisernotify.com?aff=' + _afi;

  const formmetNumber = (nu) => {
    if (nu > 0)
      return nu.toLocaleString();
    else
      return nu;
  };

  return (
    <Page>
      {
        loading === false &&
          (profileData && profileData.afi) ?
          <Card padding={0}>
            <Box paddingBlock={"400"} paddingInline={"600"} background='bg-surface-tertiary' borderColor="border" borderBlockEndWidth="025">
              <Text id='refer_Title' as="h1">Refer & earn</Text>
            </Box>
            <Box paddingInline={"600"} paddingBlock={"600"}>
              <Box paddingBlockEnd={400}>
                <div style={{ fontSize: '16px' }}>
                  <Text as='h4' fontWeight='semibold'>Refer to earn</Text>
                </div>
              </Box>
              <Box borderRadius='300' padding={400} background='bg-surface-tertiary'>
                <BlockStack gap={600}>
                  <Box background='bg-surface' borderRadius='300' padding={400} >
                    <BlockStack gap={600}>
                      <Box borderRadius='300' padding={400} background='bg-surface-tertiary' id='referfriends-box'>
                        <InlineStack blockAlign='center' align='space-between' wrap={false} >
                          <BlockStack gap={100}>
                            <Text as='h4' variant='bodyLg' fontWeight='semibold'>Refer friends & Earn credit</Text>
                            <Text variant='bodyMd' fontWeight='regular' tone='subdued'>Introduce a friend to wisernotify We pay you 25% commissions with a 45-day cookie.</Text>
                          </BlockStack>
                          <Box id="credit_Box" borderRadius='20'>
                            <BlockStack>
                              <div style={{ fontSize: '16px' }}>
                                <Text as='h6' fontWeight='semibold' alignment='center'>
                                  ${formmetNumber((performaneData?.totalcomi ?? 0) - (wdlreqCount?.wdlCount?.paidcomi ?? 0))}
                                </Text>
                              </div>
                              <Text variant='bodyLg'>Credit</Text>
                            </BlockStack>
                          </Box>
                        </InlineStack>
                      </Box>
                      <Box>
                        <BlockStack gap={300}>
                          <Box>
                            <BlockStack gap={100}>
                              <Text as='h6' variant='bodyLg' fontWeight='semibold'>Affiliate program overview</Text>
                              <div id='list'>
                                <List type="number" gap="extraTight">
                                  <List.Item><Text as='p' variant='bodyMd' tone='subdued'>Earn 25% Commissions: Benefit from a 45-day cookie window.</Text></List.Item>
                                  <List.Item><Text as='p' variant='bodyMd' tone='subdued'>Recurring Payouts: Continuous commissions on monthly or yearly subscriptions.</Text></List.Item>
                                  <List.Item><Text as='p' variant='bodyMd' tone='subdued'>Quick Access: Withdraw commissions 45 days post-sale, with a $50 minimum.</Text></List.Item>
                                  <List.Item><Text as='p' variant='bodyMd' tone='subdued'>Direct Payment: Receive funds via PayPal.</Text></List.Item>
                                </List>
                              </div>
                            </BlockStack>
                          </Box>
                          <InlineStack gap={400}>
                            <div id="refer_Textarea">
                              <TextField
                                value={script}
                                onChange={() => { }}
                                autoComplete="off"
                                placeholder='Recipients username'
                                disabled
                              />
                            </div>
                            <div>
                              <Button id='primary_btn' size='medium' fullWidth={false} onClick={() => copy_Link(script)}>Copy link</Button>
                            </div>
                          </InlineStack>
                        </BlockStack>

                      </Box>
                      <Box>
                        <BlockStack gap={400} id="referral-link-send">
                          <BlockStack gap={100}>
                            <Text as='h6' fontWeight='semibold' variant='bodyLg'>Invite your friend to do sign up through your referral link</Text>
                            <Text as='p' variant='bodyMd' tone='subdued'>Enter your friend's email address & send a referral link.</Text>
                          </BlockStack>
                          <InlineStack gap={400} >
                            <div id='invitation'>
                              <TextField
                                value={state.email}
                                onChange={(e) => changeNameValue({ 'email': e })}
                                autoComplete="off"
                                error={state.errMessage?.email ? 'This email field is requried' : false}
                              />
                            </div>
                            {validator.message('email', state.email, 'required')}
                            <div>
                              <Button id='primary_btn' onClick={() => sendEmail(script)}>Send invitation</Button>
                            </div>
                          </InlineStack>
                          <Box id='share-url-block'>
                            <InlineStack gap={400} blockAlign='center'>
                              <div className='share-url-title' style={{ paddingRight: '8px' }}>
                                <Text as='h6' variant='bodyMd' id='share_Url' tone='subdued'>Share this URL on</Text>
                              </div>
                              <Link id='link_Focus1' target="_blank" onClick={() => shareReferralLink('facebook', script)}>
                                <Thumbnail
                                  source={facebook_icon}
                                  size="small"
                                  alt="facebook"
                                />
                              </Link>
                              <Link id='link_Focus2' target='_blank' onClick={() => shareReferralLink('linkedin', script)}>
                                <Thumbnail
                                  source={linkDin_icon}
                                  size="small"
                                  alt="linkdin"
                                />
                              </Link>
                              <Link id='link_Focus3' target='_blank' onClick={() => shareReferralLink('twitter', script)}>
                                <Thumbnail
                                  source={twitter_icon}
                                  size="small"
                                  alt="twitter"
                                />
                              </Link>
                              <Link id='link_Focus4' target='_blank' onClick={() => shareReferralLink('whatsapp', script)}>
                                <Thumbnail
                                  source={whatsapp_icon}
                                  size="small"
                                  alt="whatsapp"
                                />
                              </Link>
                            </InlineStack>
                          </Box>
                        </BlockStack>
                      </Box>
                    </BlockStack>
                  </Box>
                  <Box>
                    <BlockStack gap={400}>
                      <Text as='h6' variant='bodyLg' fontWeight='semibold'>Performance</Text>
                      <Box>
                        <InlineGrid columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }} gap={300}>
                          <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                            <BlockStack inlineAlign='center' gap={200}>
                              <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Number of people who visited sign up page after clicking on referral link"} hasUnderline >
                                <Text as='p' variant='bodyMd' tone='subdued'>Clicks</Text>
                              </Tooltip>
                              < Text as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold' > {formmetNumber(performaneData.click ? performaneData.click : 0)} </Text>
                            </BlockStack>
                          </Box>
                          <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                            <BlockStack inlineAlign='center' gap={200}>
                              <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Number of people who signed up through your referral link"} hasUnderline >
                                <Text as='p' variant='bodyMd' tone='subdued'>Sign ups</Text>
                              </Tooltip>
                              <Text as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold'>{formmetNumber(performaneData.signup ? performaneData.signup : 0)}</Text>
                            </BlockStack>
                          </Box>
                          <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                            <BlockStack inlineAlign='center' gap={200}>
                              <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Number of people who purchased a paid plan through your referral link"} hasUnderline >
                                <Text as='p' variant='bodyMd' tone='subdued'>Paid signup</Text>
                              </Tooltip>
                              <Text as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold'>{formmetNumber(performaneData.paidSignup ? performaneData.paidSignup : 0)}</Text>
                            </BlockStack>
                          </Box>
                          <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                            <BlockStack inlineAlign='center' gap={200}>
                              <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Conversion ratio is the number people purchased a plan divided by the number of people who signed up"} hasUnderline >
                                <Text as='p' variant='bodyMd' tone='subdued'>Conversion ratio</Text>
                              </Tooltip>
                              <Text as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold'>{formmetNumber(performaneData ? ((performaneData.paidSignup * 100) / performaneData.signup) ? ((performaneData.paidSignup * 100) / performaneData.signup).toFixed(1) : 0 : 0)}</Text>
                            </BlockStack>
                          </Box>
                        </InlineGrid>
                      </Box>
                    </BlockStack>
                  </Box>
                  <Box>
                    <BlockStack gap={300}>
                      <Text as='h6' variant='bodyLg' fontWeight='semibold'>Your referral signup report</Text>
                      <Box>
                        <Card padding={0}>
                          <IndexTable
                            itemCount={ReferralData ? ReferralData?.length : 0}
                            headings={[
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Name of person who signed up through your referral link"}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span'>Full name</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Date on which the person you referred purchased a plan"}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span' id='signup_tblh2'>Purchase date</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span' id='signup_tblh3'>Current active plan</Text>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Yes means you’re eligible and No means the purchase isn’t eligible for the incentive"}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span' id='signup_tblh4'>Eligible</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Incentive amount i.e. 25% of total plan amount"}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span'>Incentive</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                            ]}
                            selectable={false}
                          >
                            {
                              ReferralData && ReferralData.length > 0 && ReferralData.map((data, i) => {
                                return (
                                  <IndexTable.Row
                                    key={i}
                                    position={i}>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{data.fn}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{new Date(data.cdt).toDateString()}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{data.upln}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{data.status}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{data.cern.toFixed(2)}</Text>
                                    </IndexTable.Cell>
                                  </IndexTable.Row>
                                )
                              })
                            }
                          </IndexTable>
                        </Card>
                      </Box>
                    </BlockStack>
                  </Box>
                </BlockStack>
              </Box>
              <Box paddingBlock={400}>
                <div style={{ fontSize: '16px' }}>
                  <Text as='h4' fontWeight='semibold'>Withdraw</Text>
                </div>
              </Box>
              <Box borderRadius='300' padding={400} background='bg-surface-tertiary'>
                <BlockStack gap={600}>
                  <Box borderRadius='300' padding={400} background='bg-surface'>
                    <BlockStack gap={200}>
                      <Text as='h5' variant='headingMd' fontWeight='semibold'>Wallet balance</Text>
                      <InlineStack blockAlign='center' align='space-between'>
                        <Text as='h3' id='dollar' fontWeight='semibold'>${formmetNumber(
                          (performaneData?.totalcomi ?? 0).toFixed(2) - (wdlreqCount?.wdlCount?.paidcomi ?? 0)
                        )}</Text>
                        <Text as='p' variant='bodyMd' tone='subdued' fontWeight='regular'>{profileData?.e}</Text>
                      </InlineStack>
                    </BlockStack>
                  </Box>
                  <Box>
                    <InlineStack gap={200}>
                      <TextField
                        value={state.wdlEmail}
                        onChange={(e) => changeNameValue({ wdlEmail: e })}
                        autoComplete="off"
                        placeholder="Paypal email"
                        id='wdlEmail'
                        prefix={<Box><Icon source={EmailIcon} style={{ marginRight: "20px" }} /></Box>}
                        error={state.errMessage?.wdlEmail ? 'The email field is requried' : false} />
                      {Wdlvalidator.message('wdlEmail', state.wdlEmail, 'required')}

                      <div style={{ maxWidth: "80px" }}>
                        <TextField
                          value={eligible}
                          autoComplete="off"
                          placeholder="0"
                          max={10}
                          readOnly
                        />
                      </div>
                      <Box><Button id='primary_btn' onClick={() => Withdrawbtn()} disabled={eligible > 50 ? false : true}>Withdraw</Button></Box>
                    </InlineStack>
                  </Box>
                  <Box>
                    <BlockStack gap={300}>
                      <Text as='h6' variant='bodyLg' fontWeight='semibold'>Commission summary</Text>
                      <InlineGrid columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }} gap={300}>
                        <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                          <BlockStack inlineAlign='center' gap={200}>
                            <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Total commission for all paid referral."} hasUnderline >
                              <Text as='p' variant='bodyMd' tone='subdued'>Total commission</Text>
                            </Tooltip>
                            <Text width='wide' padding="400" as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold'>{formmetNumber(performaneData ? performaneData?.totalcomi?.toFixed(2) : 0)}</Text>
                          </BlockStack>
                        </Box>
                        <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                          <BlockStack inlineAlign='center' gap={200}>
                            <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Total eligible commission for all paid referral who finish 45 days after payment. ( without refund or cancel account)"} hasUnderline >
                              <Text as='p' variant='bodyMd' tone='subdued'>Eligible</Text>
                            </Tooltip>
                            <Text as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold'>{formmetNumber(eligible)}</Text>
                          </BlockStack>
                        </Box>
                        <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                          <BlockStack inlineAlign='center' gap={200}>
                            <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Total commission released in your PayPal account."} hasUnderline >
                              <Text as='p' variant='bodyMd' tone='subdued'>Paid</Text>
                            </Tooltip>
                            <Text as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold'>{formmetNumber(wdlreqCount ? wdlreqCount.wdlCount.paidcomi : 0)}</Text>
                          </BlockStack>
                        </Box>
                        <Box background='bg-surface' paddingBlock={400} alignItems='center' borderRadius='200'>
                          <BlockStack inlineAlign='center' gap={200}>
                            <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Total commission that you asked us to release but its pending or waiting to be released."} hasUnderline >
                              <Text as='p' variant='bodyMd' tone='subdued'>Pending</Text>
                            </Tooltip>
                            <Text as='h6' variant='bodyMd' tone='subdued' fontWeight='semibold'>{formmetNumber(wdlreqCount ? wdlreqCount.wdlCount.pendingcomi : 0)}</Text>
                          </BlockStack>
                        </Box>
                      </InlineGrid>
                    </BlockStack>
                  </Box>
                  <Box>
                    <BlockStack gap={300}>
                      <Text as='h6' variant='bodyLg' fontWeight='semibold'>Commission withdraw history</Text>
                      <Box>
                        <Card padding={0}>
                          <IndexTable
                            itemCount={wdlreqCount ? wdlreqCount.wdlData?.length : 0}
                            headings={[
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"On this PayPal account, you asked us to do a withdrawal."}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span'>PayPal email </Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={" On this date, you have submitted a withdrawal request."}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span'>Request Date </Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Withdrawal status updated date."}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span' >Last update date </Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Remark given by Wiser Notify Account team."}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span'>Remark</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Amount is released or pending"}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span'>Status</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              },
                              {
                                title: <InlineStack gap={150}>
                                  <div>
                                    <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"Withdrawal requested amount."}>
                                      <Text variant='headingSm' tone='subdued' fontWeight='semibold' as='span'>Commission Amount </Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                            ]}
                            selectable={false}
                          >
                            {
                              wdlreqCount && wdlreqCount.wdlData && wdlreqCount.wdlData.map((val, i) => {
                                return (
                                  <IndexTable.Row
                                    key={i}
                                    position={i}>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{val.ppl}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{new Date(val.cdt).toDateString()}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{new Date(val.upd).toDateString()}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{val.rmk}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{val.rqst == 1 && 'success' || val.rqst == 0 && 'Pending'}</Text>
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <Text as='p' variant='bodyMd' fontWeight='regular'>{val.ttl}</Text>
                                    </IndexTable.Cell>
                                  </IndexTable.Row>
                                )
                              })

                            }
                          </IndexTable>
                        </Card>
                      </Box>
                    </BlockStack>
                  </Box>
                </BlockStack>
              </Box>
            </Box>
            {/* modal  */}
            <Modal
              open={state.withdrawModal}
              onClose={(e) => openCloseModal('withdrawModal', state.withdrawModal, e)}
              title="Confirm"
              primaryAction={{
                content: 'No',
                onAction: () => openCloseModal('withdrawModal', state.withdrawModal, 'close')
              }}
              secondaryActions={[
                {
                  content: 'Yes',
                  onAction: () => affWdlRequest()
                },
              ]
              }
            >
              <Modal.Section>
                <Box>
                  <BlockStack gap={100}>
                    <Text as='h4' fontWeight='semibold'>Are you sure about this PayPal account?</Text>
                    <Text as='h4'>By Click on Yes, We will release commission amount on this account.</Text>
                  </BlockStack>
                </Box>
              </Modal.Section>
            </Modal>
          </Card>
          :
          <Card padding={0}>
            <Box paddingBlock={"400"} paddingInline={"600"} background='bg-surface-tertiary' borderColor="border" borderBlockEndWidth="025">
              <Text id='refer_Title' as="h1">Refer & Earn</Text>
            </Box>
            <Box paddingInline={"600"} paddingBlock={"600"}>
              <Box paddingBlockEnd={400}>
                <div style={{ fontSize: '16px' }}>
                  <Text as='h4' fontWeight='semibold'>Refer to earn</Text>
                </div>
              </Box>
              <Box borderRadius='300' padding={400} background='bg-surface-tertiary'>
                <Box background='bg-surface' padding={400} borderRadius='300'>
                  <BlockStack gap={600}>
                    <Box borderRadius='300' padding={400} background='bg-surface-tertiary'>
                      <InlineStack blockAlign='center' align='space-between'>
                        <BlockStack gap={100}>
                          <Text as='h4' variant='bodyLg' fontWeight='semibold'>Refer Friends & Earn Credit</Text>
                          <Text variant='bodySm' fontWeight='regular'>Introduce a friend to wisernotify We pay you 25% commissions with a 45-day cookie.</Text>
                        </BlockStack>
                        <Box id="credit_Box" borderRadius='20'>
                          <BlockStack>
                            <div style={{ fontSize: '16px' }}>
                              <Text as='h6' fontWeight='semibold' alignment='center'>$0</Text>
                            </div>
                            <Text variant='bodyLg'>Credit</Text>
                          </BlockStack>
                        </Box>
                      </InlineStack>
                    </Box>
                  </BlockStack>
                  <Box paddingBlock={"600"}>
                    <BlockStack gap={100}>
                      <Text as='h6' variant='bodyLg' fontWeight='semibold'>Send traffic once, earn 25% commission on every transaction</Text>
                      <Text as='p' variant='bodyLg' style={{ fontWeight: '400' }}>
                        We pay you 25% commissions with a 45-day cookie. Our customers pay us every month or year, which means we pay you every month or year. We have 45 days commission release cycle. Your commission will be eligible to withdraw after 45 days of your paid signup so, after that, you can request us to release it. the minimum payout amount is $50 USD. We’ll deposit money straight into your PayPal account. Hassle-free and guaranteed.</Text>
                    </BlockStack>
                    <Box paddingBlockStart={600}>
                      {
                        ReferralData && ReferralData.ipr == false ?
                          <span>Your application is under review!</span>
                          :
                          <Button variant='primary' onClick={afiApply}>Apply now</Button>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
      }
    </Page >
  );
}

export default ReferEarnCard;