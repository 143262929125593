import React, { useState, useEffect, useCallback } from 'react';
import { RateUsCard } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import * as rateDucks from "../../ducks/rateUs";
import { toastify } from '../../ducks/toast';
import './RateUs.css';
import { checkPermission } from '../../services/permissions';

const RateUs = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    rateC: "",
    rqa: '',//redeem qouta amount
  });

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const rateUsData = useSelector((state) => state.rate?.rateUs);
  const profileData = useSelector(state => state.auth?.profile);

  useEffect(() => {
    changeNameValue({ rateC: rateUsData });
  }, [rateUsData]);

  //call getUserReward api 
  useEffect(() => {
    dispatch(rateDucks.getRateUs());
  }, []);

  //open chat
  const openChat = () => {
    if (window.Froged) {
      try {
        window.Froged('open');
      } catch (error) {
        console.error('Error opening chat:', error);
      }
    } else {
      console.warn('Froged is not initialized.');
    }
  };

  //Rate us and Email us click
  const handleClick = (e, url, btnName) => {
    if (btnName !== 'testimonial_click') {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    else {
      openChat();
      if (window.Froged) {
        try {
          window.Froged('message', 'I am interested in submitting a video testimonial.');
        } catch (error) {
          console.error('Error sending message to chat:', error);
        }
      }
    }
  };

  //add reward quota
  const addRewardQuota = () => {
    if (Math.sign(state.rqa) === 1) {
      if (state.rqa <= state.rateC && state.rateC > 0) {
        let obj = { qa: state.rqa };
        dispatch(rateDucks.addUserReward(obj));
        changeNameValue({ rqa: '' })
      } else {
        dispatch(toastify({ type: 'error', msg: "You do not have enough reward qouta!" }));
      }
    } else {
      dispatch(toastify({ type: 'error', msg: 'Please enter valid amount of qouta.' }));
    }
  };

  return (
    <>
      {
        checkPermission('gdpr', 'isview', profileData) &&
        <RateUsCard
          state={state}
          changeNameValue={changeNameValue}
          handleClick={handleClick}
          addRewardQuota={addRewardQuota}
        />
      }
    </>
  );
};

export default RateUs;
