import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    getLogin: (state, action) => { },
    getLoginSuccess: (state, action) => {
      return { ...state, login: action.payload };
    },
    signup: (state, action) => { },
    userLogin: (state, action) => { },
    forgetPD: (state, action) => { },
    userResetPD: (state, action) => { },
    loginWithGoogle: (state, action) => { },
    loginWithFacebook: (state, action) => { },
    loginWithFacebookSuccess: (state, action) => {
      return { ...state, fbLogin: action.payload };
    },
  }
});

export const {
  getLogin,
  getLoginSuccess,
  signup,
  userLogin,
  forgetPD,
  userResetPD,
  loginWithGoogle,
  loginWithFacebook,
  loginWithFacebookSuccess
} = login.actions;

export default login.reducer;
