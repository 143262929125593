import React, { useEffect, useMemo, useCallback } from 'react';
import { Text, Modal, Divider, Box, ButtonGroup, Spinner, PageActions, Button, Tooltip, Icon, InlineStack, Card, InlineGrid } from '@shopify/polaris';
import { DesktopIcon, MobileIcon, ColorIcon, SoundIcon, ThemeTemplateIcon, ReplayIcon } from '@shopify/polaris-icons';
import ColorPick from './ColorPick';
import Parser from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';
import DesignCard from './DesignCard';
import { PaidStar } from '../../img';

let itemClass = '';
let feedInterval = '';
var blinkInterval = '';
let scrollInterval = '';

//titlebar blinking
var titlebarBlinking = function (cnf) {
  let bool = true;
  clearInterval(blinkInterval);
  //set interval for repeat 2 line 
  blinkInterval = setInterval(() => {
    if (bool) {
      if (document.getElementById('TitleTxt')) {
        document.getElementById('TitleTxt').innerHTML = cnf?.fl.txt;
        document.getElementById('ImgURL').setAttribute('src', cnf?.fliu);
      }
      bool = false;
    }
    else {
      if (document.getElementById('TitleTxt')) {
        document.getElementById('TitleTxt').innerHTML = cnf?.sl.txt;
        document.getElementById('ImgURL').setAttribute('src', cnf?.sliu);
      }
      bool = true;
    }
  }, (cnf?.blnkdly || 1) * 1000);
};

//titlebar scrolling
var titlebarScrolling = function (cnf) {
  let _title = cnf?.fl.txt + '.';
  //interval for skip first char. and append in last
  clearInterval(scrollInterval);
  scrollInterval = setInterval(function () { // var scrollInterval =
    _title = _title.replace(/ /g, '.');
    //bcz when space comes, skip 2(space+character)
    if (_title.substring(0, 1) == '.')
      _title = _title.substring(2) + _title.substring(0, 2);
    else
      _title = _title.substring(1) + _title.substring(0, 1);
    _title = _title.replace(/\./g, ' ');
    if (document.getElementById('TitleTxt'))
      document.getElementById('TitleTxt').innerHTML = _title;
  }, 1000);
  if (document.getElementById('ImgURL'))
    document.getElementById('ImgURL').setAttribute('src', cnf?.fliu);
};

const PreviewCard = ({ props }) => {

  let { state, openCloseModal, handleMainTextChange, changeNameValue, callResetApi, changeTemp, updateTempById, } = props;
  let { cnf, defaultNotif, notiById, isMobileScreen, notifId, notifHeight, queryNtt, loadingState } = state;

  let tempHTML = defaultNotif?.htn?.htn || '';
  let collHTMl = '';

  //for open collector div
  if (defaultNotif && cnf && notifHeight) {
    collHTMl = notifHeight?.clhtn;
    collHTMl = collHTMl.replace(/{BACKGROUND_COLOR}/gi, cnf.bg);
    collHTMl = collHTMl.replace(/{CLCR_SCL_PRF_MSG}/gi, cnf.clmsg);
    collHTMl = collHTMl.replace(/{EMAIL_INPUT_DISPLAY}/gi, cnf.ieml ? 'block' : 'none');
    collHTMl = collHTMl.replace(/{NUMBER_INPUT_DISPLAY}/gi, cnf.iphn ? 'block' : 'none');
    collHTMl = collHTMl.replace(/{USERNAME_INPUT_DISPLAY}/gi, cnf.iunm ? 'block' : 'none');
    collHTMl = collHTMl.replace(/{EMAIL_PLACE}/gi, cnf.peml);
    collHTMl = collHTMl.replace(/{NUMBER_PLACE}/gi, cnf.pphn);
    collHTMl = collHTMl.replace(/{USERNAME_PLACE}/gi, cnf.punm);
    collHTMl = collHTMl.replace(/{CLCR_SCL_SBMT_BTN}/gi, cnf.clsb);
    collHTMl = collHTMl.replace(/{CLCR_SCL_CL_FNT_CLR}/gi, cnf.clclf);
    collHTMl = collHTMl.replace(/{CLCR_SCL_CL_BG_CLR}/gi, cnf.clcbg);
    collHTMl = collHTMl.replace(/{IS_CLCR_SCL_PRV_PLCY}/gi, cnf.iclplcy ? 'block' : 'none');
    collHTMl = collHTMl.replace(/{CLCR_SCL_PRV_PLCY}/gi, cnf.clplcy);
    collHTMl = collHTMl.replace(/{CLCR_SCL_THNKS}/gi, cnf.clthk);
  }

  //for title bar
  if (cnf?.tltyp === 'normal') {
    if (document.getElementById('TitleTxt')) {
      document.getElementById('TitleTxt').innerHTML = cnf?.fl?.txt;
      document.getElementById('ImgURL').setAttribute('src', cnf?.fliu);
    }
    clearInterval(blinkInterval);
    clearInterval(scrollInterval);
  } else if (cnf?.tltyp === 'blinking') {
    clearInterval(scrollInterval);
    titlebarBlinking(cnf);
  } else if (cnf?.tltyp === 'scrolling') {
    clearInterval(blinkInterval);
    titlebarScrolling(cnf);
  }

  //for give carousel item class
  if (notiById) {
    itemClass = notiById?.ntt === 'collector' ? 'carouse-collector' :
      notiById?.ntt === 'video' ? 'carouse-video' :
        notiById?.ntt === 'call_to_action' ? 'carouse-discount' : '';
  }

  // Timer-3-9 copy code on cta click 
  function setupCtaClickEvents() {
    const cta_Elements = document.querySelectorAll('#NFDGdflt26_Sb425txt_cta');
    cta_Elements.forEach(cta_Element => {
      cta_Element.onclick = null;

      // Set the click event
      cta_Element.onclick = function () {
        let { cnf } = state;

        if (cnf?.ctcacn === 0 && cnf?.cu) {
          const _redirectURL = cnf.cu.replace('{{FullSiteURL}}', window.location.href).replace('{{NotificationName}}', notiById?.nn);
          if (cnf?.ictrdsw) window.location.href = _redirectURL;
          else window.open(_redirectURL);
        }
        else if (cnf?.ctcacn === 5) {
          window.navigator.clipboard.writeText(cnf?.cl?.txt, () => { });
          // Show "copied" message
          const copiedElements = document.querySelectorAll('#NFTMCOPID_tmrcp_' + notifId);
          copiedElements.forEach(_Id => {
            _Id.style.visibility = 'visible';
            setTimeout(() => { _Id.style.visibility = 'hidden'; }, 2000);
          });
        }

      };
    });
  }

  setupCtaClickEvents();
  const observer = new MutationObserver(setupCtaClickEvents);
  observer.observe(document.body, { childList: true, subtree: true });

  //for hide template list div
  let isShowList = true;
  if (queryNtt === 'title_bar' ||
    queryNtt === 'custom' ||
    queryNtt === 'video' ||
    queryNtt === 'call_to_action' ||
    queryNtt === 'all_in_one' ||
    queryNtt === 'whatsapp_chat' ||
    queryNtt === 'offer' ||
    queryNtt === 'collector' ||
    queryNtt === 'social_media_followers' ||
    queryNtt === 'lottery' ||
    queryNtt === 'live_visitor_ecomm_snippet' ||
    queryNtt === 'purchase_bulk_ecomm_snippet' ||
    queryNtt === 'purchase_recent_ecomm_snippet' ||
    queryNtt === 'limited_stock_ecomm_snippet' ||
    (notiById?.idisnp === true && notiById?.isnpwdsg !== true)) {
    isShowList = false;
  }

  // get all template list
  useEffect(() => {
    let lstTemplate = state.notifData?.allTemplate?.filter(x => x.ni == state.queryNti) || [];
    changeNameValue({ lstTemplate });
  }, [state.notifData]);

  const selectNotiTemplate = (val, temp, key) => {
    let obj = {
      Temp_html: temp,
      noti_tmid: val._id,
      isSelTemp: key
    };
    changeNameValue(obj);
  }

  // Display multiple data in wrapper
  setTimeout(() => {
    let counter = 1;
    let snippetMainDivs = document.querySelectorAll('#feedAnimationDiv');

    snippetMainDivs.forEach((snippetMainDiv) => {
      if (snippetMainDiv) {
        if (feedInterval) clearInterval(feedInterval);
        snippetMainDiv.innerHTML = '';

        if (notiById?.idisnp === true && notiById?.isnpifdanmt === true) {
          let arr = [];

          for (let i = 0; i < 5; i++) {
            arr.push({ HTML: DesignCard(state, defaultNotif, 'preview') });

            let div = document.createElement('div');
            div.id = 'slide_' + (i + 1);
            div.className = 'Wsr586N-Snpit-SliDer-ItemS';
            div.style.marginBottom = notiById?.isnpspcbtwnntf + 'px';
            div.innerHTML = DesignCard(state, defaultNotif, 'preview');

            let firstChild = snippetMainDiv.firstChild;
            if (firstChild) {
              snippetMainDiv.insertBefore(div, firstChild);
            } else {
              snippetMainDiv.appendChild(div);
            }
          }

          if (arr.length > 0) {
            feedInterval = setInterval(() => {
              let element = document.getElementById('slide_' + counter);
              if (element && element.parentNode === snippetMainDiv) {
                element.parentNode.removeChild(element);
              }

              let div = document.createElement('div');
              div.id = 'slide_' + counter;
              div.className = 'Wsr586N-Snpit-SliDer-ItemS';
              div.style.marginBottom = notiById?.isnpspcbtwnntf + 'px';
              div.innerHTML = arr[counter - 1].HTML;

              let firstChild = snippetMainDiv.firstChild;
              if (firstChild) {
                snippetMainDiv.insertBefore(div, firstChild);
              } else {
                snippetMainDiv.appendChild(div);
              }

              if (counter === arr.length) counter = 0;
              counter++;
            }, (notiById?.dsnt * 1000));
          }
        }
      }
    });
  }, 100);



  // setTimeout(() => {
  //   let counter = 1;
  //   let snippetMainDiv = document.getElementById('feedAnimationDiv');
  //   if (snippetMainDiv) {
  //     clearInterval(feedInterval);
  //     snippetMainDiv.innerHTML = '';
  //     if (notiById?.idisnp === true && notiById?.isnpifdanmt === true) {
  //       let arr = [];

  //       for (let i = 0; i < 5; i++) {
  //         arr.push({ HTML: DesignCard(state, defaultNotif, 'preview') });

  //         var div = document.createElement('div');
  //         div.id = 'slide_' + (i + 1);
  //         div.className = 'Wsr586N-Snpit-SliDer-ItemS';
  //         div.style.marginBottom = notiById?.isnpspcbtwnntf + 'px';
  //         div.innerHTML = DesignCard(state, defaultNotif, 'preview');
  //         if (snippetMainDiv.firstChild) {
  //           snippetMainDiv.insertBefore(div, snippetMainDiv.firstChild);
  //         } else {
  //           snippetMainDiv.appendChild(div);
  //         }
  //       }

  //       if (arr?.length > 0) {
  //         feedInterval = setInterval(() => {

  //           //remove last record
  //           var element = document.getElementById('slide_' + counter);
  //           if (element)
  //             element.parentNode.removeChild(element);

  //           //create div & add new record
  //           var div = document.createElement('div');
  //           div.id = 'slide_' + counter;
  //           div.className = 'Wsr586N-Snpit-SliDer-ItemS';
  //           div.style.marginBottom = notiById?.isnpspcbtwnntf + 'px';
  //           div.innerHTML = arr[counter - 1].HTML;

  //           if (snippetMainDiv.firstChild) {
  //             snippetMainDiv.insertBefore(div, snippetMainDiv.firstChild);
  //           } else {
  //             snippetMainDiv.appendChild(div);
  //           }

  //           if (counter == arr?.length) counter = 0;
  //           counter++;
  //         }, (notiById?.dsnt * 1000));
  //       }
  //     }
  //   }
  // }, 100);

  const loadedHTML = useCallback((isMobiView) => {
    return (
      <div className={isMobiView ? 'nf-live-setting mobile_screen' : 'nf-live-setting'}>
        <div className="preview_hght  notificatio-pro" style={{ 'backgroundColor': state.notiBg }}>

          {/* className={(queryNtt === 'collector' || queryNtt === 'all_in_one' || queryNtt === 'call_to_action' ||
              queryNtt === 'offer') ? 'demo-live-web noti-subscriber-scroll ' : 'demo-live-web'} */}
          <div id='live_preview_block' className='demo-live-web'>
            <div className="live-preview-block"></div>

            {loadingState === false &&
              ((defaultNotif !== undefined && Object.keys(defaultNotif).length > 0) ||
                (notiById !== undefined && Object.keys(notiById).length > 0)) ?
              notiById?.ntt !== 'title_bar' ?
                <div id={isMobiView ? 'Animation_style_mobi' : 'Animation_style'}>

                  {/* collector div */}
                  {isShowList && (notiById?.idisnp !== true || notiById?.isnpwdsg !== true) &&
                    ((cnf?.ica && cnf?.ctcacn === 2) || (cnf?.nc && cnf?.ncacn === 2)) &&
                    collHTMl && Parser(collHTMl)
                  }

                  {/* for all notifcation */}
                  {(!notiById.idisnp || !notiById.isnpifdanmt) &&
                    <div className={(notiById?.idisnp === true && notiById?.isnpwdsg !== true) ? 'noti-snippet-wrapper' : ''}>{Parser(DesignCard(state, defaultNotif, 'preview'))}
                    </div>
                  }

                  <div id='feedAnimationDiv' className={(notiById?.idisnp === true && notiById?.isnpwdsg !== true) ? 'Wsr586N-Snpit-SliDerText-WraP' : 'Wsr586N-Snpit-SliDer-WraP'}></div>
                </div>
                :   //for titlebar
                <div className="FDGdflt26_WeBTitLeBar_fdhKMN FDGdflt26_WeBTitLeBar_TiTlEbaRTem01">
                  <div className="FDGdflt26_ToPhEaDer_FirstBlock">
                    <div className="FDGdflt26_WeBTitLeBar_BlocK">
                      <div className="FDGdflt26_WeBTitLeBar_imG">
                        <img id='ImgURL'></img>
                      </div>
                      <div className="FDGdflt26_WeBTitLeBar_LefTBlock">
                        <div className="FDGdflt26_WeBTitLeBar_TexT" id='TitleTxt'></div>
                      </div>
                    </div>
                  </div>
                </div>
              :
              <Box paddingBlockStart={800}>
                <InlineStack align='center' blockAlign='center'>
                  {/* <Spinner size='large' /> */}

                </InlineStack>
              </Box>
            }
          </div>
        </div>
      </div>
    );
  }, [state]);

  const mobilePrewHTML = useMemo(() => loadedHTML(true), [state]);  // passing true for showing mobile preview
  const deskPrewHTML = useMemo(() => loadedHTML(false), [state]);  // passing false for showing desktop preview

  const openTempList = () => {
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Notification List Button', 'View_All_Edit_Section', state.ProfileData?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Notification List Button', { 'event_action': 'View_All_Edit_Section', 'event_label': state.ProfileData?.e || '' });
      } catch (error) { console.log(error); }
    }

    changeNameValue({ isSelTemp: '' });
    openCloseModal('tempOpen', state.tempOpen, 'open');
  }

  return (
    <div className='sticky-preview'>
      <Card padding={0}>
        <Box paddingBlock={400} paddingInline={400}>
          <InlineStack align="space-between" blockAlign='center'>

            {state.tabActiveTyp === 1 ?
              <ButtonGroup>
                {isShowList &&  //choose template button
                  <Button icon={ThemeTemplateIcon} variant='primary'
                    onClick={() => openTempList()}> Template </Button>
                }

                {/* reset button */}
                <Button size='large' icon={ReplayIcon} id='reset_btn'
                  onClick={() => openCloseModal('resetOpen', state.resetOpen, 'open')}></Button>

                {/* sound-on/off button */}
                <div id='int_tabs'>
                  <Tooltip dismissOnMouseOut padding='400' content='Sound ON/OFF'>
                    <Button size='large' icon={SoundIcon} onClick={() => handleMainTextChange('intfsund', cnf?.intfsund === true ? false : true)}></Button>{cnf?.intfsund !== true && <span id='sndOff'></span>}
                  </Tooltip>
                </div>
              </ButtonGroup>
              :
              <ButtonGroup />
            }

            <InlineStack gap={200} blockAlign='end' align='center'>
              <ColorPick
                html={<Button size='large' id='reset_btn' icon={ColorIcon}></Button>}
                width='medium'
                colorKey="notiBg"
                colorValue={state.notiBg}
                functionCall={props.changeStates} />

              <ButtonGroup variant="segmented">
                <Button variant={isMobileScreen === true ? 'secondary' : 'primary'}
                  onClick={() => {
                    openCloseModal('webPreviewOpen', state.webPreviewOpen, 'open');
                    changeNameValue({ isMobileScreen: false });
                  }}>
                  <Icon source={DesktopIcon} />
                </Button>

                <Button variant={isMobileScreen === true ? 'primary' : 'secondary'}
                  onClick={() => changeNameValue({ isMobileScreen: true })}>
                  <Icon source={MobileIcon} />
                </Button>
              </ButtonGroup>
            </InlineStack>

          </InlineStack>
        </Box>
        <Divider />

        {/* mobile preview */}
        {mobilePrewHTML}

      </Card>

      {/* desktop preview */}
      <Modal
        size='large'
        open={state.webPreviewOpen}
        onClose={(e) => openCloseModal('webPreviewOpen', state.webPreviewOpen, 'close')}
        title={<Text variant='headingMd' fontWeight='bold'>Desktop view</Text>}>
        <div className='sidebar_wrapp'>

          {/* desktop preview */}
          {deskPrewHTML}
        </div>
      </Modal>

      {/* reset modal */}
      <Modal
        size='small'
        open={state.resetOpen}
        onClose={(e) => openCloseModal('resetOpen', state.resetOpen, e)}
        title={<Text variant='headingMd' fontWeight='bold'>Reset design</Text>}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to reset design?</Text>
            <PageActions
              primaryAction={<Button variant='primary' tone='success' onClick={() => callResetApi()}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('resetOpen', state.resetOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      {/* template list modal */}
      {state.tempOpen === true &&
        <Modal
          size='fullScreen'
          open={state.tempOpen}
          onClose={(e) => openCloseModal('tempOpen', state.tempOpen, e)}
          title={<Text variant='headingLg' fontWeight='bold'>Select template design</Text>}
          primaryAction={{
            content: 'Use Template',
            onAction: () => changeTemp(),
            disabled: (state.isSelTemp !== '') ? false : true
          }}
          secondaryActions={[{
            content: 'Cancel',
            onAction: () => openCloseModal('tempOpen', state.tempOpen, 'close')
          }]}>
          <Box padding={600}>
            <div style={{ height: '59.9vh' }}>

              {state.lstTemplate?.length > 0 ?
                <InlineGrid columns={3} gap={1000}>

                  {state.lstTemplate.map((val, key) => {
                    let _state = { ...state, cnf: val.cnf };
                    let tempContent = Parser(DesignCard(_state, val, 'modalList'));

                    return (
                      (localStorage.getItem('csub') === 'free' && val.iprmum === true) ?
                        <div className='lstTemp_wrap paidTempWraper'>
                          {queryNtt === 'review_feed' ? <img width={'100%'} src={val.iu} /> : tempContent}
                          <div className='paidTemp'>
                            <img src={PaidStar} alt="PaidStar" />
                            <Text>Only available for paid users</Text>
                            <Button size='micro' target="_blank" url='/plan'>
                              <Text fontWeight='medium' id='greenClr'>Upgrade now</Text>
                            </Button>
                          </div>
                        </div>
                        :
                        <div className='lstTemp_wrap' onClick={() => selectNotiTemplate(val, tempContent, key)}
                          style={{ borderColor: (state.isSelTemp === key) ? '#000' : '' }}>
                          {queryNtt === 'review_feed' ?
                            <img width={'100%'} src={val.iu}></img>
                            :
                            tempContent
                          }
                        </div>
                    )
                  })}

                </InlineGrid>
                :
                <div className='lstTemp_wrap'>
                  <Spinner size="large" />
                </div>
              }

            </div>
          </Box>
        </Modal>
      }

      {/* select template confirmation modal */}
      <Modal
        size=''
        open={state.changeTempOpen}
        onClose={(e) => openCloseModal('changeTempOpen', state.changeTempOpen, e)}>
        <Box paddingInline={200} paddingBlock={1000}>

          <InlineStack direction={'column'} align='center' blockAlign='center'>
            {state.queryNtt === 'review_feed' ?
              <img width={'70%'} src={ReactDOMServer.renderToStaticMarkup(state.Temp_html)}></img>
              :
              <div>{state.Temp_html}</div>
            }

            <Box paddingBlockStart={1200} paddingBlockEnd={500}>
              <Text fontWeight='bold' variant='headingLg'>
                Are you sure want to change the template?</Text>
            </Box>

            <InlineStack gap={500}>
              <Button variant='' onClick={() => openCloseModal('changeTempOpen', state.changeTempOpen, 'close')}>Cancel</Button>
              <Button variant='primary' tone='success' onClick={() => updateTempById()}>Yes, change template</Button>
            </InlineStack>
          </InlineStack>

        </Box>
      </Modal>

    </div >
  );
}

export default PreviewCard;