import React, { memo, useCallback, useEffect, useState } from 'react';
import { AnalyticsCard } from "../../components";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as AnalyticsDucks from '../../ducks/analytics';
import * as settingDucks from '../../ducks/setting';
import './Analytics.css';
import moment from 'moment';
import { checkPermission } from '../../services/permissions';

let statusTabsArray = [
  { id: 1, label: 'Last 24 Hours', value: 'today' },
  { id: 2, label: 'Last 7 days', value: 'week' },
  { id: 3, label: 'Last 30 days', value: 'month' },
  { id: 4, label: 'Custom Date', value: 'custom' }]

const Analytics = (props) => {

  let dispatch = useDispatch();

  const [state, setState] = useState({
    statusTabs: [],
    ti: '',
    selected: 0,
    nid: undefined,
    profile: {},
    pfd: '',
    ptd: '',
    ht: 'all',
    nn: 'all',
    rt: 'today',
    popoverActive: false,
    analyticOpen: false,
    frmdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    tdt: new Date(new Date().setHours(24, 0, 0, 0)).toDateString(),
    pn: 2,
    ps: 10,
    pg: 2,
    vid: '',
    analyticEs: [],
    AnalyticData: [],
    AnalyticHeader: [],
    uniqueVisitor: 0,
    iSum: 0,
    eSum: 0,
    hSum: 0,
    cSum: 0,
    clsSum: 0,
    cta: 0,
    ctr: 0,
    vs: 0,
    hs: 0,
    cs: 0,
    uqVisitor: 0,
    prevuqvSum: 0,
    previmSum: 0,
    preveSum: 0,
    prevhSum: 0,
    prevclsSum: 0,
    prevclkSum: 0,
    prevcta: 0,
    prevvs: 0,
    prevhs: 0,
    prevcs: 0,
    preuniqueVisitor: 0,
    perIsum: 0,
    perVsum: 0,
    perEsum: 0,
    perhSum: 0,
    perCsum: 0,
    perClsSum: 0,
    perCTA: 0,
    perVs: 0,
    perHs: 0,
    perCs: 0,
    perUnique: 0,
    perSingUp: 0,
    PrevSignup: 0,
    preuqVisitor: 0,
    iAry: [],
    vAry: [],
    eAry: [],
    hAry: [],
    cAry: [],
    clsAry: [],
    date: [],
    analyticDomain: [],
    visitorEs: [],
    vistHistPrData: [],
    getSign: [],
    shwDataCnt: false,
    signupCount: 0,
    preSignupCount: 0,
    istm: localStorage.getItem("istm"),
    getSettingList: '',
    domainList: [],
    notifList: [],
    activeDomainList: false,
    activeNotifList: false,
    notifDomain: [],
    notifDataList: [],
    isClearRadis: false,
    hasMore: true,
    isLoading: true,
    isShowLoadMore: true,
    activeVistHistPage: 1,
    visitHistoryItems: [],
    notiType: ''
  })

  const profileData = useSelector(state => state.auth.profile);

  const getEsAnalytic = useSelector(state => state.analytics.getEsAnalytic);
  const visitorHistoryPr = useSelector(state => state.analytics.getVisitHistoryPr);

  const getSignUp = useSelector(state => state.analytics.getSignupDataCount);
  const getSettingList = useSelector(state => state?.setting?.getSettingList?.data);
  const getSettingNotif = useSelector(state => state?.setting?.getSettingList?.notifData);

  let notiffrmSocial = props;

  useEffect(() => {
    dispatch(settingDucks.getSetting(state.istm));
  }, [state.istm])

  useEffect(() => {
    callEsAnalytic()
  }, [state.selected, state.ht, state.nn])

  useEffect(() => {
    if (Array.isArray(getSettingList) && Array.isArray(getSettingNotif)) {
      // Process domain list
      const structureDomain = getSettingList.map((elm) => ({
        content: elm.ht,
        onAction: () => { changeNameValue({ notifDomain: [elm], popoverActive: false }); },
        active: elm.ht === state.notifDomain[0]?.ht
      }));

      // Process notification list
      const structureNotif = getSettingNotif.map((elm) => ({
        content: elm.nn,
        onAction: () => { changeNameValue({ notifList: [elm], popoverActive: false, notiType: elm.ntt }); },
        active: elm.nn === state.notifList[0]?.nn
      }));

      // Prepare the domain list
      const allDomain = {
        content: 'All',
        onAction: () => { changeNameValue({ notifDomain: [], popoverActive: false }); },
      };
      const newDomainLObj = [allDomain, ...structureDomain];

      const allNotif = {
        content: 'All',
        onAction: () => { changeNameValue({ notifList: [], popoverActive: false }); },
      };

      const newNotifLObj = [allNotif, ...structureNotif];

      if (JSON.stringify(newDomainLObj) !== JSON.stringify(state.domainList) ||
        JSON.stringify(newNotifLObj) !== JSON.stringify(state.notifDataList) ||
        state.ht !== state.notifDomain[0]?.ht ||
        state.nn !== state.notifList[0]?.nn) {
        changeNameValue({
          domainList: newDomainLObj,
          notifDataList: newNotifLObj,
          ht: state.notifDomain[0]?.ht,
          nn: state.notifList[0]?.nn
        });
      }
    }
  }, [getSettingList, getSettingNotif, state.notifDomain, state.notifList, state.ht, state.notiType, state.nn, state.domainList, state.notifDataList, state.popoverActive]);


  useEffect(() => {
    if (profileData) {
      changeNameValue({ profile: profileData });

      // changeNameValue({ selected: 0 })
      // callEsAnalytic()
    }
  }, [profileData])


  useEffect(() => {
    if (getSignUp) {
      let obj = {
        preSignupCount: getSignUp.preSignupCount ? getSignUp.preSignupCount : 0,
        signupCount: getSignUp.signupCount ? getSignUp.signupCount : 0
      }
      changeNameValue({ getSignUp: obj })
    }
  }, [getSignUp, state.preSignupCount, state.signupCount])

  const tabs = statusTabsArray.map((item, i) => ({
    key: i,
    content: item.label,
    id: `${item.label}-${i}`,
  }));


  const callEsAnalytic = () => {
    //clear old analytic data
    if (state.selected !== 3) {
      dispatch(AnalyticsDucks.getEsAnalyticClear());
      //null state on tab change
      changeNameValue({ iAry: [], vAry: [], eAry: [], hAry: [], cAry: [], clsAry: [], date: [] });
    }

    if (localStorage.getItem('ti') === null) {
      return;
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    changeNameValue({ shwDataCnt: false });

    let obj = {
      // ti: localStorage.getItem('ti'),
      nid: notiffrmSocial?.notifId ? notiffrmSocial.notifId : state.notifList.length > 0 ? state.notifList[0]?._id : undefined,
      ht: state.notifDomain[0]?.ht,
      isClearRadis: state.notifDomain[0]?.ht ? true : undefined,
    };

    switch (state.selected) {
      case 0: // Today      
        obj = {
          ...obj,
          rt: 'today',
          fDate: new Date().setHours(0, 0, 0, 0),
          tDate: new Date().setHours(24, 0, 0, 0),
          popoverActive: false,
          pfd: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0),
          ptd: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(24, 0, 0, 0),
        };
        break;
      case 1: // Past week
        obj = {
          ...obj,
          rt: 'week',
          fDate: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0),
          tDate: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(24, 0, 0, 0),
          popoverActive: false,
          pfd: new Date(new Date().setDate(new Date().getDate() - 14)).setHours(0, 0, 0, 0),
          ptd: new Date(new Date().setDate(new Date().getDate() - 8)).setHours(24, 0, 0, 0),

        };
        break;
      case 2: // Past month
        obj = {
          ...obj,
          rt: 'month',
          fDate: new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0, 0),
          tDate: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(24, 0, 0, 0),
          popoverActive: false,
          pfd: new Date(new Date().setDate(new Date().getDate() - 60)).setHours(0, 0, 0, 0),
          ptd: new Date(new Date().setDate(new Date().getDate() - 31)).setHours(24, 0, 0, 0),
        };
        break;
      case 3: // Custom date
        if (state.popoverActive === false) {
          obj = {
            ...obj,
            rt: 'custom',
            fDate: new Date(new Date().setDate(new Date().getDate() - 60)).setHours(0, 0, 0, 0),
            tDate: new Date(new Date().setDate(new Date().getDate() - 8)).setHours(24, 0, 0, 0),
            pfd: new Date(new Date().setDate(new Date().getDate() - 60)).setHours(0, 0, 0, 0),
            ptd: new Date(new Date().setDate(new Date().getDate() - 31)).setHours(24, 0, 0, 0),
          }
          dispatch(AnalyticsDucks.getEsAnalytic(obj));
        }
        return;
      default:
        changeNameValue({ popoverActive: false });
        return;
    }
    changeNameValue(obj);
    dispatch(AnalyticsDucks.getEsAnalytic(obj));
  }


  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    if (getEsAnalytic) {
      changeNameValue({ analyticEs: getEsAnalytic });
    }
  }, [getEsAnalytic]);


  useEffect(() => {
    if (state.analyticEs) {
      const { Analytic, PreAnalytic } = state.analyticEs;

      let obj = {
        uniqueVisitor: Analytic?.sum.uqv || 0,
        iSum: Analytic?.sum.im || 0,
        eSum: Analytic?.sum.hv + Analytic?.sum.clk || 0,
        hSum: Analytic?.sum.hv || 0,
        cSum: Analytic?.sum.clk || 0,
        clsSum: Analytic?.sum.cls || 0,
        cta: Analytic?.sum.cta || 0,
        ctr: (Analytic?.sum.clk * 100) / (Analytic?.sum.uqv) || 0,
        vs: Analytic?.sum.vs || 0,
        hs: Analytic?.sum.hs || 0,
        cs: Analytic?.sum.cs || 0,
        uqVisitor: Analytic?.sum.uqv || 0,
        prevuqvSum: PreAnalytic?.sum.uqv || 0,
        previmSum: PreAnalytic?.sum.im || 0,
        preveSum: PreAnalytic?.sum.hv + PreAnalytic?.sum.clk || 0,
        prevhSum: PreAnalytic?.sum.hs || 0,
        prevclsSum: PreAnalytic?.sum.cls || 0,
        prevclkSum: PreAnalytic?.sum.clk || 0,
        prevcta: PreAnalytic?.sum.cta || 0,
        prevvs: PreAnalytic?.sum.vs || 0,
        prevhs: PreAnalytic?.sum.hs || 0,
        prevcs: PreAnalytic?.sum.cs || 0,
        preuqVisitor: PreAnalytic?.sum.uqv || 0,
        perVsum: percentage(Analytic?.sum.uqv || 0, PreAnalytic?.sum.uqv || 0).toFixed(),
        perIsum: percentage(Analytic?.sum.im || 0, PreAnalytic?.sum.im || 0).toFixed(),
        perEsum: percentage(Analytic?.sum.hv + Analytic?.sum.clk || 0, PreAnalytic?.sum.hv + PreAnalytic?.sum.clk || 0).toFixed(),
        perhSum: percentage(Analytic?.sum.hv || 0, PreAnalytic?.sum.hv || 0).toFixed(),
        perCsum: percentage(Analytic?.sum.clk || 0, PreAnalytic?.sum.clk || 0).toFixed(),
        perClsSum: percentage(Analytic?.sum.cls || 0, PreAnalytic?.sum.cls || 0).toFixed(),
        perCTA: percentage(Analytic?.sum.cta || 0, PreAnalytic?.sum.cta || 0).toFixed(),
        perVs: percentage(Analytic?.sum.vs || 0, PreAnalytic?.sum.vs || 0).toFixed(),
        perHs: percentage(Analytic?.sum.hs || 0, PreAnalytic?.sum.hs || 0).toFixed(),
        perCs: percentage(Analytic?.sum.cs || 0, PreAnalytic?.sum.cs || 0).toFixed(),
        perUnique: percentage(Analytic?.sum.uqv || 0, PreAnalytic?.sum.uqv || 0).toFixed(),
      };

      if (obj.iSum >= 1000000) {
        obj.iSum = (obj.iSum / 1000000).toFixed(1) + 'M+';
      } else if (obj.iSum >= 1000) {
        obj.iSum = (obj.iSum / 1000).toFixed(1) + 'K+';
      }

      if (obj.uniqueVisitor >= 1000000) {
        obj.uniqueVisitor = (obj.uniqueVisitor / 1000000).toFixed(1) + 'M+';
      } else if (obj.uniqueVisitor >= 1000) {
        obj.uniqueVisitor = (obj.uniqueVisitor / 1000).toFixed(1) + 'K+';
      }

      if (obj.eSum >= 1000000) {
        obj.eSum = (obj.eSum / 1000000).toFixed(1) + 'M+';
      } else if (obj.eSum >= 1000) {
        obj.eSum = (obj.eSum / 1000).toFixed(1) + 'K+';
      }

      if (obj.hSum >= 1000000) {
        obj.hSum = (obj.hSum / 1000000).toFixed(1) + 'M+';
      } else if (obj.hSum >= 1000) {
        obj.hSum = (obj.hSum / 1000).toFixed(1) + 'K+';
      }

      if (obj.cSum >= 1000000) {
        obj.cSum = (obj.cSum / 1000000).toFixed(1) + 'M+';
      } else if (obj.cSum >= 1000) {
        obj.cSum = (obj.cSum / 1000).toFixed(1) + 'K+';
      }

      if (obj.clsSum >= 1000000) {
        obj.clsSum = (obj.clsSum / 1000000).toFixed(1) + 'M+';
      } else if (obj.clsSum >= 1000) {
        obj.clsSum = (obj.clsSum / 1000).toFixed(1) + 'K+';
      }

      obj.cta = obj.cta.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      obj.vs = obj.vs.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      obj.hs = obj.hs.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      obj.cs = obj.cs.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

      changeNameValue(obj);
    }
  }, [state.analyticEs]);


  //For Chart

  useEffect(() => {
    if (state.analyticEs && state.analyticEs.Analytic) {
      let objReport;
      let arrview = [],
        t_visitor = [],
        arrengage = [],
        arrhover = [],
        arrclick = [],
        arrclose = [];
      let _arrDt = [];
      let arrhours = state.analyticEs.Analytic.dt;

      if (state.rt === 'today') {
        let dishours = [];
        let offset = new Date().getTimezoneOffset();
        offset = offset > 0 ? -Math.abs(offset / 60) : Math.abs(offset / 60);

        for (let t = 0; t < arrhours.length; t++) {
          let tm = arrhours[t];
          tm = parseInt(tm) + offset;
          tm = tm > 24 ? tm - 24 : tm < 0 ? tm + 24 : tm;
          _arrDt.push(tm);

          if (tm === 0) dishours.push('00:00');
          else if (tm === 1) dishours.push('01:00');
          else if (tm === 2) dishours.push('02:00');
          else if (tm === 3) dishours.push('03:00');
          else if (tm === 4) dishours.push('04:00');
          else if (tm === 5) dishours.push('05:00');
          else if (tm === 6) dishours.push('06:00');
          else if (tm === 7) dishours.push('07:00');
          else if (tm === 8) dishours.push('08:00');
          else if (tm === 9) dishours.push('09:00');
          else if (tm === 10) dishours.push('10:00');
          else if (tm === 11) dishours.push('11:00');
          else if (tm === 12) dishours.push('12:00');
          else if (tm === 13) dishours.push('13:00');
          else if (tm === 14) dishours.push('14:00');
          else if (tm === 15) dishours.push('15:00');
          else if (tm === 16) dishours.push('16:00');
          else if (tm === 17) dishours.push('17:00');
          else if (tm === 18) dishours.push('18:00');
          else if (tm === 19) dishours.push('19:00');
          else if (tm === 20) dishours.push('20:00');
          else if (tm === 21) dishours.push('21:00');
          else if (tm === 22) dishours.push('22:00');
          else if (tm === 23) dishours.push('23:00');
          else if (tm === 24) dishours.push('24:00');
          else if (tm === 0.5) dishours.push('00:30');
          else if (tm === 1.5) dishours.push('01:30');
          else if (tm === 2.5) dishours.push('02:30');
          else if (tm === 3.5) dishours.push('03:30');
          else if (tm === 4.5) dishours.push('04:30');
          else if (tm === 5.5) dishours.push('05:30');
          else if (tm === 6.5) dishours.push('06:30');
          else if (tm === 7.5) dishours.push('07:30');
          else if (tm === 8.5) dishours.push('08:30');
          else if (tm === 9.5) dishours.push('09:30');
          else if (tm === 10.5) dishours.push('10:30');
          else if (tm === 11.5) dishours.push('11:30');
          else if (tm === 12.5) dishours.push('12:30');
          else if (tm === 13.5) dishours.push('13:30');
          else if (tm === 14.5) dishours.push('14:30');
          else if (tm === 15.5) dishours.push('15:30');
          else if (tm === 16.5) dishours.push('16:30');
          else if (tm === 17.5) dishours.push('17:30');
          else if (tm === 18.5) dishours.push('18:30');
          else if (tm === 19.5) dishours.push('19:30');
          else if (tm === 20.5) dishours.push('20:30');
          else if (tm === 21.5) dishours.push('21:30');
          else if (tm === 22.5) dishours.push('22:30');
          else if (tm === 23.5) dishours.push('23:30');
        }

        for (let j = 0; j < _arrDt.length; j++) {
          objReport = state.analyticEs.Analytic;
          arrview.push(objReport.uqv[j]);
          t_visitor.push(objReport.im[j]);
          arrengage.push(objReport.hv[j] + objReport.clk[j]);
          arrhover.push(objReport.hv[j]);
          arrclick.push(objReport.clk[j]);
          arrclose.push(objReport.cls[j]);
        }

        state.vAry = arrview;
        state.iAry = t_visitor;
        state.eAry = arrengage;
        state.hAry = arrhover;
        state.cAry = arrclick;
        state.clsAry = arrclose;
        state.arrDate = dishours;

      } else {
        state.arrDate = state.analyticEs.Analytic.dt;
        let offset = new Date().getTimezoneOffset();
        offset = offset > 0 ? -Math.abs(offset / 60) : Math.abs(offset / 60);

        for (let t = 0; t < arrhours.length; t++) {
          let tm = arrhours[t];
          tm = parseInt(tm) + offset;
          tm = tm > 24 ? tm - 24 : tm < 0 ? tm + 24 : tm;
          _arrDt.push(tm);
        }

        for (let j = 0; j < _arrDt.length; j++) {
          objReport = state.analyticEs.Analytic;
          arrview.push(objReport.uqv[j]);
          t_visitor.push(objReport.im[j]);
          arrengage.push(objReport.hv[j] + objReport.clk[j]);
          arrhover.push(objReport.hv[j]);
          arrclick.push(objReport.clk[j]);
          arrclose.push(objReport.cls[j]);
        }
        state.vAry = arrview;
        state.iAry = t_visitor;
        state.eAry = arrengage;
        state.hAry = arrhover;
        state.cAry = arrclick;
        state.clsAry = arrclose;
      }
    } else {
      state.vAry = [];
      state.iAry = [];
      state.eAry = [];
      state.hAry = [];
      state.cAry = [];
      state.clsAry = [];
      state.arrDate = [];

    }
  }, [state.analyticEs, state.vAry, state]);

  const formattedDate = (date) => {
    return moment(date).valueOf();
  };

  //handle Select Custom Date.
  const handleSelectCustomDate = () => {
    if (state.selected === 3) {
      const startDate = new Date(state.frmdt);
      const endDate = new Date(state.tdt);
      endDate.setDate(endDate.getDate() + 1);

      const sd = formattedDate(startDate);
      const ed = formattedDate(endDate);
      const obj = {
        ti: state.ti,
        rt: 'custom',
        fDate: sd,
        tDate: ed,
        popoverActive: false,
        ht: state.ht
      };

      changeNameValue(obj);
      if (state.frmdt && state.tdt) {
        changeNameValue({ popoverActive: false });
      }
      dispatch(AnalyticsDucks.getEsAnalytic(obj));
    }
  }

  const handleOpenModal = () => {
    let obj = {
      pn: 1,
      ps: 20,
      nid: state.notifList.length > 0 ? state.notifList[0]._id : undefined
    }
    changeNameValue({ analyticOpen: true, activeVistHistPage: 1, vistHistPrData: [], isLoading: true });
    dispatch(AnalyticsDucks.getVisitHistoryPr(obj));
  }

  const getVistHist = () => {
    const pg = state.pn + 1;
    const pn = state.pn + 1;

    let obj = {
      pn: pg,
      ps: 20,
      nid: state.nid
    };
    dispatch(AnalyticsDucks.getVisitHistoryPr(obj));
    changeNameValue({ pn: pn, activeVistHistPage: pg, isShowLoadMore: false })
  }

  useEffect(() => {
    changeNameValue({ isShowLoadMore: true })

    let testData = JSON.stringify(state.vistHistPrData);
    let newData = JSON.parse(testData);

    if (visitorHistoryPr && visitorHistoryPr.data && visitorHistoryPr.data.length === 0) {
      changeNameValue({ hasMore: false, isLoading: false })
    }
    else if (visitorHistoryPr && visitorHistoryPr.data && visitorHistoryPr.data.length > 0) {
      if (visitorHistoryPr.data.length < 20) {
        let test = [...newData, ...visitorHistoryPr.data];
        changeNameValue({ vistHistPrData: test, hasMore: false });
      }
      else {
        let test = [...newData, ...visitorHistoryPr.data];
        changeNameValue({ vistHistPrData: test, hasMore: true });
      }
      changeNameValue({ isLoading: false })
    }
  }, [visitorHistoryPr]);


  const percentage = (cur, pre) => {
    if (cur === 0) {
      let _cnt = ((cur - pre) * 100);
      if (Math.abs(_cnt.toFixed()) > 1000) {
        return -1000;
      } else {
        return _cnt;
      }
    }
    else if (pre === 0) { return 0; }
    else { return (cur * 100) / pre - 100; }
  }

  const handleCloseModal = () => {
    changeNameValue({ analyticOpen: false });
  }

  const clickToShwCount = () => {
    let obj;
    if (state.rt === 'today') {
      obj = {
        rt: state.rt,
        fDate: new Date().setHours(0, 0, 0, 0),
        tDate: new Date().setHours(24, 0, 0, 0)
      };
    } else if (state.rt === 'week') {
      obj = {
        rt: state.rt,
        fDate: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0),
        tDate: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(24, 0, 0, 0)
      };
    } else if (state.rt === 'month') {
      obj = {
        rt: state.rt,
        fDate: new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0, 0),
        tDate: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(24, 0, 0, 0)
      };
    } else if (state.rt === 'custom') {
      obj = {
        rt: state.rt,
        fDate: new Date(new Date().setDate(new Date().getDate() - 60)).setHours(0, 0, 0, 0),
        tDate: new Date(new Date().setDate(new Date().getDate() - 8)).setHours(24, 0, 0, 0)
      };
    }
    dispatch(AnalyticsDucks.getSignupDataCount(obj));
    state.shwDataCnt = true;
  }

  const days_between = (date) => {
    if (new Date() >= new Date(date)) {
      var seconds = Math.floor((new Date() - new Date(date)) / 1000);
      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        return interval + ' years ago';
      }
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        return interval + ' months ago';
      }
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        return interval + ' days ago';
      }
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) {
        return interval + ' hours ago';
      }
      interval = Math.floor(seconds / 60);
      if (interval >= 1) {
        return interval + ' minutes ago';
      }
      return Math.floor(seconds) + ' seconds ago';
    } else {
      return 'live';
    }
  };

  const downloadAnalytic = () => {
    let AnalyticData = [];
    let AnalyticHeader = [
      { label: state.rt === 'today' ? 'Hours' : 'Date', key: 'date' },
      { label: 'Unique Visitor', key: 'arrview' },
      { label: 'Impression', key: 't_visitor' },
      { label: 'Engaged', key: 'arrengage' },
      { label: 'Hover', key: 'arrhover' },
      { label: 'Click', key: 'arrclick' },
      { label: 'Close', key: 'arrclose' }
    ];

    const dataLength = state.arrDate.length || 1;
    for (let i = 0; i < dataLength; i++) {
      AnalyticData.push({
        date: state.arrDate[i] || (state.rt === 'today' ? 'No Data' : 'No Data'),
        arrview: state.vAry[i] || 0,
        t_visitor: state.iAry[i] || 0,
        arrengage: state.eAry[i] || 0,
        arrhover: state.hAry[i] || 0,
        arrclick: state.cAry[i] || 0,
        arrclose: state.clsAry[i] || 0,
      });
    }
    changeNameValue({ AnalyticData, AnalyticHeader });
  };


  return (
    <>
      {checkPermission('analytic', 'isview', profileData) &&
        <AnalyticsCard
          state={state}
          props={props}
          changeNameValue={changeNameValue}
          handleSelectCustomDate={handleSelectCustomDate}
          tabs={tabs}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          clickToShwCount={clickToShwCount}
          days_between={days_between}
          getVistHist={getVistHist}
          downloadAnalytic={downloadAnalytic}
          profile={profileData}
          checkPermission={checkPermission}
        />
      }
    </>
  );
};

export default memo(Analytics);
