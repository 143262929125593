//call backend api
export const base = process.env.REACT_APP_NOTIF_URL
  ? process.env.REACT_APP_NOTIF_URL
  : 'http://localhost:8001/api';

const unAuthorizedUrl = ['plan', 'auth/forgot-password', 'auth/google/callback', 'auth/facebook/callback', 'user/agency/logo', 'payment/servicebot/funds'];
// import { toast } from 'react-toastify';

export const POST = (url, body) => {
  // if (window.navigator.onLine == true) {
  const authToken = localStorage.getItem('authToken');
  const token = authToken ? authToken : null;
  let headers;
  if (unAuthorizedUrl.indexOf(url) >= 0)
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
  else
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  return fetch(base + url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then(res => res.json())
    .then(res => res);
  // }
  // else {
  //   toast.error('Check your internet connection! you are disconnected');
  // }
};