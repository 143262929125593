import React, { useCallback, useEffect, useState } from 'react';
import * as whitelabelForgotPwd from '../../ducks/whitelabelForgotPwd';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { useSelector } from 'react-redux';
import { WhitelabelForgotPwdCard } from "../../components";
import './WhitelabelForgotPwd.css';

const WhitelabelForgotPwd = () => {
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    e: '',
    errMessage: {},
    brand: {},
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const brand = useSelector(state => state.whitelabelForgotPwd.brand);

  //set brand data.
  useEffect(() => {
    if (brand && brand._id) {
      changeNameValue({ brand });
    }
  }, [brand])

  useEffect(() => {
    let host = window.location.host;
    // let host = 'https://e983-2401-4900-8899-fec1-a6c9-85aa-8655-3f09.ngrok-free.app'
    host = host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];
    let obj = {
      wurl: host
    };
    dispatch(whitelabelForgotPwd.getBrand(obj));
  }, []);

  useEffect(() => {
    //load brand script if brand have it
    if (brand) {
      if (brand.hscr) {
        //$('head').append(brand.hscr);
      }
      if (brand.fscr) {
        //$('footer').append(brand.fscr);
      }
    }
  }, [brand])

  const handleForgetWhitePWD = () => {
    if (!validator.allValid()) {
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });
      let obj = {
        e: state.e,
        agc: brand.uk
      };
      dispatch(whitelabelForgotPwd.forgetWhitePWD(obj));
    }
  }

  return (
    <WhitelabelForgotPwdCard
      state={state}
      validator={validator}
      changeNameValue={changeNameValue}
      handleForgetWhitePWD={handleForgetWhitePWD}
    />
  );
};

export default WhitelabelForgotPwd;
