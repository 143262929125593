import { Box, Text, BlockStack, InlineStack, TextField, Button, InlineGrid, Icon, Modal, PageActions, Tooltip } from '@shopify/polaris';
import { ImageAddIcon, ClipboardIcon, DeleteIcon, HideIcon, ViewIcon, EditIcon } from '@shopify/polaris-icons';
import React from 'react';
import FileBase64 from 'react-file-base64';

function ProfileCard(props) {
  const { state, changeNameValue, checkPermission, profile, userProfileImg } = props;

  return (
    <Box background="bg-surface" borderRadius="200" borderColor="border">
      <div className='topName'>
        <Box paddingBlock={"400"} paddingInline={"600"}>
          <Text variant="headingLg" as="h5">
            General
          </Text>
        </Box>
      </div>

      <Box paddingBlockEnd={400}>
        <BlockStack gap="500">
          <Box paddingInlineStart={400} paddingInlineEnd={400} paddingBlockStart={400}>
            <div className='backgorudBox'>
              <Box padding="400">
                <BlockStack gap={300}>
                  <Box><Text variant='headingMd' as='p' fontWeight='semibold'>Profile details</Text></Box>

                  <BlockStack gap="200">
                    <div style={{ width: '50%' }}>
                      <Box>
                        <InlineStack align='space-between'>
                          <Box><Text tone="subdued">Profile image</Text></Box>
                          <Tooltip content="Upload profile image" width='wide' padding="400" dismissOnMouseOut >
                            <Box> <span className='edit-icon' style={{ position: 'relative' }}> <Icon source={EditIcon} />
                              <div className='Profile_Img_Icon'>
                                {
                                  checkPermission('profile', 'isedit', profile) &&
                                  <FileBase64
                                    multiple={false}
                                    onDone={(file) => userProfileImg(file)}
                                  />
                                }
                              </div>
                            </span>
                            </Box>
                          </Tooltip>
                        </InlineStack>
                        <div style={{ width: '40px', height: '40px', position: 'relative' }}>
                          {state.iu ? <img src={state.iu} width={40} />
                            :
                            <Icon
                              source={ImageAddIcon}
                              tone="base"
                            />}
                          <div className='Profile_Img'>
                            {
                              checkPermission('profile', 'isedit', profile) &&
                              <FileBase64
                                multiple={false}
                                onDone={(file) => userProfileImg(file)}
                              />
                            }
                          </div>
                        </div>
                      </Box>
                    </div>
                  </BlockStack>

                  <InlineGrid gap="300" columns={{ xs: 1, sm: 2, md: 2 }}>
                    <BlockStack gap={100}>
                      <TextField value={state.e} disabled label={<Text tone="subdued">Email</Text>} />
                    </BlockStack>
                    <TextField label={<Text tone="subdued">Full name</Text>} value={state.fn} onChange={(e) => changeNameValue({ fn: e })} error={state.errMessage.fn ? "The current password field is required." : false} />
                    {props.validator.message('fn', state.fn, 'required')}
                  </InlineGrid>
                  {checkPermission('profile', 'isedit', profile) &&
                    <Box>
                      <Button variant="primary" tone='success' onClick={() => props.updateProfile('fn', state.fn)}>Save</Button>
                    </Box>
                  }
                </BlockStack>
              </Box>
            </div>
          </Box>


          <Box paddingInlineStart={400} paddingInlineEnd={400}>
            <div className='backgorudBox'>
              <Box padding="400">
                <BlockStack gap={200}>
                  <Box><Text variant='headingMd' as='p' fontWeight='semibold'>Password</Text></Box>

                  <InlineGrid gap="300" columns={{ xs: 1, sm: 2, md: 2 }}>
                    <div className='divCpass'>
                      <TextField label={<Text tone="subdued">Current password</Text>}
                        placeholder='Enter current password'
                        type={state.isCurnPassVisible ? 'text' : 'password'}
                        value={state.pd} onChange={(e) => changeNameValue({ pd: e })}
                        error={state.errMessage.pd ? "The current password field is required." : false}
                      />
                      {props.validator.message('pd', state.pd, 'required')}
                      <Button
                        icon={state.isCurnPassVisible ? HideIcon : ViewIcon}
                        onClick={() => changeNameValue({ isCurnPassVisible: !state.isCurnPassVisible })}
                        plain
                        variant='monochromePlain'
                      />
                    </div>

                    <div className='divCpass'>
                      <TextField label={<Text tone="subdued">New password</Text>}
                        placeholder='Enter new password'
                        type={state.isNewPassVisible ? 'text' : 'password'}
                        value={state.npd} onChange={(e) => changeNameValue({ npd: e })}
                        error={state.errMessage.npd ? "The new password field is required." : false} />
                      {props.validator.message('npd', state.npd, 'required')}
                      <Button
                        icon={state.isNewPassVisible ? HideIcon : ViewIcon}
                        onClick={() => changeNameValue({ isNewPassVisible: !state.isNewPassVisible })}
                        plain
                        variant='monochromePlain'
                      />
                    </div>
                  </InlineGrid>
                  {checkPermission('profile', 'isedit', profile) &&
                    <Box>
                      <Button variant="primary" tone='success' onClick={() => props.handleChangePassword()}>Save</Button>
                    </Box>
                  }
                </BlockStack>
              </Box>
            </div>
          </Box>

          {/* API key */}
          {((profile && profile?.csub !== undefined && profile?.csub !== 'free') || (profile?.utyp === 'appsumo' || profile?.pvd === 'shopify' || profile?.pvd === 'wix' || profile?.pvd === 'shopline')) &&
            <Box paddingInlineStart={400} paddingInlineEnd={400}>
              <div className='backgorudBox' >
                <Box padding="400">
                  <div className='apiOuterBox'>
                    <pre className="apiBack">
                      &lt;!-- API BEGIN --&gt;
                      <br />
                      <span>{state.ak}</span>
                      <br />
                      &lt;!-- API END --&gt;

                    </pre>
                    <div className='apiKeyBtn'>
                      <BlockStack gap={200}>
                        <Box><Text variant='headingMd' as='p' fontWeight='semibold'>API key</Text></Box>
                        <Button onClick={() => props.handleCopyApiKey()}><Box padding={200}><BlockStack gap={100}><Icon source={ClipboardIcon} /><Text tone="subdued">Press here to copy the API</Text> </BlockStack></Box></Button>
                      </BlockStack>
                    </div>
                  </div>
                </Box>
              </div>
            </Box>}

          <Box paddingInlineStart={400} paddingInlineEnd={400}>
            <div className='backgorudBox'>
              <Box padding="400">
                <BlockStack gap={200}>
                  <InlineStack align='space-between'><Text variant='headingMd' as='p' fontWeight='semibold'>Email subscribers</Text> <Text variant="headingXs" as="h6">Receive weekly reports on registered email</Text> </InlineStack>

                  <InlineStack align='space-between'>
                    <Tooltip padding='400' content='Turn ON to receive weekly goal reports on your registered e-mail ID'
                      width='wide' hasUnderline dismissOnMouseOut>
                      <Text tone="subdued">Goal</Text>
                    </Tooltip>

                    <label className="switch">
                      <input type="checkbox" checked={state?.esub?.gl}
                        onChange={() => props.handleupdEmailSend('gl', !state?.esub?.gl)} />
                      <span className="switch-label"></span>
                      <span className="switch-handle"></span>
                    </label></InlineStack>

                  <InlineStack align='space-between'>
                    <Tooltip padding='400' content='Turn ON to receive weekly performance analytics on your registered e-mail ID'
                      width='wide' hasUnderline dismissOnMouseOut>
                      <Text tone="subdued">Analytics</Text>
                    </Tooltip>
                    <label className="switch">
                      <input type="checkbox" checked={state?.esub?.ntf}
                        onChange={() => props.handleupdEmailSend('ntf', !state?.esub?.ntf)} />
                      <span className="switch-label"></span>
                      <span className="switch-handle"></span>
                    </label></InlineStack>

                </BlockStack>
              </Box>
            </div>
          </Box>

          {/* delete account */}
          {state.deleteAccount &&
            <Box paddingInlineStart={400} paddingInlineEnd={400}>
              <div className='backgorudBox' >
                <Box padding={400}>
                  <div className='deleteBox' onClick={() => changeNameValue({ deleteOpen: true })}>
                    <Icon source={DeleteIcon} tone="critical" />
                    <Text variant="headingXs" as="h6">Delete my account</Text>
                  </div>
                </Box>
              </div>
            </Box>
          }

          <Modal // delete account modal
            size='small'
            open={state.deleteOpen}
            title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
            onClose={() => changeNameValue({ deleteOpen: false })}>
            <Modal.Section>
              <div className="pageAction_paDDing">
                <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure want to delete this account?</Text>
                <PageActions
                  primaryAction={<Button variant='primary' tone='success' onClick={() => props.handleDelAccount()}>Yes</Button>}
                  secondaryActions={[{
                    content: 'No',
                    onAction: () => changeNameValue({ deleteOpen: false })
                  }]} />
              </div>
            </Modal.Section>
          </Modal>
        </BlockStack>
      </Box >
    </Box >
  );
}

export default ProfileCard;
