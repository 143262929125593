import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const shopifyPricingPlan = createSlice({
  name: 'shopifyPricingPlan',
  initialState,
  reducers: {
    getShopifyPricingPlan: (state, action) => { },
    getShopifyPricingPlanSuccess: (state, action) => {
      return { ...state, shopifyPricingPlan: action.payload };
    },
    shopifyPayment: (state, action) => { }
  }
});

export const {
  getShopifyPricingPlan,
  getShopifyPricingPlanSuccess,
  shopifyPayment
} = shopifyPricingPlan.actions;

export default shopifyPricingPlan.reducer;
