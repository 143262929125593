import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const notifEdit = createSlice({
  name: 'notifEdit',
  initialState,
  reducers: {
    getNotfById: (state, action) => { },
    getNotfByIdSuccess: (state, action) => {
      return { ...state, notiById: action.payload };
    },
    updateNotifDesign: (state, action) => { },
    getIconList: (state, action) => { },
    getIconSuccess: (state, action) => {
      return { ...state, iconListArr: action.payload };
    },
    updDisplayType: (state, action) => { },
    updAbsoluteURL: (state, action) => { },
    addDTU: (state, action) => { },
    deleteDTU: (state, action) => { },
    addDomain: (state, action) => { },
    getDomain: (state, action) => { },
    getDomainSuccess: (state, action) => {
      return { ...state, ddnList: action.payload };
    },
    delDomain: (state, action) => { },
    updTargetToggle: (state, action) => { },
    updNotifCntry: (state, action) => { },
    addUTMDisplay: (state, action) => { },
    deleteTarget: (state, action) => { },
    addRefDisplay: (state, action) => { },
    addCoockiDisplay: (state, action) => { },
    updDST: (state, action) => { },
    addDTSCU: (state, action) => { },
    deleteDTSCU: (state, action) => { },
    getPixelData: (state, action) => { },
    getPixelDataSuccess: (state, action) => {
      return { ...state, pixelData: action.payload };
    },
    getActiveIntgList: (state, action) => { },
    getActiveIntgListSuccess: (state, action) => {
      return { ...state, lstActiveIntg: action.payload };
    },
    getDataCount: (state, action) => { },
    getDataCountSuccess: (state, action) => {
      return { ...state, dataCount: action.payload };
    },
    deleteMediaIcon: (state, action) => { },
    sendWebhookData: (state, action) => { },
    updNotifIcon: (state, action) => { },
    updNotifIconSuccess: (state, action) => {
      return { ...state, iconUrl: action.payload };
    },
    updNotifStatus: (state, action) => { },
    updNotifStatusSuccess: (state, action) => {
      return { ...state, activeStatus: action.payload };
    },
    getProductURL: (state, action) => { },
    getProductURLSuccess: (state, action) => {
      return { ...state, productURL: action.payload };
    },
    addDataSrc: (state, action) => { },
    delDataSrc: (state, action) => { },
    deleteAbTestNotif: (state, action) => { },
    createAbTestNotif: (state, action) => { },
    getUserNotif: (state, action) => { },
    getUserNotifSuccess: (state, action) => {
      return { ...state, userNotifList: action.payload };
    },
    importDisplaySetting: (state, action) => { },
    getShpPrducts: (state, action) => { },
    getShpPrductsSuccess: (state, action) => {
      return { ...state, shopProducts: action.payload };
    },
    addShpProStock: (state, action) => { },
    addShpProStockSuccess: (state, action) => { },
    changeShpProStatus: (state, action) => { },
    changeShpProStatusSuccess: (state, action) => { },
    delShpProStock: (state, action) => { },
    delShpProStockSuccess: (state, action) => { },
    getIntData: (state, action) => { },
    getIntDataSuccess: (state, action) => {
      return { ...state, intData: action.payload };
    },
    delInteCntData: (state, action) => { },
    addCapInc: (state, action) => { },
    delCapInclUrl: (state, action) => { },
    delCapExclUrl: (state, action) => { },
    callPolarisBug: (state, action) => { },
    clearState: (state) => {
      return {
        ...state, lstActiveIntg: [], dataCount: [], pixelData: [], iconListArr: [], ddnList: [],
        notiById: {}, intData: {}, shopProducts: [], userNotifList: [], activeStatus: '', iconUrl: ''
      };
    },
  }
});

export const {
  getNotfById,
  getNotfByIdSuccess,
  updateNotifDesign,
  getIconList,
  getIconSuccess,
  updDisplayType,
  updAbsoluteURL,
  addDTU,
  deleteDTU,
  addDomain,
  getDomain,
  getDomainSuccess,
  delDomain,
  updTargetToggle,
  updNotifCntry,
  addUTMDisplay,
  deleteTarget,
  addRefDisplay,
  addCoockiDisplay,
  updDST,
  addDTSCU,
  deleteDTSCU,
  getPixelData,
  getPixelDataSuccess,
  getActiveIntgList,
  getActiveIntgListSuccess,
  getDataCount,
  getDataCountSuccess,
  deleteMediaIcon,
  sendWebhookData,
  updNotifIcon,
  updNotifIconSuccess,
  updNotifStatus,
  updNotifStatusSuccess,
  getProductURL,
  getProductURLSuccess,
  addDataSrc,
  delDataSrc,
  deleteAbTestNotif,
  createAbTestNotif,
  getUserNotif,
  getUserNotifSuccess,
  importDisplaySetting,
  getShpPrducts,
  getShpPrductsSuccess,
  addShpProStock,
  addShpProStockSuccess,
  changeShpProStatus,
  changeShpProStatusSuccess,
  delShpProStock,
  delShpProStockSuccess,
  getIntData,
  getIntDataSuccess,
  delInteCntData,
  addCapInc,
  delCapInclUrl,
  delCapExclUrl,
  callPolarisBug,
  clearState
} = notifEdit.actions;

export default notifEdit.reducer;