import React, { useCallback, useState } from 'react';
import { ForgotPwdCard } from "../../components";
import { useDispatch } from 'react-redux';
import * as loginDucks from '../../ducks/login';
import SimpleReactValidator from "simple-react-validator";
import '../Login/Login.css';
import { useSelector } from 'react-redux';

const ForgotPwd = () => {
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    e: '',
    errMessage: {},
  })

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const isLoading = useSelector(state => state.loading.isLoading);

  //handle Forget Password.
  const handleForgetPD = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    } else {
      changeNameValue({ errMessage: {} });
      let obj = {
        e: state.e,
      }
      dispatch(loginDucks.forgetPD(obj));
    }
  }

  return (
    <ForgotPwdCard
      state={state}
      isLoading={isLoading}
      validator={validator}
      changeNameValue={changeNameValue}
      handleForgetPD={handleForgetPD}
    />
  );
};

export default ForgotPwd;