import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const referEarn = createSlice({
  name: 'referEarn',
  initialState,
  reducers: {
    getAffData: (state, action) => { },
    getAffDataSuccess: (state, action) => {
      return { ...state, AffData: action.payload };
    },
    getStatus: (state, action) => { },
    getStatusSuccess: (state, action) => {
      return { ...state, statusData: action.payload };
    },
    sendAffMail: (state, action) => { },
    sendAffMailSuccess: (state, action) => {
      return { ...state, sendData: action.payload };
    },
    getWdlRequest: (state, action) => { },
    getWdlRequestSuccess: (state, action) => {
      return { ...state, wdlReqAffData: action.payload };
    },
    afiApply: (state, action) => { },
    afiApplySuccess: (state, action) => {
      return { ...state, applyData: action.payload };
    },
    affWdlRequest: (state, action) => { },
    affWdlRequestSuccess: (state, action) => {
      return { ...state, affWdlRequest: action.payload };
    }
  }
});

export const {
  getAffData,
  getAffDataSuccess,
  getStatus,
  getStatusSuccess,
  sendAffMail,
  sendAffMailSuccess,
  getWdlRequest,
  getWdlRequestSuccess,
  afiApply,
  afiApplySuccess,
  affWdlRequest,
  affWdlRequestSuccess
} = referEarn.actions;

export default referEarn.reducer;
