import React, { useEffect } from 'react';
import { Text, Popover, OptionList, RangeSlider, ChoiceList, Icon, Checkbox, InlineGrid, Button, Box, InlineStack, TextField, BlockStack, Tooltip } from '@shopify/polaris';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ThemeTemplateIcon, EditIcon, TextInRowsFilledIcon, LayoutHeaderIcon, DragHandleIcon, FilterIcon, EyeCheckMarkIcon, ClockIcon, DatabaseIcon, CursorIcon, ButtonIcon } from '@shopify/polaris-icons';
import ColorPick from './ColorPick';
import { useDispatch } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import { toastify } from '../../ducks/toast';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-emoji/dist/quill-emoji.css';
import 'react-quill/dist/quill.snow.css';

const dateFormatOpt = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };

// Add fonts to whitelist
setTimeout(() => {
  var Font = Quill.import('formats/font');
  Font.whitelist = ['OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans', 'WebsiteFont'];
  Quill.register(Font, true);
}, 1000);

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

//get one emoji and replace it with our custom
const _emj = ShortNameEmoji.DEFAULTS.emojiList.filter(x => x.shortname === ':skull:');
if (_emj && _emj.length > 0) {
  let objEmj = _emj[0];
  objEmj.code_decimal = '&#x2b50;';
  objEmj.unicode = '2B50';
}

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

var custTagDropDown = Quill.import('ui/picker');
custTagDropDown = [];
Quill.register(custTagDropDown, true);

let dispatch;

const customHandler = {
  'color': function (value) {
    if (value === 'custom-color') value = window.prompt('Enter Hex Color Code');
    this.quill.format('color', value, 'user');
  },
  'background': function (value) {
    if (value === 'custom-background') value = window.prompt('Enter Hex Color Code');
    this.quill.format('background', value, 'user');
  },
  'custTagDropDown': function (value) {
    if (this.quill.getText().includes(value) !== true)
      this.quill.insertText(this.quill.getSelection().index, '{{' + value + '}}', 'user');
    else dispatch(toastify({ type: 'error', msg: '{{' + value + '}} already available' }));
  }
};

const PreventEnter = {
  bindings: {
    tab: false,
    custom: {
      key: 13,
      shiftKey: true,
      handler: function () { /** do nothing */ }
    },
    handleEnter: {
      key: 13,
      handler: function () { /** do nothing */ }
    },
    handleMouseClick: {
      handler: function () { console.log('click'); }
    }
  }
};

//config. feed panel card
const toolbarModuleForCard = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': ['OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans', 'WebsiteFont'] }],
    [{ 'custTagDropDown': [] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [
      { 'list': 'ordered' }, { 'list': 'bullet' },
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean'],
  ],
  handlers: customHandler
};

const modulesQuillForCard = {
  toolbar: toolbarModuleForCard,
  keyboard: PreventEnter,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

//config. feedpanel
const toolbarModuleForPanel = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': ['OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans', 'WebsiteFont'] }],
    [{ 'custTagDropDown': [] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [
      { 'list': 'ordered' }, { 'list': 'bullet' },
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean'],
  ],
  handlers: customHandler
};

const modulesQuillForPanel = {
  toolbar: toolbarModuleForPanel,
  keyboard: PreventEnter,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

//config. feedbtn
const toolbarModuleForBtn = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': ['OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans', 'WebsiteFont'] }],
    [{ 'custTagDropDown': [] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [
      { 'list': 'ordered' }, { 'list': 'bullet' },
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean'],
  ],
  handlers: customHandler
};

const modulesQuillForBtn = {
  toolbar: toolbarModuleForBtn,
  keyboard: PreventEnter,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

//for format quill
const formatsQuill = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent', 'align',
  'link', 'image', 'background', 'color', 'emoji'
];

function formateDate(dt) {
  const date = new Date(dt);
  return date.getFullYear() + '-' +
    String(date.getMonth() + 1).padStart(2, '0') + '-' +
    String(date.getDate()).padStart(2, '0') + 'T' +
    String(date.getHours()).padStart(2, '0') + ':' +
    String(date.getMinutes()).padStart(2, '0');
}

const ReviewFeedCard = ({ props }) => {

  let { state, handleMainTextChange, handleSettingChange, changeNameValue, openIconModal, copyHTMLSnippet,
    designValidator, editorFocus, editorBlur } = props;
  let { cnf, notifId, notiById, queryNtt, defaultNotif, iconTab, errMessage } = state;

  let isAbtest = new URL(window.location.href).searchParams.get('abId') !== null || false;

  let dsplyRuleOpts = [
    {
      label: <Tooltip dismissOnMouseOut padding='400' content='Widget will get displayed when the visitor moves, cursor outside the upper page boundary.' width='wide' hasUnderline>
        <Text>Exit intent</Text></Tooltip>, value: 1
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content={`After ${cnf?.aft} seconds, a widget will get displayed on-page.`} width='wide' hasUnderline>
        <Text>Show after {cnf?.aft || 'x'} second</Text></Tooltip>, value: 2
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content={`Widget will get displayed once visitor scroll ${cnf?.sprc}% screen window.`} width='wide' hasUnderline>
        <Text>Show after {cnf?.sprc}% scroll</Text></Tooltip>, value: 3
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content='You can set feed on your website using custom class.' width='wide' hasUnderline>
        <Text>Show on custom class click</Text></Tooltip>, value: 4
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content='Widget will get displayed on custom function call.' width='wide' hasUnderline>
        <Text>None of the above</Text></Tooltip>, value: 5
    }
  ];

  let weekOpts = [
    { label: 'Sun', value: '0' },
    { label: 'Mon', value: '1' },
    { label: 'Tue', value: '2' },
    { label: 'Wed', value: '3' },
    { label: 'Thrd', value: '4' },
    { label: 'Fri', value: '5' },
    { label: 'Sat', value: '6' }
  ];

  let notifOrder = [
    { value: '0', label: 'Ascending' },
    { value: '1', label: 'Descending' }
  ];

  let skipNotifTime = [
    { value: '1', label: '1 hour' },
    { value: '2', label: '2 hour' },
    { value: '3', label: '3 hour' },
    { value: '4', label: '4 hour' },
    { value: '5', label: '5 hour' },
    { value: '6', label: '6 hour' },
    { value: '7', label: '7 hour' },
    { value: '8', label: '8 hour' },
    { value: '9', label: '9 hour' },
    { value: '10', label: '10 hour' },
    { value: '11', label: '11 hour' },
    { value: '12', label: '12 hour' },
    { value: '18', label: '18 hour' },
    { value: '24', label: '24 hour' },
    { value: '36', label: '36 hour' },
    { value: '48', label: '48 hour' },
    { value: '60', label: '60 hour' }
  ];

  let borderStyle = [
    { value: 'solid', label: 'Solid' },
    { value: 'dashed', label: 'Dashed' },
    { value: 'dotted', label: 'Dotted' },
    { value: 'double', label: 'Double' }
  ];

  //animition for desktop
  const deskAni = [
    { value: 'none', label: 'None' },
    { value: 'fade', label: 'Fade' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'zoom_in_down', label: 'Zoom in down' },
    { value: 'slide', label: 'Slide' },
    { value: 'horizonatal_slide', label: 'Slide horizontal' },
    { value: 'side_flip_in', label: 'Slide flip in' },
    { value: 'side_flip_back', label: 'Slide flip back' },
    { value: 'flip', label: 'Flip' },
    { value: 'up_flip', label: 'Flip up' },
    { value: 'back_flip', label: 'Flip back' }
  ];

  //animition for phone
  const phoneAni = [
    { value: 'none', label: 'None' },
    { value: 'fade', label: 'Fade' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'zoom_in_down', label: 'Zoom in down' },
    { value: 'slide', label: 'Slide' },
    { value: 'horizonatal_slide', label: 'Slide horizontal' },
    { value: 'side_flip_in', label: 'Slide flip in' },
    { value: 'side_flip_back', label: 'Slide flip back' },
    { value: 'flip', label: 'Flip' },
    { value: 'up_flip', label: 'Flip up' },
    { value: 'back_flip', label: 'Flip back' }
  ];

  //position in desktop
  let deskPosition = [
    { value: 'left_top', label: 'Left top' },
    { value: 'center_top', label: 'Center top' },
    { value: 'right_top', label: 'Right top' },
    { value: 'left_center', label: 'Left center' },
    { value: 'right_center', label: 'Right center' },
    { value: 'left_bottom', label: 'Left bottom' },
    { value: 'center_bottom', label: 'Center bottom' },
    { value: 'right_bottom', label: 'Right bottom' }
  ];

  //position in phone
  let phonePosition = [
    { value: 'left_top', label: 'Left top' },
    { value: 'center_top', label: 'Center top' },
    { value: 'right_top', label: 'Right top' },
    { value: 'left_center', label: 'Left center' },
    { value: 'right_center', label: 'Right center' },
    { value: 'left_bottom', label: 'Left bottom' },
    { value: 'center_bottom', label: 'Center bottom' },
    { value: 'right_bottom', label: 'Right bottom' }
  ];

  let btnPosition = [
    { value: 'vertical', label: 'Vertical' },
    { value: 'horizontal', label: 'Horizontal' }
  ];

  let displayRecord = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' }
  ];

  let verticalTabsArr = [
    { label: 'Text editor', img: ThemeTemplateIcon },
    { label: 'Click actions', img: CursorIcon },
    { label: 'Review tab & header', img: LayoutHeaderIcon },
    { label: 'Reviewer row', img: TextInRowsFilledIcon },
    { label: 'Position & animation', img: ButtonIcon },
    { label: 'Style', img: EyeCheckMarkIcon },
    { label: 'Data control', img: DatabaseIcon },
    { label: 'Review filters', img: FilterIcon },
    { label: 'Timing control', img: ClockIcon }
  ];

  function getValue(lbl) {
    if (!lbl) return '';
    return lbl.replace(/ /g, '').replace(/^./, char => char.toLowerCase())
  }

  // hide-show tab conditions
  useEffect(() => {
    verticalTabsArr = verticalTabsArr
      .map(tab => { return { ...tab, value: getValue(tab.label) } })
      .filter(tab => {
        if (tab.label === 'Click actions') {
          return (cnf?.cb !== undefined || cnf?.cbg !== undefined);
        }
        if (tab.label === 'Position & animation') {
          return notiById?.idisnp !== true;
        }
        return true;
      });
    changeNameValue({ verticalTabsArr });
  }, [queryNtt, notiById]);

  dispatch = useDispatch();
  var style = document.createElement('style');
  document.head.appendChild(style);

  // for review feed
  let arrbtnChips = ['SOCIAL_ICON', 'AVERAGE', 'STAR', 'TOTAL_COUNT'];
  toolbarModuleForBtn.container[2][0].custTagDropDown = arrbtnChips;
  for (let i = 0; i < arrbtnChips.length; i++) {
    const e = arrbtnChips[i];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  //create dynemic value dropdown for feed panel
  let arrChips = ['SOCIAL_ICON', 'AVERAGE', 'TOTAL_COUNT', 'SOCIAL_NAME', 'STAR',];
  toolbarModuleForPanel.container[2][0].custTagDropDown = arrChips;
  for (let i = 0; i < arrChips.length; i++) {
    const e = arrChips[i];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  //create dynemic value dropdown for feed panel card
  let arrCard = ['SOCIAL_ICON', 'USERNAME', 'TIME_AGO', 'REVIEW_TEXT',];
  toolbarModuleForCard.container[2][0].custTagDropDown = arrCard;
  for (let j = 0; j < arrCard.length; j++) {
    const e = arrCard[j];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  // Handles drag end event for icon field
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(cnf?.it);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    handleMainTextChange('it', reorderedItems); //set value in cnf field
  };

  // Function to generate time options
  const generateTimeOptions = (interval = 5) => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += interval) {
        const formattedTime = new Date(0, 0, 0, hour, minutes).toLocaleTimeString('en-US', {
          hour: '2-digit', minute: '2-digit', hour12: true
        });
        options.push({ label: formattedTime, value: formattedTime });
      }
    }
    return options;
  };
  const timeOptions = generateTimeOptions();

  return (
    <Box padding={400}>

      {state.verticalActive === getValue('Text editor') ?
        <div>
          <Tooltip dismissOnMouseOut padding='400' content={'Floating title'} hasUnderline>
            <Text fontWeight='medium'>Floating title</Text>
          </Tooltip>
          <Box paddingInlineStart={400} paddingBlockStart={100}>
            <ReactQuill value={cnf?.fl} className='preview-mail hideEditor fltxt'
              modules={modulesQuillForBtn}
              formats={formatsQuill}
              onFocus={() => editorFocus('fltxt')}
              onBlur={() => editorBlur('fltxt')}
              onChange={(newValue, delta, source) => {
                if (source === 'user') { handleMainTextChange('fl', newValue) }
              }} />
          </Box>
        </div>
        :
        state.verticalActive === getValue('Click actions') ?
          <div>
            <Checkbox
              label={<Tooltip dismissOnMouseOut padding='400' content='Change icon background color of close icon.' width='wide' hasUnderline><Text fontWeight='medium'>Close icon</Text></Tooltip>}
              checked={cnf?.cb}
              onChange={(e) => handleMainTextChange('cb', e)} />
            <Box paddingInlineStart={700} paddingBlockStart={100}>
              <ColorPick
                labelName='Close icon color'
                colorKey="cbg"
                colorValue={cnf?.cbg}
                functionCall={handleMainTextChange} />
            </Box>
          </div>
          :
          state.verticalActive === getValue('Review tab & header') ?
            <BlockStack gap={500}>
              <div>
                <Box paddingBlockEnd={300}>
                  <Tooltip dismissOnMouseOut padding='400' content={'Review tab'} hasUnderline>
                    <Text fontWeight='medium'>Review tab</Text>
                  </Tooltip>
                </Box>
                <BlockStack gap={200}>
                  <div>
                    <Checkbox
                      label={<Text>Review tab text</Text>}
                      checked={cnf?.rvitb}
                      onChange={(e) => handleMainTextChange('rvitb', e)}
                    />
                    <Box paddingInlineStart={700}>
                      <ReactQuill value={cnf?.rvttl} className='preview-mail hideEditor feedtabtitletxt'
                        modules={modulesQuillForPanel}
                        formats={formatsQuill}
                        onFocus={() => editorFocus('feedtabtitletxt')}
                        onBlur={() => editorBlur('feedtabtitletxt')}
                        onChange={(newValue, delta, source) => {
                          if (source === 'user') { handleMainTextChange('rvttl', newValue) }
                        }} />
                    </Box>
                  </div>

                  <div>
                    <Checkbox
                      label={<Text>Rating display</Text>}
                      checked={cnf?.rvpisrtb}
                      onChange={(e) => handleMainTextChange('rvpisrtb', e)}
                    />
                    {cnf?.rvpisrtb === true &&
                      <Box paddingInlineStart={700} paddingBlockStart={100}>
                        <InlineGrid columns={2} gap={300}>
                          {cnf?.rvrbctb !== undefined &&
                            <ColorPick
                              labelName={<Text tone='subdued'>Rating bg color</Text>}
                              colorKey="rvrbctb"
                              colorValue={cnf?.rvrbctb}
                              functionCall={handleMainTextChange} />
                          }
                          {cnf?.rvrfctb &&
                            <ColorPick
                              labelName={<Text tone='subdued'>Rating font color</Text>}
                              colorKey="rvrfctb"
                              colorValue={cnf?.rvrfctb}
                              functionCall={handleMainTextChange} />
                          }
                        </InlineGrid>
                      </Box>
                    }
                  </div>

                  <div>
                    <Checkbox
                      label={<Text>Show "All" tab</Text>}
                      checked={cnf?.rviall}
                      onChange={(e) => handleMainTextChange('rviall', e)}
                    />
                    <Box paddingInlineStart={700}>
                      <TextField
                        value={cnf?.rvall}
                        disabled={!cnf?.rviall}
                        onChange={(e) => handleMainTextChange('rvall', e)} />
                    </Box>
                  </div>

                </BlockStack>
              </div>

              <div>
                <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Review header section'}>
                  <Text fontWeight='medium'>Review header</Text>
                </Tooltip>
                <BlockStack gap={200}>

                  {defaultNotif?.ttn !== 'review_feed_2' &&
                    <Box paddingInlineStart={700} paddingBlockStart={200}>
                      <TextField
                        value={cnf?.rvallttl}
                        label={<Text>Overall rating text</Text>}
                        onChange={(e) => handleMainTextChange('rvallttl', e)} />
                    </Box>
                  }

                  {defaultNotif?.ttn !== 'review_feed_2' &&
                    <Box paddingInlineStart={700}>
                      <Text>Header title</Text>
                      <ReactQuill value={cnf?.rvpttltx} className='preview-mail hideEditor rvpaneltxt'
                        modules={modulesQuillForPanel} formats={formatsQuill}
                        onFocus={() => editorFocus('rvpaneltxt')}
                        onBlur={() => editorBlur('rvpaneltxt')}
                        onChange={(newValue, delta, source) => {
                          if (source === 'user') { handleMainTextChange('rvpttltx', newValue) }
                        }} />
                    </Box>
                  }

                  <div>
                    {defaultNotif?.ttn !== 'review_feed_2' &&
                      <Checkbox
                        label={<Tooltip dismissOnMouseOut padding='400' content='Rating display in header section' hasUnderline width='wide'>
                          <Text>Rating display</Text></Tooltip>}
                        checked={cnf?.rvpisrtl}
                        onChange={(e) => handleMainTextChange('rvpisrtl', e)} />
                    }
                    {cnf?.rvpisrtl === true && defaultNotif?.ttn !== 'review_feed_2' &&
                      <Box paddingInlineStart={700} paddingBlockStart={100}>
                        <InlineGrid columns={2} gap={300}>
                          {cnf?.rvrbcttl &&
                            <ColorPick
                              labelName={<Text tone='subdued'>Rating bg color</Text>}
                              colorKey="rvrbcttl"
                              colorValue={cnf?.rvrbcttl}
                              functionCall={handleMainTextChange} />
                          }
                          {cnf?.rvrfcttl &&
                            <ColorPick
                              labelName={<Text tone='subdued'>Rating font color</Text>}
                              colorKey="rvrfcttl"
                              colorValue={cnf?.rvrfcttl}
                              functionCall={handleMainTextChange} />
                          }
                        </InlineGrid>
                      </Box>
                    }
                  </div>

                  <div>
                    {defaultNotif?.ttn !== 'review_feed_2' &&
                      <Box paddingBlockEnd={100}>
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding='400' content='Avatar image display in header' width='wide' hasUnderline><Text>Avatar image display</Text></Tooltip>}
                          checked={cnf?.rviavh}
                          onChange={(e) => handleMainTextChange('rviavh', e)} />
                      </Box>
                    }
                    <Box paddingInlineStart={700}>
                      <Tooltip dismissOnMouseOut padding='400' hasUnderline content={'Recommended text'}>
                        <Text tone='subdued'>Recommended text</Text>
                      </Tooltip>
                      <ReactQuill value={cnf?.rvrcmndt} className='preview-mail hideEditor rvpaneltxt'
                        modules={modulesQuillForPanel} formats={formatsQuill}
                        onFocus={() => editorFocus('rvpaneltxt')}
                        onBlur={() => editorBlur('rvpaneltxt')}
                        onChange={(newValue, delta, source) => {
                          if (source === 'user') { handleMainTextChange('rvrcmndt', newValue) }
                        }} />
                    </Box>
                  </div>

                </BlockStack>
              </div>
            </BlockStack>
            :
            state.verticalActive === getValue('Reviewer row') ?
              <BlockStack gap={500}>
                <div>
                  <Tooltip dismissOnMouseOut content={defaultNotif.ttn !== 'review_feed_1' ?
                    'Customise icon image or background color'
                    : 'Show reviewer image or custom icon in review card. Also, drag it & set priority order'} hasUnderline width='wide' padding='400'>
                    <Text fontWeight='medium'>Reviewer icon</Text>
                  </Tooltip>
                  <Box paddingInlineStart={100} paddingBlockStart={150}>
                    <Text variant='bodySm'>Drag & drop to set priority order (Icon fallback order)</Text>

                    <Box paddingBlock={400} paddingBlockEnd={800} paddingInlineStart={300}>
                      <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                        <Droppable droppableId="droppable" direction="horizontal">
                          {(provided) => (
                            <div className='draggable_wrap'
                              ref={provided.innerRef}
                              {...provided.droppableProps}>

                              {cnf?.it.map((item, index) => (
                                <Draggable key={item} draggableId={String(item)} index={index}>
                                  {(provided) => (
                                    <div className="draggable-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Icon tone='base' source={DragHandleIcon} />
                                      {item === 0 ?
                                        <label onClick={() => changeNameValue({ iconTab: 0 })}
                                          className={iconTab === 0 ? 'smooth-dnd-active' : ''}>
                                          <Text variant='bodyXs'>Icon</Text>
                                          <img width='30' src={cnf?.iu} alt="Image" />
                                          <Button id='upldIcn' onClick={() => openIconModal('iu')} icon={EditIcon} variant='monochromePlain' />
                                        </label>
                                        : item === 1 ?
                                          <label onClick={() => changeNameValue({ iconTab: 1 })}
                                            className={iconTab === 1 ? 'smooth-dnd-active' : ''}>
                                            <Text variant='bodyXs'>Map</Text>
                                            <img width='30' src={'https://storage.googleapis.com/wiser_goal/notif_icon/icomap.png'} alt="Image" />
                                          </label>
                                          : item === 2 ?
                                            <label onClick={() => changeNameValue({ iconTab: 2 })}
                                              className={iconTab === 2 ? 'smooth-dnd-active' : ''}>
                                              <Text variant='bodyXs'>Dynamic</Text>
                                              <img width='30' src={'https://storage.googleapis.com/wiser_goal/notif_icon/product-icon.png'} alt="Image" />
                                            </label>
                                            : item === 3 ?
                                              <label onClick={() => changeNameValue({ iconTab: 3 })}
                                                className={iconTab === 3 ? 'smooth-dnd-active' : ''}>
                                                <Text variant='bodyXs'>Gravatar</Text>
                                                <img width='30' src={'https://storage.googleapis.com/wiserimg/template_icon/user.png'} alt="Image" />
                                              </label>
                                              : item === 4 &&
                                              <label onClick={() => changeNameValue({ iconTab: 4 })}
                                                className={iconTab === 4 ? 'smooth-dnd-active' : ''}>
                                                <Text variant='bodyXs'>Flag</Text>
                                                <img width='30' src={'https://storage.googleapis.com/wiserimg/template_icon/flag_icon.svg?q=12'} alt="Image" />
                                              </label>
                                      }
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>

                      </DragDropContext>
                    </Box>

                    <ColorPick
                      labelName='Icon background color'
                      width='medium'
                      colorKey="ibg"
                      colorValue={cnf?.ibg || '#fff'}
                      functionCall={handleMainTextChange} />
                  </Box>

                  <Box paddingInlineStart={100} paddingBlockStart={300}>
                    <BlockStack gap={200}>
                      <div>
                        <Tooltip dismissOnMouseOut padding='400' hasUnderline content={'Review first line'}>
                          <Text>First line</Text>
                        </Tooltip>
                        <ReactQuill value={cnf?.rvfl} className='preview-mail hideEditor feedfltxt rvcardtxt'
                          modules={modulesQuillForCard} formats={formatsQuill}
                          onFocus={() => editorFocus('feedfltxt')}
                          onBlur={() => editorBlur('feedfltxt')}
                          onChange={(newValue, delta, source) => {
                            if (source === 'user') { handleMainTextChange('rvfl', newValue) }
                          }} />
                      </div>

                      <div>
                        <Tooltip dismissOnMouseOut padding='400' hasUnderline content={'Second line'}>
                          <Text>Second line</Text>
                        </Tooltip>
                        <ReactQuill value={cnf?.rvtm} className='preview-mail hideEditor feedtime rvcardtxt'
                          modules={modulesQuillForCard} formats={formatsQuill}
                          onFocus={() => editorFocus('feedtime')}
                          onBlur={() => editorBlur('feedtime')}
                          onChange={(newValue, delta, source) => {
                            if (source === 'user') { handleMainTextChange('rvtm', newValue) }
                          }} />
                      </div>

                      <div>
                        <Tooltip dismissOnMouseOut padding='400' hasUnderline content={'Third line'}>
                          <Text>Third line</Text>
                        </Tooltip>
                        <ReactQuill value={cnf?.rvtxt} className='preview-mail hideEditor feedrtxt rvcardtxt'
                          modules={modulesQuillForCard} formats={formatsQuill}
                          onFocus={() => editorFocus('feedrtxt')}
                          onBlur={() => editorBlur('feedrtxt')}
                          onChange={(newValue, delta, source) => {
                            if (source === 'user') { handleMainTextChange('rvtxt', newValue) }
                          }} />
                      </div>

                      <div>
                        <Tooltip dismissOnMouseOut padding='400' hasUnderline content={'Load more button'}><Text>Load more</Text></Tooltip>
                        <ReactQuill value={cnf?.rvpldmr} className='preview-mail hideEditor loadmorebtn rvpaneltxt'
                          modules={modulesQuillForCard} formats={formatsQuill}
                          onFocus={() => editorFocus('loadmorebtn')}
                          onBlur={() => editorBlur('loadmorebtn')}
                          onChange={(newValue, delta, source) => {
                            if (source === 'user') { handleMainTextChange('rvpldmr', newValue) }
                          }} />
                      </div>

                      <div>
                        <Tooltip dismissOnMouseOut padding='400' width='wide' hasUnderline content={'Set words said in your language for this and please do not remove {x} as it is used for value.'}><Text>Time singular</Text></Tooltip>
                        <Box paddingBlockStart={100} paddingBlockEnd={300} paddingInlineStart={300}>
                          <InlineGrid columns={2}>
                            <TextField
                              value={cnf?.m}
                              placeholder='Min'
                              onChange={(e) => handleMainTextChange('m', e)}
                              error={errMessage.minute ? true : false} />
                            {designValidator.message('minute', cnf?.m, 'required')}
                            <TextField
                              value={cnf?.h}
                              placeholder='Hour'
                              onChange={(e) => handleMainTextChange('h', e)}
                              error={errMessage.hour ? true : false} />
                            {designValidator.message('hour', cnf?.h, 'required')}
                            <TextField
                              value={cnf?.d}
                              placeholder='Day'
                              onChange={(e) => handleMainTextChange('d', e)}
                              error={errMessage.days ? true : false} />
                            {designValidator.message('days', cnf?.d, 'required')}
                            <TextField
                              value={cnf?.mn}
                              placeholder='month'
                              onChange={(e) => handleMainTextChange('mn', e)}
                              error={errMessage.month ? true : false} />
                            {designValidator.message('month', cnf?.mn, 'required')}
                          </InlineGrid>
                        </Box>
                      </div>

                      <div>
                        <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Set words said in your language for this and please do not remove {x} as it is used for value.'}><Text>Time plural</Text></Tooltip>
                        <Box paddingBlockStart={100} paddingBlockEnd={300} paddingInlineStart={300}>
                          <InlineGrid columns={2}>
                            <TextField
                              value={cnf?.mplrl}
                              placeholder='Min'
                              onChange={(e) => handleMainTextChange('mplrl', e)}
                              error={errMessage.plurMinute ? true : false} />
                            {designValidator.message('plurMinute', cnf?.mplrl, 'required')}
                            <TextField
                              value={cnf?.hplrl}
                              placeholder='Hour'
                              onChange={(e) => handleMainTextChange('hplrl', e)}
                              error={errMessage.plurHour ? true : false} />
                            {designValidator.message('plurHour', cnf?.hplrl, 'required')}
                            <TextField
                              value={cnf?.dplrl}
                              placeholder='Day'
                              onChange={(e) => handleMainTextChange('dplrl', e)}
                              error={errMessage.plurDays ? true : false} />
                            {designValidator.message('plurDays', cnf?.dplrl, 'required')}
                            <TextField
                              value={cnf?.mnplrl}
                              placeholder='month'
                              onChange={(e) => handleMainTextChange('mnplrl', e)}
                              error={errMessage.plurMonth ? true : false} />
                            {designValidator.message('plurMonth', cnf?.mnplrl, 'required')}
                          </InlineGrid>
                        </Box>
                      </div>

                    </BlockStack>
                  </Box>
                </div>

              </BlockStack>
              :
              state.verticalActive === getValue('Position & animation') ?
                <div>
                  <Tooltip dismissOnMouseOut padding='400' width='wide' hasUnderline content={'Set notification position & its animation style in desktop & mobile device'}><Text fontWeight='medium'>Position & animation</Text></Tooltip>

                  <Box paddingBlockStart={300}>
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Turn on to show the notification in Mobile device'}><Text>Show in mobile</Text></Tooltip>}
                      checked={!notiById?.hm}
                      onChange={(e) => handleSettingChange('hm', e)}
                    />
                    <Box paddingInlineStart={700}>
                      {/* mobile animation style */}
                      {cnf?.mas !== undefined &&
                        <Box paddingBlockStart={100}>
                          <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Select animation style for notification entry & exit in mobile'}>
                            <Text tone='subdued'>Animation style in mobile</Text>
                          </Tooltip>
                          <Box paddingBlockStart={100}>
                            {!notiById?.hm !== true || notiById?.idisnp ?
                              <TextField value={phoneAni.find(({ value }) => value === cnf?.mas)?.label || 'Select'}
                                disabled />
                              :
                              <Popover
                                fullWidth
                                active={state.isAniMobi}
                                onClose={() => changeNameValue({ isAniMobi: !state.isAniMobi })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isAniMobi: !state.isAniMobi })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {phoneAni.find(({ value }) => value === cnf?.mas)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={phoneAni}
                                  selected={[cnf?.mas]}
                                  onChange={() => changeNameValue({ isAniMobi: !state.isAniMobi })}
                                  onFocusOption={(e) => handleMainTextChange('mas', e)} />
                              </Popover>
                            }
                          </Box>
                        </Box>
                      }
                      <Box paddingBlockStart={150}> {/* mobile position */}
                        <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Select display position of notification on website in mobile'}><Text tone='subdued'>Display position in mobile</Text></Tooltip>

                        <Box paddingBlockStart={100}>
                          {(!notiById?.hm !== true || notiById?.idisnp) ?
                            <TextField value={phonePosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'} disabled />
                            :
                            <Popover
                              fullWidth
                              active={state.isPosMobi}
                              onClose={() => changeNameValue({ isPosMobi: !state.isPosMobi })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isPosMobi: !state.isPosMobi })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {phonePosition.find(({ value }) => value === cnf?.mdp)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={phonePosition}
                                selected={[cnf?.mdp]}
                                onChange={() => changeNameValue({ isPosMobi: !state.isPosMobi })}
                                onFocusOption={(e) => handleMainTextChange('mdp', e)} />
                            </Popover>
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box paddingBlockStart={400}>
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Turn on to show the notification on Desktop'}><Text>Show in desktop</Text></Tooltip>}
                      checked={notiById?.isdsk}
                      onChange={(e) => handleSettingChange('isdsk', e)}
                    />
                    <Box paddingInlineStart={700}>
                      {cnf?.as !== undefined &&  //animation
                        <Box paddingBlockStart={100}>
                          <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Select animation style for notification entry & exit in desktop screen'}>
                            <Text tone='subdued'>Animation style in desktop</Text>
                          </Tooltip>
                          <Box paddingBlockStart={'100'}>
                            {notiById?.isdsk !== true || notiById?.idisnp ?
                              <TextField value={deskAni.find(({ value }) => value === cnf?.as)?.label || 'Select'}
                                disabled />
                              :
                              <Popover
                                fullWidth
                                active={state.isAniDesk}
                                onClose={() => changeNameValue({ isAniDesk: !state.isAniDesk })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isAniDesk: !state.isAniDesk })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {deskAni.find(({ value }) => value === cnf?.as)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={deskAni}
                                  selected={[cnf?.as]}
                                  onChange={() => changeNameValue({ isAniDesk: !state.isAniDesk })}
                                  onFocusOption={(e) => handleMainTextChange('as', e)} />
                              </Popover>
                            }
                          </Box>
                        </Box>
                      }
                      {cnf?.dp !== undefined &&  //position
                        <Box paddingBlockStart={150}>
                          <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Select notification display position in desktop screen'}>
                            <Text tone='subdued'>Display position in desktop</Text>
                          </Tooltip>
                          <Box paddingBlockStart={'100'}>
                            {notiById?.isdsk !== true ?
                              <TextField value={deskPosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'}
                                disabled={!notiById?.isdsk} />
                              :
                              <Popover
                                fullWidth
                                active={state.isPosDesk}
                                onClose={() => changeNameValue({ isPosDesk: !state.isPosDesk })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isPosDesk: !state.isPosDesk })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {deskPosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={deskPosition}
                                  selected={[cnf?.dp]}
                                  onChange={() => changeNameValue({ isPosDesk: !state.isPosDesk })}
                                  onFocusOption={(e) => handleMainTextChange('dp', e)} />
                              </Popover>
                            }
                          </Box>
                        </Box>
                      }
                      {cnf?.cbdal !== undefined &&  //button alignment
                        <Box paddingBlockStart={150}>
                          <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Select any type of display alignment'}>
                            <Text tone='subdued'>Call back button display alignment</Text>
                          </Tooltip>
                          <Box paddingBlockStart={'100'}>
                            {notiById?.isdsk !== true || notiById?.idisnp ?
                              <TextField value={btnPosition.find(({ value }) => value === cnf?.cbdal)?.label || 'Select'}
                                disabled />
                              :
                              <Popover
                                fullWidth
                                active={state.isDsplyAlign}
                                onClose={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {btnPosition.find(({ value }) => value === cnf?.cbdal)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={btnPosition}
                                  selected={[cnf?.cbdal]}
                                  onChange={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}
                                  onFocusOption={(e) => handleMainTextChange('cbdal', e)} />
                              </Popover>
                            }
                          </Box>
                        </Box>
                      }
                    </Box>
                  </Box>
                </div>
                :
                state.verticalActive === getValue('Style') ?
                  <BlockStack gap={400}>
                    {/* cta style */}
                    <div>
                      <Text fontWeight='medium'>Button style</Text>

                      <Box paddingInlineStart={400} paddingBlockStart={300}>
                        <InlineGrid gap={200} columns={2}>
                          {cnf?.bg !== undefined &&
                            <ColorPick
                              labelName='CTA button bg color'
                              colorKey="bg"
                              colorValue={cnf?.bg || '#fff'}
                              functionCall={handleMainTextChange} />
                          }
                          {cnf?.rvpbcl !== undefined &&
                            <ColorPick
                              labelName='Review panel bg color'
                              colorKey="rvpbcl"
                              colorValue={cnf?.rvpbcl || '#fff'}
                              functionCall={handleMainTextChange} />
                          }
                          {cnf?.rvpcrbcl !== undefined && defaultNotif?.ttn !== 'review_feed_2' &&
                            <ColorPick
                              labelName='Review card bg color'
                              colorKey="rvpcrbcl"
                              colorValue={cnf?.rvpcrbcl || '#fff'}
                              functionCall={handleMainTextChange} />
                          }
                          {cnf?.rvptbg !== undefined &&
                            <ColorPick
                              labelName='Tab panel bg color'
                              colorKey="rvptbg"
                              colorValue={cnf?.rvptbg || '#fff'}
                              functionCall={handleMainTextChange} />
                          }
                        </InlineGrid>
                      </Box>
                    </div>

                    {/* notification shape */}
                    {cnf?.r !== undefined &&
                      <div>
                        <Text fontWeight='medium'>Shape</Text>
                        <Box paddingInlineStart={400} paddingBlockStart={150}>
                          <RangeSlider
                            min={0}
                            max={50}
                            value={cnf?.r || 0}
                            onChange={(e) => handleMainTextChange('r', e)}
                            label={<Text>Radius</Text>}
                            output />
                        </Box>
                      </div>
                    }

                    {/* border size & style */}
                    {(cnf?.rc !== undefined || cnf?.rs !== undefined || cnf?.rw !== undefined || cnf?.r !== undefined) &&
                      <div>
                        <Text fontWeight='medium'>Border</Text>
                        <Box paddingInlineStart={400} paddingBlockStart={150}>
                          {cnf?.rw !== undefined &&
                            <InlineStack blockAlign='center' align='space-between'>
                              <Box width='80%'>
                                <RangeSlider
                                  max={6}
                                  value={cnf?.rw || 0}
                                  onChange={(e) => handleMainTextChange('rw', e)}
                                  label={<Text>Size</Text>}
                                  output />
                              </Box>
                              <ColorPick
                                width='medium'
                                colorKey="rc"
                                colorValue={cnf?.rc}
                                functionCall={handleMainTextChange}
                              />
                            </InlineStack>
                          }
                          {cnf?.rs !== undefined &&
                            <Box paddingBlockStart={200}>
                              <Text>Style</Text>
                              <Box paddingBlockStart={100}>
                                <Popover
                                  fullWidth
                                  active={state.isBrderStyle}
                                  onClose={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}
                                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                    onClick={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}>
                                    <Text variant='bodyMd' tone='subdued'>
                                      {borderStyle.find(({ value }) => value === cnf?.rs)?.label || 'Select'}
                                    </Text>
                                  </Button>}>
                                  <OptionList
                                    options={borderStyle}
                                    selected={[cnf?.rs] || []}
                                    onChange={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}
                                    onFocusOption={(e) => handleMainTextChange('rs', e)} />
                                </Popover>
                              </Box>
                            </Box>
                          }
                        </Box>
                      </div>
                    }

                    {/* shadow */}
                    <div>
                      <Text fontWeight='medium'>Shadow</Text>

                      <Box paddingInlineStart={400} paddingBlockStart={150}>
                        <BlockStack gap={200}>
                          <InlineStack blockAlign='center' align='space-between'>
                            <Box width='80%'>
                              <RangeSlider
                                step={2}
                                max={50}
                                value={cnf?.shdbr || 0}
                                onChange={(e) => handleMainTextChange('shdbr', e)}
                                label={<Text>Effect</Text>}
                                output />
                            </Box>
                            <ColorPick
                              width='medium'
                              colorKey="shdclr"
                              colorValue={cnf?.shdclr}
                              functionCall={handleMainTextChange}
                            />
                          </InlineStack>
                          <RangeSlider
                            min={-10}
                            max={10}
                            value={cnf?.shdho || 0}
                            onChange={(e) => handleMainTextChange('shdho', e)}
                            label={<Text>Horizontal</Text>}
                            output />
                          <RangeSlider
                            min={-10}
                            max={10}
                            value={cnf?.shdvo || 0}
                            onChange={(e) => handleMainTextChange('shdvo', e)}
                            label={<Text>Vertical</Text>}
                            output />
                        </BlockStack>
                      </Box>
                    </div>

                  </BlockStack>
                  :
                  state.verticalActive === getValue('Data control') ?
                    <div>
                      <Text fontWeight='medium'>Data history</Text>
                      <Box paddingBlockStart={300}>
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding='400' content='Enable it to skip widget for specific time interval' width='wide' hasUnderline><Text>Enable it to skip seen widgets</Text></Tooltip>}
                          checked={notiById?.isnutd}
                          onChange={(e) => handleSettingChange('isnutd', e)}
                        />
                        <Box paddingInlineStart={700} paddingBlockStart={'050'}>
                          <BlockStack gap={200}>
                            <Tooltip dismissOnMouseOut padding='400' content='Don’t show same notification for selected time duration' width='wide' hasUnderline><Text tone='subdued'>Set time duration</Text></Tooltip>
                            {notiById?.isnutd !== true ?
                              <TextField value={skipNotifTime.find(({ value }) => value == notiById?.snutd)?.label || 'Select'} disabled />
                              :
                              <Popover
                                fullWidth
                                active={state.isDurTime}
                                onClose={() => changeNameValue({ isDurTime: !state.isDurTime })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isDurTime: !state.isDurTime })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {skipNotifTime.find(({ value }) => value == notiById?.snutd)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={skipNotifTime}
                                  selected={[notiById?.snutd.toString()]}
                                  onChange={() => changeNameValue({ isDurTime: !state.isDurTime })}
                                  onFocusOption={(e) => handleSettingChange('snutd', e ? Number(e) : '')} />
                              </Popover>
                            }
                          </BlockStack>
                        </Box>
                      </Box>

                    </div>
                    :
                    state.verticalActive === getValue('Review filters') ?
                      <div>
                        <Text fontWeight='medium'>Filter reviews by criteria</Text>
                        <Box paddingInlineStart={300} paddingBlockStart={300}>
                          <BlockStack gap={300}>
                            <TextField
                              type='Number'
                              value={notiById?.mnrt}
                              placeholder='Star ratings'
                              label={<Tooltip dismissOnMouseOut padding='400' content='Enter 4 to show 4 & 5 star reviews' width='wide' hasUnderline><Text>Display review if minimum star rating is {notiById?.mnrt}</Text></Tooltip>}
                              onChange={(e) => handleSettingChange('mnrt', e ? Number(e) : '')}
                              error={errMessage.minimumRating ? true : false} />
                            {designValidator.message('minimumRating', notiById?.mnrt, 'required')}
                            <TextField
                              value={notiById?.rinkw}
                              placeholder='Review include keywords'
                              label={<Tooltip dismissOnMouseOut padding='400' content='Add multiple keyword using comma(,) separator' width='wide' hasUnderline><Text>Review include keywords</Text></Tooltip>}
                              onChange={(e) => handleSettingChange('rinkw', e)}
                            />
                            <TextField
                              value={notiById?.rexkw}
                              placeholder='Review exclude keywords'
                              label={<Tooltip dismissOnMouseOut padding='400' content='Add multiple keyword using comma(,) separator' width='wide' hasUnderline><Text>Review exclude keywords</Text></Tooltip>}
                              onChange={(e) => handleSettingChange('rexkw', e)}
                            />
                            <Checkbox
                              label={<Text>Hide review without comments</Text>}
                              checked={notiById?.irhwc}
                              onChange={(e) => handleSettingChange('irhwc', e)}
                            />

                            <BlockStack gap={100}>
                              <Text>Select sorting order</Text>
                              <Popover
                                fullWidth
                                active={state.isSortFeed}
                                onClose={() => changeNameValue({ isSortFeed: !state.isSortFeed })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isSortFeed: !state.isSortFeed })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {notifOrder.find(({ value }) => value === notiById?.rsrt)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={notifOrder}
                                  selected={[notiById?.rsrt] || ['']}
                                  onChange={() => changeNameValue({ isSortFeed: !state.isSortFeed })}
                                  onFocusOption={(e) => handleSettingChange('rsrt', e)} />
                              </Popover>
                            </BlockStack>

                            <BlockStack gap={100}>
                              <Text>How many record display in one page</Text>
                              <Popover
                                fullWidth
                                active={state.isDsplyRecord}
                                onClose={() => changeNameValue({ isDsplyRecord: !state.isDsplyRecord })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isDsplyRecord: !state.isDsplyRecord })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {displayRecord.find(({ value }) => value === notiById?.rpgcnt)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={displayRecord}
                                  selected={[notiById?.rpgcnt] || ['']}
                                  onChange={() => changeNameValue({ isDsplyRecord: !state.isDsplyRecord })}
                                  onFocusOption={(e) => handleSettingChange('rpgcnt', e)} />
                              </Popover>
                            </BlockStack>

                          </BlockStack>
                        </Box>

                      </div>
                      :
                      state.verticalActive === getValue('Timing control') &&
                      <div>
                        <Text fontWeight='medium'>Timing rules</Text>

                        {/* show on Display in loop false */}
                        {(cnf?.dtrg !== undefined && (notiById?.icstlp !== true || notiById?.idisnp === true)) &&
                          <Box paddingBlockStart={300} paddingInlineStart={300}>
                            <BlockStack gap={300}>

                              {isAbtest === false &&
                                <BlockStack gap={300}>
                                  <TextField
                                    type="datetime-local"
                                    label={<Tooltip dismissOnMouseOut padding='400' content='Widgets will start displaying from this date' width='wide' hasUnderline><Text>Select start date</Text></Tooltip>}
                                    value={notiById?.strtdt ? formateDate(notiById?.strtdt) : ''}
                                    onChange={(e) => handleSettingChange('strtdt',
                                      new Date(e.toLocaleString('en-IN', dateFormatOpt)))} />

                                  <TextField
                                    type="datetime-local"
                                    label={<Tooltip dismissOnMouseOut padding='400' content='Widgets will not be displayed after this date' width='wide' hasUnderline><Text>Select expiry date</Text></Tooltip>}
                                    value={notiById?.expdt ? formateDate(notiById?.expdt) : ''}
                                    onChange={(e) => handleSettingChange('expdt',
                                      new Date(e.toLocaleString('en-IN', dateFormatOpt)))} />

                                  <Box paddingBlockStart={100}>
                                    <Checkbox
                                      label={<Text>Schedule on specific days of week</Text>}
                                      checked={cnf?.irptdy}
                                      onChange={(e) => handleMainTextChange('irptdy', e)} />
                                    {cnf?.irptdy === true &&
                                      <Box paddingBlockStart={100}>
                                        <BlockStack gap={300}>
                                          <Popover
                                            fullWidth
                                            active={state.isTimeStmp}
                                            onClose={() => changeNameValue({ isTimeStmp: !state.isTimeStmp })}
                                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure
                                              onClick={() => changeNameValue({ isTimeStmp: !state.isTimeStmp })}>
                                              <Text variant='bodyMd' tone='subdued'>
                                                {cnf?.arrptdy?.map(value => weekOpts.find(option => option.value === value)?.label).filter(Boolean).join(', ') || 'Select'}
                                              </Text>
                                            </Button>}>
                                            <Box padding={300}>
                                              <ChoiceList
                                                allowMultiple
                                                choices={weekOpts}
                                                selected={cnf?.arrptdy || []}
                                                onChange={(e) => handleMainTextChange('arrptdy', e)} />
                                            </Box>
                                          </Popover>

                                          <InlineGrid columns={2} gap={300}>
                                            <Popover
                                              fullWidth
                                              active={state.isStrtDt}
                                              onClose={() => changeNameValue({ isStrtDt: !state.isStrtDt })}
                                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                                onClick={() => changeNameValue({ isStrtDt: !state.isStrtDt })}>
                                                <Text variant='bodyMd' tone='subdued'>
                                                  {cnf?.rptstm ? timeOptions.find(({ value }) => value === cnf?.rptstm)?.label
                                                    : 'Select'}
                                                </Text>
                                              </Button>}>
                                              <OptionList
                                                options={timeOptions}
                                                selected={[cnf?.rptstm] || []}
                                                onChange={() => changeNameValue({ isStrtDt: !state.isStrtDt })}
                                                onFocusOption={(e) => handleMainTextChange('rptstm', e)} />
                                            </Popover>

                                            <Popover
                                              fullWidth
                                              active={state.isEndDt}
                                              onClose={() => changeNameValue({ isEndDt: !state.isEndDt })}
                                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                                onClick={() => changeNameValue({ isEndDt: !state.isEndDt })}>
                                                <Text variant='bodyMd' tone='subdued'>
                                                  {cnf?.rptetm ? timeOptions.find(({ value }) => value === cnf?.rptetm)?.label : 'Select'}
                                                </Text>
                                              </Button>}>
                                              <OptionList
                                                options={timeOptions}
                                                selected={[cnf?.rptetm] || []}
                                                onChange={() => changeNameValue({ isEndDt: !state.isEndDt })}
                                                onFocusOption={(e) => handleMainTextChange('rptetm', e)} />
                                            </Popover>
                                          </InlineGrid>
                                        </BlockStack>
                                      </Box>
                                    }
                                  </Box>
                                </BlockStack>
                              }

                              {/* display rule */}
                              <Box paddingBlockStart={100}>
                                <Box paddingBlockEnd={100}><Text>Display rule</Text></Box>
                                <Popover
                                  fullWidth
                                  active={state.isDsplyRule}
                                  onClose={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}
                                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                    onClick={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}>
                                    <Text variant='bodyMd' tone='subdued'>
                                      {dsplyRuleOpts.find(({ value }) => value == cnf?.dtrg)?.label || 'Select'}
                                    </Text>
                                  </Button>}>
                                  <OptionList
                                    options={dsplyRuleOpts}
                                    selected={[cnf?.dtrg]}
                                    onChange={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}
                                    onFocusOption={(e) => handleMainTextChange('dtrg', e)} />
                                </Popover>
                              </Box>

                              {cnf?.dtrg === 2 &&
                                <div>
                                  <TextField
                                    type='Number'
                                    placeholder='sec'
                                    value={cnf?.aft}
                                    onChange={(e) => handleMainTextChange('aft', e ? Number(e) : '')}
                                    error={errMessage?.minimumCount ? errMessage.minimumCount : false} />
                                  {designValidator.message('minimumCount', cnf?.aft, 'required|Positive numbers: numeric|min:1,num')}
                                </div>
                              }
                              {cnf?.dtrg === 3 &&
                                <div>
                                  <TextField
                                    type='Number'
                                    placeholder='%'
                                    value={cnf?.sprc}
                                    onChange={(e) => handleMainTextChange('sprc', e ? Number(e) : '')}
                                    error={errMessage?.afterScroll ? errMessage.afterScroll : false} />
                                  {designValidator.message('afterScroll', cnf?.sprc, 'required')}
                                </div>
                              }

                              {/* copy widget function */}
                              <Box paddingBlockStart={200}>
                                <Checkbox
                                  label={<Text>Show widget using javascript</Text>}
                                  checked={cnf?.ishntfclk}
                                  onChange={(e) => handleMainTextChange('ishntfclk', e)}
                                />
                                {cnf?.ishntfclk === true &&
                                  <Box paddingInlineStart={700} paddingBlockStart={'050'}>
                                    <BlockStack gap={100}>
                                      <InlineStack align='space-between'>
                                        <Text tone='subdued'>Call this function on custom event</Text>
                                        <Button variant='plain'
                                          onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_js_fn')}>Copy</Button>
                                      </InlineStack>
                                      <div onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_js_fn')}>
                                        <TextField
                                          id='wdgt_js_fn'
                                          value={`fnShowWidgetOnAnyEvent("{${notifId}}")`} />
                                      </div>
                                    </BlockStack>
                                  </Box>
                                }
                              </Box>

                              <div> {/* hide rule */}
                                <Checkbox
                                  checked={cnf?.ihdwn}
                                  label={<Text>Hide after {cnf?.hdwn ? cnf?.hdwn : 'x'} second</Text>}
                                  onChange={(e) => handleMainTextChange('ihdwn', e)} />
                                {cnf?.ihdwn === true &&
                                  <Box paddingInlineStart={700}>
                                    <TextField
                                      type='Number'
                                      placeholder='Hide second'
                                      value={cnf?.hdwn}
                                      onChange={(e) => handleMainTextChange('hdwn', e ? Number(e) : '')}
                                      error={errMessage.HideSecond || errMessage.MinimumCount || false} />
                                    {designValidator.message('HideSecond', cnf?.hdwn, 'required')}
                                    {designValidator.message('MinimumCount', cnf?.hdwn, 'Positive numbers: numeric|min:1,num')}
                                  </Box>}
                              </div>

                            </BlockStack>
                          </Box>
                        }
                      </div>
      }
    </Box>
  );
}

export default ReviewFeedCard;