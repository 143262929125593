import { BlockStack, Box, InlineStack, Link, Text } from '@shopify/polaris';
import React from 'react';
import { question_quote, testimonial_client1, testimonial_client2, testimonial_client3, g2_icon, shopify_icon, wiser_icon, Allappreview } from '../../img'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function TestimonialCard(props) {

  let testimonial = [
    {
      review: <Text as='p' id='Great_app'>
        “Great app for sharing social proof on our website via pop-up notifications. It offers <Text as='span' id='impress_features'> impressive features</Text> that <Text as='span'>boost conversions.</Text>”
      </Text>,
      clientImg: testimonial_client1,
      clientName: 'Kathy A.',
      CompanyPost: 'Director of Marketing',
      url: 'https://www.g2.com/products/wisernotify/reviews/wisernotify-review-5313297',
      logo: g2_icon
    },
    {
      review: <Text as='p' id='Great_app'><Text as='span'>“Great social proof app,</Text>very easy to use and customize with many advanced setting.<Text as='span'> We switched to this app from another one and never looked back.</Text>”</Text>,
      clientImg: testimonial_client2,
      clientName: 'Raj Armani',
      CompanyPost: 'CEO, imbesharam',
      url: 'https://apps.shopify.com/wiser-notify/reviews',
      logo: shopify_icon
    },
    {
      review: <Text as='p' id='Great_app'>“Our newsletter opt-in page alone experienced a <Text as='span'>32% conversion lift </Text>after we started showing social proof and recent subscribers!”</Text>,
      clientImg: testimonial_client3,
      clientName: 'Nicholas Scalice',
      CompanyPost: 'Founder, GrowthMarketer',
      url: 'https://wisernotify.com/real-social-proof-examples/?_gl=1*1cp8rku*_gcl_au*MzMzODI5NzcuMTcyNDI5ODIwNg..',
      logo: wiser_icon
    },
  ]

  return (
    <Box id='Testimonial_Frame'>
      <Box id='inner_Box'>
        <img src={question_quote} width={30} alt=''/>
        <div style={{ position: 'relative' }}>
          <Carousel showArrows={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop={true}
            showThumbs={false}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            swipeable={true}
            interval={6000}
          >
            {
              testimonial && testimonial.map((data, i) => {
                return (
                  <Box key={i} id='client-slider-item'>
                    <Text as='p' id='Great_app'>
                      {data.review}
                    </Text>
                    <Box>
                      <InlineStack gap={'400'} blockAlign='center'>
                        <img src={data.clientImg} alt=''/>
                        <BlockStack gap={100}>
                          <Link target="_blank" url={data.url}>
                            <InlineStack gap={'100'}>
                              <Text as='h6' id='client-profile-name'>{data.clientName}</Text>
                              <img src={data.logo} alt='g2' style={{ width: "18px" }} />
                            </InlineStack>
                          </Link>
                          <Text as='p' id='client_Company_Post'>{data.CompanyPost}</Text>
                        </BlockStack>
                      </InlineStack>
                    </Box>
                  </Box>
                )
              })
            }
          </Carousel>
        </div>
        <div style={{ paddingTop: '25.96px' }}>
          <Box>
            <div style={{ paddingBlockEnd: "10px" }}>
              <Text as='p' id='review_rating'>200+ reviews with <svg width="100" height="17" viewBox="0 0 100 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M91.3795 13.0421L86.0517 16.2279L87.4567 10.2236L82.7625 6.1837L88.9607 5.65501L91.3795 0L93.8018 5.65416L100 6.18285L95.293 10.2228L96.7066 16.2279L91.3795 13.0421ZM70.6898 13.0421L65.3619 16.2279L66.7677 10.2236L62.0702 6.1837L68.2684 5.65501L70.6889 0L73.1112 5.65416L79.3094 6.18285L74.6033 10.2228L76.0151 16.2279L70.6898 13.0421ZM50 13.0421L44.6695 16.2279L46.0745 10.2236L41.377 6.1837L47.5735 5.65416L49.9991 0L52.4222 5.65416L58.6205 6.18285L53.9135 10.2228L55.327 16.2279L50 13.0421ZM29.3111 13.0421L23.9823 16.2279L25.3873 10.2236L20.6898 6.1837L26.888 5.65501L29.3102 0L31.7325 5.65416L37.9307 6.18285L33.2246 10.2236L34.6373 16.2279L29.3111 13.0421ZM8.62131 13.0421L3.29256 16.2279L4.69756 10.2236L0 6.1837L6.19822 5.65416L8.62045 0L11.0427 5.65416L17.2409 6.18285L12.5348 10.2228L13.9483 16.2279L8.62131 13.0421Z" fill="#E26827" />
              </svg> rating</Text>
            </div>
            <img src={Allappreview} alt='Allreview' width={'271px'} height={'25px'} />
          </Box>
        </div>
      </Box>
    </Box >
  );
}
export default TestimonialCard;