import React from "react";
import { Text, Box, Button, Card, InlineStack, IndexTable, TextField, Icon, Divider, Badge, Modal, Select, Checkbox, Tooltip, PageActions, List, InlineGrid, VideoThumbnail } from '@shopify/polaris';
import { EditIcon, DeleteIcon, SearchIcon, EnterIcon, ClipboardIcon } from "@shopify/polaris-icons";
import moment from 'moment';

function NewUser({ props }) {
    const { state, changeNameValue } = props;

    //plan data for select dropdown list
    let plan = [];
    if (state.Whitelable) {
        plan = [
            { value: 'select', label: 'Select' },
        ];
        for (let i = 0; i < state.Whitelable.length; i++) {
            const r = state.Whitelable[i];
            plan.push({ value: r._id.toString(), label: r.pn });
        }
    }

    return (
        <div>
            <Box paddingBlockStart={100} paddingBlockEnd={300} paddingInline={400}>
                <div className='plan'>
                    <Box padding={400}>
                        <InlineStack align='space-between'>
                            <Box paddingBlockStart={100}>
                                <Text variant="bodyLg" fontWeight='semibold'>Manage account</Text>
                            </Box>
                            <Box>
                                <InlineStack gap={200}>
                                    <div className='video_plan' >
                                        <VideoThumbnail
                                            videoLength={1640}
                                            onClick={() => window.open('https://youtu.be/lNdi_FtoPFE?t=1148', '_blank')}
                                        />
                                    </div>
                                    <div className="searchBar">
                                        <TextField
                                            value={state.queryValue}
                                            onChange={(e) => changeNameValue({ queryValue: e })}
                                            autoComplete="off"
                                            placeholder="Search"
                                            suffix={<Icon
                                                source={SearchIcon}
                                                tone="base"
                                            />}
                                        />
                                    </div>
                                    {
                                        localStorage.getItem('utyp') === 'appsumo' ?
                                            props.checkPermission('manage_sub_user', 'isedit', props.profile) &&
                                            props.profile?.apstk.length > 0 &&
                                            <Box><Button size="medium" variant="primary" tone='success' onClick={() => { props.handleNewUser() }}>Add sub user</Button></Box>
                                            :
                                            props.checkPermission('manage_sub_user', 'isedit', props.profile) &&
                                            <Box><Button size="medium" variant="primary" tone='success' onClick={() => { props.handleNewUser() }}>Add sub user</Button></Box>
                                    }
                                </InlineStack>
                            </Box>
                        </InlineStack>
                        <Box paddingBlockStart={300}>
                            <Card padding={0}>
                                <IndexTable
                                    itemCount={state.SubUser ? state.SubUser.length : 0}
                                    headings={[
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>No.</Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Account details</Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Pixel code</Text>
                                        },
                                        {
                                            title: <>
                                                <Text variant="headingXs" fontWeight='medium'>Visitor quota per month</Text>
                                                <Divider />
                                                <div className="labels">
                                                    <Text variant="headingXs" fontWeight='medium'>Assigned <span className="lines"> |</span> </Text>
                                                    <Text variant="headingXs" fontWeight='medium'>Used <span className="lines"> |</span></Text>
                                                    <Text variant="headingXs" fontWeight='medium'>Left</Text>
                                                </div>
                                            </>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Show<br /> wisernotify<br />native app </Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Action</Text>
                                        }
                                    ]}
                                    selectable={false}
                                >
                                    {state.SubUser && state.SubUser.length > 0 && state.SubUser.map((i, index) => {
                                        // Calculate remaining quota
                                        let _remaining = 0;

                                        if (i.uuq) {
                                            let _uuq = Math.abs(i.uuq);
                                            if (_uuq > i.plan.pobj.uaq) {
                                                _remaining = 0;
                                            } else {
                                                _remaining = parseInt(i.plan.pobj.uaq) - parseInt(_uuq);
                                            }
                                        } else if (i.plan.pobj.uaq) {
                                            _remaining = parseInt(i.plan.pobj.uaq);
                                        } else {
                                            _remaining = 0;
                                        }
                                        // Check if left quota is 0, then display it directly
                                        if (i.ulq !== undefined && i.ulq === 0) {
                                            _remaining = 0;
                                        }

                                        //plan status
                                        let planStatus = true;
                                        if (i.plan.pendt && new Date() > new Date(i.plan.pendt)) {
                                            planStatus = false;
                                        }

                                        let ucdt = moment(new Date(i.cdt)).format('MMM DD YYYY');//user created date
                                        let supad = moment(new Date(i.plan.cdt)).format('MMM DD YYYY');//plan assign date

                                        let exDate = 'Lifetime';
                                        if (i.plan.pendt) {
                                            exDate = moment(new Date(i.plan.pendt)).format('MMM DD YYYY');//expiry date
                                        }

                                        //create subUser pixel
                                        let script = '';
                                        let _brandName = 'WiserNotify';
                                        let _brandURL = 'pt.wisernotify.com';

                                        if (state.TotalData && state.TotalData?.bn) {
                                            _brandName = state.TotalData?.bn;
                                        }

                                        if (state.TotalData && state.TotalData?.wurl) {
                                            _brandURL = state.TotalData?.wurl;
                                        }

                                        script = '<script>!function () { if (window.t4hto4) console.log("' + _brandName + ' pixel installed multiple time in this page"); else { window.t4hto4 = !0; var t = document, e = window, n = function () { var e = t.createElement("script"); e.type = "text/javascript", e.async = !0, e.src = "https://' + _brandURL + '/pixel.js?ti=' + i.ti + '", document.body.appendChild(e) }; "complete" === t.readyState ? n() : window.attachEvent ? e.attachEvent("onload", n) : e.addEventListener("load", n, !1) } }();</script>';

                                        return (
                                            <IndexTable.Row key={index}>
                                                <IndexTable.Cell>
                                                    <Text variant='bodySm' >{index + 1}</Text>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Box paddingBlockStart={200}>
                                                        <InlineStack wrap={false}>
                                                            <Box paddingInlineEnd={200}>
                                                                <Text variant="bodyMd"  >
                                                                    {/* {i.fn} */}
                                                                    {`${i.fn.charAt(0).toUpperCase()}${i.fn.slice(1)}`}
                                                                </Text>
                                                            </Box>
                                                            <List type="bullet">
                                                                <List.Item>
                                                                    <Text variant="bodySm" tone="subdued">
                                                                        {ucdt}
                                                                    </Text>
                                                                </List.Item>
                                                            </List>
                                                        </InlineStack>
                                                    </Box>
                                                    <Box paddingBlockStart={0}>
                                                        <span className="gmail-id">
                                                            <Text variant='bodySm' >
                                                                {/* {i.e} */}
                                                                {`${i.e.charAt(0).toUpperCase()}${i.e.slice(1)}`}
                                                            </Text>
                                                        </span>
                                                    </Box>
                                                    <div id="account_pn">
                                                        <Box paddingBlockStart={100}>
                                                            <InlineStack>
                                                                <Text variant='bodySm' tone="subdued" >{`${i.plan?.pobj?.pn.charAt(0).toUpperCase()}${i.plan?.pobj?.pn.slice(1)}`}</Text>
                                                                {/* <Text variant='bodySm' >{i.plan?.pobj?.pn} </Text> */}
                                                                <Box paddingInlineStart={200} paddingBlockEnd={0}><Badge tone={planStatus ? "success" : "critical"}>
                                                                    {planStatus ? "Active" : "Expired"}
                                                                </Badge>
                                                                </Box>
                                                            </InlineStack>
                                                        </Box>
                                                    </div>
                                                    <Box paddingBlockEnd={200}>
                                                        <Text variant='bodySm' tone="subdued">{supad} &nbsp; to &nbsp; {exDate}</Text>
                                                    </Box>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <div className="copyPixel" onClick={() => props.copyPixel(script)}>
                                                        <Icon
                                                            source={ClipboardIcon}
                                                            tone="base"
                                                        />
                                                    </div>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    {/* <div className="labels"> */}
                                                    <Box paddingBlock={100}><Text variant='bodySm' >Assigned  {i.plan.pobj.uaq ? i.plan.pobj.uaq.toLocaleString() : 0} </Text></Box>
                                                    <Box paddingBlock={100}><Text variant='bodySm' >Used  {i.plan.pobj.uaq ? i.uuq > i.plan.pobj.uaq ? i.plan.pobj.uaq.toLocaleString() : i.uuq.toLocaleString() : '-'} </Text></Box>
                                                    <Box paddingBlock={100}><Text variant='bodySm' >Left  {_remaining.toLocaleString()}</Text></Box>
                                                    {/* </div> */}
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Text variant='bodySm' >{i.isbuprm !== false ? 'Yes' : 'No'}</Text>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    {props.checkPermission('manage_sub_user', 'isedit', props.profile) &&
                                                        <InlineStack wrap={false} gap={100}>
                                                            <Button variant="monochromePlain" icon={EnterIcon} onClick={() => (props.loginDataModal(i._id, i.e))} />
                                                            <Button variant="monochromePlain" icon={EditIcon} onClick={() => props.UserEditData(i)} />
                                                            <Button variant="monochromePlain" icon={DeleteIcon} onClick={() => props.UserDeleteData(i._id, i.e)} />
                                                        </InlineStack>
                                                    }
                                                </IndexTable.Cell>
                                            </IndexTable.Row>
                                        )
                                    })}
                                </IndexTable>
                            </Card>
                        </Box>
                    </Box>
                </div>
            </Box>

            {/* Create new user */}
            <Modal
                open={state.createDataModal}
                title="Create new user"
                onClose={(e) => { props.openCloseModal('createDataModal', state.createDataModal, e); }}>
                <Modal.Section>
                    <TextField
                        label="Email address"
                        value={state.e}
                        onChange={(e) => changeNameValue({ e: e })}
                        autoComplete="off"
                        error={state.errMessage.e ? "The email address field is required." : false}
                    />
                    {props.validator.message('e', state.e, 'required|email')}
                    <Box paddingBlockStart={200} paddingBlockEnd={200}>
                        <TextField
                            label="Full name"
                            value={state.fn}
                            onChange={(e) => changeNameValue({ fn: e })}
                            autoComplete="off"
                            error={state.errMessage.fn ? "The full name field is required." : false}
                        />
                        {props.validator.message('fn', state.fn, 'required')}
                    </Box>
                    <TextField
                        label="Password"
                        type="password"
                        value={state.pd}
                        onChange={(e) => changeNameValue({ pd: e })}
                        autoComplete="off"
                        error={state.errMessage.pd ? "The password field is required." : false}
                    />
                    {props.validator.message('pd', state.pd, 'required')}

                    <Box paddingBlockStart={200} paddingBlockEnd={200}>
                        <Select
                            label="Plan"
                            options={plan}
                            value={state.blid}
                            onChange={(e) => changeNameValue({ blid: e })}
                            error={state.errMessage.blid ? "The plan field is required." : false}
                        />
                    </Box>
                    {props.validator.message('blid', state.blid, 'required')}
                    <Tooltip content="Click here to know about this permission." hasUnderline width="1" dismissOnMouseOut>
                        <Checkbox
                            label="Show wisernotify native app & integration"
                            checked={state.isbuprm}
                            onChange={(e) => changeNameValue({ isbuprm: e })}
                        />
                    </Tooltip>
                    <Box paddingBlockStart={300}>
                        <Tooltip content="Do you want to send signup email?" hasUnderline width="1" dismissOnMouseOut>
                            <Checkbox
                                label="Do you want to send signup email?"
                                checked={state.isEmail}
                                onChange={(e) => changeNameValue({ isEmail: e })}
                            />
                        </Tooltip>
                    </Box>
                    {props.checkPermission('manage_sub_user', 'isedit', props.profile) &&
                        <InlineStack align='start'>
                            <Box paddingBlockEnd={100} paddingBlockStart={300}>
                                <Button id='Add' size="large" onClick={() => {
                                    props.openCloseModal('createDataModal', state.createDataModal, 'close');
                                    props.createUser();
                                }}>Add</Button>
                            </Box>
                        </InlineStack>
                    }
                </Modal.Section>
            </Modal>


            {/* Login as Sub-user Modal*/}
            <Modal
                open={state.loginDataModal}
                title={<Text variant='headingLg' fontWeight='bold'>Confirmation</Text>}
                onClose={(e) => { props.openCloseModal('loginDataModal', state.loginDataModal, e); }}>
                <Modal.Section>
                    <Box paddingBlockEnd={400}>
                        <Text variant="bodyMd" as="p"> By clicking on login, you will be logged as a sub-user.</Text>
                    </Box>
                    <Divider />
                    <PageActions
                        primaryAction={{
                            content: 'Login', tone: 'seccess', variant: 'secondary',
                            onAction: () => {
                                props.handleLoginCall();
                            }
                        }}
                        secondaryActions={[
                            {
                                content: 'Close',
                                onAction: () => { props.openCloseModal('loginDataModal', state.loginDataModal, 'close'); }
                            }
                        ]}
                    />
                </Modal.Section>
            </Modal>


            {/* delete data modal */}
            <Modal
                open={state.deleteUserModal}
                title={<Text variant='headingLg' fontWeight='bold'>Confirmation</Text>}
                onClose={(e) => { props.openCloseModal('deleteUserModal', state.deleteUserModal, e); }}>
                <Modal.Section>
                    <Box paddingBlockEnd={400}>
                        <Text variant="bodyMd" as="p">By clicking on Delete, We will delete this ({state.gid}) account permanently from our server.</Text>
                    </Box>
                    <Divider />
                    <PageActions
                        primaryAction={{
                            icon: DeleteIcon, content: 'Delete', tone: 'critical',
                            onAction: () => {
                                props.UserDeleteCall(state.dataId);
                            }
                        }}
                        secondaryActions={[
                            {
                                content: 'Close',
                                onAction: () => { props.openCloseModal('deleteUserModal', state.deleteUserModal, 'close'); }
                            }
                        ]}
                    />
                </Modal.Section>
            </Modal>


            {/* edit user modal */}
            <Modal
                open={state.editUserModal}
                title="Update sub user details"
                onClose={(e) => { props.openCloseModal('editUserModal', state.editUserModal, e); }}>
                <Modal.Section>
                    <TextField
                        label="Email address"
                        value={state.e}
                        onChange={(e) => changeNameValue({ e: e })}
                        autoComplete="off"
                        error={state.errMessage.e ? "The email address field is required." : false}
                    />
                    {props.validator.message('e', state.e, 'required|email')}
                    <Box paddingBlockStart={200} paddingBlockEnd={200}>
                        <TextField
                            label="Full name"
                            value={state.fn}
                            onChange={(e) => changeNameValue({ fn: e })}
                            autoComplete="off"
                            error={state.errMessage.fn ? "The full name field is required." : false}
                        />
                        {props.validator.message('fn', state.fn, 'required')}
                    </Box>
                    <Box paddingBlockStart={0} paddingBlockEnd={200}>
                        <Select
                            label="Plan"
                            options={plan}
                            value={state.blid}
                            onChange={(e) => changeNameValue({ blid: e })}
                            error={state.errMessage.blid ? "The plan field is required." : false}
                        />
                    </Box>
                    {props.validator.message('blid', state.blid, 'required')}

                    <Tooltip content="Click here to know about this permission." hasUnderline width="1" dismissOnMouseOut>
                        <Checkbox
                            label="Show wisernotify native app & integration"
                            checked={state.isbuprm}
                            onChange={(e) => changeNameValue({ isbuprm: e })}
                        />
                    </Tooltip>
                    {props.checkPermission('manage_sub_user', 'isedit', props.profile) &&
                        <InlineStack align='center'>
                            <Box paddingBlockEnd={100} paddingBlockStart={300}>
                                <Button id='Add' onClick={() => {
                                    props.openCloseModal('editUserModal', state.editUserModal, 'close');
                                    props.editUser();
                                }}>Update</Button>
                            </Box>
                        </InlineStack>
                    }

                    <Box paddingBlockStart={300} paddingBlockEnd={100}>
                        <Text variant='headingMd'>Update password</Text>
                    </Box>
                    <InlineGrid columns={2} gap={200}>
                        <TextField
                            label="New password"
                            type="password"
                            value={state.npd}
                            onChange={(e) => changeNameValue({ npd: e })}
                            autoComplete="off"
                            error={state.errMessage.npd ? "The New Password field is required." : false}
                        />
                        {props.validator.message('npd', state.npd, 'required')}

                        <TextField
                            label="Confirm password"
                            type="password"
                            value={state.cpd}
                            onChange={(e) => changeNameValue({ cpd: e })}
                            autoComplete="off"
                            error={state.errMessage.cpd ? "The Confirm Password field is required." : false}
                        />
                        {props.validator.message('cpd', state.cpd, 'required')}
                    </InlineGrid>
                    {props.checkPermission('manage_sub_user', 'isedit', props.profile) &&
                        <InlineStack align='center'>
                            <Box paddingBlockEnd={100} paddingBlockStart={300}>
                                <Button id='Add' onClick={() => {
                                    props.openCloseModal('editUserModal', state.editUserModal, 'close');
                                    props.userPassword();
                                }}>Update</Button>
                            </Box>
                        </InlineStack>
                    }
                </Modal.Section>
            </Modal>
        </div>
    );
}

export default NewUser;