import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/manageTeam';
import { toastify } from '../ducks/toast';
import { getProfile } from '../ducks/auth';

function* getUserTeam() {
  try {
    yield put(load());
    let res;
    //pass flag user is subUser or not
    if (localStorage.getItem('isbusr') === 'true') {//white_label
      res = yield call(api.GET, '/getUserTeam?isSub=' + true);
    } else {
      res = yield call(api.GET, '/getUserTeam?isSub=' + false);
    }

    if (res.status === 'success') {
      yield put(actions.getUserTeamSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addTeammate(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addTeammate', action.payload);
    if (res.status === 'success') {
      // yield put(getUserTeam());
      yield put(actions.getUserTeam());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'update') {
      yield put(actions.getUserTeam());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//get active role list
function* getRoleList() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getRoleList?ia=' + true);
    if (res.status === 'success') {
      yield put(actions.getRoleListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//deleteTeamMember
function* deleteTeamMember(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteTeamMember', action.payload);
    if (res.status === 'success') {
      yield put(actions.getUserTeam());
      yield put(getProfile());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//get user notification list
function* getUserNotif(action) {
  try {
    yield put(load());
    let path = '/getUserNotif';
    //check if login user is team member pass a flag
    if (localStorage.getItem('istm') === 'true') {
      path = '/getUserNotif?istm=' + true;
    }
    const res = yield call(api.GET, path);
    if (res.status === 'success') {
      yield put(actions.getUserNotifSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updTeamMember(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updTeamMember', action.payload);
    if (res.status === 'success') {
      yield put(actions.getUserTeam());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}
export function* watchGetUserTeam() {
  yield takeLatest(actions.getUserTeam.type, getUserTeam);
}

export function* watchAddTeammate() {
  yield takeLatest(actions.addTeammate.type, addTeammate);
}

export function* watchGetRoleList() {
  yield takeLatest(actions.getRoleList.type, getRoleList);
}

export function* watchDeleteTeamMember() {
  yield takeLatest(actions.deleteTeamMember.type, deleteTeamMember);
}

export function* watchGetUserNotif() {
  yield takeLatest(actions.getUserNotif.type, getUserNotif);
}

export function* watchUpdTeamMember() {
  yield takeLatest(actions.updTeamMember.type, updTeamMember);
}

export default function* rootSaga() {
  yield fork(watchGetUserTeam);
  yield fork(watchAddTeammate);
  yield fork(watchGetRoleList);
  yield fork(watchDeleteTeamMember);
  yield fork(watchGetUserNotif);
  yield fork(watchUpdTeamMember);
}
