import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/whitelabelLogin';
import { toastify } from '../ducks/toast';

function* getBrand(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getBrand?wurl=' + action.payload.wurl);

    if (res.status === 'success') {
      yield put(actions.getBrandSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* brandLogin(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/brandLogin', action.payload);

    if (res.status === 'success') {
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isbusr', res.data.isbusr);//white_label
      localStorage.setItem('utyp', res.data.utyp);
      localStorage.setItem('is_hide_blackfriday', true);
      localStorage.setItem('pvd', res.data.pvd);
      if (res.data.csub) {
        localStorage.setItem('csub', res.data.csub);
      }

      //this condition for google ontap signup 
      if (
        localStorage.getItem('reg_typ') &&
        localStorage.getItem('reg_typ') !== '' &&
        localStorage.getItem('reg_typ') !== 'undefined') {
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_local');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_local' });
        } catch (ex) {
          console.log(ex);
        }
        localStorage.removeItem('reg_typ');
      }
      // if (res.data.pvd == 'shopify') {
      //   localStorage.setItem('pvd', res.data.pvd);
      // }
      //mixpanel start
      // if (window.mixpanel) {
      //   window.mixpanel.identify(res.data.e);
      //   window.mixpanel.people.set({
      //     '$email': res.data.e,
      //     '$last_login': new Date()
      //   });
      // }
      //mixpanel end
      // window.location.href = res.data.rp ? res.data.rp : '/dashboard';
      let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
      }, 2000);
    }
    else if (res.status === 'not_found') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_active') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
    localStorage.removeItem('n');
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchBrandLogin() {
  yield takeLatest(actions.brandLogin.type, brandLogin);
}

export function* watchGetBrand() {
  yield takeLatest(actions.getBrand.type, getBrand);
}

export default function* rootSaga() {
  yield fork(watchGetBrand);
  yield fork(watchBrandLogin);
}
