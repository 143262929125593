import React from 'react';
import { Text, BlockStack, InlineGrid, Divider, Spinner, Icon, Thumbnail, DropZone, InlineStack, Button, Box, TextField } from '@shopify/polaris';
import { DeleteIcon, SearchIcon, NoteIcon } from '@shopify/polaris-icons';

const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];

const IconCard = ({ props }) => {
  let { state, changeNameValue, handleDropZone, deleteMediaIcon, handleIconChange, searchIconscout,
    uploadIconfile } = props;

  let myIcon, Information, Favicon, Social, Animated, svgIcon;
  if (state?.iconList) {
    Information = state?.iconList.filter(x => x.ic === 'information');
    Favicon = state?.iconList.filter(x => x.ic === 'favicon');
    Social = state?.iconList.filter(x => x.ic === 'social');
    myIcon = state?.iconList.filter(x => x.ic === 'my');
    Animated = state?.iconList.filter(x => x.ic === 'animated');
    svgIcon = state?.iconList.filter(x => x.ic === 'svgIcon');
  }

  let iconTabs = [
    { value: 'all', label: 'All' },
    { value: 'myIcon', label: 'My icon' },
    { value: 'iconScout', label: 'Iconscout' },
    { value: 'extra', label: 'Extra' },
    { value: 'socialMedia', label: 'Social media' },
    { value: 'favicon', label: 'Favicon' },
    { value: 'animated', label: 'Animated' },
    { value: 'svg', label: 'SVG' },
  ];

  return (
    <Box padding={100}>

      {/* upload icons */}
      <Box paddingBlockEnd={600}>
        <Text fontWeight='medium'>Upload your custom icon</Text>

        <InlineStack blockAlign='center' align='space-between'>
          <Box width='90%'>
            <Box id='img_drops' paddingBlockStart={100}>
              <DropZone accept="image/*" type='image' onDrop={handleDropZone}>
                <InlineStack align='center' blockAlign='center'>

                  {state?.uploadedUrl?.length > 0 ? state.uploadedUrl.map((img, i) => (
                    <Box padding={200} key={i}>
                      <Thumbnail size="small" alt={'Logo'}
                        source={img && validImageTypes.includes(img.type) ? window.URL.createObjectURL(img) : NoteIcon} />
                    </Box>
                  )) : <Box padding={300}>
                    <Button><Text tone='base' fontWeight='semibold'>Browse</Text></Button>
                  </Box>
                  }

                </InlineStack>
              </DropZone>
            </Box>
          </Box>
          <Button variant='primary' onClick={() => uploadIconfile()}>Upload</Button>
        </InlineStack>
      </Box >

      {/* all tabs */}
      <div>
        <Text fontWeight='medium'>Choose from available icons</Text>
        <Box paddingBlockStart={600}>
          <InlineStack gap={200}>

            {iconTabs?.length > 0 && iconTabs.map((d, i) => (
              <div key={i} id='cursorPoint' onClick={() => changeNameValue({ icnTabActiveTyp: d.value })}>
                <Box minWidth='80px'>
                  <Text alignment='center' fontWeight={state.icnTabActiveTyp === d.value ? 'bold' : 'medium'} tone={state.icnTabActiveTyp === d.value ? '' : 'subdued'}>{d.label}</Text>
                  <Box paddingBlockStart={200} id='tab_underLne'>
                    {state.icnTabActiveTyp === d.value &&
                      <Divider borderWidth='100' borderColor='input-border-active' />}
                  </Box>
                </Box>
              </div>
            ))}
          </InlineStack>
          <Divider borderWidth='050' borderColor='border-brand' />
        </Box>

        <Box paddingBlockStart={200}>
          {state?.icnTabActiveTyp === 'all' ?
            <div id='iconsHght'>
              <Box paddingBlockStart={300}>
                {myIcon?.length > 0 &&
                  <BlockStack gap={100}>
                    <Text fontWeight='medium' tone='subdued'>My icon</Text>
                    <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                      {myIcon.map((val, key) => {
                        return (
                          <Box id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400" key={key}>
                            <img onClick={() => handleIconChange(val.iu)} className='iconImg' src={val.iu} alt="Icons" />
                            <Button id='iconDelete' variant='monochromePlain' tone='critical' onClick={() => deleteMediaIcon(val._id, val.iu)}>
                              <Icon tone='critical' source={DeleteIcon}></Icon></Button>
                          </Box>
                        );
                      })}
                    </InlineGrid>
                  </BlockStack>
                }
              </Box>
              <Box paddingBlockStart={400}>
                {Information?.length > 0 &&
                  <BlockStack gap={100}>
                    <Text fontWeight='medium' tone='subdued'>Information</Text>
                    <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                      {Information.map((val, key) => {
                        return (
                          <Box id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400" key={key}>
                            <img onClick={() => handleIconChange(val.iu)} className='iconImg' src={val.iu} alt="Icons" />
                          </Box>
                        );
                      })}
                    </InlineGrid>
                  </BlockStack>
                }
              </Box>
            </div>
            :
            state?.icnTabActiveTyp === 'myIcon' ?
              <Box id='iconsHght' paddingBlockStart={300}>
                {myIcon?.length > 0 &&
                  <BlockStack gap={100}>
                    <Text fontWeight='medium' tone='subdued'>My icon</Text>
                    <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                      {myIcon.map((val, key) => {
                        return (
                          <Box id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400" key={key}>
                            <img onClick={() => handleIconChange(val.iu)} className='iconImg' src={val.iu} alt="Icons" />
                            <Button id='iconDelete' variant='monochromePlain' tone='critical' onClick={() => deleteMediaIcon(val._id, val.iu)} >
                              <Icon tone='critical' source={DeleteIcon}></Icon></Button>
                          </Box>
                        );
                      })}
                    </InlineGrid>
                  </BlockStack>
                }
              </Box>
              :
              state.icnTabActiveTyp === 'iconScout' ?
                <Box paddingBlockStart={200}>

                  <div id='iconsHght'>
                    <TextField
                      id='iconScount'
                      prefix={<Icon source={SearchIcon} />}
                      autoComplete='off'
                      value={state?.iconscout}
                      placeholder='Type icon name & click on search'
                      onChange={(e) => changeNameValue({ 'iconscout': e })}
                      clearButton
                      onClearButtonClick={() => { changeNameValue({ 'iconscout': '' }); searchIconscout('clear') }}
                      connectedRight={<Button variant='primary' onClick={() => searchIconscout()}>SEARCH</Button>}
                    />

                    {state?.isLoadIconscout === false ?
                      <Box paddingBlockStart={500}>
                        {state?.arrIconscout?.length > 0 ?
                          <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                            {state.arrIconscout.map((val, key) => {
                              return (
                                <Box id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400" key={key}>
                                  <img onClick={() => handleIconChange(val)} className='iconImg' src={val} alt="Icons" />
                                </Box>
                              );
                            })}
                          </InlineGrid>
                          :
                          <Text>No record found</Text>
                        }
                      </Box>
                      :
                      <Box paddingBlockStart={1200}>
                        <InlineStack align='center' blockAlign='center'>
                          <Spinner size="large" />
                        </InlineStack>
                      </Box>
                    }
                  </div>

                </Box>
                :
                state.icnTabActiveTyp === 'extra' ?
                  <Box id='iconsHght' paddingBlockStart={400}>
                    <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                      {Information?.length > 0 && Information.map((val, key) => {
                        return (
                          <Box key={key} id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                            <img onClick={() => handleIconChange(val.iu)} className='iconImg' src={val.iu} alt="Icons" />
                          </Box>
                        )
                      })}
                    </InlineGrid>
                  </Box>
                  :
                  state.icnTabActiveTyp === 'socialMedia' ?
                    <Box id='iconsHght' paddingBlockStart={400}>
                      <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                        {Social?.length > 0 && Social.map((val, key) => {
                          return (
                            <Box key={key} id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                              <img onClick={() => handleIconChange(val.iu)} className='iconImg' src={val.iu} alt="Icons" />
                            </Box>
                          )
                        })}
                      </InlineGrid>
                    </Box>
                    :
                    state.icnTabActiveTyp === 'favicon' ?
                      <Box id='iconsHght' paddingBlockStart={400}>
                        <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                          {Favicon?.length > 0 && Favicon.map((val, key) => {
                            return (
                              <Box key={key} id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                                <img onClick={() => handleIconChange(val.iu)} className='iconImg' width={22} src={val.iu} alt="Icons" />
                              </Box>
                            )
                          })}
                        </InlineGrid>
                      </Box>
                      :
                      state.icnTabActiveTyp === 'animated' ?
                        <Box id='iconsHght' paddingBlockStart={400}>
                          <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                            {Animated?.length > 0 && Animated.map((val, key) => {
                              return (
                                <Box key={key} id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                                  <img onClick={() => handleIconChange(val.iu)} className='iconImg' src={val.iu} alt="Icons" />
                                </Box>
                              )
                            })}
                          </InlineGrid>
                        </Box>
                        :
                        state.icnTabActiveTyp === 'svg' &&
                        <Box id='iconsHght' paddingBlockStart={400}>
                          <InlineGrid columns={{ xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }} gap={400}>
                            {svgIcon.length > 0 && svgIcon.map((val, key) => {
                              return (
                                <Box key={key} id='iconWrap' background="bg-surface" minHeight='72px' borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                                  <img onClick={() => handleIconChange(val.iu)} className='iconImg' width={50} src={val.iu} alt="Icons" />
                                </Box>
                              )
                            })}
                          </InlineGrid>
                        </Box>
          }
        </Box>
      </div >
    </Box >
  );
};

export default IconCard;