import React, { useCallback, useEffect, useState } from 'react';
import { IntegrationCard } from "../../components";
import * as integrationDucks from '../../ducks/integration';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './Integration.css';

const Integration = (nttTyp) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  let queryInt = queryParams.get("int");
  let openInt = queryParams.get("opnint");
  let typ = '';

  const [state, setState] = useState({
    selctdTab: 'allApps',
    selected: 1,
    sa: '',
    nttyp: '',
    isInstalled: false,
    arrInt: [],
    arrCt: [],
    arrInstall: [],
    in: '',
    ti: '',
    uk: '',
    hpulpxl: '',
    hpul: '',
    isReloadIntData: true
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  useEffect(() => {
    // 'shopify' 'google' 'googlemybusiness' 'googleplay'
    if (openInt && state.arrInt && state.arrInt.length > 0) {
      const integrations = state.arrInt.find((val) => val.in === openInt);
      if (integrations) {
        openModal(integrations)
      }
    }
  }, [openInt, state.arrInt]);

  const openModal = (obj) => {
    // //add class for popup
    // document.getElementsByTagName('body')[0].classList.add('modal-body-open');

    if (obj.in === 'sendinblue')
      dispatch(integrationDucks.getSendInBlue());
    else if (obj.in === 'convertkit')
      dispatch(integrationDucks.getConvertkit());
    else if (obj.in === 'google')
      dispatch(integrationDucks.getGoogle());
    else if (obj.in === 'lemlist')
      dispatch(integrationDucks.getLemlist());
    else if (obj.in === 'aweber')
      dispatch(integrationDucks.getAweber());
    else if (obj.in === 'mailchimp')
      dispatch(integrationDucks.getMailchimp());
    else if (obj.in === 'facebook')
      dispatch(integrationDucks.getFBAC());
    else if (obj.in === 'bigcommerce')
      dispatch(integrationDucks.getBigCommerce());
    else if (obj.in === 'googlemybusiness')
      dispatch(integrationDucks.getGMB());
    else if (obj.in === 'zapier')
      dispatch(integrationDucks.getZap());
    else if (obj.in === 'neto')
      dispatch(integrationDucks.getNeto());
    else if (obj.in === 'calendly')
      dispatch(integrationDucks.getCalendly());
    else if (obj.in === 'wordpress')
      dispatch(integrationDucks.getPl('wordpress'));
    else if (obj.in === 'magento')
      dispatch(integrationDucks.getPl('magento'));
    else if (obj.in === 'pipedrive')
      dispatch(integrationDucks.getPipedrive());
    else if (obj.in === 'shopify')
      dispatch(integrationDucks.getShopify());
    else if (obj.in === 'shopline')
      dispatch(integrationDucks.getShopline());
    else if (obj.in === 'mailerlite')
      dispatch(integrationDucks.getMailerLite());
    else if (obj.in === 'thrivecart')
      dispatch(integrationDucks.getThrivecart());
    else if (obj.in === 'webflow')
      dispatch(integrationDucks.getWebflow());
    else if (obj.in === 'cliengo')
      dispatch(integrationDucks.getCliengo());
    else if (obj.in === 'autopilot')
      dispatch(integrationDucks.getAutopilot());
    else if (obj.in === 'eventbrite') {
      //get user ti and id
      if (profileData) {
        let userObj = {
          uk: profileData._id,
          ti: profileData.ti
        }
        setState((prevState) => ({ ...prevState, ...userObj }))
      }
      dispatch(integrationDucks.getEventbrite());
    }
    else if (obj.in === 'braintree')
      dispatch(integrationDucks.getBraintree());
    else if (obj.in === 'scheduleonce')
      dispatch(integrationDucks.getScheduleonce());
    else if (obj.in === 'stripe')
      dispatch(integrationDucks.getStripe());
    else if (obj.in === 'wordpressreview')
      dispatch(integrationDucks.getWordpressReview());
    else if (obj.in === 'acuityscheduling')
      dispatch(integrationDucks.getAcuityScheduling());
    else if (obj.in === 'sendowl')
      dispatch(integrationDucks.getSendOwl());
    else if (obj.in === 'campaignmonitor')
      dispatch(integrationDucks.getCampaignMonitor());
    else if (obj.in === 'bonusly')
      dispatch(integrationDucks.getBonusly());
    else if (obj.in === 'hubspot')
      dispatch(integrationDucks.getHubSpot());
    else if (obj.in === 'twitter')
      dispatch(integrationDucks.getTwitter());
    else if (obj.in === 'youtube_channel')
      dispatch(integrationDucks.getYtChannel());
    else if (obj.in === 'youtube_video')
      dispatch(integrationDucks.getYtVideo());
    else if (obj.in === 'capterra')
      dispatch(integrationDucks.getCapterra());
    else if (obj.in === 'integromat')
      dispatch(integrationDucks.getIntegromat());
    // else if (obj.in == 'twitter_follower')
    //    dispatch(integrationDucks.getTwitFoll());
    else if (obj.in === 'instagram_follower')
      dispatch(integrationDucks.getInstaFoll());
    else if (obj.in === 'linkedin')
      dispatch(integrationDucks.getLinkedin());
    else if (obj.in === 'thinkific')
      dispatch(integrationDucks.getThinkific());
    else if (obj.in === 'facebooklead')
      dispatch(integrationDucks.getFBLAC());
    else if (obj.in === 'custom')
      dispatch(integrationDucks.getCustomWebhook());
    else if (obj.in === 'customwebform')
      dispatch(integrationDucks.getCustomWebform());
    else if (obj.in === 'intercom')
      dispatch(integrationDucks.getIntercom());
    else if (obj.in === 'sendfox')
      dispatch(integrationDucks.getSendfox());
    else if (obj.in === 'automizy')
      dispatch(integrationDucks.getAutomizy());
    else if (obj.in === 'yelp')
      dispatch(integrationDucks.getYelp());
    else if (obj.in === 'livewebinar')
      dispatch(integrationDucks.getLivewebinar());
    else if (obj.in === 'amember')
      dispatch(integrationDucks.getAmember());
    else if (obj.in === 'newzenler')
      dispatch(integrationDucks.getNewzenler());
    else if (obj.in === 'yotpo')
      dispatch(integrationDucks.getYotpo());
    else if (obj.in === 'repuso')
      dispatch(integrationDucks.getRepuso());
    else if (obj.in === 'heysummit')
      dispatch(integrationDucks.getHeysummit());
    else if (obj.in === 'easydigitaldownloads')
      dispatch(integrationDucks.getEdd());
    else if (obj.in === 'elitefunnels')
      dispatch(integrationDucks.getElitefunnels());
    else if (obj.in === 'fastfunnels')
      dispatch(integrationDucks.getFastfunnels());
    else if (obj.in === 'getdrip')
      dispatch(integrationDucks.getGetdrip());
    else if (obj.in === 'spotify')
      dispatch(integrationDucks.getSpotify());
    else if (obj.in === 'opencart')
      dispatch(integrationDucks.getOpenCart());
    else if (obj.in === 'surveymonkey')
      dispatch(integrationDucks.getSurveymonkey());
    else if (obj.in === 'twitchtv')
      dispatch(integrationDucks.getTwitchTV());
    else if (obj.in === 'importreviews')
      dispatch(integrationDucks.getImportreview());
    else if (obj.in === 'prestashop')
      dispatch(integrationDucks.getPrestashop());
    else if (obj.in === 'wishpond')
      dispatch(integrationDucks.getWishpond());
    else if (obj.in === 'zoomai')
      dispatch(integrationDucks.getZoomai());
    else if (obj.in === 'booking')
      dispatch(integrationDucks.getBooking());
    else if (obj.in === 'trustpilot')
      dispatch(integrationDucks.getTrustpilot());
    else if (obj.in === 'tripadvisor')
      dispatch(integrationDucks.getTRPA());
    // else if (obj.in === 'ontraport')
    //   dispatch(integrationDucks.getOntraport());
    else if (obj.in === 'stamped')
      dispatch(integrationDucks.getStamped());
    else if (obj.in === 'clixli')
      dispatch(integrationDucks.getClixli());
    else if (obj.in === 'airbnb')
      dispatch(integrationDucks.getAirbnb());
    else if (obj.in === 'wiserreview')
      dispatch(integrationDucks.getWiserReview());
    else if (obj.in === 'wix')
      dispatch(integrationDucks.getWix());
    else if (obj.in === 'reviewsio')
      dispatch(integrationDucks.getReviewio());
    else if (obj.in === 'simvoly') {
      dispatch(integrationDucks.getSimvoly())
    }
    else if (obj.in === 'modwebs')
      dispatch(integrationDucks.getModwebs());
    else if (obj.in === 'appsumo')
      dispatch(integrationDucks.getAppSumo());
    else if (obj.in === 'practo')
      dispatch(integrationDucks.getPracto());
    else if (obj.in === 'googleplay')
      dispatch(integrationDucks.getGooglePlay());
    else if (obj.in === 'ecwid')
      dispatch(integrationDucks.getEcwid());
    else if (obj.in === 'facebookgroupfollowers')
      dispatch(integrationDucks.getFBGrp());
    else if (obj.in === 'encharge')
      dispatch(integrationDucks.getEncharge());
    else if (obj.in === '3dcart')
      dispatch(integrationDucks.get3dCart());
    else if (obj.in === 'amazon')
      dispatch(integrationDucks.getAmazon());
    else if (obj.in === 'dealerrater')
      dispatch(integrationDucks.getDealerRater());
    else if (obj.in === 'appleapp')
      dispatch(integrationDucks.getAppleApp());
    else if (obj.in === 'bbb')
      dispatch(integrationDucks.getBBB());
    else if (obj.in === 'ebay')
      dispatch(integrationDucks.geteBay());
    else if (obj.in === 'edmunds')
      dispatch(integrationDucks.getEdmunds());
    else if (obj.in === 'zoom')
      dispatch(integrationDucks.getZoom());
    else if (obj.in === 'etsy')
      dispatch(integrationDucks.getEtsy());
    else if (obj.in === 'hotels')
      dispatch(integrationDucks.getHotels());
    else if (obj.in === 'houzz')
      dispatch(integrationDucks.getHouzz());
    else if (obj.in === 'opentable')
      dispatch(integrationDucks.getOpenTable());
    else if (obj.in === 'mydukaan')
      dispatch(integrationDucks.getMyDukkan());
    else if (obj.in === 'instagrampost')
      dispatch(integrationDucks.getInstaPostInt());
    else if (obj.in === 'tiktok')
      dispatch(integrationDucks.getTikTok());
    else if (obj.in === 'commercetools')
      dispatch(integrationDucks.getCommercetools());
    else if (obj.in === 'elopage')
      dispatch(integrationDucks.getElopage());
    else if (obj.in === 'g2')
      dispatch(integrationDucks.getG2());
    else if (obj.in === 'squarespace')
      dispatch(integrationDucks.getSquarespace());
    else if (obj.in === 'shopperapproved')
      dispatch(integrationDucks.getShopperApproved());
    else if (obj.in === 'nopcommerce')
      dispatch(integrationDucks.getnopCommerce());
    else if (obj.t === 0) {
      console.log('call===================>');
      dispatch(integrationDucks.getWebhook(obj.in));
    }

    // if (this.props.location && this.props.location.query && this.props.location.query.int) {
    //   browserHistory.push('/integration');
    // }

    // if (['twitter', 'google'].indexOf(obj.in) !== -1) {
    //   let f = new RegExp(obj.in.split('_')[0], 'i');
    //   let intDt = this.props.integration.filter((x) => { if (f.test(x.in) == true) { x.desc = Parser(x.desc.toString()); return x; } });
    //   obj['arTab'] = intDt;
    // }
    // else {
    //   if (obj.desc) obj.desc = Parser(obj.desc.toString());
    // }
    // this.initState();

    if (obj.hpul) {
      let helpURL = obj.hpul + '?utm_source=dashboard_integration_card_popup';
      setState(prevState => ({ ...prevState, hpul: helpURL }));
    }
    if (obj.hpulpxl) {
      let pixelHelpURL = obj.hpulpxl + '?utm_source=dashboard_integration_card_popup';
      setState(prevState => ({ ...prevState, hpulpxl: pixelHelpURL }));
    }


    setState((prevState) => ({ ...prevState, ...obj }))
  }

  //get notiflist 
  useEffect(() => {
    dispatch(integrationDucks.getNotifForInt())
  }, [])
  const notiflist = useSelector(state => state.integration.notifList);
  useEffect(() => {
    changeNameValue({ notiflist });
  }, [notiflist])

  //get profile data 
  const profileData = useSelector(state => state.auth.profile);
  const isLoading = useSelector(state => state.loading.isLoading);

  //tabs.
  const statusTabs = [
    { label: `Installed (${state.arrInstall && state.arrInstall.length})`, value: 'installed' },
    { label: 'All', value: 'all' }
  ]

  //set tabs data.
  const tabs = statusTabs.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
  }));

  // coming from notifEdit page > dataSource > AddIntegration 
  //getIntMstList api call by queryInt.
  useEffect(() => {
    setTimeout(() => {
      if (queryInt) {
        dispatch(integrationDucks.getIntMstList({ sa: queryInt, ty: '' }));
      }
      else if (nttTyp.typ) {

        function getNotificationType() {
          switch (nttTyp.typ) {
            case 'signup_recent':
            case 'signup_bulk': return 'signup';
            case 'social_media_followers': return 'followers';
            case 'purchase_recent':
            case 'purchase_bulk':
            case 'purchase_recent_ecomm_snippet':
            case 'purchase_bulk_ecomm_snippet': return 'purchase';
            case 'review_bulk': return 'review_bulk';
            case 'review_recent': return 'review_recent';
            case 'channel_subscriber': return 'channel_subscriber';
            case 'video_subscriber': return 'video_subscriber';
            case 'review_feed': return 'review_feed';
            case 'social_media_post': return 'social_media_post';
            case 'social_media_count': return 'social_media_count';
            default:
              return '';
          }
        }
        typ = getNotificationType();

        changeNameValue({ nttyp: typ, queryNtt: nttTyp.typ });
        dispatch(integrationDucks.getIntMstList({ sa: 'all', ty: typ ? typ : '' }));
      }
      else dispatch(integrationDucks.getIntMstList({ sa: 'all', ty: '' }));
    }, 700);


  }, [nttTyp.typ]);

  //api data.
  const integrationArr = useSelector(state => state.integration.integrationMst);

  //set integration data.
  useEffect(() => {
    if (integrationArr !== undefined && integrationArr !== state.integrationArr) {
      changeNameValue({ integrationArr });
    }
  }, [integrationArr]);

  // escapeRegExp.
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  //set arrInt data.
  useEffect(() => {
    let arrInt = [];
    if (integrationArr?.length > 0) {
      for (let i = 0; i < integrationArr.length; i++) {
        let ele = { ...integrationArr[i] };
        if (['pixel'].indexOf(ele.in) === -1) { //'twitter_follower', 'googlemybusiness'
          if (state.sa) {
            let dn = ele.dn.toLowerCase(),
              escapedSa = escapeRegExp(state.sa.toLowerCase()),
              reg = new RegExp(escapedSa.toLowerCase());
            if (reg.test(dn)) {
              if (ele.userIntegration && ele.userIntegration.length > 0) {
                for (let j = 0; j < ele.userIntegration.length; j++) {
                  let el = ele.userIntegration[j];
                  if (el.inm === ele.in) {
                    ele['data'] = el;
                    if (ele.data.intCount >= 1) {
                      changeNameValue({ isInstalled: true });
                    }
                    arrInt.push(ele);
                  }
                }
              }
              if (!ele.data) {
                arrInt.push(ele);
              }
            }
          }
          else if (!state.sa) {
            if (ele.userIntegration && ele.userIntegration.length > 0) {
              for (let j = 0; j < ele.userIntegration.length; j++) {
                let el = ele.userIntegration[j];
                if (el.inm === ele.in) {
                  ele['data'] = el;
                  if (ele.data.intCount >= 1) {
                    changeNameValue({ isInstalled: true });
                  }
                  arrInt.push(ele);
                }
              }
            }
            if (!ele.data) {
              arrInt.push(ele);
            }
          }
        }
      }
    }
    changeNameValue({ arrInt });
  }, [integrationArr, state.sa]);

  //set arrCt data.
  useEffect(() => {
    if (state.arrInt) {
      const filArr = state.arrInt.filter((val) => val.ct === state.selctdTab)
      changeNameValue({ arrCt: filArr });
    }
  }, [state.selctdTab, state.arrInt]);

  //set arrInstall data.
  useEffect(() => {
    if (state.isInstalled && state.arrInt?.length > 0) {
      const filteredArr = state.arrInt.filter((value) =>
        value.data?.intCount >= 1 && value.in !== 'twitter_follower'
      );

      const finalArr = state.selctdTab !== 'allApps'
        ? filteredArr.filter((value) => value.ct === state.selctdTab)
        : filteredArr;

      changeNameValue({ arrInstall: finalArr });
    }
  }, [state.isInstalled, state.arrInt, state.selctdTab]);

  //callInteSearchApi.
  const callInteSearchApi = useCallback((empty) => {
    dispatch(integrationDucks.getIntMstList({ sa: empty ? '' : state.sa, ty: state.nttyp || '' }));
  }, [state.sa, state.nttyp]);

  //search By Intg.
  const searchByIntg = (e) => {
    if (e === '') {
      callInteSearchApi('empty');
    }
    changeNameValue({ sa: e });
  }

  //callInteSearchApi by sa.
  useEffect(() => {
    if (state.sa) {
      let queryTimer = setTimeout(() => callInteSearchApi(), 1000);
      return () => clearTimeout(queryTimer);
    }
  }, [state.sa]);

  //openChat.
  const openChat = () => {
    if (window.Froged) {
      window.Froged('open');
    }
  };

  const handleBackToMain = () => {
    navigate('/integration');
    changeNameValue({ in: '' });
    dispatch(integrationDucks.intClear());
    dispatch(integrationDucks.getIntMstList({ sa: 'all', ty: '' }));
  }

  return (
    <IntegrationCard
      state={state}
      isLoading={isLoading}
      changeNameValue={changeNameValue}
      openModal={openModal}
      tabs={tabs}
      searchByIntg={searchByIntg}
      callInteSearchApi={callInteSearchApi}
      openChat={openChat}
      handleBackToMain={handleBackToMain}
    />
  );
};

export default Integration;