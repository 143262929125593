import React from 'react';
import { BlockStack, Box, Button, InlineGrid, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, Text, TextField } from '@shopify/polaris';
import { wiser_logo } from '../../img';
import TestimonialCard from '../Login/TestimonialCard';

function ResetPwd(props) {
  const { state, validator, changeNameValue } = props;

  return (
    <div className='page-login-wrap'>
      <Page>
        <Box paddingBlockEnd={600}>
          <img src={wiser_logo} alt='wiser' />
        </Box>
        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
          <div className='login-part-left'>
            <Box>
              <TestimonialCard />
            </Box>
          </div>
          {props.isLoading === true ?
            <div className='lg_bg'>
              <div className='lg_block'>
                <div className='lg_content'>
                  <BlockStack gap={200}>
                    <SkeletonDisplayText size="" maxWidth='60%' />
                    <Box paddingBlockStart={200} paddingBlockEnd={800} >
                      <Box width='90%'>
                        <SkeletonBodyText lines={1} />
                      </Box>
                      <Box paddingBlockStart={100} width='15%'>
                        <SkeletonBodyText lines={1} />
                      </Box>
                    </Box>
                    <SkeletonDisplayText size="medium" maxWidth='100%' />
                    <SkeletonDisplayText size="medium" maxWidth='100%' />
                    <Box paddingBlockStart={200}>
                      <SkeletonDisplayText size="extraLarge" maxWidth='100%' />
                    </Box>
                  </BlockStack>
                  <div className='lg_content_ft'>
                    <Text variant='headingXs' as='p' fontWeight='regular'>By proceeding, you agree to the Terms and Conditions and  Privacy Policy</Text>
                    <Box paddingBlockStart={200}>
                      <div id='lg_btn_links'>
                        <InlineStack gap={200} wrap={false}>
                          <Button variant='plain'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Help</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/privacypolicy/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Privacy</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/termsconditions/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Terms</Text>
                          </Button>
                        </InlineStack>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className='lg_bg'>
              <div className='lg_block'>
                <div className='lg_content'>
                  <BlockStack gap={200}>
                    <Text variant='headingLg' as='h3' fontWeight='bold'>Reset account password</Text>
                    <Box paddingBlockEnd={200} maxWidth='90%'>
                      <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Set your new password for your account before the link expires.</Text>
                    </Box>
                    <div>
                      <TextField
                        label="Set new password"
                        type='password'
                        value={state.pd}
                        onChange={(e) => changeNameValue({ pd: e })}
                        autoComplete="off"
                        error={state.errMessage.password ? state.errMessage.password : false}
                      />
                      {validator.message('password', state.pd, 'required')}
                    </div>
                    <div>
                      <TextField
                        label="Confirm new password"
                        type='password'
                        value={state.npd}
                        onChange={(e) => changeNameValue({ npd: e })}
                        autoComplete="off"
                        error={state.errMessage.password ? state.errMessage.password : false}
                      />
                      {validator.message('password', state.npd, 'required')}
                    </div>
                    <Box paddingBlockStart={200}>
                      <div className='su_btn_bg' onClick={() => props.handleResetPD()}>
                        <Text variant='headingSm' as='p' fontWeight='regular'>Reset password</Text>
                      </div>
                    </Box>
                  </BlockStack>
                  <div className='lg_content_ft'>
                    <Text variant='headingXs' as='p' fontWeight='regular'>By proceeding, you agree to the Terms and Conditions and  Privacy Policy</Text>
                    <Box paddingBlockStart={200}>
                      <div id='lg_btn_links'>
                        <InlineStack gap={200} wrap={false}>
                          <Button variant='plain'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Help</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/privacypolicy/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Privacy</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/termsconditions/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Terms</Text>
                          </Button>
                        </InlineStack>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          }
        </InlineGrid>
      </Page>
    </div>
  );
}

export default ResetPwd; 