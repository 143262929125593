import React from 'react';
import { BlockStack, Box, Button, Card, Text, TextField } from '@shopify/polaris';
import { Link } from 'react-router-dom';

function WhitelabelForgotPwdCard(props) {
  const { state, validator, changeNameValue } = props;

  return (
    <div className='whtlbl_fgtPd'>
      <Box paddingBlockEnd={500}>
        <BlockStack inlineAlign='center'>
          {state.brand.lg &&
            <img src={state.brand.lg} style={{ width: state.brand.lgwd, height: state.brand.lghgt }} id="brand-image" alt='logo' />
          }
          <h1 className="whlabel-brandname" id="brand-name-line" style={{ fontSize: state.brand.bnfnsz, color: state.brand.bnfnclr, display: state.brand.ibn ? 'block' : 'none' }}> {state.brand.bn}</h1>
        </BlockStack>
      </Box>

        <Card>
          <Box>
            <Box paddingBlockEnd={300}>
              <Text variant='headingLg' as='h3' fontWeight='bold'>Forgot password</Text>
            </Box>
            <BlockStack gap={200}>
              <div>
                <TextField
                  label="Email"
                  value={state.e}
                  onChange={(e) => changeNameValue({ e: e })}
                  autoComplete="off"
                  error={state.errMessage.email ? state.errMessage.email : false}
                />
                {validator.message('email', state.e, 'required')}
              </div>
              <BlockStack inlineAlign='end'>
                <Link to={'/login'}>
                  <Button variant='plain'>Login?</Button>
                </Link>
              </BlockStack>
              <Box paddingBlockStart={100} background='bg-fill-brand-active' borderRadius={200}>
                <div className='whtlbl_fgtPd_btn' onClick={() => props.handleForgetWhitePWD()}>
                  <Text variant='headingSm' as='p' fontWeight='regular' alignment='center'>Reset password</Text>
                </div>
              </Box>
            </BlockStack>
          </Box>
        </Card>
    </div>
  );
}

export default WhitelabelForgotPwdCard;
