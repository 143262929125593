import React from 'react';
import { Text, Box, Button, Card, InlineStack, IndexTable, Checkbox, Modal, InlineGrid, TextField, Select, Tooltip, Divider, PageActions, VideoThumbnail } from '@shopify/polaris';
import { EditIcon, DeleteIcon } from "@shopify/polaris-icons";

function NewPlan({ props }) {
    const { changeNameValue, state } = props;

    const intervalUnit = [
        { value: 'month', label: 'Month' },
        { value: 'year', label: 'Year' },
    ];

    // Remaining quota
    let leftVisitorQuota = props.profileData?.uaq;
    let leftTeamQuota = props.profileData?.taq;
    leftVisitorQuota = leftVisitorQuota - props.profileData?.uuq;
    if (props.profileData?.suaq) {
        leftVisitorQuota = leftVisitorQuota - props.profileData?.suaq;
    }
    leftTeamQuota = leftTeamQuota - props.profileData?.tuq;
    if (props.profileData && props.profileData?.apstk && props.profileData?.apstk.length >= 4) {
        leftTeamQuota = 'UNLIMITED';
    }
    else if (props.profileData?.sutq) {
        leftTeamQuota = leftTeamQuota - props.profileData?.sutq;
    }
    if (props.profileData?.utyp) {
        let _index = intervalUnit.findIndex(x => x.value === 'lifetime');
        if (_index === -1) {
            intervalUnit.push({ value: 'lifetime', label: 'Lifetime' },);
        }
    }

    //Agency plan list (Remaining visitor quota Ex:- 588,763)
    let _quota;
    if (props.profileData?.uaq) {
        _quota = props.profileData?.uaq;
        if (props.profileData?.uuq) _quota = _quota - props.profileData?.uuq;
        if (props.profileData?.suaq) _quota = _quota - props.profileData?.suaq;
        _quota = `(Remaining visitor quota ${_quota.toLocaleString()})`;
    }

    return (
        <div>
            <Box paddingBlockStart={0} paddingBlockEnd={300} paddingInline={400}>
                <div className='plan'>
                    <Box padding={400}>
                        <div className='plan_heading'>
                            <InlineStack align='space-between'>
                                <Box paddingBlockStart={100}> <Text variant="bodyLg" fontWeight='semibold'>Agency plan list {_quota && (_quota)}</Text></Box>
                                <Box>
                                    <InlineStack wrap={false} gap={200}>
                                        <Box>
                                            <div className='video_plan' >
                                                <VideoThumbnail
                                                    videoLength={1640}
                                                    onClick={() => window.open('https://youtu.be/lNdi_FtoPFE?t=678', '_blank')}
                                                />
                                            </div>
                                        </Box>
                                        <Box>
                                            {
                                                localStorage.getItem('utyp') === 'appsumo' ?
                                                    props.profile?.apstk.length > 0 && props.checkPermission('white_label_plan', 'isedit', props.profile) &&
                                                    <Button variant="primary" tone='success' onClick={() => { props.handleNewPlan() }}>Add new plan</Button>
                                                    : props.checkPermission('white_label_plan', 'isedit', props.profile) &&
                                                    <Button variant="primary" tone='success' onClick={() => { props.handleNewPlan() }}>Add new plan</Button>
                                            }
                                        </Box>
                                    </InlineStack>
                                </Box>
                            </InlineStack>
                        </div>
                        <Box paddingBlockStart={300}>
                            <Card padding={0}>
                                <IndexTable
                                    itemCount={state.Whitelable ? state.Whitelable.length : 0}
                                    headings={[
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Plan details </Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Plan description </Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>($)Price</Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Visitor<br /> quota</Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Teammate<br /> quota</Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Assign</Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Status</Text>
                                        },
                                        {
                                            title: <Text variant='headingXs' fontWeight='medium'>Action</Text>
                                        }
                                    ]}
                                    selectable={false}
                                >
                                    {state.Whitelable && state.Whitelable.length > 0 && state.Whitelable.map((item, index) => {
                                        let assingCnt = 0;
                                        if (state.SubUser) {
                                            for (let i = 0; i < state.SubUser.length; i++) {
                                                const subData = state.SubUser[i];
                                                if (subData?.plan?.pid?.toString() === item._id?.toString()) {
                                                    assingCnt += 1;
                                                }
                                            }
                                        }

                                        return (
                                            <IndexTable.Row id={index} key={index} position={index}>
                                                <IndexTable.Cell>
                                                    <Box paddingBlock={100}>
                                                        <Text variant='bodyMd'> {`${item.pn.charAt(0).toUpperCase()}${item.pn.slice(1)}`}</Text>
                                                        {/* <Text variant='bodySm' fontWeight='medium'>{item.pn}</Text> */}
                                                        <Text variant='bodySm' tone="subdued">{`${item.pid.charAt(0).toUpperCase()}${item.pid.slice(1)}`}</Text>
                                                    </Box>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Text variant='bodySm'>
                                                        {item.pd ? item.pd.charAt(0).toUpperCase() + item.pd.slice(1) : "-"}
                                                    </Text>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Text variant='bodySm'>{item.prc}</Text>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Text variant='bodySm'>{item.uaq ? item.uaq?.toLocaleString() : 0}</Text>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Text variant='bodySm'>{item.taq ? item.taq?.toLocaleString() : 0}</Text>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Text variant='bodySm'>{assingCnt}</Text>
                                                </IndexTable.Cell>
                                                <IndexTable.Cell>
                                                    <Checkbox
                                                        checked={item.ia}
                                                        onChange={() => props.updPlanStatus(item._id, item.ia)}
                                                    />
                                                </IndexTable.Cell>
                                                {props.checkPermission('white_label_plan', 'isedit', props.profile) &&
                                                    <IndexTable.Cell>
                                                        <InlineStack wrap={false} gap={100}>
                                                            <Button variant="monochromePlain" onClick={() => props.handleEditData(item)} icon={EditIcon} />
                                                            <Button variant="monochromePlain" onClick={() => props.handleDeleteData(item._id, item.pn)} icon={DeleteIcon} />
                                                        </InlineStack>
                                                    </IndexTable.Cell>
                                                }
                                            </IndexTable.Row>
                                        )
                                    })}
                                </IndexTable>
                            </Card>
                        </Box>
                    </Box>
                </div>
            </Box>


            {/* Add new plan Modal*/}
            <Modal
                open={state.newPlanModal}
                title="Add new plan"
                onClose={(e) => { props.openCloseModal('newPlanModal', state.newPlanModal, e) }}>
                <Modal.Section>
                    <InlineGrid gap="400" columns={2}>
                        <Box>
                            <TextField
                                label="Plan name"
                                value={state.pn}
                                onChange={(e) => changeNameValue({ pn: e })}
                                autoComplete="off"
                                error={state.errMessage?.['Plan name'] ? state.errMessage?.['Plan name'] : false}
                            />
                            {props.validator.message('Plan name', state.pn, 'required')}
                        </Box>
                        <Box>
                            <TextField
                                label="Plan id"
                                value={state.pid}
                                onChange={(e) => changeNameValue({ pid: e })}
                                autoComplete="off"
                                error={state.errMessage?.['Plan id'] ? state.errMessage?.['Plan id'] : false}
                            />
                            {props.validator.message('Plan id', state.pid, 'required')}
                        </Box>
                        <Box>
                            <TextField
                                type='number'
                                label={`Assign visitor quota (Remaining ${leftVisitorQuota.toLocaleString()})`}
                                value={state.uaq}
                                onChange={(e) => changeNameValue({ uaq: e })}
                                autoComplete="off"
                                error={state.errMessage?.['Assign Visitor Quota'] ? state.errMessage?.['Assign Visitor Quota'] : false}
                            />
                            {props.validator.message('Assign Visitor Quota', state.uaq, 'required')}
                        </Box>
                        <Box>
                            <TextField
                                type='number'
                                label={`Assign teammate quota (Remaining ${leftTeamQuota.toLocaleString()})`}
                                value={state.taq}
                                onChange={(e) => changeNameValue({ taq: e })}
                                autoComplete="off"
                            />
                        </Box>
                    </InlineGrid>
                    <Box paddingBlockStart={400} paddingBlockEnd={400}>
                        <TextField
                            label="Plan description"
                            value={state.pd}
                            onChange={(e) => changeNameValue({ pd: e })}
                            autoComplete="off"
                        />
                    </Box>
                    <Box paddingBlockEnd={400}>
                        <InlineGrid gap="400" columns={3}>
                            <TextField
                                type="number"
                                label="($)Price"
                                value={state.prc}
                                onChange={(e) => changeNameValue({ prc: e })}
                                autoComplete="off"
                                error={state.errMessage?.['Price'] ? state.errMessage?.['Price'] : false}
                            />
                            {props.validator.message('Price', state.prc, 'required')}
                            <Select
                                label={state.piunt === 'lifetime' ? 'Plan valid for lifetime' : `Plan valid for ${state.pint} ${state.piunt}`}
                                options={intervalUnit}
                                onChange={(e) => changeNameValue({ piunt: e })}
                                value={state.piunt}
                            />
                            {state.piunt !== 'lifetime' &&
                                <TextField
                                    type="number"
                                    label="Plan duration"
                                    value={state.pint}
                                    onChange={(e) => changeNameValue({ pint: e })}
                                    autoComplete="off"
                                />
                            }
                        </InlineGrid>
                    </Box>
                    <Box paddingBlockEnd={0}>
                        <div className='email'>
                            <Tooltip content="Click here to know about this permissions." hasUnderline width='1%' dismissOnMouseOut>
                                <Text variant="headingSm" fontWeight="medium" as="span">
                                    Sub-account branding control
                                </Text>
                            </Tooltip>
                        </div>
                    </Box>
                    <InlineGrid gap="0" columns={3}>
                        <Checkbox
                            label="Remove brandname"
                            checked={state.crbn ? true : false}
                            onChange={(e) => changeNameValue({ crbn: e })}
                        />
                        <Checkbox
                            label="Change brand URL"
                            checked={state.ccbn ? true : false}
                            onChange={(e) => changeNameValue({ ccbn: e })}
                        />
                    </InlineGrid>
                    <InlineStack align='start'>
                        <Box paddingBlockEnd={100} paddingBlockStart={300}>
                            {
                                props.checkPermission('white_label_plan', 'isedit', props.profile) &&
                                <Button id='Add' size='large' onClick={() => {
                                    props.openCloseModal('newPlanModal', state.newPlanModal, 'close');
                                    props.addUpdatePlan();
                                }}>Add</Button>
                            }
                        </Box>
                    </InlineStack>
                </Modal.Section>
            </Modal>


            {/* delete data modal */}
            <Modal
                open={state.deleteDataModal}
                title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
                onClose={(e) => { props.openCloseModal('deleteDataModal', state.deleteDataModal, e); }}>
                <Modal.Section>
                    <Box paddingBlockEnd={400}>
                        <Text variant="bodyMd" as="p"> Delete {state.dpn} plan?</Text>
                    </Box>
                    <Divider />
                    <PageActions
                        primaryAction={{
                            icon: DeleteIcon, content: 'Delete', tone: 'critical',
                            onAction: () => {
                                props.handleDeleteCall(state.dataId);
                            }
                        }}
                        secondaryActions={[
                            {
                                content: 'Close',
                                onAction: () => { props.openCloseModal('deleteDataModal', state.deleteDataModal, 'close'); }
                            }
                        ]}
                    />
                </Modal.Section>
            </Modal>


            {/* edit data modal */}
            <Modal
                open={state.EditDataModal}
                title={<Text variant='headingMd' fontWeight='bold'>Update lead detail</Text>}
                onClose={(e) => props.openCloseModal('EditDataModal', state.EditDataModal, e)}>
                <Modal.Section>
                    <InlineGrid gap="400" columns={2}>
                        <Box>
                            <TextField
                                label="Plan name"
                                value={state.pn}
                                onChange={(e) => changeNameValue({ pn: e })}
                                autoComplete="off"
                                error={state.errMessage?.['pn'] ? state.errMessage?.['pn'] : false}
                            />
                            {props.validator.message('pn', state.pn, 'required')}
                        </Box>
                        <Box>
                            <TextField
                                label="Plan id"
                                value={state.pid}
                                onChange={(e) => changeNameValue({ pid: e })}
                                autoComplete="off"
                                error={state.errMessage?.['pid'] ? state.errMessage?.['pid'] : false}
                            />
                            {props.validator.message('pid', state.pid, 'required')}
                        </Box>
                        <Box>
                            <TextField
                                type='number'
                                label={`Assign visitor quota (Remaining ${leftVisitorQuota.toLocaleString()})`}
                                value={state.uaq}
                                onChange={(e) => changeNameValue({ uaq: e })}
                                autoComplete="off"
                                error={state.errMessage?.['uaq'] ? state.errMessage?.['uaq'] : false}
                            />
                            {props.validator.message('uaq', state.uaq, 'required')}
                        </Box>
                        <Box>
                            <TextField
                                type='number'
                                label={`Assign teammate quota (Remaining ${leftTeamQuota.toLocaleString()})`}
                                value={state.taq}
                                onChange={(e) => changeNameValue({ taq: e })}
                                autoComplete="off"
                            />
                        </Box>
                    </InlineGrid>
                    <Box paddingBlockStart={400} paddingBlockEnd={400}>
                        <TextField
                            label="Plan description"
                            value={state.pd}
                            onChange={(e) => changeNameValue({ pd: e })}
                            autoComplete="off"
                        />
                    </Box>
                    <Box paddingBlockEnd={400}>
                        <InlineGrid gap="400" columns={3}>
                            <TextField
                                type="number"
                                label="($)Price"
                                value={state.prc}
                                onChange={(e) => changeNameValue({ prc: e })}
                                autoComplete="off"
                                error={state.errMessage?.['prc'] ? state.errMessage?.['prc'] : false}
                            />
                            {props.validator.message('prc', state.prc, 'required')}
                            <Select
                                label={state.piunt === 'lifetime' ? 'Plan valid for lifetime' : `Plan valid for ${state.pint} ${state.piunt}`}
                                options={intervalUnit}
                                onChange={(e) => changeNameValue({ piunt: e })}
                                value={state.piunt}
                            />
                            {state.piunt !== 'lifetime' &&
                                <TextField
                                    type="number"
                                    label="Plan duration"
                                    value={state.pint}
                                    onChange={(e) => changeNameValue({ pint: e })}
                                    autoComplete="off"
                                />
                            }
                        </InlineGrid>
                    </Box>
                    <Box paddingBlockEnd={0}>
                        <div className='email'>
                            <Tooltip content="Click here to know about this permissions." hasUnderline width='1%' dismissOnMouseOut>
                                <Text variant="headingSm" fontWeight="medium" as="span">
                                    Sub-account branding control
                                </Text>
                            </Tooltip>
                        </div>
                    </Box>
                    <InlineGrid gap="0" columns={3}>
                        <Checkbox
                            label="Remove brandname"
                            checked={state.crbn ? true : false}
                            onChange={(e) => changeNameValue({ crbn: e })}
                        />
                        <Checkbox
                            label="Change brand URL"
                            checked={state.ccbn ? true : false}
                            onChange={(e) => changeNameValue({ ccbn: e })}
                        />
                    </InlineGrid>
                    <InlineStack align='start'>
                        <Box paddingBlockEnd={100} paddingBlockStart={300}>
                            {
                                props.checkPermission('white_label_plan', 'isedit', props.profile) &&
                                <Button id='Add' onClick={() => {
                                    props.openCloseModal('EditDataModal', state.EditDataModal, 'close');
                                    props.handleEditCall();
                                }}>Update</Button>
                            }
                        </Box>
                    </InlineStack>
                </Modal.Section>
            </Modal>

        </div>
    );
}

export default NewPlan;