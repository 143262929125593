import React, { useState, useCallback, useEffect } from 'react';
import { Box, OptionList } from '@shopify/polaris';
import { DashboardCard } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardDucks from '../../ducks/dashboard';
import { checkPermission } from '../../services/permissions';
import moment from 'moment';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    isActive: true,
    statusTabs: [ // tabs
      { label: 'Last 24 hours', value: 'today' },
      { label: 'Last 7 Days', value: 'week' },
      { label: 'Last 30 Days', value: 'month' },
      { label: 'Custom date', value: 'custom' },
    ],
    queStatus: 'today',
    selected: 0,
    queryValue: '',  //filter search value,
    paggiActive: 1,
    fd: new Date(),
    td: new Date(),
    pfd: new Date(),
    ptd: new Date(),
    nTyp: 'All',
    isClearRadis: '',
    popoverActive: false,
    pageNumber: 0,
    frmdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    tdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    analyticsOpen: false,
    analyticNotifId: ''
  });

  //get profile data
  const profile = useSelector(state => state.auth.profile);

  const dataPerformance = useSelector(state => state.dashboard?.dashboard);
  const getNotiTypList = useSelector(state => state?.dashboard?.getNotiTypList?.data);

  let PerformanceData = dataPerformance?.data;
  let TotalData = dataPerformance?.totalCount || 0;


  useEffect(() => {
    dispatch(dashboardDucks.getNotiTypList());
  }, [])

  //set the selected tab date
  useEffect(() => {
    let today = new Date();
    if (state.selected === 0) {
      changeNameValue({ popoverActive: false });
      let fd = formattedDate(today);
      let td = formattedDate(today);
      let pfd = formattedDate(new Date(new Date().setDate(new Date().getDate() - 1)));
      const obj = {
        fd, td, pfd, ptd: pfd
      };
      changeNameValue(obj);
    }

    if (state.selected === 1) {
      changeNameValue({ popoverActive: false });

      let fd = formattedDate(new Date(new Date().setDate(new Date().getDate() - 7)));
      let pfd = formattedDate(new Date(new Date().setDate(new Date().getDate() - 14)));

      let td = formattedDate(new Date(new Date().setDate(new Date().getDate() - 1)));
      let ptd = formattedDate(new Date(new Date().setDate(new Date().getDate() - 8)));

      const obj = {
        fd, td, pfd, ptd
      };
      changeNameValue(obj);
    }

    if (state.selected === 2) {
      changeNameValue({ popoverActive: false });

      let fd = formattedDate(new Date(new Date().setDate(new Date().getDate() - 30)));
      let pfd = formattedDate(new Date(new Date().setDate(new Date().getDate() - 60)));

      let td = formattedDate(new Date(new Date().setDate(new Date().getDate() - 1)));
      let ptd = formattedDate(new Date(new Date().setDate(new Date().getDate() - 31)));

      const obj = {
        fd, td, pfd, ptd
      };
      changeNameValue(obj);
    }

    if (state.selected === 3) {
      changeNameValue({ popoverActive: true });
    }
  }, [state.selected])

  const callPfmsApi = () => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(state.fd)) {
      const obj = {
        'dn': state.queStatus,
        'fd': state.fd,
        'isClearRadis': state.isClearRadis,
        'istm': localStorage.getItem('istm'),
        'num': state.paggiActive,
        'pagesize': 10,
        'pfd': state.pfd,
        'ptd': state.ptd,
        'search': state.queryValue,
        'td': state.td,
        'type': state.nTyp
      }
      dispatch(dashboardDucks.getDashboard(obj));
    }
  }

  useEffect(() => {
    if (state.queryValue) {
      let queryTimer = setTimeout(() => callPfmsApi(), 1000);
      return () => clearTimeout(queryTimer);
    }
    else {
      callPfmsApi();
    }
  }, [state.paggiActive, state.nTyp, state.queryValue, state.fd, state.td]);

  // table tab select
  const tabs = state?.statusTabs?.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
    onAction: () => queStatusClick(item.value)
  }));

  const queStatusClick = useCallback((typ) => {
    let stateObj = {
      queStatus: typ,
      paggiActive: 1
    }
    setState((prevState) => ({ ...prevState, ...stateObj }));
  }, [state.queStatus]);

  // for custom date popup open (same tab click, event is not coming)
  if (document.getElementById("Custom date-3")) {
    document.getElementById("Custom date-3").onclick = function () {
      changeNameValue({ popoverActive: true });
    };
  }

  // paggination
  const onPaggiCall = (i) => {
    let num = 0;
    if (i === '+1') {
      num = state.paggiActive + 1;
    } else if (i === '-1') {
      num = state.paggiActive - 1;
    } else {
      num = i;
    }
    changeNameValue({ 'paggiActive': num });
  }

  //get number of totaldata
  useEffect(() => {
    changeNameValue({ pageNumber: parseInt(Math.ceil(TotalData / 10)) });
  }, [TotalData]);

  const formattedDate = (date) => {
    let formateDT = moment(date ? new Date(date) : new Date()).format('YYYY-MM-DD');
    return formateDT.toString();
  }

  //list of notification type filters data
  const NotificationType = getNotiTypList?.map(element => ({ label: element.nn, value: element.nt }));

  NotificationType?.unshift({ label: 'All', value: 'All' });
  let uniqueNotificationType = [...new Map(NotificationType?.map(item => [item.value, item])).values()];

  //set display name for inline notification type
  const labelMap = {
    'purchase_bulk_ecomm_snippet': 'Sales Count - Inline',
    'live_visitor_ecomm_snippet': 'Live Visitor - Inline',
    'purchase_recent_ecomm_snippet': 'Sales - Inline'
  };

  uniqueNotificationType = uniqueNotificationType.map(x => labelMap[x.value] ? { value: x.value, label: labelMap[x.value] } : x);

  const notifWord = (str) => {
    const val = uniqueNotificationType.find(elm => elm.value === str);
    return val?.label;
  };

  const filters = [
    {
      key: 'nTyp',
      label: (state.nTyp !== "" ? `Notification: ${notifWord(state.nTyp)}` : "Notification"),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
      filter: (
        <div>
          <Box>
            <OptionList
              title="Notification type"
              onChange={(e) => changeNameValue({ 'nTyp': e[0], paggiActive: 1, isClearRadis: 'true' })}
              options={uniqueNotificationType}
              selected={[state.nTyp]}
            />
          </Box>
        </div>
      )
    }
  ];

  const handleFiltersClearAll = () => {
    let obj = {
      queryValue: '',
      nTyp: '',
    }
    changeNameValue(obj);
  }

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //select custom date
  const handleSelectDate = () => {
    const startDate = new Date(state.frmdt);
    const endDate = new Date(state.tdt);
    const fd = formattedDate(startDate);
    const td = formattedDate(endDate);
    const obj = {
      fd, td, pfd: '', ptd: '', popoverActive: false
    };
    changeNameValue(obj);
  }

  //Edit btn click
  const handleEditBtn = (data) => {
    const url = `/notifEdit?nti=${data.nti}&id=${data.notificationid}&ntt=${data.notificationType}`;
    navigate(url);
  }

  return (
    <>
      {checkPermission('performance', 'isview', profile) &&
        <DashboardCard
          state={state}
          tabs={tabs}
          filters={filters}
          profile={profile}
          checkPermission={checkPermission}
          onPaggiCall={onPaggiCall}
          handleEditBtn={handleEditBtn}
          handleFiltersClearAll={handleFiltersClearAll}
          changeNameValue={changeNameValue}
          PerformanceData={PerformanceData}
          handleSelectDate={handleSelectDate}
        />
      }
    </>
  );
};

export default Dashboard;
