import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/billingInvoice';
import { getProfile } from '../ducks/auth';
import { toastify } from '../ducks/toast';

function* getBillingInvoice(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getBillingHistory', action.payload);

    if (res.status === 'success') {
      yield put(actions.getBillingInvoiceSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* mangePaymentSrc(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/mangePaymentSrc', action.payload);

    if (res.status === 'success') {
      yield put(actions.mangePaymentSrcSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* downloadInvoice(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/downloadInvoice?iv=' + action.payload.iv);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      window.location.href = res.data.download_url;
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getShopifyBill(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getShopifyBill', action.payload);

    if (res.status === 'success') {
      yield put(actions.getShopifyBillSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getUserCard(action) {
  let res;
  try {
    yield put(load());
    if (action.payload) {
      res = yield call(api.GET, '/getProfile?pvd=' + action.payload.pvd);
    }
    else {
      res = yield call(api.GET, '/getProfile');
    }
    if (res.status === 'success') {
      yield put(actions.getUserCardSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: "Failed to get user payment method" }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//create chargebee paymant retry form
function* paymantRetry(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/paymantRetry', action.payload);

    if (res.status === 'success') {
      // yield put(toastify({ type: 'success', msg: res.m }));
      yield put(getProfile());
      if (res.data && res.data.url) {
        window.location.href = res.data.url;
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get subscription data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Error from paymantRetry' }));
  }
}

function* rmvUserAlrt() {
  try {
    yield call(api.POST, '/rmvUserAlrt');
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetBillingInvoice() {
  yield takeLatest(actions.getBillingInvoice.type, getBillingInvoice);
  yield takeLatest(actions.mangePaymentSrc.type, mangePaymentSrc);
  yield takeLatest(actions.downloadInvoice.type, downloadInvoice);
  yield takeLatest(actions.getShopifyBill.type, getShopifyBill);
  yield takeLatest(actions.getUserCard.type, getUserCard);
  yield takeLatest(actions.paymantRetry.type, paymantRetry);
  yield takeLatest(actions.rmvUserAlrt.type, rmvUserAlrt);
}

export default function* rootSaga() {
  yield fork(watchGetBillingInvoice);
}
