import React, { useCallback, useEffect } from 'react';
import { Text, Badge, Modal, InlineGrid, FullscreenBar, Tag, SkeletonBodyText, Tooltip, BlockStack, Select, Checkbox, Divider, Icon, ButtonGroup, SkeletonDisplayText, SkeletonPage, InlineStack, Card, Page, Button, Box, TextField } from '@shopify/polaris';
import { ExitIcon, PlusIcon } from '@shopify/polaris-icons';
import ReviewFeedCard from './ReviewFeedCard';
import AllinoneCard from './AllinoneCard';
import TextCard from './TextCard';
import DisplayCard from './DisplayCard';
import PreviewCard from './PreviewCard';
import DataSourceCard from './DataSourceCard';
import IconCard from './IconCard';
import IntegrationModal from '../../containers/Integration/Integration';
import { checkPermission } from '../../services/permissions';
import { ThemeStoreIcon, TargetIcon, DatabaseConnectIcon } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';

function NotifEditCard(props) {
  let { state, changeNameValue, openCloseModal, activeNotifPopUp, deleteAbTestNotif, handleAbTestTab,
    handleCreateAbTestNotif, handleBackToMain } = props;

  let mainTabs = [
    { value: 1, label: 'Design', name: 'design', Icon: ThemeStoreIcon },
    { value: 2, label: 'Display', name: 'display', Icon: TargetIcon }
  ];
  // Add "Data source" tab if condition is true
  if (state.notiById &&
    state.queryNtt !== 'live_visitor' && state.queryNtt !== 'single_visits' &&
    state.queryNtt !== 'social_media_followers' && state.queryNtt !== 'limited_stock' &&
    state.queryNtt !== 'title_bar' && state.queryNtt !== 'custom' &&
    state.queryNtt !== 'event_banner' && state.queryNtt !== 'video' &&
    state.queryNtt !== 'call_to_action' && state.queryNtt !== 'all_in_one' &&
    state.queryNtt !== 'whatsapp_chat' &&
    state.queryNtt !== 'lottery' && state.queryNtt !== 'collector' &&
    state.queryNtt !== 'offer' && state.queryNtt !== 'live_visitor_ecomm_snippet' &&
    state.queryNtt !== 'limited_stock_ecomm_snippet') {
    mainTabs.push({ value: 3, label: 'Data source', name: 'dataSource', Icon: DatabaseConnectIcon });
  }

  // ab-test tab -set active default 1st tab of ab-test (origin)
  if (state.abId !== null && state?.notiById?.vrnt) {
    // default tab active using 'abTyp'
    if (state.isDefaultTabActive !== true) {   // 'isDefaultTabActive' for calling function 1 times only 
      setTimeout(() => {
        const index = state.notiById?.vrnt.findIndex(tab => tab._id === state.abId);
        if (index !== -1) {
          changeNameValue({ tabActiveTyp: (index + 2), isDefaultTabActive: true });
          handleAbTestTab(state.notiById?.vrnt[index]);
        }
      }, 700);
    }
  }

  // Atleast 1 data-source required(click redirect to dataSource tab)
  useEffect(() => {
    if (state.tabIndex) {
      mainTabClick('dataSource', 3);
    }
  }, [state.tabIndex]);

  const mainTabClick = useCallback((typ, index) => {
    if (typ === 'dataSource') {
      changeNameValue({ btnNotiSave: (state?.notiById?.ia === true) ? 'Finish' : 'Publish' });
    }
    else if (typ === 'display' && (state.queryNtt === 'social_media_followers' ||
      state.queryNtt === 'announcement_feed' ||
      state.queryNtt === 'all_in_one' ||
      state.queryNtt === 'whatsapp_chat' ||
      state.queryNtt === 'offer' ||
      state.queryNtt === 'call_to_action' ||
      state.queryNtt === 'video' ||
      state.queryNtt === 'title_bar' ||
      state.queryNtt === 'custom' ||
      state.queryNtt === 'limited_stock' ||
      state.queryNtt === 'lottery' ||
      state.queryNtt === 'live_visitor' ||
      state.queryNtt === 'single_visits' ||
      state.queryNtt === 'live_visitor_ecomm_snippet' ||
      state.queryNtt === 'limited_stock_ecomm_snippet')) {
      changeNameValue({ btnNotiSave: (state?.notiById?.ia === true) ? 'Finish' : 'Publish' });
    } else {
      changeNameValue({ btnNotiSave: 'Next' });
    }

    changeNameValue({
      tabActiveTyp: index,
      mainActiveNm: typ,
      verticalActive: state.verticalActive
    });
  }, [state]);

  const handleNextTab = () => {
    let currentTab = state.tabActiveTyp + 1;
    let obj = { tabActiveTyp: currentTab };

    if (currentTab === 1) {
      obj['btnNotiSave'] = 'Next';
      obj['mainActiveNm'] = 'design';
      changeNameValue(obj);
    }
    else if (currentTab === 2) {
      if (
        state.queryNtt !== 'social_media_followers' &&
        state.queryNtt !== 'call_to_action' &&
        state.queryNtt !== 'all_in_one' &&
        state.queryNtt !== 'whatsapp_chat' &&
        state.queryNtt !== 'announcement_feed' &&
        state.queryNtt !== 'video' &&
        state.queryNtt !== 'limited_stock' &&
        state.queryNtt !== 'title_bar' &&
        state.queryNtt !== 'custom' &&
        state.queryNtt != 'collector' &&
        state.queryNtt != 'lottery' &&
        state.queryNtt !== 'live_visitor' &&
        state.queryNtt !== 'single_visits' &&
        state.queryNtt !== 'offer' &&
        state.queryNtt !== 'live_visitor_ecomm_snippet' &&
        state.queryNtt !== 'limited_stock_ecomm_snippet')
        obj['btnNotiSave'] = 'Next';
      else {
        obj['btnNotiSave'] = (state?.notiById?.ia === true) ? 'Finish' : 'Publish';
      }
      obj['mainActiveNm'] = 'display';
      changeNameValue(obj);
    }
    else if (currentTab === 3 &&
      state.queryNtt !== 'social_media_followers' &&
      state.queryNtt !== 'limited_stock' &&
      state.queryNtt !== 'title_bar' &&
      state.queryNtt !== 'custom' &&
      state.queryNtt !== 'video' &&
      state.queryNtt !== 'call_to_action' &&
      state.queryNtt !== 'all_in_one' &&
      state.queryNtt !== 'whatsapp_chat' &&
      state.queryNtt !== 'offer' &&
      state.queryNtt !== 'announcement_feed' &&
      state.queryNtt !== 'collector' &&
      state.queryNtt != 'lottery' &&
      state.queryNtt !== 'live_visitor' &&
      state.queryNtt !== 'single_visits' &&
      state.queryNtt !== 'live_visitor_ecomm_snippet' &&
      state.queryNtt !== 'limited_stock_ecomm_snippet') {
      obj['btnNotiSave'] = (state?.notiById?.ia === true) ? 'Finish' : 'Publish';
      obj['mainActiveNm'] = 'dataSource';
      changeNameValue(obj);
    }
    else {
      if (state.queryNti && (state?.notiById?.ia === false || (state?.arrIntg.length == 0 &&
        (state.queryNtt == 'purchase_recent' || state.queryNtt == 'purchase_bulk' ||
          state.queryNtt == 'review_recent' || state.queryNtt == 'review_bulk')))) {
        openCloseModal('publishConfirmOpen', state.publishConfirmOpen, 'open')
      }
      else handleBackToMain();
    }
  }

  // 'state.verticalActive' on this state change moving class 
  useEffect(() => {
    if (state?.verticalActive && state.verticalTabsArr?.length > 0) {
      let key = state.verticalTabsArr.findIndex(x => x.value === state.verticalActive);

      changeNameValue({ sidebarActiveIndex: key });

      if (document.getElementById('wdgt_sidebar_blk')) {
        document.getElementById('wdgt_sidebar_blk').style.cssText += 'transform: translate(0px, ' + (key * 52) + 'px);';
      }
    }
  }, [state.verticalActive, state.verticalTabsArr]);

  const removeTag = (_id) => {
    openCloseModal('variantConfirmOpen', state.variantConfirmOpen, 'open');
    changeNameValue({ variantId: _id });
  }

  let loading = useSelector((state) => state.loading.isLoading);

  return (
    <div className='container-custom container-notiedit'>
      <Page>

        {/* custom back-bar using polaris (not use 'FullscreenBar' cause of spacing issue) */}
        <Card roundedAbove padding={0}>
          <InlineStack gap={300} align='space-between'>
            <InlineStack gap={200}>
              <Box padding={300} id='dFlex'>
                <Button onClick={() => handleBackToMain()} disabled={state.isEditNotif}
                  variant='monochromePlain' icon={ExitIcon}>
                  <Text fontWeight='medium' variant='bodyLg'>Back</Text>
                </Button>
              </Box>
              <span className='back_diViDer'></span>
              <Box padding={300} id='dFlex'>

                <InlineStack gap={300} align='center' blockAlign='center'>
                  {state?.notiById?.ia !== undefined ?
                    state?.notiById?.ia === true ?
                      <Badge tone="success"><Box paddingBlock={'050'} paddingInline={100}>
                        <Text fontWeight='medium'>Active</Text>
                      </Box></Badge>
                      : <Badge><Box paddingBlock={'050'} paddingInline={100}>
                        <Text fontWeight='medium'>Pause</Text>
                      </Box></Badge>
                    : <Button loading>Pause</Button>
                  }
                  {state?.notiById?.nn !== undefined ?
                    <Text as='h5' variant='bodyLg' fontWeight='bold'>{state?.notiById?.nn}</Text>
                    :
                    <Box background='bg-fill-tertiary' borderRadius='150' paddingBlock={100} paddingInline={1600} />
                  }
                </InlineStack>
              </Box>
            </InlineStack>

            <Box padding={300} id='dFlex'>
              <ButtonGroup>
                {state.abId === null ?
                  state.btnNotiSave === 'Publish' ?
                    <Tooltip dismissOnMouseOut content='Go live' padding='400'>
                      <Button disabled={state.isEditNotif} variant='primary' tone='success'
                        onClick={() => handleNextTab()}>{state.btnNotiSave}</Button>
                    </Tooltip>
                    :
                    <Button disabled={state.isEditNotif} variant='primary' tone='success'
                      onClick={() => handleNextTab()}>{state.btnNotiSave}</Button>
                  :
                  <InlineStack gap={200}>
                    {checkPermission('notification', 'isadd', state?.ProfileData) &&
                      <Button disabled={state.isEditNotif} onClick={() => openCloseModal('crtVarntOpen', state.crtVarntOpen, 'open')} icon={PlusIcon}>Add variant</Button>}
                    <Button disabled={state.isEditNotif} variant='primary' tone='success' onClick={() => handleBackToMain()}>Finish</Button>
                  </InlineStack>
                }
              </ButtonGroup>
            </Box>

          </InlineStack>
        </Card>

        {/* main tabs */}
        <Box paddingBlockStart={600}>

          {/* design-display-dataSource main tabs */}
          {state.abId === null ?
            <InlineStack gap={500}>

              {mainTabs.map((d, i) => (
                <Button key={i} variant='monochromePlain' disabled={state.isEditNotif ? state.tabActiveTyp !== d.value : false} onClick={() => mainTabClick(d.name, (i + 1))}>
                  <Box>
                    <div className='icon-tabs'>
                      <Icon tone={state.tabActiveTyp === d.value ? 'magic' : 'base'} source={d.Icon} />
                      <Text alignment='center' fontWeight={state.tabActiveTyp === d.value ? 'medium' : 'regular'}
                        tone={(state.tabActiveTyp === d.value) ? 'magic' : state.isEditNotif ? 'disabled' : 'subdued'}>{d.label}</Text>
                    </div>
                    <Box paddingBlockStart={200} id='tab_underLne'>
                      {state.tabActiveTyp === d.value &&
                        <Divider borderWidth='050' borderColor='border-magic-secondary' />}
                    </Box>
                  </Box>
                </Button>
              ))}
            </InlineStack>
            :
            // abTest tab
            <div className='TabsWrapper'>

              {/* 1st default ab-test tab(Origin) { value: 1, label: 'Origin', name: 'origin' } */}
              {state?.notiById?.idltfrmvrnt !== true &&
                <Button variant='monochromePlain' disabled={state.isEditNotif ? state.tabActiveTyp !== 1 : false}
                  onClick={() => {
                    changeNameValue({ tabActiveTyp: 1, mainActiveNm: 'origin' });
                    handleAbTestTab('current')
                  }}>
                  <Box minWidth='80px'>
                    <InlineStack gap={150} align='center'>
                      <div className='icon-tabs'>
                        {state?.notiById?.iornl &&
                          <Tooltip dismissOnMouseOut padding='400' content={"Default variant"} hasUnderline width='wide'>
                            <svg data-tip data-for='defaultvariant' className="original-icon" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 16.002 20.998">
                              <path id="medal-outline" d="M14.94,19.5,12,17.77,9.06,19.5l.78-3.34L7.25,13.92l3.41-.29L12,10.5l1.34,3.13,3.41.29-2.59,2.24M20,2H4V4L8.86,7.64a8,8,0,1,0,6.28,0L20,4M18,15a6,6,0,1,1-7.18-5.88,5.86,5.86,0,0,1,2.36,0A6,6,0,0,1,18,15M12.63,7H11.37l-4-3h9.34Z" transform="translate(-3.999 -2)" fill={(state.tabActiveTyp === 1) ? '#645CFC' : state.isEditNotif ? '' : "#616161"} />
                            </svg>
                          </Tooltip>
                        }
                        <Text alignment='center' fontWeight={state.tabActiveTyp === 1 ? 'medium' : 'regular'} tone={(state.tabActiveTyp === 1) ? 'magic' : state.isEditNotif ? 'disabled' : 'subdued'}> Origin </Text>
                      </div>
                    </InlineStack>
                    <Box paddingBlockStart={200} id='tab_underLne'>
                      {state.tabActiveTyp === 1 &&
                        <Divider borderWidth='050' borderColor='border-magic-secondary' />}
                    </Box>
                  </Box>
                </Button>
              }

              {/* dynamic variant tabs -Variant 1, 2, .. */}
              {state?.notiById?.vrnt?.length > 0 && state.notiById.vrnt.map((d, i) => {
                let _key = i + 1; //tabs start with 1
                let tabActive = i + 2;
                return (
                  <Button variant='monochromePlain' disabled={state.isEditNotif || false} key={i}
                    onClick={() => {
                      changeNameValue({ tabActiveTyp: tabActive, mainActiveNm: 'variant' + _key });
                      handleAbTestTab(d)
                    }}>
                    <Box minWidth='80px'>
                      <div className='icon-tabs'>
                        {d.iornl &&
                          <Tooltip dismissOnMouseOut padding='400' content={"Default variant"} hasUnderline width='wide'>
                            <svg data-tip data-for='defaultvariant' className="original-icon" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 16.002 20.998">
                              <path id="medal-outline" d="M14.94,19.5,12,17.77,9.06,19.5l.78-3.34L7.25,13.92l3.41-.29L12,10.5l1.34,3.13,3.41.29-2.59,2.24M20,2H4V4L8.86,7.64a8,8,0,1,0,6.28,0L20,4M18,15a6,6,0,1,1-7.18-5.88,5.86,5.86,0,0,1,2.36,0A6,6,0,0,1,18,15M12.63,7H11.37l-4-3h9.34Z" transform="translate(-3.999 -2)" fill={(state.tabActiveTyp === tabActive) ? '#645CFC' : state.isEditNotif ? '' : "#616161"} /></svg>
                          </Tooltip>
                        }
                        <Text alignment='center' fontWeight={state.tabActiveTyp === tabActive ? 'medium' : 'regular'} tone={(state.tabActiveTyp === tabActive) ? 'magic' : state.isEditNotif ? '' : 'subdued'}>Variant {_key}</Text>

                        {(d.ttl && !d.iornl && state.isEditNotif !== true) ?
                          <Tag onRemove={() => removeTag(d._id)}>{d.ttl}</Tag>
                          :
                          <Tag>{d.ttl}</Tag>  //on editNotif true not give remove function
                        }
                      </div>

                      <Box paddingBlockStart={200} id='tab_underLne'>
                        {state.tabActiveTyp === tabActive &&
                          <Divider borderWidth='050' borderColor='border-magic-secondary' />}
                      </Box>
                    </Box>
                  </Button>
                )
              })}
            </div>
          }
          <Divider borderWidth='050' borderColor='border-brand' />
        </Box>

        {/* edit section */}
        <Box paddingBlockStart={400}>
          <div className='wdgt_prvw_wrap'>
            <InlineGrid columns={1}>

              {/*  on ab-test -not show 'display'-'datasource' tab's content */}
              {(state.tabActiveTyp === 1 || state.abId !== null) ?
                <Card padding={'100'}>
                  <div className={'wDgt_height'}>
                    <InlineGrid columns={2}>
                      <div className="left_section">
                        <Box paddingBlock={300} paddingInlineStart={300}>

                          {/* all fields sidebar (vertical tabs) */}
                          <div className='nf-sidebar'>
                            <div className='nf-sidebar-block'>
                              {state?.verticalTabsArr?.length > 0 && state.verticalTabsArr.map((data, i) =>
                                <div key={i} onClick={() => changeNameValue({ verticalActive: data.value })}
                                  className={state.sidebarActiveIndex === i ? 'side-active move_div' : 'move_div'} >
                                  {/* id={`moveLink_${data.value}`} */}
                                  <div className='move_Inner'>
                                    <InlineStack gap={200} blockAlign='center'>
                                      <Icon source={data.img} tone={state.sidebarActiveIndex === i ? 'base' : 'subdued'} />
                                      <Text tone={state.sidebarActiveIndex === i ? '' : 'subdued'} fontWeight='medium'>
                                        {data.label}</Text>
                                      {data.value === 'embedcode' && state?.notiById?.idisnp === true && <Badge tone="success">On</Badge>}
                                      {data.value === 'timingcontrol' && state.notiById?.expdt != null && new Date(state.notiById?.expdt).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && <Badge tone="critical">Expired</Badge>}

                                      {data.value === 'setcountdowntimer' && new Date(state.cnf?.tmdr).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && <Badge tone="critical">Expired</Badge>}
                                    </InlineStack>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* moving class */}
                            <div className="sidebar-ani-block is-active" id='wdgt_sidebar_blk'></div>

                            {/* for master users only -blank textbox */}
                            {localStorage.getItem('isMaster') === 'true' && state.queryNtyp === 'inlinenudge' &&
                              <Box paddingInline={300} paddingInlineEnd={600}>
                                <TextField
                                  placeholder='Enter custom html for inline widget'
                                  multiline={2}
                                  value={state?.notiById?.csthtml || ''}
                                  onChange={(e) => props.handleSettingChange('csthtml', e)} />
                              </Box>
                            }

                          </div>

                          {/* discard && save btn */}
                          {state.isEditNotif === true &&
                            <Box paddingInlineStart={500} paddingInlineEnd={800}>
                              <ButtonGroup fullWidth>
                                <Button variant='secondary' onClick={() => props.handleCancelCnf()}>Discard</Button>
                                <Button variant='primary' tone='success' onClick={() => props.handleOnClickUpdateCnf()}>Save</Button>
                              </ButtonGroup>
                            </Box>
                          }

                        </Box>
                      </div>

                      {/* tabs content */}
                      <Box background='bg-surface-secondary' id='tabsContent'>

                        {loading === true ?
                          <Box padding={500} width='80%'>
                            <BlockStack gap={700}>
                              <BlockStack gap={300}>
                                <Box background='bg-fill-tertiary' borderRadius='200' paddingBlock={200} width='45%' />
                                <SkeletonBodyText lines={3} />
                              </BlockStack>
                              <BlockStack gap={300}>
                                <Box background='bg-fill-tertiary' borderRadius='200' paddingBlock={200} width='45%' />
                                <SkeletonBodyText lines={2} />
                              </BlockStack>
                              <BlockStack gap={300}>
                                <Box background='bg-fill-tertiary' borderRadius='200' paddingBlock={200} width='45%' />
                                <SkeletonBodyText lines={3} />
                              </BlockStack>
                            </BlockStack>
                          </Box>
                          :
                          (state.queryNtt === 'whatsapp_chat' || state.queryNtt === 'offer' ||
                            state.queryNtt === 'social_media_followers' || state.queryNtt === 'all_in_one') ?
                            <AllinoneCard
                              props={props} />
                            :
                            state.queryNtt === 'review_feed' ?  // review_feed_1 & review_feed_2 two template
                              <ReviewFeedCard
                                props={props} />
                              :
                              <TextCard
                                props={props} />
                        }
                      </Box>

                    </InlineGrid>
                  </div>
                </Card>
                :
                state.mainActiveNm === 'display' ?  //display tab only
                  <DisplayCard
                    props={props}
                  />
                  :
                  state.mainActiveNm === 'dataSource' &&   //data-source tab only
                  <DataSourceCard
                    props={props}
                  />
              }

              {/* preview section */}
              <PreviewCard
                props={props}
              />

            </InlineGrid>
          </div>
        </Box>

      </Page>




      {/* youtube modal */}
      <Modal
        size='large'
        open={state.youTubeOpen}
        onClose={(e) => openCloseModal('youTubeOpen', state.youTubeOpen, 'close')}>
        <InlineStack align='center'>
          <iframe id="youtubeViDeo" width="981" height="550" src={`//www.youtube.com/embed/${state.youTubeKey}`} allowFullScreen={false}></iframe>
        </InlineStack>
      </Modal>

      {/* create variant modal */}
      <Modal
        open={state.crtVarntOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Create new variant</Text>}
        onClose={() => openCloseModal('crtVarntOpen', state.crtVarntOpen, 'close')}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <BlockStack gap={400}>
              <>
                <TextField
                  label="Variant title"
                  value={state.abTitle}
                  onChange={(value) => changeNameValue({ abTitle: value })}
                  autoComplete="off"
                  error={state.errMessage.variantTitle ? state.errMessage.variantTitle : false} />
                {props.validator.message('variantTitle', state.abTitle, 'required')}
              </>
              <TextField
                label="Description"
                placeholder='Write here & press enter'
                multiline={2}
                value={state.abDesc}
                onChange={(value) => changeNameValue({ abDesc: value })}
                autoComplete="off"
              />
              <Select
                label="Clone from which variant ?"
                options={state.arrVrnt}
                value={state.abVrnt}
                onChange={(value) => changeNameValue({ abVrnt: value })}
              />
              <Checkbox
                label="Use this variant for A/B testing"
                checked={state.abia}
                onChange={(value) => changeNameValue({ abia: value })}
              />
              <InlineStack align='center'>
                <Box paddingBlock={300}>
                  <Button variant='primary' tone='success' onClick={() => handleCreateAbTestNotif()}>Create & customize</Button>
                </Box>
              </InlineStack>
            </BlockStack>
          </div>
        </Modal.Section>
      </Modal>

      {/* ab-test delete confirmation */}
      <Modal
        size='small'
        open={state.variantConfirmOpen}
        title={<Text variant='headingLg' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('variantConfirmOpen', state.variantConfirmOpen, e)}>
        <Box paddingInline={500} paddingBlock={500}>
          <InlineStack direction={'column'} align='space-between'>
            <Box paddingBlockStart={0} paddingBlockEnd={700}>
              <Text fontWeight='medium' tone='subdued' variant='headingMd'>Are you sure want to delete this variant?</Text>
            </Box>
            <InlineStack gap={500} align='end' blockAlign='end'>
              <Button variant='' onClick={() => openCloseModal('variantConfirmOpen', state.variantConfirmOpen, 'close')}><Text variant='headingMd' fontWeight='medium'>No</Text></Button>
              <Button variant='primary' tone='critical' onClick={() => deleteAbTestNotif()}><Text variant='bodyMd' fontWeight='medium'>Yes, delete</Text></Button>
            </InlineStack>
          </InlineStack>
        </Box>
      </Modal>

      {/* Add integration modal */}
      <Modal
        size='large'
        open={state.integrateOpen}
        onClose={(e) => openCloseModal('integrateOpen', state.integrateOpen, e)} >
        <Box paddingInlineEnd={500} minHeight='90vh' id='add_inte_wrap'>
          <IntegrationModal typ={state.queryNtt} />
        </Box>
      </Modal>

      {/* publish confirmation modal */}
      {
        state?.notiById && state?.arrIntg?.length === 0 &&
          (state?.queryNtt === 'purchase_recent' ||
            state?.queryNtt === 'purchase_bulk' ||
            state?.queryNtt === 'review_recent' ||
            state?.queryNtt === 'review_bulk') ?
          <Modal
            size='small'
            open={state.publishConfirmOpen}
            title={<Text variant='headingLg' fontWeight='bold'>Data source is missing!</Text>}
            onClose={(e) => openCloseModal('publishConfirmOpen', state.publishConfirmOpen, e)}>
            <Box paddingInline={200} paddingBlock={600}>
              <InlineStack direction={'column'} align='space-between' blockAlign='center'>
                <Box paddingBlockStart={0} paddingBlockEnd={700} paddingInline={200}>
                  <Text fontWeight='medium' tone='subdued' variant='bodyMd'>
                    Looks like you haven't configured any data source in this notification. This notification requires at least one active datasource.</Text>
                </Box>
                <InlineStack gap={500}>
                  <Button onClick={() => handleBackToMain()}> Cancel </Button>
                  <Button variant='primary' tone='success' onClick={() => openCloseModal('publishConfirmOpen', state.publishConfirmOpen, 'close')}> Add data source </Button>
                </InlineStack>
              </InlineStack>
            </Box>
          </Modal>
          :
          <Modal
            size='small'
            open={state.publishConfirmOpen}
            title={<Text variant='headingMd' fontWeight='bold'>Would you like to enable this notification?</Text>}
            onClose={(e) => openCloseModal('publishConfirmOpen', state.publishConfirmOpen, e)}>
            <Box paddingInline={200} paddingBlock={800}>
              <InlineStack direction={'column'} align='space-between' blockAlign='center'>
                <Box paddingBlockStart={0} paddingBlockEnd={500}>
                  <Text fontWeight='medium' tone='subdued' variant='headingMd'>Currently it's off.</Text>
                </Box>
                <InlineStack gap={500}>
                  <Button onClick={() => handleBackToMain()}> Not now </Button>
                  <Button variant='primary' tone='success' onClick={() => activeNotifPopUp()}> Turn on </Button>
                </InlineStack>
              </InlineStack>
            </Box>
          </Modal>
      }

      {/* icon model */}
      <Modal
        size='large'
        title={<Text variant='headingLg' fontWeight='bold'>Upload icon</Text>}
        open={state.iconOpen}
        onClose={(e) => openCloseModal('iconOpen', state.iconOpen, e)}>
        <div className='modal-icon-collecter'>
          <Modal.Section>
            <IconCard  // uploading icon page
              props={props}
            />
          </Modal.Section>
        </div>
      </Modal>

    </div >
  );
}

export default NotifEditCard;
