import React, { useEffect, useState, useCallback } from 'react';
import { Box, OptionList } from '@shopify/polaris';
import { ManageTeamCard } from "../../components";
import * as manageTeamDucks from '../../ducks/manageTeam';
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission } from '../../services/permissions';
import './ManageTeam.css'
import { toastify } from '../../ducks/toast';
import { encrypt } from '../../utils/passwordService';

const ManageTeam = () => {
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    manageTeamList: [],
    getRoleList: [],
    isAddModal: false,
    isDeleteModal: false,
    isUpdateModal: false,
    errMessage: {},
    e: '',
    pd: '',
    fn: '',
    ri: '',
    pvd: '',
    ti: '',
    iu: '',
    agc: '',
    selectedMail: '',
    selectedId: '',
    selectedfn: '',
    selectedri: '',
    npass: '',
    cpass: '',
    tlq: '',
    isAssign: false,
    statusTabs: [ // tabs
      { label: 'All', value: 'all' },
      { label: 'Active', value: 'active' },
      { label: 'Deactive', value: 'deactive' },
    ],
    selected: 0,
    queryValue: '',
    domainOptions: [],
    selectedDomain: ''
  });

  const changeNameValue = (obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }

  useEffect(() => {
    dispatch(manageTeamDucks.getUserTeam());
    dispatch(manageTeamDucks.getRoleList());
    dispatch(manageTeamDucks.getUserNotif());
  }, []);

  const manageTeamList = useSelector(state => state.manageTeam?.getUserTeamList);
  const profile = useSelector(state => state.auth?.profile);
  const getRoleList = useSelector(state => state.manageTeam?.getRoleList);
  let getUserNotifList = useSelector(state => state.manageTeam?.getUserNotifList);

  useEffect(() => {
    changeNameValue({ manageTeamList, getRoleList })
  }, [manageTeamList, getRoleList]);

  useEffect(() => {
    changeNameValue({ getUserNotifList });
    //get all domian from notification 
    let domainOptions = [{ label: 'All', value: 'All' }];
    if (getUserNotifList && getUserNotifList.length > 0) {
      let notif = getUserNotifList;
      for (let i = 0; i < notif.length; i++) {
        for (let j = 0; j < notif[i].ddn.length; j++) {
          let d = domainOptions.filter(a => a.value === notif[i].ddn[j].ul);
          if (d.length == 0 && notif[i].ddn[j].ul !== null) {
            domainOptions.push({ label: notif[i].ddn[j].ul, value: notif[i].ddn[j].ul });
          }
        }
      }
    }
    changeNameValue({ domainOptions })
  }, [getUserNotifList])

  useEffect(() => {
    changeNameValue({ pvd: profile?.pvd, ti: profile?.ti, agc: profile?._id });
  }, [profile])

  //search getUserNotifList
  useEffect(() => {
    let filterdt = getUserNotifList && getUserNotifList.filter((elm) => {
      return elm.nn.toLowerCase().includes(state.queryValue.toLowerCase());
    });
    changeNameValue({ getUserNotifList: filterdt })
  }, [state.queryValue]);

  useEffect(() => {
    if (state.selected === 0) {
      changeNameValue({ getUserNotifList: getUserNotifList });
    }
    else if (state.selected === 1) {
      let filterdt = getUserNotifList && getUserNotifList.filter((elm) => {
        return elm.ia === true
      });
      changeNameValue({ getUserNotifList: filterdt });
    }
    else if (state.selected === 2) {
      let filterdt = getUserNotifList && getUserNotifList.filter((elm) => {
        return elm.ia === false
      });
      changeNameValue({ getUserNotifList: filterdt });
    }
  }, [state.selected])

  const handleAddClikBtnEvent = () => {
    if (profile && state.tlq <= 0) {
      if (localStorage.getItem('utyp') == 'appsumo' && profile.taq === 0) {
        changeNameValue({ isAddModal: true, errMessage: {}, e: '', pd: '', fn: '', ri: '' });
      }
      else {
        dispatch(toastify({ type: 'error', msg: "your all team quota is used" }));
      }
    }
    else {
      changeNameValue({ isAddModal: true, errMessage: {}, e: '', pd: '', fn: '', ri: '' });
    }
  }

  const handleAddTeam = () => {
    if (validator.errorMessages.e !== null || validator.errorMessages.pd !== null || validator.errorMessages.fn !== null || validator.errorMessages.ri !== null) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      let pass = encrypt(state.pd);
      state.pd = pass;
      let obj = {
        e: state.e,
        pd: state.pd,
        fn: state.fn,
        ri: state.ri,
        iu: state.iu,
        pvd: state.pvd,
        ti: state.ti,
      };

      if (localStorage.getItem('isbusr') == 'true') {
        obj.agc = profile.agc;
        obj.subid = state.agc;
      } else {
        obj.agc = state.agc;
      }
      obj.cn = profile.fn ? profile.fn : profile.un;
      dispatch(manageTeamDucks.addTeammate(obj));
      changeNameValue({ isAddModal: false });
    }
  }

  const handleDelTeamMember = () => {
    const obj = {
      id: state.selectedId
    }
    dispatch(manageTeamDucks.deleteTeamMember(obj));
    changeNameValue({ isDeleteModal: false });
  }

  const handleUpdTeamMember = (type) => {
    if (type === 'password') {
      if (validator.errorMessages.npass !== null || validator.errorMessages.cpass !== null) {
        validator.showMessages();
        validator.helpers.forceUpdateIfNeeded();
        changeNameValue({ 'errMessage': validator.errorMessages });
        validator.errorMessages.npass !== null && validator.errorMessages.cpass !== null ? changeNameValue({ errMessage: { ...state.errMessage, npass: 'The new password field is required.', cpass: 'The Confirm password field is required.' } }) : validator.errorMessages.npass !== null ?
          changeNameValue({ errMessage: { ...state.errMessage, npass: 'The new password field is required.', cpass: null } }) : validator.errorMessages.cpass !== null ?
            changeNameValue({ errMessage: { ...state.errMessage, npass: null, cpass: 'The Confirm password field is required.' } }) :
            changeNameValue({ errMessage: {} });
      }
      else {
        changeNameValue({ errMessage: {} });
        if (state.npass !== state.cpass) {
          return dispatch(toastify({ type: 'error', msg: "Password not match" }));
        }
        let pass = encrypt(state.cpass);
        state.cpass = pass;
        const obj = {
          pd: state.cpass,
          _id: state.selectedId
        }
        dispatch(manageTeamDucks.addTeammate(obj));
        changeNameValue({ isUpdateModal: false, npass: '', cpass: '' });

      }
    }
    else {
      if (validator.errorMessages.selectedfn !== null || validator.errorMessages.selectedri !== null) {
        validator.showMessages();
        validator.helpers.forceUpdateIfNeeded();
        changeNameValue({ 'errMessage': validator.errorMessages });
        validator.errorMessages.selectedfn !== null && validator.errorMessages.selectedri !== null ? changeNameValue({ errMessage: { ...state.errMessage, selectedfn: 'The full name field is required.', selectedri: 'please select role.' } }) :
          validator.errorMessages.selectedfn !== null ?
            changeNameValue({ errMessage: { ...state.errMessage, selectedfn: 'The full name field is required.', selectedri: null } }) :
            validator.errorMessages.selectedri !== null ?
              changeNameValue({ errMessage: { ...state.errMessage, selectedfn: null, selectedri: 'please select role.' } }) :
              changeNameValue({ errMessage: {} });
      }
      else {
        const obj = {
          fn: state.selectedfn,
          ri: state.selectedri,
          _id: state.selectedId
        }
        dispatch(manageTeamDucks.addTeammate(obj));
        changeNameValue({ isUpdateModal: false, errMessage: {} });
      }
    }
  }

  // table tab select
  const tabs = state?.statusTabs?.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
    onAction: () => queStatusClick(item.value)
  }));

  const queStatusClick = useCallback((typ) => {
    let stateObj = {
      queStatus: typ,
      paggiActive: 1
    }
    setState((prevState) => ({ ...prevState, ...stateObj }));
  }, [state.queStatus]);

  //get notification type
  const getNotifType = (typ) => {
    let _ntt = typ;
    if (typ == 'signup recent') { _ntt = 'conversion'; }
    else if (typ == 'live visitor') { _ntt = 'live visits'; }
    else if (typ == 'recent view bulk') { _ntt = 'recently viewed count'; }
    else if (typ == 'signup bulk') { _ntt = 'conversion count'; }
    else if (typ == 'purchase recent') { _ntt = 'purchased'; }
    else if (typ == 'purchase bulk') { _ntt = 'purchase count'; }
    else if (typ == 'review recent') { _ntt = 'review'; }
    else if (typ == 'review bulk') { _ntt = 'review count'; }
    else if (typ == 'video subscriber') { _ntt = 'youtube video'; }
    else if (typ == 'title bar') { _ntt = 'blinking tab'; }
    else if (typ == 'video') { _ntt = 'video popup'; }
    return _ntt;
  }

  const filters = [
    {
      key: 'selectedDomain',
      label: (state.selectedDomain !== '' ? `Domain Name: ${state.selectedDomain}` : "`Domain Name"),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
      filter: (
        <div>
          <Box>
            <OptionList
              onChange={(e) => changeNameValue({ 'selectedDomain': e[0] })}
              options={state.domainOptions}
              selected={state.selectedDomain}
            />
          </Box>
        </div>
      )
    }
  ];

  useEffect(() => {
    if (state.selectedDomain !== '') {
      if (state.selectedDomain !== 'All') {
        const filterdata = getUserNotifList && getUserNotifList.filter((elm) => elm.ddn[0]?.ul == state.selectedDomain)
        changeNameValue({ getUserNotifList: filterdata })
      }
      else {
        changeNameValue({ getUserNotifList: getUserNotifList });
      }
    }
    else {
      changeNameValue({ getUserNotifList: getUserNotifList });
    }
  }, [state.selectedDomain]);

  const handleAssignBtn = (data) => {
    const obj = {
      ntflst: data,
      id: state.selectedId
    };
    dispatch(manageTeamDucks.updTeamMember(obj));
  }
  return (
    <>
      {checkPermission('manage_team', 'isview', profile) &&
        <ManageTeamCard
          state={state}
          profile={profile}
          validator={validator}
          getUserNotifList={getUserNotifList}
          changeNameValue={changeNameValue}
          handleAddTeam={handleAddTeam}
          handleAddClikBtnEvent={handleAddClikBtnEvent}
          handleDelTeamMember={handleDelTeamMember}
          handleUpdTeamMember={handleUpdTeamMember}
          handleAssignBtn={handleAssignBtn}
          tabs={tabs}
          filters={filters}
          getNotifType={getNotifType}
        />
      }
    </>
  );
};

export default ManageTeam;
