import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const whitelabelLogin = createSlice({
  name: 'whitelabelLogin',
  initialState,
  reducers: {
    getBrand: (state, action) => { },
    getBrandSuccess: (state, action) => {
      return { ...state, brand: action.payload };
    },
    brandLogin: (state, action) => { },
  }
});

export const {
  getBrand,
  getBrandSuccess,
  brandLogin
} = whitelabelLogin.actions;

export default whitelabelLogin.reducer;
