import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/dataPage';
import { toastify } from '../ducks/toast';

function* getDataPage(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, "/getSignUpData", action.payload);
    if (res.status === "success") {
      yield put(actions.getDataPageSuccess(res.data));
    } else {
      yield put(toastify({ type: "error", msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

function* getUserInte(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, "/getUserInte", action.payload);
    if (res.status === "success") {
      yield put(actions.getUserInteSuccess(res.data));
    } else {
      yield put(toastify({ type: "error", msg: 'Failed to get user integration' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

function* delSignupData(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, "/delSignupData?&q=" + encodeURIComponent(action.payload));
    if (res.status === "success") {
      yield put(toastify({ type: "success", msg: res.m }));
      yield put(actions.getDataPageSuccess(res.data));
    } else {
      yield put(toastify({ type: "error", msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

function* deleteBulkSignUp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, "/deleteBulkSignUp", action.payload);
    if (res.status === "success") {
      yield put(toastify({ type: "success", msg: res.m }));
      yield put(actions.getDataPageSuccess(res.data));
    } else {
      yield put(toastify({ type: "error", msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

function* getNotifForSignup() {
  try {
    yield put(load());
    const res = yield call(api.GET, "/gndfs");
    if (res.status === "success") {
      yield put(actions.gndfsSuccess(res.data));
    } else {
      yield put(toastify({ type: "error", msg: 'Failed to get' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

function* updateSignupData(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, "/updateSignupData", action.payload);
    if (res.status === "success") {
      yield put(toastify({ type: "success", msg: res.m }));
    } else {
      yield put(toastify({ type: "error", msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

//check password
function* checkPass(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, "/checkPass", action.payload);
    if (res.status === "success") {
      yield put(actions.checkPassSuccess(res.data));
    } else {
      yield put(toastify({ type: "error", msg: "Failed to check user password" }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

//dawnload csv
function* getDataForCSV(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, "/getDataForCSV", action.payload);
    if (res.status === "success") {
      window.location.href = res.data;
    } else {
      yield put(toastify({ type: "error", msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: "error", msg: "Something went wrong while doing. Please try again.", }));
  }
}

export function* watchGetDataPage() {
  yield takeLatest(actions.getDataPage.type, getDataPage);
  yield takeLatest(actions.getUserInte.type, getUserInte);
  yield takeLatest(actions.delSignupData.type, delSignupData);
  yield takeLatest(actions.deleteBulkSignUp.type, deleteBulkSignUp);
  yield takeLatest(actions.getNotifForSignup.type, getNotifForSignup);
  yield takeLatest(actions.updateSignupData.type, updateSignupData);
  yield takeLatest(actions.checkPass.type, checkPass);
  yield takeLatest(actions.getDataForCSV.type, getDataForCSV);
}

export default function* rootSaga() {
  yield fork(watchGetDataPage);
}
