import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/pricingPlan';
import { toastify } from '../ducks/toast';
import { getProfile } from '../ducks/auth';

function* getPlan() {
  try {
    yield put(load());
    let res;
    if (localStorage.getItem('pvd') === 'wix' || localStorage.getItem('pvd') === 'duda') {
      res = yield call(api.GET, '/getPlans?fm=wix');
    }
    else {
      res = yield call(api.GET, '/getPlans');
    }
    if (res.status === 'success') {
      yield put(actions.getPlanSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getAddon() {
  try {
    yield put(load());
    let res;
    if (localStorage.getItem('utyp') === 'appsumo')
      res = yield call(api.GET, '/getAddon?app=appsumo');
    else
      res = yield call(api.GET, '/getAddon');

    if (res.status === 'success') {
      yield put(actions.getAddonSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createChargeBeeForm(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createChargeBeeForm', action.payload);
    if (res.status === 'success') {
      yield put(actions.createChargeBeeFormSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* chargbeePayment(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/chargbeePayment', action.payload);
    if (res.status === 'success') {
      yield put(getProfile());
      yield put(actions.chargbeePaymantSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createChargeBeeAddonForm(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createChargeBeeAddonForm', action.payload);
    if (res.status === 'success') {
      yield put(actions.createChargeBeeAddonFormSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* chargbeeAddonPayment(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/chargbeeAddonPayment', action.payload);
    if (res.status === 'success') {
      yield put(getProfile());
      yield put(actions.chargbeeAddonPaymentSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* cancelSubscription(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/cancelSubscription', action.payload);
    if (res.status === 'success') {
      yield put(getProfile());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//get stripe payment link
function* createStripeLink(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createStripeLink', action.payload);
    if (res.status === 'success') {
      // yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.createStripeLinkSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchCreateStripeLink() {
  yield takeLatest(actions.createStripeLink.type, createStripeLink);
}

export function* watchGetPlan() {
  yield takeLatest(actions.getPlan.type, getPlan);
}

export function* watchGetAddon() {
  yield takeLatest(actions.getAddon.type, getAddon);
}

export function* watchCreateChargeBeeForm() {
  yield takeLatest(actions.createChargeBeeForm.type, createChargeBeeForm);
}

export function* watchChargbeePayment() {
  yield takeLatest(actions.chargbeePayment.type, chargbeePayment);
}

export function* watchCreateChargeBeeAddonForm() {
  yield takeLatest(actions.createChargeBeeAddonForm.type, createChargeBeeAddonForm);
}

export function* watchChargbeeAddonPayment() {
  yield takeLatest(actions.chargbeeAddonPayment.type, chargbeeAddonPayment);
}

export function* watchCancelSubscription() {
  yield takeLatest(actions.cancelSubscription.type, cancelSubscription);
}

export default function* rootSaga() {
  yield fork(watchGetPlan);
  yield fork(watchGetAddon);
  yield fork(watchCreateChargeBeeForm);
  yield fork(watchChargbeePayment);
  yield fork(watchCreateChargeBeeAddonForm);
  yield fork(watchChargbeeAddonPayment);
  yield fork(watchCancelSubscription);
  yield fork(watchCreateStripeLink);
}