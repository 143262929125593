import React from 'react';
import { BlockStack, Box, Card, IndexTable, InlineStack, Layout, List, Page, Text, Tooltip } from '@shopify/polaris';
import moment from 'moment';

function DetailCard({ props }) {
  const { state, handleBackToDetail } = props;

  let defaultNotif;
  defaultNotif = JSON.stringify(state.objDetailNotif);
  defaultNotif = JSON.parse(defaultNotif);
  let arrMobPositon = [];

  let arrAnimation = [
    { value: 'fade', label: 'Fade' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'zoom_in_down', label: 'Zoom in down' },
    { value: 'slide', label: 'Slide' },
    { value: 'horizonatal_slide', label: 'Slide horizontal' },
    { value: 'side_flip_in', label: 'Slide flip in' },
    { value: 'side_flip_back', label: 'Slide flip back' },
    { value: 'flip', label: 'Flip' },
    { value: 'up_flip', label: 'Flip up' },
    { value: 'up_flip', label: 'Flip back' },
  ];

  let arrPosition = [
    { value: 'left_top', label: 'Left top' },
    { value: 'center_top', label: 'Center top' },
    { value: 'right_top', label: 'Right top' },
    { value: 'left_center', label: 'Left center' },
    { value: 'right_center', label: 'Right center' },
    { value: 'left_bottom', label: 'Left bottom' },
    { value: 'center_bottom', label: 'Center bottom' },
    { value: 'right_bottom', label: 'Right bottom' },
    { value: 'center', label: 'Center' },
    { value: 'banner_top', label: 'Top' },
    { value: 'banner_center', label: 'Center' },
    { value: 'banner_bottom', label: 'Bottom' }
  ];

  state.objDetailNotif && state.objDetailNotif.vrnt && state.objDetailNotif.vrnt.filter(x => {
    if (x.ia === true) {
      defaultNotif.cnf = x.cnf;
      defaultNotif.intd = x.intd;
      defaultNotif.dsnt = x.dsnt;
      defaultNotif.dlynt = x.dlynt;
      defaultNotif.rndnt = x.rndnt;
      defaultNotif.mnbc = x.mnbc;
      defaultNotif.isnutd = x.isnutd;
    }
  });

  if (defaultNotif.nt === 'live_visitor' ||
    defaultNotif.nt === 'purchase_recent' ||
    defaultNotif.nt === 'signup_recent' ||
    defaultNotif.nt === 'single_visits' ||
    defaultNotif.nt === 'review_recent' ||
    defaultNotif.nt === 'purchase_bulk' ||
    defaultNotif.nt === 'signup_bulk' ||
    defaultNotif.nt === 'review_bulk' ||
    defaultNotif.nt === 'recent_view_bulk' ||
    defaultNotif.nt === 'channel_subscriber' ||
    defaultNotif.nt === 'video_subscriber' ||
    defaultNotif.nt === 'custom') {
    arrMobPositon = [
      { value: 'left_top', label: 'Top' },
      { value: 'left_center', label: 'Center' },
      { value: 'left_bottom', label: 'Bottom' }
    ];
  } else {
    arrMobPositon = [
      { value: 'left_top', label: 'Left top' },
      { value: 'center_top', label: 'Center top' },
      { value: 'right_top', label: 'Right top' },
      { value: 'left_center', label: 'Left center' },
      { value: 'center', label: 'Center' },
      { value: 'right_center', label: 'Right center' },
      { value: 'left_bottom', label: 'Left bottom' },
      { value: 'center_bottom', label: 'Center bottom' },
      { value: 'right_bottom', label: 'Right bottom' }
    ];
  }

  let isSocialNotif = false;
  //is notification type
  if (defaultNotif.nt === 'live_visitor' ||
    defaultNotif.nt === 'purchase_recent' ||
    defaultNotif.nt === 'signup_recent' ||
    defaultNotif.nt === 'single_visits' ||
    defaultNotif.nt === 'review_recent' ||
    defaultNotif.nt === 'purchase_bulk' ||
    defaultNotif.nt === 'signup_bulk' ||
    defaultNotif.nt === 'review_bulk' ||
    defaultNotif.nt === 'recent_view_bulk' ||
    defaultNotif.nt === 'channel_subscriber' ||
    defaultNotif.nt === 'video_subscriber' ||
    defaultNotif.nt === 'limited_stock') {
    isSocialNotif = true;
  }

  let animation = arrAnimation.filter(x => x.value === defaultNotif.cnf.as)[0];
  let mobAnimation = arrAnimation.filter(x => x.value === defaultNotif.cnf.mas)[0];
  let position = arrPosition.filter(x => x.value === defaultNotif.cnf.dp)[0];
  let mobPositon = arrMobPositon.filter(x => x.value === defaultNotif.cnf.mdp)[0];

  const changeWord = (val) => {
    return (val.charAt(0).toUpperCase() + val.slice(1));
  }

  return (
    <Page
      title={`Notification setting for ${defaultNotif.nn}`}
      backAction={{ onAction: () => handleBackToDetail() }}
    >
      <Box paddingBlock={400}>
        <BlockStack gap={400}>
          {(defaultNotif.nt === 'signup_recent' || defaultNotif.nt === 'signup_bulk' ||
            defaultNotif.nt === 'purchase_recent' || defaultNotif.nt === 'purchase_bulk' ||
            defaultNotif.nt === 'review_recent' || defaultNotif.nt === 'review_bulk' ||
            defaultNotif.nt === 'single_visits') &&
            <div className='border_btm'>
              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Text variant="headingMd" as="h2" fontWeight='semibold'>Latest data</Text>
                    <Text variant='headingSm' as="p" fontWeight='regular'>Display setting details</Text>
                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <Card>
                    {state.recentData && state.recentData.length > 0 ?
                      <Card padding={0}>
                        <IndexTable
                          itemCount={state.recentData ? state.recentData.length : 0}
                          selectable={false}
                          headings={[
                            { title: 'Source & connection name' },
                            { title: 'Name' },
                            { title: 'Date' },
                          ]}>
                          {state.recentData.map((val, key) => (
                            <IndexTable.Row
                              id={key}
                              key={key}
                              position={key}>
                              <IndexTable.Cell>
                                <BlockStack>
                                  <Text variant="headingSm" as="h6">{changeWord(val.fa)}</Text>
                                  <Text>  {val.rmk ? (val.rmk) : ''}</Text>
                                </BlockStack>
                              </IndexTable.Cell>
                              <IndexTable.Cell>{val.fn} {val.ln}</IndexTable.Cell>
                              <IndexTable.Cell>{val.insdt ? moment(new Date(val.insdt)).format('MMM-DD-YYYY hh:mm:ss a') : ''}</IndexTable.Cell>
                            </IndexTable.Row>
                          ))}
                        </IndexTable>
                      </Card>
                      :
                      <Box>
                        <Text variant='headingSm' as='p' fontWeight='regular'>No record found.</Text>
                      </Box>
                    }
                  </Card>
                </Layout.Section>
              </Layout>
            </div>
          }

          <div className='border_btm'>
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Display rule</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>Display setting details</Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <BlockStack gap={400}>
                    {defaultNotif.dt != undefined &&
                      <Box>
                        <Text variant='headingSm' as='p' fontWeight='semibold'>Display notification on {defaultNotif.dt == '0' ? 'All pages' : ''}</Text>
                        {defaultNotif.dt == '1' &&
                          <Box paddingBlockStart={100}>
                            {defaultNotif.dtu.map((val, key) => {
                              return (
                                <Box key={key} paddingInlineStart={400}>
                                  <List type='bullet'>
                                    <InlineStack>
                                      <div className='detail_wdth'>
                                        <List.Item>
                                          {val.dt == 2 ? 'Contains ' :
                                            val.dt == 1 ? 'Equals ' :
                                              val.dt == 3 ? 'Regex ' :
                                                val.dt == 4 ? 'Does not equal ' :
                                                  val.dt == 5 ? 'Does not contain ' :
                                                    val.dt == 6 ? 'Does not Regex ' : ''}
                                        </List.Item>
                                      </div>
                                      <div className='detail_item_wdth' id='cursorPointer'>
                                        <Tooltip dismissOnMouseOut content={val.ul} width='wide'>
                                          <Text variant='headingSm' as="span" fontWeight='semibold'>{val.ul}</Text>
                                        </Tooltip>
                                      </div>
                                    </InlineStack>
                                  </List>
                                </Box>
                              );
                            })
                            }
                          </Box>
                        }
                      </Box>
                    }
                    <Box>
                      <Text variant='headingSm' as="p" fontWeight='semibold'>Display notification on below websites</Text>
                      <Box>
                        {defaultNotif.ddn.map((val, key) => {
                          return (
                            <Box key={key} paddingBlockStart={200} paddingInlineStart={400}>
                              <Text variant='headingSm' as="p" fontWeight='regular' tone='subdued'>{val.ul}</Text>
                            </Box>
                          );
                        })
                        }
                      </Box>
                    </Box>
                    {(defaultNotif.irtnv || defaultNotif.iutmd || defaultNotif.ibcks || defaultNotif.irfvf) &&
                      <Box>
                        <Text variant='headingSm' as="p" fontWeight='regular'>Segmentation</Text>
                        <Box paddingBlockStart={100} paddingInlineStart={400}>
                          <List type='bullet'>
                            {defaultNotif.irtnv && defaultNotif.rtnv != undefined &&
                              <Box>
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Returning or new visitors</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.rtnv == 0 ? 'All' : defaultNotif.rtnv == 1 ? 'New Visitor' : defaultNotif.rtnv == 2 ? 'Returning Visitor' : ''} width='wide'>
                                      <Text variant='headingSm' as="p" fontWeight='semibold'>
                                        {defaultNotif.rtnv == 0 ? 'All' :
                                          defaultNotif.rtnv == 1 ? 'New Visitor' :
                                            defaultNotif.rtnv == 2 ? 'Returning Visitor' : ''
                                        }
                                      </Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              </Box>
                            }
                            {defaultNotif.iutmd && defaultNotif.utmd.length > 0 &&
                              <Box>
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>UTM based display</List.Item>
                                  </div>
                                  <Text variant='headingSm' as="p" fontWeight='semibold'>
                                    {defaultNotif.utmd.map((val, key) => {
                                      return (
                                        <div key={key} className='detail_item_wdth'>
                                          <Text variant='headingSm' as="p" fontWeight='regular'>
                                            {val.cnd == 0 ? 'Contains' : val.cnd == 1 ? 'Exclude' : ''} - {val.txt}
                                          </Text>
                                        </div>
                                      );
                                    })}
                                  </Text>
                                </InlineStack>
                              </Box>
                            }
                            {defaultNotif.ibcks && defaultNotif.bcks && defaultNotif.bcks.length > 0 &&
                              <Box>
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Show if cookies name available</List.Item>
                                  </div>
                                  <Text variant='headingSm' as="p" fontWeight='semibold'>
                                    {defaultNotif.bcks.map((val, key) => {
                                      return (
                                        <div key={key} className='detail_item_wdth'>
                                          <Text variant='headingSm' as="p" fontWeight='regular'>
                                            {val.cnd == 0 ? 'Exact' : val.cnd == 1 ? 'Not Exact' : ''} - {val.txt}
                                          </Text>
                                        </div>
                                      );
                                    })}
                                  </Text>
                                </InlineStack>
                              </Box>
                            }
                            {defaultNotif.irfvf && defaultNotif.rfvf && defaultNotif.rfvf.length > 0 &&
                              <Box>
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Referrer site</List.Item>
                                  </div>
                                  <Text variant='headingSm' as="p" fontWeight='semibold'>
                                    {defaultNotif.rfvf.map((val, key) => {
                                      return (
                                        <div key={key} className='detail_item_wdth'>
                                          <Text variant='headingSm' as="p" fontWeight='regular'>
                                            {val.cnd == 0 ? 'Exact' : val.cnd == 1 ? 'Not Exact' : val.cnd == 2 ? 'Contains' : val.cnd == 3 ? 'Not Contains' : ''} - {val.txt}
                                          </Text>
                                        </div>
                                      );
                                    })}
                                  </Text>
                                </InlineStack>
                              </Box>
                            }
                          </List>
                        </Box>
                      </Box>
                    }
                  </BlockStack>
                </Card>
              </Layout.Section>
            </Layout>
          </div>

          {defaultNotif.nt !== 'title_bar' &&
            <div className='border_btm'>
              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Text variant="headingMd" as="h2" fontWeight='semibold'>Setting</Text>
                    <Text variant='headingSm' as="p" fontWeight='regular'>Display setting details</Text>
                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <Card>
                    <BlockStack gap={400}>
                      <Box>
                        <Text variant='headingSm' as="p" fontWeight='semibold'>Position & animation</Text>
                        <Box paddingBlockStart={100} paddingInlineStart={400}>
                          <List type="bullet">
                            {animation &&
                              <InlineStack>
                                <div className='detail_wdth'>
                                  <List.Item>Animation style in desktop</List.Item>
                                </div>
                                <div className='detail_item_wdth' id='cursorPointer'>
                                  <Tooltip dismissOnMouseOut content={animation.label} width='wide'>
                                    <Text variant='headingSm' as="span" fontWeight='semibold'>{animation.label}</Text>
                                  </Tooltip>
                                </div>
                              </InlineStack>
                            }
                            {position &&
                              <InlineStack>
                                <div className='detail_wdth'>
                                  <List.Item>Display position in desktop</List.Item>
                                </div>
                                <div className='detail_item_wdth' id='cursorPointer'>
                                  <Tooltip dismissOnMouseOut content={position.label} width='wide'>
                                    <Text variant='headingSm' as="span" fontWeight='semibold'>{position.label}</Text>
                                  </Tooltip>
                                </div>
                              </InlineStack>
                            }
                            <InlineStack>
                              <div className='detail_wdth'>
                                <List.Item>Show in mobile</List.Item>
                              </div>
                              <div className='detail_item_wdth' id='cursorPointer'>
                                <Tooltip dismissOnMouseOut content={defaultNotif.hm ? 'OFF' : 'ON'} width='wide'>
                                  <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.hm ? 'OFF' : 'ON'}</Text>
                                </Tooltip>
                              </div>
                            </InlineStack>
                            {mobAnimation &&
                              <InlineStack>
                                <div className='detail_wdth'>
                                  <List.Item>Animation style in mobile</List.Item>
                                </div>
                                <div className='detail_item_wdth' id='cursorPointer'>
                                  <Tooltip dismissOnMouseOut content={mobAnimation.label} width='wide'>
                                    <Text variant='headingSm' as="span" fontWeight='semibold'>{mobAnimation.label}</Text>
                                  </Tooltip>
                                </div>
                              </InlineStack>
                            }
                            {mobPositon &&
                              <InlineStack>
                                <div className='detail_wdth'>
                                  <List.Item>Display position in mobile</List.Item>
                                </div>
                                <div className='detail_item_wdth' id='cursorPointer'>
                                  <Tooltip dismissOnMouseOut content={mobPositon.label} width='wide'>
                                    <Text variant='headingSm' as="span" fontWeight='semibold'>{mobPositon.label}</Text>
                                  </Tooltip>
                                </div>
                              </InlineStack>
                            }
                          </List>
                        </Box>
                      </Box>
                      {isSocialNotif == true && defaultNotif.nt !== 'channel_subscriber' && defaultNotif.nt !== 'video_subscriber' && defaultNotif.nt !== 'limited_stock' &&
                        <Box>
                          <Text variant='headingSm' as="p" fontWeight='semibold'>Data setting</Text>
                          <Box paddingBlockStart={100} paddingInlineStart={400}>
                            <List type="bullet">
                              {defaultNotif.mnbc != undefined && defaultNotif.ntt === 'single_visits' &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Hide if visitors are less than</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.mnbc} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.mnbc}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.lcpt != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>{defaultNotif.ntt !== 'single_visits' ? 'Show page specific visitors count' : 'Show page specific visitors'}</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.lcpt ? 'ON' : 'OFF'} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.lcpt ? 'ON' : 'OFF'}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.isnutd != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Skip seen data</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.isnutd ? 'ON' : 'OFF'} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.isnutd ? 'ON' : 'OFF'}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {(defaultNotif.tr != undefined || defaultNotif.tu != undefined) && defaultNotif.nt !== 'review_recent' &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Display last data</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={`${defaultNotif.tr} ${defaultNotif.tu}`} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.tr} {defaultNotif.tu}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.ld != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Display last record</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.ld} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.ld}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.snutd != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Skip seen notification for</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.snutd} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.snutd}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.isdon != undefined && defaultNotif.ntt === 'signup_recent' &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Skip own data in notification</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.isdon ? 'ON' : 'OFF'} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.isdon ? 'ON' : 'OFF'}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.dmnt != undefined && (defaultNotif.ntt === 'signup_recent' || defaultNotif.nt === 'single_visits' || defaultNotif.ntt === 'purchase_recent' || defaultNotif.ntt === 'review_recent') &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Show maximum notification per page</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.dmnt == 0 ? 'All' : defaultNotif.dmnt} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.dmnt == 0 ? 'All' : defaultNotif.dmnt}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                            </List>
                          </Box>
                        </Box>
                      }
                      {(isSocialNotif == true || ((defaultNotif.nt === 'custom' || defaultNotif.nt === 'social_media_followers') && defaultNotif.icstlp == true)) && defaultNotif.nt !== 'limited_stock' &&
                        <Box>
                          <Text variant='headingSm' as="p" fontWeight='semibold'>Timer</Text>
                          <Box paddingBlockStart={100} paddingInlineStart={400}>
                            <List type="bullet">
                              {defaultNotif.intd != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Initial delay time</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={`${defaultNotif.intd} second`} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.intd} second</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.dsnt != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Display notification time</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={`${defaultNotif.dsnt} second`} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.dsnt} second</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.dlynt != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Delay between notification time</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={`${defaultNotif.dlynt} second`} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.dlynt} second</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                              {defaultNotif.rndnt != undefined &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Randomize delay</List.Item>
                                  </div>
                                  <div className='detail_item_wdth' id='cursorPointer'>
                                    <Tooltip dismissOnMouseOut content={defaultNotif.rndnt ? 'ON' : 'OFF'} width='wide'>
                                      <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.rndnt ? 'ON' : 'OFF'}</Text>
                                    </Tooltip>
                                  </div>
                                </InlineStack>
                              }
                            </List>
                          </Box>
                        </Box>
                      }
                      {((defaultNotif.nt === 'custom' ||
                        defaultNotif.nt === 'video' ||
                        defaultNotif.nt === 'call_to_action' ||
                        defaultNotif.nt === 'all_in_one' ||
                        defaultNotif.nt === 'social_media_followers' ||
                        defaultNotif.nt === 'whatsapp_chat' ||
                        defaultNotif.nt === 'review_feed' ||
                        defaultNotif.nt === 'collector') &&
                        defaultNotif.icstlp !== true) &&
                        <Box>
                          <Text variant='headingSm' as="p" fontWeight='semibold'>Behaviour rule</Text>
                          <Box paddingBlockStart={100} paddingInlineStart={400}>
                            <List type="bullet">
                              <InlineStack>
                                <div className='detail_wdth'>
                                  <List.Item>Display rule</List.Item>
                                </div>
                                <div className='detail_item_wdth'>
                                  <Text variant='headingSm' as="span" fontWeight='semibold'>
                                    {defaultNotif.cnf.dtrg == 1 ? 'Exit intent' :
                                      defaultNotif.cnf.dtrg == 2 ? `${'Show after ' + defaultNotif.cnf.aft + ' second'}` :
                                        defaultNotif.cnf.dtrg == 3 ? `${' Show after ' + defaultNotif.cnf.sprc + '% scroll'}` : ''}
                                  </Text>
                                </div>
                              </InlineStack>
                              {defaultNotif.cnf.ihdwn === true &&
                                <InlineStack>
                                  <div className='detail_wdth'>
                                    <List.Item>Hide rule</List.Item>
                                  </div>
                                  <div className='detail_item_wdth'>
                                    <Text variant='headingSm' as="span" fontWeight='semibold'>Hide after {defaultNotif.cnf.hdwn} second</Text>
                                  </div>
                                </InlineStack>
                              }
                            </List>
                          </Box>
                        </Box>
                      }
                    </BlockStack>
                  </Card>
                </Layout.Section>
              </Layout>
            </div>
          }

          {defaultNotif.nt !== 'social_media_followers' &&
            defaultNotif.nt !== 'limited_stock' &&
            defaultNotif.nt !== 'title_bar' &&
            defaultNotif.nt !== 'custom' &&
            defaultNotif.nt !== 'event_banner' &&
            defaultNotif.nt !== 'video' &&
            defaultNotif.nt !== 'call_to_action' &&
            defaultNotif.nt !== 'all_in_one' &&
            defaultNotif.nt !== 'whatsapp_chat' &&
            defaultNotif.nt !== 'collector' &&
            defaultNotif.nt !== 'single_visits' &&
            ((defaultNotif.iadvfcnd == true && defaultNotif.advfcnd.length > 0) || defaultNotif.dst != undefined || (defaultNotif.dscu && defaultNotif.dscu.length > 0) || (state.arrInte && state.arrInte.length > 0)) &&
            <div className='border_btm'>
              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Text variant="headingMd" as="h2" fontWeight='semibold'>Capture rule</Text>
                    <Text variant='headingSm' as="p" fontWeight='regular'>Display setting details</Text>
                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <Card>
                    <BlockStack gap={400}>
                      {defaultNotif.dst != undefined && (defaultNotif.nt === 'signup_recent' || defaultNotif.nt === 'signup_bulk' || defaultNotif.nt === 'single_visits') &&
                        <Box>
                          <Text variant="headingSm" as="p" fontWeight='semibold'>
                            {defaultNotif.dst == '0' ? 'Capture form submission events from all pages' : 'Capture form submission events from below URL'}
                          </Text>
                          {defaultNotif.dst == '1' && defaultNotif.dtscu.length > 0 &&
                            <Box>
                              {defaultNotif.dtscu.map((val, key) => {
                                return (
                                  <Box key={key} paddingBlockStart={100} paddingInlineStart={400}>
                                    <List type='bullet'>
                                      <InlineStack>
                                        <div className='detail_wdth'>
                                          <List.Item>
                                            {val.dst == 1 ? 'Equals ' :
                                              val.dst == 2 ? 'Contains ' :
                                                val.dst == 3 ? 'Regex ' :
                                                  val.dst == 4 ? 'Does not equal ' :
                                                    val.dst == 5 ? 'Does not contain ' :
                                                      val.dst == 6 ? 'Does not Regex ' : ''}
                                          </List.Item>
                                        </div>
                                        <div className='detail_item_wdth' id='cursorPointer'>
                                          <Tooltip dismissOnMouseOut content={val.ul} width='wide'>
                                            <Text variant='headingSm' as="span" fontWeight='semibold'>{val.ul}</Text>
                                          </Tooltip>
                                        </div>
                                      </InlineStack>
                                    </List>
                                  </Box>
                                );
                              })}
                            </Box>
                          }
                        </Box>
                      }

                      {state.arrInte && state.arrInte.length > 0 &&
                        <Box>
                          <Text variant="headingSm" as="p" fontWeight='semibold'>Integration</Text>
                          <Box>
                            {state.arrInte.map((val, key) => {
                              return (
                                <Box key={key} paddingBlockStart={100} paddingInlineStart={400}>
                                  <List type='bullet'>
                                    <InlineStack>
                                      <div className='detail_wdth'>
                                        <List.Item>{val.iin}</List.Item>
                                      </div>
                                      <div className='detail_item_wdth' id='cursorPointer'>
                                        <Tooltip dismissOnMouseOut content={val.rmk} width='wide'>
                                          <Text variant='headingSm' as="span" fontWeight='semibold'>{val.rmk}</Text>
                                        </Tooltip>
                                      </div>
                                    </InlineStack>
                                  </List>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      }

                      {defaultNotif.iadvfcnd && defaultNotif.advfcnd && defaultNotif.advfcnd.length > 0 &&
                        <Box>
                          <Text variant="headingSm" as="p" fontWeight='semibold'>Add conditions to show or hide data in notification</Text>
                          <Box>
                            {defaultNotif.advfcnd.map((val, key) => {
                              return (
                                <Box key={key} paddingBlockStart={100} paddingInlineStart={400}>
                                  <List type='bullet'>
                                    <InlineStack>
                                      <div className='detail_wdth'>
                                        <List.Item>
                                          If the variable is {
                                            val.fld == 'fn' ? '{{F_NAME}}' :
                                              val.fld == 'ln' ? '{{L_NAME}}' :
                                                val.fld == 'un' ? '{{USERNAME}}' :
                                                  val.fld == 'ct' ? '{{CITY}}' :
                                                    val.fld == 'cn' ? '{{COUNTRY}}' :
                                                      val.fld == 'pn' ? '{{PRODUCT_NAME}}' : ''
                                          } {val.fcnd == '2' ? 'Contains' : val.fcnd == '1' ? 'Equals' : ''} {val.ov}, Then {val.scnd == '2' ? 'Hide' : val.scnd == '1' ? 'Show' : ''} notification
                                        </List.Item>
                                      </div>
                                    </InlineStack>
                                  </List>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      }

                      {/* for live visitor and recent view bulk */}
                      {(defaultNotif.nt === 'live_visitor' || defaultNotif.nt === 'recent_view_bulk') &&
                        <Box>
                          <Text variant="headingSm" as="p" fontWeight='semibold'>Track live visitor data from below listed websites.</Text>
                          {defaultNotif.dscu.map((val, key) => {
                            return (
                              <Box key={key} paddingBlockStart={100} paddingInlineStart={400}>
                                <List type='bullet'>
                                  <InlineStack>
                                    <div className='detail_wdth'>
                                      <List.Item>{val.ul}</List.Item>
                                    </div>
                                  </InlineStack>
                                </List>
                              </Box>
                            );
                          })}
                        </Box>
                      }
                    </BlockStack>
                  </Card>
                </Layout.Section>
              </Layout>
            </div>
          }

          {(defaultNotif.cnf.cu != undefined || defaultNotif.cnf.nu != undefined || defaultNotif.cnf.bu != undefined) &&
            <div className='border_btm'>
              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Text variant="headingMd" as="h2" fontWeight='semibold'>Click</Text>
                    <Text variant='headingSm' as="p" fontWeight='regular'>Click for details</Text>
                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <Card>
                    <Box>
                      <Text variant="headingMd" as="h2" fontWeight='semibold'>URL</Text>
                      <Box paddingBlockStart={100} paddingInlineStart={400}>
                        <List type='bullet'>
                          {defaultNotif.cnf.cu &&
                            <InlineStack>
                              <div className='detail_wdth'>
                                <List.Item>Cta URL</List.Item>
                              </div>
                              <div className='detail_item_wdth' id='cursorPointer'>
                                <Tooltip dismissOnMouseOut content={defaultNotif.cnf.cu} width='wide'>
                                  <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.cnf.cu}</Text>
                                </Tooltip>
                              </div>
                            </InlineStack>
                          }
                          {defaultNotif.cnf.nu &&
                            <InlineStack>
                              <div className='detail_wdth'>
                                <List.Item>Notification click URL</List.Item>
                              </div>
                              <div className='detail_item_wdth' id='cursorPointer'>
                                <Tooltip dismissOnMouseOut content={defaultNotif.cnf.nu} width='wide'>
                                  <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.cnf.nu}</Text>
                                </Tooltip>
                              </div>
                            </InlineStack>
                          }
                          {defaultNotif.cnf.bu &&
                            <InlineStack>
                              <div className='detail_wdth'>
                                <List.Item>Verified label click URL</List.Item>
                              </div>
                              <div className='detail_item_wdth' id='cursorPointer'>
                                <Tooltip dismissOnMouseOut content={defaultNotif.cnf.bu} width='wide'>
                                  <Text variant='headingSm' as="span" fontWeight='semibold'>{defaultNotif.cnf.bu}</Text>
                                </Tooltip>
                              </div>
                            </InlineStack>
                          }
                        </List>
                      </Box>
                    </Box>
                  </Card>
                </Layout.Section>
              </Layout>
            </div>
          }
        </BlockStack>
      </Box>
    </Page>
  );
}

export default DetailCard;