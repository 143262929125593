import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    doLogin: (state, action) => { },
    doLoginSuccess: (state, action) => {
      return { ...state, auth: action.payload };
    },
    getProfile: (state, action) => { },
    getProfileSuccess: (state, action) => {
      return { ...state, profile: action.payload };
    },
    loginWithBGC: (state, action) => { },
    loginWithWix: (state, action) => { },
    loginWithThinkific: (state, action) => { },
    loginWithEcwid: (state, action) => { },
    loginWithDuda: (state, action) => { }
  }
});

export const {
  doLogin,
  doLoginSuccess,
  getProfile,
  getProfileSuccess,
  loginWithBGC,
  loginWithWix,
  loginWithThinkific,
  loginWithEcwid,
  loginWithDuda
} = auth.actions;

export default auth.reducer;