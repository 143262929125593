import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/rateUs';
import { toastify } from '../ducks/toast';
import { getProfile } from '../ducks/auth';

function* getRateUs(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getUserReward', action.payload);

    if (res.status === 'success') {
      yield put(actions.getRateUsSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}
function* addUserReward(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUserReward', action.payload);

    if (res.status === 'success') {
      yield put(actions.getRateUs());
      yield put(actions.addUserRewardSuccess(new Date().getTime()));
      yield put(getProfile());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetRateUs() {
  yield takeLatest(actions.getRateUs.type, getRateUs);
  yield takeLatest(actions.addUserReward.type, addUserReward);
}

export default function* rootSaga() {
  yield fork(watchGetRateUs);
}
