import React from 'react';
import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Divider, InlineGrid, InlineStack, Layout, List, Page, Popover, Select, SkeletonBodyText, SkeletonDisplayText, Text } from '@shopify/polaris';
import { plan_blackfriday_tag, checkCircle_icon, planTrue_icon } from '../../img';
import BillingSummery from './BillingSummery';
import AppsumoPlanCard from '../AppsumoPlan/AppsumoPlanCard';

function PricingPlanCard(props) {
  const { state, changeNameValue } = props;

  const tabs = [
    { tabName: 'month', label: 'Pay monthly' },
    // { tabName: 'year', label: 'Pay yearly (BLACKFRIDAY OFFER)' },
    { tabName: 'year', label: 'Pay yearly (4 Month free)' },
  ];

  const planDescList = [
    { icon: checkCircle_icon, label: '14 Day money back guarantee' },
    { icon: checkCircle_icon, label: 'Change or cancel the plan any time' }
  ]

  const basicIncludeList = [
    { icon: planTrue_icon, label: 'Premium templates' },
    { icon: planTrue_icon, label: 'Unlimited notifications & impressions' },
    { icon: planTrue_icon, label: 'Social proof & urgency widgets' },
    { icon: planTrue_icon, label: 'Multiple websites' },
    { icon: planTrue_icon, label: '200 + integrations' },
    { icon: planTrue_icon, label: 'Live chat support' },
    { icon: planTrue_icon, label: 'Reporting & settings' }
  ]

  const proIncludeList = [
    { icon: planTrue_icon, label: 'Branding control' },
    { icon: planTrue_icon, label: 'A/B testing & goal tracking' },
    { icon: planTrue_icon, label: 'Custom css' },
    { icon: planTrue_icon, label: '1 Team mate(staff)' }
  ]

  const growthIncludeList = [
    { icon: planTrue_icon, label: 'In-line social proof messaging' },
    { icon: planTrue_icon, label: 'Dedicated account manager' },
    { icon: planTrue_icon, label: 'Custom design & technical support' },
    { icon: planTrue_icon, label: '10 Team mates(staff)' }
  ]

  return (
    <div className='page-wrap page-plan-wrap'>
      <Box paddingBlockEnd={500}>
        <Page title={'Plan'}>
          <Card>
            <BlockStack gap={300}>
              <Text variant='bodyLg' as='h6' fontWeight='semibold'>Select a plan based on your monthly visitor traffic.</Text>
              <Box id='lll'>
                <InlineStack gap={200} wrap={true} blockAlign='center' id='hhh'>
                  {planDescList && planDescList.map((data, index) => (
                    <InlineStack key={index} gap={100} blockAlign='center'>
                      <img src={data.icon} alt='' />
                      <Text as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                    </InlineStack>
                  ))}
                </InlineStack>
              </Box>
            </BlockStack>
            <div id='plan_block'>
              <Box paddingBlockEnd={500} paddingBlockStart={400}>
                <Layout>
                  <Layout.Section variant='oneThird'>
                    <Box id='pln_basic_block' padding={400} borderRadius={300}>
                      <BlockStack gap={300}>
                        <Box>
                          <Badge tone="warning">Basic - only yearly available</Badge>
                        </Box>
                        <Text variant='bodySm' as='p' fontWeight='regular'>Best for who start building funnel & growing the traffic</Text>
                        <Divider borderWidth='050' />
                        {props.isLoading ?
                          <Box>
                            <Box width='30%' paddingBlockStart={300}>
                              <SkeletonBodyText lines={1} />
                            </Box>
                            <Box paddingBlock={400}>
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                            </Box>
                          </Box>
                          :
                          <Box>
                            <Box paddingBlockEnd={300}>
                              <InlineStack gap={100} blockAlign='center'>
                                <Text variant='headingLg' as='h3' fontWeight='semibold'>${state.basicPlanPrice ? state.basicPlanPrice : '-'}</Text>
                                <Text variant='bodyMd' as='p' fontWeight='regular'>per year</Text>
                                {/* {state?.selctdPlanId && state.basicPlan.id === state.selctdPlanId && (
                              <Badge tone="success">Selected</Badge>
                            )} */}
                              </InlineStack>
                            </Box>
                            <Box minHeight='50px'>
                              <Popover
                                fullWidth
                                activator={<Button fullWidth textAlign={'start'}>
                                  <Text variant='bodyMd' fontWeight='medium'>
                                    {state.basicPlanVisitor ? state.basicPlanVisitor : '-'} Visitors/month
                                  </Text>
                                </Button>}>
                              </Popover>
                            </Box>
                          </Box>
                        }
                        <Box>
                          {(state?.selctdPlanId && state.basicPlan.id === state.selctdPlanId) && (state.profile && state.profile.billing && state.profile.billing.icnc === false) ?
                            <Button id='primary_btn' fullWidth disabled>
                              <Text variant='bodyMd' as='p' fontWeight='semibold'>Current active plan</Text>
                            </Button>
                            :
                            <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('basic')} disabled={state.basicPlanPrice < state.selctdPlanPrice ? true : false}>
                              <Text variant='bodyMd' fontWeight='semibold'>{state.basicPlanPrice > state.selctdPlanPrice ? 'Upgrade yearly' : '7 Days free trial'}</Text>
                            </Button>
                          }
                        </Box>
                        <Box>
                          <BlockStack gap={100}>
                            <Text variant='bodyMd' fontWeight='semibold'>
                              Includes
                            </Text>
                            {basicIncludeList && basicIncludeList.map((data, index) => (
                              <InlineStack key={index} gap={100} blockAlign='center'>
                                <img src={data.icon} alt='' />
                                <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                              </InlineStack>
                            ))}
                          </BlockStack>
                        </Box>
                      </BlockStack>
                    </Box>
                  </Layout.Section>

                  <Layout.Section>
                    {/* BLACKFRIDAY */}
                    <Box paddingBlockStart={0}>
                      <InlineStack>
                        {/* <Text variant="headingSm" as="h6" fontWeight='bold'>
                          BLACKFRIDAY OFFER:
                        </Text>
                        <div className='grab_para'>
                          <Text variant="bodySm" as="p">
                            Grab 5 months FREE on PRO & GRO yearly plan and save 40%!
                          </Text>
                        </div> */}
                      </InlineStack>
                    </Box>
                    <Box background='bg-surface-tertiary' id='plan_pg_block' borderRadius={300} paddingInline={800} paddingBlockEnd={500}>
                      <InlineStack blockAlign='center' align='center'>
                        <Box background='bg-surface' borderRadius='300' padding={100} borderColor="border" borderWidth="025">
                          <ButtonGroup>
                            {tabs.map((data, index) => {
                              // const localStorageValue = localStorage.getItem('plv');
                              // const variant = localStorageValue ? (localStorageValue === data.tabName ? 'primary' : '') : (state.plv === data.tabName ? 'primary' : '');

                              return (
                                <Button
                                  key={index}
                                  variant={state.plv === data.tabName ? 'primary' : ''}
                                  onClick={() => props.handleTabChange(data)}
                                >
                                  <Text variant='bodyLg' as='p' fontWeight='regular'>
                                    {data.label}
                                  </Text>
                                </Button>
                              );
                            })}
                          </ButtonGroup>
                        </Box>
                      </InlineStack>
                      <Box paddingBlockStart={400}>
                        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={400} >
                          <div className='card'>
                            <Box background='bg-surface' padding={400} borderRadius='300' minHeight='429.99px'>
                              <BlockStack gap={300}>
                                <Box>
                                  <Badge tone="info">Pro</Badge>
                                </Box>
                                {/* <div className='plan-blackfriday-tag'>
                                  <img  src={plan_blackfriday_tag} alt="" />
                                </div>
                                <div class="ribbon">Save up to 40%
                                  <br />
                                  <span className='card_para'>
                                    Grab 5 months FREE
                                  </span>
                                </div> */}
                                <Text variant='bodySm' as='p' fontWeight='regular'>Best for coaching business, selling <br /> digital items & funnel builders</Text>
                                {/* <Text variant='bodySm' as='p' fontWeight='regular'>Best for coaching business, selling digital items & funnel builders</Text> */}
                                <Divider />
                                {props.isLoading ?
                                  <Box>
                                    <Box width='30%' paddingBlockStart={300}>
                                      <SkeletonBodyText lines={1} />
                                    </Box>
                                    <Box paddingBlock={400}>
                                      <SkeletonDisplayText size='medium' maxWidth='100%' />
                                    </Box>
                                  </Box>
                                  :
                                  <Box>
                                    <Box paddingBlockEnd={300}>
                                      <InlineStack gap={100} blockAlign='center'>
                                        <Text variant='headingLg' as='p' fontWeight='semibold'>${state.selctdProPlanPrice ? props.formmetNumber(state.selctdProPlanPrice) : '-'}</Text>
                                        <Text variant='headingXs' as='p' fontWeight='regular'>{state.plv === 'month' ? 'per month' : 'per year'}</Text>
                                        {/* <Text variant='headingXs' as='p' fontWeight='regular'>{localStorage.getItem('plv') === 'month' ? 'per month' : 'per year'}</Text> */}
                                        {/* {state?.selctdPlanId && state.proPlanList.find(plan => plan.cf_plan_quota === state.selctdProPlanVisitor)?.id === state.selctdPlanId && (
                                    <Badge tone="success">Selected</Badge>
                                  )} */}
                                      </InlineStack>
                                    </Box>
                                    <Box minHeight='50px'>
                                      {/* <Select
                                  options={state.proOptionList}
                                  onChange={(e) => { changeNameValue({ selctdProPlanVisitor: e }); localStorage.setItem('selctdProPlanVisitor', e); }}
                                  value={localStorage.getItem('selctdProPlanVisitor') ? localStorage.getItem('selctdProPlanVisitor') : state.selctdProPlanVisitor}
                                /> */}
                                      <Select
                                        options={state.proOptionList}
                                        onChange={(e) => changeNameValue({ selctdProPlanVisitor: e })}
                                        value={state.selctdProPlanVisitor}
                                      />
                                    </Box>
                                  </Box>
                                }
                                {(state?.selctdPlanId && state.proPlanList.find(plan => plan.cf_plan_quota === state.selctdProPlanVisitor)?.id === state.selctdPlanId) &&
                                  (state.profile && state.profile.billing && state.profile.billing.icnc === false)
                                  ?
                                  <Button id='primary_btn' fullWidth disabled>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>Current active plan</Text>
                                  </Button>
                                  :
                                  <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('pro')} disabled={state.selctdProPlanPrice < state.selctdPlanPrice ? true : false}>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>{state.selctdProPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? 'Upgrade monthly' : 'Upgrade yearly' : '7 Days free trial'}</Text>
                                  </Button>
                                }
                                <Box>
                                  <Box paddingBlockEnd={100}>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>Everything includes in basic +</Text>
                                  </Box>
                                  <BlockStack gap={100}>
                                    {proIncludeList && proIncludeList.map((data, index) => (
                                      <InlineStack key={index} gap={100} blockAlign='center'>
                                        <img src={data.icon} alt='' />
                                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                      </InlineStack>
                                    ))}
                                  </BlockStack>
                                </Box>
                              </BlockStack>
                            </Box>
                          </div>
                          <div class="card_Growth">
                            <Box background='bg-surface' padding={400} borderRadius='300'>
                              <BlockStack gap={300}>
                                <Box>
                                  <Badge tone="attention">Growth</Badge>
                                </Box>
                                {/* <div className='plan-blackfriday-tag'>
                                  <img  src={plan_blackfriday_tag} alt="" />
                                </div>
                                <div class="ribbon_Growth">Save up to 40%
                                  <br />
                                  <span className='card_growth_para'>
                                    Grab 5 months FREE
                                  </span>
                                </div> */}
                                <Text variant='bodySm' as='p' fontWeight='regular'>Best for D2C brands, agencies, CRO experts & running high traffic campaigns</Text>
                                <Divider />
                                {props.isLoading ?
                                  <Box>
                                    <Box width='30%' paddingBlockStart={300}>
                                      <SkeletonBodyText lines={1} />
                                    </Box>
                                    <Box paddingBlock={400}>
                                      <SkeletonDisplayText size='medium' maxWidth='100%' />
                                    </Box>
                                  </Box>
                                  :
                                  <Box>
                                    <Box paddingBlockEnd={300}>
                                      <InlineStack gap={100} blockAlign='center'>
                                        <Text variant='headingLg' as='h3'>
                                          ${state.selctdGrowthPlanPrice ? props.formmetNumber(state.selctdGrowthPlanPrice) : '-'}
                                        </Text>
                                        {/* <Text variant='bodySm' as='p' >{localStorage.getItem('plv') === 'month' ? 'per month' : 'per year'}</Text> */}
                                        <Text variant='bodySm' as='p' >{state.plv === 'month' ? 'per month' : 'per year'}</Text>
                                        {/* {state?.selctdPlanId && state.growthPlanList.find(plan => plan.cf_plan_quota === state.selctdGrowthPlanVisitor)?.id === state.selctdPlanId && (
                                    <Badge tone="success">Selected</Badge>
                                  )} */}
                                      </InlineStack>
                                    </Box>
                                    <Box minHeight='50px'>
                                      {/* <Select
                                  options={state.growthOptionList}
                                  onChange={(e) => { changeNameValue({ selctdGrowthPlanVisitor: e }); localStorage.setItem('selctdGrowthPlanVisitor', e); }}
                                  value={localStorage.getItem('selctdGrowthPlanVisitor') ? localStorage.getItem('selctdGrowthPlanVisitor') : state.selctdGrowthPlanVisitor}
                                /> */}
                                      <Select
                                        options={state.growthOptionList}
                                        onChange={(e) => changeNameValue({ selctdGrowthPlanVisitor: e })}
                                        value={state.selctdGrowthPlanVisitor}
                                      />
                                    </Box>
                                  </Box>
                                }
                                {(state?.selctdPlanId && state.growthPlanList.find(plan => plan.cf_plan_quota === state.selctdGrowthPlanVisitor)?.id === state.selctdPlanId)
                                  && (state.profile && state.profile.billing && state.profile.billing.icnc === false) ?
                                  <Button id='primary_btn' fullWidth disabled>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>Current active plan</Text>
                                  </Button>
                                  :
                                  <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('growth')} disabled={state.selctdGrowthPlanPrice < state.selctdPlanPrice ? true : false}>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold' >{state.selctdGrowthPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? 'Upgrade monthly' : 'Upgrade yearly' : '7 Days free trial'}</Text>
                                  </Button>
                                }
                                <Box>
                                  <Box paddingBlockEnd={100}>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                      Everything includes in pro +
                                    </Text>
                                  </Box>
                                  <BlockStack gap={100}>
                                    {growthIncludeList && growthIncludeList.map((data, index) => (
                                      <InlineStack key={index} gap={100} blockAlign='center'>
                                        <img src={data.icon} alt='' />
                                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                      </InlineStack>
                                    ))}
                                  </BlockStack>
                                </Box>
                              </BlockStack>
                            </Box>
                          </div>

                        </InlineGrid>
                      </Box>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Box>
              <Box padding={400} borderColor='border' borderWidth='025' borderRadius='300' shadow='100'>
                <InlineStack align='space-between' blockAlign='center'>
                  <BlockStack gap={100}>
                    <Text variant='bodyMd' as='h6' fontWeight='semibold' >Have more than 1 million visitors?</Text>
                    <Text tone="subdued" variant='bodyMd' as='p' fontWeight='regular'>Tell us your monthly visitors traffic & requirements. </Text>
                  </BlockStack>
                  <Button id='chat_Us' onClick={() => props.handleChatButton()}>Chat with us</Button>
                </InlineStack>
              </Box>
            </div>
          </Card>

          {state.lstAddon && state.profile &&
            (
              (state.profile.addonid !== undefined && state.profile.addonid !== 'null') ||
              (state.profile.csub !== undefined && state.profile.csub !== 'null' && state.profile.csub !== 'free' && state.profile.isbusr !== true)
            ) &&
            state.lstAddon.length > 0 && state.profile.utyp !== 'appsumo'
            &&
            state.lstAddon.map((addon, i) => {
              return (
                <Box key={i} paddingBlockStart={600}>
                  <BlockStack gap={200}>
                    <Text as='h4' variant='bodyLg' fontWeight='semibold'>Add-on</Text>
                    <Card id='Review_wall'>
                      <div className='addon-wrap addon-socialwall'>
                        <InlineStack blockAlign='center' gap={{ lg: 3200, xs: 200 }}>
                          <BlockStack gap={200}>
                            <Text variant='bodyMd' fontWeight='semibold'>{addon.name}</Text>
                            <Box width='332px'>
                              <List type="bullet">
                                <InlineStack gap={700}>
                                  <List.Item><Text tone='subdued'>Review feeds</Text></List.Item>
                                  <List.Item><Text tone='subdued'>Social feeds</Text></List.Item>
                                  <List.Item><Text tone='subdued'>Unlimited walls</Text></List.Item>
                                </InlineStack>
                              </List>
                            </Box>
                          </BlockStack>
                          <InlineStack gap={100} wrap={false}>
                            <Text variant='bodySm' as='p' textDecorationLine='line-through' tone='subdued'>$192</Text>
                            <Text variant='headingXl' as='h3' fontWeight='semibold'>$120</Text>
                            <Text variant='bodyMd' as='h3' fontWeight='regular'>per year</Text>
                          </InlineStack>
                          {state.profile.addonid === addon.id ?
                            <Badge tone='success'>Active</Badge>
                            :
                            <Button id='primary_btn' onClick={() => props.handlePurchaseChargebeeAddon(addon.id)}>Buy this add on</Button>
                          }
                        </InlineStack>
                      </div>
                    </Card>
                  </BlockStack>
                </Box>
              )
            })
          }

          {/* appsumo user type */}
          {
            (state.lstAddon && state.profile.utyp === 'appsumo' && state.lstAddon.length > 0)
            &&
            state.lstAddon.map((addon, i) => {
              return (
                <Box key={i} paddingBlockStart={600}>
                  <BlockStack gap={200}>
                    <Text as='h4' variant='bodyLg' fontWeight='semibold'>Add-on</Text>
                    <Card id='Review_wall'>
                      <div className='addon-wrap addon-socialwall'>
                        <InlineStack blockAlign='center' gap={{ lg: 3200, xs: 200 }}>
                          <BlockStack gap={200}>
                            <Text variant='bodyMd' fontWeight='semibold'>{addon.name}</Text>
                            <Box width='332px'>
                              <List type="bullet">
                                <InlineStack gap={700}>
                                  <List.Item><Text tone='subdued'>Review feeds</Text></List.Item>
                                  <List.Item><Text tone='subdued'>Social feeds</Text></List.Item>
                                  <List.Item><Text tone='subdued'>Unlimited walls</Text></List.Item>
                                </InlineStack>
                              </List>
                            </Box>
                          </BlockStack>
                          <InlineStack gap={100} wrap={false}>
                            <Text variant='bodySm' as='p' textDecorationLine='line-through' tone='subdued'>$192</Text>
                            <Text variant='headingXl' as='h3' fontWeight='semibold'>$120</Text>
                            <Text variant='bodyMd' as='h3' fontWeight='regular'>per year</Text>
                          </InlineStack>
                          {state.profile.addonid === addon.id ?
                            <Badge tone='success'>Active</Badge>
                            :
                            <Button id='primary_btn' onClick={() => props.handlePurchaseChargebeeAddon(addon.id)}>Buy this add on</Button>
                          }
                        </InlineStack>
                      </div>
                    </Card>
                  </BlockStack>
                </Box>
              )
            })
          }

          {/* <Box paddingBlockStart={300}>
        <BlockStack gap={200}>
          <Card id='Review_wall'>
            <InlineStack blockAlign='center' gap={{ lg: 600, xs: 200 }}>
              <BlockStack gap={200}>
                <Text variant='bodyMd' fontWeight='semibold'>Whit label & Sub users</Text>
                <Box width='441px'>
                  <List type="bullet">
                    <InlineStack gap={600}>
                      <List.Item>Your branded Dashboard</List.Item>
                      <List.Item>Includes 10 sub users then $5 per user</List.Item>
                    </InlineStack>
                  </List>
                </Box>
              </BlockStack>
              <Box>
                <InlineStack gap={100}>
                  <Box>
                    <Text as='p' variant='bodySm' textDecorationLine='line-through' id='old_Price' >$12</Text>
                  </Box>
                  <Text as='h3' variant='headingXl' id='new_Price'>$19</Text>
                  <Text as='h3' variant='bodySm' >per month</Text>
                </InlineStack>
              </Box>
            </InlineStack>
          </Card>
        </BlockStack>
      </Box> */}

          {
            (state.profile && state.profile.billing && state.profile.billing.icnc === false && state.profile.billing.pobj.id !== 'appsumo-spacial') &&
            < Box paddingBlock={700}>
              <Box paddingBlockEnd={'200'}>
                <Text as='p' variant='bodyLg' fontWeight='semibold'>Active plan</Text>
              </Box>
              <BillingSummery props={props} />
            </Box>
          }
          {
            localStorage.getItem('utyp') === 'appsumo' &&
            <Box paddingBlockEnd={400} paddingBlockStart={600}>
              <Box paddingBlockEnd={'200'}>
                <Text as='p' variant='bodyLg' fontWeight='semibold'>App sumo plan</Text>
              </Box>
              <AppsumoPlanCard />
            </Box>
          }
        </Page>
      </Box >
    </div >
  );
}

export default PricingPlanCard;
