import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/wixPricingPlan';
import { toastify } from '../ducks/toast';

function* getWixPricingPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getWixPricingPlan', action.payload);

    if (res.status === 'success') {
      yield put(actions.getWixPricingPlanSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* goToWixCheckOut(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/wix/goToWixCheckOut?planID=' + action.payload.planID);
    
    if (res.status === 'success') {
      window.open(res.data, '_blank');
    }
    else {
      // yield put(actions.getIntByUseError(true));
      if (res.m) {
        yield put(toastify({ type: 'error', msg: res.m }));
      } 
    }
    yield put(loaded());
  } catch (error) {
    // yield put(actions.getIntByUseError(true));
  }
}

export function* watchGetWixPricingPlan() {
  yield takeLatest(actions.getWixPricingPlan.type, getWixPricingPlan);
}

export function* watchGoToWixCheckOut() {
  yield takeLatest(actions.goToWixCheckOut.type, goToWixCheckOut);
}

export default function* rootSaga() {
  yield fork(watchGetWixPricingPlan);
  yield fork(watchGoToWixCheckOut);
}
