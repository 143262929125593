import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/appsumoPlan';
import { toastify } from '../ducks/toast';

function* getAppsumoPlan() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getAppsumoPlan');

    if (res.status === 'success') {
      yield put(actions.getAppsumoPlanSuccess(res.data));
      // if (window.fcWidget) {
      //   window.fcWidget.user.setProperties({
      //     appSumoCode: res.data.apstk.length
      //   });
      // }
      if (window.Froged) {
        window.Froged('set', {
          id: res.data.ti,
          AppSumoCode: res.data.apstk.length,
          Plan: ''
        });
      }

      if (window._cio) {
        window._cio.identify({
          id: res.data.ti,
          AppSumoCode: res.data.apstk.length,
          Plan: ''
        });
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetAppsumoPlan() {
  yield takeLatest(actions.getAppsumoPlan.type, getAppsumoPlan);
}

export default function* rootSaga() {
  yield fork(watchGetAppsumoPlan);
}
