import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, Page, Text } from '@shopify/polaris';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
import IntegrationHeader from './IntegrationHeader';
import * as integrationDucks from '../../ducks/integration';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function ZoomCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();

  const [info, setInfo] = useState({
    errMessage: {},
    ZoomCard: [],
    ia: false,
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //ZoomCard data from api
  const getZoomCardData = useSelector(state => state.integration?.userIntData);

  useEffect(() => {
    changeNameValueCard({ ZoomCard: getZoomCardData });
  }, [getZoomCardData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.ZoomCard.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ ZoomCard: updatedList })
  };

  //create ZoomCard data 
  const loginWithZoom = () => {
    let objState = {
      uk: props.profile._id,
      ti: props.profile.ti,
      uri: window.location.href.replace(/&/gi, '@'),
      path: window.location.pathname
    };
    const authURL = `https://zoom.us/oauth/authorize?client_id=YMaUP2tRHSTApdN8JoU4g&response_type=code&redirect_uri=${process.env.REACT_APP_INT_API_URL}/zoom/oauth&state=${JSON.stringify(objState)}`;
    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box align='right' padding={300}>
                <Button id='btnzoom' size='large' onClick={(e) => loginWithZoom(e)}>
                  Login with Zoom
                </Button>
              </Box>
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.ZoomCard?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                ]}
                selectable={false}
              >
                {info.ZoomCard && info.ZoomCard.length > 0 && info.ZoomCard.map((data, index) => {
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>{data.rmk}</IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={data.ia}
                              onChange={() => handleIsActive(data)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>
    </Page>
  )
}

export default ZoomCard;