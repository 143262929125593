import { Box, Page, Text, InlineGrid, Button, BlockStack, Layout, Checkbox, Select, Icon, InlineStack, ChoiceList, TextField, Tag, Tooltip, Autocomplete, EmptyState, Navigation, Label, Card, SkeletonDisplayText, SkeletonBodyText } from '@shopify/polaris';
import {
  DragHandleIcon, RefreshIcon, CodeAddIcon, AlertCircleIcon, ChartVerticalFilledIcon, ReceiptIcon, ListBulletedIcon
} from '@shopify/polaris-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

function List(props) {
  const { state, changeNameValue, checkPermission, profile } = props;
  const isLoading = useSelector(state => state?.loading?.isLoading);

  const [items, setItems] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(state.countryList);

  useEffect(() => {
    setItems(state?.ntdo);
  }, [state?.ntdo])

  // Handling drag and drop
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = Array.from(state.ntdo);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setItems(reorderedItems);
    props.handleUpdateSetting('ntdo', reorderedItems);
  };

  const removeTag = (type, val) => {
    if (type === 'arrun') {
      const filterdata = state.arrun.filter((previousTag) => previousTag !== val)
      changeNameValue({ arrun: filterdata });
      props.handleUpdateSetting('arrun', filterdata);
    }
    else if (type === 'ipfrbdn') {
      const filterdata = state.ipfrbdn.filter((previousTag) => previousTag !== val)
      changeNameValue({ ipfrbdn: filterdata });
      props.handleUpdateSetting('ipfrbdn', filterdata);
    }
    else if (type === 'dc') {
      const filterdata = state.dc.filter((previousTag) => previousTag !== val)
      changeNameValue({ dc: filterdata });
      props.handleUpdateSetting('dc', filterdata);
    }
  }

  /// country select
  const updateText = useCallback(
    (value) => {
      setInputValue(value);
      if (value === '') {
        setOptions(state.countryList);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = state.countryList.filter((option) =>
        option.label.match(filterRegex),
      );
      setOptions(resultOptions);
    },
    [state.countryList],
  );

  const countryFocus = () => {
    setOptions(state.countryList);
    return;
  }
  const updateSelection = useCallback(
    (selected) => {
      let selectedVal = selected[0].toLowerCase();
      let duplicate = state?.dc.filter((elm) => elm == selectedVal);
      if (duplicate.length <= 0) {
        const array = [...state.dc, selectedVal];
        changeNameValue({ dc: array });
        props.handleUpdateSetting('dc', array);
      }
    },
    [changeNameValue],
  );

  const verticalContentMarkup = (type, value) => {
    if (value?.length > 0) {
      return (<InlineStack spacing="extraTight" alignment="center">
        {value.map((option) => {
          let tagLabel = '';
          tagLabel = option.replace('_', ' ');
          return (
            <Tag key={`option${option}`} onRemove={() => removeTag(type, option)}>
              {tagLabel}
            </Tag>
          );
        })}
      </InlineStack>)
    }
    else {
      return null
    }
  }

  const textFieldCountry = (
    <Autocomplete.TextField
      onChange={updateText}
      label={<Tooltip padding='400' content="Select a country to display notifications only for users from that region." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Country-Specific Notification Display</Text></Tooltip>}
      value={inputValue}
      placeholder="Search Country"
      autoComplete="off"
      verticalContent={verticalContentMarkup('dc', state.dc)}
      disabled={!checkPermission('setting', 'isedit', profile)}
      onFocus={countryFocus}
    />
  );

  const textFieldIp = (
    <Autocomplete.TextField
      onChange={(e) => changeNameValue({ ipchips: e })}
      label={<Tooltip padding='400' content="Enter the IP addresses you want to block from seeing notifications." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Block IP Addresses</Text></Tooltip>}
      value={state.ipchips}
      placeholder="Add IP address and press enter key"
      autoComplete="off"
      verticalContent={verticalContentMarkup('ipfrbdn', state.ipfrbdn)}
      disabled={!checkPermission('setting', 'isedit', profile)}
    />
  );

  const textFieldPixel = (
    <Autocomplete.TextField
      onChange={(e) => changeNameValue({ valArrun: e })}
      label={<Tooltip padding='400' content="Use this option only if the pixel is not able to capture the correct name from your form." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Here, you can add correct field name/Id & inform the pixel to capture correct name field value</Text></Tooltip>}
      value={state.valArrun}
      placeholder="Add form field name/Id and press enter key"
      autoComplete="off"
      verticalContent={verticalContentMarkup('arrun', state.arrun)}
      disabled={!checkPermission('setting', 'isedit', profile)}
    />
  );
  return (
    <div className='page-wrap page-setting-wrap'>

      {state.notifSettingId == '' ?
        <>
          <Page title={'Setting'} subtitle={'Choose the domain to apply your notification settings. (Manage notification order, loops, privacy, pixel settings, and more)'}>

            {state?.getSettingList && state?.getSettingList.length > 0 ?

              <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
                {state?.getSettingList && state?.getSettingList.length > 0 ? state.getSettingList.map((elm, index) => {
                  return (
                    <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400" key={index}>
                      <BlockStack gap={100}>
                        <Text variant='headingMd' as='p' fontWeight='semibold'>Apply settings on</Text>
                        <Text tone="subdued">
                          {elm.ht}
                        </Text>
                      </BlockStack>
                      <Box paddingBlockStart={300}>
                        <Button variant="primary" tone="success" onClick={() => props.handleManageSetting(elm)}>Notification setting</Button>
                      </Box>
                    </Box>
                  )
                }) : ''}
              </InlineGrid>
              :
              <>
                {isLoading ?
                  <InlineGrid columns={3} gap={300}>
                    <Card gap={400}>
                      <BlockStack gap={300}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                      </BlockStack>
                    </Card>
                    <Card gap={400}>
                      <BlockStack gap={300}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                      </BlockStack>
                    </Card>
                    <Card gap={400}>
                      <BlockStack gap={300}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                      </BlockStack>
                    </Card>
                  </InlineGrid>
                  :
                  state?.getSettingList && state?.getSettingList.length === 0 && <Card sectioned>
                    <EmptyState
                      heading="You have not added any notifications yet."
                      action={{ content: 'Add Notification', url: '/' }}
                      image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                      fullWidth
                    >
                    </EmptyState>
                  </Card>}
              </>
            }
          </Page>
        </> :
        <Page title={'Setting'} subtitle={'Setup notification orders, loops, pixel settings, and custom CSS.'} backAction={{ onAction: () => changeNameValue({ notifSettingId: '' }) }}>
          <div className='mainDiv page-setting-indomain'>
            <Layout>
              <Layout.Section variant="oneThird">
                <div className='navigateTab'>
                  <BlockStack>
                    <Box padding={300} paddingInlineStart={600}>
                      <Text variant='headingMd' as='p' fontWeight='semibold'>Manage notification on</Text>
                      <Text>{state.domain?.length > 35 ? state.domain?.slice(0, 35) + '...' : state.domain}</Text>
                    </Box>
                  </BlockStack>
                </div>
                <div id='setting_nav'>
                  <Navigation location="/">
                    <Navigation.Section
                      items={[
                        {
                          url: '#loopCloseSetting',
                          label: 'Loop & Close setting',
                          icon: RefreshIcon,
                          selected: state.selctdTab === 'loopCloseSetting' ? true : false,
                          onClick: () => changeNameValue({ selctdTab: 'loopCloseSetting' }),
                        },
                        {
                          url: '#notificationOrder',
                          label: 'Notification order',
                          icon: ListBulletedIcon,
                          selected: state.selctdTab === 'notificationOrder' ? true : false,
                          onClick: () => changeNameValue({ selctdTab: 'notificationOrder' }),
                        },
                        {
                          url: '#customCodeCSS',
                          label: 'Custom code & CSS',
                          icon: CodeAddIcon,
                          selected: state.selctdTab === 'customCodeCSS' ? true : false,
                          onClick: () => changeNameValue({ selctdTab: 'customCodeCSS' }),
                        },
                        {
                          url: '#privacyGDPR',
                          label: 'Privacy & GDPR',
                          icon: ReceiptIcon,
                          selected: state.selctdTab === 'privacyGDPR' ? true : false,
                          onClick: () => changeNameValue({ selctdTab: 'privacyGDPR' }),
                        },
                        {
                          url: '#partyAnalytics',
                          label: '3rd Party analytics',
                          icon: ChartVerticalFilledIcon,
                          selected: state.selctdTab === 'partyAnalytics' ? true : false,
                          onClick: () => changeNameValue({ selctdTab: 'partyAnalytics' }),
                        },
                        {
                          url: '#others',
                          label: 'Others',
                          icon: AlertCircleIcon,
                          selected: state.selctdTab === 'others' ? true : false,
                          onClick: () => changeNameValue({ selctdTab: 'others' }),
                        }
                      ]}
                    />
                  </Navigation>
                </div>
              </Layout.Section>
              <Layout.Section>
                <div id='loopCloseSetting' style={{ position: 'absolute', top: '90px' }}></div>
                <BlockStack gap="500" >
                  <div>
                    <BlockStack gap="500">
                      <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                        <Box paddingBlockEnd={200} ><Text variant='headingMd' as='p' fontWeight='semibold'>Loop</Text></Box>
                        <div className='innerDiv'>
                          <Box padding="400">

                            <Checkbox
                              label={<Tooltip padding='400' content="Enable this option to loop notifications continuously." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Loop notifications</Text></Tooltip>}
                              checked={state?.lp}
                              onChange={() => { props.handleUpdateSetting('lp', !state?.lp); changeNameValue({ lp: !state?.lp }); }}
                              disabled={!checkPermission('setting', 'isedit', profile)}
                            />
                            <InlineStack blockAlign="center" gap="400" wrap={false}>
                              <div className='startloop'>
                                <Tooltip padding='400' content="Enter the minimum number of notifications required to start looping" hasUnderline width='wide' dismissOnMouseOut>
                                  <Text as='span' tone="subdued">Start looping if the number of notifications is greater than</Text>
                                </Tooltip>
                              </div>
                              <div className='optionList'>
                                <Select
                                  options={state.loopOption}
                                  onChange={(e) => { props.handleUpdateSetting('lmn', +e); changeNameValue({ lmn: +e }) }}
                                  value={state.lmn}
                                  disabled={!checkPermission('setting', 'isedit', profile) || state.lp === true ? false : true}
                                />
                              </div>
                            </InlineStack>
                          </Box>
                        </div>
                      </Box>

                      <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                        <Box paddingBlockEnd={200} ><Text variant='headingMd' as='p' fontWeight='semibold'>Close setting</Text></Box>
                        <div className='innerDiv'>
                          <Box padding="400">
                            <InlineStack blockAlign="center" gap="400" wrap={false}>
                              <Checkbox
                                label={<Tooltip padding='400' content="Select a duration to temporarily stop displaying all notifications to visitors." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Pause all notifications for the selected time period</Text></Tooltip>}
                                checked={state.itfn}
                                onChange={() => { props.handleUpdateSetting('itfn', !state?.itfn); changeNameValue({ itfn: !state?.itfn }); }}
                                disabled={!checkPermission('setting', 'isedit', profile)}
                              />
                              <div className='optionList'>
                                <Select
                                  options={state.stopNotifDay}
                                  onChange={(e) => { props.handleUpdateSetting('tmclsntf', +e); changeNameValue({ tmclsntf: +e }) }}
                                  value={state.tmclsntf}
                                  disabled={!checkPermission('setting', 'isedit', profile)}
                                />
                              </div>
                            </InlineStack>

                            {state.itfn === false &&
                              <Checkbox
                                label={<Tooltip padding='400' content="When visitors click the X icon, it will only close that specific notification, not stop the entire loop of notifications." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Close individual notifications when visitors click the X icon</Text></Tooltip>}
                                checked={state.itfsgln}
                                onChange={() => { props.handleUpdateSetting('itfsgln', !state?.itfsgln); changeNameValue({ itfsgln: !state?.itfsgln }); }}
                                disabled={!checkPermission('setting', 'isedit', profile)}
                              />}
                            <div id='notificationOrder'></div>
                            <div>
                              <Checkbox
                                label={<Tooltip padding='400' content="Enable this option to display a close icon on all notifications, allowing visitors to dismiss them." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Always display a close icon on notifications</Text></Tooltip>}
                                checked={state.isclsicn}
                                onChange={() => { props.handleUpdateSetting('isclsicn', !state?.isclsicn); changeNameValue({ isclsicn: !state?.isclsicn }); }}
                                disabled={!checkPermission('setting', 'isedit', profile)}
                              />
                            </div>
                          </Box>
                        </div>
                      </Box>
                    </BlockStack>
                  </div>


                  <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                    <Box paddingBlockEnd={200} ><Text variant='headingMd' as='p' fontWeight='semibold'>Notification order</Text></Box>
                    <div className='innerDiv'>
                      <Box padding="400">
                        <Tooltip padding='400' content="Select how your notifications will appear: in a random order for variety, or in a looped sequence to follow the set display orde" hasUnderline dismissOnMouseOut><Label tone="subdued">Choose option</Label>
                        </Tooltip>
                        <ChoiceList
                          title=""
                          choices={[
                            { label: <Text tone="subdued">Display in random order</Text>, value: 'randomOrder' },
                            { label: <Text tone="subdued">Set notifications to loop</Text>, value: 'loopOrder' },
                          ]}
                          selected={state.irnd === true ? 'randomOrder' : 'loopOrder'}
                          onChange={(e) => { props.handleUpdateSetting('icndf', e[0]); }}
                          disabled={!checkPermission('setting', 'isedit', profile)}
                        />
                        {/* </Tooltip> */}
                        {state.irnd === false &&
                          <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                            <Droppable droppableId="droppable">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps} className='dragDrop'>
                                  <div style={{ marginBottom: '5px' }}><Text tone="subdued">Drag and drop the notifications below to set your preferred display order</Text></div>
                                  {items?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div className='draglist'>
                                            <InlineStack gap={100}>
                                              <div> <Icon
                                                source={DragHandleIcon}
                                                tone="subdued"
                                              /> </div><Text tone="subdued">{item.name}</Text>
                                            </InlineStack>
                                          </div>
                                        </div>
                                      )
                                      }
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        }
                        <div id='customCodeCSS'></div>
                        <div>
                          <Checkbox
                            label={<Tooltip padding='400' content="Enable to prioritize showing conversions from the visitor's country." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Prioritize conversions from the visitor's country</Text></Tooltip>}
                            checked={state.icndf}
                            onChange={() => { props.handleUpdateSetting('icndf', !state?.icndf); changeNameValue({ icndf: !state?.icndf }); }}
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />
                        </div>
                      </Box>
                    </div>
                  </Box>


                  <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                    <Box paddingBlockEnd={200} ><Text variant='headingMd' as='p' fontWeight='semibold'>Customize pixel setting</Text></Box>
                    <div className='innerDiv'>
                      <Box padding="400">

                        <div onKeyDown={(e) => props.handleEnterEvent('arrun', e)}>
                          <Autocomplete
                            options={[]}
                            textField={textFieldPixel}
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />
                        </div>

                        <div className='custMarginTopBottom'>
                          <Checkbox
                            label={<Tooltip padding='400' content="Turn ON to use your website’s font stack to display notification text" hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Prevent to load the Google Font Family file</Text></Tooltip>}
                            checked={state.ihdgglfnt}
                            onChange={() => { props.handleUpdateSetting('ihdgglfnt', !state?.ihdgglfnt); changeNameValue({ ihdgglfnt: !state?.ihdgglfnt }); }}
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />
                        </div>

                        <div className='textField'>
                          <TextField
                            label={<Tooltip padding='400' content="Add your custom CSS code here" hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">CSS</Text></Tooltip>}
                            value={state.cstcss}
                            onChange={(e) => changeNameValue({ cstcss: e, isCssBtn: true })}
                            multiline={4}
                            autoComplete="off"
                            placeholder='CSS written  here...'
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />

                          <div className='custMarginTopBottom'>
                            <InlineStack direction="row-reverse" align="end" blockAlign="end">
                              {state.isCssBtn === true && <Button variant="primary" onClick={() => props.handleUpdateSetting('cstcss', state.cstcss)}>Update</Button>}
                            </InlineStack>
                          </div>

                          <TextField
                            label={<Tooltip padding='400' content="Add your custom Javascript code here" hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Javascript</Text></Tooltip>}
                            value={state.cstjs}
                            onChange={(e) => changeNameValue({ cstjs: e, isJsBtn: true })}
                            multiline={4}
                            autoComplete="on"
                            placeholder='Javascript written  here...'
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />
                        </div>

                        <div className='custMarginTopBottom'>
                          <InlineStack direction="row-reverse" align="end" blockAlign="end">
                            {state.isJsBtn === true && <Button variant="primary" onClick={() => props.handleUpdateSetting('cstjs', state.cstjs)}>Update</Button>}
                          </InlineStack>
                        </div>

                      </Box>
                    </div>
                  </Box>

                  <div id='privacyGDPR'>
                    <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                      <Box paddingBlockEnd={200} ><Text variant='headingMd' as='p' fontWeight='semibold'>
                        Privacy & GDPR</Text></Box>
                      <div className='innerDiv'>
                        <Box padding="400">
                          <Checkbox
                            label={<Tooltip padding='400' content="Enable this to ask visitors for permission to use cookies, local storage, and track their data." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Request Cookies, Local Storage, and Tracking Permission</Text></Tooltip>}
                            checked={state.iactrk}
                            onChange={() => { props.handleUpdateSetting('iactrk', !state?.iactrk); changeNameValue({ iactrk: !state?.iactrk }); }}
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />

                          {state.iactrk === true &&
                            <div className='priacyMargin'>
                              <TextField
                                label={<Text tone="subdued">Enter privacy & policy URL</Text>}
                                value={state.prvlnk}
                                onChange={(e) => changeNameValue({ prvlnk: e })}
                                connectedRight={<Button onClick={() => props.handleUpdateSetting('prvlnk', state.prvlnk)}>Submit</Button>}
                                disabled={!checkPermission('setting', 'isedit', profile)}
                              />
                            </div>
                          }
                          <div>
                            <Checkbox
                              label={<Tooltip padding='400' content="Enable this to track visitors' IP addresses and geo-location data for location-based notifications." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Track IP & Geo Locations</Text></Tooltip>}
                              checked={state.iaipg}
                              onChange={() => { props.handleUpdateSetting('iaipg', !state?.iaipg); changeNameValue({ iaipg: !state?.iaipg }); }}
                              disabled={!checkPermission('setting', 'isedit', profile)}
                            />
                          </div>
                          <div>
                            <Checkbox
                              label={<Tooltip padding='400' content="Enable this to store the visitor's email ID when they submit a form." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Store Visitor Email ID</Text></Tooltip>}
                              checked={state.iaeml}
                              onChange={() => { props.handleUpdateSetting('iaeml', !state?.iaeml); changeNameValue({ iaeml: !state?.iaeml }); }}
                              disabled={!checkPermission('setting', 'isedit', profile)}
                            />
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </div>

                  <div id='partyAnalytics'>
                    <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                      <Box paddingBlockEnd={200} ><Text variant='headingMd' as='p' fontWeight='semibold'>
                        Third party analytics</Text></Box>
                      <div className='innerDiv'>
                        <Box padding="400">
                          <Checkbox
                            label={<Tooltip padding='400' content="Enable this to send notification event data directly to your Google Analytics account." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Send Notification Event Data to Google Analytics</Text></Tooltip>}
                            checked={state.igglan}
                            onChange={() => { props.handleUpdateSetting('igglan', !state?.igglan); changeNameValue({ igglan: !state?.igglan }); }}
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />
                          <div>
                            <Checkbox
                              label={<Tooltip padding='400' content="Enable this to send notification event data to Google Tag Manager for further processing." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Send Notification Event Data to Google Tag Manager</Text></Tooltip>}
                              checked={state.iggltg}
                              onChange={() => { props.handleUpdateSetting('iggltg', !state?.iggltg); changeNameValue({ iggltg: !state?.iggltg }); }}
                              disabled={!checkPermission('setting', 'isedit', profile)}
                            />
                          </div>
                          <div>
                            <Checkbox
                              label={<Tooltip padding='400' content="Enable this to send notification event data to Mixpanel for advanced user tracking and analytics." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Send Notification Event Data to Mixpanel</Text></Tooltip>}
                              checked={state.imxan}
                              onChange={() => { props.handleUpdateSetting('imxan', !state?.imxan); changeNameValue({ imxan: !state?.imxan }); }}
                              disabled={!checkPermission('setting', 'isedit', profile)}
                            />
                          </div>
                          <div>
                            <Checkbox
                              label={<Tooltip padding='400' content="Enable this to send notification event data to Facebook analytics." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Send Notification Event Data to Facebook</Text></Tooltip>}
                              checked={state.ifban}
                              onChange={() => { props.handleUpdateSetting('ifban', !state?.ifban); changeNameValue({ ifban: !state?.ifban }); }}
                              disabled={!checkPermission('setting', 'isedit', profile)}
                            />
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </div>

                  <div id='others'>
                    <Box background="bg-surface" borderRadius="200" borderColor="border" borderWidth="025" padding="400">
                      <Box paddingBlockEnd={200} ><Text variant='headingMd' as='p' fontWeight='semibold'>
                        Others</Text></Box>
                      <div className='innerDiv'>
                        <Box padding="400">
                          <Checkbox
                            label={<Tooltip padding='400' content="Turn ON to block offensive words and phrases from appearing in notifications." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Enable Profanity Filter</Text></Tooltip>}
                            checked={state.ipfnty}
                            onChange={() => { props.handleUpdateSetting('ipfnty', !state?.ipfnty); changeNameValue({ ipfnty: !state?.ipfnty }); }}
                            disabled={!checkPermission('setting', 'isedit', profile)}
                          />
                          <div>
                            <Checkbox
                              label={<Tooltip padding='400' content="Turn ON to capture and display duplicate data in notifications." hasUnderline width='wide' dismissOnMouseOut><Text as='span' tone="subdued">Enable Duplicate Data Capture</Text></Tooltip>}
                              checked={state.idplcpt}
                              onChange={() => { props.handleUpdateSetting('idplcpt', !state?.idplcpt); changeNameValue({ idplcpt: !state?.idplcpt }); }}
                              disabled={!checkPermission('setting', 'isedit', profile)}
                            />
                          </div>
                          <div className='other-text-box'>
                            <div onKeyDown={(e) => props.handleEnterEvent('ipfrbdn', e)}>
                              <Autocomplete
                                options={[]}
                                textField={textFieldIp}
                                disabled={!checkPermission('setting', 'isedit', profile)}
                              />
                            </div>
                            <Box paddingBlockStart={200}>
                              <Autocomplete
                                options={options}
                                selected={[]}
                                onSelect={updateSelection}
                                textField={textFieldCountry}
                                disabled={!checkPermission('setting', 'isedit', profile)}
                              />
                            </Box>
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </div>
                </BlockStack>
              </Layout.Section>
            </Layout>
          </div>
        </Page>
      }

    </div >
  );
}

export default List;