import React from 'react'
import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Divider, InlineGrid, InlineStack, Layout, Popover, Select, Text } from '@shopify/polaris'
import { checkCircle_icon, planTrue_icon, plan_blackfriday_tag } from '../../img'
import { ChevronRightIcon } from '@shopify/polaris-icons';

function BoardingStep3({ props }) {
  const { state, changeNameValue, getSelectedPlan } = props;

  const tabs = [
    { tabName: 'month', label: 'Pay monthly' },
    // { tabName: 'year', label: 'Pay yearly (BLACKFRIDAY OFFER)' },
    { tabName: 'year', label: 'Pay yearly (4 Month free)' },

  ];

  const planDescList = [
    { icon: checkCircle_icon, label: '14 Day money back guarantee' },
    { icon: checkCircle_icon, label: 'Change or cancel the plan any time' }
  ]

  const basicIncludeList = [
    { icon: planTrue_icon, label: 'Premium templates' },
    { icon: planTrue_icon, label: 'Unlimited notifications & Impressions' },
    { icon: planTrue_icon, label: 'Social Proof & Urgency Widgets' },
    { icon: planTrue_icon, label: 'Multiple websites' },
    { icon: planTrue_icon, label: '200 + Integrations' },
    { icon: planTrue_icon, label: 'Live Chat Support' },
    { icon: planTrue_icon, label: 'Reporting & Settings' }
  ]

  const proIncludeList = [
    { icon: planTrue_icon, label: 'Branding control' },
    { icon: planTrue_icon, label: 'A/B testing & Goal Tracking' },
    { icon: planTrue_icon, label: 'Custom CSS' },
    { icon: planTrue_icon, label: '1 Team mate(staff)' }
  ]

  const growthIncludeList = [
    { icon: planTrue_icon, label: 'In-line social proof messaging' },
    { icon: planTrue_icon, label: 'Dedicated Account Manager' },
    { icon: planTrue_icon, label: 'Custom Design & Technical support' },
    { icon: planTrue_icon, label: '10 Teams mate(staff)' }
  ]
  return (
    <div className='onboard_step3'>
      <Card>
        <BlockStack gap={300}>
          <Text variant='bodyLg' as='h6' fontWeight='semibold' id='step3_Title'>Select a plan based on your monthly visitor traffic.</Text>
          <Box id='plan-moneyback-list'>
            <InlineStack gap={200} wrap={false} blockAlign='center'>
              {planDescList && planDescList.map((data, index) => (
                <InlineStack key={index} gap={100} blockAlign='center'>
                  <img src={data.icon} alt='' />
                  <Text as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                </InlineStack>
              ))}
            </InlineStack>
          </Box>
        </BlockStack>
        <div id='plan_block'>

          <Box paddingBlockEnd={500}>
            <Layout>
              <Layout.Section variant='oneThird'>
                <Box id='pln_basic_block' padding={400} borderRadius={300}>
                  <BlockStack gap={300}>
                    <Box>
                      <Badge tone="warning">Basic - Only Yearly available</Badge>
                    </Box>
                    <Text variant='bodySm' as='p' fontWeight='regular'>Best for who start building funnel & growing the traffic</Text>
                    <Divider borderWidth='050' />
                    <Box>
                      <InlineStack gap={100} blockAlign='center'>
                        <Text variant='headingLg' as='h3' fontWeight='semibold'>${state.basicPlanPrice ? state.basicPlanPrice : '-'}</Text>
                        <Text variant='headingSm' as='p' fontWeight='regular'>per year</Text>
                        {state?.selctdPlanId && state.basicPlan.id === state.selctdPlanId && (
                          <Badge tone="success">Selected</Badge>
                        )}
                      </InlineStack>
                    </Box>
                    <Box minHeight='50px'>
                      <Popover
                        fullWidth
                        activator={<Button fullWidth textAlign={'start'}>
                          <Text variant='bodyMd' fontWeight='medium'>
                            {state.basicPlanVisitor ? state.basicPlanVisitor : '-'} Visitors/month
                          </Text>
                        </Button>}>
                      </Popover>
                    </Box>
                    <Box>
                      {state?.selctdPlanId && state.basicPlan.id === state.selctdPlanId ?
                        <Button id='primary_btn' fullWidth disabled>
                          <Text variant='bodyMd' as='p' fontWeight='semibold'>Current Active Plan</Text>
                        </Button>
                        :
                        <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('basic')} disabled={state.basicPlanPrice < state.selctdPlanPrice ? true : false}>
                          <Text variant='bodyMd' fontWeight='semibold'>{state.basicPlanPrice > state.selctdPlanPrice ? 'Upgrade Yearly' : '7 Days Free Trial'}</Text>
                        </Button>
                      }
                    </Box>
                    <Box>
                      <BlockStack gap={100}>
                        <Text variant='bodyMd' fontWeight='semibold'>
                          Includes
                        </Text>
                        {basicIncludeList && basicIncludeList.map((data, index) => (
                          <InlineStack key={index} gap={100} blockAlign='center'>
                            <img src={data.icon} alt='' />
                            <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                          </InlineStack>
                        ))}
                      </BlockStack>
                    </Box>
                  </BlockStack>
                </Box>
              </Layout.Section>
              <Layout.Section id='dasdasd'>
                <InlineStack blockAlign="start" id='sdasd'>
                  <InlineStack>
                    {/* <Text variant="headingSm" as="h6" fontWeight='bold'>
                      BLACKFRIDAY OFFER:
                    </Text>
                    <div className='grab_para'>
                      <Text variant="bodySm" as="p">
                        Grab 5 months FREE on PRO & GRO yearly plan and save 40%!
                      </Text>
                    </div> */}
                  </InlineStack>
                  <div className='skip-block'>
                    <Button variant='plain' icon={ChevronRightIcon} onClick={() => props.handleSkipOnboarding()}>Skip plan</Button>
                  </div>
                </InlineStack>
                <Box background='bg-surface-tertiary' id='plan_pg_block' borderRadius={300} paddingInline={600} paddingBlockEnd={500}>
                  <InlineStack blockAlign='center' align='center'>
                    <Box background='bg-surface' borderRadius='300' padding={100} borderColor="border" borderWidth="025">
                      <ButtonGroup>
                        {tabs.map((data, index) => {
                          // const localStorageValue = localStorage.getItem('plv');
                          // const variant = localStorageValue ? (localStorageValue === data.tabName ? 'primary' : '') : (props.state.plv === data.tabName ? 'primary' : '');

                          return (
                            <Button
                              key={index}
                              variant={state.plv === data.tabName ? 'primary' : ''}
                              onClick={() => props.handleTabChange(data)}
                            >
                              <Text variant='bodyLg' as='p' fontWeight='regular'>
                                {data.label}
                              </Text>
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </Box>
                  </InlineStack>
                  <Box paddingBlockStart={400}>
                    <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={400} >
                      <div class="card">
                        <Box background='bg-surface' padding={400} borderRadius='300' minHeight='429.99px'>
                          <BlockStack gap={300}>
                            <Box>
                              <Badge tone="info">Pro</Badge>
                            </Box>
                            {/* <div className='plan-blackfriday-tag'>
                              <img src={plan_blackfriday_tag} alt="" />
                            </div>
                            <div class="ribbon">Save up to 40%
                              <br />
                              <span className='card_para'>
                                Grab 5 months FREE
                              </span>
                            </div> */}
                            <Text variant='bodySm' as='p' fontWeight='regular'>Best for coaching business, Selling digital <br /> items & Funnel builders</Text>
                            <Divider />
                            <Box>
                              <InlineStack gap={100} blockAlign='center'>
                                <Text variant='headingLg' as='p' fontWeight='semibold'>${state.selctdProPlanPrice ? state.selctdProPlanPrice : '-'}</Text>
                                <Text variant='headingSm' as='p' fontWeight='regular'>{state.plv === 'month' ? 'per month' : 'per year'}</Text>
                                {state?.selctdPlanId && state.proPlanList.find(plan => plan.cf_plan_quota === state.selctdProPlanVisitor)?.id === state.selctdPlanId && (
                                  <Badge tone="success">Selected</Badge>
                                )}
                              </InlineStack>
                            </Box>
                            <Box minHeight='50px'>
                              {/* <Select
                                                        options={state.proOptionList}
                                                        onChange={(e) => { changeNameValue({ selctdProPlanVisitor: e }); getSelectedPlan(e); }}
                                                        value={localStorage.getItem('selctdProPlanVisitor') ? localStorage.getItem('selctdProPlanVisitor') : state.selctdProPlanVisitor}
                                                    /> */}
                              <Select
                                options={state.proOptionList}
                                onChange={(e) => { changeNameValue({ selctdProPlanVisitor: e }); getSelectedPlan(e); }}
                                value={state.selctdProPlanVisitor}
                              />
                            </Box>
                            {state?.selctdPlanId && state.proPlanList.find(plan => plan.cf_plan_quota === state.selctdProPlanVisitor)?.id === state.selctdPlanId ?
                              <Button id='primary_btn' fullWidth disabled>
                                <Text variant='bodyMd' as='p' fontWeight='semibold'>Current Active Plan</Text>
                              </Button>
                              :
                              <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('pro')} disabled={state.selctdProPlanPrice < state.selctdPlanPrice ? true : false}>
                                <Text variant='bodyMd' as='p' fontWeight='semibold'>{state.selctdProPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? 'Upgrade Monthly' : 'Upgrade Yearly' : '7 Days Free Trial'}</Text>
                              </Button>
                            }
                            <Box>
                              <Box paddingBlockEnd={100}>
                                <Text variant='bodyMd' as='p' fontWeight='semibold'>Everything includes in Basic, plus</Text>
                              </Box>
                              <BlockStack gap={100}>
                                {proIncludeList && proIncludeList.map((data, index) => (
                                  <InlineStack key={index} gap={100} blockAlign='center'>
                                    <img src={data.icon} alt='' />
                                    <Text variant='headingSm' as='p' tone='subdued' fontWeight='regular'>{data.label}</Text>
                                  </InlineStack>
                                ))}
                              </BlockStack>
                            </Box>
                          </BlockStack>
                        </Box>
                      </div>
                      <div class="card_Growth">
                        <Box background='bg-surface' padding={400} borderRadius='300'>
                          <BlockStack gap={300}>
                            <Box>
                              <Badge tone="attention">Growth</Badge>
                            </Box>
                            {/* <div className='plan-blackfriday-tag'>
                              <img src={plan_blackfriday_tag} alt="" />
                            </div>
                            <div class="ribbon_Growth">Save up to 40%
                              <br />
                              <span className='card_growth_para'>
                                Grab 5 months FREE
                              </span>
                            </div> */}
                            <Text variant='bodySm' as='p' fontWeight='regular'>Best D2C brands, Agencies, CRO experts & running high traffic campaigns</Text>
                            <Divider />
                            <Box>
                              <InlineStack gap={100} blockAlign='center'>
                                <Text variant='headingLg' as='h3'>
                                  ${state.selctdGrowthPlanPrice ? state.selctdGrowthPlanPrice : '-'}
                                </Text>
                                <Text variant='headingSm' as='p' fontWeight='regular'>{state.plv === 'month' ? 'per month' : 'per year'}</Text>
                                {state?.selctdPlanId && state.growthPlanList.find(plan => plan.cf_plan_quota === state.selctdGrowthPlanVisitor)?.id === state.selctdPlanId && (
                                  <Badge tone="success">Selected</Badge>
                                )}
                              </InlineStack>
                            </Box>
                            <Box minHeight='50px'>
                              {/* <Select
                                                        options={state.growthOptionList}
                                                        onChange={(e) => { changeNameValue({ selctdGrowthPlanVisitor: e }); getSelectedPlan(e); localStorage.setItem('selctdGrowthPlanVisitor', e); }}
                                                        value={localStorage.getItem('selctdGrowthPlanVisitor') ? localStorage.getItem('selctdGrowthPlanVisitor') : state.selctdGrowthPlanVisitor}
                                                    /> */}
                              <Select
                                options={state.growthOptionList}
                                onChange={(e) => { changeNameValue({ selctdGrowthPlanVisitor: e }); getSelectedPlan(e); }}
                                value={state.selctdGrowthPlanVisitor}
                              />
                            </Box>
                            {state?.selctdPlanId && state.growthPlanList.find(plan => plan.cf_plan_quota === state.selctdGrowthPlanVisitor)?.id === state.selctdPlanId ?
                              <Button id='primary_btn' fullWidth disabled>
                                <Text variant='bodyMd' as='p' fontWeight='semibold'>Current Active Plan</Text>
                              </Button>
                              :
                              <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('growth')} disabled={state.selctdGrowthPlanPrice < state.selctdPlanPrice ? true : false}>
                                <Text variant='bodyMd' as='p' fontWeight='semibold' >{state.selctdGrowthPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? 'Upgrade Monthly' : 'Upgrade Yearly' : '7 Days Free Trial'}</Text>
                              </Button>
                            }
                            <Box>
                              <Box paddingBlockEnd={100}>
                                <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                  Everything includes in Pro, plus
                                </Text>
                              </Box>
                              <BlockStack gap={100}>
                                {growthIncludeList && growthIncludeList.map((data, index) => (
                                  <InlineStack key={index} gap={100} blockAlign='center'>
                                    <img src={data.icon} alt='' />
                                    <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                  </InlineStack>
                                ))}
                              </BlockStack>
                            </Box>
                          </BlockStack>
                        </Box>
                      </div>

                    </InlineGrid>
                  </Box>
                </Box>
              </Layout.Section>
            </Layout>
          </Box>
          <Box padding={400} borderColor='border' borderWidth='025' borderRadius='300' shadow='100'>
            <InlineStack align='space-between' blockAlign='center'>
              <BlockStack gap={100}>
                <Text variant='bodyMd' as='h6' fontWeight='semibold' >Have more than 1 million visitors?</Text>
                <Text tone="subdued" variant='bodyMd' as='p' fontWeight='regular'>Tell us your monthly visitors traffic & requirements. </Text>
              </BlockStack>
              <Button id='chat_Us' onClick={() => props.handleChatButton()}>Chat with us</Button>
            </InlineStack>
          </Box>
        </div>
      </Card >
    </div >
  )
}

export default BoardingStep3