import React, { useState, useEffect } from "react";
import {
  IndexTable, useSetIndexFiltersMode, Divider, InlineStack, DatePicker, IndexFilters, useBreakpoints, Text, Card, Page, Button, Popover, Box, ButtonGroup, Modal, PageActions, InlineGrid, TextField, OptionList, Tooltip,
  BlockStack, IndexFiltersMode, EmptySearchResult
} from "@shopify/polaris";
import { EditIcon, ViewIcon, DeleteIcon, } from "@shopify/polaris-icons";
import { skeleton } from '../../utils/skeleton';
import { Link } from 'react-router-dom';

function DataPageCard(props) {
  const { state, changeNameValue, handleSelectDate } = props;
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const { mdDown, lgUp } = useBreakpoints();
  const checkValidDate = (dateString) => {
    if (dateString && !dateString.includes("Invalid")) {
      return true;
    } else {
      return false;
    }
  };

  const [activeDateRange, setActiveDateRange] = useState({
    since: new Date(new Date().setHours(0, 0, 0, 0)),
    until: new Date(new Date().setHours(0, 0, 0, 0)),
  });

  const [{ month, year }, setDate] = useState({
    month: activeDateRange.since.getMonth(),
    year: activeDateRange.since.getFullYear(),
  });

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.since.toDateString(),
      tdt: state.until.toDateString(),
    };
    changeNameValue(obj);
  };

  const handleCalendarChange = ({ start, end }) => {
    const newDateRange = { since: start, until: end };
    changeNameValue({
      frmdt: newDateRange.since.toDateString(),
      tdt: newDateRange.until.toDateString(),
    });
    activeRangeChange(newDateRange);
  };

  // Location (ex: city, state, country)
  const values = [state.viewD.ct, state.viewD.st, state.viewD.cn];
  const nonEmptyValues = values.filter(value => value).join(', ');
  //Notification
  const cnid = state.viewD && state.viewD.cnid ? state.viewD.cnid.filter(item => item) : []

  //social-media options
  const sourceOption = [
    { value: 'google', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/ggle.svg" alt="Google" /> <span>&nbsp; Google</span> </div> },
    { value: 'facebook', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/fb.svg" alt="Facebook" /> <span>&nbsp; Facebook</span> </div> },
    { value: 'twitterpost', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/twit.svg" alt="Twitter" /> <span>&nbsp; Twitter</span> </div> },
    { value: 'instagrampost', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/insta.svg" alt="Instagram" /> <span>&nbsp; Instagram</span> </div> },
    { value: 'linkedin', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/in.svg" alt="LinkedIn" /> <span>&nbsp; LinkedIn</span> </div> },
    { value: 'g2', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/g2.svg" alt="G2" /> <span>&nbsp; G2</span> </div> },
    { value: 'capterra', label: <div className="option-item"><img className="drop-icons" src="https://storage.googleapis.com/widget_img/widget/cap.svg" alt="Capterra" /> <span>&nbsp; Capterra</span> </div> },
  ];

  const getSelectedLabel = (selectedValue) => {
    const selectedOption = sourceOption.find(option => option.value === selectedValue);
    return selectedOption ? selectedOption.label : 'Select';
  };

  //no data found
  const promotedBulkActions = [
    {
      content: 'Delete Selected Data',
      onAction: () => {
        if (state.apiD === false) {
          props.openCloseModal('deleteDataModal', state.deleteDataModal, 'open');
        }
      }
    }];

  //table url link
  const handleLink = (url) => {
    window.open(`${url}`, '_blank');
  }

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No sales, leads, or reviews records found.'}
      description={'Ensure your integration sends the expected data for notifications'}
      withIllustration
    />
  );

  //skeleton show when data is coming
  useEffect(() => {
    if (props.isLoading || props.datalistStatus?.length === undefined) {
      changeNameValue({ isReloadIntData: true });
    } else {
      changeNameValue({ isReloadIntData: false });
    }
  }, [props.isLoading, state.TotalData]);

  //get time
  function getSeenTime(date, txt) {
    const currentDate = new Date();
    const previousDate = new Date(date);

    const diffInMilliseconds = currentDate - previousDate; // Get the difference in milliseconds
    const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60); // Convert to minutes

    // Define time units
    const minutes = diffInMinutes % 60;
    const hours = Math.floor(diffInMinutes / 60) % 24;
    const days = Math.floor(diffInMinutes / 60 / 24);
    const months = Math.floor(diffInMinutes / 60 / 24 / 30) % 12;
    const years = Math.floor(diffInMinutes / 60 / 24 / 365);
    if (txt) {
      if (years > 0) {
        return `${txt} ${years} year${years > 1 ? 's' : ''} ago`;
      } else if (months > 0) {
        return `${txt} ${months} month${months > 1 ? 's' : ''} ago`;
      } else if (days > 0) {
        return `${txt} ${days} day${days > 1 ? 's' : ''} ago`;
      } else if (hours > 0) {
        return `${txt} ${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (minutes > 0) {
        return `${txt} ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else {
        return 'just now';
      }
    }
  }

  //function for set min-height of table if user has selected custom date
  const newTestFunction = (e) => {
    setTimeout(() => {
      let customStyle = document.getElementById('ab-test-dynamic-style');

      if (document.getElementById('notif_cstm_dtPop') && document.getElementById('notif_cstm_dtPop').style.display === 'block') {
        if (!customStyle) {
          customStyle = document.createElement('style');
          customStyle.id = 'ab-test-dynamic-style';
          customStyle.textContent = `
                #tbl-performance .Polaris-IndexTable-ScrollContainer {
                  min-height: 350px;
                }`;
          document.head.appendChild(customStyle);
        }
      }
      else {
        if (customStyle) {
          customStyle.remove();
        }
      }
    }, 10);
  }

  return (
    <Page title="Data"
      primaryAction={props.checkPermission('data', 'isdownload', props.profile) &&
        <Button disabled={state.isUrl} onClick={() => { props.handleExcelData() }} variant="tertiary">Download excel</Button>
      }
    >
      <Box paddingBlockEnd={400}>
        <Card padding={0}>
          <div id="none">
            <IndexFilters
              queryValue={state.queryValue}
              queryPlaceholder="Search..."
              onQueryChange={(e) => changeNameValue({ queryValue: e })}
              onQueryClear={() => changeNameValue({ queryValue: '' })}
              cancelAction={{
                onAction: () => changeNameValue({
                  dTyp: "", sPN: "", sCU: "", sSoC: "", fuid: "", sourceL: "", selected: 0, sE: "", paggiActive: 1, sourceNotif: "", nid: "", hasRefresh: true
                }),
              }}
              tabs={props.tabs}
              canCreateNewView={false}
              selected={state.selected}
              // onSelect={(e) => changeNameValue({ selected: e })}
              onSelect={(e) => { changeNameValue({ selected: e }); newTestFunction(e); }}
              filters={props.filters}
              appliedFilters={props.appliedFilters}
              onClearAll={props.handleFiltersClearAll}
              mode={mode}
              setMode={setMode}

            />
            {/* custom date popup */}
            {/* <div className="custom-date" style={{ display: state.popoverActive ? "block" : "none" }} > */}
            <div className='notif_cstm_dtPop' id='notif_cstm_dtPop' style={{ display: state.popoverActive ? 'block' : 'none' }}>
              <Box maxWidth="580px">
                <Card padding={200}>
                  <Popover.Pane>
                    <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                      <InlineStack gap="400">
                        <DatePicker
                          month={month}
                          year={year}
                          selected={{ start: activeDateRange.since, end: activeDateRange.until }}
                          onMonthChange={(month, year) => setDate({ month, year })}
                          onChange={handleCalendarChange}
                          disableDatesAfter={new Date()}
                          multiMonth={lgUp}
                          allowRange
                        />
                      </InlineStack>
                    </Box>
                  </Popover.Pane>
                  <Divider />
                  <Box padding={200}>
                    <InlineStack gap={600} align="end" blockAlign="center">
                      <Text>{checkValidDate(state.frmdt) && checkValidDate(state.tdt) ? state.frmdt + " - " + state.tdt : ""} </Text>
                      <ButtonGroup>
                        <Button variant="tertiary" onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                        <Button variant="primary" onClick={handleSelectDate}>Apply</Button>
                      </ButtonGroup>
                    </InlineStack>
                  </Box>
                </Card>
              </Box>
            </div>
          </div>
          <div className='tbl-performance' id='tbl-performance'>
            <IndexTable
              itemCount={state.isReloadIntData === true ? 10 : (props.datalistStatus?.length - 1 || 0)}
              selectedItemsCount={props.selectedResources.length}
              onSelectionChange={!props.checkPermission('data', 'isedit', props.profile) ? () => { } : props.handleSelectionChange}
              promotedBulkActions={promotedBulkActions}
              selectable={true}
              headings={[
                { title: "Username" },
                { title: "Product" },
                { title: "Source" },
                { title: "Capture URL" },
                { title: "Action" },
              ]}
              pagination={{
                hasPrevious: (state.paggiActive > 1) ? true : false,
                hasNext: (state.ttlDtCnt === 31) ? true : false,
                onPrevious: () => props.onPaggiCall('-1'),
                onNext: () => props.onPaggiCall('+1')
              }}
              emptyState={emptyStateMarkup}
            >
              {
                state.isReloadIntData ?
                  skeleton(10, 5)
                  :
                  state?.TotalData && state?.TotalData.length > 0 && state?.TotalData?.map((data, index) => {
                    const combinedText = `${data.fn || ''} ${data.ln || ''}`.trim() || '-';
                    const displayText = combinedText.length > 30 ? `${combinedText.slice(0, 30)}...` : combinedText;
                    return (
                      <IndexTable.Row
                        id={data._id}
                        key={index}
                        position={index}
                        selected={props.selectedResources.includes(data._id)}
                        onClick={() => changeNameValue({})}
                        disabled={!props.checkPermission('data', 'isedit', props.profile)}
                      >

                        {/* Username */}
                        <IndexTable.Cell>
                          <Text as="h6" fontWeight="medium" variant="headingXs">{displayText}</Text>
                          <InlineStack>
                            {data.ct || data.st || data.cn ? (
                              <>
                                <Box paddingInlineStart={100}>
                                  <div className="places">
                                    <Text as="h6" variant="bodyXs" tone="subdued">
                                      {
                                        [data.ct, data.st, data.cn].filter(Boolean).join(', ') || "-"
                                      }
                                    </Text>
                                  </div>
                                </Box>
                              </>
                            ) : (
                              <div className="not_available">
                                <Text as="h6" variant="bodyXs" tone="subdued">Location not available</Text>
                              </div>
                            )}
                          </InlineStack>
                          <Box paddingBlockStart={100}>
                            <Text as="h6" variant="bodyXs" tone="subdued">
                              {/* <div className="font_size_sm time">{new Date(data.insdt).toLocaleString()}</div> */}
                              <div className="font_size_sm time">{data.insdt ? getSeenTime(new Date(data.insdt), 'Updated') : getSeenTime(new Date(), 'Updated')}</div>
                            </Text>
                          </Box>
                        </IndexTable.Cell>

                        {/* Product */}
                        <IndexTable.Cell>
                          <Text as='h6' variant="bodySm">
                            <BlockStack>
                              <div className="product">
                                {data.pn || data.pdid ? (
                                  <>
                                    <Text>{data.pn && data.pn.length > 60 ? data.pn.slice(0, 60) + '...' : data.pn || ''}</Text>
                                    <Text>{data.pdid || ''}</Text>
                                  </>
                                ) : (
                                  <Text>-</Text>
                                )}
                              </div>
                            </BlockStack>
                          </Text>
                        </IndexTable.Cell>

                        {/* Source */}
                        <IndexTable.Cell>
                          <Text as="h6" variant="bodySm">
                            {/* <span>{data.fa.charAt(0).toUpperCase() + data.fa.slice(1)}</span> */}
                            <span>{data?.fa ? data?.fa.charAt(0).toUpperCase() + data?.fa.slice(1) : ''}</span>
                          </Text>
                          <Text as="h6" variant="bodyXs" tone="subdued">{data.rmk ? data.rmk : "-"}</Text>
                        </IndexTable.Cell>

                        {/* Capture URL */}
                        <IndexTable.Cell>
                          {data.hrf ?
                            (
                              <Tooltip content={data.hrf} width='wide' padding="400" dismissOnMouseOut >
                                <Button variant="plain" onClick={() => handleLink(data.hrf)}>
                                  <span className="text_break_hrf">
                                    {data.hrf && data.hrf.length > 60 ? data.hrf.slice(0, 40) + "..." : data.hrf || ""}
                                  </span>
                                </Button>
                              </Tooltip>
                            )
                            :
                            ("-")
                          }
                        </IndexTable.Cell>

                        {/* Action */}
                        <IndexTable.Cell>
                          <InlineStack wrap={false} gap={100}>
                            <Button variant="monochromePlain" icon={ViewIcon} onClick={() => { props.handleViewData(data) }} />
                            {props.checkPermission('data', 'isedit', props.profile) &&
                              <Button variant="monochromePlain" icon={EditIcon} onClick={() => { props.handleEditData(data, data._id) }} />
                            }
                            <Button variant="monochromePlain" icon={DeleteIcon} onClick={() => { props.handleDeleteData(data._id); changeNameValue({ apiD: true }) }} />
                          </InlineStack>
                        </IndexTable.Cell>

                      </IndexTable.Row>
                    );
                  })
              }
            </IndexTable>
          </div>
        </Card>
      </Box>

      {/* delete data modal */}
      <Modal
        open={state.deleteDataModal}
        title={<Text variant='headingMd' fontWeight='bold'>Delete ?</Text>}
        onClose={(e) => { props.openCloseModal('deleteDataModal', state.deleteDataModal, e); changeNameValue({ apiD: false }); }}>
        <Modal.Section>
          <Box paddingBlockEnd={400}>
            <Text variant="bodyMd" as="p" tone="subdued"> This record will be removed. </Text>
          </Box>
          <Divider />
          <PageActions
            primaryAction={{
              icon: DeleteIcon, content: 'Delete', tone: 'critical',
              onAction: () => {
                if (state.apiD) {
                  props.handleDeleteCall(state.dataId);
                } else {
                  props.actionListClick(('delete'));
                }
                changeNameValue({ apiD: false });
              }
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => { props.openCloseModal('deleteDataModal', state.deleteDataModal, 'close'); changeNameValue({ apiD: false }); }
              }
            ]}
          />
        </Modal.Section>
      </Modal>

      {/* view data modal  */}
      < Modal
        open={state.viewDataModal}
        title={<Text variant='headingMd' fontWeight='bold'><div id="view_lead_detail">Lead detail</div></Text>}
        onClose={(e) => props.openCloseModal('viewDataModal', state.viewDataModal, e)}
      >
        <Modal.Section>
          <Box paddingBlockEnd={400} paddingBlockStart={300}>

            <InlineGrid gap="400" columns={2}>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">User name</Text>
                <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.un}</Text>
              </Box>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">First name</Text>
                <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.fn}</Text>
              </Box>
              {state.viewD.ln && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">Last name</Text>
                  <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.ln}</Text>
                </Box>
              )}
              {state.viewD.e && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">Email</Text>
                  <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.e}</Text>
                </Box>
              )}
              {state.viewD.fa && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">Source</Text>
                  <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.fa}</Text>
                </Box>
              )}
              {state.viewD.lt && state.viewD.lg && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">Coordinates</Text>
                  <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.lt}, {state.viewD.lg}</Text>
                </Box>
              )}
              {state.viewD.pgn && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">Page name</Text>
                  <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.pgn}</Text>
                </Box>
              )}
              {state.viewD.ht && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">Host name</Text>
                  <Link to={'http://' + state.viewD.ht} target="_blank" variant="bodyMd" as="p" tone="subdued">
                    <span className="all_link_color">
                      {state.viewD.ht}
                    </span>
                  </Link>
                </Box>
              )}
              {state.viewD.rtng && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">Rating</Text>
                  <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.rtng}</Text>
                </Box>
              )}
              <Box>
                <Text v variant="bodyLg" fontWeight="medium">Location</Text>
                <Text variant="bodyMd" as="p" tone="subdued"> {nonEmptyValues || '-'}</Text>
              </Box>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">Created date</Text>
                <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.createdDate}</Text>
              </Box>
              {state.viewD.i && (
                <Box>
                  <Text variant="bodyLg" fontWeight="medium">IP Address</Text>
                  <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.i}</Text>
                </Box>
              )}
            </InlineGrid>

            <Box paddingBlockStart={400} paddingBlockEnd={400}>
              <Text variant="bodyLg" fontWeight="medium">Notification</Text>
              <Text variant="bodyMd" as="p" tone="subdued"> {cnid.length > 0 ? cnid.join(', ') : '-'}</Text>
            </Box>
            {state.viewD.pu && (
              <Box>
                <Text variant="bodyLg" fontWeight="medium">Product URL</Text>
                <Link target="_blank" to={state.viewD.pu}>
                  <span className="all_link_color">
                    {state.viewD.pu}
                  </span>
                </Link>
              </Box>
            )}
            {state.viewD.hrf && (
              <Box >
                <Text variant="bodyLg" fontWeight="medium">Capture page</Text>
                <Link target="_blank" to={state.viewD.hrf}>
                  <span className="all_link_color">
                    {state.viewD.hrf}
                  </span>
                </Link>
              </Box>
            )}
            {state.viewD.piu && (() => {
              const maxLength = 85;
              const piu = state.viewD.piu.length > maxLength ? `${state.viewD.piu.slice(0, maxLength)}...` : state.viewD.piu;
              return (
                <Box paddingBlockStart={300}>
                  <Text variant="bodyLg" fontWeight="medium">Image URL</Text>
                  <Link target="_blank" to={state.viewD.piu}>
                    <span className="all_link_color">
                      {piu}
                    </span>
                  </Link>
                </Box>
              );
            })()}
            {state.viewD.rtxt && (
              <Box paddingBlockStart={400} paddingBlockEnd={400}>
                <Text variant="bodyLg" fontWeight="medium">Reviewer text</Text>
                <Text variant="bodyMd" as="p" tone="subdued">{state.viewD.rtxt}</Text>
              </Box>
            )}
          </Box>
        </Modal.Section>
      </Modal >

      {/* edit data modal */}
      <Modal
        open={state.EditDataModal}
        title={<Text variant='headingMd' fontWeight='bold'><div id="view_lead_detail">Update lead detail</div></Text>}
        onClose={(e) => props.openCloseModal('EditDataModal', state.EditDataModal, e)}>
        <Modal.Section>
          <Box paddingBlockEnd={400} paddingBlockStart={300}>
            <InlineGrid gap="400" columns={2}>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">First name</Text>
                <TextField
                  placeholder="First name"
                  value={state.editD.fn}
                  onChange={(e) => changeNameValue({ editD: { ...state.editD, fn: e } })}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">Last name</Text>
                <TextField
                  placeholder="Last name"
                  value={state.editD.ln}
                  onChange={(e) => changeNameValue({ editD: { ...state.editD, ln: e } })}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">User name</Text>
                <TextField
                  placeholder="User name"
                  value={state.editD.un}
                  onChange={(e) => changeNameValue({ editD: { ...state.editD, un: e } })}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">City</Text>
                <TextField
                  placeholder="City"
                  value={state.editD.ct}
                  onChange={(e) => changeNameValue({ editD: { ...state.editD, ct: e } })}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">State</Text>
                <TextField
                  placeholder="State"
                  value={state.editD.st}
                  onChange={(e) => changeNameValue({ editD: { ...state.editD, st: e } })}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Text variant="bodyLg" fontWeight="medium">Country</Text>
                <TextField
                  placeholder="Country"
                  value={state.editD.cn}
                  onChange={(e) => changeNameValue({ editD: { ...state.editD, cn: e } })}
                  autoComplete="off"
                />
              </Box>
            </InlineGrid>

            {/* Reviewer Source */}
            {state.editD?.rtng && (
              <Box paddingBlockStart={400}>
                <Text variant="bodyLg" fontWeight="medium">Reviewer source</Text>
                <Popover
                  fullWidth
                  active={state.rSource}
                  onClose={() => changeNameValue({ rSource: !state.rSource, styp: state?.styp })}
                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                    onClick={() => changeNameValue({ rSource: !state.rSource })}>
                    <Text fontWeight='medium' variant='bodyMd'>
                      {getSelectedLabel(state.styp)}
                    </Text>
                  </Button>}>
                  <OptionList
                    options={sourceOption}
                    selected={state.styp ? [state.styp] : []}
                    onChange={(e) => changeNameValue({ styp: e[0], rSource: false })}
                    onFocusOption={(e) => props.handleMainTextChange(e)}
                  />
                </Popover>
              </Box>
            )}

          </Box>
          <Divider />
          <PageActions
            primaryAction={{ content: 'Update', tone: 'success', onAction: () => props.handleEditCall(state.dataId) }}
          />
        </Modal.Section>
      </Modal>

      {/* Download Excel modal */}
      <Modal
        open={state.ExcelModal}
        title={<Text variant='headingMd' fontWeight='bold'>Login</Text>}
        onClose={(e) => { props.openCloseModal('ExcelModal', state.ExcelModal, e) }}>
        <Modal.Section>
          <Box paddingBlockEnd={400}>
            <TextField
              type="password"
              label="Enter your login password to download this file"
              value={state.pd}
              onChange={(e) => changeNameValue({ pd: e })}
              placeholder="Enter your password"
              autoComplete="off"
            />
          </Box>
          <Divider />
          <PageActions
            primaryAction={{
              content: 'Submit', tone: 'success',
              onAction: () => {
                props.handleExcelCall();
              }
            }}
          />
        </Modal.Section>
      </Modal>
    </Page >
  );
}

export default DataPageCard;
