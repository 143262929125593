import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const notif = createSlice({
  name: 'notif',
  initialState,
  reducers: {
    getNotif: (state, action) => { },
    getNotifSuccess: (state, action) => {
      return { ...state, notif: action.payload };
    },
    getNotiHght: (state, action) => { },
    getNotiHghtSuccess: (state, action) => {
      return { ...state, notifHeight: action.payload };
    },
    getNotifTypeList: (state, action) => { },
    getNotifTypeListSuccess: (state, action) => { return { ...state, notifTypeList: action.payload } },
    getNotiByUser: (state, action) => { },
    getNotiByUserSuccess: (state, action) => { return { ...state, notifByUser: action.payload } },
    getNotifActivityByUser: (state, action) => { },
    getNotifActivityByUserSuccess: (state, action) => {
      return { ...state, notifActivityByUser: action.payload };
    },
    getUserNotifData: (state, action) => { },
    getUserNotifDataSuccess: (state, action) => {
      return { ...state, getUserNotifData: action.payload };
    },
    updNotifStatus: (state, action) => { },
    updNotifStatusSuccess: (state, action) => {
      return { ...state, activeStatus: action.payload };
    },
    createNotif: (state, action) => { },
    createNotifSuccess: (state, action) => { return { ...state, resCreateNotif: action.payload } },
    notifClear: (state, action) => { return { ...state, resCreateNotif: null } },
    delNotif: (state, action) => { },
    createInlineNotif: (state, action) => { },
    deleteInlineNotif: (state, action) => { },
    createCloneNotif: (state, action) => { },
    getNotifPixel: (state, action) => { },
    getNotifPixelSuccess: (state, action) => { return { ...state, notifPixel: action.payload } },
    smartDebugger: (state, action) => { },
    smartDebuggerSuccess: (state, action) => { return { ...state, smartDebuggerList: action.payload } },
    updMerge: (state, action) => { },
    updNotifName: (state, action) => { },
    createAbTestNotif: (state, action) => { },
    abTestNotifSuccess: (state, action) => {
      return { ...state, abTestNotif: action.payload };
    },
    abTestClear: (state, action) => { return { ...state, abTestNotif: null } },
    updAbTestNotif: (state, action) => { },
    setOriginalNotif: (state, action) => { },
    deleteAbTestNotif: (state, action) => { },
    getAbAnalyticEs: (state, action) => { },
    getAbAnalyticEsSuccess: (state, action) => { return { ...state, getAbAnalyticEs: action.payload } },
    getRecentCapturedData: (state, action) => { },
    getRecentCapturedDataSuccess: (state, action) => { return { ...state, recentData: action.payload } },
    getActiveIntgList: (state, action) => { },
    getActiveIntgListSuccess: (state, action) => { return { ...state, lstActiveIntg: action.payload } },
    createDummyData: (state, action) => { },
    getDumyDataSuccess: (state, action) => { return { ...state, dummyTestData: action.payload } },
  }
});

export const {
  getNotif,
  getNotifSuccess,
  getNotiHght,
  getNotiHghtSuccess,
  getNotifTypeList,
  getNotifTypeListSuccess,
  getNotiByUser,
  getNotiByUserSuccess,
  getNotifActivityByUser,
  getNotifActivityByUserSuccess,
  getUserNotifData,
  getUserNotifDataSuccess,
  updNotifStatus,
  updNotifStatusSuccess,
  createNotif,
  createNotifSuccess,
  notifClear,
  delNotif,
  createInlineNotif,
  deleteInlineNotif,
  createCloneNotif,
  getNotifPixel,
  getNotifPixelSuccess,
  smartDebugger,
  smartDebuggerSuccess,
  updMerge,
  updNotifName,
  createAbTestNotif,
  abTestNotifSuccess,
  abTestClear,
  updAbTestNotif,
  setOriginalNotif,
  deleteAbTestNotif,
  getAbAnalyticEs,
  getAbAnalyticEsSuccess,
  getRecentCapturedData,
  getRecentCapturedDataSuccess,
  getActiveIntgList,
  getActiveIntgListSuccess,
  createDummyData,
  getDumyDataSuccess
} = notif.actions;

export default notif.reducer;