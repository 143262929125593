import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/referEarn';
import { toastify } from '../ducks/toast';
import { getProfile } from '../ducks/auth';

function* getAffData() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getAffData');

    if (res.status === 'success') {
      yield put(actions.getAffDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getStatus() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getStatus');

    if (res.status === 'success') {
      yield put(actions.getStatusSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendAffMail(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/sendAffKeyMail', action.payload);

    if (res.status === 'success') {
      yield put(actions.sendAffMailSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getWdlRequest() {

  try {
    yield put(load());
    const res = yield call(api.GET, '/getWdlRequestdata');

    if (res.status === 'success') {
      yield put(actions.getWdlRequestSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* afiApply(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/afiApply', action.payload);

    if (res.status === 'success') {
      yield put(actions.afiApplySuccess(res.data));
      yield put(getProfile());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to send affiltate apply request' }));
    }
  }
  catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* affWdlRequest(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/affWdlRequest', action.payload);
    if (res.status === 'success') {
      yield put(actions.getWdlRequest());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


export function* watchGetAffData() {
  yield takeLatest(actions.getAffData.type, getAffData);
}

export function* watchGetStatus() {
  yield takeLatest(actions.getStatus.type, getStatus)
}

export function* watchSendAffMail() {
  yield takeLatest(actions.sendAffMail.type, sendAffMail)
}

export function* watchGetWdlRequest() {
  yield takeLatest(actions.getWdlRequest.type, getWdlRequest)
}

export function* watchAffapply() {
  yield takeLatest(actions.afiApply.type, afiApply)
}

export function* watchAffWdlReq() {
  yield takeLatest(actions.affWdlRequest.type, affWdlRequest)
}

export default function* rootSaga() {
  yield fork(watchGetAffData);
  yield fork(watchGetStatus);
  yield fork(watchSendAffMail);
  yield fork(watchGetWdlRequest);
  yield fork(watchAffapply);
  yield fork(watchAffWdlReq);
}
