import React, { useCallback, useEffect, useState } from 'react';
import { SignUpCard } from "../../components";
import { useDispatch } from 'react-redux';
import * as loginDucks from '../../ducks/login';
import SimpleReactValidator from "simple-react-validator";
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import '../Login/Login.css';
import { encrypt } from '../../utils/passwordService';
import './SignUp.css';

const SignUp = () => {
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });
  const cookies = new Cookies();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let user = queryParams.get("u");

  const [state, setState] = useState({
    profile: {},
    errMessage: {},
    fn: '',
    e: '',
    pd: '',
    rpt: '',
    store: '',
    openClose: false,
    userResponse: {},
    un: '',
    utm: '',
    aff: '',
    cd: '',
    lctn: {},
    cldtkn: ''
  })

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const profileState = useSelector(state => state.auth.profile);
  const isLoading = useSelector(state => state.loading.isLoading);

  useEffect(() => {
    //set profile state data.
    if (profileState) {
      changeNameValue({ profile: profileState });
    }
    if (user) {
      changeNameValue({ rpt: user });
    }
  }, [profileState, user]);

  useEffect(() => {
    if (window.turnstile && document.getElementById('cloudflare-turnstile')) {
      setTimeout(() => {
        try {
          window.turnstile.render('#cloudflare-turnstile', {
            sitekey: '0x4AAAAAAAR6g2LVrzvAVvO3',
            theme: 'light',
            language: 'auto',
            retry: 'auto'
          });
          window.document.getElementById('cloudflare-skeleton').style.display = 'none';
        } catch (error) {
          console.warn(error);
        }
      }, 1000);
    }
  }, []);

  //handle Signup.
  const handleSignup = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    } else {
      changeNameValue({ errMessage: {} });

      let pass = encrypt(state.pd);
      state.aff = cookies.get('aff');// localStorage.getItem('aff');
      //tracking user acquisition history
      state.track_ref = cookies.get('track_ref');
      state.track_entry = cookies.get('track_entry');
      state.track_exit = cookies.get('track_exit');
      if (cookies.get('utyp')) {
        state.utyp = cookies.get('utyp');
      }
      if (localStorage.getItem('W_GEO')) {
        let location = JSON.parse(localStorage.getItem('W_GEO'));
        state.lctn.ct = location.city;
        state.lctn.st = location.state;
        state.lctn.cury = location.country;
        state.lctn.ip = location.ip;
      }

      //get cloudflare token 
      if (document.querySelector('input[name="cf-turnstile-response"]')) {
        state.cldtkn = document.querySelector('input[name="cf-turnstile-response"]').value;
      }

      let obj = {
        fn: state.fn,
        e: state.e,
        pd: pass,
        aff: state.aff,
        cd: state.cd,
        cldtkn: state.cldtkn,
        lctn: state.lctn,
        openClose: state.openClose,
        store: state.store,
        track_entry: state.track_entry,
        track_exit: state.track_exit,
        track_ref: state.track_ref,
        un: state.un,
        userResponse: state.userResponse,
        utm: state.utm
      }
      dispatch(loginDucks.signup(obj));
    }
  }

  //handle Login With Google.
  const handleLoginWithGoogle = () => {
    setTimeout(() => {
      let tracker = '';
      try {
        tracker = window.ga.getAll()[0].get('clientId');
        fetch(process.env.REACT_APP_API_URL + '/updateGID?gclntid=' + tracker, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then(res => res.json()).then(() => { });
      } catch (ex) {
        console.log(ex);
      }
      // if (window.mixpanel) {
      //   window.mixpanel.track('google_signup_click', {
      //     '$email': ''
      //   });
      // }
      window.gapi.load('auth2', function () {
        let auth2 = window.gapi.auth2.init({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          scope: 'openid'
        });
        auth2.signIn().then(function () {
          const email = auth2.currentUser.get().getBasicProfile().getEmail();
          const firstName = auth2.currentUser.get().getBasicProfile().getGivenName();
          const googleAuthID = auth2.currentUser.get().getBasicProfile().getId();
          const imageURL = auth2.currentUser.get().getBasicProfile().getImageUrl();
          //Eea=Google auth id
          //U3=Email
          //Paa=Profile image
          //ig=First name
          let og = { Eea: googleAuthID, U3: email, Paa: imageURL, ig: firstName };
          if (cookies.get('aff')) {
            og.aff = cookies.get('aff');
          }
          if (state.utm) {
            og.utm = state.utm;
          }
          if (cookies.get('utyp')) {
            og.utyp = cookies.get('utyp');
          }
          if (localStorage.getItem('W_GEO')) {
            let location = JSON.parse(localStorage.getItem('W_GEO'));
            og.lctn = {
              ct: location.city,
              st: location.state,
              cury: location.country,
              ip: location.ip
            };
          }
          if (tracker) {
            og.gclntid = tracker;
          }
          og.track_ref = cookies.get('track_ref');
          og.track_entry = cookies.get('track_entry');
          og.track_exit = cookies.get('track_exit');
          dispatch(loginDucks.loginWithGoogle(og));
        });
      });
    }, 1000);
  }

  //Facebook function.
  const logFB = (obj) => {
    setTimeout(() => {
      let tracker = '';
      try {
        tracker = window.ga.getAll()[0].get('clientId');
        fetch(process.env.REACT_APP_API_URL + '/updateGID?gclntid=' + tracker, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then(res => res.json()).then(() => { });
      } catch (ex) {
        console.log(ex);
      }

      if (localStorage.getItem('UTM_SIGNUP')) {
        obj.utm = localStorage.getItem('UTM_SIGNUP');
      }
      if (cookies.get('aff')) {
        obj.aff = cookies.get('aff');
      }
      if (cookies.get('utyp')) {
        obj.utyp = cookies.get('utyp');
      }
      if (localStorage.getItem('W_GEO')) {
        let location = JSON.parse(localStorage.getItem('W_GEO'));
        obj.lctn = {
          ct: location.city,
          st: location.state,
          cury: location.country,
          ip: location.ip
        };
      }
      if (tracker) {
        obj.gclntid = tracker;
      }
      dispatch(loginDucks.loginWithFacebook(obj));
    }, 1000);
  }

  //handle Login With Facebook.
  const handleLoginWithFacebook = () => {
    window.FB.login((loginResponse) => {
      if (loginResponse.authResponse) {
        window.FB.api(process.env.REACT_APP_FB_LOGIN_AUTH_URL, (userResponse) => {
          this.setState({ userResponse: userResponse });
          logFB(userResponse);
        });
      }
    }, { scope: process.env.REACT_APP_FB_LOGIN_PERMISSION });
  };

  //handle Login With Wix.
  const handleLoginWithWix = () => {
    window.open('https://www.wix.com/app-market/web-solution/wisernotify', '_blank');
    // let objState = {
    //   uk: state.profile._id,
    //   ti: state.profile.ti,
    //   uri: window.location.href,//.replace(/&/gi, '@'),
    //   path: window.location.pathname
    // };
    // const authURL = `https://www.wix.com/installer/install?appId=c43175ad-247c-4a98-afbf-560059c8198e&redirectUrl=${process.env.REACT_APP_API_URL}/wix/oauth&&state=${JSON.stringify(objState)}`;
    // window.open(authURL, '_blank');
  }

  //handle Login With Shopify.
  const handleLoginWithShopify = () => {
    const authURL = `https://apps.shopify.com/wiser-notify`;
    window.open(authURL, '_blank');
  }

  return (
    <SignUpCard
      state={state}
      isLoading={isLoading}
      validator={validator}
      changeNameValue={changeNameValue}
      handleSignup={handleSignup}
      handleLoginWithGoogle={handleLoginWithGoogle}
      handleLoginWithFacebook={handleLoginWithFacebook}
      handleLoginWithWix={handleLoginWithWix}
      handleLoginWithShopify={handleLoginWithShopify}
    />
  );
};

export default SignUp;