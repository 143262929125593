import React, { useCallback, useEffect, useState } from 'react';
import { ResetPwdCard } from "../../components";
import { useDispatch } from 'react-redux';
import * as loginDucks from '../../ducks/login';
import SimpleReactValidator from "simple-react-validator";
import { toastify } from '../../ducks/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Login/Login.css';
import { encrypt } from '../../utils/passwordService';
import { useSelector } from 'react-redux';

const ResetPwd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = new SimpleReactValidator({ locale: 'en' });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let user = queryParams.get("u");

  const [state, setState] = useState({
    errMessage: {},
    pd: '',
    npd: '',
    rpt: ''
  })

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const isLoading = useSelector(state => state.loading.isLoading);

  useEffect(() => {
    if (user) {
      changeNameValue({ rpt: user });
    }
  }, [user]);

  //handle Reset Password.
  const handleResetPD = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    } else {
      changeNameValue({ errMessage: {} });
      if (state.pd === state.npd) {
        let pass = encrypt(state.pd);
        let obj = {
          pd: pass,
          npd: state.npd,
          rpt: state.rpt,
          navigate
        }
        dispatch(loginDucks.userResetPD(obj));
      }
      else {
        dispatch(toastify({ type: 'error', msg: 'New password and conform password not match' }));
      }
    }
  }

  return (
    <ResetPwdCard
      state={state}
      isLoading={isLoading}
      validator={validator}
      changeNameValue={changeNameValue}
      handleResetPD={handleResetPD}
    />
  );
};

export default ResetPwd;