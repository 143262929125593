import React from 'react';
import { BlockStack, Box, Button, Card, Checkbox, InlineStack, Page, Text, TextField } from '@shopify/polaris';
import { Link } from 'react-router-dom';

function WhitelabelLoginCard(props) {
  const { state, validator, changeNameValue } = props;

  return (
    <Page>
      <div className='whitelable_login'>
        <Box paddingBlockEnd={600}>
          <BlockStack inlineAlign='center' gap={300}>
            {state.brand.lg &&
              <img src={state.brand.lg} style={{ width: state.brand.lgwd, height: state.brand.lghgt }} id="brand-image" alt='logo' />
            }
            <h1 className="whlabel-brandname" id="brand-name-line" style={{ fontSize: state.brand.bnfnsz, color: state.brand.bnfnclr, display: state.brand.ibn ? 'block' : 'none' }}> {state.brand.bn}</h1>
          </BlockStack>
        </Box>
        <Card>
          <Box paddingBlockEnd={300}>
            <Text variant='headingLg' as='h3' fontWeight='bold'>Sign in</Text>
          </Box>
          <BlockStack gap={200}>
            <div>
              <TextField
                label="Email"
                value={state.e}
                onChange={(e) => changeNameValue({ e: e })}
                autoComplete="off"
                error={state.errMessage.email ? state.errMessage.email : false}
              />
              {validator.message('email', state.e, 'required')}
            </div>
            <div>
              <TextField
                label="Password"
                value={state.pd}
                onChange={(e) => changeNameValue({ pd: e })}
                autoComplete="off"
                error={state.errMessage.password ? state.errMessage.password : false}
              />
              {validator.message('password', state.pd, 'required')}
            </div>
            <InlineStack blockAlign='center' align='space-between' wrap={false}>
              <Checkbox
                label="Remember me"
                checked={state.rm}
                onChange={(e) => changeNameValue({ rm: e })}
              />
              <Link to={'/forgotPwd'}>
                <Button variant='plain'>Forgot password?</Button>
              </Link>
            </InlineStack>
            <Box paddingBlockStart={100} background='bg-fill-brand-active' borderRadius={200}>
              <div className='whtlbl_lgn_btn' onClick={() => props.handleUserLogin()}>
                <Text variant='headingSm' as='p' fontWeight='regular' alignment='center'>Login</Text>
              </div>
            </Box>
          </BlockStack>
        </Card>
      </div>
    </Page>
  );
}

export default WhitelabelLoginCard;
