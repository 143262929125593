import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip, Select, ButtonGroup } from '@shopify/polaris';
import { ArrowDiagonalIcon, ClipboardIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import IntegrationHeader from './IntegrationHeader';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';
import moment from 'moment';

function HeySummitCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    heySummit: [], //heySummit data
    errMessage: {},
    ak: '', //apikey 
    rmk: '', //name your connection
    dataId: '', //id for the delete data modal
    deleteOpen: false, //open delete modal
    modalC: false, //condition for delete modal
    idForUsInt: '',
    isIntActive: false,
    notifListData: '', //show data in modal
    showEvent: true,
    hsEobj: '',
    hsTobj: ''
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api data.
  const userIntData = useSelector(state => state.integration.userIntData);
  const hsEventData = useSelector(state => state.integration.hsEventData);

  //set heySummit data.
  useEffect(() => {
    changeNameValueCard({ heySummit: userIntData });
  }, [userIntData]);

  //set list data.
  let listData = [];
  useEffect(() => {
    if (hsEventData && hsEventData.event && hsEventData.event.results && hsEventData.event.results.length > 0) {
      for (let i = 0; i < hsEventData.event.results.length; i++) {
        const e = hsEventData.event.results[i];
        listData.push(
          { value: e.id.toString(), label: e.title }
        );
      }
    }
  }, [hsEventData]);

  //redirect to the url.
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect.
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //create HeySummit data.
  const createHeySummit = () => {
    if (!props.validator.allValid()) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      changeNameValueCard({ errMessage: {} });
      let obj = {
        ak: info.ak,
        rmk: info.rmk,
        hsEobj: info.hsEobj,
        hsTobj: info.hsTobj
      };
      dispatch(integrationDucks.createHeysummit(obj));
      changeNameValueCard({ ak: '', rmk: '', hsEobj: '', hsTobj: '', showEvent: true });
    }
  }

  //update api call (toggle button).
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.heySummit.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ heySummit: updatedList, idForUsInt: obj.id, isIntActive: obj.ia });
  };

  //handleStateChangeDrpDOWN.
  const handleStateChangeDrpDOWN = (value, name) => {
    if (name === 'hsEobj') {
      changeNameValueCard({ hsEobj: value.toString(), showTicket: true });
    }
    else if (name === 'evTyp') {
      changeNameValueCard({ evTyp: value });
    }
    else if (name === 'lng') {
      changeNameValueCard({ lng: value });
    }
    else if (name === 'ver') {
      changeNameValueCard({ ver: value });
    }
    else {
      changeNameValueCard({ hsTobj: value.toString() });
    }
  }

  //syncHeysummitEvent.
  const syncHeysummitEvent = () => {
    changeNameValueCard({ showEvent: false });
    dispatch(integrationDucks.syncHeysummitEvent(info.ak));
  }

  //openCloseModal. 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal.
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() });
    }
    openCloseModal('deleteOpen', info.deleteOpen, 'open');
    changeNameValueCard({ dataId: id });
  }

  //delete api call.
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteHeysummit(id));
    openCloseModal('deleteOpen', info.deleteOpen, 'close');
  }

  //copy Endpoint.
  const copyEndpoint = (value) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Endpoint copied" }));
    return;
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>HeySummit</span></div>} subtitle={'Show customer sign ups'} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center">
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl?.ak} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">API key</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.ak}
                        onChange={(e) => changeNameValueCard({ ak: e })}
                        placeholder='API key'
                        autoComplete="off"
                        error={info.errMessage.ak ? "The API key field is required." : false}
                      />
                      {props.validator.message('ak', info.ak, 'required|required')}
                    </div>
                    <Box paddingInlineStart={300}>
                      <Button id='primary_btn' disabled={!info.ak} onClick={() => syncHeysummitEvent()}>Sync event</Button>
                    </Box>
                  </InlineStack>
                </div>
              </Box>
              {info.showEvent === false &&
                <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                  <InlineStack blockAlign="center">
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.hsEobj} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Event list</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <Select
                        placeholder='Select event'
                        options={listData}
                        onChange={(value) => handleStateChangeDrpDOWN(value, 'hsEobj')}
                        value={info.hsEobj}
                        disabled={info.showEvent}
                      />
                    </div>
                  </InlineStack>
                </Box>
              }
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl?.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required|required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box align='center' padding={300}><Button onClick={() => createHeySummit()} variant="primary">Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.heySummit?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Webhook URL' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.heySummit && info.heySummit.length > 0 && info.heySummit.map((data, index) => {
                  if (data._id === info.idForUsInt) {
                    data.ia = info.isIntActive ? info.isIntActive : false;
                  }
                  let wbURL = data.d && data.d.web_url;
                  // if (isWhitelable() === true) {
                  // wbURL = wbURL.replace('https://is.wisernotify.com', window.location.origin);
                  // }
                  return (
                    <IndexTable.Row key={index}>
                      <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                      <IndexTable.Cell><span className="text-break">{data.rmk}</span></IndexTable.Cell>
                      <IndexTable.Cell><Button variant='plain'>{wbURL ? wbURL.slice(0, 26) + '...' : '-'}</Button></IndexTable.Cell>
                      <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                      <IndexTable.Cell>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data.ia}
                            onChange={() => handleIsActive(data)}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <ButtonGroup>
                          {props.checkPermission('integration', 'isdelete', props.profile) &&
                            <div id="delete" onClick={() => handledeleteModal(data._id)}>
                              <Banner hideIcon tone='critical'>
                                <Icon
                                  source={DeleteIcon}
                                  tone="critical"
                                />
                              </Banner>
                            </div>
                          }
                          <Button icon={ClipboardIcon} id='primary_btn' onClick={() => copyEndpoint(wbURL)}></Button>
                        </ButtonGroup>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  )
                })}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {info.modalC === true ?
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>This data-source is being used in the notification.</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
        :
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => handleDeleteCall(info.dataId)
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default HeySummitCard;