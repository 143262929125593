import React, { useEffect, useState } from 'react';
import { ProfileCard } from "../../components";
import * as ProfileDucks from '../../ducks/profile';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { checkPermission } from '../../services/permissions';
import copy from 'copy-to-clipboard';
import { toastify } from '../../ducks/toast';
import "./Profile.css";
import { encrypt } from '../../utils/passwordService';

const Profile = () => {
  const validator = new SimpleReactValidator({ locale: 'en' });

  const dispatch = useDispatch();

  const [state, setState] = useState({
    e: '',//email
    fn: '',//user name
    istm: false,
    isbusr: false,
    ak: '',//api key
    npd: '',
    pd: '',
    esub: {},
    errMessage: {},
    deleteAccount: false,
    deleteOpen: false,
    isCurnPassVisible: false,
    isNewPassVisible: false
  });

  const profile = useSelector(state => state?.auth?.profile)

  const changeNameValue = (obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }

  useEffect(() => {
    changeNameValue(profile);
    if (profile?.userTeam) {
      changeNameValue(profile.userTeam);
    }

    if (profile) {
      if (localStorage.getItem('istm') === 'true') {
        let userImg = profile.userTeam.iu || '';
        changeNameValue({ iu: userImg })
      }
      else {
        let userImg = profile.iu || '';
        changeNameValue({ iu: userImg })
      };
    }

    if (profile?.csub === 'free' || profile?.csub === undefined || profile?.csub === null || profile?.csub === '') {
      changeNameValue({ deleteAccount: true });
    }
  }, [profile]);

  const updateProfile = (type, value) => {
    if (!validator.allValid() && state.fn === '') {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: { ...state.errMessage, fn: 'The fn field is required.' } })
    }
    else {
      const obj = {
        [type]: value,
      }
      if (localStorage.getItem('istm') == 'true') {
        obj.istm = true;
      }
      dispatch(ProfileDucks.updateProfile(obj));
      localStorage.setItem('username', state.fn);
      changeNameValue({ errMessage: { ...state.errMessage, fn: null } })
    }
  }

  let handleCopyApiKey = () => {
    copy(state.ak);
    dispatch(toastify({ type: 'success', msg: "Your API Key is copied." }));
  }

  let handleChangePassword = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
      validator.errorMessages?.pd == null ? changeNameValue({ errMessage: { ...state.errMessage, pd: null, npd: 'The new password field is required.' } }) : validator.errorMessages?.npd == null ? changeNameValue({ errMessage: { ...state.errMessage, pd: 'The current password field is required.', npd: null } }) : changeNameValue({ errMessage: { ...state.errMessage, pd: 'The current password field is required.', npd: 'The new password field is required.' } })
    }
    else {
      let obj = {
        opd: encrypt(state.pd),
        pd: encrypt(state.npd)
      };
      dispatch(ProfileDucks.cPwd(obj));
      if (localStorage.getItem('istm') === 'true') {
        obj.istm = true;
      }
      changeNameValue({ errMessage: { ...state.errMessage, pd: null, npd: null } })
    }
  }

  const handleupdEmailSend = (type, val) => {
    const obj = {
      gl: state.esub?.gl,
      anltc: state.esub?.anltc,
      ntf: state.esub?.ntf
    }
    if (type === 'gl') {
      changeNameValue({ esub: { ...state.esub, gl: val } });
      obj.gl = val;
      dispatch(ProfileDucks.updEmailSend(obj));
    }
    if (type === 'ntf') {
      changeNameValue({ esub: { ...state.esub, ntf: val } });
      obj.ntf = val;
      dispatch(ProfileDucks.updEmailSend(obj));
    }
  }

  const userProfileImg = (file) => {
    changeNameValue({ iu: file.base64 })
    let obj = {
      iu: file.base64
    };
    if (localStorage.getItem('istm') == 'true') {
      obj.istm = true;
    }
    dispatch(ProfileDucks.updProfileImg(obj))
  }

  const handleDelAccount = () => {
    dispatch(ProfileDucks.deleteAccount());
  }

  return (
    <>
      {checkPermission('profile', 'isview', profile) &&
        <ProfileCard
          state={state}
          validator={validator}
          profile={profile}
          checkPermission={checkPermission}
          changeNameValue={changeNameValue}
          updateProfile={updateProfile}
          handleCopyApiKey={handleCopyApiKey}
          handleChangePassword={handleChangePassword}
          handleupdEmailSend={handleupdEmailSend}
          handleDelAccount={handleDelAccount}
          userProfileImg={userProfileImg}
        />
      }
    </>
  );
};

export default Profile;
