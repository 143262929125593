import React from 'react';

function Form(props) {
  return (
    <div>
      User Form
    </div>
  );
}

export default Form;