import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip, OptionList, Popover,
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from "react-router-dom";

function AutomizyCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    errMessage: {},
    notifListData: "",//show data in modal
    Automizy: [],
    ak: '',//API Token
    rmk: '',//cn
    dataId: '',//delete id
    ia: false,//toggle btn
    deleteDataModal: false,//open d-modal
    modalC: false,//condition for delete modal
    apiD: false,//api condition
    selected: [],
    activePopover: null,
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //Automizy data from api
  const getAutomizyData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ Automizy: getAutomizyData });
  }, [getAutomizyData]);

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle on/off button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.Automizy.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ Automizy: updatedList })
  };

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: false })
  }

  //open delete modal for sub data
  const handleSubdeleteModal = (id) => {
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: true, modalC: false })
  }

  //delete api call 
  const handleDeleteCall = () => {
    let obj = {
      id: info.dataId
    }
    dispatch(integrationDucks.deleteAutomizy(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //delete sub data api call
  const handleDeleteSubData = () => {
    let obj = {
      id: info.dataId
    };
    dispatch(integrationDucks.deleteAutomizyList(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //create sub data api call  
  const handleChangeSubData = (id, ak, fuid) => {
    let obj = {
      id: id,
      ak: ak,
      fuid: fuid
    };
    dispatch(integrationDucks.addAutomizyList(obj));
    changeNameValueCard({ activePopover: null });
  };

  //handle toggle
  const handleToggle = (id) => {
    changeNameValueCard({ activePopover: info.activePopover === id ? null : id });
  };

  //create Automizy data 
  const createAutomizy = () => {
    if (props.validator.errorMessages.ak || props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      changeNameValueCard({ errMessage: {} });
      let obj = {
        ak: info.ak,
        rmk: info.rmk,
      };
      dispatch(integrationDucks.createAutomizy(obj));
      changeNameValueCard({ ak: '', rmk: '', errMessage: {} });
    }
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create Automizy data */}
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.at} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">API Token</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.ak}
                        onChange={(e) => changeNameValueCard({ ak: e })}
                        placeholder='API Token'
                        autoComplete="off"
                        error={info.errMessage.ak ? "The secretword field is required." : false}
                      />
                      {props.validator.message('ak', info.ak, 'required|required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box align='center' padding={300}><Button onClick={(e) => createAutomizy(e)} variant="primary">Create</Button></Box>
              <Box padding={400}>
                <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
                <Card padding={0}>
                  <IndexTable
                    itemCount={info.Automizy?.length || 0}
                    headings={[
                      { title: '#' },
                      { title: 'Name' },
                      { title: 'Created date' },
                      { title: 'Active' },
                      { title: 'Action' }
                    ]}
                    selectable={false}
                  >
                    {info.Automizy && info.Automizy.length > 0 && info.Automizy.map((i, index) => {
                      let indexNumber = 0;
                      return (
                        <>
                          <IndexTable.Row key={index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="bold" as="span">
                                {index + 1}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell><span className='amazon-url'>{i.rmk}</span></IndexTable.Cell>
                            <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                            <IndexTable.Cell>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={i.ia}
                                  onChange={() => handleIsActive(i)}
                                />
                                <span className="switch-label"></span>
                                <span className="switch-handle"></span>
                              </label>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <InlineStack>
                                {props.checkPermission('integration', 'isdelete', props.profile) &&
                                  <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                    <Banner hideIcon tone='critical'>
                                      <Icon
                                        source={DeleteIcon}
                                        tone="critical"
                                      />
                                    </Banner>
                                  </div>
                                }
                                {props.checkPermission('integration', 'isadd', props.profile) &&
                                  <Box paddingInline={200}>
                                    < Popover
                                      active={info.activePopover === index}
                                      activator={
                                        <Button onClick={() => {
                                          if (i && i.d && i.d?.am_list && i.d?.am_list.length > 0) {
                                            handleToggle(index);
                                          }
                                        }}
                                        >
                                          <InlineStack>
                                            <div className='add-events'>Add list</div>
                                            <Icon
                                              source={ChevronDownIcon}
                                              tone="base"
                                            />
                                          </InlineStack>

                                        </Button>
                                      }
                                      onClose={() => changeNameValueCard({ activePopover: null })}
                                    >
                                      {i && i.d && i.d?.am_list && i.d?.am_list.length > 0 &&
                                        i.d?.am_list.filter(x => !x.ia).length > 0 ? i.d?.am_list.filter(x => !x.ia).map((obj) => (
                                          <OptionList
                                            onChange={() => handleChangeSubData(obj.id, i.d?.ak, i._id)}
                                            options={[{ value: obj.name, label: obj.name }]}
                                            selected={info.selected}
                                          />
                                        ))
                                        : <span>
                                          No record found
                                        </span>}
                                    </Popover>
                                  </Box>
                                }
                              </InlineStack>
                            </IndexTable.Cell>
                          </IndexTable.Row>

                          {
                            i && i?.d && i?.d?.am_list && i?.d.am_list?.length > 0 &&
                              i?.d?.am_list.filter(x => x.ia === true).length > 0 ? i?.d?.am_list.filter(x => x.ia === true).map((obj, i) => (
                                <IndexTable.Row key={index}>
                                  <IndexTable.Cell></IndexTable.Cell>
                                  <IndexTable.Cell>{++indexNumber}</IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd">
                                      {obj.name}
                                    </Text>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd">{obj.count}</Text>
                                  </IndexTable.Cell>
                                  {props.checkPermission('integration', 'isdelete', props.profile) &&
                                    <IndexTable.Cell>
                                      <div id="delete" onClick={() => handleSubdeleteModal(obj.id)}>
                                        <Banner hideIcon tone="critical">
                                          <Icon source={DeleteIcon} tone="critical" />
                                        </Banner>
                                      </div>
                                    </IndexTable.Cell>
                                  }
                                </IndexTable.Row>
                              ))
                              : null
                          }
                        </>
                      )
                    })}

                  </IndexTable>
                </Card>
              </Box>
            </>
          }
        </Card>
      </BlockStack>


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    if (info.apiD) {
                      handleDeleteSubData();
                    } else {
                      handleDeleteCall();
                    }
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default AutomizyCard;