import React, { useEffect } from 'react';
import { Button, DataTable, InlineStack, Text, Box, Modal, LegacyStack, Select, TextField, PageActions, InlineGrid, BlockStack, Card } from '@shopify/polaris';

import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import AssignNotifCard from './AssignNotifCard';

function ManageTeamCard(props) {
  const { state, changeNameValue } = props;

  let manageTeamList = state.manageTeamList;

  let convertedData = [[]];
  if (manageTeamList?.length > 0) {
    convertedData = manageTeamList.map((elm, index) => {
      let role = state.getRoleList?.find((e) => e._id == elm.ri);
      return [index + 1, elm.fn, elm.e, role?.dn, <InlineStack gap={200}>
        <Button icon={EditIcon} plain onClick={() => changeNameValue({ isUpdateModal: true, selectedMail: elm.e, selectedId: elm._id, selectedfn: elm.fn, selectedri: role?._id, npass: '', cpass: '', errMessage: {} })} />
        <Button icon={DeleteIcon} plain tone='critical' onClick={() => changeNameValue({ selectedMail: elm.e, selectedId: elm._id, isDeleteModal: true })} />
        <Button variant='primary' onClick={() => changeNameValue({ isAssign: true, selectedfn: elm.fn, selectedId: elm._id })}>Assign</Button>
      </InlineStack>];
    });
  }
  const rows = convertedData;

  let _tlq, TeamQuota;
  if (props.profile) {
    _tlq = props.profile.tlq;
    if (props.profile.sutq) {
      _tlq = props.profile.tlq - props.profile.sutq;
    }
    if (localStorage.getItem('utyp') == 'appsumo' && props.profile.apstk && props.profile.apstk.length > 0) {
      TeamQuota = ' ( Remaining quota {LEFT} out of {TOTAL} )';
      TeamQuota = TeamQuota.replace('{LEFT}', _tlq).replace('{TOTAL}', props.profile.taq);
      if (localStorage.getItem('utyp') == 'appsumo' && props.profile.taq === 0) {
        TeamQuota = ' ( Remaining quota UNLIMITED )';
      }
    }
    else if (localStorage.getItem('utyp') !== 'appsumo') {
      TeamQuota = ' ( Remaining quota {LEFT} out of {TOTAL} )';
      TeamQuota = TeamQuota.replace('{LEFT}', _tlq).replace('{TOTAL}', props.profile.taq);
    }
  }

  const options = [
    { label: 'Select', value: '' },
    { label: 'Manager', value: '5e469611d512f800139eb59a' },
    { label: 'Viewer', value: '5f65e25680f9ac0013847af3' },
  ];

  useEffect(() => {
    changeNameValue({ tlq: _tlq });
  }, [_tlq])

  return (<>
    {state.isAssign === false ? <Box>
      <div className='topName'>
        <Box paddingBlock={"400"} paddingInline={"600"}>
          <Text variant="headingLg" as="h5">
            Manage staff
          </Text>
        </Box>
      </div>

      <div className='bgBox'>
        <Box padding={400}>
          <Card padding={0}>
            <div className='innerStack' >
              <InlineStack align='space-between'>
                <Text variant="headingSm" as="h6">List {TeamQuota}</Text>
                {
                  localStorage.getItem('utyp') == 'appsumo' ?
                    props.profile?.apstk?.length > 0 &&
                    <Button variant="primary" tone="success" onClick={() => props.handleAddClikBtnEvent()}>Add team</Button>
                    :
                    <Button variant="primary" tone="success" onClick={() => props.handleAddClikBtnEvent()}>Add team</Button>
                }
              </InlineStack>
            </div>
            <DataTable
              columnContentTypes={[
                'text',
                'text',
                'text',
                'text',
                'text',
              ]}
              totals={['No', 'Full name', 'Email', 'Role', 'Action']}
              headings={['']}
              rows={rows[0]?.length === 0 ? [['No Record Found']] : rows}
              totalsName={{
                singular: 'No',
                plural: 'No',
              }}
            />
          </Card>
        </Box>
      </div>
    </Box> :
      <AssignNotifCard props={props} />
    }

    {/*Add Team Modal*/}
    <Modal
      open={state.isAddModal}
      onClose={() => changeNameValue({ isAddModal: false })}
      title="Add team member"
      primaryAction={{
        content: 'Add',
        onAction: () => props.handleAddTeam(),
      }}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <LegacyStack.Item>
            <BlockStack gap={200}>
              <TextField
                label="Email address"
                value={state.e}
                onChange={(e) => changeNameValue({ e: e })}
                autoComplete="off"
                error={state.errMessage.e ? "The email address field is required." : false}
              />
              {props.validator.message('e', state.e, 'required')}

              <TextField
                label="Password"
                value={state.pd}
                onChange={(e) => changeNameValue({ pd: e })}
                autoComplete="off"
                error={state.errMessage.pd ? "The password field is required." : false}
              />
              {props.validator.message('pd', state.pd, 'required')}

              <TextField
                label="Full name"
                value={state.fn}
                onChange={(e) => changeNameValue({ fn: e })}
                autoComplete="off"
                error={state.errMessage.fn ? "The full name field is required." : false}
              />
              {props.validator.message('fn', state.fn, 'required')}

              <Select
                label="Role"
                options={options}
                value={state.ri}
                onChange={(e) => changeNameValue({ ri: e })}
                error={state.errMessage.ri ? "Please select role." : false}
              />
              {props.validator.message('ri', state.ri, 'required')}
            </BlockStack>
          </LegacyStack.Item>
        </LegacyStack>
      </Modal.Section>
    </Modal>

    {/*Update Team Modal*/}
    <Modal
      open={state.isUpdateModal}
      onClose={() => changeNameValue({ isUpdateModal: false })}
      title="Update Team Member"
    >
      <Modal.Section>
        <LegacyStack vertical>
          <LegacyStack.Item>
            <TextField
              label="Email address"
              value={state.selectedMail}
              autoComplete="off"
              disabled
            />

            <TextField
              label="Full name"
              value={state.selectedfn}
              onChange={(e) => changeNameValue({ selectedfn: e })}
              autoComplete="off"
              error={state.errMessage.selectedfn ? "The full name field is required." : false}
            />
            {props.validator.message('selectedfn', state.selectedfn, 'required')}

            <Select
              label="Role"
              options={options}
              value={state.selectedri}
              onChange={(e) => changeNameValue({ selectedri: e })}
              error={state.errMessage.selectedri ? "Please select role." : false}
            />
            {props.validator.message('selectedri', state.selectedri, 'required')}
            <Box padding={300} align='center'>  <Button onClick={() => props.handleUpdTeamMember()} variant="primary">Update</Button></Box>

            <Box paddingBlockEnd={200}><Text variant="headingMd" as="h6">Update password</Text></Box>
            <InlineGrid columns={2} gap={200}>
              <TextField
                label="New password"
                value={state.npass}
                onChange={(e) => changeNameValue({ npass: e })}
                autoComplete="off"
                error={state.errMessage.npass ? "The new password field is required." : false}
              />
              {props.validator.message('npass', state.npass, 'required')}

              <TextField
                label="Confirm password"
                value={state.cpass}
                onChange={(e) => changeNameValue({ cpass: e })}
                autoComplete="off"
                error={state.errMessage.cpass ? "The confirm password field is required." : false}
              />
              {props.validator.message('cpass', state.cpass, 'required')}
            </InlineGrid>
            <Box padding={300} align='center'>  <Button onClick={() => props.handleUpdTeamMember('password')} variant="primary">Update</Button></Box>

          </LegacyStack.Item>
        </LegacyStack>
      </Modal.Section>
    </Modal>


    {/* delete Modal */}
    <Modal
      size='medium'
      open={state.isDeleteModal}
      title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
      onClose={() => changeNameValue({ isDeleteModal: false })}>
      <Modal.Section>
        <div className="pageAction_paDDing">
          <Text fontWeight='medium' variant='headingMd' as='h6'>By clicking on Delete, We will delete this {`(${state.selectedMail})`} account permanently from our server.</Text>
          <PageActions
            primaryAction={<Button id='primary_btn' onClick={() => props.handleDelTeamMember()}>Delete</Button>}
            secondaryActions={[{
              content: 'Close',
              onAction: () => changeNameValue({ isDeleteModal: false })
            }]} />
        </div>
      </Modal.Section>
    </Modal>
  </>);
};

export default ManageTeamCard;
