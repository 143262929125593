import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AgencyCard } from "../../components";
import * as agencyDucks from "../../ducks/agency";
import { toastify } from '../../ducks/toast';
import SimpleReactValidator from "simple-react-validator";
import "./Agency.css";
import copy from 'copy-to-clipboard';
import { checkPermission } from '../../services/permissions';
import { encrypt } from '../../utils/passwordService';

const Agency = () => {
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    TotalData: {},
    wurl: '',//Dashboard URL
    brandImg: {},//Brand logo
    iconImg: {},//Icon logo
    errMessage: {},
    Whitelable: [],
    SubUser: [],
    newPlanModal: false,
    pn: '',
    pid: '',
    uaq: '',
    taq: '',
    pd: '',
    pt: '',
    prc: '',
    pint: 1,
    piunt: 'month',
    crbn: false,
    ccbn: false,
    ccnu: false,
    isEdit: false,
    openClose: false,
    deleteDataModal: false,
    dataId: '',//id for delete
    agencyUser: '',//subuser email address
    dpn: '',//plan name for delete
    EditDataModal: false,//open edit modal 
    queryValue: '',//search value
    createDataModal: false,//open create modal
    SubPlan: [],
    isEmail: true,//default checked
    isbuprm: false,
    loginDataModal: false,//open login modal
    deleteUserModal: false,//open delete user modal
    gid: '',//gmail id for delete user
    editUserModal: false,//open edit user modal
    npd: '',//new password
    blid: 'select',//user plan
    cpd: '',//confirm password
    lg: '',//logo
    fvicn: '',//favicon
  });

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api call
  useEffect(() => {
    dispatch(agencyDucks.getAgency());
    dispatch(agencyDucks.getWhitelabelPlan());
    dispatch(agencyDucks.getSubUser());
    dispatch(agencyDucks.getSubPlan());
  }, []);

  // get data from api
  const getWhiteLabel = useSelector((state) => state.agency?.getagency);
  const profileData = useSelector((state) => state.auth?.profile);
  const getSubUser = useSelector((state) => state.agency?.SubUser);
  const getWhitelabelPlan = useSelector((state) => state.agency?.getWhitelabel);
  const getSubPlan = useSelector((state) => state.agency?.SubPlan);

  //set data in state
  useEffect(() => {
    changeNameValue({ TotalData: getWhiteLabel, SubUser: getSubUser, Whitelable: getWhitelabelPlan, SubPlan: getSubPlan });
  }, [getWhiteLabel, getSubUser, getWhitelabelPlan]);

  //change color of brand name
  const changeColor = (name, key, value) => {
    changeNameValue({ TotalData: { ...getWhiteLabel, bnfnclr: value } })
  };

  //call addUpdateWhiteLabel api
  const handleUpdate = () => {
    if (validator.errorMessages.bn || validator.errorMessages.feadr || validator.errorMessages.fenm || validator.errorMessages.bu) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    } else {
      changeNameValue({ errMessage: {} });
      const obj = {
        'hscr': state.TotalData?.hscr,
        'fscr': state.TotalData?.fscr,
        'feadr': state.TotalData?.feadr,
        'fenm': state.TotalData?.fenm,
        'bn': state.TotalData?.bn,
        'bu': state.TotalData?.bu,
        'nu': state.TotalData?.nu,
        'lgwd': state.TotalData?.lgwd,
        'lghgt': state.TotalData?.lghgt,
        'ibn': state.TotalData?.ibn,
        'bnfnclr': state.TotalData?.bnfnclr,
        'bnfnsz': state.TotalData?.bnfnsz,
        '_id': state.TotalData?._id
      };
      if (state.TotalData?.bu) {
        let BURL = state.TotalData?.bu.split('.');
        if (BURL.length < 2) {
          dispatch(toastify({ type: 'error', msg: "Please enter valid brand URL" }));
          return false;
        }
      }
      if (state.TotalData?.nu) {
        let NURL = state.TotalData?.nu.split('.');
        if (NURL.length < 2) {
          dispatch(toastify({ type: 'error', msg: "Please enter valid notification URL" }));
          return false;
        }
      }
      dispatch(agencyDucks.addUpdateWhiteLabel(obj));
    }
  };

  //call addUpdateCname api
  const handleUpdateCname = () => {
    let wurl = state.TotalData?.wurl || '';
    wurl = wurl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0].trim();

    // Validate URL
    let URLParts = wurl.split('.');
    if (URLParts.length < 2) {
      dispatch(toastify({ type: 'error', msg: "Please enter a valid dashboard URL" }));
      return;
    }
    const obj = {
      wurl: wurl,
      _id: state.TotalData?._id,
    };
    dispatch(agencyDucks.addUpdateCname(obj));
  };

  //Cname url change
  const handleUrlChange = (e) => {
    const updatedUrl = e.replace('https://', '');
    changeNameValue({ TotalData: { ...state.TotalData, wurl: updatedUrl } });
  };

  //updPlanStatus api call
  const updPlanStatus = (id, val) => {
    let obj = {
      id: id,
      val: !val
    };
    dispatch(agencyDucks.updStatusPlan(obj));
  }

  //open close modal
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValue({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handleNewPlan = () => {
    openCloseModal('newPlanModal', state.newPlanModal, 'open');
    changeNameValue({ openClose: true, errMessage: {} });
    changeNameValue(clearState());
  }

  //clear state on close modal
  const clearState = () => ({
    openClose: false,
    uaq: '',
    taq: '',
    pd: '',
    pt: '',
    pn: '',
    prc: '',
    pid: '',
    pint: 1,
    piunt: 'month',
    crbn: false,
    ccbn: false,
    ccnu: false,
    isEdit: false,
  });

  //addUpdatePlan api call
  const addUpdatePlan = () => {
    if (validator.errorMessages.pn || validator.errorMessages.pid || validator.errorMessages.uaq || validator.errorMessages.prc) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages, newPlanModal: true });
    } else {
      const obj = {
        bdu: "",
        id: "",
        pt: state.pt,
        ccnu: state.ccnu,
        openClose: state.openClose,
        pn: state.pn,
        pid: state.pid,
        uaq: state.uaq,
        taq: state.taq,
        pd: state.pd,
        prc: state.prc,
        pint: state.pint,
        piunt: state.piunt,
        crbn: state.crbn,
        ccbn: state.ccbn,
        isEdit: state.isEdit
      };
      dispatch(agencyDucks.addUpdatePlan(obj));
      changeNameValue(clearState());
    }
  }

  //open delete modal
  const handleDeleteData = (id, pnn) => {
    openCloseModal('deleteDataModal', state.deleteDataModal, 'open');
    changeNameValue({ dataId: id, dpn: pnn });
  }

  //delete call api 
  const handleDeleteCall = (id) => {
    let obj = {
      id: id,
    };
    dispatch(agencyDucks.deleteWhitelabelPlan((obj)));
    openCloseModal('deleteDataModal', state.deleteDataModal, 'close');
  };

  //open edit modal
  const handleEditData = (d) => {
    openCloseModal('EditDataModal', state.EditDataModal, 'open');
    let obj = {
      dataId: d._id,
      pn: d.pn,
      pid: d.pid,
      uaq: d.uaq,
      taq: d.taq,
      pd: d.pd,
      prc: d.prc,
      pint: d.pint,
      piunt: d.piunt,
      crbn: d.crbn,
      ccbn: d.ccbn
    }
    changeNameValue(obj);
  }

  //edit call api
  const handleEditCall = () => {
    let obj = {
      id: state.dataId,
      pt: state.pt,
      ccnu: state.ccnu,
      openClose: true,
      pn: state.pn,
      pid: state.pid,
      uaq: state.uaq,
      taq: state.taq,
      pd: state.pd,
      prc: state.prc,
      pint: state.pint,
      piunt: state.piunt,
      crbn: state.crbn,
      ccbn: state.ccbn,
      isEdit: true
    }
    changeNameValue({ openClose: true });
    dispatch(agencyDucks.addUpdatePlan(obj));
    openCloseModal('EditDataModal', state.EditDataModal, 'close');
  };

  //copy pixel code
  const copyPixel = (value) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Sub-user pixel code is copied." }));
    return;
  }

  //search SubUser
  useEffect(() => {
    if (state.queryValue !== '') {
      let filterdt = getSubUser.filter((elm) => {
        return elm.fn.toLowerCase().includes(state.queryValue.toLowerCase()) || elm.e.toLowerCase().includes(state.queryValue.toLowerCase());
      });
      changeNameValue({ SubUser: filterdt })
    }
    else {
      changeNameValue({ SubUser: getSubUser });
    }
  }, [state.queryValue]);

  //open create modal
  const handleNewUser = () => {
    changeNameValue({ e: "", pd: "", fn: "", blid: "select", isbuprm: false, isEmail: true, errMessage: {} });
    openCloseModal('createDataModal', state.createDataModal, 'open');
  }

  //create user api call
  const createUser = () => {
    if (validator.errorMessages.e || validator.errorMessages.pd || validator.errorMessages.fn || validator.errorMessages.blid) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages, createDataModal: true });
    } else {
      if (state.blid === 'select') {
        dispatch(toastify({ type: 'error', msg: "please select plan" }));
        return;
      }
      let pass = encrypt(state.pd);
      state.pd = pass;
      const obj = {
        e: state.e,
        pd: state.pd,
        fn: state.fn,
        blid: state.blid,
        isbuprm: state.isbuprm,
        isEmail: state.isEmail,
        //for default cnf
        'dfcnf.nu': state.TotalData?.bu,    //notification url
        'dfcnf.bu': state.TotalData?.bu,    //brand url
        'dfcnf.bn.txt': state.TotalData?.bn,//brand name
      };
      dispatch(agencyDucks.addUpdateSubUser(obj));
      openCloseModal('createDataModal', state.createDataModal, 'close');
    }
  }

  //login user
  const handleLoginCall = () => {
    let email = encrypt(state.agencyUser);
    let _url = '';
    if (state.TotalData && state.TotalData?.wurl) {
      _url = 'https://' + state.TotalData?.wurl + '/login?n=' + email;
      window.open(_url);
    }
    else {
      _url = window.location.href + '/login?n=' + email;
      localStorage.clear();
      window.location.href = _url;
    }
    openCloseModal('loginDataModal', state.loginDataModal, 'close');
  }

  //open login modal
  const loginDataModal = (id, agencyUser) => {
    openCloseModal('loginDataModal', state.loginDataModal, 'open');
    changeNameValue({ dataId: id, agencyUser: agencyUser });
  }

  //open delete user modal
  const UserDeleteData = (id, e) => {
    openCloseModal('deleteUserModal', state.deleteUserModal, 'open');
    changeNameValue({ dataId: id, gid: e });
  }

  //delete user api call
  const UserDeleteCall = (id) => {
    let obj = {
      id: id,
    };
    dispatch(agencyDucks.deleteSubUser(obj));
    openCloseModal('deleteUserModal', state.deleteUserModal, 'close');
  };

  //open edit user modal
  const UserEditData = (d) => {
    openCloseModal('editUserModal', state.editUserModal, 'open');
    let obj = {
      e: d.e,
      fn: d.fn,
      blid: d.plan?.pid,
      isbuprm: d.isbuprm,
      dataId: d._id,
      errMessage: {}
    }
    changeNameValue(obj);
  }

  //edit user api call
  const editUser = () => {
    let obj = {
      blid: state.blid,
      e: state.e,
      fn: state.fn,
      isbuprm: state.isbuprm,
      oge: state.gid,
      _id: state.dataId,
    }
    dispatch(agencyDucks.addUpdateSubUser(obj));
    openCloseModal('editUserModal', state.editUserModal, 'close');
  }

  //change password
  const userPassword = () => {
    if (validator.errorMessages.npd || validator.errorMessages.cpd) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages, editUserModal: true });
    } else {
      if (state.cpd === state.npd) {
        let obj = {
          _id: state.dataId,
          pd: encrypt(state.npd),
        }
        dispatch(agencyDucks.updSubUserPWD(obj));
        changeNameValue({ npd: '', cpd: '' });
      }
      else {
        dispatch(toastify({ type: 'error', msg: "Password not match" }));
      }
    }
  }

  //brand logo upload
  const brandLogo = (file) => {
    changeNameValue({ lg: file.base64 })
    let obj = {
      lg: file.base64
    };
    if (state.TotalData?._id) {
      obj._id = state.TotalData?._id
    }
    dispatch(agencyDucks.updWhiteLabelLogo(obj))
  }

  useEffect(() => {
    if (getWhiteLabel?.lg || getWhiteLabel?.fvicn) {
      changeNameValue({ lg: getWhiteLabel?.lg, fvicn: getWhiteLabel?.fvicn })
    }
  }, [getWhiteLabel])

  //favicon upload
  const faviconIcon = (file) => {
    changeNameValue({ fvicn: file.base64 })
    let obj = {
      isFvicn: true,
      fvicn: file.base64
    };
    if (state.TotalData?._id) {
      obj._id = state.TotalData?._id
    }
    dispatch(agencyDucks.updWhiteLabelLogo(obj))
  }

  return (
    <>
      {
        checkPermission('white_label_config', 'isview', profileData) &&
        <AgencyCard
          state={state}
          changeNameValue={changeNameValue}
          handleUpdate={handleUpdate}
          handleUpdateCname={handleUpdateCname}
          handleUrlChange={handleUrlChange}
          validator={validator}//validation
          changeColor={changeColor}
          updPlanStatus={updPlanStatus}//checkbox update
          openCloseModal={openCloseModal}
          handleNewPlan={handleNewPlan}
          addUpdatePlan={addUpdatePlan}//add new plan
          profile={profileData}
          handleDeleteCall={handleDeleteCall}//delete data
          handleDeleteData={handleDeleteData}//open delete modal
          handleEditData={handleEditData}//open edit data modal
          handleEditCall={handleEditCall}//edit data
          copyPixel={copyPixel}//copy pixel code
          handleNewUser={handleNewUser}//open create modal
          createUser={createUser}//create new user
          handleLoginCall={handleLoginCall}//login user
          loginDataModal={loginDataModal}//open login modal
          UserDeleteCall={UserDeleteCall}//delete user
          UserDeleteData={UserDeleteData}//open delete user modal
          UserEditData={UserEditData}//open edit user modal
          editUser={editUser}//edit user
          userPassword={userPassword}
          checkPermission={checkPermission}
          brandLogo={brandLogo}
          faviconIcon={faviconIcon}
        />
      }
    </>
  );
};

export default Agency;
