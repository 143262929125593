import React, { useEffect, useState } from 'react';
import { Text, Box, BlockStack, InlineStack, Card, IndexTable } from '@shopify/polaris';
import BillingSummery from '../PricingPlan/BillingSummery';
import { useSelector, useDispatch } from 'react-redux';
import * as ProfileDucks from '../../ducks/profile';
import moment from 'moment';

function PlanUsage(props) {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    historyList: [],
    profile: {}
  });

  const changeNameValue = (obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }

  const quotaHistory = useSelector(state => state?.profile?.quotaHistory)
  const profileState = useSelector(state => state.auth.profile);

  useEffect(() => {
    if (quotaHistory) {
      changeNameValue({ historyList: quotaHistory });
    }
    if (profileState) {
      changeNameValue({ profile: profileState });
    }
  }, [quotaHistory, profileState]);

  const formmetNumber = (nu) => {
    if (nu > 0)
      return nu.toLocaleString();
    else
      return nu;
  };

  useEffect(() => {
    let obj = {
      pvd: localStorage.getItem('pvd')
    };
    dispatch(ProfileDucks.getQuotaHistory(obj));
  }, []);

  return (
    <Box background="bg-surface" borderRadius="200" borderColor="border">
      <div className='rateH'>
        <BlockStack>
          <Box padding={400}>
            <Text variant="headingLg">Plan usage</Text>
          </Box>
        </BlockStack>
      </div>
      <Box >
        <div className=''>
          <Box padding={400}>
            <BlockStack gap={400}>
              <Box>
                {
                  state.profile.blid &&
                  <InlineStack align='space-between' blockAlign='center'>
                    <Text variant='headingMd' as='p' fontWeight='bold'>Plan usage</Text>
                    {/* <Text variant='headingSm' as='p' fontWeight='regular'>Receive weekly reports on registered email</Text> */}
                  </InlineStack>
                }
              </Box>
              <BillingSummery props={props} />
              <Box>
                <Box paddingBlockEnd={200}>
                  <Text variant='headingSm' as='p' fontWeight='regular'>History</Text>
                </Box>
                <Card padding={0}>
                  <IndexTable
                    itemCount={state.historyList ? state.historyList.length : 0}
                    selectable={false}
                    headings={[
                      { title: 'No.' },
                      { title: 'Month' },
                      { title: 'Assign visitor quota' },
                      { title: 'Used quota' },
                      { title: 'Left quota' },
                    ]}>
                    {state.historyList && state.historyList.length > 0 && state.historyList.map((data, index) => (
                      <IndexTable.Row
                        id={index}
                        key={index}
                        position={index}>
                        <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                        <IndexTable.Cell>{data.cdt ? moment(new Date(data.cdt)).format('MMM DD YYYY') : '-'}</IndexTable.Cell>
                        <IndexTable.Cell>{data.uaq ? formmetNumber(data.uaq) : 0}</IndexTable.Cell>
                        <IndexTable.Cell>{data.uuq ? formmetNumber(data.uuq > data.uaq ? data.uaq : data.uuq) : 0}</IndexTable.Cell>
                        <IndexTable.Cell>{data.ulq ? formmetNumber(data.ulq > 0 ? data.ulq : 0) : 0}</IndexTable.Cell>
                      </IndexTable.Row>
                    ))}
                  </IndexTable>
                </Card>
              </Box>
            </BlockStack>
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default PlanUsage;