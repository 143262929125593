import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, Autocomplete, Tooltip, Banner, Icon, Modal, Divider, PageActions, Popover, InlineError } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, SearchIcon, ChevronDownIcon, RefreshIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from 'react-router-dom';
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function FacebookleadCard(props) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props.props;
  const navigate = useNavigate();

  const [value, setValue] = useState({
    intDataList: [],
    ac: '',
    rmk: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    selected: [],
    activePopover: null,
    delSubTyp: false,
    delSubTypId: '',
    modalReidr: false,
    notifListData: [],
    searchFB: []
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.userIntData);

  useEffect(() => {
    changeValue({ intDataList })
  }, [intDataList])

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updIntIsActive(obj))
    const filterData = value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  const handleDeleteData = (id, delSub, delSubId) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data?.length > 0) {
      changeValue({ modalReidr: true, notifListData: data })
    }
    else {
      changeValue({ modalReidr: false })
    }
    openCloseModal('deleteModal', value.deleteModal, 'open');
    changeValue({ dataId: id, delSubTyp: delSub, delSubTypId: delSubId });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    if (value.delSubTyp === false) {
      dispatch(integrationDucks.deleteFBL(id));
    }
    else {
      const obj = { id: value.delSubTypId.id, _id: value.dataId }
      dispatch(integrationDucks.deleteFBLList(obj));
    }
    openCloseModal('deleteModal', value.deleteModal, 'close');
  }

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //Sync latest google page
  const syncLeadForms = (id) => {
    dispatch(integrationDucks.syncLeadForms(id));
  }

  //handle toggle
  const handleToggle = (id) => {
    changeValue({ activePopover: value.activePopover === id ? null : id });
  };

  // login with fb
  const redirectFB = () => {
    let objStateFB = {
      uk: props.profile._id,
      ti: props.profile.ti,
      uri: window.location.href.replace(/&/gi, '@'),//window.location.href,
      path: window.location.pathname
    };
    let authURL = 'https://www.facebook.com/v18.0/dialog/oauth?client_id=' +
      process.env.REACT_APP_FB_APP_ID +
      '&redirect_uri=' +
      process.env.REACT_APP_INT_API_URL +
      '/fblead/oauth&scope=business_management,email,pages_show_list,public_profile,pages_read_engagement,pages_read_user_content,pages_manage_metadata,pages_manage_ads,leads_retrieval&response_type=code&state=' + JSON.stringify(objStateFB);

    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };

  //handle select list 
  const handleAddSelect = (obj, id) => {
    let data = obj[0];
    const valObj = { ...data, _id: id };
    dispatch(integrationDucks.createFBLPage(valObj));
  }

  //search field autocomplete
  const textField = (
    <Autocomplete.TextField
      onChange={(e) => changeValue({ searchFB: e })}
      value={value.searchFB}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search by page name"
      autoComplete="off"
      label="Page list"
    />
  );

  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => {

      return (<>
        <IndexTable.Row
          id={usInt._id}
          key={index}
          position={index}
        >
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {index + 1}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{usInt.d.name}</IndexTable.Cell>
          <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
          <IndexTable.Cell> <label className="switch">
            <input type="checkbox" checked={usInt.ia}
              onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
            <span className="switch-label"></span>
            <span className="switch-handle"></span>
          </label></IndexTable.Cell>

          <IndexTable.Cell>
            <InlineStack blockAlign='center'>
              {/* syncAweber btn */}
              <Box paddingInline={100}><Tooltip content="Sync latest list" padding='400' width='wide' dismissOnMouseOut> <Button icon={RefreshIcon} variant='monochromePlain' onClick={() => syncLeadForms(usInt._id)}></Button></Tooltip></Box>

              {/* Add list */}
              {
                props.checkPermission('integration', 'isadd', props.profile) &&
                <Box paddingInline={200}>
                  <Popover
                    active={value.activePopover === index}
                    activator={
                      <Button
                        onClick={() => {
                          handleToggle(index);
                        }}
                      >
                        <InlineStack>
                          <div className='add-events'>Lead form list</div>
                          <Icon
                            source={ChevronDownIcon}
                            tone="base"
                          />
                        </InlineStack>

                      </Button>
                    }
                    onClose={() => changeValue({ activePopover: null })}
                  >
                    {usInt && usInt.d && usInt.d.lead_forms?.length > 0 && usInt.d.lead_forms.filter(x => !x.ia)?.length > 0 ? (
                      <Card>
                        <Autocomplete
                          options={usInt.d.lead_forms.filter(x => !x.ia).filter(obj => {
                            let regexStr = new RegExp(value.searchFB, 'i');
                            return !value.searchFB || obj.name.search(regexStr) !== -1;
                          })?.map(obj => ({
                            value: obj.name, label: (
                              <div>
                                <Text variant="bodyMd">{obj.name}</Text>
                                {obj.name && <Text variant="bodyXs"> ({obj.name})</Text>}
                              </div>
                            )
                          }))}
                          selected={value.searchFB}
                          onSelect={(e) => handleAddSelect(usInt.d.lead_forms.filter(x => !x.ia && x.status === 'ACTIVE')?.map(obj => (obj)), usInt._id)}
                          textField={textField}
                        />
                      </Card>
                    ) : (
                      <Box padding={400}>
                        No record found
                      </Box>
                    )
                    }
                  </Popover>
                </Box>
              }

              {/* delete btn */}
              {props.checkPermission('integration', 'isdelete', props.profile) &&
                <div id="delete" onClick={() => handleDeleteData(usInt._id, false)}>
                  <Banner hideIcon tone='critical'>
                    <Icon
                      source={DeleteIcon}
                      tone="critical"
                    />
                  </Banner>
                </div>
              }
            </InlineStack>
          </IndexTable.Cell>
        </IndexTable.Row>
        {
          usInt.d.fbTokenError && (
            <IndexTable.Row>
              <IndexTable.Cell colSpan={8}>
                <InlineError message={usInt.d.fbTokenError} />
              </IndexTable.Cell>
            </IndexTable.Row>
          )
        }
        {usInt && usInt?.d && usInt?.d?.lead_forms?.length > 0 &&
          usInt.d.lead_forms.filter(x => x.ia === true)?.length > 0 &&
          <IndexTable.Row >
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="bold" as="span">
                #
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="bold" as="span">Lead form name</Text>
            </IndexTable.Cell>
            <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">Date</Text></IndexTable.Cell>
            <IndexTable.Cell>
            </IndexTable.Cell>
          </IndexTable.Row>}

        {usInt && usInt?.d && usInt.d?.lead_forms?.length > 0 &&
          usInt.d?.lead_forms.filter(x => x.ia === true)?.length > 0 ? usInt?.d?.lead_forms.filter(x => x.ia === true)?.map((FBObj, i) => (
            <IndexTable.Row >
              <IndexTable.Cell></IndexTable.Cell>
              <IndexTable.Cell>
                <Text>
                  {i + 1}
                </Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text as="h6">{FBObj?.name}</Text>
              </IndexTable.Cell>
              <IndexTable.Cell>{moment(new Date(FBObj.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
              <IndexTable.Cell>
                {props.checkPermission('integration', 'isdelete', props.profile) &&
                  <div id="delete" onClick={() => handleDeleteData(usInt._id, true, FBObj)}>
                    <Banner hideIcon tone="critical">
                      <Icon source={DeleteIcon} tone="critical" />
                    </Banner>
                  </div>
                }
              </IndexTable.Cell>
            </IndexTable.Row >
          )) :
          <IndexTable.Row>No record found</IndexTable.Row>
        }
      </>
      )
    },
  );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to connect</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />
        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {props.checkPermission('integration', 'isadd', props.profile) &&
                <Box align='end' paddingInlineEnd={100} paddingBlockEnd={200}>
                  <Button id='login-btn-bg' variant='primary' onClick={() => { redirectFB() }}>Login with facebook</Button>
                </Box>
              }
            </InlineStack>

            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: 'No' },
                  { title: 'User name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {value.modalReidr === false ?
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                onAction: () => {
                  handleDeleteCall(value.dataId);
                }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal> :
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>This data-source is being used in the notification.</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">Kindly disable this data-source from <b><i>{value?.notifListData[0]?.data[0]}</i></b> Notification first & after that, you can delete it.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                content: 'Go to notification', tone: 'success',
                onAction: () => { navigate('/notification?ds=list'); openCloseModal('deleteModal', value.deleteModal, 'close'); }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default FacebookleadCard;