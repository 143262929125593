import React from 'react';
import { BlockStack, Box, Button, InlineGrid, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, Text, TextField } from '@shopify/polaris';
import { ArrowRightIcon } from '@shopify/polaris-icons';
import { wiser_logo } from '../../img';
import TestimonialCard from '../Login/TestimonialCard';
import { Link } from 'react-router-dom';

function ForgotPwd(props) {
  const { state, validator, changeNameValue } = props;

  return (
    <div className='page-login-wrap'>
      <Page>
        <Box paddingBlockEnd={600}>
          <img src={wiser_logo} alt='wiser' />
        </Box>
        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
          <div className='login-part-left'>
            <Box>
              <TestimonialCard />
            </Box>
          </div>
          {props.isLoading === true ?
            <div className='lg_bg'>
              <div className='lg_block'>
                <div className='lg_content'>
                  <BlockStack gap={200}>
                    <SkeletonDisplayText size="" maxWidth='47%' />
                    <Box paddingBlockStart={200} paddingBlockEnd={800}>
                      <Box width='88%'>
                        <SkeletonBodyText lines={1} />
                      </Box>
                      <Box paddingBlockStart={100} width='15%'>
                        <SkeletonBodyText lines={1} />
                      </Box>
                    </Box>
                    <SkeletonDisplayText size="medium" maxWidth='100%' />
                    <Box paddingBlockStart={100}>
                      <SkeletonDisplayText size="extraLarge" maxWidth='100%' />
                    </Box>
                  </BlockStack>
                  <Box paddingBlockStart={600}>
                    <InlineStack gap={300} blockAlign='center' wrap={false}>
                      <Text variant='headingSm' as='p' fontWeight='semibold'>Already have an account ?</Text>
                      <Box width='15%'>
                        <SkeletonBodyText lines={1} />
                      </Box>
                    </InlineStack>
                  </Box>
                  <div className='lg_content_ft'>
                    <Text variant='headingXs' as='p' fontWeight='regular'>By proceeding, you agree to the Terms and Conditions and  Privacy Policy</Text>
                    <Box paddingBlockStart={200}>
                      <div id='lg_btn_links'>
                        <InlineStack gap={200} wrap={false}>
                          <Button variant='plain'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Help</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/privacypolicy/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Privacy</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/termsconditions/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Terms</Text>
                          </Button>
                        </InlineStack>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className='lg_bg'>
              <div className='lg_block'>
                <div className='lg_content'>
                  <BlockStack gap={200}>
                    <Text variant='headingLg' as='h3' fontWeight='bold'>Trouble Logging In?</Text>
                    <Box paddingBlockEnd={600}>
                      <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>We'll send you a password reset link to your registered account</Text>
                    </Box>
                    <div>
                      <TextField
                        label="Enter your email"
                        type='email'
                        value={state.e}
                        onChange={(e) => changeNameValue({ e: e })}
                        autoComplete="off"
                        error={state.errMessage.email ? state.errMessage.email : false}
                      />
                      {validator.message('email', state.e, 'required')}
                    </div>
                    <Box paddingBlockStart={100}>
                      <div className='su_btn_bg' onClick={() => props.handleForgetPD()}>
                        <Text variant='headingSm' as='p' fontWeight='regular'>Send reset link</Text>
                      </div>
                    </Box>
                  </BlockStack>
                  <Box paddingBlockStart={600}>
                    <InlineStack gap={300} blockAlign='center' wrap={false}>
                      <Text variant='headingSm' as='p' fontWeight='semibold'>Already have an account ?</Text>
                      <div id='lg_btn_rvrs'>
                        <Link to={'/login'}><Button variant='plain' icon={ArrowRightIcon}>Log in</Button></Link>
                      </div>
                    </InlineStack>
                  </Box>
                  <div className='lg_content_ft'>
                    <Text variant='headingXs' as='p' fontWeight='regular'>By proceeding, you agree to the Terms and Conditions and  Privacy Policy</Text>
                    <Box paddingBlockStart={200}>
                      <div id='lg_btn_links'>
                        <InlineStack gap={200} wrap={false}>
                          <Button variant='plain'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Help</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/privacypolicy/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Privacy</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/termsconditions/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Terms</Text>
                          </Button>
                        </InlineStack>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          }
        </InlineGrid>
      </Page>
    </div>
  );
}

export default ForgotPwd; 