import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, Thumbnail, IndexTable, InlineStack, Page, Text, Banner, Icon, Modal, Divider, PageActions, Popover, Autocomplete,
  InlineError
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ChevronDownIcon, SearchIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { facebook_icon } from '../../img';
import { useNavigate } from "react-router-dom";
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function FacebookCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    FacebookCard: [],
    ia: false,//toggle on/off
    dataId: '',//delete data id
    deleteDataModal: false,//delete modal
    modalC: false,//modal check
    apiD: false,//api condition
    notifListData: "",//show data in modal
    selected: [],//selecte data
    activePopover: null,
    subId: '',
    searchFBPG: '',//serach
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //FacebookCard data from api
  const getFacebookCardData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ FacebookCard: getFacebookCardData });
  }, [getFacebookCardData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.FacebookCard.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ FacebookCard: updatedList })
  };

  //redirect to facebook
  const redirectFB = () => {
    let objStateFB = {
      uk: props.profile._id,
      ti: props.profile.ti,
      path: window.location.pathname,
    };
    //      uri: window.location.href.replace(/&/gi, '@'),//window.location.href,
    let authURL = 'https://www.facebook.com/v18.0/dialog/oauth?client_id=' +
      process.env.REACT_APP_FB_APP_ID +
      '&redirect_uri=' +
      process.env.REACT_APP_INT_API_URL +
      '/facebook/oauth&scope=business_management,email,pages_show_list,pages_read_engagement,pages_manage_metadata,pages_read_user_content,public_profile,read_insights,pages_manage_posts,pages_manage_engagement&response_type=code&state=' + JSON.stringify(objStateFB);

    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: false })
  }

  //open delete modal for sub data
  const handleSubdeleteModal = (id, subId) => {
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, subId: subId, apiD: true, modalC: false })
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteFB(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //delete sub data api call
  const handleDeleteSubData = () => {
    dispatch(integrationDucks.deleteFBList({ _id: info.dataId, id: info.subId }));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //handle toggle
  const handleToggle = (id) => {
    changeNameValueCard({ activePopover: info.activePopover === id ? null : id, searchFBPG: "" },);
  };

  //create fb page data
  const handleData = (object, val, id) => {
    let filterData = object.find(x => x.name === val[0]);
    const obj = {
      _id: id
    }
    const combined = { ...filterData, ...obj };
    dispatch(integrationDucks.createFBPage(combined));
    changeNameValueCard({ activePopover: null });
  }

  //search field autocomplete
  const textField = (
    <Autocomplete.TextField
      onChange={(e) => changeNameValueCard({ searchFBPG: e })}
      value={info.searchFBPG}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search by page name"
      autoComplete="off"
      label="Page List"
    />
  );

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create Facebook data */}
        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {
                props.checkPermission('integration', 'isadd', props.profile) &&
                <Box paddingBlockEnd={200}>
                  <div id='insta-btn' onClick={() => redirectFB()}>
                    <Button>
                      <InlineStack align='space-between'>
                        <Thumbnail
                          source={facebook_icon}
                          size='extraSmall'
                          alt="facebook"
                        />
                        <span className='login-text'>Login with facebook</span>
                      </InlineStack>
                    </Button>
                  </div>
                </Box>
              }
            </InlineStack>
            <Card padding={0}>
              <IndexTable
                itemCount={info.FacebookCard?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'User name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: '' },
                  { title: '' },
                  { title: '' },
                  { title: 'Action' },

                ]}
                selectable={false}
              >
                {info.FacebookCard && info.FacebookCard.length > 0 && info.FacebookCard.map((i, index) => {
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell><span className='text-break amazon-url'>{i?.d?.name}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell></IndexTable.Cell>
                        <IndexTable.Cell></IndexTable.Cell>
                        <IndexTable.Cell></IndexTable.Cell>
                        <IndexTable.Cell>
                          <InlineStack>
                            {i.d?.fbTokenError === '' || i.d?.fbTokenError === undefined ? (
                              <>
                                {
                                  props.checkPermission('integration', 'isdelete', props.profile) &&
                                  <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                    <Banner hideIcon tone='critical'>
                                      <Icon
                                        source={DeleteIcon}
                                        tone="critical"
                                      />
                                    </Banner>
                                  </div>
                                }
                                {
                                  props.checkPermission('integration', 'isadd', props.profile) &&
                                  <Box paddingInline={200}>
                                    < Popover
                                      active={info.activePopover === index}
                                      activator={
                                        <Button
                                          onClick={() => {
                                            handleToggle(index);
                                          }}
                                        >
                                          <InlineStack>
                                            <div className='add-events'>Add list</div>
                                            <Icon
                                              source={ChevronDownIcon}
                                              tone="base"
                                            />
                                          </InlineStack>

                                        </Button>
                                      }
                                      onClose={() => changeNameValueCard({ activePopover: null })}
                                    >
                                      {i && i.d && i.d.fb_page && i.d.fb_page.length > 0 && i.d.fb_page.filter(x => !x.ia).length > 0 ? (
                                        <Card>
                                          <Autocomplete
                                            options={i.d.fb_page.filter(x => !x.ia).filter(obj => {
                                              let regexStr = new RegExp(info.searchFBPG, 'i');
                                              return !info.searchFBPG || obj.name.search(regexStr) !== -1;
                                            }).map(obj => ({
                                              value: obj.name, label: (
                                                <div>
                                                  <Text variant="bodyMd">{obj.name}</Text>
                                                  {obj.id && <Text variant="bodyXs"> ({obj.id})</Text>}
                                                </div>
                                              )
                                            }))}
                                            selected={info.searchFBPG}
                                            onSelect={(e) => handleData(i.d.fb_page.filter(x => !x.ia).map(obj => (obj)), e, i._id)}
                                            textField={textField}
                                          />
                                        </Card>
                                      ) : (
                                        <Box padding={400}>
                                          No record found
                                        </Box>
                                      )
                                      }
                                    </Popover>
                                  </Box>
                                }
                              </>
                            ) : (
                              props.checkPermission('integration', 'isadd', props.profile) &&
                              <Box paddingBlockEnd={200}>
                                <div id='insta-btn' onClick={() => redirectFB()}>
                                  <Button>
                                    <InlineStack align='space-between'>
                                      <Thumbnail
                                        source={facebook_icon}
                                        size='extraSmall'
                                        alt="facebook"
                                      />
                                      <span className='login-text'> Login with facebook</span>
                                    </InlineStack>
                                  </Button>
                                </div>
                              </Box>
                            )
                            }
                          </InlineStack>
                        </IndexTable.Cell>
                      </IndexTable.Row >

                      {
                        i.d?.fbTokenError && (
                          <IndexTable.Row>
                            <IndexTable.Cell colSpan={8}>
                              <InlineError message={i.d?.fbTokenError} />
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        )
                      }

                      {i && i.d && i.d.fb_page.length > 0 &&
                        i.d.fb_page.filter(x => x.ia === true).length > 0 ? i.d.fb_page.filter(x => x.ia === true).map((LIObj, index) => (
                          <IndexTable.Row key={index}>
                            <IndexTable.Cell></IndexTable.Cell>
                            <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd">
                                {LIObj.name}
                              </Text>
                              <Text variant="bodyMd">
                                ({LIObj.id})
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd">{LIObj.category}</Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd">{LIObj.avgRating ? LIObj.avgRating : 0}</Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd">{LIObj.ratingCount ? LIObj.ratingCount : 0}</Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd">{LIObj.count ? LIObj.count : 0}</Text>
                            </IndexTable.Cell>
                            {props.checkPermission('integration', 'isdelete', props.profile) &&
                              <IndexTable.Cell>
                                <div id="delete" onClick={() => handleSubdeleteModal(i._id, LIObj.id)}>
                                  <Banner hideIcon tone="critical">
                                    <Icon source={DeleteIcon} tone="critical" />
                                  </Banner>
                                </div>
                              </IndexTable.Cell>
                            }
                          </IndexTable.Row>
                        ))
                        : null
                      }
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack >


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    if (info.apiD) {
                      handleDeleteSubData();
                    } else {
                      handleDeleteCall(info.dataId);
                    }
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default FacebookCard;