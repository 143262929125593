import React, { useCallback, useEffect, useState } from 'react';
import { Banner, BlockStack, Box, Button, Card, Divider, Icon, IndexTable, InlineStack, Modal, OptionList, Page, PageActions, Popover, Text, TextField, Tooltip } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import IntegrationHeader from './IntegrationHeader';
import * as integrationDucks from '../../ducks/integration';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

function LinkedinCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [info, setInfo] = useState({
    errMessage: {},
    LinkedinCard: [],
    url: '',
    rmk: '',
    dataId: '',
    ia: false,
    selected: [],//selecte data
    deleteDataModal: false,
    modalC: false,
    notifListData: "",
    activePopover: null,
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  const getLinkedinData = useSelector(state => state.integration?.userIntData);


  useEffect(() => {
    changeNameValueCard({ LinkedinCard: getLinkedinData });
  }, [getLinkedinData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //create sub data api call  
  const handleChangeSubData = (id, data) => {

    let obj = {
      _id: id,
      count: data.count,
      ia: data.ia,
      name: data.name,
      id: data.id
    };
    dispatch(integrationDucks.linkLinkedinPage(obj));
    changeNameValueCard({ activePopover: null });
  };

  //handle toggle
  const handleToggle = (id) => {
    changeNameValueCard({ activePopover: info.activePopover === id ? null : id });
  };

  const redirectLinkedin = () => {
    let objStateLN = {
      uk: props.profile?._id,
      ti: props.profile?.ti,
      uri: window.location.href.replace(/&/gi, '@'),
    };
    window.location.href = process.env.REACT_APP_LINKEDIN_CODE_URL.replace('{API_URL_INT}', process.env.REACT_APP_INT_API_URL) + '&state=' + JSON.stringify(objStateLN);
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: false })
  }

  //open delete modal for sub data
  const handleSubdeleteModal = (id, ipid) => {
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, ipid: ipid, apiD: true, modalC: false })
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteLinkedin(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //delete sub data api call
  const handleDeleteSubData = () => {
    let obj = {
      _id: info.dataId,
      id: info.ipid
    }
    dispatch(integrationDucks.deleteLinkList(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {props.checkPermission('integration', 'isadd', props.profile) &&
                <Box paddingBlockEnd={200}><div id='insta-btn' onClick={() => redirectLinkedin()}><Button size='large'>Connect To Linkedin</Button></div></Box>
              }
            </InlineStack>

            <Card padding={0}>
              <IndexTable
                itemCount={info.LinkedinCard?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Id' },
                  { title: 'User name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.LinkedinCard && info.LinkedinCard.length > 0 && info.LinkedinCard.map((data, index) => {
                  return (

                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>{data?.d?.id}</IndexTable.Cell>
                        <IndexTable.Cell>{data?.d?.name}</IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={data.ia}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <InlineStack>
                            {props.checkPermission('integration', 'isdelete', props.profile) &&
                              <div id="delete" onClick={() => handledeleteModal(data._id)}>
                                <Banner hideIcon tone='critical'>
                                  <Icon
                                    source={DeleteIcon}
                                    tone="critical"
                                  />
                                </Banner>
                              </div>
                            }
                            {
                              props.checkPermission('integration', 'isadd', props.profile) &&
                              <Box paddingInline={200}>
                                <Popover
                                  active={info.activePopover === index}
                                  activator={
                                    <Button onClick={() => {
                                      if (data && data.d && data.d?.page && data.d?.page.length > 0) {
                                        handleToggle(index);
                                      }
                                    }}
                                    >
                                      <InlineStack>
                                        <div className='add-events'>Add list</div>
                                        <Icon
                                          source={ChevronDownIcon}
                                          tone="base"
                                        />
                                      </InlineStack>

                                    </Button>
                                  }
                                  onClose={() => changeNameValueCard({ activePopover: null })}
                                >

                                  {data && data?.d && data.d?.page && data.d?.page?.length > 0 &&
                                    data.d?.page?.filter(x => !x.ia).length > 0 ? data.d?.page?.filter(x => !x.ia).map((obj) => (
                                      <OptionList
                                        onChange={() => handleChangeSubData(data._id, obj)}
                                        options={[{ value: obj.name, label: obj.name }]}
                                        selected={info.selected}
                                      />
                                    ))
                                    : <span>
                                      No record found
                                    </span>}
                                </Popover>
                              </Box>
                            }
                          </InlineStack>
                        </IndexTable.Cell>
                      </IndexTable.Row>

                      {
                        data && data?.d && data.d?.page && data.d?.page.length > 0 &&
                          data.d?.page.filter(x => x.ia === true).length > 0 ?
                          data.d?.page.filter(x => x.ia === true).map((LIObj, index) => (
                            <IndexTable.Row key={index}>
                              <IndexTable.Cell></IndexTable.Cell>
                              <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                              <IndexTable.Cell>
                                <Text variant="bodyMd">
                                  {LIObj.name}
                                </Text>
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                <Text variant="bodyMd">{LIObj.count}</Text>
                              </IndexTable.Cell>
                              {props.checkPermission('integration', 'isdelete', props.profile) &&
                                <IndexTable.Cell>
                                  <div id="delete" onClick={() => handleSubdeleteModal(data._id, LIObj.id)}>
                                    <Banner hideIcon tone="critical">
                                      <Icon source={DeleteIcon} tone="critical" />
                                    </Banner>
                                  </div>
                                </IndexTable.Cell>
                              }
                            </IndexTable.Row>
                          ))
                          : null
                      }
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    if (info.apiD) {
                      handleDeleteSubData();
                    } else {
                      handleDeleteCall(info.dataId);
                    }
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }

    </Page>
  )
}

export default LinkedinCard;