import React, { useEffect } from 'react';
import { Badge, Box, Button, Card, IndexFilters, IndexTable, InlineStack, Layout, Link, Navigation, Page, Text, useSetIndexFiltersMode } from '@shopify/polaris';
import { SocialAdIcon, WorkIcon, WrenchIcon, BillIcon, NoteIcon, ChatIcon, StarIcon, GlobeIcon, FlagIcon, PageIcon, MarketsIcon, AppsIcon, CartFilledIcon, CursorFilledIcon, DeliveryFilledIcon, DomainFilledIcon, LocationFilledIcon, MarketsFilledIcon, MetafieldsFilledIcon, NotificationFilledIcon, PlanFilledIcon, StoreFilledIcon, TaxFilledIcon } from '@shopify/polaris-icons';
import SimpleReactValidator from "simple-react-validator";
import { isWhitelable, checkPermission } from '../../services/permissions';
import { useSelector } from 'react-redux';

import ShopifyCard from './ShopifyCard';
import MailerliteCard from './MailerliteCard';
import SendinblueCard from './SendinblueCard';
import ConvertkitCard from './ConvertkitCard';
import ThrivecartCard from './ThrivecartCard';
import LemlistCard from './LemlistCard';
import GetdripCard from './GetdripCard';
import AweberCard from './AweberCard';
import MailchimpCard from './MailchimpCard';
import GoogleplaceCard from './GoogleplaceCard';
import StripeCard from './StripeCard';
import Shift4ShopCard from './Shift4ShopCard';
import FacebookCard from './FacebookCard';
import BigcommerceCard from './BigcommerceCard';
import MagentoCard from './MagentoCard';
import GoogleMyBusinessCard from './GoogleMyBusinessCard';
import ZapierCard from './ZapierCard';
import NetoCard from './NetoCard';
// import OntraportCard from './OntraportCard';
import CalendlyCard from './CalendlyCard';
import PipedriveCard from './PipedriveCard';
import CliengoCard from './CliengoCard';
import SquarespaceCard from './SquarespaceCard';
import AutopilotCard from './AutopilotCard';
import WixCard from './WixCard';
import HeySummitCard from './HeySummitCard';
import EventbriteCard from './EventbriteCard';
import BraintreeCard from './BraintreeCard';
import SendowlCard from './SendowlCard';
import SimvolyCard from './SimvolyCard';
import ThinkificCard from './ThinkificCard';
import AcuityschedulingCard from './AcuityschedulingCard';
import IntercomCard from './IntercomCard';
import BonuslyCard from './BonuslyCard';
import CampaignMonitorCard from './CampaignMonitorCard';
import HubspotCard from './HubspotCard';
import FastfunnelsCard from './FastfunnelsCard';
import YoutubeChannelCard from './YoutubeChannelCard';
import YoutubeVideoCard from './YoutubeVideoCard';
import CapterraCard from './CapterraCard';
import IntegromatCard from './IntegromatCard';
import RepusoCard from './RepusoCard';
import ElitefunnelsCard from './ElitefunnelsCard';
import ZoomCard from './ZoomCard';
import LinkedinCard from './LinkedinCard';
import InstagramFollowerCard from './InstagramFollowerCard';
import FacebookleadCard from './FacebookleadCard';
import CustomWebhookCard from './CustomWebhookCard';
import SendfoxCard from './SendfoxCard';
import AutomizyCard from './AutomizyCard';
import YelpCard from './YelpCard';
import LivewebinarCard from './LivewebinarCard';
import AmemberCard from './AmemberCard';
// import NewzenlerCard from './NewzenlerCard';
import YotpoCard from './YotpoCard';
import PrestashopCard from './PrestashopCard';
import OpencartCard from './OpencartCard';
import EasyDigitalDownloadsCard from './EasyDigitalDownloadsCard';
import SpotifyCard from './SpotifyCard';
import TwitchtvCard from './TwitchtvCard';
import WishpondCard from './WishpondCard';
import ZoomaiCard from './ZoomaiCard';
import BookingCard from './BookingCard';
import TripadvisorCard from './TripadvisorCard';
import StampedCard from './StampedCard';
import ClixliCard from './ClixliCard';
import AirbnbCard from './AirbnbCard';
import ReviewsioCard from './ReviewsioCard';
import AppsumoCard from './AppsumoCard';
import PractoCard from './PractoCard';
import FacebookgroupfollowersCard from './FacebookgroupfollowersCard';
import EnchargeCard from './EnchargeCard';
import EcwidCard from './EcwidCard';
import ShopperApprovedCard from './ShopperApprovedCard';
import ModwebsCard from './ModwebsCard';
import NopCommerceCard from './NopCommerceCard';
import GooglePlayCard from './GooglePlayCard';
import HotelsCard from './HotelsCard';
import EdmundsCard from './EdmundsCard';
import DealerRaterCard from './DealerRaterCard';
import BBBCard from './BBBCard';
import AppleAppCard from './AppleAppCard';
import AmazonCard from './AmazonCard';
import EtsyCard from './EtsyCard';
import HouzzCard from './HouzzCard';
import OpenTableCard from './OpenTableCard';
import InstagramPostCard from './InstagramPostCard';
import TiktokCard from './TiktokCard';
import CommercetoolsCard from './CommercetoolsCard';
import ElopageCard from './ElopageCard';
import G2Card from './G2Card';
import MyDukkanCard from './MyDukkanCard';
import WiserReviewCard from './WiserReviewCard';
import WordpressCard from './WordpressCard';
import CustomWebformCard from './CustomWebformCard';
import ImportReviewsCard from './ImportReviewsCard';
import WebflowCard from './WebflowCard.js';
import WebhookCard from './WebhookCard';
import ScheduleOnceCard from './ScheduleOnceCard.js';
import EBay from './EBay.js';
import { skeleton } from '../../utils/skeleton.js';
import ShoplineCard from './ShoplineCard.js';



function IntegrationCard(props) {
  const { state, changeNameValue, openModal } = props;
  const { mode, setMode } = useSetIndexFiltersMode();
  const validator = new SimpleReactValidator({ locale: 'en' });
  let isWhite = isWhitelable();

  //get profile data
  const profileData = useSelector(state => state.auth.profile);
  //get integration data 
  const userIntegration = useSelector(state => state.integration.userIntData);

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (props.isLoading || state.arrInt.length <= 0) {
      // Check if there's a query and no matching results to avoid showing the skeleton
      if (state.sa && state.arrInt.length === 0) {
        changeNameValue({ isReloadIntData: false });
      } else {
        changeNameValue({ isReloadIntData: true });
      }
    } else {
      changeNameValue({ isReloadIntData: false });
    }
  }, [props.isLoading, state.arrInt, state.sa]);

  return (
    <>
      {
        state.in === '' &&
        <Page title={'Integrations'} subtitle={'Connect your stack & automate the social proof on your site'}>
          <Box paddingBlockEnd={400}>
            <div id='intg_block' style={{ marginBottom: '100px' }}>
              <Layout>
                <Layout.Section variant="oneThird">
                  <div id='intg_nav'>
                    <Navigation location="/">
                      <Navigation.Section
                        items={[
                          {
                            url: '#',
                            label: 'All apps',
                            icon: AppsIcon,
                            selected: state.selctdTab === 'allApps' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'allApps' }),
                          },
                          {
                            url: '#',
                            label: 'Marketing',
                            icon: MarketsIcon,
                            selected: state.selctdTab === 'Marketing' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Marketing' }),
                          },
                          {
                            url: '#',
                            label: 'Page builder',
                            icon: PageIcon,
                            selected: state.selctdTab === 'Page Builder' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Page Builder' }),
                          },
                          {
                            url: '#',
                            label: 'Platform',
                            icon: FlagIcon,
                            selected: state.selctdTab === 'Platform' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Platform' }),
                          },
                          {
                            url: '#',
                            label: 'CRM',
                            icon: GlobeIcon,
                            selected: state.selctdTab === 'CRM' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'CRM' }),
                          },
                          {
                            url: '#',
                            label: 'Reviews',
                            icon: StarIcon,
                            selected: state.selctdTab === 'Reviews' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Reviews' }),
                          },
                          {
                            url: '#',
                            label: 'Communication',
                            icon: ChatIcon,
                            selected: state.selctdTab === 'Communication' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Communication' }),
                          },
                          {
                            url: '#',
                            label: 'Booking',
                            icon: NoteIcon,
                            selected: state.selctdTab === 'Booking' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Booking' }),
                          },
                          {
                            url: '#',
                            label: 'Billing',
                            icon: BillIcon,
                            selected: state.selctdTab === 'Billing' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Billing' }),
                          },
                          {
                            url: '#',
                            label: 'Custom',
                            icon: WrenchIcon,
                            selected: state.selctdTab === 'Custom' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Custom' }),
                          },
                          {
                            url: '#',
                            label: 'Others',
                            icon: WorkIcon,
                            selected: state.selctdTab === 'Others' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Others' }),
                          },
                          {
                            url: '#',
                            label: 'Social',
                            icon: SocialAdIcon,
                            selected: state.selctdTab === 'Social' ? true : false,
                            onClick: () => changeNameValue({ selctdTab: 'Social' }),
                          },
                        ]}
                      />
                    </Navigation>
                  </div>
                  {localStorage.getItem('isbusr') !== 'true' &&
                    <Box paddingInlineStart={600} paddingBlockStart={400}>
                      <Text variant='headingSm' as='p' fontWeight='regular'>Missing your stack?</Text>
                      <Text variant='headingSm' as='p' fontWeight='regular'>Talk with <Button variant='plain' onClick={() => props.openChat()}>Live chat support team.</Button></Text>
                    </Box>
                  }
                </Layout.Section>

                <Layout.Section>
                  <Card padding={0}>
                    <div className="sticky-index-filters">
                      <IndexFilters
                        queryValue={state.sa}
                        queryPlaceholder="Search..."
                        onQueryChange={(e) => props.searchByIntg(e)}
                        onQueryClear={() => { changeNameValue({ sa: '' }); props.callInteSearchApi('empty'); }}
                        cancelAction={{
                          onAction: () => { changeNameValue({ sa: '' }); props.callInteSearchApi('empty'); }
                        }}
                        tabs={props.tabs}
                        canCreateNewView={false}
                        selected={state.selected}
                        onSelect={(e) => changeNameValue({ selected: e })}
                        filters={[]}
                        mode={mode}
                        setMode={setMode}
                      />
                    </div>

                    {state.selected === 0 ?
                      <IndexTable
                        itemCount={state.isReloadIntData === true ? 10 : (state?.arrInstall?.length || 0)}
                        selectable={false}
                        headings={[]}
                      // pagination={{
                      //   // label: props.paggiLabel,
                      //   hasPrevious: state.paggiActive > 1 ? true : false,
                      //   hasNext: (state.paggiActive < state.pageNumber) ? true : false,
                      //   onPrevious: () => props.onPaggiCall('-1'),
                      //   onNext: () => props.onPaggiCall('+1')
                      // }} 
                      >
                        {state.isReloadIntData ?
                          skeleton(10, 1)
                          :
                          state.isInstalled && state.arrInstall && state.arrInstall.length > 0 && state.arrInstall.map((value, index) => (
                            <IndexTable.Row
                              id={value._id}
                              key={index}
                              position={index}
                            >
                              <IndexTable.Cell style="height: 60px;">
                                <InlineStack align='space-between' blockAlign='center'>
                                  <InlineStack blockAlign='center' gap={400}>
                                    <div className='list-app-iconbox'>
                                      <img src={value.img} alt='' />
                                    </div>
                                    <Box paddingBlockEnd="100" paddingBlockStart='100' >
                                      <InlineStack align='start' blockAlign='center' gap={200} wrap={false}>
                                        <Text variant='headingSm' as='p' fontWeight='medium'>{value.dn}</Text>
                                        {value.data && value.data.intCount &&
                                          <Box>
                                            <Badge tone='info'>{`Connected ${value.data.intCount}`}</Badge>
                                          </Box>
                                        }
                                      </InlineStack>
                                      <div style={{ marginTop: '3px' }}></div>
                                      <Text tone="subdued" variant='bodySm' as='p' fontWeight='regular' paddingBlockEnd="400" paddingBlockStart='200' >{value.dsc}</Text>
                                    </Box>
                                  </InlineStack>
                                  {value.t === 0 ? (
                                    value.icsn ? (
                                      <Button disabled>Temporarily unavailable</Button>
                                    ) : (
                                      <Button onClick={() => openModal(value)}>Integrate</Button>
                                    )
                                  ) : value.t === 1 ? (
                                    value.icsn ? (
                                      <Button disabled>Temporarily unavailable</Button>
                                    ) : (
                                      <Button onClick={() => openModal(value)}>Integrate</Button>
                                    )
                                  ) : (
                                    value.icsn ? (
                                      <Button disabled>Temporarily unavailable</Button>
                                    ) : (
                                      <Button onClick={() => fnRedirect(value.hpul ? value.hpul + '?utm_source=dashboard_integration_card' : value.hpulpxl + '?utm_source=dashboard_integration_card')}>Integrate</Button>
                                    )
                                  )}

                                </InlineStack>
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          ))
                        }
                      </IndexTable>
                      :
                      <IndexTable
                        itemCount={state.isReloadIntData === true ? 10 : (state?.selctdTab === 'allApps' ? state.arrInt?.length : state.arrCt.length || 0)}
                        selectable={false}
                        headings={[]}
                      // pagination={{
                      //   // label: props.paggiLabel,
                      //   hasPrevious: state.paggiActive > 1 ? true : false,
                      //   hasNext: (state.paggiActive < state.pageNumber) ? true : false,
                      //   onPrevious: () => props.onPaggiCall('-1'),
                      //   onNext: () => props.onPaggiCall('+1')
                      // }} 
                      >
                        {state.isReloadIntData ?
                          skeleton(10, 1)
                          :
                          state.selctdTab === 'allApps' ?
                            state.arrInt && state.arrInt.length > 0 && state.arrInt.map((value, index) => (
                              <IndexTable.Row
                                id={value._id}
                                key={index}
                                position={index}
                              >
                                <IndexTable.Cell>
                                  <InlineStack align='space-between' blockAlign='center'>
                                    <InlineStack blockAlign='center' gap={400}>
                                      <div className='list-app-iconbox'>
                                        <img src={value.img} alt='' />
                                      </div>
                                      {/* <img src={value.img} alt='' style={{ width: "24px", height: "24px" }} /> */}
                                      <Box paddingBlockEnd="100" paddingBlockStart='100'>
                                        <InlineStack align='start' blockAlign='center' gap={200} wrap={false}  >
                                          <Text variant='headingSm' as='p' fontWeight='medium'>{value.dn}</Text>
                                          {value.data && value.data.intCount &&
                                            <Box>
                                              <Badge tone='info'>{`Connected ${value.data.intCount}`}</Badge>
                                            </Box>
                                          }
                                        </InlineStack>
                                        <div style={{ marginTop: '3px' }}></div>
                                        <Text tone="subdued" variant='bodySm' as='p' fontWeight='regular'>{value.dsc}</Text>
                                      </Box>
                                    </InlineStack>
                                    {value.t === 0 ? (
                                      value.icsn ? (
                                        <Button disabled>Temporarily unavailable</Button>
                                      ) : (
                                        <Button onClick={() => openModal(value)}>Integrate</Button>
                                      )
                                    ) : value.t === 1 ? (
                                      value.icsn ? (
                                        <Button disabled>Temporarily unavailable</Button>
                                      ) : (
                                        <Button onClick={() => openModal(value)}>Integrate</Button>
                                      )
                                    ) : (
                                      value.icsn ? (
                                        <Button disabled>Temporarily unavailable</Button>
                                      ) : (
                                        <Button onClick={() => fnRedirect(value.hpul ? value.hpul + '?utm_source=dashboard_integration_card' : value.hpulpxl + '?utm_source=dashboard_integration_card')}>Integrate</Button>
                                      )
                                    )}
                                  </InlineStack>
                                </IndexTable.Cell>
                              </IndexTable.Row>
                            ))
                            :
                            state.arrCt && state.arrCt.length > 0 && state.arrCt.map((value, index) => (
                              <IndexTable.Row
                                id={value._id}
                                key={index}
                                position={index}
                              >
                                <IndexTable.Cell>
                                  <InlineStack align='space-between' blockAlign='center'>
                                    <InlineStack blockAlign='center' gap={400}>
                                      <img src={value.img} alt='' style={{ width: "24px", height: "24px" }} />
                                      <Box paddingBlockEnd="100" paddingBlockStart='100'>
                                        <InlineStack align='start' blockAlign='center' gap={200} wrap={false}>
                                          <Text variant='headingSm' as='p' fontWeight='medium'>{value.dn}</Text>
                                          {value.data && value.data.intCount &&
                                            <Box>
                                              <Badge tone='info'>{`Connected ${value.data.intCount}`}</Badge>
                                            </Box>
                                          }
                                        </InlineStack>
                                        <div style={{ marginTop: '3px' }}></div>
                                        <Text tone="subdued" variant='bodySm' as='p' fontWeight='regular'>{value.dsc}</Text>
                                      </Box>
                                    </InlineStack>
                                    {value.t === 0 ? (
                                      value.icsn ? (
                                        <Button disabled>Temporarily unavailable</Button>
                                      ) : (
                                        <Button onClick={() => openModal(value)}>Integrate</Button>
                                      )
                                    ) : value.t === 1 ? (
                                      value.icsn ? (
                                        <Button disabled>Temporarily unavailable</Button>
                                      ) : (
                                        <Button onClick={() => openModal(value)}>Integrate</Button>
                                      )
                                    ) : (
                                      value.icsn ? (
                                        <Button disabled>Temporarily unavailable</Button>
                                      ) : (
                                        <Button onClick={() => fnRedirect(value.hpul ? value.hpul + '?utm_source=dashboard_integration_card' : value.hpulpxl + '?utm_source=dashboard_integration_card')}>Integrate</Button>
                                      )
                                    )}
                                  </InlineStack>
                                </IndexTable.Cell>
                              </IndexTable.Row>
                            ))
                        }
                      </IndexTable>
                    }
                  </Card>
                </Layout.Section>
              </Layout>
            </div>
          </Box>
        </Page>
      }
      {
        state.in === 'shopify' &&
        <ShopifyCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'mailerlite' &&
        <MailerliteCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'sendinblue' &&
        <SendinblueCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'convertkit' &&
        <ConvertkitCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'thrivecart' &&
        <ThrivecartCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'lemlist' &&
        <LemlistCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'getdrip' &&
        <GetdripCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'aweber' &&
        <AweberCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'mailchimp' &&
        <MailchimpCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'google' &&
        <GoogleplaceCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'stripe' &&
        <StripeCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === '3dcart' &&
        <Shift4ShopCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'facebook' &&
        <FacebookCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'bigcommerce' &&
        <BigcommerceCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'magento' &&
        <MagentoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'googlemybusiness' &&
        <GoogleMyBusinessCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'zapier' &&
        <ZapierCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'neto' &&
        <NetoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {/* {
        state.in === 'ontraport' &&
        <OntraportCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      } */}
      {
        state.in === 'calendly' &&
        <CalendlyCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'pipedrive' &&
        <PipedriveCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'cliengo' &&
        <CliengoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'squarespace' &&
        <SquarespaceCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'autopilot' &&
        <AutopilotCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'wix' &&
        <WixCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'heysummit' &&
        <HeySummitCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'eventbrite' &&
        <EventbriteCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'braintree' &&
        <BraintreeCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'sendowl' &&
        <SendowlCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'simvoly' &&
        <SimvolyCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'thinkific' &&
        <ThinkificCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'acuityscheduling' &&
        <AcuityschedulingCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'intercom' &&
        <IntercomCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'bonusly' &&
        <BonuslyCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'campaignmonitor' &&
        <CampaignMonitorCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'hubspot' &&
        <HubspotCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'fastfunnels' &&
        <FastfunnelsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'youtube_channel' &&
        <YoutubeChannelCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'youtube_video' &&
        <YoutubeVideoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'capterra' &&
        <CapterraCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'integromat' &&
        <IntegromatCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'repuso' &&
        <RepusoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission} />

      }
      {
        state.in === 'elitefunnels' &&
        <ElitefunnelsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'zoom' &&
        <ZoomCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'linkedin' &&
        <LinkedinCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'instagram_follower' &&
        <InstagramFollowerCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'facebooklead' &&
        <FacebookleadCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'custom' &&
        <CustomWebhookCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'sendfox' &&
        <SendfoxCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'automizy' &&
        <AutomizyCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'yelp' &&
        <YelpCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'livewebinar' &&
        <LivewebinarCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'amember' &&
        <AmemberCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {/* {
        state.in === 'newzenler' &&
        <NewzenlerCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      } */}
      {
        state.in === 'yotpo' &&
        <YotpoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'prestashop' &&
        <PrestashopCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'opencart' &&
        <OpencartCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'easydigitaldownloads' &&
        <EasyDigitalDownloadsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'spotify' &&
        <SpotifyCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'twitchtv' &&
        <TwitchtvCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'wishpond' &&
        <WishpondCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'zoomai' &&
        <ZoomaiCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'booking' &&
        <BookingCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />

      }
      {
        state.in === 'tripadvisor' &&
        <TripadvisorCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'stamped' &&
        <StampedCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'clixli' &&
        <ClixliCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'airbnb' &&
        <AirbnbCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'reviewsio' &&
        <ReviewsioCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'appsumo' &&
        <AppsumoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'practo' &&
        <PractoCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'facebookgroupfollowers' &&
        <FacebookgroupfollowersCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'encharge' &&
        <EnchargeCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'ecwid' &&
        <EcwidCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'shopperapproved' &&
        <ShopperApprovedCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'modwebs' &&
        <ModwebsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'nopcommerce' &&
        <NopCommerceCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'googleplay' &&
        <GooglePlayCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'hotels' &&
        <HotelsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'edmunds' &&
        <EdmundsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'dealerrater' &&
        <DealerRaterCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'bbb' &&
        <BBBCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'appleapp' &&
        <AppleAppCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'amazon' &&
        <AmazonCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'etsy' &&
        <EtsyCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'houzz' &&
        <HouzzCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'opentable' &&
        <OpenTableCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'instagrampost' &&
        <InstagramPostCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'tiktok' &&
        <TiktokCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'commercetools' &&
        <CommercetoolsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'elopage' &&
        <ElopageCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'g2' &&
        <G2Card
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'mydukaan' &&
        <MyDukkanCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'wiserreview' &&
        <WiserReviewCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'wordpress' &&
        <WordpressCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'customwebform' &&
        <CustomWebformCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'importreviews' &&
        <ImportReviewsCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'webflow' &&
        <WebflowCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'scheduleonce' &&
        <ScheduleOnceCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.t === 0 && state.in !== '' &&
        <WebhookCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'ebay' &&
        <EBay
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
      {
        state.in === 'shopline' &&
        <ShoplineCard
          props={props}
          isWhite={isWhite}
          validator={validator}
          profile={profileData}
          userIntegration={userIntegration}
          checkPermission={checkPermission}
        />
      }
    </>
  );
}

export default IntegrationCard;