import React from 'react';
import { UsersFormCard, UsersListCard } from '../../components';

const Users = () => {
  return (
    <div>
      <UsersFormCard>

      </UsersFormCard>

      <UsersListCard>

      </UsersListCard>
    </div>
  );
};

export default Users;