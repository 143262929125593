import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/login';
import { toastify } from '../ducks/toast';

function* getLogin(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getLogin', action.payload);

    if (res.status === 'success') {
      yield put(actions.getLoginSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* signup(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/register', action.payload);
    if (res.status !== 'success' && window.turnstile) {
      setTimeout(() => {
        try {
          window.turnstile.render('#cloudflare-turnstile', {
            sitekey: '0x4AAAAAAAR6g2LVrzvAVvO3',
            theme: 'light',
            language: 'auto',
            retry: 'auto'
          });
        } catch (error) {
          console.warn(error);
        }
      }, 1000);
    }
    if (res.status === 'success') {
      try {
        if (window.ga) {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_local');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_local' });
        }
      } catch (ex) {
        console.log(ex);
      }

      if (window.lintrk) {
        window.lintrk('track', { conversion_id: 14211337 });
      }
      try {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC',
          'value': 0,
          'currency': 'INR',
          'transaction_id': res.data.ti
        });
      } catch (error) {
        console.log(error);
      }
      // }

      localStorage.removeItem('cd');//remove appsumo code from localstorage
      // localStorage.removeItem('UTM_SIGNUP');
      localStorage.setItem('authToken', res.data.token);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.username);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', false);
      localStorage.setItem('isbusr', false);//white_label
      localStorage.setItem('isPixelIns', false);
      localStorage.setItem('pvd', 'local');
      localStorage.setItem('isNewUser', true);
      localStorage.setItem('csub', '');
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      //facebook marketing
      if (window.fbq) {
        window.fbq('track', 'PixelInstall');
      }
      //if user not login 
      let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        (localStorage.getItem('csub') === '' || localStorage.getItem('csub') == null || localStorage.getItem('csub') === undefined) ?
          (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
            window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
            :
            window.location.href = '/onboarding'
          :
          window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
      }, 2000);
      localStorage.removeItem('rdt_url');
      localStorage.removeItem('iskponbrdng');
      // window.location.href = res.data.rp;
      // browserHistory.push('/');
    }
    else if (res.status === 'duplicate') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'used') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_found') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'error') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* userLogin(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/login', action.payload);
    if (res.status === 'success') {
      localStorage.removeItem('cd');//remove appsumo code from localstorage
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isMaster', res.data.isMaster);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.vstp && res.data.vstp.plx && res.data.vstp.plx.st !== true) {
        localStorage.setItem('isPixelIns', false);
      }
      if (res.data.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (res.data.pvd === 'shopify' || res.data.pvd === 'wix' || res.data.pvd === 'thinkific' || res.data.pvd === 'bigcommerce' || res.data.pvd === 'duda') {
        localStorage.setItem('pvd', res.data.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      //froged 
      // setTimeout(() => {
      //   if (window.Froged) {
      //     window.Froged('logout');
      //   }
      // }, 1000);

      //if user not login 
      let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('logout');
        }
        (
          (
            (localStorage.getItem('utyp') !== 'appsumo') &&
            (localStorage.getItem('istm') !== 'true') &&
            (localStorage.getItem('isbusr') !== 'true')
          ) &&
          (
            localStorage.getItem('csub') === '' ||
            localStorage.getItem('csub') == null ||
            localStorage.getItem('csub') === undefined
          )
        )
          ?
          (
            (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
              window.location.href = rdt_url ? rdt_url : (res.data.rp ? res.data.rp : '/dashboard')
              :
              window.location.href = '/onboarding'
          )
          :
          window.location.href = rdt_url ? rdt_url : (res.data.rp ? res.data.rp : '/dashboard');
      }, 2000);
      localStorage.removeItem('rdt_url');
      localStorage.removeItem('iskponbrdng');
    }
    else if (res.status === 'not_found') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_active') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* forgetPD(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/fPassword', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* userResetPD(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/rPassword', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      action.payload.navigate(`/login?reset=true`);
    }
    else if (res.status === 'duplicate') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_found') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* loginWithGoogle(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithGoogle', action.payload);
    if (res.status === 'success') {

      localStorage.setItem('istm', false);
      localStorage.setItem('isbusr', false);//white_label
      localStorage.setItem('authToken', res.data.token);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.username);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.ti) {
        localStorage.setItem('ti', res.data.ti);
      }
      localStorage.setItem('pvd', res.data.pvd);
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }

      //facebook marketing
      if (window.fbq) {
        window.fbq('track', 'PixelInstall');
      }
      if (res.data.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_local');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_local' });
        } catch (ex) {
          console.log(ex);
        }

        window.gtag('event', 'conversion', {
          'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC',
          'value': 0,
          'currency': 'INR',
          'transaction_id': localStorage.getItem('ti')
        });

        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }

      let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        (localStorage.getItem('csub') === '' || localStorage.getItem('csub') == null || localStorage.getItem('csub') === undefined) ?
          (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
            window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
            :
            window.location.href = '/onboarding'
          :
          window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
      }, 2000);
    }
    else if (res.status == 'exist') {
      yield put(loaded());
      localStorage.setItem('istm', false);
      localStorage.setItem('isbusr', false);//white_label
      localStorage.setItem('authToken', res.data.token);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.username);
      localStorage.setItem('e', res.data.e);
      if (res.data.utyp == 'dealify' || res.data.utyp == 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }

      //facebook marketing
      if (window.fbq) {
        window.fbq('track', 'PixelInstall');
      }
      window.location.href = res.data.rp;
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* loginWithFacebook(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithFacebook', action.payload);
    if (res.status === 'success') {
      yield put(actions.loginWithFacebookSuccess(false));
      // toast.info(res.m);
      localStorage.setItem('istm', false);
      localStorage.setItem('isbusr', false);//white_label
      localStorage.setItem('authToken', res.data.token);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.username);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.ti) {
        localStorage.setItem('ti', res.data.ti);
      }
      localStorage.setItem('pvd', 'facebook');
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      //mixpanel start
      // if (window.mixpanel) {
      //   window.mixpanel.identify(res.data.e);
      //   window.mixpanel.people.set({
      //     '$email': res.data.e,
      //     '$last_login': new Date()
      //   });
      // }
      //mixpanel end

      //facebook marketing
      if (window.fbq) {
        window.fbq('track', 'PixelInstall');
      }
      if (res.data.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_local');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_local' });
        } catch (ex) {
          console.log(ex);
        }

        // if (localStorage.getItem('UTM_SIGNUP')) {
        //   let signupString = localStorage.getItem('UTM_SIGNUP');
        //   if (signupString.includes('utm_source=google-ads')) {
        //     window.gtag('event', 'conversion', { 'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC' });
        //   }
        //   localStorage.removeItem('UTM_SIGNUP');
        // }
        // if (localStorage.getItem('ti')) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC',
          'value': 0,
          'currency': 'INR',
          'transaction_id': localStorage.getItem('ti')
        });
        // }

        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }
      // window.location.href = res.data.rp;
      let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        (localStorage.getItem('csub') === '' || localStorage.getItem('csub') == null || localStorage.getItem('csub') === undefined) ?
          (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
            window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
            :
            window.location.href = '/onboarding'
          :
          window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
      }, 2000);
      // browserHistory.push('/');
    }
    else if (res.status === 'error') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'emailnotadd') {
      yield put(loaded());
      yield put(actions.loginWithFacebookSuccess(true));
    }
    else if (res.status === 'duplicate') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetLogin() {
  yield takeLatest(actions.getLogin.type, getLogin);
}

export function* watchSignup() {
  yield takeLatest(actions.signup.type, signup);
}

export function* watchUserLogin() {
  yield takeLatest(actions.userLogin.type, userLogin);
}

export function* watchForgetPD() {
  yield takeLatest(actions.forgetPD.type, forgetPD);
}

export function* watchUserResetPD() {
  yield takeLatest(actions.userResetPD.type, userResetPD);
}

export function* watchLoginWithGoogle() {
  yield takeLatest(actions.loginWithGoogle.type, loginWithGoogle);
}

export function* watchLoginWithFacebook() {
  yield takeLatest(actions.loginWithFacebook.type, loginWithFacebook);
}

export default function* rootSaga() {
  yield fork(watchGetLogin);
  yield fork(watchSignup);
  yield fork(watchUserLogin);
  yield fork(watchForgetPD);
  yield fork(watchUserResetPD);
  yield fork(watchLoginWithGoogle);
  yield fork(watchLoginWithFacebook);
}