import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip, OptionList, Popover,
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';

function SendfoxCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    errMessage: {},
    Sendfox: [],//Sendfox page data
    ak: '',//Token 
    rmk: '',//name your connection
    dataId: '',//id for the delete data modal
    ia: false,//toggle checked
    deleteDataModal: false,//open delete modal
    apiD: false,
    selected: [],
    activePopover: null,
    modalC: false,//condition for delete modal
    notifListData: "",//show data in modal
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //Sendfox data from api
  const getSendfoxData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ Sendfox: getSendfoxData });
  }, [getSendfoxData]);

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: false })
  }

  //open delete modal for sub data
  const handleSubdeleteModal = (id) => {
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: true, modalC: false })
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    let obj = {
      _id: id
    };
    dispatch(integrationDucks.deleteSendfox(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //delete sub data api call
  const handleDeleteSubData = (_id,) => {
    let obj = {
      id: info.dataId,
    }
    dispatch(integrationDucks.deleteSendfoxList(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //update api call (toggle button)
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.Sendfox.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ Sendfox: updatedList })
  };

  //create Sendfox data 
  const createSendfox = () => {
    if (props.validator.errorMessages.ak || props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      changeNameValueCard({ errMessage: {} });
      let obj = {
        ak: info.ak,
        rmk: info.rmk,
      };
      dispatch(integrationDucks.createSendfox(obj));
      changeNameValueCard({ ak: '', rmk: '', errMessage: {} });
    }
  }

  //create sub data api call  
  const handleChangeSubData = (id, ak, fuid) => {
    let obj = {
      id: id,
      ak: ak,
      fuid: fuid
    };
    dispatch(integrationDucks.addSendfoxList(obj));
    changeNameValueCard({ activePopover: null })
  };

  //handle toggle
  const handleToggle = (id) => {
    changeNameValueCard({ activePopover: info.activePopover === id ? null : id });
  };

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create Sendfox data */}
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.at} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Token</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.ak}
                        onChange={(e) => changeNameValueCard({ ak: e })}
                        placeholder='Token'
                        autoComplete="off"
                        error={info.errMessage.ak ? "The token  field is required." : false}
                      />
                      {props.validator.message('ak', info.ak, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box align='center' padding={300}><Button onClick={(e) => createSendfox(e)} variant="primary">Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.Sendfox?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name	' },
                  { title: 'Created date	' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.Sendfox && info.Sendfox.length > 0 && info.Sendfox.map((data, index) => {
                  return (
                    <>
                      <IndexTable.Row>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell><span className="text-break">{data.rmk}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={data.ia}
                              onChange={() => handleIsActive(data)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <InlineStack>
                            {props.checkPermission('integration', 'isdelete', props.profile) &&
                              <div id="delete" onClick={() => handledeleteModal(data._id)}>
                                <Banner hideIcon tone='critical'>
                                  <Icon
                                    source={DeleteIcon}
                                    tone="critical"
                                  />
                                </Banner>
                              </div>
                            }
                            <Box paddingInline={200}>
                              {props.checkPermission('integration', 'isadd', props.profile) &&
                                < Popover
                                  active={info.activePopover === index}
                                  activator={
                                    <Button onClick={() => {
                                      if (data && data.d && data?.d?.sf_list?.length > 0) {
                                        handleToggle(index);
                                      }
                                    }}
                                    >
                                      <InlineStack>
                                        <div className='add-events'>Add list</div>
                                        <Icon
                                          source={ChevronDownIcon}
                                          tone="base"
                                        />
                                      </InlineStack>

                                    </Button>
                                  }
                                  onClose={() => changeNameValueCard({ activePopover: null })}
                                >
                                  {data && data.d && data?.d?.sf_list && data?.d?.sf_list?.length > 0 &&
                                    data?.d?.sf_list?.filter(x => !x.ia)?.length > 0 ? data?.d?.sf_list?.filter(x => !x.ia).map((obj) => (
                                      <OptionList
                                        onChange={() => handleChangeSubData(obj.id, data.d?.ak, data._id,)}
                                        options={[{ value: obj.name, label: obj.name }]}
                                        selected={info.selected}
                                      />
                                    ))
                                    : <span>
                                      No record found
                                    </span>}
                                </Popover>
                              }
                            </Box>
                          </InlineStack>
                        </IndexTable.Cell>
                      </IndexTable.Row >

                      {data && data.d && data.d?.sf_list && data.d?.sf_list.length > 0 &&
                        data.d?.sf_list.filter(x => x.ia === true).length > 0 ? data.d?.sf_list.filter(x => x.ia === true).map((MCObj, i) => (
                          <IndexTable.Row key={MCObj._id}>
                            <IndexTable.Cell></IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="bold" as="span">
                                {i + 1}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text as="h6">{MCObj.name}</Text>
                              <Text as="h6">{MCObj.id}</Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell></IndexTable.Cell>
                            {props.checkPermission('integration', 'isdelete', props.profile) && (
                              <IndexTable.Cell>
                                <div id="delete" onClick={() => handleSubdeleteModal(MCObj.id)}>
                                  <Banner hideIcon tone="critical">
                                    <Icon source={DeleteIcon} tone="critical" />
                                  </Banner>
                                </div>
                              </IndexTable.Cell>
                            )}
                          </IndexTable.Row>
                        ))
                        : <IndexTable.Cell>No record found</IndexTable.Cell>
                      }
                    </>
                  )
                }
                )}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack >


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    if (info.apiD) {
                      handleDeleteSubData();
                    } else {
                      handleDeleteCall(info.dataId);
                    }
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default SendfoxCard;