import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const wixPricingPlan = createSlice({
  name: 'wixPricingPlan',
  initialState,
  reducers: {
    getWixPricingPlan: (state, action) => { },
    getWixPricingPlanSuccess: (state, action) => {
      return { ...state, wixPricingPlan: action.payload };
    },

    goToWixCheckOut: (state, action) => { },
    successGoToWixCheckOut: (state, action) => {
      return { ...state, wixPricingPlanCheckout: action.payload };
    },
  }
});

export const {
  getWixPricingPlan,
  getWixPricingPlanSuccess,
  goToWixCheckOut,
  successGoToWixCheckOut

} = wixPricingPlan.actions;

export default wixPricingPlan.reducer;
