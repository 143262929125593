import React, { useCallback, useEffect, useState } from 'react';
import { GoalCard } from "../../components";
import * as goalDucks from '../../ducks/goal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import copy from 'copy-to-clipboard';
import { toastify } from '../../ducks/toast';
import moment from 'moment';
import './Goal.css';
import { checkPermission } from '../../services/permissions';

const Goal = () => {

  const validator = new SimpleReactValidator({ locale: 'en' });
  const editvalidator = new SimpleReactValidator({ locale: 'en' });
  let dispatch = useDispatch()

  let [state, setState] = useState({
    queStatus: 'today',
    selected: 0,
    addGoalOpenModal: false,
    popoverActive: false,
    frmdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    tdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    fDate: new Date(),
    tDate: new Date(),
    isGoalUrl: false,
    cnvamnt: '',
    gn: '',
    gu: '',
    ht: '',
    gut: '1',
    goalId: '',
    deleteGoalOpen: false,
    deleteGoalId: '',
    editGoalOpen: false,
    itrkglcd: '',
    errMessage: {},
    isReloadGoal: true
  });

  let statusTabs = [
    { id: 1, label: 'Last 24 hours', value: 'today' },
    { id: 2, label: 'Last 7 days', value: 'week' },
    { id: 3, label: 'Last 30 days', value: 'month' },
    { id: 4, label: 'Custom date', value: 'custom' },
  ]

  const dataGoal = useSelector((state) => state.goal);
  const profile = useSelector(state => state.auth.profile);
  const isLoading = useSelector(state => state.loading.isLoading)


  const goalData = dataGoal?.goal;

  let changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, [])

  const tabs = statusTabs.map((item, i) => ({
    key: i,
    content: item.label,
    id: `${item.label}-${i}`,
    onAction: () => goalStatusClick(item.value)
  }));

  let goalStatusClick = useCallback((typ) => {
    let obj = {
      queStatus: typ,
    }
    changeNameValue(obj)
  }, [])

  // dateFormatte 
  let formattedDate = (date) => {
    let formatteDt = moment(date ? new Date(date) : new Date).format('YYYY-MM-DD')
    return formatteDt.toString()
  }

  useEffect(() => {
    let obj = {}
    let today = new Date();

    if (state.selected === 0) {
      changeNameValue({ popoverActive: false })
      let fDate = formattedDate(today)
      let tDate = formattedDate(today)
      obj = { 'rt': 'today', fDate, tDate };
      changeNameValue(obj)
    }
    else if (state.selected === 1) {
      changeNameValue({ popoverActive: false })
      let startDate = new Date()
      startDate.setDate(today.getDate() - 7)
      let fDate = formattedDate(startDate)

      let endDate = new Date()
      endDate.setDate(today.getDate() - 1)
      let tDate = formattedDate(endDate)

      obj = { 'rt': 'week', fDate, tDate };
      changeNameValue(obj)
    }
    else if (state.selected === 2) {
      changeNameValue({ popoverActive: false })
      let startDate = new Date()
      startDate.setDate(today.getDate() - 30)
      let fDate = formattedDate(startDate)

      let endDate = new Date()
      endDate.setDate(today.getDate() - 1)
      let tDate = formattedDate(endDate)

      obj = { 'rt': 'month', fDate, tDate };
      changeNameValue(obj)
    }
    else if (state.selected === 3) {
      changeNameValue({ popoverActive: true })
    }
  }, [state.selected])

  let getGoalApicall = () => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(state.fDate)) {
      let obj = {
        'rt': state.rt,
        fDate: state.fDate,
        tDate: state.tDate
      }
      global.getGoalObj = obj;
      dispatch(goalDucks.getGoalDataById(obj))
    }
  }

  useEffect(() => {
    getGoalApicall()
  }, [state.rt, state.fDate, state.tDate])

  const handleSelectedDates = useCallback(() => {
    let startDate = new Date(state.frmdt);
    let endDate = new Date(state.tdt);

    let fDate = formattedDate(startDate);
    let tDate = formattedDate(endDate);

    let obj = {
      rt: 'custom',
      fDate,
      tDate,
    }
    changeNameValue({ popoverActive: false });
    dispatch(goalDucks.getGoalDataById(obj));
  }, [state.frmdt, state.tdt])

  useEffect(() => {
    if (goalData) {
      const sortedGoalData = [...goalData].sort(function (a, b) {
        var nameA = a.gn.toLowerCase(), nameB = b.gn.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      changeNameValue({ filterGoal: sortedGoalData })
    }
  }, [goalData]);

  const openCloseModal = useCallback((name, value, type) => {
    if (name === 'addGoalOpenModal' && value === false) {
      clearState()
      changeNameValue({ errMessage: {} })
    }

    if (type !== undefined)
      changeNameValue({ [name]: !value });
  }, [state.addGoalOpenModal]);

  let createGoalBtn = () => {
    if (validator.errorMessages.gn !== null || validator.errorMessages.cnvamnt != null || validator.errorMessages.gu != null || validator.errorMessages.gut != null || validator.errorMessages.ht != null) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    } else {
      changeNameValue({ errMessage: {} });
      let obj = {
        cnvamnt: state.cnvamnt,
        gn: state.gn,
        gu: state.gu,
        gut: state.gut,
        ht: state?.gu?.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '').split('/')[0].split('?')[0],
        isFirst: false
      };
      if (state.gut === '1') {
        obj.ht = state?.ht?.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '').split('/')[0].split('?')[0];
      }

      if (goalData && goalData.length === 0) {
        if (profile && profile.vstp && profile.vstp.gl && profile.vstp.gl.st == false) {
          obj.isFirst = true
        }
      }

      dispatch(goalDucks.createNewGoal(obj));
      openCloseModal('addGoalOpenModal', state.addGoalOpenModal, 'close');
      clearState();
    }
  }

  let handleDeleteCall = useCallback(() => {
    dispatch(goalDucks.deleteGoal(state?.deleteGoalId));
    setTimeout(() => openCloseModal('deleteGoalOpen', state.deleteGoalOpen, 'close'), 100);
  }, [state.deleteGoalId])


  const handleEditGoal = (data) => {
    openCloseModal('editGoalOpen', state.editGoalOpen, 'open');
    let obj = {
      gName: data.gn,
      gValue: data.cnvamnt,
      goalId: data._id,
      gUrlt: data.gut,
      gUrl: data.gu,
      hTime: data.ht,
      isFirst: false,
      itrkglcd: data.itrkglcd
    };
    changeNameValue(obj);
    obj = {}
    changeNameValue({ errMessage: {} });
  };

  const handleGoalChange = () => {
    if (editvalidator.errorMessages.gName !== null || editvalidator.errorMessages.gValue != null) {
      editvalidator.showMessages();
      editvalidator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: editvalidator.errorMessages });
    } else {
      let obj = {
        cnvamnt: state.gValue,
        gn: state.gName,
        itrkglcd: state.itrkglcd,
        _id: state.goalId,
      };
      openCloseModal('editGoalOpen', state.editGoalOpen, 'close');
      dispatch(goalDucks.updateGoal(obj));
      changeNameValue({ errMessage: {} });
    }
  };

  const clearState = () => {
    let obj = {
      cnvamnt: '',
      gn: '',
      ht: '',
      gut: '1',
      gu: '',
      gName: '',
      gValue: '',
      itrkglcd: false
    }
    changeNameValue(obj);
  }

  let refreshPage = useCallback(() => {
    let today = new Date();
    let fDate = formattedDate(today);
    let tDate = formattedDate(today);

    let obj = {
      rt: 'today',
      fDate: fDate,
      tDate: tDate
    }
    dispatch(goalDucks.getGoalDataById(obj))
  }, []);

  let handleCopyGoal = (id) => {
    let copyTxt = 'fnCustomGoalComplete("' + id + '");';
    copy(copyTxt, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Function copied" }));
  }

  useEffect(() => {
    if (isLoading || state.filterGoal?.length === undefined) {
      changeNameValue({ isReloadGoal: true })
    }
    else {
      changeNameValue({ isReloadGoal: false })
    }
  }, [isLoading, goalData, state.filterGoal])


  return (
    <>
      {
        checkPermission('goal', 'isview', profile) &&
        <GoalCard
          state={state}
          tabs={tabs}
          changeNameValue={changeNameValue}
          openCloseModal={openCloseModal}
          handleSelectedDates={handleSelectedDates}
          goalData={goalData}
          createGoalBtn={createGoalBtn}
          handleDeleteCall={handleDeleteCall}
          handleGoalChange={handleGoalChange}
          validator={validator}
          editvalidator={editvalidator}
          refreshPage={refreshPage}
          handleCopyGoal={handleCopyGoal}
          checkPermission={checkPermission}
          profile={profile}
          handleEditGoal={handleEditGoal}
          isLoading={isLoading}
        />
      }
    </>
  );
};

export default Goal;
