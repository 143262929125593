import React, { useCallback, useEffect, useState } from 'react';
import { ShopifyPricingPlanCard } from "../../components";
import { useDispatch } from 'react-redux';
import * as shopifyPricingPlan from '../../ducks/shopifyPricingPlan';
import { useSelector } from 'react-redux';

const ShopifyPricingPlan = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    profile: {},
    planBilling: true,
    proPlanList: [],
    proOptionList: [],
    growthPlanList: [],
    growthOptionList: [],
    selctdProPlanPrice: '',
    selctdProPlanVisitor: '',
    selctdGrowthPlanPrice: '',
    selctdGrowthPlanVisitor: '',
    selctdPlanId: '',
    selctdPlanPrice: '',
    plvChange: false,
    selectedPlanTyp: 'month'
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const profileState = useSelector(state => state.auth.profile);
  const lstPlan = useSelector(state => state.shopifyPricingPlan.shopifyPricingPlan);

  //api call.
  useEffect(() => {
    dispatch(shopifyPricingPlan.getShopifyPricingPlan());
  }, []);

  useEffect(() => {
    //set profileState data.
    if (profileState && profileState.billing) {
      changeNameValue({ profile: profileState, selctdPlanId: profileState.billing.pobj._id, selctdPlanPrice: profileState?.billing?.pobj?.prc, selectedPlanTyp: profileState?.billing?.pobj?.pt });
    }

    if (lstPlan) {
      const growthPlans = lstPlan.filter(plan => plan.grp === 'growth');
      const proPlans = lstPlan.filter(plan => plan.grp === 'pro');
      if (growthPlans.length > 0 && proPlans.length > 0) {
        changeNameValue({ growthPlanList: growthPlans, proPlanList: proPlans });
      }
      changeNameValue({ plvChange: true });
    }
  }, [profileState, lstPlan]);

  //handle Tab Change.
  const handleTabChange = (data) => {
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Plan Toggle Dashboard', data.tabName, state.profile?.e || '', { transport: 'beacon' });

        window.gtag('event', 'Plan Toggle Dashboard', { 'event_action': data.tabName, 'event_label': state.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  }

  // function convert visitors.
  const convert = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  };

  // function updatePlanOptions
  const updatePlanOptions = (planList, optionListKey) => {
    if (planList) {
      planList.sort((a, b) => a.uaq - b.uaq);

      const options = planList.map((val) => ({
        label: `${convert(val.uaq)} Visitors/month`,
        value: val.uaq,
      }));
      changeNameValue({ [optionListKey]: options });
    }
  };

  // useEffect to update plan options on state change
  useEffect(() => {
    const proMonthOption = state.proPlanList.filter((elm) => elm.pt === state.selectedPlanTyp);
    const growthMonthOption = state.growthPlanList.filter((elm) => elm.pt === state.selectedPlanTyp);

    updatePlanOptions(proMonthOption, 'proOptionList');
    updatePlanOptions(growthMonthOption, 'growthOptionList');

    let prcPro = proMonthOption.length > 0 && proMonthOption.sort((a, b) => a.prc - b.prc);
    let prcGrowth = growthMonthOption.length > 0 && growthMonthOption.sort((a, b) => a.prc - b.prc);

    changeNameValue({ selctdProPlanPrice: prcPro[0]?.prc, selctdGrowthPlanPrice: prcGrowth[0]?.prc, selctdProPlanVisitor: prcPro[0]?.uaq, selctdGrowthPlanVisitor: prcGrowth[0]?.uaq });
    changeNameValue({ plvChange: false });
  }, [state.proPlanList, state.growthPlanList]);

  useEffect(() => {
    if (lstPlan) {
      let sortLstPlan = lstPlan.filter((elm) => elm.prc !== 0).sort((elmA, elmB) => elmA.prc - elmB.prc);
      let index = sortLstPlan?.findIndex(item => item.prc === profileState?.billing?.pobj?.prc);

      if ((index + 1) < sortLstPlan?.length) {
        let nextPlan = sortLstPlan[index + 1];
        if (nextPlan?.grp === 'growth') {
          changeNameValue({ selctdGrowthPlanVisitor: parseInt(nextPlan.uaq), selctdGrowthPlanPrice: nextPlan.prc })
        }
        else {
          changeNameValue({ selctdProPlanVisitor: parseInt(nextPlan.uaq), selctdProPlanPrice: nextPlan.prc })
        }
      }
      else {
        let nextPlan = sortLstPlan[index];

        if (nextPlan?.grp === 'growth') {
          changeNameValue({ selctdGrowthPlanVisitor: parseInt(nextPlan.uaq), selctdGrowthPlanPrice: nextPlan.prc })
        }
        else {
          changeNameValue({ selctdProPlanVisitor: parseInt(nextPlan.uaq), selctdProPlanPrice: nextPlan.prc })
        }
      }
    }
  }, [state.plvChange])

  // change visitior
  useEffect(() => {
    const proMonthOption = state.proPlanList.filter((elm) => elm.pt === `${state.selectedPlanTyp}`);
    const growthMonthOption = state.growthPlanList.filter((elm) => elm.pt === `${state.selectedPlanTyp}`);

    if (state.selctdProPlanVisitor !== '') {
      let selctdProPlanPrice = proMonthOption.find((elm) => elm.uaq === state.selctdProPlanVisitor);
      changeNameValue({ selctdProPlanPrice: selctdProPlanPrice?.prc })
    }

    if (state.selctdGrowthPlanVisitor !== '') {
      let selctdGrowthPlanPrice = growthMonthOption.find((elm) => elm.uaq === state.selctdGrowthPlanVisitor);
      changeNameValue({ selctdGrowthPlanPrice: selctdGrowthPlanPrice?.prc })
    }

  }, [state.selctdProPlanVisitor, state.selctdGrowthPlanVisitor])

  const handleShopifyPayment = (val) => {

    let p = lstPlan?.find((elm) => elm.prc === val);

    const obj = {
      shop: state.profile.billing.s,
      pid: p.pid,
      fn: state.profile.fn,
      e: state.profile.e,
      p: p
    }

    dispatch(shopifyPricingPlan.shopifyPayment(obj));
  }

  return (
    <ShopifyPricingPlanCard
      state={state}
      planBilling={state.planBilling}
      changeNameValue={changeNameValue}
      handleTabChange={handleTabChange}
      handleShopifyPayment={handleShopifyPayment}
    />
  );
};

export default ShopifyPricingPlan;
