import NotificationConstant from '../../services/NotificationConstant.json';

let countDownInterval = '';
let animationClass = '';
let compactMobi = 'NFDGdflt26';
let positionClass = '';
let textAlign = '';
let activeTab = '';
let cnt = 0;
let iscall = false;
let arrClickCode = [];

//convet hex to rgba
function hexToRGBA(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16), g = parseInt(hex.slice(3, 5), 16), b = parseInt(hex.slice(5, 7), 16);
  if (alpha)
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  else return 'rgb(' + r + ', ' + g + ', ' + b + ')';
}

var replaceFeedHTML = function (tab, _cnf) {
  let icon = '';
  let title = '';
  let tempHTML = _cnf?.rvpttltx;
  let starDivHTML = '<div class="header-rating-value" style="display:{IS_DSPL_STR_TTL}; background:{RV_TTL_RTNG_BGCLR}; color:{RV_TTL_RTNG_FNTCLR};">{{AVERAGE}}<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15px" height="15px"version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 406.125 406.125"style="enable-background:new 0 0 406.125 406.125;" xml:space="preserve"><g><path style="fill:{RV_TTL_RTNG_FNTCLR};"d="M260.133,155.967c-4.487,0-9.25-3.463-10.64-7.73L205.574,13.075c-1.39-4.268-3.633-4.268-5.023,0   L156.64,148.237c-1.39,4.268-6.153,7.73-10.64,7.73H3.88c-4.487,0-5.186,2.138-1.553,4.78l114.971,83.521   c3.633,2.642,5.454,8.242,4.064,12.51L77.452,391.932c-1.39,4.268,0.431,5.592,4.064,2.951l114.971-83.521   c3.633-2.642,9.519-2.642,13.152,0l114.971,83.529c3.633,2.642,5.454,1.317,4.064-2.951l-43.911-135.154  c-1.39-4.268,0.431-9.868,4.064-12.51l114.971-83.521c3.633-2.642,2.934-4.78-1.553-4.78H260.133V155.967z"></path></g></svg></div>';
  if (tab === 'All') {
    title = _cnf?.rvallttl ? _cnf?.rvallttl : 'Overall Rating';
  }
  if (tab === 'Google') {
    icon = '<img width="15" height="15" class="noti_rvfd_scl_icn" src=' + NotificationConstant.REVIEW_APP_IMG_GGL + '>';
    title = 'Google';
  }
  else if (tab === 'Facebook') {
    icon = '<img width="15" height="15" class="noti_rvfd_scl_icn" src=' + NotificationConstant.REVIEW_APP_IMG_FB + '>';
    title = 'Facebook';
  }
  tempHTML = tempHTML.replace(/{{STAR}}/gi, NotificationConstant.STAR);
  tempHTML = tempHTML.replace(/{STR_RTNG_WDTH_1}/gi, 15);
  tempHTML = tempHTML.replace(/{STR_RTNG_HGHT_1}/gi, 15);
  tempHTML = tempHTML.replace(/{{AVERAGE}}/gi, NotificationConstant.AVERAGE);
  tempHTML = tempHTML.replace(/{{SOCIAL_ICON}}/gi, icon);
  tempHTML = tempHTML.replace(/{{SOCIAL_NAME}}/gi, title);
  tempHTML = tempHTML.replace(/{{TOTAL_COUNT}}/gi, NotificationConstant.TOTAL_COUNT);

  starDivHTML = starDivHTML.replace(/{{AVERAGE}}/gi, NotificationConstant.AVERAGE);
  starDivHTML = starDivHTML.replace(/{IS_DSPL_STR_TTL}/gi, _cnf?.rvpisrtl ? 'flex' : 'none');
  starDivHTML = starDivHTML.replace(/{RV_TTL_RTNG_BGCLR}/gi, _cnf?.rvrbcttl);
  starDivHTML = starDivHTML.replace(/{RV_TTL_RTNG_FNTCLR}/gi, _cnf?.rvrfcttl);
  tempHTML += starDivHTML;
  return tempHTML;
};

var timeSince = function (tu, tr, conf) {
  var intervalType;
  if (tu === 'hour') {
    intervalType = (conf.hplrl != '{x} hours' && conf.hplrl != '{x} hour') ? conf.hplrl : tr > 1 ? '{x} hours' : '{x} hour';
  }
  else {
    intervalType = (conf.dplrl != '{x} days' && conf.dplrl != '{x} day') ? conf.dplrl : tr > 1 ? '{x} days' : '{x} day';
  }
  return intervalType.replace(/{x}/gi, tr).replace(/{X}/gi, tr);
};

var replaceLotteryWidgetCode = function (event, _cnf) { //_notifID 
  const _arrCode = _cnf?.arrlcyltry;
  let clk_div_id = event.target.id;

  // if click on img, get closet div's id
  if (!clk_div_id) clk_div_id = event.target.closest('div') ? event.target.closest('div').id : event.target.id;

  if (clk_div_id && clk_div_id !== 'NFDGdflt26_ltry_mn_dv') {
    const get_click_div = document.querySelectorAll('#' + clk_div_id);

    if (get_click_div.length > 0) {
      get_click_div.forEach(click_div => {
        if (click_div?.firstChild && !click_div.firstChild.id) {//
          cnt = 0;
          iscall = false;

          //generate rendom number from 0 to 100;
          let rndCode = Math.floor(Math.random() * 100);

          for (let i = 0; i < _arrCode.length; i++) {
            cnt += parseInt(_arrCode[i].cpnpsblt);
            if (cnt >= rndCode) {
              if (!iscall) {
                iscall = true;
                arrClickCode.push(_arrCode[i].cpncd);

                if (_arrCode[i].cpncd === 'Sad') {
                  click_div.innerHTML = '<div class="WN-game--eraser_after_shown" id="NFDGdflt26_ltry_cpn_cd"><img src="https://storage.googleapis.com/wiser_goal/website/images/icon-smile.svg" alt="image" /></div>';
                } else {
                  click_div.innerHTML = '<div class="WN-game--eraser_after_shown" id="NFDGdflt26_ltry_cpn_cd"><div class="WN-game--eraser-title WN-game--eraser-title_font-30">' + _arrCode[i].cpnnm + '</div></div>';
                }
              }
            }
          }
        }
      });
    }
  }
};

const DesignCard = (state, tmpData, frm) => {
  let { notiById, isMobileScreen, notifHeight, notifId, allTemplate, cnf } = state;

  let tempHTML = '';
  let templateList = '';
  let _cnf = cnf || tmpData?.cnf || {};
  let tmid = tmpData?.tmid || '';
  let _notifID = tmid ? tmpData._id : notifId || '';
  let notiByIdData = tmid ? tmpData : notiById || {};
  let dctp = notiByIdData.dctp || '';
  let idisnp = notiByIdData.idisnp || '';

  if (tmpData?.iornl === false && (frm === 'listCard' || frm === 'preview' || frm === 'modalList')) {
    tmpData?.vrnt?.length > 0 && tmpData.vrnt.filter(z => {
      if (z.iornl === true) { tmid = z.tmid; _cnf = z.cnf; dctp = z.dctp; idisnp = z.idisnp; }
    });
  }

  if (tmid) {
    templateList = allTemplate && allTemplate.filter(x => x._id === tmid)[0];
  }
  else {
    templateList = tmpData;
  }

  // don't replace unSupported dynamic value
  const getDynmcValue = (typ, val) => {
    if (frm !== 'preview') return val;

    if (state.arrChips?.length > 0 && state.arrChips.includes(typ)) return val;
    else return 'undefined';
  }

  var replaceFeedIcon = function (tab, _cnf) {
    let icon = '';
    let title = '';
    let HTML = _cnf?.rvfl;
    if (tab === 'All' || tab === 'Google') {
      icon = '<img width="15" height="15" class="noti_rvfd_scl_icn" src=' + NotificationConstant.REVIEW_APP_IMG_GGL + '>';
      title = 'Google';
    }
    else if (tab === 'Facebook') {
      icon = '<img width="15" height="15" class="noti_rvfd_scl_icn" src=' + NotificationConstant.REVIEW_APP_IMG_FB + '>';
      title = 'Facebook';
    }

    HTML = HTML.replace(/{{SOCIAL_ICON}}/gi, icon);
    HTML = HTML.replace(/{{SOCIAL_NAME}}/gi, title);
    HTML = HTML.replace(/{{USERNAME}}/gi, NotificationConstant.USERNAME);
    HTML = HTML.replace(/{{TIME_AGO}}/gi, _cnf?.dplrl ? _cnf?.dplrl.replace(/{x}/gi, '2').replace(/{X}/gi, '2') : '');
    HTML = HTML.replace(/{{REVIEW_TEXT}}/gi, NotificationConstant.REVIEW_TEXT);
    return HTML;
  };

  if (!templateList)
    return '';


  if (templateList && templateList !== undefined && templateList.htn) {

    // from template list modal (review feed use img)
    if (frm === 'modalList' && notiById.ntt === 'review_feed') {
      tempHTML = tmpData?.iu || '';
    }
    else tempHTML = templateList?.htn?.htn || '';

    //for image url
    let iu = '';
    if (_cnf && _cnf?.it && _cnf?.it[0] === 0 && _cnf?.iu) {
      iu = _cnf?.iu;
    }
    else if (_cnf && _cnf?.it && _cnf?.it[0] === 1) {
      iu = 'https://storage.googleapis.com/wiser_goal/notif_icon/icomap.png?q=123';
    }
    else if (_cnf && _cnf?.it && _cnf?.it[0] === 2) {
      iu = 'https://storage.googleapis.com/wiser_goal/notif_icon/product-icon.png';
    }
    else if (_cnf && _cnf?.it && _cnf?.it[0] === 3) {
      iu = 'https://storage.googleapis.com/wiserimg/template_icon/user.png';
    }
    else if (_cnf && _cnf?.it && _cnf?.it[0] === 4) {
      iu = 'https://storage.googleapis.com/wiserimg/template_icon/flag_icon.svg?q=123';
    }

    tempHTML = tempHTML.replace(/{IMAGE_URL}/gi, iu);
    tempHTML = tempHTML.replace(/{IMAGE_URL}/gi, iu);
    tempHTML = tempHTML.replace(/{IS_IMG_DISPLAY}/gi, _cnf?.iimg ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{RNDM_CLR_CD}/gi, '#645CFC');
    tempHTML = tempHTML.replace(/{IMG_PADD}/gi, (_cnf?.imgpd ? _cnf?.imgpd : 0) + 'px');
    tempHTML = tempHTML.replace(/{BACKGROUND_COLOR}/gi, _cnf?.bg || '');
    tempHTML = tempHTML.replace(/{BG_CLR_BLR}/gi, _cnf?.bgblr + 'px' || '');
    tempHTML = tempHTML.replace(/{RADIUS_SIZE}/gi, _cnf?.r + 'px' || '');
    tempHTML = tempHTML.replace(/{BORDER_SIZE}/gi, _cnf?.rw + 'px');
    tempHTML = tempHTML.replace(/{BORDER_STYLE}/gi, _cnf?.rs);
    tempHTML = tempHTML.replace(/{BORDER_COLOR}/gi, _cnf?.rc);
    tempHTML = tempHTML.replace(/{ICON_BG_COLOR}/gi, _cnf?.ibg || '');
    tempHTML = tempHTML.replace(/{BRAND_LINE_DISPLAY}/gi, (_cnf?.ib && !idisnp) ? 'block' : 'none');
    tempHTML = tempHTML.replace(/{BRAND_LINE_FLEX}/gi, (_cnf?.ib && !idisnp) ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{BRAND_LINE_URL}/gi, _cnf?.bu || '');
    tempHTML = tempHTML.replace(/{NOTIFICATION_CLICK_URL}/gi, _cnf?.bu);
    tempHTML = tempHTML.replace(/{CLOSE_BUTTON_DISPLAY}/gi, (_cnf?.cb && !idisnp) ? 'block' : 'none');
    tempHTML = tempHTML.replace(/{CLOSE_BUTTON_COLOR}/gi, _cnf?.cbg || '');
    tempHTML = tempHTML.replace(/{CTA_DISPLAY}/gi, ((!idisnp || templateList?.ttn === 'promotional_banner_timer') && _cnf?.ica) ? 'block' : 'none');
    tempHTML = tempHTML.replace(/{CTA_BN_FLEX}/gi, (_cnf?.ica && !idisnp) ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{CTA_URL}/gi, _cnf?.cu || '');
    // is trim for mobile 
    tempHTML = tempHTML.replace(/{TRIM_CLS}/gi, _cnf?.itrmmbl ? 'NoTi_TruNcte' : '');

    let isFreePlanPowerdBy = true;
    if (localStorage.getItem('utyp') === 'appsumo' || localStorage.getItem('csub') !== 'free') {
      isFreePlanPowerdBy = false;
    }

    tempHTML = tempHTML.replace(/{PWRDBY_LBL}/gi, (frm === 'preview' && isFreePlanPowerdBy) ? '<div class="PoWerd_TootTip">Get any paid plan to remove it</div><img class="PoWerd_IcOnImg" src="https://storage.googleapis.com/wiser_goal/notif_icon/power-icon.svg"/><span>by wisernotify</span> <img class="PoWerd_CloSe" src="https://storage.googleapis.com/wiser_goal/notif_icon/power-close.svg"/>' : '');

    if (frm === 'preview')
      tempHTML = tempHTML.replace(/href/gi, ''); //remove href: bcz when set link in text-editor, on line click it redirect to another page

    tempHTML = tempHTML.replace(/{SHDOW_HZ_OFF}/gi, _cnf?.shdho + 'px' || '');
    tempHTML = tempHTML.replace(/{SHDOW_VL_OFF}/gi, _cnf?.shdvo + 'px' || '');
    tempHTML = tempHTML.replace(/{SHDOW_BLR_RDUS}/gi, _cnf?.shdbr + 'px' || '');
    tempHTML = tempHTML.replace(/{SHDOW_CLR}/gi, _cnf?.shdclr || '');

    if (_cnf?.r !== undefined) tempHTML = tempHTML.replace(/{PADDING_LEFT}/gi, Math.floor(_cnf?.r / 3) + 'px');
    if (_cnf?.ibg) tempHTML = tempHTML.replace(/{COUNT_LINE_OPACITY_BG}/gi, hexToRGBA(_cnf?.ibg, 0.3));

    //for limited stock
    if (_cnf?.cnt) {
      tempHTML = tempHTML.replace(/{COUNT}/gi, _cnf?.cnt.txt);
      tempHTML = tempHTML.replace(/{ITEM_COUNT}/gi, _cnf?.cnt.txt);
      tempHTML = tempHTML.replace(/{COUNT_LINE_FONT_COLOR}/gi, _cnf?.cnt.clr);
      tempHTML = tempHTML.replace(/{COUNT_LINE_FONT_FAMILY}/gi, _cnf?.cnt.fml);
      tempHTML = tempHTML.replace(/{COUNT_LINE_FONT_SIZE}/gi, _cnf?.cnt.sz + 'px');
      tempHTML = tempHTML.replace(/{COUNT_LINE_FONT_WEIGHT}/gi, _cnf?.cnt.stl);
    }

    if (_cnf?.fl) {
      tempHTML = tempHTML.replace(/{FIRST_LINE}/gi, _cnf?.fl.txt);
      tempHTML = tempHTML.replace(/{FIRST_LINE_FONT_COLOR}/gi, _cnf?.fl.clr);
      tempHTML = tempHTML.replace(/{FIRST_LINE_FONT_FAMILY}/gi, _cnf?.fl.fml);
      tempHTML = tempHTML.replace(/{FIRST_LINE_FONT_SIZE}/gi, _cnf?.fl.sz + 'px');
      tempHTML = tempHTML.replace(/{FIRST_LINE_FONT_WEIGHT}/gi, _cnf?.fl.stl);
      if (_cnf?.fl.clr != undefined)
        tempHTML = tempHTML.replace(/{FIRST_LINE_OPACITY_BG}/gi, hexToRGBA(_cnf?.fl.clr, 0.3));
    }
    if (_cnf?.sl) {
      tempHTML = tempHTML.replace(/{SECOND_LINE}/gi, _cnf?.sl.txt);
      tempHTML = tempHTML.replace(/{SECOND_LINE_FONT_COLOR}/gi, _cnf?.sl.clr);
      tempHTML = tempHTML.replace(/{SECOND_LINE_FONT_FAMILY}/gi, _cnf?.sl.fml);
      tempHTML = tempHTML.replace(/{SECOND_LINE_FONT_SIZE}/gi, _cnf?.sl.sz + 'px');
      tempHTML = tempHTML.replace(/{SECOND_LINE_FONT_WEIGHT}/gi, _cnf?.sl.stl);
    }
    if (_cnf?.tl) {
      tempHTML = tempHTML.replace(/{THIRD_LINE}/gi, _cnf?.tl.txt);
      tempHTML = tempHTML.replace(/{THIRD_LINE_FONT_COLOR}/gi, _cnf?.tl.clr);
      tempHTML = tempHTML.replace(/{THIRD_LINE_FONT_FAMILY}/gi, _cnf?.tl.fml);
      tempHTML = tempHTML.replace(/{THIRD_LINE_FONT_SIZE}/gi, _cnf?.tl.sz + 'px');
      tempHTML = tempHTML.replace(/{THIRD_LINE_FONT_WEIGHT}/gi, _cnf?.tl.stl);
    }
    if (_cnf?.frtl) {
      tempHTML = tempHTML.replace(/{FOURTH_LINE}/gi, _cnf?.frtl.txt);
      tempHTML = tempHTML.replace(/{FOURTH_LINE_FONT_COLOR}/gi, _cnf?.frtl.clr);
      tempHTML = tempHTML.replace(/{FOURTH_LINE_FONT_FAMILY}/gi, _cnf?.frtl.fml);
      tempHTML = tempHTML.replace(/{FOURTH_LINE_FONT_SIZE}/gi, _cnf?.frtl.sz + 'px');
      tempHTML = tempHTML.replace(/{FOURTH_LINE_FONT_WEIGHT}/gi, _cnf?.frtl.stl);
    }
    if (_cnf?.fftl) {
      tempHTML = tempHTML.replace(/{FIFTH_LINE}/gi, _cnf?.fftl.txt);
      tempHTML = tempHTML.replace(/{FIFTH_LINE_FONT_COLOR}/gi, _cnf?.fftl.clr);
      tempHTML = tempHTML.replace(/{FIFTH_LINE_FONT_FAMILY}/gi, _cnf?.fftl.fml);
      tempHTML = tempHTML.replace(/{FIFTH_LINE_FONT_SIZE}/gi, _cnf?.fftl.sz + 'px');
      tempHTML = tempHTML.replace(/{FIFTH_LINE_FONT_WEIGHT}/gi, _cnf?.fftl.stl);
    }
    if (_cnf?.sxtl) {
      tempHTML = tempHTML.replace(/{SIXTH_LINE}/gi, _cnf?.sxtl.txt);
      tempHTML = tempHTML.replace(/{SIXTH_LINE_FONT_COLOR}/gi, _cnf?.sxtl.clr);
      tempHTML = tempHTML.replace(/{SIXTH_LINE_FONT_FAMILY}/gi, _cnf?.sxtl.fml);
      tempHTML = tempHTML.replace(/{SIXTH_LINE_FONT_SIZE}/gi, _cnf?.sxtl.sz + 'px');
      tempHTML = tempHTML.replace(/{SIXTH_LINE_FONT_WEIGHT}/gi, _cnf?.sxtl.stl);
    }
    if (_cnf?.svntl) {
      tempHTML = tempHTML.replace(/{SEVENTH_LINE}/gi, _cnf?.svntl.txt);
      tempHTML = tempHTML.replace(/{SEVENTH_LINE_FONT_COLOR}/gi, _cnf?.svntl.clr);
      tempHTML = tempHTML.replace(/{SEVENTH_LINE_FONT_FAMILY}/gi, _cnf?.svntl.fml);
      tempHTML = tempHTML.replace(/{SEVENTH_LINE_FONT_SIZE}/gi, _cnf?.svntl.sz + 'px');
      tempHTML = tempHTML.replace(/{SEVENTH_LINE_FONT_WEIGHT}/gi, _cnf?.svntl.stl);
    }
    if (_cnf?.bn) {
      tempHTML = tempHTML.replace(/{BRAND_LINE}/gi, _cnf?.bn.txt);
      tempHTML = tempHTML.replace(/{BRAND_LINE_FONT_COLOR}/gi, _cnf?.bn.clr);
      tempHTML = tempHTML.replace(/{BRAND_LINE_FONT_FAMILY}/gi, _cnf?.bn.fml);
      tempHTML = tempHTML.replace(/{BRAND_LINE_FONT_SIZE}/gi, _cnf?.bn.sz + 'px');
      tempHTML = tempHTML.replace(/{BRAND_LINE_FONT_WEIGHT}/gi, _cnf?.bn.stl);
    }

    //for replace HTML snippet
    if (idisnp === true && notiByIdData?.isnpwdsg !== true && _cnf?.fdln != undefined) {
      tempHTML = _cnf?.fdln;
    }
    if (_cnf?.cl) {
      tempHTML = tempHTML.replace(/{CTA_LABLE}/gi, _cnf?.cl?.txt);
      tempHTML = tempHTML.replace(/{CTA_LINE_FONT_COLOR}/gi, _cnf?.cl?.clr);
      tempHTML = tempHTML.replace(/{CTA_LINE_FONT_FAMILY}/gi, _cnf?.cl?.fml);
      tempHTML = tempHTML.replace(/{CTA_LINE_FONT_SIZE}/gi, _cnf?.cl?.sz + 'px');
      tempHTML = tempHTML.replace(/{CTA_LINE_FONT_WEIGHT}/gi, _cnf?.cl?.stl);
      tempHTML = tempHTML.replace(/{CTA_BG_COLOR}/gi, _cnf?.clbg);
    }
    if (_cnf?.cntv) {
      tempHTML = tempHTML.replace(/{VIEWER_COUNT}/gi, _cnf?.cntv?.txt);
      tempHTML = tempHTML.replace(/{COUNT_VIEW_LINE_FONT_COLOR}/gi, _cnf?.cntv?.clr);
      tempHTML = tempHTML.replace(/{COUNT_VIEW_LINE_FONT_FAMILY}/gi, _cnf?.cntv?.fml);
      tempHTML = tempHTML.replace(/{COUNT_VIEW_LINE_FONT_SIZE}/gi, _cnf?.cntv?.sz + 'px');
      tempHTML = tempHTML.replace(/{COUNT_VIEW_LINE_FONT_WEIGHT}/gi, _cnf?.cntv?.stl);
    }
    if (frm === 'preview' && isMobileScreen && _cnf?.flecmmbl) { //for mobile view
      tempHTML = tempHTML.replace(/{FIRST_LINE_ECOMM_SNPT}/gi, _cnf?.flecmmbl?.txt);
    }
    if (_cnf?.flecmdsk) {
      tempHTML = tempHTML.replace(/{FIRST_LINE_ECOMM_SNPT}/gi, _cnf?.flecmdsk?.txt);
    }

    if (_cnf?.ib) {
      tempHTML = tempHTML.replace(/{BRAND_LINE_DISPLAY_THIRD_LINE}/gi, (_cnf?.ica && !idisnp) ? 'none' : 'block');
    }
    else tempHTML = tempHTML.replace(/{BRAND_LINE_DISPLAY_THIRD_LINE}/gi, 'none');

    if (_cnf?.rimg?.twt) {
      tempHTML = tempHTML.replace(/{{REVIEW_APP_IMG}}/gi, '<img src=' + _cnf?.rimg.twt + '>');
    }
    if (_cnf?.rimg?.inst) {
      tempHTML = tempHTML.replace(/{{REVIEW_APP_IMG}}/gi, '<img src=' + _cnf?.rimg.inst + '>');
    }
    if (_cnf?.rimg?.ggl) {
      tempHTML = tempHTML.replace(/{{REVIEW_APP_IMG}}/gi, '<img src=' + _cnf?.rimg.ggl + '>');
    }
    if (_cnf?.rimg?.fb) {
      tempHTML = tempHTML.replace(/{{REVIEW_APP_IMG}}/gi, '<img src=' + _cnf?.rimg.fb + '>');
    }

    tempHTML = tempHTML.replace(/{VIDEO_LINK}/gi, _cnf?.vurl || '');
    tempHTML = tempHTML.replace(/{NUMBER_PLACE}/gi, _cnf?.pphn || '');
    tempHTML = tempHTML.replace(/{EMAIL_PLACE}/gi, _cnf?.peml || '');
    tempHTML = tempHTML.replace(/{USERNAME_PLACE}/gi, _cnf?.punm || '');
    tempHTML = tempHTML.replace(/{USERNAME_INPUT_DISPLAY}/gi, _cnf?.iunm ? 'block' : 'none');
    tempHTML = tempHTML.replace(/{IS_CLCR_SCL_PRV_PLCY}/gi, _cnf?.iclplcy ? 'block' : 'none');
    tempHTML = tempHTML.replace(/{CLCR_SCL_PRV_PLCY}/gi, _cnf?.clplcy);
    // if (_cnf?.iphn && _cnf?.ieml) {
    //   tempHTML = tempHTML.replace(/{EMAIL_PHONE_ICON_DISPLAY}/gi, 'block');
    //   tempHTML = tempHTML.replace(/{PHONE_ICON_DISPLAY}/gi, 'none');
    //   tempHTML = tempHTML.replace(/{EMAIL_ICON_DISPLAY}/gi, 'none');
    // }

    if (_cnf?.iphn != undefined) {
      tempHTML = tempHTML.replace(/{NUMBER_INPUT_DISPLAY}/gi, _cnf?.iphn ? 'block' : 'none');
      // tempHTML = tempHTML.replace(/{EMAIL_PHONE_ICON_DISPLAY}/gi, 'none');
      //tempHTML = tempHTML.replace(/{PHONE_ICON_DISPLAY}/gi, _cnf?.iphn ? 'block' : 'none');
    }
    if (_cnf?.ieml != undefined) {
      tempHTML = tempHTML.replace(/{EMAIL_INPUT_DISPLAY}/gi, _cnf?.ieml ? 'block' : 'none');
      // tempHTML = tempHTML.replace(/{EMAIL_PHONE_ICON_DISPLAY}/gi, 'none');
      // tempHTML = tempHTML.replace(/{EMAIL_ICON_DISPLAY}/gi, _cnf?.ieml ? 'block' : 'none');
    }

    //show cta lable or not
    if (_cnf?.icbicn != undefined) {
      tempHTML = tempHTML.replace(/{IS_DSPL_ONLY_ICN}/gi, _cnf?.icbicn ? 'none' : 'block');
    }
    //CTA label icon url
    tempHTML = tempHTML.replace(/{CTA_ICON}/gi, _cnf?.cticn ? _cnf?.cticn : 'https://storage.googleapis.com/wiser_goal/user_notif_img/default/phone_email.png');

    //display feedback notification placeholder
    if (_cnf?.pmsg != undefined) {
      tempHTML = tempHTML.replace(/{MESSAGE_PLACE}/gi, _cnf?.pmsg);
    }
    if (_cnf?.imsg != undefined)
      tempHTML = tempHTML.replace(/{MESSAGE_INPUT_DISPLAY}/gi, _cnf?.imsg ? 'block' : 'none');

    //set padding, margin, border-radius with background color for quill editor
    const radius = _cnf && _cnf?.r ? _cnf?.r : 1;
    if (frm === 'preview')
      tempHTML = tempHTML.replace(/background-color/gi, 'padding:0 8px; margin-right:1px;  border-radius:' + radius + 'px; background-color');
    else
      tempHTML = tempHTML.replace(/background-color/gi, 'padding:1px 14px 1px; margin-right:1px;  border-radius:' + radius + 'px; display: inline-block; margin-bottom: 4px; background-color');

    //remove whitespace from textarea: callback notification
    if (frm === 'preview') {
      for (let i = 0; i < document.getElementsByClassName('fEEdbAcK_coNtENt_TextArea').length; i++) {
        document.getElementsByClassName('fEEdbAcK_coNtENt_TextArea')[i].value = '';
      }
    }

    //if timer notification
    let _idTyp = (frm === 'listCard') ? 'nid' : (frm === 'abTest') ? 'id' : '';
    if (_cnf?.tmdr != undefined && _cnf?.itm) {
      tempHTML = tempHTML.replace(/{BANNER_ID}/gi, _notifID + _idTyp);
      clearInterval(countDownInterval);

      let countDownDate = '';
      let days = '';
      let hours = '';
      let minutes = '';
      let seconds = '';

      if (frm === 'preview' && _cnf?.itmegrn) {
        days = _cnf?.itmd ? _cnf.itmd : 0;
        hours = _cnf?.itmh ? _cnf.itmh : 0;
        minutes = _cnf?.itmm ? _cnf.itmm : 0;
        seconds = _cnf?.itms ? _cnf.itms : 0;
        countDownDate = new Date().getTime() + (days * 24 * 60 * 60 * 1000) + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);
      }
      else countDownDate = new Date(_cnf?.tmdr).getTime();

      let cntDownCopy = countDownDate || '';

      setTimeout(() => {
        const ntfDays = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_dy_' + _notifID + _idTyp);
        if (ntfDays?.length > 0) ntfDays.forEach(element => element.innerHTML = '00');
        const ntfHrs = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_hr_' + _notifID + _idTyp);
        if (ntfHrs?.length > 0) ntfHrs.forEach(element => element.innerHTML = '00');
        const ntfMnts = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_mnt_' + _notifID + _idTyp);
        if (ntfMnts?.length > 0) ntfMnts.forEach(element => element.innerHTML = '00');
        const ntfScnds = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_scnd_' + _notifID + _idTyp);
        if (ntfScnds?.length > 0) ntfScnds.forEach(element => element.innerHTML = '00');
      }, 100);

      const updateCountdown = (cntDownCopy) => {
        let now = new Date().getTime();
        let distance = cntDownCopy - now;

        days = Math.max(0, Math.floor(distance / (1000 * 60 * 60 * 24)));
        hours = Math.max(0, Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        minutes = Math.max(0, Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        seconds = Math.max(0, Math.floor((distance % (1000 * 60)) / 1000));

        let daysID = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_dy_' + _notifID + _idTyp);
        if (daysID?.length > 0 && !_cnf?.itmrhddd) {
          daysID.forEach(element => element.innerHTML = days < 10 ? ('0' + days) : days);
        }
        else hours = Math.max(0, Math.floor(distance / (1000 * 60 * 60)));

        let hourID = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_hr_' + _notifID + _idTyp);
        if (hourID?.length > 0 && !_cnf?.itmrhdhh) {
          hourID.forEach(element => element.innerHTML = hours < 10 ? ('0' + hours) : hours);
        }
        else minutes = Math.max(0, Math.floor(distance / (1000 * 60)));

        let minID = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_mnt_' + _notifID + _idTyp);
        if (minID?.length > 0 && !_cnf?.itmrhdmm) {
          minID.forEach(element => element.innerHTML = minutes < 10 ? ('0' + minutes) : minutes);
        }
        else seconds = Math.max(0, Math.floor(distance / (1000)));

        let secID = document.querySelectorAll('#NFDGdflt26_hfb5Tbfm_scnd_' + _notifID + _idTyp);
        if (secID?.length > 0 && !_cnf?.itmrhdss) {
          secID.forEach(element => element.innerHTML = seconds < 10 ? ('0' + seconds) : seconds);
        }

        if (distance < 1000) {
          clearInterval(countDownInterval);
        }
      };

      setTimeout(() => {
        clearInterval(countDownInterval);
        if (frm === 'preview') {
          countDownInterval = setInterval(() => {
            updateCountdown(cntDownCopy);
          }, 1000);
        } else updateCountdown(cntDownCopy);
      }, 700);
    }

    let _un = NotificationConstant.USERNAME;
    let _fn = NotificationConstant.F_NAME;
    let _ln = NotificationConstant.L_NAME;
    let _ct = NotificationConstant.CITY;
    let _st = NotificationConstant.STATE;
    let _cn = NotificationConstant.COUNTRY;

    //for capitalize each word
    if (_cnf?.icpew === true) {
      _un = _un.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      _fn = _fn.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      _ln = _ln.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      _ct = _ct.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      _st = _st.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      _cn = _cn.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    }

    //for Shorten name. username
    if (_cnf?.isrtnm === true) {
      _un = _un ? _un.split(' ').map(x => x[0]).join(' ').toUpperCase() + '.' : '';
    }

    //check if firstname is shorten is on/off
    if (_cnf?.isrtfnm)
      _fn = _fn ? _fn.substring(0, 1).toUpperCase() + '.' : '';

    //check if lastname is shorten is on/off
    if (_cnf?.isrtlnm)
      _ln = _ln ? _ln.substring(0, 1).toUpperCase() + '.' : '';

    //check if country name is shorten is on/off
    if (_cnf?.isrtcn)
      _cn = _cn ? _cn.substring(0, 2).toUpperCase() : '';

    //for add remove on_hover show brand name class
    if (_cnf?.ibhv === true) {
      tempHTML = tempHTML.replace(/{BN_HVR_CLS}/gi, 'Icon_hover_text');
    }

    //for feed panel div
    if (frm === 'listCard') {
      tempHTML = tempHTML.replace(/NFDGdflt26_rvfd_cta_btn_/gi, ' ');
      tempHTML = tempHTML.replace(/NFDGdflt26_rvfd_pnldv_mdl_/gi, ' ');
    }

    tempHTML = tempHTML.replace(/{RV_PNL_BG_CLR}/gi, _cnf?.rvpbcl);
    tempHTML = tempHTML.replace(/{RV_PNL_CARD_BG_CLR}/gi, _cnf?.rvpcrbcl);
    tempHTML = tempHTML.replace(/{RV_BUTTON}/gi, _cnf?.fl);
    tempHTML = tempHTML.replace(/{RV_FIRSTLN}/gi, _cnf?.rvfl);
    tempHTML = tempHTML.replace(/{RV_TIME}/gi, _cnf?.rvtm);
    tempHTML = tempHTML.replace(/{RV_TEXT}/gi, _cnf?.rvtxt);
    tempHTML = tempHTML.replace(/{RV_AVTAR_IMG}/gi, 'src="' + iu + '"');
    tempHTML = tempHTML.replace(/{RV_AVTAR_IMG_H1}/gi, iu);
    tempHTML = tempHTML.replace(/{RV_AVTAR_IMG_H2}/gi, iu);
    tempHTML = tempHTML.replace(/{RV_AVTAR_IMG_H3}/gi, iu);
    tempHTML = tempHTML.replace(/{RV_RCMNDED_TXT}/gi, _cnf?.rvrcmndt);
    tempHTML = tempHTML.replace(/{RV_IS_AVTAR_HEADER}/gi, _cnf?.rviavh ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{RV_IS_AVTAR_IMG}/gi, _cnf?.iimg ? 'block' : 'none');
    tempHTML = tempHTML.replace(/{RV_IS_DISPLAY_AVERAGE}/gi, _cnf?.rviav ? 'block' : 'none');
    tempHTML = tempHTML.replace(/{RV_IS_TAB_DSPLY}/gi, _cnf?.rvitb ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{IS_DSPL_STR_TTL}/gi, _cnf?.rvpisrtl ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{RV_PNL_TB_BG}/gi, _cnf?.rvptbg);
    tempHTML = tempHTML.replace(/{RV_TTL_RTNG_BGCLR}/gi, _cnf?.rvrbcttl);
    tempHTML = tempHTML.replace(/{RV_TTL_RTNG_FNTCLR}/gi, _cnf?.rvrfcttl);
    tempHTML = tempHTML.replace(/{RV_PNL_LD_MORE}/gi, _cnf?.rvpldmr);

    //for time ago
    if (notiByIdData.ntt === 'recent_view_bulk' || notiByIdData.ntt === 'signup_bulk' ||
      notiByIdData.ntt === 'purchase_bulk') {
      if (notiByIdData && notiByIdData?.tr && notiByIdData?.tu && _cnf?.hplrl && _cnf?.dplrl) {
        tempHTML = tempHTML.replace(/{{TIME_AGO}}/gi, timeSince(notiByIdData.tu, notiByIdData.tr, _cnf));
      }
    }
    else if (notiByIdData.ntt === 'single_visits') {
      tempHTML = tempHTML.replace(/{{TIME_AGO}}/gi, (_cnf?.mplrl || '{x} months ago').replace(/{x}/gi, '2').replace(/{X}/gi, '2'));
    }
    else {
      tempHTML = tempHTML.replace(/{{TIME_AGO}}/gi, (_cnf?.dplrl || '{x} days ago').replace(/{x}/gi, '2').replace(/{X}/gi, '2'));
    }

    // for review feed panel tabs
    if (frm === 'preview' && _cnf?.rvttl) {
      activeTab = '';
      let dHTML = _cnf?.rvttl;
      let _arrTab = ['Google', 'Facebook'];
      let reviewPanelTitleHTML = '';

      //for get selected tab data     
      if (_cnf?.rviall != false) {
        activeTab = 'All';
        dHTML = dHTML.replace(/{{SOCIAL_ICON}}/gi, '');
        dHTML = dHTML.replace(/{{SOCIAL_NAME}}/gi, (_cnf?.rvall || 'All'));
        reviewPanelTitleHTML = '<li class="notif_rvfd_tbcls active" data-id=' + _notifID + ' data-name="All">' + dHTML + '</li>';
      }
      for (let i = 0; i < _arrTab.length; i++) {
        let tabClass = '';
        if (activeTab === '') {
          activeTab = 'Google';
          tabClass = 'active';
        }
        const name = _arrTab[i];
        dHTML = _cnf?.rvttl;
        let HTMLSocialIcon = '';
        if (name === 'Google') HTMLSocialIcon = '<img width="15" height="15" class="noti_rvfd_scl_icn" src=' + NotificationConstant.REVIEW_APP_IMG_GGL + '>';
        if (name === 'Facebook') HTMLSocialIcon = '<img width="15" height="15" class="noti_rvfd_scl_icn" src=' + NotificationConstant.REVIEW_APP_IMG_FB + '>';
        dHTML = dHTML.replace(/{{SOCIAL_ICON}}/gi, HTMLSocialIcon);
        dHTML = dHTML.replace(/{{SOCIAL_NAME}}/gi, name);
        reviewPanelTitleHTML += '<li class="notif_rvfd_tbcls ' + tabClass + '" data-id=' + _notifID + ' data-name=' + name + '>' + dHTML + '<div class="header-rating-value" style="display:{IS_DSPL_STR_TAB}; background:{RV_TAB_RTNG_BGCLR}; color:{RV_TAB_RTNG_FNTCLR};">{{AVERAGE}}<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15px" height="15px"version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 406.125 406.125"style="enable-background:new 0 0 406.125 406.125;" xml:space="preserve"><g><path style="fill:{RV_TAB_RTNG_FNTCLR};"d="M260.133,155.967c-4.487,0-9.25-3.463-10.64-7.73L205.574,13.075c-1.39-4.268-3.633-4.268-5.023,0   L156.64,148.237c-1.39,4.268-6.153,7.73-10.64,7.73H3.88c-4.487,0-5.186,2.138-1.553,4.78l114.971,83.521   c3.633,2.642,5.454,8.242,4.064,12.51L77.452,391.932c-1.39,4.268,0.431,5.592,4.064,2.951l114.971-83.521   c3.633-2.642,9.519-2.642,13.152,0l114.971,83.529c3.633,2.642,5.454,1.317,4.064-2.951l-43.911-135.154   c-1.39-4.268,0.431-9.868,4.064-12.51l114.971-83.521c3.633-2.642,2.934-4.78-1.553-4.78H260.133V155.967z"></path></g></svg></div></li>';
      }
      reviewPanelTitleHTML = reviewPanelTitleHTML.replace(/{IS_DSPL_ALL}/gi, _cnf?.rviall ? 'flex' : 'none');
      reviewPanelTitleHTML = reviewPanelTitleHTML.replace(/{IS_DSPL_STR_TAB}/gi, _cnf?.rvpisrtb ? 'flex' : 'none');
      reviewPanelTitleHTML = reviewPanelTitleHTML.replace(/{RV_TAB_RTNG_BGCLR}/gi, _cnf?.rvrbctb);
      reviewPanelTitleHTML = reviewPanelTitleHTML.replace(/{RV_TAB_RTNG_FNTCLR}/gi, _cnf?.rvrfctb);

      tempHTML = tempHTML.replace(/{RV_TAB_TEXT}/gi, reviewPanelTitleHTML);
    }

    //for youtube channel/video
    tempHTML = tempHTML.replace(/{IS_YTB_SUB_CNT}/gi, _cnf?.iytbsbc ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{IS_YTB_VIEW_CNT}/gi, _cnf?.iytbvc ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{IS_YTB_LIKE_CNT}/gi, _cnf?.iytblc ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{IS_YTB_FAV_CNT}/gi, _cnf?.iytbfvc ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{IS_YTB_CMNT_CNT}/gi, _cnf?.iytbctc ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{IS_YTB_VIDEO_CNT}/gi, _cnf?.iytbvdc ? 'flex' : 'none');

    tempHTML = tempHTML.replace(/{YTB_TTL_CLR}/gi, _cnf?.ytbttclr);
    tempHTML = tempHTML.replace(/{YTB_TTL_FNT_FML}/gi, _cnf?.ytbttffl);
    tempHTML = tempHTML.replace(/{YTB_TTL_FNT_WGT}/gi, _cnf?.ytbttfwt);

    tempHTML = tempHTML.replace(/{YTB_CNT_CLR}/gi, _cnf?.ytbcntclr);
    tempHTML = tempHTML.replace(/{YTB_CNT_FNT_FML}/gi, _cnf?.ytbcntffl);
    tempHTML = tempHTML.replace(/{YTB_CNT_FNT_WGT}/gi, _cnf?.ytbcntfwt);

    //for feed panel 
    tempHTML = tempHTML.replace(/{{SOCIAL_ICON}}/gi, '<img src=' + NotificationConstant.SOCIAL_ICON + '>');
    tempHTML = tempHTML.replace(/{{SOCIAL_NAME}}/gi, NotificationConstant.SOCIAL_NAME);
    tempHTML = tempHTML.replace(/{{STAR}}/gi, NotificationConstant.STAR);
    tempHTML = tempHTML.replace(/{{AVERAGE}}/gi, NotificationConstant.AVERAGE);
    tempHTML = tempHTML.replace(/{{TOTAL_COUNT}}/gi, NotificationConstant.TOTAL_COUNT);
    tempHTML = tempHTML.replace(/{RV_FD_MN_TITLE_TXT}/gi, NotificationConstant.SOCIAL_NAME);
    tempHTML = tempHTML.replace(/{RV_FD_MN_TITLE_AVG}/gi, NotificationConstant.AVERAGE);
    tempHTML = tempHTML.replace(/{{REVIEW_FEED_STAR}}/gi, NotificationConstant.RATING_HTML);
    tempHTML = tempHTML.replace(/{{REVIEW_TEXT}}/gi, NotificationConstant.REVIEW_TEXT + '  ' + (_cnf.rdmr ? _cnf.rdmr : ''));
    tempHTML = tempHTML.replace(/{{USER_RATING}}/gi, NotificationConstant.USER_RATING);
    tempHTML = tempHTML.replace(/{{TOTAL_RATING}}/gi, NotificationConstant.TOTAL_RATING);
    tempHTML = tempHTML.replace(/{{AVG_RATING}}/gi, NotificationConstant.AVG_RATING);
    tempHTML = tempHTML.replace(/{{REVIEW_APP}}/gi, NotificationConstant.REVIEW_APP);
    tempHTML = tempHTML.replace(/{{STOCK_COUNT}}/gi, NotificationConstant.STOCK_COUNT);
    tempHTML = tempHTML.replace(/{{RATING_HTML}}/gi, NotificationConstant.RATING_HTML);
    tempHTML = tempHTML.replace(/{RATING_HTML}/gi, _cnf?.irmvrtng ? '' : NotificationConstant.RATING_HTML);
    tempHTML = tempHTML.replace(/{IS_TOTAL_RATING}/gi, _cnf?.irmvrtng ? 'none' : 'flex');
    tempHTML = tempHTML.replace(/{STR_RTNG_CLR}/gi, _cnf?.strrtngclr ? _cnf?.strrtngclr : '#E7711B');
    tempHTML = tempHTML.replace(/{{FB_ICON}}/gi, '<img src=' + NotificationConstant.REVIEW_APP_IMG_FB + '>');
    tempHTML = tempHTML.replace(/{{GGL_ICON}}/gi, '<img src=' + NotificationConstant.REVIEW_APP_IMG_GGL + '>');

    //for lottery widget
    tempHTML = tempHTML.replace(/{LTRY_BDG_ICN}/gi, _cnf?.lcyctryicn);
    tempHTML = tempHTML.replace(/{LTRY_BDG_BGCLR}/gi, _cnf?.lcyctrybgclr);

    // for lottery
    if (frm === 'preview') {

      setTimeout(() => {
        //show lottery widget coupon code message block onclick of 'texteditor'
        let cpnBox = document.querySelectorAll('#NFDGdflt26_Sb42SInFL_FrmDV_' + state.notifId);
        if (state.queryNtt === 'lottery' && state.verticalActive === 'texteditor' && cpnBox?.length > 0) {
          cpnBox.forEach(element => element.classList.add('NFDGdflt26_fhr_shw'));
        }
        else if (cpnBox && cpnBox[0] && cpnBox[0].classList[cpnBox[0].classList.length - 1] === 'NFDGdflt26_fhr_shw') {
          cpnBox[0].classList.remove('NFDGdflt26_fhr_shw');
        }

        //show lottery widget coupon code block (cta block) onclck of 'clickActions'
        let tnkDiv = document.querySelectorAll('#NFDGdflt26_Sb42SInFL_Thnk_' + state.notifId);
        if (state.queryNtt === 'lottery' && state.verticalActive === 'clickActions' && tnkDiv?.length > 0) {
          tnkDiv.forEach(element => element.classList.add('NFDGdflt26_fhr_shw'));
        }
        else if (state.verticalActive !== 'clickActions' && tnkDiv && tnkDiv[0] && tnkDiv[0].classList[tnkDiv[0].classList.length - 1] === 'NFDGdflt26_fhr_shw') {
          tnkDiv[0].classList.remove('NFDGdflt26_fhr_shw');
        }

        // add click event on lottery scratch
        let lotryDiv = document.querySelectorAll('#NFDGdflt26_ltry_mn_dv');
        if (lotryDiv.length > 0) {
          lotryDiv.forEach(element => {
            element.addEventListener('click', function (e) {
              replaceLotteryWidgetCode(e, _cnf, _notifID);
            });
          });
        }
      }, 500);

      // for open lottery widget modal 
      setTimeout(() => {
        let rvfdMdl = document.querySelectorAll('#NFDGdflt26_rvfd_pnldv_mdl_' + _notifID);
        if (rvfdMdl?.length > 0 && _cnf && notiByIdData?.ntt === 'lottery') {
          rvfdMdl.forEach(div => {
            if (!_cnf?.iimg) {
              div.classList.add('modal-rvwfeed-show');

              let liveBlock = document.querySelectorAll('#live_preview_block');
              if (liveBlock?.length > 0) {
                liveBlock.forEach(block => block.classList.add('NFDGdflt26_Sb425txt_modal_center'));
              }
            }
          });
        }
      }, 10);
    }

    if (notiByIdData.ntt === 'review_bulk') {
      tempHTML = tempHTML.replace(/{{PAGE_NAME}}/gi, NotificationConstant.YTB_CNL_TITLE);
    }
    tempHTML = tempHTML.replace(/{{PAGE_RTNG_AVG}}/gi, NotificationConstant.AVG_RATING);
    tempHTML = tempHTML.replace(/{{PAGE_DSCPTN}}/gi, NotificationConstant.PAGE_DSCPTN);
    tempHTML = tempHTML.replace(/{{PAGE_CTGR}}/gi, NotificationConstant.PAGE_CTGR);

    //for youtube channel/video          
    if (notiByIdData.ntt === 'channel_subscriber') {
      tempHTML = tempHTML.replace(/{{PAGE_NAME}}/gi, NotificationConstant.YTB_CNL_TITLE);
      tempHTML = tempHTML.replace(/{{PAGE_SUB_CNT}}/gi, NotificationConstant.SUB_CNT);
      tempHTML = tempHTML.replace(/{{PAGE_VIEW_CNT}}/gi, NotificationConstant.YTB_CNL_VIEW_CNT);
      tempHTML = tempHTML.replace(/{{PAGE_CMNT_CNT}}/gi, NotificationConstant.YTB_CNL_CMNT_CNT);
      tempHTML = tempHTML.replace(/{{PAGE_VIDEO_CNT}}/gi, NotificationConstant.YTB_VID_CNT);
    } else {
      tempHTML = tempHTML.replace(/{{PAGE_NAME}}/gi, NotificationConstant.YTB_VID_TITLE);
      tempHTML = tempHTML.replace(/{{PAGE_VIEW_CNT}}/gi, NotificationConstant.YTB_VID_VIEW_CNT);
      tempHTML = tempHTML.replace(/{{PAGE_LIKE_CNT}}/gi, NotificationConstant.YTB_VID_LIKE_CNT);
      tempHTML = tempHTML.replace(/{{PAGE_CMNT_CNT}}/gi, NotificationConstant.YTB_VID_CMNT_CNT);
      tempHTML = tempHTML.replace(/{{PAGE_FAV_CNT}}/gi, NotificationConstant.YTB_VID_FAV_CNT);
    }

    //for custom notification timer
    tempHTML = tempHTML.replace(/{TMR_TXT}/gi, _cnf?.tmtxt);
    tempHTML = tempHTML.replace(/{{COUNTER}}/gi, _cnf?.hdwn);
    tempHTML = tempHTML.replace(/{IS_TMR_DSPLY}/gi, (_cnf?.itm && (_cnf?.itmntf || (_cnf?.ihdwn && !notiByIdData.icstlp))) ? 'flex' : 'none');
    tempHTML = tempHTML.replace(/{TMR_BG_CLR}/gi, _cnf?.tmtxtbg);
    tempHTML = tempHTML.replace(/{TMR_TXT_CLR}/gi, _cnf?.tmtxtclr);
    tempHTML = tempHTML.replace(/{TMR_CNT_DWN_BG_CLR}/gi, _cnf?.tmcntbg);
    tempHTML = tempHTML.replace(/{CPY_BTN_COLOR_CLS}/gi, _cnf?.ctcacn === 5 ? 'dsply_coPy_cta' : '');
    tempHTML = tempHTML.replace(/{IS_HD_DD_TMR}/gi, _cnf?.itmrhddd ? 'none' : 'flex');
    tempHTML = tempHTML.replace(/{IS_HD_HH_TMR}/gi, _cnf?.itmrhdhh ? 'none' : 'flex');
    tempHTML = tempHTML.replace(/{IS_HD_MM_TMR}/gi, _cnf?.itmrhdmm ? 'none' : 'flex');
    tempHTML = tempHTML.replace(/{IS_HD_SS_TMR}/gi, _cnf?.itmrhdss ? 'none' : 'flex');
    tempHTML = tempHTML.replace(/{TMR_FNT_SZ}/gi, _cnf?.tmrfntsz + 'px');
    tempHTML = tempHTML.replace(/{CP_CD_CTA_TXT}/gi, _cnf?.cpcdcttxt || 'COPY CODE');

    //hide play icon in youtube notification
    if ((notiByIdData.ntt === 'channel_subscriber' || notiByIdData.ntt === 'video_subscriber')) {
      if (_cnf?.it && _cnf?.it[0] === 0) tempHTML = tempHTML.replace('utube-icon', 'utube-icon utube-icon-dnone');
      else tempHTML = tempHTML.replace('utube-icon', 'utube-icon');
    }

    //add padding class when image hide
    if (_cnf?.iimg === false) {
      tempHTML = tempHTML.replace('NoTi_Box_Right', 'NoTi_Box_Right NotiBox_Right_Pad20');
      tempHTML = tempHTML.replace('TypeNoTi_Single_Right', 'TypeNoTi_Single_Right NotiBox_PadX15');
      tempHTML = tempHTML.replace('TypeNoTi-triangle-btm', 'TypeNoTi-triangle-btm review-user-hdn');
      tempHTML = tempHTML.replace('TypeNoTi_Tem61', 'TypeNoTi_Tem61 TypeNoTi_Tem61_imgnone');
    }
    else if (_cnf?.iimg) {
      tempHTML = tempHTML.replace('NoTi_Box_Right', 'NoTi_Box_Right');
      tempHTML = tempHTML.replace('TypeNoTi_Single_Right', 'TypeNoTi_Single_Right');
      tempHTML = tempHTML.replace('TypeNoTi-triangle-btm', 'TypeNoTi-triangle-btm');
      tempHTML = tempHTML.replace('TypeNoTi_Tem61', 'TypeNoTi_Tem61');
    }

    //for dynamic heights
    if (notifHeight && notiByIdData.ntt !== 'review_feed') {

      let size = 'eighty';
      if (frm === 'preview' && isMobileScreen === false) {
        size = dctp;
      }
      else size = dctp;

      let ht = '';
      if (size && notifHeight && notifHeight?.dcss && notifHeight?.dcss?.[size] !== undefined) {
        ht = notifHeight?.dcss?.[size];
      } else {
        ht = '';
      }

      if (ht != undefined && ht != '') {
        let rw = _cnf?.rw ? _cnf?.rw : 0;
        tempHTML = tempHTML.replace(/{WIDTH}/gi, ht.WIDTH + 'px');
        tempHTML = tempHTML.replace(/{HEIGHT}/gi, (ht.HEIGHT + (rw * 2)) + 'px');
        tempHTML = tempHTML.replace(/{MX_HGHT_7}/gi, (ht.MX_HGHT_7 + (rw * 2)) + 'px');
        tempHTML = tempHTML.replace(/{MX_WDTH_1}/gi, (ht.MX_WDTH_1) + 'px');
        tempHTML = tempHTML.replace(/{MX_HGHT_1}/gi, (ht.MX_HGHT_1) + 'px');
        tempHTML = tempHTML.replace(/{MX_WDTH_2}/gi, (ht.MX_WDTH_2) + 'px');
        tempHTML = tempHTML.replace(/{MX_HGHT_2}/gi, (ht.MX_HGHT_2) + 'px');
        tempHTML = tempHTML.replace(/{MX_WDTH_3}/gi, ht.MX_WDTH_3 + 'px');
        tempHTML = tempHTML.replace(/{MX_HGHT_3}/gi, ht.MX_HGHT_3 + 'px');
        tempHTML = tempHTML.replace(/{MX_WDTH_4}/gi, ht.MX_WDTH_4 + 'px');
        tempHTML = tempHTML.replace(/{MX_HGHT_4}/gi, ht.MX_HGHT_4 + 'px');
        tempHTML = tempHTML.replace(/{MX_WDTH_5}/gi, ht.MX_WDTH_5 + 'px');
        tempHTML = tempHTML.replace(/{MX_HGHT_5}/gi, ht.MX_HGHT_5 + 'px');
        tempHTML = tempHTML.replace(/{IMG_1_W}/gi, (ht.IMG_1_W) + 'px');
        tempHTML = tempHTML.replace(/{IMG_1_H}/gi, (ht.IMG_1_H) + 'px');
        tempHTML = tempHTML.replace(/{IMG_2_W}/gi, (ht.IMG_2_W) + 'px');
        tempHTML = tempHTML.replace(/{IMG_2_H}/gi, (ht.IMG_2_H) + 'px');
        tempHTML = tempHTML.replace(/{IMG_3_W}/gi, ht.IMG_3_W + 'px');
        tempHTML = tempHTML.replace(/{IMG_3_H}/gi, ht.IMG_3_H + 'px');
        tempHTML = tempHTML.replace(/{IMG_4_W}/gi, ht.IMG_4_W + 'px');
        tempHTML = tempHTML.replace(/{IMG_4_H}/gi, ht.IMG_4_H + 'px');
        tempHTML = tempHTML.replace(/{IMG_5_W}/gi, ht.IMG_5_W + 'px');
        tempHTML = tempHTML.replace(/{IMG_5_H}/gi, ht.IMG_5_H + 'px');
        tempHTML = tempHTML.replace(/{GRID_TMP_CL}/gi, ht.GRID_TMP_CL);
        tempHTML = tempHTML.replace(/{FLX}/gi, ht.FLX);
        tempHTML = tempHTML.replace(/{CRCL_ICN_W_1}/gi, ht.CRCL_ICN_W_1 + 'px');
        tempHTML = tempHTML.replace(/{CRCL_ICN_H_1}/gi, ht.CRCL_ICN_H_1 + 'px');
        tempHTML = tempHTML.replace(/{CRCL_ICN_W_2}/gi, ht.CRCL_ICN_W_2 + 'px');
        tempHTML = tempHTML.replace(/{CRCL_ICN_H_2}/gi, ht.CRCL_ICN_H_2 + 'px');
        tempHTML = tempHTML.replace(/{CRCL_ICN_H}/gi, ht.CRCL_ICN_H + 'px');
        tempHTML = tempHTML.replace(/{SNG_LN_NTF_H}/gi, ht.SNG_LN_NTF_H + 'px');
        tempHTML = tempHTML.replace(/{CNT_FN_SZ_1}/gi, ht.CNT_FN_SZ_1 + 'px');
        tempHTML = tempHTML.replace(/{CNT_FN_SZ_2}/gi, ht.CNT_FN_SZ_2 + 'px');
        tempHTML = tempHTML.replace(/{FL_FN_SZ}/gi, ht.FL_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{SL_FN_SZ}/gi, ht.SL_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{TRD_FN_SZ}/gi, ht.TRD_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{FRTH_FN_SZ}/gi, ht.FRTH_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{SXTH_FN_SZ}/gi, ht.SXTH_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{FL_MGN_BT}/gi, ht.FL_MGN_BT + 'px');
        tempHTML = tempHTML.replace(/{SL_MGN_BT}/gi, ht.SL_MGN_BT + 'px');
        tempHTML = tempHTML.replace(/{CTA_FN_SZ}/gi, ht.CTA_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{CTA_MARGIN}/gi, ht.CTA_MARGIN + 'px');
        tempHTML = tempHTML.replace(/{CTA_ARRW_PD_RT}/gi, ht.CTA_ARRW_PD_RT + 'px');
        tempHTML = tempHTML.replace(/{CTA_ARRW_W}/gi, ht.CTA_ARRW_W + 'px');
        tempHTML = tempHTML.replace(/{CTA_ARRW_H}/gi, ht.CTA_ARRW_H + 'px');
        tempHTML = tempHTML.replace(/{BN_FN_SZ}/gi, ht.BN_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{BN_ICN_W}/gi, ht.BN_ICN_W + 'px');
        tempHTML = tempHTML.replace(/{BN_ICN_H}/gi, ht.BN_ICN_H + 'px');
        tempHTML = tempHTML.replace(/{TM_FN_SZ}/gi, ht.TM_FN_SZ + 'px');
        tempHTML = tempHTML.replace(/{CLS_ICN_W}/gi, ht.CLS_ICN_W + 'px');
        tempHTML = tempHTML.replace(/{CLS_ICN_H}/gi, ht.CLS_ICN_H + 'px');
        tempHTML = tempHTML.replace(/{MGN_LT_2}/gi, ht.MGN_LT_2 + 'px');
        tempHTML = tempHTML.replace(/{MGN_TP_1}/gi, ht.MGN_TP_1 + 'px');
        tempHTML = tempHTML.replace(/{MGN_TP_2}/gi, ht.MGN_TP_2 + 'px');
        tempHTML = tempHTML.replace(/{MGN_TP_3}/gi, ht.MGN_TP_3 + 'px');
        tempHTML = tempHTML.replace(/{MGN_RT_2}/gi, ht.MGN_RT_2 + 'px');
        tempHTML = tempHTML.replace(/{MGN_BT_1}/gi, ht.MGN_BT_1 + 'px');
        tempHTML = tempHTML.replace(/{MGN_BT_2}/gi, ht.MGN_BT_2 + 'px');
        tempHTML = tempHTML.replace(/{MGN_BT_3}/gi, ht.MGN_BT_3 + 'px');
        tempHTML = tempHTML.replace(/{MGN_BT_4}/gi, ht.MGN_BT_4 + 'px');
        tempHTML = tempHTML.replace(/{SVG_HGHT_1}/gi, ht.SVG_HGHT_1 + 'px');
        tempHTML = tempHTML.replace(/{SVG_WDTH_1}/gi, ht.SVG_WDTH_1 + 'px');
        tempHTML = tempHTML.replace(/{SVG_PD_1}/gi, ht.SVG_PD_1 + 'px');
        tempHTML = tempHTML.replace(/{SVG_PD_2}/gi, ht.SVG_PD_2 + 'px');
        tempHTML = tempHTML.replace(/{STR_RTNG_WDTH_1}/gi, ht.STR_RTNG_WDTH_1 + 'px');
        tempHTML = tempHTML.replace(/{STR_RTNG_HGHT_1}/gi, ht.STR_RTNG_HGHT_1 + 'px');
      }
      else {
        tempHTML = tempHTML.replace(/{STR_RTNG_WDTH_1}/gi, 10);
        tempHTML = tempHTML.replace(/{STR_RTNG_HGHT_1}/gi, 10);
      }
    } else {
      tempHTML = tempHTML.replace(/{STR_RTNG_WDTH_1}/gi, 15);
      tempHTML = tempHTML.replace(/{STR_RTNG_HGHT_1}/gi, 15);
    }

    //whats app chat pop up
    tempHTML = tempHTML.replace(/{AINCT_ICN}/gi, '');
    tempHTML = tempHTML.replace(/{AINCT_URL}/gi, '');

    let _lbl = '';
    if (isMobileScreen) _lbl = '_mobi';

    setTimeout(() => {
      if (frm === 'preview') {
        if (document.getElementById('Animation_style' + _lbl)) {
          document.getElementById('Animation_style' + _lbl).className = '';
          //for desktop and mobile screen animation
          let animation = _cnf?.as;
          let position = _cnf?.dp;
          let compact;
          if (isMobileScreen) {
            animation = _cnf?.mas;
            position = _cnf?.mdp;
            compact = _cnf?.icmptdsgn;
          }
          // for compact design view for mobile
          if (notiByIdData && idisnp === true && notiByIdData.isnpifdanmt === true) {
            compactMobi = 'NFDGdflt26';
          }
          else if (compact) {
            compactMobi = 'NFDGdflt26_Sb425txt_cmpt_dsgn';
          }
          else {
            compactMobi = 'NFDGdflt26';
          }
          //for notification effect
          if (notiByIdData && idisnp === true && notiByIdData.isnpifdanmt === true) {
            animationClass = 'NFDGdflt26';
          }
          else if (animation === 'fade') animationClass = 'NFDGdflt26_Sb425txt_as_fd';
          else if (animation === 'bounce') animationClass = 'NFDGdflt26_Sb425txt_as_bnc';
          else if (animation === 'zoom') animationClass = 'NFDGdflt26_Sb425txt_as_zoom';
          else if (animation === 'zoom_in_down') animationClass = 'NFDGdflt26_Sb425txt_as_zoomindw';
          else if (animation === 'slide') animationClass = 'NFDGdflt26_Sb425txt_as_slide';
          else if (animation === 'horizonatal_slide') animationClass = 'NFDGdflt26_Sb425txt_as_hzslide';
          else if (animation === 'side_flip_in') animationClass = 'NFDGdflt26_Sb425txt_as_infpslide';
          else if (animation === 'side_flip_back') animationClass = 'NFDGdflt26_Sb425txt_as_backfpside';
          else if (animation === 'flip') animationClass = 'NFDGdflt26_Sb425txt_as_flip';
          else if (animation === 'up_flip') animationClass = 'NFDGdflt26_Sb425txt_as_upflip';
          else if (animation === 'back_flip') animationClass = 'NFDGdflt26_Sb425txt_as_backflip';
          else animationClass = 'NFDGdflt26';

          //for banner notification
          if (notiByIdData && idisnp === true) {
            if (notiByIdData.isnpifdanmt === true) {
              positionClass = 'NFDGdflt26';
            }
            else if (templateList.ttn === 'promotional_banner_timer') {
              positionClass = 'NFDGdflt26_Sb425_bnnr_cnt';
            }
            else {
              positionClass = 'NFDGdflt26_Sb425txt_snippet_cnt';
            }
          }
          else if (position === 'banner_top') positionClass = 'NFDGdflt26_Sb425_bnnr_tp';
          else if (position === 'banner_center') positionClass = 'NFDGdflt26_Sb425_bnnr_cnt';
          else if (position === 'banner_bottom') positionClass = 'NFDGdflt26_Sb425_bnnr_bt';
          //for other notification
          else if (position === 'left_top') positionClass = 'NFDGdflt26_Sb425txt_lt_tp';
          else if (position === 'center_top') positionClass = 'NFDGdflt26_Sb425txt_cnt_tp';
          else if (position === 'right_top') positionClass = 'NFDGdflt26_Sb425txt_rt_tp';
          else if (position === 'right_center') positionClass = 'NFDGdflt26_Sb425txt_rt_cnt';
          else if (position === 'center') positionClass = 'NFDGdflt26_Sb425txt_cnt_cnt';
          else if (position === 'left_center') positionClass = 'NFDGdflt26_Sb425txt_lt_cnt';
          else if (position === 'right_bottom') positionClass = 'NFDGdflt26_Sb425txt_rt_bt';
          else if (position === 'center_bottom') positionClass = 'NFDGdflt26_Sb425txt_cnt_bt';
          else if (position === 'left_bottom') positionClass = 'NFDGdflt26_Sb425txt_lt_bt';
          else positionClass = 'NFDGdflt26';

          //append more class for vertical & horizontal div: this class common for mobile & desktop
          if (_cnf?.cbdal === 'vertical') textAlign = 'NFDGdflt26_Sb425txt_vrtcl';
          else if (_cnf?.cbdal === 'horizontal') textAlign = 'NFDGdflt26_Sb425txt_hrzntl';
          else textAlign = 'null';
          let custcss_cls = `wn_${_notifID}`;

          document.getElementById('Animation_style' + _lbl).classList.add(animationClass, positionClass, compactMobi, textAlign, custcss_cls);

          //add pointer class if notif_click on
          if (_cnf?.nc && (_cnf?.nu || _cnf?.inccld) &&
            document.getElementById('Animation_style' + _lbl).querySelectorAll('#NFDGdflt26_mnDv').length) {
            document.getElementById('Animation_style' + _lbl).querySelectorAll('#NFDGdflt26_mnDv')[0].classList.add('notif_hover_pointer');
          }

          if ((_cnf?.cu) &&
            document.getElementById('Animation_style' + _lbl).querySelectorAll('#NFDGdflt26_Sb425txt_cta').length) {
            document.getElementById('Animation_style' + _lbl).querySelectorAll('#NFDGdflt26_Sb425txt_cta')[0].classList.add('notif_hover_pointer');
          }

          //for on click change tab panel
          document.getElementById('Animation_style' + _lbl).onclick = function (e) {
            if (e?.target.closest('ul') && e?.target.closest('ul').id === 'NFDGdflt26_rvfd_tbul_' + _notifID) {
              //remove active class
              let lstTabClass = document.getElementsByClassName('notif_rvfd_tbcls');
              for (let i = 0; i < lstTabClass.length; i++) {
                const oTbcls = lstTabClass[i];
                if (oTbcls.getAttribute('data-id') === _notifID) oTbcls.classList.remove('active');
              }

              //add active class for tab click
              if (e?.target.getAttribute('data-name') || (e?.target.closest('li') && e?.target.closest('li').getAttribute('data-name'))) {
                const HTMLNode = e?.target.getAttribute('data-name') ? e?.target : e?.target.closest('li');
                //add active class for selected tab
                HTMLNode.classList.add('active');
                //for get selected tab data
                activeTab = HTMLNode.getAttribute('data-name');


                //for feed panel 
                const tab_ttl = document.querySelectorAll('#NFDGdflt26_rvfd_tbttl_' + _notifID);
                if (tab_ttl?.length > 0) {
                  tab_ttl.forEach(element => {
                    element.innerHTML = replaceFeedHTML(activeTab, _cnf);
                  });
                }

                //for review first line
                const rvfd_pnl = document.querySelectorAll('#rvfd_pnl_id');
                if (_cnf?.rvfl && rvfd_pnl.length > 0) {
                  rvfd_pnl.forEach(element => {
                    element.innerHTML = replaceFeedIcon(activeTab, _cnf);
                  });
                }
              }
            }
          };
        }

        //for feed panel  
        const tab_ttl = document.querySelectorAll('#NFDGdflt26_rvfd_tbttl_' + _notifID);
        if (tab_ttl?.length > 0) {
          tab_ttl.forEach(element => {
            element.innerHTML = replaceFeedHTML(activeTab, _cnf);
          });
        }

        //for review first line
        const rvfd_pnl = document.querySelectorAll('#rvfd_pnl_id');
        if (_cnf?.rvfl && rvfd_pnl.length > 0) {
          rvfd_pnl.forEach(element => {
            element.innerHTML = replaceFeedIcon(activeTab, _cnf);
          });
        }

        //for display only icon
        const chatIcnDiv = document.querySelectorAll('#NFDGdflt2_chat_main_div_' + _notifID);
        if (chatIcnDiv?.length > 0) {
          chatIcnDiv.forEach(element => {
            if (_cnf?.icbicn === true)
              element.classList.add('SocialLinkIcon_OnlyList');
            else element.classList.remove('SocialLinkIcon_OnlyList');
          });
        }
      }

      //for all_in_one notif
      if (notiByIdData.ntt === 'all_in_one' && _cnf && _cnf?.ainct) {

        //common div for repeat  
        const temp = '<div data-href="{AINCT_URL}" id="chat_dvloop_{BANNER_ID}"' +
          'class="SocialLink_Item NoTi-d-flex Items-Center"' +
          'style="box-shadow:{SHDOW_HZ_OFF} {SHDOW_VL_OFF} {SHDOW_BLR_RDUS} {SHDOW_CLR}; background:{AINCT_BG_CLR};">' +
          '<div class="SocialLink_Svg" style="width: {AINCT_TTL_ICN_SZ};"><img class="SocialLink_chat_img" src="{AINCT_ICN}" style="height: {AINCT_TTL_ICN_SZ}; width: {AINCT_TTL_ICN_SZ};"></div>' +
          '<div class="SocialLink_Text" style="display:{IS_DSPL_ONLY_ICN}; font-size: {AINCT_TTL_FN_SZ}; font-family: {AINCT_TTL_FN_FMLY}; font-weight: {AINCT_TTL_FN_WGHT}; color: {AINCT_TTL_FN_CLR};">{AINCT_TTL}</div>' +
          '</div>';
        let chatHTML = '';

        //repeat HTML for create chat widget
        for (let i = 0; i < _cnf?.ainct.length; i++) {
          const o = _cnf?.ainct[i];
          if (o.ia) chatHTML += temp.replace(/{IS_DSPL_ONLY_ICN}/gi, _cnf?.icbicn ? 'none' : 'block')
            .replace(/{AINCT_URL}/gi, o.url)
            .replace(/{AINCT_BG_CLR}/gi, o.bgc)
            .replace(/{AINCT_ICN}/gi, o.icn)
            .replace(/{AINCT_TTL}/gi, o.ttl)
            .replace(/{AINCT_TTL_FN_SZ}/gi, o.ttlfnsz + 'px')
            .replace(/{AINCT_TTL_ICN_SZ}/gi, (o.ttlfnsz ? parseInt(o.ttlfnsz) + 6 : 14) + 'px')
            .replace(/{AINCT_TTL_FN_FMLY}/gi, o.ttlfnfml)
            .replace(/{AINCT_TTL_FN_CLR}/gi, o.ttlfnclr)
            .replace(/{AINCT_TTL_FN_WGHT}/gi, o.ttlfnwght)
            .replace(/{SHDOW_HZ_OFF}/gi, _cnf?.shdho + 'px').replace(/{SHDOW_VL_OFF}/gi, _cnf?.shdvo + 'px')
            .replace(/{SHDOW_BLR_RDUS}/gi, _cnf?.shdbr + 'px').replace(/{SHDOW_CLR}/gi, _cnf?.shdclr);
        }

        //append chat HTML in main div
        setTimeout(() => {
          const chatDiv = document.querySelectorAll('#NFDGdflt2_chat_main_div_' + notiByIdData._id);
          if (chatDiv?.length > 0) {
            chatDiv.forEach(element => element.innerHTML = chatHTML);
          }

          const winDowShowArr = document.querySelectorAll('#NFDGdflt26_Sb42bIG_ICn_' + notiByIdData._id);
          if (winDowShowArr?.length > 0) {
            winDowShowArr.forEach(winDowShow => {
              if (winDowShow) {
                if (frm === 'preview') {
                  if (!_cnf.iimg) {
                    winDowShow.classList.remove('NFDGdflt26_Sb_oPN_LgPoUP');
                    winDowShow.classList.add('NFDGdflt26_DefaultOpen_LgPoUP');
                  }
                  else winDowShow.classList.remove('NFDGdflt26_DefaultOpen_LgPoUP');
                }
                else winDowShow.classList.add('NFDGdflt26_Sb_oPN_LgPoUP');
              }
            });
          }
        }, 10);
      }

      //for social media followers
      if (notiByIdData.ntt === 'social_media_followers' && _cnf && _cnf?.ainct) {
        //common div for repeat 
        let cmnDiv = '<div class="Social-Media-Item NoTi-d-flex" id="chat_dvloop_{BANNER_ID}" data-id="{BANNER_ID}" style="background:{AINCT_BG_CLR};">' +
          '<div class="Social-Media-Left" style="display:{AINCT_FLWR_ICN_DSPLY}"> <img class="Social-Media-Img" src="{AINCT_ICN}" /></div><div class="Social-Media-Right">' +
          '<div class="Social-Media-Count" style="font-size: {AINCT_TTL_FN_SZ}; font-family: {AINCT_TTL_FN_FMLY}; font-weight: {AINCT_TTL_FN_WGHT}; color: {AINCT_TTL_FN_CLR};">{AINCT_FLWR_CNT}</div><div class="Social-Media-Tag" style="display:{AINCT_FLWR_TTL_DSPLY}; font-family:{AINCT_TTL_FN_FMLY}; color:{AINCT_TTL_FN_CLR};">{AINCT_FLWR_CNT_TYP}</div></div></div>';

        let fwlHTML = '';
        //repeat HTML for create chat widget
        for (let i = 0; i < _cnf?.ainct.length; i++) {
          const o = _cnf?.ainct[i];
          if (o.ia) fwlHTML += cmnDiv.replace(/{AINCT_FLWR_CNT_TYP}/gi, o.cnttyp)
            .replace(/{AINCT_FLWR_CNT}/gi, NotificationConstant.AINCT_FLWR_CNT)
            .replace(/{AINCT_FLWR_ICN_DSPLY}/gi, o.ismflicn ? 'flex' : 'none')
            .replace(/{AINCT_FLWR_TTL_DSPLY}/gi, o.ismflttn ? 'flex' : 'none')
            .replace(/{AINCT_BG_CLR}/gi, o.bgc)
            .replace(/{AINCT_ICN}/gi, o.icn)
            .replace(/{AINCT_TTL_FN_SZ}/gi, o.ttlfnsz + 'px')
            .replace(/{AINCT_TTL_FN_FMLY}/gi, o.ttlfnfml)
            .replace(/{AINCT_TTL_FN_CLR}/gi, o.ttlfnclr)
            .replace(/{AINCT_TTL_FN_WGHT}/gi, o.ttlfnwght)
            .replace(/{SHDOW_HZ_OFF}/gi, _cnf?.shdho + 'px').replace(/{SHDOW_VL_OFF}/gi, _cnf?.shdvo + 'px')
            .replace(/{SHDOW_BLR_RDUS}/gi, _cnf?.shdbr + 'px').replace(/{SHDOW_CLR}/gi, _cnf?.shdclr);
        }
        //append chat HTML in main div
        setTimeout(() => {
          const flwerDiv = document.querySelectorAll('#NFDGdflt2_social_flwer_main_div_' + notiByIdData._id);
          if (flwerDiv?.length > 0) flwerDiv.forEach(element => element.innerHTML = fwlHTML)
        }, 10);
      }

      //for Whatsapp Chat
      if (notiByIdData.ntt === 'whatsapp_chat' && _cnf && _cnf?.ainct) {
        //common div for repeat
        let chatDiv = '<div class="whatsapp-popup-content-item"><div class="whatsapp-content-item-block" style="background:{AINCT_BG_CLR};">'
          + '<div class="whatsapp-popup-avatar"> <img src="{AINCT_ICN}" /></div><div class="whatsapp-popup-txt" style=" font-size: {AINCT_TTL_FN_SZ}; font-family: {AINCT_TTL_FN_FMLY}; font-weight: {AINCT_TTL_FN_WGHT}; color: {AINCT_TTL_FN_CLR};">'
          + '<div class="whatsapp-popup-name">{AINCT_TTL}</div><div class="whatsapp-popup-duty">{AINCT_SHRT_DSC}</div></div></div></div>';

        let chatHTML = '';
        //repeat HTML for create chat widget
        for (let i = 0; i < _cnf?.ainct.length; i++) {
          const o = _cnf?.ainct[i];
          if (o.ia) chatHTML += chatDiv.replace(/{AINCT_TTL}/gi, o.ttl)
            .replace(/{AINCT_SHRT_DSC}/gi, o.sdsc)
            .replace(/{AINCT_BG_CLR}/gi, o.bgc)
            .replace(/{AINCT_ICN}/gi, o.icn);
        }
        //append chat HTML in main div 
        const wtsap_div = document.querySelectorAll('#NFDGdflt2_wtsap_main_div_' + _notifID);
        if (wtsap_div?.length > 0) {
          wtsap_div.forEach(element => element.innerHTML = chatHTML);
        }
      }

      //for offer
      if (notiByIdData.ntt === 'offer' && _cnf && _cnf?.ainct) {
        //common div for repeat
        let chatDiv = '<div class="offer-popup-content-item"><div class="offer-popup-line" style="background:{CTA_BG_COLOR};"></div><div class="offer-content-item-block" id="offr_dvloop_{BANNER_ID}" style="background:{AINCT_BG_CLR};">' + '<div class="offer-popup-txt" id="offr_dvloop_{BANNER_ID}" style=" font-size: {AINCT_TTL_FN_SZ}; font-family: {AINCT_TTL_FN_FMLY}; font-weight: {AINCT_TTL_FN_WGHT}; color: {AINCT_TTL_FN_CLR};">' + '<div class="offer-popup-name" id="offr_dvloop_{BANNER_ID}">{AINCT_TTL}</div><div class="offer-popup-duty">{AINCT_SHRT_DSC}</div></div></div></div>';

        let chatHTML = '';
        //repeat HTML for create chat widget
        for (let i = 0; i < _cnf?.ainct.length; i++) {
          const o = _cnf?.ainct[i];
          if (o.ia) chatHTML += chatDiv.replace(/{AINCT_TTL}/gi, o.ttl)
            .replace(/{AINCT_SHRT_DSC}/gi, o.sdsc)
            .replace(/{AINCT_BG_CLR}/gi, o.bgc)
            .replace(/{BANNER_ID}/gi, _notifID)
            .replace(/{CTA_BG_COLOR}/gi, _cnf?.clbg);
        }
        //append chat HTML in main div 
        const offerDiv = document.querySelectorAll('#NFDGdflt2_offr_main_div_' + _notifID);
        if (offerDiv && offerDiv.length > 0) {
          offerDiv.forEach(element => {
            element.innerHTML = chatHTML;
          });
        }
      }
    }, 10);

    // dynamic value list
    tempHTML = tempHTML.replace(/{{USERNAME}}/gi, _un);
    tempHTML = tempHTML.replace(/{{F_NAME}}/gi, _fn);
    tempHTML = tempHTML.replace(/{{L_NAME}}/gi, _ln);
    tempHTML = tempHTML.replace(/{{CITY}}/gi, _ct);
    tempHTML = tempHTML.replace(/{{STATE}}/gi, _st);
    tempHTML = tempHTML.replace(/{{COUNTRY}}/gi, _cn);
    tempHTML = tempHTML.replace(/{{COUNTRY_FLAG}}/gi, '<img class="NoTi_country_flag_img" src="https://storage.googleapis.com/wiserimg/template_icon/flag_icon.svg?q=123"></img>');
    tempHTML = tempHTML.replace(/{{CUSTOM_1}}/gi, 'Custom_One');
    tempHTML = tempHTML.replace(/{{CUSTOM_2}}/gi, 'Custom_Two');
    tempHTML = tempHTML.replace(/{{CUSTOM_3}}/gi, 'Custom_Three');
    tempHTML = tempHTML.replace(/{{COUPON_CODE}}/gi, NotificationConstant.COUPON_CODE);
    tempHTML = tempHTML.replace(/{{COUNT}}/gi, NotificationConstant.COUNT);
    tempHTML = tempHTML.replace(/{{ITEM_COUNT}}/gi, NotificationConstant.COUNT);
    tempHTML = tempHTML.replace(/{{VIEWER_COUNT}}/gi, NotificationConstant.COUNT_VIEW);
    tempHTML = tempHTML.replace(/{{ANONYMOUS}}/gi, 'Anonymous');
    tempHTML = tempHTML.replace(/{{PRODUCT_NAME}}/gi, NotificationConstant.PRODUCT_NAME);

    tempHTML = tempHTML.replace(/{BANNER_ID}/gi, _notifID);

    //add custom css
    if (document.getElementById('cstm_Css' + notiByIdData._id))
      document.getElementById('cstm_Css' + notiByIdData._id).remove();

    if (notiByIdData?.icstcss === true && notiByIdData?.cstcss) {
      var style = document.createElement('style');
      style.innerHTML = (frm === 'preview')
        ? notiByIdData.cstcss
        : fnAppendNotifIdToSelector(notiByIdData?.cstcss, _notifID);
      style.id = 'cstm_Css' + notiByIdData._id;
      document.getElementsByTagName('head')[0].appendChild(style);
    }
  }

  return tempHTML;
};

var fnAppendNotifIdToSelector = function (cssStr, notifId) {
  try {
    return cssStr.replace(/\tmpData/gi, '').split('}').map(rule => rule ?
      ('.wn_' + notifId + ' ' + rule + '}') : '').join('');
  } catch (error) {
    return cssStr;
  }
};

export default DesignCard;