import React, { useEffect } from 'react';
import { Box, Card, Text, Tooltip, BlockStack, InlineStack, Badge, ResourceList, ResourceItem, EmptySearchResult, Modal, Autocomplete, Checkbox, Divider, ChoiceList, Popover, OptionList, Collapsible, Tag, Spinner, Icon, Button, RadioButton, InlineGrid, Link, TextField } from '@shopify/polaris';
import { ChevronUpIcon, PlusIcon, ChevronDownIcon, XIcon, ImportIcon, PlayIcon } from '@shopify/polaris-icons';
import { useState, useCallback, useMemo } from 'react';
import { Country } from 'country-state-city';
import { isWhitelable } from '../../services/permissions';
let isWhite = isWhitelable();

const displayRule = [
  { value: '2', label: 'Contains' },
  { value: '1', label: 'Equals' },
  { value: '3', label: 'Regex' },
  { value: '5', label: 'Does not contain' },
  { value: '4', label: 'Does not equal' },
  { value: '6', label: 'Does not regex' }
];

const optUtmCond = [
  { value: '0', label: 'Contains' },
  { value: '1', label: 'Exclude' }
];

const cookiesObj = [
  { value: '0', label: 'Exact' },
  { value: '1', label: 'Not Exact' },
  { value: '2', label: 'Contains' },
  { value: '3', label: 'Not contains' }
];

const ReferralObj = [
  { value: '2', label: 'Contains' },
  { value: '0', label: 'Exact' },
  { value: '1', label: 'Not Exact' },
  { value: '3', label: 'Not contains' }
];

const DisplayCard = ({ props }) => {

  let { state, changeNameValue, handleDisplayChange, addDTUFunction, deleteDTUFunction, ImportDisplay,
    callAddDomain, deleteDomain, removeCountry, handleTagetAudience, handleSelectLocation, addUTMDisplay,
    handleDeleteTarget, addRefDisplay, addCoockiDisplay, handleTabsCollapse, openCloseModal, searchShopProducts,
    addShpProdFunction, deleteShpProdFunction, toggleShpProdct } = props;

  const allCountries = useMemo(() => Country.getAllCountries(), []);

  const [hideQryValue, setHideQryValue] = useState('');
  const [shwQryValue, setShwQryValue] = useState('');

  const filteredOptions = useMemo(() => { //get all countires as label-value
    return allCountries.map((country) => {
      return { value: country.name, label: country.name };
    });
  }, [allCountries]);

  const [countryOptions, setOptions] = useState(filteredOptions);

  // on searching for hide ntf
  useEffect(() => {
    if (hideQryValue.length > 1) {
      setShwQryValue('');
      setOptions(countryOptions.filter((country) =>
        new RegExp(hideQryValue, 'i').test(country?.value)
      ));
    }
  }, [hideQryValue]);

  // on searching for display ntf
  useEffect(() => {
    if (shwQryValue.length > 1) {
      setHideQryValue('');
      setOptions(countryOptions.filter((country) =>
        new RegExp(shwQryValue, 'i').test(country?.value)
      ));
    }
  }, [shwQryValue]);

  const handleSearchCountry = useCallback((value, typ) => {
    if (typ === 'arexclcnt') setHideQryValue(value);
    else setShwQryValue(value);

    if (value === '') { setOptions(filteredOptions); return; }

    const resultOptions = filteredOptions.filter((option) =>
      option.label.match(new RegExp(value, 'i'))
    );
    setOptions(resultOptions);
  }, [filteredOptions]);

  // remove Tag
  const verticalContentMarkup = (typ) =>
    state[typ].length > 0 ? (
      <InlineStack gap={200} alignment="center">
        {state[typ].map((option, i) => {
          return (
            <Tag key={`option${option}`}
              onRemove={() => removeCountry(i, typ)}>{option}
            </Tag>
          );
        })}
      </InlineStack>
    ) : null;

  // auto autoComplete searching field
  const textField = (typ) => {
    return (
      <Autocomplete.TextField
        onChange={(e) => handleSearchCountry(e, typ)}
        value={((typ === 'arexclcnt') ? hideQryValue : shwQryValue) || ''}
        placeholder="Type country name"
        verticalContent={verticalContentMarkup(typ)}
        autoComplete="off" />
    )
  };

  // create dynamic placeholder
  let newPlaceholder, newLabel;
  if (state.dtu == '1') {
    newPlaceholder = 'E.g. https://mydomain.com/about_us/';
    newLabel = 'Enter url to display a notification when the exact url is detected';
  }
  if (state.dtu == '2') {
    newPlaceholder = 'E.g. about_us ';
    newLabel = 'Enter a keyword to display a notification whenever the keyword is available in url(s)';
  }
  if (state.dtu == '3') {
    newPlaceholder = 'E.g. https://mydomain.com/*  any path written instead of “*” will be accepted';
    newLabel = 'Enter pattern to display notifications when url(s) matches this pattern';
  }
  if (state.dtu == '5') {
    newPlaceholder = 'E.g. about_us';
    newLabel = 'Enter a keyword to hide displaying a notification whenever the keyword is available in url(s)';
  }
  if (state.dtu == '4') {
    newPlaceholder = 'E.g. https://mydomain.com/about_us/';
    newLabel = 'Enter url to hide displaying a notification when the exact url is detected';
  }
  if (state.dtu == '6') {
    newPlaceholder = 'E.g. https://mydomain.com/*  any path written instead of “*” will not be accepted';
    newLabel = 'Enter pattern to hide displaying notifications when url(s) matches this pattern';
  }

  let domainListArr = (state.isAdd === true) ? state.ddnList : state.ddList;

  const getProLbl = useCallback((val) => {
    const item = state.shopProductsList?.length > 0 && state.shopProductsList.find(({ value }) => value === val);
    return item ? item.label : null;
  }, [state.shopProductsList]);

  // auto autoComplete searching field
  const proSearchField = () => {
    return (<Autocomplete.TextField
      onChange={(e) => searchShopProducts(e)}
      value={state.shpProValue}
      onFocus={() => props.callShpProducts()}
      placeholder="Search product.."
      autoComplete="off" />)
  };

  // store data as label-value
  let importOptions = (state?.notifFullList?.length > 0 && state.notifFullList.map((data, i) => {
    const label = (
      <div key={i}>{data.nn}
        <div>{data?.ddn?.length > 0 && data.ddn.map((d, j) => (
          <Link removeUnderline monochrome={true} key={j} target="_blank" url={`http://${d.ul}`}>{d.ul}</Link>
        ))}
        </div>
      </div>
    );
    return {
      value: data.nn,
      label: label,
    };
  }) || []);

  const segmentCnt = [
    state.icntntf,
    state.iutmd,
    state.irtnv,
    state.irfvf,
    state.ibcks
  ].reduce((count, flag) => count + (flag ? 1 : 0), 0);

  function getDeleteIcon() {
    return <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.55945 18C3.04407 18 2.60287 17.8042 2.23586 17.4125C1.86885 17.0208 1.68534 16.55 1.68534 16V3H0.748291V1H5.43356V0H11.0559V1H15.7411V3H14.8041V16C14.8041 16.55 14.6206 17.0208 14.2536 17.4125C13.8866 17.8042 13.4454 18 12.93 18H3.55945ZM12.93 3H3.55945V16H12.93V3ZM5.43356 14H7.30766V5H5.43356V14ZM9.18177 14H11.0559V5H9.18177V14Z' fill='#e22c38' /></svg>;
  }

  let mulWebsites = domainListArr?.length > 0 &&
    domainListArr.slice(0, 3).map(item => item.ul).join(', ') + (domainListArr.length > 3 ? '..' : '');

  let targetArr = [];
  if (state.icntntf === true) targetArr.push('Country');
  if (state.iutmd === true) targetArr.push('UTMs');
  if (state.irtnv === true) targetArr.push('Targeting visitors');
  if (state.irfvf === true) targetArr.push('Refferrer');
  if (state.ibcks === true) targetArr.push('Cookies');
  let adTargetOpt = targetArr.join(', ');

  return (
    <div>
      <InlineStack direction={'column'} gap={600}>
        <div id='scroll_HgHt'>
          <Card>
            <BlockStack gap={400}>

              {/* target all notification */}
              <Box background='bg-surface-active' borderRadius='300'>
                <div id='cursorPoint' onClick={() => handleTabsCollapse('isFirstTabActive', state.isFirstTabActive)}>
                  <Box padding={400} paddingBlockEnd={state.isFirstTabActive ? 200 : 400}>
                    <InlineStack align='space-between' blockAlign='start'>
                      <InlineStack gap={'050'} direction={'column'} blockAlign='start'>
                        <Tooltip dismissOnMouseOut padding='400' width='wide' hasUnderline content='Select condition to display notifications on specific / all pages'><Text fontWeight='semibold'>Select pages to display notifications on</Text></Tooltip>
                        <Text tone='subdued'>
                          {state.dt === '0' ? 'All pages' : 'Pages when url condition matches'}
                        </Text>
                      </InlineStack>

                      <InlineStack gap={200} blockAlign='center'>
                        {(localStorage.getItem('isbusr') == 'false' && isWhite == false) &&
                          <div id='cursorPoint' onClick={() => {
                            openCloseModal('youTubeOpen', state.youTubeOpen, 'open');
                            changeNameValue({ youTubeKey: '7LxCclNanFE' })
                          }}>
                            <Box id='videoLink' background='bg-fill-inverse-active'
                              borderRadius='100' paddingBlock={'050'} paddingInlineStart={100} paddingInlineEnd={200}>
                              <InlineStack blockAlign='center'>
                                <Icon source={PlayIcon} /><Text variant='bodySm'>3:44</Text>
                              </InlineStack>
                            </Box>
                          </div>
                        }
                        <Icon source={state.isFirstTabActive ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                      </InlineStack>
                    </InlineStack>
                  </Box>
                </div>

                <Collapsible
                  open={state.isFirstTabActive}
                  transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                  <div>
                    <Divider borderColor='border-secondary' />

                    <Box padding={400} paddingBlock={300} id='displyTbScroll'>
                      <BlockStack>
                        <RadioButton
                          label={<Text>All pages</Text>}
                          id="allPage"
                          name="targetNtf"
                          checked={state.dt === '0'}
                          onChange={() => handleDisplayChange('dt', '0')} />

                        <InlineStack align='space-between' blockAlign='center'>
                          <RadioButton
                            label={<Text>Pages when url condition matches</Text>}
                            id="conditional"
                            name="targetNtf"
                            checked={state.dt === '1'}
                            onChange={() => handleDisplayChange('dt', '1')} />
                          <Checkbox
                            label={<Tooltip dismissOnMouseOut padding='400' content='Turn off to ignore query param(e.g. utm_source)' width='wide' hasUnderline><Text tone='subdued'>Use absolute URLs</Text></Tooltip>}
                            checked={state?.dabs}
                            onChange={(e) => handleDisplayChange('dabs', e)}
                          />
                        </InlineStack>
                      </BlockStack>

                      {state.dt === '1' &&
                        <Box paddingInlineStart={700} paddingBlockStart={100} paddingBlockEnd={100}>

                          {/* when selected for Add conditions */}
                          <Box paddingBlock={100} paddingBlockStart={300}>
                            <InlineStack gap={400}>
                              <Box width='26%'>
                                <Text tone='subdued'>Select condition</Text>
                              </Box>
                              <Box width='70%'>
                                <Text tone='subdued'>Enter the desired url, keyword, or pattern</Text>
                              </Box>
                            </InlineStack>
                          </Box>

                          <BlockStack gap={400}>

                            {/* default empty  */}
                            <InlineStack gap={400}>
                              <Box width='26%'>
                                <Popover
                                  fullWidth
                                  active={state.isNtfUrl2}
                                  onClose={() => changeNameValue({ isNtfUrl2: !state.isNtfUrl2 })}
                                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                    onClick={() => changeNameValue({ isNtfUrl2: !state.isNtfUrl2 })}>
                                    <Text fontWeight='medium' variant='bodyMd'>
                                      {displayRule.find(({ value }) => value == state?.dtu)?.label || 'Select'}
                                    </Text>
                                  </Button>}>
                                  <OptionList
                                    options={displayRule}
                                    selected={[state?.dtu].toString()}
                                    onChange={() => changeNameValue({ isNtfUrl2: !state.isNtfUrl2 })}
                                    onFocusOption={(e) => changeNameValue({ 'dtu': e })} />
                                </Popover>
                              </Box>
                              <Box width='71%'>
                                <Tooltip dismissOnMouseOut padding='400' content={newLabel} width='wide'>
                                  <TextField
                                    value={state.dtURL}
                                    autoComplete="off"
                                    placeholder={newPlaceholder}
                                    onChange={(e) => handleDisplayChange('dtURL', e)}
                                    error={state.dtUrlError === true || false}
                                    connectedRight={<Box paddingInlineStart={200}><Button variant='primary' tone='success' onClick={() => addDTUFunction()}> Save </Button></Box>} />
                                </Tooltip>
                              </Box>
                            </InlineStack>

                            <Box paddingInlineEnd={'050'}>
                              <BlockStack gap={150}>
                                {/* dynamic conditions */}
                                {state.dtuArray?.length > 0 && state.dtuArray.slice().reverse().map((val, i) => {
                                  return (
                                    <InlineStack key={i} gap={400}>
                                      <Box width='26%'>
                                        <TextField
                                          disabled
                                          id='dis_black'
                                          value={displayRule.find(({ value }) => value == val?.dt)?.label || 'Select'} />
                                      </Box>
                                      <Box width='70%'>
                                        <TextField
                                          disabled
                                          id='dis_black'
                                          value={val.ul}
                                          connectedRight={<Box paddingInlineStart={200}><Button onClick={() => deleteDTUFunction(val._id)} variant='monochromePlain'>{getDeleteIcon()}</Button></Box>} />
                                      </Box>
                                    </InlineStack>
                                  );
                                })}
                              </BlockStack>
                            </Box>

                          </BlockStack>
                        </Box>
                      }

                    </Box>

                  </div>
                </Collapsible>
              </Box>

              {/* show on multiple sites */}
              <Box background='bg-surface-active' borderRadius='300'>
                <div id='cursorPoint' onClick={() => handleTabsCollapse('isSecTabActive', state.isSecTabActive)}>
                  <Box padding={400} paddingBlockEnd={state.isSecTabActive ? 200 : 400}>
                    <InlineStack align='space-between'>
                      <InlineStack gap={'050'} direction={'column'} blockAlign='start'>
                        <Tooltip dismissOnMouseOut padding='400' content='Add website domain/url(s) to display notifications' width='wide' hasUnderline>
                          <Text fontWeight='semibold'>Display notifications on multiple websites
                            {/* {domainListArr?.length > 0 && <Badge tone="success">{domainListArr?.length}</Badge>} */}
                          </Text></Tooltip>
                        <Text tone='subdued'>{mulWebsites}</Text>
                      </InlineStack>
                      <Box>
                        <Icon source={state.isSecTabActive ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                      </Box>
                    </InlineStack>
                  </Box>
                </div>

                <Collapsible
                  open={state.isSecTabActive}
                  transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                  <div>
                    <Divider borderColor='border-secondary' />
                    <Box padding={400} paddingBlock={300} id='displyTbScroll'>
                      <Box paddingBlockEnd={150}><Text tone='subdued'>Add new website domain/sub-domain/url</Text></Box>
                      <BlockStack gap={300}>

                        {/* default empty  */}
                        <TextField
                          value={state.cddn}
                          autoComplete="off"
                          placeholder="Enter website url(s), you can add multiple websites"
                          onChange={(e) => changeNameValue({ 'cddn': e })}
                          connectedRight={<Box paddingInlineStart={200}><Button variant='primary' tone='success'
                            onClick={() => callAddDomain()}>Save</Button></Box>}
                          error={state.cddnError === true || false}
                        />

                        <Box paddingInlineEnd={'050'}>
                          <BlockStack gap={150}>

                            {/* dynamic conditions */}
                            {domainListArr?.length > 0 && domainListArr.slice().reverse().map((val, i) => {
                              return (
                                <InlineStack key={i} gap={300}>
                                  <Box width='92%' background='bg-fill-tertiary' paddingBlock={'050'} borderRadius='200'>
                                    <Link monochrome target={'_blank'} url={'http://' + val.ul}>
                                      <Text id='dis_black'>{val.ul}</Text></Link>
                                  </Box>
                                  <Button disabled={domainListArr.length <= 1} onClick={() => deleteDomain(val._id)} variant='monochromePlain'>{getDeleteIcon()}</Button>
                                </InlineStack>
                              );
                            })}
                          </BlockStack>
                        </Box>

                      </BlockStack>
                    </Box>
                  </div>
                </Collapsible>
              </Box>

              {/* advance targeting options */}
              {state.queryNtt !== 'title_bar' &&
                <Box background='bg-surface-active' borderRadius='300'>
                  <div id='cursorPoint' onClick={() => handleTabsCollapse('isThirdTabActive', state.isThirdTabActive)}>
                    <Box padding={400} paddingBlockEnd={state.isThirdTabActive ? 200 : 400}>
                      <InlineStack align='space-between'>
                        <InlineStack gap={'050'} direction={'column'} blockAlign='start'>
                          <Tooltip dismissOnMouseOut padding='400' content='Customize visitor targeting based on behavior, location, and source' hasUnderline width='wide'><Text fontWeight='semibold'>Advanced targeting options {segmentCnt > 0 && <Badge tone="success">{segmentCnt}</Badge>}</Text></Tooltip>
                          <Text tone='subdued'>
                            {adTargetOpt || 'Choose Visitor, Country, UTMs, Cookies & Referrer targeting options'}
                          </Text>
                        </InlineStack>
                        <Box>
                          <Icon source={state.isThirdTabActive ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                        </Box>
                      </InlineStack>
                    </Box>
                  </div>

                  <Collapsible
                    open={state.isThirdTabActive}
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <div>
                      <Divider borderColor='border-secondary' />
                      <Box padding={400} paddingBlock={300} id='displyTbScroll'>
                        <BlockStack gap={400}>

                          <div>
                            <Checkbox
                              label={<Tooltip dismissOnMouseOut padding='400' content='Enable to choose notification display conditions, by default it will be visible to all visitors.' width='wide' hasUnderline><Text>Country based targeting</Text></Tooltip>}
                              checked={state?.icntntf}
                              onChange={(e) => handleTagetAudience('icntntf', e)}
                            />
                            {state?.icntntf === true &&
                              <Box paddingInlineStart={600} paddingBlockStart={'050'}>
                                <BlockStack gap={200}>
                                  <Card padding={400}>
                                    <Box paddingBlockEnd={100}>
                                      <Tooltip dismissOnMouseOut padding='400' content='Select countries, where you want to show this notification' width='wide' hasUnderline><Text tone='subdued'>Display notification in selected country</Text></Tooltip>
                                    </Box>
                                    <Autocomplete
                                      allowMultiple
                                      options={countryOptions}
                                      selected={state.arinclcnt || []}
                                      textField={textField('arinclcnt')}
                                      onSelect={(e) => handleSelectLocation(e, 'arinclcnt')}
                                    />
                                  </Card>
                                  <Card padding={400}>
                                    <Box paddingBlockEnd={100}>
                                      <Tooltip dismissOnMouseOut padding='400' content='Select countries, where you want to hide this notification' width='wide' hasUnderline><Text tone='subdued'>Hide notification in selected country</Text></Tooltip>
                                    </Box>
                                    <Autocomplete
                                      allowMultiple
                                      options={countryOptions}
                                      selected={state.arexclcnt || []}
                                      textField={textField('arexclcnt')}
                                      onSelect={(e) => handleSelectLocation(e, 'arexclcnt')}
                                    />
                                  </Card>
                                </BlockStack>
                              </Box>
                            }
                          </div>

                          <div>
                            <InlineStack blockAlign='center' align='space-between'>
                              <Checkbox
                                label={<Tooltip dismissOnMouseOut padding='400' content='Enable to display it based on traffic source, campaign or other UTM key-value available in url.' width='wide' hasUnderline><Text>UTM based targeting</Text></Tooltip>}
                                checked={state?.iutmd}
                                onChange={(e) => handleTagetAudience('iutmd', e)}
                              />
                              {(localStorage.getItem('isbusr') == 'false' && isWhite == false) &&
                                <div id='cursorPoint' onClick={() => {
                                  openCloseModal('youTubeOpen', state.youTubeOpen, 'open');
                                  changeNameValue({ youTubeKey: 'wk7ry2uZVAU' })
                                }}>
                                  <Box id='videoLink' background='bg-fill-inverse-active'
                                    borderRadius='100' paddingBlock={'050'} paddingInlineStart={100} paddingInlineEnd={200}>
                                    <InlineStack>
                                      <Icon source={PlayIcon} /><Text variant='bodySm'>1:28</Text>
                                    </InlineStack>
                                  </Box>
                                </div>
                              }
                            </InlineStack>

                            {state?.iutmd === true &&
                              <Box paddingInlineStart={700}>
                                <Box paddingBlock={100}>
                                  <InlineStack gap={400}>
                                    <Box width='26%'>
                                      <Text tone='subdued'>Select condition</Text>
                                    </Box>
                                    <Box width='70%'>
                                      <Tooltip dismissOnMouseOut padding='400' hasUnderline content='Enter part of url that is succeeded by a question mark in your url(e.g. ?utm_source+search……)' width='wide'><Text tone='subdued'>Enter data</Text></Tooltip>
                                    </Box>
                                  </InlineStack>
                                </Box>

                                <BlockStack gap={300}>

                                  {/* default empty  */}
                                  <InlineStack gap={400}>
                                    <Box width='26%'>
                                      <Popover
                                        fullWidth
                                        active={state.isUTMBased}
                                        onClose={() => changeNameValue({ isUTMBased: !state.isUTMBased })}
                                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                          onClick={() => changeNameValue({ isUTMBased: !state.isUTMBased })}>
                                          <Text fontWeight='medium' variant='bodyMd'>
                                            {optUtmCond.find(({ value }) => value == state?.utmCnd)?.label || 'Select'}
                                          </Text>
                                        </Button>}>
                                        <OptionList
                                          options={optUtmCond}
                                          selected={[state?.utmCnd].toString()}
                                          onChange={() => changeNameValue({ isUTMBased: !state.isUTMBased })}
                                          onFocusOption={(e) => handleTagetAudience('utmCnd', e)} />
                                      </Popover>
                                    </Box>
                                    <Box width='71%'>
                                      <TextField
                                        value={state.utmTxt}
                                        autoComplete="off"
                                        placeholder={'Enter data (e.g., ?utm)'}
                                        onChange={(e) => handleDisplayChange('utmTxt', e)}
                                        error={state.utmTxtError === true || false}
                                        connectedRight={<Box paddingInlineStart={200}><Button variant='primary' tone='success'
                                          onClick={() => addUTMDisplay()}> Save </Button></Box>} />
                                    </Box>
                                  </InlineStack>

                                  <BlockStack gap={150}>

                                    {/* dynamic conditions */}
                                    {state.UtmArray?.length > 0 && state.UtmArray.slice().reverse().map((val, i) => {
                                      return (
                                        <InlineStack key={i} gap={400}>
                                          <Box width='26%'>
                                            <TextField
                                              disabled
                                              id='dis_black'
                                              value={optUtmCond.find(({ value }) => value == val?.cnd)?.label || ''} />
                                          </Box>
                                          <Box width='67%'>
                                            <TextField
                                              id='dis_black'
                                              disabled
                                              value={val.txt}
                                              connectedRight={<Box paddingInlineStart={200}><Button onClick={() => handleDeleteTarget(val._id, 'utm')} variant='monochromePlain'>{getDeleteIcon()}</Button></Box>} />
                                          </Box>
                                        </InlineStack>
                                      );
                                    })}
                                  </BlockStack>

                                </BlockStack>
                              </Box>
                            }
                          </div>

                          <div>
                            <InlineStack blockAlign='center' align='space-between'>
                              <Checkbox
                                label={<Tooltip dismissOnMouseOut padding='400' content='Enable to choose notification display conditions, by default it will be visible to all visitors.' width='wide' hasUnderline><Text>Returning or new visitors</Text></Tooltip>}
                                checked={state?.irtnv}
                                onChange={(e) => handleTagetAudience('irtnv', e)}
                              />
                              {(localStorage.getItem('isbusr') === 'false' && isWhite == false) &&
                                <div id='cursorPoint' onClick={() => {
                                  openCloseModal('youTubeOpen', state.youTubeOpen, 'open');
                                  changeNameValue({ youTubeKey: 'mWCEoC40jfk' })
                                }}>
                                  <Box id='videoLink' background='bg-fill-inverse-active'
                                    borderRadius='100' paddingBlock={'050'} paddingInlineStart={100} paddingInlineEnd={200}>
                                    <InlineStack>
                                      <Icon source={PlayIcon} /><Text variant='bodySm'>1:23</Text>
                                    </InlineStack>
                                  </Box>
                                </div>
                              }
                            </InlineStack>

                            {state?.irtnv === true &&
                              <Box paddingInlineStart={1000} paddingBlockStart={'050'}>
                                <ChoiceList
                                  title={<Text>Select condition</Text>}
                                  choices={[
                                    { value: '0', label: <Text tone='subdued'>All</Text> },
                                    { value: '1', label: <Text tone='subdued'>New visitor</Text> },
                                    { value: '2', label: <Text tone='subdued'>Returning visitor</Text> }
                                  ]}
                                  selected={[state.rtnv]}
                                  onChange={(e) => handleTagetAudience('rtnv', e.toString())} />
                              </Box>
                            }
                          </div>

                          <div>
                            <InlineStack blockAlign='center' align='space-between'>
                              <Checkbox
                                label={<Tooltip dismissOnMouseOut padding='400' content='Enable to set visitor conditions based on referrer site.' width='wide' hasUnderline><Text>Referrer site</Text></Tooltip>}
                                checked={state?.irfvf}
                                onChange={(e) => handleTagetAudience('irfvf', e)}
                              />
                              {(localStorage.getItem('isbusr') == 'false' && isWhite == false) &&
                                <div id='cursorPoint' onClick={() => {
                                  openCloseModal('youTubeOpen', state.youTubeOpen, 'open');
                                  changeNameValue({ youTubeKey: 'v-627hP2q7c' })
                                }}>
                                  <Box id='videoLink' background='bg-fill-inverse-active'
                                    borderRadius='100' paddingBlock={'050'} paddingInlineStart={100} paddingInlineEnd={200}>
                                    <InlineStack>
                                      <Icon source={PlayIcon} /><Text variant='bodySm'>1:58</Text>
                                    </InlineStack>
                                  </Box>
                                </div>
                              }
                            </InlineStack>
                            {state?.irfvf === true &&
                              <Box paddingInlineStart={700}>

                                <Box paddingBlock={100}>
                                  <InlineStack gap={400}>
                                    <Box width='26%'><Text tone='subdued'>Select condition</Text></Box>
                                    <Box width='70%'><Text tone='subdued'>Enter data</Text></Box>
                                  </InlineStack>
                                </Box>

                                <BlockStack gap={300}>

                                  {/* default empty  */}
                                  <InlineStack gap={400}>
                                    <Box width='26%'>
                                      <Popover
                                        fullWidth
                                        active={state.isReffSite}
                                        onClose={() => changeNameValue({ isReffSite: !state.isReffSite })}
                                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                          onClick={() => changeNameValue({ isReffSite: !state.isReffSite })}>
                                          <Text fontWeight='medium' variant='bodyMd'>
                                            {ReferralObj.find(({ value }) => value == state?.refCnd)?.label || 'Select'}
                                          </Text>
                                        </Button>}>
                                        <OptionList
                                          options={ReferralObj}
                                          selected={[state?.refCnd].toString()}
                                          onChange={() => changeNameValue({ isReffSite: !state.isReffSite })}
                                          onFocusOption={(e) => handleTagetAudience('refCnd', e)} />
                                      </Popover>
                                    </Box>
                                    <Box width='71%'>
                                      <TextField
                                        value={state.refTxt}
                                        autoComplete="off"
                                        placeholder={'Enter referrer site'}
                                        onChange={(e) => handleDisplayChange('refTxt', e)}
                                        error={state.refTxtError === true || false}
                                        connectedRight={<Box paddingInlineStart={200}><Button variant='primary' tone='success'
                                          onClick={() => addRefDisplay()}> Save </Button></Box>} />
                                    </Box>
                                  </InlineStack>

                                  <BlockStack gap={150}>
                                    {/* dynamic conditions */}
                                    {state.refArray?.length > 0 && state.refArray.slice().reverse().map((val, i) => {
                                      return (
                                        <InlineStack key={i} gap={400}>
                                          <Box width='26%'>
                                            <TextField
                                              disabled
                                              id='dis_black'
                                              value={ReferralObj.find(({ value }) => value == val?.cnd)?.label || ''} />
                                          </Box>
                                          <Box width='68%'>
                                            <TextField
                                              disabled
                                              id='dis_black'
                                              value={val.txt}
                                              connectedRight={<Box paddingInlineStart={200}><Button onClick={() => handleDeleteTarget(val._id, 'ref')} variant='monochromePlain'>{getDeleteIcon()}</Button></Box>} />
                                          </Box>
                                        </InlineStack>
                                      );
                                    })}
                                  </BlockStack>

                                </BlockStack>
                              </Box>
                            }
                          </div>

                          <div>
                            <Checkbox
                              label={<Tooltip dismissOnMouseOut padding='400' content='Enable to select conditions to show if cookie name is available' width='wide' hasUnderline><Text>Show if cookies name available</Text></Tooltip>}
                              checked={state?.ibcks}
                              onChange={(e) => handleTagetAudience('ibcks', e)}
                            />
                            {state?.ibcks === true &&
                              <Box paddingInlineStart={700}>

                                <Box paddingBlock={100}>
                                  <InlineStack gap={400}>
                                    <Box width='26%'>
                                      <Text tone='subdued'>Select condition</Text>
                                    </Box>
                                    <Box width='70%'>
                                      <Text tone='subdued'>Enter data</Text>
                                    </Box>
                                  </InlineStack>
                                </Box>

                                <BlockStack gap={300}>

                                  {/* default empty  */}
                                  <InlineStack gap={400}>
                                    <Box width='26%'>
                                      <Popover
                                        fullWidth
                                        active={state.isCookieCok}
                                        onClose={() => changeNameValue({ isCookieCok: !state.isCookieCok })}
                                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                          onClick={() => changeNameValue({ isCookieCok: !state.isCookieCok })}>
                                          <Text fontWeight='medium' variant='bodyMd'>
                                            {cookiesObj.find(({ value }) => value == state?.cokCnd)?.label || 'Select'}
                                          </Text>
                                        </Button>}>
                                        <OptionList
                                          options={cookiesObj}
                                          selected={[state?.cokCnd].toString()}
                                          onChange={() => changeNameValue({ isCookieCok: !state.isCookieCok })}
                                          onFocusOption={(e) => handleTagetAudience('cokCnd', e)} />
                                      </Popover>
                                    </Box>
                                    <Box width='71%'>
                                      <TextField
                                        value={state.cokTxt}
                                        autoComplete="off"
                                        placeholder={'Enter cookie name'}
                                        onChange={(e) => handleDisplayChange('cokTxt', e)}
                                        error={state.cokTxtError === true || false}
                                        connectedRight={<Box paddingInlineStart={200}><Button variant='primary' tone='success'
                                          onClick={() => addCoockiDisplay()}>Save</Button></Box>} />
                                    </Box>
                                  </InlineStack>

                                  <BlockStack gap={150}>
                                    {/* dynamic conditions */}
                                    {state.cookieArray?.length > 0 && state.cookieArray.slice().reverse().map((val, i) => {
                                      return (
                                        <InlineStack key={i} gap={400}>
                                          <Box width='26%'>
                                            <TextField
                                              disabled
                                              id='dis_black'
                                              value={cookiesObj.find(({ value }) => value == val?.cnd)?.label || 'Select'} />
                                          </Box>
                                          <Box width='68%'>
                                            <TextField
                                              disabled
                                              id='dis_black'
                                              value={val.txt}
                                              connectedRight={<Box paddingInlineStart={200}><Button onClick={() => handleDeleteTarget(val._id, 'cok')} variant='monochromePlain'>{getDeleteIcon()}</Button></Box>} />
                                          </Box>
                                        </InlineStack>
                                      );
                                    })}
                                  </BlockStack>

                                </BlockStack>
                              </Box>
                            }
                          </div>

                        </BlockStack>
                      </Box>
                    </div>
                  </Collapsible>
                </Box>
              }

              {/*set custom stock */}
              {state.queryNtt === 'limited_stock' &&
                <Box background='bg-surface-active' borderRadius='300'>
                  <div id='cursorPoint' onClick={() => handleTabsCollapse('isFourTabActive', state.isFourTabActive)}>
                    <Box padding={400} paddingBlockEnd={state.isFourTabActive ? 200 : 400}>
                      <InlineStack align='space-between'>
                        <InlineStack gap={'050'} direction={'column'} blockAlign='start'>
                          <Text fontWeight='semibold'>Set custom stock {state?.shpPrdArray?.length > 0 && <Badge tone="success">{state.shpPrdArray.length}</Badge>}</Text>
                          <Text tone='subdued'>Search and select a product to configure left stock notifications</Text>
                        </InlineStack>
                        <Box>
                          <Icon source={state.isFourTabActive ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                        </Box>
                      </InlineStack>
                    </Box>
                  </div>

                  <Collapsible
                    open={state.isFourTabActive}
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <div>
                      <Divider borderColor='border-secondary' />

                      <Box id='displyTbScroll' paddingBlockEnd={400} paddingInlineStart={700} paddingBlockStart={100} paddingInlineEnd={500} >
                        <Box paddingBlock={100} paddingBlockStart={200}>
                          <InlineGrid columns={['twoThirds', 'oneThird']} gap={300}>
                            <Text tone='subdued'>Choose product</Text>
                            <Tooltip content='Enter the total stock count available for the selected product' padding='400' width='wide'><Text tone='subdued'>Set Initial Stock</Text></Tooltip>
                          </InlineGrid>
                        </Box>

                        <BlockStack gap={400}>

                          {/* default empty */}
                          <BlockStack gap={100}>
                            <InlineGrid columns={['twoThirds', 'oneThird']} gap={300}>
                              <div>
                                <Autocomplete
                                  loading={state?.loadingState}
                                  options={state?.loadingState ? ['test'] : state.shopProductsList || []}
                                  selected={state?.slctShpPrd?.split('|')[0] || []}
                                  textField={proSearchField()}
                                  onSelect={(e) => handleDisplayChange('slctShpPrd', e + '|' + getProLbl(e.toString()))}
                                  emptyState={state?.loadingState !== true && state.shopProductsList?.length < 0 && <EmptySearchResult title={'No data found'} withIllustration />} />

                              </div>
                              <TextField
                                type='Number'
                                value={state.qtycnt}
                                autoComplete="off"
                                placeholder={'Stock count'}
                                onChange={(e) => handleDisplayChange('qtycnt', e)}
                                error={state.dtUrlError === true || false}
                                connectedRight={<Box paddingInlineStart={200}><Button variant='primary' tone='success'
                                  onClick={() => addShpProdFunction()}>Save</Button></Box>}
                              />
                            </InlineGrid>
                          </BlockStack>

                          <Box paddingInlineEnd={'050'}>

                            {state.loadingState === true ?  //skeleton
                              <BlockStack gap={200}>
                                <InlineGrid columns={['twoThirds', 'oneThird', 'oneThird', 'oneThird']} alignItems='center'>
                                  <Box paddingBlock={100} borderRadius='150' width='50%' background='bg-fill-tertiary' />
                                  <Box paddingBlock={100} borderRadius='150' width='70%' background='bg-fill-tertiary' />
                                  <Box paddingBlock={100} borderRadius='150' width='70%' background='bg-fill-tertiary' />
                                  <InlineStack blockAlign='center' gap={200}>
                                    <Box paddingBlock={100} borderRadius='150' width='48%' background='bg-fill-tertiary' />
                                    <Box paddingInline={'050'} />
                                    <Box paddingBlock={100} borderRadius='150' width='35%' background='bg-fill-tertiary' />
                                  </InlineStack>
                                </InlineGrid>
                                {/* dynamic entry */}
                                {state?.shpPrdArray?.length > 0 && state.shpPrdArray.slice().reverse().map((val, i) => {
                                  return (
                                    <InlineGrid key={i} columns={['twoThirds', 'oneThird', 'oneThird', 'oneThird']} gap={300} alignItems='center'>
                                      <Box paddingBlock={400} borderRadius='150' background='bg-fill-tertiary' />
                                      <Box paddingBlock={400} borderRadius='150' background='bg-fill-tertiary' />
                                      <Box paddingBlock={400} borderRadius='150' background='bg-fill-tertiary' width='90%' />
                                      <Box paddingInlineStart={'050'}>
                                        <InlineStack gap={1000}>
                                          <Box paddingBlock={'050'} paddingInline={500} borderRadius='full' background='bg-fill-tertiary' />
                                          <Box paddingBlock={300} paddingInline={300} borderRadius='150' background='bg-fill-tertiary' />
                                        </InlineStack>
                                      </Box>
                                    </InlineGrid>
                                  );
                                })}
                              </BlockStack>
                              :
                              <BlockStack gap={200}>

                                {state?.shpPrdArray?.length > 0 &&
                                  <InlineGrid columns={['twoThirds', 'oneThird', 'oneThird', 'oneThird']} alignItems='center'>
                                    <Text>Product name</Text>
                                    <Text>Initial stock</Text>
                                    <Text>Left stock</Text>
                                    <div id='dFlex'>
                                      <Text id='spaceWrap'>Reset stock</Text>
                                      <Box paddingInlineStart={400}><Text>Action</Text></Box>
                                    </div>
                                  </InlineGrid>
                                }

                                {/* dynamic entry */}
                                {state?.shpPrdArray?.length > 0 && state.shpPrdArray.slice().reverse().map((val, i) => {
                                  return (
                                    <InlineGrid key={i} columns={['twoThirds', 'oneThird', 'oneThird', 'oneThird']} gap={300} alignItems='center'>
                                      <Tooltip content={val.nm}>
                                        <TextField disabled id='dis_black' value={val.nm.length > 26
                                          ? val.nm.substring(0, 26) + '...' : val.nm} />
                                      </Tooltip>
                                      <TextField disabled id='dis_black' value={val.qtycnt} />
                                      <TextField disabled id='dis_black' value={val.lftqtycnt} />
                                      <Box paddingInlineStart={300}>
                                        <InlineStack gap={1000}>
                                          <label className="switch">
                                            <input type="checkbox" name='irst' checked={val.irst} onChange={(e) => toggleShpProdct(val._id, e.target.checked)} />
                                            <span className="switch-label"></span>
                                            <span className="switch-handle"></span>
                                          </label>
                                          <Button onClick={() => deleteShpProdFunction(val._id)} variant='monochromePlain'>{getDeleteIcon()}</Button>
                                        </InlineStack>
                                      </Box>
                                    </InlineGrid>
                                  );
                                })}
                              </BlockStack>
                            }

                          </Box>

                        </BlockStack>
                      </Box>

                    </div>
                  </Collapsible>
                </Box>
              }
            </BlockStack>

            {/* import display rules */}
            <Box paddingInlineEnd={500} paddingBlockStart={300}>
              <InlineStack align='end'>

                <Popover
                  fullWidth
                  active={state.isImprtRules}
                  onClose={() => changeNameValue({ isImprtRules: !state.isImprtRules })}
                  activator={<div id='cursorPoint' onClick={() => changeNameValue({ isImprtRules: !state.isImprtRules })}>
                    <InlineStack align='space-between' gap={100}>
                      <InlineStack gap={'050'} align='center' blockAlign='center'>
                        <Icon source={ImportIcon} />
                        <Text fontWeight='semibold' as='span'>Import display rules</Text>
                      </InlineStack>
                      <Box><Icon source={state.isImprtRules ? ChevronUpIcon : ChevronDownIcon}
                        tone="base" /></Box>
                    </InlineStack>
                  </div>}>

                  <div className='imprt_Scroll'>
                    {(state.loadingState === true && importOptions <= 0) ?
                      <div style={{ textAlign: 'center', padding: '1rem' }}>
                        <Spinner accessibilityLabel="Loading" size="small" />
                      </div>
                      :
                      <OptionList
                        title={<Text fontWeight='bold' id='whiteSpace'>Clone display rules from notifications</Text>}
                        options={importOptions || []}
                        selected={['']}
                        onChange={() => {
                          changeNameValue({ isImprtRules: !state.isImprtRules });
                          openCloseModal('importRuleOpen', state.importRuleOpen, 'close');
                        }}
                        onFocusOption={(e) => changeNameValue({ impActiveRule: e })}
                      />
                    }
                  </div>
                </Popover>

              </InlineStack>
            </Box>
          </Card>
        </div>
      </InlineStack>



      {/* import rules confirmation */}
      < Modal
        open={state.importRuleOpen}
        onClose={(e) => openCloseModal('importRuleOpen', state.importRuleOpen, e)}
        title={< Text variant='headingMd' fontWeight='bold' > Confirm import</Text >}>
        <Box paddingInline={500} paddingBlock={500}>
          <InlineStack direction={'column'} align='space-between'>
            <Box paddingBlockStart={0} paddingBlockEnd={600}>
              <Text fontWeight='medium' variant='headingMd'>Do you want to copy display rules from "{state?.impActiveRule}" ?</Text>
              <Box paddingBlockStart={200}>
                <Text fontWeight='regular' tone='subdued' variant='headingMd'>This will replace your current settings.</Text>
              </Box>
            </Box>

            <InlineStack gap={500} align='end' blockAlign='end'>
              <Button variant='' onClick={() => openCloseModal('importRuleOpen', state.importRuleOpen, 'close')}><Text variant='headingMd' fontWeight='medium'>No</Text></Button>
              <Button variant="primary" onClick={() => ImportDisplay()}>
                <Text variant='bodyMd' fontWeight='medium'>Yes, import data setting</Text></Button>
            </InlineStack>

          </InlineStack>
        </Box>
      </Modal >

    </div >
  );
}

export default DisplayCard;