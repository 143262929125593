import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, TextField, Select, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip,
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from "react-router-dom";

function MailerliteCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, handleBackToMain } = props.props;

  const [info, setInfo] = useState({
    userIntData: [],
    ver: 'V1',
    ak: '',
    gi: '',//Group id
    rmk: '',
    deleteDataModal: false,//open delete modal
    dataId: '',//delete data id
    errMessage: {},
    ia: false,
    modalC: false,//condition for delete modal
    notifListData: "",//show data in modal
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //userIntData data from api
  const getUserIntData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ userIntData: getUserIntData });
  }, [getUserIntData]);

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //dropdown options
  const optionsVer = [
    { value: 'V1', label: 'Classic' },
    { value: 'V2', label: 'New' }
  ];

  //open delete modal
  const handleDeleteData = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false });
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteSendInBlue(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //create MailerLite data 
  const createMailerLite = () => {
    if (props.validator.errorMessages.ak || props.validator.errorMessages.rmk || props.validator.errorMessages.gi) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      let obj = {
        ak: info.ak,
        rmk: info.rmk,
        ver: info.ver,
        gi: info.gi
      };
      dispatch(integrationDucks.createMailerLite(obj));
      changeNameValueCard({ ak: '', rmk: '', gi: '', ver: 'V1', errMessage: {} });
    }
  }

  //updPlanStatus (toggle)
  const updPlanStatus = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.userIntData.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ userIntData: updatedList })
  };

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How To Connect</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create MailerLite data */}
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.ak} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">API Key</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.ak}
                        onChange={(e) => changeNameValueCard({ ak: e })}
                        placeholder='API Key'
                        autoComplete="off"
                        error={info.errMessage.ak ? "The API Key field is required." : false}
                      />
                      {props.validator.message('ak', info.ak, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={"Enter group id"} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Group Id</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.gi}
                        onChange={(e) => changeNameValueCard({ gi: e })}
                        placeholder='Group Id'
                        autoComplete="off"
                        error={info.errMessage.gi ? "The Group Id field is required." : false}
                      />
                      {props.validator.message('gi', info.gi, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.ver} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Select version</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <Select
                        options={optionsVer}
                        onChange={(e) => changeNameValueCard({ ver: e })}
                        value={info.ver}
                      />
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box align='center' padding={300}><Button onClick={(e) => createMailerLite(e)} variant="primary">Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.userIntData?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Group Id' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.userIntData && info.userIntData.length > 0 && info.userIntData.map((i, index) => {
                  return (
                    <IndexTable.Row key={index}>
                      <IndexTable.Cell>
                        <Text variant="bodyMd" fontWeight="bold" as="span">
                          {index + 1}
                        </Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>  {i.d?.gi}</IndexTable.Cell>
                      <IndexTable.Cell>{i.rmk}</IndexTable.Cell>
                      <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                      <IndexTable.Cell>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={i.ia}
                            onChange={() => updPlanStatus(i)}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </IndexTable.Cell>
                      {props.checkPermission('integration', 'isdelete', props.profile) &&
                        <IndexTable.Cell>
                          <div id="delete" onClick={() => handleDeleteData(i._id)}>
                            <Banner hideIcon tone='critical'>
                              <Icon
                                source={DeleteIcon}
                                tone="critical"
                              />
                            </Banner>
                          </div>
                        </IndexTable.Cell>
                      }
                    </IndexTable.Row>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e) }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close') }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    handleDeleteCall(info.dataId);
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page>
  )
}

export default MailerliteCard;