import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip, OptionList, Popover,
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ClipboardIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import copy from 'copy-to-clipboard';
import { toastify } from '../../ducks/toast';
import { useNavigate } from "react-router-dom";

function ThrivecartCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    errMessage: {},
    ThriveCart: [],
    sw: '',//Secret word
    rmk: '',
    dataId: '',//id for the delete data modal
    ia: false,
    deleteDataModal: false,//open delete modal
    isIntActive: false,
    ae: "Add events",//add event drop-down list
    apiD: false,
    selected: [],
    activePopover: null,
    modalC: false,//condition for delete modal
    notifListData: "",//show data in modal
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //ThriveCart data from api
  const getThriveCartData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ ThriveCart: getThriveCartData });
  }, [getThriveCartData]);

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.ThriveCart.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ ThriveCart: updatedList })
  };

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: false })
  }

  //open delete modal for sub data
  const handleSubdeleteModal = (id, val) => {
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: true, ae: val, modalC: false })
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteThrivecart(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //delete sub data api call
  const handleDeleteSubData = (id, val) => {
    let obj = {
      ev: val,
      _id: id
    };
    dispatch(integrationDucks.deleteThrivecartWB(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //create sub data api call  
  const handleChange = (selectedValue, _id) => {
    let obj = {
      ev: selectedValue,
      _id: _id
    };
    dispatch(integrationDucks.createThrivecartWB(obj));
    changeNameValueCard({ ae: selectedValue, activePopover: null });
  };

  //handle toggle
  const handleToggle = (id) => {
    changeNameValueCard({ activePopover: info.activePopover === id ? null : id });
  };

  //create ThriveCart data 
  const createThrivecart = () => {
    if (props.validator.errorMessages.sw || props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      changeNameValueCard({ errMessage: {} });
      let obj = {
        sw: info.sw,
        rmk: info.rmk,
      };
      dispatch(integrationDucks.createThrivecart(obj));
      changeNameValueCard({ sw: '', rmk: '', errMessage: {} });
    }
  }

  //copy endpoint
  const copyEndpoint = (url) => {
    copy(url, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Endpoint copied" }));
    return;
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create ThriveCart data */}
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.sw} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Secret word</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.sw}
                        onChange={(e) => changeNameValueCard({ sw: e })}
                        placeholder='Secret word'
                        autoComplete="off"
                        error={info.errMessage.sw ? "The secretword field is required." : false}
                      />
                      {props.validator.message('sw', info.sw, 'required|required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>

              {props.checkPermission('integration', 'isadd', props.profile) &&
                <Box align='center' padding={300}><Button onClick={(e) => createThrivecart(e)} variant="primary">Create</Button></Box>
              }
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.ThriveCart?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Secret word' },
                  { title: 'Webhook URL' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.ThriveCart && info.ThriveCart.length > 0 && info.ThriveCart.map((i, index) => {
                  let indexNumber = 0;
                  if (i._id === info.idForUsInt) i.ia = info.isIntActive ? info.isIntActive : false;
                  let _arr = [];
                  if (i.d.is === false) {
                    _arr.push({ label: 'Subscription', value: 'subscription' });
                  }
                  if (i.d.io === false) {
                    _arr.push({ label: 'Order', value: "order" });
                  }
                  let wbURL = i.d && i.d?.wu;
                  if (props.isWhite === true) {
                    wbURL = wbURL.replace('https://is.wisernotify.com', window.location.origin);
                  }
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell> {i.d?.sw}</IndexTable.Cell>
                        <IndexTable.Cell><span className="text-break">{wbURL ? wbURL.slice(0, 30) + '...' : ''}</span></IndexTable.Cell>
                        <IndexTable.Cell>{i.rmk}</IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <InlineStack>
                            {props.checkPermission('integration', 'isdelete', props.profile) &&
                              <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                <Banner hideIcon tone='critical'>
                                  <Icon
                                    source={DeleteIcon}
                                    tone="critical"
                                  />
                                </Banner>
                              </div>
                            }
                            <Box paddingInline={200}>
                              <div id="delete" onClick={() => copyEndpoint(wbURL)}>
                                <Banner hideIcon tone='info'>
                                  <Icon
                                    source={ClipboardIcon}
                                    tone="info"
                                  />
                                </Banner>
                              </div>
                            </Box>
                            <Box paddingInline={200}>
                              <Popover
                                active={info.activePopover === index}
                                activator={
                                  <Button onClick={() => {
                                    if (_arr.length !== 0) {
                                      handleToggle(index);
                                    }
                                  }}
                                  >
                                    <InlineStack>
                                      <div className='add-events'>Add events</div>
                                      <Icon
                                        source={ChevronDownIcon}
                                        tone="base"
                                      />
                                    </InlineStack>

                                  </Button>
                                }
                                onClose={() => changeNameValueCard({ activePopover: null })}
                              >
                                <OptionList
                                  onChange={(selected) => handleChange(selected[0], i._id)}
                                  options={_arr}
                                  selected={info.selected}
                                />
                              </Popover>
                            </Box>
                          </InlineStack>
                        </IndexTable.Cell>
                      </IndexTable.Row>

                      {props.checkPermission('integration', 'isadd', props.profile) && i.d.is === true &&
                        <IndexTable.Row>
                          <IndexTable.Cell></IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text variant="bodyMd" fontWeight="bold" as="span">
                              {++indexNumber}
                            </Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text as="h6">Subscription</Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell></IndexTable.Cell>
                          <IndexTable.Cell></IndexTable.Cell>
                          <IndexTable.Cell></IndexTable.Cell>
                          {props.checkPermission('integration', 'isdelete', props.profile) &&
                            <IndexTable.Cell>
                              <div id="delete" onClick={() => handleSubdeleteModal(i._id, 'Subscription')}>
                                <Banner hideIcon tone="critical">
                                  <Icon source={DeleteIcon} tone="critical" />
                                </Banner>
                              </div>
                            </IndexTable.Cell>
                          }
                        </IndexTable.Row>
                      }
                      {props.checkPermission('integration', 'isadd', props.profile) && i.d.io === true &&
                        <IndexTable.Row >
                          <IndexTable.Cell></IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text variant="bodyMd" fontWeight="bold" as="span">
                              {++indexNumber}
                            </Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text as="h6">Order</Text>

                          </IndexTable.Cell>
                          <IndexTable.Cell></IndexTable.Cell>
                          <IndexTable.Cell></IndexTable.Cell>
                          <IndexTable.Cell></IndexTable.Cell>
                          {props.checkPermission('integration', 'isdelete', props.profile) &&
                            <IndexTable.Cell>
                              <div id="delete" onClick={() => handleSubdeleteModal(i._id, 'Order')}>
                                <Banner hideIcon tone="critical">
                                  <Icon source={DeleteIcon} tone="critical" />
                                </Banner>
                              </div>
                            </IndexTable.Cell>
                          }
                        </IndexTable.Row>
                      }
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    if (info.apiD) {
                      handleDeleteSubData(info.dataId, info.ae);
                    } else {
                      handleDeleteCall(info.dataId);
                    }
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default ThrivecartCard;