import { BlockStack, Box, Button, ButtonGroup, Card, DatePicker, Divider, EmptySearchResult, Icon, IndexFilters, IndexTable, InlineStack, Link, Modal, Page, Popover, Spinner, Text, Tooltip, useBreakpoints, useSetIndexFiltersMode } from '@shopify/polaris';
import { ViewIcon } from '@shopify/polaris-icons';
import React, { useEffect, useState } from 'react';
import { skeleton } from '../../utils/skeleton';
import { useSelector } from 'react-redux';

function VisitorCard(props) {

  let { state, tabs, changeNameValue, onPaggiCall, onPaggination, filters, TotalData, openCloseModal, handleViewHistry, days_between, handleSelectDate } = props

  const { mode, setMode } = useSetIndexFiltersMode();
  let { mdDown, lgUp } = useBreakpoints()
  let [activeDateRange, setActiveDateRange] = useState({
    since: new Date(new Date().setHours(0, 0, 0, 0)),
    until: new Date(new Date().setHours(0, 0, 0, 0))
  })

  const [{ month, year }, setDate] = useState({
    month: activeDateRange.since.getMonth(),
    year: activeDateRange.since.getFullYear()
  });

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.since.toDateString(),
      tdt: state.until.toDateString()
    }
    changeNameValue(obj);
  }

  const handleCalendarChange = ({ start, end }) => {
    const newDateRange = { since: start, until: end };

    changeNameValue({
      frmdt: newDateRange.since.toDateString(),
      tdt: newDateRange.until.toDateString()
    });
    activeRangeChange(newDateRange);
  };

  // for custom date popup open (same tab click, event i s not coming)
  if (document.getElementById("Custom date-3")) {
    document.getElementById("Custom date-3").onclick = function () {
      changeNameValue({ popoverActive: true })
    }
  }

  const checkValidDate = (dateString) => {
    if (dateString && !dateString.includes("Invalid")) {
      return true;
    } else {
      return false;
    }
  }

  if (state.seVisHistry && state.seVisHistry.ct && state.seVisHistry.st && state.seVisHistry.cn) {
    state.seVisHistry['location'] = state.seVisHistry.ct + ',' + ' ' + state.seVisHistry.st + ',' + ' ' + state.seVisHistry.cn;
  }

  const getHeaderVid = () => {
    return <Tooltip dismissOnMouseOut padding='400' width='wide' content={<span> {state.seVisHistry?.vid}</span>}>
      <span>{state.seVisHistry?.vid}</span>
    </Tooltip>;
  }

  const emptyStateMarkup = (
    <EmptySearchResult
      title='Visitor data unavailable.'
      description={'Check that the pixel code is correctly installed on the pages where you want to display notifications.'}
      withIllustration />
  );

  return (
    <Page title='Visitor'>
      <Box paddingBlockEnd={400}>
        <Card padding={0}>
          <div id="none_search">
            <IndexFilters
              tabs={tabs}
              canCreateNewView={false}
              selected={state.selected}
              onSelect={(e) => changeNameValue({ selected: e })}
              onQueryChange={() => { changeNameValue({ popoverActive: false }) }}
              cancelAction={{
                onAction: () => changeNameValue({ queryValue: '', cntryQuery: '' })
              }}
              filters={filters}
              onClearAll={() => changeNameValue({ cntryQuery: '', cntryQuery: '' })}
              mode={mode}
              setMode={setMode}
            />
          </div>

          <div id='visitor_Tbl'>
            <IndexTable
              itemCount={state.reLoadVisitor ? 3 : (state.DataVisitor.length || 0)}
              selectable={false}
              emptyState={emptyStateMarkup}
              headings={[
                { title: <Text fontWeight='medium' variant='bodyMd'>No</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Lead</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Hostname</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Location</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Last seen</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Impression</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Hover</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Click</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Action</Text> },
              ]}
              pagination={{
                hasPrevious: state.paggiActive > 1 ? true : false,
                hasNext: state.paggiActive < state.pageNumber ? true : false,
                onPrevious: () => onPaggiCall('-1'),
                onNext: () => onPaggiCall('+1')
              }}
            >
              {
                state.reLoadVisitor === true ?
                  skeleton(10, 8)
                  :
                  state.DataVisitor.length > 0 &&
                  state.DataVisitor.map((data, index) => {
                    let { ct, cn, st } = data;
                    let loc = '';

                    if (ct && st && cn) {
                      loc = `${ct}, ${st}, ${cn}`;
                    } else if (!ct && st && cn) {
                      loc = `${st}, ${cn}`;
                    } else if (ct && !st && cn) {
                      loc = `${ct}, ${cn}`;
                    } else if (ct && st && !cn) {
                      loc = `${ct}, ${st}`;
                    }
                    data = { ...data, loc: loc };

                    return (
                      <IndexTable.Row
                        id={index}
                        key={index}
                        position={index}
                      >
                        <IndexTable.Cell>
                          <Text as='p'>{(index + 1) + (state.paggiActive - 1) * 10}</Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as="h6" >
                            <span>{data.vid || ''}</span>
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as='h6'>
                            <Link monochrome target='_blank' url={`http://${data.ht}`}>{data.ht || ''}</Link>
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as='h6' id="txt_limit2">
                            <span>
                              {data.ct && data.st && data.cn ? (
                                <>
                                  <Text as='p'>{data.st},</Text>
                                  <Text as='p' truncate='true'>{data.ct}, {data.cn}</Text>
                                </>
                              ) : (
                                '-'
                              )}
                            </span>
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as='p' truncate='true'>{days_between(data.upddt)}</Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as='p'>{data.vc}</Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as='p'>{data.hc}</Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text as='p'>{data.cc}</Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <div onClick={() => handleViewHistry(data)} id='cursorPoint'>
                            <Icon
                              source={ViewIcon}
                              tone="base"
                              id='cursorPoint'
                            />
                          </div>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    )
                  })
              }
            </IndexTable>
          </div>
        </Card>
      </Box>

      <div className='visitorcstm_date' style={{ display: state.popoverActive ? 'block' : 'none' }}>
        <Box maxWidth='580px'>
          <Card padding={200}>
            <Popover.Pane>
              <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                <InlineStack gap="400">
                  <DatePicker
                    month={month}
                    year={year}
                    selected={{
                      start: activeDateRange.since,
                      end: activeDateRange.until,
                    }}
                    onMonthChange={(month, year) => setDate({ month, year })}
                    disableDatesAfter={new Date()}
                    onChange={handleCalendarChange}
                    multiMonth={lgUp}
                    allowRange />
                </InlineStack>
              </Box>
            </Popover.Pane>

            <Divider />
            <Box padding={200}>
              <InlineStack gap={600} align="end" blockAlign='center'>
                <Text>
                  {(checkValidDate(state.frmdt) && checkValidDate(state.tdt))
                    ? state.frmdt + " - " + state.tdt : ''}
                </Text>
                <ButtonGroup>
                  <Button variant='tertiary' onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                  <Button variant='primary' onClick={handleSelectDate}>Apply</Button>
                </ButtonGroup>
              </InlineStack>
            </Box>

          </Card>
        </Box>
      </div>

      < Modal
        size='large'
        open={state.visitHistoryOpenModal}
        onClose={(e) => openCloseModal('visitHistoryOpenModal', state.visitHistoryOpenModal, e)}
      >
        <BlockStack gap={200}>
          <Box paddingBlockStart={600} paddingInline={600}>
            <BlockStack gap={300}>
              <InlineStack gap="400" wrap={false} blockAlign="center">
                <Text fontWeight="bold" as="p">
                  {getHeaderVid(state.seVisHistry?.vid)}
                </Text>
                <Text as='span' tone='subdued'>
                  {days_between(state.seVisHistry.upddt)}
                </Text>
              </InlineStack>
              <InlineStack gap={300}>
                <BlockStack gap={300}>
                  <InlineStack gap="200" wrap={false} blockAlign="center">
                    <Text as='p'>{state.seVisHistry.location}</Text>
                    <Text as='p' tone='subdued'>
                      {state.seVisHistry?.i ? `(${state.seVisHistry.i})` : ''}
                    </Text>
                  </InlineStack>
                  <InlineStack gap={1200}>
                    <BlockStack gap={100}>
                      <Text>Engagement</Text>
                      <Text fontWeight='bold' variant='bodyLg'>{(state.seVisHistry.cc + state.seVisHistry.hc)}</Text>
                    </BlockStack>
                    <BlockStack gap={100}>
                      <Text>Impression</Text>
                      <div id='cursorPoint' onClick={() => state.seVisHistry.vc !== 0 && changeNameValue({ eventType: 'impression', vid: state.seVisHistry.vid })}>
                        <Text fontWeight='bold' variant='bodyLg'>{state.seVisHistry.vc}</Text>
                      </div>
                    </BlockStack>
                    <BlockStack gap={100}>
                      <Text>Click count</Text>
                      <div id='cursorPoint' onClick={() => state.seVisHistry.cc !== 0 && changeNameValue({ eventType: 'click', vid: state.seVisHistry.vid })}>
                        <Text fontWeight='bold' variant='bodyLg'>{state.seVisHistry.cc}</Text>
                      </div>
                    </BlockStack>
                    <BlockStack gap={100}>
                      <Text>Hover count</Text>
                      <div id='cursorPoint' onClick={() => state.seVisHistry.hc !== 0 && changeNameValue({ eventType: 'hover', vid: state.seVisHistry.vid })}>
                        <Text fontWeight='bold' variant='bodyLg'>{state.seVisHistry.hc}</Text>
                      </div>
                    </BlockStack>
                  </InlineStack>
                </BlockStack>
              </InlineStack>
            </BlockStack>
          </Box>
          <Box paddingBlock={100}>
            <Divider />
          </Box>
          <div>
            <Box padding={600} paddingBlockStart="100" paddingBlockEnd={800} >
              <Card padding={0}>
                <div id='visitHistory_tble'>
                  <IndexTable
                    itemCount={state.isReloadVisithstry === true ? 3 : (state.visitHistory?.length || 0)}
                    selectable={false}
                    headings={[
                      { title: <Text fontWeight='medium' variant='bodyMd'>No</Text>, paddingBlockEnd: '0' },
                      { title: <Text fontWeight='medium' variant='bodyMd'>Viewed Page</Text> },
                      { title: <Text fontWeight='medium' variant='bodyMd'>Event</Text> },
                      { title: <Text fontWeight='medium' variant='bodyMd'>Notification</Text> },
                      { title: <Text fontWeight='medium' variant='bodyMd'>Time</Text> },
                    ]}
                    pagination={{
                      hasPrevious: state.activeVistHistPage > 1 ? true : false,
                      hasNext: state.activeVistHistPage < state.pageNum ? true : false,
                      onPrevious: () => onPaggination('-1'),
                      onNext: () => onPaggination('+1')
                    }}
                  >
                    {
                      state.isReloadVisithstry ? skeleton(10, 5) :
                        state.visitHistory && state.visitHistory.length > 0 && (
                          state.visitHistory.map((obj, index) => {
                            let hr = obj.hrf.length > 150 ? `${obj.hrf.slice(0, 150)}...` : obj.hrf;
                            return (
                              <IndexTable.Row
                                id={obj.value}
                                key={index}
                                position={index}
                              >
                                <IndexTable.Cell>
                                  <Text as="p">{(index + 1) + (state.activeVistHistPage - 1) * 10}</Text>
                                </IndexTable.Cell>
                                <IndexTable.Cell>
                                  <Text as="p" id="txt2_limit2">
                                    <a style={{ color: '#303030' }} target="_blank" href={obj.hrf}>{hr}</a>
                                  </Text>
                                </IndexTable.Cell>
                                <IndexTable.Cell>
                                  {(() => {
                                    switch (obj.ev) {
                                      case 'view':
                                        return 'impression';
                                      case 'engage':
                                        return 'engagement';
                                      case 'click':
                                        return 'click';
                                      case 'hover':
                                        return 'hover';
                                      case 'view_submit':
                                        return 'view & submit';
                                      case 'cta':
                                        return 'call to action';
                                      case 'reload':
                                        return 'reload';
                                      case 'hover_submit':
                                        return 'hover & submit';
                                      case 'click_submit':
                                        return 'click & submit';
                                      default:
                                        return '';
                                    }
                                  })()}
                                </IndexTable.Cell>
                                <IndexTable.Cell>
                                  <div id="txt3_limit2">
                                    {obj.nn ? obj.nn : '-'}
                                  </div>
                                </IndexTable.Cell>
                                <IndexTable.Cell>
                                  <Text as="p">{days_between(obj.t)}</Text>
                                </IndexTable.Cell>
                              </IndexTable.Row>
                            );
                          })
                        )
                    }
                  </IndexTable>
                </div>
              </Card>
            </Box>
          </div>
        </BlockStack>
      </Modal >

    </Page >
  )
}
export default VisitorCard;

