import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, BlockStack, IndexTable, Page, Text, Banner, Icon, Modal, Divider, PageActions } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import IntegrationHeader from './IntegrationHeader';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function EcwidCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    ecwid: [], //ecwid data
    idForUsInt: '',
    isIntActive: false,
    dataId: '', //id for the delete data modal
    deleteOpen: false, //open delete modal
    modalC: false, //condition for delete modal
    notifListData: '' //show data in modal
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api data.
  const userIntData = useSelector(state => state.integration.userIntData);

  //set ecwid data.
  useEffect(() => {
    changeNameValueCard({ ecwid: userIntData });
  }, [userIntData]);

  //redirect to the url.
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect.
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update api call (toggle button).
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.ecwid.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ ecwid: updatedList, idForUsInt: obj.id, isIntActive: obj.ia });
  };

  //redirect ECWID.
  const redirectECWID = () => {
    const authURL = `https://my.ecwid.com/api/oauth/authorize?client_id=wisernotify&redirect_uri=https://api.wisernotify.com/api/ecwidOauth&response_type=code&scope=read_catalog, read_store_profile, read_orders&state=${props.profile.ti}`;
    window.open(authURL, '_blank');
  }

  //openCloseModal. 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal.
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() });
    }
    openCloseModal('deleteOpen', info.deleteOpen, 'open');
    changeNameValueCard({ dataId: id });
  }

  //delete api call.
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteEcwid(id));
    openCloseModal('deleteOpen', info.deleteOpen, 'close');
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>Ecwid by lightspeed</span></div>} subtitle={'Show purchase data'} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <BlockStack inlineAlign='end'>
              <Box>
                <Button onClick={() => redirectECWID()} variant="primary" tone="success">Login with ecwid by lightspeed</Button>
              </Box>
            </BlockStack>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.ecwid?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.ecwid && info.ecwid.length > 0 && info.ecwid.map((data, index) => {
                  if (data._id === info.idForUsInt) {
                    data.ia = info.isIntActive ? info.isIntActive : false;
                  }
                  return (
                    <IndexTable.Row key={index}>
                      <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                      <IndexTable.Cell><span className="text-break">{data.rmk}</span></IndexTable.Cell>
                      <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                      <IndexTable.Cell>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data.ia}
                            onChange={() => handleIsActive(data)}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        {props.checkPermission('integration', 'isdelete', props.profile) &&
                          <div id="delete" onClick={() => handledeleteModal(data._id)}>
                            <Banner hideIcon tone='critical'>
                              <Icon
                                source={DeleteIcon}
                                tone="critical"
                              />
                            </Banner>
                          </div>
                        }
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  )
                })}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {info.modalC === true ?
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>This data-source is being used in the notification.</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
        :
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => handleDeleteCall(info.dataId)
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default EcwidCard;