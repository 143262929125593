export const checkPermission = (moduleName, permission, profile) => {
  if (profile && !profile.role) {
    return true;
  }
  else if (profile && profile.role && profile.permission) {
    let _flag = false;
    const dataPermission = profile.permission.filter(x => x.mid.n === moduleName);

    if (dataPermission && dataPermission.length > 0) {
      const objData = dataPermission[0];
      switch (permission) {
        case 'isadd':
          _flag = objData.iad;
          break;
        case 'isdelete':
          _flag = objData.idlt;
          break;
        case 'isdownload':
          _flag = objData.idwnl;
          break;
        case 'isedit':
          _flag = objData.iedt;
          break;
        case 'isview':
          _flag = objData.ivw;
          break;

        default:
          return '';
      }
    }
    return _flag;
  }
  else {
    return false;
  }
};

export const isWhitelable = () => {
  const host = window.location.host;
  const arr = ['localhost:3000', 'localhost:3002', 'app.wisernotify.com', 'devapp.wisernotify.com', 'wisernotify.azurewebsites.net'];
  if (host && arr.includes(host))
    return false;
  return true;
};
