import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/notifShopify';
import { toastify } from '../ducks/toast';
import * as auth from '../ducks/auth';
import * as apiCron from '../services/apiCron';

function* getNotifShopify(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getNotifType', action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotifShopifySuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotifByUser(action) {
  try {
    yield put(load());
    let path = '/getNotifByUser';

    //check if login user is team member pass a flag
    if (localStorage.getItem('istm') === 'true') {
      path = '/getNotifByUser?istm=' + true;
    }
    const res = yield call(api.POST, path, action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotifByUserSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification by user' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotifActivityByUser(action) {
  try {
    yield put(load());
    let path = '/getNotifActivityByUser';

    //check if login user is team member pass a flag
    if (localStorage.getItem('istm') === 'true') {
      path = '/getNotifActivityByUser?istm=' + true;
    }
    const res = yield call(api.POST, path, action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotifActivityByUserSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification by user' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getUserNotifData(action) {
  try {
    // yield put(load());
    const res = yield call(api.POST, '/getUserNotifData', action.payload);
    if (res.status === 'success') {
      yield put(actions.getUserNotifDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification by user' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getUniqCron(action) {
  try {
    yield put(load());
    const res = yield call(apiCron.GET, '/uniq?ti=' + action.payload);
    if (res.status !== 'success') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else {
      yield put(auth.getProfile());
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createNotifShopify(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createNotif', action.payload);
    if (res.status === 'success') {

      if (action.payload.typ && action.payload.typ === 'inlineNudge') {
        action.payload.navigate(`/notifEdit?nti=${res.data.nti}&id=${res.data._id}&ntt=${res.data.ntt}&ntyp=inlinenudge`);
      }
      else if (action.payload.typ && action.payload.typ === 'widget') {
        action.payload.navigate(`/notifEdit?nti=${res.data.nti}&id=${res.data._id}&ntt=${res.data.ntt}&ntyp=widget`);
      }
      else {
        action.payload.navigate(`/notifEdit?nti=${res.data.nti}&id=${res.data._id}&ntt=${res.data.ntt}`);
      }
      yield put(actions.createNotifShopifySuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotifByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updNotifStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `/updNotifStatus`, action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'limit') {
      if (action.payload.togl && action.payload.togl === true) {
        let obj = {
          _id: action.payload._id,
          dt: new Date().getTime()
        };
        yield put(actions.updNotifStatusSuccess(obj));
      }
      else {
        yield put(toastify({ type: 'error', msg: res.m }));
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification status' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delNotification(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/delNotification?id=' + action.payload.id + '&fn=' + action.payload.fn);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotifByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createInlineNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createInlineNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotifByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteInlineNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteInlineNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotifByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotiHght(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getNotifHeight', action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotiHghtSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCloneNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/cloneNotifi', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotifByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* smartDebugger(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `/smartDebuger?nti=${action.payload}`);
    if (res.status === 'success') {
      yield put(actions.smartDebuggerSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updNotifName(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updNotifName', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetNotifShopify() {
  yield takeLatest(actions.getNotifShopify.type, getNotifShopify);
}

export function* watchGetNotifByUser() {
  yield takeLatest(actions.getNotifByUser.type, getNotifByUser);
}

export function* watchGetNotifActivityByUser() {
  yield takeLatest(actions.getNotifActivityByUser.type, getNotifActivityByUser);
}

export function* watchGetUserNotifData() {
  yield takeLatest(actions.getUserNotifData.type, getUserNotifData);
}

export function* watchGetUniqCron() {
  yield takeLatest(actions.getUniqCron.type, getUniqCron);
}

export function* watchCreateNotifShopify() {
  yield takeLatest(actions.createNotifShopify.type, createNotifShopify);
}

export function* watchUpdNotifStatus() {
  yield takeLatest(actions.updNotifStatus.type, updNotifStatus);
}

export function* watchDelNotification() {
  yield takeLatest(actions.delNotification.type, delNotification);
}

export function* watchCreateInlineNotif() {
  yield takeLatest(actions.createInlineNotif.type, createInlineNotif);
}

export function* watchDeleteInlineNotif() {
  yield takeLatest(actions.deleteInlineNotif.type, deleteInlineNotif);
}

export function* watchGetNotiHght() {
  yield takeLatest(actions.getNotiHght.type, getNotiHght);
}

export function* watchCreateCloneNotif() {
  yield takeLatest(actions.createCloneNotif.type, createCloneNotif);
}

export function* watchSmartDebugger() {
  yield takeLatest(actions.smartDebugger.type, smartDebugger);
}

export function* watchUpdNotifName() {
  yield takeLatest(actions.updNotifName.type, updNotifName);
}

export default function* rootSaga() {
  yield fork(watchGetNotifShopify);
  yield fork(watchGetNotifByUser);
  yield fork(watchGetNotifActivityByUser);
  yield fork(watchGetUserNotifData);
  yield fork(watchGetUniqCron);
  yield fork(watchCreateNotifShopify);
  yield fork(watchUpdNotifStatus);
  yield fork(watchDelNotification);
  yield fork(watchCreateInlineNotif);
  yield fork(watchDeleteInlineNotif);
  yield fork(watchGetNotiHght);
  yield fork(watchCreateCloneNotif);
  yield fork(watchSmartDebugger);
  yield fork(watchUpdNotifName);
}
