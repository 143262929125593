import React from 'react';
import { Page, Text, Layout, Box, BlockStack, Navigation } from '@shopify/polaris';
import {
  SettingsIcon, BankIcon, ClipboardChecklistIcon, StarIcon, TeamIcon
} from '@shopify/polaris-icons';
import { Profile, ManageTeam, Agency, RateUs } from '../../containers';
import PlanUsage from '../Profile/PlanUsage';

function ManageAccountCard(props) {
  const { state, changeNameValue, checkPermission, profile } = props;

  const items =
    [
      checkPermission('profile', 'isview', profile) &&
      {
        url: '#',
        label: 'General',
        icon: SettingsIcon,
        selected: state.selectedMenu === 'general' ? true : false,
        onClick: () => changeNameValue({ selectedMenu: 'general' }),
      },
      localStorage.getItem('istm') !== 'true' && localStorage.getItem('pvd') !== 'shopify' &&
      {
        url: '#',
        label: 'Manage staff',
        icon: TeamIcon,
        selected: state.selectedMenu === 'manageTeam' ? true : false,
        onClick: () => changeNameValue({ selectedMenu: 'manageTeam' }),
      },
      (profile?.csub == 'agency' || localStorage.getItem('utyp') == 'appsumo') &&
      checkPermission('white_label_config', 'isview', profile) && localStorage.getItem('isbusr') == 'false' &&
      {
        url: '#',
        label: 'Agency',
        icon: BankIcon,
        selected: state.selectedMenu === 'agency' ? true : false,
        onClick: () => changeNameValue({ selectedMenu: 'agency' }),
      },
      checkPermission('reward', 'isview', profile) && localStorage.getItem('isbusr') == 'false' && localStorage.getItem('istm') !== 'true' &&
      {
        url: '#',
        label: 'Rate us',
        icon: StarIcon,
        selected: state.selectedMenu === 'rateUs' ? true : false,
        onClick: () => changeNameValue({ selectedMenu: 'rateUs' }),
      },
      {
        url: '#',
        label: 'Plan usage',
        icon: ClipboardChecklistIcon,
        selected: state.selectedMenu === 'planUsage' ? true : false,
        onClick: () => changeNameValue({ selectedMenu: 'planUsage' }),
      }
    ];
  let filterItems = items?.filter((elm) => elm !== false);

  return (
    <Page>
      <div className='page-wrap page-account-wrap'>
        <Layout>
          <Layout.Section variant="oneThird">
            <div className='navigationTop'>
              <BlockStack>
                <Box padding={300} paddingInlineStart={600}>
                  <Text variant='headingMd' as='p' fontWeight='semibold'>Your profile</Text>
                  <Text>{profile?.userTeam ? profile?.userTeam?.e : profile?.e}</Text>
                </Box>
              </BlockStack>
            </div>
            <div id='manageAc_nav'>
              <Navigation location="/">
                <Navigation.Section
                  items={filterItems}
                />
              </Navigation>
            </div>
          </Layout.Section>

          <Layout.Section>
            {
              state.selectedMenu === 'general' ?
                <Profile></Profile>
                :
                state.selectedMenu === 'manageTeam' ?
                  <ManageTeam></ManageTeam>
                  :
                  state.selectedMenu === 'agency' ?
                    <Agency></Agency>
                    :
                    state.selectedMenu === 'rateUs' ?
                      <RateUs></RateUs>
                      :
                      state.selectedMenu === 'planUsage' ?
                        <PlanUsage></PlanUsage>
                        :
                        ""
            }
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
}

export default ManageAccountCard;
