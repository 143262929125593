import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/profile';
import { toastify } from '../ducks/toast';
import { getProfile } from '../ducks/auth';

function* updateProfile(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updProfile', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(getProfile());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* cPwd(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/cPwd', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(getProfile());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updEmailSend(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updEmailSend', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      // yield put(getProfile());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updProfileImg(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updProfileImg', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(getProfile());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//deleteAccount
function* deleteAccount(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteAccount', action.payload);

    if (res.status === 'success') {
      window.localStorage.clear();
      window.location.href = '/login';
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete account' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getQuotaHistory(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getQuotaHistory?pvd=' + action.payload.pvd);

    if (res.status === 'success') {
      yield put(actions.getQuotaHistorySuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchUpdateProfile() {
  yield takeLatest(actions.updateProfile.type, updateProfile);
}

export function* watchcPwd() {
  yield takeLatest(actions.cPwd.type, cPwd);
}

export function* watchUpdEmailSend() {
  yield takeLatest(actions.updEmailSend.type, updEmailSend);
}

export function* watchUpdProfileImg() {
  yield takeLatest(actions.updProfileImg.type, updProfileImg);
}

export function* watchDeleteAccount() {
  yield takeLatest(actions.deleteAccount.type, deleteAccount);
}

export function* watchGetQuotaHistory() {
  yield takeLatest(actions.getQuotaHistory.type, getQuotaHistory);
}

export default function* rootSaga() {
  yield fork(watchUpdateProfile);
  yield fork(watchcPwd);
  yield fork(watchUpdEmailSend);
  yield fork(watchUpdProfileImg)
  yield fork(watchDeleteAccount);
  yield fork(watchGetQuotaHistory);
}
