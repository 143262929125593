import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/shopifyPricingPlan';
import { toastify } from '../ducks/toast';

function* getShopifyPricingPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getShopifyPlan', action.payload);

    if (res.status === 'success') {
      yield put(actions.getShopifyPricingPlanSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* shopifyPayment(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/shopify/shopifyPayment', action.payload);

    if (res.status === 'success') {
      // window.location.href = res.data;
      if (res.data) window.parent.location.href = res.data;
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetShopifyPricingPlan() {
  yield takeLatest(actions.getShopifyPricingPlan.type, getShopifyPricingPlan);
}

export function* watchShopifyPayment() {
  yield takeLatest(actions.shopifyPayment.type, shopifyPayment);
}

export default function* rootSaga() {
  yield fork(watchGetShopifyPricingPlan);
  yield fork(watchShopifyPayment);
}
