import { Text, Card, Page, Button, Box, Icon, ButtonGroup, IndexFilters, IndexTable, Badge, useSetIndexFiltersMode, InlineStack, Popover, ActionList, BlockStack, Tooltip, Modal, PageActions, TextField, SkeletonBodyText, SkeletonPage, LegacyCard, SkeletonDisplayText, useBreakpoints, Spinner, Autocomplete, Banner } from '@shopify/polaris';
import { PlusIcon, ClockIcon, MenuHorizontalIcon, DomainLandingPageIcon, CheckIcon, XIcon, EditIcon, SearchIcon } from '@shopify/polaris-icons';
import { useDispatch } from 'react-redux';
import AnalyticsModal from '../../containers/Analytics/Analytics';
import Parser from 'html-react-parser';
import { notif_empty } from '../../img';
import AddNotifCard from './AddNotifCard';
import DesignCard from '../NotifEdit/DesignCard';
import { checkPermission } from '../../services/permissions';
import moment from 'moment';
import Clone from './Clone';
import Debugger from './Debugger';
import AbTestCard from './AbTestCard';
import DetailCard from './DetailCard';
import { toastify } from '../../ducks/toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { skeleton } from '../../utils/skeleton';

function NotifCard(props) {
  const dispatch = useDispatch();

  const { state, changeNameValue, handleEditNotif, openCloseModal } = props;
  const { mode, setMode } = useSetIndexFiltersMode();

  const wordSort = (word) => {
    if (word !== undefined && word !== '' && word !== null) {
      if (word.length > 16) {
        return word.slice(0, 16) + '...';
      }
      return word;
    }
  }

  const toggleActive = () => changeNameValue({ activeDomainList: !state.activeDomainList });

  const activator = (
    <Button onClick={toggleActive} disclosure variant="tertiary" >
      {state.notifDomain.length === 0 ? 'Domain' : state.notifDomain[0]?.label}
    </Button>
  );

  const handleToggle = (id) => {
    changeNameValue({ activePopover: state.activePopover === id ? null : id })
  };

  const handleLink = (url) => {
    if (url) {
      window.open(`https://${url}`, '_blank');
    }
  }

  const renderListHTML = (state, data) => {
    return Parser(DesignCard(state, data, 'listCard'));
  }

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let abId = queryParams.get("ab");
    if (abId && state.userNotifList?.length > 0 && state.isTest !== true) {
      let abData = state.userNotifList.filter(x => x._id === abId)[0];
      changeNameValue({ objAbtestNotif: abData, isTest: true });
    }
  }, [queryParams.get("ab"), state.isTest, state.userNotifList]);

  const handleClickMenuitem = (typ, data) => {
    let obj = {};
    if (typ === 'AB_Testing') {
      obj = { abTest: true, objAbtestNotif: data, activePopover: null }
      navigate(location.pathname + '?ab=' + data._id);
    }
    else if (typ === 'Analytic')
      obj = { analyticsOpen: true, analyticNotifId: data._id, analyticNotifName: data.nn }
    else if (typ === 'Clone')
      obj = { isClone: true, ccid: data._id, cnname: data.nn }
    else if (typ === 'Details')
      obj = { detailShow: true, objDetailNotif: data, activePopover: null }
    else if (typ === 'Test_Data') {
      obj = { dummyData: true, objDummyData: data, activePopover: null }
    }

    changeNameValue(obj);

    //track click event
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Notification List Button', typ, state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Notification List Button', { 'event_action': typ, 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  }

  const handleAddNotif = () => {
    if (checkPermission('notification', 'isadd', state?.profile)) {
      changeNameValue({ isConfiguration: true });
    }
    else {
      dispatch(toastify({ type: 'error', msg: 'you have not permission to create notification!' }));
    }
  }

  const handleDeleteAction = (data) => {
    openCloseModal('deleteOpen', state.deleteOpen, 'open');
    changeNameValue({ selctdNotif: data });
  }

  function getSeenTime(date, txt) {
    const currentDate = new Date();
    const previousDate = new Date(date);

    const diffInMilliseconds = currentDate - previousDate; // Get the difference in milliseconds
    const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60); // Convert to minutes

    // Define time units
    const minutes = diffInMinutes % 60;
    const hours = Math.floor(diffInMinutes / 60) % 24;
    const days = Math.floor(diffInMinutes / 60 / 24);
    const months = Math.floor(diffInMinutes / 60 / 24 / 30) % 12;
    const years = Math.floor(diffInMinutes / 60 / 24 / 365);
    if (txt) {
      if (years > 0) {
        return `${txt} ${years} year${years > 1 ? 's' : ''} ago`;
      } else if (months > 0) {
        return `${txt} ${months} month${months > 1 ? 's' : ''} ago`;
      } else if (days > 0) {
        return `${txt} ${days} day${days > 1 ? 's' : ''} ago`;
      } else if (hours > 0) {
        return `${txt} ${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (minutes > 0) {
        return `${txt} ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else {
        return `${txt} just now`;
      }
    }
    else {
      if (years > 0) {
        return `${years} year${years > 1 ? 's' : ''} ago`;
      } else if (months > 0) {
        return `${months} month${months > 1 ? 's' : ''} ago`;
      } else if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else {
        return 'just now';
      }
    }
  }

  const handleTrublShotClick = (id, notifName) => {
    changeNameValue({
      pixelOpen: true, userNotifId: id, userNotifName: notifName, webUrl: '', userPixelVerify: ''
    });

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Notification list button', 'Troubleshoot link', state?.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Notification list button', { 'event_action': 'Troubleshoot link', 'event_label': state?.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
  }

  // Check notification activity
  const checkNotifActivity = (id, notifName) => {
    let findNotif = state.notifActivity?.find((elm) => elm._id === id);
    if (findNotif?.isFound === false) {
      return <span className='notif-activity'>
        <Badge
          tone="critical"
          progress="complete"
        >
          Not seen in the last 24 hours.<Button variant="plain"
            onClick={() => handleTrublShotClick(id, notifName)}><Text variant='bodySm'>Troubleshoot?</Text></Button>
        </Badge>
      </span>;
    }
    else {
      return <span className='notif-activity'>
        <Badge
          tone="success"
          progress="complete"
        >
          <Button variant="plain" onClick={() => props.handleGetUserNotifData(id, notifName)}><Text tone='success' variant='bodySm'>Last seen {getSeenTime(findNotif?.lstDt)}.</Text></Button> <Button variant="plain" onClick={() => handleTrublShotClick(id, notifName)}><Text variant='bodySm'>Troubleshoot?</Text></Button>
        </Badge>
      </span>;
    }
  };

  const textField = (
    <Autocomplete.TextField
      onChange={(e) => changeNameValue({ searchUserNotifUrl: e })}
      value={state.searchUserNotifUrl}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search by page URL"
      autoComplete="off"
    />
  );

  return (
    <div>
      {!queryParams.get("ab") && !state.detailShow ?
        <div>
          {/* <div className='subdubd_bg cutsm_Poltab'>
            <Card padding={100} roundedAbove='md'>
              <ButtonGroup>
                {tabsMain.map((d, i) => (
                  <Button key={i} variant={state.listTyp === d.tabName ? 'tertiary' : ''}
                    onClick={() => props.handleTabChange(d)}
                    icon={<Icon source={d.icon} tone={state.listTyp === d.tabName ? '' : 'subdued'} />}>
                    <Text fontWeight='medium' tone={state.listTyp === d.tabName ? '' : 'subdued'}>{d.label}</Text>
                  </Button>
                ))}
              </ButtonGroup>
            </Card>
          </div> */}
          {
            !state.isConfiguration && state.listTyp !== 'inlinelist' ?
              !props.isLoading && state.userNotifList && state.userNotifList !== '' && state?.userNotifList?.length !== 0 || state.queryValue !== '' || state.selected === 1 || state.selected === 2 ?
                <Page>
                  <Box paddingBlockStart={100} paddingBlockEnd={600}>
                    <BlockStack gap={600}>
                      <Box>
                        <InlineStack align='space-between'>
                          <Text variant='headingLg' as='p' fontWeight='semibold'>{`${state.listTyp === 'list' ? 'Social proof' : state.listTyp === 'widget' ? 'Widget' : 'Inline'} notification list`}</Text>
                          <ButtonGroup>

                            <Popover
                              active={state.activeDomainList}
                              activator={activator}
                              autofocusTarget="first-node"
                              onClose={toggleActive}
                            >
                              <ActionList
                                actionRole="menuitem"
                                items={state.domainList}
                              />
                            </Popover>
                            {checkPermission('notification', 'isadd', state.profile) &&
                              <Button variant="primary" icon={PlusIcon} onClick={() => handleAddNotif()}>Add notification</Button>
                            }
                          </ButtonGroup>
                        </InlineStack>
                      </Box>
                      {((state.profile.pvd === 'shopify' || state.profile.pvd === 'wix' || state.profile.pvd === 'thinkific' || state.profile.pvd === 'bigcommerce') && state.profile.csub === 'free') &&
                        <Banner tone='warning'>
                          <div style={{ display: 'flex' }}>
                            <p>
                              Free Plan: Limited to 1 active notification.
                            </p>
                            <Box paddingInlineStart={100}><Button variant="plain" onClick={() => props.handleNavigatePlan()}>Upgrade to unlock more.</Button></Box>
                          </div>
                        </Banner>
                      }

                      <Box id='nTfEdit_tbl'>
                        <Card padding={0}>
                          <IndexFilters
                            queryValue={state.queryValue}
                            queryPlaceholder="Search..."
                            onQueryChange={(e) => changeNameValue({ queryValue: e })}
                            onQueryClear={() => changeNameValue({ queryValue: '' })}
                            cancelAction={{
                              onAction: () => changeNameValue({ queryValue: '', filterNotif: ['all'], notifTyp: [] })
                            }}
                            tabs={props.tabs}
                            canCreateNewView={false}
                            selected={state.selected}
                            onSelect={(e) => changeNameValue({ selected: e })}
                            filters={props.filters}
                            onClearAll={props.handleFiltersClearAll}
                            mode={mode}
                            setMode={setMode}
                          />

                          <IndexTable
                            itemCount={state.userNotifList ? state.userNotifList.length : 0}
                            selectable={false}
                            headings={[
                              { title: 'Preview', id: 'thwidthpreview' },
                              { title: 'Name', id: 'thwidthname' },
                              { title: 'Website', id: 'thwidthwebsite' },
                              { title: 'Status', id: 'thwidthstatus' },
                              { title: 'Change', id: 'thwidthchange' },
                              { title: 'Actions' }
                            ]}
                            pagination={{
                              // label: props.paggiLabel,
                              hasPrevious: state.paggiActive > 1 ? true : false,
                              hasNext: (state.paggiActive < state.pageNumber) ? true : false,
                              onPrevious: () => props.onPaggiCall('-1'),
                              onNext: () => props.onPaggiCall('+1')
                            }} >
                            {
                              state.userNotifList !== '' && state.userNotifList && state.userNotifList.length !== 0 && state.userNotifList.map((data, index) => {
                                //for title bar
                                if (data.nt === 'title_bar' && data.cnf.tltyp) {
                                  setTimeout(() => {
                                    if (document.getElementById('TitleTxt_' + index)) {
                                      document.getElementById('TitleTxt_' + index).innerHTML = data.cnf.fl.txt;
                                      document.getElementById('ImgURL_' + index).setAttribute('src', data.cnf.fliu);
                                    }
                                  }, 1000);
                                }
                                return (
                                  <IndexTable.Row
                                    id={data._id}
                                    key={index}
                                    position={index}
                                  // onClick={() => changeNameValue({})}  //for whole row click none
                                  >
                                    <IndexTable.Cell id='nTf_wrap'>
                                      <div className={`nTf_hTMl wn_${data._id} notificatio-pro`}>
                                        {data.nt === 'title_bar' ?
                                          <div className="FDGdflt26_WeBTitLeBar_fdhKMN FDGdflt26_WeBTitLeBar_TiTlEbaRTem01">
                                            <div className="FDGdflt26_ToPhEaDer_FirstBlock">
                                              <div className="FDGdflt26_WeBTitLeBar_BlocK">
                                                <div className="FDGdflt26_WeBTitLeBar_imG">
                                                  <img id={`ImgURL_${index}`} alt='' />
                                                </div>
                                                <div className="FDGdflt26_WeBTitLeBar_LefTBlock">
                                                  <div className="FDGdflt26_WeBTitLeBar_TexT" id={`TitleTxt_${index}`}>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          data.nt === 'video' ? //video notification
                                            <div className="notilist-notivideo">
                                              {renderListHTML(state, data)}
                                            </div>
                                            :
                                            data.nt === 'call_to_action' ? //call_to_action notification
                                              <div className="notilist-callback">
                                                {renderListHTML(state, data)}
                                              </div>
                                              :
                                              (data.nt === 'collector' || data.nt === 'social_media_followers') ?
                                                <div className="notilist-promotional">
                                                  {renderListHTML(state, data)}
                                                </div>
                                                :
                                                data.nt === 'all_in_one' ? //All in one
                                                  <div className="notilist_allinone">
                                                    {renderListHTML(state, data)}
                                                  </div>
                                                  :
                                                  renderListHTML(state, data)
                                        }

                                      </div>
                                    </IndexTable.Cell>

                                    <IndexTable.Cell>
                                      <Text id='txt_limit' variant="headingXs" fontWeight="semibold" as="p">{data.rttl}</Text>
                                      <Box paddingBlockStart={200} paddingBlockEnd={200}>
                                        <BlockStack gap="200" align="start">
                                          {state.editNotifNames[index]?.isEdit ?
                                            <TextField
                                              placeholder='Notification name'
                                              value={state.editNotifNames[index]?.nn}
                                              onChange={(value) => props.handleTextChange(index, value)}
                                              autoComplete="off"
                                              connectedRight={
                                                <ButtonGroup>
                                                  <Button icon={CheckIcon} onClick={() => props.handleUpdateNotifName(index, data)}></Button>
                                                  <Button icon={XIcon} onClick={() => props.handleEditNotifNameClose(index)}></Button>
                                                </ButtonGroup>
                                              }
                                            />
                                            :
                                            <div className='edit-notiname-block'>
                                              <InlineStack gap={200} blockAlign='center'>
                                                <Text id='notilist-notiname' variant="headingMd" as="h6" fontWeight="semibold"  >
                                                  {data.nn}
                                                </Text>
                                                <Tooltip dismissOnMouseOut padding='400' content={data.nn}>
                                                  <div id='notilist-noti-edit' className='edit-notiname-icon' onClick={() => props.handleEditNotifNameShow(index, { isEdit: true, nn: data.nn })}>
                                                    <Icon source={EditIcon} />
                                                  </div>
                                                </Tooltip>
                                              </InlineStack>
                                            </div>
                                          }

                                          <Box>
                                            <Badge>{data.mnn}</Badge>
                                          </Box>
                                          <div style={{ width: '80%' }}>
                                            <Tooltip dismissOnMouseOut padding='400' content={<BlockStack id='tooltipbig'><Text>Created on: {moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</Text><Text>Last modified: {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY hh:mm:ss a')}</Text></BlockStack>} width='wide'>
                                              <Text variant="bodyXs" tone='subdued' fontWeight="medium" as="p" >
                                                {data.udt ? getSeenTime(new Date(data.udt), 'Updated') : getSeenTime(new Date(), 'Updated')}
                                              </Text>
                                            </Tooltip>
                                          </div>
                                        </BlockStack>
                                      </Box>
                                    </IndexTable.Cell>

                                    <IndexTable.Cell>
                                      <Box>
                                        <ButtonGroup>

                                          <Tooltip dismissOnMouseOut padding='400' content=
                                            {data.dt == 0 ? <Text>Display notification on all pages</Text> :
                                              <>
                                                {
                                                  data.dtu != undefined &&
                                                    data?.dtu?.length > 0 ?
                                                    <BlockStack>
                                                      <Text variant="headingSm" as="h6">Display rules</Text>
                                                      {data?.dtu.map((elm, index) => {
                                                        return <Text key={index} id='tooltipbig'>{elm.dt == 1 ? 'Equals: ' :
                                                          elm.dt == 2 ? 'Contains: ' :
                                                            elm.dt == 3 ? 'Regex: ' :
                                                              elm.dt == 4 ? 'Does not equal: ' :
                                                                elm.dt == 5 ? 'Does not contain: ' :
                                                                  elm.dt == 6 ? 'Does not Regex: ' : ''}
                                                          {data.dtu[index]?.ul}</Text>

                                                      })}
                                                    </BlockStack> :
                                                    <BlockStack>
                                                      <Text variant="headingSm" as="h6">Display rules</Text>
                                                      <Text>No condition found.</Text>
                                                    </BlockStack>
                                                }
                                              </>
                                            } width=''>
                                            <div className='iconRound'>
                                              <Icon source={DomainLandingPageIcon} tone="info" />
                                            </div>
                                          </Tooltip>
                                          {
                                            (data.nt == 'live_visitor' ||
                                              data.nt == 'purchase_recent' ||
                                              data.nt == 'signup_recent' ||
                                              data.nt == 'single_visits' ||
                                              data.nt == 'review_recent' ||
                                              data.nt == 'purchase_bulk' ||
                                              data.nt == 'signup_bulk' ||
                                              data.nt == 'review_bulk' ||
                                              data.nt == 'recent_view_bulk' ||
                                              data.nt == 'channel_subscriber' ||
                                              data.nt == 'video_subscriber' ||
                                              data.nt == 'limited_stock' || ((data.nt === 'custom' || data.nt === 'social_media_followers') && data.icstlp == true)) &&
                                            data.nt !== 'limited_stock' &&
                                            state.listTyp !== 'inlinelist' && state.listTyp !== 'widget' && (data.intd || data.dsnt || data.dlynt || data.rndnt) &&
                                            <Tooltip dismissOnMouseOut padding='400' content=
                                              {<BlockStack id='tooltipbig'>
                                                <Text>Initial delay time : {data.intd} {data.intd > 1 ? 'seconds' : 'second'}</Text>
                                                <Text>Display notification time: {data.dsnt} {data.dsnt > 1 ? 'seconds' : 'second'}</Text>
                                                <Text>Delay between notification time: {data.dlynt} {data.dlynt > 1 ? 'seconds' : 'second'}</Text>
                                                <Text>Randomize delay: {data.rndnt == true ? 'On' : 'Off'}</Text>
                                              </BlockStack>}
                                              width='wide'>
                                              <div className='iconRound'>
                                                <Icon source={ClockIcon} tone="info" />
                                              </div>
                                            </Tooltip>
                                          }

                                          {((data.nt === 'custom' || data.nt === 'video' ||
                                            data.nt === 'call_to_action' || data.nt === 'all_in_one' ||
                                            data.nt === 'social_media_followers' || data.nt === 'whatsapp_chat' ||
                                            data.nt === 'offer' || data.nt === 'review_feed' || data.nt === 'collector') &&
                                            data.icstlp !== true) &&
                                            state.listTyp !== 'inlinelist' && state.listTyp !== 'list' && (data.cnf.dtrg === 1 || data.cnf.dtrg === 2 || data.cnf.dtrg === 3) &&
                                            <Tooltip dismissOnMouseOut padding='400' content=
                                              {data.cnf.ihdwn ? data.cnf.dtrg == 1 ?
                                                <BlockStack>
                                                  <Text>Display rule : Exit intent </Text>
                                                  <Text>Hide rule : Hide after {data.cnf.hdwn} {data.cnf.hdwn > 1 ? 'seconds' : 'second'}</Text>
                                                </BlockStack> :
                                                data.cnf.dtrg == 2 ?
                                                  <BlockStack>
                                                    <Text>Display rule : Show after {data.cnf.aft} {data.cnf.aft > 1 ? 'seconds' : 'second'}</Text>
                                                    <Text>Hide rule : Hide after {data.cnf.hdwn} {data.cnf.hdwn > 1 ? 'seconds' : 'second'}</Text>
                                                  </BlockStack> :
                                                  data.cnf.dtrg == 3 ?
                                                    <BlockStack>
                                                      <Text>Display rule : Show after {data.cnf.sprc}% scroll</Text>
                                                      <Text>Hide rule : Hide after {data.cnf.hdwn} {data.cnf.hdwn > 1 ? 'seconds' : 'second'}</Text>
                                                    </BlockStack> : '' :
                                                <Text>
                                                  {data.cnf.dtrg === 1 ? 'Display rule : Exit intent' :
                                                    data.cnf.dtrg === 2 ? `Display rule : Show after ${data.cnf.aft} ${data.cnf.aft > 1 ? 'seconds' : 'second'}` :
                                                      data.cnf.dtrg === 3 ? `Display rule : Show after ${data.cnf.sprc}% scroll` :
                                                        ''}
                                                </Text>}
                                              width='wide'>
                                              <div className='iconRound'>
                                                <Icon source={ClockIcon} tone="info" />
                                              </div>
                                            </Tooltip>
                                          }
                                        </ButtonGroup>

                                        <Box paddingBlockEnd={300}>
                                          {data?.ddn.map((elm, index) => { return index < 3 ? <Text key={index}><Button variant='plain' onClick={() => handleLink(data.ddn[index].ul)}><Text tone="subdued">{wordSort(data.ddn[index].ul)}</Text></Button></Text> : '' })}
                                        </Box>

                                        {data.ia &&
                                          <div>
                                            {checkNotifActivity(data._id, data.nn)}
                                          </div>
                                        }
                                      </Box>
                                    </IndexTable.Cell>

                                    <IndexTable.Cell id='noti-list-onoff'>
                                      <InlineStack gap={200} blockAlign='center'>
                                        <Badge size='medium' tone={data.ia ? 'success' : 'critical'}>
                                          <Text variant='headingXs' fontWeight='medium' as='span'>
                                            {data.ia ? 'On' : 'Off'}
                                          </Text>
                                        </Badge>
                                        <label className="switch">
                                          <input type="checkbox" checked={data.ia}
                                            onChange={() => props.handleupdNotifStatus(data)} />
                                          <span className="switch-label"></span>
                                          <span className="switch-handle"></span>
                                        </label>
                                      </InlineStack>
                                    </IndexTable.Cell>

                                    <IndexTable.Cell id='notilist-data-td'>
                                      {
                                        data.ia == true &&
                                        (data.nt == 'purchase_recent' ||
                                          data.nt == 'purchase_bulk' ||
                                          data.nt == 'review_recent' ||
                                          data.nt == 'review_bulk' ||
                                          data.nt == 'review_feed' ||
                                          data.nt == 'video_subscriber' ||
                                          data.nt == 'channel_subscriber') &&
                                        <div>
                                          {
                                            data.dsi.length == 0 &&
                                            <span className='notilist-data-link' onClick={() => handleEditNotif(data, '', '', 'datasource')}><Text as="p" tone="subdued">At least one datasource required! </Text><Text as="p" tone="critical">Connect it.</Text></span>
                                          }
                                        </div>
                                      }
                                      <Button variant="primary" tone="success" onClick={() => handleEditNotif(data)} >Customize</Button>
                                    </IndexTable.Cell>

                                    <IndexTable.Cell>
                                      <Popover
                                        active={state.activePopover === index}
                                        activator={
                                          <Button onMouseEnter={() => handleToggle(index)} icon={MenuHorizontalIcon}>
                                          </Button>
                                        }
                                        onClose={() => changeNameValue({ activePopover: null })}
                                      >
                                        <ActionList
                                          actionRole="menuitem"
                                          items={[
                                            {
                                              content: `A/B Test ${data.vrnt?.length > 0 ? `(${data.vrnt?.length + 1})` : ''}`,
                                              onAction: () => handleClickMenuitem('AB_Testing', data),
                                            }, {
                                              content: 'Analytics',
                                              onAction: () => handleClickMenuitem('Analytic', data),
                                            }, {
                                              content: 'Clone',
                                              onAction: () => handleClickMenuitem('Clone', data),
                                            }, {
                                              content: 'Details',
                                              onAction: () => handleClickMenuitem('Details', data),
                                            }, {
                                              content: (
                                                !data.ia ?
                                                  <div className='1235'>
                                                    <Tooltip dismissOnMouseOut id="tooltip-debugger" padding='400' content={
                                                      <Text id='tooltip-debugger' variant="bodySm" as="p">
                                                        This notification is OFF. Please turn it ON to diagnose.
                                                      </Text>
                                                      // "This notification is OFF. Please turn it ON to diagnose."
                                                    } preferredPosition="below" width='wide'>
                                                      <span>Debugger</span>
                                                    </Tooltip>
                                                  </div>
                                                  : <span>Debugger</span>
                                              ),
                                              onAction: () => {
                                                if (data.ia) {
                                                  props.handleDebugger(data);
                                                }
                                              },
                                              disabled: !data.ia,
                                            },
                                            //  {
                                            //   content: 'Test Data',
                                            //   onAction: () => handleClickMenuitem('Test_Data', data),
                                            // },
                                            {
                                              content: (checkPermission('notification', 'isdelete', state?.profile) || (state?.profile.userTeam && state?.profile?.userTeam?._id == data?.cby)) &&
                                                <Button variant='plain' tone='critical'>Delete</Button>,
                                              onAction: () => handleDeleteAction(data),
                                            },
                                          ]} />
                                      </Popover>
                                    </IndexTable.Cell>
                                  </IndexTable.Row>
                                );
                              })}
                          </IndexTable>

                        </Card>
                      </Box>
                    </BlockStack>
                  </Box>
                </Page>
                :
                <Page>
                  {
                    (state.userNotifList === '') || props.isLoading ?
                      <Box paddingBlock={400}>
                        <SkeletonPage primaryAction>
                          <BlockStack gap={300}>
                            <Card>
                              <SkeletonBodyText lines={1} />
                            </Card>
                            <Card>
                              <BlockStack gap={300}>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                              </BlockStack>
                            </Card>
                            <Card>
                              <BlockStack gap={300}>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                              </BlockStack>
                            </Card>
                          </BlockStack>
                        </SkeletonPage>
                      </Box>
                      :
                      <Box paddingBlockStart={400}>
                        <Card>
                          <Box padding={400}>
                            <BlockStack gap={200} align='center' inlineAlign='center'>
                              <Box>
                                <img src={notif_empty} alt='notif' />
                              </Box>
                              <Text variant='headingLg' as='p' fontWeight='semibold'>
                                {state.listTyp === 'list' && `Increase Trust with Social Proof`}
                                {state.listTyp === 'widget' && `Boost Engagement with Widgets`}
                              </Text>
                              <Text variant='headingMd' as='p' fontWeight='regular'>
                                {state.listTyp === 'list' && `Show sales, leads, reviews, and visitor counters to build trust.`}
                                {state.listTyp === 'widget' && `Guide visitors to take action with offers, timers, buttons, and follower counters.`}
                              </Text>
                              <Box paddingBlockStart={200}>
                                <ButtonGroup>
                                  {/* <Button>Learn more</Button> */}
                                  {checkPermission('notification', 'isadd', state.profile) &&
                                    <Button variant='primary' onClick={() => changeNameValue({ isConfiguration: !state.isConfiguration })}>
                                      {state.listTyp === 'list' && ` Start creating social proof`}
                                      {state.listTyp === 'widget' && ` Start creating widget`}
                                    </Button>
                                  }
                                </ButtonGroup>
                              </Box>
                            </BlockStack>
                          </Box>
                        </Card>
                      </Box>
                  }
                </Page>
              :
              <AddNotifCard
                state={state}
                isLoading={props.isLoading}
                changeNameValue={changeNameValue}
                handleAddSocialShow={props.handleAddSocialShow}
                handleAddWidgetShow={props.handleAddWidgetShow}
                handleAddWebsite={props.handleAddWebsite}
                handleRemoveWebsite={props.handleRemoveWebsite}
                handleCreateNotif={props.handleCreateNotif}
                handleBackToMain={props.handleBackToMain}
                handleInlnUpdNotifStatus={props.handleInlnUpdNotifStatus}
                handleCreateInlnNotif={props.handleCreateInlnNotif}
                handleCollapsible={props.handleCollapsible}
                handleInstallPdpShowClose={props.handleInstallPdpShowClose}
                handleInstallCtgShowClose={props.handleInstallCtgShowClose}
                handleCopyCode={props.handleCopyCode}
                openCloseModal={props.openCloseModal}
                handleEditNotif={props.handleEditNotif}
                handleDelInlnNotif={props.handleDelInlnNotif}
                openChat={props.openChat}
                handleSugtTextChange={props.handleSugtTextChange}
                handleSugtOptionChange={props.handleSugtOptionChange}
                handleSugtInlnPdpTxtChange={props.handleSugtInlnPdpTxtChange}
                handleSugtInlnPdpOptChange={props.handleSugtInlnPdpOptChange}
                handleSugtInlnCtgTxtChange={props.handleSugtInlnCtgTxtChange}
                handleSugtInlnCtgOptChange={props.handleSugtInlnCtgOptChange}
                handleCreateStripeLink={props.handleCreateStripeLink}
              />
          }

          {state.isClone === true ?
            <Clone props={props} />
            : state.isDebugger === true ? <Debugger props={props} /> : ''}
        </div>
        :
        <>
          {queryParams.get("ab") && <AbTestCard props={props} />}
          {state.detailShow && <DetailCard props={props} />}
        </>
      }


      <Modal // delete notification
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this notification?</Text>
            <PageActions
              primaryAction={<Button variant='primary' tone='success' onClick={() => props.handleDelNotif(state.selctdNotif)}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      {/* open Analytics modal */}
      <Modal
        size='large'
        open={state.analyticsOpen}
        onClose={() => changeNameValue({ analyticsOpen: false, analyticNotifId: '' })}
      >
        <div className='full-screen-open'>
          <Box minHeight='90vh' id='add_inte_wrap' paddingInlineEnd={300}>
            <AnalyticsModal notifId={state.analyticNotifId} frm={'notification'} notifName={state.analyticNotifName} />
          </Box>
        </div>
      </Modal>

      {/* get user notif data modal*/}
      <Modal
        size='large'
        open={state.isUserNotifDataModal}
        title={<Text variant='headingMd' fontWeight='bold'>{state.userNotifSeenName}: Last seen in last 24 hours.</Text>}
        onClose={(e) => props.handleCloseUserNotifDataModal(e)}
      >
        <Modal.Section>
          <Box paddingBlockEnd={200}>
            <Autocomplete
              options={[]}
              textField={textField}
            />
          </Box>
          <LegacyCard>
            <IndexTable
              condensed={useBreakpoints().smDown}
              itemCount={state.getUserNotifData?.length === undefined ? 1 : state.getUserNotifData?.length}
              selectable={false}
              headings={[
                { title: 'No' },
                { title: 'Page URL' },
                { title: 'Event' },
                { title: 'Last seen' },
              ]}
              pagination={{
                // label: props.paggiLabel,
                hasPrevious: state.paggiActiveUserNotif > 1 ? true : false,
                hasNext: state.getUserNotifData?.length >= 20 ? true : false,
                onPrevious: () => props.onPaggiCallUserNotif('-1'),
                onNext: () => props.onPaggiCallUserNotif('+1')
              }}
            >
              {
                state.isSeenLoading ?
                  skeleton(20, 4)
                  :
                  <>
                    {state.getUserNotifData?.length > 0 && state.getUserNotifData.map(
                      (
                        elm,
                        index,
                      ) => (
                        <IndexTable.Row
                          id={elm._id}
                          key={index}
                          selected={false}
                          position={index}
                        >
                          <IndexTable.Cell>
                            <Text variant="bodyMd" fontWeight="bold" as="span">
                              {index + 1}
                            </Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>{elm.hrf.length > 70 ? elm.hrf.slice(0, 70) + '...' : elm.hrf}</IndexTable.Cell>
                          <IndexTable.Cell>{elm.ev}</IndexTable.Cell>
                          <IndexTable.Cell>{getSeenTime(elm.t)}</IndexTable.Cell>
                        </IndexTable.Row>
                      ),
                    )
                    }
                  </>
              }
            </IndexTable>
          </LegacyCard>
        </Modal.Section>
      </Modal>

      {/* Pixel page url modal */}
      <Modal
        open={state.pixelOpen}
        onClose={(e) => props.handleCloseInstallPixel(e)}
        title={<BlockStack id='troubleshoot-header'>
          <InlineStack gap={300}> <Text variant='headingMd' as='h1'>{state.userNotifName}</Text><Box><Badge tone="attention">Troubleshoot</Badge></Box></InlineStack>
          <Text variant='bodyMd' tone='subdued'>Check notification status on a specific page</Text>
        </BlockStack>}>
        <Modal.Section>
          <Text variant='bodyMd' tone='subdued'>We’ll first check if the pixel code is loading on the page you provide. If the pixel is detected, you can continue troubleshooting design, display, or data-source configurations directly on that page.</Text>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <Text fontWeight="medium" variant='bodyMd' tone='subdued'>Enter the URL of the page where you expect the notification to appear</Text>
          </Box>
          <InlineStack gap={200}>
            <Box width='80%'>
              <TextField
                placeholder='Enter your site/page URL'
                value={state.webUrl}
                onChange={(value) => props.changeNameValue({ webUrl: value })}
              />
            </Box>
            {
              <Button variant="tertiary" onClick={() => props.handleVerifyPixel()}>
                Verify Pixel
              </Button>
            }
          </InlineStack>

          <Box paddingBlockStart={500}  >
            <InlineStack gap={300}>
              {state.isPending ? (
                <div className="step-third-crtnoti troubleshoot-notice-block">
                  <div className="step-third-block step-pixel-verify">
                    <Text tone="subdued">Wait for 10-50 seconds, checking the pixel code...</Text>
                    <Spinner size="small" />
                  </div>
                </div>
              ) : state.userPixelVerify ? (
                state.userPixelVerify.isFound ? (
                  <div className="step-third-crtnoti troubleshoot-notice-block">
                    <div className="step-third-block step-pixel-verify">
                      <div className="step-pixel"></div>
                      <Text tone="success">
                        This page is loading the pixel code successfully.
                      </Text>
                    </div>
                    <Box paddingInlineStart={200}>
                      <Button variant="primary" tone="success" onClick={() => props.handleTroubleshoot()}>
                        Continue to Troubleshoot
                      </Button>
                    </Box>
                  </div>
                ) : (
                  <div className="step-third-crtnoti troubleshoot-notice-block" style={{ flexDirection: 'row-reverse' }}>
                    <div className="step-third-block">
                      <div className="step-pixel"></div>
                      <div className='step-novisit-text'>
                        <Text tone="critical">No visits in the last 24 hours or the pixel isn’t installed. Ensure the pixel is installed and the page has been visited.
                          {localStorage.getItem('isbusr') === 'false' && (
                            <>
                              <Text>Need help?</Text>
                              <Button variant="plain" onClick={() => props.handleChat()}>Chat With Us</Button>
                            </>
                          )}


                        </Text>

                      </div>

                      {/* <Spinner size="small" /> */}
                    </div>


                  </div>
                )
              ) : null}
            </InlineStack>
          </Box>
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default NotifCard;