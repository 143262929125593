import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const goal = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    getGoalDataById: (state, action) => { },
    getGoalDataByIdSuccess: (state, action) => {
      return { ...state, goal: action.payload };
    },
    createNewGoal: (state, action) => { },
    addGoalSuccess: (state, action) => {
      return { ...state, addGoal: action.payload };
    },
    deleteGoal: (state, action) => { },
    deleteGoalSuccess: (state, action) => {
      return { ...state, Deletegoal: action.payload };
    },
    updateGoal: (state, action) => { },
    updateGoalSuccess: (state, action) => {
      return { ...state, Updategoal: action.payload };
    }
  }
});

export const {
  getGoalDataById,
  getGoalDataByIdSuccess,
  createNewGoal,
  addGoalSuccess,
  deleteGoal,
  deleteGoalSuccess,
  updateGoal,
  updateGoalSucces
} = goal.actions;

export default goal.reducer;
