import { combineReducers } from 'redux';
import loading from './loading';
import notifEdit from './notifEdit';
import auth from './auth';
import analytics from './analytics';
import notifShopify from './notifShopify';
import dashboard from './dashboard';
import toast from './toast';
import header from './header';
import notif from './notif';
import data from './dataPage';
import visitor from './visitor';
import goal from './goal';
import setting from './setting';
import rate from './rateUs';
import pixel from './pixel';
import profile from './profile';
import manageTeam from './manageTeam';
import integration from './integration';
import agency from './agency';
import referEarn from './referEarn';
import login from './login';
import pricingPlan from './pricingPlan';
import shopifyPricingPlan from './shopifyPricingPlan';
import onBoarding from './OnBoard';
import whitelabelLogin from './whitelabelLogin';
import whitelabelForgotPwd from './whitelabelForgotPwd';
import wixPricingPlan from './wixPricingPlan';
import appsumoPlan from './appsumoPlan';
import billingInvoice from './billingInvoice';

const reducer = combineReducers({
  loading,
  notifEdit,
  auth,
  analytics,
  notifShopify,
  dashboard,
  toast,
  header,
  notif,
  data,
  visitor,
  goal,
  setting,
  rate,
  pixel,
  profile,
  referEarn,
  manageTeam,
  integration,
  agency,
  login,
  pricingPlan,
  shopifyPricingPlan,
  onBoarding,
  whitelabelLogin,
  whitelabelForgotPwd,
  wixPricingPlan,
  appsumoPlan,
  billingInvoice,
});

export default reducer;
