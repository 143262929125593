import React, { useEffect, useCallback } from 'react';
import { Text, Popover, OptionList, RangeSlider, Tooltip, Collapsible, ChoiceList, Icon, Checkbox, InlineGrid, Button, Box, InlineStack, TextField, BlockStack } from '@shopify/polaris';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ThemeTemplateIcon, EditIcon, ChevronDownIcon, ChevronRightIcon, ListBulletedIcon, DragHandleIcon, PlusIcon, EyeCheckMarkIcon, ClockIcon, DatabaseIcon, CursorIcon, ButtonIcon } from '@shopify/polaris-icons';
import ColorPick from './ColorPick';
import { useDispatch } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import { toastify } from '../../ducks/toast';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-emoji/dist/quill-emoji.css';
import 'react-quill/dist/quill.snow.css';

const dateFormatOpt = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };

// Add fonts to whitelist
setTimeout(() => {
  var Font = Quill.import('formats/font');
  Font.whitelist = ['OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans', 'WebsiteFont'];
  Quill.register(Font, true);
}, 1000);

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

//get one emoji and replace it with our custom
const _emj = ShortNameEmoji.DEFAULTS.emojiList.filter(x => x.shortname === ':skull:');
if (_emj && _emj.length > 0) {
  let objEmj = _emj[0];
  objEmj.code_decimal = '&#x2b50;';
  objEmj.unicode = '2B50';
}

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

var custTagDropDown = Quill.import('ui/picker');
custTagDropDown = [];
Quill.register(custTagDropDown, true);

let dispatch;

const customHandler = {
  'color': function (value) {
    if (value === 'custom-color') value = window.prompt('Enter Hex Color Code');
    this.quill.format('color', value, 'user');
  },
  'background': function (value) {
    if (value === 'custom-background') value = window.prompt('Enter Hex Color Code');
    this.quill.format('background', value, 'user');
  },
  'custTagDropDown': function (value) {
    if (this.quill.getText().includes(value) !== true)
      this.quill.insertText(this.quill.getSelection().index, '{{' + value + '}}', 'user');
    else dispatch(toastify({ type: 'error', msg: '{{' + value + '}} already available' }));
  }
};

const toolbarModule = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': ['OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans', 'WebsiteFont'] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [
      { 'list': 'ordered' }, { 'list': 'bullet' },
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean'],
  ],
  handlers: customHandler
};

//for common module
const modulesQuill = {
  toolbar: toolbarModule,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

//for format quill
const formatsQuill = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent', 'align',
  'link', 'image', 'background', 'color', 'emoji'
];

function formateDate(dt) {
  const date = new Date(dt);
  return date.getFullYear() + '-' +
    String(date.getMonth() + 1).padStart(2, '0') + '-' +
    String(date.getDate()).padStart(2, '0') + 'T' +
    String(date.getHours()).padStart(2, '0') + ':' +
    String(date.getMinutes()).padStart(2, '0');
}

const AllinoneCard = ({ props }) => {

  let { state, handleMainTextChange, handleSettingChange, changeNameValue, handleTextChange, openIconModal, copyHTMLSnippet,
    designValidator, AddfieldinArw, deletefieldinArw, handleArrayChange, openCloseModal, editorFocus, editorBlur } = props;

  let { cnf, notifId, notiById, queryNtt, defaultNotif, iconTab, errMessage } = state;

  let isAbtest = new URL(window.location.href).searchParams.get('abId') !== null || false;

  let dsplyRuleOpts = [
    {
      label: <Tooltip dismissOnMouseOut padding='400' content='Widget will get displayed when the visitor moves, cursor outside the upper page boundary.' width='wide' hasUnderline>
        <Text>Exit intent</Text></Tooltip>, value: 1
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content={`After ${cnf?.aft} seconds, a widget will get displayed on-page.`} width='wide' hasUnderline>
        <Text>Show after {cnf?.aft || 'x'} second</Text></Tooltip>, value: 2
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content={`Widget will get displayed once visitor scroll ${cnf?.sprc}% screen window.`} width='wide' hasUnderline>
        <Text>Show after {cnf?.sprc}% scroll</Text></Tooltip>, value: 3
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content='You can set feed on your website using custom class.' width='wide' hasUnderline>
        <Text>Show on custom class click</Text></Tooltip>, value: 4
    },
    {
      label: <Tooltip dismissOnMouseOut padding='400' content='Widget will get displayed on custom function call.' width='wide' hasUnderline>
        <Text>None of the above</Text></Tooltip>, value: 5
    }
  ];

  let weekOpts = [
    { label: 'Sun', value: '0' },
    { label: 'Mon', value: '1' },
    { label: 'Tue', value: '2' },
    { label: 'Wed', value: '3' },
    { label: 'Thrd', value: '4' },
    { label: 'Fri', value: '5' },
    { label: 'Sat', value: '6' }
  ];

  let skipNotifTime = [
    { value: '1', label: '1 hour' },
    { value: '2', label: '2 hour' },
    { value: '3', label: '3 hour' },
    { value: '4', label: '4 hour' },
    { value: '5', label: '5 hour' },
    { value: '6', label: '6 hour' },
    { value: '7', label: '7 hour' },
    { value: '8', label: '8 hour' },
    { value: '9', label: '9 hour' },
    { value: '10', label: '10 hour' },
    { value: '11', label: '11 hour' },
    { value: '12', label: '12 hour' },
    { value: '18', label: '18 hour' },
    { value: '24', label: '24 hour' },
    { value: '36', label: '36 hour' },
    { value: '48', label: '48 hour' },
    { value: '60', label: '60 hour' }
  ];

  let notifTiming = [
    { value: '1', label: '1 second' },
    { value: '2', label: '2 second' },
    { value: '3', label: '3 second' },
    { value: '4', label: '4 second' },
    { value: '5', label: '5 second' },
    { value: '6', label: '6 second' },
    { value: '7', label: '7 second' },
    { value: '8', label: '8 second' },
    { value: '9', label: '9 second' },
    { value: '10', label: '10 second' },
    { value: '11', label: '11 second' },
    { value: '12', label: '12 second' },
    { value: '13', label: '13 second' },
    { value: '14', label: '14 second' },
    { value: '15', label: '15 second' },
    { value: '16', label: '16 second' },
    { value: '17', label: '17 second' },
    { value: '18', label: '18 second' },
    { value: '19', label: '19 second' },
    { value: '20', label: '20 second' },
    { value: '21', label: '21 second' },
    { value: '22', label: '22 second' },
    { value: '23', label: '23 second' },
    { value: '24', label: '24 second' },
    { value: '25', label: '25 second' },
    { value: '26', label: '26 second' },
    { value: '27', label: '27 second' },
    { value: '28', label: '28 second' },
    { value: '29', label: '29 second' },
    { value: '30', label: '30 second' },
    { value: '40', label: '40 second' },
    { value: '50', label: '50 second' },
    { value: '100', label: '100 second' },
    { value: '150', label: '150 second' },
    { value: '200', label: '200 second' },
    { value: '250', label: '250 second' },
    { value: '300', label: '300 second' },
    { value: '350', label: '350 second' },
    { value: '400', label: '400 second' },
    { value: '500', label: '500 second' },
    { value: '600', label: '600 second' },
    { value: '700', label: '700 second' },
    { value: '800', label: '800 second' },
    { value: '900', label: '900 second' },
    { value: '1000', label: '1000 second' },
    { value: '1200', label: '1200 second' },
    { value: '1400', label: '1400 second' },
    { value: '1600', label: '1600 second' },
    { value: '1800', label: '1800 second' },
    { value: '2000', label: '2000 second' },
    { value: '2200', label: '2200 second' },
    { value: '2400', label: '2400 second' },
    { value: '2600', label: '2600 second' },
    { value: '2800', label: '2800 second' },
    { value: '3000', label: '3000 second' },
    { value: '3500', label: '3500 second' },
    { value: '4000', label: '4000 second' },
    { value: '5000', label: '5000 second' }
  ];

  let delayNotifTime = [
    { value: '2', label: '2 second' },
    { value: '3', label: '3 second' },
    { value: '4', label: '4 second' },
    { value: '5', label: '5 second' },
    { value: '6', label: '6 second' },
    { value: '7', label: '7 second' },
    { value: '8', label: '8 second' },
    { value: '9', label: '9 second' },
    { value: '10', label: '10 second' },
    { value: '11', label: '11 second' },
    { value: '12', label: '12 second' },
    { value: '13', label: '13 second' },
    { value: '14', label: '14 second' },
    { value: '15', label: '15 second' },
    { value: '16', label: '16 second' },
    { value: '17', label: '17 second' },
    { value: '18', label: '18 second' },
    { value: '19', label: '19 second' },
    { value: '20', label: '20 second' },
    { value: '21', label: '21 second' },
    { value: '22', label: '22 second' },
    { value: '23', label: '23 second' },
    { value: '24', label: '24 second' },
    { value: '25', label: '25 second' },
    { value: '26', label: '26 second' },
    { value: '27', label: '27 second' },
    { value: '28', label: '28 second' },
    { value: '29', label: '29 second' },
    { value: '30', label: '30 second' },
    { value: '40', label: '40 second' },
    { value: '50', label: '50 second' },
    { value: '60', label: '60 second' },
    { value: '100', label: '100 second' },
    { value: '150', label: '150 second' },
    { value: '200', label: '200 second' },
    { value: '250', label: '250 second' },
    { value: '300', label: '300 second' },
    { value: '350', label: '350 second' },
    { value: '400', label: '400 second' },
    { value: '500', label: '500 second' },
    { value: '600', label: '600 second' },
    { value: '700', label: '700 second' },
    { value: '800', label: '800 second' },
    { value: '900', label: '900 second' },
    { value: '1000', label: '1000 second' }
  ];

  //animition for desktop
  const deskAni = [
    { value: 'none', label: 'None' },
    { value: 'fade', label: 'Fade' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'zoom_in_down', label: 'Zoom in down' },
    { value: 'slide', label: 'Slide' },
    { value: 'horizonatal_slide', label: 'Slide horizontal' },
    { value: 'side_flip_in', label: 'Slide flip in' },
    { value: 'side_flip_back', label: 'Slide flip back' },
    { value: 'flip', label: 'Flip' },
    { value: 'up_flip', label: 'Flip up' },
    { value: 'back_flip', label: 'Flip back' }
  ];

  //animition for phone
  const phoneAni = [
    { value: 'none', label: 'None' },
    { value: 'fade', label: 'Fade' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'zoom_in_down', label: 'Zoom in down' },
    { value: 'slide', label: 'Slide' },
    { value: 'horizonatal_slide', label: 'Slide horizontal' },
    { value: 'side_flip_in', label: 'Slide flip in' },
    { value: 'side_flip_back', label: 'Slide flip back' },
    { value: 'flip', label: 'Flip' },
    { value: 'up_flip', label: 'Flip up' },
    { value: 'back_flip', label: 'Flip back' }
  ];

  //position in desktop
  let deskPosition = [
    { value: 'left_top', label: 'Left top' },
    { value: 'center_top', label: 'Center top' },
    { value: 'right_top', label: 'Right top' },
    { value: 'left_center', label: 'Left center' },
    { value: 'right_center', label: 'Right center' },
    { value: 'left_bottom', label: 'Left bottom' },
    { value: 'center_bottom', label: 'Center bottom' },
    { value: 'right_bottom', label: 'Right bottom' }
  ];

  let phonePosition = [
    { value: 'left_top', label: 'Left top' },
    { value: 'center_top', label: 'Center top' },
    { value: 'right_top', label: 'Right top' },
    { value: 'left_center', label: 'Left center' },
    { value: 'center', label: 'Center' },
    { value: 'right_center', label: 'Right center' },
    { value: 'left_bottom', label: 'Left bottom' },
    { value: 'center_bottom', label: 'Center bottom' },
    { value: 'right_bottom', label: 'Right bottom' }
  ];

  let fontFamilyOpts = [
    { value: 'Open sans', label: 'Open Sans' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Poppins', label: 'Poppins' },
    { value: 'Raleway', label: 'Raleway' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Circular Std Book', label: 'Circular Book' },
    { value: ' ', label: 'Website Font' }
  ];

  let fontStyleOpts = [
    { value: 'normal', label: 'Normal' },
    { value: 'bold', label: 'Bold' }
  ];

  let fontSizeOpts = [
    { value: '10', label: '10' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '16', label: '16' },
    { value: '18', label: '18' },
    { value: '20', label: '20' },
    { value: '22', label: '22' },
    { value: '24', label: '24' },
    { value: '26', label: '26' },
    { value: '28', label: '28' },
  ];

  let btnPosition = [
    { value: 'vertical', label: 'Vertical' },
    { value: 'horizontal', label: 'Horizontal' }
  ];

  let borderStyle = [
    { value: 'solid', label: 'Solid' },
    { value: 'dashed', label: 'Dashed' },
    { value: 'dotted', label: 'Dotted' },
    { value: 'double', label: 'Double' }
  ];

  dispatch = useDispatch();
  var style = document.createElement('style');
  document.head.appendChild(style);

  // Handles drag end event for icon field
  const onDragEnd = (result, name, value) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(value);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    handleMainTextChange(name, reorderedItems); //set value in cnf field
  };

  // Function to generate time options
  const generateTimeOptions = (interval = 5) => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += interval) {
        const formattedTime = new Date(0, 0, 0, hour, minutes).toLocaleTimeString('en-US', {
          hour: '2-digit', minute: '2-digit', hour12: true
        });
        options.push({ label: formattedTime, value: formattedTime });
      }
    }
    return options;
  };
  const timeOptions = generateTimeOptions();

  // default select "state.verticalTabsArr"'s 1st element ( 'all_in_one' 1st tab is 'position&animation')
  useEffect(() => {
    if (state.queryNtt === 'all_in_one' || state.queryNtt === 'social_media_followers') {
      changeNameValue({ verticalActive: 'clickactions' });
    }
  }, [state.queryNtt]);

  // set defualt active tab 'Add list' if it showing on widget
  useEffect(() => {
    if (cnf?.ainct) {
      changeNameValue({ verticalActive: 'addlist' });
    }
  }, [cnf?.ainct]);

  // get/set social media account
  const followerMap = {
    facebook: state.fbFwl,
    twitter_follower: state.twtFwl,
    linkedin: state.linkFwl,
    instagram_follower: state.instaFwl,
    youtube_channel: state.ytbFwl,
    twitchtv: state.twhFwl,
    facebookgroupfollowers: state.FbGrpFwl,
    tiktok: state.tiktokFwl
  };

  const getIntiOptions = useCallback((val) => {
    const arrPage = [];
    const data = followerMap[val.inm];
    if (data) {
      data.forEach((data) => {
        arrPage.push({ value: data.pgid.toString(), label: data.name });
      });
    }
    return arrPage;
  }, [state]);

  const handleCollapse = (id) => {
    changeNameValue({ isOpenCards: state.isOpenCards === id ? null : id });
    props.handleArrayEditor(id, cnf?.ainct[id].bgc);  //add bgcolor to quill editor
  }

  // tabs array
  let verticalTabsArr = [
    { label: 'Add list', img: ListBulletedIcon },
    { label: 'Text editor', img: ThemeTemplateIcon },
    { label: 'Click actions', img: CursorIcon },
    { label: 'Position & animation', img: ButtonIcon },
    { label: 'Style', img: EyeCheckMarkIcon },
    { label: 'Data control', img: DatabaseIcon },
    { label: 'Timing control', img: ClockIcon }
  ];

  function getValue(lbl) {
    if (!lbl) return '';
    return lbl.replace(/ /g, '').replace(/^./, char => char.toLowerCase())
  }

  // hide-show tab name conditions
  useEffect(() => {
    verticalTabsArr = verticalTabsArr
      .map(tab => { return { ...tab, value: getValue(tab.label) } })
      .filter(tab => {
        if (tab.label === 'Text editor') {
          return (cnf?.fl || cnf?.sl);
        }
        if (tab.label === 'Position & animation') {
          return notiById?.idisnp !== true;
        }
        if (tab.label === 'Add list') {
          return cnf?.ainct;
        }
        if (tab.label === 'Timing control') {
          return (queryNtt === 'all_in_one' || queryNtt === 'social_media_followers' ||
            queryNtt === 'whatsapp_chat' || queryNtt === 'offer') &&
            cnf?.dtrg !== undefined && (notiById?.icstlp !== undefined || notiById?.idisnp === true)
            || (queryNtt === 'social_media_followers');
        }
        return true;
      });
    changeNameValue({ verticalTabsArr });
  }, [queryNtt, notiById]);


  return (
    <Box padding={400}>

      {state.verticalActive === getValue('Add list') ?
        <div>

          <DragDropContext onDragEnd={(e) => onDragEnd(e, 'ainct', cnf?.ainct)}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <div className='drag_allWrap'
                  ref={provided.innerRef}
                  {...provided.droppableProps}>

                  <BlockStack gap={400}>

                    {cnf?.ainct?.length > 0 && cnf?.ainct.map((val, key) => {
                      const arrPageOpts = getIntiOptions(val);
                      return (
                        <Draggable key={key} draggableId={String(key)} index={key}>
                          {(provided) => (
                            <div className={val.ia === true && val.url !== undefined && val.url === '' ? "ntfError" : ''}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              id={'block_' + key}
                            >
                              <InlineStack align='space-between'>
                                <InlineStack gap={200}>
                                  <Icon tone='base' source={DragHandleIcon} />

                                  <Checkbox
                                    label={<Text id='txt_limit' fontWeight='medium'>{val.ttl ? val.ttl.replace(/<[^>]+>/g, '') : ''}</Text>}
                                    checked={val.ia}
                                    onChange={(e) => handleArrayChange('ia', e, key)}
                                  />
                                </InlineStack>
                                <Button variant='plain' onClick={() => handleCollapse(key)}>
                                  {state.isOpenCards === key ? <Icon source={ChevronDownIcon} tone="base"></Icon> :
                                    <Icon source={ChevronRightIcon} tone="base"></Icon>}
                                </Button>
                              </InlineStack>

                              <Collapsible open={state.isOpenCards === key}
                                transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                                <Box paddingInlineStart={800} paddingBlockStart={200}>
                                  <BlockStack gap={300}>

                                    {queryNtt === 'social_media_followers' &&
                                      <BlockStack gap={100}>
                                        <InlineStack align='space-between'>
                                          <Text>Select social media account*</Text>
                                          <Button variant='plain'
                                            onClick={() => openCloseModal('integrateOpen', state.integrateOpen, 'open')}>Add</Button>
                                        </InlineStack>

                                        <Popover
                                          fullWidth
                                          active={state.isSocialAcc}
                                          onClose={() => changeNameValue({ isSocialAcc: !state.isSocialAcc })}
                                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                            onClick={() => changeNameValue({ isSocialAcc: !state.isSocialAcc })}>
                                            <Text variant='bodyMd' tone='subdued'>
                                              {arrPageOpts.find(({ value }) => value == val.pgid)?.label || 'Select..'}
                                            </Text>
                                          </Button>}>
                                          <OptionList
                                            options={arrPageOpts}
                                            selected={[val.pgid] || ''}
                                            onChange={() => changeNameValue({ isSocialAcc: !state.isSocialAcc })}
                                            onFocusOption={(e) => handleArrayChange('pgid', e, key, val.inm)} />
                                        </Popover>
                                      </BlockStack>
                                    }

                                    {/* show when social media account id is present */}
                                    {val.pgid &&
                                      <BlockStack gap={200}>
                                        <TextField
                                          value={val.url}
                                          autoComplete='off'
                                          placeholder={state.queryNtt === 'whatsapp_chat' ? 'Please enter the phone number' : 'Please enter the url'}
                                          label={<Text>URL</Text>}
                                          onChange={(e) => handleArrayChange('url', e, key)} />
                                        <Checkbox
                                          label={<Tooltip dismissOnMouseOut padding='400' content='Turn off to open the link in a new tab.' width='wide' hasUnderline>
                                            <Text>Open in same tab</Text></Tooltip>}
                                          checked={val.irdsw}
                                          onChange={(e) => handleArrayChange('irdsw', e, key)}
                                        />
                                      </BlockStack>
                                    }

                                    {(val.url !== undefined && queryNtt !== 'social_media_followers') &&
                                      <div>
                                        <Text>
                                          {queryNtt === 'whatsapp_chat' ?
                                            <Tooltip dismissOnMouseOut padding='400' content={"Enter your phone number including the country code, like this: +14844760364"} hasUnderline width='wide'>
                                              <Text>Phone no.*</Text>
                                            </Tooltip>
                                            :
                                            <InlineStack align='space-between' blockAlign='center'>
                                              <Text>URL*</Text>
                                              {queryNtt === 'all_in_one' &&
                                                <Checkbox
                                                  label={<Tooltip dismissOnMouseOut padding='400' content={"Turn off to open the link in a new tab."} hasUnderline width='wide'><Text>Open in same tab</Text></Tooltip>}
                                                  checked={val.irdsw}
                                                  onChange={(e) => handleArrayChange('irdsw', e, key)}
                                                />
                                              }
                                            </InlineStack>
                                          }
                                        </Text>
                                        <TextField
                                          value={val.url}
                                          autoComplete='off'
                                          placeholder={state.queryNtt === 'whatsapp_chat' ? 'Please enter the phone number' : 'Please enter the url'}
                                          onChange={(e) => handleArrayChange('url', e, key)}
                                          error={val.ia === true && !val.url}
                                        />
                                      </div>
                                    }

                                    {/* {queryNtt === 'all_in_one' &&
                                              <Checkbox
                                                label={<Tooltip dismissOnMouseOut padding='400' content={"Turn off to open the link in a new tab."} hasUnderline width='wide'><Text>Open in same tab</Text></Tooltip>}
                                                checked={val.irdsw}
                                                onChange={(e) => handleArrayChange('irdsw', e, key)} />
                                            } */}

                                    {queryNtt !== 'offer' &&
                                      <div>
                                        {queryNtt === 'social_media_followers' ?
                                          <Checkbox
                                            label={<Text>Display social media icon*</Text>}
                                            checked={val.ismflicn}
                                            onChange={(e) => handleArrayChange('ismflicn', e, key)} />
                                          :
                                          <Box paddingBlockEnd={200}><Text>Icon*</Text></Box>
                                        }

                                        <Box paddingBlockEnd={500} paddingInlineStart={700}>
                                          <div className='draggable_wrap'>
                                            <div className="draggable-item">
                                              <Icon tone='base' source={DragHandleIcon} />
                                              <label className='smooth-dnd-active'>
                                                <Text tone='subdued' variant='bodyXs' fontWeight='medium'>Icon</Text>
                                                <img width='30' style={{ background: cnf?.clbg }} src={val.icn} alt="Image" />
                                                <Button id='upldIcn' onClick={() => openIconModal('icn', key)} icon={EditIcon} variant='monochromePlain' />
                                              </label>
                                            </div>
                                          </div>
                                        </Box>
                                      </div>
                                    }

                                    <div>
                                      {queryNtt === 'social_media_followers' ?
                                        <InlineStack blockAlign='end'>
                                          <Checkbox
                                            label={<Text>Display social media title*</Text>}
                                            checked={val.ismflttn}
                                            onChange={(e) => handleArrayChange('ismflttn', e, key)} />
                                          <InlineStack gap={300} align='center'>
                                            <Box width='80%'>
                                              <TextField
                                                autoComplete='off'
                                                value={val.cnttyp}
                                                disabled={!val.ismflttn}
                                                placeholder='Enter the social media title'
                                                onChange={(e) => handleArrayChange('cnttyp', e, key)} />
                                            </Box>
                                            <ColorPick
                                              width='medium'
                                              colorKey="ttlfnclr"
                                              parameter={key}
                                              colorValue={val.ttlfnclr}
                                              functionCall={handleArrayChange} />
                                          </InlineStack>
                                        </InlineStack>
                                        :
                                        <Text>Title*</Text>
                                      }

                                      {(queryNtt === 'whatsapp_chat' || queryNtt === 'offer') ?
                                        <ReactQuill value={val?.ttl}
                                          className={'preview-mail hideEditor ttltxt' + key + ' react_quill_cls_' + key}
                                          modules={modulesQuill} formats={formatsQuill}
                                          onFocus={() => editorFocus('ttltxt' + key)}
                                          onChange={(newValue, delta, source) => {
                                            if (source === 'user') { handleArrayChange('ttl', newValue, key) }
                                          }} />
                                        :
                                        queryNtt !== 'social_media_followers' &&
                                        <InlineStack gap={300} blockAlign='center'>
                                          <Box width='80%'>
                                            <TextField
                                              autoComplete='off'
                                              value={val.ttl}
                                              placeholder='Please enter the title'
                                              onChange={(e) => handleArrayChange('ttl', e, key)} />
                                          </Box>
                                          <ColorPick
                                            width='medium'
                                            colorKey="ttlfnclr"
                                            parameter={key}
                                            colorValue={val.ttlfnclr}
                                            functionCall={handleArrayChange} />
                                        </InlineStack>
                                      }
                                    </div>

                                    {val?.sdsc !== undefined &&
                                      <div>
                                        <Text>Short description</Text>
                                        <ReactQuill value={val.sdsc}
                                          className={'preview-mail hideEditor sdsctxt' + key + ' react_quill_cls_' + key}
                                          modules={modulesQuill} formats={formatsQuill}
                                          onFocus={() => editorFocus('sdsctxt' + key)}
                                          onChange={(newValue, delta, source) => {
                                            if (source === 'user') { handleArrayChange('sdsc', newValue, key) }
                                          }} />
                                      </div>
                                    }

                                    {/* font-family/ style/size/bg-color */}
                                    {queryNtt !== 'whatsapp_chat' && queryNtt !== 'offer' ?
                                      <BlockStack gap={300}>
                                        <InlineGrid columns={2} gap={300}>
                                          <div>
                                            <Box paddingBlockEnd={100}><Text>Font-family</Text></Box>
                                            <Popover
                                              fullWidth
                                              active={state.isCtaFamily2}
                                              onClose={() => changeNameValue({ isCtaFamily2: !state.isCtaFamily2 })}
                                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                                onClick={() => changeNameValue({ isCtaFamily2: !state.isCtaFamily2 })}>
                                                <Text variant='bodyMd' tone='subdued'>
                                                  {fontFamilyOpts.find(({ value }) => value === val.ttlfnfml)?.label || 'Select'}
                                                </Text>
                                              </Button>}>
                                              <OptionList
                                                options={fontFamilyOpts}
                                                selected={[val.ttlfnfml]}
                                                onChange={() => changeNameValue({ isCtaFamily2: !state.isCtaFamily2 })}
                                                onFocusOption={(e) => handleArrayChange('ttlfnfml', e, key)} />
                                            </Popover>
                                          </div>

                                          <div>
                                            <Box paddingBlockEnd={100}><Text>Font-weight</Text></Box>
                                            <Popover
                                              fullWidth
                                              active={state.isCtaStyle2}
                                              onClose={() => changeNameValue({ isCtaStyle2: !state.isCtaStyle2 })}
                                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                                onClick={() => changeNameValue({ isCtaStyle2: !state.isCtaStyle2 })}>
                                                <Text variant='bodyMd' tone='subdued'>
                                                  {fontStyleOpts.find(({ value }) => value === val.ttlfnwght)?.label || 'Select'}
                                                </Text>
                                              </Button>}>
                                              <OptionList
                                                options={fontStyleOpts}
                                                selected={[val.ttlfnwght]}
                                                onChange={() => changeNameValue({ isCtaStyle2: !state.isCtaStyle2 })}
                                                onFocusOption={(e) => handleArrayChange('ttlfnwght', e, key)} />
                                            </Popover>
                                          </div>
                                        </InlineGrid>
                                        <InlineGrid columns={2} gap={300}>

                                          <BlockStack gap={100}>
                                            <Text>Font-size</Text>
                                            <Popover
                                              fullWidth
                                              active={state.isAinctFSize}
                                              onClose={() => changeNameValue({ isAinctFSize: !state.isAinctFSize })}
                                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                                onClick={() => changeNameValue({ isAinctFSize: !state.isAinctFSize })}>
                                                <Text variant='bodyMd' tone='subdued'>
                                                  {fontSizeOpts.find(({ value }) => value == val.ttlfnsz)?.label || 'Select'}
                                                </Text>
                                              </Button>}>
                                              <OptionList
                                                options={fontSizeOpts}
                                                selected={[val.ttlfnsz]}
                                                onChange={() => changeNameValue({ isAinctFSize: !state.isAinctFSize })}
                                                onFocusOption={(e) => handleArrayChange('ttlfnsz', e, key)} />
                                            </Popover>
                                          </BlockStack>
                                          <ColorPick
                                            labelName='Background color'
                                            colorKey="bgc"
                                            parameter={key}
                                            colorValue={val.bgc}
                                            functionCall={handleArrayChange} />
                                        </InlineGrid>
                                      </BlockStack>
                                      :
                                      <ColorPick
                                        labelName='Background color'
                                        colorKey="bgc"
                                        parameter={key}
                                        colorValue={val.bgc}
                                        functionCall={handleArrayChange} />
                                    }

                                    {val.ldsc !== undefined && queryNtt === 'whatsapp_chat' &&
                                      <div>
                                        <InlineStack align='space-between'>
                                          <Text tone='subdued'>Message*</Text>
                                          <Button variant='plain'
                                            onClick={() => handleArrayChange('ldsc', val.ldsc, key)}>Add page URL</Button>
                                        </InlineStack>
                                        <TextField
                                          value={val.ldsc}
                                          autoComplete='off'
                                          placeholder='Please enter the message'
                                          onChange={(e) => handleArrayChange('ldsc', e, key)} />
                                      </div>
                                    }

                                    {val.idlt !== false &&
                                      <InlineStack align='end'>
                                        <Button variant='plain' onClick={() => deletefieldinArw(key)}>
                                          <Text tone='critical'>Delete</Text></Button>
                                      </InlineStack>
                                    }

                                  </BlockStack>
                                </Box>
                              </Collapsible>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}

                  </BlockStack>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

          </DragDropContext>

          {/* add item cta */}
          {queryNtt !== 'social_media_followers' &&
            <Box paddingBlockStart={300}>
              <Button id='greenClr' variant='plain' icon={PlusIcon}
                onClick={() => AddfieldinArw()}><Text fontWeight='medium'>Add item</Text></Button>
            </Box>
          }

        </div>
        :
        state.verticalActive === getValue('Text editor') ?
          <div>

            <Text fontWeight='medium'>Title*</Text>
            <Box paddingInlineStart={100} paddingBlockStart={0}>
              <ReactQuill value={cnf?.fl?.txt}
                className={`preview-mail hideEditor ${state.queryNtt === 'offer' ? 'offertxt' : 'ttltxt'}`}
                modules={modulesQuill}
                formats={formatsQuill}
                onFocus={() => editorFocus(state.queryNtt === 'offer' ? 'offertxt' : 'ttltxt')}
                onBlur={() => editorBlur(state.queryNtt === 'offer' ? 'offertxt' : 'ttltxt')}
                onChange={(newValue, delta, source) => {
                  if (source === 'user') { handleTextChange('fl', 'txt', newValue) }
                }} />
            </Box>

            <Box paddingBlockStart={300}>
              <Text fontWeight='medium'>Short description*</Text>
              <Box paddingInlineStart={100} paddingBlockStart={0}>
                <ReactQuill value={cnf?.sl?.txt} className='preview-mail hideEditor sdsctxt'
                  modules={modulesQuill}
                  formats={formatsQuill}
                  onFocus={() => editorFocus('sdsctxt')}
                  onBlur={() => editorBlur('sdsctxt')}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') { handleTextChange('sl', 'txt', newValue) }
                  }} />
              </Box>
            </Box>

          </div>
          :
          state.verticalActive === getValue('Click actions') ?
            <div>

              {(cnf?.iu !== undefined || cnf?.ibg !== undefined) &&
                <BlockStack gap={300}>

                  {cnf?.iimg !== undefined ?
                    <Checkbox
                      label={<Text fontWeight='medium'>CTA appearance</Text>}
                      checked={cnf?.iimg}
                      onChange={(e) => handleMainTextChange('iimg', e)} />
                    :
                    <Box paddingBlockEnd={200}><Text fontWeight={'medium'}>CTA configuration</Text></Box>
                  }

                  {defaultNotif?.iit === true && cnf?.it !== undefined &&
                    <Box paddingBlockEnd={800}>
                      <DragDropContext onDragEnd={(e) => onDragEnd(e, 'it', cnf?.it)}>
                        <Droppable droppableId="droppable" direction="horizontal">
                          {(provided) => (
                            <div className='draggable_wrap'
                              ref={provided.innerRef}
                              {...provided.droppableProps}>

                              {cnf?.it.map((item, index) => (
                                <Draggable key={item} draggableId={String(item)} index={index}>
                                  {(provided) => (
                                    <div className="draggable-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Icon tone='base' source={DragHandleIcon} />
                                      {item === 0 ?
                                        <label onClick={() => changeNameValue({ iconTab: 0 })}
                                          className={iconTab === 0 ? 'smooth-dnd-active' : ''}>
                                          <Text tone='subdued' variant='bodyXs' fontWeight='medium'>Icon</Text>
                                          <img width='30' style={{ background: cnf?.ibg }} src={cnf?.iu} alt="Image" />
                                          <Button id='upldIcn' onClick={() => openIconModal('iu')} icon={EditIcon} variant='monochromePlain' />
                                        </label>
                                        : item === 1 ?
                                          <label onClick={() => changeNameValue({ iconTab: 1 })}
                                            className={iconTab === 1 ? 'smooth-dnd-active' : ''}>
                                            <Text tone='subdued' variant='bodyXs' fontWeight='medium'>Map</Text>
                                            <img width='30' src={'https://storage.googleapis.com/wiser_goal/notif_icon/icomap.png'} alt="Image" />
                                          </label>
                                          : item === 2 ?
                                            <label onClick={() => changeNameValue({ iconTab: 2 })}
                                              className={iconTab === 2 ? 'smooth-dnd-active' : ''}>
                                              <Text tone='subdued' variant='bodyXs' fontWeight='medium'>Dynamic</Text>
                                              <img width='30' src={'https://storage.googleapis.com/wiser_goal/notif_icon/product-icon.png'} alt="Image" />
                                            </label>
                                            : item === 3 ?
                                              <label onClick={() => changeNameValue({ iconTab: 3 })}
                                                className={iconTab === 3 ? 'smooth-dnd-active' : ''}>
                                                <Text tone='subdued' variant='bodyXs' fontWeight='medium'>Gravatar</Text>
                                                <img width='30' src={'https://storage.googleapis.com/wiserimg/template_icon/user.png'} alt="Image" />
                                              </label>
                                              : item === 4 &&
                                              <label onClick={() => changeNameValue({ iconTab: 4 })}
                                                className={iconTab === 4 ? 'smooth-dnd-active' : ''}>
                                                <Text tone='subdued' variant='bodyXs' fontWeight='medium'>Flag</Text>
                                                <img width='30' src={'https://storage.googleapis.com/wiserimg/template_icon/flag_icon.svg?q=12'} alt="Image" />
                                              </label>
                                      }
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>

                      </DragDropContext>
                    </Box>
                  }

                  <InlineGrid gap={300} columns={2}>
                    {cnf?.ibg &&
                      <ColorPick
                        labelName='CTA background color'
                        width='medium'
                        colorKey="ibg"
                        colorValue={cnf?.ibg || '#fff'}
                        functionCall={handleMainTextChange} />
                    }
                    <ColorPick
                      labelName='Close icon color'
                      width='medium'
                      colorKey="cbg"
                      colorValue={cnf?.cbg || '#fff'}
                      functionCall={handleMainTextChange} />
                  </InlineGrid>

                  {defaultNotif?.ttn === 'all_in_one' &&
                    <Checkbox
                      label={<Text>Close on item click</Text>}
                      checked={cnf?.iclspp}
                      onChange={(e) => handleMainTextChange('iclspp', e)} />
                  }
                </BlockStack>
              }

              {cnf?.cb !== undefined &&
                <div>
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding='400' content='Choose color of notification close icon' width='wide' hasUnderline>
                      <Text>Close icon</Text></Tooltip>}
                    checked={cnf?.cb}
                    onChange={(e) => handleMainTextChange('cb', e)}
                  />
                  <Box paddingInlineStart={700}>
                    <ColorPick
                      width='medium'
                      colorKey="cbg"
                      colorValue={cnf?.cbg || '#fff'}
                      functionCall={handleMainTextChange} />
                  </Box>
                </div>
              }

              {/* cta label & link */}
              {cnf?.cl &&
                <div>
                  {(queryNtt !== 'lottery' && queryNtt !== 'offer' && defaultNotif?.ttn !== 'call_back_2') ?
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding='400' content='Customise CTA label and add redirecting URL' width='wide' hasUnderline>
                        <Text fontWeight='medium'>Button & link name</Text></Tooltip>}
                      checked={cnf?.ica}
                      onChange={(e) => handleMainTextChange('ica', e)} />
                    :
                    <Box paddingBlockEnd={200}>
                      <Tooltip dismissOnMouseOut padding='400' content='Customise CTA label and add redirecting URL' width='wide' hasUnderline><Text fontWeight={'medium'}>Button & link name</Text></Tooltip>
                    </Box>
                  }

                  <Box paddingInlineStart={300}>
                    <BlockStack gap={300}>
                      <TextField
                        autoComplete='off'
                        placeholder='Label name'
                        value={cnf?.cl?.txt}
                        onChange={(e) => handleTextChange('cl', 'txt', e)} />

                      <InlineGrid columns={2} gap={200}>
                        <div>
                          <Box paddingBlockEnd={100}><Text>Font-family</Text></Box>
                          <Popover
                            fullWidth
                            active={state.isCtaFamily}
                            onClose={() => changeNameValue({ isCtaFamily: !state.isCtaFamily })}
                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                              onClick={() => changeNameValue({ isCtaFamily: !state.isCtaFamily })}>
                              <Text variant='bodyMd' tone='subdued'>
                                {fontFamilyOpts.find(({ value }) => value === cnf?.cl?.fml)?.label || 'Select'}
                              </Text>
                            </Button>}>
                            <OptionList
                              options={fontFamilyOpts}
                              selected={[cnf?.cl?.fml]}
                              onChange={() => changeNameValue({ isCtaFamily: !state.isCtaFamily })}
                              onFocusOption={(e) => handleTextChange('cl', 'fml', e)} />
                          </Popover>
                        </div>

                        <div>
                          <Box paddingBlockEnd={100}><Text>Font-weight</Text></Box>
                          <Popover
                            fullWidth
                            active={state.isCtaStyle}
                            onClose={() => changeNameValue({ isCtaStyle: !state.isCtaStyle })}
                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                              onClick={() => changeNameValue({ isCtaStyle: !state.isCtaStyle })}>
                              <Text variant='bodyMd' tone='subdued'>
                                {fontStyleOpts.find(({ value }) => value === cnf?.cl?.stl)?.label || 'Select'}
                              </Text>
                            </Button>}>
                            <OptionList
                              options={fontStyleOpts}
                              selected={[cnf?.cl?.stl]}
                              onChange={() => changeNameValue({ isCtaStyle: !state.isCtaStyle })}
                              onFocusOption={(e) => handleTextChange('cl', 'stl', e)} />
                          </Popover>
                        </div>
                      </InlineGrid>

                      <InlineGrid columns={2} gap={200}>
                        {cnf?.cl?.sz !== undefined && queryNtt === 'custom' &&
                          <div>
                            <Box paddingBlockEnd={100}><Text>Font-size</Text></Box>
                            <Popover
                              fullWidth
                              active={state.isCtaFsize}
                              onClose={() => changeNameValue({ isCtaFsize: !state.isCtaFsize })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isCtaFsize: !state.isCtaFsize })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {fontSizeOpts.find(({ value }) => value == cnf?.cl?.sz)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={fontSizeOpts}
                                selected={[cnf?.cl?.sz.toString()]}
                                onChange={() => changeNameValue({ isCtaFsize: !state.isCtaFsize })}
                                onFocusOption={(e) => handleTextChange('cl', 'sz', e)} />
                            </Popover>
                          </div>
                        }

                        <ColorPick
                          labelName='Text color'
                          colorKey="cl"
                          secondKey="clr"
                          colorValue={cnf?.cl?.clr}
                          functionCall={handleTextChange} />
                        {cnf?.clbg &&
                          <ColorPick
                            labelName='Background color'
                            colorKey="clbg"
                            colorValue={cnf?.clbg}
                            functionCall={handleMainTextChange} />
                        }
                      </InlineGrid>

                      {/* cta icon upload */}
                      {(defaultNotif?.ttn === 'call_back_1' || defaultNotif?.ttn === 'call_back_2' ||
                        defaultNotif?.ttn === 'offer_1') &&
                        <div>
                          <Box paddingBlockEnd={200}>
                            <Checkbox
                              label={<Tooltip dismissOnMouseOut padding='400' content='Enable it to show only CTA button icon' width='wide' hasUnderline>
                                <Text>Show only CTA button icon</Text></Tooltip>}
                              checked={cnf?.icbicn}
                              onChange={(e) => handleMainTextChange('icbicn', e)} />
                          </Box>
                          <Text>CTA button icon</Text>
                          <Box paddingBlockStart={200} paddingBlockEnd={500}>
                            <div className='draggable_wrap'>
                              <div className="draggable-item">
                                <Icon tone='base' source={DragHandleIcon} />
                                <label className='smooth-dnd-active'>
                                  <Text tone='subdued' variant='bodyXs' fontWeight='medium'>Icon</Text>
                                  <img width='30' style={{ background: cnf?.clbg, padding: '3px' }} src={cnf?.cticn} alt="Image" />
                                  <Button id='upldIcn' onClick={() => openIconModal('cticn')} icon={EditIcon} variant='monochromePlain' />
                                </label>
                              </div>
                            </div>
                          </Box>
                        </div>
                      }

                    </BlockStack>
                  </Box>
                </div>
              }

            </div>
            :
            state.verticalActive === getValue('Position & animation') ?
              <div>
                <Tooltip dismissOnMouseOut padding='400' content='Set notification position & its animation style in desktop & mobile device' hasUnderline width='wide'><Text fontWeight='medium'>Position & animation</Text></Tooltip>

                <Box paddingBlockStart={300}>
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding="400" content='Enable to show the notification in mobile device' width='wide' hasUnderline><Text>Show in mobile</Text></Tooltip>}
                    checked={!notiById?.hm}
                    onChange={(e) => handleSettingChange('hm', e)}
                  />
                  <Box paddingInlineStart={600}>
                    {/* mobile animation style */}
                    {cnf?.mas !== undefined &&
                      <Box paddingBlockStart={100}>
                        <Tooltip dismissOnMouseOut padding="400" width='wide' hasUnderline content='Select animation style for notification entry & exit in mobile'><Text tone={'subdued'}>Animation style in mobile</Text></Tooltip>
                        <Box paddingBlockStart={100}>
                          {!notiById?.hm !== true || notiById?.idisnp ?
                            <TextField value={phoneAni.find(({ value }) => value === cnf?.mas)?.label || 'Select'}
                              disabled />
                            :
                            <Popover
                              fullWidth
                              active={state.isAniMobi}
                              onClose={() => changeNameValue({ isAniMobi: !state.isAniMobi })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isAniMobi: !state.isAniMobi })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {phoneAni.find(({ value }) => value === cnf?.mas)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={phoneAni}
                                selected={[cnf?.mas]}
                                onChange={() => changeNameValue({ isAniMobi: !state.isAniMobi })}
                                onFocusOption={(e) => handleMainTextChange('mas', e)} />
                            </Popover>
                          }
                        </Box>
                      </Box>
                    }
                    <Box paddingBlockStart={150}> {/* mobile position */}
                      <Text tone='subdued'>Display position in mobile</Text>

                      <Box paddingBlockStart={100}>
                        {!notiById?.hm !== true || notiById?.idisnp ?
                          <TextField value={phonePosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'} disabled />
                          :
                          <Popover
                            fullWidth
                            active={state.isPosMobi}
                            onClose={() => changeNameValue({ isPosMobi: !state.isPosMobi })}
                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                              onClick={() => changeNameValue({ isPosMobi: !state.isPosMobi })}>
                              <Text variant='bodyMd' tone='subdued'>
                                {phonePosition.find(({ value }) => value === cnf?.mdp)?.label || 'Select'}
                              </Text>
                            </Button>}>
                            <OptionList
                              options={phonePosition}
                              selected={[cnf?.mdp]}
                              onChange={() => changeNameValue({ isPosMobi: !state.isPosMobi })}
                              onFocusOption={(e) => handleMainTextChange('mdp', e)} />
                          </Popover>
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box paddingBlockStart={400}>
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding="400" width='wide' content='Enable to show the notification on Desktop' hasUnderline><Text>Show in desktop</Text></Tooltip>}
                    checked={notiById?.isdsk}
                    onChange={(e) => handleSettingChange('isdsk', e)}
                  />
                  <Box paddingInlineStart={600}>
                    {cnf?.as !== undefined &&  //animation
                      <Box paddingBlockStart={100}>
                        <Tooltip dismissOnMouseOut padding="400" content='Select animation style for notification entry & exit in desktop screen' width='wide' hasUnderline><Text tone={'subdued'}>Animation style in desktop</Text></Tooltip>
                        <Box paddingBlockStart={'100'}>
                          {notiById?.isdsk !== true || notiById?.idisnp ?
                            <TextField value={deskAni.find(({ value }) => value === cnf?.as)?.label || 'Select'}
                              disabled />
                            :
                            <Popover
                              fullWidth
                              active={state.isAniDesk}
                              onClose={() => changeNameValue({ isAniDesk: !state.isAniDesk })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isAniDesk: !state.isAniDesk })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {deskAni.find(({ value }) => value === cnf?.as)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={deskAni}
                                selected={[cnf?.as]}
                                onChange={() => changeNameValue({ isAniDesk: !state.isAniDesk })}
                                onFocusOption={(e) => handleMainTextChange('as', e)} />
                            </Popover>
                          }
                        </Box>
                      </Box>
                    }
                    {cnf?.dp !== undefined &&  //position
                      <Box paddingBlockStart={150}>
                        <Tooltip dismissOnMouseOut padding="400" content='Select notification display position in desktop screen' width='wide' hasUnderline><Text tone={'subdued'}>Display position in desktop</Text></Tooltip>
                        <Box paddingBlockStart={'100'}>
                          {notiById?.isdsk !== true ?
                            <TextField value={deskPosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'}
                              disabled={!notiById?.isdsk} />
                            :
                            <Popover
                              fullWidth
                              active={state.isPosDesk}
                              onClose={() => changeNameValue({ isPosDesk: !state.isPosDesk })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isPosDesk: !state.isPosDesk })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {deskPosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={deskPosition}
                                selected={[cnf?.dp]}
                                onChange={() => changeNameValue({ isPosDesk: !state.isPosDesk })}
                                onFocusOption={(e) => handleMainTextChange('dp', e)} />
                            </Popover>
                          }
                        </Box>
                      </Box>
                    }
                    {cnf?.cbdal !== undefined &&  //button alignment
                      <Box paddingBlockStart={100}>
                        <Text tone='subdued'>Call back button display alignment</Text>
                        <Box paddingBlockStart={'100'}>
                          {notiById?.isdsk !== true || notiById?.idisnp ?
                            <TextField value={btnPosition.find(({ value }) => value === cnf?.cbdal)?.label || 'Select'}
                              disabled />
                            :
                            <Popover
                              fullWidth
                              active={state.isDsplyAlign}
                              onClose={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {btnPosition.find(({ value }) => value === cnf?.cbdal)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={btnPosition}
                                selected={[cnf?.cbdal]}
                                onChange={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}
                                onFocusOption={(e) => handleMainTextChange('cbdal', e)} />
                            </Popover>
                          }
                        </Box>
                      </Box>
                    }
                  </Box>
                </Box>
              </div>
              :
              state.verticalActive === getValue('Style') ?
                <BlockStack gap={400}>

                  {/* notification shape */}
                  {cnf?.r !== undefined &&
                    <div>
                      <Text fontWeight='medium'>Shape</Text>
                      <Box paddingInlineStart={400} paddingBlockStart={150}>

                        <InlineStack blockAlign='center' align='space-between'>
                          <Box width='80%'>
                            <RangeSlider
                              min={0}
                              max={50}
                              value={cnf?.r || 0}
                              onChange={(e) => handleMainTextChange('r', e)}
                              label={<Text>Radius</Text>}
                              output />
                          </Box>
                          <ColorPick
                            width='medium'
                            colorKey="bg"
                            colorValue={cnf?.bg}
                            functionCall={handleMainTextChange}
                          />
                        </InlineStack>
                      </Box>
                    </div>
                  }

                  {/* border size & style */}
                  {(cnf?.rc !== undefined || cnf?.rs !== undefined || cnf?.rw !== undefined || cnf?.r !== undefined) &&
                    <div>
                      <Text fontWeight='medium'>Border</Text>
                      <Box paddingInlineStart={400} paddingBlockStart={150}>
                        {cnf?.rw !== undefined &&
                          <InlineStack blockAlign='center' align='space-between'>
                            <Box width='80%'>
                              <RangeSlider
                                max={6}
                                value={cnf?.rw || 0}
                                onChange={(e) => handleMainTextChange('rw', e)}
                                label={<Text>Size</Text>}
                                output />
                            </Box>
                            <ColorPick
                              width='medium'
                              colorKey="rc"
                              colorValue={cnf?.rc}
                              functionCall={handleMainTextChange}
                            />
                          </InlineStack>
                        }
                        {cnf?.rs !== undefined &&
                          <Box paddingBlockStart={200}>
                            <Text>Style</Text>
                            <Box paddingBlockStart={100}>
                              <Popover
                                fullWidth
                                active={state.isBrderStyle}
                                onClose={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {borderStyle.find(({ value }) => value === cnf?.rs)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={borderStyle}
                                  selected={[cnf?.rs] || []}
                                  onChange={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}
                                  onFocusOption={(e) => handleMainTextChange('rs', e)} />
                              </Popover>
                            </Box>
                          </Box>
                        }
                      </Box>
                    </div>
                  }

                  {/* shadow */}
                  <div>
                    <Text fontWeight='medium'>Shadow</Text>

                    <Box paddingInlineStart={400} paddingBlockStart={150}>
                      <BlockStack gap={200}>
                        <InlineStack blockAlign='center' align='space-between'>
                          <Box width='80%'>
                            <RangeSlider
                              step={2}
                              max={50}
                              value={cnf?.shdbr || 0}
                              onChange={(e) => handleMainTextChange('shdbr', e)}
                              label={<Text>Effect</Text>}
                              output />
                          </Box>
                          <ColorPick
                            width='medium'
                            colorKey="shdclr"
                            colorValue={cnf?.shdclr}
                            functionCall={handleMainTextChange}
                          />
                        </InlineStack>
                        <RangeSlider
                          min={-10}
                          max={10}
                          value={cnf?.shdho || 0}
                          onChange={(e) => handleMainTextChange('shdho', e)}
                          label={<Text>Horizontal</Text>}
                          output />
                        <RangeSlider
                          min={-10}
                          max={10}
                          value={cnf?.shdvo || 0}
                          onChange={(e) => handleMainTextChange('shdvo', e)}
                          label={<Text>Vertical</Text>}
                          output />
                      </BlockStack>
                    </Box>
                  </div>

                </BlockStack>
                :
                state.verticalActive === getValue('Data control') ?
                  <div>

                    <Text fontWeight='medium'>Data history</Text>
                    <Box paddingBlockStart={300}>
                      <Checkbox
                        label={<Tooltip dismissOnMouseOut padding='400' content='Enable it to skip widget for specific time interval' width='wide' hasUnderline><Text>Enable it to skip seen widgets</Text></Tooltip>}
                        checked={notiById?.isnutd}
                        onChange={(e) => handleSettingChange('isnutd', e)}
                      />
                      <Box paddingInlineStart={700} paddingBlockStart={100}>
                        <BlockStack gap={'050'}>
                          <Tooltip dismissOnMouseOut padding='400' content='Don’t show same notification for selected time duration' width='wide' hasUnderline><Text tone='subdued'>Set time duration</Text></Tooltip>

                          {notiById?.isnutd !== true ?
                            <TextField value={skipNotifTime.find(({ value }) => value == notiById?.snutd)?.label || 'Select'} disabled />
                            :
                            <Popover
                              fullWidth
                              active={state.isDurTime}
                              onClose={() => changeNameValue({ isDurTime: !state.isDurTime })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isDurTime: !state.isDurTime })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {skipNotifTime.find(({ value }) => value == notiById?.snutd)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={skipNotifTime}
                                selected={[notiById?.snutd.toString()]}
                                onChange={() => changeNameValue({ isDurTime: !state.isDurTime })}
                                onFocusOption={(e) => handleSettingChange('snutd', e ? Number(e) : '')} />
                            </Popover>
                          }
                        </BlockStack>
                      </Box>
                    </Box>

                  </div>
                  :
                  state.verticalActive === getValue('Timing control') &&
                  <div>
                    <Text fontWeight='medium'>Timing rules</Text>

                    {queryNtt === 'social_media_followers' &&
                      <div>
                        <Box paddingBlockStart={300} paddingBlockEnd={100}>
                          <Checkbox
                            label={<Tooltip dismissOnMouseOut padding="400" content='Turn it ON to display widgets in loop' width='wide' hasUnderline><Text>Display in loop</Text></Tooltip>}
                            checked={notiById?.icstlp}
                            onChange={(e) => handleSettingChange('icstlp', e)} />
                        </Box>

                        {/* show on display in loop true && if 'display in loop' not available show this by default */}
                        {notiById?.icstlp === true &&
                          <Box paddingBlockStart={'050'} paddingInlineStart={700}>
                            <BlockStack gap={300}>

                              {/* hide if HTML snippet toogle on */}
                              {!notiById?.idisnp &&
                                (queryNtt !== 'live_visitor_ecomm_snippet' &&
                                  queryNtt !== 'purchase_bulk_ecomm_snippet' &&
                                  queryNtt !== 'purchase_recent_ecomm_snippet' &&
                                  queryNtt !== 'limited_stock_ecomm_snippet') &&
                                <BlockStack gap={100}>
                                  <Tooltip dismissOnMouseOut padding="400" content='Set delay to start showing first notification' width='wide' hasUnderline><Text tone='subdued'>Initial delay time</Text></Tooltip>
                                  <Popover
                                    fullWidth
                                    active={state.isInitDelay}
                                    onClose={() => changeNameValue({ isInitDelay: !state.isInitDelay })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isInitDelay: !state.isInitDelay })}>
                                      <Text variant='bodyMd' tone='subdued'>
                                        {notifTiming.find(({ value }) => value == notiById?.intd)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={notifTiming}
                                      selected={[notiById?.intd?.toString()]}
                                      onChange={() => changeNameValue({ isInitDelay: !state.isInitDelay })}
                                      onFocusOption={(e) => handleSettingChange('intd', e)} />
                                  </Popover>
                                </BlockStack>
                              }

                              <BlockStack gap={100}>
                                <Tooltip dismissOnMouseOut padding="400" content='Set time duration for displaying notifications' width='wide' hasUnderline><Text tone='subdued'>Display notification time</Text></Tooltip>
                                <Popover
                                  fullWidth
                                  active={state.isDsplyNtf}
                                  onClose={() => changeNameValue({ isDsplyNtf: !state.isDsplyNtf })}
                                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                    onClick={() => changeNameValue({ isDsplyNtf: !state.isDsplyNtf })}>
                                    <Text variant='bodyMd' tone='subdued'>
                                      {notifTiming.find(({ value }) => value == notiById?.dsnt)?.label || 'Select'}
                                    </Text>
                                  </Button>}>
                                  <OptionList
                                    options={notifTiming}
                                    selected={[notiById?.dsnt?.toString()]}
                                    onChange={() => changeNameValue({ isDsplyNtf: !state.isDsplyNtf })}
                                    onFocusOption={(e) => handleSettingChange('dsnt', e)} />
                                </Popover>
                              </BlockStack>

                              {/* hide if HTML snippet toogle on */}
                              {!notiById?.idisnp &&
                                (queryNtt !== 'live_visitor_ecomm_snippet' &&
                                  queryNtt !== 'purchase_bulk_ecomm_snippet' &&
                                  queryNtt !== 'purchase_recent_ecomm_snippet' &&
                                  queryNtt !== 'limited_stock_ecomm_snippet') &&
                                <BlockStack gap={100}>
                                  <Tooltip dismissOnMouseOut padding="400" content='Set time difference between two notifications' width='wide' hasUnderline><Text tone='subdued'>Apply fix delay time between two notification</Text></Tooltip>

                                  {notiById?.rndnt !== false ?  //for disabled popover
                                    <TextField disabled value={delayNotifTime.find(({ value }) => value == notiById?.dlynt)?.label || 'Select'} />
                                    :
                                    <Popover
                                      fullWidth
                                      active={state.isApplyFix}
                                      onClose={() => changeNameValue({ isApplyFix: !state.isApplyFix })}
                                      activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                        onClick={() => changeNameValue({ isApplyFix: !state.isApplyFix })}>
                                        <Text variant='bodyMd' tone='subdued'>
                                          {delayNotifTime.find(({ value }) => value == notiById?.dlynt)?.label || 'Select'}
                                        </Text>
                                      </Button>}>
                                      <OptionList
                                        options={delayNotifTime}
                                        selected={[notiById?.dlynt?.toString()]}
                                        onChange={() => changeNameValue({ isApplyFix: !state.isApplyFix })}
                                        onFocusOption={(e) => handleSettingChange('dlynt', e)} />
                                    </Popover>
                                  }
                                </BlockStack>
                              }

                              {!notiById?.idisnp &&
                                (queryNtt !== 'live_visitor_ecomm_snippet' &&
                                  queryNtt !== 'purchase_bulk_ecomm_snippet' &&
                                  queryNtt !== 'purchase_recent_ecomm_snippet' &&
                                  queryNtt !== 'limited_stock_ecomm_snippet') &&
                                <Box paddingBlockEnd={200}>
                                  <Checkbox
                                    label={<Tooltip dismissOnMouseOut padding="400" content='Enable this to set random time difference between two notifications' width='wide' hasUnderline><Text tone='subdued'>Randomize delay</Text></Tooltip>}
                                    checked={notiById?.rndnt}
                                    onChange={(e) => handleSettingChange('rndnt', e)} />
                                </Box>
                              }
                            </BlockStack>
                          </Box>
                        }
                      </div>
                    }

                    {/* show on Display in loop false */}
                    {(cnf?.dtrg !== undefined && (notiById?.icstlp !== true || notiById?.idisnp === true)) &&
                      <div>
                        {queryNtt === 'social_media_followers' &&
                          <Box paddingBlockStart={300}><Text fontWeight='medium'>Behaviour rule</Text></Box>
                        }
                        <Box paddingBlockStart={300} paddingInlineStart={300}>
                          <BlockStack gap={300}>

                            {isAbtest === false &&
                              <BlockStack gap={300}>
                                <TextField
                                  type="datetime-local"
                                  label={<Tooltip dismissOnMouseOut padding='400' content='Widgets will start displaying from this date' width='wide' hasUnderline><Text>Select start date</Text></Tooltip>}
                                  value={notiById?.strtdt ? formateDate(notiById?.strtdt) : ''}
                                  onChange={(e) => handleSettingChange('strtdt',
                                    new Date(e.toLocaleString('en-IN', dateFormatOpt)))}
                                />
                                <TextField
                                  type="datetime-local"
                                  label={<Tooltip dismissOnMouseOut padding='400' content='Widgets will not be displayed after this date' width='wide' hasUnderline><Text>Select expiry date</Text></Tooltip>}
                                  value={notiById?.expdt ? formateDate(notiById?.expdt) : ''}
                                  onChange={(e) => handleSettingChange('expdt',
                                    new Date(e.toLocaleString('en-IN', dateFormatOpt)))}
                                />
                                <Box paddingBlockStart={200}>
                                  <Checkbox
                                    label={<Text>Schedule on specific days of week</Text>}
                                    checked={cnf?.irptdy}
                                    onChange={(e) => handleMainTextChange('irptdy', e)}
                                  />
                                  {cnf?.irptdy === true &&
                                    <Box paddingBlockStart={100}>
                                      <BlockStack gap={300}>
                                        <Popover
                                          fullWidth
                                          active={state.isTimeStmp}
                                          onClose={() => changeNameValue({ isTimeStmp: !state.isTimeStmp })}
                                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure
                                            onClick={() => changeNameValue({ isTimeStmp: !state.isTimeStmp })}>
                                            <Text variant='bodyMd' tone='subdued'>
                                              {cnf?.arrptdy?.map(value => weekOpts.find(option => option.value === value)?.label).filter(Boolean).join(', ') || 'Select'}
                                            </Text>
                                          </Button>}>
                                          <Box padding={300}>
                                            <ChoiceList
                                              allowMultiple
                                              choices={weekOpts}
                                              selected={cnf?.arrptdy || []}
                                              onChange={(e) => handleMainTextChange('arrptdy', e)} />
                                          </Box>
                                        </Popover>

                                        <InlineGrid columns={2} gap={300}>
                                          <Popover
                                            fullWidth
                                            active={state.isStrtDt}
                                            onClose={() => changeNameValue({ isStrtDt: !state.isStrtDt })}
                                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                              onClick={() => changeNameValue({ isStrtDt: !state.isStrtDt })}>
                                              <Text variant='bodyMd' tone='subdued'>
                                                {cnf?.rptstm ? timeOptions.find(({ value }) => value === cnf?.rptstm)?.label
                                                  : 'Select'}
                                              </Text>
                                            </Button>}>
                                            <OptionList
                                              options={timeOptions}
                                              selected={[cnf?.rptstm] || []}
                                              onChange={() => changeNameValue({ isStrtDt: !state.isStrtDt })}
                                              onFocusOption={(e) => handleMainTextChange('rptstm', e)} />
                                          </Popover>

                                          <Popover
                                            fullWidth
                                            active={state.isEndDt}
                                            onClose={() => changeNameValue({ isEndDt: !state.isEndDt })}
                                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                              onClick={() => changeNameValue({ isEndDt: !state.isEndDt })}>
                                              <Text variant='bodyMd' tone='subdued'>
                                                {cnf?.rptetm ? timeOptions.find(({ value }) => value === cnf?.rptetm)?.label : 'Select'}
                                              </Text>
                                            </Button>}>
                                            <OptionList
                                              options={timeOptions}
                                              selected={[cnf?.rptetm] || []}
                                              onChange={() => changeNameValue({ isEndDt: !state.isEndDt })}
                                              onFocusOption={(e) => handleMainTextChange('rptetm', e)} />
                                          </Popover>
                                        </InlineGrid>
                                      </BlockStack>
                                    </Box>
                                  }
                                </Box>
                              </BlockStack>
                            }

                            {/* display rule */}
                            <Box paddingBlockStart={200}>
                              <Box paddingBlockEnd={100}><Text>Display rule</Text></Box>
                              <Popover
                                fullWidth
                                active={state.isDsplyRule}
                                onClose={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {dsplyRuleOpts.find(({ value }) => value == cnf?.dtrg)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={dsplyRuleOpts}
                                  selected={[cnf?.dtrg] || []}
                                  onChange={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}
                                  onFocusOption={(e) => handleMainTextChange('dtrg', e)} />
                              </Popover>
                            </Box>

                            {cnf?.dtrg === 2 &&
                              <div>
                                <TextField
                                  type='Number'
                                  placeholder='sec'
                                  value={cnf?.aft}
                                  onChange={(e) => handleMainTextChange('aft', e ? Number(e) : '')}
                                  error={errMessage?.minimumCount ? errMessage.minimumCount : false} />
                                {designValidator.message('minimumCount', cnf?.aft, 'required|Positive numbers: numeric|min:1,num')}
                              </div>
                            }
                            {cnf?.dtrg === 3 &&
                              <div>
                                <TextField
                                  type='Number'
                                  placeholder='%'
                                  value={cnf?.sprc}
                                  onChange={(e) => handleMainTextChange('sprc', e ? Number(e) : '')}
                                  error={errMessage?.afterScroll ? errMessage.afterScroll : false} />
                                {designValidator.message('afterScroll', cnf?.sprc, 'required')}
                              </div>
                            }

                            {/* copy widget function */}
                            <Box paddingBlockStart={200}>
                              <Checkbox
                                label={<Tooltip dismissOnMouseOut padding='400' content={`It looks like: <button onClick='fnOnclickShowWidget("${notiById?._id}")'> Click </button>`} width='wide' hasUnderline><Text>Show widget using javascript</Text></Tooltip>}
                                checked={cnf?.ishntfclk}
                                onChange={(e) => handleMainTextChange('ishntfclk', e)} />

                              {cnf?.ishntfclk === true &&
                                <Box paddingInlineStart={700} paddingBlockStart={'050'}>
                                  <BlockStack gap={100}>
                                    <InlineStack align='space-between'>
                                      <Text tone='subdued'>Call this function on custom event</Text>
                                      <Button variant='plain'
                                        onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_js_fn')}>Copy</Button>
                                    </InlineStack>
                                    <div onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_js_fn')}>
                                      <TextField id='wdgt_js_fn' value={`fnShowWidgetOnAnyEvent("{${notifId}}")`} />
                                    </div>
                                  </BlockStack>
                                </Box>
                              }
                            </Box>

                            {/* hide rule */}
                            <div>
                              <Checkbox
                                checked={cnf?.ihdwn}
                                label={<Text>Hide after {cnf?.hdwn ? cnf?.hdwn : 'x'} second</Text>}
                                onChange={(e) => handleMainTextChange('ihdwn', e)}
                              />
                              {cnf?.ihdwn === true &&
                                <Box paddingInlineStart={700}>
                                  <TextField
                                    type='Number'
                                    placeholder='Hide second'
                                    value={cnf?.hdwn}
                                    onChange={(e) => handleMainTextChange('hdwn', e ? Number(e) : '')}
                                    error={errMessage.HideSecond || errMessage.MinimumCount || false} />
                                  {designValidator.message('HideSecond', cnf?.hdwn, 'required')}
                                  {designValidator.message('MinimumCount', cnf?.hdwn, 'Positive numbers: numeric|min:1,num')}
                                </Box>
                              }
                            </div>

                          </BlockStack>
                        </Box>
                      </div>
                    }
                  </div>
      }
    </Box >
  );
}

export default AllinoneCard;