import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, IndexTable, Page, Text, InlineStack, Tooltip, TextField
} from '@shopify/polaris';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { toastify } from '../../ducks/toast';
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function ThinkificCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();

  //state data.
  const [info, setInfo] = useState({
    Thinkific: [],
    curl: '',
    ia: false,//for toggle on/off
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //Thinkific data from api
  const getThinkificData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ Thinkific: getThinkificData });
  }, [getThinkificData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.Thinkific.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ Thinkific: updatedList })
  };

  //login with Thinkific

  const loginWithThinkific = () => {
    let formattedCurl = info.curl.trim();

    if (formattedCurl.startsWith('https://') || formattedCurl.endsWith('.thinkific.com')) {
      dispatch(toastify({ type: 'error', msg: "Invalid URL!" }));
      return;
    }

    if (!formattedCurl.startsWith('https://')) {
      formattedCurl = 'https://' + formattedCurl;
    }

    if (!formattedCurl.endsWith('.thinkific.com')) {
      formattedCurl += '.thinkific.com';
    }

    let redirect_uri = 'https://api.wisernotify.com';
    if (window.location.host === 'localhost:3002') {
      redirect_uri = 'http://localhost:8001';
    }

    let cURL = '{SUBDOMAIN}/oauth2/authorize?' +
      'client_id=b78000b59796a451c61ba3e93d0f174f' +
      '&redirect_uri={REDIRECT_URI}/api/thinkific/oauth' +
      '&response_mode=query' +
      '&response_type=code id_token' +
      '&state=' + localStorage.getItem('authToken') +
      '&scope=write:site_scripts openid profile email site';

    cURL = cURL.replace('{REDIRECT_URI}', redirect_uri);
    cURL = cURL.replace('{SUBDOMAIN}', formattedCurl);

    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, cURL);
    }
    else {
      window.open(cURL, '_self');
    }
  }


  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* Thinkific data*/}
        <Card>
          <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
            <div className='form-width'>
              <InlineStack blockAlign="center" >
                <div className="textfeild-lable">
                  <Tooltip content={state.tl?.curl} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                    <Text variant="headingSm" as="h6">Enter Subdomain</Text>
                  </Tooltip>
                </div>
                <div className="textfeild-box">
                  <TextField
                    type="text"
                    value={info.curl}
                    onChange={(e) => changeNameValueCard({ curl: e })}
                    placeholder='Enter Subdomain'
                    prefix="https://"
                    suffix=".thinkific.com"
                    autoComplete="off"
                  />
                </div>
              </InlineStack>
            </div>
          </Box>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <Box align='center' padding={300}><Button onClick={() => loginWithThinkific()} disabled={info.curl ? false : true} variant="primary" tone="success">Login With Thinkific</Button></Box>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.Thinkific?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Store URL' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                ]}
                selectable={false}
              >
                {info.Thinkific && info.Thinkific.length > 0 && info.Thinkific.map((i, index) => {
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell><span>{i.d.su}</span></IndexTable.Cell>
                        <IndexTable.Cell><span>{i.rmk}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>

                      </IndexTable.Row>
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>
    </Page >
  )
}

export default ThinkificCard;