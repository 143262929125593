import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/goal';
import { toastify } from '../ducks/toast';

function* getGoalDataById(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getGoalbyIdES?rt=' + action.payload.rt + '&fDate=' + action.payload.fDate + '&tDate=' + action.payload.tDate);

    if (res.status === 'success') {
      yield put(actions.getGoalDataByIdSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createNewGoal(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createGoal', action.payload)

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getGoalDataById(global.getGoalObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.getGoalDataById(global.getGoalObj));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGoal(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/delGoalByid?_id=' + action.payload + '')

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getGoalDataById(global.getGoalObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.getGoalDataById(global.getGoalObj));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateGoal(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateGoal', action.payload)

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getGoalDataById(global.getGoalObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.getGoalDataById(global.getGoalObj));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetGoal() {
  yield takeLatest(actions.getGoalDataById.type, getGoalDataById);
}

export function* watchAddGoal() {
  yield takeLatest(actions.createNewGoal.type, createNewGoal);
}

export function* watchDeleteGoal() {
  yield takeLatest(actions.deleteGoal.type, deleteGoal);
}

export function* watchUpdateGoal() {
  yield takeLatest(actions.updateGoal.type, updateGoal);
}

export default function* rootSaga() {
  yield fork(watchGetGoal);
  yield fork(watchAddGoal);
  yield fork(watchDeleteGoal);
  yield fork(watchUpdateGoal);
}


