import { BlockStack, Box, Button, Card, Icon, InlineGrid, InlineStack, Scrollable, Text, TextField, Thumbnail } from '@shopify/polaris'
import { SearchIcon } from '@shopify/polaris-icons'

function BoardingStep2({ props }) {

  let { state, changeNameValue, handleRoleChange, handleSearch, handleSelectApp } = props

  return (
    <Card>
      <BlockStack gap={400}>
        <BlockStack gap={200}>
          <Text as='h3' id='step2_Title'>Tell us your site builder & marketing stack  </Text>
          <Text as='p' variant='bodyLg'> We’ll use this recommend the notifications & guides to automate the social proof & FOMO marketing</Text>
        </BlockStack>
        <TextField
          type="text"
          onChange={(e) => handleSearch(e)}
          value={state.searchApp}
          prefix={<Icon
            source={SearchIcon}
            tone="base"
          />}
          autoComplete="off"
          placeholder='Search 300+ apps '
        />
        <div className='onbording-integre-box'>
          <Scrollable
            style={{ height: '400px' }}
            // focusable
            scrollbarGutter="stable"
            scrollbarWidth="thin"
          >
            <InlineGrid columns={{ xl: 4, lg: 4, md: 3, sm: 1 }} gap={600}>
              {
                state.filteredAppList && state.filteredAppList.map((data, i) => {
                  let selected_Box = state.selectedApps.includes(data.dn)
                  return (
                    data.dn !== 'Other' ?
                      <Box onClick={(e) => handleSelectApp(e, data.dn)} id={selected_Box ? 'selected_App' : 'defult'} borderWidth="025" borderRadius='100' key={i}>
                        <Box paddingBlock={400} paddingInlineStart={400}>
                          <InlineStack gap={200} blockAlign='center'>
                            <Thumbnail
                              source={data.img}
                              size="extraSmall"
                              alt={data.dn}
                            />
                            <Text as='h6' variant='bodyMd'>{data.dn}</Text>
                          </InlineStack>
                        </Box>
                      </Box>
                      :
                      // display other integration box
                      <div style={{ padding: '16px', borderRadius: '4px' }} onClick={(e) => handleSelectApp(e, 'Other')}
                        id={state.selectedApps.includes('Other') ? 'selected_App' : 'defult'}
                      >
                        <Text as='h6' variant='bodyLg'>Other</Text>
                        {state.selectedApps && state.selectedApps.includes('Other') &&
                          <div style={{ paddingRight: '10px', paddingTop: '5px' }}>
                            <TextField
                              type="text"
                              onChange={(value) => changeNameValue({ 'otherApp': value })}
                              value={state.otherApp}
                              placeholder='Enter application platform'
                            />
                          </div>
                        }
                      </div>
                  )
                })
              }
            </InlineGrid>
          </Scrollable>
        </div>
      </BlockStack>
      <div style={{ marginTop: '65px' }}>
        <InlineStack align='end' blockAlign='center'>
          <Button id='continu_Btn' variant='primary' onClick={() => handleRoleChange()}>Continue</Button>
        </InlineStack>
      </div>
    </Card>
  )
}

export default BoardingStep2