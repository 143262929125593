import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/agency';
import { toastify } from '../ducks/toast';

function* getAgency(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getWhiteLabel', action.payload);

    if (res.status === 'success') {
      yield put(actions.getAgencySuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

// Settings upadte
function* addUpdateWhiteLabel(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdateWhiteLabel', action.payload);

    if (res.status === 'update') {
      yield put(toastify({ type: 'success', msg: res.m }));
      if (!action.payload._id) {
        yield put(actions.getAgency());
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//upload logo (base64)
function* updWhiteLabelLogo(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updWhiteLabelLogo', action.payload);
    if (res.status === 'success') {
      yield put(actions.getAgency());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to upadte logo' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

// Dashboard URL update*
function* addUpdateCname(action) {
  try {
    yield put(load());

    const res = yield call(api.POST, '/addUpdateCname', action.payload);
    if (res.status === 'update') {
      yield put(toastify({ type: 'success', msg: res.m }));
      if (!action.payload._id) {
        yield put(actions.getAgency());
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//getSubUser api call
function* getSubUser(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getSubUser', action.payload);

    if (res.status === 'success') {
      yield put(actions.getSubUserSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//getWhitelabelPlan api call
function* getWhitelabelPlan() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getWhitelabelPlan');

    if (res.status === 'success') {
      yield put(actions.getWhitelabelPlanSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get plan' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//for checkbox update
function* updStatusPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updStatusPlan', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getWhitelabelPlan());
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update plan' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//add new plan api
function* addUpdatePlan(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdatePlan', action.payload);

    if (res.status === 'success' || res.status === 'update') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getWhitelabelPlan());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//delete plan api
function* deleteWhitelabelPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteWhitelabelPlan', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getWhitelabelPlan());
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete plan' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//getSubPlan api call
function* getSubPlan() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getSubPlan');

    if (res.status === 'success') {
      yield put(actions.getSubPlanSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get sub plan' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//addUpdateSubUser api call
function* addUpdateSubUser(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdateSubUser', action.payload);

    if (res.status === 'success' || res.status === 'update') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getSubUser());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//deleteSubUser api for delete sub user
function* deleteSubUser(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteSubUser', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getSubUser());
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete sub user' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//user password update
function* updSubUserPWD(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updSubUserPWD', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update sub user password' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetAgency() {
  yield takeLatest(actions.getAgency.type, getAgency);
  yield takeLatest(actions.addUpdateWhiteLabel.type, addUpdateWhiteLabel);
  yield takeLatest(actions.updWhiteLabelLogo.type, updWhiteLabelLogo);
  yield takeLatest(actions.addUpdateCname.type, addUpdateCname);
  yield takeLatest(actions.getSubUser.type, getSubUser);
  yield takeLatest(actions.getWhitelabelPlan.type, getWhitelabelPlan);
  yield takeLatest(actions.updStatusPlan.type, updStatusPlan);
  yield takeLatest(actions.addUpdatePlan.type, addUpdatePlan);
  yield takeLatest(actions.deleteWhitelabelPlan.type, deleteWhitelabelPlan);
  yield takeLatest(actions.getSubPlan.type, getSubPlan);
  yield takeLatest(actions.addUpdateSubUser.type, addUpdateSubUser);
  yield takeLatest(actions.deleteSubUser.type, deleteSubUser);
  yield takeLatest(actions.updSubUserPWD.type, updSubUserPWD);
}

export default function* rootSaga() {
  yield fork(watchGetAgency);
}
