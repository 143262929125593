import { useState, useCallback } from 'react';
import { ColorPicker, Popover, InlineStack, Text, TextField, Box } from '@shopify/polaris';

const ColorPick = (props) => {
  const rgb_num = 255, hue_num = 360, sv_num = 100;

  const { colorKey, secondKey, colorValue, functionCall, parameter, width, height, labelName, html } = props;

  const [colorPopActive, setPopActive] = useState(false);

  const toggleActive = useCallback(() =>
    setPopActive((colorPopActive) => !colorPopActive), []
  );

  const handleTextChange = (colorKey, secondKey, value) => {
    value = value.replace(/[^A-Fa-f0-9#]/g);

    if (!value.includes('undefined')) {
      if (secondKey !== undefined)
        functionCall([colorKey], secondKey, value);
      else if (parameter !== undefined)
        functionCall([colorKey], value, parameter);
      else
        functionCall([colorKey], value);
    }
  }

  const handlePickerChange = (colorKey, secondKey, value) => {
    if (!value) value = {};

    let h = value.hue || 0;
    let s = value.saturation * 100 || 0;
    let v = value.brightness * 100 || 0;
    let hex = hsbToHex(h, s, v);

    if (secondKey !== undefined)
      functionCall([colorKey], secondKey, hex);
    else if (parameter !== undefined)
      functionCall([colorKey], hex, parameter);
    else
      functionCall([colorKey], hex);
  };

  const convertHsb = (color) => {
    if (!color) color = "#000000";

    let { h, s, v } = hexToHsb(color);
    return { hue: h, saturation: s / 100, brightness: v / 100 };
  };

  function hexToHsb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let obj = result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : {};

    let { r, g, b } = obj;

    if (typeof r === 'object') {
      const args = r;
      r = args.r; g = args.g; b = args.b;
    }

    // It converts [0,255] format, to [0,1]
    r = (r === rgb_num) ? 1 : (r % rgb_num / parseFloat(rgb_num));
    g = (g === rgb_num) ? 1 : (g % rgb_num / parseFloat(rgb_num));
    b = (b === rgb_num) ? 1 : (b % rgb_num / parseFloat(rgb_num));

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    let h, s, v = max;
    let d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max === min)
      h = 0;
    else {
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }

    return {
      h: Math.round(h * hue_num),
      s: Math.round(s * sv_num),
      v: Math.round(v * sv_num)
    };
  }

  function hsbToHex(h, s, v) {
    if (typeof h === 'object') {
      const args = h;
      h = args.h; s = args.s; v = args.v;
    }

    h = (h % 360 + 360) % 360;
    h = (h === hue_num) ? 1 : (h % hue_num / parseFloat(hue_num) * 6);
    s = (s === sv_num) ? 1 : (s % sv_num / parseFloat(sv_num));
    v = (v === sv_num) ? 1 : (v % sv_num / parseFloat(sv_num));

    let i = Math.floor(h);
    let f = h - i;
    let p = v * (1 - s);
    let q = v * (1 - f * s);
    let t = v * (1 - (1 - f) * s);
    let mod = i % 6;
    let r = [v, q, p, p, t, v][mod];
    let g = [t, v, v, q, p, p][mod];
    let b = [p, p, t, v, v, q][mod];

    r = Math.floor(r * rgb_num);
    g = Math.floor(g * rgb_num);
    b = Math.floor(b * rgb_num);

    if (typeof r === 'object') {
      const args = r;
      r = args.r; g = args.g; b = args.b;
    }

    r = Math.round(r).toString(16);
    g = Math.round(g).toString(16);
    b = Math.round(b).toString(16);

    r = r.length === 1 ? '0' + r : r;
    g = g.length === 1 ? '0' + g : g;
    b = b.length === 1 ? '0' + b : b;

    return '#' + r + g + b;
  }

  return (
    <div>
      <Box paddingBlockEnd={100}> {/* Label name */}
        {typeof labelName !== 'string' ? labelName : <Text> {labelName}</Text>}
      </Box>

      {/* popup onclick */}
      <Popover
        active={colorPopActive}
        onClose={toggleActive}
        activator={ //onclick open picker
          <div onClick={toggleActive} style={{ background: colorValue, borderRadius: '8px', border: !html && '1px solid #8080804a' }}>
            {html ? html :
              width === 'medium' ?
                <Box paddingInline={400} paddingBlock={400}></Box>
                :
                <Box paddingInline={width || 1800} paddingBlock={height || 400}></Box>
            }
          </div>}
      >
        {/* ColorPicker */}
        <Box paddingBlock={400} paddingInlineStart={400}>
          <ColorPicker onChange={(e) => handlePickerChange(colorKey, secondKey, e)} color={convertHsb(colorValue)} />
          <InlineStack>
            <Box paddingBlockStart={200} maxWidth='93%'>
              <TextField
                type="text"
                value={colorValue}
                onChange={(e) => handleTextChange(colorKey, secondKey, e)}
              />
            </Box>
          </InlineStack>
        </Box>
      </Popover>
    </div>
  );
};

export default ColorPick;
