import React, { useState } from 'react';
import { Badge, Banner, BlockStack, Box, Button, ButtonGroup, Card, Checkbox, DatePicker, Divider, Icon, IndexFilters, IndexTable, InlineStack, Modal, Page, PageActions, Popover, RadioButton, Select, Text, TextField, Tooltip, useBreakpoints, useSetIndexFiltersMode } from '@shopify/polaris';
import { BulletIcon, DeleteIcon, EditIcon, PlusIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import DesignCard from '../NotifEdit/DesignCard';
import moment from 'moment';
import { checkPermission } from '../../services/permissions';
let newObjAbtestNotif = [];
let ttlEng = 0;
let cnt = 0;
let topVrnt = '';
let ActiveVrnt = 0;

function AbTestCard({ props }) {
  const { state, changeNameValue, openCloseModal } = props;

  const { mode, setMode } = useSetIndexFiltersMode();
  const { mdDown, lgUp } = useBreakpoints();

  const [activeDateRange, setActiveDateRange] = useState({
    since: new Date(new Date().setHours(0, 0, 0, 0)),
    until: new Date(new Date().setHours(0, 0, 0, 0))
  });

  const [{ month, year }, setDate] = useState({
    month: activeDateRange.since.getMonth(),
    year: activeDateRange.since.getFullYear()
  });

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.since.toDateString(),
      tdt: state.until.toDateString()
    }
    changeNameValue(obj);
  }

  const handleCalendarChange = ({ start, end }) => {
    const newDateRange = { since: start, until: end };

    changeNameValue({
      frmdt: newDateRange.since.toDateString(),
      tdt: newDateRange.until.toDateString()
    });
    activeRangeChange(newDateRange);
  };

  const checkValidDate = (dateString) => {
    if (dateString && !dateString.includes("Invalid")) {
      return true;
    } else {
      return false;
    }
  }

  // for custom date popup open (same tab click, event is not coming)
  if (document.getElementById("Custom Date-3")) {
    document.getElementById("Custom Date-3").onclick = function () {
      changeNameValue({ popoverActive: true });
    };
  }

  const getHeaderNm = (ttl, desc) => {
    return <Tooltip dismissOnMouseOut width='wide' content={<span>{desc}</span>}>
      <span id='cursorPointer'>{ttl}</span>
    </Tooltip>;
  }

  const renderListHTML = (state, data) => {
    return Parser(DesignCard(state, data, 'abTest'));
  }

  //set newObjAbtestNotif data.
  if (state.objAbtestNotif) {
    newObjAbtestNotif = state.objAbtestNotif;
    newObjAbtestNotif = JSON.stringify(state.objAbtestNotif);
    newObjAbtestNotif = JSON.parse(newObjAbtestNotif);
  }

  //for count the active variant
  if (newObjAbtestNotif.vrnt && newObjAbtestNotif.vrnt.length > 0) {
    for (let p = 0; p < newObjAbtestNotif.vrnt.length; p++) {
      if (newObjAbtestNotif.vrnt[p].ia === true) {
        ActiveVrnt += 1;
      }
    }
  }
  //count the active main notif.
  if (newObjAbtestNotif.idltfrmvrnt !== true && newObjAbtestNotif.iafab) {
    ActiveVrnt += 1;
  }

  //notif not run then display
  newObjAbtestNotif.perCnt = 0;
  newObjAbtestNotif.im = 0;
  newObjAbtestNotif.hv = 0;
  newObjAbtestNotif.clk = 0;
  newObjAbtestNotif.cls = 0;
  newObjAbtestNotif.eng = 0;
  newObjAbtestNotif.ctr = 0;
  newObjAbtestNotif.cta = 0;

  //no data found in variant
  if (newObjAbtestNotif.vrnt) {
    for (let k = 0; k < newObjAbtestNotif.vrnt.length; k++) {
      newObjAbtestNotif.vrnt[k].perCnt = 0;
      newObjAbtestNotif.vrnt[k].im = 0;
      newObjAbtestNotif.vrnt[k].hv = 0;
      newObjAbtestNotif.vrnt[k].clk = 0;
      newObjAbtestNotif.vrnt[k].cls = 0;
      newObjAbtestNotif.vrnt[k].eng = 0;
      newObjAbtestNotif.vrnt[k].ctr = 0;
      newObjAbtestNotif.vrnt[k].cta = 0;
    }
  }

  if (state.abAnalyticEs && state.abAnalyticEs.data.length > 0) {
    if (newObjAbtestNotif) {
      //sum of all variant hover and click
      for (let k = 0; k < state.abAnalyticEs.data.length; k++) {
        ttlEng += state.abAnalyticEs.data[k].hv + state.abAnalyticEs.data[k].clk;
      }
      for (let i = 0; i < state.abAnalyticEs.data.length; i++) {
        let objEng = state.abAnalyticEs.data[i];
        let eng = objEng.hv + objEng.clk;
        if (!objEng.vrntid) {
          newObjAbtestNotif.perCnt = ttlEng ? (eng / ttlEng * 100).toFixed(2) : 0;
          newObjAbtestNotif.im = objEng.im;
          newObjAbtestNotif.hv = objEng.hv;
          newObjAbtestNotif.clk = objEng.clk;
          newObjAbtestNotif.cls = objEng.cls;
          newObjAbtestNotif.eng = eng;
          newObjAbtestNotif.cta = objEng.cta;
          newObjAbtestNotif.ctr = objEng.im ? (objEng.clk * 100 / objEng.im).toFixed(2) : 0;
          //.for default notif.
          cnt = newObjAbtestNotif.perCnt;
          topVrnt = newObjAbtestNotif._id;
        }
        if (newObjAbtestNotif.vrnt) {
          for (let j = 0; j < newObjAbtestNotif.vrnt.length; j++) {
            let objVrnt = newObjAbtestNotif.vrnt[j];
            if (objVrnt._id === objEng.vrntid) {
              newObjAbtestNotif.vrnt[j].perCnt = ttlEng ? (eng / ttlEng * 100).toFixed(2) : 0;
              newObjAbtestNotif.vrnt[j].im = objEng.im;
              newObjAbtestNotif.vrnt[j].hv = objEng.hv;
              newObjAbtestNotif.vrnt[j].clk = objEng.clk;
              newObjAbtestNotif.vrnt[j].cls = objEng.cls;
              newObjAbtestNotif.vrnt[j].eng = eng;
              newObjAbtestNotif.vrnt[j].cta = objEng.cta;
              newObjAbtestNotif.vrnt[j].ctr = objEng.im ? (objEng.clk * 100 / objEng.im).toFixed(2) : 0;
            }
            //for highlights top count        
            //.for varinat           
            if (Number(cnt) < Number(newObjAbtestNotif.vrnt[j].perCnt)) {
              cnt = newObjAbtestNotif.vrnt[j].perCnt;
              topVrnt = objVrnt._id;
            }
          }
        }
      }
    }
  }

  //function for set min-height of table if user has selected custom date
  const newTestFunction = (e) => {
    setTimeout(() => {
      let customStyle = document.getElementById('ab-test-dynamic-style');

      if (document.getElementById('notif_cstm_dtPop') && document.getElementById('notif_cstm_dtPop').style.display === 'block') {
        if (!customStyle) {
          customStyle = document.createElement('style');
          customStyle.id = 'ab-test-dynamic-style';
          customStyle.textContent = `
            #ab_test_tbl .Polaris-IndexTable-ScrollContainer {
              min-height: 350px;
            }`;
          document.head.appendChild(customStyle);
        }
      }
      else {
        if (customStyle) {
          customStyle.remove();
        }
      }
    }, 10);

  }

  return (
    <Page
      title={`A/B testing (${newObjAbtestNotif.nn})`}
      backAction={{ onAction: () => props.handleBackToAbtest() }}
      primaryAction={checkPermission('notification', 'isadd', state.profile) && <Button variant="primary" icon={PlusIcon} onClick={() => { openCloseModal('crtVarntOpen', state.crtVarntOpen, 'open'); changeNameValue({ popoverActive: false }); }}>Add variant</Button>}>
      <BlockStack gap={400}>
        {/* <Badge progress="complete" tone="success" size='large'>Total: {state?.abAnalyticEs && state?.abAnalyticEs?.uqv} Visitors, Divided equally to each variant</Badge> */}
        <Card padding={0}>
          <Banner tone="success" icon={BulletIcon}><Text>Total: {state?.abAnalyticEs && state?.abAnalyticEs?.uqv ? state?.abAnalyticEs?.uqv : 0} visitors, divided equally to each variant</Text></Banner>
        </Card>
        <Box paddingBlockEnd={400}>
          <Card padding={0}>
            <div id='ab_filter'>
              <IndexFilters
                tabs={props.abTestTabs}
                canCreateNewView={false}
                selected={state.selctdAbtest}
                onSelect={(e) => { changeNameValue({ selctdAbtest: e }); newTestFunction(e); }}
                mode={mode}
                setMode={setMode}
              />
            </div>

            {/* custom date popup */}
            <div className='notif_cstm_dtPop' id='notif_cstm_dtPop' style={{ display: state.popoverActive ? 'block' : 'none' }}>
              <Box maxWidth='580px'>
                <Card padding={200}>
                  <Popover.Pane>
                    <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                      <InlineStack gap="400">
                        <DatePicker
                          month={month}
                          year={year}
                          selected={{
                            start: activeDateRange.since,
                            end: activeDateRange.until,
                          }}
                          onMonthChange={(month, year) => setDate({ month, year })}
                          onChange={handleCalendarChange}
                          disableDatesBefore={new Date(new Date().setHours(0, 0, 0, 0) - 61 * 24 * 60 * 60 * 1000)}
                          disableDatesAfter={new Date()}
                          multiMonth={lgUp}
                          allowRange />
                      </InlineStack>
                    </Box>
                  </Popover.Pane>

                  <Divider />
                  <Box padding={200}>
                    <InlineStack gap={600} align="end" blockAlign='center'>
                      <Text>
                        {(checkValidDate(state.frmdt) && checkValidDate(state.tdt))
                          ? state.frmdt + " - " + state.tdt : ''}
                      </Text>
                      <ButtonGroup>
                        <Button variant='tertiary' onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                        <Button variant='primary' onClick={() => props.handleSelectCustomDate()}>Apply</Button>
                      </ButtonGroup>
                    </InlineStack>
                  </Box>

                </Card>
              </Box>
            </div>

            <div id='ab_test_tbl'>
              <IndexTable
                itemCount={state?.objAbtestNotif?.vrnt?.length + 1}
                selectable={false}
                headings={[
                  { title: 'Preview' },
                  { title: 'Name' },
                  { title: getHeaderNm('Impressions', 'Number of times notification is shown') },
                  { title: getHeaderNm('Hovers', 'Number of times visitors hovered around the notification') },
                  { title: getHeaderNm('Clicked', 'Number of clicks on notifications') },
                  { title: getHeaderNm('Engage', 'Number of times visitor engaged with notification') },
                  { title: getHeaderNm('CTA', 'CTA is the number of times when user clicked on Call to Action button') },
                  { title: getHeaderNm('Performance', 'Check rate of engagement') },
                  { title: 'Actions' }
                ]}
              // pagination={{
              //   hasPrevious: state.paggiActive > 1 ? true : false,
              //   hasNext: (state.paggiActive < state.pageNumber) ? true : false,
              //   onPrevious: () => props.onPaggiCall('-1'),
              //   onNext: () => props.onPaggiCall('+1')
              // }}
              >
                {/* for default notif. */}
                {
                  newObjAbtestNotif.idltfrmvrnt !== true &&

                  <IndexTable.Row
                    id={1}
                    key={1}
                    position={1}
                  >
                    <IndexTable.Cell id='ab_nTf_wrap'>
                      <div className={`ab_nTf_hTMl wn_${newObjAbtestNotif._id}`}>
                        {newObjAbtestNotif.nt === 'title_bar' ?
                          <div className="FDGdflt26_WeBTitLeBar_fdhKMN FDGdflt26_WeBTitLeBar_TiTlEbaRTem01">
                            <div className="FDGdflt26_ToPhEaDer_FirstBlock">
                              <div className="FDGdflt26_WeBTitLeBar_BlocK">
                                <div className="FDGdflt26_WeBTitLeBar_imG">
                                  <img id={`ImgURL_${newObjAbtestNotif._id}`} alt='' />
                                </div>
                                <div className="FDGdflt26_WeBTitLeBar_LefTBlock">
                                  <div className="FDGdflt26_WeBTitLeBar_TexT" id={`TitleTxt_${newObjAbtestNotif._id}`}>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          newObjAbtestNotif.nt === 'video' ? //video notification
                            <div className="notilist-notivideo">
                              {renderListHTML(state, newObjAbtestNotif)}
                            </div>
                            :
                            newObjAbtestNotif.nt === 'call_to_action' ? //call_to_action notification
                              <div className="notilist-callback">
                                {renderListHTML(state, newObjAbtestNotif)}
                              </div>
                              :
                              newObjAbtestNotif.nt === 'collector' ?
                                <div className="notilist-promotional">
                                  {renderListHTML(state, newObjAbtestNotif)}
                                </div>
                                :
                                newObjAbtestNotif.nt === 'social_media_followers' ?
                                  <div className="notilist-promotional">
                                    {renderListHTML(state, newObjAbtestNotif)}
                                  </div>
                                  :
                                  newObjAbtestNotif.nt === 'all_in_one' ? //All in one
                                    <div className="notilist_allinone">
                                      {renderListHTML(state, newObjAbtestNotif)}
                                    </div>
                                    :
                                    renderListHTML(state, newObjAbtestNotif)
                        }

                      </div>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <Text id='txt_limit' variant="headingXs" fontWeight="semibold" as="p">{newObjAbtestNotif.rttl}</Text>
                      <Box paddingBlockStart={200} paddingBlockEnd={200}>
                        <BlockStack gap="200" align="start">
                          <InlineStack gap={200} blockAlign='center'>
                            <Text variant="headingMd" as="h6" fontWeight="medium" >
                              {newObjAbtestNotif.nn}
                            </Text>
                          </InlineStack>
                          <div>
                            <Tooltip dismissOnMouseOut content={<BlockStack><Text>Created on: {moment(new Date(newObjAbtestNotif.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</Text><Text>Last modified: {moment(newObjAbtestNotif.udt ? new Date(newObjAbtestNotif.udt) : new Date()).format('MMM-DD-YYYY hh:mm:ss a')}</Text></BlockStack>} width='wide'>
                              <Text variant="bodyXs" tone='subdued' fontWeight="medium" as="p" >
                                {moment(newObjAbtestNotif.udt ? new Date(newObjAbtestNotif.udt) : new Date()).format('MMM-DD-YYYY')}
                              </Text>
                            </Tooltip>
                          </div>
                        </BlockStack>
                      </Box>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text variant='headingMd' as='p' fontWeight='regular'>{newObjAbtestNotif.im ? `${newObjAbtestNotif.im}` : 0}</Text>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <Text variant='headingMd' as='p' fontWeight='regular'>{newObjAbtestNotif.hv ? `${newObjAbtestNotif.hv}` : 0}</Text>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <Text variant='headingMd' as='p' fontWeight='regular'>{newObjAbtestNotif.clk ? `${newObjAbtestNotif.clk}` : 0}</Text>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <Text variant='headingMd' as='p' fontWeight='regular'>{newObjAbtestNotif.eng ? `${newObjAbtestNotif.eng}` : 0}</Text>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <Text variant='headingMd' as='p' fontWeight='regular'>{newObjAbtestNotif.cta ? `${newObjAbtestNotif.cta}` : 0}</Text>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <BlockStack align='center' inlineAlign='center'>
                        <InlineStack gap={200}>
                          <Text variant='headingMd' as='p' fontWeight='regular'>{newObjAbtestNotif.perCnt ? newObjAbtestNotif.perCnt : 0} %</Text>
                          {topVrnt === newObjAbtestNotif._id &&
                            <Box>
                              <Badge tone='success'>Best</Badge>
                            </Box>
                          }
                        </InlineStack>
                        <RadioButton
                          label={newObjAbtestNotif.iornl ? 'Primary' : 'Set Primary'}
                          value={newObjAbtestNotif._id}
                          checked={newObjAbtestNotif.iornl}
                          onChange={() => props.handlerbtnChange('current', newObjAbtestNotif._id)}
                        />
                      </BlockStack>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <ButtonGroup noWrap>
                        <label className="switch">
                          <input type="checkbox" checked={newObjAbtestNotif.ia}
                            onChange={() => props.handleUpdAbTestNotifStatus(newObjAbtestNotif)} disabled={newObjAbtestNotif.iornl} />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                        {checkPermission('notification', 'isedit', state.profile) &&
                          <div onClick={() => props.handleEditNotif(newObjAbtestNotif, 'ab_test', 'current')} id='cursorPointer'>
                            <Icon source={EditIcon} />
                          </div>
                        }
                        {checkPermission('notification', 'isdelete', state.profile) && newObjAbtestNotif.iornl !== true &&
                          <div id='cursorPointer' onClick={() => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ defaultAbtest: true }) }}>
                            <Icon source={DeleteIcon} />
                          </div>
                        }
                      </ButtonGroup>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                }

                {newObjAbtestNotif.vrnt && newObjAbtestNotif.vrnt.length > 0 && newObjAbtestNotif.vrnt.map((data, index) => {
                  return (
                    <IndexTable.Row
                      id={index}
                      key={index}
                      position={index}
                    >
                      <IndexTable.Cell id='ab_nTf_wrap'>
                        <div className={`ab_nTf_hTMl wn_${data._id}`}>
                          {data.nt === 'title_bar' ?
                            <div className="FDGdflt26_WeBTitLeBar_fdhKMN FDGdflt26_WeBTitLeBar_TiTlEbaRTem01">
                              <div className="FDGdflt26_ToPhEaDer_FirstBlock">
                                <div className="FDGdflt26_WeBTitLeBar_BlocK">
                                  <div className="FDGdflt26_WeBTitLeBar_imG">
                                    <img id={`ImgURL_${data._id}`} alt='' />
                                  </div>
                                  <div className="FDGdflt26_WeBTitLeBar_LefTBlock">
                                    <div className="FDGdflt26_WeBTitLeBar_TexT" id={`TitleTxt_${data._id}`}>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            newObjAbtestNotif.nt === 'video' ? //video notification
                              <div className="notilist-notivideo">
                                {renderListHTML(state, data)}
                              </div>
                              :
                              newObjAbtestNotif.nt === 'call_to_action' ? //call_to_action notification
                                <div className="notilist-callback">
                                  {renderListHTML(state, data)}
                                </div>
                                :
                                newObjAbtestNotif.nt === 'collector' ?
                                  <div className="notilist-promotional">
                                    {renderListHTML(state, data)}
                                  </div>
                                  :
                                  newObjAbtestNotif.nt === 'social_media_followers' ?
                                    <div className="notilist-promotional">
                                      {renderListHTML(state, data)}
                                    </div>
                                    :
                                    newObjAbtestNotif.nt === 'all_in_one' ? //All in one
                                      <div className="notilist_allinone">
                                        {renderListHTML(state, data)}
                                      </div>
                                      :
                                      renderListHTML(state, data)
                          }

                        </div>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <div className='edit-notiname-block'>
                          <InlineStack gap={200} blockAlign='center'>
                            <Text id='txt_limit' variant="headingXs" fontWeight="semibold" as="p">{data.ttl}</Text>
                            <div className='edit-notiname-icon' id='cursorPointer' onClick={() => props.handleUpdAbTestShow(data)}>
                              <Icon source={EditIcon} />
                            </div>
                          </InlineStack>
                        </div>
                        <Box paddingBlockStart={200} paddingBlockEnd={200}>
                          <BlockStack gap="200" align="start">
                            <div style={{ padding: '0 50px 0 0' }}>
                              <Tooltip dismissOnMouseOut content={<BlockStack><Text>Created on: {moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</Text><Text>Last modified: {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY hh:mm:ss a')}</Text></BlockStack>} width='wide'>
                                <Text variant="bodyXs" tone='subdued' fontWeight="medium" as="p" >
                                  {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY')}
                                </Text>
                              </Tooltip>
                            </div>
                          </BlockStack>
                        </Box>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <Text variant='headingMd' as='p' fontWeight='regular'>{data.im ? `${data.im} ` : 0}</Text>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <Text variant='headingMd' as='p' fontWeight='regular'>{data.hv ? `${data.hv} ` : 0}</Text>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <Text variant='headingMd' as='p' fontWeight='regular'>{data.clk ? `${data.clk} ` : 0}</Text>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <Text variant='headingMd' as='p' fontWeight='regular'>{data.eng ? `${data.eng} ` : 0}</Text>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <Text variant='headingMd' as='p' fontWeight='regular'>{data.cta ? `${data.cta} ` : 0}</Text>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <BlockStack>
                          <InlineStack gap={200}>
                            <Text variant='headingMd' as='p' fontWeight='regular'>{data.perCnt ? data.perCnt : 0} %</Text>
                            {topVrnt === data._id &&
                              <Box>
                                <Badge tone='success'>Best</Badge>
                              </Box>
                            }
                          </InlineStack>
                          <RadioButton
                            label={data.iornl ? 'Primary' : 'Set Primary'}
                            value={data._id}
                            checked={data.iornl}
                            onChange={() => props.handlerbtnChange('variant', data._id)}
                          />
                        </BlockStack>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <ButtonGroup noWrap>
                          <label className="switch">
                            <input type="checkbox" checked={data.ia}
                              onChange={(e) => props.handleUpdAbTestVarntStatus(newObjAbtestNotif._id, data, e.target.checked)} disabled={data.iornl} />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                          {checkPermission('notification', 'isedit', state.profile) &&
                            <div id='cursorPointer' onClick={() => props.handleEditNotif(data, 'ab_test', data._id)}>
                              <Icon source={EditIcon} />
                            </div>
                          }
                          {checkPermission('notification', 'isdelete', state.profile) && data.iornl !== true &&
                            <div id='cursorPointer' onClick={() => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ vartId: data._id }) }}>
                              <Icon source={DeleteIcon} />
                            </div>
                          }
                        </ButtonGroup>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  )
                })}
              </IndexTable>
            </div>
          </Card>
        </Box>
      </BlockStack>

      <Modal // create variant
        open={state.crtVarntOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Create new variant</Text>}
        onClose={() => openCloseModal('crtVarntOpen', state.crtVarntOpen, 'close')}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <BlockStack gap={400}>
              <div>
                <TextField
                  label="Variant title"
                  value={state.abTitle}
                  onChange={(value) => changeNameValue({ abTitle: value })}
                  autoComplete="off"
                  error={state.errMessage.variantTitle ? state.errMessage.variantTitle : false}
                />
                {props.validator.message('variantTitle', state.abTitle, 'required')}
              </div>
              <TextField
                label="Description"
                placeholder='Write here & Press enter'
                multiline={3}
                value={state.abDesc}
                onChange={(value) => changeNameValue({ abDesc: value })}
                autoComplete="off"
              />
              <Select
                label="Clone from which variant ?"
                options={state.arrVrnt}
                value={state.abVrnt}
                onChange={(value) => changeNameValue({ abVrnt: value })}
              />
              <Checkbox
                label="Use this variant for A/B testing"
                checked={state.abia}
                onChange={(value) => changeNameValue({ abia: value })}
              />
              <Box paddingBlockStart={400}>
                <Button variant='primary' tone='success' onClick={() => props.handleCreateAbTestNotif()}>Create & customize</Button>
              </Box>
            </BlockStack>
          </div>
        </Modal.Section>
      </Modal>

      <Modal // update variant
        open={state.edtVarntOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Update variant</Text>}
        onClose={() => openCloseModal('edtVarntOpen', state.edtVarntOpen, 'close')}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <BlockStack gap={400}>
              <div>
                <TextField
                  label="Variant title"
                  value={state.abTitle}
                  onChange={(value) => changeNameValue({ abTitle: value })}
                  autoComplete="off"
                  error={state.errMessage.variantTitle ? state.errMessage.variantTitle : false}
                />
                {props.validator.message('variantTitle', state.abTitle, 'required')}
              </div>
              <TextField
                label="Description"
                placeholder='Write here & Press enter'
                multiline={3}
                value={state.abDesc}
                onChange={(value) => changeNameValue({ abDesc: value })}
                autoComplete="off"
              />
              <Box paddingBlockStart={400}>
                <Button variant='primary' tone='success' onClick={() => props.handleUpdAbTestNotif()}>Update</Button>
              </Box>
            </BlockStack>
          </div>
        </Modal.Section>
      </Modal>

      <Modal // delete variant
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={() => openCloseModal('deleteOpen', state.deleteOpen, 'close')}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure want to delete this variant?</Text>
            <PageActions
              primaryAction={<Button variant='primary' tone='success' onClick={() => state.defaultAbtest ? props.handleDeleteAbTestNotif(newObjAbtestNotif._id) : props.handleDeleteAbTestNotif(newObjAbtestNotif._id, state.vartId)}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default AbTestCard;