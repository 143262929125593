import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const agency = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    getAgency: (state, action) => { },
    getAgencySuccess: (state, action) => {
      return { ...state, getagency: action.payload };
    },
    // Settings upadte
    addUpdateWhiteLabel: (state, action) => { },
    //upload logo (base64)
    updWhiteLabelLogo: (state, action) => { },
    //Cname update
    addUpdateCname: (state, action) => { },
    getSubUser: (state, action) => { },
    getSubUserSuccess: (state, action) => {
      return { ...state, SubUser: action.payload };
    },
    getWhitelabelPlan: (state, action) => { },
    getWhitelabelPlanSuccess: (state, action) => {
      return { ...state, getWhitelabel: action.payload };
    },
    updStatusPlan: (state, action) => { },
    addUpdatePlan: (state, action) => { },
    deleteWhitelabelPlan: (state, action) => { },
    getSubPlan: (state, action) => { },
    getSubPlanSuccess: (state, action) => {
      return { ...state, SubPlan: action.payload };
    },
    addUpdateSubUser: (state, action) => { },
    deleteSubUser: (state, action) => { },
    //user password update
    updSubUserPWD: (state, action) => { },
  }
});

export const {
  getAgency,
  getAgencySuccess,
  addUpdateWhiteLabel,
  updWhiteLabelLogo,
  addUpdateCname,
  getSubUser,
  getSubUserSuccess,
  getWhitelabelPlan,
  getWhitelabelPlanSuccess,
  updStatusPlan,
  addUpdatePlan,
  deleteWhitelabelPlan,
  getSubPlan,
  getSubPlanSuccess,
  addUpdateSubUser,
  deleteSubUser,
  updSubUserPWD
} = agency.actions;

export default agency.reducer;
