import { Banner, BlockStack, Box, Button, ButtonGroup, Card, Checkbox, DatePicker, Divider, EmptySearchResult, IndexFilters, IndexTable, InlineGrid, InlineStack, Modal, OptionList, Page, PageActions, Popover, Text, TextField, Tooltip, useBreakpoints, useSetIndexFiltersMode } from '@shopify/polaris';
import { BulletIcon, ComposeIcon, DeleteIcon } from '@shopify/polaris-icons';
import moment from 'moment';
import React, { useState } from 'react';
import { skeleton } from '../../utils/skeleton';
function GoalCard(props) {


  let { state, tabs, changeNameValue, openCloseModal, handleSelectedDates, goalData, createGoalBtn, handleDeleteCall, handleEditGoal, handleGoalChange, validator, editvalidator, refreshPage, handleCopyGoal, profile, checkPermission, isLoading } = props


  const { mode, setMode } = useSetIndexFiltersMode();
  let { mdDown, lgUp } = useBreakpoints();

  const [activeDateRange, setActiveDateRange] = useState({
    since: new Date(new Date().setHours(0, 0, 0, 0)),
    until: new Date(new Date().setHours(0, 0, 0, 0))
  })

  let [{ month, year }, setDate] = useState({
    month: activeDateRange.since.getMonth(),
    year: activeDateRange.until.getFullYear()
  })

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.since.toDateString(),
      tdt: state.until.toDateString()
    }
    changeNameValue(obj);
  }

  const handleCalendarChange = ({ start, end }) => {
    const newDateRange = { since: start, until: end };

    changeNameValue({
      frmdt: newDateRange.since.toDateString(),
      tdt: newDateRange.until.toDateString()
    });
    activeRangeChange(newDateRange);
  };

  // for custom date popup open (same tab click, event is not coming)
  if (document.getElementById("Custom date-3")) {
    document.getElementById("Custom date-3").onclick = function () {
      changeNameValue({ popoverActive: true });
    }
  }

  const checkValidDate = (dateString) => {
    if (dateString && !dateString.includes("Invalid"))
      return true;
    else return false;
  }

  // select URI
  let setGoalUrl = [
    { value: '0', label: 'Exact URL' },
    { value: '1', label: 'URL containing a keyword' },
  ]

  //formmet number.
  const formmetNumber = (nu) => {
    return nu.toLocaleString();
  }

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'See how WiserNotify notifications improve your conversions.'}
      description={'Add a goal to get started.'}
      withIllustration
    />
  );

  return (
    <Page title='Goal'
      primaryAction={checkPermission('goal', 'isadd', profile) && <Button variant="primary" onClick={() => {
        changeNameValue({ popoverActive: false });
        openCloseModal('addGoalOpenModal', state.addGoalOpenModal, 'open');
      }} >Add Goal</Button>}
    >
      <Card padding={0}>
        <div id='goal_filter'>
          <InlineStack align='space-between'>
            <IndexFilters
              tabs={tabs}
              selected={state.selected}
              onSelect={(e) => changeNameValue({ selected: e })}
              filters={[]}
              mode={mode}
              setMode={setMode}
              canCreateNewView={false}
            />
            <Box paddingInlineEnd={300}>
              <Button onClick={() => refreshPage()}>Refresh</Button>
            </Box>
          </InlineStack>
        </div>

        <div id='goalTbl' style={{ marginBottom: '20px' }}>
          <IndexTable
            itemCount={state.isReloadGoal ? 3 : (state.filterGoal?.length || 0)}
            selectable={false}
            emptyState={emptyStateMarkup}
            headings={[
              { title: <Text fontWeight='medium' variant='bodyMd'>Notification name</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Conversions</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Contribution</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Conv.rate</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Goal visits</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Impression</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Hover</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Click</Text> },
              { title: <Text fontWeight='medium' variant='bodyMd'>Actions</Text> }
            ]}
          >
            {
              state.isReloadGoal ?
                skeleton(10, 8) :
                state.filterGoal && state.filterGoal?.length > 0 &&
                state.filterGoal.map((data, index) => {
                  return (
                    <IndexTable.Row
                      key={index}
                      position={index}>
                      <IndexTable.Cell>
                        <BlockStack gap="050">
                          <Text as='h6' fontWeight='medium' variant='bodyMd'>{data.gn}</Text>
                          <Tooltip dismissOnMouseOut width='wide' padding="400" content={<span className="tooltip-content">{data.gu}</span>}>
                            <Text as='p' fontWeight='regular' id='goalURL' tone='subdued' variant='bodySm'> {data.gu.substring(0, 30)}</Text>
                          </Tooltip>
                          <Text as='p' fontWeight='regular' tone='subdued' variant='bodySm'>{moment(new Date(data.cdt)).format('MMM-DD-YYYY')}</Text>
                        </BlockStack>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={`${formmetNumber(data.uqv)} visitors completed the goal by landing on the goal URL, regardless of whether they interacted with Wisernotify notifications.`}>
                          <InlineStack gap="300" wrap={false} blockAlign="center" >
                            <Text as='h2' variant='bodyLg' fontWeight='regular' > {formmetNumber(data.uqv)} </Text>
                            < Text as='span' variant='bodyXs' fontWeight='bold' id='conve_Span' > ${formmetNumber(data.uqv ? (data.uqv * (data.cnvamnt ? data.cnvamnt : 1)).toFixed(2) : 0)} </Text>
                          </InlineStack>
                        </Tooltip>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={`Out of ${formmetNumber(data.uqv)} total conversions, ${(data.uqv ? ((data.guqv / data.uqv) * 100).toFixed(2) : 0)} % conversions involved at least one interaction with a notification.`}>
                          <InlineStack gap="400" wrap={false} blockAlign="center" >
                            <Text as='h2' variant='bodyLg' fontWeight='regular' > {formmetNumber(data.uqv ? ((data.guqv / data.uqv) * 100).toFixed(2) : 0)} % </Text>
                            < Text as='h2' variant='bodyXs' fontWeight='bold' id='contribut_Span' > ${formmetNumber(((data.cnvamnt ? data.cnvamnt : 1) * data.guqv).toFixed(2))} </Text>
                          </InlineStack>
                        </Tooltip>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={`The conversion rate is ${formmetNumber(((data.guqv > 0 ? (data.guqv / data.im) : 0) * 100).toFixed(2))}%, directly impacted by notifications. This is calculated based on goal visits and impressions.`}>
                          <Text as='h2' variant='bodyLg' fontWeight='regular'>{formmetNumber(((data.guqv > 0 ? (data.guqv / data.im) : 0) * 100).toFixed(2))} %</Text>
                        </Tooltip>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={`${formmetNumber(data.guqv)} visitors reached the goal URL after interacting with a notification.`}>
                          <Text as='h2' variant='bodyLg' fontWeight='regular' > {formmetNumber(data.guqv)} </Text>
                        </Tooltip>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={`The notification has been seen ${formmetNumber(data.im)} times by goal page visitors.`}>
                          <Text as='h2' variant='bodyLg' fontWeight='regular' > {formmetNumber(data.im)} </Text>
                        </Tooltip>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={`The notification was hovered over ${formmetNumber(data.hv)} times by goal page visitors.`}>
                          <Text as='h2' variant='bodyLg' fontWeight='regular' > {formmetNumber(data.hv)} </Text>
                        </Tooltip>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={`The notification was clicked ${formmetNumber(data.clk > 0 ? data.clk : 0)} times by goal page visitors.`}>
                          <Text as='h2' variant='bodyLg' fontWeight='regular' > {formmetNumber(data.clk > 0 ? data.clk : 0)} </Text>
                        </Tooltip>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <ButtonGroup>
                          {(checkPermission('goal', 'isedit', profile) || (profile?.
                            userTeam && profile.userTeam._id == data.uk)) &&
                            <Button variant='monochromePlain' icon={ComposeIcon} onClick={() => handleEditGoal(data)} />
                          }
                          {(checkPermission('goal', 'isdelete', profile) || (profile?.
                            userTeam && profile.userTeam._id == data.uk)) &&
                            <Button variant='monochromePlain' icon={DeleteIcon} onClick={() => {
                              openCloseModal('deleteGoalOpen', state.deleteGoalOpen, 'open');
                              changeNameValue({ deleteGoalId: data._id, popoverActive: false });
                            }} />
                          }
                        </ButtonGroup>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  )
                })
            }
          </IndexTable>
        </div>
      </Card>

      <div className='goalcstm_date' style={{ display: state.popoverActive ? 'block' : 'none' }}>
        <Box maxWidth='580px'>
          <Card padding={200}>
            <Popover.Pane>
              <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                <InlineStack gap="400">
                  <DatePicker
                    month={month}
                    year={year}
                    selected={{
                      start: activeDateRange.since,
                      end: activeDateRange.until,
                    }}
                    onMonthChange={(month, year) => setDate({ month, year })}
                    onChange={handleCalendarChange}
                    disableDatesBefore={new Date(new Date().setHours(0, 0, 0, 0) - 61 * 24 * 60 * 60 * 1000)}
                    disableDatesAfter={new Date()}
                    multiMonth={lgUp}
                    allowRange />
                </InlineStack>
              </Box>
            </Popover.Pane>
            <Divider />
            <Box paddingBlock={200}>
              <InlineStack align="end" blockAlign='center' gap={400}>
                <Text>
                  {(checkValidDate(state.frmdt) && checkValidDate(state.tdt))
                    ? state.frmdt + " - " + state.tdt : ''}
                </Text>
                <ButtonGroup>
                  <Button variant='tertiary' onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                  <Button variant="primary" onClick={handleSelectedDates}>Apply</Button>
                </ButtonGroup>
              </InlineStack>
            </Box>
          </Card>
        </Box>
      </div>

      {/* create goal Modal  */}
      <Modal
        title={<Box><Text as='h2' variant='headingLg'>Create goal</Text></Box>}
        open={state.addGoalOpenModal}
        onClose={(e) => openCloseModal('addGoalOpenModal', state.addGoalOpenModal, e)}>
        <Box paddingInline={600} paddingBlockStart={500} paddingBlockEnd={800}>
          <BlockStack gap={400}>
            <Box>
              <BlockStack gap={100}>
                <div>
                  <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"This is the name of the goal you're tracking, such as 'Purchase Completion' or 'Signup Success.'"} > <Text>Goal name</Text></Tooltip >
                </div>
                < div >
                  <TextField
                    value={state.gn}
                    onChange={(e) => changeNameValue({ 'gn': e })}
                    autoComplete="off"
                    placeholder='Enter your goal name'
                    error={state.errMessage?.gn ? 'The Goal name field is required.' : false} />
                  {validator.message('gn', state.gn, 'required')}
                </div>
              </BlockStack>
            </Box>

            < Box >
              <BlockStack gap={100}>
                <div>
                  <Tooltip dismissOnMouseOut width='wide' padding="400" content={'Set the average monetary value (in your preferred currency) for each goal completion, like the price of a product or service.'} hasUnderline > <Text>Average goal value </Text></Tooltip >
                </div>
                < div >
                  <TextField
                    type='number'
                    value={state.cnvamnt}
                    onChange={(e) => changeNameValue({ cnvamnt: e })}
                    autoComplete="off"
                    error={state.errMessage?.cnvamnt ? 'The goal value field is required.' : false} />
                  {validator.message('cnvamnt', state.cnvamnt, 'required')}
                </div>
              </BlockStack>
            </Box>

            <Box>
              <BlockStack gap={100}>
                <div>
                  <Tooltip dismissOnMouseOut width='wide' padding="400" content="If you have one specific confirmation URL, choose 'Exact URL'. If you want to target multiple URLs that share similar keywords (e.g., 'success' or 'confirmation'), choose 'Keyword Containing URL'." hasUnderline >
                    <Text as="span" >
                      Set goal completion URL
                    </Text>
                  </Tooltip>
                </div>
                < Popover
                  fullWidth
                  active={state.isGoalUrl}
                  onClose={() => changeNameValue({ isGoalUrl: !state.isGoalUrl })}
                  activator={< Button id='disSelect' textAlign="left" fullWidth disclosure="select" onClick={() => changeNameValue({ isGoalUrl: !state.isGoalUrl })}>
                    <Text fontWeight='medium' variant='bodyMd' >
                      {setGoalUrl.find(({ value }) => value === state.gut)?.label || 'select'}
                    </Text>
                  </Button>}>
                  <OptionList
                    options={setGoalUrl}
                    selected={[state.gut.toString()]}
                    onChange={() => changeNameValue({ isGoalUrl: !state.isGoalUrl })}
                    onFocusOption={(e) => changeNameValue({ 'gut': e })} />
                </Popover>
              </BlockStack>
            </Box>
            {state.gut === '1' ?
              <InlineGrid columns={{ xs: 1, sm: 1, md: 2, }} gap={800}>
                <Box>
                  <BlockStack gap={100}>
                    <div>
                      <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Enter your website domain, e.g., 'mydomain.com' (without 'http(s)' or 'www')."} hasUnderline > <Text>Enter website domain </Text></Tooltip >
                    </div>
                    < div >
                      <TextField
                        value={state.ht}
                        onChange={(e) => changeNameValue({ 'ht': e })
                        }
                        autoComplete="off"
                        placeholder='e.g www.yourdomainname.com'
                        error={state?.errMessage?.ht ? 'The website domain field is required.' : false} />
                      {validator.message('ht', state.ht, 'required')}

                    </div>
                  </BlockStack>
                </Box>

                <Box>
                  <BlockStack gap={100}>
                    <div>
                      <Tooltip dismissOnMouseOut width='wide' padding="400" content={"Enter a keyword found in the goal completion URL, such as 'success' or 'thankyou', to track multiple URLs."} hasUnderline > <Text>Enter a keyword that includes the URL </Text></Tooltip >
                    </div>
                    < div >
                      <TextField
                        value={state.gu}
                        onChange={(e) => changeNameValue({ 'gu': e })}
                        autoComplete="off"
                        placeholder='Enter keyword'
                        error={state?.errMessage?.gu ? 'The contain keyword field is required.' : false} />
                      {validator.message('gu', state.gu, 'required')}
                    </div>
                  </BlockStack>
                </Box>
              </InlineGrid>
              :
              <Box>
                <TextField
                  label="Exact URL"
                  value={state.gu}
                  onChange={(e) => changeNameValue({ 'gu': e })}
                  autoComplete="off"
                  placeholder='Enter exact URL'
                  error={state.errMessage?.Url || false} />
                {validator.message('Url', state.gu, 'required')}
              </Box>
            }

            <Box background='bg-fill' >
              <Banner icon={BulletIcon} title="The goal URL can’t be changed after it is set." />
            </Box>
          </BlockStack>
          <Box paddingBlockStart={500}>
            <Button variant='primary' tone='success' onClick={() => createGoalBtn()}>Create a goal</Button>
          </Box>
        </Box>
      </Modal>

      {/* DeleteGoalModal  */}
      <Modal
        open={state.deleteGoalOpen}
        onClose={(e) => openCloseModal('deleteGoalOpen', state.deleteGoalOpen, e)}
        title="Confirm Deletion" >
        <Modal.Section>
          <Text as='h4'>Are you sure to delete goal.</Text>
          <PageActions
            primaryAction={{
              icon: DeleteIcon,
              content: 'Delete',
              tone: 'critical',
              onAction: () => handleDeleteCall()
            }}
            secondaryActions={[{
              content: 'No',
              onAction: () => openCloseModal('deleteGoalOpen', state.deleteGoalOpen, 'close')
            }]} />
        </Modal.Section>
      </Modal>

      {/* edit goal modal */}
      <Modal
        title={<Box paddingInlineStart={200}><Text as='h2' variant='headingLg'>Edit goal</Text></Box>}
        open={state.editGoalOpen}
        onClose={(e) => openCloseModal('editGoalOpen', state.editGoalOpen, e)} >
        <Box paddingInline={600} paddingBlockStart={300}>
          <BlockStack gap={300}>
            <Box>
              <BlockStack gap={100}>
                <div>
                  <Tooltip dismissOnMouseOut width='wide' padding="400" hasUnderline content={"This is the name of the goal you're tracking, such as 'Purchase Completion' or 'Signup Success.'"} > <Text>Goal name </Text></Tooltip >
                </div>
                < div >
                  <TextField
                    value={state.gName}
                    onChange={(e) => changeNameValue({ 'gName': e })}
                    autoComplete="off"
                    placeholder='Enter goal name'
                    error={state.errMessage.gName ? "The goal name field is required." : false}
                  />
                  {editvalidator.message('gName', state.gName, 'required')}
                </div>
              </BlockStack>
            </Box>
            <Box>
              <BlockStack gap={100}>
                <div>
                  <Tooltip dismissOnMouseOut width='wide' padding="400" content={'Set the average monetary value (in your preferred currency) for each goal completion, like the price of a product or service.'} hasUnderline > <Text>Average goal value </Text></Tooltip >
                </div>
                < div >
                  <TextField
                    type='number'
                    value={state.gValue}
                    onChange={(e) => changeNameValue({ 'gValue': e })}
                    autoComplete="off"
                    error={state.errMessage?.gValue ? 'The goal value field is required.' : false} />
                  {editvalidator.message('gValue', state.gValue, 'required')}
                </div>
              </BlockStack>
            </Box>

            <InlineStack align='space-between'>
              <Text as='p' variant='bodyLg' fontWeight='semibold'>Track goal using code?</Text>
              <Checkbox
                checked={state.itrkglcd}
                onChange={(e) => changeNameValue({ 'itrkglcd': e })}
              />
            </InlineStack>
            <Box>
              {state.itrkglcd ?
                <Box>
                  <BlockStack gap={200}>
                    <div>
                      <Tooltip dismissOnMouseOut width='wide' padding="400" content={'Copy and paste this function on custom event'} hasUnderline>
                        <Text as='span'>Track your goal using custom function</Text>
                      </Tooltip>
                    </div>
                    <Box>
                      <div className='copyGoalId' onClick={() => handleCopyGoal(state.goalId)}>
                        fnCustomGoalComplete("{state.goalId}");
                      </div>
                    </Box>
                  </BlockStack>
                </Box>
                :
                <Box>
                  <BlockStack gap={200}>
                    <div>
                      <Tooltip dismissOnMouseOut width='wide' padding="400" content="If you have one specific confirmation URL, choose 'Exact URL'. If you want to target multiple URLs that share similar keywords (e.g., 'success' or 'confirmation'), choose 'Keyword Containing URL'." hasUnderline >
                        <Text as="span" >
                          Set goal completion URL
                        </Text>
                      </Tooltip>
                    </div>
                    <Popover
                      fullWidth
                      activator={<Button id='disSelect' textAlign="left" disabled fullWidth disclosure="select">
                        <Text fontWeight='medium' variant='bodyMd'>
                          {setGoalUrl.find(({ value }) => value === state.gut)?.label || 'select'}
                        </Text>
                      </Button>}>
                    </Popover>
                    {state.gut === '1' ?
                      <InlineGrid columns={2} gap={800}>
                        <TextField
                          label={<Tooltip dismissOnMouseOut width='wide' padding="400" content={"Enter your website domain, e.g., 'mydomain.com' (without 'http(s)' or 'www')."} hasUnderline><Text>Enter website domain</Text></Tooltip>}
                          value={state.hTime}
                          autoComplete="off"
                          placeholder='e.g www.yourdomainname.com'
                          disabled
                        />
                        <TextField
                          label={<Tooltip dismissOnMouseOut width='wide' padding="400" content={"Enter a keyword found in the goal completion URL, such as 'success' or 'thankyou', to track multiple URLs."} hasUnderline><Text> Enter a keyword that includes the URL</Text></Tooltip>}
                          value={state.gUrl}
                          autoComplete="off"
                          placeholder='Enter keyword'
                          disabled
                        />
                      </InlineGrid>
                      :
                      <TextField
                        label="Exact URL"
                        value={state.gUrl}
                        autoComplete="off"
                        placeholder='Enter exact URL'
                        disabled
                      />
                    }
                    <Box background='bg-fill' >
                      <Banner icon={BulletIcon} title="The goal URL can’t be changed after it is set." />
                    </Box>
                  </BlockStack>
                </Box>
              }
            </Box>
          </BlockStack>

          <InlineStack align='center'>
            <Box paddingBlockStart={200} paddingBlockEnd={500}>
              <Button variant='primary' tone='success' onClick={() => handleGoalChange()}>Update Goal</Button>
            </Box>
          </InlineStack>
        </Box>
      </Modal>
    </Page>
  );
}

export default GoalCard;
