import React from 'react';
import { Badge, BlockStack, Button, Card, InlineStack, Text, Tooltip, VideoThumbnail } from '@shopify/polaris';
import { ImportIcon } from '@shopify/polaris-icons';

function IntegrationHeader({
  isWhite,
  data,
  fnRedirect,
  getDemoCsv,
  getPlugin,
  isInstall,
}) {
  let objTyp = {};
  if (data && data.typ && data.typ.length > 0) {
    data.typ.filter(x => {
      if (x == 'review_recent' || x == 'review_bulk' || x == 'review') {
        objTyp['review'] = 'Review';
      } else if (x == 'signup') {
        objTyp['conversion'] = 'Conversion';
      } else if (x == 'purchase') {
        objTyp['sales'] = 'Sales';
      } else if (x == 'channel_subscriber' || x == 'video_subscriber') {
        objTyp['video'] = 'Video';
      } else if (x == 'review_feed') {
        objTyp['feed'] = 'Review feed';
      } else if (x == 'followers' || x == 'Linkedin') {
        objTyp['others'] = 'Followers';
      } else if (x == 'social_media_count') {
        objTyp['social_media_count'] = 'Social media counter';
      }
      //  else {
      //   objTyp[x] = x;
      // }
    });
  }

  return (
    <>
      {isWhite === false ?
        <Card>
          <BlockStack gap={200}>
            <InlineStack gap={300}>
              <div style={{ width: '200px' }}>
                <Text variant="headingXs" as="h6">Supported notifications</Text>
              </div>
              {Object.keys(objTyp).map((key, index) => (
                <Badge progress="complete" key={index}>{objTyp[key]}</Badge>
              ))}
            </InlineStack>
            <InlineStack gap={300}>
              <div style={{ width: '200px' }}>
              </div>
              <Text>{data.dsc ? data.dsc : ''}</Text>
            </InlineStack>
            {isInstall === true &&
              <InlineStack gap={300}>
                <div style={{ width: '200px' }}>
                  <Text variant="headingXs" as="h6">Install from</Text>
                </div>
                <InlineStack gap={300}>
                  <a target='_blank' href='https://chrome.google.com/webstore/detail/wisernotify/pejajkcjblmoidllgncbpahfadhlmimd'> <Text>Install chrome extension to pull reviews</Text></a>
                  <div className='videoDiv'>
                    <VideoThumbnail
                      videoLength={179}
                      onClick={() => window.open('https://www.youtube.com/watch?v=Oqp3bu7xEiU', '_blank')}
                    />
                  </div>
                </InlineStack>
              </InlineStack>
            }
            {data.in === 'magento' &&
              <div style={{ marginLeft: "207px" }}>
                <Tooltip content='Click here to Download magento plugin (Zip file)' width='wide'>
                  <Button icon={ImportIcon} variant='plain' onClick={() => getPlugin()}>Download app module (zip file)</Button>
                </Tooltip>
              </div>
            }
            {data.in === 'opencart' &&
              <div style={{ marginLeft: "207px" }}>
                <Tooltip content='Click here to Download opencart extension (Zip file)' width='wide'>
                  <Button icon={ImportIcon} variant='plain' onClick={() => getPlugin()}>Download app module (zip file)</Button>
                </Tooltip>
              </div>
            }
            {data.in === 'prestashop' &&
              <div style={{ marginLeft: "207px" }}>
                <Tooltip content='Click here to Download prestashop module (Zip file)' width='wide'>
                  <Button icon={ImportIcon} variant='plain' onClick={() => getPlugin()}>Download app module (zip file)</Button>
                </Tooltip>
              </div>
            }
          </BlockStack>
        </Card>
        :
        <>
          {
            data.in === 'importreviews' &&
            <InlineStack gap={300}>
              <div style={{ width: '200px' }}>
                <Button variant="monochromePlain" onClick={() => getDemoCsv()}><Text variant="headingXs" as="h6"> Download review template (CSV file)</Text></Button>
              </div>
              <Button variant="plain" onClick={() => getDemoCsv()}>Click here to download review template (CSV file)</Button>
            </InlineStack>
          }
        </>
      }
    </>
  )
}

export default IntegrationHeader