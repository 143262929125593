import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, IndexTable, Icon, PageActions, Banner, Page, Divider, Modal, Text, InlineStack
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from "react-router-dom";
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function SquarespaceCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    Squarespace: [],
    ia: false,//for toggle on/off
    deleteDataModal: false,//open delete modal
    modalC: false,//condition for delete modal
    notifListData: "",//show data in modal
    dataId: '',//delete data id
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //Squarespace data from api
  const getSquarespaceData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ Squarespace: getSquarespaceData });
  }, [getSquarespaceData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.Squarespace.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ Squarespace: updatedList })
  };

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, })
  }

  //delete api call 
  const handleDeleteCall = () => {
    let obj = {
      _id: info.dataId,
    }
    dispatch(integrationDucks.deleteSquarespace(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  const loginWithSquarespace = () => {
    // let objState = {
    //   uk: profile._id,
    //   ti: profile.ti,
    //   uri: window.location.href,//.replace(/&/gi, '@'),
    //   path: window.location.pathname
    // };
    const authURL = `https://login.squarespace.com/api/1/login/oauth/provider/authorize?client_id=${process.env.REACT_APP_SQUARESPACE_ID}&redirect_uri=${process.env.REACT_APP_SQUARESPACE_AUTH_URL}&scope=website.orders.read,website.inventory.read,website.products.read&access_type=offline&state=${props.profile.ti}`;
    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* Squarespace data*/}
        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {props.checkPermission('integration', 'isadd', props.profile) && localStorage.getItem('pvd') !== 'wix' &&
                <Box paddingBlockEnd={200}><div id='insta-btn' onClick={() => loginWithSquarespace()}><Button size='large'>Login with squarespace</Button></div></Box>
              }
            </InlineStack>
            <Card padding={0}>
              <IndexTable
                itemCount={info.Squarespace?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' },
                ]}
                selectable={false}
              >
                {info.Squarespace && info.Squarespace.length > 0 && info.Squarespace.map((i, index) => {
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell><span className='tbl-hightlight'>{i.rmk}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          {props.checkPermission('integration', 'isdelete', props.profile) &&
                            <div id="delete" onClick={() => handledeleteModal(i._id)}>
                              <Banner hideIcon tone='critical'>
                                <Icon
                                  source={DeleteIcon}
                                  tone="critical"
                                />
                              </Banner>
                            </div>
                          }
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e) }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close') }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e) }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => { handleDeleteCall() }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close') }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default SquarespaceCard;