import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const dataPage = createSlice({
  name: 'dataPage',
  initialState,
  reducers: {
    getDataPage: (state, action) => { },
    getDataPageSuccess: (state, action) => {
      return { ...state, dataPage: action.payload };
    },
    getUserInte: (state, action) => { },
    getUserInteSuccess: (state, action) => {
      return { ...state, userInte: action.payload };
    },
    delSignupData: (state, action) => { },
    deleteBulkSignUp: (state, action) => { },
    getNotifForSignup: (state, action) => { },
    gndfsSuccess: (state, action) => {
      return { ...state, gndf: action.payload };
    },
    updateSignupData: (state, action) => { },
    checkPass: (state, action) => { },
    checkPassSuccess: (state, action) => {
      return { ...state, checkPass: action.payload };
    },
    getDataForCSV: (state, action) => { },
  }
});

export const {
  getDataPage,
  getDataPageSuccess,
  getUserInte,
  getUserInteSuccess,
  delSignupData,
  deleteBulkSignUp,
  getNotifForSignup,
  gndfsSuccess,
  updateSignupData,
  checkPass,
  checkPassSuccess,
  getDataForCSV
} = dataPage.actions;

export default dataPage.reducer;
