import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, Thumbnail, IndexTable, InlineStack, Page, Text, Banner, Icon, Modal, Divider, PageActions, Popover,
  Autocomplete,
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, RefreshIcon, SearchIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { facebook_icon } from '../../img';
import { useNavigate } from "react-router-dom";
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function FacebookgroupfollowersCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    Facebookgroup: [],
    ia: false,//toggle on/off
    dataId: '',//delete data id
    selected: [],//selecte data
    activePopover: null,
    subId: '',
    searchFBGrp: '',//serach

    deleteDataModal: false,//delete modal
    modalC: false,//modal check
    apiD: false,//api condition
    notifListData: "",//show data in modal
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //Facebookgroup data from api
  const getFacebookgroupData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ Facebookgroup: getFacebookgroupData });
  }, [getFacebookgroupData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.Facebookgroup.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ Facebookgroup: updatedList })
  };

  //redirect to facebook
  const redirectFB = () => {
    let objStateFB = {
      uk: props.profile._id,
      ti: props.profile.ti,
      uri: window.location.href.replace(/&/gi, '@'),//window.location.href,
      path: window.location.pathname
    };
    let authURL = 'https://www.facebook.com/v19.0/dialog/oauth?client_id=' +
      '164374917463431' +
      '&redirect_uri=' +
      'https://is.wisernotify.com/api' +
      '/facebookgroupfollowers/oauth&scope=email,pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_metadata,public_profile,groups_access_member_info,business_management&response_type=code&state=' +
      JSON.stringify(objStateFB);

    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: false })
  }

  //open delete modal for sub data
  const handleSubdeleteModal = (id, subId) => {
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, subId: subId, apiD: true, modalC: false })
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteFBGrpAcc(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //delete sub data api call
  const handleDeleteSubData = () => {
    dispatch(integrationDucks.deleteFBGrp({ _id: info.dataId, id: info.subId }));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //handle toggle
  const handleToggle = (id) => {
    changeNameValueCard({ activePopover: info.activePopover === id ? null : id, searchFBGrp: "" },);
  };

  //create fb page data
  const handleData = (object, val, id) => {
    let filterData = object.find(x => x.name === val[0]);
    const obj = {
      _id: id
    }
    const combined = { ...filterData, ...obj };
    dispatch(integrationDucks.createFBGrp(combined));
    changeNameValueCard({ activePopover: null });
  }

  //search field autocomplete
  const textField = (
    <Autocomplete.TextField
      onChange={(e) => changeNameValueCard({ searchFBGrp: e })}
      value={info.searchFBGrp}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search by page name"
      autoComplete="off"
      label="List"
    />
  );

  //sync groups
  const syncGroups = (id) => {
    dispatch(integrationDucks.syncGroups(id));
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create Facebook Group Followers data */}
        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {
                props.checkPermission('integration', 'isadd', props.profile) &&
                <Box paddingBlockEnd={200}>
                  <div id='insta-btn' onClick={() => redirectFB()}>
                    <Button>
                      <InlineStack align='space-between'>
                        <Thumbnail
                          source={facebook_icon}
                          size='extraSmall'
                          alt="facebook"
                        />
                        <span className='login-text'>Login with facebook</span>
                      </InlineStack>
                    </Button>
                  </div>
                </Box>
              }
            </InlineStack>
            <Card padding={0}>
              <IndexTable
                itemCount={info.Facebookgroup?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'User name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: '' },
                  { title: '' },
                  { title: '' },
                  { title: 'Action' },
                ]}
                selectable={false}
              >
                {info.Facebookgroup && info.Facebookgroup.length > 0 && info.Facebookgroup.map((i, index) => {
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell><span className='text-break amazon-url'>{i?.d?.name}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell></IndexTable.Cell>
                        <IndexTable.Cell></IndexTable.Cell>
                        <IndexTable.Cell></IndexTable.Cell>
                        <IndexTable.Cell>
                          <InlineStack>
                            <>
                              {
                                props.checkPermission('integration', 'isdelete', props.profile) &&
                                <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                  <Banner hideIcon tone='critical'>
                                    <Icon
                                      source={DeleteIcon}
                                      tone="critical"
                                    />
                                  </Banner>
                                </div>
                              }
                              <Box paddingInline={200}>
                                <div id="delete" onClick={() => syncGroups(i._id)}>
                                  <Banner hideIcon tone='warning'>
                                    <Icon
                                      source={RefreshIcon}
                                      tone="warning"
                                    />
                                  </Banner>
                                </div>
                              </Box>
                              {
                                props.checkPermission('integration', 'isadd', props.profile) &&
                                <Box paddingInline={200}>
                                  < Popover
                                    active={info.activePopover === index}
                                    activator={
                                      <Button
                                        onClick={() => {
                                          handleToggle(index);
                                        }}
                                      >
                                        <InlineStack>
                                          <div className='add-events'> Group list</div>
                                        </InlineStack>
                                      </Button>
                                    }
                                    onClose={() => changeNameValueCard({ activePopover: null })}
                                  >
                                    {i && i.d && i.d.groups && i.d.groups.length > 0 && i.d.groups.filter(x => !x.ia).length > 0 ? (
                                      <Card>
                                        <Autocomplete
                                          options={i.d.groups.filter(x => !x.ia).filter(obj => {
                                            let regexStr = new RegExp(info.searchFBGrp, 'i');
                                            return !info.searchFBGrp || obj.name.search(regexStr) !== -1;
                                          }).map(obj => ({
                                            value: obj.name, label: obj.name
                                          }))}
                                          selected={info.searchFBGrp}
                                          onSelect={(e) => handleData(i.d.groups.filter(x => !x.ia).map(obj => (obj)), e, i._id)}
                                          textField={textField}
                                        />
                                      </Card>
                                    ) : (
                                      <Box padding={400}>
                                        No record found
                                      </Box>
                                    )
                                    }
                                  </Popover>
                                </Box>
                              }
                            </>
                          </InlineStack>
                        </IndexTable.Cell>
                      </IndexTable.Row >

                      {i && i.d && i.d.groups && i.d.groups.length > 0 &&
                        i.d.groups.filter(x => x.ia === true).length > 0 ? i.d.groups.filter(x => x.ia === true).map((LIObj, index) => (
                          <IndexTable.Row key={index}>
                            <IndexTable.Cell></IndexTable.Cell>
                            <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd">
                                {LIObj.name}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Text variant="bodyMd">{LIObj.member_count}</Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              {moment(new Date(LIObj.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                            </IndexTable.Cell>
                            {props.checkPermission('integration', 'isdelete', props.profile) &&
                              <IndexTable.Cell>
                                <div id="delete" onClick={() => handleSubdeleteModal(i._id, LIObj.id)}>
                                  <Banner hideIcon tone="critical">
                                    <Icon source={DeleteIcon} tone="critical" />
                                  </Banner>
                                </div>
                              </IndexTable.Cell>
                            }
                          </IndexTable.Row>
                        ))
                        : null
                      }
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack >


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    if (info.apiD) {
                      handleDeleteSubData();
                    } else {
                      handleDeleteCall(info.dataId);
                    }
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default FacebookgroupfollowersCard;