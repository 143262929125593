import {
  Box,
  Card,
  IndexFilters,
  IndexTable,
  Page,
  useSetIndexFiltersMode,
  Grid,
  Text,
  Popover,
  InlineStack,
  DatePicker,
  useBreakpoints,
  Divider,
  ButtonGroup,
  Button,
  Modal,
  Tooltip,
  ActionList,
  BlockStack,
  InlineGrid,
  SkeletonDisplayText,
  SkeletonBodyText
} from '@shopify/polaris';
import React, { memo, useState } from 'react';
import MultiLine from './MultiLine';
import VisitorLine from './VisitorLine';
import ImpressionLine from './ImpressionLine';
import CloseLine from './CloseLine';
import HoverLine from './HoverLine';
import ClickLine from './ClickLine';
import EngagedLine from './EngagedLine';
import { IconChartDown_icon, IconChartUp_icon } from '../../img';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

function AnalyticsCard(props) {
  const { state, changeNameValue } = props;

  let notifData = props.props && props.props;
  const isLoading = useSelector(state => state?.loading?.isLoading);

  const { mode, setMode } = useSetIndexFiltersMode();
  const { mdDown, lgUp } = useBreakpoints();

  const checkValidDate = (dateString) => {
    if (dateString && !dateString.includes("Invalid")) {
      return true;
    } else {
      return false;
    }
  };

  const [activeDateRange, setActiveDateRange] = useState({
    since: new Date(new Date().setHours(0, 0, 0, 0)),
    until: new Date(new Date().setHours(0, 0, 0, 0))
  });

  const [{ month, year }, setDate] = useState({
    month: activeDateRange.since.getMonth(),
    year: activeDateRange.since.getFullYear()
  });

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.since.toDateString(),
      tdt: state.until.toDateString()
    }
    changeNameValue(obj);
  }

  const handleCalendarChange = ({ start, end }) => {
    const newDateRange = { since: start, until: end };

    changeNameValue({
      frmdt: newDateRange.since.toDateString(),
      tdt: newDateRange.until.toDateString()
    });
    activeRangeChange(newDateRange);
  };

  // for custom date popup open (same tab click, event is not coming)
  if (document.getElementById("Custom Date-3")) {
    document.getElementById("Custom Date-3").onclick = function () {
      changeNameValue({ popoverActive: true });
    };
  }

  return (
    <Page
      title={notifData?.notifName ? `${notifData.notifName} - Analytics` : 'Analytics'}
      primaryAction={props.checkPermission('analytic', 'isdownload', props.profile) &&
        <CSVLink data={state.AnalyticData} headers={state.AnalyticHeader} filename="Analytic_Report.csv">
          <Button variant='plain' onClick={() => props.downloadAnalytic()}>Download excel</Button>
        </CSVLink>
      }
      actionGroups={[
        {
          title: state.notifDomain.length === 0 ? 'All sites' : state.notifDomain[0]?.ht,
          actions: state.domainList,
        },

        ...(notifData.notifId
          ? []
          : [
            {
              title: state.notifList.length === 0 ? 'All notifications' : state.notifList[0]?.nn,
              actions: state.notifDataList,
            },
          ]),
      ]}
    >

      <Card padding={0}>

        <div id='analytic_filter'>
          <IndexFilters
            tabs={props.tabs}
            canCreateNewView={false}
            selected={state.selected}
            onSelect={(e) => changeNameValue({ selected: e })}
            mode={mode}
            setMode={setMode}
          />
        </div>
        <IndexTable
          itemCount={0}
          selectable={false}
          emptyState={[]}
          headings={[]}
        />
        {/* custom date popup */}
        <div className='analytic_cstm_dtPop' style={{ display: state.popoverActive ? 'block' : 'none' }}>
          <Box maxWidth='580px'>
            <Card padding={200}>
              <Popover.Pane>
                <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                  <InlineStack gap="400">
                    <DatePicker
                      month={month}
                      year={year}
                      selected={{
                        start: activeDateRange.since,
                        end: activeDateRange.until,
                      }}
                      onMonthChange={(month, year) => setDate({ month, year })}
                      onChange={handleCalendarChange}
                      disableDatesBefore={new Date(new Date().setHours(0, 0, 0, 0) - 61 * 24 * 60 * 60 * 1000)}
                      disableDatesAfter={new Date()}
                      multiMonth={lgUp}
                      allowRange />
                  </InlineStack>
                </Box>
              </Popover.Pane>

              <Divider />
              <Box padding={200}>
                <InlineStack gap={600} align="end" blockAlign='center'>
                  <Text>{checkValidDate(state.frmdt) && checkValidDate(state.tdt) ? state.frmdt + " - " + state.tdt : ""} </Text>
                  <ButtonGroup>
                    <Button variant='tertiary' onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                    <Button variant='primary' onClick={() => props.handleSelectCustomDate()}>Apply</Button>
                  </ButtonGroup>
                </InlineStack>
              </Box>

            </Card>
          </Box>
        </div>

        <Box paddingInlineStart={400} paddingInlineEnd={400} paddingBlockEnd={400}>
          <Grid columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}>
            <Card sectioned>
              <div style={{ height: '118px' }}>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content="Number of unique visitors who visited your websites." width="wide" padding="400">
                      <Text variant="headingSm" as="span">
                        Visitors
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        {/* <div style={{ cursor: 'pointer', color: '#645CFC' }} onClick={() => props.handleOpenModal()}> */}
                        <div style={{ color: '#645CFC' }}>
                          <Text variant="bodyLg" as="p" fontWeight="semibold">
                            {state.uniqueVisitor}
                          </Text>
                        </div>
                        {state.rt !== 'custom' && state.rt !== 'month' && state.perUnique !== '0' && (
                          <Box paddingInlineStart={200}>
                            <ButtonGroup>
                              <div className="chart-updown-arw">
                                {state.perUnique > 0 ? (
                                  <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                ) : (
                                  <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                )}
                              </div>
                              <Text variant="bodyLg" as="h5" fontWeight="semibold" tone={state.perUnique > 0 ? 'success' : 'critical'}>
                                {state.perUnique > 1000 ? 1000 : Math.abs(state.perUnique)}%
                              </Text>
                            </ButtonGroup>
                          </Box>
                        )}
                      </InlineStack>
                    </Box>
                    <div className="card-analytic-chart">
                      <VisitorLine vAry={state.vAry} date={state.arrDate} />
                    </div>
                  </>
                )}
              </div>
            </Card>


            <Card sectioned>
              <div style={{ height: '118px' }}>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content="Number of times notification impression happened on your site." width='wide' padding='400'>
                      <Text variant="headingSm" as="span">
                        Impressions
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        <div>
                          <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.iSum}</Text>
                        </div>
                        {(state.rt !== 'custom' && state.rt !== 'month' && state.perIsum !== '0') && (
                          <Box paddingInlineStart={200}>
                            <ButtonGroup>
                              <div className="chart-updown-arw">
                                {state.perIsum > 0 ? (
                                  <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                ) : (
                                  <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                )}
                              </div>
                              <Text
                                variant="bodyLg"
                                as="h5"
                                fontWeight="semibold"
                                tone={state.perIsum > 0 ? 'success' : 'critical'}
                              >
                                {state.perIsum > 1000 ? 1000 : Math.abs(state.perIsum)}%
                              </Text>
                            </ButtonGroup>
                          </Box>
                        )}
                      </InlineStack>
                    </Box>
                    <div className="card-analytic-chart">
                      <ImpressionLine
                        iAry={state.iAry}
                        date={state.arrDate}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>

            <Card sectioned>
              <div style={{ height: '118px' }}>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content="Number of times close clicks happened on your notifications." width='wide' padding='400'>
                      <Text variant="headingSm" as="span">
                        Close
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        <div>
                          <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.clsSum}</Text>
                        </div>
                      </InlineStack>
                    </Box>
                    <div className="card-analytic-chart">
                      <CloseLine
                        clsAry={state.clsAry}
                        date={state.arrDate}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>

            <Card sectioned>
              <div style={{ height: '118px' }}>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content="Number of times hovers happened on your notifications." width='wide' padding='400'>
                      <Text variant="headingSm" as="span">
                        Hovers
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        <div>
                          <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.hSum}</Text>
                        </div>
                        {(state.rt !== 'custom' && state.rt !== 'month' && state.perhSum !== '0') && (
                          <Box paddingInlineStart={200}>
                            <ButtonGroup>
                              <div className="chart-updown-arw">
                                {state.perhSum > 0 ? (
                                  <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                ) : (
                                  <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                )}
                              </div>
                              <Text
                                variant="bodyLg"
                                as="h5"
                                fontWeight="semibold"
                                tone={state.perhSum > 0 ? 'success' : 'critical'}
                              >
                                {state.perhSum > 1000 ? 1000 : Math.abs(state.perhSum)}%
                              </Text>
                            </ButtonGroup>
                          </Box>
                        )}
                      </InlineStack>
                    </Box>

                    <div className="card-analytic-chart">
                      <HoverLine
                        hAry={state.hAry}
                        date={state.arrDate}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>

            <Card sectioned>
              <div style={{ height: '118px' }}>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content="Number of clicks happened on your notifications." width='wide' padding='400'>
                      <Text variant="headingSm" as="span">
                        Clicks
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        <div>
                          <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.cSum}</Text>
                        </div>
                        {(state.rt !== 'custom' && state.rt !== 'month' && state.perCsum !== '0') && (
                          <Box paddingInlineStart={200}>
                            <ButtonGroup>
                              <div className="chart-updown-arw">
                                {state.perCsum > 0 ? (
                                  <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                ) : (
                                  <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                )}
                              </div>
                              <Text
                                variant="bodyLg"
                                as="h5"
                                fontWeight="semibold"
                                tone={state.perCsum > 0 ? 'success' : 'critical'}
                              >
                                {state.perCsum > 1000 ? 1000 : Math.abs(state.perCsum)}%
                              </Text>
                            </ButtonGroup>
                          </Box>
                        )}
                      </InlineStack>
                    </Box>

                    <div className="card-analytic-chart">
                      <ClickLine
                        cAry={state.cAry}
                        date={state.arrDate}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>

            <Card sectioned>
              <div style={{ height: '118px' }}>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content="Number of times total hover & clicks happened on your notifications." width='wide' padding='400'>
                      <Text variant="headingSm" as="span">
                        Engaged
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        <div>
                          <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.eSum}</Text>
                        </div>
                        {(state.rt !== 'custom' && state.rt !== 'month' && state.perEsum !== '0') && (
                          <Box paddingInlineStart={200}>
                            <ButtonGroup>
                              <div className="chart-updown-arw">
                                {state.perEsum > 0 ? (
                                  <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                ) : (
                                  <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                )}
                              </div>
                              <Text
                                variant="bodyLg"
                                as="h5"
                                fontWeight="semibold"
                                tone={state.perEsum > 0 ? 'success' : 'critical'}
                              >
                                {state.perEsum > 1000 ? 1000 : Math.abs(state.perEsum)}%
                              </Text>
                            </ButtonGroup>
                          </Box>
                        )}
                      </InlineStack>
                    </Box>
                    <div className="card-analytic-chart">
                      <EngagedLine
                        eAry={state.eAry}
                        date={state.arrDate}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>
          </Grid>
        </Box>

        <Box paddingInlineStart={400} paddingInlineEnd={400} paddingBlockEnd={400}>
          <Grid columns={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}>
            {state.nid && (
              <Card sectioned>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content='CTR is the number of click received on your notification divided by the number <br />of Visitors ( CTR= Clicks/Visitors).' width='wide' padding='400'>
                      <Text variant="headingSm" as="span" className="text-border">
                        CTR
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        <div>
                          <Text variant="bodyLg" as="p" fontWeight='semibold'>
                            {state.ctr ? state.ctr.toFixed(2) : 0}
                            {state.ctr > 0 && '%'}
                          </Text>
                        </div>
                      </InlineStack>
                    </Box>
                  </>
                )}
              </Card>
            )}

            <Card sectioned>
              {isLoading ? (
                <BlockStack gap={300}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={1} />
                </BlockStack>
              ) : (
                <>
                  <Tooltip hasUnderline content='Number of clicks happened on your "Call To Action" button or link on your notifications.' width='wide' padding='400'>
                    <Text variant="headingSm" as="span" className="text-border">
                      CTA
                    </Text>
                  </Tooltip>
                  <Box paddingBlockStart={200}>
                    <InlineStack gap={100}>
                      <div>
                        <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.cta}</Text>
                      </div>
                      {(state.rt !== 'custom' && state.rt !== 'month' && state.perCTA !== '0') && (
                        <Box paddingInlineStart={200}>
                          {state.perCTA !== 0 && (
                            <ButtonGroup>
                              <div className="chart-updown-arw">
                                {state.perCTA > 0 ? (
                                  <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                ) : (
                                  <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                )}
                              </div>
                              <Text
                                variant="bodyLg"
                                as="h5"
                                fontWeight="semibold"
                                tone={state.perCTA > 0 ? 'success' : 'critical'}
                              >
                                {state.perCTA > 1000 ? 1000 : Math.abs(state.perCTA)}%
                              </Text>
                            </ButtonGroup>
                          )}
                        </Box>
                      )}
                    </InlineStack>
                  </Box>
                </>
              )}
            </Card>
            {(
              !state.notiType ||
              state.notiType === 'collector' ||
              state.notiType === 'live_visitor' ||
              state.notiType === 'purchase_recent' ||
              state.notiType === 'signup_recent' ||
              state.notiType === 'single_visits' ||
              state.notiType === 'video_subscriber' ||
              state.notiType === 'channel_subscriber' ||
              state.notiType === 'signup_bulk' ||
              state.notiType === 'purchase_bulk' ||
              state.notiType === 'review_recent' ||
              state.notiType === 'limited_stock' ||
              state.notiType === 'recent_view_bulk'
            ) && (
                <Card sectioned>
                  {isLoading ? (
                    <BlockStack gap={300}>
                      <SkeletonDisplayText size="small" />
                      <SkeletonBodyText lines={1} />
                    </BlockStack>
                  ) : (
                    <>
                      <Tooltip hasUnderline content="Number of data received through integration & from your website." width='wide' padding='400'>
                        <Text variant="headingSm" as="span">
                          Data
                        </Text>
                      </Tooltip>
                      <Box paddingBlockStart={200}>
                        {!state.shwDataCnt ? (
                          <Button variant="plain" onClick={() => props.clickToShwCount()}>
                            Click to see the count
                          </Button>
                        ) : (
                          <Text variant="bodyLg" as="p" fontWeight="semibold">
                            {state.getSignUp ? state.getSignUp.signupCount : 0}
                          </Text>
                        )}
                      </Box>
                    </>
                  )}
                </Card>
              )}
            {!state.nid && (
              <Card sectioned>
                {isLoading ? (
                  <BlockStack gap={300}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={1} />
                  </BlockStack>
                ) : (
                  <>
                    <Tooltip hasUnderline content="Number of visitors submitted the form after watching the notification." width='wide' padding='400'>
                      <Text variant="headingSm" as="span">
                        Viewed & Submitted
                      </Text>
                    </Tooltip>
                    <Box paddingBlockStart={200}>
                      <InlineStack gap={100}>
                        <div>
                          <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.vs}</Text>
                        </div>
                        {(state.rt !== 'custom' && state.rt !== 'month' && state.perVs !== '0') && (
                          <Box paddingInlineStart={200}>
                            <ButtonGroup>
                              <div className="chart-updown-arw">
                                {state.perVs > 0 ? (
                                  <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                ) : (
                                  <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                )}
                              </div>
                              <Text
                                variant="bodyLg"
                                as="h5"
                                fontWeight="semibold"
                                tone={state.perVs > 0 ? 'success' : 'critical'}
                              >
                                {state.perVs > 1000 ? 1000 : Math.abs(state.perVs)}%
                              </Text>
                            </ButtonGroup>
                          </Box>
                        )}
                      </InlineStack>
                    </Box>
                  </>
                )}
              </Card>
            )}
            <Card sectioned>
              {isLoading ? (
                <BlockStack gap={300}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={1} />
                </BlockStack>
              ) : (
                <>
                  <Tooltip hasUnderline content="Number of visitors submitted the form after hovering the notifications." width='wide' padding='400'>
                    <Text variant="headingSm" as="span">
                      Hovered & Submitted
                    </Text>
                  </Tooltip>
                  <Box paddingBlockStart={200}>
                    <InlineStack gap={100}>
                      <div>
                        <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.hs}</Text>
                      </div>
                      {(state.rt !== 'custom' && state.rt !== 'month' && state.perHs !== '0') && (
                        <Box paddingInlineStart={200}>
                          <ButtonGroup>
                            <div className="chart-updown-arw">
                              {state.perHs > 0 ? (
                                <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                              ) : (
                                <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                              )}
                            </div>
                            <Text
                              variant="bodyLg"
                              as="h5"
                              fontWeight="semibold"
                              tone={state.perHs > 0 ? 'success' : 'critical'}
                            >
                              {state.perHs > 1000 ? 1000 : Math.abs(state.perHs)}%
                            </Text>
                          </ButtonGroup>
                        </Box>
                      )}
                    </InlineStack>
                  </Box>
                </>
              )}
            </Card>

            <Card sectioned>
              {isLoading ? (
                <BlockStack gap={300}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={1} />
                </BlockStack>
              ) : (
                <>
                  <Tooltip hasUnderline content="Number of visitors submitted the form after clicking on the notifications." width='wide' padding='400'>
                    <Text variant="headingSm" as="span">
                      Clicked & Submitted
                    </Text>
                  </Tooltip>
                  <Box paddingBlockStart={200}>
                    <InlineStack gap={100}>
                      <div>
                        <Text variant="bodyLg" as="p" fontWeight='semibold'>{state.cs}</Text>
                      </div>
                      {(state.rt !== 'custom' && state.rt !== 'month' && state.perCs !== '0') && (
                        <Box paddingInlineStart={200}>
                          <ButtonGroup>
                            <div className="chart-updown-arw">
                              {state.perCs > 0 ? (
                                <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                              ) : (
                                <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                              )}
                            </div>
                            <Text
                              variant="bodyLg"
                              as="h5"
                              fontWeight="semibold"
                              tone={state.perCs > 0 ? 'success' : 'critical'}
                            >
                              {state.perCs > 1000 ? 1000 : Math.abs(state.perCs)}%
                            </Text>
                          </ButtonGroup>
                        </Box>
                      )}
                    </InlineStack>
                  </Box>
                </>
              )}
            </Card>
          </Grid>
        </Box>

        <Box paddingInlineStart={400} paddingInlineEnd={400} paddingBlockEnd={400} id='viewgraph'>
          <MultiLine
            props={props}
            state={state}
            iAry={state.iAry}
            vAry={state.vAry}
            eAry={state.eAry}
            hAry={state.hAry}
            cAry={state.cAry}
            clsAry={state.clsAry}
            date={state.arrDate}
          />
        </Box>

        {/* <Box paddingInlineStart={400} paddingInlineEnd={400} paddingBlockEnd={400} id="viewgraph">
          {
            isLoading && state?.arrDate?.length > 0 ?
              <BlockStack gap={300}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={2} />
              </BlockStack>
              :
              <MultiLine
                props={props}
                state={state}
                iAry={state.iAry}
                vAry={state.vAry}
                eAry={state.eAry}
                hAry={state.hAry}
                cAry={state.cAry}
                clsAry={state.clsAry}
                date={state.arrDate}
              />
          }
        </Box> */}

      </Card>


      <Modal
        open={state.analyticOpen}
        onClose={() => props.handleCloseModal()}
        title="Visitor history"
        large
      >
        <div id='history_model'>
          <Modal.Section>
            <IndexTable
              id="modal-in-modal"
              resourceName={{ singular: 'visitor', plural: 'visitors' }}
              itemCount={state.vistHistPrData?.length || 0}
              headings={[
                { title: 'No' },
                { title: 'Viewed page' },
                { title: 'Event' },
                { title: 'Time' },
              ]}
              selectable={false}
            >
              {state.vistHistPrData?.map((visitor, index) => (
                <IndexTable.Row id={visitor.id} key={index} position={index}>
                  <IndexTable.Cell alignment="start" id='indexStyle'>{(index + 1)}</IndexTable.Cell>
                  <IndexTable.Cell id='hrefStyle'>
                    <a href={visitor.hrf} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                      {visitor.hrf}
                    </a>
                  </IndexTable.Cell>
                  <IndexTable.Cell id='evStyle'>{visitor.ev}</IndexTable.Cell>
                  <IndexTable.Cell id='timeStyle'>{props.days_between(visitor.t)}</IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </Modal.Section>
          {state.isShowLoadMore && state.hasMore && !state.isLoading && (
            <div style={{ textAlign: 'center', marginTop: '16px' }}>
              <Button
                onClick={() => props.getVistHist()}
                variant='plain'
              >
                Load more
              </Button>
            </div>
          )}
          {!state.isShowLoadMore && (
            <div style={{ textAlign: 'center', marginTop: '16px' }}>
              <Button variant='plain'>
                Loading
              </Button>
            </div>
          )}
        </div>
      </Modal>

    </Page>
  );
}

export default memo(AnalyticsCard);
