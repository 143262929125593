import React, { useEffect } from 'react';
import { Text, Popover, OptionList, RangeSlider, ChoiceList, Icon, Tooltip, Checkbox, InlineGrid, Button, Box, InlineStack, TextField, BlockStack, ButtonGroup, Badge } from '@shopify/polaris';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ThemeTemplateIcon, PlayIcon, ClipboardIcon, EditIcon, DragHandleIcon, CheckCircleIcon, CollectionFeaturedIcon, EyeCheckMarkIcon, ClockIcon, MoonIcon, SunIcon, DatabaseIcon, CalendarTimeIcon, DiscountCodeIcon, CursorIcon, CodeIcon, ButtonIcon, DeleteIcon } from '@shopify/polaris-icons';
import { isWhitelable } from '../../services/permissions';
import ColorPick from './ColorPick';
import Parser from 'html-react-parser';
import { useDispatch } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import { toastify } from '../../ducks/toast';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-emoji/dist/quill-emoji.css';
import 'react-quill/dist/quill.snow.css';
let isWhite = isWhitelable();

let arrChips = [];
const dateFormatOpt = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };

// Add fonts to whitelist
setTimeout(() => {
  var Font = Quill.import('formats/font');
  Font.whitelist = ['OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans', 'WebsiteFont'];
  Quill.register(Font, true);
}, 1000);

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

//get one emoji and replace it with our custom
const _emj = ShortNameEmoji.DEFAULTS.emojiList.filter(x => x.shortname === ':skull:');
if (_emj && _emj.length > 0) {
  let objEmj = _emj[0];
  objEmj.code_decimal = '&#x2b50;';
  objEmj.unicode = '2B50';
}

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

var custTagDropDown = Quill.import('ui/picker');
custTagDropDown = [];
Quill.register(custTagDropDown, true);

let dispatch;

const customHandler = {
  'color': function (value) {
    if (value === 'custom-color') value = window.prompt('Enter Hex Color Code');
    this.quill.format('color', value, 'user');
  },
  'background': function (value) {
    if (value === 'custom-background') value = window.prompt('Enter Hex Color Code');
    this.quill.format('background', value, 'user');
  },
  'custTagDropDown': function (value) {
    if (this.quill.getText().includes(value) !== true)
      this.quill.insertText(this.quill.getSelection().index, '{{' + value + '}}', 'user');
    else dispatch(toastify({ type: 'error', msg: '{{' + value + '}} already available' }));
  }
};

const customHandlerDrpDown = {
  'custTagDropDown': function (value) {
    if (this.quill.getText().includes(value) !== true)
      this.quill.insertText(this.quill.getSelection().index, '{{' + value + '}}', 'user');
    else
      dispatch(toastify({ type: 'error', msg: '{{' + value + '}} already available' }));
  }
};

const toolbarInlineModule = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': ['WebsiteFont', 'OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans'] }],
    [{ 'custTagDropDown': [] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [
      { 'list': 'ordered' }, { 'list': 'bullet' },
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean']
  ],
  handlers: customHandler
};

//for custom notification template.
const toolbarModuleFontSize = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': ['WebsiteFont', 'OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans'] }],
    ['bold', 'italic', 'underline'],
    [
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean'],
  ],
  handlers: customHandler
};

const toolbarModuleWebFont = {
  container: [
    [{ 'font': ['WebsiteFont', 'OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans'] }],
    ['bold', 'italic', 'underline'],
    [
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean'],
  ],
  handlers: customHandler
};

const toolbarModuleWithoutFontSize = {
  container: [
    [{ 'font': ['WebsiteFont', 'OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans'] }],
    [{ 'custTagDropDown': [] }],
    ['bold', 'italic', 'underline'],
    [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
    { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean']
  ],
  handlers: customHandler
};

const toolbarModule = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': ['WebsiteFont', 'OpenSans', 'Lato', 'Poppins', 'Raleway', 'Roboto', 'DMSans'] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [
      { 'list': 'ordered' }, { 'list': 'bullet' },
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'emoji', 'clean'],
  ],
  handlers: customHandler
};

const toolbarModuleEmlTemp = {
  container: [
    [{ 'custTagDropDown': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
  ],
  handlers: customHandlerDrpDown
};

const modulesPreventEnterQuill = {
  toolbar: toolbarModuleWithoutFontSize,
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function () { /** do nothing */ }
      },
      handleEnter: {
        key: 13,
        handler: function () { /** do nothing */ }
      },
      handleMouseClick: {
        handler: function () { console.log('click'); }
      }
    }
  },
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true
};

// for custom notification template.
const modulesFontSizeQuill = {
  toolbar: toolbarModuleFontSize,
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function () { /** do nothing */ }
      },
      handleEnter: {
        key: 13,
        handler: function () { /** do nothing */ }
      },
      handleMouseClick: {
        handler: function () { console.log('click'); }
      }
    }
  },
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true
};

// for custom notification template.
const modulesWebFontQuill = {
  toolbar: toolbarModuleWebFont,
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function () { /** do nothing */ }
      },
      handleEnter: {
        key: 13,
        handler: function () { /** do nothing */ }
      },
      handleMouseClick: {
        handler: function () { console.log('click'); }
      }
    }
  },
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true
};

// for email template only 2 option
const modulesQuillEmlTemp = {
  toolbar: toolbarModuleEmlTemp,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

//for inline module
const modulesInlineQuill = {
  toolbar: toolbarInlineModule,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

//for common module
const modulesQuill = {
  toolbar: toolbarModule,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

//for format quill
const formatsQuill = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent', 'align',
  'link', 'image', 'background', 'color', 'emoji'
];

function formateDate(dt) {
  const date = new Date(dt);
  return date.getFullYear() + '-' +
    String(date.getMonth() + 1).padStart(2, '0') + '-' +
    String(date.getDate()).padStart(2, '0') + 'T' +
    String(date.getHours()).padStart(2, '0') + ':' +
    String(date.getMinutes()).padStart(2, '0');
}



const TextCard = ({ props }) => {

  let { state, handleMainTextChange, handleTextChange, handleSettingChange, openCloseModal,
    changeNameValue, openIconModal, copyHTMLSnippet, addUtmSource, sendWebhookData,
    designValidator, addCouponcode, deletefieldinArw, AddfieldinArw, editorFocus, editorBlur } = props;

  let { cnf, notifId, notiById, queryNtt, queryNtyp, defaultNotif, paidType, iconTab, ProfileData, errMessage } = state;

  let billing = ProfileData?.billing;
  let isAbtest = state.abId !== null || false;

  //for hide template list div
  let isShowList = true;
  if (queryNtt === 'title_bar' || queryNtt === 'custom' ||
    queryNtt === 'video' || queryNtt === 'call_to_action' ||
    queryNtt === 'collector' || queryNtt === 'lottery' ||
    (notiById?.idisnp === true && notiById?.isnpwdsg !== true)) {
    isShowList = false;
  }

  let isWidget = false;
  if (!isShowList) { isWidget = true; }

  let dsplyRuleOpts = [
    {
      label: <Tooltip dismissOnMouseOut padding="400" content='Widget will get displayed when the visitor moves, cursor outside the upper page boundary.' width='wide' hasUnderline>
        <Text>Exit intent</Text></Tooltip>, value: 1
    },
    {
      label: <Tooltip dismissOnMouseOut padding="400" content={`After ${cnf?.aft} seconds, a widget will get displayed on-page.`} width='wide' hasUnderline>
        <Text>Show after {cnf?.aft || 'x'} second</Text></Tooltip>, value: 2
    },
    {
      label: <Tooltip dismissOnMouseOut padding="400" content={`Widget will get displayed once visitor scroll ${cnf?.sprc}% screen window.`} width='wide' hasUnderline>
        <Text>Show after {cnf?.sprc}% scroll</Text></Tooltip>, value: 3
    },
    {
      label: <Tooltip dismissOnMouseOut padding="400" content='You can set feed on your website using custom class.' width='wide' hasUnderline>
        <Text>Show on custom class click</Text></Tooltip>, value: 4
    },
    {
      label: <Tooltip dismissOnMouseOut padding="400" content='Widget will get displayed on custom function call.' width='wide' hasUnderline>
        <Text>None of the above</Text></Tooltip>, value: 5
    }
  ];

  let weekOpts = [
    { label: 'Sun', value: '0' },
    { label: 'Mon', value: '1' },
    { label: 'Tue', value: '2' },
    { label: 'Wed', value: '3' },
    { label: 'Thrd', value: '4' },
    { label: 'Fri', value: '5' },
    { label: 'Sat', value: '6' }
  ];

  let skipNotifTime = [
    { value: '1', label: '1 hour' },
    { value: '2', label: '2 hour' },
    { value: '3', label: '3 hour' },
    { value: '4', label: '4 hour' },
    { value: '5', label: '5 hour' },
    { value: '6', label: '6 hour' },
    { value: '7', label: '7 hour' },
    { value: '8', label: '8 hour' },
    { value: '9', label: '9 hour' },
    { value: '10', label: '10 hour' },
    { value: '11', label: '11 hour' },
    { value: '12', label: '12 hour' },
    { value: '18', label: '18 hour' },
    { value: '24', label: '24 hour' },
    { value: '36', label: '36 hour' },
    { value: '48', label: '48 hour' },
    { value: '60', label: '60 hour' }
  ];

  let notifPerPage = [
    { value: '0', label: 'All' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' }
  ];

  let notifTiming = [
    { value: '1', label: '1 second' },
    { value: '2', label: '2 second' },
    { value: '3', label: '3 second' },
    { value: '4', label: '4 second' },
    { value: '5', label: '5 second' },
    { value: '6', label: '6 second' },
    { value: '7', label: '7 second' },
    { value: '8', label: '8 second' },
    { value: '9', label: '9 second' },
    { value: '10', label: '10 second' },
    { value: '11', label: '11 second' },
    { value: '12', label: '12 second' },
    { value: '13', label: '13 second' },
    { value: '14', label: '14 second' },
    { value: '15', label: '15 second' },
    { value: '16', label: '16 second' },
    { value: '17', label: '17 second' },
    { value: '18', label: '18 second' },
    { value: '19', label: '19 second' },
    { value: '20', label: '20 second' },
    { value: '21', label: '21 second' },
    { value: '22', label: '22 second' },
    { value: '23', label: '23 second' },
    { value: '24', label: '24 second' },
    { value: '25', label: '25 second' },
    { value: '26', label: '26 second' },
    { value: '27', label: '27 second' },
    { value: '28', label: '28 second' },
    { value: '29', label: '29 second' },
    { value: '30', label: '30 second' },
    { value: '40', label: '40 second' },
    { value: '50', label: '50 second' },
    { value: '100', label: '100 second' },
    { value: '150', label: '150 second' },
    { value: '200', label: '200 second' },
    { value: '250', label: '250 second' },
    { value: '300', label: '300 second' },
    { value: '350', label: '350 second' },
    { value: '400', label: '400 second' },
    { value: '500', label: '500 second' },
    { value: '600', label: '600 second' },
    { value: '700', label: '700 second' },
    { value: '800', label: '800 second' },
    { value: '900', label: '900 second' },
    { value: '1000', label: '1000 second' },
    { value: '1200', label: '1200 second' },
    { value: '1400', label: '1400 second' },
    { value: '1600', label: '1600 second' },
    { value: '1800', label: '1800 second' },
    { value: '2000', label: '2000 second' },
    { value: '2200', label: '2200 second' },
    { value: '2400', label: '2400 second' },
    { value: '2600', label: '2600 second' },
    { value: '2800', label: '2800 second' },
    { value: '3000', label: '3000 second' },
    { value: '3500', label: '3500 second' },
    { value: '4000', label: '4000 second' },
    { value: '5000', label: '5000 second' }
  ];

  let delayNotifTime = [
    { value: '2', label: '2 second' },
    { value: '3', label: '3 second' },
    { value: '4', label: '4 second' },
    { value: '5', label: '5 second' },
    { value: '6', label: '6 second' },
    { value: '7', label: '7 second' },
    { value: '8', label: '8 second' },
    { value: '9', label: '9 second' },
    { value: '10', label: '10 second' },
    { value: '11', label: '11 second' },
    { value: '12', label: '12 second' },
    { value: '13', label: '13 second' },
    { value: '14', label: '14 second' },
    { value: '15', label: '15 second' },
    { value: '16', label: '16 second' },
    { value: '17', label: '17 second' },
    { value: '18', label: '18 second' },
    { value: '19', label: '19 second' },
    { value: '20', label: '20 second' },
    { value: '21', label: '21 second' },
    { value: '22', label: '22 second' },
    { value: '23', label: '23 second' },
    { value: '24', label: '24 second' },
    { value: '25', label: '25 second' },
    { value: '26', label: '26 second' },
    { value: '27', label: '27 second' },
    { value: '28', label: '28 second' },
    { value: '29', label: '29 second' },
    { value: '30', label: '30 second' },
    { value: '40', label: '40 second' },
    { value: '50', label: '50 second' },
    { value: '60', label: '60 second' },
    { value: '100', label: '100 second' },
    { value: '150', label: '150 second' },
    { value: '200', label: '200 second' },
    { value: '250', label: '250 second' },
    { value: '300', label: '300 second' },
    { value: '350', label: '350 second' },
    { value: '400', label: '400 second' },
    { value: '500', label: '500 second' },
    { value: '600', label: '600 second' },
    { value: '700', label: '700 second' },
    { value: '800', label: '800 second' },
    { value: '900', label: '900 second' },
    { value: '1000', label: '1000 second' }
  ];

  let displayMinutesHours = [
    { value: '1', label: '1 minute' },
    { value: '2', label: '2 minute' },
    { value: '3', label: '3 minute' },
    { value: '4', label: '4 minute' },
    { value: '5', label: '5 minute' },
    { value: '10', label: '10 minute' },
    { value: '15', label: '15 minute' },
    { value: '20', label: '20 minute' },
    { value: '25', label: '25 minute' },
    { value: '30', label: '30 minute' },
    { value: '35', label: '35 minute' },
    { value: '40', label: '40 minute' },
    { value: '45', label: '45 minute' },
    { value: '50', label: '50 minute' },
    { value: '55', label: '55 minute' },
    { value: '60', label: '1 hour' },
    { value: '120', label: '2 hour' },
    { value: '180', label: '3 hour' },
    { value: '240', label: '4 hour' },
    { value: '300', label: '5 hour' },
    { value: '360', label: '6 hour' },
    { value: '420', label: '7 hour' },
    { value: '480', label: '8 hour' },
    { value: '540', label: '9 hour' },
    { value: '600', label: '10 hour' },
    { value: '660', label: '11 hour' },
    { value: '720', label: '12 hour' },
    { value: '780', label: '13 hour' },
    { value: '840', label: '14 hour' },
    { value: '900', label: '15 hour' },
    { value: '960', label: '16 hour' },
    { value: '1020', label: '17 hour' },
    { value: '1080', label: '18 hour' },
    { value: '1140', label: '19 hour' },
    { value: '1200', label: '20 hour' },
    { value: '1260', label: '21 hour' },
    { value: '1320', label: '22 hour' },
    { value: '1380', label: '23 hour' },
    { value: '1440', label: '24 hour' }
  ];

  // only for single_visits
  if (queryNtt === 'single_visits') {
    displayMinutesHours = [
      { value: '1', label: '1 hour' },
      { value: '2', label: '2 hour' },
      { value: '3', label: '3 hour' },
      { value: '4', label: '4 hour' },
      { value: '5', label: '5 hour' },
      { value: '6', label: '6 hour' },
      { value: '7', label: '7 hour' },
      { value: '8', label: '8 hour' },
      { value: '9', label: '9 hour' },
      { value: '10', label: '10 hour' },
      { value: '11', label: '11 hour' },
      { value: '12', label: '12 hour' },
      { value: '13', label: '13 hour' },
      { value: '14', label: '14 hour' },
      { value: '15', label: '15 hour' },
      { value: '16', label: '16 hour' },
      { value: '17', label: '17 hour' },
      { value: '18', label: '18 hour' },
      { value: '19', label: '19 hour' },
      { value: '20', label: '20 hour' },
      { value: '21', label: '21 hour' },
      { value: '22', label: '22 hour' },
      { value: '23', label: '23 hour' },
      { value: '24', label: '24 hour' }
    ];
  }

  let borderStyle = [
    { value: 'solid', label: 'Solid' },
    { value: 'dashed', label: 'Dashed' },
    { value: 'dotted', label: 'Dotted' },
    { value: 'double', label: 'Double' }
  ];

  const disType = [
    { value: 'normal', label: 'Normal' },
    { value: 'blinking', label: 'Blinking' },
    { value: 'scrolling', label: 'Scrolling' }
  ];

  //animition for desktop
  const descAni = [
    { value: 'none', label: 'None' },
    { value: 'fade', label: 'Fade' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'zoom_in_down', label: 'Zoom in down' },
    { value: 'slide', label: 'Slide' },
    { value: 'horizonatal_slide', label: 'Slide horizontal' },
    { value: 'side_flip_in', label: 'Slide flip in' },
    { value: 'side_flip_back', label: 'Slide flip back' },
    { value: 'flip', label: 'Flip' },
    { value: 'up_flip', label: 'Flip up' },
    { value: 'back_flip', label: 'Flip back' }
  ];

  //animition for phone
  const phoneAni = [
    { value: 'none', label: 'None' },
    { value: 'fade', label: 'Fade' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'zoom', label: 'Zoom' },
    { value: 'zoom_in_down', label: 'Zoom in down' },
    { value: 'slide', label: 'Slide' },
    { value: 'horizonatal_slide', label: 'Slide horizontal' },
    { value: 'side_flip_in', label: 'Slide flip in' },
    { value: 'side_flip_back', label: 'Slide flip back' },
    { value: 'flip', label: 'Flip' },
    { value: 'up_flip', label: 'Flip up' },
    { value: 'back_flip', label: 'Flip back' }
  ];

  //position in desktop
  let deskPosition = [];
  if (defaultNotif?.ttn === 'promotional_banner_timer'
    && (defaultNotif?.tn !== 'Timer-3' && defaultNotif?.tn !== 'Timer-7' &&
      defaultNotif?.tn !== 'Timer-3' && defaultNotif?.tn !== 'Timer-9')) {
    deskPosition = [
      { value: 'banner_top', label: 'Top' },
      { value: 'banner_center', label: 'Center' },
      { value: 'banner_bottom', label: 'Bottom' }
    ];
  }
  else {
    deskPosition = [
      { value: 'left_top', label: 'Left top' },
      { value: 'center_top', label: 'Center top' },
      { value: 'right_top', label: 'Right top' },
      { value: 'left_center', label: 'Left center' },
      { value: 'right_center', label: 'Right center' },
      { value: 'left_bottom', label: 'Left bottom' },
      { value: 'center_bottom', label: 'Center bottom' },
      { value: 'right_bottom', label: 'Right bottom' }
    ];
  }

  //position in phone
  let phonePosition = [];
  if (queryNtt === 'video') {
    phonePosition = [{ value: 'center', label: 'Center' }];
  }
  else if (queryNtt === 'live_visitor' || queryNtt === 'purchase_recent' ||
    queryNtt === 'signup_recent' || queryNtt === 'single_visits' ||
    queryNtt === 'review_recent' || queryNtt === 'purchase_bulk' ||
    queryNtt === 'signup_bulk' || queryNtt === 'review_bulk' ||
    queryNtt === 'recent_view_bulk' || queryNtt === 'channel_subscriber' ||
    queryNtt === 'video_subscriber' || queryNtt === 'custom' ||
    queryNtt === 'social_media_post' || queryNtt === 'social_media_count' ||
    (defaultNotif?.tn === 'Timer-3' || defaultNotif?.tn === 'Timer-7' ||
      defaultNotif?.tn === 'Timer-8' || defaultNotif?.tn === 'Timer-9')) {
    // for short width timer notif add left position class
    phonePosition = [
      { value: 'left_top', label: 'Top' },
      { value: 'left_center', label: 'Center' },
      { value: 'left_bottom', label: 'Bottom' }
    ];
  }
  else if (defaultNotif?.ttn === 'promotional_banner_timer') {
    phonePosition = [  //for full screen view add banner class(ex. Timer:-1,2,4,6,10)
      { value: 'banner_top', label: 'Top' },
      { value: 'banner_center', label: 'Center' },
      { value: 'banner_bottom', label: 'Bottom' }
    ];
  }
  else if (queryNtt === 'collector' || queryNtt === 'call_to_action' || queryNtt === 'lottery') {
    phonePosition = [
      { value: 'left_top', label: 'Left top' },
      { value: 'center_top', label: 'Center top' },
      { value: 'right_top', label: 'Right top' },
      { value: 'left_center', label: 'Left center' },
      { value: 'center', label: 'Center' },
      { value: 'right_center', label: 'Right center' },
      { value: 'left_bottom', label: 'Left bottom' },
      { value: 'center_bottom', label: 'Center bottom' },
      { value: 'right_bottom', label: 'Right bottom' }
    ];
  }

  let btnPosition = [
    { value: 'vertical', label: 'Vertical' },
    { value: 'horizontal', label: 'Horizontal' }
  ];

  let timeStemp = [
    { value: 'day', label: 'Day' },
    { value: 'month', label: 'Month' },
    { value: 'hour', label: 'Hour' },
    { value: 'second', label: 'Second' }
  ];

  let timeStempSingle = [
    { value: 'hour', label: 'Hour' }
  ];

  let fontFamilyOpts = [
    { value: 'Open sans', label: 'Open Sans' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Poppins', label: 'Poppins' },
    { value: 'Raleway', label: 'Raleway' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Circular Std Book', label: 'Circular Book' },
    { value: ' ', label: 'Website Font' }
  ];

  let fontStyleOpts = [
    { value: 'normal', label: 'Normal' },
    { value: 'bold', label: 'Bold' }
  ];

  let fontSizeOpts = [
    { value: '10', label: '10' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '16', label: '16' },
    { value: '18', label: '18' },
    { value: '20', label: '20' },
    { value: '22', label: '22' },
    { value: '24', label: '24' },
    { value: '26', label: '26' },
    { value: '28', label: '28' }
  ];

  let timerFontSize = [
    { value: '14', label: '14' },
    { value: '16', label: '16' },
    { value: '18', label: '18' },
    { value: '20', label: '20' },
    { value: '22', label: '22' },
    { value: '24', label: '24' },
    { value: '26', label: '26' },
    { value: '28', label: '28' },
    { value: '30', label: '30' },
    { value: '32', label: '32' },
    { value: '34', label: '34' },
    { value: '36', label: '36' },
    { value: '38', label: '38' },
    { value: '40', label: '40' }
  ];

  let TimerAction = [
    { value: '1', label: 'Hide' },
    { value: '2', label: 'Restart' }
  ];

  let displayTime = [
    { value: 'day', label: 'Days' },
    { value: 'hour', label: 'Hours' }
  ];

  let isSocialNotif = false;
  //is notification type
  if (queryNtt === 'purchase_recent' || queryNtt === 'purchase_bulk' ||
    queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' ||
    queryNtt === 'review_recent' || queryNtt === 'review_bulk' ||
    queryNtt === 'live_visitor' || queryNtt === 'single_visits' ||
    queryNtt === 'recent_view_bulk' || queryNtt === 'limited_stock' ||
    queryNtt === 'channel_subscriber' || queryNtt === 'video_subscriber' ||
    queryNtt === 'social_media_post' || queryNtt === 'social_media_count' ||
    queryNtt === 'live_visitor_ecomm_snippet' || queryNtt === 'limited_stock_ecomm_snippet' ||
    queryNtt === 'purchase_recent_ecomm_snippet' || queryNtt === 'purchase_bulk_ecomm_snippet') {
    isSocialNotif = true;
  }

  let isCTARadio = false;
  if (queryNtt === 'purchase_recent' || queryNtt === 'purchase_bulk' ||
    queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' ||
    queryNtt === 'review_recent' || queryNtt === 'review_bulk' || queryNtt === 'recent_view_bulk' ||
    queryNtt === 'single_visits' || queryNtt === 'social_media_count' ||
    queryNtt === 'channel_subscriber' || queryNtt === 'video_subscriber' ||
    queryNtt === 'video' || (queryNtt === 'call_to_action' &&
      (defaultNotif?.tn === 'Timer-3' || defaultNotif?.tn === 'Timer-9' || defaultNotif?.tn === 'Timer-10')) ||
    (queryNtt === 'custom' && (defaultNotif?.ttn === 'custom_14'))) {
    isCTARadio = true;
  }

  let ctaLinkOpts = [
    { value: 0, label: 'Redirect to custom URL' },
    { value: 1, label: 'Redirect to dynamic URL' },
    {
      value: 2, label: <Tooltip dismissOnMouseOut padding="400" content='On cta click open collector' hasUnderline width='wide'>
        <Text>Open collector</Text></Tooltip>
    },
    { value: 3, label: 'None of the above' },
    { value: 4, label: 'Close the widget' },
    { value: 5, label: 'Copy code' }
  ];

  // hide show conditions
  ctaLinkOpts = ctaLinkOpts.filter(option => {
    if (option.value === 1) { //show value-1 opt only if return condition true
      return queryNtt === 'purchase_recent' || queryNtt === 'review_recent' || queryNtt === 'social_media_count';
    }
    else if (option.value === 2) {  //show value-2 opt only if return condition true
      return queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' || queryNtt === 'single_visits';
    }
    else if (option.value === 4) {
      return queryNtt === 'video';
    }
    else if (option.value === 5) {  //add condition for show copy code functionality show
      return (defaultNotif?.tn === 'Timer-3' || defaultNotif?.tn === 'Timer-9' ||
        defaultNotif?.tn === 'Timer-10' || defaultNotif?.ttn === 'custom_14');
    }
    else return true; // Keep all other options
  });

  let isNotifRadio = false;
  if (queryNtt === 'purchase_recent' || queryNtt === 'review_recent' ||
    queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' ||
    queryNtt === 'channel_subscriber' || queryNtt === 'video_subscriber' ||
    queryNtt === 'social_media_count' || queryNtt === 'social_media_post' ||
    queryNtt === 'single_visits') {
    isNotifRadio = true;
  }

  let notifLinkOpts = [
    {
      value: 0, label: <Tooltip dismissOnMouseOut padding="400" content='Enter the redirect URL' hasUnderline width='wide'><Text>Redirect to custom URL</Text></Tooltip>
    },
    {
      value: 1, label: <Tooltip dismissOnMouseOut padding="400" content='Enter the dynamic URL' hasUnderline width='wide'><Text>Redirect to dynamic URL</Text></Tooltip>
    },
    {
      value: 2, label: <Tooltip dismissOnMouseOut padding="400" content='Open collector on notification click' hasUnderline width='wide'><Text>Open collector</Text></Tooltip>
    }
  ];

  // hide show conditions
  notifLinkOpts = notifLinkOpts.filter(option => {
    if (option.value === 1) { //show value-1 opt only if return condition true
      return (queryNtt === 'review_recent' || queryNtt === 'purchase_recent' || queryNtt === 'video_subscriber' ||
        queryNtt === 'channel_subscriber' || queryNtt === 'social_media_post' || queryNtt === 'social_media_count');
    }
    else if (option.value === 2) {  //show value-2 opt only if return condition true
      return (queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' || queryNtt === 'single_visits');
    }
    else return true; // Keep all other options
  });

  const langOptions = [
    { value: 'en', label: 'English' },
    { value: 'af', label: 'Afrikaans' },
    { value: 'ar', label: 'Arabic' },
    { value: 'zh-CN', label: 'Chinese(Simplified)' },
    { value: 'zh-TW', label: 'Chinese(Traditional)' },
    { value: 'cs', label: 'Czech' },
    { value: 'nl', label: 'Dutch' },
    { value: 'et', label: 'Estonian' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'el', label: 'Greek' },
    { value: 'iw', label: 'Hebrew' },
    { value: 'hi', label: 'Hindi' },
    { value: 'hu', label: 'Hungarian' },
    { value: 'id', label: 'Indonesian' },
    { value: 'it', label: 'Italian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
    { value: 'pl', label: 'Polish' },
    { value: 'pt', label: 'Portuguese(Portugal, Brazil)' },
    { value: 'ro', label: 'Romanian' },
    { value: 'ru', label: 'Russian' },
    { value: 'sk', label: 'Slovak' },
    { value: 'sl', label: 'Slovenian' },
    { value: 'es', label: 'Spanish' },
    { value: 'sv', label: 'Swedish' },
    { value: 'ta', label: 'Tamil' },
    { value: 'th', label: 'Thai' },
    { value: 'tr', label: 'Turkish' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'ur', label: 'Urdu' },
    { value: 'vi', label: 'Vietnamese' }
  ];

  let isCollector = false;
  if (queryNtt === 'purchase_recent' || queryNtt === 'purchase_bulk' ||
    queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' ||
    queryNtt === 'review_recent' || queryNtt === 'review_bulk' ||
    queryNtt === 'live_visitor' || queryNtt === 'single_visits' ||
    queryNtt === 'channel_subscriber' || queryNtt === 'video_subscriber' ||
    queryNtt === 'recent_view_bulk' || queryNtt === 'custom' ||
    queryNtt === 'social_media_post' || queryNtt === 'social_media_count' ||
    queryNtt === 'limited_stock' || (defaultNotif?.ttn === 'promotional_banner_timer')) {
    isCollector = true;
  }

  let showImgPadding = true;
  if (queryNtt === 'call_to_action' || queryNtt === 'video' || queryNtt === 'collector') {
    showImgPadding = false;
  }

  let shwCmpctView = false;
  if ((queryNtt === 'review_recent' || queryNtt === 'review_bulk' ||
    queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' ||
    queryNtt === 'purchase_recent' || queryNtt === 'purchase_bulk' ||
    queryNtt === 'recent_view_bulk' || queryNtt === 'single_visits' ||
    queryNtt === 'live_visitor' || queryNtt === 'channel_subscriber' ||
    queryNtt === 'video_subscriber' || queryNtt === 'custom' ||
    queryNtt === 'call_to_action') &&
    defaultNotif?.ttn !== 'recent_view_7' &&
    defaultNotif?.ttn !== 'bulk_purchase_8' &&
    defaultNotif?.ttn !== 'bulk_signup_8' &&
    defaultNotif?.ttn !== 'product_purchase_9' &&
    defaultNotif?.ttn !== 'product_purchase_15' &&
    defaultNotif?.ttn !== 'recent_signup_8' &&
    defaultNotif?.ttn !== 'recent_signup_14' &&
    defaultNotif?.ttn !== 'recent_signup_17' &&
    defaultNotif?.ttn !== 'live_visitor_4' &&
    (defaultNotif?.ttn === 'promotional_banner_timer' && defaultNotif?.tn === 'Timer-9') ||
    defaultNotif?.ttn !== 'promotional_banner_timer') {
    shwCmpctView = true;
  }

  let shape_height = 80;
  if (notiById?.dctp === 'eighty')
    shape_height = 80;
  else if (notiById?.dctp === 'ninety')
    shape_height = 90;
  else if (notiById?.dctp === 'hundred')
    shape_height = 100;
  else if (notiById?.dctp === 'oneten')
    shape_height = 110;

  dispatch = useDispatch();

  arrChips = [];
  if (queryNtt === 'live_visitor') arrChips = ['COUNT'];
  else if (queryNtt === 'purchase_recent') arrChips = ['ANONYMOUS', 'F_NAME', 'L_NAME', 'USERNAME', 'CITY', 'STATE', 'COUNTRY', 'PRODUCT_NAME', 'TIME_AGO', 'COUNTRY_FLAG', 'CUSTOM_1', 'CUSTOM_2', 'CUSTOM_3'];
  else if (queryNtt === 'signup_recent') arrChips = ['ANONYMOUS', 'F_NAME', 'L_NAME', 'USERNAME', 'CITY', 'STATE', 'COUNTRY', 'TIME_AGO', 'COUNTRY_FLAG', 'CUSTOM_1', 'CUSTOM_2', 'CUSTOM_3'];
  else if (queryNtt === 'single_visits') arrChips = ['ANONYMOUS', 'CITY', 'STATE', 'COUNTRY', 'TIME_AGO', 'COUNTRY_FLAG', 'CUSTOM_1', 'CUSTOM_2', 'CUSTOM_3'];
  else if (queryNtt === 'review_bulk') arrChips = ['COUNT', 'REVIEW_APP_IMG', 'FB_ICON', 'GGL_ICON', 'PAGE_NAME'];
  else if (queryNtt === 'social_media_count') arrChips = ['COUNT', 'REVIEW_APP_IMG', 'PAGE_NAME', 'RATING_HTML'];
  else if (queryNtt === 'signup_bulk' || queryNtt === 'purchase_bulk' || queryNtt === 'recent_view_bulk') arrChips = ['COUNT', 'TIME_AGO'];
  else if (queryNtt === 'review_recent' && notiById?.idisnp === true) arrChips = ['ANONYMOUS', 'F_NAME', 'L_NAME', 'USERNAME', 'REVIEW_APP_IMG', 'REVIEW_TEXT', 'RATING_HTML', 'CITY', 'STATE', 'COUNTRY', 'TIME_AGO', 'REVIEW_APP', 'CUSTOM_1', 'CUSTOM_2', 'CUSTOM_3'];
  else if (queryNtt === 'review_recent') arrChips = ['ANONYMOUS', 'F_NAME', 'L_NAME', 'USERNAME', 'REVIEW_APP_IMG', 'REVIEW_TEXT', 'CITY', 'STATE', 'COUNTRY', 'TIME_AGO', 'REVIEW_APP', 'CUSTOM_1', 'CUSTOM_2', 'CUSTOM_3'];
  else if (queryNtt === 'social_media_post') arrChips = ['ANONYMOUS', 'F_NAME', 'L_NAME', 'USERNAME', 'REVIEW_APP_IMG', 'REVIEW_TEXT', 'CITY', 'STATE', 'COUNTRY', 'CUSTOM_1', 'CUSTOM_2', 'CUSTOM_3'];
  else if (queryNtt === 'limited_stock') arrChips = ['ITEM_COUNT', 'VIEWER_COUNT'];
  else if (queryNtt === 'purchase_recent_ecomm_snippet') arrChips = ['F_NAME', 'L_NAME', 'USERNAME', 'CITY', 'STATE', 'COUNTRY', 'TIME_AGO'];
  else if (queryNtt === 'purchase_bulk_ecomm_snippet') arrChips = ['COUNT', 'TIME_AGO'];
  else if (queryNtt === 'live_visitor_ecomm_snippet') arrChips = ['COUNT'];
  else if (queryNtt === 'channel_subscriber' && notiById?.idisnp === true) arrChips = ['PAGE_SUB_CNT', 'PAGE_NAME', 'PAGE_VIEW_CNT', 'PAGE_CMNT_CNT', 'PAGE_VIDEO_CNT'];
  else if (queryNtt === 'video_subscriber' && notiById?.idisnp === true) arrChips = ['PAGE_NAME', 'PAGE_VIEW_CNT', 'PAGE_CMNT_CNT', 'PAGE_LIKE_CNT', 'PAGE_FAV_CNT'];
  else if (queryNtt === 'channel_subscriber') arrChips = ['PAGE_SUB_CNT', 'PAGE_NAME'];
  else if (queryNtt === 'video_subscriber') arrChips = ['PAGE_NAME'];
  else if (queryNtt === 'lottery') arrChips = ['COUPON_CODE'];

  useEffect(() => {
    changeNameValue({ arrChips });
  }, []);

  //create dynamic value dropdown for editor
  let arrSetChips = ['USERNAME', 'EMAIL', 'PHONE', 'MESSAGE', 'CITY', 'STATE', 'DATE', 'COUNTRY', 'IP_ADDRESS', 'PAGE_URL', 'HOST_NAME', 'LATITUDE', 'LONGITUDE'];

  var style = document.createElement('style');
  document.head.appendChild(style);

  toolbarModuleEmlTemp.container[0][0].custTagDropDown = arrSetChips;
  for (let i = 0; i < arrSetChips.length; i++) {
    const e = arrSetChips[i];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  toolbarModule.container[0][0].custTagDropDown = arrChips;
  for (let i = 0; i < arrChips.length; i++) {
    const e = arrChips[i];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  toolbarModuleWithoutFontSize.container[1][0].custTagDropDown = arrChips;
  for (let i = 0; i < arrChips.length; i++) {
    const e = arrChips[i];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  toolbarInlineModule.container[2][0].custTagDropDown = arrChips;
  for (let i = 0; i < arrChips.length; i++) {
    const e = arrChips[i];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  // Handles drag end event for icon field
  const onDragEnd = (result) => {
    // console.log(result,"......result")
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(cnf?.it);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    handleMainTextChange('it', reorderedItems); //set value in cnf field
  };

  // Function to generate time options
  const generateTimeOptions = (interval = 5) => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += interval) {
        const formattedTime = new Date(0, 0, 0, hour, minutes).toLocaleTimeString('en-US', {
          hour: '2-digit', minute: '2-digit', hour12: true
        });
        options.push({ label: formattedTime, value: formattedTime });
      }
    }
    return options;
  };
  const timeOptions = generateTimeOptions();

  let modulesFrstLine = modulesPreventEnterQuill;
  let modulesSecLine = modulesPreventEnterQuill;
  let modulesThirdLine = modulesPreventEnterQuill;
  let modulesFourLine = modulesPreventEnterQuill;
  let modulesFifthLine = modulesPreventEnterQuill;
  let modulesSixLine = modulesPreventEnterQuill;
  let modulesSvnLine = modulesPreventEnterQuill;

  if (queryNtt === 'call_to_action' || queryNtt === 'collector' ||
    queryNtt === 'lottery' || defaultNotif?.ttn === 'custom_6') {
    modulesFrstLine = modulesQuill;
  }

  if (queryNtt === 'lottery' || defaultNotif?.ttn === 'custom_6' ||
    (queryNtt === 'collector' && (defaultNotif?.ttn === 'call_back_1' || defaultNotif?.ttn === 'call_back_3')) ||
    (queryNtt === 'call_to_action' && defaultNotif?.ttn !== 'call_back_2')) {
    modulesSecLine = modulesQuill;
  }

  if (queryNtt === 'lottery' || queryNtt === 'collector' ||
    defaultNotif?.ttn === 'call_back_1' || defaultNotif?.ttn === 'call_back_3' ||
    (queryNtt === 'call_to_action' && defaultNotif?.ttn !== 'call_back_2')) {
    modulesThirdLine = modulesQuill;
  }

  if (queryNtt === 'collector' || queryNtt === 'call_to_action' || queryNtt === 'lottery') {
    modulesFourLine = modulesQuill;
  }

  if (queryNtt === 'collector' || queryNtt === 'call_to_action') {
    modulesFifthLine = modulesQuill;
    modulesSixLine = modulesQuill;
    modulesSvnLine = modulesQuill;
  }
  else if (queryNtt === 'lottery') {
    modulesFifthLine = modulesInlineQuill;
  }

  if (queryNtt === 'custom') {
    modulesFrstLine = modulesFontSizeQuill;
    modulesSecLine = modulesFontSizeQuill;
    modulesThirdLine = modulesFontSizeQuill;
    modulesFourLine = modulesFontSizeQuill;
  }

  // tabs array 
  let verticalTabsArr = [
    { label: 'Text editor', img: ThemeTemplateIcon },
    { label: 'Click actions', img: CursorIcon },
    { label: 'Position & animation', img: ButtonIcon },
    { label: 'Style', img: EyeCheckMarkIcon },
    { label: 'Set countdown timer', img: CalendarTimeIcon },
    { label: 'Embed code', img: CodeIcon },
    { label: 'Data control', img: DatabaseIcon },
    { label: 'Timing control', img: ClockIcon },
    { label: 'Verify label', img: CheckCircleIcon },
    { label: 'Collector', img: CollectionFeaturedIcon },
    { label: 'Coupon code', img: DiscountCodeIcon }
  ];

  function getValue(lbl) {
    if (!lbl) return '';
    return lbl.replace(/ /g, '').replace(/^./, char => char.toLowerCase())
  }


  useEffect(() => {
    verticalTabsArr = verticalTabsArr
      .map(tab => { return { ...tab, value: getValue(tab.label) } })
      .filter(tab => {

        if (tab.label === 'Click actions') {
          return (!notiById?.idisnp || queryNtt === 'call_to_action') &&
            queryNtt !== 'live_visitor_ecomm_snippet' && queryNtt !== 'purchase_bulk_ecomm_snippet' &&
            queryNtt !== 'purchase_recent_ecomm_snippet' && queryNtt !== 'limited_stock_ecomm_snippet' &&
            (cnf?.cl !== undefined || cnf?.cb !== undefined || cnf?.nc !== undefined);
        }
        if (tab.label === 'Verify label') {
          return cnf?.ib !== undefined && !notiById?.idisnp &&
            queryNtt !== 'live_visitor_ecomm_snippet' &&
            defaultNotif?.ttn !== 'recent_review_4'
        }

        if (tab.label === 'Collector') {
          return (!notiById?.idisnp && (queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' || queryNtt === 'single_visits') || ((cnf?.isnd !== undefined || cnf?.isdeml !== undefined) && isAbtest === false));
        }

        if (tab.label === 'Style') {
          return cnf && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            queryNtt !== 'title_bar' &&
            defaultNotif?.ttn !== 'recent_review_6' && defaultNotif?.ttn !== 'recent_review_8' &&
            defaultNotif?.ttn !== 'recent_review_10' && defaultNotif?.ttn !== 'recent_review_11' &&
            defaultNotif?.ttn !== 'recent_review_12' && defaultNotif?.ttn !== 'bulk_purchase_8' &&
            defaultNotif?.ttn !== 'product_purchase_9' && defaultNotif?.ttn !== 'product_purchase_15' &&
            defaultNotif?.ttn !== 'recent_signup_8' && defaultNotif?.ttn !== 'recent_signup_17' &&
            defaultNotif?.ttn !== 'recent_signup_14' && defaultNotif?.ttn !== 'bulk_signup_8' &&
            defaultNotif?.ttn !== 'live_visitor_4' && defaultNotif?.ttn !== 'recent_view_7' &&
            (cnf?.rc !== undefined || cnf?.rw !== undefined || cnf?.rs !== undefined || defaultNotif?.idcss === true || cnf?.r !== undefined || cnf?.bg)
        }

        if (tab.label === 'Data control') {
          return queryNtt !== 'title_bar';
        }

        if (tab.label === 'Timing control') {
          return (queryNtt === 'custom' || queryNtt === 'video' ||
            queryNtt === 'call_to_action' || queryNtt === 'all_in_one' ||
            queryNtt === 'social_media_followers' || queryNtt === 'lottery' ||
            queryNtt === 'whatsapp_chat' || queryNtt === 'review_feed' ||
            queryNtt === 'collector' || queryNtt === 'offer') &&
            cnf?.dtrg !== undefined && (notiById?.icstlp !== undefined || notiById?.idisnp === true)
            ||   // condition for (setTiming) tab
            (queryNtt !== 'limited_stock' &&
              (isSocialNotif === true || (queryNtt === 'custom' && !notiById?.idisnp) ||
                queryNtt === 'social_media_followers'));
        }
        if (tab.label === 'Set countdown timer') {
          return cnf?.tmdr !== undefined;
        }
        if (tab.label === 'Coupon code') {
          return cnf?.arrlcyltry !== undefined;
        }
        return true;
      });

    changeNameValue({ verticalTabsArr });
  }, [queryNtt, notiById, defaultNotif]);

  return (
    <Box padding={400}>

      {state.verticalActive === getValue('Text editor') ?
        <BlockStack gap={600}>

          {/* for blinking tab only */}
          {(queryNtt === 'title_bar' && cnf?.tltyp !== undefined) &&
            <div>
              {cnf?.fl !== undefined &&
                <BlockStack gap={200}>
                  <TextField
                    autoComplete='off'
                    value={cnf?.fl?.txt}
                    label={<Tooltip dismissOnMouseOut padding="400" content='Write 1st line text' width='wide' hasUnderline><Text fontWeight='medium'>Line 1</Text></Tooltip>}
                    onChange={(e) => handleTextChange('fl', 'txt', e)}
                    error={errMessage?.firstLine ? errMessage.firstLine : false} />
                  {designValidator.message('firstLine', cnf?.fl.txt, 'required')}

                  <div className="draggable-item">
                    <Box padding={100}>
                      <BlockStack gap={300}>
                        <Text variant='bodyXs' tone='subdued'>Icon</Text>
                        <img width='30' src={cnf?.fliu} alt="Image" />
                      </BlockStack>
                    </Box>
                    <Button id='upldIcn' onClick={() => openIconModal('fliu')} icon={EditIcon} variant='monochromePlain' />
                  </div>
                </BlockStack>
              }

              {cnf?.sl !== undefined && cnf?.tltyp === 'blinking' &&
                <BlockStack gap={200}>
                  <TextField
                    autoComplete='off'
                    value={cnf?.sl?.txt}
                    label={<Tooltip dismissOnMouseOut padding="400" content='Write 2nd line text' hasUnderline width='wide'><Text fontWeight='medium'>Line 2</Text></Tooltip>}
                    onChange={(e) => handleTextChange('sl', 'txt', e)}
                    error={errMessage?.secondLine ? errMessage.secondLine : false} />
                  {designValidator.message('secondLine', cnf?.sl.txt, 'required')}

                  <div className="draggable-item">
                    <Box padding={100}>
                      <BlockStack gap={300}>
                        <Text variant='bodyXs' tone='subdued'>Icon</Text>
                        <img width='30' src={cnf?.sliu} alt="Image" />
                      </BlockStack>
                    </Box>
                    <Button id='upldIcn' onClick={() => openIconModal('sliu')} icon={EditIcon} variant='monochromePlain' />
                  </div>
                </BlockStack>
              }
            </div>
          }

          {/* mobile view for inline notif */}
          {cnf?.flecmmbl !== undefined &&
            <div>
              <Tooltip dismissOnMouseOut padding="400" content={'Set text for mobile view.'} hasUnderline>
                <Text fontWeight='medium'>For mobile view</Text>
              </Tooltip>
              <Box paddingInlineStart={400} paddingBlockStart={100}>
                <ReactQuill value={cnf?.flecmmbl?.txt} className='preview-mail hideEditor flecmmbl'
                  modules={modulesInlineQuill}
                  formats={formatsQuill}
                  onFocus={() => editorFocus('flecmmbl')}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') { handleTextChange('flecmmbl', 'txt', newValue) }
                  }} />
              </Box>
            </div>
          }

          {/* desktop view for inline notif */}
          {cnf?.flecmdsk !== undefined &&
            <div>
              <Tooltip dismissOnMouseOut padding="400" content={'Set text for desktop view.'} hasUnderline>
                <Text fontWeight='medium'>For desktop view</Text>
              </Tooltip>
              <Box paddingInlineStart={400} paddingBlockStart={100}>
                <ReactQuill value={cnf?.flecmdsk?.txt} className='preview-mail hideEditor flecmdsk'
                  modules={modulesInlineQuill}
                  formats={formatsQuill}
                  onFocus={() => editorFocus('flecmdsk')}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') { handleTextChange('flecmdsk', 'txt', newValue) }
                  }} />
              </Box>
            </div>
          }

          {/* counter block */}
          {cnf?.cnt !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            <div>
              <Text fontWeight='medium'>{queryNtt === 'limited_stock' ? 'Stock counter' : 'Enter counter'}</Text>
              <Box paddingInlineStart={400} paddingBlockStart={100}>
                <ReactQuill value={cnf?.cnt?.txt} className='preview-mail hideEditor cnttxt'
                  modules={modulesPreventEnterQuill}
                  formats={formatsQuill}
                  onFocus={() => editorFocus('cnttxt')}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') { handleTextChange('cnt', 'txt', newValue, 'removespace') }
                  }} />
              </Box>
            </div>
          }

          {/* for limited stock only */}
          {cnf?.cntv !== undefined &&
            <div>
              <Text fontWeight='medium'>Viewer counter</Text>
              <Box paddingInlineStart={400} paddingBlockStart={100}>
                <ReactQuill value={cnf?.cntv?.txt} className='preview-mail hideEditor cntvtxt'
                  modules={modulesPreventEnterQuill}
                  formats={formatsQuill}
                  onFocus={() => editorFocus('cntvtxt')}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') { handleTextChange('cntv', 'txt', newValue, 'removespace') }
                  }} />
              </Box>
            </div>
          }

          {/* text editor's seven lines */}
          {(cnf?.fl || cnf?.sl || cnf?.tl || cnf?.frtl || cnf?.fftl || cnf?.sxtl || cnf?.svntl) &&
            queryNtt !== 'title_bar' &&
            <div>

              <Tooltip padding='400' width='wide' hasUnderline
                content={queryNtyp === 'widget' ?
                  'Customize the text using bold, underlines, and emojis to make the notification message more appealing and engaging' :
                  'Edit notification text using dynamic values to show real-time action details'}>
                <Text fontWeight='medium'>Text editor</Text>
              </Tooltip>

              <Box paddingInlineStart={400} paddingBlockStart={100}>
                <BlockStack gap={300}>

                  {/* first line */}
                  {cnf?.fl !== undefined &&
                    (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <ReactQuill value={cnf?.fl?.txt} className='preview-mail hideEditor fltxt'
                      modules={modulesFrstLine}
                      formats={formatsQuill}
                      onFocus={() => editorFocus('fltxt')}
                      onBlur={() => editorBlur('fltxt')}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleTextChange('fl', 'txt', newValue, 'removespace') }
                      }} />
                  }

                  {/* second line */}
                  {cnf?.sl !== undefined && queryNtt !== 'title_bar' &&
                    (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <div>
                      {cnf?.iphn !== undefined && cnf?.peml === undefined &&
                        <Checkbox
                          label={<Text>Phone</Text>}
                          checked={cnf?.iphn}
                          onChange={(e) => handleMainTextChange('iphn', e)} />
                      }
                      {defaultNotif?.ttn === 'call_back_2' ? // 'contact' widget
                        <TextField
                          type='tel'
                          multiline
                          autoComplete='off'
                          value={cnf?.sl?.txt}
                          onChange={(e) => handleTextChange('sl', 'txt', e)} />
                        :
                        <ReactQuill value={cnf?.sl?.txt} className='preview-mail hideEditor sltxt'
                          modules={modulesSecLine}
                          formats={formatsQuill}
                          onFocus={() => editorFocus('sltxt')}
                          onBlur={() => editorBlur('sltxt')}
                          onChange={(newValue, delta, source) => {
                            if (source === 'user') { handleTextChange('sl', 'txt', newValue, 'removespace') }
                          }} />
                      }
                    </div>
                  }

                  {defaultNotif?.ttn === 'call_back_2' && // 'contact' widget
                    <Box paddingBlockEnd={300}>
                      <BlockStack gap={300}>
                        <InlineGrid columns={2} gap={200}>
                          {cnf?.sl.fml !== undefined &&
                            <div>
                              <Box paddingBlockEnd={100}><Text tone='subdued'>Font-family</Text></Box>
                              <Popover
                                fullWidth
                                active={state.isCtaPhnFamily}
                                onClose={() => changeNameValue({ isCtaPhnFamily: !state.isCtaPhnFamily })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isCtaPhnFamily: !state.isCtaPhnFamily })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {fontFamilyOpts.find(({ value }) => value === cnf?.sl?.fml)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={fontFamilyOpts}
                                  selected={[cnf?.sl?.fml]}
                                  onChange={() => changeNameValue({ isCtaPhnFamily: !state.isCtaPhnFamily })}
                                  onFocusOption={(e) => handleTextChange('sl', 'fml', e)} />
                              </Popover>
                            </div>
                          }
                          {cnf?.sl.stl !== undefined &&
                            <div>
                              <Box paddingBlockEnd={100}><Text tone='subdued'>Font-weight</Text></Box>
                              <Popover
                                fullWidth
                                active={state.isCtaPhnStyle}
                                onClose={() => changeNameValue({ isCtaPhnStyle: !state.isCtaPhnStyle })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isCtaPhnStyle: !state.isCtaPhnStyle })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {fontStyleOpts.find(({ value }) => value === cnf?.sl?.stl)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={fontStyleOpts}
                                  selected={[cnf?.sl?.stl]}
                                  onChange={() => changeNameValue({ isCtaPhnStyle: !state.isCtaPhnStyle })}
                                  onFocusOption={(e) => handleTextChange('sl', 'stl', e)} />
                              </Popover>
                            </div>
                          }
                        </InlineGrid>

                        {cnf?.sl?.sz !== undefined &&
                          <InlineGrid columns={2} gap={200}>
                            <div>
                              <Box paddingBlockEnd={100}><Text tone='subdued'>Font-size</Text></Box>
                              <Popover
                                fullWidth
                                active={state.isCtaPhnFsize}
                                onClose={() => changeNameValue({ isCtaPhnFsize: !state.isCtaPhnFsize })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isCtaPhnFsize: !state.isCtaPhnFsize })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {fontSizeOpts.find(({ value }) => value == cnf?.sl?.sz)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={fontSizeOpts}
                                  selected={[cnf?.sl?.sz.toString()]}
                                  onChange={() => changeNameValue({ isCtaPhnFsize: !state.isCtaPhnFsize })}
                                  onFocusOption={(e) => handleTextChange('sl', 'sz', e)} />
                              </Popover>
                            </div>
                            <ColorPick
                              labelName={<Text tone='subdued'>Text color</Text>}
                              colorKey="sl"
                              secondKey="clr"
                              colorValue={cnf?.sl?.clr}
                              functionCall={handleTextChange} />
                          </InlineGrid>
                        }
                      </BlockStack>
                    </Box>
                  }

                  {/*third line*/}
                  {cnf?.tl !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <div>
                      {cnf?.ieml !== undefined && cnf?.peml === undefined &&
                        <Checkbox
                          label={<Text>Email</Text>}
                          checked={cnf?.ieml}
                          onChange={(e) => handleMainTextChange('ieml', e)} />
                      }
                      {defaultNotif?.ttn === 'call_back_2' ?
                        <TextField
                          type='email'
                          value={cnf?.tl.txt}
                          onChange={(e) => handleTextChange('tl', 'txt', e)} />
                        :
                        <ReactQuill value={cnf?.tl?.txt} className='preview-mail hideEditor tltxt'
                          modules={modulesThirdLine}
                          formats={formatsQuill}
                          onFocus={() => editorFocus('tltxt')}
                          onBlur={() => editorBlur('tltxt')}
                          onChange={(newValue, delta, source) => {
                            if (source === 'user') { handleTextChange('tl', 'txt', newValue, 'removespace') }
                          }} />
                      }
                    </div>
                  }

                  {defaultNotif?.ttn === 'call_back_2' && // 'contact' widget
                    <Box paddingBlockEnd={300}>
                      <BlockStack gap={300}>
                        <InlineGrid columns={2} gap={200}>
                          {cnf?.tl?.fml !== undefined &&
                            <div>
                              <Box paddingBlockEnd={100}><Text tone='subdued'>Font-family</Text></Box>
                              <Popover
                                fullWidth
                                active={state.isCtaEMLFamily}
                                onClose={() => changeNameValue({ isCtaEMLFamily: !state.isCtaEMLFamily })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isCtaEMLFamily: !state.isCtaEMLFamily })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {fontFamilyOpts.find(({ value }) => value === cnf?.tl?.fml)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={fontFamilyOpts}
                                  selected={[cnf?.tl?.fml]}
                                  onChange={() => changeNameValue({ isCtaEMLFamily: !state.isCtaEMLFamily })}
                                  onFocusOption={(e) => handleTextChange('tl', 'fml', e)} />
                              </Popover>
                            </div>
                          }
                          {cnf?.tl?.stl !== undefined &&
                            <div>
                              <Box paddingBlockEnd={100}><Text tone='subdued'>Font-weight</Text></Box>
                              <Popover
                                fullWidth
                                active={state.isCtaEMLStyle}
                                onClose={() => changeNameValue({ isCtaEMLStyle: !state.isCtaEMLStyle })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isCtaEMLStyle: !state.isCtaEMLStyle })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {fontStyleOpts.find(({ value }) => value === cnf?.tl?.stl)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={fontStyleOpts}
                                  selected={[cnf?.tl?.stl]}
                                  onChange={() => changeNameValue({ isCtaEMLStyle: !state.isCtaEMLStyle })}
                                  onFocusOption={(e) => handleTextChange('tl', 'stl', e)} />
                              </Popover>
                            </div>
                          }
                        </InlineGrid>

                        {cnf?.tl?.sz !== undefined &&
                          <InlineGrid columns={2} gap={200}>
                            <div>
                              <Box paddingBlockEnd={100}><Text tone='subdued'>Font-size</Text></Box>
                              <Popover
                                fullWidth
                                active={state.isCtaEMLFsize}
                                onClose={() => changeNameValue({ isCtaEMLFsize: !state.isCtaEMLFsize })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isCtaEMLFsize: !state.isCtaEMLFsize })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {fontSizeOpts.find(({ value }) => value == cnf?.tl?.sz)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={fontSizeOpts}
                                  selected={[cnf?.tl?.sz.toString()]}
                                  onChange={() => changeNameValue({ isCtaEMLFsize: !state.isCtaEMLFsize })}
                                  onFocusOption={(e) => handleTextChange('tl', 'sz', e)} />
                              </Popover>
                            </div>
                            <ColorPick
                              labelName={<Text tone='subdued'>Text color</Text>}
                              colorKey="tl"
                              secondKey="clr"
                              colorValue={cnf?.tl?.clr || '#fff'}
                              functionCall={handleTextChange} />
                          </InlineGrid>
                        }
                      </BlockStack>
                    </Box>
                  }

                  {/* forth line */}
                  {cnf?.frtl !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <ReactQuill value={cnf?.frtl?.txt} className='preview-mail hideEditor frtltxt'
                      modules={modulesFourLine}
                      formats={formatsQuill}
                      onFocus={() => editorFocus('frtltxt')}
                      onBlur={() => editorBlur('frtltxt')}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleTextChange('frtl', 'txt', newValue, 'removespace') }
                      }} />
                  }

                  {/* fifth line */}
                  {cnf?.fftl !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <ReactQuill value={cnf?.fftl?.txt} className='preview-mail hideEditor fftltxt'
                      modules={modulesFifthLine} formats={formatsQuill}
                      onFocus={() => editorFocus('fftltxt')}
                      onBlur={() => editorBlur('fftltxt')}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleTextChange('fftl', 'txt', newValue, 'removespace') }
                      }} />
                  }

                  {/* sixth line */}
                  {cnf?.sxtl !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <ReactQuill value={cnf?.sxtl?.txt} className='preview-mail hideEditor sxtltxt'
                      modules={modulesSixLine} formats={formatsQuill}
                      onFocus={() => editorFocus('sxtltxt')}
                      onBlur={() => editorBlur('sxtltxt')}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleTextChange('sxtl', 'txt', newValue, 'removespace') }
                      }} />
                  }

                  {/* seventh line */}
                  {cnf?.svntl !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <ReactQuill value={cnf?.svntl?.txt} className='preview-mail hideEditor svntltxt'
                      modules={modulesSvnLine} formats={formatsQuill}
                      onFocus={() => editorFocus('svntltxt')}
                      onBlur={() => editorBlur('svntltxt')}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleTextChange('svntl', 'txt', newValue, 'removespace') }
                      }} />
                  }

                  {/* isTruncate  */}
                  {cnf?.itrmmbl !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enable to shorten the text for mobile view only' width='wide' hasUnderline><Text> Truncate on Mobile </Text></Tooltip>}
                      checked={cnf?.itrmmbl}
                      onChange={(e) => handleMainTextChange('itrmmbl', e)} />
                  }

                  {/* for HTML snippet */}
                  {notiById?.idisnp === true && notiById?.isnpwdsg !== true &&
                    <ReactQuill value={cnf?.fdln} className='preview-mail hideEditor fdlntxt'
                      modules={modulesInlineQuill} formats={formatsQuill}
                      onFocus={() => editorFocus('fdlntxt')}
                      onBlur={() => editorBlur('fdlntxt')}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleMainTextChange('fdln', newValue) }
                      }} />
                  }
                </BlockStack>
              </Box>
            </div>
          }

          {/* privacy policy text */}
          {cnf?.clplcy !== undefined && queryNtt === 'collector' &&
            <div>
              <InlineStack align='space-between'>
                <Checkbox
                  label={<Tooltip dismissOnMouseOut padding="400" content='Write your text & set your privacy policy link' hasUnderline width='wide'>
                    <Text fontWeight='medium'>Privacy policy</Text></Tooltip>}
                  checked={cnf?.iclplcy}
                  onChange={(e) => handleMainTextChange('iclplcy', e)}
                />
              </InlineStack>
              <Box paddingInlineStart={700}>
                <ReactQuill value={cnf?.clplcy} className='preview-mail hideEditor plcytxt'
                  modules={modulesQuill} formats={formatsQuill}
                  onFocus={() => editorFocus('plcytxt')}
                  onBlur={() => editorBlur('plcytxt')}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') { handleMainTextChange('clplcy', newValue) }
                  }} />
              </Box>
            </div>
          }

          {/* display username input */}
          {queryNtt !== 'live_visitor' && queryNtt !== 'purchase_recent' &&
            queryNtt !== 'signup_recent' && queryNtt !== 'review_bulk' &&
            queryNtt !== 'signup_bulk' && queryNtt !== 'purchase_bulk' &&
            queryNtt !== 'review_recent' && queryNtt !== 'recent_view_bulk' &&
            queryNtt !== 'channel_subscriber' && queryNtt !== 'video_subscriber' &&
            queryNtt !== 'custom' && queryNtt !== 'single_visits' &&
            cnf?.punm !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            <div>
              <InlineStack align='space-between'>
                <Checkbox
                  label={<Tooltip dismissOnMouseOut padding="400" content='Write placeholder for username input' width='wide' hasUnderline><Text fontWeight='medium'>Ask username</Text></Tooltip>}
                  checked={cnf?.iunm}
                  onChange={(e) => handleMainTextChange('iunm', e)}
                />
                <Checkbox
                  label={<Text>Mandatory</Text>}
                  checked={cnf?.iucmlsry}
                  onChange={(e) => handleMainTextChange('iucmlsry', e)}
                />
              </InlineStack>
              <Box paddingInlineStart={700} paddingBlockStart={100}>
                <TextField
                  value={cnf?.punm}
                  onChange={(e) => handleMainTextChange('punm', e)} />
              </Box>
            </div>
          }

          {/* display email input */}
          {queryNtt !== 'live_visitor' && queryNtt !== 'purchase_recent' &&
            queryNtt !== 'signup_recent' && queryNtt !== 'review_bulk' &&
            queryNtt !== 'signup_bulk' && queryNtt !== 'purchase_bulk' &&
            queryNtt !== 'review_recent' && queryNtt !== 'recent_view_bulk' &&
            queryNtt !== 'channel_subscriber' && queryNtt !== 'video_subscriber' &&
            queryNtt !== 'custom' && queryNtt !== 'single_visits' &&
            queryNtt !== 'social_media_post' && queryNtt !== 'social_media_count' &&
            queryNtt !== 'live_visitor_ecomm_snippet' &&
            cnf?.peml !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            <div>
              <InlineStack align='space-between'>
                <Checkbox
                  label={<Tooltip dismissOnMouseOut padding="400" content='Write placeholder for email input' width='wide' hasUnderline>
                    <Text fontWeight='medium'>Ask e-mail</Text></Tooltip>}
                  checked={cnf?.ieml}
                  onChange={(e) => handleMainTextChange('ieml', e)}
                />
                <Checkbox
                  label={<Text>Mandatory</Text>}
                  checked={cnf?.iecmlsry}
                  onChange={(e) => handleMainTextChange('iecmlsry', e)}
                />
              </InlineStack>
              <Box paddingInlineStart={700} paddingBlockStart={100}>
                <TextField
                  value={cnf?.peml}
                  onChange={(e) => handleMainTextChange('peml', e)} />
              </Box>
            </div>
          }

          {/* display number input */}
          {queryNtt !== 'live_visitor' && queryNtt !== 'purchase_recent' &&
            queryNtt !== 'signup_recent' && queryNtt !== 'review_bulk' &&
            queryNtt !== 'signup_bulk' && queryNtt !== 'purchase_bulk' &&
            queryNtt !== 'review_recent' && queryNtt !== 'recent_view_bulk' &&
            queryNtt !== 'channel_subscriber' && queryNtt !== 'video_subscriber' &&
            queryNtt !== 'custom' && queryNtt !== 'single_visits' &&
            queryNtt !== 'social_media_post' && queryNtt !== 'social_media_count' &&
            queryNtt !== 'live_visitor_ecomm_snippet' &&
            cnf?.pphn !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            <div>
              <InlineStack align='space-between'>
                <Checkbox
                  label={<Tooltip dismissOnMouseOut padding="400" content='Write placeholder for number input' width='wide' hasUnderline>
                    <Text fontWeight='medium'>Ask number</Text></Tooltip>}
                  checked={cnf?.iphn}
                  onChange={(e) => handleMainTextChange('iphn', e)}
                />
                <Checkbox
                  label={<Text>Mandatory</Text>}
                  checked={cnf?.ipcmlsry}
                  onChange={(e) => handleMainTextChange('ipcmlsry', e)}
                />
              </InlineStack>
              <Box paddingInlineStart={700} paddingBlockStart={100}>
                <TextField
                  value={cnf?.pphn}
                  onChange={(e) => handleMainTextChange('pphn', e)} />
              </Box>
            </div>
          }

          {/* display message input */}
          {queryNtt !== 'live_visitor' && queryNtt !== 'purchase_recent' &&
            queryNtt !== 'signup_recent' && queryNtt !== 'review_bulk' &&
            queryNtt !== 'signup_bulk' && queryNtt !== 'purchase_bulk' &&
            queryNtt !== 'review_recent' && queryNtt !== 'recent_view_bulk' &&
            queryNtt !== 'channel_subscriber' && queryNtt !== 'video_subscriber' &&
            queryNtt !== 'custom' && queryNtt !== 'single_visits' &&
            cnf?.pmsg !== undefined && (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            <div>
              <InlineStack align='space-between'>
                <InlineStack blockAlign='center'>
                  {cnf?.imsg !== undefined ?
                    <Checkbox
                      checked={cnf?.imsg}
                      onChange={(e) => handleMainTextChange('imsg', e)} />
                    :
                    <Box paddingInlineStart={500}></Box>
                  }
                  <Tooltip dismissOnMouseOut padding="400" width='wide' hasUnderline content='Write placeholder for feedback input'>
                    <Text fontWeight='medium'>Message box</Text></Tooltip>
                </InlineStack>
                <Checkbox
                  label={<Text>Mandatory</Text>}
                  checked={cnf?.imcmlsry}
                  onChange={(e) => handleMainTextChange('imcmlsry', e)}
                />
              </InlineStack>
              <Box paddingInlineStart={700} paddingBlockStart={100}>
                <TextField
                  value={cnf?.pmsg}
                  onChange={(e) => handleMainTextChange('pmsg', e)} />
              </Box>
            </div>
          }

          {/* for YouTube channel/video */}
          {(notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            (queryNtt === 'channel_subscriber' || queryNtt === 'video_subscriber') &&
            <div>
              <Tooltip dismissOnMouseOut padding="400" content='Counter text formmating' width='wide' hasUnderline>
                <Text fontWeight='medium'>Counter text</Text>
              </Tooltip>
              <Box paddingInlineStart={300} paddingBlockStart={200}>
                <InlineGrid columns={2} gap={200}>
                  <div>
                    <Box paddingBlockEnd={100}><Text>Font-family</Text></Box>
                    <Popover
                      fullWidth
                      active={state.isCnFnt}
                      onClose={() => changeNameValue({ isCnFnt: !state.isCnFnt })}
                      activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                        onClick={() => changeNameValue({ isCnFnt: !state.isCnFnt })}>
                        <Text variant='bodyMd' tone='subdued'>
                          {fontFamilyOpts.find(({ value }) => value === cnf?.ytbcntffl)?.label || 'Select'}
                        </Text>
                      </Button>}>
                      <OptionList
                        options={fontFamilyOpts}
                        selected={[cnf?.ytbcntffl] || ['']}
                        onChange={() => changeNameValue({ isCnFnt: !state.isCnFnt })}
                        onFocusOption={(e) => handleMainTextChange('ytbcntffl', e)} />
                    </Popover>
                  </div>

                  <div>
                    <Box paddingBlockEnd={100}><Text>Font-Style</Text></Box>
                    <Popover
                      fullWidth
                      active={state.isCnStyle}
                      onClose={() => changeNameValue({ isCnStyle: !state.isCnStyle })}
                      activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                        onClick={() => changeNameValue({ isCnStyle: !state.isCnStyle })}>
                        <Text variant='bodyMd' tone='subdued'>
                          {fontStyleOpts.find(({ value }) => value === cnf?.ytbcntfwt)?.label || 'Select'}
                        </Text>
                      </Button>}>
                      <OptionList
                        options={fontStyleOpts}
                        selected={[cnf?.ytbcntfwt] || ['']}
                        onChange={() => changeNameValue({ isCnStyle: !state.isCnStyle })}
                        onFocusOption={(e) => handleMainTextChange('ytbcntfwt', e)} />
                    </Popover>
                  </div>

                  <ColorPick
                    labelName='Text color'
                    colorKey="ytbcntclr"
                    colorValue={cnf?.ytbcntclr}
                    functionCall={handleMainTextChange} />

                </InlineGrid>
              </Box>
            </div>
          }

          {/* Icon img upload & size */}
          {cnf && (cnf?.iu !== undefined || cnf?.ibg !== undefined) &&
            (notiById?.idisnp !== true || notiById?.isnpwdsg === true) &&
            <div>
              {(defaultNotif?.iit === true && cnf?.iimg !== undefined &&
                defaultNotif?.ttn !== 'recent_signup_8' &&
                defaultNotif?.ttn !== 'recent_signup_17' &&
                defaultNotif?.ttn !== 'product_purchase_9' &&
                defaultNotif?.ttn !== 'product_purchase_15' &&
                defaultNotif?.ttn !== 'all_in_one' &&
                defaultNotif?.ttn !== 'recent_review_6') ?
                <Checkbox
                  label={<Tooltip dismissOnMouseOut padding="400" content='Customise icon image or background color' width='wide' hasUnderline><Text fontWeight='medium'>Media (Image)</Text></Tooltip>}
                  checked={cnf?.iimg}
                  onChange={(e) => handleMainTextChange('iimg', e)} />
                :
                <Box paddingInlineStart={300} paddingBlockEnd={100}>
                  <Tooltip dismissOnMouseOut padding="400" content='Customise icon image or background color' width='wide' hasUnderline><Text fontWeight='medium'>Media (Image)</Text></Tooltip>
                </Box>
              }
              <Box paddingInlineStart={700}>
                {cnf?.it?.length > 1 &&
                  <Box paddingBlockEnd={200}><Text variant='bodySm'>Drag & drop to set priority order (Icon fallback order)</Text></Box>}

                {defaultNotif?.iit === true && cnf?.it !== undefined &&
                  <Box paddingBlockStart={200} paddingBlockEnd={800}>
                    <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                      <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                          <div className='draggable_wrap'
                            ref={provided.innerRef}
                            {...provided.droppableProps}>

                            {cnf?.it?.map((item, index) => (
                              <Draggable key={item} draggableId={String(item)} index={index}>
                                {(provided) => (
                                  <div className="draggable-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Icon tone='base' source={DragHandleIcon} />
                                    {item === 0 ?
                                      <label onClick={() => changeNameValue({ iconTab: 0 })}
                                        className={iconTab === 0 ? 'smooth-dnd-active' : ''}>
                                        <Text variant='bodyXs' tone='subdued'>Icon</Text>
                                        <img width='30' src={cnf?.iu}
                                          style={{ background: defaultNotif?.tn === 'Timer-10' ? cnf?.clbg : cnf?.ibg ? cnf?.ibg : (cnf?.bg && cnf?.bg !== ' ') ? cnf?.bg : (state.defaultNotif.tn === 'Timer-7' ? '#dd611b' : '#fff') }}
                                          alt="Image" />
                                        <Button id='upldIcn' onClick={() => openIconModal('iu')} icon={EditIcon} variant='monochromePlain' />
                                      </label>
                                      : item === 1 ?
                                        <label onClick={() => changeNameValue({ iconTab: 1 })}
                                          className={iconTab === 1 ? 'smooth-dnd-active' : ''}>
                                          <Text variant='bodyXs' tone='subdued'>Map</Text>
                                          <img width='30' src={'https://storage.googleapis.com/wiser_goal/notif_icon/icomap.png'} alt="Image" />
                                        </label>
                                        : item === 2 ?
                                          <label onClick={() => changeNameValue({ iconTab: 2 })}
                                            className={iconTab === 2 ? 'smooth-dnd-active' : ''}>
                                            <Text variant='bodyXs' tone='subdued'>Dynamic</Text>
                                            <img width='30' src={'https://storage.googleapis.com/wiser_goal/notif_icon/product-icon.png'} alt="Image" />
                                          </label>
                                          : item === 3 ?
                                            <label onClick={() => changeNameValue({ iconTab: 3 })}
                                              className={iconTab === 3 ? 'smooth-dnd-active' : ''}>
                                              <Text variant='bodyXs' tone='subdued'>Gravatar</Text>
                                              <img width='30' src={'https://storage.googleapis.com/wiserimg/template_icon/user.png'} alt="Image" />
                                            </label>
                                            : item === 4 &&
                                            <label onClick={() => changeNameValue({ iconTab: 4 })}
                                              className={iconTab === 4 ? 'smooth-dnd-active' : ''}>
                                              <Text variant='bodyXs' tone='subdued'>Flag</Text>
                                              <img width='30' src={'https://storage.googleapis.com/wiserimg/template_icon/flag_icon.svg?q=12'} alt="Image" />
                                            </label>
                                    }
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                    </DragDropContext>
                  </Box>
                }

                {cnf && defaultNotif?.iit === true && showImgPadding === true ?
                  <InlineStack blockAlign='center' align='space-between'>
                    <Box width={cnf?.ibg !== undefined ? '80%' : '100%'}>
                      <RangeSlider
                        min={0}
                        max={20}
                        label={<Tooltip dismissOnMouseOut padding="400" content='Image size' width='wide' hasUnderline><Text>Adjust the size of the image.</Text></Tooltip>}
                        value={cnf?.imgpd ? cnf?.imgpd : 0}
                        onChange={(e) => handleMainTextChange('imgpd', e)}
                        output />
                    </Box>
                    {cnf?.ibg !== undefined &&
                      <ColorPick
                        width='medium'
                        colorKey="ibg"
                        colorValue={cnf?.ibg}
                        functionCall={handleMainTextChange} />
                    }
                  </InlineStack>
                  :   // 'imgpd' field not show then, show icon bg-color field in full width out-side
                  cnf?.ibg !== undefined &&
                  <Box paddingBlockStart={200}>
                    <ColorPick
                      labelName='Icon background color'
                      colorKey="ibg"
                      colorValue={cnf?.ibg}
                      functionCall={handleMainTextChange} />
                  </Box>
                }

                {(queryNtt === 'review_recent' || defaultNotif?.ttn === 'bulk_review_6' ||
                  defaultNotif?.ttn === 'bulk_review_5' || defaultNotif?.ttn === 'social_media_post_1') &&
                  defaultNotif?.ttn !== 'recent_review_6' &&
                  <Box paddingBlockStart={400}>
                    <ColorPick
                      labelName='Star color'
                      width='medium'
                      colorKey="strrtngclr"
                      colorValue={cnf?.strrtngclr}
                      functionCall={handleMainTextChange} />
                  </Box>
                }

              </Box>
            </div>
          }

          {cnf?.lcyctryicn !== undefined &&
            <div>
              <Text fontWeight='medium'>Badge icon</Text>
              <Box paddingInlineStart={700}>
                <InlineStack align='space-between' blockAlign='center'>
                  <Text>Badge icon upload</Text>
                  <ColorPick
                    width='medium'
                    colorKey="lcyctrybgclr"
                    colorValue={cnf?.lcyctrybgclr}
                    functionCall={handleMainTextChange} />
                </InlineStack>

                <Box paddingBlockStart={200}>
                  <div className='draggable_wrap'>
                    <div className="draggable-item">
                      <Icon tone='base' source={DragHandleIcon} />
                      <label className='smooth-dnd-active'>
                        <Text variant='bodyXs' fontWeight='medium'>Icon</Text>
                        <img width='30' style={{ background: cnf?.lcyctrybgclr }} src={cnf?.lcyctryicn} alt="Image" />
                        <Button id='upldIcn' onClick={() => openIconModal('lcyctryicn')} icon={EditIcon} variant='monochromePlain' />
                      </label>
                    </div>
                  </div>
                </Box>

              </Box>
            </div>
          }

          {/* hide time ago */}
          {(queryNtt === 'purchase_recent' || queryNtt === 'purchase_recent_ecomm_snippet' ||
            queryNtt === 'signup_recent' || queryNtt === 'single_visits') &&
            <div>
              <Checkbox
                label={<Tooltip dismissOnMouseOut padding="400" content='Customize timestamp' width='wide' hasUnderline>
                  <Text fontWeight='medium'>Hide "Time ago" value</Text></Tooltip>}
                checked={notiById?.isrtag}
                onChange={(e) => handleSettingChange('isrtag', e)} />

              <Box paddingInlineStart={700}>
                <Text>If it is greater than {notiById?.tmagvl} {notiById?.tmagtyp}(s), show {notiById?.tmagtxt} text</Text>

                <Box paddingBlockStart={150} paddingBlockEnd={200}>
                  <InlineGrid columns={2} gap={300}>
                    <TextField
                      type='Number'
                      placeholder='Interval'
                      value={notiById?.tmagvl}
                      disabled={!notiById?.isrtag}
                      onChange={(e) => handleSettingChange('tmagvl', e ? Number(e) : '')} />
                    {designValidator.message('Interval', notiById?.tmagvl, 'required')}

                    {notiById?.isrtag !== true ?
                      <TextField disabled
                        value={notiById?.tmagtyp?.charAt(0).toUpperCase() + notiById?.tmagtyp?.slice(1) || 'Select'} />
                      :
                      <Popover
                        fullWidth
                        active={state.isTimeStmp}
                        onClose={() => changeNameValue({ isTimeStmp: !state.isTimeStmp })}
                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                          onClick={() => changeNameValue({ isTimeStmp: !state.isTimeStmp })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {notiById?.tmagtyp?.charAt(0).toUpperCase() + notiById?.tmagtyp?.slice(1) || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={(queryNtt !== 'single_visits') ? timeStemp : timeStempSingle}
                          selected={[notiById?.tmagtyp]}
                          onChange={() => changeNameValue({ isTimeStmp: !state.isTimeStmp })}
                          onFocusOption={(e) => handleSettingChange('tmagtyp', e)} />
                      </Popover>
                    }
                  </InlineGrid>
                </Box>

                <TextField
                  type='text' autoComplete='off'
                  placeholder='Enter alternate word for timeago'
                  disabled={!notiById?.isrtag}
                  onChange={(e) => handleSettingChange('tmagtxt', e)}
                  value={notiById?.tmagtxt}
                  error={errMessage?.replaceWord ? errMessage.replaceWord : false} />
                {designValidator.message('replaceWord', notiById?.tmagtxt, 'required')}

              </Box>
            </div>
          }

          {(cnf?.dflc !== undefined || cnf?.dfrvtxt !== undefined || cnf?.dfun !== undefined) &&
            <div>
              <Text fontWeight='medium'>Fallback values</Text>
              <Box paddingInlineStart={400} paddingBlockStart={150}>
                <BlockStack gap={400}>

                  {/* for default location */}
                  {cnf?.dflc !== undefined &&
                    <div>
                      <Checkbox
                        label={<Tooltip dismissOnMouseOut padding="400" content="Enter location's fallback values" width='wide' hasUnderline><Text>Show visitor's location as fallback value</Text></Tooltip>}
                        checked={cnf?.isdlip}
                        onChange={(e) => handleMainTextChange('isdlip', e)} />
                      <TextField
                        type='text'
                        placeholder='Location'
                        disabled={!cnf?.isdlip}
                        onChange={(e) => handleMainTextChange('dflc', e)}
                        value={cnf?.dflc} />
                    </div>
                  }

                  {/* for default Name */}
                  {cnf?.dfun !== undefined &&
                    <TextField
                      label={<Tooltip dismissOnMouseOut padding="400" content="Enter username, first name, last name's multiple fallback values"><Text>Fallback value for name</Text></Tooltip>}
                      type='text'
                      placeholder='Location'
                      onChange={(e) => handleMainTextChange('dfun', e)}
                      value={cnf?.dfun} />
                  }

                  {/* for default review text */}
                  {cnf?.dfrvtxt !== undefined &&
                    <TextField
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enter review text fallback values' width='wide' hasUnderline>
                        <Text>Fallback value for review text</Text></Tooltip>}
                      type='text'
                      placeholder='Review text'
                      onChange={(e) => handleMainTextChange('dfrvtxt', e)}
                      value={cnf?.dfrvtxt} />
                  }

                </BlockStack>
              </Box>
            </div>
          }

          {(cnf?.icpew !== undefined || cnf?.isrtfnm !== undefined ||
            cnf?.isrtlnm !== undefined || cnf?.isrtnm !== undefined ||
            cnf?.irmvrtng !== undefined || cnf?.isrtcn !== undefined) &&
            <div>
              <Text fontWeight='medium'>Trim & capitalization</Text>
              <Box paddingInlineStart={400} paddingBlockStart={150}>
                <InlineStack direction={'column'} gap={0}>
                  {/* for capitalize each word*/}
                  {cnf?.icpew !== undefined &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enable it to capitalize every dynamic word into the notification' width='wide' hasUnderline><Text>Capitalize each word</Text></Tooltip>}
                      checked={cnf?.icpew}
                      onChange={(e) => handleMainTextChange('icpew', e)} />
                  }
                  {/* for Shorten name. firstname */}
                  {cnf?.isrtfnm !== undefined &&
                    <Checkbox
                      label={<Text>Shorten first name</Text>}
                      checked={cnf?.isrtfnm}
                      onChange={(e) => handleMainTextChange('isrtfnm', e)} />
                  }
                  {/* for Shorten name. lastname */}
                  {cnf?.isrtlnm !== undefined &&
                    <Checkbox
                      label={<Text>Shorten last name</Text>}
                      checked={cnf?.isrtlnm}
                      onChange={(e) => handleMainTextChange('isrtlnm', e)} />
                  }
                  {/* for Shorten name. username */}
                  {cnf?.isrtnm !== undefined &&
                    <Checkbox
                      label={<Text>Shorten username</Text>}
                      checked={cnf?.isrtnm}
                      onChange={(e) => handleMainTextChange('isrtnm', e)} />
                  }
                  {/* for remove star rating */}
                  {cnf?.irmvrtng !== undefined &&
                    <Checkbox
                      label={<Text>Remove star rating</Text>}
                      checked={cnf?.irmvrtng}
                      onChange={(e) => handleMainTextChange('irmvrtng', e)} />
                  }
                  {/* for Shorten country name */}
                  {cnf?.isrtcn !== undefined &&
                    <Checkbox
                      label={<Text>Show country code</Text>}
                      checked={cnf?.isrtcn}
                      onChange={(e) => handleMainTextChange('isrtcn', e)} />
                  }
                </InlineStack>
              </Box>
            </div>
          }

          {(cnf?.iytbvc !== undefined || cnf?.iytbsbc !== undefined || cnf?.iytblc !== undefined ||
            cnf?.iytbdlc !== undefined || cnf?.iytbsbc !== undefined || cnf?.iytbctc !== undefined ||
            cnf?.iytbfvc !== undefined || cnf?.iytbvdc !== undefined) &&
            <div>
              <Text fontWeight='medium'>Show or hide text</Text>

              <Box paddingInlineStart={400} paddingBlockStart={150}>
                <InlineStack direction={'column'} gap={0}>

                  {(notiById?.idisnp !== true || notiById?.isnpwdsg === true) && cnf?.iytbvc !== undefined &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enable it to show video view counter' width='wide' hasUnderline>
                        <Text>View count</Text></Tooltip>}
                      checked={cnf?.iytbvc}
                      onChange={(e) => handleMainTextChange('iytbvc', e)} />
                  }
                  {(notiById?.idisnp !== true || notiById?.isnpwdsg === true) && cnf?.iytbsbc !== undefined &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Display youtube subscriber count' width='wide' hasUnderline>
                        <Text>Display subscriber count</Text></Tooltip>}
                      checked={cnf?.iytbsbc}
                      onChange={(e) => handleMainTextChange('iytbsbc', e)} />
                  }
                  {(notiById?.idisnp !== true || notiById?.isnpwdsg === true) && cnf?.iytblc !== undefined &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enable it to show video like counter' width='wide' hasUnderline>
                        <Text>Display like count</Text></Tooltip>}
                      checked={cnf?.iytblc}
                      onChange={(e) => handleMainTextChange('iytblc', e)} />
                  }
                  {(notiById?.idisnp !== true || notiById?.isnpwdsg === true) && cnf?.iytbdlc !== undefined &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Display youtube dislike count' width='wide' hasUnderline>
                        <Text>Display dislike count</Text></Tooltip>}
                      checked={cnf?.iytbdlc}
                      onChange={(e) => handleMainTextChange('iytbdlc', e)} />
                  }
                  {notiById?.idisnp !== true && cnf?.iytbctc !== undefined && queryNtt !== 'channel_subscriber' &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" width='wide' hasUnderline content='Enable it to show video comment counter'>
                        <Text>Display comment count</Text></Tooltip>}
                      checked={cnf?.iytbctc}
                      onChange={(e) => handleMainTextChange('iytbctc', e)} />
                  }
                  {(notiById?.idisnp !== true || notiById?.isnpwdsg === true) && cnf?.iytbfvc !== undefined &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enable it to show favorite counter' width='wide' hasUnderline>
                        <Text>Display favorite count</Text></Tooltip>}
                      checked={cnf?.iytbfvc}
                      onChange={(e) => handleMainTextChange('iytbfvc', e)} />
                  }
                  {(notiById?.idisnp !== true || notiById?.isnpwdsg === true) && cnf?.iytbvdc !== undefined &&
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Display youtube video count' width='wide' hasUnderline>
                        <Text>Display video count</Text></Tooltip>}
                      checked={cnf?.iytbvdc}
                      onChange={(e) => handleMainTextChange('iytbvdc', e)} />
                  }

                </InlineStack>
              </Box>
            </div>
          }

          {/* language translate */}
          {notiById?.lng !== undefined && queryNtt !== 'title_bar' && queryNtt !== 'review_recent' &&
            <div>
              <Tooltip dismissOnMouseOut padding="400" width='wide' hasUnderline content='Set language for notification text.'>
                <Text fontWeight='medium'>Language translate</Text>
              </Tooltip>
              <Box paddingBlockStart={100} paddingInlineStart={300}>
                <Popover
                  fullWidth
                  active={state.isLangTrans}
                  onClose={() => changeNameValue({ isLangTrans: !state.isLangTrans })}
                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                    onClick={() => changeNameValue({ isLangTrans: !state.isLangTrans })}>
                    {langOptions.find(({ value }) => value === notiById?.lng)?.label || 'Select'}
                  </Button>}>
                  <OptionList
                    options={langOptions}
                    selected={[notiById?.lng] || []}
                    onChange={() => changeNameValue({ isLangTrans: !state.isLangTrans })}
                    onFocusOption={(e) => handleSettingChange('lng', e)} />
                </Popover>
              </Box>
            </div>
          }

          {queryNtt === 'video' &&
            <div>
              <Tooltip dismissOnMouseOut padding="400" content={'Add YouTube URL'} width='wide' hasUnderline>
                <Text fontWeight='medium'>YouTube video URL</Text></Tooltip>
              <Box paddingBlockEnd={100} paddingInlineStart={300}>
                <BlockStack gap={100}>
                  <Box paddingBlockEnd={200}>
                    <TextField
                      value={cnf?.vurl}
                      onChange={(e) => handleMainTextChange('vurl', e)} />
                  </Box>
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding="400" width='wide' hasUnderline content='Enable this option to autoplay video'>
                      <Text tone='subdued'>Autoplay</Text></Tooltip>}
                    checked={cnf?.atpvd}
                    onChange={(e) => handleMainTextChange('atpvd', e)}
                  />
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding="400" hasUnderline width='wide' content='Enable this option to mute video'><Text tone='subdued'>Mute</Text></Tooltip>}
                    checked={cnf?.imtvd}
                    onChange={(e) => handleMainTextChange('imtvd', e)}
                  />
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to Hide related video' width='wide' hasUnderline>
                      <Text tone='subdued'>Hide related video</Text></Tooltip>}
                    checked={(cnf?.isrltdvd === undefined || cnf?.isrltdvd === true) ? true : false}
                    onChange={(e) => handleMainTextChange('isrltdvd', e)}
                  />
                </BlockStack>
              </Box>
            </div>
          }

          {/* Time singular & plural */}
          {(cnf?.m !== undefined || queryNtt === 'review_recent') &&
            <div>
              <Tooltip dismissOnMouseOut padding='400' width='wide' hasUnderline content={'Set words said in your language for this and please do not remove {x} as it is used for value.'}><Text fontWeight='medium'>Time singular</Text></Tooltip>
              <Box paddingBlockStart={100} paddingBlockEnd={400} paddingInlineStart={300}>
                <InlineGrid columns={2}>
                  <TextField
                    value={cnf?.m}
                    placeholder='Min' autoComplete='off'
                    onChange={(e) => handleMainTextChange('m', e)}
                    error={errMessage.minute ? true : false} />
                  {designValidator.message('minute', cnf?.m, 'required')}
                  <TextField
                    value={cnf?.h}
                    placeholder='Hour' autoComplete='off'
                    onChange={(e) => handleMainTextChange('h', e)}
                    error={errMessage.hour ? true : false} />
                  {designValidator.message('hour', cnf?.h, 'required')}
                  <TextField
                    value={cnf?.d}
                    placeholder='Day' autoComplete='off'
                    onChange={(e) => handleMainTextChange('d', e)}
                    error={errMessage.days ? true : false} />
                  {designValidator.message('days', cnf?.d, 'required')}
                  <TextField
                    value={cnf?.mn}
                    placeholder='month' autoComplete='off'
                    onChange={(e) => handleMainTextChange('mn', e)}
                    error={errMessage.month ? true : false} />
                  {designValidator.message('month', cnf?.mn, 'required')}
                </InlineGrid>
              </Box>

              <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content={'Set words said in your language for this and please do not remove {x} as it is used for value.'}><Text fontWeight='medium'>Time plural</Text></Tooltip>
              <Box paddingBlockStart={100} paddingInlineStart={300}>
                <InlineGrid columns={2}>
                  <TextField
                    value={cnf?.mplrl}
                    placeholder='Min' autoComplete='off'
                    onChange={(e) => handleMainTextChange('mplrl', e)}
                    error={errMessage.plurMinute ? true : false} />
                  {designValidator.message('plurMinute', cnf?.mplrl, 'required')}
                  <TextField
                    value={cnf?.hplrl}
                    placeholder='Hour' autoComplete='off'
                    onChange={(e) => handleMainTextChange('hplrl', e)}
                    error={errMessage.plurHour ? true : false} />
                  {designValidator.message('plurHour', cnf?.hplrl, 'required')}
                  <TextField
                    value={cnf?.dplrl}
                    placeholder='Day' autoComplete='off'
                    onChange={(e) => handleMainTextChange('dplrl', e)}
                    error={errMessage.plurDays ? true : false} />
                  {designValidator.message('plurDays', cnf?.dplrl, 'required')}
                  <TextField
                    value={cnf?.mnplrl}
                    placeholder='month' autoComplete='off'
                    onChange={(e) => handleMainTextChange('mnplrl', e)}
                    error={errMessage.plurMonth ? true : false} />
                  {designValidator.message('plurMonth', cnf?.mnplrl, 'required')}
                </InlineGrid>
              </Box>
            </div>
          }

        </BlockStack>
        :
        state.verticalActive === getValue('Click actions') ?
          <BlockStack gap={400}>

            {/* cta label & link field */}
            {cnf?.cl !== undefined &&
              <div>
                {(queryNtt !== 'lottery' && queryNtt !== 'channel_subscriber' && queryNtt !== 'collector' && queryNtt !== 'offer' && defaultNotif?.ttn !== 'call_back_2') ?
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding="400" content='Customise CTA label and add redirecting URL' width='wide' hasUnderline><Text fontWeight='medium'>Button & link name</Text></Tooltip>}
                    checked={cnf?.ica}
                    onChange={(e) => handleMainTextChange('ica', e)} />
                  :
                  <Box paddingBlockEnd={200}>
                    <Tooltip dismissOnMouseOut padding="400" content='Customise CTA label and add redirecting URL' width='wide' hasUnderline><Text fontWeight={'medium'}>Button & link name</Text></Tooltip>
                  </Box>
                }

                < Box paddingInlineStart={(queryNtt !== 'lottery' && queryNtt !== 'channel_subscriber' &&
                  queryNtt !== 'collector' && defaultNotif?.ttn !== 'call_back_2') ? 700 : 300}>
                  <BlockStack gap={300}>
                    <TextField
                      value={cnf?.cl?.txt}
                      placeholder='Label name' autoComplete='off'
                      onChange={(e) => handleTextChange('cl', 'txt', e)}
                      error={errMessage?.ctaLabel ? errMessage?.ctaLabel : false} />
                    {cnf?.ica && designValidator.message('ctaLabel', cnf?.cl?.txt, 'required')}

                    <InlineGrid columns={2} gap={200}>
                      <div>
                        <Box paddingBlockEnd={100}><Text>Font-family</Text></Box>
                        <Popover
                          fullWidth
                          active={state.isCtaFamily}
                          onClose={() => changeNameValue({ isCtaFamily: !state.isCtaFamily })}
                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                            onClick={() => changeNameValue({ isCtaFamily: !state.isCtaFamily })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {fontFamilyOpts.find(({ value }) => value === cnf?.cl?.fml)?.label || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={fontFamilyOpts}
                            selected={[cnf?.cl?.fml]}
                            onChange={() => changeNameValue({ isCtaFamily: !state.isCtaFamily })}
                            onFocusOption={(e) => handleTextChange('cl', 'fml', e)} />
                        </Popover>
                      </div>

                      <div>
                        <Box paddingBlockEnd={100}><Text>Font-weight</Text></Box>
                        <Popover
                          fullWidth
                          active={state.isCtaStyle}
                          onClose={() => changeNameValue({ isCtaStyle: !state.isCtaStyle })}
                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                            onClick={() => changeNameValue({ isCtaStyle: !state.isCtaStyle })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {fontStyleOpts.find(({ value }) => value === cnf?.cl?.stl)?.label || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={fontStyleOpts}
                            selected={[cnf?.cl?.stl]}
                            onChange={() => changeNameValue({ isCtaStyle: !state.isCtaStyle })}
                            onFocusOption={(e) => handleTextChange('cl', 'stl', e)} />
                        </Popover>
                      </div>
                    </InlineGrid>

                    <InlineGrid columns={2} gap={200}>
                      {cnf?.cl?.sz !== undefined && queryNtt === 'custom' ||
                        (defaultNotif?.ttn === 'promotional_banner_timer' && defaultNotif?.tn === 'Timer-9') &&
                        <div>
                          <Box paddingBlockEnd={100}><Text>Font-size</Text></Box>
                          <Popover
                            fullWidth
                            active={state.isCtaFsize}
                            onClose={() => changeNameValue({ isCtaFsize: !state.isCtaFsize })}
                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                              onClick={() => changeNameValue({ isCtaFsize: !state.isCtaFsize })}>
                              <Text variant='bodyMd' tone='subdued'>
                                {fontSizeOpts.find(({ value }) => value == cnf?.cl?.sz)?.label || 'Select'}
                              </Text>
                            </Button>}>
                            <OptionList
                              options={fontSizeOpts}
                              selected={[cnf?.cl?.sz?.toString()]}
                              onChange={() => changeNameValue({ isCtaFsize: !state.isCtaFsize })}
                              onFocusOption={(e) => handleTextChange('cl', 'sz', e)} />
                          </Popover>
                        </div>
                      }

                      {cnf?.clbg !== undefined &&
                        <ColorPick
                          labelName='Background color'
                          colorKey="clbg"
                          colorValue={cnf?.clbg}
                          functionCall={handleMainTextChange} />
                      }
                      <ColorPick
                        labelName='Text color'
                        colorKey="cl"
                        secondKey="clr"
                        colorValue={cnf?.cl?.clr}
                        functionCall={handleTextChange} />
                    </InlineGrid>

                    {cnf?.iytvdcta !== undefined &&
                      <Checkbox
                        label={<Tooltip dismissOnMouseOut padding="400" content='If visitor clicks on CTA label, open YouTube video popup & auto play it.' width='wide' hasUnderline><Text>Open YouTube video popup</Text></Tooltip>}
                        checked={cnf?.iytvdcta}
                        onChange={(e) => handleMainTextChange('iytvdcta', e)} />
                    }

                    {cnf?.iytvdcta === true &&
                      <BlockStack gap={300}>
                        <TextField
                          label={<Text>Enter YouTube video URL</Text>}
                          placeholder='Enter video URL' autoComplete='off'
                          value={cnf?.yturlcta}
                          onChange={(e) => handleMainTextChange('yturlcta', e)}
                          error={errMessage?.youtubeVideoURL ? errMessage.youtubeVideoURL : false} />
                        {cnf?.ica && designValidator.message('youtubeVideoURL', cnf?.yturlcta, 'required')}

                        <Checkbox
                          disabled={!cnf?.yturlcta}
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to autoplay video' width='wide' hasUnderline><Text>Autoplay</Text></Tooltip>}
                          checked={cnf?.atpvd}
                          onChange={(e) => handleMainTextChange('atpvd', e)}
                        />
                        <Checkbox
                          disabled={!cnf?.yturlcta}
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to mute video' width='wide' hasUnderline><Text>Mute</Text></Tooltip>}
                          checked={cnf?.imtvd}
                          onChange={(e) => handleMainTextChange('imtvd', e)}
                        />
                        <Checkbox
                          disabled={!cnf?.yturlcta}
                          checked={cnf?.isrltdvd === true ? true : false}
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to Hide related video' width='wide' hasUnderline><Text>Hide related video</Text></Tooltip>}
                          onChange={(e) => handleMainTextChange('isrltdvd', e)}
                        />
                      </BlockStack>
                    }

                    {/* CTA redirect url */}
                    {isCTARadio !== true && cnf?.iytvdcta !== true && (queryNtt !== 'lottery' && queryNtt !== 'collector' && defaultNotif?.ttn !== 'call_back_2') &&
                      <div>
                        <InlineStack align='space-between'>
                          <Text>CTA URL</Text>
                          <Button variant='plain' onClick={() => addUtmSource(cnf?.cu, 'cu')}>Add UTM</Button>
                        </InlineStack>
                        <TextField
                          autoComplete='off'
                          placeholder='Label name'
                          value={cnf?.cu}
                          onChange={(e) => handleMainTextChange('cu', e)} />
                      </div>
                    }

                    {isCTARadio === true &&
                      <div>
                        <Box paddingBlockEnd={100}><Text>Choose link</Text></Box>
                        <Popover
                          fullWidth
                          active={state.isCtaLink}
                          onClose={() => changeNameValue({ isCtaLink: !state.isCtaLink })}
                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                            onClick={() => changeNameValue({ isCtaLink: !state.isCtaLink })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {ctaLinkOpts.find(({ value }) => value === cnf?.ctcacn)?.label || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={ctaLinkOpts}
                            selected={[cnf?.ctcacn]}
                            onChange={() => changeNameValue({ isCtaLink: !state.isCtaLink })}
                            onFocusOption={(e) => handleMainTextChange('ctcacn', e)} />
                        </Popover>

                        {cnf?.ctcacn === 0 &&
                          <Box paddingBlockStart={300}>
                            <InlineStack align='end'>
                              <Button variant='plain' onClick={() => addUtmSource(cnf?.cu, 'cu')}>Add UTM</Button>
                            </InlineStack>
                            <TextField
                              value={cnf?.cu}
                              placeholder='CTA URL'
                              onChange={(e) => handleMainTextChange('cu', e)} />
                          </Box>
                        }

                        {cnf?.ctcacn === 5 && (defaultNotif?.tn === 'Timer-3') &&
                          <Box paddingBlockStart={300}>
                            <TextField
                              value={cnf?.cpcdcttxt}
                              placeholder='Label name'
                              onChange={(e) => handleMainTextChange('cpcdcttxt', e)} />
                          </Box>
                        }
                      </div>
                    }

                    <BlockStack gap={200}>
                      {cnf?.iytvdcta !== true && queryNtt !== 'lottery' && queryNtt !== 'collector' && defaultNotif?.ttn !== 'call_back_2' &&
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding="400" content='Turn off to open the link in a new tab' width='wide' hasUnderline><Text tone='subdued'>Open in same tab</Text></Tooltip>}
                          checked={cnf?.ictrdsw}
                          onChange={(e) => handleMainTextChange('ictrdsw', e)} />
                      }

                      {(defaultNotif?.ttn === 'promotional_banner_timer' || queryNtt === 'custom' ||
                        queryNtt === 'call_to_action' || queryNtt === 'collector' ||
                        queryNtt === 'lottery') &&
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable to stop hiding widget when user clicks on CTA' width='wide' hasUnderline><Text tone='subdued'>Stop hiding widget on CTA click</Text></Tooltip>}
                          checked={cnf?.istphntctaclk}
                          onChange={(e) => handleMainTextChange('istphntctaclk', e)} />
                      }

                      {/* cta icon upload */}
                      {(defaultNotif?.ttn === 'call_back_1' || defaultNotif?.ttn === 'call_back_2' ||
                        defaultNotif?.ttn === 'offer_1') &&
                        <div>
                          <Box paddingBlockEnd={400}>
                            <Checkbox
                              label={<Tooltip dismissOnMouseOut padding="400" content='Enable it to show only CTA button icon' width='wide' hasUnderline><Text tone='subdued'>Show only CTA button icon</Text></Tooltip>}
                              checked={cnf?.icbicn}
                              onChange={(e) => handleMainTextChange('icbicn', e)} />
                          </Box>

                          {/* for the icon model */}
                          <Text>CTA button icon</Text>
                          <Box paddingBlockStart={150} paddingBlockEnd={500} paddingInlineStart={300}>
                            <div className='draggable_wrap'>
                              <div className="draggable-item">
                                <Icon tone='base' source={DragHandleIcon} />
                                <label className='smooth-dnd-active'>
                                  <Text variant='bodyXs' tone='subdued'>Icon</Text>
                                  <img width='30' style={{ background: cnf?.clbg, padding: '3px' }} src={cnf?.cticn} alt="Image" />
                                  <Button id='upldIcn' onClick={() => openIconModal('cticn')} icon={EditIcon} variant='monochromePlain' />
                                </label>
                              </div>
                            </div>
                          </Box>
                        </div>
                      }

                    </BlockStack>
                  </BlockStack>
                </Box>
              </div>
            }

            {/* Close icon field */}
            {(notiById?.idisnp !== true || notiById?.isnpwdsg !== true) &&
              cnf?.cb !== undefined &&
              <div>
                <InlineStack align='space-between' blockAlign='start'>
                  <Checkbox
                    label={<Tooltip dismissOnMouseOut padding="400" width='wide' content={'Close button visible on mobile.'} hasUnderline>
                      <Text fontWeight='medium'>Close icon</Text></Tooltip>}
                    checked={cnf?.cb}
                    onChange={(e) => handleMainTextChange('cb', e)}
                  />
                  {cnf?.cb === true &&
                    <ColorPick
                      width='medium'
                      colorKey="cbg"
                      colorValue={cnf?.cbg}
                      functionCall={handleMainTextChange} />
                  }
                </InlineStack>
                {(isWidget && cnf?.cb === true) &&
                  <Box paddingInlineStart={700}>
                    <Checkbox
                      disabled={cnf?.ishntfclk ? true : false}
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to close the widget if visitors click outside of it' width='wide' hasUnderline><Text>Close it if visitors click outside</Text></Tooltip>}
                      checked={cnf?.ishntfclk ? false : cnf?.cloclk}
                      onChange={(e) => handleMainTextChange('cloclk', e)}
                    />
                  </Box>
                }
              </div>
            }

            {/* notification clickable field */}
            {(notiById?.idisnp !== true || notiById?.isnpwdsg !== true) &&
              (cnf?.nc !== undefined && queryNtt !== 'collector' &&
                queryNtt !== 'video' && defaultNotif?.ttn !== 'call_back_2') &&
              <div>
                <Checkbox
                  label={<Tooltip dismissOnMouseOut padding="400" content='Enable to make the notification clickable' width='wide' hasUnderline><Text fontWeight='medium'>Notification clickable</Text></Tooltip>}
                  checked={cnf?.nc}
                  onChange={(e) => handleMainTextChange('nc', e)}
                />
                {cnf?.nc === true &&
                  <Box paddingInlineStart={700} paddingBlockStart={200}>
                    {cnf?.iytvdnc !== undefined &&
                      <Box paddingBlockEnd={200}>
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding="400" content='If visitor clicks on notification, open YouTube video popup & auto play it' width='wide' hasUnderline><Text>Open YouTube video popup</Text></Tooltip>}
                          checked={cnf?.iytvdnc}
                          onChange={(e) => handleMainTextChange('iytvdnc', e)} />
                      </Box>
                    }

                    {cnf?.iytvdnc === true &&
                      <BlockStack gap={300}>
                        <TextField
                          value={cnf?.yturlnc}
                          placeholder='Enter video URL' autoComplete='off'
                          label={<Text>Enter YouTube video URL</Text>}
                          onChange={(e) => handleMainTextChange('yturlnc', e)}
                          error={errMessage?.youtubeVideoUrl ? errMessage.youtubeVideoUrl : false} />
                        {cnf?.nc && designValidator.message('youtubeVideoUrl', cnf?.yturlnc, 'required')}

                        <Checkbox
                          disabled={!cnf?.yturlnc}
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to autoplay video' width='wide' hasUnderline><Text>Autoplay</Text></Tooltip>}
                          checked={cnf?.atpvdnc}
                          onChange={(e) => handleMainTextChange('atpvdnc', e)}
                        />
                        <Checkbox
                          disabled={!cnf?.yturlnc}
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to mute video' width='wide' hasUnderline><Text>Mute</Text></Tooltip>}
                          checked={cnf?.imtvdnc}
                          onChange={(e) => handleMainTextChange('imtvdnc', e)}
                        />
                        <Checkbox
                          disabled={!cnf?.yturlnc}
                          checked={cnf?.isrltdvdnc === true ? true : false}
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to Hide related video' width='wide' hasUnderline><Text>Hide related video</Text></Tooltip>}
                          onChange={(e) => handleMainTextChange('isrltdvdnc', e)}
                        />
                      </BlockStack>
                    }

                    {/* for custom and other notif. */}
                    {isNotifRadio !== true && cnf?.iytvdnc !== true &&
                      <BlockStack gap={'050'}>
                        <InlineStack align='space-between'>
                          <Tooltip dismissOnMouseOut padding="400" content='Enter the redirect URL' width='wide' hasUnderline><Text>Set redirect URL</Text></Tooltip>
                          <Button variant='plain' onClick={() => addUtmSource(cnf?.nu, 'nu')}>Add UTM</Button>
                        </InlineStack>
                        <TextField
                          value={cnf?.nu}
                          placeholder='Enter URL' autoComplete='off'
                          onChange={(e) => handleMainTextChange('nu', e)}
                          error={errMessage?.notificationClickable ? errMessage.notificationClickable : false} />
                        {cnf?.nc && designValidator.message('notificationClickable', cnf?.nu, 'required')}
                      </BlockStack>
                    }

                    {isNotifRadio === true &&
                      <div>
                        <Box paddingBlockEnd={100}><Text>Choose link</Text></Box>
                        <Popover
                          fullWidth
                          active={state.isNtfLink}
                          onClose={() => changeNameValue({ isNtfLink: !state.isNtfLink })}
                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                            onClick={() => changeNameValue({ isNtfLink: !state.isNtfLink })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {notifLinkOpts.find(({ value }) => value === cnf?.ncacn)?.label || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={notifLinkOpts}
                            selected={[cnf?.ncacn]}
                            onChange={() => changeNameValue({ isNtfLink: !state.isNtfLink })}
                            onFocusOption={(e) => handleMainTextChange('ncacn', e)} />
                        </Popover>

                        {cnf?.ncacn === 0 &&
                          <Box paddingBlockStart={300}>
                            <InlineStack align='end'>
                              <Button variant='plain' onClick={() => addUtmSource(cnf?.nu, 'nu')}>Add UTM</Button>
                            </InlineStack>
                            <TextField
                              value={cnf?.nu}
                              placeholder='CTA URL'
                              onChange={(e) => handleMainTextChange('nu', e)} />
                          </Box>
                        }
                      </div>
                    }

                    {cnf?.iytvdnc !== true &&
                      <Box paddingBlockStart={200}>
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding="400" content='Turn off to open the link in a new tab' width='wide' hasUnderline><Text tone='subdued'>Open in same tab</Text></Tooltip>}
                          checked={cnf?.incrdsw}
                          onChange={(e) => handleMainTextChange('incrdsw', e)} />
                      </Box>
                    }
                  </Box>
                }
              </div>
            }
          </BlockStack>
          :
          state.verticalActive === getValue('Position & animation') ?
            <div>
              {/* Position & animation */}
              {queryNtt !== 'title_bar' ?
                <Box>
                  <Tooltip dismissOnMouseOut padding="400" content='Set notification position and its animation style in desktop & mobile devices' width='wide' hasUnderline><Text fontWeight='medium'>Position & animation</Text></Tooltip>

                  <Box paddingBlockStart={300}>
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" content='Enable to show the notification in Mobile device' width='wide' hasUnderline><Text>Show in mobile</Text></Tooltip>}
                      checked={!notiById?.hm}
                      onChange={(e) => handleSettingChange('hm', e)} />

                    <Box paddingInlineStart={700}>
                      {queryNtt !== 'limited_stock' &&
                        <div>
                          {cnf?.mas !== undefined &&
                            <Box paddingBlockStart={100}>
                              <Tooltip dismissOnMouseOut padding="400" width='wide' hasUnderline content='Select animation style for notification entry & exit in mobile'><Text tone={'subdued'}>Animation style in mobile</Text></Tooltip>
                              <Box paddingBlockStart={'050'}>
                                {(!notiById?.hm !== true || notiById?.idisnp) ?
                                  <TextField value={phoneAni.find(({ value }) => value === cnf?.mas)?.label || 'Select'}
                                    disabled />
                                  :
                                  <Popover
                                    fullWidth
                                    active={state.isAniMobi}
                                    onClose={() => changeNameValue({ isAniMobi: !state.isAniMobi })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isAniMobi: !state.isAniMobi })}>
                                      <Text variant='bodyMd' tone='subdued'>
                                        {phoneAni.find(({ value }) => value === cnf?.mas)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={phoneAni}
                                      selected={[cnf?.mas]}
                                      onChange={() => changeNameValue({ isAniMobi: !state.isAniMobi })}
                                      onFocusOption={(e) => handleMainTextChange('mas', e)} />
                                  </Popover>
                                }
                              </Box>
                            </Box>
                          }

                          {(queryNtt !== 'live_visitor_ecomm_snippet' && queryNtt !== 'purchase_bulk_ecomm_snippet' &&
                            queryNtt !== 'purchase_recent_ecomm_snippet' && queryNtt !== 'limited_stock_ecomm_snippet') &&
                            <Box paddingBlockStart={200}>
                              <Tooltip dismissOnMouseOut padding="400" content='Select display position of notification on website in mobile' width='wide' hasUnderline><Text tone={'subdued'}>Display position in mobile</Text></Tooltip>

                              <Box paddingBlockStart={'050'}>
                                {(!notiById?.hm !== true || notiById?.idisnp) ?
                                  <TextField value={phonePosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'} disabled />
                                  :
                                  <Popover
                                    fullWidth
                                    active={state.isPosMobi}
                                    onClose={() => changeNameValue({ isPosMobi: !state.isPosMobi })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isPosMobi: !state.isPosMobi })}>
                                      <Text variant='bodyMd' tone='subdued'>
                                        {phonePosition.find(({ value }) => value === cnf?.mdp)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={phonePosition}
                                      selected={[cnf?.mdp]}
                                      onChange={() => changeNameValue({ isPosMobi: !state.isPosMobi })}
                                      onFocusOption={(e) => handleMainTextChange('mdp', e)} />
                                  </Popover>
                                }
                              </Box>
                            </Box>
                          }
                        </div>
                      }

                      {/* for sticky timer in header */}
                      {cnf?.mdp !== 'banner_center' && notiById?.idisnp !== true &&
                        (defaultNotif?.ttn === 'promotional_banner_timer' &&
                          (defaultNotif?.tn !== 'Timer-3' || defaultNotif?.tn !== 'Timer-8' || defaultNotif?.tn !== 'Timer-9')) &&
                        <Box paddingBlockStart={200}>
                          <Checkbox
                            label={cnf?.dp === 'banner_top' ?
                              <Tooltip dismissOnMouseOut padding="400" content={"Banner automatically  push the page down"} hasUnderline width='wide'>
                                <Text tone='subdued'>Push the page down</Text>
                              </Tooltip>
                              :
                              <Tooltip dismissOnMouseOut padding="400" content={"Banner automatically push the page up"} hasUnderline width='wide'>
                                <Text tone='subdued'>Push the page up</Text>
                              </Tooltip>
                            }
                            checked={cnf?.imsnpstk}
                            onChange={(e) => handleMainTextChange('imsnpstk', e)} />
                        </Box>
                      }
                    </Box>
                  </Box>
                  <Box paddingBlockStart={400}>
                    <Checkbox
                      label={<Tooltip dismissOnMouseOut padding="400" width='wide' content='Enable to show the notification on Desktop' hasUnderline><Text>Show in desktop</Text></Tooltip>}
                      checked={notiById?.isdsk}
                      onChange={(e) => handleSettingChange('isdsk', e)} />

                    <Box paddingInlineStart={700}>
                      {queryNtt !== 'limited_stock' && notiById?.isdsk === true &&
                        <div>
                          {cnf?.as !== undefined &&
                            <Box paddingBlockStart={100}>
                              <Tooltip dismissOnMouseOut padding="400" content='Select animation style for notification entry & exit in desktop screen' width='wide' hasUnderline><Text tone={'subdued'}>Animation style in desktop</Text></Tooltip>
                              <Box paddingBlockStart={'050'}>
                                {(notiById?.isdsk !== true || notiById?.idisnp) ?
                                  <TextField value={descAni.find(({ value }) => value === cnf?.as)?.label || 'Select'}
                                    disabled />
                                  :
                                  <Popover
                                    fullWidth
                                    active={state.isAniDesk}
                                    onClose={() => changeNameValue({ isAniDesk: !state.isAniDesk })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isAniDesk: !state.isAniDesk })}>
                                      <Text variant='bodyMd' tone='subdued'>
                                        {descAni.find(({ value }) => value === cnf?.as)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={descAni}
                                      selected={[cnf?.as]}
                                      onChange={() => changeNameValue({ isAniDesk: !state.isAniDesk })}
                                      onFocusOption={(e) => handleMainTextChange('as', e)} />
                                  </Popover>
                                }
                              </Box>
                            </Box>
                          }

                          {cnf?.dp !== undefined && queryNtt !== 'live_visitor_ecomm_snippet' &&
                            <Box paddingBlockStart={200}>
                              <Tooltip dismissOnMouseOut padding="400" content='Select notification display position in desktop screen' width='wide' hasUnderline><Text tone={'subdued'}>Display position in desktop</Text></Tooltip>
                              <Box paddingBlockStart={'050'}>
                                {(notiById?.isdsk !== true || notiById?.idisnp) ?
                                  <TextField value={deskPosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'}
                                    disabled />
                                  :
                                  <Popover
                                    fullWidth
                                    active={state.isPosDesk}
                                    onClose={() => changeNameValue({ isPosDesk: !state.isPosDesk })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isPosDesk: !state.isPosDesk })}>
                                      <Text variant='bodyMd' tone='subdued'>
                                        {deskPosition.find(({ value }) => value === cnf?.dp)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={deskPosition}
                                      selected={[cnf?.dp]}
                                      onChange={() => changeNameValue({ isPosDesk: !state.isPosDesk })}
                                      onFocusOption={(e) => handleMainTextChange('dp', e)} />
                                  </Popover>
                                }
                              </Box>
                            </Box>
                          }

                          {/* cta button alignment */}
                          {cnf?.cbdal !== undefined &&
                            <Box paddingBlockStart={200}>
                              <Tooltip dismissOnMouseOut padding="400" hasUnderline content='Select any type of display alignment'>
                                <Text tone='subdued'>Call back button display alignment</Text>
                              </Tooltip>
                              <Box paddingBlockStart={'050'}>
                                <Popover
                                  fullWidth
                                  active={state.isDsplyAlign}
                                  onClose={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}
                                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                    onClick={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}>
                                    <Text variant='bodyMd' tone='subdued'>
                                      {btnPosition.find(({ value }) => value === cnf?.cbdal)?.label || 'Select'}
                                    </Text>
                                  </Button>}>
                                  <OptionList
                                    options={btnPosition}
                                    selected={[cnf?.cbdal]}
                                    onChange={() => changeNameValue({ isDsplyAlign: !state.isDsplyAlign })}
                                    onFocusOption={(e) => handleMainTextChange('cbdal', e)} />
                                </Popover>
                              </Box>
                            </Box>
                          }

                        </div>
                      }

                      {/* for sticky timer in header */}
                      {cnf?.dp !== 'banner_center' && notiById?.idisnp !== true &&
                        (defaultNotif?.ttn === 'promotional_banner_timer' &&
                          (defaultNotif?.tn !== 'Timer-3' && defaultNotif?.tn !== 'Timer-8' && defaultNotif?.tn !== 'Timer-9')) &&
                        <Box paddingBlockStart={200}>
                          <Checkbox
                            label={cnf?.dp === 'banner_top' ?
                              <Tooltip dismissOnMouseOut padding="400" content={"Banner automatically push the page down"} hasUnderline width='wide'>
                                <Text tone='subdued'>Push the page down</Text>
                              </Tooltip>
                              :
                              <Tooltip dismissOnMouseOut padding="400" content={"Banner automatically push the page up"} hasUnderline width='wide'>
                                <Text tone='subdued'>Push the page up</Text>
                              </Tooltip>
                            }
                            checked={cnf?.isnpstk}
                            onChange={(e) => handleMainTextChange('isnpstk', e)} />
                        </Box>
                      }
                    </Box>

                    {/* compact view */}
                    {shwCmpctView &&
                      <Box paddingBlockStart={400}>
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable to show the notification in Mobile device Compact view' width='wide' hasUnderline><Text>Compact design view for mobile</Text></Tooltip>}
                          checked={cnf?.icmptdsgn}
                          onChange={(e) => handleMainTextChange('icmptdsgn', e)} />
                      </Box>
                    }
                  </Box>
                </Box>
                :
                cnf?.tltyp !== undefined && //blinking tab animation field
                <div>
                  <Text fontWeight='medium'>Animation</Text>
                  <Box paddingBlockStart={100} paddingInlineStart={400}>
                    <BlockStack gap={300}>
                      <Popover
                        fullWidth
                        active={state.isAnimiieOpen}
                        onClose={() => changeNameValue({ isAnimiieOpen: !state.isAnimiieOpen })}
                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                          onClick={() => changeNameValue({ isAnimiieOpen: !state.isAnimiieOpen })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {disType.find(({ value }) => value === cnf?.tltyp)?.label || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={disType}
                          selected={[cnf?.tltyp] || ['']}
                          onChange={() => changeNameValue({ isAnimiieOpen: !state.isAnimiieOpen })}
                          onFocusOption={(e) => handleMainTextChange('tltyp', e)} />
                      </Popover>

                      <TextField
                        type='Number'
                        value={cnf?.afrtm}
                        autoComplete='off'
                        placeholder='Enter second'
                        label={<Text>Start animation effect after {cnf?.afrtm} seconds</Text>}
                        onChange={(e) => handleMainTextChange('afrtm', e ? Number(e) : '')} />

                      {queryNtt === 'title_bar' && cnf?.tltyp === 'blinking' &&
                        <TextField
                          type='Number'
                          value={cnf?.blnkdly}
                          placeholder='Enter second'
                          label={<Text>Delay between blinking tab {cnf?.blnkdly} seconds</Text>}
                          onChange={(e) => handleMainTextChange('blnkdly', e ? Number(e) : '')} />
                      }

                      {queryNtt === 'title_bar' &&
                        <div>
                          <Checkbox
                            label={<Tooltip dismissOnMouseOut padding="400" content={`It looks like: <button onClick='fnShowWidgetOnAnyEvent("${notiById?._id}")'> Click </button>`} width='wide' hasUnderline><Text>Show widget using javascript</Text></Tooltip>}
                            checked={cnf?.ishntfclk}
                            onChange={(e) => handleMainTextChange('ishntfclk', e)}
                          />
                          {cnf?.ishntfclk === true &&
                            <Box paddingInlineStart={700} paddingBlockEnd={200} paddingBlockStart={'050'}>
                              <BlockStack gap={100}>
                                <InlineStack align='space-between'>
                                  <Text tone='subdued'>Call this function on custom event</Text>
                                  <Button variant='plain'
                                    onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_ttlBr_fn')}>Copy</Button>
                                </InlineStack>
                                <div onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_ttlBr_fn')}>
                                  <TextField id='wdgt_ttlBr_fn' value={`fnShowWidgetOnAnyEvent("{${notifId}}")`} />
                                </div>
                              </BlockStack>
                            </Box>
                          }
                        </div>
                      }

                    </BlockStack>
                  </Box>
                </div>
              }

            </div>
            :
            state.verticalActive === getValue('Style') ?
              <BlockStack gap={400}>

                {/* dark theme */}
                {!isWidget &&
                  <div>
                    <Text fontWeight='medium'>Mode</Text>
                    <Box paddingInlineStart={300} paddingBlockStart={100}>
                      <InlineStack align='space-between'>
                        <Text>Color scheme</Text>
                        <ButtonGroup>
                          <Button icon={MoonIcon} onClick={() => handleSettingChange('idrkthm', true)} variant={notiById?.idrkthm === true ? 'primary' : 'secondary'} />
                          <Button icon={SunIcon} onClick={() => handleSettingChange('idrkthm', false)} variant={notiById?.idrkthm !== true ? 'primary' : 'secondary'} />
                        </ButtonGroup>
                      </InlineStack>
                    </Box>
                  </div>
                }

                {/* notification shape */}
                {(defaultNotif?.idcss === true || cnf?.r !== undefined || cnf?.bg !== undefined) &&
                  <div>
                    <Tooltip dismissOnMouseOut padding="400" content='Customize shape' width='wide' hasUnderline>
                      <Text fontWeight='medium'>Shape</Text></Tooltip>
                    <Box paddingInlineStart={400} paddingBlockStart={150}>

                      {(defaultNotif?.idcss === true && defaultNotif?.ttn !== 'product_purchase_12') ?
                        <Box paddingBlockEnd={200}>
                          <InlineStack blockAlign='center' align='space-between'>
                            <Box width='80%'>
                              <RangeSlider
                                min={80}
                                max={110}
                                step={10}
                                label={<Text>Size</Text>}
                                value={shape_height}
                                onChange={(e) => handleSettingChange('dctp', e)}
                                output />
                            </Box>
                            <ColorPick
                              width='medium'
                              colorKey="bg"
                              colorValue={cnf?.bg}
                              functionCall={handleMainTextChange}
                            />
                          </InlineStack>
                          {queryNtyp === 'inlinenudge' && cnf?.bgblr !== undefined &&
                            <Box paddingBlockStart={200}>
                              <RangeSlider
                                min={0}
                                max={30}
                                value={cnf?.bgblr || 0}
                                onChange={(e) => handleMainTextChange('bgblr', e)}
                                label={<Tooltip dismissOnMouseOut padding="400" content='Background Blur' width='wide' hasUnderline><Text>Effect</Text></Tooltip>}
                                output />
                            </Box>
                          }
                        </Box>
                        :
                        <Box paddingBlockEnd={200}>
                          <ColorPick
                            labelName='Background color'
                            colorKey="bg"
                            colorValue={cnf?.bg}
                            functionCall={handleMainTextChange}
                          />
                          {cnf && defaultNotif?.ttn === 'promotional_banner_timer' &&
                            (cnf?.tmcntbg !== undefined || cnf?.tmtxtclr !== undefined) &&
                            <Box paddingBlockStart={200}>
                              <InlineGrid columns={2} gap={300}>
                                <ColorPick
                                  labelName='Timer bg color'
                                  colorKey="tmcntbg"
                                  colorValue={cnf?.tmcntbg}
                                  functionCall={handleMainTextChange} />
                                <ColorPick
                                  labelName='Timer text color'
                                  colorKey="tmtxtclr"
                                  colorValue={cnf?.tmtxtclr}
                                  functionCall={handleMainTextChange} />
                              </InlineGrid>
                            </Box>
                          }
                        </Box>
                      }
                      {cnf?.r !== undefined &&
                        <RangeSlider
                          min={0}
                          max={50}
                          value={cnf?.r || 0}
                          onChange={(e) => handleMainTextChange('r', e)}
                          label={<Text>Radius</Text>}
                          output />
                      }
                    </Box>
                  </div>
                }

                {/* border size & style */}
                {(cnf?.rc !== undefined || cnf?.rw !== undefined || cnf?.rs !== undefined) &&
                  defaultNotif?.ttn !== 'live_visitor_6' &&
                  <div>
                    <Tooltip dismissOnMouseOut padding="400" content='Customize border' width='wide' hasUnderline><Text fontWeight='medium'>Border</Text></Tooltip>
                    <Box paddingInlineStart={400} paddingBlockStart={150}>
                      {cnf?.rw !== undefined &&
                        <InlineStack blockAlign='center' align='space-between'>
                          <Box width='80%'>
                            <RangeSlider
                              max={6}
                              value={cnf?.rw || 0}
                              onChange={(e) => handleMainTextChange('rw', e)}
                              label={<Text>Size</Text>}
                              output />
                          </Box>
                          <ColorPick
                            width='medium'
                            colorKey="rc"
                            colorValue={cnf?.rc}
                            functionCall={handleMainTextChange}
                          />
                        </InlineStack>
                      }
                      {cnf?.rs !== undefined &&
                        <Box paddingBlockStart={300}>
                          <Text>Style</Text>
                          <Box paddingBlockStart={100}>
                            <Popover
                              fullWidth
                              active={state.isBrderStyle}
                              onClose={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}
                              activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}>
                                <Text variant='bodyMd' tone='subdued'>
                                  {borderStyle.find(({ value }) => value === cnf?.rs)?.label || 'Select'}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={borderStyle}
                                selected={cnf?.rs}
                                onChange={() => changeNameValue({ isBrderStyle: !state.isBrderStyle })}
                                onFocusOption={(e) => handleMainTextChange('rs', e)} />
                            </Popover>
                          </Box>
                        </Box>
                      }
                    </Box>
                  </div>
                }

                {/* shadow */}
                <div>
                  <Tooltip dismissOnMouseOut padding="400" content='Customize shadow effect' width='wide' hasUnderline>
                    <Text fontWeight='medium'>Shadow</Text></Tooltip>
                  <Box paddingInlineStart={400} paddingBlockStart={150}>
                    <BlockStack gap={200}>
                      <InlineStack blockAlign='center' align='space-between'>
                        <Box width='80%'>
                          <RangeSlider
                            step={2}
                            max={50}
                            value={cnf?.shdbr || 0}
                            onChange={(e) => handleMainTextChange('shdbr', e)}
                            label={<Text>Effect</Text>}
                            output />
                        </Box>
                        <ColorPick
                          width='medium'
                          colorKey="shdclr"
                          colorValue={cnf?.shdclr}
                          functionCall={handleMainTextChange}
                        />
                      </InlineStack>
                      <RangeSlider
                        min={-10}
                        max={10}
                        value={cnf?.shdho || 0}
                        onChange={(e) => handleMainTextChange('shdho', e)}
                        label={<Text>Horizontal</Text>}
                        output />
                      <RangeSlider
                        min={-10}
                        max={10}
                        value={cnf?.shdvo || 0}
                        onChange={(e) => handleMainTextChange('shdvo', e)}
                        label={<Text>Vertical</Text>}
                        output />
                    </BlockStack>
                  </Box>
                </div>

              </BlockStack>
              :
              state.verticalActive === getValue('Set countdown timer') ?
                <div>
                  {cnf?.tmdr !== undefined &&
                    <div>
                      <Checkbox
                        label={<Tooltip dismissOnMouseOut padding="400" content='Set date, when you want to expire your offer' width='wide' hasUnderline>
                          <Text fontWeight='medium'>Set countdown timer</Text></Tooltip>}
                        checked={cnf?.itm}
                        onChange={(e) => handleMainTextChange('itm', e)} />
                      {cnf?.itm === true &&
                        <Box paddingInlineStart={700} paddingBlockStart={100}>
                          <TextField
                            disabled={cnf?.itmegrn}
                            type="datetime-local"
                            label={
                              new Date(cnf?.tmdr).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ? (
                                <>
                                  Fixed countdown <Badge tone="critical">expired</Badge>
                                </>
                              ) : (
                                <Tooltip
                                  dismissOnMouseOut
                                  padding="400"
                                  content="Set the timer duration to specify how long the timer will be displayed. After this duration, the notification will automatically hide when the selected date-time is reached."
                                  hasUnderline
                                  width="wide"
                                >
                                  <Text>Fixed countdown</Text>
                                </Tooltip>
                              )
                            }
                            value={cnf?.tmdr ? formateDate(cnf?.tmdr) : ''}
                            onChange={(e) => handleMainTextChange('tmdr', e ? new Date(e.toLocaleString('en-IN', dateFormatOpt)).getTime() : '')}
                          />

                          <Box paddingBlockStart={300}>
                            <Checkbox
                              label={<Tooltip dismissOnMouseOut padding="400" content='Use this countdown to display some special offers to users who have arrived on your website, asking them to avail the offer within the specified time.' width='wide' hasUnderline>
                                <InlineStack gap={200}>
                                  <Text>Evergreen countdown</Text>
                                </InlineStack>
                              </Tooltip>}
                              checked={cnf?.itmegrn}
                              onChange={(e) => handleMainTextChange('itmegrn', e)} />
                            {cnf?.itmegrn === true &&
                              <InlineGrid columns={2}>
                                <TextField
                                  type='Number'
                                  disabled={!cnf?.itmegrn}
                                  value={cnf?.itmd}
                                  placeholder='Days'
                                  onChange={(e) => handleMainTextChange('itmd', e)}
                                  error={errMessage?.days ? errMessage?.days : false} />
                                {designValidator.message('days', cnf?.itmd, 'required|max:30,num|min:0,num')}
                                <TextField
                                  type='Number'
                                  disabled={!cnf?.itmegrn}
                                  value={cnf?.itmh}
                                  placeholder='Hours'
                                  onChange={(e) => handleMainTextChange('itmh', e)}
                                  error={errMessage?.hours ? errMessage?.hours : false} />
                                {designValidator.message('hours', cnf?.itmh, 'required|max:24,num|min:0,num')}
                                <TextField
                                  type='Number'
                                  disabled={!cnf?.itmegrn}
                                  value={cnf?.itmm}
                                  placeholder='Minutes'
                                  onChange={(e) => handleMainTextChange('itmm', e)}
                                  error={errMessage?.minutes ? errMessage?.minutes : false} />
                                {designValidator.message('minutes', cnf?.itmm, 'required|max:60,num|min:0,num')}
                                <TextField
                                  type='Number'
                                  disabled={!cnf?.itmegrn}
                                  value={cnf?.itms}
                                  placeholder='Seconds'
                                  onChange={(e) => handleMainTextChange('itms', e)}
                                  error={errMessage?.seconds ? errMessage?.seconds : false} />
                                {designValidator.message('seconds', cnf?.itms, 'required|max:60,num|min:0,num')}
                              </InlineGrid>
                            }
                          </Box>

                          {cnf?.itmegrn === true &&
                            <Box paddingBlockStart={300}>
                              <Box paddingBlockEnd={100}>
                                <Tooltip dismissOnMouseOut padding="400" content='If you want to restart the timer after it expires then select "Restart" option or If you want to hide it then select "Hide" the option' hasUnderline width='wide'><Text>Action after expiry</Text></Tooltip></Box>
                              <Popover
                                fullWidth
                                active={state.isActnAfter}
                                onClose={() => changeNameValue({ isActnAfter: !state.isActnAfter })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isActnAfter: !state.isActnAfter })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {TimerAction.find(({ value }) => value === cnf?.itafexp)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={TimerAction}
                                  selected={[cnf?.itafexp] || ['']}
                                  onChange={() => changeNameValue({ isActnAfter: !state.isActnAfter })}
                                  onFocusOption={(e) => handleMainTextChange('itafexp', e)} />
                              </Popover>

                              {cnf?.itafexp === '2' &&
                                <Box paddingBlockStart={300}>
                                  <TextField
                                    type='Number'
                                    value={cnf?.itrsttm}
                                    placeholder='Enter the minutes' autoComplete='off'
                                    label={<Tooltip dismissOnMouseOut padding="400" content='The timer will be started again after this specific time once the timer expires.' width='wide' hasUnderline><Text>Restart after {cnf?.itrsttm} minutes</Text></Tooltip>}
                                    onChange={(e) => handleMainTextChange('itrsttm', e ? Number(e) : '')}
                                    error={errMessage.restartAfter ? errMessage.restartAfter : false} />
                                  {designValidator.message('restartAfter', cnf?.itrsttm, 'required')}
                                </Box>
                              }
                            </Box>
                          }

                          <Box paddingBlock={300}>
                            <Checkbox
                              label={<Text>Auto-redirect upon timer expiry</Text>}
                              checked={cnf?.tmrirdurl}
                              onChange={(e) => handleMainTextChange('tmrirdurl', e)}
                            />
                            {cnf?.tmrirdurl === true &&
                              <Box paddingInlineStart={700}>
                                <TextField
                                  value={cnf?.tmrrdurl}
                                  placeholder='Enter redirect URL' autoComplete='off'
                                  onChange={(e) => handleMainTextChange('tmrrdurl', e)}
                                  error={errMessage?.redirectUrl ? errMessage.redirectUrl : false} />
                                {designValidator.message('redirectUrl', cnf?.tmrrdurl, 'required')}
                              </Box>
                            }
                          </Box>

                          {/* hide single counter field timer notification */}
                          {cnf && defaultNotif?.ttn === 'promotional_banner_timer' &&
                            <BlockStack gap={300}>
                              <Checkbox
                                label={<Text>Enable to hide day counter</Text>}
                                checked={cnf?.itmrhddd}
                                onChange={(e) => handleMainTextChange('itmrhddd', e)}
                              />
                              <Checkbox
                                label={<Text>Enable to hide hour counter</Text>}
                                checked={cnf?.itmrhdhh}
                                onChange={(e) => handleMainTextChange('itmrhdhh', e)}
                              />
                              <Checkbox
                                label={<Text>Enable to hide minute counter</Text>}
                                checked={cnf?.itmrhdmm}
                                onChange={(e) => handleMainTextChange('itmrhdmm', e)}
                              />

                              {(defaultNotif?.tn === 'Timer-1' || defaultNotif?.tn === 'Timer-4' || defaultNotif?.tn === 'Timer-6' || defaultNotif?.tn === 'Timer-10') &&
                                <BlockStack gap={100}>
                                  <Text tone='subdued'>Font size for timer digit</Text>
                                  <Popover
                                    fullWidth
                                    active={state.isFnSTimer}
                                    onClose={() => changeNameValue({ isFnSTimer: !state.isFnSTimer })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isFnSTimer: !state.isFnSTimer })}>
                                      <Text variant='bodyMd' tone='subdued'>
                                        {timerFontSize.find(({ value }) => value === cnf?.tmrfntsz)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={timerFontSize}
                                      selected={[cnf?.tmrfntsz] || ['']}
                                      onChange={() => changeNameValue({ isFnSTimer: !state.isFnSTimer })}
                                      onFocusOption={(e) => handleMainTextChange('tmrfntsz', e)} />
                                  </Popover>
                                </BlockStack>
                              }
                            </BlockStack>
                          }

                          {cnf?.tmrirdurl !== undefined &&
                            <Box paddingBlockStart={300}>
                              <Checkbox
                                label={<Tooltip dismissOnMouseOut padding="400" content='Turn off to open the link in a new tab.' width='wide' hasUnderline>
                                  <Text>Open in same tab</Text></Tooltip>}
                                checked={cnf?.tmrirdnwtb}
                                onChange={(e) => handleMainTextChange('tmrirdnwtb', e)} />
                            </Box>
                          }
                        </Box>
                      }
                    </div>
                  }
                </div>
                :
                state.verticalActive === getValue('Embed code') ?
                  <BlockStack gap={400}>

                    {/* embed HTML */}
                    {isCollector && notiById?.idisnp !== undefined &&
                      <div>
                        <InlineStack blockAlign='center' align='space-between'>
                          <Checkbox
                            label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to hide popup effect and display as in-line notification' width='wide' hasUnderline><Text fontWeight='medium'>Embed into your HTML</Text></Tooltip>}
                            checked={notiById?.idisnp}
                            onChange={(e) => handleSettingChange('idisnp', e)} />
                          {(localStorage.getItem('isbusr') === 'false' && isWhite === false) &&
                            <div id='cursorPoint' onClick={() => {
                              openCloseModal('youTubeOpen', state.youTubeOpen, 'open');
                              changeNameValue({ youTubeKey: 'JSoRHDNAqkw' })
                            }}>
                              <Box id='videoLink' background='bg-fill-inverse-active'
                                borderRadius='100' paddingBlock={'050'} paddingInlineStart={100} paddingInlineEnd={200}>
                                <InlineStack>
                                  <Icon source={PlayIcon} /><Text variant='bodySm'>2:45</Text>
                                </InlineStack>
                              </Box>
                            </div>
                          }
                        </InlineStack>

                        {notiById?.idisnp === true &&
                          <div>  {/* copy code */}
                            {localStorage.getItem('pvd') !== 'duda' &&
                              <Box paddingInlineStart={700} paddingBlockStart={100}>
                                {queryNtt !== 'limited_stock' ?
                                  <div>
                                    <InlineStack align='space-between'>
                                      <Text>Put this HTML tag at your desired place</Text>
                                      <Button variant='monochromePlain' icon={ClipboardIcon}
                                        onClick={() => copyHTMLSnippet(notifId, '', 'html_snippet_txt')}></Button>
                                    </InlineStack>
                                    <Box paddingBlockStart={100}>
                                      <div onClick={() => copyHTMLSnippet(notifId, '', 'html_snippet_txt')}>
                                        <TextField id='html_snippet_txt' multiline='2'
                                          value={`<div id=${notifId} class='htmlsnpt'></div>`} />
                                      </div>
                                    </Box>
                                  </div>
                                  :
                                  <div>
                                    <InlineStack align='space-between'>
                                      <Text>Put this HTML tag at your desired place</Text>
                                      <Button variant='monochromePlain' icon={ClipboardIcon}
                                        onClick={() => copyHTMLSnippet(notifId, 'limitedStock', 'html_snippet_txt')}></Button>
                                    </InlineStack>
                                    <Box paddingBlockStart={100}>
                                      <div onClick={() => copyHTMLSnippet(notifId, 'limitedStock', 'html_snippet_txt')}>
                                        <TextField id='html_snippet_txt' multiline
                                          value={`<div id=${notifId} data-id="{{ product.id }}" class='htmlsnpt wn_lmtdstk_snpt'></div>`} />
                                      </div>
                                    </Box>
                                  </div>
                                }
                              </Box>
                            }

                            {/* with design */}
                            {queryNtt !== 'custom' && queryNtt !== 'limited_stock' &&
                              defaultNotif?.ttn !== 'promotional_banner_timer' &&
                              <Box paddingBlockStart={400}>
                                <Checkbox
                                  label={<Tooltip dismissOnMouseOut padding="400" content='Turn "ON" to show notification design else it will show only text.' width='wide' hasUnderline><Text fontWeight='medium'>With design</Text></Tooltip>}
                                  checked={notiById?.isnpwdsg}
                                  onChange={(e) => handleSettingChange('isnpwdsg', e)} />
                              </Box>
                            }

                            {/* multiple livefeed */}
                            {(queryNtt === 'signup_recent' || queryNtt === 'purchase_recent' || queryNtt === 'review_recent' || queryNtt === 'single_visits') &&
                              <Box paddingBlockStart={400}>
                                <Checkbox
                                  label={<Text fontWeight='medium'>Display multiple data as a live feed</Text>}
                                  checked={notiById?.isnpifdanmt}
                                  onChange={(e) => handleSettingChange('isnpifdanmt', e)} />

                                <Box paddingInlineStart={700} paddingBlockStart={100}>
                                  <TextField
                                    type='Number'
                                    value={notiById?.isnpcsthgt}
                                    disabled={!notiById?.isnpifdanmt}
                                    label={<Text>Customize height of the live feed</Text>}
                                    onChange={(e) => handleSettingChange('isnpcsthgt', e ? Number(e) : '')}
                                  />
                                  <Box paddingBlockStart={150}>
                                    <TextField
                                      type='Number'
                                      value={notiById?.isnpspcbtwnntf}
                                      disabled={!notiById?.isnpifdanmt}
                                      label={<Text>Space between two notification</Text>}
                                      onChange={(e) => handleSettingChange('isnpspcbtwnntf', e ? Number(e) : '')}
                                    />
                                  </Box>
                                </Box>

                              </Box>
                            }
                          </div>
                        }
                      </div>
                    }

                    {/* embed into shopify - showing in call_back_1,3 & 4 */}
                    {(queryNtt === 'call_to_action' && defaultNotif?.ttn !== 'call_back_2') &&
                      <div>
                        <Checkbox
                          label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to embed into shopify' hasUnderline width='wide'><Text fontWeight='medium'>Embed into shopify</Text></Tooltip>}
                          checked={notiById?.iemdshpfy}
                          onChange={(e) => handleSettingChange('iemdshpfy', e)} />

                        {notiById?.iemdshpfy === true &&
                          <div> {/* copy code */}
                            {localStorage.getItem('pvd') !== 'duda' &&
                              <Box paddingInlineStart={700} paddingBlockStart={100}>
                                <InlineStack align='space-between'>
                                  <Text>Put this id in shopify extension</Text>
                                  <Button variant='monochromePlain' icon={ClipboardIcon}
                                    onClick={() => copyHTMLSnippet(notifId, 'shopify', 'html_snipShopi_txt')}></Button>
                                </InlineStack>
                                <Box paddingBlockStart={100}>
                                  <div onClick={() => copyHTMLSnippet(notifId, 'shopify', 'html_snipShopi_txt')}>
                                    <TextField id='html_snipShopi_txt' multiline='2' value={notifId} />
                                  </div>
                                </Box>
                              </Box>
                            }
                          </div>
                        }
                      </div>
                    }

                    {/* add custom css*/}
                    <div>
                      <Checkbox
                        label={<Tooltip dismissOnMouseOut padding="400" content='Enable this option to add custom css' width='wide' hasUnderline><Text fontWeight='medium'>Add custom css</Text></Tooltip>}
                        checked={notiById?.icstcss}
                        onChange={(e) => handleSettingChange('icstcss', e)}
                      />
                      {notiById?.icstcss === true &&
                        <Box paddingInlineStart={700} paddingBlockStart={100}>
                          <TextField
                            multiline={4}
                            value={notiById?.cstcss || ''}
                            autoComplete='off'
                            onChange={(e) => handleSettingChange('cstcss', e)} />
                        </Box>
                      }
                    </div>

                  </BlockStack>
                  :
                  state.verticalActive === getValue('Data control') ?
                    <div>
                      <Text fontWeight='medium'>Data history</Text>
                      <Box paddingBlockStart={300} paddingInlineStart={500}>
                        <BlockStack gap={300}>

                          {(queryNtt === 'purchase_recent' || queryNtt === 'purchase_bulk' ||
                            queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' ||
                            queryNtt === 'purchase_recent_ecomm_snippet' || queryNtt === 'purchase_bulk_ecomm_snippet' ||
                            queryNtt === 'recent_view_bulk') && isAbtest === false &&
                            <BlockStack gap={100}>
                              {(queryNtt === 'purchase_recent' || queryNtt === 'purchase_recent_ecomm_snippet' || queryNtt === 'signup_recent') ?
                                <Text>Display last {notiById?.tr} {notiById?.tu} data</Text>
                                :
                                <Text>Display last {notiById?.tr} {notiById?.tu} records</Text>
                              }

                              {/* 'tr'-textBox & 'tu'-dropdown field */}
                              <InlineGrid columns={2} gap={300}>
                                <TextField
                                  type='Number'
                                  placeholder='day/hour' autoComplete='off'
                                  value={notiById?.tr}
                                  onChange={(e) => handleSettingChange('tr', e ? Number(e) : '')}
                                  error={errMessage?.dayHour ? errMessage.dayHour : false} />
                                {designValidator.message('dayHour', notiById?.tr, 'required')}

                                <Popover
                                  fullWidth
                                  active={state.isLstDays}
                                  onClose={() => changeNameValue({ isLstDays: !state.isLstDays })}
                                  activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                    onClick={() => changeNameValue({ isLstDays: !state.isLstDays })}>
                                    <Text variant='bodyMd' tone='subdued'>
                                      {displayTime.find(({ value }) => value === notiById?.tu)?.label || 'Select'}
                                    </Text>
                                  </Button>}>
                                  <OptionList
                                    options={displayTime}
                                    selected={[notiById?.tu]}
                                    onChange={() => changeNameValue({ isLstDays: !state.isLstDays })}
                                    onFocusOption={(e) => handleSettingChange('tu', e)} />
                                </Popover>
                              </InlineGrid>
                            </BlockStack>
                          }

                          {(queryNtt === 'purchase_recent' || queryNtt === 'purchase_recent_ecomm_snippet'
                            || queryNtt === 'signup_recent' || queryNtt === 'single_visits') && isAbtest === false &&
                            <div>
                              <TextField
                                type='number'
                                value={notiById?.ld}
                                placeholder={queryNtt === 'signup_recent' ? 'Signup record'
                                  : queryNtt === 'single_visits' ? 'Visit record'
                                    : 'Purchased record'} autoComplete='off'
                                label={<Text>Display last {notiById?.ld} {queryNtt === 'single_visits' ? 'visits' : 'record'}</Text>}
                                onChange={(e) => handleSettingChange('ld', e ? Number(e) : '')}
                                error={errMessage?.displayRecord ? errMessage.displayRecord : false} />
                              {designValidator.message('displayRecord', notiById?.ld, 'required')}
                            </div>
                          }

                          {(queryNtt === 'live_visitor' || queryNtt === 'live_visitor_ecomm_snippet'
                            || queryNtt === 'single_visits') &&
                            <BlockStack gap={100}>
                              {(queryNtt === 'live_visitor' || queryNtt === 'live_visitor_ecomm_snippet') ?
                                <Text>Display last {displayMinutesHours.find(x => x.value == notiById?.tr)?.label || ''} records</Text>
                                :
                                <Text>Display last {displayMinutesHours.find(x => x.value == notiById?.tr)?.label || ''} visits</Text>
                              }
                              <Popover
                                fullWidth
                                active={state.isLveRecrds}
                                onClose={() => changeNameValue({ isLveRecrds: !state.isLveRecrds })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isLveRecrds: !state.isLveRecrds })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {displayMinutesHours.find(({ value }) => value == notiById?.tr)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={displayMinutesHours}
                                  selected={[notiById?.tr?.toString()]}
                                  onChange={() => changeNameValue({ isLveRecrds: !state.isLveRecrds })}
                                  onFocusOption={(e) => handleSettingChange('tr', e)} />
                              </Popover>
                            </BlockStack>
                          }

                          {(queryNtt === 'live_visitor' || queryNtt === 'live_visitor_ecomm_snippet' ||
                            queryNtt === 'signup_bulk' || queryNtt === 'purchase_bulk' ||
                            queryNtt === 'recent_view_bulk' || queryNtt === 'review_bulk' ||
                            queryNtt === 'social_media_count' || queryNtt === 'purchase_bulk_ecomm_snippet') &&
                            <div>
                              <TextField
                                type="number"
                                label={(queryNtt === 'live_visitor' || queryNtt === 'live_visitor_ecomm_snippet') ?
                                  <Text>Hide if visitors are less than {notiById?.mnbc}</Text>
                                  : <Text>Hide if count is less than {notiById?.mnbc}</Text>}
                                placeholder='count' autoComplete='off'
                                value={notiById?.mnbc}
                                onChange={(e) => handleSettingChange('mnbc', e ? Number(e) : '')}
                                error={errMessage?.visitorCount ? errMessage.visitorCount : false} />
                              {designValidator.message('visitorCount', notiById?.mnbc, 'required')}
                            </div>
                          }

                          {(queryNtt === 'live_visitor' || queryNtt === 'single_visits') &&
                            <Checkbox
                              label={<Tooltip dismissOnMouseOut padding="400" content={queryNtt === 'live_visitor' ? 'Display live visitor count for specific page' : 'Display live visitor for specific page'}>
                                <Text>Show page specific visitors {queryNtt === 'live_visitor' && 'count'}</Text></Tooltip>}
                              checked={notiById?.lcpt}
                              onChange={(e) => handleSettingChange('lcpt', e)} />
                          }

                          {queryNtt === 'recent_view_bulk' &&
                            <div>
                              <Checkbox
                                checked={notiById?.icntpth}
                                label={<Tooltip dismissOnMouseOut padding="400" content='Enable it to show specific page visitor count' width='wide' hasUnderline><Text>Show specific page visitor count</Text></Tooltip>}
                                onChange={(e) => handleSettingChange('icntpth', e)} />
                              <TextField
                                value={notiById?.cntpth}
                                disabled={!notiById?.icntpth}
                                placeholder='Enter page URL' autoComplete='off'
                                onChange={(e) => handleSettingChange('cntpth', e)}
                                error={errMessage?.pageURl ? errMessage.pageURl : false} />
                              {designValidator.message('pageURl', notiById?.cntpth, 'required')}
                            </div>
                          }

                          {queryNtt === 'review_recent' && isAbtest === false &&
                            <div>
                              <TextField
                                type="number"
                                value={notiById?.mnrt}
                                label={<Tooltip dismissOnMouseOut padding="400" content='Enter 4 to show 4 & 5 star reviews' width='wide' hasUnderline><Text>Display review if minimum star rating is {notiById?.mnrt}</Text></Tooltip>}
                                placeholder='Star ratings' autoComplete='off'
                                onChange={(e) => handleSettingChange('mnrt', e ? Number(e) : '')}
                                error={errMessage?.minimumRating ? errMessage.minimumRating : false} />
                              {designValidator.message('minimumRating', notiById?.mnrt, 'required')}
                            </div>
                          }

                          {(queryNtt === 'review_recent' || queryNtt === 'social_media_post') && isAbtest === false &&
                            <BlockStack gap={300}>
                              <TextField
                                type="number"
                                value={notiById?.mnrtxtln}
                                placeholder='Review text length' autoComplete='off'
                                label={<Tooltip dismissOnMouseOut padding="400" content='Set characters limit to display review into the notification' width='wide' hasUnderline><Text>Display review if it has min. {notiById?.mnrtxtln} characters</Text></Tooltip>}
                                onChange={(e) => handleSettingChange('mnrtxtln', e ? Number(e) : '')}
                                error={errMessage?.minimumReview ? errMessage.minimumReview : false} />
                              {designValidator.message('minimumReview', notiById?.mnrtxtln, 'required')}

                              <TextField
                                type="number"
                                value={notiById?.ld}
                                placeholder='Reviews' autoComplete='off'
                                label={<Text>Display last {notiById?.ld} reviews</Text>}
                                onChange={(e) => handleSettingChange('ld', e ? Number(e) : '')}
                                error={errMessage?.displayReviews ? errMessage.displayReviews : false} />
                              {designValidator.message('displayReviews', notiById?.ld, 'required')}
                            </BlockStack>
                          }

                          {(queryNtt === 'limited_stock' || queryNtt === 'limited_stock_ecomm_snippet') &&
                            <BlockStack gap={300}>
                              <div>
                                <Checkbox
                                  label={<Tooltip dismissOnMouseOut padding="400" content='Make it OFF if you want to set a limit for showing notification' width='wide' hasUnderline><Text>Show total quantity count?</Text></Tooltip>}
                                  checked={notiById?.itqc}
                                  onChange={(e) => handleSettingChange('itqc', e)}
                                />

                                <Box paddingInlineStart={700}>
                                  <TextField
                                    type='Number'
                                    value={notiById?.mqt}
                                    placeholder='Enter minimum quantity' autoComplete='off'
                                    disabled={notiById?.itqc}
                                    label={<Tooltip dismissOnMouseOut padding="400" content='Write the minimum "item left" count to display item left line' width='wide' hasUnderline><Text tone='subdued'>Show "item left" counter line if the item value is less than {notiById?.mqt || 'x'}</Text></Tooltip>}
                                    onChange={(e) => handleSettingChange('mqt', e ? Number(e) : '')}
                                    error={errMessage?.minumumQuantity ? errMessage.minumumQuantity : false} />
                                  {!notiById?.itqc && designValidator.message('minumumQuantity', notiById?.mqt, 'required')}
                                </Box>
                              </div>

                              <div>
                                <Checkbox
                                  label={<Text>Show message when the inventory is higher</Text>}
                                  checked={cnf?.ismsmxqty}
                                  onChange={(e) => handleMainTextChange('ismsmxqty', e)}
                                />
                                <Box paddingInlineStart={700} paddingBlockStart={'050'}>
                                  <BlockStack gap={300}>
                                    <TextField
                                      type='Number'
                                      value={cnf?.cntmxqty}
                                      disabled={!cnf?.ismsmxqty}
                                      placeholder='Minimum stock count'
                                      label={<Tooltip dismissOnMouseOut padding="400" content='Add stock count when you want to display below message' width='wide' hasUnderline><Text tone='subdued'>Minimum stock count</Text></Tooltip>}
                                      onChange={(e) => handleMainTextChange('cntmxqty', e ? Number(e) : '')}
                                      error={errMessage?.minimumStockCount ? errMessage.minimumStockCount : false} />
                                    {cnf?.ismsmxqty && designValidator.message('minimumStockCount', cnf?.cntmxqty, 'required')}

                                    <BlockStack gap={100}>
                                      <Tooltip dismissOnMouseOut padding="400" content='Enter the message you want to always display about inventory. This helps add urgency when stock counts are high.' width='wide' hasUnderline><Text tone='subdued'>Write display message</Text></Tooltip>
                                      {!cnf?.ismsmxqty ?
                                        <TextField value={Parser(cnf?.msgmxqty).props?.children?.props?.children || ''} disabled />
                                        :
                                        <ReactQuill value={cnf?.msgmxqty || ''}
                                          className='preview-mail hideEditor msgmxqty'
                                          modules={modulesWebFontQuill} formats={formatsQuill}
                                          onFocus={() => editorFocus('msgmxqty')}
                                          onBlur={() => editorBlur('msgmxqty')}
                                          onChange={(newValue, delta, source) => {
                                            if (source === 'user') { handleMainTextChange('msgmxqty', newValue, 'removespace') }
                                          }} />
                                      }
                                    </BlockStack>

                                    {queryNtt !== 'limited_stock_ecomm_snippet' &&
                                      <div>
                                        <TextField
                                          type="number"
                                          value={notiById?.mnbc}
                                          placeholder='min visitor count'
                                          label={<Tooltip dismissOnMouseOut padding="400" content='Write minimum "viewer" counter value to display viewer line' width='wide' hasUnderline><Text tone='subdued'>Show "viewer" counter line if its value is greater than {notiById?.mnbc}</Text></Tooltip>}
                                          onChange={(e) => handleSettingChange('mnbc', e ? Number(e) : '')}
                                          error={errMessage?.viewerCounter ? errMessage.viewerCounter : false} />
                                        {designValidator.message('viewerCounter.', notiById?.mnbc, 'required')}
                                      </div>
                                    }

                                  </BlockStack>
                                </Box>
                              </div>
                            </BlockStack>
                          }

                          <BlockStack gap={200}>
                            {queryNtt === 'signup_recent' &&
                              <Checkbox
                                label={<Tooltip dismissOnMouseOut padding="400" content='Enable it to stop displaying  own conversion into the notification' width='wide' hasUnderline><Text>Skip own data in notification</Text></Tooltip>}
                                checked={notiById?.isdon}
                                onChange={(e) => handleSettingChange('isdon', e)} />
                            }

                            <BlockStack gap={100}>
                              {queryNtt !== 'limited_stock' && queryNtt !== 'live_visitor_ecomm_snippet' &&
                                queryNtt !== 'purchase_bulk_ecomm_snippet' && queryNtt !== 'purchase_recent_ecomm_snippet' &&
                                queryNtt !== 'limited_stock_ecomm_snippet' &&
                                <Checkbox
                                  label={isSocialNotif === true ?
                                    <Tooltip dismissOnMouseOut padding="400" content='Enable it to stop displaying seen data' width='wide' hasUnderline><Text>Skip seen data</Text></Tooltip>
                                    : <Tooltip dismissOnMouseOut padding="400" content='Enable it to skip widget for specific time interval' width='wide' hasUnderline><Text>Enable it to skip seen widgets</Text></Tooltip>
                                  }
                                  checked={notiById?.isnutd}
                                  onChange={(e) => handleSettingChange('isnutd', e)} />
                              }

                              {queryNtt !== 'limited_stock' && notiById?.isnutd === true &&
                                <Box paddingInlineStart={'0'}>
                                  <BlockStack gap={'050'}>
                                    <Tooltip dismissOnMouseOut padding="400" content='Don’t show same notification for selected time duration' width='wide' hasUnderline><Text tone='subdued'>Set time duration</Text></Tooltip>

                                    {notiById?.isnutd !== true ?
                                      <TextField value={skipNotifTime.find(({ value }) => value === notiById?.snutd)?.label || 'Select'} disabled />
                                      :
                                      <Popover
                                        fullWidth
                                        active={state.isDurTime}
                                        onClose={() => changeNameValue({ isDurTime: !state.isDurTime })}
                                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                          onClick={() => changeNameValue({ isDurTime: !state.isDurTime })}>
                                          <Text variant='bodyMd' tone='subdued'>
                                            {skipNotifTime.find(({ value }) => value == notiById?.snutd)?.label || 'Select'}
                                          </Text>
                                        </Button>}>
                                        <OptionList
                                          options={skipNotifTime}
                                          selected={[notiById?.snutd?.toString()]}
                                          onChange={() => changeNameValue({ isDurTime: !state.isDurTime })}
                                          onFocusOption={(e) => handleSettingChange('snutd', e ? Number(e) : '')} />
                                      </Popover>
                                    }
                                  </BlockStack>
                                </Box>
                              }
                            </BlockStack>

                          </BlockStack>

                          {(queryNtt === 'signup_recent' || queryNtt === 'purchase_recent'
                            || queryNtt === 'review_recent' || queryNtt === 'social_media_post') &&
                            <BlockStack gap={100}>
                              <Tooltip dismissOnMouseOut padding="400" content={`maximum ${notiById?.dmnt > 0 ? notiById?.dmnt : 'all'} notification will display per page`} width='wide' hasUnderline><Text>Set max. data limit to display into notification per page</Text></Tooltip>
                              <Popover
                                fullWidth
                                active={state.isMaxLimit}
                                onClose={() => changeNameValue({ isMaxLimit: !state.isMaxLimit })}
                                activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isMaxLimit: !state.isMaxLimit })}>
                                  <Text variant='bodyMd' tone='subdued'>
                                    {notifPerPage.find(({ value }) => value == notiById?.dmnt)?.label || 'Select'}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={notifPerPage}
                                  selected={[notiById?.dmnt?.toString()]}
                                  onChange={() => changeNameValue({ isMaxLimit: !state.isMaxLimit })}
                                  onFocusOption={(e) => handleSettingChange('dmnt', e)} />
                              </Popover>
                            </BlockStack>
                          }

                        </BlockStack>
                      </Box>
                    </div>
                    :
                    state.verticalActive === getValue('Timing control') ?
                      <BlockStack gap={300}>

                        {queryNtt !== 'limited_stock' && (isSocialNotif === true || (queryNtt === 'custom' && !notiById?.idisnp) || queryNtt === 'social_media_followers') &&
                          <div>
                            <Text fontWeight='medium'>Timing rules</Text>

                            {queryNtt === 'custom' &&
                              <Box paddingBlockStart={300} paddingBlockEnd={100}>
                                <Checkbox
                                  label={<Tooltip dismissOnMouseOut padding="400" content='Turn it ON to display widgets in loop' width='wide' hasUnderline><Text>Display in loop</Text></Tooltip>}
                                  checked={notiById?.icstlp}
                                  onChange={(e) => handleSettingChange('icstlp', e)} />
                              </Box>
                            }

                            {/* show on display in loop true && if 'display in loop' not available show this by default */}
                            {(queryNtt !== 'custom' || (notiById?.icstlp === true && (queryNtt === 'custom'))) &&
                              <Box paddingBlockStart={queryNtt === 'custom' ? 100 : 200}
                                paddingInlineStart={queryNtt === 'custom' ? 700 : 500}>
                                <BlockStack gap={300}>

                                  {/* hide if HTML snippet toogle on */}
                                  {!notiById?.idisnp &&
                                    (queryNtt !== 'live_visitor_ecomm_snippet' &&
                                      queryNtt !== 'purchase_bulk_ecomm_snippet' &&
                                      queryNtt !== 'purchase_recent_ecomm_snippet' &&
                                      queryNtt !== 'limited_stock_ecomm_snippet') &&
                                    <BlockStack gap={100}>
                                      <Tooltip dismissOnMouseOut padding="400" content='Set delay to start showing first notification' width='wide' hasUnderline><Text tone='subdued'>Initial delay time</Text></Tooltip>
                                      <Popover
                                        fullWidth
                                        active={state.isInitDelay}
                                        onClose={() => changeNameValue({ isInitDelay: !state.isInitDelay })}
                                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                          onClick={() => changeNameValue({ isInitDelay: !state.isInitDelay })}>
                                          <Text variant='bodyMd' tone='subdued'>
                                            {notifTiming.find(({ value }) => value == notiById?.intd)?.label || 'Select'}
                                          </Text>
                                        </Button>}>
                                        <OptionList
                                          options={notifTiming}
                                          selected={[notiById?.intd?.toString()]}
                                          onChange={() => changeNameValue({ isInitDelay: !state.isInitDelay })}
                                          onFocusOption={(e) => handleSettingChange('intd', e)} />
                                      </Popover>
                                    </BlockStack>
                                  }

                                  <BlockStack gap={100}>
                                    <Tooltip dismissOnMouseOut padding="400" content='Set time duration for displaying notifications' width='wide' hasUnderline><Text tone='subdued'>Display notification time</Text></Tooltip>
                                    <Popover
                                      fullWidth
                                      active={state.isDsplyNtf}
                                      onClose={() => changeNameValue({ isDsplyNtf: !state.isDsplyNtf })}
                                      activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                        onClick={() => changeNameValue({ isDsplyNtf: !state.isDsplyNtf })}>
                                        <Text variant='bodyMd' tone='subdued'>
                                          {notifTiming.find(({ value }) => value == notiById?.dsnt)?.label || 'Select'}
                                        </Text>
                                      </Button>}>
                                      <OptionList
                                        options={notifTiming}
                                        selected={[notiById?.dsnt?.toString()]}
                                        onChange={() => changeNameValue({ isDsplyNtf: !state.isDsplyNtf })}
                                        onFocusOption={(e) => handleSettingChange('dsnt', e)} />
                                    </Popover>
                                  </BlockStack>

                                  {/* hide if HTML snippet toogle on */}
                                  {!notiById?.idisnp &&
                                    (queryNtt !== 'live_visitor_ecomm_snippet' &&
                                      queryNtt !== 'purchase_bulk_ecomm_snippet' &&
                                      queryNtt !== 'purchase_recent_ecomm_snippet' &&
                                      queryNtt !== 'limited_stock_ecomm_snippet') &&
                                    <BlockStack gap={100}>
                                      <Tooltip dismissOnMouseOut padding="400" content='Set time difference between two notifications' width='wide' hasUnderline><Text tone='subdued'>Apply fix delay time between two notification</Text></Tooltip>

                                      {notiById?.rndnt !== false ?  //for disabled popover
                                        <TextField disabled value={delayNotifTime.find(({ value }) => value == notiById?.dlynt)?.label || 'Select'} />
                                        :
                                        <Popover
                                          fullWidth
                                          active={state.isApplyFix}
                                          onClose={() => changeNameValue({ isApplyFix: !state.isApplyFix })}
                                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                            onClick={() => changeNameValue({ isApplyFix: !state.isApplyFix })}>
                                            <Text variant='bodyMd' tone='subdued'>
                                              {delayNotifTime.find(({ value }) => value == notiById?.dlynt)?.label || 'Select'}
                                            </Text>
                                          </Button>}>
                                          <OptionList
                                            options={delayNotifTime}
                                            selected={[notiById?.dlynt?.toString()]}
                                            onChange={() => changeNameValue({ isApplyFix: !state.isApplyFix })}
                                            onFocusOption={(e) => handleSettingChange('dlynt', e)} />
                                        </Popover>
                                      }
                                    </BlockStack>
                                  }

                                  {!notiById?.idisnp &&
                                    (queryNtt !== 'live_visitor_ecomm_snippet' &&
                                      queryNtt !== 'purchase_bulk_ecomm_snippet' &&
                                      queryNtt !== 'purchase_recent_ecomm_snippet' &&
                                      queryNtt !== 'limited_stock_ecomm_snippet') &&
                                    <Box paddingBlockEnd={200}>
                                      <Checkbox
                                        label={<Tooltip dismissOnMouseOut padding="400" content='Enable this to set random time difference between two notifications' width='wide' hasUnderline><Text tone='subdued'>Randomize delay</Text></Tooltip>}
                                        checked={notiById?.rndnt}
                                        onChange={(e) => handleSettingChange('rndnt', e)} />
                                    </Box>
                                  }
                                </BlockStack>
                              </Box>
                            }
                          </div>
                        }

                        {/* show on display in loop false */}
                        {((queryNtt === 'custom' || queryNtt === 'video' ||
                          queryNtt === 'call_to_action' || queryNtt === 'lottery' ||
                          queryNtt === 'collector') && cnf?.dtrg !== undefined &&
                          (notiById?.icstlp !== true || notiById?.idisnp === true)) &&
                          <div>
                            <Text fontWeight='medium'>Behaviour rule</Text>
                            <Box paddingBlockStart={300} paddingInlineStart={300}>
                              <BlockStack gap={300}>

                                {/* select the start date and expire date */}
                                {isSocialNotif === false && isAbtest === false &&
                                  <BlockStack gap={300}>

                                    <TextField
                                      type="datetime-local"
                                      label={<Tooltip dismissOnMouseOut padding="400" content='Widgets will start displaying from this date' width='wide' hasUnderline><Text tone='subdued'>Select start date</Text></Tooltip>}
                                      value={notiById?.strtdt ? formateDate(notiById?.strtdt) : ''}
                                      onChange={(e) => handleSettingChange('strtdt', e ? new Date(e.toLocaleString('en-IN', dateFormatOpt)) : '')} />

                                    <TextField
                                      type="datetime-local"
                                      label={
                                        new Date(notiById?.expdt).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && notiById?.expdt != null ? (
                                          <>
                                            Select expiry date <Badge tone='critical'>Expired</Badge>
                                          </>
                                        ) : (
                                          <Tooltip
                                            dismissOnMouseOut
                                            padding="400"
                                            content="Widgets will not be displayed after this date"
                                            width="wide"
                                            hasUnderline
                                          >
                                            <Text tone="subdued">Select expiry date</Text>
                                          </Tooltip>
                                        )
                                      }
                                      value={notiById?.expdt ? formateDate(notiById?.expdt) : ''}
                                      onChange={(e) => handleSettingChange('expdt', e ? new Date(e.toLocaleString('en-IN', dateFormatOpt)) : '')} />

                                    <div>
                                      <Checkbox
                                        label={<Text tone='subdued'>Schedule on specific days of week</Text>}
                                        checked={cnf?.irptdy}
                                        onChange={(e) => handleMainTextChange('irptdy', e)} />
                                      {cnf?.irptdy === true &&
                                        <Popover
                                          fullWidth
                                          active={state.isSchdleWeek}
                                          onClose={() => changeNameValue({ isSchdleWeek: !state.isSchdleWeek })}
                                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure
                                            onClick={() => changeNameValue({ isSchdleWeek: !state.isSchdleWeek })}>
                                            <Text variant='bodyMd' tone='subdued'>
                                              {cnf?.arrptdy?.map(value => weekOpts.find(option => option.value === value)?.label).filter(Boolean).join(', ') || 'Select'}
                                            </Text>
                                          </Button>}>
                                          <Box padding={300}>
                                            <ChoiceList
                                              allowMultiple
                                              choices={weekOpts}
                                              selected={cnf?.arrptdy || []}
                                              onChange={(e) => handleMainTextChange('arrptdy', e)} />
                                          </Box>
                                        </Popover>
                                      }
                                    </div>

                                    {cnf?.irptdy === true &&
                                      <InlineGrid columns={2} gap={300}>
                                        <div>
                                          <Tooltip dismissOnMouseOut padding="400" content='After this time, the widget will be appear' width='wide' hasUnderline><Text tone='subdued'>Select start time</Text></Tooltip>
                                          <Popover
                                            fullWidth
                                            active={state.isStrtDt}
                                            onClose={() => changeNameValue({ isStrtDt: !state.isStrtDt })}
                                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                              onClick={() => changeNameValue({ isStrtDt: !state.isStrtDt })}>
                                              <Text variant='bodyMd' tone='subdued'>
                                                {cnf?.rptstm ? timeOptions.find(({ value }) => value === cnf?.rptstm)?.label : 'Select'}
                                              </Text>
                                            </Button>}>
                                            <OptionList
                                              options={timeOptions}
                                              selected={[cnf?.rptstm] || []}
                                              onChange={() => changeNameValue({ isStrtDt: !state.isStrtDt })}
                                              onFocusOption={(e) => handleMainTextChange('rptstm', e)} />
                                          </Popover>
                                        </div>

                                        <div>
                                          <Tooltip dismissOnMouseOut padding="400" content='After this time, the widget will be disappear' width='wide' hasUnderline><Text tone='subdued'>Select end time</Text></Tooltip>
                                          <Popover
                                            fullWidth
                                            active={state.isEndDt}
                                            onClose={() => changeNameValue({ isEndDt: !state.isEndDt })}
                                            activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                              onClick={() => changeNameValue({ isEndDt: !state.isEndDt })}>
                                              <Text variant='bodyMd' tone='subdued'>
                                                {cnf?.rptetm ? timeOptions.find(({ value }) => value === cnf?.rptetm)?.label : 'Select'}
                                              </Text>
                                            </Button>}>
                                            <OptionList
                                              options={timeOptions}
                                              selected={[cnf?.rptetm] || []}
                                              onChange={() => changeNameValue({ isEndDt: !state.isEndDt })}
                                              onFocusOption={(e) => handleMainTextChange('rptetm', e)} />
                                          </Popover>
                                        </div>
                                      </InlineGrid>
                                    }
                                  </BlockStack>
                                }

                                {/* display rule */}
                                <BlockStack gap={100}>
                                  <Text tone='subdued'>Display rule</Text>
                                  <Popover
                                    fullWidth
                                    active={state.isDsplyRule}
                                    onClose={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}>
                                      <Text variant='bodyMd' tone='subdued'>
                                        {dsplyRuleOpts.find(({ value }) => value == cnf?.dtrg)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={dsplyRuleOpts}
                                      selected={[cnf?.dtrg]}
                                      onChange={() => changeNameValue({ isDsplyRule: !state.isDsplyRule })}
                                      onFocusOption={(e) => handleMainTextChange('dtrg', e)} />
                                  </Popover>
                                </BlockStack>

                                {cnf?.dtrg === 2 &&
                                  <div>
                                    <TextField
                                      type='Number'
                                      placeholder='sec'
                                      value={cnf?.aft}
                                      onChange={(e) => handleMainTextChange('aft', e ? Number(e) : '')}
                                      error={errMessage?.sec || errMessage?.minimumCount || false} />
                                    {designValidator.message('sec', cnf?.aft, 'required')}
                                    {designValidator.message('minimumCount', cnf?.aft, 'Positive numbers: numeric|min:1,num')}
                                  </div>
                                }

                                {cnf?.dtrg === 3 &&
                                  <div>
                                    <TextField
                                      type='Number'
                                      placeholder='%'
                                      value={cnf?.sprc}
                                      onChange={(e) => handleMainTextChange('sprc', e ? Number(e) : '')}
                                      error={errMessage?.afterScroll ? errMessage.afterScroll : false} />
                                    {designValidator.message('afterScroll', cnf?.sprc, 'required')}
                                  </div>
                                }

                                <BlockStack gap={200}>
                                  {/* copy widget function */}
                                  <div>
                                    <Checkbox
                                      label={<Tooltip dismissOnMouseOut padding="400" width='wide' hasUnderline content={`It looks like: <button onClick='fnOnclickShowWidget("${notiById?._id}")'> Click </button>`}><Text tone='subdued'>Show widget using javascript</Text></Tooltip>}
                                      checked={cnf?.ishntfclk}
                                      onChange={(e) => handleMainTextChange('ishntfclk', e)} />

                                    {cnf?.ishntfclk === true &&
                                      <Box paddingInlineStart={700} paddingBlockStart={'050'}>
                                        <BlockStack gap={100}>
                                          <InlineStack align='space-between'>
                                            <Text tone='subdued' fontWeight='regular' variant='headingXs'>Call this function on custom event</Text>
                                            <Button variant='plain'
                                              onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_js_fn')}>Copy</Button>
                                          </InlineStack>
                                          <div onClick={() => copyHTMLSnippet(notifId, 'widget', 'wdgt_js_fn')}>
                                            <TextField
                                              id='wdgt_js_fn'
                                              value={`fnShowWidgetOnAnyEvent("{${notifId}}")`} />
                                          </div>
                                        </BlockStack>
                                      </Box>
                                    }
                                  </div>

                                  {/* hide rule */}
                                  <div>
                                    <Checkbox
                                      checked={cnf?.ihdwn}
                                      label={<Text tone='subdued'>Hide after {cnf?.hdwn ? cnf?.hdwn : 'x'} second</Text>}
                                      onChange={(e) => handleMainTextChange('ihdwn', e)}
                                    />
                                    {cnf?.ihdwn === true &&
                                      <Box paddingInlineStart={700}>
                                        <TextField
                                          type='Number'
                                          placeholder='Hide second'
                                          value={cnf?.hdwn}
                                          onChange={(e) => handleMainTextChange('hdwn', e ? Number(e) : '')}
                                          error={errMessage.HideSecond || errMessage.MinimumCount || false} />
                                        {designValidator.message('HideSecond', cnf?.hdwn, 'required')}
                                        {designValidator.message('MinimumCount', cnf?.hdwn, 'Positive numbers: numeric|min:1,num')}
                                      </Box>
                                    }
                                  </div>

                                  {/* Show Timer */}
                                  {cnf?.itm !== undefined && defaultNotif?.ttn !== 'promotional_banner_timer' &&
                                    <div>
                                      <InlineStack align='space-between' blockAlign='center'>
                                        <Checkbox
                                          checked={cnf?.itm}
                                          label={<Text tone='subdued'>Show timer text</Text>}
                                          onChange={(e) => handleMainTextChange('itm', e)}
                                        />
                                        <Button variant='plain' onClick={() => addUtmSource(cnf?.tmtxt, 'tmtxt')}>Add counter</Button>
                                      </InlineStack>
                                      {cnf?.itm === true &&
                                        <Box paddingInlineStart={700}>
                                          <Box paddingBlockEnd={300}>
                                            <TextField
                                              placeholder='Please enter the timer text'
                                              value={cnf?.tmtxt || ''}
                                              onChange={(e) => handleMainTextChange('tmtxt', e)} />
                                          </Box>
                                          <InlineGrid columns={2} gap={300}>
                                            <ColorPick
                                              labelName={<Text tone='subdued'>Second text color</Text>}
                                              colorKey="tmtxtclr"
                                              colorValue={cnf?.tmtxtclr}
                                              functionCall={handleMainTextChange} />
                                            <ColorPick
                                              labelName={<Text tone='subdued'>Second bg color</Text>}
                                              colorKey="tmtxtbg"
                                              colorValue={cnf?.tmtxtbg}
                                              functionCall={handleMainTextChange} />
                                          </InlineGrid>
                                        </Box>
                                      }
                                    </div>
                                  }

                                </BlockStack>

                              </BlockStack>
                            </Box>
                          </div>
                        }

                      </BlockStack>
                      :
                      state.verticalActive === getValue('Verify label') ?
                        <div>

                          {/* for paid plan give disabled field */}
                          {paidType === 'free' ?
                            <Tooltip dismissOnMouseOut padding="400" width='wide' content="This feature available in paid plan">
                              <Checkbox
                                label={<Text fontWeight='medium'>Verified by label</Text>}
                                checked={cnf?.ib || true}
                              // disabled onChange={(e) => handleMainTextChange('ib', e)}
                              />
                              <Box paddingInlineStart={700}>
                                <TextField readOnly value={cnf?.bn?.txt} />
                              </Box>
                            </Tooltip>
                            :
                            <div>
                              <Checkbox
                                label={<Text fontWeight='medium'>Verified by label</Text>}
                                checked={cnf?.ib}
                                disabled={localStorage.getItem('utyp') === 'appsumo' ? false : billing?.pobj?.crbn !== undefined ? !billing?.pobj?.crbn : false}
                                onChange={(e) => handleMainTextChange('ib', e)}
                              />
                              {cnf?.ib === true &&
                                <Box paddingInlineStart={700} paddingBlockStart={100}>
                                  <BlockStack gap={300}>
                                    <InlineStack blockAlign='end' align='space-between'>
                                      <Box width='86%'>
                                        <TextField
                                          value={cnf?.bn?.txt}
                                          placeholder='Verify by'
                                          label={<Text>Write label</Text>}
                                          disabled={localStorage.getItem('utyp') === 'appsumo' ? false : billing?.pobj?.crbn !== undefined ? !billing?.pobj?.crbn : false}
                                          onChange={(e) => handleTextChange('bn', 'txt', e)} />
                                      </Box>
                                      <ColorPick
                                        width='medium'
                                        colorKey="bn"
                                        secondKey="clr"
                                        colorValue={cnf?.bn?.clr}
                                        functionCall={handleTextChange}
                                      />
                                    </InlineStack>

                                    <InlineGrid columns={2} gap={200}>
                                      <div>
                                        <Box paddingBlockEnd={100}><Text>Font-family</Text></Box>
                                        <Popover
                                          fullWidth
                                          active={state.isVerfTxt}
                                          onClose={() => changeNameValue({ isVerfTxt: !state.isVerfTxt })}
                                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                            onClick={() => changeNameValue({ isVerfTxt: !state.isVerfTxt })}>
                                            <Text variant='bodyMd' tone='subdued'>
                                              {fontFamilyOpts.find(({ value }) => value === cnf?.bn?.fml)?.label || 'Select'}
                                            </Text>
                                          </Button>}>
                                          <OptionList
                                            options={fontFamilyOpts}
                                            selected={[cnf?.bn?.fml || []]}
                                            onChange={() => changeNameValue({ isVerfTxt: !state.isVerfTxt })}
                                            onFocusOption={(e) => handleTextChange('bn', 'fml', e)} />
                                        </Popover>
                                      </div>

                                      <div>
                                        <Box paddingBlockEnd={100}><Text>Font-weight</Text></Box>
                                        <Popover
                                          fullWidth
                                          active={state.isVerfStl}
                                          onClose={() => changeNameValue({ isVerfStl: !state.isVerfStl })}
                                          activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                            onClick={() => changeNameValue({ isVerfStl: !state.isVerfStl })}>
                                            <Text variant='bodyMd' tone='subdued'>
                                              {fontStyleOpts.find(({ value }) => value === cnf?.bn?.stl)?.label || 'Select'}
                                            </Text>
                                          </Button>}>
                                          <OptionList
                                            options={fontStyleOpts}
                                            selected={[cnf?.bn?.stl]}
                                            onChange={() => changeNameValue({ isVerfStl: !state.isVerfStl })}
                                            onFocusOption={(e) => handleTextChange('bn', 'stl', e)} />
                                        </Popover>
                                      </div>
                                    </InlineGrid>

                                    <div>
                                      <TextField
                                        label={<Text>Link</Text>}
                                        value={cnf?.bu}
                                        placeholder='Enter Redirect Url'
                                        disabled={localStorage.getItem('utyp') === 'appsumo' ? false : billing?.pobj?.crbn !== undefined ? !billing?.pobj?.crbn : false}
                                        onChange={(e) => handleMainTextChange('bu', e)}
                                      />
                                      <InlineStack align='end'> {/* add utm link */}
                                        <Button variant='plain' onClick={() => addUtmSource(cnf?.bu, 'bu')}>Add UTM</Button>
                                      </InlineStack>
                                    </div>

                                    {cnf?.ibhv !== undefined &&
                                      <Checkbox
                                        label={<Tooltip dismissOnMouseOut padding="400" content='Display brand name once visitor hover on verify icon' width='wide' hasUnderline><Text>Show brandname on hover</Text></Tooltip>}
                                        checked={notiById?.ibhv}
                                        onChange={(e) => handleSettingChange('ibhv', e)} />
                                    }

                                  </BlockStack>
                                </Box>
                              }
                            </div>
                          }

                        </div>
                        :
                        state.verticalActive === getValue('Collector') ?
                          <BlockStack gap={500}>

                            {/* collector */}
                            {(queryNtt === 'signup_recent' || queryNtt === 'signup_bulk' || queryNtt === 'single_visits') &&
                              <div>
                                <Text fontWeight='medium'>Collector</Text>

                                <Box paddingInlineStart={400} paddingBlock={300}>
                                  <BlockStack gap={300}>

                                    <div>
                                      <Tooltip dismissOnMouseOut padding="400" content='Write brief description' width='wide' hasUnderline><Text>Description</Text></Tooltip>
                                      <ReactQuill value={cnf?.clmsg} className='preview-mail hideEditor clmsgtxt'
                                        modules={modulesQuill} formats={formatsQuill}
                                        onFocus={() => editorFocus('clmsgtxt')}
                                        onBlur={() => editorBlur('clmsgtxt')}
                                        onChange={(newValue, delta, source) => {
                                          if (source === 'user') { handleMainTextChange('clmsg', newValue) }
                                        }} />
                                    </div>

                                    <div>
                                      <Checkbox
                                        label={<Tooltip dismissOnMouseOut padding="400" content='Write placeholder value for name Field' width='wide' hasUnderline><Text>Name input</Text></Tooltip>}
                                        checked={cnf?.iunm}
                                        onChange={(e) => handleMainTextChange('iunm', e)}
                                      />
                                      <TextField
                                        value={cnf?.punm}
                                        disabled={!cnf?.iunm}
                                        onChange={(e) => handleMainTextChange('punm', e)} />
                                    </div>

                                    <div>
                                      <Checkbox
                                        label={<Tooltip dismissOnMouseOut padding="400" content='Write placeholder value for email Field' width='wide' hasUnderline><Text>Email input</Text></Tooltip>}
                                        checked={cnf?.ieml}
                                        onChange={(e) => handleMainTextChange('ieml', e)}
                                      />
                                      <TextField
                                        value={cnf?.peml}
                                        disabled={!cnf?.ieml}
                                        onChange={(e) => handleMainTextChange('peml', e)} />
                                    </div>

                                    <div>
                                      <Checkbox
                                        label={<Tooltip dismissOnMouseOut padding="400" content='Write placeholder value for number field' width='wide' hasUnderline><Text>Number input</Text></Tooltip>}
                                        checked={cnf?.iphn}
                                        onChange={(e) => handleMainTextChange('iphn', e)}
                                      />
                                      <TextField
                                        value={cnf?.pphn}
                                        disabled={!cnf?.iphn}
                                        onChange={(e) => handleMainTextChange('pphn', e)} />
                                    </div>

                                    <div>
                                      <Checkbox
                                        label={<Tooltip dismissOnMouseOut padding="400" content='Write your text & set your privacy policy link' width='wide' hasUnderline><Text>Privacy policy</Text></Tooltip>}
                                        checked={cnf?.iclplcy}
                                        onChange={(e) => handleMainTextChange('iclplcy', e)}
                                      />
                                      <ReactQuill value={cnf?.clplcy} className='preview-mail hideEditor plcytxt'
                                        modules={modulesQuill} formats={formatsQuill}
                                        onFocus={() => editorFocus('plcytxt')}
                                        onBlur={() => editorBlur('plcytxt')}
                                        onChange={(newValue, delta, source) => {
                                          if (source === 'user') { handleMainTextChange('clplcy', newValue) }
                                        }} />
                                    </div>

                                    <div>
                                      <Tooltip dismissOnMouseOut padding="400" content='Change button text & color combination' width='wide' hasUnderline><Text>Button</Text></Tooltip>
                                      <ReactQuill value={cnf?.clsb} className='preview-mail hideEditor clsbtxt'
                                        modules={modulesQuill} formats={formatsQuill}
                                        onFocus={() => editorFocus('clsbtxt')}
                                        onBlur={() => editorBlur('clsbtxt')}
                                        onChange={(newValue, delta, source) => {
                                          if (source === 'user') { handleMainTextChange('clsb', newValue) }
                                        }} />
                                    </div>

                                    <div>
                                      <Tooltip dismissOnMouseOut padding="400" content='Thanks message' width='wide' hasUnderline><Text>Thanks message</Text></Tooltip>
                                      <ReactQuill value={cnf?.clthk} className='preview-mail hideEditor clthktxt'
                                        modules={modulesQuill} formats={formatsQuill}
                                        onFocus={() => editorFocus('clthktxt')}
                                        onBlur={() => editorBlur('clthktxt')}
                                        onChange={(newValue, delta, source) => {
                                          if (source === 'user') { handleMainTextChange('clthk', newValue) }
                                        }} />
                                    </div>

                                    <InlineStack gap={200}>
                                      <ColorPick
                                        labelName='Close icon text color'
                                        colorKey="clclf"
                                        colorValue={cnf?.clclf}
                                        functionCall={handleMainTextChange} />
                                      <ColorPick
                                        labelName='Close icon bg color'
                                        colorKey="clcbg"
                                        colorValue={cnf?.clcbg}
                                        functionCall={handleMainTextChange} />
                                    </InlineStack>

                                  </BlockStack>
                                </Box>
                              </div>
                            }

                            {/* send submitted data */}
                            {(cnf?.isnd !== undefined || cnf?.isdeml !== undefined) &&
                              notiById?.idisnp !== true && isAbtest === false &&
                              <Box paddingBlockEnd={600}>
                                <Tooltip dismissOnMouseOut padding="400" content='Send submitted data to third platform.' hasUnderline width='wide'>
                                  {(queryNtt === 'signup_recent' || queryNtt === 'single_visits'
                                    || queryNtt === 'signup_bulk') ?
                                    <Text fontWeight='medium'>Send submitted data (Collector only)</Text>
                                    : <Text fontWeight='medium'>Send submitted data</Text>
                                  }
                                </Tooltip>

                                <Box paddingInlineStart={400} paddingBlock={200}>
                                  <BlockStack gap={300}>

                                    <div>
                                      <InlineStack align='space-between' blockAlign='center'>
                                        <Checkbox
                                          label={<Tooltip dismissOnMouseOut padding="400" content='Enter URL where you want to receive submitted data' hasUnderline width='wide'><Text>Through webhook</Text></Tooltip>}
                                          checked={cnf?.isnd}
                                          onChange={(e) => handleMainTextChange('isnd', e)} />
                                        <Button onClick={() => { cnf?.isnd && sendWebhookData(cnf?.wbh) }} variant='plain'>Test data</Button>
                                      </InlineStack>
                                      <TextField
                                        placeholder='Webhook URL(where to send data)'
                                        value={cnf?.wbh}
                                        autoComplete='off'
                                        disabled={!cnf?.isnd}
                                        onChange={(e) => handleMainTextChange('wbh', e)}
                                        error={errMessage?.webhookUrl ? errMessage.webhookUrl : false} />
                                      {cnf?.isnd === true && designValidator.message('webhookUrl', cnf?.wbh, 'required')}
                                    </div>

                                    <div>
                                      <Checkbox
                                        label={<Tooltip dismissOnMouseOut padding="400" content='Enter your email id where you want to receive submitted data' hasUnderline width='wide'><Text>Through email</Text></Tooltip>}
                                        checked={cnf?.isdeml}
                                        onChange={(e) => handleMainTextChange('isdeml', e)} />
                                      <TextField
                                        placeholder='Email address(where to receive data)'
                                        value={cnf?.demla}
                                        autoComplete='off'
                                        disabled={!cnf?.isdeml}
                                        onChange={(e) => handleMainTextChange('demla', e)}
                                        error={errMessage?.emailAddress ? errMessage.emailAddress : false} />
                                      {cnf?.isdeml === true && designValidator.message('emailAddress', cnf?.demla, 'required')}
                                    </div>

                                    {cnf?.isdeml === true &&
                                      <BlockStack gap={300}>
                                        <TextField
                                          label={<Text tone='subdued'>Email subject</Text>}
                                          placeholder='Enter email subject' autoComplete='off'
                                          value={cnf?.demls}
                                          onChange={(e) => handleMainTextChange('demls', e)}
                                          error={errMessage?.emailSubject ? errMessage.emailSubject : false} />
                                        {designValidator.message('emailSubject', cnf?.demls, 'required')}

                                        <div>
                                          <Box paddingBlockEnd={100}>
                                            <Text tone='subdued'>Email template</Text>
                                          </Box>
                                          <ReactQuill value={cnf?.demlt} className='preview-mail showEditor'
                                            modules={modulesQuillEmlTemp} formats={formatsQuill}
                                            onFocus={() => editorFocus('demlttxt')}
                                            onBlur={() => editorBlur('demlttxt')}
                                            onChange={(newValue, delta, source) => {
                                              if (source === 'user') { handleMainTextChange('demlt', newValue) }
                                            }} />
                                        </div>
                                      </BlockStack>
                                    }

                                  </BlockStack>
                                </Box>
                              </Box>
                            }

                          </BlockStack>
                          :
                          state.verticalActive === getValue('Coupon code') &&
                          <BlockStack gap={200}>
                            <Text fontWeight='medium'>Coupon code (The sum of total possibility should be 100.)</Text>
                            {cnf?.arrlcyltry?.length > 0 && cnf?.arrlcyltry.map((val, key) => {
                              return (
                                <Box paddingBlockStart={300} paddingBlockEnd={200} key={key}>

                                  <InlineStack align='space-between'>
                                    <Text fontWeight='medium'>Number: {key + 1}</Text>
                                    {val.idlt !== false && <Button variant='monochromePlain' tone='critical' onClick={() => deletefieldinArw(key, queryNtt)} ><Icon tone='critical' source={DeleteIcon}></Icon></Button>}
                                  </InlineStack>

                                  <Box paddingInlineStart={300} paddingBlockStart={200}>
                                    <TextField
                                      value={val.cpnnm}
                                      label={<Text>Name</Text>}
                                      disabled={val.idlt === false ? true : false}
                                      onChange={(e) => addCouponcode('cpnnm', key, e)} />
                                    <Box paddingBlockStart={150}>
                                      <InlineGrid columns={2} gap={300}>
                                        <TextField
                                          value={val.cpncd}
                                          label={<Text>Code</Text>}
                                          disabled={val.idlt === false ? true : false}
                                          onChange={(e) => addCouponcode('cpncd', key, e)} />
                                        <TextField
                                          type='number'
                                          value={val.cpnpsblt}
                                          disabled={val.idlt === false ? true : false}
                                          label={<Text>Possibility</Text>}
                                          onChange={(e) => addCouponcode('cpnpsblt', key, e ? Number(e) : '')} />
                                      </InlineGrid>
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                            <InlineStack align='center'>
                              <Button variant='primary' tone='success' onClick={() => AddfieldinArw()}>Add new</Button>
                            </InlineStack>
                          </BlockStack>
      }
    </Box >
  );
}

export default TextCard;