import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const Onboard = createSlice({
    name: 'Onboard',
    initialState,
    reducers: {
        updObIndtry: (state, action) => { },
        updObIndtrySuccess: (state, action) => {
            return { ...state, updObIndtry: action.payload };
        },
        getIntList: (state, action) => { },
        getIntListSuccess: (state, action) => {
            return { ...state, getappList: action.payload };
        },
        skipOnboarding: (state, action) => { }
    }
});

export const {
    updObIndtry,
    updObIndtrySuccess,
    getIntList,
    getIntListSuccess,
    skipOnboarding
} = Onboard.actions;

export default Onboard.reducer;
