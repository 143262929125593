import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action) => { },
    cPwd: (state, action) => { },
    updEmailSend: (state, action) => { },
    updProfileImg: (state, action) => { },
    deleteAccount: (state, action) => { },
    getQuotaHistory: (state, action) => { },
    getQuotaHistorySuccess: (state, action) => {
      return { ...state, quotaHistory: action.payload };
    }
  }
});

export const {
  updateProfile,
  cPwd,
  updEmailSend,
  updProfileImg,
  deleteAccount,
  getQuotaHistory,
  getQuotaHistorySuccess
} = profile.actions;

export default profile.reducer;
