import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/whitelabelForgotPwd';
import { toastify } from '../ducks/toast';

function* getBrand(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getBrand?wurl=' + action.payload.wurl);

    if (res.status === 'success') {
      yield put(actions.getBrandSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* forgetWhitePWD(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/forgetWhitePWD' , action.payload);

    if (res.status === 'success') {
      // browserHistory.push('/login');
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetBrand() {
  yield takeLatest(actions.getBrand.type, getBrand);
}

export function* watchForgetWhitePWD() {
  yield takeLatest(actions.forgetWhitePWD.type, forgetWhitePWD);
}

export default function* rootSaga() {
  yield fork(watchGetBrand);
  yield fork(watchForgetWhitePWD);
}
