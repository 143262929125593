import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotifEditCard } from '../../components';
import * as notifEditDucks from '../../ducks/notifEdit';
import * as notifDucks from '../../ducks/notif';
import * as authDucks from '../../ducks/auth';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';
import SimpleReactValidator from "simple-react-validator";
import { useNavigate, useLocation } from 'react-router-dom';
import './NotifDesign.scss'; //for notifcation design css
import './NotifEdit.css';
const moment = require('moment');

let arrCountry = [];
const country_state_city = require('country-state-city');
const countryList = country_state_city?.Country?.getAllCountries();  //get all countries

countryList?.length > 0 && countryList.map(country => {
  arrCountry.push({ 'name': country.name });
});

const regExp_youtube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

const NotifEdit = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const validator = new SimpleReactValidator({ locale: 'en' });
  const designValidator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    btnNotiSave: 'Next',
    tabActiveTyp: 1,
    verticalTabsArr: [],
    notiById: {},
    notiByIdCopy: {},
    cloneNotiById: {},
    sidebarActiveIndex: 0,
    verticalActive: 'texteditor',
    isEditNotif: false,
    selectTemp: '',
    defaultNotif: {},
    isMobileScreen: true,
    loadingState: true,
    paidType: '',
    cnf: {},
    iconTab: 0,
    iconOpen: false,
    notifId: '',
    queryNti: '',
    queryNtt: '',
    queryNtyp: '',
    errMessage: {},
    icnTabActiveTyp: 'all',
    arrIconscout: [],
    iconscout: '',
    iconUrl: '',
    isLoadIconscout: false,
    notiBg: '#fff',
    tempOpen: false,
    publishConfirmOpen: false,
    isDefaultTabActive: false,
    abVrnt: 'current',
    abId: null,
    arrVrnt: [],
    inteCountOpen: false,
    unSuportValue: '',
    proKeyValue: 0,
    shopProducts: [],

    dtu: '2',
    dtURL: '',
    ddnList: [],
    ddList: [],
    isAdd: false,
    arinclcnt: [],
    arexclcnt: [],
    utmCnd: '0',
    utmTxt: '',
    rtnv: '0',
    refCnd: '2',
    cokCnd: '0',
    refArray: [],
    cookieArray: [],
    isFirstTabActive: true,
    isSecTabActive: false,
    isThirdTabActive: false,
    isFourTabActive: false,
    shopProductsList: [],

    dtscu: '2',
    dtscURL: '',
    dst: '0',
    durlType: 'includeURL',
    pixelData: [],
    getCount: false,
    getCloseCount: false,
    inteTabActive: 'installed',
    integrateOpen: false,
    iadvfcnd: false,
    fld: '',
    fcnd: '2',
    ov: '',
    scnd: '2',
    pagiCode: 1, //for page number(used in integration data modal)
    signupData: [],
    isCall: true,
    lstActiveIntg: [],
    dataCount: []
  });

  let notifId = queryParams.get("id");
  let queryNti = queryParams.get("nti");
  let queryNtt = queryParams.get("ntt");
  let queryNtyp = queryParams.get("ntyp");
  let abId = queryParams.get("abTyp") || null;
  let tabIndex = queryParams.get("tab") || null;

  useEffect(() => {
    let obj = {};
    if (notifId) obj['notifId'] = notifId;
    if (queryNti) obj['queryNti'] = queryNti;
    if (queryNtt) obj['queryNtt'] = queryNtt;
    if (queryNtyp) obj['queryNtyp'] = queryNtyp;
    if (abId) obj['abId'] = abId || null;
    if (tabIndex) obj['tabIndex'] = tabIndex || null;

    changeNameValue(obj);
  }, [notifId, queryNti, queryNtt, queryNtyp, abId, tabIndex]);

  const loadingState = useSelector(state => state.loading.isLoading) || false;
  const ProfileData = useSelector(state => state.auth.profile);
  const notifData = useSelector(state => state.notif.notif);
  const notifHeight = useSelector(state => state.notif.notifHeight);
  const notiById = useSelector(state => state.notifEdit.notiById);
  const ddnListArr = useSelector(state => state.notifEdit.ddnList);
  const pixelDataArr = useSelector(state => state.notifEdit.pixelData);
  const lstActiveIntgArr = useSelector(state => state.notifEdit.lstActiveIntg);
  const dataCountArr = useSelector(state => state.notifEdit.dataCount);
  const iconListArr = useSelector(state => state.notifEdit.iconListArr);
  const iconUrlState = useSelector(state => state.notifEdit.iconUrl);
  const productURL = useSelector(state => state.notifEdit.productURL);
  const userNotifList = useSelector(state => state.notifEdit.userNotifList);
  const shopProducts = useSelector(state => state.notifEdit.shopProducts);
  const intData = useSelector(state => state.notifEdit.intData);

  // clear all the state
  useEffect(() => {
    dispatch(notifEditDucks.clearState());
    changeNameValue({ iconOpen: false });
  }, []);

  // add default abTest variant
  useEffect(() => {
    const notiById = { ...state.notiById };

    if (state.crtVarntOpen === true && state.abId !== null && Object.keys(notiById).length > 0) {
      const options = notiById?.vrnt?.length > 0 ?
        notiById?.vrnt.map(variant => ({
          label: variant.ttl,
          value: variant.ttl
        })) : [];

      // Check if the main variant is not deleted
      const arrVrnt = notiById?.idltfrmvrnt ? options
        : [{ label: 'Original', value: 'current' }, ...options];

      changeNameValue({ arrVrnt });
      openCloseModal('addVariantOpen', state.addVariantOpen, 'open');
    }
  }, [state, state.crtVarntOpen]);

  useEffect(() => {
    if (state.notifId) {
      searchIconscout(state.queryNtt);
      dispatch(notifEditDucks.getNotfById(state.notifId));
      dispatch(notifDucks.getNotif());
      dispatch(notifDucks.getNotiHght());
    }
  }, [state.notifId]);

  // integration count click get data for modal
  useEffect(() => {
    if (intData !== undefined && state.isCall === false) {
      if (intData.data) {
        let stateObj = {}, signupData = [];
        stateObj['isCall'] = true;
        stateObj['isDataLoaded'] = false;

        if (state.signupData.length === 0) {
          intData.data.filter(z => { signupData.push(z); });
        }
        else {
          for (let i = 0; i < intData.data.length; i++) {
            const j = intData.data[i];
            let _index = state.signupData.findIndex(x => x._id.toString() === j._id.toString());
            if (_index === -1) signupData.push(j)
          }
        }

        stateObj['signUpCount'] = intData.count;
        stateObj['signupData'] = signupData;
        changeNameValue(stateObj);
      }
    }
  }, [intData]);

  useEffect(() => {
    if (shopProducts !== undefined && shopProducts?.length > 0) {
      let shopProductsList = [];
      shopProducts.forEach(product => {
        const listItem = {
          value: product.pid,
          label: product.pnm ? product.pnm : ''
        };
        shopProductsList.push(listItem);
      });
      changeNameValue({ shopProductsList, shopProducts });
    }
  }, [shopProducts]);

  useEffect(() => { //on import display rules click call api(display tab)
    if (state.isImprtRules === true)
      dispatch(notifEditDucks.getUserNotif());
  }, [state.isImprtRules]);

  useEffect(() => {
    if (userNotifList !== undefined && userNotifList !== state.notifFullList)
      changeNameValue({ notifFullList: userNotifList, notifFullCopy: userNotifList });
  }, [userNotifList]);

  //for All_in_one notif get product url 
  useEffect(() => {
    if (productURL && productURL !== state.productURL && state.proKeyValue) {
      let _cnf = JSON.stringify({ ...state.cnf });
      let cnf = JSON.parse(_cnf);
      const currentState = cnf?.ainct;
      currentState[state.proKeyValue]['url'] = (productURL && productURL.pu) ? productURL.pu : '';
      cnf.ainct = currentState;

      changeNameValue({ cnf, productURL });
    }
  }, [productURL, state]);

  useEffect(() => {
    //for custom icon upload 
    if (iconUrlState && iconUrlState !== state.iconUrl) {
      handleIconChange(iconUrlState);
      changeNameValue({ iconUrl: iconUrlState });
    }
  }, [iconUrlState]);

  useEffect(() => {
    if (lstActiveIntgArr !== undefined) {
      let sortlstActiveIntg = [...lstActiveIntgArr].sort((a, b) => {
        if (a.dn < b.dn) { return -1; } if (a.dn > b.dn) { return 1; } return 0;
      });

      const stateObj = { fbFwl: [], twtFwl: [], linkFwl: [], instaFwl: [], ytbFwl: [], twhFwl: [], FbGrpFwl: [], tiktokFwl: [], };

      if (sortlstActiveIntg?.length > 0) {
        // get/set social media account
        sortlstActiveIntg
          .filter(val => val.typ.includes('followers'))
          .forEach(val => {
            val.items.forEach(fwl => {
              const { inm, d, _id } = fwl;

              switch (inm) {
                case 'facebook':
                  d.fb_page?.forEach(fb => {
                    if (fb.ia) stateObj.fbFwl.push({ name: fb.name, uid: _id, pgid: fb.id });
                  });
                  break;
                case 'twitter_follower':
                  stateObj.twtFwl.push({ name: d.urn, uid: _id, pgid: _id });
                  break;
                case 'linkedin':
                  d.page?.forEach(lnk => {
                    if (lnk.ia) stateObj.linkFwl.push({ name: lnk.name, uid: _id, pgid: lnk.id });
                  });
                  break;
                case 'instagram_follower':
                  d.page?.forEach(insta => {
                    if (insta.ia) stateObj.instaFwl.push({ name: insta.name, uid: _id, pgid: insta.ipid });
                  });
                  break;
                case 'youtube_channel':
                  stateObj.ytbFwl.push({ name: d.pgn, uid: _id, pgid: _id });
                  break;
                case 'twitchtv':
                  stateObj.twhFwl.push({ name: d.display_name, uid: _id, pgid: d.chid });
                  break;
                case 'facebookgroupfollowers':
                  d.groups?.forEach(fb => {
                    if (fb.ia) stateObj.FbGrpFwl.push({ name: fb.name, uid: _id, pgid: fb.id });
                  });
                  break;
                case 'tiktok':
                  stateObj.tiktokFwl.push({ name: d.user.display_name, uid: _id, pgid: d.user.union_id });
                  break;
                default:
                  break;
              }
            });
          });
      }

      stateObj['lstActiveIntg'] = sortlstActiveIntg;
      changeNameValue(stateObj);
    }
  }, [lstActiveIntgArr]);

  useEffect(() => {
    if (loadingState !== undefined)
      changeNameValue({ loadingState });

    if (pixelDataArr !== undefined && pixelDataArr.length > 0)
      changeNameValue({ pixelData: pixelDataArr });

    if (iconListArr !== undefined)
      changeNameValue({ iconList: iconListArr });

    if (ddnListArr !== undefined)
      changeNameValue({ ddnList: ddnListArr });

    if (notifHeight && notifHeight !== undefined && notifHeight !== state.notifHeight)
      changeNameValue({ notifHeight });

  }, [loadingState, dataCountArr, iconListArr, pixelDataArr, ddnListArr, notifHeight]);

  useEffect(() => {
    if (dataCountArr !== undefined && dataCountArr !== state.dataCount) {
      changeNameValue({ dataCount: dataCountArr });
    }
  }, [dataCountArr, state.dataCount]);

  useEffect(() => { // get domain list on refresh
    if (state?.ddnList?.length <= 0 && state.notifId) {
      dispatch(notifEditDucks.getDomain(state.notifId));
    }
  }, [state.notifId, state.ddnList]);

  useEffect(() => {
    if (ProfileData !== null && ProfileData !== undefined && ProfileData !== state.ProfileData) {
      let paidType = ProfileData?.csub;
      let fn = '';

      let ti = ProfileData?.ti;
      if (localStorage.getItem('istm') === 'true') {
        if (ProfileData?.userTeam?.fn) fn = ProfileData.userTeam.fn;
      }
      else fn = ProfileData?.fn;

      if (ProfileData?.utyp === 'appsumo' &&
        ProfileData?.apsmver === 2 &&
        ProfileData?.apstk.length === 1
      ) { paidType = 'free'; }

      changeNameValue({ ProfileData, paidType, fn, ti });
    }
    else dispatch(authDucks.getProfile());
  }, [ProfileData]);

  useEffect(() => {
    if (notiById !== undefined && notiById !== state.notiById) {
      let notiByIdArr = { ...notiById };
      let obj = {};

      //if main notif. is delete then show the first notif. in variant list    
      if (notiByIdArr?.idltfrmvrnt === true && state.abId === 'current') {
        const objVrnt = notiByIdArr.vrnt[0];
        obj['abSaveId'] = objVrnt._id;
        const clonedNotiByIdArr = { ...notiByIdArr };
        Object.assign(clonedNotiByIdArr, objVrnt);
        notiByIdArr = clonedNotiByIdArr;
      }

      //display variant as a original notif.
      if (state.abId === null && notiByIdArr.iornl === false) {
        notiByIdArr?.vrnt?.length > 0 && notiByIdArr.vrnt.filter(x => {
          if (x.iornl === true) {
            obj['abSaveId'] = x._id;
            const clonedNotiByIdArr = { ...notiByIdArr };
            Object.assign(clonedNotiByIdArr, x);
            obj['cloneNotiById'] = JSON.stringify(clonedNotiByIdArr);
            notiByIdArr = clonedNotiByIdArr;
          }
        });
      }

      obj = {
        ...obj,
        cloneNotiById: JSON.stringify(notiByIdArr),
        notiById: notiByIdArr,
        notiByIdCopy: notiByIdArr,
        objAbtestNotif: notiByIdArr,
        selectTemp: notiByIdArr?.tmid,
        cnf: notiByIdArr?.cnf,
        dt: (notiByIdArr?.dt >= 0 && notiByIdArr?.dt <= 2) ? notiByIdArr?.dt.toString() : '3', //default '3'
        dst: (notiByIdArr?.dst >= 0 && notiByIdArr?.dst <= 2) ? notiByIdArr?.dst.toString() : '3', //default '3'
        dtuArray: notiByIdArr?.dtu || [],
        dtscuArray: notiByIdArr?.dtscu || [],
        ddList: notiByIdArr?.ddn?.length > 0 ? notiByIdArr?.ddn : [], // get user domain list
        arinclcnt: notiByIdArr?.arinclcnt,
        arexclcnt: notiByIdArr?.arexclcnt,
        UtmArray: notiByIdArr?.utmd || [],
        rtnv: notiByIdArr?.rtnv?.toString(),
        cookieArray: notiByIdArr?.bcks || [],
        refArray: notiByIdArr?.rfvf || [],
        filterArray: notiByIdArr?.advfcnd || [],
        iconTab: (notiByIdArr?.cnf?.it?.length > 0 && notiByIdArr?.cnf?.it[0]) || 0,
        icntntf: notiByIdArr?.icntntf || false,
        irtnv: notiByIdArr?.irtnv || false,
        iutmd: notiByIdArr?.iutmd || false,
        ibcks: notiByIdArr?.ibcks || false,
        irfvf: notiByIdArr?.irfvf || false,
        shpPrdArray: notiByIdArr?.prdctstk || [],
        ...(notiByIdArr?.dabs !== undefined && { dabs: notiByIdArr?.dabs }),
        ...(notiByIdArr?.dsabs !== undefined && { dsabs: notiByIdArr?.dsabs }),
        ...(state.iadvfcnd !== true && { iadvfcnd: notiByIdArr?.iadvfcnd || false }),
      };

      //for loop for check datasource id
      let arrIntg = [];
      if (notiByIdArr?.dsi?.length > 0) {
        for (let i = 0; i < notiByIdArr.dsi.length; i++) {
          arrIntg.push(notiByIdArr.dsi[i].uid);
        }
      }

      //for capture url
      let arrCaptureUrl = [];
      if (notiByIdArr?.dscu && notiByIdArr?.dseu) {
        const captureIncUrl = notiByIdArr?.dscu;
        const captureExcUrl = notiByIdArr?.dseu;
        captureIncUrl.map(u => { arrCaptureUrl.push({ u, t: 'inc' }); });
        captureExcUrl.map(u => { arrCaptureUrl.push({ u, t: 'exc' }); });
      }
      if (arrCaptureUrl?.length > 0)
        obj['arrCaptureUrl'] = arrCaptureUrl;

      obj['arrIntg'] = arrIntg;
      changeNameValue(obj);
    }
  }, [notiById]);

  useEffect(() => {
    if (state.notiById !== undefined && state.isCreateVariant === true) {
      let obj = {};

      if (state.isCreateVariant === true && state.notiById?.vrnt?.length > 0) {//for display select created variant
        obj['isCreateVariant'] = false;
        const variant = state.notiById?.vrnt[state.notiById?.vrnt.length - 1];
        obj['abId'] = variant._id;
        handleAbTestTab(variant);

        changeNameValue({ tabActiveTyp: state.notiById?.vrnt.length - 1 });
      }
      else if (state?.abId !== 'current') {  //check working or not
        //for display selected a/b testing tab
        let data = '';
        if (state.isDeleteVariant === true) {
          obj['isDeleteVariant'] = false;
          data = state.notiById.vrnt.length > 0 ? state.notiById.vrnt[0] : 'current';
        }
        else {
          data = state.notiById?.vrnt?.length > 0 && state.notiById.vrnt.filter(x => x._id === state.abId)[0];
        }
        handleAbTestTab(data);
        changeNameValue({ tabActiveTyp: state.notiById?.vrnt.length - 1 });
      }

      changeNameValue(obj);
    }
  }, [state]);

  useEffect(() => {
    if (notifData && notifData !== undefined && notifData !== state.notifData && state.selectTemp) {
      let defaultNotif = notifData.allTemplate.filter(x => x._id === state.selectTemp)[0];
      changeNameValue({ notifData, defaultNotif });
    }
  }, [notifData]);

  // open first step on main tab click
  useEffect(() => {
    if (state.mainActiveNm === 'display') {
      handleTabsCollapse('isFirstTabActive', !state.isFirstTabActive);
      changeNameValue({ 'isFirstTabActive': true });
    }
    else if (state.mainActiveNm === 'dataSource') {
      if (['purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet', 'review_recent', 'review_bulk', 'review_feed', 'channel_subscriber', 'video_subscriber', 'social_media_post', 'social_media_count'].indexOf(state.queryNtt) === -1) {
        handleTabsCollapse('isFirstTabActive', !state.isFirstTabActive);
        changeNameValue({ 'isFirstTabActive': true });
      }
      else if (['live_visitor', 'recent_view_bulk'].indexOf(state.queryNtt) === -1) {
        handleTabsCollapse('isSecTabActive', !state.isSecTabActive);
        changeNameValue({ 'isSecTabActive': true });
      }
      else if (['signup_recent', 'purchase_recent', 'purchase_recent_ecomm_snippet', 'review_recent', 'social_media_post'].indexOf(state.queryNtt) !== -1) {
        handleTabsCollapse('isThirdTabActive', !state.isThirdTabActive);
        changeNameValue({ 'isThirdTabActive': true });
      }
      else {
        handleTabsCollapse('isFirstTabActive', !state.isFirstTabActive);
        changeNameValue({ 'isFirstTabActive': true });
      }
    }
  }, [state.mainActiveNm]);


  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, [state]);

  // for colorPicker(preview bg color)
  const changeStates = useCallback((name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }))
  }, [state]);

  const changeTemp = () => {
    if (state.isSelTemp !== '') {
      openCloseModal('tempOpen', state.tempOpen, 'close');
      // open confirmation modal
      setTimeout(() => openCloseModal('changeTempOpen', state.changeTempOpen, 'open'), 200);
    }
    else dispatch(toastify({ type: 'error', msg: 'please select template !' }));
  }

  const updateTempById = () => {
    let stateObj = {};
    let { cnf, notifData, notiById, ProfileData, noti_tmid } = state;

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Premium Template Upgrade Plan', noti_tmid, ProfileData?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Premium Template Upgrade Plan', { 'event_action': noti_tmid, 'event_label': ProfileData?.e || '' });
      } catch (error) { console.log(error); }
    }

    let _notiById = JSON.stringify(notiById);
    let _NewById = JSON.parse(_notiById);
    let temp = JSON.stringify(notifData?.allTemplate.filter(x => x._id === noti_tmid)[0]);
    let parse_temp = JSON.parse(temp);
    let Newcnf = parse_temp.cnf;
    let oldCnf = cnf;

    _NewById.lng = 'en'; //for default language //template change set English language  
    _NewById.tmagtxt = 'recently';  //for Time ago text
    _NewById.dctp = 'eighty';//for default cnf //on template change remove size.
    if (parse_temp.dctp !== undefined && parse_temp.dctp !== null) _NewById.dctp = parse_temp.dctp;
    Newcnf.fdln = oldCnf.fdln;  //for HTML snippet

    //for receive submitted data
    if (oldCnf.isnd !== undefined) Newcnf.isnd = oldCnf.isnd;
    if (oldCnf.wbh !== undefined) Newcnf.wbh = oldCnf.wbh;
    if (oldCnf.isdeml !== undefined) Newcnf.isdeml = oldCnf.isdeml;
    if (oldCnf.demla !== undefined) Newcnf.demla = oldCnf.demla;
    if (oldCnf.demlt !== undefined) Newcnf.demlt = oldCnf.demlt;

    //for sub user || teammate user
    //white_label
    if (localStorage.getItem('isbusr') === 'true' || localStorage.getItem('istm') === 'true') {
      if (Newcnf.bn !== undefined) Newcnf.bn.txt = ProfileData?.brand?.bn; //brand name
      if (Newcnf.bu !== undefined) Newcnf.bu = ProfileData?.brand?.bu;     //brand url
      if (Newcnf.cu !== undefined) Newcnf.cu = ProfileData?.brand?.bu;     //CTA url
      if (Newcnf.nu !== undefined) Newcnf.nu = ProfileData?.brand?.bu;     //notif. url
      if (Newcnf.demls) Newcnf.demls = Newcnf.demls.replace('WiserNotify', ProfileData?.brand?.bn); //for email subject
    }

    //for apply dark theme changes 
    if (_NewById?.idrkthm) { //for handle on template change light theme changes not apply
      let _cnf = applyThemeColor(_NewById.idrkthm);
      Newcnf = { ...Newcnf, ..._cnf };
    }

    stateObj['cnf'] = Newcnf;
    stateObj['selectTemp'] = noti_tmid;
    stateObj['isEditNotif'] = true;
    stateObj['notiById'] = _NewById;
    stateObj['defaultNotif'] = parse_temp;

    changeNameValue(stateObj);
    openCloseModal('changeTempOpen', state.changeTempOpen, 'close');
  }

  // on browse icon
  const handleDropZone = async (imgdrop) => {
    if (imgdrop && imgdrop.length > 0) {
      base64FileURL(imgdrop[0], (obj) => {
        let stateObj = {
          uploadedUrl: imgdrop,
          iconUrl: obj,
          iconLabel: imgdrop[0].name
        }
        changeNameValue(stateObj);
      })
    }
  }

  //base64 file convert
  const base64FileURL = (element, callback) => {
    let file = element;
    let reader = new window.FileReader();
    reader.onloadend = function (e) {
      callback(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  //upload custom icon url
  const uploadIconfile = () => {
    if (state.iconUrl) {
      let obj = {
        _id: state.notifId,
        icon: state.iconUrl,
        icoType: state.isIconType,
        fn: state.fn
      };
      dispatch(notifEditDucks.updNotifIcon(obj));
      changeNameValue({ isLoadIcon: true, iconUrl: '', iconLabel: '' });
    }
  }

  //delete icon
  const deleteMediaIcon = (id, url) => {
    const obj = {
      id: id,
      url: url
    };
    dispatch(notifEditDucks.deleteMediaIcon(obj));
    changeNameValue({ isLoadIcon: true });
  }

  const queryMap = {
    'live_visitor': 'see',
    'signup_recent': 'fire',
    'single_visits': 'fire',
    'signup_bulk': 'people',
    'purchase_recent': 'purchase',
    'purchase_bulk': 'cart',
    'review_recent': 'review',
    'review_bulk': 'review',
    'video_subscriber': 'youtube',
    'channel_subscriber': 'youtube',
    'recent_view_bulk': 'users',
    'collector': 'person',
    'custom': 'announcement',
    'whatsapp_chat': 'social media',
    'all_in_one': 'social media',
    'social_media_followers': 'social media'
  };

  //handle search icon scout
  const searchIconscout = async (typ) => { //remove ntt from parameter, and use state
    let _arrData = [];
    let iconscout = (state.iconscout && typ !== 'clear') ? state.iconscout : queryMap[state.queryNtt] || '';

    changeNameValue({ isLoadIconscout: true });

    try {
      const response = await fetch(`https://api.iconscout.com/v2/search?query=${iconscout}&product_type=item&asset=icon&price=free&per_page=200&page=1&sort=popular&quality=false`, {
        method: 'GET',
        headers: { 'Client-Id': 279435890707056 }
      });

      const res = await response.json();
      const data = res?.response?.items?.data || [];
      _arrData = data.map(item => item.urls.png_128);
    }
    catch (error) {
      console.error('Error fetching icons:', error);
    }

    changeNameValue({ arrIconscout: _arrData, isLoadIconscout: false });
  }

  //handle icon change URL
  const handleIconChange = (value) => {
    let currentState = { ...state.cnf };

    if (state.isIconType === 'iu') {
      currentState.iu = value;
    } else if (state.isIconType === 'fliu') {
      currentState.fliu = value;
    } else if (state.isIconType === 'sliu') {
      currentState.sliu = value;
    } else if (state.isIconType === 'icn' && state.iconstate !== undefined) {
      handleArrayChange('icn', value, state.iconstate);
      openCloseModal('iconOpen', state.iconOpen, 'close');
      return;
    } else if (state.isIconType === 'imgdsc' && state.iconstate !== undefined) {
      handleArrayChange('imgdsc', value, state.iconstate);
      openCloseModal('iconOpen', state.iconOpen, 'close');
      return;
    } else if (state.isIconType === 'cticn') {
      currentState.cticn = value;
    } else if (state.isIconType === 'lcyctryicn') {
      currentState.lcyctryicn = value;
    }

    openCloseModal('iconOpen', true, 'close'); // close the modal
    changeNameValue({ cnf: currentState, isEditNotif: true });
  }

  //handle A/b testing tab changes
  const handleAbTestTab = (data) => {
    if (data && data !== 'current' && state.notiById) {
      let stateObj = {};
      let _notiById = JSON.stringify({ ...state.notiById });
      let notiById = JSON.parse(_notiById);

      stateObj['cnf'] = data.cnf;
      stateObj['selectTemp'] = data.tmid;
      stateObj['abId'] = data._id;

      notiById.dctp = data.dctp || 'eighty';
      notiById.hm = data.hm;
      notiById.mnbc = data.mnbc;
      notiById.lcpt = data.lcpt;
      notiById.isdon = data.isdon;
      notiById.isnutd = data.isnutd;
      notiById.snutd = data.snutd;
      notiById.dmnt = data.dmnt;
      notiById.dsnt = data.dsnt;
      notiById.dlynt = data.dlynt;
      notiById.intd = data.intd;
      notiById.rndnt = data.rndnt;
      notiById.mqt = data.mqt;
      notiById.icstlp = data.icstlp;
      notiById.irhwc = data.irhwc;
      notiById.rexkw = data.rexkw;
      notiById.rinkw = data.rinkw;
      notiById.rsrt = data.rsrt;
      notiById.rpgcnt = data.rpgcnt;
      notiById.lng = data.lng;
      notiById.idisnp = data.idisnp;
      notiById.iemdshpfy = data.iemdshpfy;
      notiById.isrtag = data.isrtag;
      notiById.tmagvl = data.tmagvl;
      notiById.tmagtyp = data.tmagtyp;
      notiById.tmagtxt = data.tmagtxt;
      notiById.isdsk = data.isdsk;
      notiById.icntpth = data.icntpth;
      notiById.cntpth = data.cntpth;
      notiById.itqc = data.itqc || false;
      notiById.isnpwdsg = data.isnpwdsg;
      notiById.isnpifdanmt = data.isnpifdanmt;
      notiById.isnpcsthgt = data.isnpcsthgt;
      notiById.isnpspcbtwnntf = data.isnpspcbtwnntf;
      notiById.idrkthm = data.idrkthm;
      notiById.icstcss = data.icstcss;
      notiById.cstcss = data.cstcss || '';
      notiById.csthtml = data.csthtml || '';

      stateObj['notiById'] = { ...state.notiById, ...notiById };
      changeNameValue(stateObj);
    }
    else if (state.abId !== 'current') {
      let stateObj = {};
      let notifById = JSON.parse(state.cloneNotiById);
      stateObj['cnf'] = notifById.cnf;
      stateObj['notiById'] = notifById;
      stateObj['selectTemp'] = notifById.tmid;
      stateObj['abId'] = 'current';
      changeNameValue(stateObj);
    }
  }

  //handle Create AbTest Notif (AbTest).
  const handleCreateAbTestNotif = useCallback(() => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {}, isCreateVariant: true });
      let obj = {};
      let notifId = state?.notifId || '';

      if (state.abVrnt === 'current') {
        let notifById = JSON.parse(state.cloneNotiById);

        obj = {
          id: notifId || '',
          cnf: notifById.cnf || '',
          ttl: state.abTitle,
          desc: state.abDesc,
          abia: state.abia,
          fn: state.fn,
          tmid: notifById.tmid || '',
          dctp: notifById.dctp || 'eighty',
          hm: notifById.hm || '',
          mnbc: notifById.mnbc,
          lcpt: notifById.lcpt,
          isdon: notifById.isdon || '',
          isnutd: notifById.isnutd || '',
          snutd: notifById.snutd || '',
          dmnt: notifById.dmnt || '',
          dsnt: notifById.dsnt || '',
          dlynt: notifById.dlynt || '',
          intd: notifById.intd || '',
          rndnt: notifById.rndnt || '',
          mqt: notifById.mqt,
          icstlp: notifById.icstlp || '',
          irhwc: notifById.irhwc || '',
          rexkw: notifById.rexkw || '',
          rinkw: notifById.rinkw || '',
          rsrt: notifById.rsrt || '',
          rpgcnt: notifById.rpgcnt || '',
          lng: notifById.lng || '',
          idisnp: notifById.idisnp || '',
          isrtag: notifById.isrtag || '',
          tmagvl: notifById.tmagvl || '',
          tmagtyp: notifById.tmagtyp || '',
          tmagtxt: notifById.tmagtxt || '',
          isdsk: notifById.isdsk || '',
          icntpth: notifById.icntpth,
          cntpth: notifById.cntpth,
          itqc: notifById.itqc || false,
          isnpwdsg: notifById.isnpwdsg,
          isnpifdanmt: notifById.isnpifdanmt,
          isnpcsthgt: notifById.isnpcsthgt || '',
          isnpspcbtwnntf: notifById.isnpspcbtwnntf || '',
          idrkthm: notifById.idrkthm || '',
          icstcss: notifById.icstcss || '',
          cstcss: notifById.cstcss || '',
          csthtml: notifById.csthtml || '',
        };
      }
      else {
        obj = state?.objAbtestNotif?.vrnt && state.objAbtestNotif.vrnt.filter(x => x.ttl === state.abVrnt)[0];
        obj = {
          ...obj,
          id: notifId,
          ttl: state.abTitle,
          desc: state.abDesc,
          abia: state.abia,
          fn: state.fn
        }
      }

      dispatch(notifEditDucks.createAbTestNotif(obj));
      openCloseModal('crtVarntOpen', state.crtVarntOpen, 'close');
      changeNameValue({ abTitle: '', abDesc: '', abia: true, abVrnt: 'current', isDefaultTabActive: false });
    }
  }, [state]);

  const deleteAbTestNotif = () => {
    let _objNotif = { ...state.objAbtestNotif };
    let _arrVrnt = state.arrVrnt;
    let stateObj = { isDeleteVariant: true };

    let obj = {
      notifId: state.notifId,
      abId: state.variantId
    };

    //if notif. variant delete in side panel then remove from the array
    if (state.variantId && _objNotif?.vrnt?.length > 0) {//delete variant
      for (let i = 0; i < _objNotif.vrnt.length; i++) {
        if (_objNotif.vrnt[i]._id === state.variantId) {
          let updatedVrnt = _objNotif.vrnt.slice();
          updatedVrnt.splice(i, 1);
          _objNotif.vrnt = updatedVrnt;

          //remove from the variant array
          if (_arrVrnt && Array.isArray(_arrVrnt)) _arrVrnt.splice(i, 1);
        }
      }

      // if no varinant, set defualt 'origin' tab active 
      if (_objNotif?.vrnt?.length === 0) stateObj['tabActiveTyp'] = 1;
    }
    else {//delete main variant
      _objNotif.idltfrmvrnt = true;
      //remove from the variant array
      if (_arrVrnt) _arrVrnt.splice(0, 1);
    }

    stateObj['objAbtestNotif'] = _objNotif;
    stateObj['arrVrnt'] = _arrVrnt;
    changeNameValue(stateObj);

    dispatch(notifEditDucks.deleteAbTestNotif(obj));
    openCloseModal('variantConfirmOpen', state.variantConfirmOpen, 'close');
  }

  //handle all_in_one change
  const handleArrayChange = useCallback((name, value, key, inm) => {
    let fbFwl = [], linkFwl = [], twtFwl = [], instaFwl = [], ytbFwl = [], twhFwl = [], FbGrpFwl = [], tiktokFwl = [];
    let currentCNF = { ...state.cnf };

    let ainctArr = currentCNF?.ainct && [...currentCNF?.ainct.map(item => ({ ...item }))];

    if (name === 'ldsc' && value && value.includes('{{PAGE_URL}}') !== true) {
      ainctArr[key][name] = value + ' {{PAGE_URL}}';
    }
    else ainctArr[key][name] = value;

    if (name === 'pgid') {
      dispatch(notifEditDucks.getProductURL({ pgid: value }));
      changeNameValue({ proKeyValue: key });

      const followerMap = {
        facebook: fbFwl,
        twitter_follower: twtFwl,
        linkedin: linkFwl,
        instagram_follower: instaFwl,
        youtube_channel: ytbFwl,
        twitchtv: twhFwl,
        facebookgroupfollowers: FbGrpFwl,
        tiktok: tiktokFwl,
      };

      let filterData = followerMap[inm];
      if (filterData) {
        const matchingFollower = filterData.find(x => x.pgid === value);
        if (matchingFollower) {
          ainctArr[key]['uid'] = matchingFollower.uid;
        }
      }
    }

    if (name == 'bgc') {
      handleArrayEditor(key, value);
    }

    let stateObj = {
      fbFwl: fbFwl,
      twtFwl: twtFwl,
      linkFwl: linkFwl,
      instaFwl: instaFwl,
      ytbFwl: ytbFwl,
      twhFwl: twhFwl,
      FbGrpFwl: FbGrpFwl,
      tiktokFwl: tiktokFwl,
    }
    currentCNF.ainct = ainctArr;
    changeNameValue({ cnf: currentCNF, isEditNotif: true }, stateObj);
  }, [state.cnf]);

  //add page url tag for what's app widget (remove if arraychange function is same)
  const addCustomTag = (name, state, value) => {
    if (name === 'ldsc') {
      if (value && value.includes('{{PAGE_URL}}') !== true) {
        let _cnf = JSON.stringify({ ...state.cnf });
        let cnf = JSON.parse(_cnf);
        let ainct = cnf.ainct;
        ainct[state][name] = value + ' {{PAGE_URL}}';
        cnf.ainct = ainct;

        changeNameValue({ cnf, isEditNotif: true });
      }
    }
  }

  // Apply background to quill editor -(Add list tab)
  const handleArrayEditor = useCallback((key, value) => {
    setTimeout(() => {
      if (key !== undefined) {
        if (document.getElementsByClassName('react_quill_cls_' + key)) {
          let arrEditor = document.getElementsByClassName('react_quill_cls_' + key);
          for (let i = 0; i < arrEditor.length; i++) {
            let childdiv = arrEditor[i].childNodes[1].childNodes[0];
            childdiv.style.background = value;
          }
        }
      } else if (state?.cnf?.ainct) {
        for (let j = 0; j < state.cnf.ainct.length; j++) {
          let _key = j;
          let value = state.cnf.ainct[j].bgc;
          if (document.getElementsByClassName('react_quill_cls_' + _key)) {
            let arrEditor = document.getElementsByClassName('react_quill_cls_' + _key);
            for (let i = 0; i < arrEditor.length; i++) {
              let childdiv = arrEditor[i].childNodes[1].childNodes[0];
              childdiv.style.background = value;
            }
          }
        }
      }
    }, 1);
  }, [state.cnf]);

  //send dummy webhook data
  const sendWebhookData = (url) => {
    const _obj = {
      url: url,
      obj: {
        username: 'Mr.Alok',
        firstname: 'Mr.Alok',
        lastname: 'Smith',
        email: 'alok@gmail.com',
        phone: '202-555-0118',
        message: 'I am satisfy with your service',
        city: 'Albany',
        state: 'New York',
        country: 'USA',
        pageurl: 'http://example.com/contact-us',
        ip: '102.10.2.45',
        latitude: '40.730610',
        longitude: '-73.935242',
        hostname: 'example.com'
      }
    };
    dispatch(notifEditDucks.sendWebhookData(_obj));
  }

  //on click add- utm add
  const addUtmSource = (val, name) => {
    let NewURL = val;

    if (name === 'tmtxt') {
      NewURL = val + ' {{COUNTER}}';
    }
    else {
      let utm = 'utm_source=WiserNotify&utm_medium={{FullSiteURL}}&utm_campaign={{NotificationName}}';
      //tech brand name from agency account if sub user login 

      //white_label
      if (localStorage.getItem('isbusr') === 'true') {
        let bn = ProfileData?.brand?.bn;
        utm = utm.replace('WiserNotify', bn);
      }
      if (val && val.includes('{{FullSiteURL}}') !== true) {
        if (val.indexOf('?') !== -1) {
          NewURL = val + '&' + utm;
        } else {
          NewURL = val + '?' + utm;
        }
      }
    }

    let currentState = { ...state.cnf };
    currentState[name] = NewURL;

    changeNameValue({ isEditNotif: true, cnf: currentState });
  }

  //handle setting Tabs changes and language translate
  const handleSettingChange = (name, value) => {
    let _currentState = JSON.stringify({ ...state.notiById });
    let currentState = JSON.parse(_currentState);

    if (name === 'dctp') {
      if (value === 80) value = 'eighty';
      else if (value === 90) value = 'ninety';
      else if (value === 100) value = 'hundred';
      else if (value === 110) value = 'oneten';
      else if (value === 120) value = 'onetwenty';
      else if (value === 130) value = 'onethirty';
      else if (value === 140) value = 'onefourty';
      else value = 'eighty';
    }

    //for show in mobile
    if (name === 'hm')
      value = !value;

    //for review feed set default duration
    if ((name === 'isnutd' && value === true) && currentState?.snutd === null) {
      currentState.snutd = 1;
    }

    //if Display in loop toggle on then open sidepanel
    if (name === 'icstlp' && value === true)
      state.settingTab = 'timer';

    //handle notif. template theme change 
    if (name === 'idrkthm')
      applyThemeColor(value);

    //in Timer notif. and custom notif. if embed html true
    if ((state.queryNtt === 'custom' || state.queryNtt === 'call_to_action' ||
      state.queryNtt === 'limited_stock') && name === 'idisnp') {
      currentState.isnpwdsg = value;
    }

    // language change function
    if (name === 'lng')
      handleLanguageChange(value);

    currentState[name] = value;
    changeNameValue({ notiById: currentState, isEditNotif: true });
  }

  //handle text editor change
  const handleTextChange = useCallback((name, key, value, removeEnter) => {
    if (removeEnter !== undefined)
      value = value.replace(/<[^/>][^>]*><\/[^>]+>/gim, '');

    const _cnf = state.cnf ? JSON.stringify({ ...state.cnf }) : {};
    const currentState = _cnf ? JSON.parse(_cnf) : {};
    currentState[name][key] = value;

    // auto fill time_ago fields, if all are empty(for already created notif. only)
    if (state.queryNtt === 'review_recent') {
      if (!currentState.m && !currentState.h && !currentState.d && !currentState.mn && !currentState.mplrl && !currentState.hplrl && !currentState.dplrl && !currentState.mnplrl) {
        currentState.m = '{x} minute ago'; currentState.mplrl = '{x} minutes ago';
        currentState.h = '{x} hour ago'; currentState.hplrl = '{x} hours ago';
        currentState.d = '{x} day ago'; currentState.dplrl = '{x} days ago';
        currentState.mn = '{x} month ago'; currentState.mnplrl = '{x} months ago';
      }
    }

    // Regex to match HTML tags and dynamic values
    // const htmlTagRegex = /<[^>]+>/;
    // const dynamicValueRegex = /{{(.*?)}}/g;
    let newArr = state.newArr || [];

    // // If value contains HTML tags, proceed with dynamic value checks
    // if (htmlTagRegex.test(value)) {
    //   const unsupportedDynamicValues = new Set(); // Collect unsupported dynamic values

    //   let match;
    //   while ((match = dynamicValueRegex.exec(value)) !== null) {
    //     const dynamicValue = match[1].trim(); // Extract dynamic value

    //     if (dynamicValue && state.arrChips && !state.arrChips.includes(dynamicValue)) {
    //       unsupportedDynamicValues.add(dynamicValue); // Add to unsupported values set
    //     }
    //   }

    //   // If there are unsupported dynamic values, update newArr
    //   if (unsupportedDynamicValues.size > 0) {
    //     const existingEntryIndex = newArr.findIndex(item => item.name === name && item.key === key);

    //     if (existingEntryIndex > -1) {
    //       // Update existing entry's value with unsupported dynamic values
    //       newArr[existingEntryIndex].value = Array.from(unsupportedDynamicValues)
    //         .map(val => `{{${val}}}`)
    //         .join(', ');
    //     } else {
    //       // Add new entry for unsupported dynamic values
    //       newArr.push({
    //         name,
    //         key,
    //         value: Array.from(unsupportedDynamicValues)
    //           .map(val => `{{${val}}}`)
    //           .join(', ')
    //       });
    //     }
    //   } else {
    //     // Remove entry if no unsupported dynamic values
    //     newArr = newArr.filter(item => !(item.name === name && item.key === key));
    //   }
    // } else {
    //   // If no HTML tags or dynamic values, remove the entry from newArr
    //   newArr = newArr.filter(item => !(item.name === name && item.key === key));
    // }


    changeNameValue({ cnf: currentState, isEditNotif: true, newArr });
  }, [state.cnf]);

  const handleMainTextChange = useCallback((name, value) => {
    const currentState = { ...state.cnf, [name]: value };

    // auto fill time_ago fields, if all are empty(for already created notif. only)
    if (state.queryNtt === 'review_recent') {
      if (!currentState.m && !currentState.h && !currentState.d && !currentState.mn && !currentState.mplrl && !currentState.hplrl && !currentState.dplrl && !currentState.mnplrl) {
        currentState.m = '{x} minute ago'; currentState.mplrl = '{x} minutes ago';
        currentState.h = '{x} hour ago'; currentState.hplrl = '{x} hours ago';
        currentState.d = '{x} day ago'; currentState.dplrl = '{x} days ago';
        currentState.mn = '{x} month ago'; currentState.mnplrl = '{x} months ago';
      }
    }

    // set default time on checkbox true
    if (name === 'irptdy' && value === true) {
      if (currentState?.rptstm !== '' || currentState?.rptstm !== undefined) {
        currentState.rptstm = '12:00 AM'; //starting time
      }
      if (currentState?.rptetm !== '' || currentState?.rptetm !== undefined) {
        currentState.rptetm = '11:55 PM'; // end time
      }
    }

    // 'Show widget using javascript' is on, off 'Close it if visitors click outside'
    if (name === 'ishntfclk' && value === true) {
      currentState.cloclk = false;
    }

    // Sound of/off
    if (name === 'intfsund') {
      handleNotifSound(value);
    }

    // for timer notification hide counter
    if (name === 'itmrhdhh' && value === true) {
      currentState.itmrhddd = true;
    }
    else if (name === 'itmrhdmm' && value === true) {
      currentState.itmrhddd = true;
      currentState.itmrhdhh = true;
    }

    //CTA click action convert in int
    if (name === 'ctcacn') {
      currentState.ctcacn = parseInt(value);
    }

    //Notification click action convert in int
    if (name === 'ncacn') {
      currentState.ncacn = parseInt(value);
    }

    //for Embed video url in video notification
    if (name === 'vurl' || name === 'yturlcta' || name === 'atpvd' || name === 'imtvd' || name === 'isrltdvd') {
      let isAuto = currentState.atpvd ? 1 : 0;
      let isMute = currentState.imtvd ? 1 : 0;
      let isRel = currentState.isrltdvd ? 0 : 1;

      if (currentState.vurl) {
        let vurl = currentState.vurl.replace('?autoplay=0', '')
          .replace('&autoplay=0', '')
          .replace('&mute=0', '')
          .replace('&rel=0', '')
          .replace('&muted=0', '')
          .replace('?autoplay=1', '')
          .replace('&autoplay=1', '')
          .replace('&mute=1', '')
          .replace('&rel=1', '')
          .replace('&muted=1', '');
        let match = vurl.match(regExp_youtube);
        if (match && match[2].length === 11) {
          currentState.vurl = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=' + isAuto + '&mute=' + isMute + '&rel=' + isRel;
        } else if (vurl) { // for other player
          let sign = vurl.includes('?') ? '&' : '?';
          currentState.vurl = vurl + sign + 'autoplay=' + isAuto + '&muted=' + isMute + '&rel=' + isRel;
        }
      }

      if (currentState.yturlcta) {
        let yturlcta = currentState.yturlcta.replace('?autoplay=0', '')
          .replace('&autoplay=0', '')
          .replace('&mute=0', '')
          .replace('&rel=0', '')
          .replace('&muted=0', '')
          .replace('?autoplay=1', '')
          .replace('&autoplay=1', '')
          .replace('&mute=1', '')
          .replace('&rel=1', '')
          .replace('&muted=1', '');

        let match = yturlcta.match(regExp_youtube);
        if (match && match[2].length === 11) {
          currentState.yturlcta = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=' + isAuto + '&mute=' + isMute + '&rel=' + isRel;
        } else if (yturlcta) { // for other player
          let sign = yturlcta.includes('?') ? '&' : '?';
          currentState.yturlcta = yturlcta + sign + 'autoplay=' + isAuto + '&muted=' + isMute + '&rel=' + isRel;
        }
      }
    }

    //Embed video url for custom notif.
    //for notification click
    if (name === 'yturlnc' || name === 'atpvdnc' || name === 'imtvdnc' || name === 'isrltdvdnc') {
      let isAuto = currentState.atpvdnc ? 1 : 0;
      let isMute = currentState.imtvdnc ? 1 : 0;
      let isRel = currentState.isrltdvdnc ? 0 : 1;
      if (currentState.yturlnc) {
        let vurl = currentState.yturlnc.replace('?autoplay=0', '')
          .replace('&autoplay=0', '')
          .replace('&mute=0', '')
          .replace('&rel=0', '')
          .replace('&muted=0', '')
          .replace('?autoplay=1', '')
          .replace('&autoplay=1', '')
          .replace('&mute=1', '')
          .replace('&rel=1', '')
          .replace('&muted=1', '');

        let match = vurl.match(regExp_youtube);
        if (match && match[2].length === 11) {
          currentState.yturlnc = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=' + isAuto + '&mute=' + isMute + '&rel=' + isRel;
        } else if (vurl) { // for other player
          let sign = vurl.includes('?') ? '&' : '?';
          currentState.yturlnc = vurl + sign + 'autoplay=' + isAuto + '&muted=' + isMute + '&rel=' + isRel;
        }
      }
    }

    //show lottery widget form div
    let frmDiv = document.querySelectorAll('#NFDGdflt26_Sb42SInFL_FrmDV_' + state.notiById._id);
    if (frmDiv?.length > 0) {
      if (name === 'iunm' || name === 'ieml' || name === 'iphn' || name === 'imsg') {
        frmDiv.forEach(element => element.classList.add('NFDGdflt26_fhr_shw'));
      }
    }

    changeNameValue({ cnf: currentState, isEditNotif: true });
  }, [state.cnf, state.notiById]);

  //active notification from the popup
  const activeNotifPopUp = () => {
    let notiById = JSON.stringify({ ...state.notiById });
    let _notiById = JSON.parse(notiById);

    _notiById.ia = true;

    let data = {
      _id: _notiById._id,
      iafab: _notiById.iafab,
      fn: state.fn,
      ia: true,
      csub: ProfileData?.csub,
      idmtstusr: ProfileData?.idmtstusr,
      ntt: _notiById.nt
    };

    dispatch(notifEditDucks.updNotifStatus(data));
    changeNameValue({ notiById: _notiById });
    handleBackToMain();
  }

  // apply background color to ql-edit's text background -on every bg color change
  useEffect(() => {
    editorBackground();
  }, [state.verticalActive, state?.cnf?.bg, state?.cnf?.rvpcrbcl, state?.cnf?.rvpbcl,
  state?.cnf?.rvptbg, state?.cnf?.ibg, state?.cnf?.clbg, state.tabActiveTyp, state?.defaultNotif]);

  const editorBackground = useCallback(() => {
    setTimeout(() => {
      if (state?.cnf) {
        const { bg, rvpcrbcl, rvpbcl, rvptbg, ibg, clbg } = state.cnf;

        // All text line background
        let qlEdit = Array.from(document.getElementsByClassName('ql-container'));
        qlEdit.forEach((oEditor) => {
          oEditor.style.background =
            (!bg || bg == ' ') && state?.defaultNotif?.tn === 'Timer-7' ?
              '#dd611b' : // timer-7 defualt bg-img showing, so giving custom color to editor(bg == ' ' -> this cond. is for old notifs.)
              state?.notiById?.idisnp === true && state?.notiById?.isnpwdsg !== true
                ? '#fff' : bg;
          //HTML snippet editor not add class
        });

        //for feed panel review card block 
        let rvBlock = Array.from(document.getElementsByClassName('rvcardtxt'));
        rvBlock.forEach((block) => {
          let rvtxt = block.childNodes[1]?.childNodes[0];
          if (rvtxt) rvtxt.style.background = rvpcrbcl;
        });

        //for feed panel
        let rvPanel = Array.from(document.getElementsByClassName('rvpaneltxt'));
        rvPanel.forEach((panel) => {
          let feeddiv = panel.childNodes[1]?.childNodes[0];
          if (feeddiv) feeddiv.style.background = rvpbcl;
        });

        //for feed panel tab txt
        let tabtxt = document.getElementsByClassName('feedtabtitletxt')[0];
        if (tabtxt) {
          let tabdiv = tabtxt.childNodes[1]?.childNodes[0];
          if (tabdiv) tabdiv.style.background = rvptbg;
        }

        //for Whatsapp Chat editor text
        let ttltxt = document.getElementsByClassName('ttltxt')[0];
        if (ttltxt) {
          let ttldiv = ttltxt.childNodes[1]?.childNodes[0];
          if (ttldiv) ttldiv.style.background = ibg;
        }

        //for offer Chat editor text
        let offertxt = document.getElementsByClassName('offertxt')[0];
        if (offertxt) {
          let offerdiv = offertxt.childNodes[1]?.childNodes[0];
          if (offerdiv) offerdiv.style.background = clbg;
        }

        //for fourth line editor
        var frtltxt = document.getElementsByClassName('frtltxt')[0];
        if (frtltxt && state.notiById.ntt !== 'lottery') {
          var childdiv = frtltxt.childNodes[1].childNodes[0];
          if (childdiv) childdiv.style.background = ibg;
        }

        //for custom notif-8. editor
        if (state.selectTemp === '5f22a23269343d11a002a4f7') {
          var customtxt = document.getElementsByClassName('fltxt')[0];
          if (customtxt) {
            var customdiv = customtxt.childNodes[1].childNodes[0];
            customdiv.style.background = ibg;
          }
        }
      }

    }, 1);
  }, [state.cnf, state.defaultNotif]);

  const handleNotifSound = (val) => {
    if (val === true) {
      new window.Audio('https://storage.googleapis.com/wiser_goal/notification_tone/notification_sound_2.mp3').play();
    }
  }

  const handleBackToMain = () => {
    if (!abId) {
      navigate(-1);
    }
    else { //on abTesting back open abTest Modal
      let url = 'socialproof';
      if (queryNtyp === 'widget') url = 'widgets';
      else if (queryNtyp === 'inlinenudge') url = 'inline';
      navigate('/' + url + '?ab=' + state.notifId);
    }

    changeNameValue({ tabActiveTyp: 1, abId: null, notifById: {} });
  }

  // open 1st collapse on main tab(display,datasource) click
  useEffect(() => {
    // handleTabsCollapse('isFirstTabActive', !state.isFirstTabActive);
    // changeNameValue({ 'isFirstTabActive': true });

    // call integration api on dataSource tab clk
    if (state.mainActiveNm === 'dataSource' || queryParams.get("ntt") === 'social_media_followers') {
      dispatch(notifEditDucks.getActiveIntgList());
    }
  }, [state.mainActiveNm]);

  const editorFocus = (typ) => {
    if (document.getElementsByClassName('showEditor').length) {
      const arrShow = document.getElementsByClassName('showEditor');
      for (let i = 0; i < arrShow.length; i++) {
        const oShow = arrShow[i];
        oShow.classList.remove('showEditor');
      }
    }
    if (document.getElementsByClassName(typ).length > 0)
      document.getElementsByClassName(typ)[0].classList.add('showEditor');

    //for show thanks div
    const id = state.notifId;
    const ttn = state?.defaultNotif?.ttn;
    let frmDivArr = document.querySelectorAll('#NFDGdflt26_Sb42SInFL_FrmDV_' + id);
    let tnkDivArr = document.querySelectorAll('#NFDGdflt26_Sb42SInFL_Thnk_' + id);

    frmDivArr.length > 0 && frmDivArr.forEach(frmDiv => {
      tnkDivArr.length > 0 && tnkDivArr.forEach(tnkDiv => {
        if (typ === 'tltxt' && ttn === 'call_back_1') {
          frmDiv.classList.add('NFDGdflt26_fhr_hde');
          tnkDiv.classList.add('NFDGdflt26_fhr_shw');
        }
        else if (typ === 'sltxt' && ttn === 'call_back_3') {
          frmDiv.classList.add('NFDGdflt26_fhr_hde');
          tnkDiv.classList.add('NFDGdflt26_fhr_shw');
        }
        else if (typ === 'frtltxt' && ttn === 'call_back_4') {
          frmDiv.classList.add('NFDGdflt26_fhr_hde');
          tnkDiv.classList.add('NFDGdflt26_fhr_shw');
        }
        if ((typ === 'tltxt' || typ === 'frtltxt') && ttn === 'lottery_1') {
          frmDiv.classList.add('NFDGdflt26_fhr_shw');
        }
        if (typ === 'fftltxt' && ttn === 'lottery_1') {
          tnkDiv.classList.add('NFDGdflt26_fhr_shw');
        }
      });
    });

    //for collector thanks div show  
    if (typ === 'clthk') {
      let colFrmDiv = document.querySelectorAll('#NFDGdflt26_Slp_cllbck_FrmDV');
      let colthnkDiv = document.querySelectorAll('#NFDGdflt26_Slp_cllbck_Thnk');
      if (colFrmDiv.length > 0) colFrmDiv.forEach(element => element.classList.add('NFDGdflt26_fhr_hde'));
      if (colthnkDiv.length > 0) colthnkDiv.forEach(element => element.classList.add('NFDGdflt26_fhr_shw'));
    }
  }

  const editorBlur = () => {
    const id = state.notifId;
    let frmDivArr = document.querySelectorAll('#NFDGdflt26_Sb42SInFL_FrmDV_' + id);
    let tnkDivArr = document.querySelectorAll('#NFDGdflt26_Sb42SInFL_Thnk_' + id);

    frmDivArr.length > 0 && frmDivArr.forEach(frmDiv => {
      frmDiv.classList.remove('NFDGdflt26_fhr_hde');
      frmDiv.classList.remove('NFDGdflt26_fhr_shw');
    });
    tnkDivArr.length > 0 && tnkDivArr.forEach(tnkDiv => {
      tnkDiv.classList.remove('NFDGdflt26_fhr_hde');
      tnkDiv.classList.remove('NFDGdflt26_fhr_shw');
    });

    //for collector thanks div hide   
    let colFrmDiv = document.querySelectorAll('#NFDGdflt26_Slp_cllbck_FrmDV');
    let colthnkDiv = document.querySelectorAll('#NFDGdflt26_Slp_cllbck_Thnk');
    if (colFrmDiv.length > 0) colFrmDiv.forEach(element => element.classList.remove('NFDGdflt26_fhr_hde'));
    if (colthnkDiv.length > 0) colthnkDiv.forEach(element => element.classList.remove('NFDGdflt26_fhr_shw'));
  }

  //open modal for card
  const openIconModal = (iconType, data) => {
    let obj = {
      isLoadIcon: true,
      iconstate: data,
      isIconType: iconType,
      iconUrl: '',
      uploadedUrl: [],
      icnTabActiveTyp: 'all',
      iconscout: ''
    };
    changeNameValue(obj);
    dispatch(notifEditDucks.getIconList());
    openCloseModal('iconOpen', state.iconOpen, 'open');
  }

  const handleOnClickUpdateCnf = () => {

    // if (state.newArr?.length > 0) {
    //   const unsupportedValues = state.newArr.map(item => `${item.value}`).join(', ');
    //   const errorText = unsupportedValues ? `${unsupportedValues} ${state.newArr?.length === 1 ? 'is' : 'are'} unsupported ${state.newArr?.length === 1 ? 'value' : 'values'} ` : '';
    //   dispatch(toastify({ type: 'error', msg: errorText }));
    //   return false;
    // }

    // check Max.days
    if (state.notiById?.tr && state.notiById?.tu === 'day') {
      if (state.notiById?.ntt === 'recent_view_bulk' && state.notiById?.tr > 8) {
        dispatch(toastify({ type: 'error', msg: 'Max. days 8' }));
        return false;
      } else if (state.notiById?.tr > 365) {
        dispatch(toastify({ type: 'error', msg: 'Max. days 365' }));
        return false;
      }
    }

    //check Max. hours
    if (state.notiById.tr && state.notiById.tu === 'hour' && state.notiById.tr > 23) {
      dispatch(toastify({ type: 'error', msg: 'Max. hours 23' }));
      return false;
    }
    //check Max.review limit
    else if (state.notiById.ntt === 'review_recent' && state.notiById.ld && state.notiById.ld > 30) {
      dispatch(toastify({ type: 'error', msg: 'We can display maximum 30 reviews.' }));
      return false;
      //check Max. record limit
    } else if ((state.notiById.ntt === 'signup_recent' || state.notiById.ntt === 'single_visits'
      || state.notiById.ntt === 'purchase_recent') && state.notiById.ld && state.notiById.ld > 30) {
      dispatch(toastify({ type: 'error', msg: 'We can display maximum 30 records.' }));
      return false;
    }

    //for check enter coupon code duplicate or not
    if (state.cnf.arrlcyltry?.length > 0) {
      if (document.getElementById('coupon_code_block')) {
        document.getElementById('coupon_code_block').classList.remove('nf-group-error');
        let isCodeMatch = false;
        state.cnf.arrlcyltry.filter((x, xkey) => {
          state.cnf.arrlcyltry.filter((y, ykey) => {
            if (xkey !== ykey && x.cpncd.toLowerCase() === y.cpncd.toLowerCase()) {
              isCodeMatch = true;
            }
          });
        });
        if (isCodeMatch) {
          document.getElementById('coupon_code_block').classList.add('nf-group-error');
          dispatch(toastify({ type: 'error', msg: 'You have entered a duplicate coupon code. Enter a unique coupon code.' }));
          return false;
        }
      }
    }

    if (!designValidator.allValid()) {
      designValidator.showMessages();
      designValidator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: designValidator.errorMessages });
    }
    else {
      let { notiById, queryNtt, defaultNotif } = state;

      let stateObj = {};
      const isInvalid = (value) => value === null || value === undefined;

      if ((queryNtt === 'live_visitor' || queryNtt === 'single_visits') && isInvalid(notiById.lcpt)) {
        stateObj.lcpt = notiById.lcpt;
      } else if (queryNtt === 'review_recent' && state.abId !== null && isInvalid(notiById.mnrt)) {
        stateObj.mnrt = notiById.mnrt;
      } else if (queryNtt === 'limited_stock' && isInvalid(notiById.mnbc)) {
        stateObj.mnbc = notiById.mnbc;
      } else if ((queryNtt === 'review_recent' || queryNtt === 'social_media_post')
        && state.abId !== null && isInvalid(notiById.mnrtxtln)) {
        stateObj.mnrtxtln = notiById.mnrtxtln;
      } else if ((queryNtt === 'limited_stock' || queryNtt === 'limited_stock_ecomm_snippet') && isInvalid(notiById.mqt)) {
        stateObj.mqt = notiById.mqt;
      } else if (isInvalid(notiById.dctp)) {
        stateObj.dctp = notiById.dctp;
      } else if (queryNtt === 'recent_view_bulk' && isInvalid(notiById.icntpth)) {
        stateObj.icntpth = notiById.icntpth;
      } else if (queryNtt === 'recent_view_bulk' && isInvalid(notiById.cntpth)) {
        stateObj.cntpth = notiById.cntpth;
      } else if (queryNtt !== 'custom' && queryNtt !== 'limited_stock' &&
        defaultNotif?.ttn !== 'promotional_banner_timer' && isInvalid(notiById.isnpwdsg)) {
        stateObj.isnpwdsg = notiById.isnpwdsg;
      } else if ((queryNtt === 'signup_recent' || queryNtt === 'purchase_recent' ||
        queryNtt === 'review_recent' || queryNtt === 'single_visits') && isInvalid(notiById.isnpifdanmt)) {
        stateObj.isnpifdanmt = notiById.isnpifdanmt;
      }

      if (Object.keys(stateObj).length > 0) {
        let obj = {
          fieldName: stateObj,
          ti: localStorage.getItem('ti'),
          email: localStorage.getItem('e'),
          notifId: window.location.href
        };
        dispatch(notifEditDucks.callPolarisBug(obj));
      }

      let obj = {
        _id: state.notifId,
        ...((state.abSaveId || state.abId) && { abId: state.abSaveId || state.abId }),
        cnf: state.cnf,
        tr: notiById.tr,
        tu: notiById.tu,
        ld: notiById.ld,
        lcpt: notiById.lcpt,
        mnrt: notiById.mnrt,
        mnbc: notiById.mnbc,
        mnrtxtln: notiById.mnrtxtln,
        mqt: notiById.mqt,
        lng: notiById.lng,
        hm: notiById.hm,
        icstlp: notiById.icstlp,
        fn: state.fn,
        tmid: state.selectTemp,
        isdon: notiById.isdon,
        dctp: notiById.dctp || 'eighty',
        isnutd: notiById.isnutd,
        snutd: (notiById.isnutd === true && notiById.snutd === null) ? 1 : notiById.snutd,  //default 1
        dmnt: notiById.dmnt,
        irhwc: notiById.irhwc,
        rexkw: notiById.rexkw,
        rinkw: notiById.rinkw,
        rsrt: notiById.rsrt,
        rpgcnt: notiById.rpgcnt,
        idisnp: notiById.idisnp,
        iemdshpfy: notiById.iemdshpfy,
        dsnt: notiById.dsnt,
        dlynt: notiById.dlynt,
        rndnt: notiById.rndnt,
        intd: notiById.intd,
        isrtag: notiById.isrtag,
        tmagvl: notiById.tmagvl,
        tmagtyp: notiById.tmagtyp,
        tmagtxt: notiById.tmagtxt,
        isdsk: notiById.isdsk,
        icntpth: notiById.icntpth,
        cntpth: notiById.cntpth,
        itqc: notiById.itqc || false,
        expdt: notiById.expdt || '',
        strtdt: notiById.strtdt || '',
        isnpwdsg: notiById.isnpwdsg,
        isnpifdanmt: notiById.isnpifdanmt,
        isnpcsthgt: notiById.isnpcsthgt,
        isnpspcbtwnntf: notiById.isnpspcbtwnntf,
        idrkthm: notiById.idrkthm,
        icstcss: notiById.icstcss,
        cstcss: notiById.cstcss || '',
        csthtml: notiById.csthtml || '',
        ntt: notiById.ntt
      };
      dispatch(notifEditDucks.updateNotifDesign(obj));
      changeNameValue({ isEditNotif: false, errMessage: {}, isDefaultTabActive: false });
      setTimeout(() => { editorBackground(); }, 700);
    }
  }

  const handleCancelCnf = () => {
    let obj = { isEditNotif: false, isAdd: false, errMessage: true, NewArr: [] };
    let colneData = JSON.parse(state.cloneNotiById);

    //if A/B test variant then filter from the all variant and restore old data  
    if (state.abId && state.abId !== 'current' && colneData?.vrnt?.length > 0) {
      colneData = colneData?.vrnt.filter(x => x._id === state.abId)[0];
    }

    // discard bg color from 'Add list' quill editor
    if (state?.isOpenCards)
      handleArrayEditor(state?.isOpenCards, colneData?.cnf?.ainct[state?.isOpenCards]?.bgc);

    obj['cnf'] = colneData?.cnf;
    obj['notiById'] = { ...state.notiByIdCopy, ...colneData };
    obj['selectTemp'] = colneData?.tmid;

    let defaultNotif = state.notifData?.allTemplate.filter(x => x._id === colneData?.tmid)[0] || '';
    if (defaultNotif) obj['defaultNotif'] = defaultNotif;

    changeNameValue(obj);
  }

  const copyHTMLSnippet = (notifId, type, copId) => {
    let copyTxt = '', copyMsg = '';

    // for selected copy code 
    if (document.getElementById(copId))
      document.getElementById(copId).select();

    if (type === 'widget') {
      copyTxt = 'fnShowWidgetOnAnyEvent("' + notifId + '");';
      copyMsg = 'Function copied.';
    }
    else if (type === 'shopify') {
      copyTxt = notifId;
      copyMsg = 'Id is copied.';
    }
    else if (type === 'feed') {
      copyTxt = 'wiserFeed_' + notifId;
      copyMsg = 'HTML tag copied.';
    }
    else if (type === 'limitedStock') {
      copyTxt = '<div id="' + notifId + '" data-id="{{ product.id }}" class="htmlsnpt wn_lmtdstk_snpt"></div>';
      copyMsg = 'HTML tag copied.';
    }
    else {
      copyTxt = '<div id="' + notifId + '" class="htmlsnpt"></div>';
      copyMsg = 'HTML tag copied.';
    }

    dispatch(toastify({ type: 'success', msg: copyMsg }));
    copy(copyTxt, { debug: true });
  }

  // modal open/close function
  const openCloseModal = useCallback((name, value, type) => {
    let obj = {};

    //clear state on close modal
    if (name === 'crtVarntOpen' && type === 'close') {
      obj['abTitle'] = '';
      obj['abDesc'] = '';
      obj['abVrnt'] = 'current';
      obj['abia'] = true;
    }
    if (name === 'inteCountOpen' && type === 'close') {
      obj['pgid'] = '';
      obj['fuid'] = '';
    }

    if (name === 'webPreviewOpen') // on desktop modal close, active mobile tab
      obj['isMobileScreen'] = true;

    // don't close modal on click of outside
    if (type !== undefined) {
      obj[name] = !value;
    }
    changeNameValue(obj);
  }, []);

  const callResetApi = () => {
    let temp = JSON.stringify(state?.defaultNotif);
    let temp_parse = JSON.parse(temp);

    let _cnf = JSON.stringify({ ...state?.cnf });
    let cnf = JSON.parse(_cnf);

    if (temp_parse) {
      let Newcnf = temp_parse.cnf;

      let _notiById = JSON.parse(JSON.stringify(state.notiById));
      _notiById.lng = 'en';

      if (temp_parse.dctp !== undefined && temp_parse.dctp !== null) {
        _notiById.dctp = temp_parse.dctp;
      }
      changeNameValue({ notiById: _notiById });

      //for subuser
      //condtion for sub user and team mate load brand name from agnecy account
      //white_label
      if (localStorage.getItem('isbusr') === 'true' || localStorage.getItem('istm') === 'true') {
        let bn = ProfileData?.brand?.bn;
        let bu = ProfileData?.brand?.bu;
        if (Newcnf.bn !== undefined) Newcnf.bn.txt = bn;   //brand name
        if (Newcnf.bu !== undefined) Newcnf.bu = bu;       //brand url
        if (Newcnf.cu !== undefined) Newcnf.cu = bu;       //CTA url
        if (Newcnf.nu !== undefined) Newcnf.nu = bu;       //notif. url
        if (Newcnf.demls) Newcnf.demls = Newcnf.demls.replace('WiserNotify', bn); //for email subject
      }
      let Tmdrfield = state.cnf.tmdr; // onReset - timeDuration field is not reset
      cnf = Newcnf;
      cnf.tmdr = Tmdrfield;
    }

    //for fdln field (Embed into your HTML)
    if (state?.notifData?.notifType) {
      const obj = state.notifData.notifType.filter(x => {
        if (x._id.toString() === state.queryNti)
          return x;
      })[0];
      cnf.fdln = obj.config.fdln;
    }

    openCloseModal('resetOpen', state.resetOpen, 'close');
    changeNameValue({ cnf, isEditNotif: true });
  }

  const handleLanguageChange = async (lng) => {
    let currentCnf = JSON.parse(JSON.stringify({ ...state.cnf }));
    let currentNotiById = JSON.parse(JSON.stringify({ ...state.notiById }));
    currentNotiById.lng = lng;

    // const cnfFields = ['fl', 'sl', 'tl', 'frtl', 'cl', 'd', 'h', 'm', 'mn', 'fdln',
    //   'ag', 'rdmr', 'fftl', 'sxtl', 'mplrl', 'hplrl', 'dplrl', 'mnplrl'];

    // Fields to be translated from cnf
    const cnfFields = ['fl', 'sl', 'tl', 'frtl', 'fftl', 'sxtl', 'svntl', 'cnt', 'cntv', 'cl', 'd', 'h', 'm', 'mn', 'fdln',
      'ag', 'rdmr', 'mplrl', 'hplrl', 'dplrl', 'mnplrl'];

    // Field to be translated from notiById
    const notiByIdFields = ['tmagtxt'];

    let translationInputs = [
      ...cnfFields.map(field => currentCnf[field]?.txt ?? currentCnf[field] ?? ''),
      currentNotiById.tmagtxt ?? ''
    ];

    let payload = JSON.stringify({
      q: translationInputs, // Fields to translate
      target: lng           // Target language
    })
      .replace(/{{TIME_AGO}}/gi, '{{TIME_AGO_D}}') // Replace placeholders
      .replace(/{{CITY}}/gi, '{{CITY_D}}')
      .replace(/{{COUNTRY}}/gi, '{{COUNTRY_D}}')
      .replace(/{{PRODUCT_NAME}}/gi, '{{PRODUCT_NM}}');

    const response = await fetch('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCiobSr4-dnFBZ9nLj8tbyo01zeCiyEMik', {
      method: 'POST',
      body: payload,
    });

    const apiResponse = await response.json();
    const translations = apiResponse?.data?.translations || [];

    // Apply translations to cnfFields
    cnfFields.forEach((field, index) => {
      if (translations[index] && currentCnf[field] !== undefined) {
        const translatedText = translations[index].translatedText
          ? translations[index].translatedText
            .replace(/{{TIME_AGO_D}}/gi, '{{TIME_AGO}}')
            .replace(/{{CITY_D}}/gi, '{{CITY}}')
            .replace(/{{COUNTRY_D}}/gi, '{{COUNTRY}}')
            .replace(/{{PRODUCT_NM}}/gi, '{{PRODUCT_NAME}}')
          : '';

        if (typeof currentCnf[field] === 'object' && currentCnf[field]?.txt !== undefined) {
          currentCnf[field].txt = translatedText;
        } else {
          currentCnf[field] = translatedText;
        }
      }
    });

    // Apply translations to notiById fields
    notiByIdFields.forEach((field, index) => {
      const translationIndex = cnfFields.length + index; // Get the correct index for notiById fields
      if (translations[translationIndex] && currentNotiById[field] !== undefined) {
        currentNotiById[field] = translations[translationIndex].translatedText ?? '';
      }
    });

    changeNameValue({ cnf: currentCnf, notiById: currentNotiById });
  };

  // optimize this function @@@
  const applyThemeColor = (value) => {
    let { queryNtt, notifId, ProfileData } = state;

    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Dark Mode Toggle', queryNtt + '_' + notifId, ProfileData?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Dark Mode Toggle', { 'event_action': queryNtt + '_' + notifId, 'event_label': ProfileData?.e || '' });
      } catch (error) { console.log(error); }
    }

    let currTemp = state?.notifData?.allTemplate.filter(x => x._id === state.selectTemp)[0];

    if (value === true) {
      let obj = currTemp.drkthm ? currTemp.drkthm : {};

      const _cnf = JSON.stringify(state.cnf);
      let cnf = JSON.parse(_cnf);

      if (cnf.cnt && cnf.cnt.txt !== undefined) cnf.cnt.txt = cnf.cnt.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.fl && cnf.fl.txt !== undefined) cnf.fl.txt = cnf.fl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.sl && cnf.sl.txt !== undefined) cnf.sl.txt = cnf.sl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.tl && cnf.tl.txt !== undefined) cnf.tl.txt = cnf.tl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.frtl && cnf.frtl.txt !== undefined) cnf.frtl.txt = cnf.frtl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.fftl && cnf.fftl.txt !== undefined) cnf.fftl.txt = cnf.fftl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.sxtl && cnf.sxtl.txt !== undefined) cnf.sxtl.txt = cnf.sxtl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.flecmdsk && cnf.flecmdsk.txt !== undefined) cnf.flecmdsk.txt = cnf.flecmdsk.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.flecmmbl && cnf.flecmmbl.txt !== undefined) cnf.flecmmbl.txt = cnf.flecmmbl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);

      //for limited stock notif   
      if (cnf.cntv && cnf.cntv.txt !== undefined) cnf.cntv.txt = cnf.cntv.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);

      //for youtube channel / video
      if (cnf.ytbcntclr !== undefined && obj.fntClr) cnf.ytbcntclr = obj.fntClr;

      //for collector in recent sign-up
      if (cnf.clmsg !== undefined) cnf.clmsg = cnf.clmsg.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.clsb !== undefined) cnf.clsb = cnf.clsb.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.clplcy !== undefined) cnf.clplcy = cnf.clplcy.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);
      if (cnf.clthk !== undefined) cnf.clthk = cnf.clthk.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, obj.fntClr);

      if (cnf.bn && cnf.bn.clr !== undefined && obj.fntClr) cnf.bn.clr = obj.fntClr;
      if (cnf.cl && cnf.cl.clr !== undefined && obj.ctaFntClr) cnf.cl.clr = obj.ctaFntClr;
      if (cnf.clbg !== undefined && obj.ctabgClr) cnf.clbg = obj.ctabgClr;
      if (cnf.bg !== undefined && obj.bgClr) cnf.bg = obj.bgClr;
      if (cnf.ibg !== undefined && obj.icnBgClr) cnf.ibg = obj.icnBgClr;

      changeNameValue({ cnf });
      return cnf;
    }
    else {
      let newCnf = currTemp.cnf;

      const _cnf = JSON.stringify({ ...state.cnf });
      let cnf = JSON.parse(_cnf);

      if (cnf.cnt && cnf.cnt.txt !== undefined) cnf.cnt.txt = cnf.cnt.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.fl && cnf.fl.txt !== undefined) cnf.fl.txt = cnf.fl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.sl && cnf.sl.txt !== undefined) cnf.sl.txt = cnf.sl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.tl && cnf.tl.txt !== undefined) cnf.tl.txt = cnf.tl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.frtl && cnf.frtl.txt !== undefined) cnf.frtl.txt = cnf.frtl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.fftl && cnf.fftl.txt !== undefined) cnf.fftl.txt = cnf.fftl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.sxtl && cnf.sxtl.txt !== undefined) cnf.sxtl.txt = cnf.sxtl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.flecmdsk && cnf.flecmdsk.txt !== undefined) cnf.flecmdsk.txt = cnf.flecmdsk.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.flecmmbl && cnf.flecmmbl.txt !== undefined) cnf.flecmmbl.txt = cnf.flecmmbl.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');

      //for limited stock notif      
      if (cnf.cntv && cnf.cntv.txt !== undefined) cnf.cntv.txt = cnf.cntv.txt.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');

      //for youtube channel / video color
      if (cnf.ytbcntclr !== undefined && newCnf.ytbcntclr) cnf.ytbcntclr = newCnf.ytbcntclr;

      //for collector in recent sing-up
      if (cnf.clmsg !== undefined) cnf.clmsg = cnf.clmsg.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.clsb !== undefined) cnf.clsb = cnf.clsb.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.clplcy !== undefined) cnf.clplcy = cnf.clplcy.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');
      if (cnf.clthk !== undefined) cnf.clthk = cnf.clthk.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi, 'rgb(92, 110, 133)');

      if (cnf.bn && cnf.bn.clr !== undefined && newCnf.bn.clr) cnf.bn.clr = newCnf.bn.clr;
      if (cnf.cl && cnf.cl.clr !== undefined && newCnf.cl.clr) cnf.cl.clr = newCnf.cl.clr;
      if (cnf.clbg !== undefined && newCnf.clbg) cnf.clbg = newCnf.clbg;
      if (cnf.bg !== undefined && newCnf.bg) cnf.bg = newCnf.bg;
      if (cnf.ibg !== undefined && newCnf.ibg) cnf.ibg = newCnf.ibg;

      changeNameValue({ cnf });
      return cnf;
    }
  };

  // add clickEvent to notifications/widgets
  useEffect(() => {
    // button click open popup (lottery | review-feed | offer)
    const interval = setInterval(() => {
      var clsForToggleCallbackDiv = document.getElementsByClassName('NFDGdflt26_cLiCkTocAlL_BlOcK');
      if (clsForToggleCallbackDiv?.length > 0) {

        for (let i = 0; i < clsForToggleCallbackDiv.length; i++) {
          const _class = clsForToggleCallbackDiv[i];

          if (!_class.dataset.listenerAdded) {  // Check if the listener is already attached
            _class.removeEventListener('click', handleOnToggleCallbackDiv);
            _class.addEventListener('click', handleOnToggleCallbackDiv);
            _class.dataset.listenerAdded = 'true'; // Mark the listener as added
          }
        }
        clearInterval(interval);
      }
    }, 300);

    //close button icon
    const interval2 = setInterval(() => {
      var clsForCloseFeedPanel = document.querySelectorAll('#NFDGdflt26_rvfd_cls_btn_' + state.notifId);
      if (clsForCloseFeedPanel?.length > 0) {
        for (let i = 0; i < clsForCloseFeedPanel.length; i++) {
          const _close = clsForCloseFeedPanel[i];
          if (!_close.dataset.listenerAdded) { // Check if the listener is already attached
            _close.removeEventListener('click', hideFeedpanel);
            _close.addEventListener('click', hideFeedpanel);
            _close.dataset.listenerAdded = 'true';  // Mark the listener as added
          }
        }
        clearInterval(interval2);
      }
    }, 300);

    //close button icon
    const interval3 = setInterval(() => {
      var clsForToggleFeedbackDiv = document.querySelectorAll('.NFDGdflt26_fEEdbAcK_HeAdEr');
      if (clsForToggleFeedbackDiv?.length > 0) {
        for (let i = 0; i < clsForToggleFeedbackDiv.length; i++) {
          const _close = clsForToggleFeedbackDiv[i];
          if (!_close.dataset.listenerAdded) {   // Check if the listener is already attached
            _close.removeEventListener('click', handleOnToggleFeedbackDiv);
            _close.addEventListener('click', handleOnToggleFeedbackDiv);
            _close.dataset.listenerAdded = 'true';  // Mark the listener as added
          }
        }
        clearInterval(interval3);
      }
    }, 300);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, [state]);

  const stateRef = useRef(state); //for not get current value of state
  useEffect(() => { stateRef.current = state; }, [state]);

  // close panel divs on close button 
  const hideFeedpanel = (e) => {
    const data_id = e?.target?.getAttribute('data-id');

    if (data_id !== '{BANNER_ID}') {
      //r eview feed close btn click hide review feed panel div
      const reviewFeedClose = document.querySelectorAll('#NFDGdflt26_rvfd_pnldv_' + data_id);
      if (reviewFeedClose?.length > 0) {
        reviewFeedClose.forEach(element => {
          element.classList.remove('Noti_rfdv_mndv_blk');
        });
      }

      // on click modal's close btn hide modal div  (reviewFeed || lottery)
      const _panelDiv = document.querySelectorAll('#NFDGdflt26_rvfd_pnldv_mdl_' + data_id);
      const liveBlock = document.querySelectorAll('#live_preview_block');

      if (_panelDiv && _panelDiv.length > 0 && (stateRef.current.cnf.iimg === true || state?.queryNtt === 'review_feed')) {
        _panelDiv.forEach(element => {
          element.classList.remove('modal-rvwfeed-show');
        });
        if (liveBlock && liveBlock.length > 0) {
          liveBlock.forEach(element => {
            element.classList.remove('NFDGdflt26_Sb425txt_modal_center');
          });
        }
      }

      // for hide/show Lottery Icon 
      const ltryIcon = document.querySelectorAll('.NFDGdflt26_ltry_Icon');
      if (ltryIcon?.length > 0) {
        ltryIcon.forEach(element => {
          element.classList.remove('Noti_rfdv_mndv_blk');
        });
      }
    }
  }

  //hide/show callback div
  const handleOnToggleCallbackDiv = (e) => {
    let _target = e?.target;
    let data_id = _target?.getAttribute('data-id');

    // not find data id -> then get from parentNodes
    if (!data_id && _target?.parentNode)
      data_id = _target.parentNode.getAttribute('data-id');
    if (!data_id && _target?.parentNode?.parentNode)
      data_id = _target.parentNode.parentNode.getAttribute('data-id');
    if (!data_id && _target?.parentNode?.parentNode?.parentNode)
      data_id = _target.parentNode.parentNode.parentNode.getAttribute('data-id');

    if (data_id !== '{BANNER_ID}') {
      const clss = e?.target.getAttribute('class');
      if (clss === 'NFDGdflt26_fEEdbAcK_HeAdEr') return;

      // offer button click
      const longPopup = document.querySelectorAll('#NFDGdflt26_Sb42bIG_ICn_' + data_id);
      if (longPopup?.length > 0) {
        longPopup.forEach(element => element.classList.toggle('NFDGdflt26_Sb_oPN_LgPoUP'));
      }

      // write caption
      const smllIcon = document.querySelectorAll('#NFDGdflt26_SbOfrtBNNR42t_0_' + data_id);
      if (smllIcon?.length > 0) {
        smllIcon.forEach(element => element.classList.toggle('CLiCkTocAlL_WinDowOpen'));
      }

      // offer close icon
      const offerIcon = document.querySelectorAll('#NFDGdflt26_Sb42SmLL_BnRICn_' + data_id);
      if (offerIcon?.length > 0) {
        offerIcon.forEach(element => element.classList.toggle('NFDGdflt26_Sb_hdE_SMpoUP'));
      }

      //for show review feed panel div 
      const revFeed = document.querySelectorAll('#NFDGdflt26_rvfd_pnldv_' + data_id);
      if (revFeed && revFeed.length > 0) {
        revFeed.forEach(element => element.classList.toggle('Noti_rfdv_mndv_blk'));
      }

      //for show review feed modal div and open lottery modal
      const panelModal = document.querySelectorAll('#NFDGdflt26_rvfd_pnldv_mdl_' + data_id);
      const liveBlock = document.querySelectorAll('#live_preview_block');
      if (panelModal?.length > 0 && liveBlock?.length > 0) {
        panelModal.forEach(element => element.classList.add('modal-rvwfeed-show'));
        liveBlock.forEach(element => element.classList.add('NFDGdflt26_Sb425txt_modal_center'));
      }

      // for hide/show Lottery Icon 
      const ltryIcon = document.querySelectorAll('.NFDGdflt26_ltry_Icon');
      if (ltryIcon && ltryIcon.length > 0) {
        ltryIcon.forEach(element => element.classList.toggle('Noti_rfdv_mndv_blk'));
      }
    }
  }

  // hide-show feedback div (collector)
  const handleOnToggleFeedbackDiv = (e) => {
    let data_id = e?.target?.getAttribute('data-id');
    const collectDiv = document.querySelectorAll('#NFDGdflt26_Sb42bIG_ICn_' + data_id);
    if (collectDiv?.length > 0)
      collectDiv.forEach(element => element.classList.toggle('NFDGdflt26_Sb_oPN_LgPoUP'));
  }

  // display card functions
  const handleDisplayChange = (name, value) => {
    let stateObj = { [name]: value };

    if (name === 'slctShpPrd') {
      changeNameValue({ shpProValue: value?.split('|')[1] });
    }

    if (name === 'dt') {
      if (value === '0') stateObj['urlType'] = '1';
      else stateObj['urlType'] = 'includeURL';

      let obj = {
        dt: value,
        _id: state.notifId,
        fn: state.fn
      };
      stateObj['dt'] = value;
      dispatch(notifEditDucks.updDisplayType(obj));
    }
    else if (name === 'dabs') {
      let doc = {
        dabs: value,
        _id: state.notifId,
        ntt: state.notiById.ntt
      };
      dispatch(notifEditDucks.updAbsoluteURL(doc));
    }
    changeNameValue(stateObj);
  }

  const addDTUFunction = () => {
    if (state.dtu && state.dtURL) {
      let obj = {
        dt: state.dtu,
        ul: state.dtURL,
        _id: state.notifId,
        fn: state.fn,
        ntt: state.notiById?.ntt
      };

      if (obj?.dt === '1' || obj?.dt === '4') {
        let isValidUrl = '';
        isValidUrl = obj.ul.split('.');
        if (isValidUrl.length < 2) {
          dispatch(toastify({ type: 'error', msg: 'Please enter valid URL' }));
          return;
        }
      }

      dispatch(notifEditDucks.addDTU(obj));
      changeNameValue({ dtu: '2', dtURL: '', dtUrlError: false });
    }
    else changeNameValue({ dtUrlError: true });
  }

  const deleteDTUFunction = (id) => {
    if (id) {
      const obj = {
        _id: id,
        nid: state.notifId,
        fn: state.fn,
        ntt: state.notiById?.ntt
      };
      dispatch(notifEditDucks.deleteDTU(obj));
    }
    else dispatch(toastify({ type: 'error', msg: 'Please Add condition' }));
  }

  //add Domain
  const callAddDomain = () => {
    if (state.cddn !== '' && state.cddn !== undefined) {
      let stateObj = { cddnError: false };
      let _domain = state.cddn?.trim().replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];

      let obj = {
        _id: state.notifId,
        ul: _domain,
        fn: state.fn,
        ntt: state.notiById?.ntt
      };

      // find duplicate or not
      let isDuplicate = state.ddnList?.some(item => item?.ul === obj?.ul.toLowerCase());

      if (isDuplicate) dispatch(toastify({ type: 'error', msg: 'Domain already exist' }));
      else {
        if (_domain.split('.')?.length < 2)
          dispatch(toastify({ type: 'error', msg: 'Please enter valid URL' }));
        else {
          stateObj['cddn'] = '';
          stateObj['isAdd'] = true;
          dispatch(notifEditDucks.addDomain(obj));
        }
      }
      changeNameValue(stateObj);
    }
    else changeNameValue({ cddnError: true });
  }

  const deleteDomain = (id) => {
    if (id) {
      let obj = {
        ddnID: id,
        _id: state.notifId,
        fn: state.fn,
        ntt: state.notiById?.ntt
      };
      dispatch(notifEditDucks.delDomain(obj));
      changeNameValue({ isAdd: true });
    }
    else dispatch(toastify({ type: 'error', msg: 'Please add domain' }));
  }

  const handleTagetAudience = (name, value) => {
    changeNameValue({ [name]: value });

    const toggelObj = {
      name: name,
      value: value,
      nid: state.notifId,
      ntt: state.notiById?.ntt
    };

    // condition matches call api
    if (['irtnv', 'icntntf', 'iutmd', 'ibcks', 'irfvf', 'rtnv', 'iadvfcnd'].includes(name)) {
      dispatch(notifEditDucks.updTargetToggle(toggelObj));
    }
  }

  const removeCountry = (index, typ) => {
    const { arinclcnt, arexclcnt, notifId, notiById } = state;
    let array = (typ === 'arinclcnt') ? [...arinclcnt] : [...arexclcnt];

    // remove matched array
    if (index > -1) array.splice(index, 1);

    let updatedState = (typ === 'arinclcnt') ? { arinclcnt: array } : { arexclcnt: array };
    changeNameValue(updatedState);

    let obj = {
      _id: notifId,
      ...(typ === 'arinclcnt' && { arinclcnt: array }),
      ...(typ === 'arexclcnt' && { arexclcnt: array }),
      ntt: notiById?.ntt
    };

    dispatch(notifEditDucks.updNotifCntry(obj));
  }

  const handleSelectLocation = (value, typ) => {
    let obj = {
      _id: notifId,
      ...(typ === 'arexclcnt' && { arexclcnt: value }),
      ...(typ === 'arinclcnt' && { arinclcnt: value }),
      ntt: state.notiById.ntt
    };
    dispatch(notifEditDucks.updNotifCntry(obj));
    changeNameValue({ [typ]: value });
  }

  const addUTMDisplay = () => {
    if (state.utmCnd && state.utmTxt) {
      let utmObj = {
        cnd: state.utmCnd,
        txt: state.utmTxt,
        nid: state.notifId,
        ntt: state.notiById?.ntt
      };

      dispatch(notifEditDucks.addUTMDisplay(utmObj));
      changeNameValue({ utmCnd: '0', utmTxt: '', utmTxtError: false });
    }
    else {
      changeNameValue({ utmTxtError: true });
    }
  }

  const handleDeleteTarget = (id, typ) => {
    if (id) {
      const obj = {
        oid: id,
        nid: state.notifId,
        typ: typ,
        ntt: state.notiById?.ntt
      };
      dispatch(notifEditDucks.deleteTarget(obj));
    }
    else dispatch(toastify({ type: 'error', msg: 'Please fill the details' }));
  }

  const addRefDisplay = () => {
    if (state.refCnd && state.refTxt) {
      let reffObj = {
        cnd: state.refCnd,
        txt: state.refTxt,
        nid: state.notifId,
        ntt: state.notiById?.ntt
      };

      dispatch(notifEditDucks.addRefDisplay(reffObj));
      changeNameValue({ refCnd: '0', refTxt: '', refTxtError: false });
    }
    else changeNameValue({ refTxtError: true });
  }

  const addCoockiDisplay = () => {
    if (state.cokCnd && state.cokTxt) {
      let reffObj = {
        cnd: state.cokCnd,
        txt: state.cokTxt,
        nid: state.notifId,
        ntt: state.notiById?.ntt
      };

      dispatch(notifEditDucks.addCoockiDisplay(reffObj));
      changeNameValue({ cokCnd: '0', cokTxt: '', cokTxtError: false });
    }
    else changeNameValue({ cokTxtError: true });
  }

  const ImportDisplay = () => {
    const activeId = state?.notifFullList?.find(notif => notif.nn === state.impActiveRule)?._id || '';

    if (activeId && state.notifId) {
      let objId = {
        fromNotifId: activeId,
        _id: state.notifId
      }
      setTimeout(() => {
        dispatch(notifEditDucks.getNotfById(state.notifId));
        dispatch(notifEditDucks.getDomain(state.notifId));
      }, 400);
      dispatch(notifEditDucks.importDisplaySetting(objId));
      openCloseModal('importRuleOpen', state.importRuleOpen, 'close');
    }
  }

  const callShpProducts = () => {
    if (state.shopProducts?.length <= 0) {
      setTimeout(() => dispatch(notifEditDucks.getShpPrducts()), 300);
    }
  }

  const timeoutIdRef = useRef(null);

  const searchShopProducts = useCallback((value) => {
    // Clear the previous timeout if it exists
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    if (value && value !== '') {
      if (value.length >= 2) {
        timeoutIdRef.current = setTimeout(() => {
          dispatch(notifEditDucks.getShpPrducts({ title: value }));
        }, 1000);
      }
    }
    else { //after removing search word call api
      dispatch(notifEditDucks.getShpPrducts({ title: value }));
    }

    changeNameValue({ shpProValue: value });
  }, [state.shopProductsList, state.shpProValue]);

  const addShpProdFunction = () => {
    let { slctShpPrd, qtycnt } = state;

    if (slctShpPrd && qtycnt) {
      const [_pid, _pnm] = slctShpPrd.split('|');

      let obj = {
        id: _pid,
        nm: _pnm,
        qtycnt: qtycnt,
        fn: state.fn,
        ntt: state.queryNtt,
        _id: state.notifId
      };

      dispatch(notifEditDucks.addShpProStock(obj));
      changeNameValue({ slctShpPrd: '', qtycnt: '', shpProValue: '' });
    }
    else {
      if (!slctShpPrd || !qtycnt) {
        const message = !slctShpPrd && !qtycnt
          ? 'please fill the details!'
          : !slctShpPrd
            ? 'please select product!'
            : 'please enter count!';
        dispatch(toastify({ type: 'error', msg: message }));
      }
    }
  }

  // on-off prdctstk Function
  const toggleShpProdct = (id, ia) => {
    const obj = {
      _id: id,
      irst: ia,
      nid: state.notifId,
      fn: state.fn,
      ntt: state.notiById.ntt
    };
    dispatch(notifEditDucks.changeShpProStatus(obj));
  }

  // delete prdctstk Function
  const deleteShpProdFunction = (id) => {
    if (id) {
      const obj = {
        _id: id,
        fn: state.fn,
        ntt: state.notiById.ntt,
        nid: state.notifId
      };
      dispatch(notifEditDucks.delShpProStock(obj));
    }
    else dispatch(toastify({ type: 'error', msg: 'Please Add condition' }));
  }

  // Display and Data-Source tabs- 3 collapses where opening one closes others 
  const handleTabsCollapse = useCallback((name, value) => {
    setState(previState => { // set all value false
      Object.keys(previState).forEach((key, val) => {
        if (key === 'isFirstTabActive' || key === 'isSecTabActive' ||
          key === 'isThirdTabActive' || key === 'isFourTabActive') {
          previState[key] = false;
        }
      })
      return previState;
    });
    changeNameValue({ [name]: !value });
  }, []);

  // data-source card functions
  const handleSourceChange = (name, value) => {
    let stateObj = { [name]: value };

    if (name === 'dst') {
      let obj = {
        dst: value,
        _id: state.notifId,
        fn: state.fn
      };
      stateObj['dst'] = value;
      dispatch(notifEditDucks.updDST(obj));
    }
    else if (name === 'dsabs') {
      let doc = {
        dsabs: value,
        _id: state.notifId,
        fn: state.fn,
        ntt: state.notiById.ntt
      };
      dispatch(notifEditDucks.updAbsoluteURL(doc));
    }

    changeNameValue(stateObj);
  }

  const addDtscURLFunction = () => {
    if (state.dtscu && state.dtscURL) {
      let obj = {
        dst: state.dtscu,
        ul: state.dtscURL,
        _id: state.notifId,
        fn: state.fn
      };

      if (obj?.dst === '1' || obj?.dst === '4') {
        let isValidUrl = '';
        isValidUrl = obj.ul.split('.');
        if (isValidUrl.length < 2) {
          dispatch(toastify({ type: 'error', msg: 'Please enter valid URL' }));
          return;
        }
      }

      dispatch(notifEditDucks.addDTSCU(obj));
      changeNameValue({ dtscu: '2', dtscURL: '', dtscURLError: false });
    }
    else {
      changeNameValue({ dtscURLError: true });
    }
  }

  const deleteDtscFunction = (id) => {
    if (id) {
      const obj = {
        _id: id,
        nid: state.notifId,
        fn: state.fn
      };
      dispatch(notifEditDucks.deleteDTSCU(obj));
    }
    else dispatch(toastify({ type: 'error', msg: 'Please Add condition' }));
  }

  const getPixelData = () => {
    dispatch(notifEditDucks.getPixelData({ id: state.notifId }));
    changeNameValue({ isCall: false });

    //open side panel to display conversion data
    openCloseModal('pixelPanelOpen', state.pixelPanelOpen, 'open');
  }

  const getIntCount = (ary, typ) => {
    if (state.getCount === false) {
      changeNameValue({ getCount: true });

      let date = new Date(new Date().setDate(new Date().getDate() - 30));
      let obj = {
        ary: ary,
        typ: typ,
        date: moment(new Date(date)).format('YYYY-MM-DD'),
      };
      dispatch(notifEditDucks.getDataCount(obj));
    }
    else {
      if (state.dataCount) {
        if (ary.length > state.dataCount.length && state.getCloseCount === true) {
          changeNameValue({ getCloseCount: false });

          let date = new Date(new Date().setDate(new Date().getDate() - 30));
          let obj = {
            ary: ary,
            typ: typ,
            date: moment(new Date(date)).format('YYYY-MM-DD'),
          };
          dispatch(notifEditDucks.getDataCount(obj));
        }
      }
    }
  }

  const handleAddFilterConditions = () => {
    let _id = document.getElementById(('disSelectErr')); //add error on epmty popover

    if ((state.fld !== '') && (state?.fcnd !== '3' ? state.ov !== '' : true)) {
      if (_id) _id.classList.remove('popErr');
      changeNameValue({ fldError: false, ovError: false });

      let obj = {
        name: 'advfcnd',
        fld: state.fld,
        fcnd: state.fcnd,
        ...(state?.fcnd !== '3' && { ov: state.ov }),
        scnd: state.scnd,
        nid: state.notifId
      };

      dispatch(notifEditDucks.updTargetToggle(obj));

      let stateObj = {
        fld: '',
        fcnd: '1',
        ov: '',
        scnd: '1',
        fldError: false,
        fcndError: false,
        scndError: false
      }
      changeNameValue(stateObj);
    }
    else {
      if (state.fld === '' && _id) _id.classList.add('popErr');
      if (state?.fcnd !== '3' && state.ov === '') changeNameValue({ ovError: true });
    }
  }

  //add new coupon code for lottery widget
  const addCouponcode = (name, key, value) => {
    let currentCNF = { ...state.cnf };
    let currentState = currentCNF?.arrlcyltry && [...currentCNF?.arrlcyltry.map(item => ({ ...item }))];
    currentState[key][name] = value;

    if (name === 'cpnpsblt') {
      let _cnt = 0;
      let ttlCnt = 100;

      if (currentState.length === 2) {
        currentState[0].cpnpsblt = ttlCnt - (value > 100 ? value.substring(0, 2) : value);
      }
      else {
        for (let i = 0; i < currentState.length; i++) {
          if (key != i && i !== 0) {
            ttlCnt = ttlCnt - currentState[i].cpnpsblt;
          }
        }
        _cnt = ttlCnt - (value > 100 ? value.substring(0, 2) : value);
        if (_cnt < 0) {
          currentState[0].cpnpsblt = 0;
          let isCall = false;
          for (let j = 0; j < currentState.length; j++) {
            if (key !== j && j !== 0 && !isCall) {
              isCall = true;
              currentState[j].cpnpsblt = _cnt = parseInt(currentState[j].cpnpsblt) + _cnt;
              if (_cnt < 0) {
                isCall = false;
                currentState[j].cpnpsblt = 0;
              }
            }
          }
        } else {
          currentState[0].cpnpsblt = _cnt;
        }
      }
    }

    currentCNF.arrlcyltry = currentState;
    changeNameValue({ cnf: currentCNF, isEditNotif: true });
  }

  //delete the object in all_in_one array
  const deletefieldinArw = (key, notifType) => {
    let currentCNF = { ...state.cnf };
    let arrlcyArr = currentCNF?.arrlcyltry && [...currentCNF?.arrlcyltry.map(item => ({ ...item }))];
    let ainctArr = currentCNF?.ainct && [...currentCNF?.ainct.map(item => ({ ...item }))];

    if (notifType && notifType === 'lottery' && arrlcyArr) {
      //for increase default chances
      arrlcyArr[0].cpnpsblt += parseInt(arrlcyArr[key]?.cpnpsblt);
      arrlcyArr.splice(key, 1);
    }
    else if (ainctArr) {
      ainctArr.splice(key, 1);
    }

    currentCNF.arrlcyltry = arrlcyArr;
    currentCNF.ainct = ainctArr;
    changeNameValue({ cnf: currentCNF, isEditNotif: true });
  }

  //push the object in all_in_one array
  const AddfieldinArw = () => {
    let currentCNF = { ...state.cnf };

    let arrlcyArr = currentCNF?.arrlcyltry && [...currentCNF?.arrlcyltry.map(item => ({ ...item }))];
    let ainctArr = currentCNF?.ainct && [...currentCNF?.ainct.map(item => ({ ...item }))];

    if (state.queryNtt === 'lottery' && arrlcyArr) {
      let isCodeMatch = false;
      arrlcyArr.filter((x, xkey) => {
        arrlcyArr.filter((y, ykey) => {
          if (xkey !== ykey && x.cpncd.toLowerCase() === y.cpncd.toLowerCase()) {
            isCodeMatch = true;
          }
        });
      });
      if (isCodeMatch) {
        dispatch(toastify({ type: 'error', msg: 'You have entered a duplicate coupon code. Enter a unique coupon code.' }));
      }
      else {
        arrlcyArr.push({
          cpnnm: 'New Code',
          cpncd: 'New_Code',
          cpnpsblt: 0
        });
      }
    }
    else if (ainctArr) {
      for (let i = 0; i < ainctArr.length; i++) { //remove border from all div
        document.getElementById('block_' + i).classList.remove('nf-group-error');
        let obj = ainctArr[i];
        if (obj.ia === true && (obj.ttl === '' || obj.icn === '' || obj.bgc === '')) {
          if (document.getElementById('block_' + i)) {
            document.getElementById('block_' + i).classList.add('nf-group-error');
          }
          return false;
        }
      }
      if (state.queryNtt === 'whatsapp_chat') { //for whats_app_chat notif.
        ainctArr.push({
          ttl: 'Enter title',
          url: '',
          sdsc: 'Short Description',
          ldsc: 'Hi',
          icn: 'https://storage.googleapis.com/wiser_goal/user_notif_img/marketer/avatar.png',
          bgc: '#F5F7F9',
          ia: true
        });
      }
      else if (state.queryNtt === 'offer') { //for whats_app_chat notif.
        ainctArr.push({
          ttl: 'Enter title',
          sdsc: 'Short Description',
          bgc: '#e9ab0d1a',
          ia: true
        });
      }
      else { //for all_in_one notif.
        ainctArr.push({
          ttl: 'Enter title',
          ttlfnsz: '14',
          ttlfnfml: 'Circular Std Book',
          ttlfnclr: '#000',
          ttlfnwght: 'normal',
          url: '',
          icn: 'https://storage.googleapis.com/wiser_goal/notif_icon/icon_user.png',
          bgc: '#F0F8FF',
          ia: true
        });
      }
    }

    currentCNF.arrlcyltry = arrlcyArr;
    currentCNF.ainct = ainctArr;
    changeNameValue({ cnf: currentCNF, isEditNotif: true });
  }

  // add/delete data source
  const handleDataSrc = (id, pid, chkBox) => {
    let arrIntg = [];
    let obj = {
      uid: id,
      _id: state.notifId,
      fn: state.fn,
      ntt: state.notiById.ntt,
      ...(pid && { pid })
    };

    if (chkBox === true) {
      //add in integration array
      arrIntg.push(id);
      dispatch(notifEditDucks.addDataSrc(obj));
    }
    else if (chkBox === false) {
      // Remove from the integration array
      arrIntg = arrIntg.filter(item => item !== id);
      dispatch(notifEditDucks.delDataSrc(obj));
    }

    changeNameValue({ arrIntg });
  }

  const addCaptureUrl = () => {
    if (state.dul) {
      let stateObj = { dulError: false };

      let obj = {
        _id: state.notifId,
        ul: state.dul.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0],
        dst: state.dul,
        fn: state.fn
      };

      if (obj.ul.indexOf('.') === -1) {
        dispatch(toastify({ type: 'error', msg: 'Please enter valid URL' }));
        stateObj['dul'] = '';
        return;
      }

      let { ddn, dscu } = state?.notiById;
      let duplChk = dscu && dscu.filter(x => x.ul === obj.ul);
      if (duplChk.length === 0) {
        let objCap = { ddn: ddn, ul: obj.ul, _id: state?.notifId, fn: state.fn };
        dispatch(notifEditDucks.addCapInc(objCap));
        stateObj['dul'] = '';
      }
      else {
        dispatch(toastify({ type: 'error', msg: 'Already added' }));
        stateObj['dul'] = '';
        return;
      }

      changeNameValue(stateObj);
    }
    else {
      changeNameValue({ dulError: true });
    }
  }

  const deleteCaptureURL = (id, type) => {
    if (id) {
      const obj = {
        _id: id,
        nid: state.notifId,
        fn: state.fn
      };
      if (type === 'inc')
        dispatch(notifEditDucks.delCapInclUrl(obj));
      else if (type === 'exc')
        dispatch(notifEditDucks.delCapExclUrl(obj));
    }
    else dispatch(toastify({ type: 'error', msg: 'Please add url' }));
  }

  const getIntData = (pg, notifTyp, fuid, pgid, intName) => {
    let stateObj = { isCall: false, isDataLoaded: true };

    let pagiCode = 1;
    if (pg === '+1') pagiCode = state.pagiCode + 1;
    else if (pg === '-1') pagiCode = state.pagiCode - 1;

    let obj = {
      page: pagiCode,
      typ: state.queryNtt,
      inm: intName || state.intName,
      ...((state.fuid || fuid) ? { fuid: fuid || state.fuid } : { pgid: pgid || state.pgid })
    };

    let notifTypStt = notifTyp || state.notifTyp;
    if (notifTypStt === 'signup' || notifTypStt === 'purchase') {
      let date = new Date(new Date().setDate(new Date().getDate() - 30));
      obj.date = moment(new Date(date)).format('YYYY-MM-DD');
    }

    stateObj.pagiCode = pagiCode;
    changeNameValue(stateObj);
    dispatch(notifEditDucks.getIntData(obj));
  }

  const deleteSignUpData = () => {
    let dataCount = { ...state.dataCount };
    let array = state.signupData;

    if (state.intDelIndex > -1) {
      array.splice(state.intDelIndex, 1);
    }

    for (let i = 0; i < dataCount.length; i++) {
      const j = { ...dataCount[i] };
      if (state.pgid) {
        if (j.pgid && j.pgid.toString() === state.pgid.toString()) {
          j.count = j.count - 1;
        }
      }
      else {
        if (j.fuid && j.fuid.toString() === state.fuid.toString()) {
          j.count = j.count - 1;
        }
      }
      dataCount[i] = j;
    }

    changeNameValue({ signupData: array, signUpCount: state.signUpCount - 1, dataCount: dataCount });
    dispatch(notifEditDucks.delInteCntData(state.intDelObj));
    openCloseModal('intCntDeleteConfirmOpen', state.intCntDeleteConfirmOpen, 'close');
  }

  return (
    <NotifEditCard
      state={state}
      validator={validator}
      designValidator={designValidator}
      handleOnClickUpdateCnf={handleOnClickUpdateCnf}
      handleCancelCnf={handleCancelCnf}
      changeNameValue={changeNameValue}
      handleBackToMain={handleBackToMain}
      openIconModal={openIconModal}
      openCloseModal={openCloseModal}
      callResetApi={callResetApi}
      copyHTMLSnippet={copyHTMLSnippet}
      handleSettingChange={handleSettingChange}
      handleMainTextChange={handleMainTextChange}
      handleTextChange={handleTextChange}
      sendWebhookData={sendWebhookData}
      addUtmSource={addUtmSource}
      editorBackground={editorBackground}
      deletefieldinArw={deletefieldinArw}
      AddfieldinArw={AddfieldinArw}
      handleDropZone={handleDropZone}
      uploadIconfile={uploadIconfile}
      handleIconChange={handleIconChange}
      deleteMediaIcon={deleteMediaIcon}
      searchIconscout={searchIconscout}
      changeStates={changeStates}
      changeTemp={changeTemp}
      updateTempById={updateTempById}
      activeNotifPopUp={activeNotifPopUp}
      handleArrayChange={handleArrayChange}
      addCustomTag={addCustomTag}
      deleteAbTestNotif={deleteAbTestNotif}
      handleAbTestTab={handleAbTestTab}
      handleCreateAbTestNotif={handleCreateAbTestNotif}
      editorFocus={editorFocus}
      editorBlur={editorBlur}
      handleArrayEditor={handleArrayEditor}

      handleDisplayChange={handleDisplayChange}
      addDTUFunction={addDTUFunction}
      deleteDTUFunction={deleteDTUFunction}
      callAddDomain={callAddDomain}
      deleteDomain={deleteDomain}
      handleTagetAudience={handleTagetAudience}
      removeCountry={removeCountry}
      handleSelectLocation={handleSelectLocation}
      addUTMDisplay={addUTMDisplay}
      handleDeleteTarget={handleDeleteTarget}
      addRefDisplay={addRefDisplay}
      addCoockiDisplay={addCoockiDisplay}
      handleTabsCollapse={handleTabsCollapse}
      ImportDisplay={ImportDisplay}
      callShpProducts={callShpProducts}
      searchShopProducts={searchShopProducts}
      addShpProdFunction={addShpProdFunction}
      toggleShpProdct={toggleShpProdct}
      deleteShpProdFunction={deleteShpProdFunction}

      handleSourceChange={handleSourceChange}
      addDtscURLFunction={addDtscURLFunction}
      deleteDtscFunction={deleteDtscFunction}
      getPixelData={getPixelData}
      getIntCount={getIntCount}
      handleAddFilterConditions={handleAddFilterConditions}
      addCouponcode={addCouponcode}
      handleDataSrc={handleDataSrc}
      getIntData={getIntData}
      deleteSignUpData={deleteSignUpData}
      addCaptureUrl={addCaptureUrl}
      deleteCaptureURL={deleteCaptureURL}
    />
  );
};

export default NotifEdit;
