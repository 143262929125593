import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Card, BlockStack, Thumbnail, IndexTable, InlineStack, Page, Text, InlineError, Banner, Icon, Modal, Divider, PageActions, Popover, OptionList
} from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { facebook_icon } from '../../img';
import { useNavigate } from "react-router-dom";
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';

function InstagramFollowerCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    InstagramFollowerCard: [],
    ia: false,//toggle on/off
    dataId: '',//delete data id
    deleteDataModal: false,//delete modal
    modalC: false,//modal check
    apiD: false,//api condition
    notifListData: "",//show data in modal
    selected: [],//selecte data
    activePopover: null,
    ipid: '',
  });

  //set data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //InstagramFollowerCard data from api
  const getInstagramFollowerData = useSelector(state => state.integration?.userIntData);

  //set data in state 
  useEffect(() => {
    changeNameValueCard({ InstagramFollowerCard: getInstagramFollowerData });
  }, [getInstagramFollowerData]);

  //redirect to the url
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update toggle button
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.InstagramFollowerCard.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ InstagramFollowerCard: updatedList })
  };

  //redirect to facebook
  const redirectFB = () => {
    let objStateFB = {
      uk: props.profile._id,
      ti: props.profile.ti,
      uri: window.location.href.replace(/&/gi, '@'),//window.location.href,
      path: window.location.pathname
    };
    let authURL = 'https://www.facebook.com/v18.0/dialog/oauth?client_id=' + process.env.REACT_APP_FB_APP_ID + '&redirect_uri=' + process.env.REACT_APP_INT_API_URL + '/instagram/oauth&scope=email,manage_pages,pages_show_list,instagram_basic,public_profile&response_type=code&state=' + JSON.stringify(objStateFB);
    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };
  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() })
    }
    else {
      changeNameValueCard({ modalC: false, })
    }
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, apiD: false })
  }

  //open delete modal for sub data
  const handleSubdeleteModal = (id, ipid) => {
    openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
    changeNameValueCard({ dataId: id, ipid: ipid, apiD: true, modalC: false })
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteInstaFollower(id));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //delete sub data api call
  const handleDeleteSubData = () => {
    let obj = {
      _id: info.dataId,
      id: info.ipid
    }
    dispatch(integrationDucks.deleteInstaList(obj));
    openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
  }

  //handle toggle
  const handleToggle = (id) => {
    changeNameValueCard({ activePopover: info.activePopover === id ? null : id });
  };

  //create sub data api call  
  const handleChangeSubData = (id, ipid) => {
    let obj = {
      _id: id,
      ipid: ipid
    };
    dispatch(integrationDucks.linkInstaPage(obj));
    changeNameValueCard({ activePopover: null });
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        {/* create Instagram business data */}
        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {props.checkPermission('integration', 'isadd', props.profile) &&
                <Box paddingBlockEnd={200}><div id='insta-btn' onClick={() => redirectFB()}><Button size='large'>Connect to instagram</Button></div></Box>
              }
            </InlineStack>
            <Card padding={0}>
              <IndexTable
                itemCount={info.InstagramFollowerCard?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'User name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.InstagramFollowerCard && info.InstagramFollowerCard.length > 0 && info.InstagramFollowerCard.map((i, index) => {
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell><span className='text-break amazon-url'>{i?.d?.name}</span></IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={i.ia}
                              onChange={() => handleIsActive(i)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>

                        <IndexTable.Cell>
                          <InlineStack>
                            {i.d?.fbTokenError === '' || i.d?.fbTokenError === undefined ? (
                              <>
                                {
                                  props.checkPermission('integration', 'isdelete', props.profile) &&
                                  <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                    <Banner hideIcon tone='critical'>
                                      <Icon
                                        source={DeleteIcon}
                                        tone="critical"
                                      />
                                    </Banner>
                                  </div>
                                }

                                {
                                  props.checkPermission('integration', 'isadd', props.profile) &&
                                  <Box paddingInline={200}>
                                    < Popover
                                      active={info.activePopover === index}
                                      activator={
                                        <Button onClick={() => {
                                          if (i && i.d && i.d?.page && i.d?.page.length > 0) {
                                            handleToggle(index);
                                          }
                                        }}
                                        >
                                          <InlineStack>
                                            <div className='add-events'>Add list</div>
                                            <Icon
                                              source={ChevronDownIcon}
                                              tone="base"
                                            />
                                          </InlineStack>

                                        </Button>
                                      }
                                      onClose={() => changeNameValueCard({ activePopover: null })}
                                    >
                                      {i && i.d && i.d?.page && i.d?.page.length > 0 &&
                                        i.d?.page.filter(x => !x.ia && x.ipid).length > 0 ? i.d?.page.filter(x => !x.ia && x.ipid).map((obj) => (
                                          <OptionList
                                            onChange={() => handleChangeSubData(i._id, obj.ipid)}
                                            options={[{ value: obj.name, label: obj.name }]}
                                            selected={info.selected}
                                          />
                                        ))
                                        : <span>
                                          No record found
                                        </span>}
                                    </Popover>
                                  </Box>
                                }

                              </>
                            ) : (
                              props.checkPermission('integration', 'isadd', props.profile) &&
                              <Box paddingBlockEnd={200}>
                                <div id='insta-btn' onClick={() => redirectFB()}>
                                  <Button>
                                    <InlineStack align='space-between'>
                                      <Thumbnail
                                        source={facebook_icon}
                                        size='extraSmall'
                                        alt="facebook"
                                      />
                                      <span className='login-text'>Login with Instagram</span>
                                    </InlineStack>
                                  </Button>
                                </div>
                              </Box>
                            )
                            }

                          </InlineStack>
                        </IndexTable.Cell>
                      </IndexTable.Row >

                      {
                        i.d?.fbTokenError && (
                          <IndexTable.Row>
                            <IndexTable.Cell colSpan={5}>
                              <InlineError message={i.d?.fbTokenError} />
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        )
                      }

                      {
                        i && i?.d && i.d?.page && i.d?.page.length > 0 &&
                          i.d?.page.filter(x => x.ia === true).length > 0 ?
                          i.d?.page.filter(x => x.ia === true).map((LIObj, index) => (
                            <IndexTable.Row key={index}>
                              <IndexTable.Cell></IndexTable.Cell>
                              <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                              <IndexTable.Cell>
                                <Text variant="bodyMd">
                                  {LIObj.name}
                                </Text>
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                <Text variant="bodyMd">{LIObj.count}</Text>
                              </IndexTable.Cell>
                              {props.checkPermission('integration', 'isdelete', props.profile) &&
                                <IndexTable.Cell>
                                  <div id="delete" onClick={() => handleSubdeleteModal(i._id, LIObj.ipid)}>
                                    <Banner hideIcon tone="critical">
                                      <Icon source={DeleteIcon} tone="critical" />
                                    </Banner>
                                  </div>
                                </IndexTable.Cell>
                              }
                            </IndexTable.Row>
                          ))
                          : null
                      }
                    </>
                  )
                })}

              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack >


      {/* delete data modal */}
      {
        info.modalC === true ?
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  Notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
          :
          <Modal
            open={info.deleteDataModal}
            title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
            onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
            <Modal.Section>
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => {
                    if (info.apiD) {
                      handleDeleteSubData();
                    } else {
                      handleDeleteCall(info.dataId);
                    }
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                  }
                ]}
              />
            </Modal.Section>
          </Modal>
      }
    </Page >
  )
}

export default InstagramFollowerCard;