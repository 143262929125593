import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const setting = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    getSetting: (state, action) => { },
    getSettingSuccess: (state, action) => {
      return { ...state, getSettingList: action.payload };
    },
    getSettingById: (state, action) => { },
    getSettingByIdSuccess: (state, action) => {
      return { ...state, getSettingByIdData: action.payload }
    },
    updateSetting:(state,action) => { }
  }
});

export const {
  getSetting,
  getSettingSuccess,
  getSettingById,
  getSettingByIdSuccess,
  updateSetting
} = setting.actions;

export default setting.reducer;