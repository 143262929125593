import React, { useEffect, useState } from 'react';
import { ManageAccountCard } from "../../components";
import { checkPermission } from '../../services/permissions';
import { useSelector, useDispatch } from 'react-redux';
import './ManageAccount.css'
import * as ProfileDucks from '../../ducks/profile';
import * as authDucks from '../../ducks/auth';

const ManageAccount = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    selectedMenu: 'general'
  })

  //get profile
  const profile = useSelector(state => state.auth?.profile);

  const changeNameValue = (obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }

  useEffect(() => {
    let obj = {
      pvd: localStorage.getItem('pvd')
    };
    dispatch(ProfileDucks.getQuotaHistory(obj));
    dispatch(authDucks.getProfile());
  }, []);

  return (
    <ManageAccountCard
      state={state}
      profile={profile}
      checkPermission={checkPermission}
      changeNameValue={changeNameValue}
    />
  );
};

export default ManageAccount;
