import React, { useCallback, useEffect, useState } from 'react';
import { ReferEarnCard } from "../../components";
import './ReferEarn.css'
import { useDispatch } from 'react-redux';
import * as referEarnDucks from "../../ducks/referEarn";
import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { toastify } from '../../ducks/toast';
import SimpleReactValidator from 'simple-react-validator';

const ReferEarn = () => {
  const validator = new SimpleReactValidator({ locale: 'en' });
  const Wdlvalidator = new SimpleReactValidator({ locale: 'en' });

  let [state, setState] = useState({
    email: '',
    wdlEmail: '',
    errMessage: {},
    withdrawModal: false
  });

  let changeNameValue = useCallback((obj) => {
    setState((prevstate) => ({ ...prevstate, ...obj }))
  }, [])

  let loading = useSelector((state) => state.loading.isLoading)
  let getAffData = useSelector((state) => state.referEarn.AffData)
  let getProfile = useSelector((state) => state.auth)
  let getStatus = useSelector((state) => state.referEarn.statusData)
  let getWdlRequest = useSelector((state) => state.referEarn)

  let performaneData = getAffData?.Perfomance || {}
  let ReferralData = getAffData?.ReferralData || []
  let profileData = getProfile?.profile || ''
  let wdlreqCount = getWdlRequest?.wdlReqAffData || ''

  let eligible = (performaneData?.eligiblecomi ?? 0) - (wdlreqCount.wdlCount?.paidcomi ?? 0) - (wdlreqCount.wdlCount?.pendingcomi ?? 0);

  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(referEarnDucks.getAffData())
    dispatch(referEarnDucks.getStatus())
    dispatch(referEarnDucks.getWdlRequest())
  }, [])

  useEffect(() => {
    if (profileData) {
      let _afi = profileData.afi || '';
      let script = 'https://wisernotify.com?aff=' + _afi;
      if (window.Froged) {
        window.Froged('set', { referralURL: script });
      }

      if (window._cio) {
        window._cio.identify({
          id: localStorage.getItem('ti'),
          referralURL: script
        });
      }
    }
  }, [])

  let copy_Link = (value) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Your Referl link is copied" }));
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Referral Share', 'copy', value, { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Referral Share', { 'event_action': 'copy', 'event_label': value });
      } catch (error) {
        console.log(error);
      }
    }
    return;
  }

  let sendEmail = (tag) => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    } else {
      changeNameValue({ email: '', errMessage: {} })
      let obj = {
        'afi': profileData.afi,
        'email': state.email,
        'fn': profileData.fn,
        'tag': tag,
        'userMail': profileData ? profileData.e : ''
      }
      dispatch(referEarnDucks.sendAffMail(obj))
    }
  }

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.REACT_APP_FB_APP_ID,
      cookie: true,
      xfbml: true,
      version: process.env.REACT_APP_FACEBOOK_APP_VERSION ? process.env.REACT_APP_FACEBOOK_APP_VERSION : 'v5.0'
    });
  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', '  facebook-jssdk'));

  let shareReferralLink = (name, url) => {
    let shareUrl = '';
    if (name === 'facebook') {
      window.FB.ui({
        display: 'popup',
        method: 'share',
        quote: 'I’ve found this amazing marketing tool that’s helped me build trust and boost conversions. Wisernotify turns visitor actions into social proof, making it easier for customers to make purchases. Get $10 OFF when you sign up using this link:' + url,
        href: url,
      }, function () { });
    }
    else if (name === 'linkedin') {
      url = url.replace(/=/, '%3D');
      shareUrl = 'https://www.linkedin.com/shareArticle?mini=true&url= Hey, I’ve found this amazing marketing tool that’s helped me build trust and boost conversions. Wisernotify turns visitor actions into social proof, making it easier for customers to make purchases. Get $10 OFF when you sign up using this link:' + url; // '&title=&summary=&source=LinkedIn';
      window.open(shareUrl)
    }
    else if (name === 'twitter') {
      shareUrl = 'https://twitter.com/intent/tweet?via=WiserNotify&text=Hey, I’ve found this amazing marketing tool that’s helped me build trust and boost conversions. Wisernotify turns visitor actions into social proof, making it easier for customers to make purchases. Get $10 OFF when you sign up using this link:' + url;
      window.open(shareUrl);
    }
    else if (name === 'whatsapp') {
      shareUrl = 'https://api.whatsapp.com/send?text=Hey, I’ve found this amazing marketing tool that’s helped me build trust and boost conversions. Wisernotify turns visitor actions into social proof, making it easier for customers to make purchases. Get $10 OFF when you sign up using this link:' + url;
      window.open(shareUrl);
    }
    try {
      let tracker = window.ga.getAll()[0];
      tracker.send('event', 'Referral Share', name, url, { transport: 'beacon' });
      //for GA-4
      window.gtag('event', 'Referral Share', { 'event_action': name, 'event_label': url });
    } catch (error) {
      console.log(error, 'error');
    }
  }

  let Withdrawbtn = () => {
    if (Wdlvalidator.errorMessages.wdlEmail != null) {
      Wdlvalidator.showMessages();
      Wdlvalidator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: Wdlvalidator.errorMessages });
    } else {
      changeNameValue({ errMessage: {} });
      openCloseModal('withdrawModal', state.withdrawModal, 'open');
    }
  };


  let affWdlRequest = () => {
    let obj = {
      wdlemail: state.wdlEmail
    }
    dispatch(referEarnDucks.affWdlRequest(obj))
    openCloseModal('withdrawModal', state.withdrawModal, 'close');
  }

  let afiApply = () => {
    let obj = {
      e: profileData.e
    };
    if (window.Froged) {
      window.Froged('track', 'referral_signup');
    }
    if (window._cio) {
      window._cio.track('referral_signup');
    }
    dispatch(referEarnDucks.afiApply(obj))
  }

  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) changeNameValue({ [name]: !value });
  }, []);

  return (
    <ReferEarnCard
      performaneData={performaneData}
      ReferralData={ReferralData}
      profileData={profileData}
      copy_Link={copy_Link}
      changeNameValue={changeNameValue}
      state={state}
      sendEmail={sendEmail}
      validator={validator}
      shareReferralLink={shareReferralLink}
      getStatus={getStatus}
      eligible={eligible}
      wdlreqCount={wdlreqCount}
      loading={loading}
      afiApply={afiApply}
      affWdlRequest={affWdlRequest}
      Withdrawbtn={Withdrawbtn}
      openCloseModal={openCloseModal}
      Wdlvalidator={Wdlvalidator}
    />
  );
};

export default ReferEarn;
