import React, { useEffect } from 'react';
import { Box, Card, Text, Spinner, Link, useSetIndexFiltersMode, BlockStack, Thumbnail, IndexFilters, Tooltip, EmptySearchResult, LegacyCard, Modal, Badge, IndexTable, InlineStack, Checkbox, Divider, Popover, OptionList, Collapsible, Icon, Button, RadioButton, InlineGrid, TextField } from '@shopify/polaris';
import { ChevronUpIcon, PlusIcon, DeleteIcon, XIcon, ChevronDownIcon, FlagIcon, ChatIcon, PlanIcon, PersonAddIcon, EnvelopeSoftPackIcon, PlayIcon } from '@shopify/polaris-icons';
import { isWhitelable } from '../../services/permissions';
import { useSelector } from 'react-redux';
import { skeleton } from '../../utils/skeleton';
let isWhite = isWhitelable();

const displayRule = [
  { value: '2', label: 'Contains' },
  { value: '1', label: 'Equals' },
  { value: '3', label: 'Regex' },
  { value: '5', label: 'Does not contain' },
  { value: '4', label: 'Does not equal' },
  { value: '6', label: 'Does not regex' }
];

const conditionOne = [
  { value: '2', label: 'Contains' },
  { value: '1', label: 'Equals' },
  { value: '3', label: 'Is Empty' },
];

const conditionTwo = [
  { value: '2', label: 'Hide' },
  { value: '1', label: 'Show' }
];

const DataSourceCard = ({ props }) => {
  const { mode, setMode } = useSetIndexFiltersMode();
  let { state, changeNameValue, handleSourceChange, addDtscURLFunction, deleteDtscFunction, handleTabsCollapse,
    getPixelData, openCloseModal, handleTagetAudience, getIntCount, handleDeleteTarget, handleAddFilterConditions, handleDataSrc, getIntData, deleteSignUpData, addCaptureUrl, deleteCaptureURL } = props;

  let { lstActiveIntg, notiById } = state;


  let newPlaceholder, newLabel;
  if (state.dtscu === '2') {
    newPlaceholder = 'E.g. about_us ';
    newLabel = 'Enter a keyword to start capturing form submission data URL(s) containing this keyword';
  }
  if (state.dtscu === '1') {
    newPlaceholder = 'E.g. https://mydomain.com/about_us/';
    newLabel = 'Enter URL to start capturing form submission data from the same URL';
  }
  if (state.dtscu === '3') {
    newPlaceholder = 'E.g. https://mydomain.com/*  any path written instead of “*” will be accepted';
    newLabel = 'Enter pattern to start capturing form submission data from URL(s) containing this pattern';
  }
  if (state.dtscu === '5') {
    newPlaceholder = 'E.g. about_us';
    newLabel = 'Enter a keyword to stop capturing form submission data from URL(s) containing this keyword';
  }
  if (state.dtscu === '4') {
    newPlaceholder = 'E.g. https://mydomain.com/about_us/';
    newLabel = 'Enter URL to stop capturing form submission data from the same URL';
  }
  if (state.dtscu === '6') {
    newPlaceholder = 'E.g. https://mydomain.com/*  any path written instead of “*” will not be accepted';
    newLabel = 'Enter pattern to stop capturing form submission data from URL(s) containing this pattern';
  }

  let arryItemInt = [];
  let arrDataInt = [];
  let typ = '';
  let msgNo = 0;
  let arrDataIntCount = [];
  let intCountCopy = [];
  let arrData = [];

  if (['signup_recent', 'signup_bulk'].indexOf(notiById?.ntt) > -1) { typ = 'signup'; }
  else if (['purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet'].indexOf(notiById?.ntt) > -1) { typ = 'purchase'; }
  else if (['review_bulk'].indexOf(notiById?.ntt) > -1) { typ = 'review_bulk'; }
  else if (['review_recent'].indexOf(notiById?.ntt) > -1) { typ = 'review_recent'; }
  else if (['channel_subscriber'].indexOf(notiById?.ntt) > -1) { typ = 'channel_subscriber'; }
  else if (['video_subscriber'].indexOf(notiById?.ntt) > -1) { typ = 'video_subscriber'; }
  else if (['review_feed'].indexOf(notiById?.ntt) > -1) { typ = 'review_feed'; }
  else if (['social_media_post'].indexOf(notiById?.ntt) > -1) { typ = 'social_media_post'; }
  else if (['social_media_count'].indexOf(notiById?.ntt) > -1) { typ = 'social_media_count'; }

  function getNotificationType(ntt) {
    switch (ntt) {
      case 'signup_recent':
      case 'signup_bulk':
        return 'signup';
      case 'purchase_recent':
      case 'purchase_bulk':
      case 'purchase_recent_ecomm_snippet':
      case 'purchase_bulk_ecomm_snippet':
        return 'purchase';
      // Add other cases as needed
      default:
        return ntt;
    }
  }
  typ = getNotificationType(state.queryNtt);


  lstActiveIntg?.map((val) => {
    if (val.typ.indexOf(typ) > -1) arrDataIntCount.push(val);
  });
  arrDataIntCount = arrDataIntCount || [];










  // const typ1 = getNotificationType(notiById?.ntt);

  // Memoized data transformation for performance
  // const arrDataInt1 = useMemo(() => {
  //   if (!lstActiveIntg?.length) return [];

  //   const dataWithCount = lstActiveIntg.map(data => {
  //     const isDisable = !data.typ.includes(typ);
  //     const countData = data.items.map(item => ({
  //       ...item,
  //       count: calculateCount(item, state.dataCount),
  //       isActiveTest: checkIfActive(item, state.arrDataSource),
  //     }));

  //     return { ...data, items: countData, isDisable };
  //   });

  //   return dataWithCount;
  // }, [lstActiveIntg, typ, state.dataCount, state.arrDataSource]);
  // const arrDataInt1 = '';



  // function getNotificationType(ntt) {
  //   switch (ntt) {
  //     case 'signup_recent':
  //     case 'signup_bulk':
  //       return 'signup';
  //     case 'purchase_recent':
  //     case 'purchase_bulk':
  //     case 'purchase_recent_ecomm_snippet':
  //     case 'purchase_bulk_ecomm_snippet':
  //       return 'purchase';
  //     // Add other cases as needed
  //     default:
  //       return ntt;
  //   }
  // }


  // function calculateCount(item) {
  //   let { dataCount } = state;
  //   let relevantCount = '';

  //   if (dataCount?.length > 0) {
  //     for (let k = 0; k < dataCount.length; k++) {
  //       const d = dataCount[k];

  //       if (item.inm === 'facebook' && item.d.fb_page) {
  //         item.d.fb_page = item.d.fb_page.map(q => {
  //           if (q.id === d.pgid) relevantCount = d.count
  //         });
  //       }
  //       else if (item.inm === 'instagrampost' && item.d.fb_page) {
  //         item.d.fb_page = item.d.fb_page.map(q => {
  //           if (q.instagram_business_account.id === d.pgid) relevantCount = d.count
  //         });
  //       }
  //       else if (item.inm === 'facebooklead' && item.d.lead_forms) {
  //         item.d.lead_forms = item.d.lead_forms.map(q => {
  //           if (q.id === d.pgid) relevantCount = d.count
  //         });
  //       }
  //       else if (item.inm === 'googlemybusiness') {
  //         // item.d.lc = item.d.lc.map(g => {
  //         //   if (g.name === d.pgid) relevantCount = d.count
  //         // });
  //       }
  //       else if (item.inm === 'aweber' && item.d.aweberList) {
  //         item.d.aweberList = item.d.aweberList.map(g => {
  //           if (g.id === d.pgid) relevantCount = d.count
  //         });
  //       }
  //       else if (d.fuid === item._id) {
  //         relevantCount = d.count;
  //       }
  //     }
  //   }

  //   // const relevantCount = dataCount.find(dc => dc.fuid === item._id);
  //   return relevantCount ? relevantCount.count : item.count;
  // }

  // function checkIfActive(item, arrDataSource) {
  //   return arrDataSource.some(data => data.uid === item._id && data.pid === item.id);
  // }





  // let newArr = lstActiveIntg && lstActiveIntg.flatMap(item => {
  //   const { items, ...rest } = item;
  //   return items.map(subItem => ({
  //     ...rest,
  //     items: [subItem]
  //   }));
  // });


  // add isDisable true or not
  if (lstActiveIntg?.length > 0) {
    arrData = lstActiveIntg.map(val => {
      let newVal = { ...val };
      newVal['typAr'] = [];

      if (newVal?.typ?.length > 0) {
        let typArrStr = '';
        for (let i = 0; i < newVal.typ.length; i++) {
          const element = newVal.typ[i];
          if (['signup', 'signup_recent', 'signup_bulk'].indexOf(element) > -1) { typArrStr = 'lead'; }
          else if (['purchase', 'purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet'].indexOf(element) > -1) { typArrStr = 'sales'; }
          else if (['review', 'review_bulk', 'review_recent', 'review_feed'].indexOf(element) > -1) { typArrStr = 'review'; }
          else if (['channel_subscriber', 'video_subscriber'].indexOf(element) > -1) { typArrStr = 'video'; }
          else if (['social_media_post'].indexOf(element) > -1) { typArrStr = 'social_media_post'; }
          else if (['social_media_count'].indexOf(element) > -1) { typArrStr = 'social_media_count'; }
          newVal['typAr'].push(typArrStr);
        }
        newVal['typAr'] = [...new Set(newVal['typAr'])];
      }

      if (newVal.typ.indexOf(typ) > -1) newVal['isDisable'] = false;
      else newVal['isDisable'] = true;

      return newVal;
    });

    intCountCopy = arrData;
  }

  useEffect(() => {
    changeNameValue({ dataCount: (state.dataCount && state.getCount === true) ? state.dataCount : [] });
  }, [state.dataCount, state.getCount]);


  // add count to integration
  for (let i = 0; i < arrDataIntCount?.length; i++) {
    let a = JSON.parse(JSON.stringify(arrDataIntCount[i]));

    for (let j = 0; j < a.items.length; j++) {
      let l = a.items[j];

      if (state.dataCount?.length > 0) {
        for (let k = 0; k < state.dataCount.length; k++) {
          const d = state.dataCount[k];

          if (l.inm === 'facebook' && l.d.fb_page) {
            l.d.fb_page = l.d.fb_page.map(q => (q.id === d.pgid) ? { ...q, count: d.count } : q
            );
            a.items[j] = l;
          }
          else if (l.inm === 'instagrampost' && l.d.fb_page) {
            l.d.fb_page = l.d.fb_page.map(q =>
              (q.instagram_business_account.id === d.pgid) ? { ...q, count: d.count } : q
            );
            a.items[j] = l;
          }
          else if (l.inm === 'facebooklead' && l.d.lead_forms) {
            l.d.lead_forms = l.d.lead_forms.map(q =>
              (q.id === d.pgid) ? { ...q, count: d.count } : q
            );
            a.items[j] = l;
          }
          else if (l.inm === 'googlemybusiness') {
            l.d.lc = l.d.lc.map(g =>
              (g.name === d.pgid) ? { ...g, count: d.count } : g
            );
            a.items[j] = l;
          }
          else if (l.inm === 'aweber' && l.d.aweberList) {
            l.d.aweberList = l.d.aweberList.map(g =>
              (g.id === d.pgid) ? { ...g, count: d.count } : g
            );
            a.items[j] = l;
          }
          else if (d.fuid === l._id) {
            l = { ...l, count: d.count };
            a.items[j] = l;
          }
        }
      }

      if (l.inm == 'facebook' && l?.d?.fb_page) {
        for (let i = 0; i < l?.d?.fb_page.length; i++) {
          const f = l?.d?.fb_page[i];
          if (f.ia == true) {
            arryItemInt.push({ pgid: f.id });
          }
        }
      }
      else if (l.inm == 'instagrampost' && l?.d?.fb_page) {
        for (let i = 0; i < l?.d?.fb_page.length; i++) {
          const f = l?.d?.fb_page[i];
          if (f.ia == true) {
            arryItemInt.push({ pgid: f.instagram_business_account.id });
          }
        }
      }
      else if (l.inm == 'facebooklead' && l?.d.lead_forms) {
        for (let i = 0; i < l?.d.lead_forms.length; i++) {
          const f = l?.d.lead_forms[i];
          if (f.ia == true) {
            arryItemInt.push({ pgid: f.id });
          }
        }
      }
      else if (l.inm == 'googlemybusiness' && l?.d.lc) {
        for (let i = 0; i < l?.d.lc.length; i++) {
          const g = l?.d.lc[i];
          if (g.ia == true) {
            arryItemInt.push({ pgid: g.name });
          }
        }
      }
      else if (l.inm == 'aweber' && l?.d.aweberList) {
        for (let i = 0; i < l?.d.aweberList.length; i++) {
          const g = l?.d.aweberList[i];
          if (g.ia == true) {
            arryItemInt.push({ pgid: g.id });
          }
        }
      }
      else {
        arryItemInt.push({ fuid: l._id });
      }

    }
    arrDataIntCount[i] = a;

    // add only items to intCountCopy
    let _index = intCountCopy.findIndex(item => item.in === a.in);
    if (_index !== -1) {
      intCountCopy[_index].items = a.items || [];
    }
  }

  // @@@@@ add checked true or not
  for (let i = 0; i < arrDataIntCount?.length; i++) {
    let a = JSON.parse(JSON.stringify(arrDataIntCount[i]));

    for (let j = 0; j < a.items.length; j++) {
      let l = a.items[j];


      if (state?.arrDataSource?.length > 0) {
        if (l.inm === 'facebook' && l.d.fb_page) {
          l.d.fb_page?.length > 0 && l.d.fb_page.map(q => {
            const matchedData = state?.arrDataSource?.find(d => l._id === d.uid && q.id == d.pid);
            if (matchedData) l = { ...l, isActiveTest: true };
          });
          a.items[j] = l;
        }
        else if (l.inm === 'instagrampost' && l.d.fb_page) {
          l.d.fb_page?.length > 0 && l.d.fb_page.map(q => {
            const matchedData = state?.arrDataSource?.find(d => l._id === d.uid && d.pid == q.instagram_business_account.id);
            if (matchedData) l = { ...l, isActiveTest: true };
          });
          a.items[j] = l;
        }
        else if (l.inm === 'facebooklead' && l.d.lead_forms) {
          l.d.lead_forms?.length > 0 && l.d.lead_forms.map(q => {
            const matchedData = state?.arrDataSource?.find(d => l._id.id === d.uid && d.pid == q.id);
            if (matchedData) l = { ...l, isActiveTest: true };
          });
          a.items[j] = { ...l };
        }
        else if (l.inm === 'googlemybusiness' && l.d.lc) {
          l.d.lc = l.d.lc?.length > 0 && l.d.lc.map(q => {
            if (q?.ia === true) {
              const matchedData = state?.arrDataSource?.find(d => d.uid === l._id && d.pid === q?.name);
              if (matchedData) {
                l = { ...l, isActiveTest: true };
                return { ...q, isActiveTest: true };
              }
            }
            return q;
          })
          a.items[j] = { ...l };
        }
        else if (l.inm === 'aweber' && l.d.aweberList) {
          l.d.aweberList?.length > 0 && l.d.aweberList.map(q => {
            const matchedData = state?.arrDataSource?.find(d => l._id === d.uid && d.pid == q.id);
            if (matchedData) l = { ...l, isActiveTest: true };
          });
          a.items[j] = l;
        }
        else if (l._id) {
          const matchedData = state?.arrDataSource?.find(d => d.uid === l._id);
          if (matchedData) l = { ...l, isActiveTest: true };
          a.items[j] = l;
        }
      }
    }
    arrDataIntCount[i] = a;

    // add only items to intCountCopy
    let _index = intCountCopy.findIndex(item => item.in === a.in);
    if (_index !== -1) {
      intCountCopy[_index].items = a.items || [];
    }
  }


  if (arrDataInt?.length > 0) {
    arrDataInt.map((x) => {
      if (x.items.length == 0) { msgNo = msgNo + 1; }
      if (msgNo === arrDataInt?.length) { arrDataInt = []; }
    });
  }

  let _isFirst = true;
  if (_isFirst == true && arryItemInt?.length > 0) {
    getIntCount(arryItemInt, typ);
  }
  else {
    if (arrDataInt?.length !== arryItemInt?.length) {
      getIntCount(arryItemInt, typ);
    }
  }

  // get total count of checked integration
  let availableIntCount = 0;
  if (notiById && intCountCopy?.length > 0) {

    intCountCopy.forEach(val => {
      val.items.forEach(obj => {

        const incrementCount = () => {
          if (val?.isDisable === false) availableIntCount++;
        };
        const isValidPage = page => page.ia === true;

        if (obj.inm === 'facebook' && obj.d.fb_page) {
          obj.d.fb_page.filter(isValidPage).forEach(incrementCount);
        }
        else if (obj.inm === 'facebooklead' && obj.d.lead_forms) {
          obj.d.lead_forms.filter(x => x.ia === true && x.status === 'ACTIVE').forEach(incrementCount);
        }
        else if (obj.inm === 'googlemybusiness' && obj.d.lc) {
          obj.d.lc.filter(isValidPage).forEach(incrementCount);
        }
        else if (obj.inm === 'spotify') {
          let arrObj = [];
          ['playlists', 'artists', 'albums'].forEach(key => {
            obj.d[key]?.forEach(fpl => arrObj.push({ id: fpl.id, name: fpl.name }));
          });
          arrObj.forEach(incrementCount);
        }
        else if (['wordpressreview', 'shopify', 'google', 'eventbrite', 'magento', 'tiktok'].includes(obj.inm)) {
          incrementCount();
        }
        else if (['wordpress', 'nopcommerce', 'opencart'].includes(obj.inm) && !['signup_recent', 'signup_bulk'].includes(notiById?.ntt)) {
          incrementCount();
        }
        else if (obj.inm === 'aweber') {
          obj.d.aweberList.filter(isValidPage).forEach(incrementCount);
        }
        else if (obj.inm === 'wix') {
          // const isValidPurchase = ['purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet'].includes(notiById?.ntt) && obj.d.type !== 'signup';
          // const isValidSignup = ['signup_bulk', 'signup_recent'].includes(notiById?.ntt) && obj.d.type === 'signup';

          // if ((isValidPurchase || isValidSignup)) {
          //   incrementCount();
          // } 

          const validNtt = ['purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet'].includes(notiById?.ntt);
          const conversionNtt = ['signup_bulk', 'signup_recent'].includes(notiById?.ntt);

          if ((validNtt && obj.d.type !== 'signup') || (conversionNtt && obj.d.type === 'signup')) {
            incrementCount();
          }
        }
        else if (obj.inm === 'instagrampost' && obj.d.fb_page) {
          obj.d.fb_page.filter(isValidPage).forEach(incrementCount);
        }
        else if (obj.rmk && (val.t === 0 || obj.inm !== 'twitter')) {
          incrementCount();
        }
        else if ((obj.inm === 'twitter' && notiById?.ntt === 'social_media_post') ||
          (obj.inm === 'twitter_follower' && notiById?.ntt === 'social_media_count')) {
          incrementCount();
        }
        else if (obj.d.page && (obj.inm === 'instagram_follower' || obj.inm === 'linkedin')) {
          obj.d.page.filter(isValidPage).forEach(incrementCount);
        }
        else if (obj.inm === 'facebookgroupfollowers' && obj.d.groups) {
          obj.d.groups.filter(isValidPage).forEach(incrementCount);
        }
        else if (['twitter'].indexOf(obj.inm) === -1 && obj.rmk) {
          incrementCount();
        }
      });
    });
  }



  let advanceObj;
  if (notiById && notiById?.ntt == 'signup_recent') {
    advanceObj = [
      { value: 'fn', label: '{{F_NAME}}' },
      { value: 'ln', label: '{{L_NAME}}' },
      { value: 'un', label: '{{USERNAME}}' },
      { value: 'ct', label: '{{CITY}}' },
      { value: 'st', label: '{{STATE}}' },
      { value: 'cn', label: '{{COUNTRY}}' },
      { value: 'cst1', label: '{{CUSTOM_1}}' },
      { value: 'cst2', label: '{{CUSTOM_2}}' },
      { value: 'cst3', label: '{{CUSTOM_3}}' }
    ];
  }
  if (notiById && (notiById?.ntt == 'purchase_recent' || notiById?.ntt == 'purchase_recent_ecomm_snippet')) {
    advanceObj = [
      { value: 'fn', label: '{{F_NAME}}' },
      { value: 'ln', label: '{{L_NAME}}' },
      { value: 'un', label: '{{USERNAME}}' },
      { value: 'ct', label: '{{CITY}}' },
      { value: 'st', label: '{{STATE}}' },
      { value: 'cn', label: '{{COUNTRY}}' },
      { value: 'pn', label: '{{PRODUCT_NAME}}' },
      { value: 'cst1', label: '{{CUSTOM_1}}' },
      { value: 'cst2', label: '{{CUSTOM_2}}' },
      { value: 'cst3', label: '{{CUSTOM_3}}' }
    ];
  }
  if (notiById && notiById?.ntt == 'review_recent') {
    advanceObj = [
      { value: 'fn', label: '{{F_NAME}}' },
      { value: 'ln', label: '{{L_NAME}}' },
      { value: 'un', label: '{{USERNAME}}' },
      { value: 'ct', label: '{{CITY}}' },
      { value: 'st', label: '{{STATE}}' },
      { value: 'cn', label: '{{COUNTRY}}' },
      { value: 'pn', label: '{{PRODUCT_NAME}}' },
      { value: 'rtxt', label: '{{REVIEW_TEXT}}' },
      { value: 'rtng', label: '{{USER_RATING}}' },
      { value: 'cst1', label: '{{CUSTOM_1}}' },
      { value: 'cst2', label: '{{CUSTOM_2}}' },
      { value: 'cst3', label: '{{CUSTOM_3}}' }
    ];
  }
  if (notiById && notiById?.ntt == 'social_media_post') {
    advanceObj = [
      { value: 'fn', label: '{{F_NAME}}' },
      { value: 'ln', label: '{{L_NAME}}' },
      { value: 'un', label: '{{USERNAME}}' },
      { value: 'ct', label: '{{CITY}}' },
      { value: 'st', label: '{{STATE}}' },
      { value: 'cn', label: '{{COUNTRY}}' },
      { value: 'rtxt', label: '{{REVIEW_TEXT}}' },
      { value: 'cst1', label: '{{CUSTOM_1}}' },
      { value: 'cst2', label: '{{CUSTOM_2}}' },
      { value: 'cst3', label: '{{CUSTOM_3}}' }
    ];
  }

  useEffect(() => {
    let arrDataSource = [];
    // datasource tab integration
    lstActiveIntg?.length > 0 && lstActiveIntg.filter(intg => {
      intg.items.filter(item => {
        state?.notiById?.dsi && state.notiById?.dsi.filter(dsi => {
          if (dsi.uid === item._id) {
            arrDataSource.push({ uid: dsi.uid, pid: dsi.pid });
          }
        });
      });
    });

    changeNameValue({ arrDataSource });
  }, [lstActiveIntg, notiById]);

  const openDataPanel = (obj, typ, rmk, count, pgid) => {
    if (count > 0) {

      changeNameValue({ fuid: '', pgid: '' });

      let stateObj = {
        signupData: [],
        sideIntName: obj.inm.replace('_', ' ') + ' (' + rmk + ')',
        intName: obj.inm,
        notifTyp: typ
      };

      if (pgid) stateObj.pgid = pgid;
      else stateObj.fuid = obj._id;

      changeNameValue({ ...stateObj, isReloadDataCount: true });
      getIntData(1, stateObj.notifTyp, stateObj.fuid, stateObj.pgid, stateObj.intName);
      openCloseModal('inteCountOpen', state.inteCountOpen, 'open');
    }
  }

  //Variable for set which type of notification for integration
  let typNotif = '';
  if (['review_bulk', 'review_feed', 'review_recent'].indexOf(state.queryNtt) > -1) { typNotif = 'review'; }

  let uniqueItems = new Set();

  arrDataInt = [
    ...intCountCopy.filter((item) => {
      const isDuplicate = uniqueItems.has(item.in);
      uniqueItems.add(item.in);
      return !isDuplicate;
    }),
    ...arrDataInt.filter((item) => {
      const isDuplicate = uniqueItems.has(item.in);
      uniqueItems.add(item.in);
      return !isDuplicate;
    }),
  ];

  if (arrDataInt?.length > 0) {
    arrDataInt = arrDataInt.sort((a, b) => {
      return (a.isDisable === true ? 1 : 0) - (b.isDisable === true ? 1 : 0);
    });

    // items split
    arrDataInt = arrDataInt.flatMap(item => {
      const { items, ...rest } = item;
      return items.map(subItem => ({
        ...rest,
        items: [subItem]
      }));
    });

    // sort by checkbox true
    arrDataInt = arrDataInt?.length > 0 && arrDataInt.map(item => {
      const clonedItem = JSON.parse(JSON.stringify(item));
      clonedItem?.items?.length > 0 && clonedItem.items.forEach(subItem => {
        if (subItem.d?.lc?.length > 0) {
          subItem.d.lc = subItem.d.lc.sort((lcA, lcB) => {
            return (lcB.isActiveTest === true) - (lcA.isActiveTest === true);
          });
        }
      });

      return clonedItem;
    });

    arrDataInt = arrDataInt?.length > 0 && arrDataInt.sort((a, b) => {
      const aHasCondition = a.items?.length > 0 && a.items.some(subItem =>
        subItem.isActiveTest === true || (subItem.d?.lc?.length > 0 && subItem.d.lc.some(lcItem => lcItem?.isActiveTest === true))
      );
      const bHasCondition = b.items?.length > 0 && b.items.some(subItem =>
        subItem.isActiveTest === true || (subItem.d?.lc?.length > 0 && subItem.d.lc.some(lcItem => lcItem?.isActiveTest === true))
      );
      return bHasCondition - aHasCondition;
    });
  }

  // arrDataInt = arrDataInt.sort((a, b) => {
  //   const aHasActive = a.items.some(item => item.isActiveTest === true);
  //   const bHasActive = b.items.some(item => item.isActiveTest === true);
  //   return bHasActive - aHasActive;
  // });

  const disableHoverHTMl = (val) => {
    return (
      <div>
        {val?.isDisable === true &&
          <div className='supprt_by'>
            <InlineStack gap={100}>
              Supported notification:
              {val?.typAr?.length > 0 && val.typAr.map((x1, i) => {
                return (
                  <InlineStack gap={100} key={i}>
                    {x1 === 'sales' ? <Icon source={PlanIcon} />
                      : x1 === 'lead' ? <Icon source={FlagIcon} />
                        : x1 === 'review' ? <Icon source={ChatIcon} />
                          : x1 === 'video' ? <Icon source={PlayIcon} />
                            : x1 === 'social_media_count' ? <Icon source={PersonAddIcon} />
                              : x1 === 'social_media_post' ? <Icon source={EnvelopeSoftPackIcon} /> : ''
                    }
                    <Text>{x1}</Text>
                  </InlineStack>
                )
              })}
            </InlineStack>
          </div>}
      </div>
    );
  }

  let trackDataSites = state.arrCaptureUrl?.length > 0 &&
    state.arrCaptureUrl.slice(0, 3).map(item => item.u.ul).join(', ') + (state.arrCaptureUrl.length > 3 ? '..' : '');

  let intRecords = [];
  let intRecordsDsply = '';

  useEffect(() => {
    intRecordsDsply = intRecords.length > 3 ?
      intRecords.slice(0, 3).join(', ') + '..' : intRecords.join(', ');

    if (state.intRecordsDsply !== intRecordsDsply) {
      changeNameValue({ intRecordsDsply });
    }
  }, [intRecords, state.intRecordsDsply]);

  // 1st 3 checked records
  const _dsiArr = notiById?.dsi && Array.from(new Set(notiById?.dsi.map(item => item.iin)));
  let matchedData = [];
  _dsiArr?.length > 0 && _dsiArr.forEach(noti => {
    let match = arrDataInt?.length > 0 && arrDataInt.find(data => data.in === noti);
    if (match) { matchedData.push({ dn: match.dn, img: match.img }); }
  });

  const maxDisplay = 3;
  const displayData = matchedData.slice(0, maxDisplay);
  const hasMore = matchedData.length > maxDisplay;

  // img with text display selected 3 records
  const intDsplyRecords = matchedData?.length === 0 ? <Text tone='subdued'>Select or Add more integration</Text>
    : <InlineStack>
      <InlineStack gap={200}>
        {displayData.map((item, i) => (
          <InlineStack gap={100} key={i} align='center' blockAlign='center'>
            <img className='inteImg' src={item.img} alt={item.dn} />
            {item.dn}{i < displayData.length - 1 ? ', ' : ''}
          </InlineStack>
        ))}
      </InlineStack>
      {hasMore && '..'}
    </InlineStack>;

  // search filter by app name & connection name
  // if (state?.intQuryValue?.length > 1 && arrDataInt?.length > 0) {
  //   const searchTerm = state.intQuryValue.toLowerCase();
  //   const filteredData = arrDataInt.filter(item =>
  //     item.in && item.in.toLowerCase().includes(searchTerm) || // Search by app name
  //     item.rmk && item.rmk.toLowerCase().includes(searchTerm)   // Search by connection name
  //     // Add additional conditions here if needed
  //   );
  //   arrDataInt = filteredData;
  // }

  const getRmkForItem = (item, notiById) => {
    if (item.rmk && item.inm !== 'google') return item.rmk.toLowerCase();

    const extractors = {
      facebook: item => item.d?.fb_page?.length > 0 && item.d.fb_page.filter(x => x?.ia === true).map(fpl => fpl?.name.toLowerCase()).join(' ') || '',

      facebooklead: item => item.d?.lead_forms?.length > 0 && item.d.lead_forms.filter(x => x?.ia === true && x?.status === 'ACTIVE').map(fpl => fpl?.name.toLowerCase()).join(' ') || '',

      googlemybusiness: item => item.d?.lc?.length > 0 && item.d.lc.filter(x => x?.ia === true).map(fpl => fpl?.locationName.toLowerCase()).join(' ') || '',

      spotify: item => {
        let arrObj = [];
        ['playlists', 'artists', 'albums'].forEach(key => {
          item.d?.[key]?.forEach(fpl => arrObj.push(fpl.name.toLowerCase()));
        });
        return arrObj.join(' ') || '';
      },

      wordpressreview: item => item?.d?.name && item.d.name.toLowerCase() || '',

      shopify: item => item?.d?.shop && item.d.shop.toLowerCase() || '',

      google: item => item.d?.pageId ? item.d.pageName.toLowerCase() : '',

      eventbrite: item => item?.d?.id && item.d.id.toLowerCase() || '',

      wordpress: item => (notiById?.ntt !== 'signup_recent' && notiById?.ntt !== 'signup_bulk') ? item?.d?.ht && item.d.ht.toLowerCase() : '',

      nopcommerce: item => (notiById?.ntt !== 'signup_recent' && notiById?.ntt !== 'signup_bulk') ? item?.d?.ht && item.d.ht.toLowerCase() : '',

      opencart: item => (notiById?.ntt !== 'signup_recent' && notiById?.ntt !== 'signup_bulk') ? item?.d?.ht && item.d.ht.toLowerCase() : '',

      magento: item => item?.d?.ht && item.d.ht.toLowerCase() || '',

      aweber: item => item.d.aweberList?.length > 0 && item.d.aweberList.filter(x => x?.ia).map(fpl => fpl?.name.toLowerCase()).join(' ') || '',

      wix: item => (['purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet'].includes(notiById?.ntt) && item.d.type !== 'signup')
        ? item.rmk?.toLowerCase()
        : '',

      instagrampost: item => item?.d?.fb_page?.length > 0 && item.d.fb_page.filter(x => x.ia).map(fpl => fpl.name.toLowerCase()).join(' ') || '',

      instagram_follower: item => item?.d?.page?.length > 0 && item.d.page.filter(x => x.ia).map(fpl => fpl.name.toLowerCase()).join(' ') || '',

      linkedin: item => item?.d?.page?.length > 0 && item.d.page.filter(x => x.ia).map(fpl => fpl.name.toLowerCase()).join(' ') || '',

      tiktok: item => item.d.user.display_name && item.d.user.display_name.toLowerCase() || '',

      facebookgroupfollowers: item => item?.d?.groups?.length > 0 && item.d.groups.filter(x => x.ia).map(fpl => fpl.name.toLowerCase()).join(' ') || '',
    };

    return extractors[item.inm]?.(item) || '';
  };

  if (state?.intQuryValue?.length > 1 && arrDataInt?.length > 0) {
    const searchTerm = state.intQuryValue.toLowerCase();
    let rmk = '';

    const filteredData = arrDataInt?.length > 0 && arrDataInt.filter(item => {
      item.items.forEach((data) => {
        rmk = getRmkForItem(data, notiById); // Dynamically get rmk
      });
      return item.in?.toLowerCase().includes(searchTerm) || rmk.includes(searchTerm);
    });
    arrDataInt = filteredData;
  }

  let loading = useSelector((state) => state.loading.isLoading);
  useEffect(() => {
    if (loading || state.lstActiveIntg?.length <= 0 || state.lstActiveIntg === undefined) {
      changeNameValue({ isReloadIntData: true });
    }
    else changeNameValue({ isReloadIntData: false });
  }, [loading, state.lstActiveIntg])

  useEffect(() => {
    if (loading || state.signUpCount === undefined) {
      changeNameValue({ isReloadDataCount: true });
    }
    else changeNameValue({ isReloadDataCount: false });
  }, [loading, state.signupData])

  const emptyStateMarkup = (
    <EmptySearchResult
      description={'No integration found (required).'}
      withIllustration />
  );

  const emptyStatePixel = (
    <EmptySearchResult
      description={'No form submissions yet. Please wait for a visitor to submit data.'}
      withIllustration />
  );

  function getDeleteIcon() {
    return <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.55945 18C3.04407 18 2.60287 17.8042 2.23586 17.4125C1.86885 17.0208 1.68534 16.55 1.68534 16V3H0.748291V1H5.43356V0H11.0559V1H15.7411V3H14.8041V16C14.8041 16.55 14.6206 17.0208 14.2536 17.4125C13.8866 17.8042 13.4454 18 12.93 18H3.55945ZM12.93 3H3.55945V16H12.93V3ZM5.43356 14H7.30766V5H5.43356V14ZM9.18177 14H11.0559V5H9.18177V14Z' fill='#e22c38' /></svg>;
  }

  return (
    <div>
      <InlineStack direction={'column'} gap={600}>
        <div id='scroll_HgHt'>
          <Card>
            <BlockStack gap={400}>

              {/* auto capture forms */}
              {notiById && ['purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet', 'review_recent', 'review_bulk', 'review_feed', 'channel_subscriber', 'video_subscriber', 'social_media_post', 'social_media_count'].indexOf(notiById?.ntt) == -1 &&
                <Box background='bg-surface-active' borderRadius='300'>
                  <div id='cursorPoint' onClick={() => handleTabsCollapse('isFirstTabActive', state.isFirstTabActive)}>
                    <Box padding={400}>
                      <InlineStack align='space-between' blockAlign='start'>

                        {['recent_view_bulk'].indexOf(state.queryNtt) > -1 ?
                          <InlineStack align='start' direction={'column'} blockAlign='start'>
                            <Tooltip dismissOnMouseOut padding='400' content='Count live visitors from sites added below' width='wide' hasUnderline><Text fontWeight='semibold'>Count live visitor from the sites below</Text></Tooltip>
                            <Text tone='subdued'>{trackDataSites}</Text>
                          </InlineStack>
                          :
                          <InlineStack align='start' direction={'column'}>
                            <Tooltip dismissOnMouseOut padding='400' content='Automatically capture form submissions on your site.' hasUnderline width='wide'><Text fontWeight='semibold'>Auto capture forms</Text></Tooltip>
                            <Text tone='subdued'>
                              {state.dst === '0' ? 'From all pages' : 'When condition matches'}
                            </Text>
                          </InlineStack>
                        }

                        <Box>
                          <Icon source={state.isFirstTabActive ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                        </Box>
                      </InlineStack>
                    </Box>
                  </div>

                  <Collapsible
                    open={state.isFirstTabActive}
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <div><Divider borderColor='border-secondary' />

                      <Box padding={400} paddingBlock={300} id='dataSrceScroll'>
                        {['signup_recent', 'signup_bulk'].indexOf(state.queryNtt) > -1 &&
                          <Box paddingBlockEnd={200}>
                            <InlineStack align='space-between'>
                              <Tooltip dismissOnMouseOut padding='400' content='Our pixel will capture form submission data like Name, IP, location as per the conditions mentioned below' width='wide' hasUnderline><Text>Choose conditions for the pixel to capture the form submission data</Text></Tooltip>
                              <Link onClick={() => getPixelData()}>View captured data</Link>
                            </InlineStack>
                          </Box>
                        }

                        {/* recently viewd count */}
                        {['recent_view_bulk'].indexOf(state.queryNtt) > -1 ?
                          <div>
                            <Box paddingInlineStart={300}>

                              {/* when selected for Add conditions */}
                              <Box paddingBlockEnd={100}>
                                <Tooltip dismissOnMouseOut padding='400' content='Enter website URL from which you want to capture live visitor count data' width='wide' hasUnderline><Text tone='subdued'>Add website URL(s)</Text></Tooltip>
                              </Box>

                              <BlockStack gap={400}>

                                {/* default empty */}
                                <InlineStack gap='300'>
                                  <Box width='80%'>
                                    <TextField
                                      value={state.dul || ''}
                                      autoComplete="off"
                                      placeholder={'Enter Website URL'}
                                      onChange={(e) => handleSourceChange('dul', e)}
                                      error={state.dulError === true || false} />
                                  </Box>
                                  <Button variant='primary' tone='success'
                                    onClick={() => addCaptureUrl()}>Save</Button>
                                </InlineStack>

                                <Box paddingInlineEnd={400}>
                                  <BlockStack gap={150}>

                                    {/* dynamic conditions */}
                                    {state?.arrCaptureUrl?.length > 0 && state.arrCaptureUrl.slice().reverse().map((val, i) => {
                                      return (
                                        <InlineStack key={i} gap={300}>
                                          <Box width='85%' background='bg-fill-tertiary' paddingBlock={'050'} borderRadius='200'>
                                            <Link monochrome target={'_blank'} url={'http://' + val.u.ul}>
                                              <Text id='dis_black'>{val.u.ul}</Text></Link>
                                          </Box>
                                          <Button onClick={() => deleteCaptureURL(val.u._id, val.t)} variant='monochromePlain'>{getDeleteIcon()}</Button>
                                        </InlineStack>
                                      );
                                    })}

                                  </BlockStack>
                                </Box>

                              </BlockStack>
                            </Box>
                          </div>
                          :
                          <div> {/* signup recent & signup bulk */}
                            <BlockStack>
                              <RadioButton
                                label={<Text>From all pages</Text>}
                                id="allPage"
                                name="targetNtf"
                                checked={state.dst === '0'}
                                onChange={() => handleSourceChange('dst', '0')} />
                              <InlineStack blockAlign='center' align='space-between'>
                                <RadioButton
                                  label={<Text>When condition matches</Text>}
                                  id="conditional"
                                  name="targetNtf"
                                  checked={state.dst === '1'}
                                  onChange={() => handleSourceChange('dst', '1')} />
                                <Checkbox
                                  label={<Tooltip dismissOnMouseOut padding='400' content='Turn OFF to ignore query param (e.g. utm_source)' width='wide' hasUnderline><Text tone='subdued'>Use absolute URLs</Text></Tooltip>}
                                  checked={state?.dsabs}
                                  onChange={(e) => handleSourceChange('dsabs', e)}
                                />
                              </InlineStack>
                            </BlockStack>

                            {state.dst === '1' &&
                              <Box paddingInlineStart={700} paddingBlockStart={100} paddingInlineEnd={100}>

                                {/* when selected for Add conditions */}
                                <Box paddingBlock={100} paddingBlockStart={300}>
                                  <InlineStack gap={400}>
                                    <Box width='27%'>
                                      <Text tone='subdued'>Select condition</Text>
                                    </Box>
                                    <Box width='70%'>
                                      <Text tone='subdued'>Enter the desired URL, keyword, or pattern</Text>
                                    </Box>
                                  </InlineStack>
                                </Box>

                                <BlockStack gap={400}>

                                  {/* default empty */}
                                  <InlineStack gap={400}>
                                    <Box width='27%'>
                                      <Popover
                                        fullWidth
                                        active={state.isNtfUrl2}
                                        onClose={() => changeNameValue({ isNtfUrl2: !state.isNtfUrl2 })}
                                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                          onClick={() => changeNameValue({ isNtfUrl2: !state.isNtfUrl2 })}>
                                          <Text fontWeight='medium' variant='bodyMd'>
                                            {displayRule.find(({ value }) => value == state.dtscu)?.label || 'Select'}
                                          </Text>
                                        </Button>}>
                                        <OptionList
                                          options={displayRule}
                                          selected={[state.dtscu].toString()}
                                          onChange={() => changeNameValue({ isNtfUrl2: !state.isNtfUrl2 })}
                                          onFocusOption={(e) => changeNameValue({ 'dtscu': e.toString() })} />
                                      </Popover>
                                    </Box>
                                    <Box width='70%'>
                                      <Tooltip dismissOnMouseOut padding='400' content={newLabel} width='wide'>
                                        <TextField
                                          value={state.dtscURL}
                                          autoComplete="off"
                                          placeholder={newPlaceholder}
                                          onChange={(e) => handleSourceChange('dtscURL', e)}
                                          error={state.dtscURLError === true || false}
                                          connectedRight={<Box paddingInlineStart={200}><Button variant='primary' tone='success' onClick={() => addDtscURLFunction()}> Save </Button>
                                          </Box>} />
                                      </Tooltip>
                                    </Box>
                                  </InlineStack>

                                  <Box paddingInlineEnd={'050'}>
                                    <BlockStack gap={150}>

                                      {/* dynamic conditions */}
                                      {state.dtscuArray?.length > 0 && state.dtscuArray.slice().reverse().map((val, i) => {
                                        return (
                                          <InlineStack key={i} gap={400}>
                                            <Box width='27%'>
                                              <TextField
                                                disabled
                                                id='dis_black'
                                                value={displayRule.find(({ value }) => value == val?.dst)?.label || ''} />
                                            </Box>
                                            <Box width='68%'>
                                              <TextField
                                                disabled
                                                id='dis_black'
                                                value={val.ul}
                                                connectedRight={<Box paddingInlineStart={200}><Button onClick={() => deleteDtscFunction(val._id)} variant='monochromePlain'>{getDeleteIcon()}</Button></Box>} />
                                            </Box>
                                          </InlineStack>
                                        );
                                      })}
                                    </BlockStack>
                                  </Box>

                                </BlockStack>
                              </Box>
                            }
                          </div>
                        }

                      </Box>

                    </div>
                  </Collapsible>
                </Box>
              }

              {/* integrations*/}
              {['live_visitor', 'recent_view_bulk'].indexOf(notiById?.ntt) == -1 &&
                <Box background='bg-surface-active' borderRadius='300'>
                  <div id='cursorPoint' onClick={() => handleTabsCollapse('isSecTabActive', state.isSecTabActive)}>
                    <Box padding={400}>
                      <InlineStack align='space-between'>
                        <InlineStack gap='050' align='start' direction={'column'}>
                          <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content="Select an app to display its data in notifications or add more integrations."><Text fontWeight='semibold'>Your integrations ({notiById?.dsi?.length}/{availableIntCount})</Text></Tooltip>
                          {intDsplyRecords}
                        </InlineStack>
                        <Box>
                          <Icon source={state.isSecTabActive ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                        </Box>
                      </InlineStack>
                    </Box>
                  </div>

                  <Collapsible
                    open={state.isSecTabActive}
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <div className='inteTable'>
                      <Divider borderColor='border-secondary' />

                      <Box padding={400} paddingBlock={300}>
                        <Box paddingBlockEnd={200}>
                          <InlineStack align='space-between'>
                            <Text>Select app to start showing data from below integrations</Text>
                            <Button variant='primary' icon={PlusIcon} onClick={() => openCloseModal('integrateOpen', state.integrateOpen, 'open')}>Add integration</Button>
                          </InlineStack>
                        </Box>

                        <LegacyCard>
                          <Card padding={0}>
                            <IndexFilters
                              queryValue={state.intQuryValue}
                              queryPlaceholder="Search by App or connection name.."
                              onQueryChange={(e) => changeNameValue({ intQuryValue: e })}
                              onQueryClear={() => changeNameValue({ intQuryValue: '' })}
                              cancelAction={{ onAction: () => changeNameValue({ intQuryValue: '' }) }}
                              tabs={[]}
                              canCreateNewView={false}
                              selected={state.selected}
                              onSelect={(e) => changeNameValue({ selected: e })}
                              filters={[]}
                              onClearAll={props.handleFiltersClearAll}
                              mode={mode}
                              setMode={setMode}
                            />
                            <IndexTable
                              selectable={false}
                              itemCount={state.isReloadIntData === true ? 3 : (arrDataInt?.length || 0)}
                              emptyState={emptyStateMarkup}
                              headings={[
                                { title: <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content='Select apps to start showing data into notifications.'><Text variant='headingSm' fontWeight='medium'>Select app</Text></Tooltip> },
                                { title: <Text variant='headingSm' fontWeight='medium'>Connection name</Text> },
                                { title: <Tooltip dismissOnMouseOut padding='400' hasUnderline width='wide' content='No. of data received in last 60 days'><Text variant='headingSm' fontWeight='medium'>Count</Text></Tooltip> }
                              ]}>

                              {state.isReloadIntData ?
                                skeleton(3, 3)
                                :
                                arrDataInt?.length > 0 && arrDataInt.map((val) =>
                                  val.items.map((obj, ik) => {
                                    if (obj.inm === 'facebook' && obj.d.fb_page) {
                                      return (obj.d.fb_page?.length > 0 &&
                                        obj.d.fb_page.filter(x => x.ia === true).map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'fb_' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox
                                                    checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.id, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline>
                                                  <Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                {val.isDisable === true ? <span></span>
                                                  : fpl.count !== '' && fpl.count !== undefined ?
                                                    <Button variant='plain' onClick={() => openDataPanel(obj, typ, fpl.name, fpl.count, fpl.id)}> {fpl.count} </Button>
                                                    : <Spinner size="small" />
                                                }
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        })
                                      )
                                    }
                                    else if (obj.inm === 'facebooklead' && obj.d.lead_forms) {
                                      return (obj.d.lead_forms?.length > 0 &&
                                        obj.d.lead_forms.filter(x => x.ia === true && x.status === 'ACTIVE').map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'fbLead_' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm} checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.id, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline>
                                                  <Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                {val.isDisable === true ? <span></span>
                                                  : fpl.count !== '' && fpl.count !== undefined ?
                                                    <Button variant='plain' onClick={() => openDataPanel(obj, typ, fpl.name, fpl.count, fpl.id)}> {fpl.count} </Button>
                                                    : <Spinner size="small" />
                                                }
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (obj.inm === 'googlemybusiness' && obj.d.lc) {
                                      return (
                                        obj.d.lc.filter(x => x.ia === true).map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'gb_' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm} checked={fpl.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.name, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.locationName} width='wide' hasUnderline><Text id='txt_Width'>{fpl.locationName}</Text></Tooltip>
                                                {fpl.adrs && <><br /><Text id='txt_Width'>{fpl.adrs}</Text></>}
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                {val.isDisable === true ? <span></span>
                                                  : fpl.count !== '' && fpl.count !== undefined ?
                                                    <Button variant='plain' onClick={() => openDataPanel(obj, typ, fpl.name, fpl.count, fpl.id)}> {fpl.count} </Button>
                                                    : <Spinner size="small" />
                                                }
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (obj.inm === 'spotify') {
                                      let arrObj = [];
                                      Object.keys(obj.d).map((key) => {
                                        if (key === 'playlists' || key === 'artists' || key === 'albums') {
                                          obj.d[key].map((fpl) => {
                                            arrObj.push({ id: fpl.id, name: fpl.name });
                                          });
                                        }
                                      });
                                      return (
                                        arrObj?.length > 0 && arrObj.map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'spfy_' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm} checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.id, e)}
                                                    disabled={val.isDisable} />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline><Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <span> - </span>
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (obj.inm === 'wordpressreview') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'wp_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm} checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, obj.d.id, e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.name} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.d.name}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.name, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'shopify') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'shpy_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.shop} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.d.shop}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.shop, obj.count)}> {obj.count} </Button>
                                                :
                                                <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'google' && obj?.d?.pageId) {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'ggl_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, obj._id, e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.pageName} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.d.pageName}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.pageName, obj.count)}> {obj.count} </Button>
                                                :
                                                <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'eventbrite') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'eb_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.id} width='wide' hasUnderline><Text id='txt_Width'>{obj.d.id}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.id, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'wordpress' && notiById?.ntt !== 'signup_recent' && notiById?.ntt !== 'signup_bulk') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'wp2_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.ht} width='wide' hasUnderline><Text id='txt_Width'>{obj.d.ht}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.ht, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'nopcommerce' && notiById?.ntt !== 'signup_recent' && notiById?.ntt !== 'signup_bulk') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'np_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.ht} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.d.ht}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.ht, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'opencart' && notiById?.ntt !== 'signup_recent' && notiById?.ntt !== 'signup_bulk') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'oc_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.ht} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.d.ht}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.ht, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'magento') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'mgt_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.d.ht} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.d.ht}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.d.ht, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'aweber' && obj?.d?.aweberList) {
                                      return (obj.d.aweberList?.length > 0 &&
                                        obj.d.aweberList.filter(x => x.ia === true).map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'awbr' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm}
                                                    checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.id, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline>
                                                  <Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                {val.isDisable === true ? <span></span>
                                                  : fpl.count !== '' && fpl.count !== undefined ?
                                                    <Button variant='plain' onClick={() => openDataPanel(obj, typ, fpl.name, fpl.count, fpl.id)}> {fpl.count} </Button>
                                                    : <Spinner size="small" />
                                                }
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (obj.inm === 'wix') {
                                      if (['purchase_recent', 'purchase_bulk', 'purchase_recent_ecomm_snippet', 'purchase_bulk_ecomm_snippet'].indexOf(notiById?.ntt) !== -1 && obj.d.type !== 'signup') {
                                        return (
                                          <IndexTable.Row
                                            disabled={val.isDisable}
                                            id={ik}
                                            key={'wx_' + ik}
                                            position={ik}>
                                            <IndexTable.Cell>
                                              <div className='IntWraper'>
                                                <Checkbox name={obj.inm}
                                                  checked={obj.isActiveTest || false}
                                                  onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                  disabled={val.isDisable}
                                                />
                                                <Thumbnail size='extraSmall' source={val.img} />
                                                <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                              </div>
                                            </IndexTable.Cell>
                                            <IndexTable.Cell>
                                              <Tooltip dismissOnMouseOut padding='400' content={obj.rmk} width='wide' hasUnderline>
                                                <Text id='txt_Width'>{obj.rmk}</Text></Tooltip>
                                            </IndexTable.Cell>
                                            <IndexTable.Cell>
                                              {val.isDisable === true ? <span></span>
                                                : typ === 'channel_subscriber' || typ === 'video_subscriber' ?
                                                  <span>-</span>
                                                  : obj.count !== '' && obj.count !== undefined ?
                                                    <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.rmk, obj.count)}> {obj.count} </Button>
                                                    : <Spinner size="small" />
                                              }
                                            </IndexTable.Cell>
                                            {disableHoverHTMl(val)}
                                          </IndexTable.Row>
                                        );
                                      }
                                      else if (['signup_bulk', 'signup_recent'].indexOf(notiById?.ntt) !== -1 && obj.d.type === 'signup') {
                                        return (
                                          <IndexTable.Row
                                            disabled={val.isDisable}
                                            id={ik}
                                            key={'wx2_' + ik}
                                            position={ik}>
                                            <IndexTable.Cell>
                                              <div className='IntWraper'>
                                                <Checkbox name={obj.inm}
                                                  checked={obj.isActiveTest || false}
                                                  onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                  disabled={val.isDisable}
                                                />
                                                <Thumbnail size='extraSmall' source={val.img} />
                                                <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                              </div>
                                            </IndexTable.Cell>
                                            <IndexTable.Cell>
                                              <Tooltip dismissOnMouseOut padding='400' content={obj.rmk} width='wide' hasUnderline><Text id='txt_Width'>{obj.rmk}</Text></Tooltip>
                                            </IndexTable.Cell>
                                            <IndexTable.Cell>
                                              {val.isDisable === true ? <span></span>
                                                : typ === 'channel_subscriber' || typ === 'video_subscriber' ?
                                                  <span>-</span>
                                                  : obj.count !== '' && obj.count !== undefined ?
                                                    <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.rmk, obj.count)}> {obj.count} </Button>
                                                    : <Spinner size="small" />
                                              }
                                            </IndexTable.Cell>
                                            {disableHoverHTMl(val)}
                                          </IndexTable.Row>
                                        );
                                      }
                                    }
                                    else if (obj.inm === 'instagrampost' && obj.d.fb_page) {
                                      return (obj.d.fb_page?.length > 0 &&
                                        obj.d.fb_page.filter(x => x.ia === true).map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'insPst_' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm}
                                                    checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl?.instagram_business_account.id, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline>
                                                  <Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                {val.isDisable === true ? <span></span>
                                                  : fpl.count !== '' && fpl.count !== undefined ?
                                                    <Button variant='plain' onClick={() => openDataPanel(obj, typ, fpl.name, fpl.count, fpl?.instagram_business_account.id)}> {fpl.count} </Button>
                                                    : <Spinner size="small" />
                                                }
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (val.t === 0 && obj?.rmk) {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'t0_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.rmk} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.rmk}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.rmk, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'twitter' && notiById?.ntt === 'social_media_post') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'twtrPst_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.rmk} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.rmk}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : obj.count !== '' && obj.count !== undefined ?
                                                <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.rmk, obj.count)}> {obj.count} </Button>
                                                : <Spinner size="small" />}
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'twitter_follower' && notiById?.ntt === 'social_media_post') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'twtrFlwr_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.rmk} width='wide' hasUnderline>
                                              <Text id='txt_Width'>{obj.rmk}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Button variant='plain'>
                                              {val.isDisable === true ? <span></span>
                                                : obj?.d?.count ? obj.d.count : '0'} </Button>
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'instagram_follower' && obj.d.page) {
                                      return (obj.d.page?.length > 0 &&
                                        obj.d.page.filter(x => x.ia === true).map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'instFlw_' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm}
                                                    checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.ipid, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline>
                                                  <Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                {val.isDisable === true ? <span></span>
                                                  : notiById?.ntt === 'social_media_count' ?
                                                    <Button variant='plain'> {fpl.count ? fpl.count : 0} </Button>
                                                    : fpl.count !== '' && fpl.count !== undefined ?
                                                      <Button variant='plain' onClick={() => openDataPanel(obj, typ, fpl.name, fpl.count, fpl.id)}> {fpl.count} </Button>
                                                      : <Spinner size="small" />
                                                }
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (obj.inm === 'linkedin' && obj.d.page) {
                                      return (obj.d.page?.length > 0 &&
                                        obj.d.page.filter(x => x.ia === true).map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={'lnkdn_' + kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm}
                                                    checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.id, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline><Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                {val.isDisable === true ? <span></span>
                                                  : notiById?.ntt === 'social_media_count' ?
                                                    <Button variant='plain'> {fpl.count || 0} </Button>
                                                    : fpl.count !== '' && fpl.count !== undefined ?
                                                      <Button variant='plain'> {fpl.count} </Button>
                                                      : <Spinner size="small" />
                                                }
                                              </IndexTable.Cell>
                                              {disableHoverHTMl(val)}
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (obj.inm === 'tiktok') {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={'tktk_' + ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, obj._id, e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj?.d?.user?.display_name} width='wide' hasUnderline><Text id='txt_Width'>{obj?.d?.user?.display_name || '-'}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Button variant='plain'>
                                              {val.isDisable === true ? <span></span> : obj.d.user.follower_count || 0}
                                            </Button>
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                    else if (obj.inm === 'facebookgroupfollowers' && obj.d.groups) {
                                      return (obj.d.groups?.length > 0 &&
                                        obj.d.groups.filter(x => x.ia === true).map((fpl, kf) => {
                                          return (
                                            <IndexTable.Row
                                              disabled={val.isDisable}
                                              id={kf}
                                              key={kf}
                                              position={kf}>
                                              <IndexTable.Cell>
                                                <div className='IntWraper'>
                                                  <Checkbox name={obj.inm}
                                                    checked={obj.isActiveTest || false}
                                                    onChange={(e) => handleDataSrc(obj._id, fpl.id, e)}
                                                    disabled={val.isDisable}
                                                  />
                                                  <Thumbnail size='extraSmall' source={val.img} />
                                                  <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Tooltip dismissOnMouseOut padding='400' content={fpl.name} width='wide' hasUnderline><Text id='txt_Width'>{fpl.name}</Text></Tooltip>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <Button variant='plain'>
                                                  {val.isDisable === true ? <span></span> : fpl?.member_count || 0} </Button>
                                              </IndexTable.Cell>
                                            </IndexTable.Row>
                                          );
                                        }))
                                    }
                                    else if (['twitter'].indexOf(obj.inm) === -1 && obj?.rmk) {
                                      return (
                                        <IndexTable.Row
                                          disabled={val.isDisable}
                                          id={ik}
                                          key={ik}
                                          position={ik}>
                                          <IndexTable.Cell>
                                            <div className='IntWraper'>
                                              <Checkbox name={obj.inm}
                                                checked={obj.isActiveTest || false}
                                                onChange={(e) => handleDataSrc(obj._id, '', e)}
                                                disabled={val.isDisable}
                                              />
                                              <Thumbnail size='extraSmall' source={val.img} />
                                              <Text fontWeight='medium' as='span' id='spaceWrap'>{val.dn} </Text>
                                            </div>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Tooltip dismissOnMouseOut padding='400' content={obj.rmk} width='wide' hasUnderline><Text id='txt_Width'>{obj.rmk}</Text></Tooltip>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            {val.isDisable === true ? <span></span>
                                              : typ === 'channel_subscriber' || typ === 'video_subscriber' ?
                                                <span>-</span>
                                                : obj.count !== '' && obj.count !== undefined ?
                                                  <Button variant='plain' onClick={() => openDataPanel(obj, typ, obj.rmk, obj.count)}> {obj.count || 0} </Button>
                                                  : <Spinner size="small" />
                                            }
                                          </IndexTable.Cell>
                                          {disableHoverHTMl(val)}
                                        </IndexTable.Row>
                                      );
                                    }
                                  })
                                )}

                            </IndexTable>

                          </Card>
                        </LegacyCard>

                      </Box>

                    </div>
                  </Collapsible>
                </Box>
              }

              {/* data filter */}
              {['signup_recent', 'purchase_recent', 'purchase_recent_ecomm_snippet', 'review_recent', 'social_media_post'].indexOf(notiById?.ntt) !== -1 &&
                <Box background='bg-surface-active' borderRadius='300'>
                  <div id='cursorPoint' onClick={() => handleTabsCollapse('isThirdTabActive', state.isThirdTabActive)}>
                    <Box padding={400}>
                      <InlineStack align='space-between' blockAlign='start'>
                        <InlineStack align='start' direction={'column'}>
                          <Tooltip dismissOnMouseOut padding='400' width='wide' hasUnderline content='This option allows you to control which data you want to show or hide in notification'><Text fontWeight='semibold'>Data filtering options {state?.filterArray?.length > 0 && <Badge tone="success">{state.filterArray.length}</Badge>}</Text></Tooltip>
                          <Text tone='subdued'>Set conditions to filter displayed data effectively.</Text>
                        </InlineStack>
                        <InlineStack gap={300} blockAlign='center'>
                          {(localStorage.getItem('isbusr') === 'false' && isWhite == false) &&
                            <div id='cursorPoint' onClick={() => {
                              openCloseModal('youTubeOpen', state.youTubeOpen, 'open');
                              changeNameValue({ youTubeKey: 'A_yZiTXL2zY' })
                            }}>
                              <Box id='videoLink' background='bg-fill-inverse-active'
                                borderRadius='100' paddingBlock={'050'} paddingInlineStart={100} paddingInlineEnd={200}>
                                <InlineStack blockAlign='center'>
                                  <Icon source={PlayIcon} /><Text variant='bodySm'>2:18</Text>
                                </InlineStack>
                              </Box>
                            </div>
                          }
                          <Icon source={state.isThirdTabActive ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                        </InlineStack>
                      </InlineStack>
                    </Box>
                  </div>

                  <Collapsible
                    open={state.isThirdTabActive}
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <div><Divider borderColor='border-secondary' />

                      <Box paddingInline={400} paddingBlock={300} id='dataSrceScroll'>
                        <Box paddingBlockStart={100} paddingInlineEnd={200}>
                          <Checkbox
                            label={<Text id='txt_Width'>Add conditions to show or hide data in notification</Text>}
                            checked={state?.iadvfcnd}
                            onChange={(e) => handleTagetAudience('iadvfcnd', e)}
                          />

                          {state.iadvfcnd === true &&
                            <Box paddingInlineStart={600} paddingBlockStart={100}>
                              {/* when selected for Add conditions */}
                              <Box paddingBlockEnd={100} paddingBlockStart={100}>
                                <InlineGrid columns={4} gap={300}>
                                  <Text tone='subdued'>If the variable is</Text>
                                  <Text tone='subdued'>And</Text>
                                  <Text tone='subdued'>Value</Text>
                                  <Text tone='subdued'>Then</Text>
                                </InlineGrid>
                              </Box>
                              <BlockStack gap={400}>

                                {/* default empty */}
                                <InlineGrid columns={4} gap={200}>
                                  <Popover
                                    fullWidth
                                    active={state.isDtFilter1}
                                    onClose={() => changeNameValue({ isDtFilter1: !state.isDtFilter1 })}
                                    activator={<Button id='disSelectErr' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isDtFilter1: !state.isDtFilter1 })}>
                                      <Text id='txt_Width' variant='bodyMd'>
                                        {advanceObj?.find(({ value }) => value == state.fld)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={advanceObj}
                                      selected={[state.fld]}
                                      onChange={() => changeNameValue({ isDtFilter1: !state.isDtFilter1 })}
                                      onFocusOption={(e) => handleTagetAudience('fld', e)} />
                                  </Popover>
                                  <Popover
                                    fullWidth
                                    active={state.isDtFilter2}
                                    onClose={() => changeNameValue({ isDtFilter2: !state.isDtFilter2 })}
                                    activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                      onClick={() => changeNameValue({ isDtFilter2: !state.isDtFilter2 })}>
                                      <Text id='txt_Width' variant='bodyMd'>
                                        {conditionOne.find(({ value }) => value == state.fcnd)?.label || 'Select'}
                                      </Text>
                                    </Button>}>
                                    <OptionList
                                      options={conditionOne}
                                      selected={[state.fcnd].toString()}
                                      onChange={() => changeNameValue({ isDtFilter2: !state.isDtFilter2 })}
                                      onFocusOption={(e) => handleTagetAudience('fcnd', e.toString())} />
                                  </Popover>
                                  <TextField
                                    disabled={state?.fcnd === '3'}
                                    value={state.ov}
                                    autoComplete="off"
                                    placeholder={'value'}
                                    onChange={(e) => handleTagetAudience('ov', e)}
                                    error={state.ovError === true || false} />
                                  <InlineStack blockAlign='center' gap={200}>
                                    <Box width='62%'>
                                      <Popover
                                        fullWidth
                                        active={state.isDtFilter3}
                                        onClose={() => changeNameValue({ isDtFilter3: !state.isDtFilter3 })}
                                        activator={<Button id='disSelect' textAlign="left" fullWidth disclosure="select"
                                          onClick={() => changeNameValue({ isDtFilter3: !state.isDtFilter3 })}>
                                          <Text id='txt_Width' variant='bodyMd'>
                                            {conditionTwo.find(({ value }) => value == state.scnd)?.label || 'Select'}
                                          </Text>
                                        </Button>}>
                                        <OptionList
                                          options={conditionTwo}
                                          selected={[state.scnd].toString()}
                                          onChange={() => changeNameValue({ isDtFilter3: !state.isDtFilter3 })}
                                          onFocusOption={(e) => handleTagetAudience('scnd', e.toString())} />
                                      </Popover>
                                    </Box>
                                    <Box width='5%'>
                                      <Button variant='primary' tone='success'
                                        onClick={() => handleAddFilterConditions()}>Save</Button>
                                    </Box>
                                  </InlineStack>
                                </InlineGrid>

                                <BlockStack gap={150}>
                                  {/* dynamic conditions */}
                                  {state.filterArray?.length > 0 && state.filterArray.slice().reverse().map((val, key) => {
                                    return (
                                      <BlockStack key={key} gap={100}>
                                        <InlineGrid columns={4} gap={200}>
                                          <TextField
                                            disabled
                                            id='dis_black'
                                            value={advanceObj.find(({ value }) => value == val.fld)?.label || 'Select'} />
                                          <TextField
                                            disabled
                                            id='dis_black'
                                            value={conditionOne.find(({ value }) => value == val.fcnd)?.label || ''} />
                                          <TextField
                                            disabled
                                            id='dis_black'
                                            value={val.ov || '-'} />
                                          <InlineStack blockAlign='center' gap={200}>
                                            <Box width='62%'>
                                              <TextField
                                                disabled
                                                id='dis_black'
                                                value={conditionTwo.find(({ value }) => value == val.scnd)?.label || ''} />
                                            </Box>
                                            <Box width='5%'>
                                              <Button onClick={() => handleDeleteTarget(val._id, 'advfcnd')} variant='monochromePlain'>{getDeleteIcon()}</Button>
                                            </Box>
                                          </InlineStack>

                                        </InlineGrid>
                                      </BlockStack>
                                    );
                                  })}
                                </BlockStack>

                              </BlockStack>
                            </Box>
                          }
                        </Box>
                      </Box>

                    </div>
                  </Collapsible>
                </Box>
              }

            </BlockStack>
          </Card >
        </div >
      </InlineStack>



      {/* integration count click show data modal */}
      <Modal
        size='large'
        open={state.inteCountOpen}
        title={< Text variant='headingLg' id='textCapital' fontWeight='bold'> {state.sideIntName}</Text >}
        onClose={(e) => state.intCntDeleteConfirmOpen ? changeNameValue()
          : openCloseModal('inteCountOpen', state.inteCountOpen, 'close')
        }>
        <div style={{ maxHeight: "500px" }}>
          <Box paddingInline={400} paddingBlock={400}>
            <IndexTable
              selectable={false}
              itemCount={state.isReloadDataCount === true ? 3 : state.signUpCount != undefined}
              headings={typNotif === 'review' ?
                [{ title: <Text variant="bodyMd" fontWeight="medium" as="span">No.</Text> },
                { title: <Text fontWeight='medium' vari ant='bodyMd'>First name</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Last name</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Rating</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Rating text</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Date</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Action</Text> }]
                :
                [{ title: <Text fontWeight='medium' variant='bodyMd'>No.</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>First name</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Last name</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Purchase</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Product ID</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Phone</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Data capture page</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Location</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Date</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Action</Text> }]}
              pagination={{
                hasPrevious: state.pagiCode > 1 ? true : false,
                hasNext: (state.pagiCode < parseInt(Math.ceil(state.signUpCount / 20)) || 0) ? true : false,
                onPrevious: () => getIntData('-1'),
                onNext: () => getIntData('+1')
              }}>

              {state.isReloadDataCount === true ?
                skeleton(8, 6)
                :
                state?.signupData?.length > 0 && state.signupData.map((obj, i) => {
                  let { ct, cn, st } = obj;
                  let loc = '';
                  if (ct && st && cn) { loc = ct + ',' + ' ' + st + ',' + ' ' + cn; }
                  else if (!ct && st && cn) { loc = st + ',' + ' ' + cn; }
                  else if (ct && !st && cn) { loc = ct + ',' + ' ' + cn; }
                  else if (ct && st && !cn) { loc = ct + ',' + ' ' + st; }
                  else if (!ct && !st && cn) { loc = cn; }
                  else if (ct && !st && !cn) { loc = ct; }
                  else if (!ct && st && !cn) { loc = st; }

                  const newObj = { ...obj, loc };
                  return (
                    <IndexTable.Row
                      id={newObj._id}
                      key={i}
                      position={i}>
                      <IndexTable.Cell>
                        {(i + 1)}
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Text tone="subdued" id='txt_Width'>{newObj.fn || '-'}</Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Text tone="subdued" id='txt_Width'>{newObj.ln || '-'}</Text>
                      </IndexTable.Cell>
                      {typNotif === 'review' &&
                        <>
                          <IndexTable.Cell>
                            <Text tone="subdued" id='txt_Width'>{newObj.rtng || '5'}</Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text tone="subdued" id='txt_Width'>{newObj.rtxt || '-'}</Text>
                          </IndexTable.Cell>
                        </>
                      }
                      {typNotif !== 'review' &&
                        <>
                          <IndexTable.Cell>
                            <Text tone="subdued" id='txt_Width'>{newObj.pn || '-'}</Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text tone="subdued" id='txt_Width'>{newObj.pdid || '-'}</Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text tone="subdued" id='txt_Width'>{newObj.phn || '-'}</Text>
                          </IndexTable.Cell>
                          <IndexTable.Cell>
                            <Tooltip dismissOnMouseOut padding='400' content={newObj.hrf}>
                              <Button variant='plain' url={newObj.hrf} target="_blank">
                                {newObj.hrf ? newObj.newObj.length > 73 ? newObj.newObj.slice(0, 73) + '...' : newObj.newObj : '-'}
                              </Button>
                            </Tooltip>
                          </IndexTable.Cell>
                          <IndexTable.Cell>
                            <Text tone="subdued" id='txt_Width'>{newObj.loc || '-'}</Text>
                          </IndexTable.Cell>
                        </>
                      }
                      <IndexTable.Cell>
                        <Text tone="subdued" id='txt_Width'>{new Date(newObj.insdt).toLocaleString()}</Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Button icon={DeleteIcon} variant='tertiary' tone='critical'
                          onClick={() => {
                            changeNameValue({ intDelObj: { _id: newObj._id, frm: 'notif' }, intDelIndex: i });
                            openCloseModal('intCntDeleteConfirmOpen', state.intCntDeleteConfirmOpen, 'open')
                          }} />
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  );
                })

              }

            </IndexTable>
          </Box>
        </div>
      </Modal >

      {/*  integration count click modal data delete confirmation */}
      < Modal
        size='small'
        open={state.intCntDeleteConfirmOpen}
        title={< Text variant='headingLg' fontWeight='bold' > Confirmation</Text >}
        onClose={(e) => openCloseModal('intCntDeleteConfirmOpen', state.intCntDeleteConfirmOpen, e)}>
        <Box paddingInline={500} paddingBlock={500}>
          <InlineStack direction={'column'} align='space-between'>
            <Box paddingBlockStart={0} paddingBlockEnd={700}>
              <Text fontWeight='medium' tone='subdued' variant='headingMd'>Are you sure want to delete this data?</Text>
            </Box>
            <InlineStack gap={500} align='end' blockAlign='end'>
              <Button variant='' onClick={() => openCloseModal('intCntDeleteConfirmOpen', state.intCntDeleteConfirmOpen, 'close')}><Text variant='headingMd' id='txt_Width'>No</Text></Button>
              <Button variant='primary' tone='critical' onClick={() => deleteSignUpData()}><Text variant='bodyMd' id='txt_Width'>Yes, delete</Text></Button>
            </InlineStack>
          </InlineStack>
        </Box>
      </Modal >

      {/* pixel capture data */}
      <Modal
        size='large'
        open={state.pixelPanelOpen}
        onClose={(e) => openCloseModal('pixelPanelOpen', state.pixelPanelOpen, e)}
        title={< Text variant='headingLg' fontWeight='bold'> Captured Data</Text >}>
        <Modal.Section>
          <div className='capture_table'>
            <IndexTable
              itemCount={state?.pixelData?.length || 0}
              selectable={false}
              emptyState={emptyStatePixel}
              headings={[
                { title: <Text fontWeight='medium' variant='bodyMd'>No.</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>First name</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Last name</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Data capture page</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Location</Text> },
                { title: <Text fontWeight='medium' variant='bodyMd'>Date</Text> }
              ]}>

              {state?.pixelData?.length > 0 && state.pixelData.map((obj, index) => {
                let { ct, cn, st } = obj;
                let loc = '';
                if (ct && st && cn) { loc = ct + ',' + ' ' + st + ',' + ' ' + cn; }
                else if (!ct && st && cn) { loc = st + ',' + ' ' + cn; }
                else if (ct && !st && cn) { loc = ct + ',' + ' ' + cn; }
                else if (ct && st && !cn) { loc = ct + ',' + ' ' + st; }
                const newObj = { ...obj, loc };
                return (
                  <IndexTable.Row
                    id={index}
                    key={index}
                    position={index}>
                    <IndexTable.Cell>
                      <Text>{(index + 1)}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text>{newObj.fn ? newObj.fn : '-'}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text>{newObj.ln ? newObj.ln : '-'}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Tooltip dismissOnMouseOut content={newObj.hrf} padding='400' width='wide'>
                        <Link id='txt_1Line' monochrome target='_blank' url={newObj.hrf}>{newObj.hrf}</Link>
                      </Tooltip>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Tooltip dismissOnMouseOut content={newObj.loc} hasUnderline padding='400' width='wide'>
                        <Text id='txt_limit'>{newObj.loc ? newObj.loc : '-'}</Text>
                      </Tooltip>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text>{new Date(newObj.insdt).toLocaleString()}</Text>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </div>
        </Modal.Section>
      </Modal >
    </div >
  );
}

export default DataSourceCard;