import React, { useCallback, useEffect, useState } from 'react';
import { WhitelabelLoginCard } from "../../components";
import * as whitelabelLogin from '../../ducks/whitelabelLogin';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { useSelector } from 'react-redux';
import './WhitelabelLogin.css';
import { encrypt } from '../../utils/passwordService';

const WhitelabelLogin = () => {
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    e: '',
    pd: '',
    rm: true,
    errMessage: {},
    brand: {},
    isSetHead: false,
    isSetFoot: false
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const brand = useSelector(state => state.whitelabelLogin.brand);

  //set brand data.
  useEffect(() => {
    if (brand && brand._id) {
      changeNameValue({ brand });
    }
  }, [brand])

  useEffect(() => {
    let host = window.location.host;
    // let host = 'https://e983-2401-4900-8899-fec1-a6c9-85aa-8655-3f09.ngrok-free.app'
    host = host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];
    let obj = {
      wurl: host
    };
    dispatch(whitelabelLogin.getBrand(obj));


    //check user have email than login 
    let params = new URLSearchParams(window.location.search);
    const login_email = params.get('an');

    //check localStorage if n(email) login user
    if (login_email && login_email !== undefined) {
      let obj = {
        n: JSON.stringify(login_email)
      };
      dispatch(whitelabelLogin.brandLogin(obj));
    }
  }, []);

  useEffect(() => {
    //load brand script if brand have it
    var link;
    if (brand) {
      if (brand.hscr && state.isSetHead === false) {
        changeNameValue({ isSetHead: true });
        //$('head').append(brand.hscr);
      }
      if (brand.fscr && state.isSetFoot === false) {
        changeNameValue({ isSetFoot: true });
        //$('footer').append(brand.fscr);
      }

      let _favIcn = brand.fvicn ? brand.fvicn : brand.lg ? brand.lg : 'https://storage.googleapis.com/wiserimg/user_img/default_company.png';
      //if (nextProp.brand.fvicn) {
      link = document.createElement('link');
      link.rel = 'icon';
      link.href = _favIcn;
      document.head.appendChild(link);
      localStorage.setItem('fvicn', _favIcn);
      localStorage.setItem('alg', brand.lg ? brand.lg : 'https://storage.googleapis.com/wiserimg/user_img/default_company.png');
      // }
      // else {
      //   link = document.createElement('link');
      //   link.rel = 'icon';
      //   link.href = 'https://storage.googleapis.com/wiserimg/user_img/default_company.png';
      //   document.head.appendChild(link);
      //   localStorage.setItem('fvicn', 'https://storage.googleapis.com/wiserimg/user_img/default_company.png');
      // }
      if (brand.bn) {
        document.title = brand.bn;
      }

    }
  }, [brand, state.isSetHead, state.isSetFoot])

  const handleUserLogin = () => {
    if (!validator.allValid()) {
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      let pass = encrypt(state.pd);
      changeNameValue({ pd: pass, errMessage: {} });
      let obj = {
        e: state.e,
        pd: state.pd,
        uk: brand.uk
      };
      dispatch(whitelabelLogin.brandLogin(obj));
      changeNameValue({ pd: '' });
    }
  }

  return (
    <WhitelabelLoginCard
      state={state}
      validator={validator}
      changeNameValue={changeNameValue}
      handleUserLogin={handleUserLogin}
    />
  );
};

export default WhitelabelLogin;
