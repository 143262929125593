import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, TextField, Tooltip, Banner, Icon, Modal, Divider, PageActions, RadioButton, List, Spinner, Select } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ClipboardIcon, ChannelsIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from 'react-router-dom';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';

function CustomWebformCard(props) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props.props;
  const navigate = useNavigate();

  const [value, setValue] = useState({
    intDataList: [],
    forurl: '',
    sbmcls: '',
    frmclass: '',
    rmk: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    modalReidr: false,
    notifListData: [],
    rbtnTyp: 0,
    isMapModal: false,
    mapId: '',
    dflKeySelect: '',
    rcvKeySelect: '',
    optionrvcKeyVal: [],
    optionKeyword: [],
    isloading: false,
    CWFInterval: '',
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.userIntData);
  let receivedKeyVal = useSelector(state => state.integration.wbffd);
  let findAmPData = value.intDataList && value.intDataList.length > 0 && value?.intDataList?.find((elm) => elm._id == value?.mapId);

  useEffect(() => {
    const arrayOp = [{ label: '-- Select key/value --', value: '' }];
    if (receivedKeyVal?.length > 0) {
      clearInterval(value.CWFInterval);

      receivedKeyVal?.length > 0 && receivedKeyVal?.map((elm) => { arrayOp.push({ label: elm, value: elm }) });
      changeValue({ optionrvcKeyVal: arrayOp, isloading: false });
    }
    else if (value.isMapModal === true) {
      changeValue({ isloading: true })
      getCWFInt();
    }
    changeValue({ intDataList, optionrvcKeyVal: arrayOp });
  }, [intDataList, receivedKeyVal])

  // Active Toggle
  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updIntIsActive(obj))
    const filterData = value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  const handleDeleteData = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data?.length > 0) {
      changeValue({ modalReidr: true, notifListData: data })
    }
    else {
      changeValue({ modalReidr: false })
    }
    openCloseModal('deleteModal', value.deleteModal, 'open');
    changeValue({ dataId: id });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteCustomWebform(id));
    openCloseModal('deleteModal', value.deleteModal, 'close');
  }

  // how to connect 
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  // create btn
  const handleCreateBtn = () => {
    if (value.rbtnTyp == 1 ? props.validator.errorMessages.forurl || props.validator.errorMessages.rmk || props.validator.errorMessages.frmclass || props.validator.errorMessages.sbmcls : props.validator.errorMessages.forurl || props.validator.errorMessages.rmk || props.validator.errorMessages.frmclass || props.validator.errorMessages.sbmcls) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      let newCddn = value.forurl.split('.');
      if (newCddn.length < 2) {
        dispatch(toastify({ type: 'error', msg: "Please enter valid url" }));
        return
      }
      if (value.forurl.indexOf('http://') !== 0 && value.forurl.indexOf('https://') !== 0) {
        dispatch(toastify({ type: 'error', msg: "Please use http:// or https://" }));
        return;
      }
      const obj = {
        forurl: value.forurl,
        frmclass: value.frmclass,
        sbmtyp: value.rbtnTyp,
        sbmcls: value.sbmcls,
        rmk: value.rmk
      };
      dispatch(integrationDucks.createCustomWebform(obj));
      changeValue({ forurl: '', rmk: '', frmclass: '', sbmcls: '', errMessage: {} });
    }
  }

  const handleRadioBtn = (val) => {
    changeValue({ rbtnTyp: val })
  }

  //copy endpoint
  const copyEndpoint = (url) => {
    copy(url, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Endpoint copied" }));
    return;
  }

  //mapping btn click
  const handleMappingModal = (id) => {
    keyWordFilter(id);
    changeValue({ isMapModal: true, mapId: id, dflKeySelect: '', rcvKeySelect: '' });
    dispatch(integrationDucks.getWBFormField(id));
  }

  // delete mapping field
  const handleDeleteMapField = (val) => {
    const obj = {
      fuid: value.mapId,
      key: val
    };
    dispatch(integrationDucks.delCWFKy(obj));
    const oldOption = value.optionKeyword;
    oldOption.push({ label: val, value: val });
    changeValue({ optionKeyword: oldOption });
  }

  // custom String Toggle
  const handleCustomString = (_id, active) => {
    const obj = {
      id: _id,
      irlvurl: active
    }
    dispatch(integrationDucks.updIntIsActive(obj))
    const filterData = value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, d: { ...elm.d, irlvurl: active } };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //Object For WiserNotify Key
  let objk = {
    un: '{{USERNAME}}',
    e: '{{EMAIL}}',
    phn: '{{PHONE}}',
    ct: '{{CITY}}',
    st: '{{STATE}}',
    cn: '{{COUNTRY}}',
    i: '{{IP}}',
    lt: '{{LATITUDE}}',
    lg: '{{LONGITUDE}}',
    pn: '{{PRODUCT_NAME}}',
    pu: '{{PRODUCT_URL}}',
    piu: '{{PRODUCT_IMAGE}}',
    fa: '{{REVIEW_APP}}',
    rtxt: '{{REVIEW_TEXT}}',
    pgn: '{{PAGE_NAME}}',
    pgid: '{{PAGE_ID}}',
    rtng: '{{USER_RATING}}',
    insdt: '{{CAPTURE_DATE}}',
    pdid: '{{PRODUCT_ID}}',
    // rtng: '{{USER_RATING}}',
    // rtxt: '{{REVIEW_TEXT}}',
    // pgn: '{{PAGE_NAME}}',
    // pgid: '{{PAGE_ID}}',
    fn: '{{F_NAME}}',
    ln: '{{L_NAME}}',
    // oid: '{{ORDER_ID}}',
    // yvc: '{{YTB_VIEW_CNT}}',
    // ylc: '{{YTB_LIKE_CNT}}',
    // ydc: '{{YTB_DIS_LIKE_CNT}}',
    // yfc: '{{YTB_FAV_CNT}}',
    // ycc: '{{YTB_CMNT_CNT}}',
    // ysc: '{{YTB_SUB_CNT}}',
    // yvdc: '{{YTB_VIDEO_CNT}}',
    cst1: '{{CUSTOM_1}}',
    cst2: '{{CUSTOM_2}}',
    cst3: '{{CUSTOM_3}}'
  };

  // mapping listed keyword remove 
  const keyWordFilter = (id) => {
    let filterKeyWord = value.intDataList?.find((elm) => elm._id == id);
    filterKeyWord?.amp?.map((value) => {
      const keys = Object.keys(value)[0];
      for (const key1 in objk) {
        if (objk[key1] == keys) {
          delete objk[key1];
        }
      }
    });
    let arrayOp = [];
    for (const key in objk) {
      if (objk.hasOwnProperty(key)) {
        arrayOp.push({ [key]: objk[key] });
      }
    }
    let optionKeyword = [];
    optionKeyword = arrayOp?.map((elm) => { return { label: elm[Object.keys(elm)[0]], value: elm[Object.keys(elm)[0]] } })
    optionKeyword.unshift({ label: '-- Select keyword --', value: '' })
    changeValue({ optionKeyword })
  }

  //mapping Add btn
  const handleAddField = () => {
    if (value.dflKeySelect !== '' && value.rcvKeySelect !== '') {
      const obj = {
        wfkey: value.dflKeySelect,
        cfkey: value.rcvKeySelect,
        fuid: value.mapId
      }
      dispatch(integrationDucks.createCWFKy(obj));
      let optionfilter = value.optionKeyword.filter((elm) => elm.label !== value.dflKeySelect)
      changeValue({ dflKeySelect: '', rcvKeySelect: '', optionKeyword: optionfilter });
    }
  }

  //custom Web Form Interval
  const getCWFInt = () => {
    clearInterval(value.CWFInterval);
    let CWFInterval = setInterval(function () {
      dispatch(integrationDucks.getWBFormField(value.mapId));
    }, 5000);
    changeValue({ CWFInterval })
  }

  //start listening btn
  const handleStartListening = () => {
    getCWFInt();
    dispatch(integrationDucks.updCWFFld({ fuid: value.mapId }));
  }

  const rowMarkupMap = findAmPData && findAmPData.amp.length > 0 && findAmPData?.amp?.map(
    (
      elm,
      index,
    ) => (
      <IndexTable.Row
        id={elm._id}
        key={elm._id}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {index + 1}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{Object.keys(elm)[0]}</IndexTable.Cell>
        <IndexTable.Cell>{elm[Object.keys(elm)[0]]}</IndexTable.Cell>
        <IndexTable.Cell>
          <div id="delete" onClick={() => handleDeleteMapField(Object.keys(elm)[0])}>
            <Banner hideIcon tone='critical'>
              <Icon
                source={DeleteIcon}
                tone="critical"
              />
            </Banner>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => (
      <IndexTable.Row
        id={usInt._id}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {index + 1}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell><BlockStack> <span>{usInt.rmk}</span> <p onClick={() => fnRedirect(usInt.d && usInt.d.formURL)}>
          <Tooltip content={usInt?.d?.formURLl} padding='400' width='wide' dismissOnMouseOut>
            {usInt.d && usInt.d.formURL ? usInt.d.formURL.length > 15 ? usInt.d.formURL.slice(0, 15) + '...' : usInt.d.formURL : '-'}
          </Tooltip></p></BlockStack></IndexTable.Cell>
        <IndexTable.Cell>
          <Tooltip content={usInt.d.frmclass} padding='400' width='wide' dismissOnMouseOut>
            {usInt.d && usInt.d.frmclass ? usInt.d.frmclass.length > 15 ? usInt.d.frmclass.slice(0, 15) + '...' : usInt.d.frmclass : '-'}
          </Tooltip></IndexTable.Cell>
        <IndexTable.Cell>{usInt.d && usInt.d.sbmcls ? usInt.d.sbmcls : '-'}</IndexTable.Cell>
        <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
        <IndexTable.Cell> <label className="switch">
          <input type="checkbox" checked={usInt.d.irlvurl}
            onChange={() => handleCustomString(usInt._id, !usInt.d.irlvurl)} />
          <span className="switch-label"></span>
          <span className="switch-handle"></span>
        </label></IndexTable.Cell>

        <IndexTable.Cell> <label className="switch">
          <input type="checkbox" checked={usInt.ia}
            onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
          <span className="switch-label"></span>
          <span className="switch-handle"></span>
        </label></IndexTable.Cell>

        <IndexTable.Cell>
          <InlineStack blockAlign='center'>
            <Box paddingInline={100}>
              <div id="delete" onClick={() => copyEndpoint(usInt.d.frmclass)}>
                <Banner hideIcon tone='info'>
                  <Icon
                    source={ClipboardIcon}
                    tone="info"
                  />
                </Banner>
              </div>
            </Box>

            <Box paddingInline={100}>
              <div id="delete" onClick={() => handleMappingModal(usInt._id)}>
                <Banner hideIcon tone='success'>
                  <Icon
                    source={ChannelsIcon}
                    tone="success"
                  />
                </Banner>
              </div>
            </Box>

            {props.checkPermission('integration', 'isdelete', props.profile) &&
              <div id="delete" onClick={() => handleDeleteData(usInt._id)}>
                <Banner hideIcon tone='critical'>
                  <Icon
                    source={DeleteIcon}
                    tone="critical"
                  />
                </Banner>
              </div>
            }

          </InlineStack>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to connect</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <BlockStack gap={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.forurl} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">URL</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.forurl}
                        onChange={(e) => changeValue({ forurl: e })}
                        placeholder='URL'
                        autoComplete="off"
                        error={value.errMessage.forurl ? "The URL field is required." : false}
                      />
                      {props.validator.message('forurl', value.forurl, 'required')}
                    </div>
                  </InlineStack>
                </div>
                <Box paddingBlock={300}>
                  <InlineStack gap={300}>
                    <RadioButton
                      label=" Collect data on form submissions"
                      checked={value.rbtnTyp === 0}
                      id="disabled"
                      name="accounts"
                      onChange={() => handleRadioBtn(0)}
                    />
                    <RadioButton
                      label="Collect data on classname clicks"
                      id="optional"
                      name="accounts"
                      checked={value.rbtnTyp === 1}
                      onChange={() => handleRadioBtn(1)}
                    />
                  </InlineStack>
                </Box>

                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.frmclass} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Form class name</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.frmclass}
                        onChange={(e) => changeValue({ frmclass: e })}
                        placeholder='Form class name'
                        autoComplete="off"
                        error={value.errMessage.frmclass ? "The form class name field is required." : false}
                      />
                      {props.validator.message('frmclass', value.frmclass, 'required')}
                    </div>
                  </InlineStack>
                </div>

                {value.rbtnTyp == 1 && <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.sbmcls} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Submit class name</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.sbmcls}
                        onChange={(e) => changeValue({ sbmcls: e })}
                        placeholder='Submit class name'
                        autoComplete="off"
                        error={value.errMessage.sbmcls ? "The submit class name field is required." : false}
                      />
                      {props.validator.message('sbmcls', value.sbmcls, 'required')}
                    </div>
                  </InlineStack>
                </div>}

                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.rmk}
                        onChange={(e) => changeValue({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={value.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', value.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>
              </BlockStack>
              <Box align='center' padding={300}><Button variant="primary" onClick={() => handleCreateBtn()}>Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box><Text variant="headingSm" as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: 'No' },
                  { title: 'Name' },
                  { title: 'Form class name' },
                  { title: 'Submit class name' },
                  { title: 'Created date' },
                  { title: 'Ignore query string' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* Mapping Modal */}
      <Modal
        open={value.isMapModal}
        title={<Text variant='headingMd' fontWeight='bold'>Field mapping</Text>}
        onClose={(e) => { openCloseModal('isMapModal', value.isMapModal, e); clearInterval(value.CWFInterval); }}>
        <Modal.Section>
          <Box paddingBlockEnd={200}>
            <Text variant='headingMd' fontWeight='bold'>Action required:</Text>
            <List type="bullet">
              <List.Item><Text variant="bodyMd" as="p">Before clicking on start listening, make sure you have added a classname in your form tag, then click on start listening.</Text></List.Item>
              <List.Item> <Text variant="bodyMd" as="p">Once WiserNotify system receives first data, after that you need to map those data with WiserNotify keyword.</Text></List.Item>
            </List>
          </Box>
          <Box paddingBlockEnd={400}>
            <Text variant='headingMd' fontWeight='bold'>Status:</Text>
            <InlineStack gap={200} blockAlign='center'>  <Button variant="primary" tone="success" onClick={() => handleStartListening()} disabled={value.isloading === true ? true : false} >Start listening</Button> {value.isloading && <Spinner accessibilityLabel="Small spinner example" size="small" />}</InlineStack>
          </Box>

          <Box paddingBlockEnd={400}>
            <Text variant="headingSm" fontWeight="bold">Mapping setup</Text>
            <InlineStack gap={400} blockAlign={'Stretch'} wrap={false}>
              <Box width='43%'>
                <Select
                  label="Select default keyword"
                  options={value.optionKeyword}
                  onChange={(e) => changeValue({ dflKeySelect: e })}
                  value={value.dflKeySelect}
                />
              </Box>
              <Box width='43%'>
                <Select
                  label="Select received key-value"
                  options={value.optionrvcKeyVal}
                  onChange={(e) => changeValue({ rcvKeySelect: e })}
                  value={value.rcvKeySelect}
                />
              </Box>
              <Box paddingBlockStart={600}>
                <Button variant="primary" size="large" onClick={() => handleAddField()}>Add</Button>
              </Box>
            </InlineStack>
          </Box>
          <Box paddingBlockEnd={400}>
            <Card padding={0}>
              <IndexTable
                itemCount={findAmPData?.amp?.length || 0}
                selectable={false}
                headings={[
                  { title: 'No' },
                  { title: 'Key' },
                  { title: 'Value' },
                  { title: 'Action' }
                ]}
              >
                {rowMarkupMap}
              </IndexTable>
            </Card>
          </Box>
        </Modal.Section>
      </Modal>

      {/* delete data modal */}
      {value.modalReidr === false ?
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                onAction: () => {
                  handleDeleteCall(value.dataId);
                }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal> :
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>This Data-source is being used in the notification.</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">Kindly disable this data-source from <b><i>{value?.notifListData[0]?.data[0]}</i></b> Notification first & after that, you can delete it.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                content: 'Go to notification', tone: 'success',
                onAction: () => { navigate('/notification?ds=list'); openCloseModal('deleteModal', value.deleteModal, 'close'); }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default CustomWebformCard;