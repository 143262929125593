import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, TextField, Tooltip, Banner, Icon, Modal, Divider, PageActions, RadioButton, Select, Tag } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { toastify } from '../../ducks/toast';
import { useNavigate } from 'react-router-dom';

function YoutubeVideoCard(props) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props.props;
  const navigate = useNavigate();

  const [value, setValue] = useState({
    intDataList: [],
    ytps: '',
    ytvir: '',
    rmk: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    selected: [],
    activePopover: null,
    delSubTyp: false,
    delSubTypId: '',
    modalReidr: false,
    notifListData: [],
    vdUse: 'playlist',
    playlist: [],
    playlistSelected: '',
    playListArray: [],
    isUpdate: false,
    selectVideoField: false,
    videolist: [],
    videoAddList: []
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.userIntData);
  let playlistListData = useSelector(state => state.integration?.videoDital);
  let videoListData = useSelector(state => state.integration?.playList);

  useEffect(() => {
    changeValue({ intDataList, playlistListData })
  }, [intDataList, playlistListData])

  useEffect(() => {
    if (playlistListData?.length > 0 && value.selectVideoField === true) {
      let playlist = playlistListData?.map((elm) => { return { label: elm.snippet.title, value: elm.contentDetails.videoId } });
      playlist?.unshift({ label: 'Select video...', value: '' });
      changeValue({ playlist })
    }
  }, [value.selectVideoField, playlistListData])

  useEffect(() => {
    if (videoListData?.vid !== '') {
      let VideoArray = value.videoAddList.length > 0 ? value.videoAddList : [];
      if (VideoArray?.some(item => item?.vid === videoListData?.vid)) {
        dispatch(toastify({ type: 'error', msg: "Video is already Added." }));
        return;
      }
      if (videoListData !== undefined) {
        VideoArray.push(videoListData);
      }
      changeValue({ videoAddList: VideoArray });
    }
  }, [videoListData]);

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updIntIsActive(obj))
    const filterData = value.intDataList && value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  const handleDeleteData = (id, delSub, delSubId) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data?.length > 0) {
      changeValue({ modalReidr: true, notifListData: data })
    }
    else {
      changeValue({ modalReidr: false })
    }
    openCloseModal('deleteModal', value.deleteModal, 'open');
    changeValue({ dataId: id, delSubTyp: delSub, delSubTypId: delSubId });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    if (value.delSubTyp === false) {
      dispatch(integrationDucks.deleteYtVideo(id));
    }
    else {
      dispatch(integrationDucks.deleteYtRecord(value.delSubTypId));
    }
    openCloseModal('deleteModal', value.deleteModal, 'close');
  }

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  const handleCreateBtn = (typ) => {
    if (props.validator.errorMessages.ytps || props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      const listArray = value.playListArray?.map((elm) => { return { title: elm.label, vid: elm.value } });
      if (typ === 'update') {
        const obj = {
          rmk: value.rmk,
          usid: value.dataId,
          ytvi: listArray
        }
        dispatch(integrationDucks.createVideo(obj));
        changeValue({ rmk: '', dataId: '', isUpdate: false, playListArray: [], errMessage: {}, playlistSelected: '', ytps: '', playlist: [], selectVideoField: false })
      }
      if (typ === 'create') {
        const obj = {
          rmk: value.rmk,
          ytvi: listArray
        }
        dispatch(integrationDucks.createVideo(obj));
        changeValue({ rmk: '', isUpdate: false, playListArray: [], dataId: '', errMessage: {}, playlistSelected: '', ytps: '', playlist: [], selectVideoField: false })
      }
    }
  }


  // add playlist btn
  const handleAddPlayList = () => {
    if (value.playlistSelected !== '') {
      if (value.playListArray?.some(item => item.value === value.playlistSelected)) {
        dispatch(toastify({ type: 'error', msg: "Video is already selected." }));
        return;
      }
      const arrayOfList = value.playListArray;
      const data = value.playlist.find((elm) => elm.value === value.playlistSelected);
      arrayOfList.push(data);
      changeValue({ playListArray: arrayOfList })
    }
  }

  // remove playlist tag
  const removePlayListTag = (val) => {
    const filterPlaylistArray = value.playListArray.filter((elm) => elm.value !== val);
    changeValue({ playListArray: filterPlaylistArray })
  }

  // Update list btn
  const handleUpdateData = (id, rmk) => {
    changeValue({ dataId: id, rmk, isUpdate: true });
  }

  // video create btn 
  const handleCreateVideoBtn = (typ) => {
    if (props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      if (typ === 'update') {
        const obj = {
          rmk: value.rmk,
          single: true,
          usid: value.dataId,
          ytvi: value.videoAddList
        }
        dispatch(integrationDucks.createVideo(obj));
        changeValue({ rmk: '', dataId: '', isUpdate: false, ytvir: '', errMessage: {}, videoAddList: [] })
      }
      if (typ === 'create') {
        const obj = {
          rmk: value.rmk,
          single: true,
          ytvi: value.videoAddList
        }
        dispatch(integrationDucks.createVideo(obj));
        changeValue({ rmk: '', ytvir: '', isUpdate: false, errMessage: {}, videoAddList: [] });
      }
    }
  }

  // AddVideoId btn
  const AddVideoId = () => {
    if (props.validator.errorMessages.ytvir) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      dispatch(integrationDucks.checkVideo(value.ytvir));
      changeValue({ ytvir: '' });
    }
  }

  // remove video tag
  const removeVideoListTag = (val) => {
    const filterVideolist = value.videoAddList.filter((elm) => elm.vid !== val);
    changeValue({ videoAddList: filterVideolist });
  }

  //toggle radio btn
  const handleRadioBtn = (val) => {
    changeValue({ vdUse: val, rmk: '', ytps: '', ytvir: '', isUpdate: false });
  }

  const getPlayList = () => {
    if (props.validator.errorMessages.ytps !== null) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      dispatch(integrationDucks.getPlayList(value.ytps));
      changeValue({ selectVideoField: true });
    }
  }

  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => {
      return (<>
        <IndexTable.Row
          id={usInt._id}
          key={index}
          position={index}
        >
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {index + 1}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{usInt.rmk}</IndexTable.Cell>
          <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
          <IndexTable.Cell></IndexTable.Cell>
          <IndexTable.Cell></IndexTable.Cell>
          <IndexTable.Cell></IndexTable.Cell>

          <IndexTable.Cell> <label className="switch">
            <input type="checkbox" checked={usInt.ia}
              onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
            <span className="switch-label"></span>
            <span className="switch-handle"></span>
          </label>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div style={{ display: 'flex', gap: '10px' }}>
              {/* delete btn */}
              {props.checkPermission('integration', 'isdelete', props.profile) &&
                <div id="delete" onClick={() => handleDeleteData(usInt._id, false)}>
                  <Banner hideIcon tone='critical'>
                    <Icon
                      source={DeleteIcon}
                      tone="critical"
                    />
                  </Banner>
                </div>
              }

              {/* Update btn */}
              {props.checkPermission('integration', 'isdelete', props.profile) &&
                <div id="delete" onClick={() => handleUpdateData(usInt._id, usInt.rmk)}>
                  <Banner hideIcon tone='info'>
                    <Icon
                      source={EditIcon}
                      tone="info"
                    />
                  </Banner>
                </div>
              }
            </div>
          </IndexTable.Cell>
        </IndexTable.Row>
        {usInt?.inteData?.length > 0 &&
          <IndexTable.Row >
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="bold" as="span">
                #
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="bold" as="span">Video title</Text>
            </IndexTable.Cell>
            <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">View</Text></IndexTable.Cell>
            <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">Like</Text></IndexTable.Cell>

            <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">Favourite</Text></IndexTable.Cell>

            <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">Comment</Text></IndexTable.Cell>
            <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">Action</Text></IndexTable.Cell>

          </IndexTable.Row>}

        {usInt?.inteData?.map((objTh, i) => (
          <IndexTable.Row >
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell>
              <Text>
                {i + 1}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="h6">{objTh.pgn}</Text>
            </IndexTable.Cell>
            <IndexTable.Cell>{objTh.pgvwcnt}</IndexTable.Cell>
            <IndexTable.Cell>{objTh.pglkcnt}</IndexTable.Cell>
            <IndexTable.Cell>{objTh.pgfvcnt}</IndexTable.Cell>
            <IndexTable.Cell>{objTh.pgcmntcnt}</IndexTable.Cell>
            <IndexTable.Cell>
              {props.checkPermission('integration', 'isdelete', props.profile) &&
                <div id="delete" onClick={() => handleDeleteData(usInt._id, true, objTh._id)}>
                  <Banner hideIcon tone="critical">
                    <Icon source={DeleteIcon} tone="critical" />
                  </Banner>
                </div>
              }
            </IndexTable.Cell>
          </IndexTable.Row >
        ))
        }
      </>
      )
    },
  );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to connect</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <BlockStack gap={200}>
                <Box paddingBlock={300}>
                  <InlineStack gap={300}>
                    <RadioButton
                      label="Add channel by id"
                      checked={value.vdUse === 'playlist'}
                      onChange={() => handleRadioBtn('playlist')}
                    />
                    <RadioButton
                      label="Add channel by username"
                      checked={value.vdUse === 'video'}
                      onChange={() => handleRadioBtn('video')}
                    />
                  </InlineStack>
                </Box>

                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.rmk}
                        onChange={(e) => changeValue({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={value.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', value.rmk, 'required')}
                    </div>
                  </InlineStack>
                </div>

                {value.vdUse === 'playlist' ? <><div className='form-width'>
                  <InlineStack blockAlign="center">
                    <div className='textfeild-lable'>
                      <Tooltip content={state.tl.ytps} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Playlist Id</Text>
                      </Tooltip>
                    </div>
                    <div className='textfeild-box'>
                      <TextField
                        value={value.ytps}
                        onChange={(e) => changeValue({ ytps: e })}
                        placeholder='Playlist Id'
                        autoComplete="off"
                        error={value.errMessage.ytps ? "The playlist Id field is required." : false}
                      />
                      {props.validator.message('ytps', value.ytps, 'required')}
                    </div>
                    <Box paddingInlineStart={200}><Button onClick={() => getPlayList()}>Search</Button></Box>
                  </InlineStack>
                </div>
                  {value.playlist?.length > 0 && value.ytps !== '' ? <>
                    <div className='form-width'>
                      <InlineStack blockAlign="center">
                        <div className='textfeild-lable'>
                          <Tooltip content={state.tl.ytvi} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                            <Text variant="headingSm" as="h6">Select video from below list</Text>
                          </Tooltip>
                        </div>
                        <div className='textfeild-box'>
                          <Select
                            options={value.playlist}
                            onChange={(e) => changeValue({ playlistSelected: e })}
                            value={value.playlistSelected}
                          />
                          {props.validator.message('ytps', value.ytps, 'required')}
                        </div>
                        <Box paddingInlineStart={200}><Button onClick={() => handleAddPlayList()}>Add</Button></Box>
                      </InlineStack>
                    </div>
                    {value.playListArray?.map((option) => (
                      <div style={{ 'paddingLeft': '212px' }}><Box><Tag onRemove={() => removePlayListTag(option.value)}>{option.label}</Tag></Box></div>
                    ))}

                    {value.isUpdate === true && <Box align='center' padding={300}>
                      <InlineStack gap={200} align='center'>
                        <Button variant="primary" tone="success" onClick={() => handleCreateBtn('update')} disabled={value.playListArray.length > 0 ? false : true}>Update</Button>
                        <Button variant="primary" tone="success" onClick={() => changeValue({ isUpdate: false, rmk: '' })}>Cancel</Button>
                      </InlineStack>
                    </Box>
                    }
                  </> : ''
                  }
                </>
                  :
                  <>
                    <div className='form-width'>
                      <InlineStack blockAlign="center">
                        <div className='textfeild-lable'>
                          <Tooltip content={state.tl.ytvir} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                            <Text variant="headingSm" as="h6">Video Id</Text>
                          </Tooltip>
                        </div>
                        <div className='textfeild-box'>
                          <TextField
                            value={value.ytvir}
                            onChange={(e) => changeValue({ ytvir: e })}
                            placeholder='Video Id'
                            autoComplete="off"
                            error={value.errMessage.ytvir ? "The video Id field is required." : false}
                          />
                          {props.validator.message('ytvir', value.ytvir, 'required')}
                        </div>
                        <Box paddingInlineStart={200}><Button onClick={() => AddVideoId()}>Add</Button></Box>
                      </InlineStack>
                    </div>

                    {value.videoAddList?.length > 0 && value.videoAddList?.map((option) => (
                      <div style={{ 'paddingLeft': '212px' }}><Box><Tag onRemove={() => removeVideoListTag(option?.vid)}>{option?.title}</Tag></Box></div>
                    ))}

                    {value.isUpdate === true && <Box align='center' padding={300}>
                      <InlineStack gap={200} align='center'>
                        <Button variant="primary" onClick={() => handleCreateVideoBtn('update')} disabled={value.videoAddList.length > 0 ? false : true}>Update</Button>
                        <Button onClick={() => changeValue({ isUpdate: false, rmk: '' })}>Cancel</Button>
                      </InlineStack>
                    </Box>
                    }
                    {value.videoAddList?.length > 0 && value.isUpdate === false && <Box align='center' padding={300}><Button variant="primary" onClick={() => handleCreateVideoBtn('create')}>Create</Button></Box>}

                  </>}
              </BlockStack>
              {value.playListArray?.length > 0 && value.isUpdate === false && <Box align='center' padding={300}><Button variant="primary" onClick={() => handleCreateBtn('create')}>Create</Button></Box>}

            </>
          }
          <Box padding={400}>
            <Box><Text variant="headingSm" as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: 'No' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: '' },
                  { title: '' },
                  { title: '' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {value.modalReidr === false ?
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                onAction: () => {
                  handleDeleteCall(value.dataId);
                }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal> :
        <Modal
          open={value.deleteModal}
          title={<Text variant='headingMd' fontWeight='bold'>This data-source is being used in the notification.</Text>}
          onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
          <Modal.Section>
            <Box paddingBlockEnd={400}>
              <Text variant="bodyMd" as="p">Kindly disable this data-source from <b><i>{value?.notifListData[0]?.data[0]}</i></b> Notification first & after that, you can delete it.</Text>
            </Box>
            <Divider />
            <PageActions
              primaryAction={{
                content: 'Go to notification', tone: 'success',
                onAction: () => { navigate('/notification?ds=list'); openCloseModal('deleteModal', value.deleteModal, 'close'); }
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
                }
              ]}
            />
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default YoutubeVideoCard;