import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, Icon, PageActions, Banner, Page, Divider, Modal, Text, Tooltip } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import IntegrationHeader from './IntegrationHeader';
import moment from 'moment';

function EnchargeCard(props) {
  const { state, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    encharge: [], //encharge data
    errMessage: {},
    ak: '', //apikey 
    rmk: '', //name your connection
    dataId: '', //id for the delete data modal
    deleteOpen: false, //open delete modal
    modalC: false, //condition for delete modal
    idForUsInt: '',
    isIntActive: false,
    notifListData: '' //show data in modal
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api data.
  const userIntData = useSelector(state => state.integration.userIntData);

  //set encharge data.
  useEffect(() => {
    changeNameValueCard({ encharge: userIntData });
  }, [userIntData]);

  //openCloseModal. 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //open delete modal.
  const handledeleteModal = (id) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() });
    }
    openCloseModal('deleteOpen', info.deleteOpen, 'open');
    changeNameValueCard({ dataId: id });
  }

  //delete api call.
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteEncharge(id));
    openCloseModal('deleteOpen', info.deleteOpen, 'close');
  }

  //redirect to the url.
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect.
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //update api call (toggle button).
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.encharge.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ encharge: updatedList, idForUsInt: obj.id, isIntActive: obj.ia });
  };

  //create Encharge data.
  const createEncharge = () => {
    if (!props.validator.allValid()) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      changeNameValueCard({ errMessage: {} });
      let obj = {
        ak: info.ak,
        rmk: info.rmk,
      };
      dispatch(integrationDucks.createEncharge(obj));
      changeNameValueCard({ ak: '', rmk: '' });
    }
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>Encharge</span></div>} subtitle={'Show customer sign ups'} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={200}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl?.ak} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">API key</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.ak}
                        onChange={(e) => changeNameValueCard({ ak: e })}
                        placeholder='API key'
                        autoComplete="off"
                        error={info.errMessage.ak ? "The API key field is required." : false}
                      />
                      {props.validator.message('ak', info.ak, 'required|required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                <div className='form-width'>
                  <InlineStack blockAlign="center" >
                    <div className="textfeild-lable">
                      <Tooltip content={state.tl?.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="headingSm" as="h6">Name your connection</Text>
                      </Tooltip>
                    </div>
                    <div className="textfeild-box">
                      <TextField
                        type="text"
                        value={info.rmk}
                        onChange={(e) => changeNameValueCard({ rmk: e })}
                        placeholder='Name your connection'
                        autoComplete="off"
                        error={info.errMessage.rmk ? "The name your connection field is required." : false}
                      />
                      {props.validator.message('rmk', info.rmk, 'required|required')}
                    </div>
                  </InlineStack>
                </div>
              </Box>
              <Box align='center' padding={300}><Button onClick={() => createEncharge()} variant="primary">Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.encharge?.length || 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.encharge && info.encharge.length > 0 && info.encharge.map((data, index) => {
                  if (data._id === info.idForUsInt) {
                    data.ia = info.isIntActive ? info.isIntActive : false;
                  }
                  return (
                    <IndexTable.Row key={index}>
                      <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                      <IndexTable.Cell><span className="text-break">{data.rmk}</span></IndexTable.Cell>
                      <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                      <IndexTable.Cell>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data.ia}
                            onChange={() => handleIsActive(data)}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        {props.checkPermission('integration', 'isdelete', props.profile) &&
                          <div id="delete" onClick={() => handledeleteModal(data._id)}>
                            <Banner hideIcon tone='critical'>
                              <Icon
                                source={DeleteIcon}
                                tone="critical"
                              />
                            </Banner>
                          </div>
                        }
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  )
                })}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      {info.modalC === true ?
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>This data-source is being used in the notification.</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
        :
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => handleDeleteCall(info.dataId)
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default EnchargeCard;