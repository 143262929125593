//call backend api
export const base = process.env.REACT_APP_CRON_API_URL;

const unAuthorizedUrl = [];

export const GET = (url) => {
  const authToken = localStorage.getItem('authToken');
  const token = authToken ? authToken : null;

  let headers;
  if (unAuthorizedUrl.indexOf(url.split('?')[0]) >= 0)
    headers = {};
  else
    headers = {
      Authorization: `Bearer ${token}`
    };
    
  return fetch(base + url, {
    method: 'GET',
    headers: headers,
  })
    .then(res => res.json())
    .then(res => res);
};
