import React from 'react';
import { Text, Box, BlockStack, Button, InlineStack, TextField, Card, ResourceList, ResourceItem, Avatar, Banner, List } from '@shopify/polaris';
import { Two_P, g2_icon, captera_icon, trustpilot_icon, wordpress_icon, tweet_icon, shopify_icon, LinkedIn_icon, wix_icon, testimonial_icon, finger_icon } from '../../img';

function RateUsCard(props) {

  const { state, changeNameValue, handleClick } = props;

  //format number
  const formmetNumber = (nu) => {
    return nu ? nu.toLocaleString() : "0";
  };

  return (
    <div>
      <Box background="bg-surface" borderRadius="200" borderColor="border">
        <div className='rateH'>
          <BlockStack>
            <Box padding={400}>
              <Text variant="headingLg">Rate us </Text>
            </Box>
          </BlockStack>
        </div>


        {/* Remaining reward balance */}
        <Box padding={400}>
          <div className='remaining'>
            <InlineStack gap="400" wrap={false} blockAlign="center">
              <Box padding={400}>
                <img src={Two_P} alt='' />
              </Box>
              <Box padding={400}>
                <Box padding={300}>
                  <Text variant="headingMd">
                    Remaining reward balance: {formmetNumber(state.rateC)} visitor quota
                  </Text>
                  <Text variant="bodyMd">
                    Use these balance when you’re running out of visitor quota
                  </Text>
                </Box>
                <Box padding={300}>
                  <TextField
                    placeholder='Enter quota you want to redeem'
                    type="number"
                    value={state.rqa}
                    onChange={(e) => {
                      const value = Number(e); // Convert the input to a number
                      if (value >= 0) {
                        changeNameValue({ rqa: value });
                      }
                    }}
                    autoComplete="off"
                    connectedRight={<Box paddingInlineStart={200}><Button size='large' variant="primary" tone='success' onClick={props.addRewardQuota}>Redeem</Button></Box>}
                  />
                </Box>
              </Box>
            </InlineStack>
          </div>
        </Box>


        {/* How it works  */}
        <Box paddingInline={400} paddingBlockEnd={200}>
          <div className='how'>
            <Box padding={400}>
              <Text variant="bodyLg" fontWeight='semibold'>
                How it works
              </Text>
              <Box className='earn' >
                <Card padding={0}>
                  <Banner hideIcon>
                    <Box>
                      <Text variant='headingSm' fontWeight='medium'>Earn a 5k visitor quota per review</Text>
                    </Box>
                  </Banner>
                </Card>
              </Box>
              <div className='ruleP'>
                <Text variant='headingSm' fontWeight='medium'>
                  You can earn up to <span className='fifty'>50,000</span> in one-time quota increments by completing tasks.
                </Text>
                <div className='numberT'>
                  <List type="number">
                    <List.Item>
                      <Text variant='headingSm' fontWeight='medium' >
                        Capture a screenshot or obtain a link as proof of completion.
                      </Text>
                    </List.Item>
                    <Box paddingBlockStart={100}>
                      <List.Item>
                        <Text variant='headingSm' fontWeight='medium'>
                          Email the screenshot or link to  <span className='support'>support@wisernotify.com</span>, or use our  <span className='live-chat'>live chat</span>  support for verification. <br /> We'll promptly credit your account once verified.
                        </Text>
                      </List.Item>
                    </Box>
                  </List>
                </div>
              </div>
            </Box>
          </div>
        </Box>


        {/* Complete tasks */}
        <Box paddingBlockStart={200} paddingInline={400} paddingBlockEnd={0} >
          <div className='complete'>
            <Box padding={400}>
              <InlineStack gap="200" wrap={false} blockAlign="center">
                <Text variant="headingMd" fontWeight='semibold'>
                  Complete tasks
                </Text>
                <Box className='get-up' >
                  <Card padding={0}>
                    <Banner hideIcon>
                      <Box>
                        <Text variant='headingSm' fontWeight='medium'>Get up to extra 50,000 visitor quota</Text>
                      </Box>
                    </Banner>
                  </Card>
                </Box>
              </InlineStack>
            </Box>
            <Box paddingInline={400} paddingBlockEnd={400}>
              <Card padding={0}>
                <ResourceList
                  resourceName={{ singular: 'customer', plural: 'customers' }}
                  items={[
                    {
                      avatarSource: g2_icon,
                      name: 'Write your review on G2',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: "https://www.g2.com/products/wisernotify/reviews/start"
                    },
                    {
                      avatarSource: captera_icon,
                      name: 'Write your review on captera',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: "https://reviews.capterra.com/new/200592"
                    },
                    {
                      avatarSource: trustpilot_icon,
                      name: 'Write your review on trustpilot',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: "https://www.trustpilot.com/evaluate/wisernotify.com"
                    },
                    {
                      avatarSource: wordpress_icon,
                      name: 'Write your review on wordpress',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: "https://wordpress.org/plugins/wiser-notify/#reviews"
                    },
                    {
                      avatarSource: tweet_icon,
                      name: 'Tweet your most appealing thing about wisernotify',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: 'https://twitter.com/wisernotify'
                    },
                    {
                      avatarSource: LinkedIn_icon,
                      name: 'Share how you use wisernotify on linkedin',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: 'https://www.linkedin.com/company/wisernotify/'
                    },
                    {
                      avatarSource: shopify_icon,
                      name: 'Write a review on shopify',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: 'https://apps.shopify.com/wiser-notify#modal-show=ReviewListingModal'
                    },
                    {
                      avatarSource: wix_icon,
                      name: 'Write a review on wix',
                      visit: <Text variant='bodySm'>Earn 5,000 visits</Text>,
                      url: 'https://www.wix.com/app-market/add-review/c43175ad-247c-4a98-afbf-560059c8198e'
                    },
                    {
                      avatarSource: testimonial_icon,
                      name: 'Share your video testimonial',
                      visit: <Text variant='bodySm'>Earn 10,000 visits</Text>,
                      id: "10"
                    },
                  ]}
                  renderItem={(item) => {
                    const { url, avatarSource, name, visit, id } = item;
                    return (
                      <ResourceItem
                        verticalAlignment="center"
                        media={
                          <div id='avatar_pic'>
                            <Avatar customer size="md" name={name} source={avatarSource} />
                          </div>
                        }
                        accessibilityLabel={`View details for ${name}`}
                        name={name}
                        onClick={(e) => handleClick(e, url, id === '10' ? 'testimonial_click' : 'rate_click')}
                      >
                        <InlineStack align='space-between'>
                          <Box>
                            <Text variant="headingXs" >
                              {name}
                            </Text>
                            <div className='visit' >
                              <Text variant="headingXs" >
                                {visit}
                              </Text>
                            </div>
                          </Box>
                          <Box>
                            <Button >
                              {name === 'Share your video testimonial' ? 'Email us' : 'Rate now'}
                            </Button>
                          </Box>
                        </InlineStack>
                      </ResourceItem>
                    );
                  }}
                />
              </Card>
            </Box>
          </div>
        </Box >


        {/* Rules  */}
        < Box padding={400} >
          <div className='rules'>
            <Box padding={400}>
              <Text variant="headingMd" fontWeight='semibold'>
                Rules
              </Text>
            </Box>
            <Box paddingInlineStart={400} paddingBlockEnd={200}>
              <InlineStack gap="200" wrap={false} blockAlign="center">
                <div className='finger-1'>
                  <Avatar size="sm" customer source={finger_icon} />
                </div>
                <div className='ruleP'>
                  <Text variant='headingSm' fontWeight='medium' >
                    Earn 5,000 visitor quota for each platform you review (e.g., Capterra). Review on as many platforms as you like.
                  </Text>
                </div>
              </InlineStack>
            </Box>
            <Box paddingInlineStart={400} paddingBlockEnd={200}>
              <InlineStack gap="200" wrap={false} blockAlign="center">
                <div className='finger-2'>
                  <Avatar size="sm" customer source={finger_icon} />
                </div>
                <div className='ruleP'>
                  <Text variant='headingSm' fontWeight='medium' >
                    Review us on all platforms within 7 days and get up to 50,000 visitor credits. Send screenshots to confirm.
                  </Text>
                </div>
              </InlineStack>
            </Box>
            <Box paddingInlineStart={400} paddingBlockEnd={200}>
              <InlineStack gap="200" wrap={false} blockAlign="center" >
                <div className='finger-3'>
                  <Avatar size="sm" customer source={finger_icon} />
                </div>
                <div className='ruleP'>
                  <Text variant='headingSm' fontWeight='medium' >
                    Visitor quota expires after 1 year.
                  </Text>
                </div>
              </InlineStack>
            </Box>
            <Box paddingInlineStart={400} paddingBlockEnd={200}>
              <InlineStack gap="200" wrap={false} blockAlign="center">
                <div className='finger-4'>
                  <Avatar size="sm" customer source={finger_icon} />
                </div>
                <div className='ruleP'>
                  <Text variant='headingSm' fontWeight='medium' >
                    The redeemed quota will expire on your next billing date. Redeem only when needed.
                  </Text>
                </div>
              </InlineStack>
            </Box>
          </div>
        </Box >
      </Box >
    </div >
  );
}

export default RateUsCard;
