import { useSelector } from 'react-redux';
import './Spinner.css';
import { ProgressBar, Spinner } from '@shopify/polaris';
import { Frame, Loading } from '@shopify/polaris';

function SpinnerLoader(props) {
  const isLoading = useSelector(state => state.loading.isLoading);

  return (
    // <div className='loading-wrap' style={{ display: isLoading ? 'flex' : 'none' }}>
    //   <div className='spinner_loading_main_div'>
    //     <Spinner size="large" />
    //   </div>
    // </div>
    <div id='progress_main_div' style={{display: isLoading ? 'flex' : 'none', height:'0px' }}>
      <Frame>
        <Loading />
      </Frame>
    </div>
  );
}

export default SpinnerLoader;