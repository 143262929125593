import React, { useCallback, useEffect, useState } from 'react';
import './Visitor.css';
import { VisitorCard } from '../../components';
import * as visitorDucks from '../../ducks/visitor';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Filters, TextField } from '@shopify/polaris';
import { data } from '@shopify/app-bridge/actions/Modal';

const Visitor = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    statusTabs: [
      { id: 1, label: 'Today', value: 'Today' },
      { id: 2, label: 'Last 7 days', value: 'week' },
      { id: 3, label: 'Last 30 days', value: 'month' },
      { id: 4, label: 'Custom date', value: 'custom' }
    ],
    paggiActive: 1,
    activeVistHistPage: 1,
    pageNum: 0,
    queStatus: 'Today',
    selected: 0,
    popoverActive: false,
    pageNumber: 0,
    frmdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    tdt: new Date(new Date().setHours(0, 0, 0, 0)).toDateString(),
    queryValue: '',
    cntryQuery: '',
    visitHistoryOpenModal: false,
    searchData: false,
    seVisHistry: {},
    shouldCallAPI: false,
    isReloadVisithstry: false,
    reLoadVisitor: true,
  });

  const dataVisitor = useSelector((state) => state.visitor.visitor);
  const visitHistoryData = useSelector((state) => state.visitor?.visitHistory);
  const loading = useSelector((state) => state.loading.isLoading)


  const TotalData = dataVisitor?.count || 0;
  const visitHistorycount = visitHistoryData?.totalRec || 0;

  useEffect(() => {
    if (dataVisitor) {
      changeNameValue({ DataVisitor: dataVisitor.data })
    }
  }, [dataVisitor])

  useEffect(() => {
    if (visitHistoryData != undefined && visitHistoryData && visitHistoryData?.data) {
      changeNameValue({ visitHistory: visitHistoryData?.data })
    }
  }, [visitHistoryData])

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj, shouldCallAPI: true }));
  }, []);

  const tabs = state.statusTabs.map((item, i) => ({
    key: i,
    content: item.label,
    id: `${item.label}-${i}`,
    onAction: () => visitorStatusClick(item.value)
  }));

  const visitorStatusClick = useCallback((typ) => {
    let obj = {
      queStatus: typ,
      paggiActive: 1,
    };
    changeNameValue(obj)
  }, [state.queStatus]);

  useEffect(() => {
    let stateObj = {}

    if (state.selected === 0) {
      changeNameValue({ popoverActive: false });
      stateObj.td = {
        fm: new Date().setHours(0, 0, 0, 0),
        to: new Date().setHours(24, 0, 0, 0),
      };
      changeNameValue(stateObj);
    }
    if (state.selected === 1) {
      changeNameValue({ popoverActive: false });
      stateObj.wd = {
        fm: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0),
        to: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 59),
      };
      changeNameValue(stateObj)
    }
    if (state.selected === 2) {
      changeNameValue({ popoverActive: false });
      stateObj.md = {
        fm: new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0),
        to: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 59),
      };
      changeNameValue(stateObj)
    }
    if (state.selected === 3) {
      changeNameValue({ popoverActive: true });
    }
  }, [state.selected]);

  const getVisitor = () => {
    if (state.td || state.wd || state.md) {
      const stateObj = {
        pn: state.paggiActive,
        ps: 10,
      };

      if (state.queryValue) {
        stateObj.eu = state.queryValue;
      }

      if (state.cntryQuery) {
        stateObj.cn = state.cntryQuery;
      }

      if (state.selected === 0) {
        stateObj.td = state.td;
      } else if (state.selected === 1) {
        stateObj.wd = state.wd;
      } else if (state.selected === 2) {
        stateObj.md = state.md;
      }
      else if (state.selected === 3) {
        let startDate = new Date(state.frmdt).setHours(0, 0, 0, 0);
        let endDate = new Date(state.tdt).setHours(24, 0, 0, 0);
        stateObj.sd = startDate;
        stateObj.cd = endDate;
      }
      changeNameValue({ ...stateObj, reLoadVisitor: true })
      dispatch(visitorDucks.getVisitor(stateObj));
    }
  };

  useEffect(() => {
    if (state.td || state.wd || state.md) {
      changeNameValue({ paggiActive: 1 });
    }
  }, [state.td, state.wd, state.md]);

  useEffect(() => {
    if (state.shouldCallAPI) {
      if (state.queryValue || state.cntryQuery) {
        let queryTimer = setTimeout(() => getVisitor(), 1000);
        return () => clearTimeout(queryTimer);
      } else {
        getVisitor();
        setState((prevState) => ({ ...prevState, shouldCallAPI: false }));
      }
    }
  }, [state.paggiActive, state.td, state.wd, state.md, state.queryValue, state.cntryQuery]);


  const handleSelectDate = useCallback(() => {
    const startDate = new Date(state.frmdt).setHours(0, 0, 0, 0);
    const endDate = new Date(state.tdt).setHours(24, 0, 0, 0);

    const stateObj = {
      pn: state.paggiActive,
      ps: 10,
      sd: startDate,
      ed: endDate
    };
    changeNameValue({ popoverActive: false });
    dispatch(visitorDucks.getVisitor(stateObj));
  }, [state.frmdt, state.tdt, state.paggiActive]);

  const onPaggiCall = (i) => {
    let num = 0;
    if (i === '+1') {
      num = state.paggiActive + 1;
    } else if (i === '-1') {
      num = state.paggiActive - 1;
    } else {
      num = i;
    }
    changeNameValue({ paggiActive: num });
  };

  const onPaggination = (i) => {
    let num = 1;
    if (i === '+1') {
      num = state.activeVistHistPage + 1;
    } else if (i === '-1') {
      num = state.activeVistHistPage - 1;
    } else num = i;

    changeNameValue({ activeVistHistPage: num });
  };


  useEffect(() => {
    if (state.visitHistoryOpenModal) {
      let objQ = {
        pn: state.activeVistHistPage,
        ps: 10
      };

      if (state.eventType) {
        objQ.q = [state.eventType];
      }

      if (state.vid) {
        objQ.vid = state.vid;
      }

      if (state.seVisHistry && state.visitHistoryOpenModal) {
        objQ.vid = state.seVisHistry.vid;
      }
      dispatch(visitorDucks.getVisitHistr(objQ));
    }
  }, [
    state.seVisHistry,
    state.activeVistHistPage,
    state.eventType,
    state.vid,
    state.visitHistoryOpenModal
  ]);


  //call visitorHistory Api 
  const handleViewHistry = (obj) => {
    openCloseModal('visitHistoryOpenModal', state.visitHistoryOpenModal, 'open');
    changeNameValue({ activeVistHistPage: 1, seVisHistry: obj, isReloadVisithstry: true });
  };

  useEffect(() => {
    changeNameValue({ pageNumber: Math.ceil(TotalData / 10) });
  }, [TotalData]);

  useEffect(() => {
    changeNameValue({ pageNum: Math.ceil(visitHistorycount / 10) });
  }, [visitHistorycount]);

  const filters = [
    {
      key: 'cntryQuery',
      pinned: true,
      label: state.cntryQuery !== "" ? `Search by country: ${state.cntryQuery}` : "Search by country",
      hideClearButton: true,
      onRemove: () => changeNameValue({ cntryQuery: '' }),
      filter: (
        <Box paddingBlock={200}>
          <TextField
            value={state.cntryQuery}
            placeholder='Search by country'
            onChange={(e) => changeNameValue({ cntryQuery: e })}
            clearButton
            onClearButtonClick={() => changeNameValue({ cntryQuery: '' })}
          />
        </Box>
      ),
      shortcut: true,
    },
    {
      key: 'queryValue',
      pinned: true,
      label: state.queryValue !== "" ? `Search by email and hostname: ${state.queryValue}` : "Search by email and hostname",
      hideClearButton: true,
      onRemove: () => changeNameValue({ queryValue: '' }),
      filter: (
        <Box paddingBlock={200}>
          <TextField
            value={state.queryValue}
            placeholder='Search by email and hostname'
            onChange={(e) => changeNameValue({ queryValue: e })}
            clearButton
            onClearButtonClick={() => changeNameValue({ queryValue: '' })}
          />
        </Box>
      ),
      shortcut: true,
    },
  ];

  const days_between = (date) => {
    if (new Date() >= new Date(date)) {
      const seconds = Math.floor((new Date() - new Date(date)) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) return `${interval} years ago`;
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) return `${interval} months ago`;
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) return `${interval} days ago`;
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) return `${interval} hours ago`;
      interval = Math.floor(seconds / 60);
      if (interval >= 1) return `${interval} minutes ago`;
      return `${Math.floor(seconds)} seconds ago`;
    } else {
      return 'live';
    }
  };

  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValue({ [name]: !value });
    }
    if (name == 'visitHistoryOpenModal' && value === true) {
      changeNameValue({ eventType: '' })
    }
  }, [state.seVisHistry, state.eventType]);


  useEffect(() => {
    if (loading || state.visitHistory === undefined || state.visitHistory.length < 0) {
      changeNameValue({ isReloadVisithstry: true })
    }
    else {
      changeNameValue({ isReloadVisithstry: false })
    }
  }, [loading, state.visitHistory])

  const isLoading = useSelector(state => state.loading.isLoading);


  useEffect(() => {
    if (isLoading || state.DataVisitor === undefined || state.DataVisitor.length < 0) {
      changeNameValue({ reLoadVisitor: true })
    }
    else {
      changeNameValue({ reLoadVisitor: false })
    }
  }, [isLoading, state.DataVisitor])


  return (
    <VisitorCard
      state={state}
      tabs={tabs}
      changeNameValue={changeNameValue}
      onPaggiCall={onPaggiCall}
      handleSelectDate={handleSelectDate}
      filters={filters}
      openCloseModal={openCloseModal}
      handleViewHistry={handleViewHistry}
      days_between={days_between}
      onPaggination={onPaggination}
    />
  );
};

export default Visitor;
