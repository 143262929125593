import React from 'react';
import { Layout,  LegacyCard, TextContainer,  BlockStack, Box, Button, InlineGrid, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, Text, TextField } from '@shopify/polaris';
import { ArrowRightIcon } from '@shopify/polaris-icons';
import { google_icon, shopify_icon, wiser_logo, wix_lg_icon } from '../../img';
import TestimonialCard from '../Login/TestimonialCard';
import { Link } from 'react-router-dom';


function SignUpCard(props) {
  const { state, validator, changeNameValue } = props;

  const LoginList = [
    {
      icon: google_icon,
      label: 'Google',
      action: () => props.handleLoginWithGoogle()
    },
    {
      icon: shopify_icon,
      label: 'Shopify',
      action: () => props.handleLoginWithShopify()
    },
    {
      icon: wix_lg_icon,
      label: 'Wix',
      action: () => props.handleLoginWithWix()
    }
  ]

  return (
    <div className='page-login-wrap'>
      <Page>
        <Box paddingBlockEnd={600}>
          <img src={wiser_logo} alt='wiser' />
        </Box>
        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
          <div className='login-part-left'>
            <Box>
              <TestimonialCard />
            </Box>
          </div>
          {props.isLoading === true ?
            <div className='lg_bg'>
              <div className='lg_block'>
                <div className='lg_content'>
                  <Box>
                    <BlockStack gap={300}>
                      <Box paddingBlockEnd={300}>
                        <SkeletonDisplayText size="" maxWidth='70%' />
                      </Box>
                      <SkeletonDisplayText size="medium" maxWidth='100%' />
                      <SkeletonDisplayText size="medium" maxWidth='100%' />
                      <SkeletonDisplayText size="medium" maxWidth='100%' />
                      <Box paddingBlockStart={300}>
                        <SkeletonDisplayText size="extraLarge" maxWidth='100%' />
                      </Box>
                    </BlockStack>
                  </Box>
                  <Box paddingBlock={300}>
                    <InlineStack blockAlign='center' wrap={false}>
                      <span className="or_divider" />
                      <Box paddingInline={400}>
                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Or you can sign up with</Text>
                      </Box>
                      <span className="or_divider" />
                    </InlineStack>
                  </Box>
                  <Box paddingBlockEnd={500}>
                    <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={300}>
                      {Array(3).fill().map((_, index) => (
                        <SkeletonDisplayText size="medium" key={index} />
                      ))}
                    </InlineGrid>
                  </Box>
                  <Box paddingBlock={600}>
                    <InlineStack gap={300} blockAlign='center' align='end' wrap={false}>
                      <Text variant='headingSm' as='p' fontWeight='semibold'>Already have an account ?</Text>
                      <Box width='15%'>
                        <SkeletonBodyText lines={1} />
                      </Box>
                    </InlineStack>
                  </Box>
                  <div className='lg_content_ft'>
                    <Text variant='headingXs' as='p' fontWeight='regular' tone='subdued'>By proceeding, you agree to the Terms and Conditions and  Privacy Policy</Text>
                    <Box paddingBlockStart={200}>
                      <div id='lg_btn_links'>
                        <InlineStack gap={200} wrap={false}>
                          <Button variant='plain'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Help</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/privacypolicy/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Privacy</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/termsconditions/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Terms</Text>
                          </Button>
                        </InlineStack>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className='lg_bg'>
              <div className='lg_block'>
                <div className='lg_content'>
                  <BlockStack gap={200}>
                    <Text variant='headingLg' as='h3' fontWeight='bold'>Create a Wisernotify account</Text>
                    <Box paddingBlockEnd={200}>
                      {/* <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Start Your 7 Days Free Trial</Text> */}
                    </Box>
                  </BlockStack>
                  <BlockStack gap={200}>
                    <div>
                      <TextField
                        label="Full Name"
                        value={state.fn}
                        onChange={(e) => changeNameValue({ fn: e })}
                        autoComplete="off"
                        error={state.errMessage.fullName ? state.errMessage.fullName : false}
                      />
                      {validator.message('fullName', state.fn, 'required')}
                    </div>
                    <div>
                      <TextField
                        label="Email"
                        type='email'
                        value={state.e}
                        onChange={(e) => changeNameValue({ e: e })}
                        autoComplete="off"
                        error={state.errMessage.email ? state.errMessage.email : false}
                      />
                      {validator.message('email', state.e, 'required')}
                    </div>
                    <div>
                      <TextField
                        label="Password"
                        type='password'
                        value={state.pd}
                        onChange={(e) => changeNameValue({ pd: e })}
                        autoComplete="off"
                        error={state.errMessage.password ? state.errMessage.password : false}
                      />
                      {validator.message('password', state.pd, 'required')}
                    </div>

                    {/* create cloudflare captcha  */}
                    <div id='cloudflare-turnstile' className='turnstile-custom-css'>
                      <div className='cloudflare-skeleton' id='cloudflare-skeleton'>
                        <Layout.Section>
                          <LegacyCard sectioned>
                            <TextContainer>
                              <SkeletonDisplayText size="small" />
                              <SkeletonBodyText lines={1} />
                            </TextContainer>
                          </LegacyCard>
                        </Layout.Section>
                        {/* <TextContainer>
                          <SkeletonDisplayText size="medium" />
                          <SkeletonDisplayText size="small" />
                          <SkeletonBodyText lines={1} />
                        </TextContainer> */}
                      </div>
                    </div>

                    <div className='su_btn_bg' onClick={() => props.handleSignup()}>
                      <Text variant='headingSm' as='p' fontWeight='regular'>Start Your 7 Days Free Trial</Text>
                    </div>
                  </BlockStack>
                  <Box paddingBlock={300}>
                    <InlineStack blockAlign='center' wrap={false}>
                      <span className="or_divider" />
                      <Box paddingInline={400}>
                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Or you can sign up with                      </Text>
                      </Box>
                      <span className="or_divider" />
                    </InlineStack>
                  </Box>
                  <Box>
                    <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={300}>
                      {LoginList && LoginList.map((data, index) => (
                        <div className='lg_btn_bg' key={index} onClick={data.action}>
                          <InlineStack gap={200} blockAlign='center' wrap={false}>
                            <img src={data.icon} alt='icon' />
                            <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                          </InlineStack>
                        </div>
                      ))}
                    </InlineGrid>
                  </Box>
                  <Box paddingBlock={500}>
                    <InlineStack gap={300} blockAlign='center' align='end' wrap={false}>
                      <Text variant='headingSm' as='p' fontWeight='semibold'>Already have an account ?</Text>
                      <div id='lg_btn_rvrs'>
                        <Link to={'/login'}><Button variant='plain' icon={ArrowRightIcon}>Log in</Button></Link>
                      </div>
                    </InlineStack>
                  </Box>
                  <div className='lg_content_ft'>
                    <Text variant='headingXs' as='p' fontWeight='regular' tone='subdued'>By proceeding, you agree to the Terms and Conditions and  Privacy Policy</Text>
                    <Box paddingBlockStart={200}>
                      <div id='lg_btn_links'>
                        <InlineStack gap={200} wrap={false}>
                          <Button variant='plain'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Help</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/privacypolicy/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Privacy</Text>
                          </Button>
                          <Button variant='plain' url={`https://wisernotify.com/termsconditions/`} target='_blank'>
                            <Text variant='headingSm' as='p' fontWeight='regular'>Terms</Text>
                          </Button>
                        </InlineStack>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          }
        </InlineGrid>
      </Page>
    </div>
  );
}

export default SignUpCard; 