import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const whitelabelForgotPwd = createSlice({
  name: 'whitelabelForgotPwd',
  initialState,
  reducers: {
    getBrand: (state, action) => { },
    getBrandSuccess: (state, action) => {
      return { ...state, brand: action.payload };
    },
    forgetWhitePWD: (state, action) => { },
  }
});

export const {
  getBrand,
  getBrandSuccess,
  forgetWhitePWD
} = whitelabelForgotPwd.actions;

export default whitelabelForgotPwd.reducer;
