import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const billingInvoice = createSlice({
  name: 'billingInvoice',
  initialState,
  reducers: {
    getBillingInvoice: (state, action) => { },
    getBillingInvoiceSuccess: (state, action) => {
      return { ...state, billingHistory: action.payload };
    },
    mangePaymentSrc: (state, action) => { },
    mangePaymentSrcSuccess: (state, action) => {
      return { ...state, mangePaymentSrc: action.payload };
    },
    downloadInvoice: (state, action) => { },
    getShopifyBill: (state, action) => { },
    getShopifyBillSuccess: (state, action) => {
      return { ...state, shopifyBill: action.payload };
    },
    getUserCard: (state, action) => { },
    getUserCardSuccess: (state, action) => {
      return { ...state, userCard: action.payload };
    },
    paymantRetry: (state, action) => { },
    rmvUserAlrt: (state, action) => { }
  }
});

export const {
  getBillingInvoice,
  getBillingInvoiceSuccess,
  mangePaymentSrc,
  mangePaymentSrcSuccess,
  downloadInvoice,
  getShopifyBill,
  getShopifyBillSuccess,
  getUserCard,
  getUserCardSuccess,
  paymantRetry,
  rmvUserAlrt
} = billingInvoice.actions;

export default billingInvoice.reducer;
