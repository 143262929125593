import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, BlockStack, TextField, IndexTable, InlineStack, PageActions, Page, Divider, Modal, Text, Tooltip, RadioButton, Select, ButtonGroup, Icon } from '@shopify/polaris';
import { ArrowDiagonalIcon, ClipboardIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

function WebhookCard(props) {
  const { state, changeNameValue, handleBackToMain } = props.props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data.
  const [info, setInfo] = useState({
    webhookList: [],
    errMessage: {},
    dataId: '', //delete id,
    inm: '', //intgration name
    deleteOpen: false, //open delete modal,
    ytsc: true,
    modalC: false, //condition for delete modal
    notifListData: '' //show data in modal
  });

  //set changeNameValueCard data.
  const changeNameValueCard = useCallback((obj) => {
    setInfo((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const webhookList = useSelector(state => state.integration?.webhookList);

  let arrNotWebhook = ['pabblyconnect', 'integrately'];
  let arrField = [];
  for (const i in state.tl) {
    const element = state.tl[i];
    arrField.push({ ky: i, tp: element });
  }
  let listData = [];
  listData.push(
    { value: 'meeting.created', label: 'Meeting created' },
    { value: 'meeting.registration_created', label: 'Meeting registrant created' }
  );

  //set webhookList data.
  useEffect(() => {
    changeNameValueCard({ webhookList });
  }, [webhookList]);

  //open Close Modal.
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValueCard({ [name]: !value });
    }
  }, []);

  //redirect to the url.
  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  //how to connect.
  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  //clear State.
  const clearState = () => {
    let obj = {
      rmk: '',
      ak: '',
      fid: '',
      su: '',
      urn: '',
      ac: '',
      li: '',
      un: '',
      ui: '',
      pwd: '',
      evTyp: '',
      ci: '',
      sk: ''
    }
    changeNameValue(obj);
  }

  //create Webhook.
  const createWebhook = () => {
    if (!props.validator.allValid()) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeNameValueCard({ errMessage: props.validator.errorMessages });
    }
    else {
      changeNameValueCard({ errMessage: {} });
      let objWB = {
        _id: state._id,
        rmk: state.rmk,
        ak: state.ak,
        fid: state.fid,
        su: state.su,
        urn: state.urn,
        ac: state.ac,
        li: state.li,
        un: state.un,
        ui: state.ui,
        pwd: state.pwd,
        evTyp: state.evTyp,
        ci: state.ci,
        sk: state.sk
      };
      if (state.in === 'recurly') {
        objWB.evTyp = info.ytsc === true ? 'new_subscription_notification' : 'paid_charge_invoice_notification';
      }
      if (state.in === 'opensea') {
        objWB.evTyp = info.ytsc === true ? 'created' : 'successful';
      }
      // if (this.props.brand) {
      //   objWB.wurl = this.props.brand.wurl;
      // }
      objWB.sa = state.sa ? state.sa : 'all';
      objWB.ty = state.ty ? state.ty : '';
      dispatch(integrationDucks.createWebhook(objWB));
      changeNameValueCard({ ytsc: true });
      clearState();
    }
  };

  //handle IsActive.
  const handleIsActive = (item) => {
    let obj = {
      id: item._id,
      ia: !item.ia
    };
    dispatch(integrationDucks.updIntIsActive(obj));
    const updatedList = info.webhookList.map((elm) =>
      elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
    );
    changeNameValueCard({ webhookList: updatedList })
  };

  //open delete modal
  const handledeleteModal = (id, inm) => {
    let data = state.notiflist?.filter(x => x._id === id);
    if (data && data.length > 0) {
      changeNameValueCard({ modalC: true, notifListData: data[0].data.toString() });
    }
    openCloseModal('deleteOpen', info.deleteOpen, 'open');
    changeNameValueCard({ dataId: id, inm: inm })
  }

  //handle Delete
  const handleDelete = () => {
    let obj = {
      id: info.dataId,
      sa: state.sa ? state.sa : 'all',
      ty: state.ty ? state.ty : '',
      inm: info.inm
    }
    dispatch(integrationDucks.deleteWebhook(obj));
    openCloseModal('deleteOpen', info.deleteOpen, 'close');
  }


  //copy endpoint
  const copyEndpoint = (url) => {
    copy(url, { debug: true });
    dispatch(toastify({ type: 'success', msg: "Endpoint copied" }));
    return;
  }

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} subtitle={state.dsc} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to integrate with this platform ?</Button> : <></>}>
      <BlockStack gap={400}>

        {/* header */}
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />

        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <div>
              {arrField && arrField.length > 0 &&
                arrField.map((x, i) => {
                  const fieldName = x.tp.replace('Enter', '').trim();
                  if (x.ky === 'rmk') {
                    return (
                      <Box key={i} paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                        <div className='form-width'>
                          <InlineStack blockAlign="center" >
                            <div className="textfeild-lable">
                              <Tooltip content={state.tl.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                                <Text variant="headingSm" as="h6">Name your connection</Text>
                              </Tooltip>
                            </div>
                            <div className="textfeild-box">
                              <TextField
                                type="text"
                                value={state.rmk}
                                onChange={(e) => changeNameValue({ rmk: e })}
                                placeholder='Name your connection'
                                autoComplete="off"
                                error={info.errMessage.rmk ? "The name your connection field is required." : false}
                              />
                              {props.validator.message('rmk', state.rmk, 'required')}
                            </div>
                          </InlineStack>
                        </div>
                        {state.in === 'integrately' &&
                          <Box paddingBlockStart={200}>
                            <div className='form-width'>
                              <InlineStack blockAlign="center" >
                                <div className='textfeild-lable'></div>
                                <div className='textfeild_shp'>
                                  <TextField
                                    value={props.profile.ak}
                                    disabled
                                    connectedRight={<Button icon={ClipboardIcon} variant='primary' onClick={() => copyEndpoint(props.profile.ak)}>Copy API key</Button>}
                                  />
                                </div>
                              </InlineStack>
                            </div>
                          </Box>
                        }
                      </Box>
                    )
                  } else {
                    return (
                      <Box key={i} paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
                        <div className='form-width'>
                          <InlineStack blockAlign="center" >
                            <div className="textfeild-lable">
                              <Tooltip content={x.tp} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                                <Text variant="headingSm" as="h6">{x.tp.replace('Enter', '')}</Text>
                              </Tooltip>
                            </div>
                            <div className="textfeild-box">
                              <TextField
                                type="text"
                                placeholder={x.tp}
                                value={state[x.ky]}
                                onChange={(e) => changeNameValue({ [x.ky]: e })}
                                autoComplete="off"
                                error={info.errMessage[fieldName] ? info.errMessage[fieldName] : false}
                              />
                              {props.validator.message(fieldName, state[x.ky], 'required')}
                            </div>
                          </InlineStack>
                        </div>
                      </Box>
                    )
                  }
                })}
            </div>
          }
          {state.in === 'zoom' &&
            <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
              <InlineStack blockAlign="center" >
                <div className="textfeild-lable">
                  <Tooltip content={'Event'} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                    <Text variant="headingSm" as="h6">Event</Text>
                  </Tooltip>
                </div>
                <div>
                  <Select
                    options={listData}
                    value={state.evTyp}
                    onChange={(e) => changeNameValue({ evTyp: e })}
                  />
                </div>
              </InlineStack>
            </Box>
          }
          {
            state.in === 'recurly' &&
            <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
              <InlineStack blockAlign="center" >
                <div className="textfeild-lable">
                  <Tooltip content={'Event'} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                    <Text variant="headingSm" as="h6">Event</Text>
                  </Tooltip>
                </div>
                <InlineStack gap={400}>
                  <RadioButton
                    label="New subscription notification"
                    checked={info.ytsc === true}
                    name="events"
                    onChange={() => changeNameValueCard({ ytsc: !info.ytsc })}
                  />
                  <RadioButton
                    label="Paid charge invoice notification"
                    checked={info.ytsc === false}
                    name="events"
                    onChange={() => changeNameValueCard({ ytsc: !info.ytsc })}
                  />
                </InlineStack>
              </InlineStack>
            </Box>
          }
          {
            state.in === 'opensea' &&
            <Box paddingBlockStart={200} paddingBlockEnd={200} paddingInline={200}>
              <InlineStack blockAlign="center" >
                <div className="textfeild-lable">
                  <Tooltip content={'Event'} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                    <Text variant="headingSm" as="h6">Event</Text>
                  </Tooltip>
                </div>
                <InlineStack gap={400}>
                  <RadioButton
                    label="Created"
                    checked={info.ytsc === true}
                    name="events"
                    onChange={() => changeNameValueCard({ ytsc: !info.ytsc })}
                  />
                  <RadioButton
                    label="Successful"
                    checked={info.ytsc === false}
                    name="events"
                    onChange={() => changeNameValueCard({ ytsc: !info.ytsc })}
                  />
                </InlineStack>
              </InlineStack>
            </Box>
          }
          <Box align='center' padding={300}>
            <Button onClick={() => createWebhook()} variant="primary">Create</Button>
          </Box>

          <Box padding={400}>
            <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={info.webhookList ? info.webhookList.length : 0}
                headings={[
                  { title: '#' },
                  { title: 'Name' },
                  { title: 'Endpoint' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Action' }
                ]}
                selectable={false}
              >
                {info.webhookList && info.webhookList.length > 0 && info.webhookList.map((data, index) => {
                  if (data._id === data.idForUsInt) {
                    data.ia = data.isIntActive ? data.isIntActive : false;
                  }
                  let wbURL = data.d && data.d.web_url;
                  if (props.isWhite === true) {
                    wbURL = wbURL.replace('https://is.wisernotify.com', window.location.origin);
                  }
                  return (
                    <>
                      <IndexTable.Row key={index}>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" as="span">
                            {index + 1}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>{data.rmk}</IndexTable.Cell>
                        <IndexTable.Cell>{arrNotWebhook.indexOf(data.inm) === -1 ? wbURL.slice(0, 30) + '...' : '-'}</IndexTable.Cell>
                        <IndexTable.Cell>{moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={data.ia}
                              onChange={() => handleIsActive(data)}
                            />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <ButtonGroup>
                            {
                              arrNotWebhook.indexOf(data.inm) === -1 &&
                              <Tooltip content={`Click to copy webhook URL`} width='wide' padding="400" dismissOnMouseOut >
                                <Button variant='primary' tone='success' icon={ClipboardIcon} onClick={() => copyEndpoint(data.inm === 'kartra' ? wbURL.replace('https://', '') : wbURL)}></Button>
                              </Tooltip>
                            }
                            {props.checkPermission('integration', 'isdelete', props.profile) &&
                              <Tooltip content={`Delete`} width='wide' padding="400" dismissOnMouseOut >
                                <div id="delete">
                                  <Button variant='primary' tone='critical' icon={DeleteIcon} onClick={() => handledeleteModal(data._id, data.inm)}></Button>
                                </div>
                              </Tooltip>
                            }
                          </ButtonGroup>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    </>
                  )
                })}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {info.modalC === true ?
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>This data-source is being used in the notification.</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">Kindly disable this data-source from
                  <b><i>{" "}{info.notifListData}{" "}</i></b>
                  notification first & after that, you can delete it.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  content: 'Go to notification',
                  onAction: () => {
                    navigate('/notification?ds=list')
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
        :
        <Modal
          open={info.deleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
          onClose={(e) => openCloseModal('deleteOpen', info.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Box paddingBlockEnd={400}>
                <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
              </Box>
              <Divider />
              <PageActions
                primaryAction={{
                  icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
                  onAction: () => handleDelete()
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => openCloseModal('deleteOpen', info.deleteOpen, 'close')
                  }
                ]}
              />
            </div>
          </Modal.Section>
        </Modal>
      }
    </Page>
  )
}

export default WebhookCard;