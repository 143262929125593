import { BlockStack, Box, Button, Card, InlineGrid, InlineStack, Text, TextField } from '@shopify/polaris';
import React from 'react';

function BoardingStep1({ props }) {

  let { state, changeNameValue, handleSelNext, handleCheckboxChange } = props;

  const select_Arr = [
    { name: 'E-commerce & retail', value: 'e-commerce and retail' },
    { name: 'Lead generation', value: 'lead generation' },
    { name: 'Online coach or course creator', value: 'online coach or course creator' },
    { name: 'Health care', value: 'health care' },
    { name: 'Saas & software', value: 'saas & software' },
    { name: 'Marketing Agency', value: 'marketing agency' },
    { name: 'Events & ticketing', value: 'events & ticketing' },
    { name: 'Content publishing & blogging', value: 'content publishing & blogging' },
    { name: 'Food or tourism', value: 'food or tourism' },
    { name: 'Web agency', value: 'web agency' },
    { name: 'Finance & insurance', value: 'finance & insurance' },
    { name: 'Other', value: 'Other' },
  ];

  return (
    <Card>
      <Box paddingBlockStart={600}>
        <BlockStack gap={200}>
          <Text as='h3' id='step1_Title'>Which industry do you belongs to?</Text>
          <Text as='p' variant='bodyLg'>Pick the industry that matches your business to get personalized recommendations</Text>
        </BlockStack>
      </Box>
      <Box id='selected_check' >
        <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1 }} gap={500}>
          {select_Arr.map((x, i) => {
            const isChecked = state.selectedValues && state.selectedValues?.includes(x.value);
            return (
              <Box onClick={() => handleCheckboxChange(x.value)} id='selected' key={i} borderColor="border" borderWidth="025" borderRadius='100'>
                <label className="custom-checkbox">
                  {x.name}
                  <input
                    type="checkbox"
                    checked={isChecked}
                    name='selected'
                    value={x.value}
                  />
                  <span className="checkmark"></span>
                </label>
                {isChecked && x.value === 'Other' && (
                  <Box paddingBlockStart={200} paddingInlineEnd={300}>
                    <TextField
                      placeholder='Enter your Industry'
                      autoComplete="off"
                      value={state.otherValue}
                      onChange={(e) => changeNameValue({ 'otherValue': e })}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </InlineGrid>
      </Box>
      <InlineStack align="end" blockAlign='center'>
        <Button id='continu_Btn' variant='primary' disabled={state.selectedValues == ''} onClick={() => handleSelNext()}>Continue</Button>
      </InlineStack>
    </Card>
  );
}

export default BoardingStep1;