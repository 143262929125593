import { createSlice } from '@reduxjs/toolkit';

const initialState = {};


const visitor = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    getVisitor: (state, action) => { },
    getVisitorSuccess: (state, action) => {
      return { ...state, visitor: action.payload };
    },
    getVisitHistr: (state, action) => { },
    getVisitorHistrSuccess: (state, action) => {
      return { ...state, visitHistory: action.payload }
    }
  }
});

export const {
  getVisitor,
  getVisitorSuccess,
  getVisitHistr,
  getVisitorHistrSuccess
} = visitor.actions;

export default visitor.reducer;
