import BoardingStep1 from './BoardingStep1';
import { Box, Text, Card } from '@shopify/polaris'
import { wiser_logo } from '../../img'
import BoardingStep2 from './BoardingStep2';
import BoardingStep3 from './BoardingStep3';

function Card1(props) {

  const getStepCount = () => {
    const { indtry, arrusrpltfm } = props.state.profile;

    if (indtry === '' || indtry === undefined) {
      return 1;
    } else if (!arrusrpltfm || arrusrpltfm?.length === 0) {
      return 2;
    } else {
      return 3;
    }
  };

  return (
    <div style={{ maxWidth: '1160px', width: '100%', margin: 'auto' }}>
      <div id='Top_wiserLogo' className='onboarding-wrap'>
        <img src={wiser_logo} alt='wiser' width={"150px"} />
        <Box paddingBlockEnd={100} paddingInlineEnd={600} color='text-emphasis'>
          <Text as='p' variant='bodyLg' alignment='end'>
            {getStepCount()}/3
          </Text>
        </Box>
      </div >
      <div className='onBord_container'>
        {
          (getStepCount() === 1) ?
            <BoardingStep1 props={props} /> :
            (getStepCount() === 2) ?
              <BoardingStep2 props={props} /> :
              <BoardingStep3 props={props} />
        }
      </div>
    </div>
  );
}

export default Card1;