import React, { memo } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';

const MultiLine = (props) => {

  const { state, iAry, vAry, eAry, hAry, cAry, clsAry, date } = props;

  let type = '';
  if (state.rt === 'today') {
    type = 'time';
  } else {
    type = 'datetime';
  }

  function formatDateArray() {
    if (date && date.length > 0) {

      let newData = JSON.stringify(date);
      newData = JSON.parse(newData);

      if (state.rt === 'custom' && newData && newData.length > 0) {
        for (let i = 0; i < newData.length; i++) {
          const date = new Date(newData[i]);
          const formattedDate = moment(date).format('MM/DD/YYYY');
          newData[i] = formattedDate;
        }
      }
      for (let i = 0; i < newData.length; i++) {
        const date = newData[i];
        const [month, day] = date.split('/');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        newData[i] = `${day} ${monthNames[parseInt(month, 10) - 1]}`;
      }
      return newData;
    }
    return []
  }


  // function formatDateArray(dates) {
  //   const newData = JSON.parse(JSON.stringify(dates));

  //   const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  //   // Map over each date and format it
  //   const formattedData = newData.map(date => {
  //     const dateObj = new Date(date);

  //     const day = dateObj.getUTCDate();
  //     const month = dateObj.getUTCMonth();

  //     return `${day} ${monthNames[month]}`;
  //   });

  //   return formattedData;
  // }



  const options = {
    colors: ['#2dca73', '#4c84ff', '#FF5A5A', '#fba153', '#4cceff', '#a04cff',],

    chart: {
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 15,
        left: 2,
        blur: 3,
        color: ['#2dca73', '#4c84ff', '#FF5A5A', '#fba153', '#4cceff', '#a04cff',],
        opacity: 0.15
      },
      toolbar: {
        tools: {
          download: false
        }
      }
    },

    stroke: {
      curve: 'smooth',
      width: 4
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: type,
      // categories: date
      categories: state.rt === 'today' ? date : formatDateArray(date),
    },
    yaxis: {
      tickAmount: 5,
    },
    grid: {
      show: true,
      borderColor: '#ECEEF4',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: ['#fff', '#fff'],
        opacity: 0.1
      },
      column: {
        colors: ['#fff', '#fff'],
        opacity: 0.1
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },
    legend: {
      position: 'top',
      itemMargin: {
        horizontal: 0,
        vertical: 0
      },
    }
  };

  const seriesData = [
    {
      name: 'Visitors',
      data: vAry.length > 0 ? vAry : [0]
    },
    {
      name: 'Impressions',
      data: iAry.length > 0 ? iAry : [0]
    },
    {
      name: 'Close',
      data: clsAry.length > 0 ? clsAry : [0]
    },
    {
      name: 'Hover',
      data: hAry.length > 0 ? hAry : [0]
    },
    {
      name: 'Clicks',
      data: cAry.length > 0 ? cAry : [0]
    },
    {
      name: 'Engaged Visitors',
      data: eAry.length > 0 ? eAry : [0]
    }
  ];
  return <Chart options={options} series={seriesData} type="line" width="100%" height="420px" />;
};

export default memo(MultiLine);
