import React from 'react';
import { Box, Card, InlineGrid, Layout, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from '@shopify/polaris';

function ShopifyInitSkeleton(props) {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card>
            <Box paddingBlockEnd={400}>
              <SkeletonDisplayText size="small" />
            </Box>
            <SkeletonBodyText lines={3} />
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <Box paddingBlockEnd={600}>
              <SkeletonDisplayText size="small" />
            </Box>
            <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>
              {Array.from({ length: 5 }).map((_, i) => (
                <div className='card_bg' key={i}>
                  <Box paddingBlockEnd={800}>
                    <div className='noti_thumb_block'>
                      <SkeletonThumbnail size="large" />
                    </div>
                  </Box>
                  <Box width='30%' paddingBlockEnd={200}>
                    <SkeletonBodyText lines={1} />
                  </Box>
                  <SkeletonBodyText lines={1} />
                  <Box paddingBlockStart={400}>
                    <SkeletonDisplayText size="small" />
                  </Box>
                </div>
              ))}
            </InlineGrid>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default ShopifyInitSkeleton;