import React, { useEffect } from 'react';
import { HeaderCard } from '..';
import { createApp } from '@shopify/app-bridge';
import { AppLink, NavigationMenu, Redirect } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';

function EntryCard(props) {
  const navigate = useNavigate();

  useEffect(() => {
    //subscribe shopify admin menu navigation click event
    const strObjConfig = localStorage.getItem('store_config');
    if (strObjConfig) {
      try {
        const appShopify = createApp(JSON.parse(strObjConfig));
        let arrItems = [];

        if (localStorage.getItem('pvd') === 'shopify') {
          arrItems.push(AppLink.create(appShopify, { label: 'Notifications', destination: '/notifShopify' }));
        } else {
          arrItems = arrItems.concat([
            AppLink.create(appShopify, { label: 'Social proof', destination: '/socialproof' }),
            AppLink.create(appShopify, { label: 'Widget', destination: '/widgets' }),
            AppLink.create(appShopify, { label: 'Inline', destination: '/inline' })
          ]);
        }

        arrItems = arrItems.concat([
          AppLink.create(appShopify, { label: 'Settings', destination: '/setting' }),
          AppLink.create(appShopify, { label: 'Performance', destination: '/performance' }),
          AppLink.create(appShopify, { label: 'Analytics', destination: '/analytic' }),
          AppLink.create(appShopify, { label: 'Visitor', destination: '/visitor' }),
          AppLink.create(appShopify, { label: 'Goal', destination: '/goal' }),
          AppLink.create(appShopify, { label: 'Data', destination: '/data' }),
          AppLink.create(appShopify, { label: 'Integrations', destination: '/integration' }),
          AppLink.create(appShopify, { label: 'Plan', destination: '/plan' }),
          AppLink.create(appShopify, { label: 'Billing Invoice', destination: '/payment' }),
          AppLink.create(appShopify, { label: 'Account', destination: '/profile' })
        ]);

        //Navigation menu for shopify
        NavigationMenu.create(appShopify, {
          items: arrItems
        });

        const unsubscribe = appShopify.subscribe(Redirect.Action.APP, (payload) => {
          window._cio.page(payload.path);
          navigate(payload.path); // Use replace to prevent going back in history
        });

        return () => {
          unsubscribe();
        }
      } catch (error) {
        console.error('Error initializing Shopify App Bridge:', error);
      }
    }
    //end subscribe 
  }, [navigate])

  return (
    <div>
      <HeaderCard component={props.component} />
    </div>
  );
}

export default EntryCard;