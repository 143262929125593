import React, { useState, useCallback, useEffect } from 'react';
import { Text, Box, BlockStack, InlineGrid, Button, Checkbox, InlineStack, TextField, DropZone, RangeSlider, Icon, Tooltip, Select, Thumbnail, VideoThumbnail } from '@shopify/polaris';
import { EditIcon } from '@shopify/polaris-icons';
import ColorPick from '../NotifEdit/ColorPick';
import NewPlan from './NewPlan';
import NewUser from './NewUser';
import { Default_logo } from '../../img';
import FileBase64 from 'react-file-base64';

function AgencyCard(props) {
  const { state, changeNameValue, brandLogo, faviconIcon } = props;

  //font size
  const fontSize = [
    { label: '10', value: 10 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '16', value: 16 },
    { label: '18', value: 18 },
    { label: '20', value: 20 },
    { label: '22', value: 22 },
    { label: '24', value: 24 },
    { label: '26', value: 26 },
    { label: '28', value: 28 },
  ];

  return (
    <div>
      <Box background="bg-surface" borderRadius="200" borderColor="border">
        {/* heading  */}
        <div className='agencyH'>
          <BlockStack>
            <Box padding={400}>
              <Text variant="headingLg">Agency branding</Text>
            </Box>
          </BlockStack>
        </div>


        {/* Settings */}

        <Box padding={400} paddingBlockEnd={300}>
          <div className={state.TotalData?.ibn ? 'settings' : 'setting-low'}>
            <Box padding={400}>
              <InlineStack align='space-between'>
                <Box>
                  <Tooltip hasUnderline content="Mention your branding details like notification powered by 'Your brand name', email configuration & much more" width='wide' padding="400" dismissOnMouseOut >
                    <Text variant="bodyLg" fontWeight='semibold' >Settings</Text>
                  </Tooltip>
                </Box>
                <div className='video_plan' >
                  <VideoThumbnail
                    videoLength={1640}
                    onClick={() => window.open('https://youtu.be/lNdi_FtoPFE?t=1148', '_blank')}
                  />
                </div>
              </InlineStack>
              <Box paddingBlockStart={400}>
                <InlineGrid gap="400" columns={{ xs: 1, sm: 2, md: 2 }}>
                  <Box>
                    <InlineStack align='space-between'>
                      <Box> <Text variant="bodyMd" fontWeight='Medium'>Brand logo</Text></Box>
                      <Tooltip content="Upload company logo" width='wide' padding="400" dismissOnMouseOut >
                        <Box>
                          <span className='edit-icon' style={{ position: 'relative' }}>
                            <Icon source={EditIcon} />
                            <div className='Profile_Img_Icon'>
                              {
                                props.checkPermission('profile', 'isedit', props.profile) &&
                                <FileBase64
                                  multiple={false}
                                  onDone={(file) => brandLogo(file)}
                                />
                              }
                            </div>
                          </span>
                        </Box>
                      </Tooltip>
                    </InlineStack>
                    {/* Brand logo */}
                    <BlockStack gap="200">
                      <div style={{ width: '40px', height: '40px', position: 'relative' }}>
                        {state?.lg ?
                          <img src={state?.lg} width={40} />
                          :
                          <Icon
                            source={Default_logo}
                            tone="base"
                          />}
                        <div className='Profile_Img'>
                          {
                            props.checkPermission('profile', 'isedit', props.profile) &&
                            <FileBase64
                              multiple={false}
                              onDone={(file) => brandLogo(file)}
                            />
                          }
                        </div>
                      </div>
                    </BlockStack>
                  </Box>
                  <Box>
                    <InlineStack align='space-between'>
                      <Box><Text variant="bodyMd" fontWeight='Medium'>Favicon icon</Text></Box>
                      <Tooltip content="Upload company favicon" width='wide' padding="400" dismissOnMouseOut >
                        <Box>
                          <span className='edit-icon' style={{ position: 'relative' }}>
                            <Icon source={EditIcon} />
                            <div className='Profile_Img_Icon'>
                              {
                                props.checkPermission('profile', 'isedit', props.profile) &&
                                <FileBase64
                                  multiple={false}
                                  onDone={(file) => faviconIcon(file)}
                                />
                              }
                            </div>
                          </span>
                        </Box>
                      </Tooltip>
                    </InlineStack>
                    {/* Favicon icon */}
                    <BlockStack gap="200">
                      <div style={{ width: '40px', height: '40px', position: 'relative' }}>
                        {state?.fvicn ?
                          <img src={state?.fvicn} width={40} />
                          :
                          state?.lg
                            ?
                            <img src={state?.lg} width={40} />
                            :
                            <Icon
                              source={Default_logo}
                              tone="base"
                            />}
                        <div className='Profile_Img'>
                          {
                            props.checkPermission('profile', 'isedit', props.profile) &&
                            <FileBase64
                              multiple={false}
                              onDone={(file) => faviconIcon(file)}
                            />
                          }
                        </div>
                      </div>
                    </BlockStack>
                  </Box>
                  <Box>
                    <Text variant="bodyMd" fontWeight='Medium'>Brand logo width</Text>
                    <Box paddingBlockStart={300}>
                      <RangeSlider
                        min={100}
                        max={300}
                        value={state.TotalData?.lgwd}
                        onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, lgwd: e } })}
                        output
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Text variant="bodyMd" fontWeight='Medium'>Brand logo height</Text>
                    <Box paddingBlockStart={300}>
                      <RangeSlider
                        min={50}
                        max={200}
                        value={state.TotalData?.lghgt}
                        onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, lghgt: e } })}
                        output
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box paddingBlockEnd={100}>
                      <Tooltip hasUnderline content="We use this email ID to send notifications about reports and registration/password recovery emails." width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="bodyMd" fontWeight='Medium'>Email ID*</Text>
                      </Tooltip>
                    </Box>
                    <TextField
                      type="email"
                      value={state.TotalData?.feadr}
                      onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, feadr: e } })}
                      autoComplete="email"
                      error={state.errMessage?.['feadr'] ? state.errMessage?.['feadr'] : false}
                    />
                    {props.validator.message('feadr', state.TotalData?.feadr, 'required|email')}
                  </Box>
                  <Box>
                    <Box paddingBlockEnd={100}>
                      <Tooltip hasUnderline content="We use this name in email notifications." width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="bodyMd" fontWeight='Medium'>Email name*</Text>
                      </Tooltip>
                    </Box>
                    <TextField
                      value={state.TotalData?.fenm}
                      onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, fenm: e } })}
                      autoComplete="name"
                      error={state.errMessage?.['fenm'] ? state.errMessage?.['fenm'] : false}
                    />
                    {props.validator.message('fenm', state.TotalData?.fenm, 'required')}
                  </Box>
                  <Box>
                    <InlineStack>
                      <span className='brand-checked'>
                        <Checkbox
                          checked={state.TotalData?.ibn}
                          onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, ibn: e } })}
                        />
                      </span>
                      <Box paddingBlockEnd={200}>
                        <Tooltip hasUnderline content="By default, We will show this custom branding text (that replaces default by 'By wiser') on social proof notification/widget." width='wide' padding="400" dismissOnMouseOut >
                          <Text variant="bodyMd" fontWeight='Medium'>Brand name*</Text>
                        </Tooltip>
                      </Box>
                    </InlineStack>
                    <TextField
                      value={state.TotalData?.bn}
                      onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, bn: e } })}
                      autoComplete="name"
                      error={state.errMessage?.bn ? state.errMessage?.bn : false}
                    />
                    {props.validator.message('bn', state.TotalData?.bn, 'required')}

                  </Box>
                  <Box>
                    <Box paddingBlockEnd={100}>
                      <Tooltip hasUnderline content="By default, We will use this link & make custom branding text clickable." width='wide' padding="400" dismissOnMouseOut >
                        <div className='website_url'> <Text variant="bodyMd" fontWeight='Medium'>Website URL*</Text></div>
                      </Tooltip>
                    </Box>
                    <TextField
                      value={state.TotalData?.bu}
                      onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, bu: e } })}
                      autoComplete="name"
                      error={state.errMessage?.['bu'] ? state.errMessage?.['bu'] : false}
                    />
                    {props.validator.message('bu', state.TotalData?.bu, 'required')}
                  </Box>

                  {state.TotalData?.ibn && (
                    <Box>
                      <Box paddingBlockEnd={100}>
                        <Tooltip hasUnderline content="Set brand name font-size" width='wide' padding="400" dismissOnMouseOut >
                          <Text variant="bodyMd" fontWeight='Medium'>Brand name font-size</Text>
                        </Tooltip>
                      </Box>
                      <Box paddingBlockStart={100}>
                        <Select
                          options={fontSize}
                          onChange={(value) => changeNameValue({ TotalData: { ...state.TotalData, bnfnsz: +value } })}
                          value={state.TotalData?.bnfnsz || ''}
                        />
                      </Box>
                    </Box>
                  )}
                  {state.TotalData?.ibn && (
                    <div id='brand_color_main'>
                      <Box>
                        <Box paddingBlockEnd={100}>
                          <Tooltip hasUnderline content="Set brand name color" width='wide' padding="400" dismissOnMouseOut >
                            <Text variant="bodyMd" fontWeight='Medium'>Brand name color</Text>
                          </Tooltip>
                        </Box>
                        <ColorPick
                          colorKey='TotalData'
                          secondKey='bnfnclr'
                          colorValue={state.TotalData?.bnfnclr}
                          functionCall={props.changeColor}
                        />
                      </Box>
                    </div>
                  )}
                  <Box>
                    <Box paddingBlockEnd={100}>
                      <Tooltip hasUnderline content="Run 3rd party javascript tag" width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="bodyMd" fontWeight='Medium'>Header script</Text>
                      </Tooltip>
                    </Box>
                    <TextField
                      value={state.TotalData?.hscr}
                      onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, hscr: e } })}
                      multiline={3}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Box paddingBlockEnd={100}>
                      <Tooltip hasUnderline content="Run 3rd party javascript tag" width='wide' padding="400" dismissOnMouseOut >
                        <Text variant="bodyMd" fontWeight='Medium'>Footer script</Text>
                      </Tooltip>
                    </Box>
                    <TextField
                      value={state.TotalData?.fscr}
                      onChange={(e) => changeNameValue({ TotalData: { ...state.TotalData, fscr: e } })}
                      multiline={3}
                      autoComplete="off"
                    />
                  </Box>
                </InlineGrid>
                {localStorage.getItem('utyp') === 'appsumo' ? props.checkPermission('white_label_config', 'isedit', props.profile) &&
                  <Box paddingBlockStart={500}>
                    <Button variant="primary" tone='success' onClick={props.handleUpdate}>Update</Button>
                  </Box>
                  :
                  props.checkPermission('white_label_config', 'isedit', props.profile) &&
                  <Box paddingBlockStart={500}>
                    <Button variant="primary" tone='success' onClick={props.handleUpdate}>Update</Button>
                  </Box>
                }
              </Box>
            </Box>
          </div>
        </Box >


        {/* Cname setup */}

        < Box padding={400} paddingBlockStart={100} >
          <div className='cname-setup'>
            <Box padding={400}>
              <Tooltip content="Configure below CNAMEs in your DNS account & make it 100% Whitelabel dashboard" hasUnderline width='wide' padding="400" dismissOnMouseOut >
                <Text variant="bodyLg" fontWeight='semibold'>Cname setup</Text>
              </Tooltip>
            </Box>
            <Box paddingInlineStart={400} paddingBlockStart={0}>
              <Tooltip content="Enter your agency domain URL and make sure you add CNAME URL properly" hasUnderline width='wide' padding="400" dismissOnMouseOut >
                <Text variant="headingSm" fontWeight="medium" as="span">
                  Dashboard URL*<span className='cname-label'>(CNAME: dash.wisercommerce.com)</span>
                </Text>
              </Tooltip>
              <Box paddingInlineEnd={400} paddingBlockStart={100}>
                <TextField
                  value={state.TotalData?.wurl ? 'https://' + state.TotalData.wurl : ''}
                  onChange={props.handleUrlChange}
                  autoComplete="off"
                />
              </Box>
              {
                localStorage.getItem('utyp') === 'appsumo' ?
                  props.checkPermission('white_label_config', 'isedit', props.profile) &&
                    props.profile.apstk.length > 0 &&
                    props.whiteLabel ?
                    props.whiteLabel.isSSL !== true &&
                    <Box paddingBlockStart={400}>
                      <Button variant="primary" tone="success" onClick={props.handleUpdateCname}>
                        Update
                      </Button>
                    </Box>
                    :
                    <Box paddingBlockStart={400}>
                      <Button variant="primary" tone="success" onClick={props.handleUpdateCname}>
                        Update
                      </Button>
                    </Box>
                  :
                  props.checkPermission('white_label_config', 'isedit', props.profile) &&
                  <Box paddingBlockStart={400}>
                    <Button variant="primary" tone="success" onClick={props.handleUpdateCname}>
                      Update
                    </Button>
                  </Box>
              }

            </Box>
            <Box padding={400}>
              <div className='note'>
                <Box padding={300}>
                  <div className='noteH'><Text variant="headingMd" fontWeight='semibold'>Note</Text></div>
                  <Text variant="bodyMd" fontWeight='normal'>You can set your CNAME only once. If you need to change it later, there will be a <span className='blue-text'>$75 </span>fee due to the complexity of the process, which involves generating a new SSL certificate and making internal adjustments.</Text>
                  <Box paddingBlockStart={100}><Text variant="bodyMd" fontWeight='normal'>For any questions, feel free to reach out via <span className='blue-text'>live chat</span>  or email us at <span className='blue-text'>support@wisernotify.com</span> </Text></Box>
                </Box>
              </div>
            </Box>
          </div>
        </Box >

        {/* Plan and User */}
        < NewPlan props={props} />
        <NewUser props={props} />
      </Box >
    </div >
  );
}

export default AgencyCard;
