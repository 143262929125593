import React, { useState, useEffect } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, TextField, Tooltip } from '@shopify/polaris';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import { toastify } from '../../ducks/toast';
import moment from 'moment';
import IntegrationHeader from './IntegrationHeader';

function ShopifyCard(props) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props.props;
  const [value, setValue] = useState({
    intDataList: [],
    domain: '',
    errMessage: {}
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.userIntData);

  useEffect(() => {
    changeValue({ intDataList })
  }, [intDataList])

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updIntIsActive(obj))
    const filterData = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  const handleupdPixelActive = (_id, active) => {
    if (active == true) {
      dispatch(integrationDucks.addPixelTag({ id: _id }));
    }
    else {
      dispatch(integrationDucks.deletePixel(_id));
    }
    const filterData = value.intDataList && value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, pixelStatus: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  const handleCreateBtn = async () => {
    if (props.validator.errorMessages.domain) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      if (value.domain) {
        let { userIntegration } = props;
        let storeName = value.domain?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0].split('?')[0];
        let shop = storeName + '.myshopify.com';
        let data = userIntegration.filter(x => x.shop == shop);
        var isValid = storeName.includes('.myshopify.com');
        if (isValid) {
          dispatch(toastify({ type: 'error', msg: "Enter valid store name" }));
        }
        else if (data && data.length > 0) {
          dispatch(toastify({ type: 'error', msg: "Shopfy account already in use!" }));
        }
        else {
          // if (cookies.get('aff')) {
          //   window.location.href = process.env.REACT_APP_SHOPIFY_API_URL + storeName + '.myshopify.com?aff=' + cookies.get('aff');
          // }
          // else {
          const ti = props.profile ? props.profile.ti : '';
          // window.location.href = process.env.REACT_APP_SHOPIFY_API_URL + storeName + '.myshopify.com&ti=' + ti;

          const _path = process.env.REACT_APP_API_URL + `/shopify/oauth?host=https://app.wisernotify.com/&shop=${value.domain}.myshopify.com&ti=${ti}`;

          //Retrieve the redirect URL using fetch instead of performing a backend redirect
          const response = await fetch(_path, {
            method: 'GET',
            headers: {},
          });
          const data = await response.text();
          window.location.href = data;
          //}
        }
      }
      changeValue({ errMessage: {} })
    }
  }

  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => (
      <IndexTable.Row
        id={usInt._id}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {index + 1}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{usInt.shop}</IndexTable.Cell>
        <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
        <IndexTable.Cell> <label className="switch">
          <input type="checkbox" checked={usInt.ia}
            onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
          <span className="switch-label"></span>
          <span className="switch-handle"></span>
        </label></IndexTable.Cell>
        <IndexTable.Cell> <label className="switch">
          <input type="checkbox" checked={usInt.pixelStatus}
            onChange={() => handleupdPixelActive(usInt._id, !usInt.pixelStatus)} />
          <span className="switch-label"></span>
          <span className="switch-handle"></span>
        </label></IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={<div className='app-inner-box'><img src={state.img} alt='' className='app-inner-img' /><span>{state.dn}</span></div>} backAction={{ onAction: () => handleBackToMain() }} primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to connect</Button> : <></>}>
      <BlockStack gap={200}>
        <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        />
        <Card>
          {props.checkPermission('integration', 'isadd', props.profile) &&
            <>
              <div className='form-width'>
                <InlineStack blockAlign="center" >
                  <div className='textfeild-lable'>
                    <Tooltip content={state.tl.su} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                      <Text variant="headingSm" as="h6">Store URL</Text>
                    </Tooltip>
                  </div>
                  <div className='textfeild_shp'>
                    <TextField
                      value={value.domain}
                      onChange={(e) => changeValue({ domain: e })}
                      placeholder='Domain'
                      autoComplete="off"
                      error={value.errMessage.domain ? "The domain field is required." : false}
                      suffix='.myshopify.com'
                    />
                    {props.validator.message('domain', value.domain, 'required')}
                  </div>
                </InlineStack>
              </div>
              <Box align='center' padding={300}><Button variant="primary" onClick={() => handleCreateBtn()}>Create</Button></Box>
            </>
          }
          <Box padding={400}>
            <Box><Text variant="headingSm" as="h6">Account details</Text></Box>
            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: 'No' },
                  { title: 'Store' },
                  { title: 'Created date' },
                  { title: 'Active' },
                  { title: 'Active pixel' }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>
    </Page>
  )
}

export default ShopifyCard;