import React from 'react'
import { Button, InlineStack, Text, Box, IndexTable, IndexFilters, EmptySearchResult, Badge, useIndexResourceState, BlockStack, useSetIndexFiltersMode, Card } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';

function AssignNotifCard({ props }) {
  const { mode, setMode } = useSetIndexFiltersMode();
  const { state, changeNameValue } = props;
  let Tdata = state.getUserNotifList?.map(x => ({ ...x, id: x._id }));

  let manageTeamList = state.manageTeamList;

  // Initialize the defaultSelectedIds with an empty array if not available yet
  const defaultSelectedIds = manageTeamList?.[0]?.ntflst || [];

  let { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(Tdata, {
      selectedResources: defaultSelectedIds,
    });

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No Items found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );


  return (
    <Box>
      <div className='topName'>
        <Box paddingBlock={"400"} paddingInline={"600"}>
          <InlineStack gap={200}>
            <Button variant='monochromePlain' icon={ArrowLeftIcon} onClick={() => changeNameValue({ isAssign: false })}></Button>
            <Text variant="headingLg" as="h5">
              {state.selectedfn}
            </Text>
          </InlineStack>
        </Box>
      </div>

      <div className='bgBox' style={{ height: '690px' }}>
        <Box padding={400}>
          <Box paddingBlockEnd={400} paddingInlineStart={300}>
            <Text variant="headingMd" as="h3">
              Assign notification
            </Text>
            <Text>Select the notification & click on assign</Text>
          </Box>
          <div style={{ height: '570px', overflowY: 'scroll' }}>
            <Card padding={0}>
              <IndexFilters
                queryValue={state.queryValue}
                queryPlaceholder="Search..."
                onQueryChange={(e) => changeNameValue({ queryValue: e })}
                onQueryClear={() => changeNameValue({ queryValue: '' })}
                cancelAction={{
                  onAction: () => changeNameValue({ queryValue: '', selectedDomain: '' })
                }}
                tabs={props.tabs}
                canCreateNewView={false}
                selected={state.selected}
                onSelect={(e) => changeNameValue({ selected: e })}
                filters={props.filters}
                appliedFilters={props.appliedFilters}
                onClearAll={props.handleFiltersClearAll}
                mode={mode}
                setMode={setMode}
              />
              <IndexTable
                itemCount={state.getUserNotifList ? state.getUserNotifList.length : 0}
                emptyState={emptyStateMarkup}
                selectedItemsCount={
                  allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: 'Notification' },
                  { title: 'Status' }
                ]}
              >
                {state.getUserNotifList?.map((elm, index) => (
                  <IndexTable.Row
                    id={elm._id}
                    key={index}
                    selected={selectedResources.includes(elm._id)}
                    position={index}
                    onClick={() => changeNameValue({})} //for whole row click none
                  >
                    <IndexTable.Cell>
                      <BlockStack>
                        <InlineStack gap={200}>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {elm.nn}
                          </Text>
                          <Box><Badge tone="info">{props.getNotifType(elm.ntt.replace(/_/g, ' '))}</Badge></Box>
                        </InlineStack>
                        <div className='domainWrap'>
                          {
                            elm.ddn.length > 0 &&
                            elm.ddn.map((d, j) => {
                              if (d.ul !== null) {
                                return (
                                  <a className="domian-name" target="_blank" href={`${'http://' + d.ul}`}><Text tone="subdued">{d.ul}</Text></a>
                                );
                              }
                              return '-';
                            })
                          }
                        </div>
                      </BlockStack>
                    </IndexTable.Cell>

                    <IndexTable.Cell>{elm.ia == true ? <Badge tone="success">Active</Badge> : <Badge tone="critical">Deactive</Badge>}</IndexTable.Cell>
                  </IndexTable.Row>
                ))
                }
              </IndexTable>
            </Card>
          </div>
          <Box paddingBlockStart={300} align='end'>
            <Button variant="primary" tone="success" onClick={() => props.handleAssignBtn(selectedResources)}>Assign</Button>
          </Box>
        </Box>
      </div>
    </Box>
  )
}

export default AssignNotifCard;