import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const rateUs = createSlice({
  name: 'rateUs',
  initialState,
  reducers: {
    getRateUs: (state, action) => { },
    getRateUsSuccess: (state, action) => {
      return { ...state, rateUs: action.payload };
    },
    addUserReward: (state, action) => { },
    addUserRewardSuccess: (state, action) => {
      return { ...state, UserReward: action.payload };
    }
  }
});

export const {
  getRateUs,
  getRateUsSuccess,
  addUserReward,
  addUserRewardSuccess,
} = rateUs.actions;

export default rateUs.reducer;
