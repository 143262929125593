import React, { useState } from 'react';
import {
  IndexTable, useSetIndexFiltersMode, Tooltip, Divider, InlineStack, DatePicker, IndexFilters, useBreakpoints, Text, Card, Page, Button, Popover, Box, Modal, ButtonGroup, EmptySearchResult
} from '@shopify/polaris';
import AnalyticsModal from '../../containers/Analytics/Analytics';
import { IconChartDown_icon, IconChartUp_icon } from '../../img';
import { ComposeIcon, ChartDonutIcon } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { skeleton } from '../../utils/skeleton';

const DashboardCard = (props) => {

  const { state, changeNameValue, PerformanceData, handleSelectDate, checkPermission, profile } = props;

  const { mode, setMode } = useSetIndexFiltersMode();
  const { mdDown, lgUp } = useBreakpoints();
  const isLoading = useSelector(state => state?.loading?.isLoading);

  const [activeDateRange, setActiveDateRange] = useState({
    since: new Date(new Date().setHours(0, 0, 0, 0)),
    until: new Date(new Date().setHours(0, 0, 0, 0))
  });

  const [{ month, year }, setDate] = useState({
    month: activeDateRange.since.getMonth(),
    year: activeDateRange.since.getFullYear()
  });

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.since.toDateString(),
      tdt: state.until.toDateString()
    }
    changeNameValue(obj);
  }

  const handleCalendarChange = ({ start, end }) => {
    const newDateRange = { since: start, until: end };

    changeNameValue({
      frmdt: newDateRange.since.toDateString(),
      tdt: newDateRange.until.toDateString()
    });
    activeRangeChange(newDateRange);
  };

  const checkValidDate = (dateString) => {
    if (dateString && !dateString.includes("Invalid")) {
      return true;
    } else {
      return false;
    }
  }

  // for custom date popup open (same tab click, event is not coming)
  if (document.getElementById("Custom Date-3")) {
    document.getElementById("Custom Date-3").onclick = function () {
      changeNameValue({ popoverActive: true });
    };
  }

  function formatNumber(num) {
    if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else {
      return num.toString();
    }
  }

  const percentage = (cur, pre, type) => {
    if (type === 'unique') {
      if (cur === 0) {
        let _uni = ((cur - pre) * 100);
        if (Math.abs(_uni.toFixed()) > 1000) {
          return -1000;
        }
        else {
          return _uni;
        }
      }
      else if (pre === 0) { return 0; }
      else { return (cur * 100) / pre - 100; }
    }
    if (type === 'view') {
      if (cur === 0) {
        let _view = ((cur - pre) * 100);
        if (Math.abs(_view.toFixed()) > 1000) {
          return -1000;
        }
        else {
          return _view;
        }
      }
      else if (pre === 0) { return 0; }
      else { return (cur * 100) / pre - 100; }
    }
    if (type === 'hover') {
      if (cur === 0) {
        let _hover = ((cur - pre) * 100);
        if (Math.abs(_hover.toFixed()) > 1000) {
          return -1000;
        }
        else {
          return _hover;
        }
      }
      else if (pre === 0) { return 0; }
      else { return (cur * 100) / pre - 100; }
    }
    if (type === 'click') {
      if (cur === 0) {
        let _click = ((cur - pre) * 100);
        if (Math.abs(_click.toFixed()) > 1000) {
          return -1000;
        }
        else {
          return _click;
        }
      }
      else if (pre === 0) { return 0; }
      else { return (cur * 100) / pre - 100; }
    }
    if (type === 'close') {
      if (cur === 0) {
        let _close = ((cur - pre) * 100);
        if (Math.abs(_close.toFixed()) > 1000) {
          return -1000;
        }
        else {
          return _close;
        }
      }
      else if (pre === 0) { return 0; }
      else { return (cur * 100) / pre - 100; }
    }
  }
  const getHeaderNm = (ttl, desc) => {
    return <Tooltip padding='400' width='wide' content={<span><b>{ttl} :</b> {desc}</span>} hasUnderline dismissOnMouseOut>
      <span id='cursorPointer'>{ttl}</span>
    </Tooltip>;
  }

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No Items found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  //function for set min-height of table if user has selected custom date
  const newTestFunction = (e) => {
    setTimeout(() => {
      let customStyle = document.getElementById('ab-test-dynamic-style');

      if (document.getElementById('notif_cstm_dtPop') && document.getElementById('notif_cstm_dtPop').style.display === 'block') {
        if (!customStyle) {
          customStyle = document.createElement('style');
          customStyle.id = 'ab-test-dynamic-style';
          customStyle.textContent = `
              #tbl-performance .Polaris-IndexTable-ScrollContainer {
                min-height: 350px;
              }`;
          document.head.appendChild(customStyle);
        }
      }
      else {
        if (customStyle) {
          customStyle.remove();
        }
      }
    }, 10);
  }

  return (
    <div className='header-title-managereviews'>
      <Page title={"Performance"}>

        <Box id='dash_table' paddingBlockEnd={400}>
          <Card padding={0}>

            <IndexFilters
              queryValue={state.queryValue}
              queryPlaceholder="Search..."
              onQueryChange={(e) => changeNameValue({ queryValue: e, isClearRadis: 'true' })}
              onQueryClear={() => changeNameValue({ queryValue: '' })}
              cancelAction={{
                onAction: () => changeNameValue({ queryValue: '', nTyp: 'All' })
              }}
              tabs={props.tabs}
              canCreateNewView={false}
              selected={state.selected}
              onSelect={(e) => { changeNameValue({ selected: e }); newTestFunction(e); }}
              filters={props.filters}
              appliedFilters={props.appliedFilters}
              onClearAll={props.handleFiltersClearAll}
              mode={mode}
              setMode={setMode}
            />

            {/* custom date popup */}
            <div className='notif_cstm_dtPop' id='notif_cstm_dtPop' style={{ display: state.popoverActive ? 'block' : 'none' }}>
              <Box maxWidth='580px'>
                <Card padding={200}>
                  <Popover.Pane>
                    <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                      <InlineStack gap="400">
                        <DatePicker
                          month={month}
                          year={year}
                          selected={{
                            start: activeDateRange.since,
                            end: activeDateRange.until,
                          }}
                          onMonthChange={(month, year) => setDate({ month, year })}
                          onChange={handleCalendarChange}
                          disableDatesBefore={new Date(new Date().setHours(0, 0, 0, 0) - 61 * 24 * 60 * 60 * 1000)}
                          disableDatesAfter={new Date()}
                          multiMonth={lgUp}
                          allowRange />
                      </InlineStack>
                    </Box>
                  </Popover.Pane>

                  <Divider />
                  <Box padding={200}>
                    <InlineStack gap={600} align="end" blockAlign='center'>
                      <Text>
                        {(checkValidDate(state.frmdt) && checkValidDate(state.tdt))
                          ? state.frmdt + " - " + state.tdt : ''}
                      </Text>
                      <ButtonGroup>
                        <Button variant='tertiary' onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                        <Button variant='primary' onClick={handleSelectDate}>Apply</Button>
                      </ButtonGroup>
                    </InlineStack>
                  </Box>

                </Card>
              </Box>
            </div>

            <div className='tbl-performance' id='tbl-performance'>
              <IndexTable
                itemCount={PerformanceData?.length === undefined ? 1 : PerformanceData?.length}
                selectable={false}
                emptyState={emptyStateMarkup}
                headings={[
                  { title: 'Notification name' },
                  { title: getHeaderNm('Visitors', 'The total number of unique visitors who saw this notification.') },
                  { title: getHeaderNm('Impressions', 'The total number of times this notification was displayed to visitors.') },
                  { title: getHeaderNm('Hovers', 'The number of times visitors hovered over the notification.') },
                  { title: getHeaderNm('Clicks', 'The number of times visitors clicked on the notification.') },
                  { title: getHeaderNm('CTR', 'The percentage of visitors who clicked on the notification after seeing it.') },
                  { title: getHeaderNm('ER', 'The percentage of visitors who interacted with the notification, such as hovering or clicking.') },
                  { title: 'Actions' }
                ]}
                pagination={{
                  // label: props.paggiLabel,
                  hasPrevious: state.paggiActive > 1 ? true : false,
                  hasNext: (state.paggiActive < state.pageNumber) ? true : false,
                  onPrevious: () => props.onPaggiCall('-1'),
                  onNext: () => props.onPaggiCall('+1')
                }} >

                {
                  isLoading ?
                    skeleton(10, 8)
                    :
                    PerformanceData && PerformanceData.length > 0 && PerformanceData.map((data, index) => {
                      let up = percentage(data.currentPeriodUnique, data.previousPeriodUnique, 'unique');
                      let ip = percentage(data.currentPeriodView, data.previousPeriodView, 'view');
                      let hp = percentage(data.currentPeriodHover, data.previousPeriodHover, 'hover');
                      let cp = percentage(data.currentPeriodClick, data.previousPeriodClick, 'click');
                      // let cs = percentage(data.currentPeriodClose, data.previousPeriodClose, 'close');

                      return (
                        <IndexTable.Row
                          id={index}
                          key={index}
                          position={index}
                        >
                          <IndexTable.Cell>
                            <Text variant="headingXs" fontWeight="semibold" as="p">{data.notificationName}</Text>

                            <Box paddingBlockStart={100} >
                              <Text variant="headingXs" as="p" fontWeight="medium" tone='subdued' >
                                <span >{data.ddn[0].ul}</span>
                              </Text>
                            </Box>
                          </IndexTable.Cell>

                          <IndexTable.Cell>
                            <Text as="h6" >
                              <InlineStack gap={100}>
                                <div> {formatNumber(data.currentPeriodUnique)}</div>
                                {state.selected !== 2 && state.selected !== 3 &&
                                  <>
                                    <div>
                                      {
                                        up !== 0 ?
                                          up > 0 ?
                                            <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                            :
                                            <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                          : null
                                      }
                                    </div>
                                    {state.selected !== 2 && state.selected !== 3 && up !== 0 ?
                                      <sup className={up !== 0 ? up > 0 ? "chart-up-arw" : "chart-down-arw" : ''}>
                                        {up > 1000 ? 1000 : Math.abs(up.toFixed())}% </sup>
                                      : null}
                                  </>
                                }
                              </InlineStack>
                            </Text>
                          </IndexTable.Cell>


                          <IndexTable.Cell>
                            <Text as="h6" >
                              <InlineStack gap={100}>
                                <div> {formatNumber(data.currentPeriodView)}</div>
                                {state.selected !== 2 && state.selected !== 3 &&
                                  <>
                                    <div>
                                      {
                                        ip !== 0 ?
                                          ip > 0 ?
                                            <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                            :
                                            <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                          : null
                                      }
                                    </div>
                                    {state.selected !== 2 && state.selected !== 3 && up !== 0 ?
                                      <sup className={ip !== 0 ? ip > 0 ? "chart-up-arw" : "chart-down-arw" : ''}>
                                        {ip > 1000 ? 1000 : Math.abs(ip.toFixed())}% </sup>
                                      : null}
                                  </>
                                }
                              </InlineStack>
                            </Text>
                          </IndexTable.Cell>

                          <IndexTable.Cell>
                            <Text as="h6" >
                              <InlineStack gap={100}>
                                <div> {formatNumber(data.currentPeriodHover)}</div>
                                {state.selected !== 2 && state.selected !== 3 &&
                                  <>
                                    <div>
                                      {
                                        hp !== 0 ?
                                          hp > 0 ?
                                            <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                            :
                                            <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                          : null
                                      }
                                    </div>
                                    {state.selected !== 2 && state.selected !== 3 && up !== 0 ?
                                      <sup className={hp !== 0 ? hp > 0 ? "chart-up-arw" : "chart-down-arw" : ''}>
                                        {hp > 1000 ? 1000 : Math.abs(hp.toFixed())}% </sup>
                                      : null}
                                  </>
                                }
                              </InlineStack>
                            </Text>
                          </IndexTable.Cell>

                          <IndexTable.Cell>
                            <Text as="h6" >
                              <InlineStack gap={100}>
                                <div> {formatNumber(data.currentPeriodClick)}</div>
                                {state.selected !== 2 && state.selected !== 3 &&
                                  <>
                                    <div>
                                      {
                                        cp !== 0 ?
                                          cp > 0 ?
                                            <img className="chart-up-arw" src={IconChartUp_icon} alt="arrow" />
                                            :
                                            <img className="chart-down-arw" src={IconChartDown_icon} alt="arrow" />
                                          : null
                                      }
                                    </div>
                                    {state.selected !== 2 && state.selected !== 3 && up !== 0 ?
                                      <sup className={cp !== 0 ? cp > 0 ? "chart-up-arw" : "chart-down-arw" : ''}>
                                        {cp > 1000 ? 1000 : Math.abs(cp.toFixed())}% </sup>
                                      : null}
                                  </>
                                }
                              </InlineStack>
                            </Text>
                          </IndexTable.Cell>

                          <IndexTable.Cell>
                            <Text as="h6" >
                              <span>{(data.currentPeriodClick / data.currentPeriodUnique * 100) > 0 ? (data.currentPeriodClick / data.currentPeriodUnique * 100).toFixed(2) + '%' : '0.00%'}</span>
                            </Text>
                          </IndexTable.Cell>

                          <IndexTable.Cell>
                            <Text as="h6" >
                              <span>{(data.currentPeriodHover / data.currentPeriodUnique * 100) > 0 ? (data.currentPeriodHover / data.currentPeriodUnique * 100).toFixed(2) + '%' : '0.00%'}</span>
                            </Text>
                          </IndexTable.Cell>

                          <IndexTable.Cell>
                            {
                              checkPermission('performance', 'isview', profile) &&
                              <ButtonGroup>
                                <Button variant='monochromePlain' icon={ChartDonutIcon} onClick={() => changeNameValue({ analyticsOpen: true, analyticNotifId: data.notificationid })} />
                                {
                                  checkPermission('notification', 'isedit', profile) &&
                                  <Button variant='monochromePlain' icon={ComposeIcon} onClick={() => props.handleEditBtn(data)} />
                                }
                              </ButtonGroup>
                            }
                          </IndexTable.Cell>

                        </IndexTable.Row>
                      );
                    })}
              </IndexTable>
            </div>
          </Card>
        </Box >

        {/* open Analytics modal */}
        <Modal
          size='large'
          open={state.analyticsOpen}
          onClose={() => changeNameValue({ analyticsOpen: false, analyticNotifId: '' })}
        >
          <Box minHeight='90vh' id='add_inte_wrap'>
            <AnalyticsModal notifId={state.analyticNotifId} frm={'performance'} />
          </Box>
        </Modal>
      </Page >
    </div >
  );
}

export default DashboardCard;